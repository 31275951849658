import React from "react";
import { VscAdd } from "react-icons/vsc";
import { BsListUl } from "react-icons/bs";
import { RiKeyboardLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import NoPermission from "../NoPermission/NoPermission";

const DepartmentsManagement = () => {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "department.add_department",
    "department.view_department\n",
  ]);

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={"Failed to Fetch Permissions"} />;

  const canCreateDepartment =
    permissions?.find((permission) => permission.name === "department.add_department")?.value === true;

  const canViewDepartment =
    permissions?.find((permission) => permission.name === "department.view_department")?.value === true;

  if (!canCreateDepartment && !canViewDepartment) {
    return <NoPermission />;
  }

  return (
    <div className="w-full border-gray-300 border rounded-lg bg-white py-4">
      <div className="w-[98%] flex justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 ">

        <p className="flex items-center gap-x-3">
          <RiKeyboardLine className="w-5 h-5" />
          Department Management
        </p>
      </div>
      <div className="grid grid-cols-2  gap-y-5 mt-12">

        {canCreateDepartment && (
          <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
            <Link
              to={"./create-department"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <VscAdd className="w-12 h-12  " />
              </div>
            </Link>
            <p className="text-lg font-semibold">Create Department</p>
          </div>
        )}

        {canViewDepartment && (
          <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
            <Link
              to={"./departments-list"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full  bg-slate-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <BsListUl className="w-12 h-12  " />
              </div>
            </Link>
            <p className="text-lg font-semibold ">Departments List</p>
          </div>
        )}

      </div>
    </div>
  );
};

export default DepartmentsManagement;
