import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { MdOutlineAccessTime } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";

import {
  getSelectedBookedRoomAction,
  getSelectedBookedRoomSingleAction,
} from "../../../redux/actions/bookingListAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { GoPerson } from "react-icons/go";
import { TbBuildingEstate } from "react-icons/tb";
import { IoBedOutline, IoCloudyNightOutline, IoPersonOutline } from "react-icons/io5";
import { LiaCalendar, LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { GiHotMeal, GiPriceTag } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { PiDatabase } from "react-icons/pi";
import { getSelectedTransactionAction } from "../../../redux/actions/allTransactionsAction";
import ListFilters from "../../../utils/ListFilters";

const BookingListTableId = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const [isloading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [amounts, setAmounts] = useState([]);
  const [booking, setBooking] = useState([]);
  useEffect(() => {
    dispatch(
      getSelectedBookedRoomAction(token, params.short_id, (result) => {
        if (result.status === 200) {
          const newAmounts = [];
          const newBooking = [];
          result.data.map((item) => {
            dispatch(
              getSelectedTransactionAction(token, item.id, (result) => {
                if (result.status === 200) {
                  newAmounts.push(...result.data.results);
                }
              }),
            );
            // dispatch(
            //   getOptionByIdAction(token, item.id, (result) => {
            //     if (result.status === 200) {
            //
            //       newBooking.push(result.data);
            //     }
            //   }),
            // );
          });
          setAmounts(newAmounts);
          setBooking(newBooking);
        }
      }),
    );
  }, []);

  const { selectedBookedRoom } = useSelector((state) => state.bookingListReducer);

  const { selectBookingRoomFlag } = useSelector((state) => state.bookingListReducer);

  const rows = selectedBookedRoom.map((item, index) => {
    return {
      short_id: item.short_id,
      //  booking_group:item.booking_group,
      id: item.id,
      client_names: item.client_names.map((info) => info.arabic_name),
      client_names_english: item.client_names.map((info) => info.english_name),
      hotel: item.hotel,
      room_name: item.room_name,
      room: item.room,
      arrival_time_from: item.arrival_time_from,
      arrival_time_to: item.arrival_time_to,
      from_date: item.from_date,
      to_date: item.to_date,
      nights: item.nights,
      currency_type: item.currency_type,
      total_price: item.total_price,
      number_of_persons: item.number_of_persons,
      phone_number: item.phone_number,
      whatsapp_number: item.whatsapp_number,
      email: item.email,
      booking_type: item.booking_type,
      payment_type: item.payment_type,
      client_requests_tags: item.client_requests_tags,
      client_requests_text: item.client_requests_text,
      meal_plan: item.meal_plan,
      adults: item.adults,
      created_date: new Date(item.created_date).toLocaleDateString(),
      created_time: item.created_date.slice(11, 19),
      last_updated_date: new Date(item.last_updated_date).toLocaleDateString(),
      paid: 1,
      inventory_status: item.inventory_status,
      num: index,
    };
  });

  const columns = [
    {
      key: "short_id",
      title: (
        <div className="flex items-center gap-x-1">
          <PiDatabase className="w-5 h-5  text-yellow-500 mb-1" /> BOOKING ORDER{" "}
        </div>
      ),
      dataIndex: "short_id",
      render: (text, params) => {
        return (
          <button
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
            }}
            className={`w-full h-full flex items-center gap-x-1 ${!params.inventory_status && "text-yellow-600"}`}
          >
            <div className="w-24">{params.short_id}</div>
            <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
          </button>
        );
      },
    },
    //   {

    //     key: 'booking_group',
    //     title: 'BOOKING  ORDER',
    //     width: 200 ,
    // },
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1">
          <PiDatabase className="w-5 h-5   text-yellow-500 mb-1" /> ID{" "}
        </div>
      ),
      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
            className={`w-full h-full flex items-center gap-x-1 ${!params.inventory_status && "text-yellow-600"}`}
          >
            {params.id}
            <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35] " />
          </button>
        );
      },
    },
    {
      key: "client_names",
      title: (
        <div className=" flex items-center gap-x-1">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          CLIENT NAME (AR)
        </div>
      ),
      dataIndex: "client_names",
      editable: false,
      render: (text, params) => {
        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {params.client_names[0]}
          </button>
        );
      },
    },
    {
      key: "client_names_english",
      title: (
        <div className="  flex items-center gap-x-1 ">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          CLIENT NAME (EN)
        </div>
      ),
      dataIndex: "client_names_english",
      editable: false,
      render: (text, params) => {
        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <GoPerson className='w-5 h-5  text-[#1f3a53]' /> */}
            {params.client_names_english[0]}
          </button>
        );
      },
    },
    {
      key: "hotel",
      title: (
        <div className=" flex  items-center gap-x-1">
          <TbBuildingEstate className="w-5 h-5  text-yellow-500 mb-1" />
          HOTEL NAME
        </div>
      ),
      dataIndex: "hotel",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-2 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.hotel}
          </button>
        );
      },
    },
    {
      key: "room_name",
      title: (
        <div className=" flex items-center gap-x-1">
          <IoBedOutline className="w-5 h-5  text-yellow-500 mb-1" />
          ROOM TYPE
        </div>
      ),
      editable: false,
      dataIndex: "room_name",
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-5 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <IoBedOutline className='w-5 h-5 text-yellow-600' /> */}
            {params.room_name}
          </button>
        );
      },
    },
    //   {
    //   key: 'room',
    //   title: <div className='flex items-center gap-x-1'><IoKeyOutline className='w-5 h-5  text-yellow-500' />ROOM ID</div>,
    //   width: 150,
    //   editable: false,
    //   render: (text,params) => {
    //     //

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <IoKeyOutline className='w-5 h-5 text-teal-600' /> */}
    //                 {params.room}
    //         </button>

    //     );
    //   },
    //  },
    {
      key: "arrival_time_from",
      title: (
        <div className="flex items-center gap-x-1">
          <MdOutlineAccessTime className="w-5 h-5  text-yellow-500" />
          ARRIVAL_TIME_FROM
        </div>
      ),

      dataIndex: "arrival_time_from",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            <MdOutlineAccessTime className="w-5 h-5 text-green-700" />
            {params.arrival_time_from}
          </button>
        );
      },
    },
    {
      key: "arrival_time_to",
      title: (
        <div className="  flex items-center gap-x-1">
          <MdOutlineAccessTime className="w-5 h-5  text-yellow-500" />
          ARRIVAL_TIME_TO
        </div>
      ),

      dataIndex: "arrival_time_to",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            <MdOutlineAccessTime className="w-5 h-5 text-red-700" />
            {params.arrival_time_to}
          </button>
        );
      },
    },
    {
      key: "from_date",
      title: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CHECK-IN
        </div>
      ),

      dataIndex: "from_date",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-2 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            <LiaCalendar className="w-5 h-5 text-green-700" />
            {params.from_date}
          </button>
        );
      },
    },
    {
      key: "to_date",
      title: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CHECK-OUT
        </div>
      ),

      dataIndex: "to_date",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-2 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            <LiaCalendar className="w-5 h-5 text-red-700" />
            {params.to_date}
          </button>
        );
      },
    },

    {
      key: "nights",
      title: (
        <div className=" flex items-center gap-x-1">
          <IoCloudyNightOutline className="w-5 h-5  text-yellow-500" />
          NIGHTS
        </div>
      ),

      dataIndex: "nights",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <IoCloudyNightOutline className='w-5 h-5 text-yellow-500' /> */}
            {params.nights}
          </button>
        );
      },
    },
    {
      key: "total_price",
      title: (
        <div className=" flex items-center gap-x-1">
          <GiPriceTag className="w-5 h-5  text-yellow-500" />
          TOTAL PRICE
        </div>
      ),

      dataIndex: "total_price",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-5 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <GiPriceTag className='w-5 h-5 text-teal-700' /> */}
            {params.total_price} {params.currency_type}
          </button>
        );
      },
    },
    {
      key: "paid",
      title: (
        <div className=" flex items-center gap-x-1 ml-10">
          <GiPriceTag className="w-5 h-5  text-yellow-500" />
          PAID
        </div>
      ),

      dataIndex: "paid",
      editable: false,

      render: (text, params) => {
        //  const paids = selectedTransaction && selectedTransaction.results.map((item)=>item.booked_room_id  === 699)
        const paidFilter = amounts.filter((item) => item.booked_room_id === params.id);

        const visa = paidFilter.filter((item) => item.transaction_type === "(POS) Visa");
        const notvisa = paidFilter.filter((item) => item.transaction_type !== "(POS) Visa");

        return (
          <button
            className={`w-full h-full flex justify-center items-center gap-x-1  ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {paidFilter.length > 0
              ? paidFilter
                  .filter((item) => item.transaction_status === "confirmed")
                  .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0)
              : 0.0}
            <span className="text-blue-950">{params.currency_type}</span>
          </button>
        );
      },
    },
    {
      key: "balance",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <GiPriceTag className="w-5 h-5  text-yellow-500" />
          OUTSTANDING AMOUNT
        </div>
      ),

      dataIndex: "balance",
      editable: false,
      render: (text, params) => {
        //
        const paidFilter = amounts.filter((item) => item.booked_room_id === params.id);
        const visa = paidFilter.filter((item) => item.transaction_type === "(POS) Visa");
        const notvisa = paidFilter.filter((item) => item.transaction_type !== "(POS) Visa");

        return (
          <div className="w-full h-full f">
            <button
              className={`w-full h-full flex justify-center items-center gap-x-1   ${!params.inventory_status && "text-yellow-600"}`}
              onClick={() => {
                navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
                dispatch(getSelectedBookedRoomSingleAction(token, params.id));
              }}
            >
              {paidFilter.length > 0 ? (
                +params.total_price -
                  paidFilter
                    .filter((item) => item.transaction_status === "confirmed")
                    .reduce(
                      (acc, balc) => acc + parseFloat(balc.conversion_rate_price && balc.conversion_rate_price),
                      0,
                    ) >
                0 ? (
                  <p className="text-red-700 flex items-center gap-x-1 ">
                    {+params.total_price -
                      paidFilter
                        .filter((item) => item.transaction_status === "confirmed")
                        .reduce(
                          (acc, balc) => acc + parseFloat(balc.conversion_rate_price && balc.conversion_rate_price),
                          0,
                        )}
                    <span className="text-blue-950">{params.currency_type}</span>{" "}
                  </p>
                ) : (
                  <p className="text-green-700 flex items-center gap-x-1">
                    {+params.total_price -
                      paidFilter
                        .filter((item) => item.transaction_status === "confirmed")
                        .reduce(
                          (acc, balc) => acc + parseFloat(balc.conversion_rate_price && balc.conversion_rate_price),
                          0,
                        )}
                    <span className="text-blue-950">{params.currency_type}</span>
                  </p>
                )
              ) : (
                <p className="text-red-700 flex items-center gap-x-1">
                  {+params.total_price}
                  <span>{params.currency_type}</span>
                </p>
              )}
            </button>
          </div>
        );
      },
    },

    {
      key: "number_of_persons",
      title: (
        <div className=" flex items-center gap-x-1">
          <IoPersonOutline className="w-5 h-5  text-yellow-500" />
          PERSONS
        </div>
      ),

      dataIndex: "number_of_persons",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <IoPersonOutline className='w-5 h-5 text-blue-900' /> */}
            {params.number_of_persons}
          </button>
        );
      },
    },
    // {
    //   key: 'email',
    //   title: <div className=' flex items-center gap-x-1'><AiOutlineMail className='w-5 h-5  text-yellow-500' />EMAIL</div>,

    //   width: 200,
    //   editable: false,
    //   render: (text,params) => {
    //     //

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <AiOutlineMail className='w-5 h-5 text-amber-800' /> */}
    //                 {params.email}
    //         </button>

    //     );
    //   },
    // },
    // {
    //   key: 'phone_number',
    //   title: <div className=' flex items-center gap-x-1'><AiOutlinePhone className='w-5 h-5  text-yellow-500' />PHONE NUMBER</div>,

    //   width: 200,
    //   editable: false,
    //   render: (text,params) => {
    //     //

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <AiOutlinePhone className='w-5 h-5 text-orange-600' /> */}
    //                 {params.phone_number}
    //         </button>

    //     );
    //   },
    // },
    // {
    //   key: 'whatsapp_number',
    //   title: <div className='flex items-center gap-x-1'><MdOutlineWhatsapp className='w-5 h-5  text-yellow-500' />WHATSAPP NUMBER</div>,

    //   width: 300,
    //   editable: false,
    //   render: (text,params) => {
    //     //

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <MdOutlineWhatsapp className='w-5 h-5 text-sky-600' /> */}
    //                 {params.whatsapp_number}
    //         </button>

    //     );
    //   },
    // },
    {
      key: "booking_type",
      title: (
        <div className="flex items-center gap-x-1">
          <LiaFileInvoiceDollarSolid className="w-5 h-5 mb-1 text-yellow-500" />
          VCR
        </div>
      ),
      dataIndex: "booking_type",
      editable: false,
      render: (text, params) => {
        const bookingOperation = booking
          ? booking.filter((item) => item.booked_room_id === JSON.stringify(params.id))
          : [];
        return params.payment_type === "cash" ? (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-5 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {bookingOperation.length > 0
              ? bookingOperation.map((item) => {
                  if (item.payment_type) {
                    return item.payment_type;
                  } else {
                    return "cash";
                  }
                })
              : "cash"}
          </button>
        ) : (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-5 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {bookingOperation.length > 0
              ? bookingOperation.map((item) => {
                  if (item.payment_type) {
                    return item.payment_type;
                  } else {
                    return "voucher";
                  }
                })
              : "voucher"}
          </button>
        );
      },
    },
    {
      key: "meal_plan",
      title: (
        <div className="flex items-center gap-x-1">
          <GiHotMeal className="w-5 h-5 mb-1 text-yellow-500" />
          MEAL PLAN
        </div>
      ),
      dataIndex: "meal_plan",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.meal_plan}
          </button>
        );
      },
    },
    {
      key: "adults",
      title: (
        <div className="flex items-center gap-x-1">
          <IoPersonOutline className="w-5 h-5  text-yellow-500" />
          ADULTS
        </div>
      ),

      dataIndex: "adults",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.adults}
          </button>
        );
      },
    },
    // {
    //   key: 'client_requests_tags',
    //   title: 'CLIENT REQUESTS TAGS',

    //   width: 200,
    //   editable: false,
    //   render: (text,params) => {

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <FaRegCalendarAlt className='w-5 h-5 text-green-600' /> */}
    //                 {/* {params.created_date} */}
    //         </button>

    //     );
    //   }
    // },
    // {
    //   key: 'client_requests_text',
    //   title: 'CLIENT REQUESTS TEXT',

    //   width: 200,
    //   editable: false,
    // },
    {
      key: "created_date",
      title: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CREATED DATE
        </div>
      ),
      dataIndex: "created_date",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <FaRegCalendarAlt className='w-5 h-5 text-green-600' /> */}
            {params.created_date}
          </button>
        );
      },
    },
    {
      key: "created_time",
      title: (
        <div className="flex items-center gap-x-1">
          <MdOutlineAccessTime className="w-5 h-5  text-yellow-500" />
          CREATED TIME
        </div>
      ),
      dataIndex: "created_time",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.created_time}
          </button>
        );
      },
    },
    {
      key: "last_updated_date",
      title: "LAST UPDATED DATE",
      dataIndex: "last_updated_date",
      editable: false,
      render: (text, params) => {
        //

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.last_updated_date}
          </button>
        );
      },
    },

    {
      key: "booking type",
      title: (
        <div className=" flex items-center gap-x-1">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          BOOKING TYPE
        </div>
      ),
      dataIndex: "booking type",
      editable: false,
      render: (text, params) => {
        //
        const bookingOperation = booking
          ? booking.filter((item) => item.booked_room_id === JSON.stringify(params.id))
          : [];

        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-4 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {bookingOperation.length > 0
              ? bookingOperation.map((item) => item.booking_type_for_status)
              : "New bookings "}
          </button>
        );
      },
    },
    {
      key: "status",
      title: (
        <div className=" flex items-center gap-x-1">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          BOOKING STATUS
        </div>
      ),
      dataIndex: "status",
      editable: false,
      render: (text, params) => {
        //

        const bookingOperation = booking
          ? booking.filter((item) => item.booked_room_id === JSON.stringify(params.id))
          : [];
        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-12 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {bookingOperation.length > 0 ? bookingOperation.map((item) => item.booking_status) : "new"}
          </button>
        );
      },
    },
    {
      key: "option_date",
      title: (
        <div className=" flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          OPTION DATE
        </div>
      ),
      dataIndex: "option_date",
      editable: false,
      render: (text, params) => {
        //
        const bookingOperation = booking
          ? booking.filter((item) => item.booked_room_id === JSON.stringify(params.id))
          : [];
        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {bookingOperation ? bookingOperation.map((item) => item.option_date.slice(0, 10)) : " "}
          </button>
        );
      },
    },

    {
      key: "source",
      title: (
        <div className=" flex items-center gap-x-1">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          SOURCE
        </div>
      ),
      dataIndex: "source",
      editable: false,
      render: (text, params) => {
        //

        const paidFilter = amounts ? amounts.filter((item) => item.booked_room_id === JSON.stringify(params.id)) : [];
        return (
          <button
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {paidFilter.length > 0 ? paidFilter[0].source : " "}
          </button>
        );
      },
    },
  ];
  return (
    <div className="formPage-wrap ">
      <div className="w-full overflow-x-scroll ">
        {selectBookingRoomFlag ? (
          <>
            <TopBarProgress />
            <div className="w-full h-full flex items-center justify-center">
              <Loading />
            </div>
          </>
        ) : (
          <div className="w-full overflow-auto bg-gray-50">
            <ListFilters columns={columns} rows={rows} title="Booking Table List" />
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingListTableId;
