import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteCollectionAction, getCollectionRoomId } from "../../../redux/actions/collectionAction";
import cookie from "react-cookies";
import lightToast from "light-toast";
import { AiFillDelete, AiOutlineEye, AiOutlineInfoCircle, AiOutlinePhone } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { MdOutlineAccessTime } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { LiaCalendar } from "react-icons/lia";
import { BiTransfer } from "react-icons/bi";
import { PiDatabase, PiNoteFill } from "react-icons/pi";
import { GiRailRoad } from "react-icons/gi";
import { BsCollection } from "react-icons/bs";
import { RiSecurePaymentLine } from "react-icons/ri";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const SelectedCollectionRequest = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const params = useParams();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [id, setId] = useState("");
  const [collectionData, setCollectionData] = useState();
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    setCheck(true);
    dispatch(
      getCollectionRoomId(token, params.id, (result) => {
        if (result.status === 200) {
          setCheck(false);
          setCollectionData(result.data);
        }
      }),
    );
  }, []);
  const handleDelete = (id) => {
    dispatch(
      deleteCollectionAction(token, id, (result) => {
        if (result.status === 204) {
          lightToast.success("The Representative Collection was completed Deleted");
          setCheck(true);
          dispatch(
            getCollectionRoomId(token, params.id, (result) => {
              if (result.status === 200) {
                setCheck(false);
                setCollectionData(result.data);
              }
            }),
          );
        }
      }),
    );
  };
  const rows =
    collectionData &&
    collectionData.map((item) => {
      return {
        id: item.id,
        created_date: item.created_date.slice(0, 10),
        created_time: item.created_date.slice(11, 19),
        collection_purpose: item.collection_purpose,
        booking_order_no: item.booking_order_no,
        booking_id_no: item.booking_id_no,
        hotel_confirmation_number: item.hotel_confirmation_number,
        client_number: item.client_number,
        collection_type: item.collection_type,
        collection_date: item.collection_time.slice(0, 10),
        collection_time: item.collection_time.slice(11, 19),
        collection_address: item.collection_address,
        notes: item.notes,
        collection_satus: item.collection_satus,
        collector_name: item.collector_name,
        short_id: item.booking_order,
      };
    });
  const columns = [
    {
      field: "id",
      headerName: (
        <div className=" text-center  flex justify-center items-center gap-x-1  text-[11px] font-semibold">
          <PiDatabase className="w-4 h-4   text-yellow-500 mb-1" /> ID{" "}
        </div>
      ),
      width: 100,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 ml-4 h-full"
          >
            {params.row.id}
          </Link>
        );
      },
    },

    {
      field: "created_date",
      headerName: (
        <div className="flex items-center gap-x-1   text-[11px] font-semibold">
          <LiaCalendar className="w-4 h-4  text-yellow-500" />
          CREATED DATE
        </div>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.created_date}
          </Link>
        );
      },
    },
    {
      field: "created_time",
      headerName: (
        <div className="flex items-center gap-x-1   text-[11px] font-semibold">
          <MdOutlineAccessTime className="w-4 h-4 text-yellow-500" />
          CREATED TIME
        </div>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.created_time}
          </Link>
        );
      },
    },
    {
      field: "collection_purpose",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <BsCollection className="w-4 h-4 text-yellow-500" />
          COLLECTION PURPOSE
        </div>
      ),
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.collection_purpose}
          </Link>
        );
      },
    },
    {
      field: "booking_order_no",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          BOOKING ORDER{" "}
        </div>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/booking-list-id/${params.row.short_id}`}
            className="w-full flex items-center  gap-x-2 px-8 h-full"
            // onClick={() => navigate(`/dashboard/booking-list-id/${params.row.short_id}`)}
          >
            {params.row.booking_order_no}

            <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
          </Link>
        );
      },
    },
    {
      field: "booking_id_no",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          BOOKING ID{" "}
        </div>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/booking-list-id/${params.row.short_id}`}
            className="w-full flex items-center  gap-x-2 px-8 h-full"
            // onClick={() => navigate(`/dashboard/booking-list-id/${params.row.short_id}`)}
          >
            {params.row.booking_id_no}
            <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
          </Link>
        );
      },
    },
    {
      field: "hotel_confirmation_number",
      headerName: (
        <div className="flex items-center gap-x-1 text-[11px] font-semibold">
          <BiTransfer className="w-4 h-4  text-yellow-500" />
          HOTEL CONFIRMATION
        </div>
      ),
      width: 170,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.hotel_confirmation_number}
          </Link>
        );
      },
    },
    {
      field: "client_number",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <AiOutlinePhone className="w-4 h-4 text-yellow-500" />
          CLIENT NUMBER{" "}
        </div>
      ),
      width: 170,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.client_number}
          </Link>
        );
      },
    },
    {
      field: "collection_type",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <RiSecurePaymentLine className="w-4 h-4 text-yellow-500" />
          COLLECTION TYPE{" "}
        </div>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full capitalize"
          >
            {params.row.collection_type}
          </Link>
        );
      },
    },
    {
      field: "collection_date",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <LiaCalendar className="w-4 h-4 text-yellow-500" />
          COLLECTION DATE{" "}
        </div>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.collection_date}
          </Link>
        );
      },
    },
    {
      field: "collection_time",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <MdOutlineAccessTime className="w-4 h-4 text-yellow-500" />
          COLLECTION TIME{" "}
        </div>
      ),
      width: 170,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.collection_time}
          </Link>
        );
      },
    },
    {
      field: "collection_address",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <GiRailRoad className="w-4 h-4 text-yellow-500" />
          COLLECTION ADDRESS{" "}
        </div>
      ),
      width: 170,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.collection_address}
          </Link>
        );
      },
    },
    {
      field: "notes",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <PiNoteFill className="w-4 h-4 text-yellow-500" />
          NOTES
        </div>
      ),
      width: 120,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.row.notes}
          </Link>
        );
      },
    },
    {
      field: "collection_satus",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <AiOutlineInfoCircle className="w-4 h-4 text-yellow-500" />
          COLLECTION STATUS
        </div>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full capitalize"
          >
            {params.row.collection_satus}
          </Link>
        );
      },
    },
    {
      field: "collector_name",
      headerName: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <AiOutlineInfoCircle className="w-4 h-4 text-yellow-500" />
          COLLECTION NAME
        </div>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/dashboard/collection-requests/preview/${params.row.id}`}
            // onClick={() => navigate(`/dashboard/collection-requests/preview/${params.row.id}`)}
            className="w-full flex items-center gap-x-2 ml-12 h-full"
          >
            {params.row.collector_name}
          </Link>
        );
      },
    },
    {
      field: "operations",
      width: 150,
      headerName: <div className="flex items-center gap-x-1  text-[11px] font-semibold">OPERATIONS</div>,
      renderCell: (params) => {
        return (
          <div className="flex items-center  gap-x-3 ml-5">
            <Link
              to={`/dashboard/collection-requests/preview/${params.row.id}`}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-slate-800 " />
            </Link>
            {permission.map((item) => {
              if (item.name === "room_booking.delete_collection" && item.value === true) {
                return (
                  <button
                    onClick={() => {
                      setId(params.row.id);
                      setConfirm(true);
                    }}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <AiFillDelete className="w-5 h-5 text-red-700" />
                  </button>
                );
              }
            })}
          </div>
        );
      },
    },
  ];

  /////permission/////////
  const permissionData = {
    permission_codes: [
      "room_booking.view_collection",
      "room_booking.change_collection",
      "room_booking.delete_collection",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full overflow-x-scroll bg-white">
      {confirm && (
        <div className="relative z-50">
          <div className="absolute flex flex-col items-center justify-center gap-y-2 w-[300px] h-36 bg-white border-gray-300 border top-96 left-[500px] bg-center rounded-md ">
            <p className="text-2xl font-light">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id);
                }}
                className="bg-green-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div className=' flex justify-center mx-auto w-full border-[#002244] border text-[white] bg-[#002244] rounded-lg text-2xl py-2 mb-3'>
            <p className='flex items-center gap-x-3'>Representative Collection</p>
         </div> */}
      {check && !permissionFlag ? (
        <div className="flex items-center justify-center w-full h-full">
          <TopBarProgress /> <Loading />
        </div>
      ) : collectionData ? (
        <>
          {permissionFlag && permission.some((item) => item.value) ? (
            <Box sx={{ height: "100%", flex: "100%", backgroundColor: "white" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                // rowCount={ bookedHubRoomsStore.count}
                // onSortModelChange={comparator}
                // columnVisibilityModel={columnVisibility}
                // onColumnVisibilityModelChange={(visibility) => {
                //   setColumnVisibility(visibility);
                //   localStorage.setItem("grid-visibility-booking-list", JSON.stringify(visibility));
                // }}
                // paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
                // onPaginationModelChange={handlePageChange}
                // paginationMode="server"
              />
            </Box>
          ) : (
            <div className="w-full h-sreen">
              <NoPermission />
            </div>
          )}
        </>
      ) : (
        <div className="w-full h-full flex items-center justify-center ">
          <TopBarProgress /> <Loading />
        </div>
      )}
    </div>
  );
};

export default SelectedCollectionRequest;
