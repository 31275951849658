import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineEye, AiOutlineUser } from "react-icons/ai";
import { useNavigate, useParams } from "react-router";
import { getData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { CiSearch } from "react-icons/ci";
import useData from "../../../customHooks/useData";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { RxReset } from "react-icons/rx";
import { RiUserSearchLine } from "react-icons/ri";
import { MdDeleteForever, MdOutlineMailOutline, MdOutlineSettings } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { PiUserSwitchLight } from "react-icons/pi";
import { chechSuperUserAction, switchUserAction } from "../../../redux/actions/switchUserAction";
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";
import usePermissions from "../../../customHooks/usePermissions";
import { Pagination } from "@mui/material";
import { setURI } from "../../../redux/actions/uriAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";

export default function SearchUsers() {
  const page_size = 15;
  const countRef = useRef(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();
  const [users, setUsers] = useState(null);
  const groups = useData(`permissions/api/v1/user-groups/`);
  const groupsArr = groups ? groups.map((group) => ({ label: group.name, value: group.id })) : [];
  const [chosenGroup, setChosenGroup] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [id, setId] = useState();
  const [switchFlag, setSwitchFlag] = useState(false);
  const [superUser, setSuperUser] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  const token = cookie.load("access_token");
  const decodedToken = jwtDecode(token);
  const userId = decodedToken;
  const totalPages = Math.ceil(countRef.current / page_size);
  //   const url = group === 'NA' ? `/auth/api/v1/users/?pagination=true&page_size=2&page=1` :
  //   const groups = useData(`/auth/api/v1/users/?find=moa&pagination=true&group=test`)

  const permissionData = {
    permission_codes: [" permissions_management.users_profile"],
  };

  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  const handleClick = () => {
    dispatch(setURI("users"));
  };
  useEffect(() => {
    dispatch(getData(`/auth/api/v1/users/?pagination=true&page_size=${page_size}&page=${page}`, getGroupsSuccess));
    dispatch(
      chechSuperUserAction((result) => {
        if (result.status === 200) {
          setSuperUser(result.data);
        }
      }),
    );
  }, [page]);

  function getGroupsSuccess(resp) {
    setIsLoading(false);
    countRef.current = resp.data.count;
    setUsers(resp.data.results);

    setIsSearching(false);
  }

  const columns = [
    {
      field: "id",
      headerName: (
        <div className=" flex  items-center gap-x-1">
          <AiOutlineUser className="w-5 h-5  text-yellow-500 mb-1" />
          ID
        </div>
      ),
      filterable: false,
      sortable: false,
      flex: 0.3,
      renderCell: (params) => {
        return <div className="w-full flex items-center justify-start pl-3">{params.row.id}</div>;
      },
    },
    {
      field: "username",
      headerName: (
        <div className=" flex  items-center gap-x-1">
          <AiOutlineUser className="w-5 h-5  text-yellow-500 mb-1" />
          USER NAME
        </div>
      ),
      filterable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "email",
      headerName: (
        <div className=" flex  items-center gap-x-1">
          <MdOutlineMailOutline className="w-5 h-5  text-yellow-500 mb-1" />
          EMAIL
        </div>
      ),
      filterable: false,
      sortable: false,
      flex: 1,
    },
    // { field: "group", sortable: false, headerName: "Group", flex: 1 },
    {
      field: "actions",
      filterable: false,
      sortable: false,
      flex: 1.5,
      headerName: (
        <div className=" flex w-full gap-2 text-center items-center justify-center  text-xs ">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),

      renderCell: renderActions,
    },
  ];
  const rows = users
    ? users.map((user) => {
        return {
          id: user.id,
          username: user.username,
          email: user.email,
        };
      })
    : [];

  function renderActions(params) {
    return (
      <div className="w-full flex justify-center items-center gap-x-3 h-screen">
        {permission && permission?.some((item) => item?.value === true) && (
          <Link
            onClick={handleClick}
            to={`/dashboard/user/${params.row.id}`}
            className="flex items-center gap-x-1 hover:bg-slate-700 active:scale-95 duration-300 transition-all bg-slate-800 rounded-md px-3 py-1 text-white font-semibold "
          >
            View <AiOutlineEye className="w-4 h-4 text-white " />
          </Link>
        )}
        {permissions?.some((item) => item.value === true) && (
          <Link
            to={`/dashboard/permissions/edit-user/${params.row.id}`}
            className="flex items-center gap-x-1 hover:bg-green-700 active:scale-95 duration-300 transition-all bg-green-800 rounded-md px-3 py-1 text-white font-semibold "
          >
            Edit <FiEdit className="w-4 h-4 text-white " />
          </Link>
        )}
        {superUser?.is_superuser === true && (
          <button
            onClick={() => {
              setConfirm(true);
              setId(params.row.id);
            }}
            type="button"
            className="flex items-center gap-x-1 hover:bg-sky-800 active:scale-95 duration-300 transition-all bg-sky-600 rounded-md px-1 py-0.5 text-white font-semibold "
          >
            Switch <PiUserSwitchLight className="w-6 h-6 text-white " />
          </button>
        )}
        {/* <FiEdit size={20} onClick={() => navigate(`/dashboard/permissions/edit-user/${params.row.id}`)} /> */}
      </div>
    );
  }

  function handlePageChange(params) {
    setCurrentPage(params.page + 1);
    setIsLoading(true);
    navigate(`/dashboard/permissions/search-users/${params.page + 1}`);
  }

  function handlePagination(event, page) {
    setCurrentPage(page);
    setIsLoading(true);
    navigate(`/dashboard/permissions/search-users/${page}`);
  }

  function searchByName() {
    setIsSearching(true);
    dispatch(
      getData(`/auth/api/v1/users/?find=${searchValue}`, (resp) => {
        countRef.current = resp.data.length;
        setUsers(resp.data);
        setIsSearching(false);
      }),
    );
  }

  function searchByGroup(e) {
    setIsSearching(true);
    setChosenGroup(e);
    dispatch(
      getData(`/auth/api/v1/users/?group=${e.label}`, (resp) => {
        countRef.current = resp.data.length;
        setUsers(resp.data);
        setIsSearching(false);
      }),
    );
  }

  function reset() {
    setIsSearching(true);
    dispatch(getData(`/auth/api/v1/users/?pagination=true&page_size=${page_size}&page=${page}`, getGroupsSuccess));
    if (chosenGroup) {
      setChosenGroup(null);
    }
    if (searchValue) {
      setSearchValue("");
    }
  }

  function onEnter(e) {
    if (e.keyCode === 13) {
      searchByName();
    }
  }

  // Switch Function

  const handleSwitch = () => {
    setSwitchFlag(true);
    dispatch(
      switchUserAction({ user_id: id }, (result) => {
        if (result.status === 200) {
          setSwitchFlag(false);
          setSuccessFlag(true);
          cookie.remove("access_token", { path: "/" });
          cookie.remove("refresh_token", { path: "/" });
          cookie.save("access_token", result.data.tokens.access, { path: "/" });
          cookie.save("refresh_token", result.data.tokens.refresh, { path: "/" });

          setTimeout(() => {
            setSuccessFlag(false);
            setConfirm(false);
            navigate("/dashboard");
          }, [100]);
        } else {
          setSwitchFlag(false);
          setConfirm(false);
        }
      }),
    );
  };

  const [permissions, permissionsFlag] = usePermissions(["auth_users.change_customuser"]);

  return (
    <div className="w-full h-full">
      {isLoading && (
        <div className="w-full flex items-center justify-center h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
      {/* {isSearching && (
        <>
        
          <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3 z-10">
            <HashLoader size={30} color="white" />
            <div className="text-white font-bold mx-3">Loading...</div>
          </div>
        </>
      )} */}
      {!isLoading && superUser && permissionsFlag && (
        <div className="">
          {" "}
          <div className="w-full flex items-center justify-center  ">
            {" "}
            <h2 className="header-h2 ">
              <RiUserSearchLine className="w-6 h-6 text-yellow-600" /> Search Users
            </h2>
          </div>
          <div className="input-par my-8 form-wrap">
            <div className="input-chil">
              <label htmlFor="" className="font-semibold text-[#002244]">
                Search Name or Email
              </label>
              <div className="w-full flex">
                <div className="relative w-full">
                  <input
                    type="text"
                    placeholder="User Name / E-Mail"
                    className="w-full h-10  border rounded-lg bg-white px-2"
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    onKeyDown={onEnter}
                  />
                  <CiSearch
                    size={30}
                    className="absolute right-[5px] top-[5px] cursor-pointer text-gray-500"
                    onClick={searchByName}
                  />
                </div>
                <button
                  className="flex items-center gap-x-1 bg-[#002244] text-white px-2   text-sm rounded-lg mx-2 hover:scale-105 active:scale-95 duration-300 transition-all"
                  onClick={reset}
                >
                  Reset <RxReset className="w-5 h-5" />
                </button>
              </div>
            </div>
            <div className="input-chil">
              <label htmlFor="" className="font-semibold text-[#002244]">
                Search Group
              </label>
              <div className="flex w-full ">
                <Select
                  options={groupsArr}
                  className="w-full h-10 border rounded-lg bg-white"
                  isSearchable
                  placeholder="Group"
                  onChange={searchByGroup}
                  value={chosenGroup}
                />
                <button
                  className="flex items-center gap-x-1 bg-[#002244] text-white px-2   text-sm rounded-lg mx-2 hover:scale-105 active:scale-95 duration-300 transition-all"
                  onClick={reset}
                >
                  Clear <MdDeleteForever className="w-5 h-5 " />
                </button>
              </div>
            </div>
          </div>
          {isSearching ? (
            <div className="w-full flex items-center justify-center h-screen">
              <Loading /> <TopBarProgress />
            </div>
          ) : (
            <div className="w-full  relative">
              <DataGrid
                columns={columns}
                rowCount={countRef.current}
                rows={rows}
                paginationModel={{ page: Number(page - 1), pageSize: page_size }}
                onPaginationModelChange={handlePageChange}
                paginationMode="server"

                // columnVisibilityModel={columnVisibility}
                // onColumnVisibilityModelChange={(visibility) => {
                //   setColumnVisibility(visibility);
                //   localStorage.setItem("contract-grid-visibility", JSON.stringify(visibility));
                // }}
              />
              <div className="absolute bottom-4">
                {" "}
                <Pagination count={totalPages} page={currentPage} onChange={handlePagination} color="primary" />
              </div>
            </div>
          )}
        </div>
      )}
      {confirm && (
        <div
          className={` fixed ${
            confirm ? "top-0 left-0 right-0 bottom-0 h-screen  " : ""
          }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-white  p-5 absolute  flex flex-col justify-center items-center gap-y-5 py-4 h-96    ${
              confirm
                ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            {successFlag ? (
              <>
                <img src="/images/success-icon-28.png" className="w-52 h-52" />
                <p className="text-lg font-semibold  text-[#002244]">It has been transferred to the desired account</p>
              </>
            ) : (
              <>
                <img src="/images/free-switch-icon-10.jpg" className="w-48 h-48" />
                {switchFlag && (
                  <div className="w-full flex items-center justify-center h-screen">
                    <Loading /> <TopBarProgress />
                  </div>
                )}
                <p className="text-lg font-semibold  text-[#002244]">
                  Are you want to switch your account to this user account?
                </p>
                <div className="w-full flex items-center justify-center gap-x-5 text-white">
                  <button
                    disabled={switchFlag}
                    onClick={() => {
                      // setConfirm(false);
                      handleSwitch();
                    }}
                    className="bg-green-900 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
                  >
                    Yes,I'm Sure
                  </button>
                  <button
                    onClick={() => {
                      setConfirm(false);
                    }}
                    className="bg-red-900 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
                  >
                    No,take me back
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
