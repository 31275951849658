import React from "react";
import { GetIconByFileExtension } from "../../../../../utils/utils";

function AttachmentsIcons({ attachments }) {
  const icons = attachments.map((attachment) => {
    const extension = attachment.split(".").pop().split("?")[0];
    const IconComponent = GetIconByFileExtension(extension);

    return {
      IconComponent,
      url: attachment,
    };
  });

  return (
    <div className="flex gap-4">
      {icons.map((icon, index) => (
        <a key={index} href={icon.url} target="_blank" rel="noopener noreferrer">
          <icon.IconComponent style={{ fontSize: "24px" }} />
        </a>
      ))}
    </div>
  );
}

export default AttachmentsIcons;
