import { POST_MAHCINE, POST_MAHCINE_FLAG , ALL_MACHINE_FLAG , ALL_MACHINE } from "../types/types";

const initialState = {
    postMachineData: null,
    postMachineDataFlag:false,
    allPosMachineData:null,
    allPosMachineFlag:false
}


export const posMachineReducer = (state = initialState , action) => {
    switch(action.type)
    {
        case POST_MAHCINE:
            return{...state , postMachineData : action.payload }
        case POST_MAHCINE_FLAG:
             return{...state , postMachineDataFlag : action.payload }
        case ALL_MACHINE:
            return{...state , allPosMachineData : action.payload }
        case ALL_MACHINE_FLAG:
            return{...state , allPosMachineFlag : action.payload }
        default:
            return state;
    }
}