import React from "react";
import Loading from "../Loading/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { IoAdd } from "react-icons/io5";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
import { HiOutlineViewGridAdd } from "react-icons/hi";
// import jwtDecode from 'jwt-decode';
import dayjs from "dayjs";
import { TimePicker } from "antd";
import { AiOutlineCheck } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { LiaCalendar } from "react-icons/lia";
import { MdOutlineDone } from "react-icons/md";
import { createNewBatchAction, getPosMachineBatch } from "../../../redux/actions/newBatchAction";
import { getCurrencyMidNumber, getMidNumber } from "../../../redux/actions/posVisaAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

const CreateNewBatch = () => {
  const dispatch = useDispatch();
  useRemoveScroll();
  const token = cookie.load("access_token");
  const format = "HH:mm";
  const navigate = useNavigate();
  // const decodedToken = jwtDecode(token);
  const [toggle, setToggle] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [currency, setCurrency] = useState("");
  const [bank, setBank] = useState("");
  const [mid, setMid] = useState("");

  useEffect(() => {
    dispatch(getMidNumber(token));
    dispatch(getBankListAction(token));
  }, []);

  const { midNumberData } = useSelector((state) => state.posVisaReducer);
  const { currencyMidNumber } = useSelector((state) => state.posVisaReducer);
  const { posMachineBatch } = useSelector((state) => state.newBatchReducer);
  const { createNewBatchFlag } = useSelector((state) => state.newBatchReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  let midNumber = midNumberData
    ? midNumberData.map((item, index) => {
        return { value: item.id, label: item.mid_number };
      })
    : [];
  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setTime(`${hours1}:${minutes1}`);
  };

  const [posArray, setPosArray] = useState([]);
  const total = posArray && posArray.reduce((acc, balc) => acc + parseFloat(balc.amount), 0);
  const subtotal = posArray && posArray.reduce((acc, balc) => acc + parseFloat(balc.balance), 0);
  const handleSelect = (e) => {
    if (posArray.length !== 0) {
      const posIndex = posArray.findIndex((item) => item.id === e.id);
      if (posIndex >= 0) {
        const filterPosArray = posArray.filter((item) => item.id !== e.id);
        setPosArray(filterPosArray);
      } else {
        setPosArray((prev) => [...prev, { id: e.id, amount: e.amount, balance: e.balance }]);
      }
    } else {
      // setPos((prev)=>[...prev , e.amount])
      setPosArray((prev) => [...prev, { id: e.id, amount: e.amount, balance: e.balance }]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (time === "") {
      alert("Please select a time."); // You can replace this with your validation logic
      return;
    } else {
      const bankTable = Bank ? Bank.filter((item) => item.label === bank) : [];
      const batchData = {
        batch_number: e.target.batch_number.value,
        creation_date: `${date},${time}`,
        mid_number: mid,
        currency: currency,
        bank: bankTable[0].value,
        pos_machine_ids: posArray.map((item) => item.id),
        created_by: 2,
        total: total,
        subtotal: Number(subtotal).toFixed(2),
      };

      dispatch(
        createNewBatchAction(token, batchData, (result) => {
          if (result.status === 201) {
            lightToast.success("Create New Batch was completed successfully");
            navigate(-1);
          }
        }),
      );
    }
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.change_batch"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////

  return (
    <div className="w-full md:px-6 px-2 my-8">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {midNumberData && permissionFlag ? (
          permission.map((item) => {
            if (item.name === "accounting.change_batch" && item.value === true) {
              return (
                <div className=" w-full  border-gray-300 border rounded-lg p-5 ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      <HiOutlineViewGridAdd className="w-6 h-6 text-white" />
                      Create New Batch{" "}
                    </h2>
                  </div>
                  <form
                    className="mt-3 bg-gray-50 border rounded-md p-2 py-4 w-full"
                    encType="multipart/form-data"
                    onSubmit={handleSubmit}
                  >
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" text-center w-full text-gray-600">Batch Number</label>
                      <input
                        placeholder="Batch Number"
                        name="batch_number"
                        type="number"
                        required
                        // onChange={(e)=>setSourceName(e.target.value)}
                        className=" w-full px-3 h-10  border rounded-md bg-white "
                      />
                      <div className="input-par">
                        <div className="input-chil">
                          <label className=" flex justify-center text-gray-600 ">Date</label>
                          <input
                            placeholder="Tranfer Date"
                            type="date"
                            required
                            onChange={(e) => setDate(e.target.value)}
                            className=" w-full px-3 h-10  border rounded-md floating-input bg-white "
                          />
                        </div>
                        <div className="input-chil">
                          <label className=" flex justify-center text-gray-600 ">Time</label>
                          <TimePicker
                            clearIcon={false}
                            defaultValue={dayjs("00:00", format)}
                            format={format}
                            onChange={handleTimeChange}
                            className="w-full h-10 "
                          />
                          {/* <TimePicker  name='time' clearIcon={false}  defaultValue={dayjs( '00:00' , format)} format={format} className='w-full' onChange={handleTimeChange}  />    */}
                        </div>
                      </div>

                      <label className=" flex text-gray-600 ">Mid Number</label>
                      <Select
                        name="mid_number"
                        options={midNumber}
                        onChange={(e) => {
                          setMid(e.label);
                          setToggle(false);
                          setToggleLoading(true);
                          dispatch(getPosMachineBatch(token, e.label));
                          dispatch(
                            getCurrencyMidNumber(token, e.label, (result) => {
                              if (result.status === 200) {
                                setPosArray([]);
                                setToggle(true);
                                setToggleLoading(false);
                                setCurrency(result.data.currency);
                                setBank(result.data.bank);
                              }
                            }),
                          );
                        }}
                        isSearchable
                        className=" w-full  h-10   rounded-md  bg-white "
                        placeholder="Mid Number"
                      />

                      {toggle ? (
                        <>
                          <div className="input-par">
                            <div className="input-chil">
                              <label className=" flex text-gray-600 ">Currency</label>
                              <div
                                name="currency"
                                className=" w-full px-3 h-10  border rounded-md floating-input bg-white"
                              >
                                <p className="text-black mt-2">{currencyMidNumber && currencyMidNumber.currency}</p>
                              </div>
                            </div>
                            <div className="input-chil">
                              <label className=" flex text-gray-600 ">Bank Name</label>
                              <div name="bank" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                                <p className="text-black mt-2">{currencyMidNumber && currencyMidNumber.bank}</p>
                              </div>
                            </div>
                          </div>
                          <div className="input-par">
                            <div className="input-chil">
                              <label className=" flex text-gray-600">Total</label>
                              <div
                                name="currency"
                                className=" w-full px-3 h-10  border rounded-md floating-input bg-white"
                              >
                                <p className="text-black mt-2">{total}</p>
                              </div>
                            </div>
                            <div className="input-chil">
                              {" "}
                              <label className=" flex text-gray-600">SubTotal</label>
                              <div
                                name="currency"
                                className=" w-full px-3 h-10  border rounded-md floating-input bg-white"
                              >
                                <p className="text-black mt-2">{subtotal}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        toggleLoading && (
                          <div
                            name="bank"
                            className=" w-full flex items-center justify-center rounded-md floating-input "
                          >
                            <ButtonLoading />
                          </div>
                        )
                      )}

                      <label className=" flex text-gray-600">Transactions VISA</label>
                      <div className="w-full grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 px-2 justify-between gap-x-2 rounded-lg bg-[#002244] ">
                        <div class="flex flex-col items-center justify-between gap-y-4 border-solid ">
                          <div className="w-full flex items-center  py-3 text-white  ">
                            <p className="flex items-center gap-x-1">
                              <MdOutlineDone className="w-5 h-5 text-white" />
                              Select
                            </p>
                          </div>
                        </div>
                        <div class="flex flex-col items-center justify-between  border-solid">
                          <div className="w-full flex items-center   py-3  text-white ">
                            <p className="flex items-center gap-x-1">
                              {" "}
                              <BiTransfer className="w-5 h-5 text-white" />
                              ID
                            </p>
                          </div>
                        </div>
                        <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                          <div className="w-full flex items-center  py-3 text-white ">
                            <p className="flex items-center gap-x-1">
                              {" "}
                              <BiTransfer className="w-5 h-5 text-white" />
                              Booking Order{" "}
                            </p>
                          </div>
                        </div>
                        {/* <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                     <div className="w-full flex items-center justify-center py-3 text-white ">
                       <p className="flex items-center gap-x-1"> <BiTransfer className="w-5 h-5 text-white" />Order ID</p>
                      </div>
                  </div> */}
                        <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                          <div className="w-full flex items-center  py-3 text-white  ">
                            <p className="flex items-center gap-x-1">
                              <GiMoneyStack className="w-5 h-5 text-white" />
                              Total
                            </p>
                          </div>
                        </div>
                        <div class="flex flex-col items-center justify-between gap-y-4   border-solid">
                          <div className="w-full flex items-center  py-3 text-white ">
                            <p className="flex items-center gap-x-1">
                              <GiMoneyStack className="w-5 h-5 text-white" />
                              Balance
                            </p>
                          </div>
                        </div>
                        <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                          <div className="w-full flex items-center j py-3 text-white  ">
                            <p className="flex items-center gap-x-1">
                              <LiaCalendar className="w-5 h-5 text-white" />
                              Date
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full grid grid-cols-6 items-center rounded-lg bg-white ">
                        {toggle ? (
                          posMachineBatch?.length === 0 ? (
                            <div class="flex flex-col items-center justify-between  border-solid col-span-6 bg-gray-100">
                              <div className="w-full flex items-center justify-center  py-3  text-gray-800 ">
                                <p className="text-gray-500">Not Found Transactions Visa For This MID Number </p>
                              </div>
                            </div>
                          ) : (
                            posMachineBatch.map((item) => {
                              return (
                                <>
                                  <div class="flex flex-col items-center justify-between gap-y-4 border-solid   ">
                                    <div className="w-full flex items-center justify-center py-3 text-gray-800  ">
                                      <input
                                        type="checkbox"
                                        onChange={() => handleSelect(item)}
                                        value={Number(item.id)}
                                        className={` flex items-center justify-center border border-solid cursor-pointer rounded-md border-gray-500 w-5 h-5 text-white `}
                                      />
                                    </div>
                                  </div>
                                  <div class="flex flex-col items-center justify-between  border-solid">
                                    <div className="w-full flex items-center justify-center  py-3  text-gray-800 ">
                                      <p>{item.id}</p>
                                    </div>
                                  </div>

                                  <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                                    <div className="w-full flex items-center justify-center py-3 text-gray-800 ">
                                      <p>{item.booked_room} </p>
                                    </div>
                                  </div>
                                  {/* <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                     <div className="w-full flex items-center justify-center py-3 text-gray-800 ">
                       <p>{item.booked_room}</p>
                      </div>
                  </div> */}
                                  <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                                    <div className="w-full flex items-center justify-center py-3 text-gray-800  ">
                                      <p>{item.amount}</p>
                                    </div>
                                  </div>
                                  <div class="flex flex-col items-center justify-between gap-y-4   border-solid">
                                    <div className="w-full flex items-center justify-center py-3 text-gray-800 ">
                                      <p>{item.balance}</p>
                                    </div>
                                  </div>
                                  <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                                    <div className="w-full flex items-center justify-center py-3 text-gray-800  ">
                                      <p>{item.created_at.slice(0, 10)}</p>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          )
                        ) : toggleLoading ? (
                          <div class="flex flex-col items-center justify-between  border-solid col-span-6 ">
                            <div className="w-full flex items-center justify-center  py-3  ">
                              <ButtonLoading />
                            </div>
                          </div>
                        ) : (
                          <div class="flex flex-col items-center justify-between  border-solid col-span-6 bg-gray-100">
                            <div className="w-full flex items-center justify-center  py-3  text-gray-800 ">
                              <p className="text-gray-500">Please Select MID Number</p>
                            </div>
                          </div>
                        )}
                      </div>

                      <button
                        type="Submit"
                        disabled={createNewBatchFlag}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-md bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Create{" "}
                        {createNewBatchFlag ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <IoAdd className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              );
            } else {
              return (
                <div className="w-full h-screen">
                  <NoPermission />
                </div>
              );
            }
          })
        ) : (
          <div className="flex  items-center justify-center h-full w-full">
            <TopBarProgress /> <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateNewBatch;
