import {
  ADD_CASH_FLAG,
  ALL_BOOKING_STATUS,
  ALL_HOTELS_NAME,
  BANK_TRANSFER_FLAG,
  BANKLIST,
  BOOKEDROOMID,
  BOOKINGSTATUS,
  BOOKINGTYPES,
  CONVERT_ADD_UPDATA,
  CURRENCY,
  DELETE_ORDER,
  GET_OPERATION_FLAG,
  GET_PAYMENT_LIST,
  GET_PAYMENT_LIST_FLAG,
  GETBOOKEDROOMS,
  JUMBO_FLAG,
  OERATION_DATA_ID,
  OPERATION_FLAG,
  OPERATION_UPDATA_FLAG,
  OPERATIONDATA,
  SELECTED_BOOKED_ORDER_FLAG,
  SINGELBOOKEDROOM,
  UPDATEFLAG,
} from "../types/types";
import api_token from "../../api/UserApi";
import lightToast from "light-toast";
import { toast } from "react-hot-toast";
import { NotificationManager } from "react-notifications";
import axios from "axios";

export const getBookedRoomsAction = (
  token,
  field,
  x,
  page,
  pageSize,
  sortingOrder,
  lastOperationStatus = "",
  callback,
) => {
  const params = {
    page: page,
    page_size: pageSize,
    sorting_order: sortingOrder,
  };

  if (lastOperationStatus) {
    params["last_operation_status"] = lastOperationStatus;
  }

  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/booking/api/v1/get-booked-rooms/?${field}`, {
        params: params,
      });
      dispatch({
        type: GETBOOKEDROOMS,
        payload: response.data,
      });
      callback(response);
    } catch (error) {
      callback();
    }
  };
};

export const filterBookingAction = (
  token,
  field,
  bookingID,
  page,
  pageSize,
  sortingOrder,
  lastOperationStatus = "",
  callback,
) => {
  return async (dispatch, getState) => {
    const params = {
      page: page,
      page_size: pageSize,
      sorting_order: sortingOrder,
    };

    if (lastOperationStatus) {
      params["last_operation_status"] = lastOperationStatus;
    }

    try {
      const response = await api_token(token).get(`/booking/api/v1/get-booked-rooms/?${field}=${bookingID}`, {
        params: params,
      });
      dispatch({
        type: GETBOOKEDROOMS,
        payload: response.data,
      });
      callback(response);
    } catch (error) {
      callback();
    }
  };
};

export const getAllHotels = (token, callback) => {
  return async (dispatch, getState) => {
    const response = await api_token(token).get(`en/hotels/api/v1/get-hotel-names/`);
    try {
      dispatch({
        type: ALL_HOTELS_NAME,
        payload: response.data,
      });
    } catch (error) {
      callback(response);
    }
  };
};

// Not used
export const sortBookingListAction = (token, data) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("/booking/api/v1/get-booked-rooms/", {
        params: { sorting_order: data },
      });
      dispatch({
        type: GETBOOKEDROOMS,
        payload: response.data.results,
      });
    } catch (error) {}
  };
};

export const getSelectedBookedRoomAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SELECTED_BOOKED_ORDER_FLAG,
        payload: true,
      });
      const response = await api_token(token).get("booking/api/v1/get-booked-rooms/group/", {
        params: { short_id: data },
      });
      dispatch({
        type: BOOKEDROOMID,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: SELECTED_BOOKED_ORDER_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
export const getSelectedBookedRoomSingleAction = (token, id, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_OPERATION_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`booking/api/v1/booked-room/${id}/`, { signal });
      dispatch({
        type: SINGELBOOKEDROOM,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_OPERATION_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const getPaymentListAction = (token, payemntStatus, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_PAYMENT_LIST_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`booking/api/v1/booked-rooms/payment-status/${payemntStatus}/`);
      dispatch({
        type: GET_PAYMENT_LIST,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_PAYMENT_LIST_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const updateAction = (token, data, clientTag, data2, id, callback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATEFLAG,
      payload: true,
    });
    try {
      const response = await api_token(token).put(`booking/api/v1/update-client-name/${id}/`, data);

      const response1 =
        clientTag[0] !== undefined &&
        (await api_token(token).put(`booking/api/v1/update-client-requests-tags/${id}/`, clientTag));
      const response2 = await api_token(token).put(`booking/api/v1/update-book-room/${id}/`, data2);
      dispatch({
        type: SINGELBOOKEDROOM,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: UPDATEFLAG,
          payload: false,
        });
      }
      callback(response);
    } catch (error) {
      dispatch({
        type: UPDATEFLAG,
        payload: false,
      });
      NotificationManager.error("There Is An Empty Field");
    }
  };
};
export const deleteAction = (token, data, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_ORDER,
        payload: true,
      });
      const response = await api_token(token).delete(`booking/api/v1/booked-room/${id}/delete/`, data);

      dispatch({
        type: DELETE_ORDER,
        payload: false,
      });
      callback(response);
    } catch (error) {}
  };
};
const abortController = new AbortController();
export const bankTransferAction = (token, transferData, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: BANK_TRANSFER_FLAG,
        payload: true,
      });

      const response = await api_token(token).post(
        `booking/api/v1/bank-transfer/`,
        transferData,
        { signal: abortController.signal },
        {
          headers: {
            "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
          },
        },
      );
      dispatch({
        type: BANKLIST,
        payload: response.data,
      });
      if (response.status === 201) {
        dispatch({
          type: BANK_TRANSFER_FLAG,
          payload: false,
        });
      }
      callback(response);
    } catch (error) {
      if (axios.isCancel(error)) {
      }
    }
  };
};

export const getCurrencyAction = (token, signal) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`contracts/api/currency-types/`, { signal });
      dispatch({
        type: CURRENCY,
        payload: response.data,
      });
    } catch (error) {}
  };
};

export const operationAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: OPERATION_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`booking/api/v1/booked-room-operations/`, data);
      dispatch({
        type: OPERATIONDATA,
        payload: response.data,
      });
      callback(response);
      if (response.status === 201) {
        dispatch({
          type: OPERATION_FLAG,
          payload: false,
        });
        dispatch({
          type: CONVERT_ADD_UPDATA,
          payload: true,
        });
        lightToast.success("This Order is Added");
      }
    } catch (error) {}
  };
};
export const cancelUpdateConvertButton = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: CONVERT_ADD_UPDATA,
      payload: false,
    });
  };
};
export const updateOperationAction = (token, id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: OPERATION_UPDATA_FLAG,
        payload: true,
      });

      const response = await api_token(token).put(`booking/api/v1/booked-room-operations/${id}/`, data);
      dispatch({
        type: OERATION_DATA_ID,
        payload: response.data,
      });

      if (response.status === 200) {
        dispatch({
          type: OPERATION_UPDATA_FLAG,
          payload: false,
        });
        lightToast.success("This Order is Updated");
      }

      callback(response.data);
    } catch (error) {}
  };
};

export const getOptionByIdAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/get-operation-booked-room/${id}/`);
      dispatch({
        type: OERATION_DATA_ID,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const getBookingTypeAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/booking-types/`);
      dispatch({
        type: BOOKINGTYPES,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const getBookingStatusAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/booking-status/?booking_type_id=${id}`);
      dispatch({
        type: BOOKINGSTATUS,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};
export const getAllBookingStatus = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/booking-status/`);
      dispatch({
        type: ALL_BOOKING_STATUS,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const getServicesTypeAction = (token, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/service-types/`, { signal });

      callback(response.data);
    } catch (error) {}
  };
};

export const gateWayAction = (token, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/payments-gateway/`, { signal });

      callback(response.data);
    } catch (error) {}
  };
};

export const addCashAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_CASH_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`booking/api/v1/cash-payments-transaction/`, data);
      if (response.status === 201) {
        dispatch({
          type: ADD_CASH_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const jumboPayAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: JUMBO_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`booking/api/v1/payments-transaction/`, data);
      if (response.status === 201) {
        dispatch({
          type: JUMBO_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      dispatch({
        type: JUMBO_FLAG,
        payload: false,
      });
      toast.error(`Jumbo Invoice Number Has Already Been Added Before`);
    }
  };
};
