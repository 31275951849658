import React, { useEffect, useMemo, useState } from "react";
import StaticFormSelect from "../StaticFormSelect/StaticFormSelect";
import StaticFromInput from "../StaticFormInput/StaticFromInput";
import {
  addChildMaxAgeValue,
  addChildMinAgeValue,
  addChildOrderValue,
  addChildPriceValue,
  addNewChildTypeObject,
  addNewPriceTypeObj,
  addNewRoomPackageObj,
  addPriceValue,
  addRoomPackageID,
  addRoomPackagePrice,
  addTypeValue,
  deleteNewChildTypeObject,
  deletePriceTypeObj,
  deleteRoomPackageObj,
} from "../../../redux/actions/contractValidity";
import { useDispatch, useSelector } from "react-redux";
import { BsPeopleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { BiMinusCircle } from "react-icons/bi";
import { MdAddCircleOutline } from "react-icons/md";
import { FaChildren } from "react-icons/fa6";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../../organisms/Loading/Loading";
import Errorfetch from "../../organisms/Errorfetch/Errorfetch";
import { TbPackages } from "react-icons/tb";

export default function ContractFormDynamicCardGrandChild({
  validity_index,
  date_index,
  room_types,
  maxAdults,
  maxChildren,
  maxOccupancy,
  contractType,
  roomID,
}) {
  const maxAdultsSelectOptions = Array.from({ length: maxAdults || 10 }, (_, i) => ({ id: i + 1, name: i + 1 }));
  const maxChildrenSelectOptions = Array.from({ length: maxChildren || 10 }, (_, i) => ({ id: i + 1, name: i + 1 }));
  const ageSelectOptions = Array.from({ length: 12 }, (_, i) => ({ id: i, name: i }));

  const dispatch = useDispatch();
  let [input, setInput] = useState([1]);
  let [childInput, setChildInput] = useState([]);
  let [packagesInput, setPackagesInput] = useState([1]);

  let [numOfPersons, setNumOfPersons] = useState([null]);
  const contractValidity = useSelector((state) => state.contractValidity);

  const children_room_types = useMemo(() => {
    return contractValidity[validity_index].date_range[date_index].validity_children_price || [];
  }, [contractValidity, date_index, validity_index]);

  const room_packages = useMemo(() => {
    return contractValidity[validity_index].date_range[date_index].validity_room_packages || [];
  }, [contractValidity, date_index, validity_index]);

  useEffect(() => {
    if (room_types) {
      let inputs = Array.from({ length: room_types.length }, () => 1);
      let nums = room_types.map((obj) => `${obj.type}`);
      setNumOfPersons(nums);
      setInput(inputs);
    }

    if (children_room_types) {
      let inputs = Array.from({ length: children_room_types.length }, () => 1);

      setChildInput(inputs);
    }
  }, [children_room_types, room_types]);

  useEffect(() => {
    if (room_packages) {
      let inputs = Array.from({ length: room_packages.length }, () => 1);

      setPackagesInput(inputs);
    }
  }, [room_packages]);

  const {
    isPending,
    data: roomPackages,
    error,
  } = useQuery({
    queryKey: ["room_packages", roomID],
    queryFn: () => fetchDataQuery(`/en/rooms/api/v1/room-package-occupancy/?room=${roomID}`),
    enabled: contractType === "per_room",
  });

  function addInput() {
    setInput([...input, 1]);
    dispatch(addNewPriceTypeObj(validity_index, date_index));
  }

  function addChildInput() {
    setChildInput([...childInput, 1]);
    dispatch(addNewChildTypeObject(validity_index, date_index));
  }

  function addPackageInput() {
    if (packagesInput.length === roomPackages.length) {
      toast.error("You can't add more packages than the room has");
      return;
    }
    setPackagesInput([...packagesInput, 1]);
    dispatch(addNewRoomPackageObj(validity_index, date_index));
  }

  function removeInput() {
    if (input.length > 1) {
      let newArr = input.slice();
      newArr.pop();
      let numOfPersonsCopy = [...numOfPersons];
      numOfPersonsCopy.pop();
      setNumOfPersons(numOfPersonsCopy);
      setInput(newArr);
      dispatch(deletePriceTypeObj(validity_index, date_index));
    }
  }

  function removeChildInput() {
    let newArr = childInput.slice();
    newArr.pop();
    setChildInput(newArr);
    dispatch(deleteNewChildTypeObject(validity_index, date_index));
  }

  function removePackageInput() {
    if (packagesInput.length > 1) {
      let newArr = packagesInput.slice();
      newArr.pop();
      setPackagesInput(newArr);
      dispatch(deleteRoomPackageObj(validity_index, date_index));
    }
  }

  function handleTypeData(type, idx) {
    dispatch(addTypeValue(validity_index, date_index, idx, Number(type)));
  }

  function handleRoomPackageID(id, idx) {
    dispatch(addRoomPackageID(validity_index, date_index, idx, Number(id)));
  }

  function handleRoomPackagePrice(id, idx) {
    dispatch(addRoomPackagePrice(validity_index, date_index, idx, Number(id)));
  }

  function handelPriceData(price, idx) {
    dispatch(addPriceValue(validity_index, date_index, idx, price));
  }

  function handleChildOrder(idx, order) {
    dispatch(addChildOrderValue(validity_index, date_index, idx, Number(order)));
  }

  function handleChildMinAgeData(age, idx) {
    dispatch(addChildMinAgeValue(validity_index, date_index, idx, age));
  }

  function handleChildMaxAgeData(age, idx) {
    dispatch(addChildMaxAgeValue(validity_index, date_index, idx, age));
  }

  function handleChildPrice(price, idx) {
    dispatch(addChildPriceValue(validity_index, date_index, idx, Number(price)));
  }

  if (contractType === "per_room") {
    if (isPending) {
      return <Loading />;
    }

    if (error) {
      return <Errorfetch Error={"Error fetching packages"} />;
    }

    if (!roomPackages || roomPackages.length === 0) {
      return (
        <div className="flex items-center justify-center h-56 flex-col gap-4">
          <p className="font-bold"> No packages added to this room </p>
        </div>
      );
    }

    function convertPackageToString(roomPackage) {
      if (roomPackage) {
        const { max_adults, children_room_package_occupancy } = roomPackage;

        if (children_room_package_occupancy.length === 0) {
          return `${max_adults} adl${max_adults > 1 ? "s" : ""} only`;
        }

        const childrenDescriptions = children_room_package_occupancy
          .map((child) => {
            return `${child.ordering_child} ch ${child.max_age} y`;
          })
          .join(" – ");

        return `${max_adults} adl${max_adults > 1 ? "s" : ""} + ${children_room_package_occupancy.length} chs: (${childrenDescriptions})`;
      } else {
        return "sddd";
      }
    }

    const roomPackagesOptions = roomPackages?.map((roomPackage) => ({
      id: roomPackage?.id,
      name: convertPackageToString(roomPackage),
    }));

    return (
      <div className="w-full flex flex-col gap-y-2 py-3 px-10 ">
        {packagesInput.map((elem, idx) => {
          return (
            <div key={idx} className="w-fzull flex md:flex-row flex-col items-center justify-center gap-x-10 p-2 px-5">
              <div className="md:w-[50%] w-full">
                <StaticFormSelect
                  name="room_package"
                  text="Room Package"
                  options={roomPackagesOptions}
                  placeholder="Select package"
                  contractCallBack={(e, id) => {
                    const packagesIDs = contractValidity[validity_index].date_range[
                      date_index
                    ].validity_room_packages.map((obj) => Number(obj.package));

                    if (packagesIDs.includes(Number(id))) {
                      toast.error(`you can't add the same package twice in the same validity`);
                      e.target.value = null;
                      handleRoomPackageID("", idx);
                      return;
                    }

                    handleRoomPackageID(id, idx);
                  }}
                  defaultValue={
                    room_packages.length > 0
                      ? roomPackagesOptions.find((option) => option.id === room_packages[idx]?.package)?.id
                      : ""
                  }
                />
              </div>
              <div className="md:w-[50%]  w-full">
                <StaticFromInput
                  name="room_package_price"
                  text="Price"
                  type="number"
                  placeholder="Price"
                  canDefaultValueZero={true}
                  func={(price) => handleRoomPackagePrice(Number(price), idx)}
                  defaultValue={room_packages.length > 0 ? Number(room_packages[idx]?.price) : ""}
                />
              </div>
            </div>
          );
        })}
        <div className="flex gap-2 justify-end">
          <div className="flex items-center justify-end px-5 gap-x-1 mt-3">
            <MdAddCircleOutline
              className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-green-800"
              onClick={addPackageInput}
            />

            <TbPackages className="w-5 h-5 text-gray-800" />
            <BiMinusCircle
              className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-red-800"
              onClick={removePackageInput}
            />
          </div>
        </div>
      </div>
    );
  }

  if (contractType === "per_person") {
    return (
      <div className="w-full flex flex-col gap-y-2 py-3 px-10 ">
        <p>Max Occupancy (Adults + Children) : {maxOccupancy}</p>
        <div className="w-full border rounded-md p-2">
          {input.map((elem, idx) => {
            return (
              <div
                key={idx}
                className="w-fzull flex md:flex-row flex-col items-center justify-center gap-x-10 p-2 px-5"
              >
                <div className="md:w-[50%] w-full">
                  <StaticFormSelect
                    name="room_type"
                    text="Number of Adults"
                    options={maxAdultsSelectOptions}
                    placeholder="Select number of Adults"
                    contractCallBack={(e, type) => {
                      // 1) Validation num 1
                      // If the user tries to add the same number twice in the same validity
                      if (numOfPersons.includes(type) && numOfPersons[idx] !== type) {
                        toast.error(`you can't add the same number twice in the same validity`);
                        e.target.value = null;
                        handleTypeData("", idx);
                      }

                      let copy = [...numOfPersons];
                      copy[idx] = type;
                      setNumOfPersons(copy);
                      handleTypeData(type, idx);
                    }}
                    defaultValue={room_types ? Number(room_types[idx]?.type) : ""}
                  />
                </div>
                <div className="md:w-[50%]  w-full">
                  <StaticFromInput
                    name="room_price"
                    text="Price"
                    type="number"
                    placeholder="Price"
                    func={(price) => handelPriceData(price === "" ? "" : Number(price), idx)}
                    defaultValue={room_types ? Number(room_types[idx]?.price) : ""}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className={`w-full border rounded-md p-2 ${childInput.length === 0 && "hidden"}`}>
          {childInput.map((elem, idx) => {
            return (
              <div
                key={idx + elem}
                className="w-full flex md:flex-row flex-col items-center justify-between gap-x-10 p-2 px-5"
              >
                <StaticFormSelect
                  name="ordering_child"
                  text="Child Order"
                  type={"number"}
                  options={maxChildrenSelectOptions}
                  canDefaultValueZero={true}
                  contractCallBack={(e, order) => {
                    // Clear the input fields if the user tries to change the order
                    e.target.parentElement.parentElement.querySelector("[name='end_age']").value = null;
                    e.target.parentElement.parentElement.querySelector("[name='start_age']").value = null;

                    handleChildMinAgeData("", idx);
                    handleChildMaxAgeData("", idx);

                    // 1) Validation
                    // Ensure that the user add children orders in sequence starting from 1
                    const parentElement = e.target.parentElement.parentElement.parentElement;
                    const orders = parentElement.querySelectorAll("[name='ordering_child']");

                    const ordersArray = Array.from(orders)
                      .map((orderElement) => Number(orderElement.value))
                      .filter((o) => o !== 0);

                    const orderNum = Number(order);

                    if (orderNum !== 1 && !ordersArray.includes(orderNum - 1)) {
                      toast.error(`You must add the previous child order first`);
                      e.target.value = null;
                      e.target.parentElement.parentElement.querySelector("[name='end_age']").value = null;
                      e.target.parentElement.parentElement.querySelector("[name='start_age']").value = null;

                      handleChildMinAgeData("", idx);
                      handleChildMaxAgeData("", idx);

                      handleChildOrder(idx, "");
                      return;
                    }

                    handleChildOrder(idx, Number(order));
                  }}
                  placeholder="Select Child Order"
                  // func={(order) => handleChildOrder(idx, Number(order))}
                  defaultValue={
                    children_room_types?.length > 0 && Number(children_room_types[idx]?.ordering_child)
                      ? Number(children_room_types[idx]?.ordering_child)
                      : ""
                  }
                />

                <StaticFormSelect
                  disabled={
                    !contractValidity[validity_index]?.date_range[date_index]?.validity_children_price[idx]
                      ?.ordering_child
                  }
                  name="start_age"
                  options={ageSelectOptions}
                  text="Min age"
                  type="number"
                  canDefaultValueZero={true}
                  placeholder="Min age"
                  contractCallBack={(e, age) => {
                    const maxAge =
                      contractValidity[validity_index].date_range[date_index].validity_children_price[idx].end_age;

                    // 1) Validation num 1
                    // Min age must be less than max age

                    if (age >= maxAge && maxAge !== null && maxAge !== "") {
                      toast.error("The minimum age must be less than the maximum age.");
                      e.target.value = null;
                      handleChildMinAgeData("", idx);

                      return;
                    }

                    // 2) Validation num 2
                    // No conflict with the same child order
                    const order =
                      contractValidity[validity_index].date_range[date_index].validity_children_price[idx]
                        .ordering_child;
                    const conflictingAge = contractValidity[validity_index].date_range[
                      date_index
                    ].validity_children_price.find(
                      (child) => child.ordering_child === order && age >= child.start_age && age <= child.end_age,
                    );

                    if (conflictingAge) {
                      toast.error("The minimum age conflicts with an existing range for the same child order.");
                      e.target.value = null;
                      e.target.parentElement.querySelector("[name='end_age']");

                      handleChildMinAgeData("", idx);
                      handleChildMaxAgeData("", idx);

                      const parentElement = e.target.parentElement.parentElement;
                      const maxAgeInput = parentElement.querySelector("[name='end_age']");

                      if (maxAgeInput) {
                        maxAgeInput.value = null;
                      }

                      return;
                    }

                    handleChildMinAgeData(Number(age), idx);
                  }}
                  defaultValue={
                    children_room_types?.length > 0 &&
                    children_room_types[idx]?.start_age !== null &&
                    children_room_types[idx]?.start_age !== undefined
                      ? children_room_types[idx]?.start_age
                      : ""
                  }
                />

                <StaticFormSelect
                  disabled={
                    !contractValidity[validity_index]?.date_range[date_index]?.validity_children_price[idx]
                      ?.ordering_child ||
                    contractValidity[validity_index]?.date_range[date_index]?.validity_children_price[idx]
                      ?.start_age === ""
                  }
                  name="end_age"
                  text="Max age"
                  type="number"
                  options={ageSelectOptions}
                  canDefaultValueZero={true}
                  placeholder="Max age"
                  contractCallBack={(e, age) => {
                    // 1) Validation num 1
                    // Max age must be greater than min age
                    const minAge =
                      contractValidity[validity_index].date_range[date_index].validity_children_price[idx].start_age;
                    if (age <= minAge && minAge) {
                      toast.error("The maximum age must be greater than the minimum age.");
                      e.target.value = null;
                      handleChildMaxAgeData("", idx);

                      return;
                    }

                    // 2) Validation num 2
                    // No conflict with the same child order
                    const order =
                      contractValidity[validity_index].date_range[date_index].validity_children_price[idx]
                        .ordering_child;

                    const conflictingAge = contractValidity[validity_index].date_range[
                      date_index
                    ].validity_children_price.find(
                      (child) => child.ordering_child === order && age >= child.start_age && age <= child.end_age,
                    );

                    if (conflictingAge) {
                      toast.error("The maximum age conflicts with an existing range for the same child order.");
                      e.target.value = null;
                      handleChildMaxAgeData("", idx);
                      handleChildMinAgeData("", idx);
                      const parentElement = e.target.parentElement.parentElement;
                      const maxAgeInput = parentElement.querySelector("[name='start_age']");
                      if (maxAgeInput) {
                        maxAgeInput.value = null;
                      }
                      return;
                    }

                    handleChildMaxAgeData(Number(age), idx);
                  }}
                  defaultValue={
                    children_room_types?.length > 0 &&
                    children_room_types[idx]?.end_age !== null &&
                    children_room_types[idx]?.end_age !== undefined
                      ? children_room_types[idx]?.end_age
                      : ""
                  }
                />

                <StaticFromInput
                  name="child_price"
                  text="Price"
                  type="number"
                  canDefaultValueZero={true}
                  placeholder="Price"
                  func={(price) => handleChildPrice(Number(price), idx)}
                  defaultValue={
                    children_room_types?.length > 0 &&
                    children_room_types[idx]?.end_age !== null &&
                    children_room_types[idx]?.end_age !== undefined
                      ? children_room_types[idx]?.price
                      : ""
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="flex gap-2 justify-end">
          <div className="flex items-center justify-end px-5 gap-x-1 mt-3">
            <MdAddCircleOutline
              className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-green-800"
              onClick={addChildInput}
            />

            <FaChildren className="w-5 h-5 text-gray-800" />
            <BiMinusCircle
              className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-red-800"
              onClick={removeChildInput}
            />
          </div>

          <div className="flex items-center justify-end px-5 gap-x-1 mt-3">
            <MdAddCircleOutline
              className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-green-800"
              onClick={addInput}
            />

            <BsPeopleFill className="w-5 h-5 text-gray-800" />
            <BiMinusCircle
              className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-red-800"
              onClick={removeInput}
            />
          </div>
        </div>
      </div>
    );
  }
}
