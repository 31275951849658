import React, { useEffect, useState } from "react";
import Select from "react-select";
import cookie from "react-cookies";
import { FaWpforms } from "react-icons/fa6";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { getHotelContracts } from "../../../redux/actions/getContracts";
import { areaAction, destinationAction, hotelArea } from "../../../redux/actions/searchformAction";
import { getContractTest } from "../../../redux/actions/TestingTools";
import RoomSearchCard from "../../molecules/RoomSearchCard/RoomSearchCard";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import Loading from "../Loading/Loading";
import { Spinner } from "flowbite-react";
import PresonChild from "./PresonChild";
import { useSearchParams } from "react-router-dom";

const token = cookie.load("access_token");
let numberOfPersonsArr = [];

for (let i = 1; i < 13; i++) {
  numberOfPersonsArr.push({ value: i, label: i });
}

export default function PriceTestTool() {
  const [searchParams] = useSearchParams();
  const [AllQuery, setAllQuery] = useState(searchParams.size === 0 ? false : true);

  // constants
  const dispatch = useDispatch();
  // get data
  const [hotelData, setHotelData] = useState(null);
  const [AraeData, setAraeData] = useState(null);
  const [ContractData, setContractData] = useState(null);

  const [focusedInput, setFocusedInput] = useState(null);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [desFlag, setDesFlag] = useState(false);
  const [DestionationSelect, setDestionationSelect] = useState(null);
  const [area, setArea] = useState(null);
  const [areaDataLoad, setAreaDataLoad] = useState(false);
  const [hotel, setHotel] = useState(null);
  const [hotelDataLoad, setHotelDataLoad] = useState(false);
  const [nights, setNights] = useState("");
  const [contract, setContract] = useState(null);
  const [contractLoad, setContractLoad] = useState(false);
  const [roomsData, setRoomsData] = useState([]);
  const [errMsg, setErrMsg] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [roomDetails, setRoomDetails] = useState([{ adults: 1, children: [] }]);

  // reducers
  const hotels = useSelector((state) => state.hotelNames);
  const contracts = useSelector((state) => state.hotelContracts);
  const { destinationData, areaData } = useSelector((state) => state.SearchFormReducer);

  // form arrays
  const destinationsArr = destinationData?.map((destObj) => ({ label: destObj.name, value: destObj.id }));
  const areaArr = AraeData ? AraeData.map((area) => ({ label: area.name, value: area.id })) : [];
  const hotelArr = hotelData ? hotelData?.map((hotels) => ({ label: hotels.name, value: hotels.id })) : [];
  const contractsArr = ContractData
    ? ContractData.map((contract) => ({ label: contract.name, value: contract.id }))
    : [];

  // Get specific query params
  const cityQuery = searchParams.get("cityQuery");
  const areaQuery = searchParams.get("areaQuery");
  const hotelNameQuery = searchParams.get("hotelNameQuery");
  const contractNameQuery = searchParams.get("contractNameQuery");

  // Find the data that matches Query
  const destinationQueryOption = destinationsArr?.find((data) => {
    return String(data.value) === cityQuery;
  });

  const areaQueryOption = areaArr?.find((data) => {
    return String(data.value) === areaQuery;
  });

  const hotelsQueryOption = hotelArr?.find((data) => {
    return String(data.value) === hotelNameQuery;
  });

  const contractsQueryOption = contractsArr?.find((data) => {
    return data.label === contractNameQuery;
  });

  // Fetch data for query
  useEffect(() => {
    if (cityQuery) {
      setAreaDataLoad(true);
      dispatch(
        areaAction(token, cityQuery, "en", (result) => {
          if (result.status === 200) {
            setAreaDataLoad(false);
            setAraeData(result.data);
          }
        }),
      );
    }

    if (areaQuery) {
      setHotelDataLoad(true);
      dispatch(
        hotelArea(token, areaQuery, "en", (result) => {
          if (result.status === 200) {
            setHotelDataLoad(false);
            setHotelData(result.data);
            setHotel("");
          }
        }),
      );
    }

    if (hotelNameQuery) {
      setContractLoad(true);
      dispatch(
        getHotelContracts(hotelNameQuery, (result) => {
          if (result.status === 200) {
            setContractLoad(false);
            setContractData(result.data);
          }
        }),
      );
    }
  }, [hotelNameQuery, cityQuery, areaQuery]);

  // effects
  useEffect(() => {
    dispatch(
      destinationAction(token, "en", (result) => {
        setDesFlag(true);
      }),
    );
  }, []);

  useEffect(() => {
    if (roomsData.data && roomsData.data.length === 0) {
      setErrMsg(true);
    } else {
      setErrMsg(false);
    }
  }, [roomsData]);

  // functions
  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    if (startDate && endDate) {
      setNights(Math.floor((endDate - startDate) / 86400000));
    }
  };

  function handleNightsChange(e) {
    if (e.target.value >= 1) {
      setNights(e.target.value);
      let diff = e.target.value - nights;
      if (diff < 0) {
        setDateRange({ ...dateRange, endDate: dateRange.endDate.subtract(diff * -1, "days") });
      } else {
        setDateRange({ ...dateRange, endDate: dateRange.endDate.add(diff, "days") });
      }
    }
  }

  // const details = [
  //   { adults: persons?.value, children: children?.value === 0 || children?.value ? [children?.value] : [] },
  // ];

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const data = {
      area: area?.value || areaQuery,
      persons: JSON.stringify([roomDetails[0]?.adults]),
      people: JSON.stringify(roomDetails),
      hotel: hotel?.value || hotelNameQuery,
      nights: Number(nights),
      from_date: dateRange.startDate.format("YYYY-MM-DD"),
      to_date: dateRange.endDate.format("YYYY-MM-DD"),
      contract: contract?.value || contractsQueryOption.value,
    };
    dispatch(
      getContractTest(data, (data) => {
        setRoomsData(data);
        setIsDisabled(false);
      }),
    );
  }

  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      try {
        // Check if the screen width is "md" based on Tailwind CSS classes
        if (window.innerWidth < 1024) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      } catch (error) {}
    };

    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);

    // Initial check when the component is mounted
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="formPage-wrap ">
      {desFlag ? (
        <div className="flex flex-col items-center  w-full">
          <div className=" w-full ">
            <div className=" flex justify-center ">
              <h2 className="header-h2">
                <FaWpforms className="w-5 h-5" />
                Price Test Tool
              </h2>
            </div>
            <form className="form-wrap" encType="multipart/form-data" onSubmit={handleSubmit}>
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <div className="input-par">
                  <div className="input-chil">
                    <label className=" flex text-gray-600 ">Destination</label>
                    <Select
                      required
                      placeholder="Destination"
                      className="w-full h-10 border rounded-lg bg-white z-40"
                      options={destinationsArr}
                      value={DestionationSelect || (AllQuery && destinationQueryOption) || null}
                      onChange={(e) => {
                        setDestionationSelect(e);
                        setAreaDataLoad(true);
                        dispatch(
                          areaAction(token, e.value, "en", (result) => {
                            if (result.status === 200) {
                              setAreaDataLoad(false);
                              setAraeData(result.data);
                              setArea("");
                              setHotel("");
                              setContract("");
                              setContractData("");
                              setHotelData("");
                              setAllQuery(false);
                            }
                          }),
                        );
                      }}
                    />
                  </div>
                  <div className="input-chil">
                    <label className=" flex text-gray-600 ">Area</label>
                    {areaDataLoad ? (
                      <ButtonLoading />
                    ) : (
                      <Select
                        required
                        className="w-full h-10 border rounded-lg bg-white z-30"
                        placeholder={DestionationSelect ? "Please Select Area" : "Please Select Destination"}
                        options={areaArr}
                        value={area || (AllQuery && areaQueryOption) || null}
                        onChange={(e) => {
                          setArea(e);
                          setHotelDataLoad(true);
                          dispatch(
                            hotelArea(token, e.value, "en", (result) => {
                              if (result.status === 200) {
                                setHotelDataLoad(false);
                                setHotelData(result.data);
                                setHotel("");
                              }
                            }),
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="input-par mb-5">
                  <div className="input-chil">
                    {" "}
                    <label className=" flex text-gray-600 ">Hotel</label>
                    {hotelDataLoad ? (
                      <ButtonLoading />
                    ) : (
                      <Select
                        required
                        placeholder={area ? "Please Select Hotel" : "Please Select Area"}
                        className="w-full h-10 border rounded-lg bg-white z-20"
                        options={hotelArr}
                        value={hotel || (AllQuery && hotelsQueryOption) || null}
                        onChange={(e) => {
                          setContractLoad(true);
                          setHotel(e);
                          dispatch(
                            getHotelContracts(e.value, (result) => {
                              if (result.status === 200) {
                                setContractLoad(false);
                                setContractData(result.data);
                                setContract("");
                              }
                            }),
                          );
                        }}
                      />
                    )}
                  </div>
                  <div className="input-chil">
                    {" "}
                    <label className="flex text-gray-600">Contract</label>
                    {contractLoad ? (
                      <ButtonLoading />
                    ) : (
                      <Select
                        required
                        placeholder={hotel ? "Please Select Contract" : "Please Select Hotel"}
                        className="w-full h-10 border rounded-lg bg-white z-10"
                        options={contractsArr}
                        onChange={(e) => setContract(e)}
                        value={contract || (AllQuery && contractsQueryOption) || null}
                      />
                    )}
                  </div>
                </div>

                <PresonChild setRoomDetails={setRoomDetails} roomDetails={roomDetails} />

                <div className="w-full gap-4 flex max-sm:flex-col mt-5 ">
                  <div className="flex-1 max-sm:w-full flex flex-col gap-y-1">
                    <label className="flex   text-gray-600  mr-10 ">Date Range</label>
                    <DateRangePicker
                      startDate={dateRange.startDate}
                      startDateId="startDate"
                      endDate={dateRange.endDate}
                      endDateId="endDate"
                      onDatesChange={handleDateChange}
                      focusedInput={focusedInput}
                      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                      minimumNights={0} // Allow same-day selection
                      required
                      small
                      verticalBorderSpacing={50} // Adjust the spacing between monthsW
                      autofocus
                      orientation={open ? "vertical" : "horizontal"}
                      numberOfMonths={open ? 6 : 2}
                      daySize={open ? 42 : 40}
                      verticalHeight={800}
                      // weekDayFormat={t("lan") === "en" ? "ddd" : "dddd"}
                      weekDayFormat="ddd"
                      noNavButtons
                    />
                  </div>
                  <div className="flex-1 max-sm:w-full flex flex-col gap-y-1">
                    <label className="flex w-full  text-gray-600  ">Nights</label>
                    <input
                      type="number"
                      placeholder="Nights"
                      className="w-full  h-10 px-3 border rounded-lg bg-white text-gray-800"
                      value={nights}
                      disabled={!dateRange.startDate}
                      onChange={(e) => handleNightsChange(e)}
                    />
                  </div>
                </div>

                <button
                  type="Submit"
                  disabled={isDisabled}
                  className="flex mt-3 items-center gap-3 px-4 py-1.5 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  Test Price {isDisabled && <Spinner color="info" aria-label="Info spinner example" />}
                </button>
              </div>
            </form>
          </div>
          {roomsData.data && roomsData.data.length > 0 && (
            <div className="border-gray-300 border rounded-lg p-5 my-3 w-[90%]">
              <RoomSearchCard rooms={roomsData?.data[0]?.rooms} />
            </div>
          )}
          {errMsg && <div>No Rooms Available for this contract data</div>}
        </div>
      ) : (
        <div className="w-full flex justify-center items-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
