import React, { useState } from "react";
import { useFormik } from "formik";
import { FaStar, FaWpforms } from "react-icons/fa";
import Select from "react-select";
import RepeatComponent from "../../molecules/RepeatComponent/RepeatComponent";
import { addHotelValidationSchema } from "./addHotelValidationSchema";
import { Rating } from "react-simple-star-rating";
import "./AddHotel.scss";
import GoogleMap from "../../molecules/GoogleMap/GoogleMap";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { IoAdd } from "react-icons/io5";
import useData from "../../../customHooks/useData";
import { getData, sendFormData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useNavigate } from "react-router";
import FileUploader from "../FileUploader/FileUploader";

export default function AddHotel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // fetched data to be used in the form
  const features = useData("/hotels/api/v1/get-features/");
  const countries = useData("site-settings/api/countries-flags");
  const extraInfo = useData("/hotels/api/v1/hotel-extra-info");

  //states
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingArea, setLoadingArea] = useState(false);
  const countriesArr = countries
    ? countries.map((country) => ({ label: country.name, value: country.id, countryId: country.country }))
    : [];

  const citiesArr = cities.map((city) => ({ label: city.name, value: city.id }));
  const areasArr = areas.map((area) => ({ label: area.name, value: area.id }));
  const featuresArr = features ? features.map((feature) => ({ label: feature.feature, value: feature.id })) : [];
  const extraInfArr = extraInfo ? extraInfo.map((info) => ({ label: info.name, value: info.id })) : [];
  const [mapData, setMapData] = useState({ lat: 30.0444, lng: 31.2357 });
  const [cityTimeZone, setCityTimeZone] = useState(null);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      name_ar: "",
      _country: "",
      city: "",
      area: "",
      address: "",
      address_ar: "",
      hotelMapAddress: "",
      payment_type: "",
      internet: "",
      description: "",
      description_ar: "",
      telephone: [""],
      reservationTelephone: [""],
      reservationEmail: [""],
      receptionEmail: [""],
      restaurants: [""],
      images: [],
      videos: [],
      rate: null,
      features: [],
      extra_info: [],
    },
    validationSchema: addHotelValidationSchema,
    onSubmit: submit,
  });

  function submit(values) {
    const {
      images,
      videos,
      features,
      extra_info,
      telephone,
      reservationTelephone,
      reservationEmail,
      receptionEmail,
      restaurants,
      hotelMapAddress,
      ...restOfValues
    } = values;

    for (const image of images) {
      if (!image.valid) {
        toast.error(`Image ${image.name} is not valid because ${image.errors[0]}`);
        setSubmitting(false);
        return;
      }
    }

    if (videos.length > 0) {
      for (const video of videos) {
        if (!video.valid) {
          toast.error(`Video ${video.name} is not valid because ${video.errors[0]}`);
          setSubmitting(false);
          return;
        }
      }
    }

    const formData = new FormData();
    features.forEach((feature) => formData.append("features", feature));
    extra_info.forEach((info) => formData.append("extra_info", info));
    images.forEach((image) => formData.append("images", image.file));
    videos.forEach((video) => formData.append("videos", video.file));
    Object.keys(restOfValues).forEach((item) => formData.append(`${item}`, restOfValues[item]));
    formData.append("latitude", mapData.lat);
    formData.append("longitude", mapData.lng);
    formData.append("hotel_map_address", hotelMapAddress);
    formData.append("telephone", JSON.stringify(Object.assign({}, telephone)));
    formData.append("reservation_telephone", JSON.stringify(Object.assign({}, reservationTelephone)));
    formData.append("reservation_email", JSON.stringify(Object.assign({}, reservationEmail)));
    formData.append("reception_email", JSON.stringify(Object.assign({}, receptionEmail)));
    formData.append("restaurants", JSON.stringify(Object.assign({}, restaurants)));
    dispatch(
      sendFormData(
        "/en/hotels/api/v1/add-get-hotels/",
        formData,
        (resp) => {
          toast.success("Your Hotel was added successfully");
          setSubmitting(false);
          navigate(`/dashboard/edit-hotel/${resp.data.id}`);
        },
        (error) => {
          NotificationManager.error(error.response.data);
          setSubmitting(false);
        },
      ),
    );
  }

  function getCities(country) {
    setLoading(true);
    setChosenCity(null);
    setChosenArea(null);
    setFieldValue("city", "");
    setFieldValue("area", "");
    dispatch(
      getData(
        `/en/hotels/api/v1/get-cities/${country}/`,
        (resp) => {
          if (resp.data.length === 0) {
            toast.error("This Country has no Cities in the system Please choose another one");
          }
          setCities(resp.data);
          setLoading(false);
        },
        (err) => {
          toast.error("there was an error");
          setLoading(false);
        },
      ),
    );
  }

  function getAreas(city) {
    setLoadingArea(true);
    setChosenArea(null);
    setFieldValue("area", "");
    dispatch(
      getData(
        `/en/hotels/api/v1/city-areas/${city}/`,
        (resp) => {
          if (resp.data.length === 0) {
            toast.error("This City has no Areas Please choose another one");
          }
          setAreas(resp.data);
          setLoadingArea(false);
        },
        (err) => {
          toast.error("there was an error");
          setLoadingArea(false);
        },
      ),
    );
  }

  return (
    <div className="formPage-wrap ">
      {(!features || !countries) && (
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress /> <Loading />
        </div>
      )}
      {features && countries && (
        <div className=" w-full ">
          <div className=" flex justify-center ">
            <h2 className="header-h2">
              <FaWpforms className="w-5 h-5" />
              Add Hotel
            </h2>
          </div>
          <form onSubmit={handleSubmit} className="w-full  form-wrap flex flex-col gap-y-8 ">
            <div className="input-par">
              <div className="input-chil">
                <label className=" w-full text-gray-600 font-semibold flex items-center ">Hotel Name</label>
                <input
                  placeholder="Hotel Name"
                  name="name"
                  type="text"
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.name && touched.name && "border-red-900"
                  }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && (
                  <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                    {errors.name}
                  </div>
                )}
              </div>
              <div className="input-chil">
                <label className="  w-full text-gray-600 font-semibold flex items-center ">Hotel Arabic Name</label>
                <input
                  placeholder="Hotel Arabic Name"
                  name="name_ar"
                  type="text"
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.name_ar && touched.name_ar && "border-red-900"
                  }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name_ar && touched.name_ar && (
                  <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                    {errors.name_ar}
                  </div>
                )}
              </div>
            </div>
            <div className=" p-4 rounded-md border flex flex-col gap-4">
              <div className="input-chil">
                <label className=" text-center w-full text-gray-600 font-semibold flex items-center ">Country</label>
                <Select
                  name="_country"
                  options={countriesArr}
                  isSearchable
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: `${errors._country && touched._country && "rgb(135 27 27)"}`,
                    }),
                  }}
                  className={`w-full  h-10  rounded-lg  bg-white `}
                  placeholder="Country"
                  onChange={(e) => {
                    getCities(e.value);
                    setFieldValue("_country", e.countryId);
                  }}
                  onBlur={handleBlur}
                />
                {errors._country && touched._country && (
                  <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                    {errors._country}
                  </div>
                )}
              </div>
              <div className="input-par">
                {loading ? (
                  <div className="w-full flex items-center  justify-center">
                    <ButtonLoading />
                  </div>
                ) : (
                  values._country && (
                    <div className="input-chil">
                      <label className="  w-full text-gray-600 font-semibold flex items-center ">City</label>
                      <Select
                        name="city"
                        options={citiesArr}
                        isSearchable
                        value={chosenCity}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${errors.city && touched.city && "rgb(135 27 27)"}`,
                          }),
                        }}
                        className={`w-full  h-10  rounded-lg  bg-white `}
                        placeholder="City"
                        onChange={(e) => {
                          const chosenCity = cities.find((city) => city.id === e.value);
                          const timezone = chosenCity.timezone;
                          setCityTimeZone(timezone);

                          if (timezone) {
                            getAreas(e.label);
                          } else {
                            setChosenArea(null);
                            setFieldValue("area", null);
                            setAreas([]);
                          }

                          setChosenCity(e);
                          setFieldValue("city", e.value);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.city && touched.city && (
                        <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                          {errors.city}
                        </div>
                      )}
                    </div>
                  )
                )}
                {loadingArea ? (
                  <div className="w-full flex items-center  justify-center">
                    <ButtonLoading />
                  </div>
                ) : values.city && areasArr.length > 0 ? (
                  <div className="input-chil">
                    <label className="  w-full text-gray-600 font-semibold flex items-center ">Area</label>
                    <Select
                      name="area"
                      options={areasArr}
                      isSearchable
                      value={chosenArea}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: `${errors.area && touched.area && "rgb(135 27 27)"}`,
                        }),
                      }}
                      className={`w-full  h-10  rounded-lg  bg-white `}
                      placeholder="Area"
                      onChange={(e) => {
                        setChosenArea(e);
                        setFieldValue("area", e.value);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.area && touched.area && (
                      <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                        {errors.area}
                      </div>
                    )}
                  </div>
                ) : (
                  values.city &&
                  (cityTimeZone ? (
                    <div className="input-chil text-center flex justify-center items-center rounded-md md:px-4 p-2 gap-x-2 text-gray-700 bg-gray-300 h-10 mt-6 ">
                      <p className="font-semibold text-center ">No Area For This City , Please Select Another City</p>
                    </div>
                  ) : (
                    <div className="input-chil text-center flex justify-center items-center rounded-md md:px-4 p-2 gap-x-2 text-gray-700 bg-gray-300 h-10 mt-6 ">
                      <p className="font-semibold text-center ">No Timezone For This City , Please Add it</p>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="input-par">
              <div className="input-chil">
                <label className="  w-full text-gray-600 font-semibold flex items-center ">Address</label>
                <input
                  placeholder="Address"
                  name="address"
                  type="text"
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.address && touched.address && "border-red-900"
                  }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.address && touched.address && (
                  <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                    {errors.address}
                  </div>
                )}
              </div>
              <div className="input-chil">
                <label className="  w-full text-gray-600 font-semibold flex items-center ">Arabic Address</label>
                <input
                  placeholder="Arabic Address"
                  name="address_ar"
                  type="text"
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.address_ar && touched.address_ar && "border-red-900"
                  }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.address_ar && touched.address_ar && (
                  <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                    {errors.address_ar}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <label className=" text-center w-full text-gray-600 font-semibold flex items-center ">Internet</label>
              <input
                placeholder="Internet"
                name="internet"
                type="text"
                className={`w-full px-3 h-10  border rounded-lg bg-white ${
                  errors.internet && touched.internet && "border-red-900"
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.internet && touched.internet && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.internet}
                </div>
              )}
            </div>
            <div className="">
              <label className="text-center w-full text-gray-600 font-semibold flex items-center  ">Payment Type</label>
              <div
                className={`flex items-center justify-center gap-x-10 w-full border rounded-md h-10     bg-white ${
                  errors.payment_type && touched.payment_type && "border border-red-900"
                }`}
              >
                <div className="flex items-center justify-center gap-x-3 w-[50%]">
                  <label className="text-gray-600 text-lg">Cash</label>
                  <input
                    placeholder="Employee Name"
                    name="payment_type"
                    type="radio"
                    value="cash"
                    className=" w-5 h-5  border rounded-lg bg-white "
                    onChange={(e) => setFieldValue("payment_type", e.target.value)}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="flex items-center justify-center gap-x-3 w-[50%]">
                  <label className="text-gray-600 text-lg">Credit</label>
                  <input
                    placeholder="Total Amount"
                    name="payment_type"
                    type="radio"
                    value="credit"
                    className=" w-5 h-5  border rounded-lg bg-white font-semibold"
                    onChange={(e) => setFieldValue("payment_type", e.target.value)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              {errors.payment_type && touched.payment_type && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.payment_type}
                </div>
              )}
            </div>

            <div className="input-par">
              <div className="input-chil">
                <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                  Telephone
                </label>
                <RepeatComponent
                  state={values.telephone}
                  setState={setFieldValue}
                  name="telephone"
                  errors={touched.telephone && errors.telephone}
                >
                  {(index) => (
                    <input
                      placeholder="Telephone"
                      name="telephone"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.telephone && touched.telephone && "border-red-900"
                      }`}
                      onChange={(e) => {
                        let oldState = [...values.telephone];
                        oldState[index] = e.target.value;
                        setFieldValue("telephone", oldState);
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </RepeatComponent>
              </div>
              <div className="input-chil">
                <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                  Reservation Telephone
                </label>
                <RepeatComponent
                  state={values.reservationTelephone}
                  setState={setFieldValue}
                  name="reservationTelephone"
                  errors={touched.reservationTelephone && errors.reservationTelephone}
                >
                  {(index) => (
                    <input
                      placeholder="Reservation Telephone"
                      name="reservationTelephone"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.reservationTelephone && touched.reservationTelephone && "border-red-900"
                      }`}
                      onChange={(e) => {
                        let oldState = [...values.reservationTelephone];
                        oldState[index] = e.target.value;
                        setFieldValue("reservationTelephone", oldState);
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </RepeatComponent>
              </div>
            </div>
            <div className="input-par">
              <div className="input-chil">
                <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                  Reservation Email
                </label>
                <RepeatComponent
                  state={values.reservationEmail}
                  setState={setFieldValue}
                  name="reservationEmail"
                  errors={touched.reservationEmail && errors.reservationEmail}
                >
                  {(index) => (
                    <input
                      placeholder="Reservation Email"
                      name="reservationEmail"
                      type="text"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.reservationEmail && touched.reservationEmail && "border-red-900"
                      }`}
                      onChange={(e) => {
                        let oldState = [...values.reservationEmail];
                        oldState[index] = e.target.value;
                        setFieldValue("reservationEmail", oldState);
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </RepeatComponent>
              </div>
              <div className="input-chil">
                <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                  Reception Email
                </label>
                <RepeatComponent
                  state={values.receptionEmail}
                  setState={setFieldValue}
                  name="receptionEmail"
                  errors={touched.receptionEmail && errors.receptionEmail}
                >
                  {(index) => (
                    <input
                      placeholder="Reception Email"
                      name="receptionEmail"
                      type="text"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.receptionEmail && touched.receptionEmail && "border-red-900"
                      }`}
                      onChange={(e) => {
                        let oldState = [...values.receptionEmail];
                        oldState[index] = e.target.value;
                        setFieldValue("receptionEmail", oldState);
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </RepeatComponent>
              </div>
            </div>
            <div className="">
              <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                Restaurants
              </label>
              <RepeatComponent
                state={values.restaurants}
                setState={setFieldValue}
                name="restaurants"
                errors={touched.restaurants && errors.restaurants}
              >
                {(index) => (
                  <input
                    placeholder="Restaurants"
                    name="restaurants"
                    type="text"
                    className={`w-full px-3 h-10  border rounded-lg bg-white ${
                      errors.restaurants && touched.restaurants && "border-red-900"
                    }`}
                    onChange={(e) => {
                      let oldState = [...values.restaurants];
                      oldState[index] = e.target.value;
                      setFieldValue("restaurants", oldState);
                    }}
                    onBlur={handleBlur}
                  />
                )}
              </RepeatComponent>
            </div>
            <div className="">
              <label className="flex items-center justify-center w-full text-gray-600 font-semibold">Description</label>
              <textarea
                name="description"
                type="text"
                className={`w-full p-3 border rounded-lg bg-white ${
                  errors.description && touched.description && "border-red-900"
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.description && touched.description && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.description}
                </div>
              )}
            </div>
            <div className="">
              <label className="flex items-center justify-center w-full text-gray-600 font-semibold">
                Arabic Description
              </label>
              <textarea
                name="description_ar"
                type="text"
                className={`w-full p-3 border rounded-lg bg-white ${
                  errors.description_ar && touched.description_ar && "border-red-900"
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.description_ar && touched.description_ar && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.description_ar}
                </div>
              )}
            </div>
            <div className="">
              <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                Features
              </label>
              <Select
                name="features"
                options={featuresArr}
                isSearchable
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: `${errors.features && touched.features && "rgb(135 27 27)"}`,
                  }),
                }}
                className={`w-full  h-10  rounded-lg  bg-white `}
                isMulti
                placeholder="Features"
                onChange={(selectedOptions) => {
                  const updatedFeatures = selectedOptions.map((option) => option.label);
                  setFieldValue("features", updatedFeatures);
                }}
                onBlur={handleBlur}
              />
              {errors.features && touched.features && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.features}
                </div>
              )}
            </div>
            <div className="">
              <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                Extra Info
              </label>
              <Select
                name="extra_info"
                options={extraInfArr}
                isSearchable
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: `${errors.extra_info && touched.extra_info && "rgb(135 27 27)"}`,
                  }),
                }}
                className={`w-full  h-10  rounded-lg  bg-white capitalize `}
                isMulti
                placeholder="Hotel Extra Info"
                onChange={(selectedOptions) => {
                  const updatedExtra = selectedOptions.map((option) => option.value);
                  setFieldValue("extra_info", updatedExtra);
                }}
                onBlur={handleBlur}
              />
              {errors.extra_info && touched.extra_info && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.extra_info}
                </div>
              )}
            </div>
            <div className="">
              <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                Upload Images
              </label>
              <div
                className={` w-full  border rounded-md   bg-white ${
                  errors.images && touched.images && "border border-red-900"
                }`}
              >
                <FileUploader
                  accept={"image/*"}
                  label={"Drag and drop hotel images here or click to browse"}
                  files={values.images}
                  setFiles={(files) => {
                    setFieldValue("images", files);
                  }}
                />
              </div>
              {errors.images && touched.images && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.images}
                </div>
              )}
            </div>

            <div className="">
              <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                Upload Videos
              </label>
              <div
                className={`w-full border rounded-md bg-white ${
                  errors.videos && touched.videos && "border border-red-900"
                }`}
              >
                <FileUploader
                  accept={"video/*"}
                  label={"Drag and drop hotel videos here or click to browse"}
                  maxFileSize={50}
                  files={values.videos}
                  setFiles={(files) => {
                    setFieldValue("videos", files);
                  }}
                />
              </div>
              {errors.videos && touched.videos && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.videos}
                </div>
              )}
            </div>
            <div className={`w-full  `}>
              <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                Rating
              </label>
              <div
                className={`flex items-center justify-start w-full border rounded-md px-3 rating   h-10 p-4  bg-white ${
                  errors.videos && touched.videos && "border border-red-900"
                }`}
              >
                <Rating
                  onClick={(rate) => setFieldValue("rate", rate)}
                  iconsCount={5}
                  size={10}
                  fillIcon={<FaStar />}
                  emptyIcon={<FaStar />}
                />
              </div>
              {errors.rate && touched.rate && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.rate}
                </div>
              )}
            </div>
            <div className="w-full">
              <GoogleMap
                getLocationData={(data) => setMapData(data)}
                defaultProps={{
                  center: {
                    lat: 30.0444,
                    lng: 31.2357,
                  },
                  zoom: 15,
                }}
                formikSet={setFieldValue}
              />
            </div>

            <div className="flex justify-center mt-6">
              <button type="Submit" disabled={isSubmitting} className="addButton">
                Add Hotel{" "}
                {isSubmitting ? (
                  <>
                    <ButtonLoading /> <TopBarProgress />
                  </>
                ) : (
                  <IoAdd className="w-5 h-5" />
                )}
              </button>
            </div>
          </form>
          {loading && (
            <>
              <TopBarProgress />
              {/* <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                <HashLoader size={30} color="white" />
                <div className="text-white font-bold my-2">Please Wait...</div>
              </div> */}
            </>
          )}
          <NotificationContainer />
        </div>
      )}
      <NotificationContainer />
    </div>
  );
}
