import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import cookie from "react-cookies";
import { AiFillDelete, AiOutlineEye, AiOutlineInfoCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { getSelectedTransactionAction } from "../../../redux/actions/allTransactionsAction";
import Loading from "../Loading/Loading";
import { deleteBankTransferAction } from "../../../redux/actions/bankTransferAction";
import lightToast from "light-toast";
import { deleteCashAction } from "../../../redux/actions/cashAction";
import { deletePaymentTransactions } from "../../../redux/actions/paymentTransactionAction";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { GoPerson } from "react-icons/go";
import { PiPercent } from "react-icons/pi";
import { BsCurrencyExchange } from "react-icons/bs";
import { LiaCalendar } from "react-icons/lia";
import { IoKeyOutline } from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";
import { MdOutlineDone, MdOutlineSettings } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import { deletePosVisaAction } from "../../../redux/actions/posVisaAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const SelectedAllTransaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = cookie.load("access_token");
  const [isOpen, setIsOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const params = useParams();
  const [check, setCheck] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [transaction, setTransaction] = useState(null);
  const TransactionArray = ["Cash", "(POS) Visa", "Bank Transfer", "Jumbo4pay"];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    dispatch(
      getSelectedTransactionAction(
        token,
        params.id,
        (result) => {
          if (result.status === 200) {
            setLoad(true);
          }
        },
        signal,
      ),
    );
    return () => {
      controller.abort();
    };
  }, []);
  const { selectedTransaction } = useSelector((state) => state.allTransactionsReducer);
  const { selectedTransactionFlag } = useSelector((state) => state.allTransactionsReducer);

  const handleDelete = (id) => {
    if (type === "Cash") {
      dispatch(
        deleteCashAction(token, id, (result) => {
          if (result.status === 204) {
            lightToast.success("This Cash was completed Deleted");
            dispatch(getSelectedTransactionAction(token, params.id));
          }
        }),
      );
    } else if (type === "Jumbo4pay") {
      dispatch(
        deletePaymentTransactions(token, id, (result) => {
          if (result.status === 204) {
            lightToast.success("This Jumpo4Pay was completed Deleted");
            dispatch(getSelectedTransactionAction(token, params.id));
          }
        }),
      );
    } else if (type === "Bank Transfer") {
      dispatch(
        deleteBankTransferAction(token, id, (result) => {
          if (result.status === 204) {
            lightToast.success("This Bank Transfer was completed Deleted");
            dispatch(getSelectedTransactionAction(token, params.id));
          }
        }),
      );
    } else if (type === "(POS) Visa") {
      dispatch(
        deletePosVisaAction(token, id, (result) => {
          if (result.status === 204) {
            lightToast.success("This Pos Visa was completed Deleted");
            dispatch(getSelectedTransactionAction(token, params.id));
          }
        }),
      );
    }
  };

  const { allTransactionsData } = useSelector((state) => state.allTransactionsReducer);
  const { allTransactionsFlag } = useSelector((state) => state.allTransactionsReducer);
  const { deleteBankTransferFlag } = useSelector((state) => state.bankTransferReducer);
  const { deleteCashFlag } = useSelector((state) => state.cashReducer);
  const { deletePaymentFlag } = useSelector((state) => state.paymentTransactionReducer);
  const { deletePosVisaFlag } = useSelector((state) => state.posVisaReducer);

  const rows =
    selectedTransaction &&
    selectedTransaction.results.map((item, index) => {
      return {
        amount: item.amount,
        booked_room_id: item.booked_room_id,
        collected_by: item.collected_by,
        commission: item.commission,
        currency: item.currency,
        transaction_date: item.transaction_date.slice(0, 10),
        id: index,
        keyId: item.id,
        subtotal: item.subtotal,
        transaction_status: item.transaction_status,
        transaction_type: item.transaction_type,
      };
    });

  const columns = [
    {
      field: "transaction_type",
      headerName: (
        <div className="flex items-center gap-x-1">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          TYPE
        </div>
      ),

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="ml-2">{params.row.transaction_type}</div>;
      },
    },

    {
      field: "amount",
      headerName: (
        <div className="flex items-center gap-x-1">
          <GiMoneyStack className="w-5 h-5  text-yellow-500" />
          AMOUNT
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="ml-10">{Number(params.row.amount)}</div>;
      },
    },

    {
      field: "collected_by",
      headerName: (
        <div className="flex items-center gap-x-1">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          USERNAME
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="ml-10">{params.row.collected_by}</div>;
      },
    },

    {
      field: "commission",
      headerName: (
        <div className="flex items-center gap-x-1">
          <PiPercent className="w-5 h-5  text-yellow-500" />
          COMMISSION
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="ml-10">{params.row.commission}</div>;
      },
    },
    {
      field: "currency",
      headerName: (
        <div className="flex items-center gap-x-1">
          <BsCurrencyExchange className="w-5 h-5  text-yellow-500" />
          CURRENCY
        </div>
      ),

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="ml-10">{params.row.currency}</div>;
      },
    },
    {
      field: "transaction_date",
      headerName: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          DATE
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="ml-3">{params.row.transaction_date}</div>;
      },
    },
    {
      field: "id",
      headerName: (
        <div className="flex items-center gap-x-1">
          <IoKeyOutline className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return <div className="flex items-center justify-start gap-x-5 ml-5">{params.row.keyId}</div>;
      },
    },
    // {
    //   field: 'booked_room_id',
    //   headerName: 'ID',

    //   flex: 1,
    //   editable: false,
    // },
    {
      field: "subtotal",
      headerName: (
        <div className="flex items-center gap-x-1">
          <GiTakeMyMoney className="w-5 h-5  text-yellow-500" />
          SUBTOTAL
        </div>
      ),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <div className="ml-10">{params.row.subtotal}</div>;
      },
    },
    {
      field: "transaction_status",
      headerName: (
        <div className="flex items-center gap-x-1">
          <AiOutlineInfoCircle className="w-5 h-5  text-yellow-500" />
          STATUS
        </div>
      ),

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.transaction_type === "Cash" ||
            params.row.transaction_type === "(POS) Visa" ||
            params.row.transaction_type === "Jumbo4pay" ? (
              <div className="w-full flex items-center justify-center capitalize gap-x-1 mr-4  h-[70%] rounded-md bg-green-700 bg-opacity-70 text-white font-semibold">
                <p>confirmed</p> <MdOutlineDone className="w-4 h-4 mb-1 border-gray-300 border rounded-full " />
              </div>
            ) : params.row.transaction_status === "pending" ? (
              <div className="w-full flex items-center justify-center capitalize gap-x-1 mr-4 bg-yellow-300  text-gray-800 h-[70%] rounded-md font-semibold ">
                <p>{params.row.transaction_status}....</p>
              </div>
            ) : (
              <div className="w-full flex items-center justify-center capitalize gap-x-1 mr-4  h-[70%] rounded-md bg-green-700 bg-opacity-70 text-white font-semibold">
                <p>{params.row.transaction_status}</p>{" "}
                <MdOutlineDone className="w-4 h-4 mb-1 border-gray-300 border rounded-full " />
              </div>
            )}
          </>
        );
      },
    },

    {
      field: "operations",
      filterable: false,
      sortable: false,
      flex: 1,
      headerName: (
        <div className=" flex items-center gap-x-1 text-xs ">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      renderCell: (params) => {
        return (
          <div className="flex items-center justify-start gap-x-3 ml-5">
            {permission.map((item) => {
              if (params.row.transaction_type === "Bank Transfer") {
                if (item.name === "room_booking.view_banktransfer" && item.value === true) {
                  return (
                    <Link
                      to={`/dashboard/all-transactions/1/id/preview/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <AiOutlineEye className="w-5 h-5 text-slate-900 " />
                    </Link>
                  );
                }
              } else if (params.row.transaction_type === "Cash") {
                if (item.name === "room_booking.view_cashpaymenttransaction" && item.value === true) {
                  return (
                    <Link
                      to={`/dashboard/all-transactions/1/id/preview/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <AiOutlineEye className="w-5 h-5 text-slate-900 " />
                    </Link>
                  );
                }
              } else if (params.row.transaction_type === "(POS) Visa") {
                if (item.name === "accounting.view_posmachinetransaction" && item.value === true) {
                  return (
                    <Link
                      to={`/dashboard/all-transactions/1/id/preview/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <AiOutlineEye className="w-5 h-5 text-slate-900 " />
                    </Link>
                  );
                }
              } else if (params.row.transaction_type === "Jumbo4pay") {
                if (item.name === "room_booking.view_payment" && item.value === true) {
                  return (
                    <Link
                      to={`/dashboard/all-transactions/1/id/preview/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <AiOutlineEye className="w-5 h-5 text-slate-900 " />
                    </Link>
                  );
                }
              }
            })}

            {permission.map((item) => {
              if (params.row.transaction_type === "Bank Transfer") {
                if (item.name === "room_booking.change_banktransfer" && item.value === true) {
                  return (
                    <Link
                      to={`/dashboard/all-transactions/1/id/update/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <FiEdit className="w-5 h-5 text-green-800 " />
                    </Link>
                  );
                }
              } else if (params.row.transaction_type === "Cash") {
                if (item.name === "room_booking.change_cashpaymenttransaction" && item.value === true) {
                  return (
                    <Link
                      to={`/dashboard/all-transactions/1/id/update/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <FiEdit className="w-5 h-5 text-green-800 " />
                    </Link>
                  );
                }
              } else if (params.row.transaction_type === "(POS) Visa") {
                if (item.name === "accounting.change_posmachinetransaction" && item.value === true) {
                  return (
                    <Link
                      to={`/dashboard/all-transactions/1/id/update/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <FiEdit className="w-5 h-5 text-green-800 " />
                    </Link>
                  );
                }
              } else if (params.row.transaction_type === "Jumbo4pay") {
                if (item.name === "room_booking.change_payment" && item.value === true) {
                  return (
                    <Link
                      to={`/dashboard/all-transactions/1/id/update/${params.row.booked_room_id}/${params.row.transaction_type}/${params.row.keyId}`}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <FiEdit className="w-5 h-5 text-green-800 " />
                    </Link>
                  );
                }
              }
            })}

            {permission.map((item) => {
              if (params.row.transaction_type === "Bank Transfer") {
                if (item.name === "room_booking.delete_banktransfer" && item.value === true) {
                  return (
                    <button
                      onClick={() => {
                        setId(params.row.keyId);
                        setType(params.row.transaction_type);
                        setConfirm(true);
                      }}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <AiFillDelete className="w-5 h-5 text-red-800" />
                    </button>
                  );
                }
              } else if (params.row.transaction_type === "Cash") {
                if (item.name === "room_booking.delete_cashpaymenttransaction" && item.value === true) {
                  return (
                    <button
                      onClick={() => {
                        setId(params.row.keyId);
                        setType(params.row.transaction_type);
                        setConfirm(true);
                      }}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <AiFillDelete className="w-5 h-5 text-red-800" />
                    </button>
                  );
                }
              } else if (params.row.transaction_type === "(POS) Visa") {
                if (item.name === "accounting.delete_posmachinetransaction" && item.value === true) {
                  return (
                    <button
                      onClick={() => {
                        setId(params.row.keyId);
                        setType(params.row.transaction_type);
                        setConfirm(true);
                      }}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <AiFillDelete className="w-5 h-5 text-red-800" />
                    </button>
                  );
                }
              } else if (params.row.transaction_type === "Jumbo4pay") {
                if (item.name === "room_booking.delete_payment" && item.value === true) {
                  return (
                    <button
                      onClick={() => {
                        setId(params.row.keyId);
                        setType(params.row.transaction_type);
                        setConfirm(true);
                      }}
                      className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                    >
                      <AiFillDelete className="w-5 h-5 text-red-800" />
                    </button>
                  );
                }
              }
            })}
          </div>
        );
      },
    },
  ];

  /////permission/////////
  const permissionData = {
    permission_codes: [
      "room_booking.view_banktransfer",
      "room_booking.change_banktransfer",
      "room_booking.delete_banktransfer",
      "room_booking.view_cashpaymenttransaction",
      "room_booking.change_cashpaymenttransaction",
      "room_booking.delete_cashpaymenttransaction",
      "accounting.view_posmachinetransaction",
      "accounting.change_posmachinetransaction",
      "accounting.delete_posmachinetransaction",
      "room_booking.view_payment",
      "room_booking.change_payment",
      "room_booking.delete_payment",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full overflow-x-auto ">
      {confirm && (
        <div
          className={` fixed ${
            confirm ? "top-0 left-0 right-0 bottom-0 h-screen  " : ""
          }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${
              confirm
                ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <p className="text-2xl font-light  text-white">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id, type);
                }}
                className="bg-green-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      {load ? (
        deleteBankTransferFlag || deleteCashFlag || deletePaymentFlag || (deletePosVisaFlag && !permissionFlag) ? (
          <>
            {" "}
            <TopBarProgress />
            <div className="w-full h-full flex items-center justify-center">
              <Loading />
            </div>
          </>
        ) : permissionFlag && permission.some((item) => item.value) ? (
          <Box sx={{ height: "100%", width: "100%", backgroundColor: "white", overflowX: "auto" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // rowCount={ allTransactionsData.count}
              // onSortModelChange={comparator}
              // columnVisibilityModel={columnVisibility}
              // onColumnVisibilityModelChange={(visibility) => {
              //   setColumnVisibility(visibility);
              //   localStorage.setItem("grid-visibility-transaction-list", JSON.stringify(visibility));
              // }}
              // paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
              // onPaginationModelChange={handlePageChange}
              // paginationMode="server"
              className=" xl:w-full  md:w-[150%] w-[250%]"
            />
          </Box>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <>
          <TopBarProgress />
          <div className="w-full h-full flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedAllTransaction;
