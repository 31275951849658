import React from "react";
import ClientFamilyForm from "./ClientFamilyForm";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import cookie from "react-cookies";
import jwtDecode from "jwt-decode";
import { useMutation } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import usePermissions from "../../../../customHooks/usePermissions";
import Errorfetch from "../../Errorfetch/Errorfetch";
import Loading from "../../Loading/Loading";
import NoPermission from "../../NoPermission/NoPermission";

function AddClientFamilyForm() {
  const { clientID } = useParams();
  const navigate = useNavigate();
  const user = jwtDecode(cookie.load("access_token"));
  const userID = user.user_id;

  const [permissions, permissionFlag, permissionsError] = usePermissions(["auth_users.add_customerfriends"]);

  const isAddingFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.add_customerfriends")?.value === true;

  const { isPending, mutate: createFamily } = useMutation({
    queryKey: ["add-family"],
    mutationFn: (data) => {
      return mutateDataQuery(`/auth/api/v1/friends/bulk-create/`, "post", data, {
        "Content-Type": "application/json",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Family added Successfully");
      navigate(`/dashboard/client-family/${clientID}/family-list`);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to add Family");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to add Family");
    },
  });

  function handleSubmit(values, helpers) {
    values.family.map((familyMember) => {
      familyMember.added_by = userID;
      familyMember.customer = Number(clientID);
      return familyMember;
    });

    const data = JSON.stringify(values.family);

    createFamily(data);
  }

  const personSchema = Yup.object().shape({
    name_en: Yup.string().required("English name is required"),
    name_ar: Yup.string().required("Arabic name is required"),
  });

  const validationSchema = Yup.object().shape({
    family: Yup.array()
      .of(personSchema)
      .required("Family is required")
      .min(1, "At least one family member is required"),
  });

  const initialValues = {
    family: [
      {
        name_en: "",
        name_ar: "",
      },
    ],
  };

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  if (!permissionFlag) {
    return <Loading />;
  }

  return isAddingFamilyAllowed ? (
    <ClientFamilyForm
      onSubmitButtonTitle="Add Family & Friends"
      isPending={isPending}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      title="Add Client Family & Friends"
      validationSchema={validationSchema}
    />
  ) : (
    <NoPermission />
  );
}

export default AddClientFamilyForm;
