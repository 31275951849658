import api_token from "../../api/UserApi";
import cookie from "react-cookies";
const token = cookie.load("access_token");

export const createSearchPermission = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post("/permissions/api/search-permission/", data);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const getSearchListAction = (callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("/permissions/api/search-permission/");
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const getSearchByIdAction = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/permissions/api/search-permission/${id}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const UpdateSearchByIdAction = (id, values, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`/permissions/api/search-permission/${id}/`, values);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const deletePermisssion = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`/permissions/api/search-permission/${id}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
