import { useEffect, useState } from "react";
import api_token from "../api/UserApi";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";

const token = cookie.load("access_token");

function getData(url, callback, success) {
  return async function () {
    try {
      const response = await api_token(token).get(url);
      if (response.status === 200) {
        callback(response.data);
        success(response);
      }
    } catch (error) {}
  };
}

export default function useData(url, setLoad, rerender) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  useEffect(() => {
    dispatch(
      getData(
        url,
        (data) => setData(data),
        (success) => setLoad(true),
      ),
    );
  }, [rerender]);
  return data;
}
