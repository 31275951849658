import React, { useEffect, useState } from "react";
import { RiKeyboardLine, RiUserSearchFill } from "react-icons/ri";
import { PiHandshakeLight } from "react-icons/pi";
import { VscAdd } from "react-icons/vsc";
import { useNavigate } from "react-router";
import { CiEdit } from "react-icons/ci";
import { MdOutlineAssignmentInd } from "react-icons/md";
import { FaList } from "react-icons/fa";
import { TbUsersPlus } from "react-icons/tb";
import { BsFillMenuButtonFill, BsFillMenuAppFill } from "react-icons/bs";
import { PiUsersThreeFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { chechSuperUserAction } from "../../../redux/actions/switchUserAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";

export default function Permissions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [superUser, setSuperUser] = useState();
  useEffect(() => {
    dispatch(
      chechSuperUserAction((result) => {
        if (result.status === 200) {
          setSuperUser(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap ">
      {superUser ? (
        <div className=" ">
          <div className="w-full flex justify-center ">
            <h2 className="header-h2">
              <RiKeyboardLine className="w-5 h-5" />
              Permissions
            </h2>
          </div>
          {superUser?.is_superuser === true ? (
            <div className="grid md:grid-cols-3 gap-2 sm:grid-cols-2 grid-cols-1  gap-y-5 my-5 form-wrap !max-w-5xl">
              <div class="flex  flex-col gap-y-4 items-center justify-between  px-3 pt-3  border-solid">
                <Link
                  to={"./add-group-permissions"}
                  // onClick={() => navigate("./add-group-permissions")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center">
                    <div className="relative flex flex-col">
                      <PiHandshakeLight className="w-12 h-12" />
                      <VscAdd className="absolute top-10 left-10 w-4 h-4" />
                    </div>
                  </div>
                </Link>
                <p className="text-lg font-semibold">Add Group Permissions</p>
              </div>

              <div class="flex   flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./edit-group-permissions"}
                  // onClick={() => navigate("./edit-group-permissions")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <CiEdit className="w-12 h-12" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Edit Group Permissions</p>
              </div>
              {/* <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
          <Link
            onClick={() => navigate("./assign-users-to-groups")}
            className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
          >
            <div className="w-full flex justify-center items-center ">
              <MdOutlineAssignmentInd className="w-12 h-12" />
            </div>
          </Link>
          <p className="text-lg font-semibold">Assign Users to Groups</p>
        </div> */}
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./groups-list"}
                  // onClick={() => navigate("./groups-list")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <FaList className="w-12 h-12" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Groups List</p>
              </div>
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./add-users"}
                  // onClick={() => navigate("./add-users")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <TbUsersPlus className="w-12 h-12" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Add Users</p>
              </div>
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./search-users"}
                  // onClick={() => navigate("./search-users")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <PiUsersThreeFill className="w-12 h-12" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Search Users</p>
              </div>
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./add-menus"}
                  // onClick={() => navigate("./add-menus")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <BsFillMenuButtonFill className="w-12 h-12" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Add Menus</p>
              </div>
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./edit-menus"}
                  // onClick={() => navigate("./edit-menus")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <BsFillMenuAppFill className="w-12 h-12" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Edit Menus</p>
              </div>
              <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./search-permission"}
                  // onClick={() => navigate("./search-users")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <RiUserSearchFill className="w-12 h-12" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Search permission</p>
              </div>
            </div>
          ) : (
            <div className="w-full flex items-center justify-center h-screen">
              <NoPermission />
            </div>
          )}
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
