import React, { useEffect } from "react";
import fetchDataQuery from "../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { FieldErrorText, FormLabel } from "../FormComponents";

const CustomCity = ({ formValue, onChange, Getlocal = {} }) => {
  const {
    data: citiesData,
    error: citiesError,
    isLoading: citiesloading,
  } = useQuery({
    queryKey: [`get-cities`],
    queryFn: () => fetchDataQuery(`/search/api/v1/get-cities/`),
  });

  const dataSelector = citiesData
    ? citiesData?.map((city) => ({
        value: city.id,
        label: city.name,
      }))
    : [];

  return (
    <div className="w-full">
      <FormLabel label={"City"} htmlFor={"city"} />
      <Select
        name="city"
        id="city"
        isLoading={citiesloading}
        isDisabled={citiesloading}
        onChange={(e) => {
          onChange("city", e);
        }}
        loadingMessage={() => "Getting cities"}
        placeholder="Choose City"
        options={dataSelector}
        value={formValue?.city}
        defaultValue={Getlocal?.city}
      />
      {citiesError && <FieldErrorText message={"Error fetching cities"} />}
    </div>
  );
};

export default CustomCity;
