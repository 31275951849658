import { GET_ALL_ROOMS, GET_HOTEL_ROOMS, GET_ROOM_DETAILS, CLEAR_HOTEL_ROOMS } from "../types/types";

const defaultState = [];
const roomDetailsDefaultState = {
  room_name: "",
};

export function roomsReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_ALL_ROOMS:
      return action.payload;
    default:
      return state;
  }
}

export function hotelRoomsReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_HOTEL_ROOMS:
      return action.payload;
    case CLEAR_HOTEL_ROOMS:
      return action.payload;
    default:
      return state;
  }
}

export function roomDetailsReducer(state = roomDetailsDefaultState, action) {
  switch (action.type) {
    case GET_ROOM_DETAILS:
      return action.payload;
    default:
      return state;
  }
}
