import React, { useEffect, useMemo, useState } from "react";
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import TaskManagerForm from "./TaskManagerForm";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapterPlugin from "../ITDepartment/Tickets/CustomUploadAdapterPlugin";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import NoPermission from "../NoPermission/NoPermission";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";

const AddNewTask = () => {
  const [editorData, setEditorData] = useState("");
  const [editorInstance, setEditorInstance] = useState(null);
  const [editorKey, setEditorKey] = useState(0);
  const [toolDocument, setToolDcoument] = useState([]);
  const [adding, setAdding] = useState();
  const [userData, setUserData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [TypeData, setTypeData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const navigate = useNavigate();
  const {
    mutate: taskManager,
    isLoading,
    isPending,
  } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery("/tasks-management/api/v1/task-assignments/", "post", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    mutationKey: "taskManager",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Task Created Successfully");
      navigate(`/dashboard/task-list`);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to create new Task");
    },
  });
  // const {
  //   data: Task,
  //   error,
  //   isSuccess,
  //   isLoadingTask,
  // } = useQuery({
  //   queryKey: ["Task"],
  //   queryFn: () => fetchDataQuery("/auth/api/v1/users/"),
  // });

  // useEffect(() => {
  //   if (isSuccess && Task) {
  //     setUserData(Task);
  //   }
  // }, [Task, isSuccess]);

  // const userOtions = useMemo(() => userData?.map((user) => ({ label: user.username, value: user.id })), [userData]);

  const {
    data: department,
    error: errorDepartment,
    isSuccess: departmentSuccess,
    isLoadingTask: departmentLoading,
  } = useQuery({
    queryKey: ["department"],
    queryFn: () => fetchDataQuery("/department/api/v1/department"),
  });

  useEffect(() => {
    if (departmentSuccess && department) {
      setGroupData(department.results);
    }
  }, [department, departmentSuccess]);
  const grouOptions = useMemo(() => groupData?.map((user) => ({ label: user.name, value: user.id })), [groupData]);
  const {
    data: taskType,
    error: errorType,
    isSuccess: typeSuccess,
    isLoadingTask: typeLoading,
  } = useQuery({
    queryKey: ["taskType"],
    queryFn: () => fetchDataQuery("/tasks-management/api/v1/task-types/"),
  });
  useEffect(() => {
    if (typeSuccess && taskType) {
      setTypeData(taskType);
    }
  }, [taskType, typeSuccess]);
  const typeOptions = useMemo(() => TypeData?.map((user) => ({ label: user.name, value: user.id })), [TypeData]);
  const editorConfig = {
    extraPlugins: [CustomUploadAdapterPlugin],
    language: {
      // The UI will be English.
      ui: "en",
      // But the content will be edited in Arabic.
      content: "ar",
    },
    toolbar: {
      items: [
        "undo",
        "redo",
        "fontfamily",
        "fontsize",
        "|",
        "bold",
        "italic",
        "|",
        "link",
        "uploadImage",
        "|",
        "alignment", // Add the alignment option to the toolbar
      ],
      shouldNotGroupWhenFull: true,
    },
    alignment: {
      options: ["left", "center", "right", "justify"],
    },
  };

  // const handleEditorChange = (event, editor) => {
  //   const data = editor.getData();
  //   // const strippedData = stripHTMLTags(data); // "Hello"
  //   setEditorData(data);
  // };

  const statusVal = [
    { label: "New", value: "new" },
    { label: "Pending", value: "pending" },
    { label: "Done", value: "done" },
  ];

  const formik = useFormik({
    initialValues: {
      name: "", // Default empty if no initial values
      task_type: "",
      taskStatus: "",
      department: [],
      user: [], // Changed to array
      task_details: "",
    },
    validationSchema: Yup.object()
      .shape({
        name: Yup.string().required("Task Name is required"),
        task_type: Yup.object().required("Task Type is required"),
        task_details: Yup.string().required("Task Details is required"),

        user: Yup.array().nullable(),
        department: Yup.array().nullable(),
      })
      .test("user-or-department", "At least one of 'user' or 'department' is required", function (value) {
        const { user, department } = value || {};
        const isUserValid = user && user.length > 0;
        const isDepartmentValid = department && department.length > 0;

        // Ensure at least one of them is not empty
        if (!isUserValid && !isDepartmentValid) {
          return this.createError({ path: "user", message: "At least one of 'user' or 'department' must be selected" });
        }
        return true;
      }),

    onSubmit: async (values, { setSubmitting }) => {
      setAdding(true);
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("task_type", values.task_type.value);
        formData.append("taskStatus", "new");

        const departments =
          values.department.length > 0 ? values.department?.map((departmentId) => departmentId.value) : [];

        // Prepare user array
        const users = values.user?.map((userId) => (userId.value ? userId.value : userId.id ? userId.id : userId));
        // Append department and user arrays as JSON strings
        formData.append("department", JSON.stringify(departments));
        formData.append("user", JSON.stringify(users));

        formData.append("task_details", values.task_details);

        // Handle document attachments
        if (toolDocument.length > 0) {
          for (let doc of toolDocument) {
            if (!doc.valid) {
              toast.error(`File ${doc.name} is not valid because ${doc.errors[0]}`);
              return;
            }
            formData.append("attachment", doc.file);
          }
        }
        // alert(JSON.stringify(users));
        // Use the FormData for the API call
        await taskManager(formData);
      } catch (error) {
        toast.error(error.message || "Failed to create new Task");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [permissions, permissionFlag, permissionsError] = usePermissions(["tasks_management.add_taskassignment"]);
  const isAddingingOfferAllowed =
    permissions?.find((permission) => permission.name === "tasks_management.add_taskassignment")?.value === true;

  // this will delete the hotel stored in the state by passing it to the action then remove the popup

  if (!permissionFlag) {
    return <Loading />;
  }
  // if (error || !Task) {
  //   <Errorfetch Error={error?.message || "Error Fetching Tasks "} />;
  // }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  return isAddingingOfferAllowed ? (
    <TaskManagerForm
      title={"Add New Task"}
      formik={formik}
      toolDocument={toolDocument}
      setToolDcoument={setToolDcoument}
      adding={adding}
      setAdding={setAdding}
      statusVal={statusVal}
      departmentsVal={grouOptions}
      // taskTypes={typeOptions.length > 0 ? typeOptions : taskTypes}
      taskTypes={typeOptions}
      // handleEditorChange={handleEditorChange}
      editorKey={editorKey}
      ClassicEditor={ClassicEditor}
      editorData={editorData}
      editorConfig={editorConfig}
      setEditorInstance={setEditorInstance}
      // users={userOtions}
      isPending={isPending}
      isLoading={isLoading}
      isEdit={false}
      formErrors={formErrors}
      hasSubmitted={hasSubmitted}
    />
  ) : (
    <NoPermission />
  );
};

export default AddNewTask;
