import React, { useEffect, useMemo, useRef, useState } from "react";
import "../AddHotelForm/DashboardForm.scss";
import "./Inventory.scss";
import cookie from "react-cookies";
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import { eachDayOfInterval, endOfMonth, startOfMonth } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { destinationAction } from "../../../redux/actions/searchformAction";
import { getHotelRooms } from "../../../redux/actions/getRooms";
import toast from "react-hot-toast";
import { createBulk, deleteBulk, getInventoryList } from "../../../redux/actions/inventory";
import DynamicCheckBox from "../../atoms/DynamicCheckBox/DynamicCheckBox";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { tr } from "date-fns/locale";
import { LiaFileContractSolid } from "react-icons/lia";
import { BiSolidShow } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import fetchDataQuery from "../../../react_query/fetchDataQuery";

import Select from "react-select";

import { useQuery } from "@tanstack/react-query";

const fetchCities = () => fetchDataQuery("/search/api/v1/get-cities/");
const fetchAreas = (city) => fetchDataQuery(`/search/api/load-areas/?city_name=${city}`);
const fetchHotels = (city, area) =>
  fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]&areas=[${area ? area : 0}]`);

const today = new Date();
const thisYear = today.getFullYear();
const thisMonth = today.getMonth() + 1;
const yearsArr = [];
for (let i = thisYear; i < thisYear + 10; i++) {
  yearsArr.push({ id: i, name: i });
}
const token = cookie.load("access_token");

export default function Inventory() {
  const formRef = useRef();
  const dispatch = useDispatch();
  // const cities = useSelector((state) => state.SearchFormReducer);
  // const hotels = useSelector((state) => state.cityHotels);
  const rooms = useSelector((state) => state.hotelRooms);
  const inventoryList = useSelector((state) => state.inventoryList);
  const [showInv, setShowInv] = useState(false);
  const [hotelFlag, setHotelFlag] = useState(false);
  const [flag, setFlag] = useState(false);
  const [monthsArr, setMonthsArr] = useState([]);
  const [daysOfMonth, setDaysOfMonth] = useState(null);
  const [deleteFromInventory, setDeleteFromInvenntory] = useState([]);
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [chosenHotelVal, setChosenHotelsVal] = useState([]);
  const [sendToInventory, setSendToInventory] = useState([]);
  const [roomsHotel, setroomsHotel] = useState([]);

  const {
    data: cities,
    isLoading: loadingCities,
    error: citiesError,
  } = useQuery({
    queryKey: "cities",
    queryFn: fetchCities,
  });

  const chosenCityVal = chosenCity && chosenCity.value;
  const roomA = () =>
    dispatch(
      getHotelRooms(chosenHotelVal?.value, (result) => {
        if (result.status === 200) {
          setroomsHotel(result.data);
        }
      }),
    );
  useEffect(() => {
    if (chosenHotelVal.value) {
      roomA();
    }
  }, [chosenHotelVal.value]);
  const {
    data: areas,
    isLoading: loadingAreas,
    error: areasError,
    refetch: refetchAreas,
  } = useQuery({
    queryKey: ["areas", chosenCityVal],
    queryFn: () => fetchAreas(chosenCityVal),
    enabled: !!chosenCityVal && chosenCityVal !== "All",
  });

  const {
    data: hotels,
    isLoading: loadingHotels,
    error: hotelsError,
    refetch: refetchHotels,
  } = useQuery({
    queryKey: ["hotels", chosenCityVal, chosenArea],
    queryFn: () => {
      const areaValue = chosenArea
        ? chosenArea.value === "All"
          ? areas.map((area) => area.id)
          : chosenArea.value
        : "All";
      return fetchHotels(chosenCityVal, areaValue);
    },
    enabled: !!chosenCityVal && (!!chosenArea || chosenCityVal === "All"),
  });

  useEffect(() => {
    if (chosenCity) {
      if (chosenCity.value !== "All") {
        refetchAreas();
      } else {
        setChosenArea({ label: "All", value: "All" });
      }
    }
  }, [chosenCity, refetchAreas]);

  useEffect(() => {
    if (chosenArea) {
      refetchHotels();
    }
  }, [chosenArea, refetchHotels]);

  useEffect(() => {
    if (hotels) {
      setChosenHotels(hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })));
    }
  }, [hotels]);
  const citiesArr = cities?.destinationData?.map((obj) => {
    return { id: obj.id, name: obj.name };
  });
  const hotelsArr = hotels?.results?.map((hotel) => {
    return {
      id: hotel.id,
      name: hotel.name,
    };
  });
  const cityOptions = useMemo(() => cities?.map((city) => ({ label: city.name, value: city.id })), [cities]);
  const areaOptions = useMemo(
    () => [{ label: "All", value: "All" }, ...(areas?.map((area) => ({ label: area.name, value: area.id })) || [])],
    [areas],
  );
  const hotelOptions = useMemo(() => chosenHotels, [chosenHotels]);

  useEffect(() => {
    dispatch(destinationAction(token));
  }, []);

  function showInventory(e) {
    e.preventDefault();
    const startDate = startOfMonth(new Date(e.target.year.value, e.target.month.value));
    const endDate = endOfMonth(startDate);
    setDaysOfMonth(eachDayOfInterval({ start: startDate, end: endDate }));
    setFlag(true);
    dispatch(
      getInventoryList(e.target.year.value, e.target.month.value, chosenHotelVal?.value, (result) => {
        if (result.status === 200) {
          setFlag(false);
        } else {
          setFlag(false);
        }
      }),
    );
    setShowInv(true);
  }

  function change(e, room, date) {
    // if the user is unchecking the checkbox
    e.target.parentElement.style.backgroundColor = "rgb(0,255,0,0.3)";
    if (e.target.checked) {
      // if the room and date were checked before in the same session
      if (
        sendToInventory.some((obj) => {
          return obj.date === date && obj.room === room.id;
        })
      ) {
        const filteredSendToInventort = sendToInventory.filter((obj) => {
          return !(obj.date === date && obj.room === room.id);
        });
        setSendToInventory(filteredSendToInventort);
      } else {
        let idObj = inventoryList.filter((obj) => obj.date === date && obj.room === room.id);

        setDeleteFromInvenntory([...deleteFromInventory, idObj[0].id]);
      }
      // if the user is checking the ckeckbox
    } else {
      e.target.parentElement.style.backgroundColor = "rgb(255,0,0,0.3)";
      if (
        inventoryList.some((obj) => {
          return obj.date === date && obj.room === room.id;
        })
      ) {
        let idObj = inventoryList.filter((obj) => obj.date === date && obj.room === room.id);
        let filteredDeleteFromInventory = deleteFromInventory.filter((id) => id !== idObj[0].id);
        setDeleteFromInvenntory(filteredDeleteFromInventory);
      } else {
        const obj = {
          status: "Unavailable",
          date: date,
          room: room.id,
        };
        setSendToInventory([...sendToInventory, obj]);
      }
    }
  }

  function sendData() {
    if (sendToInventory) {
      dispatch(createBulk(sendToInventory));
    }
    if (deleteFromInventory) {
      dispatch(deleteBulk(deleteFromInventory));
    }
    setSendToInventory([]);
    setDeleteFromInvenntory([]);
    dispatch(getInventoryList(formRef.current.year.value, formRef.current.month.value, chosenHotelVal?.value));
    toast.success("Your data was added successfully");
  }

  function hideInventory() {
    if (showInv) {
      setShowInv(false);
    }
  }

  function getYear(year) {
    let months = [];
    hideInventory();
    if (Number(year) === thisYear) {
      for (let i = thisMonth; i < 13; i++) {
        months.push({ id: i - 1, name: i });
        setMonthsArr(months);
      }
    } else {
      for (let i = 1; i < 13; i++) {
        months.push({ id: i - 1, name: i });
        setMonthsArr(months);
      }
    }
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["inventory_managements.view_roominventory", "inventory_managements.change_roominventory"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap min-h-screen">
      {permissionFlag ? (
        permission?.map((item) => {
          if (item.name === "inventory_managements.view_roominventory" && item.value === true) {
            return (
              <div className="w-full">
                <div className=" flex items-center justify-center ">
                  <h2 className="header-h2 ">
                    {" "}
                    <LiaFileContractSolid className="w-6 h-6" />
                    Inventory
                  </h2>
                </div>

                <form className="w-full form-wrap  flex flex-col gap-4" onSubmit={showInventory} ref={formRef}>
                  <div className="w-full flex max-sm:flex-col gap-4 items-center justify-center">
                    <div className="flex-1 max-sm:w-full">
                      {
                        <div className=" border p-2 grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 rounded-md">
                          <div className="input-chil">
                            <label className="font-semibold w-full flex gap-x-2 text-[#002244]">City</label>
                            <Select
                              required
                              className="w-full h-10 text-gray-800 font-semibold border rounded-lg bg-white"
                              options={cityOptions}
                              placeholder="Select City"
                              onChange={(e) => {
                                setChosenCity(e);
                              }}
                              value={chosenCity}
                              isLoading={loadingCities}
                            />
                            {citiesError && <p className="text-red-500">{citiesError.message}</p>}
                          </div>
                          <div className="input-chil">
                            <label className="w-full text-gray-600 font-semibold flex items-center">Area</label>
                            <Select
                              name="area"
                              options={areaOptions}
                              isSearchable
                              value={chosenArea}
                              isDisabled={chosenCityVal ? loadingAreas : true}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: areasError && "rgb(135 27 27)",
                                }),
                              }}
                              className="w-full h-10 rounded-lg bg-white"
                              placeholder="Area"
                              onChange={(e) => {
                                setChosenArea(e);
                              }}
                            />
                          </div>
                          <div className="input-chil">
                            <label className="w-full text-gray-600 font-semibold flex items-center">Hotel</label>
                            <Select
                              name="hotel"
                              options={hotelOptions}
                              isSearchable
                              isDisabled={chosenCity && chosenArea ? false : true}
                              value={chosenHotelVal}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: hotelsError && "rgb(135 27 27)",
                                }),
                              }}
                              className="w-full  rounded-lg bg-white"
                              placeholder="Hotel"
                              onChange={(e) => {
                                setChosenHotelsVal(e);
                              }}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="w-full flex max-sm:flex-col gap-4 items-center justify-center">
                    <div className="flex-1 max-sm:w-full">
                      <StaticFormSelect
                        name="year"
                        text="Select Year"
                        options={yearsArr}
                        placeholder="Choose year"
                        callback={getYear}
                      />
                    </div>
                    <div className="flex-1 max-sm:w-full">
                      <StaticFormSelect
                        name="month"
                        text="Select Month"
                        options={monthsArr}
                        placeholder="Choose month"
                        callback={hideInventory}
                      />
                    </div>{" "}
                  </div>
                  <div className="w-full flex justify-center items-center  ">
                    <button
                      type="submit"
                      disabled={flag}
                      className="flex items-center justify-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] cursor-pointer  text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <BiSolidShow className="w-5 h-5" /> Show Inventory
                    </button>
                  </div>
                </form>
                {daysOfMonth &&
                  showInv &&
                  (flag ? (
                    <div className="w-full flex items-center justify-center">
                      <Loading /> <TopBarProgress />
                    </div>
                  ) : (
                    <div className="flex flex-col table-container px-5">
                      <div className="inline-block min-w-full py-2">
                        <div className="overflow-auto flex">
                          <table className="sticky left-0 bg-white ">
                            <tr className="h-[93px]">
                              <th className="bg-primary-200">Dates</th>
                            </tr>

                            {rooms?.map((room, index) => {
                              return (
                                <tr className="h-[40px]">
                                  <div
                                    className={`whitespace-nowrap px-6 py-4 font-medium ${
                                      index % 2 !== 0 && "bg-primary-100"
                                    }`}
                                  >
                                    {room.room_name}
                                  </div>
                                </tr>
                              );
                            })}
                          </table>
                          <table className="min-w-full text-center text-sm font-light ">
                            <thead className="border-b font-medium dark:border-neutral-500">
                              <tr className="bg-primary-200 h-[60px]">
                                {daysOfMonth?.map((day, index) => {
                                  let formatArr = `${day}`.split(" ");

                                  return (
                                    <th scope="col" className="px-6 py-4" key={index}>
                                      {`${formatArr[0]} ${formatArr[1]} ${formatArr[2]}`}
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {rooms?.map((room, index) => {
                                return (
                                  <tr className="border-b dark:border-neutral-500 h-[40px]" key={index}>
                                    {daysOfMonth?.map((day, index) => {
                                      const localDate = new Date(day.getTime() - day.getTimezoneOffset() * 60000);
                                      let date = localDate.toISOString().split("T")[0];
                                      let defaultChecked, backgroundColor;
                                      if (inventoryList.some((obj) => obj.date === date && obj.room === room.id)) {
                                        defaultChecked = false;
                                        backgroundColor = "rgb(255,0,0,0.3)";
                                      } else {
                                        defaultChecked = true;
                                        backgroundColor = "rgb(0,255,0,0.3)";
                                      }
                                      return (
                                        <td key={index} style={{ backgroundColor }}>
                                          <DynamicCheckBox
                                            defaultChecked={defaultChecked}
                                            name={`input-${index}`}
                                            onChange={change}
                                            room={room}
                                            date={date}
                                          />
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {permission?.map((item) => {
                        if (item.name === "inventory_managements.change_roominventory" && item.value === true) {
                          return (
                            <div className="w-full flex justify-end items-center px-10 ">
                              <button
                                onClick={sendData}
                                className="flex items-center justify-center gap-x-1 px-3 py-1 rounded-lg bg-green-900 cursor-pointer  text-white hover:bg-green-800 active:scale-95 duration-300 transition-all"
                              >
                                <FiSend className="w-5 h-5" /> Send to Inventory
                              </button>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ))}
              </div>
            );
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
