import React, { useEffect } from "react";
import "./DashStates.scss";
import DashStatesCard from "../../molecules/DashStatesCard/DashStatesCard";
import { useDispatch, useSelector } from "react-redux";
import { getBookingStats } from "../../../redux/actions/bookingRequests";

export default function DashStates() {
  const dispatch = useDispatch();
  const bookingRequestsStats = useSelector((state) => state.bookingsStats);
  const bookingRequestsQuantity = Object.values(bookingRequestsStats).reduce((a, b) => a + b, 0);
  useEffect(() => {
    dispatch(getBookingStats());
  }, []);
  return (
    <div className="w-full grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 place-content-center  px-6">
      <DashStatesCard
        props={{
          svg: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <title>email</title>
              <path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
            </svg>
          ),
          quantity: "12,350",
          title: "Active Contracts",
          percentage: "+14%",
          size: "40",
          progress: "0.75",
          bg: "inline-block p-6 bg-gradient-to-r from-sky-400 to-sky-800  ",
        }}
      />
      <DashStatesCard
        props={{
          svg: (
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium text-dark dark:text-light css-i4bv87-MuiSvgIcon-root"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="PointOfSaleIcon"
              fill="currentColor"
            >
              <path d="M17 2H7c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 4H7V4h10v2zm3 16H4c-1.1 0-2-.9-2-2v-1h20v1c0 1.1-.9 2-2 2zm-1.47-11.81C18.21 9.47 17.49 9 16.7 9H7.3c-.79 0-1.51.47-1.83 1.19L2 18h20l-3.47-7.81zM9.5 16h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm3 4h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm3 4h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5z"></path>
            </svg>
          ),
          quantity: "561,321",
          title: "Expired Contracts",
          percentage: "+21%",
          size: "40",
          progress: "0.46",
          bg: "inline-block p-6 bg-gradient-to-br from-yellow-400 to-orange-500",
        }}
      />
      <DashStatesCard
        props={{
          svg: (
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium text-dark dark:text-light css-i4bv87-MuiSvgIcon-root"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="PersonAddIcon"
              fill="currentColor"
            >
              <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
            </svg>
          ),
          quantity: "65,521",
          title: "New Bookings  ",
          percentage: "+5%",
          size: "40",
          progress: "0.21",
          bg: "inline-block p-6 bg-gradient-to-l from-green-400 to-teal-500  ",
        }}
      />
      <DashStatesCard
        props={{
          svg: (
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium text-dark dark:text-light css-i4bv87-MuiSvgIcon-root"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="TrafficIcon"
              fill="currentColor"
            >
              <path d="M20 10h-3V8.86c1.72-.45 3-2 3-3.86h-3V4c0-.55-.45-1-1-1H8c-.55 0-1 .45-1 1v1H4c0 1.86 1.28 3.41 3 3.86V10H4c0 1.86 1.28 3.41 3 3.86V15H4c0 1.86 1.28 3.41 3 3.86V20c0 .55.45 1 1 1h8c.55 0 1-.45 1-1v-1.14c1.72-.45 3-2 3-3.86h-3v-1.14c1.72-.45 3-2 3-3.86zm-8 9c-1.11 0-2-.9-2-2s.89-2 2-2c1.1 0 2 .9 2 2s-.89 2-2 2zm0-5c-1.11 0-2-.9-2-2s.89-2 2-2c1.1 0 2 .9 2 2s-.89 2-2 2zm0-5c-1.11 0-2-.9-2-2 0-1.11.89-2 2-2 1.1 0 2 .89 2 2 0 1.1-.89 2-2 2z"></path>
            </svg>
          ),
          quantity: bookingRequestsQuantity,
          title: "New Requests",
          stats: Object.values(bookingRequestsStats),
          dataLabel: "Requests",
          labels: ["New", "Pending", "Closed"],
          bg: "inline-block p-6 bg-gradient-to-br from-teal-400 to-cyan-400",
        }}
      />
    </div>
  );
}
