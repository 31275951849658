import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import ListFilters from "../../../utils/ListFilters";
import { MdBedroomParent, MdEmail, MdOutlinePermIdentity } from "react-icons/md";
import { FaHotel } from "react-icons/fa";
import { AiOutlineNumber } from "react-icons/ai";
import { IoMdPerson } from "react-icons/io";
import { CgCalendarDates } from "react-icons/cg";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { FaGear, FaRegImage } from "react-icons/fa6";
import { useParams } from "react-router";
import Loading from "../Loading/Loading";
import usePermissions from "../../../customHooks/usePermissions";
import NoPermission from "../NoPermission/NoPermission";
import Errorfetch from "../Errorfetch/Errorfetch";

function VouchersList(props) {
  const { short_id, id } = useParams();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "room_booking.view_evoucher",
    "room_booking.change_evoucher",
  ]);

  const isViewingVoucherAllowed =
    permissions?.find((permission) => permission.name === "room_booking.view_evoucher")?.value === true;

  const isChangingVoucherAllowed =
    permissions?.find((permission) => permission.name === "room_booking.change_evoucher")?.value === true;

  const { isLoading: isLoadingVouchers, data: vouchers } = useQuery({
    queryKey: ["vouchers", page, pageSize],
    queryFn: () => {
      return fetchDataQuery(`/booking/api/v1/e-vouchers/by-booked-room/${id}/?page=${page}&page_size=${pageSize}`);
    },
    enabled: isViewingVoucherAllowed,
  });

  if (isLoadingVouchers || !permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch Error={`Error Fetching permissions ${permissionsError.message}`} />;
  }

  const rows = vouchers?.results?.map((voucher) => {
    return {
      id: voucher?.id,
      randomId: voucher?.random_id,
      hotelName: voucher?.hotel_name,
      systemConfirmationNumber: voucher?.system_confirmation_number,
      guestNames: voucher?.guest_name,
      checkInDate: voucher?.check_in_date,
      checkOutDate: voucher?.check_out_date,
      roomType: voucher?.room_type,
      issuedBy: voucher?.issued_by?.username,
      createdAt: voucher?.created_date,
    };
  });

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
    },

    {
      key: "hotelName",

      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaHotel className="w-5 h-5  text-yellow-500" />
          Hotel Name
        </div>
      ),
      dataIndex: "hotelName",
    },
    {
      key: "systemConfirmationNumber",

      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <AiOutlineNumber className="w-5 h-5  text-yellow-500" />
          System Confirmation Number
        </div>
      ),
      dataIndex: "systemConfirmationNumber",
    },
    {
      key: "guestNames",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <IoMdPerson className="w-5 h-5  text-yellow-500" />
          Guest Names
        </div>
      ),
      dataIndex: "guestNames",
    },
    {
      key: "checkInDate",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <CgCalendarDates className="w-5 h-5  text-yellow-500" />
          Check In Date{" "}
        </div>
      ),
      dataIndex: "checkInDate",
    },
    {
      key: "checkOutDate",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <CgCalendarDates className="w-5 h-5  text-yellow-500" />
          Check Out Date{" "}
        </div>
      ),
      dataIndex: "checkOutDate",
    },
    {
      key: "roomType",

      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdBedroomParent className="w-5 h-5  text-yellow-500" />
          Room Type
        </div>
      ),
      dataIndex: "roomType",
    },
    {
      key: "issuedBy",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <IoMdPerson className="w-5 h-5  text-yellow-500" />
          Issued By
        </div>
      ),
      dataIndex: "issuedBy",
    },
    {
      key: "createdAt",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <CgCalendarDates className="w-5 h-5  text-yellow-500" />
          Created At{" "}
        </div>
      ),
      render: (value) => moment(value).format("DD-MM-YYYY HH:mm"),
      dataIndex: "createdAt",
    },
    {
      key: "operations",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaGear className="w-5 h-5  text-yellow-500" />
          Operations
        </div>
      ),
      render: (text, params) => {
        return (
          <div className="flex gap-2">
            <Link
              to={`/booking-voucher/${params.randomId}`}
              target="_blank"
              className="flex items-center gap-x-1 bg-cyan-900 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-cyan-700 active:scale-95 duration-300 transition-all"
            >
              <FaRegImage className="w-5 h-5 text-white" />
            </Link>

            {isChangingVoucherAllowed && (
              <Link
                to={`/dashboard/booking-list-single-id/${short_id}/${id}/booking-voucher/${params.randomId}/send-email`}
                className="flex items-center gap-x-1 bg-yellow-900 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-yellow-700 active:scale-95 duration-300 transition-all"
              >
                <MdEmail className="w-5 h-5 text-white" />
              </Link>
            )}
          </div>
        );
      },
      dataIndex: "operations",
    },
  ];

  return isViewingVoucherAllowed ? (
    <ListFilters
      handlePagination={(page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
      }}
      currentPage={page}
      listPageSize={pageSize}
      totalPages={vouchers?.count / pageSize}
      title="Booking Vouchers List"
      rows={rows}
      columns={columns}
    />
  ) : (
    <NoPermission />
  );
}

export default VouchersList;
