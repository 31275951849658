import React, { useState, useEffect, useRef } from "react";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

export default function DynamicCheckbox({ name, text, checkedByParent, setCheckedByParent, value, disabled }) {
  useRemoveScroll();
  const inputRef = useRef();
  const [checked, setChecked] = useState(false);

  function handleChange(e) {
    setChecked(!checked);
    setCheckedByParent(!checked);
    if (checkedByParent && setCheckedByParent) {
      setCheckedByParent(!checkedByParent);
    }
  }

  useEffect(() => {
    setChecked(checkedByParent);
    setCheckedByParent(checkedByParent);
  }, [checkedByParent, setCheckedByParent]);

  useEffect(() => {
    if (disabled) {
      setChecked(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (checked === false && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [checked]);

  useEffect(() => {
    if (value && inputRef.current) {
      inputRef.current.value = value;
    }
  }, [value]);

  return (
    <div className="w-full flex flex-col items-center gap-y-1 ">
      <div className="w-full flex items-center gap-x-2">
        <input
          type="checkbox"
          className={`w-4 h-4 ${disabled && "cursor-not-allowed"}`}
          onChange={handleChange}
          id={name}
          name={name}
          checked={checked && !disabled}
          disabled={disabled}
        />
        <label htmlFor={name} className={`text-slate-800 font-semibold ${disabled && "cursor-not-allowed"}`}>
          {text}
        </label>
      </div>
      {
        <div className="w-full">
          <input
            disabled={disabled || !checked}
            type="number"
            name={`${name}Price`}
            placeholder={`${text} price`}
            className={`w-[60%] px-3 h-10  border rounded-lg bg-white focus:outline-none focus:ring focus:border-blue-300  ${(disabled || !checked) && "cursor-not-allowed !bg-stone-200"}`}
            required
            ref={inputRef}
          />
        </div>
      }
    </div>
  );
}
