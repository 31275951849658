import { GET_INVENTORY_LIST } from "../types/types";

export default function inventoryListReducer(state = [], action) {
  switch (action.type) {
    case GET_INVENTORY_LIST:
      return action.payload;
    default:
      return state;
  }
}
