import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import HotelPopUp from "./HotelPopUp";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { getHotelsDescription } from "../../../redux/actions/bookingOrderAction";
import { useTranslation } from "react-i18next";
import { FaStar } from "react-icons/fa";

const HotelsImg = ({ hotelImages }) => {
  const [open, setOpen] = useState(false);
  const [hotelsDetails, setHotelsDetails] = useState();
  const [detailsFlag, setdetailsFlag] = useState(false);
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [NumberSlice, setNumberSlice] = useState(4);

  useEffect(() => {
    // Function to check if the screen size is small
    const checkScreenSize = () => {
      if (window.innerWidth < 900 && window.innerWidth > 600) {
        setIsSmallScreen(900);
      } else if (window.innerWidth < 600) {
        setIsSmallScreen(600);
      } else if (window.innerWidth > 1000) {
        setIsSmallScreen(false); // Adjust the breakpoint as needed
      }
    };

    // Initial check
    checkScreenSize();

    // Event listener to handle screen size changes
    window.addEventListener("resize", checkScreenSize);

    // Clean up
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (isSmallScreen === 900) {
      setNumberSlice(2);
    } else if (isSmallScreen === false) {
      setNumberSlice(4);
    } else if (isSmallScreen === 600) {
      setNumberSlice(1);
    }
  }, [isSmallScreen]);

  const handleHotelsDetials = (id) => {
    dispatch(
      getHotelsDescription(token, id, t("lan"), (result) => {
        if (result.status === 200) {
          setdetailsFlag(true);
          setHotelsDetails(result.data);
        }
      }),
    );
  };

  return (
    <>
      {open && (
        <HotelPopUp
          hotelsDetails={hotelsDetails}
          setdetailsFlag={setdetailsFlag}
          detailsFlag={detailsFlag}
          setOpen={setOpen}
          open={open}
        />
      )}

      <span className="flex flex-col items-center">
        <p dir={t("dir")} className=" flex items-center gap-x-1  text-xl md:text-2xl font-bold text-[#001a35] py-5 ">
          {t("plantrip")}
          <img src="/images/travel.png" className="h-[3rem] w-[3rem] " alt={""} />
        </p>
        <div className="bg-orange-600 h-[0.3rem] w-[3rem] rounded-md"></div>
      </span>

      <div className="w-full flex flex-col lg:flex-row items-center justify-center z-0 gap-16 px-5">
        <Carousel
          sx={{ width: "100%" }}
          next={(next, active) => {}}
          prev={(prev, active) => {}}
          autoPlay
          cycleNavigation
          animation={"fade"}
          navButtonsAlwaysVisible
          zIndex={0}
        >
          {hotelImages
            .reduce((acc, item, index) => {
              if (index % NumberSlice === 0) {
                acc.push([]);
              }
              acc[acc.length - 1].push(item);
              return acc;
            }, [])
            .map((slideItems, slideIndex) => (
              <div key={slideIndex} style={{ display: "flex", gap: "10px" }}>
                {slideItems.map((item, index) => (
                  <div key={index} className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                    <article className="overflow-hidden rounded-lg shadow-lg  w-full">
                      <span className=" overflow-hidden flex lg:h-[250px] h-[200px] w-full p-3">
                        <img
                          alt="Placeholder"
                          className=" object-fill  rounded-xl w-full "
                          src={item?.images[0]?.image_url}
                        />
                      </span>

                      <header className="flex items-center justify-between leading-tight  px-4">
                        <p className=" text-gray-600 text-sm  ">
                          {item.country && item?.country + " , "} {item?.area}{" "}
                        </p>
                      </header>
                      <header className="flex items-center justify-between leading-tight px-4">
                        <p className="text-base font-medium  text-black">
                          {item.name && item?.name.length > 40 ? item?.name.slice(15) : item?.name + "..."}
                        </p>

                        <p className=" text-base flex gap-1 items-center text-gray-600 font-bold   ">
                          <FaStar className=" text-amber-400 " />
                          {item?.rate}
                        </p>
                      </header>

                      <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                        <p
                          onClick={() => {
                            setOpen(true);
                            handleHotelsDetials(item.id);
                          }}
                          className=" cursor-pointer text-base font-medium  text-cyan-600 hover:text-cyan-900 transition duration-300 ease-in-out"
                        >
                          {t("getmoreinfo")} &rarr;{" "}
                        </p>
                      </footer>
                    </article>
                  </div>
                ))}
              </div>
            ))}
        </Carousel>
      </div>
    </>
  );
};

export default HotelsImg;
