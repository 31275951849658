import { Button, Modal } from "flowbite-react";
import React, { useState } from "react";
import { Form, Formik } from "formik";
import ContractAssistantRoomSelector from "../ContractAssistantRoomSelector";
import ContractAssistanceValiditySelector from "./ContractAssistanceValiditySelector";
import { toast } from "react-toastify";
import ContractAssistanceAdultsSelector from "./ContractAssistanceAdultsSelector";
import * as Yup from "yup";
import { addContractPolicy } from "../../../../../redux/actions/contractValidity";
import { useDispatch } from "react-redux";

const modalTheme = {
  body: {
    base: "flex-1 p-6",
  },
  content: {
    inner: "relative flex flex-col rounded-lg bg-white shadow dark:bg-gray-700",
  },
};

const initialValues = {
  allRooms: false,
  rooms: [],
  validities: [],
  adults: [],
};

const validationSchema = Yup.object().shape({
  rooms: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required("Room ID is required"),
        name: Yup.string().required("Room name is required"),
        maxAdults: Yup.number().required("Max adults is required"),
        maxChildren: Yup.number().required("Max children is required"),
        maxOccupancy: Yup.number().required("Max occupancy is required"),
      }),
    )
    .min(1, "At least one room is required"),
  validities: Yup.array()
    .of(
      Yup.object().shape({
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
      }),
    )
    .min(1, "At least one validity is required"),
  adults: Yup.array().of(Yup.number().required("Adults is required")).min(1, "At least one adult is required"),
});

const ContractAssistanceTool = ({ hotelRooms }) => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  function handleSubmit(values, helpers) {
    const policyAdded = dispatch(addContractPolicy(values));

    if (!policyAdded) {
      return;
    }
    setOpenModal(false);
    helpers.resetForm();
  }

  return (
    <>
      <Button
        color="success"
        onClick={() => {
          if (hotelRooms.length === 0) {
            toast.error("No rooms available, please select city and hotel first");
            return;
          }
          setOpenModal(true);
        }}
      >
        Contract Assistance Tool
      </Button>
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ resetForm, submitForm, values, errors }) => {
          return (
            <Form>
              <Modal
                theme={modalTheme}
                size="5xl"
                show={openModal}
                onClose={() => {
                  setOpenModal(false);
                  resetForm();
                }}
              >
                <Modal.Header>Contract Assistant Tool</Modal.Header>
                <Modal.Body>
                  <div className="space-y-6">
                    <ContractAssistantRoomSelector selectedRooms={hotelRooms} />
                    <ContractAssistanceAdultsSelector />
                    <ContractAssistanceValiditySelector />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button type={"submit"} onClick={submitForm}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ContractAssistanceTool;
