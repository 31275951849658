import React from "react";
import { CgBoy } from "react-icons/cg";
import { IoIosPerson } from "react-icons/io";
import Errorfetch from "../../organisms/Errorfetch/Errorfetch";
import { HiInformationCircle } from "react-icons/hi2";
import { Alert } from "flowbite-react";

function offerType(abbr) {
  switch (abbr) {
    case "b.o":
      return "Bed Only";
    case "b.b":
      return "Breakfast Included";
    case "h.b":
      return "Half Board";
    case "f.b":
      return "Full Board";
    case "s.a":
      return "Soft All";
  }
}

export default function RoomSearchCard({ rooms }) {
  let RoomArray = [];

  rooms?.map((selectedroom, index1) => {
    if (selectedroom.merged_contracts.shared_validities.length !== 0) {
      selectedroom.merged_contracts.shared_validities.map((item, index2) =>
        item.days_data.days_list.slice(0, 1).map((commission) => {
          item.total_price.map((price, index3) => {
            if (item.contract_meal_included === price.offer_type) {
              item.days_data.days_list.slice(0, 1).map((info2) => {
                RoomArray.push({
                  uniqueId: price.pk + price.offer_price,
                  price: price.offer_price,
                  original_price: price.offer_price_original,
                  netPrice: price.net_price_original,
                  offerType: price.offer_type,
                  persons_num: selectedroom.persons.adults,
                  children_num: selectedroom.persons.children.length,
                  selectedroom: selectedroom.room_name,
                  currency: item.currency,
                  search_currency: price.currency_search,
                  room_images: selectedroom.room_image,
                  roomDescription: selectedroom.room_description,
                });
              });
            } else {
              item.days_data.days_list.slice(0, 1).map((info2) => {
                Object.values(info2.meals_offers).map((info3) => {
                  if (price.offer_type === info3.offer_type) {
                    RoomArray.push({
                      uniqueId: price.pk + price.offer_price,
                      price: price.offer_price,
                      original_price: price.offer_price_original,
                      netPrice: price.net_price_original,
                      offerType: price.offer_type,
                      persons_num: selectedroom.persons.adults,
                      children_num: selectedroom.persons.children.length,
                      selectedroom: selectedroom.room_name,
                      currency: item.currency,
                      search_currency: price.currency_search,
                      room_images: selectedroom.room_image,
                      roomDescription: selectedroom.room_description,
                    });
                  }
                });
              });
            }
          });
        }),
      );
    }
    if (selectedroom.contracts.length !== 0) {
      selectedroom.contracts.map((item, index2) =>
        item.total_price.map((price, index3) => {
          if (item.contract_meal_included === price.offer_type) {
            Object.values(item.validities).map((info) => {
              info.days_data.days_list.slice(0, 1)?.map((info2) => {
                RoomArray.push({
                  uniqueId: price.pk + price.offer_price,
                  price: price.offer_price,
                  original_price: price.offer_price_original,
                  netPrice: price.net_price_original,
                  offerType: price.offer_type,
                  roomId: selectedroom.room_pk,
                  currency: item.currency,
                  rooms_extra_offers: selectedroom?.rooms_extra_offers,
                  search_currency: price.currency_search,
                  persons_num: selectedroom.persons.adults,
                  children_num: selectedroom.persons.children.length,
                  roomDescription: selectedroom.room_description,
                  selectedroom: selectedroom.room_name,
                  hotelname: selectedroom.hotel_name,
                  room_images: selectedroom.room_image,
                });
              });
            });
          } else {
            Object.values(item.validities).map((info) => {
              info.days_data.days_list.slice(0, 1).map((info3) => {
                Object.values(info3.meals_offers).map((info4) => {
                  if (price.offer_type === info4.offer_type) {
                    RoomArray.push({
                      uniqueId: price.pk + price.offer_price,
                      room_extra_info: selectedroom.room_extra_info,
                      price: price.offer_price,
                      original_price: price.offer_price_original,
                      netPrice: price.net_price_original,
                      offerType: price.offer_type,
                      currency: item.currency,
                      search_currency: price.currency_search,
                      persons_num: selectedroom.persons.adults,
                      children_num: selectedroom.persons.children.length,
                      roomDescription: selectedroom.room_description,
                      selectedroom: selectedroom.room_name,
                      room_images: selectedroom.room_image,
                    });
                  }
                });
              });
            });
          }
        }),
      );
    }
  });

  let allRoom = RoomArray?.sort((a, b) => a.price - b.price);

  return (
    <>
      {allRoom?.map((room, id) => (
        <div key={id} className="w-full mb-5  py-5 px-[1rem] md:px-4  boxShadow border  rounded-2xl relative  ">
          <div className="flex flex-wrap w-full justify-between items-center ">
            <div className=" flex xl:flex-row flex-col xl:flex justify-start items-start gap-x-3 gap-y-2 w-full xl:w-[60%]   ">
              <img src={room?.room_images} className="w-full xl:w-44  xl:h-44 rounded-2xl cursor-pointer" />
              <div className="  grid  relative  ">
                <div className=" w-full gap-y-2   md:mb-4 md:flex items-center justify-between">
                  <p className=" pb-2 text-sm lg:text-lg font-serif font-bold text-[#002244] w-[11rem]  cursor-pointer hover:text-black duration-300 transition-all">
                    {room?.selectedroom}
                  </p>

                  <p className="flex items-center justify-center bg-[#002244]  w-[11rem] rounded-xl py-1 text-white  font-medium text-xs  capitalize  ">
                    {offerType(room?.offerType)}
                  </p>
                </div>

                <p className="text-xs lg:text-sm line-clamp-2 sm:mt-0 mt-3  md:w-[17.4rem] break-words ">
                  {room?.roomDescription}
                </p>
              </div>
            </div>

            <div className="flex xl:w-[40%] w-full justify-end  items-center gap-y-2 mt-5 z-10">
              <div className="flex justify-center items-center gap-x-2">
                <p className="flex items-center gap-1 text-[#002244] text-xl font-semibold ">
                  {room?.persons_num} <IoIosPerson className="h-5 w-5 " />
                </p>
                <p className="flex items-center gap-1 text-cyan-700 text-xl font-semibold ">
                  {room?.children_num} <CgBoy className="h-5 w-5 text-cyan-700" />
                </p>
                <p className="flex items-end gap-x-1  text-xl text-[#002244] font-semibold ">
                  {" "}
                  {room?.original_price}{" "}
                  <span className=" md:text-md text-sm font-semibold text-yellow-600">{room?.search_currency}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}

      {RoomArray?.length === 0 && (
        <Alert color="warning" icon={HiInformationCircle}>
          <span className="font-medium">Info alert!</span> No Rooms Available for this contract data.
        </Alert>
      )}
    </>
  );
}
