import React, { useEffect } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import Select from "react-select";
import { FieldErrorText } from "../../../../../customComponents/FormComponents";

const ContractAssistanceAdultsSelector = () => {
  const { values, setFieldValue } = useFormikContext();
  const { rooms } = values;
  const maxAdultsInAllRooms = rooms.map((room) => room.maxAdults).sort((a, b) => b - a)[0];
  const maxAdultsSelectOptions = Array.from({ length: maxAdultsInAllRooms || 10 }, (_, i) => ({
    label: i + 1,
    value: i + 1,
  }));

  useEffect(() => {
    if (rooms.length === 0) {
      setFieldValue("adults", []);
    }
  }, [rooms.length, setFieldValue]);

  return (
    <div className="">
      <h2 className="text-gray-800 font-semibold">Number of Adults</h2>
      <Select
        isDisabled={rooms.length === 0}
        name={"adults"}
        id={"adults"}
        value={rooms.length === 0 ? [] : values.adults.map((item) => ({ label: item, value: item }))}
        styles={{
          container: (provided) => ({
            ...provided,
            marginTop: "4px",
          }),
        }}
        options={maxAdultsSelectOptions}
        placeholder={"Select number of adults"}
        isMulti={true}
        onChange={(selected, actionMeta) => {
          if (actionMeta.action === "select-option") {
            setFieldValue(
              "adults",
              selected.map((item) => item.value),
            );
          } else if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
            setFieldValue(
              "adults",
              selected.map((item) => item.value),
            );
          } else if (actionMeta.action === "clear") {
            setFieldValue("adults", []);
          }
        }}
      />
      <ErrorMessage name="adults" render={(msg) => <FieldErrorText message={msg} />} />
    </div>
  );
};

export default ContractAssistanceAdultsSelector;
