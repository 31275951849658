import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import { PiNoteFill } from "react-icons/pi";
import lightToast from "light-toast";
import { BsCalendarWeek, BsCollection } from "react-icons/bs";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { FaWhatsapp, FaWpforms } from "react-icons/fa6";
import TopBarProgress from "react-topbar-progress-indicator";
import { GoPerson } from "react-icons/go";
import PhoneInput from "react-phone-number-input";
import { addCollectorAction } from "../../../redux/actions/collectorsAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const AddCollectors = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const { bankTransferFlag } = useSelector((state) => state.bookingListReducer);
  const department = [
    { value: "all", name: "All" },
    { value: "hotels_booking", name: "Hotels Booking" },
    { value: "airlines_ticketing ", name: "Airlines Ticketing" },
  ];

  let departmentAll = department.map((item) => {
    return { value: item.value, label: item.name };
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("collector_mobile", phoneNumber);
    formData.append("collector_whatsapp", phoneNumber);
    setLoad(true);
    dispatch(
      addCollectorAction(token, formData, (result) => {
        if (result.status === 201) {
          setLoad(false);
          lightToast.success("Add Collector was completed successfully");
          navigate(-1);
        }
      }),
    );
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.add_collector"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////

  return (
    <div className="formPage-wrap">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {selectedSingleRoom && permissionFlag ? (
          permission.map((item) => {
            if (item.name === "room_booking.add_collector" && item.value === true) {
              return (
                <div key={item.id} className=" w-full ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      {" "}
                      <FaWpforms className="w-5 h-5" />
                      Add Collectors Form
                    </h2>
                  </div>
                  <form className="form-wrap" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <div className="flex w-full max-sm:flex-col gap-4">
                        <div className="flex-1 max-sm:w-full">
                          <label className=" text-center w-full text-gray-600   ">Collector Name</label>
                          <div className="w-full relative text-gray-600">
                            <input
                              placeholder="Collector Name"
                              name="collector_name"
                              type="text"
                              required
                              className=" w-full px-8 h-10  border rounded-lg bg-white "
                            />
                            <div className="absolute top-[11px] left-2">
                              <GoPerson className="w-5 h-5 text-gray-400" />
                            </div>
                          </div>
                        </div>
                        <div className="flex-1 max-sm:w-full">
                          <label className=" flex text-gray-600 ">Collector Department</label>
                          <div className="w-full relative text-gray-600">
                            <Select
                              name="collector_department"
                              placeholder="Collector Department"
                              options={departmentAll}
                              onChange={(e) => {}}
                              required
                              isSearchable
                              className=" w-full pl-8 h-10  border rounded-lg  bg-white capitalize"
                            />
                            <div className="absolute top-[11px] left-2">
                              <BsCollection className="w-5 h-5 text-gray-400" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex w-full max-sm:flex-col gap-4">
                        <div className="flex-1 max-sm:w-full">
                          <label className=" flex text-gray-600 ">Collector Mobile</label>

                          <PhoneInput
                            placeholder="Phone Number"
                            type="text"
                            required
                            defaultCountry="EG"
                            className=" w-full px-2 h-10  border rounded-lg bg-white "
                            onChange={setPhoneNumber}
                          />
                        </div>
                        <div className="flex-1 max-sm:w-full">
                          <label className=" flex text-gray-600 ">Collector Whatsapp</label>
                          <div className=" w-full flex flex-col justify-center items-start px-2 h-10  border rounded-md floating-input bg-white ">
                            <div className=" flex items-center gap-x-1  ">
                              <FaWhatsapp className="w-5 h-5 text-gray-400" />
                              {phoneNumber ? phoneNumber : <p className="text-gray-600">Collector Whatsapp</p>}
                            </div>
                          </div>
                        </div>
                      </div>

                      <label className="flex justify-center text-gray-600 ">Joining Date</label>
                      <div className="w-full relative ">
                        <input
                          placeholder="Joining Date"
                          name="joining_date"
                          type="date"
                          required
                          className=" w-full px-9 h-10  border rounded-lg floating-input bg-white text-gray-600"
                        />
                        <div className="absolute top-[11px] left-2">
                          <BsCalendarWeek className="w-5 h-5 text-gray-400" />
                        </div>
                      </div>
                      <label className=" flex text-gray-600 ">Notes *</label>
                      <div className="w-full relative ">
                        <textarea
                          name="notes"
                          type="text"
                          required
                          className=" w-full px-8  py-2 h-12  border rounded-lg bg-white "
                        />
                        <div className="absolute top-[11px] left-2">
                          <PiNoteFill className="w-5 h-5 text-gray-400" />
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <button type="Submit" disabled={load} className="addButton">
                          Add{" "}
                          {load ? (
                            <>
                              <TopBarProgress />
                              <ButtonLoading />
                            </>
                          ) : (
                            <IoAdd className="w-5 h-5" />
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              );
            } else {
              return (
                <div className="w-full h-screen ">
                  <NoPermission />
                </div>
              );
            }
          })
        ) : (
          <>
            <TopBarProgress /> <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default AddCollectors;
