import { AREADATA, DESTINATIONDATA, HOTEL_CITY_DATA, HOTELDATA, NATIONALITY } from "../types/types";
import api_token from "../../api/UserApi";

export const destinationAction = (token, lang, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("/search/api/v1/get-cities/", {
        headers: { "Accept-Language": lang },
      });
      dispatch({
        type: DESTINATIONDATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};
// this action to send guest code to get specific destination
export const updatedDestinationAction = (token, lang, code, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(
        code ? `/search/api/v1/get-cities-search/?unique_code=${code}` : `/search/api/v1/get-cities-search/`,
        {
          headers: { "Accept-Language": lang },
        },
      );
      dispatch({
        type: DESTINATIONDATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const areaAction = (token, destination, lang, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("/search/api/load-areas/", {
        params: { city_name: destination },
        headers: { "Accept-Language": lang },
      });
      dispatch({
        type: AREADATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

// this action to send guest code to get specific area
export const updatedAreaAction = (token, destination, lang, code, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(
        code ? `/search/api/load-areas/?unique_code=${code}` : "/search/api/load-areas/",
        {
          params: { city_name: destination },
          headers: { "Accept-Language": lang },
        },
      );
      dispatch({
        type: AREADATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const hotelAction = (token, area, lang, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("search/api/load-hotels/", {
        params: { area_name: area },
        headers: { "Accept-Language": lang },
      });
      dispatch({
        type: HOTELDATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const hotelSearchAreaAction = (token, area, lang, callback, cityId) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("search/api/load-hotels-many/", {
        params: { area: area, city: cityId },
        headers: { "Accept-Language": lang },
      });
      dispatch({
        type: HOTELDATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

// this action to send guest code to get specific hotel
export const updatedHotelAction = (token, area, lang, code, callback, cityId) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(
        code ? `/search/api/load-hotels-many-search/?unique_code=${code}` : "/search/api/load-hotels-many-search/",
        {
          params: { area: area, city: cityId },
          headers: { "Accept-Language": lang },
        },
      );
      dispatch({
        type: HOTELDATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const hotelArea = (token, area, lang, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`search/api/load-hotels-v2/?area_name=${area}`, {
        headers: { "Accept-Language": lang },
      });

      callback(response);
    } catch (error) {}
  };
};

export const getHotelCityAction = (token, city, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/hotels/api/v1/hotel-filter/?city=${city}`);
      dispatch({
        type: HOTEL_CITY_DATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const getNationalityAction = (token, lang, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("site-settings/api/countries-flags", {
        headers: { "Accept-Language": lang },
        // { signal }
      });
      dispatch({
        type: NATIONALITY,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

// this action to send guest code to get specific nationalities
export const updatedNationalityAction = (token, lang, code, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(
        code
          ? `/site-settings/api/search/countries-flags?unique_code=${code}`
          : "/site-settings/api/search/countries-flags",
        {
          headers: { "Accept-Language": lang },
          // { signal }
        },
      );
      dispatch({
        type: NATIONALITY,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

// this action to send guest code to get specific markup

export const updateMarkupAction = (token, code, callback, lang) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        code ? `/markup/api/search/?unique_code=${code}` : `/markup/api/search/`,
        { headers: { "Accept-Language": lang } },
      );
      callback(response);
    } catch (error) {}
  };
};

export const getCountryHotelAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("en/hotels/api/v1/get-hotel-countries/");

      callback(response);
    } catch (error) {}
  };
};
