import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { useSelector } from "react-redux";
import moment from "moment";
import { IoMoonOutline } from "react-icons/io5";
import "./calendar.css";
import { useTranslation } from "react-i18next";
import "moment/locale/ar"; // Import Arabic locale
import "moment/locale/en-gb"; // Import English locale
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateRangePickerComponent = ({
  setFormCheckIn,
  setFormCheckOut,
  setNights,
  calendarDashboard,
  dashboardSearch,
  dashboardSearchDate,
  setDashboardSearchDate,
}) => {
  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer);

  const [startDate, setStartDate] = useState(
    dataFromSearchForReseve.length === 0 ? null : moment(dataFromSearchForReseve.from_date),
  );
  const [endDate, setEndDate] = useState(
    dataFromSearchForReseve.length === 0
      ? null
      : dataFromSearchForReseve
        ? moment(dataFromSearchForReseve && dataFromSearchForReseve.to_date)
        : null,
  );
  const [focusedInput, setFocusedInput] = useState(null);
  const [numberOfDays, setNumberOfDays] = useState();
  const [t, i18n] = useTranslation();
  useEffect(() => {
    if (dataFromSearchForReseve.length !== 0) {
      setStartDate(moment(dataFromSearchForReseve.from_date));
      setEndDate(moment(dataFromSearchForReseve.to_date));
      setNumberOfDays(dataFromSearchForReseve ? dataFromSearchForReseve.nights : 0);
    }
  }, [dataFromSearchForReseve]);
  moment.locale(dashboardSearch ? "en" : t("lan"));
  const localeCode = t("localeCode");

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleDateChange = ({ startDate, endDate }) => {
    dashboardSearchDate && setDashboardSearchDate(false);
    const inputDate = new Date(startDate);
    const inputDate2 = new Date(endDate);
    const formattedStartDate = formatDate(inputDate);
    const formattedEndDate = formatDate(inputDate2);
    setStartDate(startDate);

    setFormCheckIn(formattedStartDate);
    setEndDate(endDate);
    setFormCheckOut(formattedEndDate);

    if (startDate && endDate) {
      const days = moment(endDate).diff(startDate, "days");
      setNumberOfDays(days);
      setNights(days);
    }
  };

  const handleDaysChange = (event) => {
    const days = parseInt(event.target.value, 10);
    setNumberOfDays(days);
    setNights(days);

    if (startDate && days) {
      const newEndDate = moment(startDate).add(days, "days");
      const inputnewEndDate = new Date(newEndDate);
      const formattednewEndDate = formatDate(inputnewEndDate);
      setEndDate(newEndDate);
      setFormCheckOut(formattednewEndDate);
    }
  };
  // const weekDays = ["SUNDAY", "MONDAY", "TU", "WE", "TH", "FR", "SA"];
  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
  }, [t("lan")]);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      try {
        // Check if the screen width is "md" based on Tailwind CSS classes
        if (window.innerWidth < 1024) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      } catch (error) {}
    };

    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);

    // Initial check when the component is mounted
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //

  // const [openModal, setOpenModal] = useState(false);
  // const [FocusedInputModal, setFocusedInputModal] = useState("startDate");
  // const handleopenModal = () => {
  //   setOpenModal((prevOpenModal) => !prevOpenModal);
  //   setFocusedInputModal("startDate");
  // };

  // ... (previous code)

  // const handleDoneClick = () => {
  //   setOpenModal((prevOpenModal) => !prevOpenModal);
  // };

  // useEffect(() => {
  //   if (FocusedInputModal === null) {
  //     setFocusedInputModal("startDate");
  //   }
  // }, [FocusedInputModal]);

  //

  const isEndDateBlocked = (day) => {
    return startDate && day.isSame(startDate, "day");
  };

  return (
    <div className="sm:w-fit  w-full ">
      <div className="flex flex-col sm:flex-row items-center justify-between sm:gap-5 w-full  ">
        <div
          className={`w-full lg:flex lg:items-center justify-center   gap-x-2 border-gray-400 ${
            calendarDashboard ? "" : "border-b "
          }`}
        >
          {!open && !calendarDashboard && (
            <p className="text-[#002244]  font-bold">{calendarDashboard ? "checkIn" : t("checkIn")}</p>
          )}
          {open && (
            <div className=" flex gap-6 justify-center ">
              <p className="text-[#002244]  font-bold">{t("lan") === "en" ? "Check-In" : "موعد الذهاب"}</p>
              <p className="text-[#002244]  font-bold">{t("lan") === "en" ? "Check-Out" : "موعد العودة"}</p>
            </div>
          )}
          <div className={`${dashboardSearchDate && "flex justify-center"} `}>
            <DateRangePicker
              required
              startDate={
                dashboardSearchDate
                  ? null
                  : startDate
                    ? startDate
                    : Object.keys(dataFromSearchForReseve).length === 0
                      ? null
                      : moment(dataFromSearchForReseve.from_date)
              }
              endDate={
                dashboardSearchDate
                  ? null
                  : endDate
                    ? endDate
                    : Object.keys(dataFromSearchForReseve).length === 0
                      ? null
                      : moment(dataFromSearchForReseve.to_date)
              }
              // startDateId="start_date"
              // endDateId="end_date"
              onDatesChange={handleDateChange}
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              startDatePlaceholderText={dashboardSearch ? "Check-In" : t("lan") === "en" ? "Check-In" : "موعد الذهاب"}
              endDatePlaceholderText={dashboardSearch ? "Check-Out" : t("lan") === "en" ? "Check-Out" : "موعد العودة"}
              displayFormat={dashboardSearch ? "DD/MM/YYYY" : t("lan") === "en" ? "DD/MM/YYYY" : "YYYY/MM/DD"}
              dateFormat="yyyy-MM-dd"
              showDefaultInputIcon={!calendarDashboard}
              // orientation={open ? "vertical" : "horizontal"}
              minDate={moment().startOf("day")}
              minimumNights={0}
              noBorder
              language={calendarDashboard ? "en" : dashboardSearch ? "en" : t("lan") === "en" ? "en" : "ar"}
              isRTL={dashboardSearch ? false : t("lan") === "en" ? false : true}
              // verticalHeight={280} // Adjust the height according to your layout
              // numberOfMonths={2} // Set this to 1 to display only one calendar when open
              verticalBorderSpacing={50} // Adjust the spacing between months
              small
              autofocus
              withFullScreenPortal={open ? true : false}
              orientation={open ? "verticalScrollable" : "horizontal"}
              numberOfMonths={open ? 6 : 2}
              daySize={open ? 42 : 40}
              verticalHeight={800}
              // weekDayFormat={t("lan") === "en" ? "ddd" : "dddd"}
              weekDayFormat="ddd"
              noNavButtons
              isDayBlocked={isEndDateBlocked}
            />
          </div>
        </div>
        {calendarDashboard ? (
          <div className=" w-full sm:w-fit flex items-center   gap-x-2">
            <IoMoonOutline className="w-8 h-8 text-[#002244]  " />

            <p className="text-[#002244] font-semibold"> Nights </p>

            <input
              type="number"
              className="w-full md:w-14 h-9 text-center text-gray-500 rounded-md border-gray-300 border"
              value={numberOfDays}
              onChange={handleDaysChange}
              // placeholder="Number of Days"
              min="1"
              required
            />
          </div>
        ) : (
          <div className=" w-full sm:w-fit  flex items-center justify-between py-1 gap-x-2">
            <p className="text-[#002244]  font-bold"> {t("nights")} </p>

            <input
              type="number"
              className="w-max md:w-14 h-9 text-center text-gray-500 rounded-md border-gray-300 border"
              value={numberOfDays}
              onChange={handleDaysChange}
              // placeholder="Number of Days"
              min="1"
              required
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangePickerComponent;
