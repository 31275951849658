import { Button, Modal } from "flowbite-react";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

function DeletePackageModal({ packageID, roomID }) {
  const [openModal, setOpenModal] = useState(false);
  const queryClient = useQueryClient();

  const { isPending, mutate: deletePackage } = useMutation({
    mutationFn: () => {
      return mutateDataQuery(`/en/rooms/api/v1/room-package-occupancy/${packageID}/`, "delete");
    },
    mutationKey: "deletePackage",
    onSuccess: () => {
      toast.success("Package Deleted Successfully");
      setOpenModal(false);
      queryClient.refetchQueries({
        queryKey: ["room_packages", roomID],
      });
    },
  });

  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        className="w-8 h-8 rounded-full   bg-red-800 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-red-800"
      >
        <MdDelete />
      </button>
      <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {`Are you sure you want to delete package ${packageID} ?`}
            </h3>
            <div className="flex justify-center gap-4">
              <Button disabled={isPending} isProcessing={isPending} color="failure" onClick={deletePackage}>
                {"Yes"}
              </Button>
              <Button color="gray" disabled={isPending} onClick={() => setOpenModal(false)}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeletePackageModal;
