import { GET_CURENCY_RATE_HISTORY_DETAIL, GET_CURRENCY_RATES, GET_CURRENCY_RATES_HISTORY } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getCurrencyRatesSuccess(data) {
  return {
    type: GET_CURRENCY_RATES,
    payload: data,
  };
}

function getCurrencyRatesHistorySuccess(data) {
  return {
    type: GET_CURRENCY_RATES_HISTORY,
    payload: data,
  };
}

function getCurrencyHistoryRatesDetailsSuccess(data) {
  return {
    type: GET_CURENCY_RATE_HISTORY_DETAIL,
    payload: data,
  };
}

export function getCurrencyRates() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/site-settings/api/exhange-rate/reshape`);
      dispatch(getCurrencyRatesSuccess(response.data));
    } catch (error) {}
  };
}

export function updateCurrencyRates(data, callback) {
  return async function () {
    try {
      const response = await api_token(token).post(`/en/site-settings/api/exhange-rate/create-update/`, data);
      if (response.status === 200 && callback) {
        callback();
      }
    } catch (error) {}
  };
}

export function getCurrencyRatesHistory(page, pageSize, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/history/api/exchange-rate/?page_size=${pageSize}&page=${page}`);
      dispatch(getCurrencyRatesHistorySuccess(response.data));
      if (response && callback) {
        callback();
      }
    } catch (error) {}
  };
}

export function getCurrencyHistoryRatesDetails(id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/history/api/exchange-rate/${id}/`);
      dispatch(getCurrencyHistoryRatesDetailsSuccess(response.data));
      if (response) {
        callback();
      }
    } catch (error) {}
  };
}
