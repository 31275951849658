import React, { useEffect, useState } from "react";
import useHotelNames from "../../../customHooks/useHotelNames";
import useData from "../../../customHooks/useData";
import Select from "react-select";
import { FaUndoAlt, FaWpforms } from "react-icons/fa";
import usePermissions from "../../../customHooks/usePermissions";
import { useFormik } from "formik";
import { editRoomValidationSchema } from "./editRoomValidationSchema";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { IoAdd } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { deleteFormData, editFormData } from "../../../redux/actions/sendDataAction";
import { useNavigate, useParams } from "react-router";
import Loading from "../Loading/Loading";
import NoPermission from "../NoPermission/NoPermission";
import { editRoom_URL, roomFeatures_URL } from "../../../urls";
import lightToast from "light-toast";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { TiDelete } from "react-icons/ti";
import PopUp from "../PopUp/PopUp";
import TopBarProgress from "react-topbar-progress-indicator";
import FileUploader from "../FileUploader/FileUploader";
import { toast } from "react-toastify";

export function returnSVG(elem) {
  if (elem.tagName === "svg") {
    return elem;
  } else {
    return elem.parentNode;
  }
}

export default function EditRoom() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hotels = useHotelNames();
  const features = useData(roomFeatures_URL);
  const room = useData(`/en/rooms/api/v1/room-detail-id/${id}/`);
  const [permissions, permissionsFlag] = usePermissions(["rooms_management.change_rooms"]);
  const [deletedImgs, setDeletedImgs] = useState([]);
  const [imgToggle, setImgToggle] = useState();
  const [chosenExtra, setChosenExtra] = useState(null);
  const hotelsArr = hotels ? hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })) : [];
  const featuresArr = features ? features.map((feature) => ({ label: feature.feature, value: feature.id })) : [];
  const extraInfo = useData("/rooms/api/v1/room-extra-info");
  const extraInfArr = extraInfo ? extraInfo.map((info) => ({ label: info.name, value: info.id })) : [];
  const [isOpen, setIsOpen] = useState(false);
  const [fetaureData, setFeatures] = useState([]);

  function closeModal() {
    setIsOpen(false);
  }

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues: {
      hotel_name: "",
      room_name: "",
      room_name_ar: "",
      description: "",
      description_ar: "",
      space: "",
      max_adults: "",
      max_children: "",
      max_occupancy: "",
      view: "",
      view_ar: "",
      balcony: "",
      features: [],
      ordering: "",
      room_images: [],
      extra_info: [],
    },
    validationSchema: editRoomValidationSchema,
    onSubmit: submit,
  });

  useEffect(() => {
    if (room) {
      setFieldValue("hotel_name", room.hotel_id);
      setFieldValue("room_name", room.room_name);
      setFieldValue("room_name_ar", room.room_name_ar);
      setFieldValue("description", room.description);
      setFieldValue("description_ar", room.description_ar);
      setFieldValue("space", room.space);
      setFieldValue("view", room.view);
      setFieldValue("view_ar", room.view_ar);
      setFieldValue("balcony", room.balcony);
      setFieldValue(
        "features",
        room?.features?.map((feat) => ({ label: feat.feature, value: feat.id })),
      );
      setFieldValue("ordering", room.ordering);
      setFieldValue("max_adults", room.max_adults);
      setFieldValue("max_children", room.max_children);
      setFieldValue("max_occupancy", room.max_occupancy);
      setFieldValue(room?.extra_info?.map((info) => ({ label: info.name, value: info.id })));
      setFieldValue(
        "extra_info",
        room?.extra_info?.map((info) => info.id),
      );
      setChosenExtra(room?.extra_info?.map((info) => ({ label: info.name, value: info.id })));
    }
  }, [room]);

  function submit(values) {
    const { extra_info, room_images, features, ...restOfValues } = values;
    // Convert room_images to an array
    const roomImagesArray = Array.from(room_images);

    for (const image of room_images) {
      if (!image.valid) {
        toast.error(`Image ${image.name} is not valid because ${image.errors[0]}`);
        setSubmitting(false);
        return;
      }
    }

    const formData = new FormData();
    if (extra_info?.length > 0) {
      formData.append("extra_info", `[${extra_info}]`);
    } else {
      formData.append("extra_info", "[]");
    }

    if (features.length > 0) {
      formData.append("features", `[${features?.map((i) => i.value)}]`);
    } else {
      formData.append("features", "[]");
    }
    let formDataContent = "";
    formData.forEach((value, key) => {
      formDataContent += `${key}: ${value}\n`;
    });

    // alert(formDataContent);
    room_images.forEach((image) => formData.append("room_images", image.file));
    Object.keys(restOfValues).forEach((item) => formData.append(`${item}`, restOfValues[item]));
    dispatch(
      editFormData(
        `${editRoom_URL}${id}/`,
        formData,
        (resp) => {
          dispatch(
            deleteFormData(
              `${editRoom_URL}${id}/`,
              {
                data: { image_ids: deletedImgs },
              },
              () => {
                navigate(`/dashboard/room-details/${resp.data.id}`);
                lightToast.success("Your room was edited successfully");
                setSubmitting(false);
              },
              () => {
                NotificationManager.error("There was an error In your Room Please try again later");
                setSubmitting(false);
              },
            ),
          );
        },
        (error) => {
          NotificationManager.error("There was an error In your Room Please try again later");
          setSubmitting(false);
        },
      ),
    );
  }

  function removeImg(e, imgObj) {
    // check if the user deleted all images to make the image input required
    // the state here will reflect the state right before changing so we must add 1
    if (deletedImgs.length + 1 === room.room_images.length && values.room_images === "") {
      NotificationManager.error("you can't delete all images unless you append an image first");
    } else {
      const svg = returnSVG(e.target);
      svg.style.display = "none";
      svg.nextSibling.style.display = "block";
      setDeletedImgs([...deletedImgs, imgObj.id]);
    }
  }

  function undoRemoveImg(e, imgObj) {
    const svg = returnSVG(e.target);
    svg.style.display = "none";
    svg.previousSibling.style.display = "block";
    const deletedImgsArr = deletedImgs.slice(0);
    setDeletedImgs(
      deletedImgsArr.filter((id) => {
        return id !== imgObj.id;
      }),
    );
  }

  return (
    <div className="formPage-wrap">
      {permissionsFlag ? (
        permissions[0].value ? (
          <div className="w-full">
            {(!hotels || !features || !room) && <Loading />}
            {hotels && features && room && (
              <div className=" w-full ">
                <div className=" flex justify-center ">
                  <p className="header-h2">
                    <FaWpforms className="w-5 h-5" />
                    Edit Room
                  </p>
                </div>
                <form className="form-wrap w-full" onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="w-full flex flex-col justify-center items-center gap-y-2">
                    <label className=" text-center w-full text-gray-600 font-semibold">Hotel Name</label>
                    <Select
                      name="hotel_name"
                      options={hotelsArr}
                      value={hotelsArr.filter((hotel) => hotel.value === values.hotel_name)[0]}
                      isSearchable
                      className={`w-full  h-10  rounded-lg  bg-white ${
                        errors.hotel_name && touched.hotel_name && "border border-red-900"
                      }`}
                      placeholder="Currency"
                      onChange={(e) => setFieldValue("hotel_name", e.value)}
                      onBlur={handleBlur}
                    />
                    {errors.hotel_name && touched.hotel_name && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.hotel_name}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Room Name</label>
                    <input
                      placeholder="Room Name"
                      name="room_name"
                      type="text"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.room_name && touched.room_name && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.room_name}
                    />
                    {errors.room_name && touched.room_name && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.room_name}
                      </div>
                    )}

                    <label className="text-center w-full text-gray-600 font-semibold">Room Arabic Name</label>
                    <input
                      placeholder="Room Arabic Name"
                      name="room_name_ar"
                      type="text"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.room_name_ar && touched.room_name_ar && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.room_name_ar}
                    />
                    {errors.room_name_ar && touched.room_name_ar && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.room_name_ar}
                      </div>
                    )}

                    <label className="flex text-gray-600 font-semibold">Description</label>
                    <textarea
                      name="description"
                      type="text"
                      className={`w-full p-3 border rounded-lg bg-white ${
                        errors.description && touched.description && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    {errors.description && touched.description && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.description}
                      </div>
                    )}

                    <label className="flex text-gray-600 font-semibold">Arabic Description</label>
                    <textarea
                      name="description_ar"
                      type="text"
                      className={`w-full p-3 border rounded-lg bg-white ${
                        errors.description_ar && touched.description_ar && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description_ar}
                    />
                    {errors.description_ar && touched.description_ar && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.description_ar}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Space</label>
                    <input
                      placeholder="Space"
                      name="space"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.space && touched.space && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.space}
                    />
                    {errors.space && touched.space && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.space}</div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Max Adults</label>
                    <input
                      placeholder="Max Adults"
                      name="max_adults"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.max_adults && touched.max_adults && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.max_adults}
                    />
                    {errors.max_adults && touched.max_adults && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.max_adults}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Max Children</label>
                    <input
                      placeholder="Max Children"
                      name="max_children"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.max_children && touched.max_children && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.max_children}
                    />
                    {errors.max_children && touched.max_children && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.max_children}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Max Occupancy</label>
                    <input
                      placeholder="Max Occupancy"
                      name="max_occupancy"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.max_occupancy && touched.max_occupancy && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.max_occupancy}
                    />
                    {errors.max_occupancy && touched.max_occupancy && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.max_occupancy}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">View</label>
                    <input
                      placeholder="View"
                      name="view"
                      type="text"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.view && touched.view && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.view}
                    />
                    {errors.view && touched.view && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.view}</div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Arabic View</label>
                    <input
                      placeholder="Arabic View"
                      name="view_ar"
                      type="text"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.view_ar && touched.view_ar && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.view_ar}
                    />
                    {errors.view_ar && touched.view_ar && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.view_ar}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Features</label>
                    <Select
                      name="features"
                      options={featuresArr}
                      isSearchable
                      isMulti
                      className={`w-full  h-10  rounded-lg  bg-white ${
                        errors.features && touched.features && "border border-red-900"
                      }`}
                      placeholder="features"
                      onChange={(selectedOptions) => {
                        // Get the updated array of values for features
                        const updatedFeatures = selectedOptions?.map((option) => option);
                        const updatedFeatures2 = selectedOptions?.map((option) => option.value);
                        // Update formik values directly with the updated features array
                        setFieldValue("features", updatedFeatures);
                        setFeatures(updatedFeatures2);
                      }}
                      onBlur={handleBlur}
                      value={values?.features?.map((feature) => feature)}
                    />
                    {errors.features && touched.features && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.features}
                      </div>
                    )}
                    <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                      Extra Info
                    </label>
                    <Select
                      name="extra_info"
                      options={extraInfArr}
                      value={chosenExtra}
                      isSearchable
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: `${errors.extra_info && touched.extra_info && "rgb(135 27 27)"}`,
                        }),
                      }}
                      className={`w-full  h-10  rounded-lg  bg-white capitalize `}
                      isMulti
                      placeholder="Room Extra Info"
                      onChange={(selectedOptions) => {
                        setChosenExtra(selectedOptions);
                        const updatedExtra = selectedOptions.map((option) => option.value);
                        setFieldValue("extra_info", updatedExtra);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.extra_info && touched.extra_info && (
                      <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                        {errors.extra_info}
                      </div>
                    )}
                    <label className="text-center w-full text-gray-600 font-semibold">Balcony</label>
                    <div
                      className={`flex items-center justify-center gap-x-10  h-10 w-full p-3 border rounded-lg bg-white ${
                        errors.balcony && touched.balcony && "border-red-900"
                      }`}
                    >
                      <div className="flex items-center justify-center gap-x-3 w-[50%]">
                        <label className="text-gray-600 text-lg">Yes</label>
                        <input
                          placeholder="Employee Name"
                          name="balcony"
                          type="radio"
                          value="Yes"
                          className=" w-5 h-5  border rounded-lg bg-white "
                          onChange={(e) => setFieldValue("balcony", e.target.value)}
                          onBlur={handleBlur}
                          checked={values.balcony === "Yes"}
                        />
                      </div>
                      <div className="flex items-center justify-center gap-x-3 w-[50%]">
                        <label className="text-gray-600 text-lg">No</label>
                        <input
                          placeholder="Total Amount"
                          name="balcony"
                          type="radio"
                          value="No"
                          className=" w-5 h-5  border rounded-lg bg-white font-semibold"
                          onChange={(e) => setFieldValue("balcony", e.target.value)}
                          onBlur={handleBlur}
                          checked={values.balcony === "No"}
                        />
                      </div>
                    </div>
                    {errors.balcony && touched.balcony && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.balcony}
                      </div>
                    )}
                    <label className=" text-center w-full text-gray-600 font-semibold">Arrange Rooms</label>
                    <input
                      placeholder="Arrange Rooms"
                      name="ordering"
                      type="number"
                      value={values.ordering}
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.ordering && touched.ordering && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.ordering && touched.ordering && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.ordering}
                      </div>
                    )}
                    <label className=" text-center w-full text-gray-600 font-semibold">Upload Images</label>
                    <div
                      className={` w-full  border rounded-md   bg-white ${
                        errors.room_images && touched.room_images && "border border-red-900"
                      }`}
                    >
                      <FileUploader
                        accept={"image/*"}
                        label={"Drag and drop room images here or click to browse"}
                        files={values.room_images}
                        setFiles={(files) => {
                          setFieldValue("room_images", files);
                        }}
                      />
                    </div>
                    {errors.room_images && touched.room_images && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.room_images}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Old Images</label>
                    <div className="w-full grid grid-cols-6 gap-2 border rounded-md overflow-auto h-32 py-1">
                      {room.room_images &&
                        room?.room_images?.map((imgObj, index) => {
                          return (
                            <div key={index}>
                              <TiDelete
                                className="w-5 h-5 text-red-800 cursor-pointer"
                                onClick={(e) => removeImg(e, imgObj)}
                              />
                              <FaUndoAlt style={{ display: "none" }} onClick={(e) => undoRemoveImg(e, imgObj)} />
                              <img
                                src={`${imgObj?.image}`}
                                className="w-24 h-24 cursor-pointer"
                                onClick={() => {
                                  setIsOpen(true);
                                  setImgToggle(`${imgObj.image}`);
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="flex justify-center mt-6">
                    <button type="Submit" disabled={isSubmitting} className="updateButton">
                      Edit Room{" "}
                      {isSubmitting ? (
                        <>
                          {" "}
                          <TopBarProgress />
                          <ButtonLoading />{" "}
                        </>
                      ) : (
                        <IoAdd className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}
            <NotificationContainer />
          </div>
        ) : (
          <NoPermission />
        )
      ) : (
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress />
          <Loading />
        </div>
      )}
      <PopUp close={closeModal} isOpen={isOpen} image={imgToggle} header={"Room Image"} />
    </div>
  );
}
