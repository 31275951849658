import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineEye, AiOutlineLoading } from "react-icons/ai";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import {
  MdOutlineDescription,
  MdOutlineDriveFileRenameOutline,
  MdOutlinePermIdentity,
  MdOutlinePerson,
  MdOutlineSettings,
  MdPendingActions,
} from "react-icons/md";
import { RiHotelFill } from "react-icons/ri";
import { Link, useSearchParams } from "react-router-dom";
import ListFilters from "../../../utils/ListFilters";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import usePermissions from "../../../customHooks/usePermissions";
import { DetailsModal } from "../../../customComponents/TableComponents";
import { Button, Modal } from "flowbite-react";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { IoLockClosedSharp } from "react-icons/io5";
import { VscGitPullRequestNewChanges } from "react-icons/vsc";

const SpecialRateList = () => {
  const [data, setData] = useState([]);

  const [listPageSize, setListPageSize] = useState(20);
  const [filteredData, setFilteredData] = useState([]);
  const [activeStatus, setActiveStatus] = useState("New");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const totalPages = Math.ceil(data?.count / listPageSize);
  const {
    data: special,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["offer", currentPage, listPageSize],
    queryFn: () =>
      fetchDataQuery(`/sales-management/api/v1/special-rate-requests/?page=${currentPage}&page_size=${listPageSize}`),
    keepPreviousData: true, // Keep the previous data while loading new data
  });

  useEffect(() => {
    const searchParams = {
      page: currentPage,
      pageSize: listPageSize,
    };

    setSearchParams(searchParams);
  }, [currentPage, listPageSize, setSearchParams]);
  const SpecialData = data.results;
  useEffect(() => {
    if (isSuccess) {
      setData(special);
      const initialFilteredTasks = SpecialData?.filter(
        (task) => task.status.toLowerCase() === activeStatus.toLowerCase(),
      );
      setFilteredData(initialFilteredTasks);
    }
  }, [special, isSuccess, listPageSize, currentPage, SpecialData, activeStatus]);

  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "sales_management.view_specialraterequest",
    "sales_management.change_specialraterequest",
    "sales_management.delete_specialraterequest",
  ]);
  const isViewingrequestListAllowed =
    permissions?.find((permission) => permission.name === "sales_management.view_specialraterequest")?.value === true;

  const isEditingrequestListAllowed =
    permissions?.find((permission) => permission.name === "sales_management.change_specialraterequest")?.value === true;

  const isDeletingrequestListAllowed =
    permissions?.find((permission) => permission.name === "sales_management.delete_specialraterequest")?.value === true;

  // this will delete the hotel stored in the state by passing it to the action then remove the popup

  if (!permissionFlag) {
    return <Loading />;
  }

  if (error || !special) {
    <Errorfetch Error={error?.message || "Error Fetching Special rate request "} />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const handleTableChange = (page, size) => {
    setListPageSize(size);
    setCurrentPage(page);
  };
  const handleChange = (value) => {
    setActiveStatus(value);
    setCurrentPage(1);
    const filteredTasks = SpecialData?.filter((task) => task.status.toLowerCase() === value.toLowerCase());

    // Set the filtered tasks to filteredData state
    setFilteredData(filteredTasks);
  };
  const rows =
    filteredData &&
    filteredData?.map((rate) => {
      return {
        id: rate.id,
        city: rate.hotel.city.name,
        area: rate.hotel.area.name,
        room_type: rate?.room_type?.map((i) => i?.room_name),
        hotel: rate.hotel.name,
        number_of_persons: rate.number_of_persons,
        check_in_date: rate.check_in_date,
        check_out_date: rate.check_out_date,
        client_name: rate.client_name,
        client_number: rate.client_number,
        request_details: rate.request_details,
        created_at: rate?.created_at?.slice(0, 10),
        status: rate.status,
      };
    });

  const updateFilteredList = (updatedTaskList) => {
    const filteredTasks = updatedTaskList.filter((task) => task.status.toLowerCase() === activeStatus.toLowerCase());
    setFilteredData(filteredTasks);
  };
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params.id}
          </Link>
        );
      },
      dataIndex: "id",
    },
    {
      key: "city",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlineDriveFileRenameOutline className="w-5 h-5  text-yellow-500" />
          City
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params.city}
          </Link>
        );
      },
      dataIndex: "city",
    },
    {
      key: "hotel",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlineDriveFileRenameOutline className="w-5 h-5  text-yellow-500" />
          Hotel
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params.hotel}
          </Link>
        );
      },
      dataIndex: "hotel",
    },
    {
      key: "room",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlineDescription className="w-5 h-5  text-yellow-500" />
          Room Type
        </div>
      ),
      render: (text, params) => {
        return (
          <div className="w-full h-full  flex  gap-x-1 ">
            {params.room_type.length > 1 ? (
              <DetailsModal title="Rooms Type">
                <div className=" flex flex-col gap-2">
                  {params.room_type?.map((i, idx) => (
                    <p key={idx}>
                      {idx + 1}- {i}
                    </p>
                  ))}
                </div>
              </DetailsModal>
            ) : (
              params.room_type
            )}
          </div>
        );
      },
      dataIndex: "room",
    },
    {
      key: "num_person",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <RiHotelFill className="w-5 h-5  text-yellow-500" />
          Number of persons
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params.number_of_persons}
          </Link>
        );
      },
      dataIndex: "num_person",
    },
    {
      key: "status",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePerson className="w-5 h-5  text-yellow-500" />
          Status
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params.status}
          </Link>
        );
      },
      dataIndex: "status",
    },
    {
      key: "check_in",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePerson className="w-5 h-5  text-yellow-500" />
          Check in Date
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params.check_in_date}
          </Link>
        );
      },
      dataIndex: "check_in",
    },
    {
      key: "check_out",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaRegCalendarAlt className="w-5 h-5  text-yellow-500" />
          Check out Date
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params?.check_out_date}
          </Link>
        );
      },
      dataIndex: "check_out",
    },
    {
      key: "client_name",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaRegCalendarAlt className="w-5 h-5  text-yellow-500" />
          Client Name
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params?.client_name}
          </Link>
        );
      },
      dataIndex: "client_name",
    },
    {
      key: "client_number ",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaRegCalendarAlt className="w-5 h-5  text-yellow-500" />
          Client number
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params?.client_number}
          </Link>
        );
      },
      dataIndex: "client_number ",
    },
    {
      key: "request_details  ",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaRegCalendarAlt className="w-5 h-5  text-yellow-500" />
          Request Details
        </div>
      ),
      render: (text, params) => {
        return (
          <Link
            title={params?.request_details}
            to={`/dashboard/special-rates-requests-list/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
          >
            {params?.request_details.substr(0, 40)}...
          </Link>
        );
      },
      dataIndex: "request_details ",
    },
    {
      key: "created_at",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaRegCalendarAlt className="w-5 h-5  text-yellow-500" />
          Created At
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/special-rates-requests-list/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params?.created_at?.slice(0, 10)}
          </Link>
        );
      },
      dataIndex: "created_at",
    },
    {
      key: "operations",

      title: (
        <div className=" flex items-center w-full justify-center gap-x-1 text-xs ">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            {isViewingrequestListAllowed && (
              <Link to={`/dashboard/special-rates-requests-list/${params.id}`}>
                <AiOutlineEye className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800" />
              </Link>
            )}

            {isEditingrequestListAllowed && (
              <Link to={`/dashboard/special-rates-requests-list-update/${params.id}`}>
                <FiEdit
                  // onClick={() => navigate(`/dashboard/edit-hotel/${params.id}`)}
                  className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
                />
              </Link>
            )}

            {isDeletingrequestListAllowed && (
              <DeleteOfferModal offerId={params.id} offerName={params.name} updateFilteredList={updateFilteredList} />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <section className="formPage-wrap">
      <div className="   w-max  ">
        <div className="w-full flex flex-row max-md:flex-col gap-2 justify-center items-center  ">
          <Button.Group className="flex flex-wrap">
            <Button color={activeStatus === "New" ? "info" : "gray"} onClick={() => handleChange("New")}>
              <span className="flex items-center gap-2">
                <VscGitPullRequestNewChanges />
                New Requests
              </span>
            </Button>
            <Button color={activeStatus === "Pending" ? "info" : "gray"} onClick={() => handleChange("Pending")}>
              <span className="flex items-center gap-2">
                <MdPendingActions />
                Pending Requests
              </span>
            </Button>
            <Button color={activeStatus === "Closed" ? "info" : "gray"} onClick={() => handleChange("Closed")}>
              <span className="flex items-center gap-2 ">
                <IoLockClosedSharp />
                Closed Requests
              </span>
            </Button>
          </Button.Group>
        </div>
      </div>
      <ListFilters
        columns={columns}
        rows={rows}
        title="Special Rate List"
        handlePagination={handleTableChange}
        currentPage={currentPage}
        totalPages={totalPages}
        listPageSize={listPageSize}
      />
    </section>
  );
};

export default SpecialRateList;
const DeleteOfferModal = ({ offerId, offerName, updateFilteredList }) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  const { isPending, mutate: deleteOffer } = useMutation({
    mutationFn: () => mutateDataQuery(`/sales-management/api/v1/special-rate-requests/${offerId}/`, "delete"),
    mutationKey: ["delete-offer", offerId],
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success(`Offer ${offerName} deleted successfully`);

      // Invalidate the query to refetch the list of offers
      queryClient.invalidateQueries(["offer"]);
      updateFilteredList(updatedTaskList.results);

      // Close the modal
      setShowModal(false);
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to delete offer");
    },
  });

  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <>
      <button onClick={() => setShowModal(true)} className="cursor-pointer">
        <AiFillDelete className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-700 hover:text-red-900" />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showModal}
        position={"center"}
        onClose={() => setShowModal(false)}
      >
        <Modal.Body className="flex flex-col py-4 bg-gray-100 rounded-lg gap-8">
          <h2 className="text-xl capitalize font-bold text-gray-900 mb-4 text-center">
            Do you want to delete Offer {offerName}?
          </h2>
          <div className="flex justify-around items-center">
            <Button
              isProcessing={isPending}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              disabled={isPending}
              onClick={() => deleteOffer()}
              color="failure"
            >
              Delete
            </Button>
            <Button disabled={isPending} onClick={() => setShowModal(false)} color="gray">
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
