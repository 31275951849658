import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { MdOutlineDone } from "react-icons/md";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { TbListDetails } from "react-icons/tb";
import TopBarProgress from "react-topbar-progress-indicator";
import { getSelectedPosVisa } from "../../../redux/actions/posVisaAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import PopUp from "../PopUp/PopUp";

const PosVisaDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const { selectetdPosVisa } = useSelector((state) => state.posVisaReducer);
  const { selectetdPosVisaFlag } = useSelector((state) => state.posVisaReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    dispatch(getSelectedPosVisa(token, params.id));
    dispatch(getBankListAction(token));
  }, []);
  let bank = bankData && selectetdPosVisa ? bankData.filter((item) => item.id === selectetdPosVisa.bank) : [];

  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.view_posmachinetransaction"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full overflow-x-auto h-full">
      {selectetdPosVisa && !selectetdPosVisaFlag && permissionFlag ? (
        permissionFlag &&
        permission.map((item) => {
          if (item.name === "accounting.view_posmachinetransaction" && item.value === true) {
            return (
              <div className="container border-gray-300 border rounded-lg  p-10 ">
                <div className="flex flex-col justify-center items-center ">
                  <div className="flex flex-col    w-full  text-black rounded-md  ">
                    <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                      <p className="flex items-center gap-x-3">
                        <TbListDetails className="w-5 h-5" />
                        POS(Visa) Details{" "}
                      </p>
                    </div>
                    {/* Hotel id & id & persons*/}

                    {/* WhatsApp Number & Email*/}
                    <div className="flex w-full justify-between items-center  p-3  ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Amount</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1 ">
                            {selectetdPosVisa.amount}
                            <span>{selectetdPosVisa.currency}</span>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">ID</p>
                        </div>
                        <div>
                          <p className="flex items-center gap-x-1"> {selectetdPosVisa.id}</p>
                        </div>
                      </div>
                    </div>
                    {/* Client text & Client Tag Request*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Commission</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">{selectetdPosVisa.commission} </p>
                          <p className="flex items-center gap-x-1"></p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">MID Number</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">{selectetdPosVisa.mid_number}</p>
                        </div>
                      </div>
                    </div>
                    {/* Hotel & Room Name*/}
                    <div className="flex w-full justify-between items-center  p-3 ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Balance</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">
                            {selectetdPosVisa.balance}
                            <span>{selectetdPosVisa.currency}</span>{" "}
                          </p>
                        </div>
                      </div>

                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Booked Room</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{selectetdPosVisa.booked_room}</p>
                        </div>
                      </div>
                    </div>
                    {/* Client text & Client Tag Request*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Convertion Price </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">
                            {selectetdPosVisa.conversion_rate_price}
                            <span>{selectetdPosVisa.contract_currency}</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Source Name </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">{selectetdPosVisa.source_name}</p>
                        </div>
                      </div>
                    </div>
                    {/* Client text & Client Tag Request*/}
                    <div className="flex w-full justify-between items-center  p-3  ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Currency</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">{selectetdPosVisa.currency} </p>
                          <p className="flex items-center gap-x-1"></p>
                        </div>
                      </div>

                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Bank </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">{bank.map((item) => item.name)}</p>
                          <p className="flex items-center gap-x-1"></p>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Contract Currency</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">{selectetdPosVisa.contract_currency} </p>
                        </div>
                      </div>

                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Created Date</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">{selectetdPosVisa.created_at.slice(0, 10)} </p>
                          <p className="flex items-center gap-x-1"></p>
                        </div>
                      </div>
                    </div>
                    {/* Client & phone Number*/}
                    <div className="flex w-full justify-between items-center  p-3 ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Convertion Rate </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">{selectetdPosVisa.conversion_rate}</p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Transaction Status</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800 capitalize">
                          {selectetdPosVisa.transaction_status === "confirmed" ? (
                            <p className="w-full flex items-center  justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-green-700 font-semibold">
                              {selectetdPosVisa.transaction_status}{" "}
                              <MdOutlineDone className="bg-green-700 border text-white rounded-full  " />
                            </p>
                          ) : (
                            <p className="w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-yellow-600 font-semibold">
                              {selectetdPosVisa.transaction_status}....
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Client & phone Number*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Transactions Receipt</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <button onClick={() => setIsOpen(true)} type="button" className="w-full">
                            <img className="w-36 h-36" src={selectetdPosVisa.transaction_receipt} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return <NoPermission />;
          }
        })
      ) : (
        <>
          {" "}
          <TopBarProgress />
          <div className="w-full h-full flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
      <PopUp close={closeModal} isOpen={isOpen} image={selectetdPosVisa?.transaction_receipt} />
    </div>
  );
};

export default PosVisaDetails;
