import React, { useEffect } from "react";
import Form from "../../organisms/Form/Form";
import "./Login.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";

const Login = () => {
  const { loginError } = useSelector((state) => state.loginReducerUser);
  const location = useLocation();

  useEffect(() => {
    if (location.state === "error") {
      NotificationManager.error("This User is not whitelisted or IP not whitelisted.");
    }
  }, []);
  // when user switch to another user and will redirect to dashboard  if occur error will navigate to login and show this error msg

  return (
    <>
      <div className="flex flex-col items-center ">
        {/* <nav className=" absolute container flex justify-center sm:justify-start  items-center h-24  text-white md:none  ">
          <div>
            <img src="/images/logo2.png" className=" h-36 " />
          </div>
        </nav> */}

        <section className=" flex w-full justify-center cover_img_mobile bg-cover bg-center bg-no-repeat">
          <div className="cover_img h-screen  w-1/2 bg-cover bg-center bg-no-repeat lg:block  hidden"></div>

          <div className=" flex flex-col overflow-hidden h-screen p-5  lg:w-1/2 w-full  justify-center items-center my-auto text-white ">
            {
              loginError && (
                <Alert color="failure" className=" mb-3" icon={HiInformationCircle}>
                  <span className="font-medium">Info alert!</span> {loginError}
                </Alert>
              )

              // <p className="text-red-700 mb-1 font-bold text-xl">{loginError}</p>
            }
            <div className="w-full xl:w-[65%] lg:w-[80%]   ml-[2%] md:shadow-none shadow-2xl  lg:bg-inherit bg-white rounded-2xl  ">
              <Form />
            </div>
          </div>
        </section>
      </div>
      <NotificationContainer />
    </>
  );
};

export default Login;
