import React, { useEffect, useState } from "react";
import { getSelectedMeet } from "../../../redux/actions/meetAssistAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { TbListDetails } from "react-icons/tb";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";

const RepresentativeMemberDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [meetData, setMeetData] = useState();

  useEffect(() => {
    //////////// Cancellation Request ////////////
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(
      getSelectedMeet(params.id, signal, (result) => {
        if (result.status === 200) {
          setMeetData(result.data);
        }
      })
    );
    return () => {
      controller.abort();
    };
  }, []);
  return (
    <div className="container border-gray-300 border rounded-lg  p-10 ">
      {meetData ? (
        <div className="flex flex-col justify-center items-center ">
          <div className="flex flex-col    w-full  text-black rounded-md  ">
            <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
              <p className="flex items-center gap-x-3">
                <TbListDetails className="w-5 h-5" />
                Representative Service{" "}
              </p>
            </div>
            <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="text-sm flex items-center gap-x-1 font-bold"> ID</p>
                </div>
                <div className="w-[70%] font-semibold text-gray-800">
                  <p className="flex items-center gap-x-1">{meetData.id}</p>
                </div>
              </div>
              <div className="flex items-center w-[50%]">
                <div className="w-[40%]  ">
                  <p className="text-sm flex items-center gap-x-1 font-bold"> REPRESENTATIVE NAME</p>
                </div>
                <div className="w-[60%] font-semibold text-gray-800">
                  <p className="flex items-center gap-x-1"> {meetData.representative_name}</p>
                </div>
              </div>
            </div>
            <div className="flex w-full justify-between items-center  p-3  ">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="text-sm flex items-center gap-x-1 font-bold"> USER</p>
                </div>
                <div className="w-[70%] font-semibold text-gray-800">
                  <p className="flex items-center gap-x-1">{meetData?.user?.username}</p>
                </div>
              </div>
              <div className="flex items-center w-[50%]">
                <div className="w-[40%]  ">
                  <p className="text-sm flex items-center gap-x-1 font-bold"> LINCENSES ID NUMBER</p>
                </div>
                <div className="w-[60%] font-semibold text-gray-800">
                  <p className="flex items-center gap-x-1"> {meetData.licenses_id_number}</p>
                </div>
              </div>
            </div>
            <div className="flex w-full justify-between items-center  p-3 bg-gray-200  ">
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[33%]  ">
                  <p className="text-sm flex items-center gap-x-1 font-bold"> MOBILE NUMBER</p>
                </div>
                <div className="w-[70%] font-semibold text-gray-800">
                  <p className="flex items-center gap-x-1">{meetData.mobile_number}</p>
                </div>
              </div>
              <div className="flex  justify-between items-center w-[50%] ">
                <div className="w-[40%]  ">
                  <p className="text-sm flex items-center gap-x-1 font-bold"> CREATED DATE</p>
                </div>
                <div className="w-[60%] font-semibold text-gray-800">
                  <p className="flex items-center gap-x-1">{meetData.created_date.slice(0, 10)}</p>
                </div>
              </div>
              {/* <div className="flex items-center w-[50%]">
                <div className="w-[33%]  ">
                  <p className="text-sm flex items-center gap-x-1 font-bold">BOOKINE ORDER</p>
                </div>
                <div className="w-[70%] font-semibold text-gray-800">
                  <p className="flex items-center gap-x-1"> {meetData.licenses_id_number}</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default RepresentativeMemberDetails;
