import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import PhoneInput from "react-phone-number-input";
import { DatePicker } from "antd";
import Select from "react-select";
import dayjs from "dayjs";
import { useDebounce } from "use-debounce";
import { useQuery } from "@tanstack/react-query";
import { BsThreeDots } from "react-icons/bs";

export const FormSection = ({ title, children }) => {
  return (
    <div className="w-full form-wrap">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className={`w-full `}>
          <div className=" flex justify-center  ">
            <h2 className="header-h2 !w-max px-4 py-2 rounded-lg !bg-[#002244] !text-white">{title.toUpperCase()}</h2>
          </div>

          <div className="space-y-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const FormLabel = ({ label, htmlFor }) => {
  return (
    <label htmlFor={htmlFor} className="flex mb-1 font-semibold text-sm  text-gray-600">
      {label}
    </label>
  );
};

export const FieldErrorText = ({ message }) => {
  return <p className="text-center font-medium tracking-wide text-red-500 text-xs !mt-1">{message}</p>;
};

export const FormPhoneInput = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  function handleChange(value) {
    if (value === undefined) {
      void helpers.setValue("");

      field.onChange("");
      return;
    }

    void helpers.setValue(value);
    field.onChange(value);
  }

  return (
    <div className="w-full">
      <PhoneInput
        international={true}
        country={"EG"}
        withCountryCallingCode={true}
        value={field.value}
        onChange={handleChange}
        onBlur={field.onBlur}
        {...props}
        className={`w-full ${meta.touched && meta.error && "border-red-300"}`}
      />

      {meta.touched && meta.error && meta.error.length > 1 ? <FieldErrorText message={meta.error} /> : null}
    </div>
  );
};

export const FormTextInput = ({ label, type, Icon = {}, className, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full">
      {label && <FormLabel label={label} htmlFor={props.id || props.name} />}
      <div className="w-full relative text-gray-600">
        <input
          id={props.id || props.name}
          className={`w-full px-8 h-10 border rounded-lg bg-white ${meta.touched && meta.error && "border-red-500"} ${className} disabled:bg-gray-100 disabled:text-gray-400  disabled:cursor-not-allowed`}
          type={`${type || "text"}`}
          {...field}
          {...props}
          data-date-format={type === "date" && "DD/MM/YYYY"}
        />
        <div className="absolute top-[11px] left-2">
          <Icon className="w-5 h-5 text-gray-400" />
        </div>
      </div>
      {meta.touched && meta.error && meta.error.length > 1 ? <FieldErrorText message={meta.error} /> : null}
    </div>
  );
};

export const FormCheckboxInput = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div>
      <label className="flex gap-1 items-center justify-center cursor-pointer">
        <input className="cursor-pointer" type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? <FieldErrorText message={meta.error} /> : null}
    </div>
  );
};

export const FormTextAreaInput = ({ label, Icon, ...props }) => {
  const [field, meta] = useField({ ...props });

  return (
    <div className="w-full">
      {label && <FormLabel label={label} htmlFor={props.id} />}

      <div className="w-full relative text-gray-600">
        <Field
          id={props.id || props.name}
          className={` w-full py-2 px-8 border ${meta.touched && meta.error && "border-red-500"}  rounded-lg bg-white`}
          as="textarea"
          {...props}
        />
        <div className="absolute top-[11px] left-2">
          <Icon className="w-5 h-5 text-gray-400" />
        </div>
      </div>
      <ErrorMessage
        name={props.id || props.name}
        render={(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      />
    </div>
  );
};

export const FormDatePicker = ({ label, onSelectDate, defaultValue = new Date(), ...props }) => {
  const { values, errors, touched } = useFormikContext();
  const hasError = errors[props.name] && touched[props.name];

  return (
    <>
      <FormLabel label={label} htmlFor={props.id || props.name} />
      <DatePicker
        defaultValue={dayjs(defaultValue)}
        name={props.name}
        id={props.id}
        style={{
          width: "100%",
          marginTop: "4px",
          borderColor: hasError ? "red" : undefined,
        }}
        size="large"
        format="DD-MM-YYYY"
        onChange={onSelectDate}
        {...props}
      />
      <ErrorMessage name={props.id || props.name} render={(msg) => <FieldErrorText message={msg} />} />
    </>
  );
};

export const FormSelectInput = ({ label, name, options, ...props }) => {
  const { setFieldValue, validateForm } = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <>
      {label && <FormLabel label={label} htmlFor={name} />}
      <Select
        onChange={(selectedOption) => {
          setFieldValue(name, selectedOption.value);
          validateForm();
        }}
        name={name}
        id={name}
        options={options}
        {...props}
      />
      {meta.touched && meta.error && meta.error.length > 1 ? <FieldErrorText message={meta.error} /> : null}
    </>
  );
};

function AsyncSearchTextInput({
  label,
  fetchData,
  queryKeyPrefix,
  placeholder,
  extractKey,
  extractValue,
  value,
  setValue,
  disabled,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1500);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const { data, isLoading, isError } = useQuery({
    queryKey: [queryKeyPrefix, debouncedSearchQuery],
    queryFn: async () => fetchData(debouncedSearchQuery),
    enabled: debouncedSearchQuery?.length === 0 || debouncedSearchQuery?.length >= 3,
  });
  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocused, isLoading]);

  return (
    <>
      <FormLabel htmlFor="async-search" label={label} />
      <div className="relative !mt-1">
        <div className="w-full relative text-gray-600">
          <input
            ref={inputRef}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            disabled={disabled || isLoading}
            placeholder={placeholder}
            id="async-search"
            className={`${
              isFocused && "border-[#2684FF] border-2"
            } w-full px-2 h-10 border rounded-lg bg-white disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed`}
            type="text"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

          {isLoading && <BsThreeDots className="absolute animate-pulse w-5 h-5 top-2 right-5" />}
        </div>

        {isFocused && (searchQuery.length >= 3 || searchQuery.length === 0) && (
          <ul className="absolute top-12 right-0 left-0 py-1 rounded-xl bg-white shadow-lg z-[1000] overflow-hidden">
            {data?.results?.length === 0 ? (
              <li className="px-4 py-2 text-center">No results found</li>
            ) : (
              data?.results?.map((item) => {
                if (value.find((v) => extractKey(v) === extractKey(item))) {
                  return null;
                }
                return (
                  <li
                    className="hover:cursor-pointer hover:bg-blue-100 px-4 py-2"
                    key={extractKey(item)}
                    onMouseDown={() => {
                      setValue([...value, item]);
                    }}
                  >
                    {extractValue(item)}
                  </li>
                );
              })
            )}
          </ul>
        )}
        {isError && <FieldErrorText message={"Error fetching data"} />}
      </div>
    </>
  );
}

export default AsyncSearchTextInput;
