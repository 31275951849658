import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export function addHotelBankDetails(data, callback) {
  return async function () {
    try {
      for (let i = 0; i < data.length; i++) {
        const response = await api_token(token).post("/en/hotels/api/hotel-account-bank", data[i]);

        if (response && callback) {
          callback();
        }
      }
    } catch (error) {}
  };
}

export const hotelBankDetailsList = (page, sorting, callback) => {
  return async function () {
    try {
      const response = await api_token(token).get(
        `/en/hotels/api/hotel-account-bank?page_size=10&page=${page}&ordering=${sorting}`,
      );
      callback(response);
    } catch (error) {}
  };
};

export const selectedHotelBank = (id, callback) => {
  return async function () {
    try {
      const response = await api_token(token).get(`/en/hotels/api/hotel-account-bank/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const deleteSelectedHotelBank = (id, callback) => {
  return async function () {
    try {
      const response = await api_token(token).delete(`/en/hotels/api/hotel-account-bank/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const getSelectedHotelBankNumber = (hotelId, callback) => {
  return async function () {
    try {
      const response = await api_token(token).get(`/en/hotels/api/hotel-account-bank?hotel=${hotelId}`);
      callback(response);
    } catch (error) {}
  };
};
