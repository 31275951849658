import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TbListDetails } from "react-icons/tb";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { getSelectedPaymentHotel, updateCashInHotel } from "../../../redux/actions/hotelPayments";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import Select from "react-select";
import lightToast from "light-toast";
import { AiOutlineEye } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";
import { getAllCollectorsAction } from "../../../redux/actions/collectorsAction";

const CashInHotelOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [load, setload] = useState(false);
  const [details, setDetails] = useState();
  const [statusToggle, setStatusToggle] = useState(false);
  const [status, setStatus] = useState();
  const [orderToggle, setOrderToggle] = useState(false);
  const [order, setOrder] = useState();
  const [cashierName, setCashierName] = useState();
  const [collectors, setCollectors] = useState();
  const [notes, setNotes] = useState();
  const [collectorName, setCollectorName] = useState();
  const [Payment, setPayment] = useState();
  const [imgFile, setImgFile] = useState();
  const [bankTransferReceipt, setBankTransferReceipt] = useState();
  const [loadUpdate, setLoadUpdate] = useState();
  const token = cookie.load("access_token");

  useEffect(() => {
    dispatch(
      getSelectedPaymentHotel(params.id, (result) => {
        if (result.status === 200) {
          setDetails(result.data);
          setStatus(result.data.method_payment_detail?.cashier_status);
          setOrder(result.data.method_payment_detail?.order_status);
          setCashierName(result.data.method_payment_detail?.cashier_name);
          setCollectorName(result.data.method_payment_detail?.collector_name);
          setNotes(result.data.method_payment_detail?.notes);
          setPayment(result.data.attachment);
          dispatch(
            getAllCollectorsAction(token, (result3) => {
              if (result3.status === 200) {
                setload(true);
                setCollectors(result3.data.results);
              }
            }),
          );
        }
      }),
    );
  }, [loadUpdate]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImgFile(URL.createObjectURL(file));
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setLoadUpdate(true);
    setload(false);
    const value = {
      method_payment: params.title,
      cashier_status: status,
      cashier_name: cashierName,
      collector_name: collectorName,
      order_status: order,
      notes: notes,
    };
    const formData = new FormData(e.target);
    const checkInvoiceImg = formData.getAll("attachment");
    formData.append("method_payment_detail", JSON.stringify(value));
    if (checkInvoiceImg[0].size === 0) {
      formData.delete("attachment");
    }
    dispatch(
      updateCashInHotel(params.id, formData, (result) => {
        if (result.status === 200) {
          setload(true);
          setLoadUpdate(false);
          lightToast.success("Cash In Hotel Order Was Completed Updated");
        }
      }),
    );
  };

  let collectorsData =
    load &&
    collectors?.map((item) => {
      return { value: item.id, label: item.collector_name };
    });
  const cashierStatus = ["pending ", "Done "];
  const orderStatus = ["pending", "Collected by Collector", "Done "];

  return (
    <div className="w-full  overflow-x-auto h-screen">
      {load ? (
        <div className="container border-gray-300 border rounded-lg  px-10 py-5 ">
          <div className="flex flex-col justify-center items-center ">
            <form className="flex flex-col    w-full  text-black rounded-md  " onSubmit={handleUpdate}>
              <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                <p className="flex items-center gap-x-3">
                  <TbListDetails className="w-5 h-5" />
                  Cash In Hotel Order
                </p>
              </div>

              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Booking Order</p>
                  </div>
                  <div className="flex items-center gap-x-1 w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{details.booking_short_id}</p>
                    <button
                      onClick={() =>
                        navigate(`/dashboard/booking-list-single-id/${details.booking_short_id}/${details.booking}`)
                      }
                      className="hover:scale-105 active:scale-95 duration-300 transition-all "
                    >
                      <AiOutlineEye className="w-5 h-5 text-[#002244]" />
                    </button>
                  </div>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Last Payment Date</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">
                      {" "}
                      {details.last_date_for_payment.slice(0, 10)} / {details.last_date_for_payment.slice(11, 19)}
                    </p>
                  </div>
                </div>
              </div>
              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Booking ID </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{details.booking}</p>
                  </div>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Cashier status</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <div className="relative w-full inline-block text-left">
                      <div>
                        <button
                          onClick={() => setStatusToggle(!statusToggle)}
                          type="button"
                          className={`flex justify-start items-center px-2 w-[80%] h-9 capitalize rounded-md border border-gray-300 shadow-sm ${status === "pending" ? "bg-yellow-300 bg-opacity-90 hover:bg-yellow-400  hover:bg-opacity-80  text-black" : status === "done" ? "bg-green-800 bg-opacity-90 hover:bg-green-800  font-semibold text-white" : "bg-gray-50"}  text-sm font-medium text-gray-700   active:scale-95 duration-300 transition-all`}
                        >
                          {status}
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>

                      {statusToggle && (
                        <div className="w-[80%] origin-top-right absolute  mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                          <div
                            className="py-1 w-full capitalize"
                            role="menu"
                            aria-orientation="horizontal"
                            aria-labelledby="options-menu"
                          >
                            {cashierStatus.map((item, index) => {
                              return (
                                <button
                                  className="w-full flex items-center justify-start  px-4 py-2  capitalize text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => {
                                    setStatusToggle(!statusToggle);
                                    setStatus(item);
                                  }}
                                  key={index}
                                >
                                  <p>{item}</p>
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Hotel Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    {/* <p className='flex items-center gap-x-1'>{bankDetails.hotel.name}</p> */}
                  </div>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Cashier Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <input
                      defaultValue={cashierName}
                      onChange={(e) => setCashierName(e.target.value)}
                      className="w-[80%] flex items-center gap-x-1 py-0.5 border-gray-300 border rounded-md pl-2"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Amount</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{details.amount} </p>
                    <p className="flex items-center gap-x-1"></p>
                  </div>
                </div>

                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collector Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <Select
                      placeholder="Collector Name"
                      type="text"
                      required
                      defaultValue={[{ value: collectorName, label: collectorName }]}
                      options={collectorsData}
                      onChange={(e) => setCollectorName(e.label)}
                      className="w-[80%]  rounded-md  z-50 text-left"
                    />
                  </div>
                </div>
              </div>
              {/* Client & phone Number*/}
              <div className="flex w-full justify-between items-center  p-3 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Currency </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{details.currency_type}</p>
                  </div>
                </div>

                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Order Status</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <div className="relative w-full inline-block text-left">
                      <div>
                        <button
                          onClick={() => setOrderToggle(!orderToggle)}
                          type="button"
                          className={`flex justify-start px-3 items-center w-[80%] h-9 capitalize rounded-md border border-gray-300 shadow-sm ${status === "pending" ? "bg-yellow-300 bg-opacity-90 hover:bg-yellow-400  hover:bg-opacity-80  text-black" : status === "done" ? "bg-green-800 bg-opacity-90 hover:bg-green-800  font-semibold text-white" : "bg-white"}  text-sm font-medium text-gray-700   active:scale-95 duration-300 transition-all`}
                        >
                          {order ? order : details.collection_satus}
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>

                      {orderToggle && (
                        <div className="w-[80%] origin-top-right absolute text-left mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                          <div
                            className="py-1 w-full capitalize"
                            role="menu"
                            aria-orientation="horizontal"
                            aria-labelledby="options-menu"
                          >
                            {orderStatus.map((item, index) => {
                              return (
                                <button
                                  className="w-full flex items-center justify-start px-4 py-2  capitalize text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => {
                                    setOrderToggle(!orderToggle);
                                    setOrder(item);
                                  }}
                                  key={index}
                                >
                                  <p>{item}</p>
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* WhatsApp Number & Email*/}
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Created By</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{details.creator} </p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold text-sm">Copy of Payment</p>
                  </div>
                  <div className="flex flex-col gap-y-3 w-[70%] font-semibold text-gray-800">
                    {imgFile ? (
                      <div className="w-[80%] flex justify-center items-center ">
                        <img src={imgFile} className="w-16 h-16" />
                      </div>
                    ) : (
                      Payment && (
                        <div className="w-[80%] flex justify-center items-center ">
                          <img src={Payment} className="w-16 h-16" />
                        </div>
                      )
                    )}
                    <input
                      placeholder="Bank Name"
                      name="attachment"
                      type="file"
                      onChange={handleFileChange}
                      className=" w-[80%] px-2 py-0.5 border-gray-300 border rounded-lg  "
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-between items-center  p-3 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Creation Date</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">
                      {details.created_date.slice(0, 10)} / {details.created_date.slice(11, 19)}{" "}
                    </p>
                  </div>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold text-sm">Notes</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <textarea
                      defaultValue={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      className="w-[80%] flex items-center gap-x-1 pl-1 h-10 rounded-md border-gray-300 border"
                    />
                  </div>
                </div>
              </div>

              {/* Hotel & Room Name*/}

              {/*                            
                            <div className='flex w-full justify-between items-center  p-3 bg-gray-200  '>
                            <div className='flex  justify-between items-center w-[50%] '>
                                  <div className='w-[33%]  '>
                                      <p className='flex items-center gap-x-1 font-bold'>Iban Number</p>
                                  </div> 
                                  <div className='w-[70%] font-semibold text-gray-800'>
                                  <p className='flex items-center gap-x-1'>{bankDetails.iban_number}</p>
                                  </div>
                                  </div>
                                  
                                  <div className='flex  justify-between items-center w-[50%] '>
                                  <div className='w-[33%]  '>
                                      <p className='flex items-center gap-x-1 font-bold'>Date </p>
                                  </div> 
                                  <div className='w-[70%] font-semibold text-gray-800 flex items-center justify-end'>
                                    <button   
                                            type="submit"
                                            disabled={loadUpdate}
                                            className="flex items-center gap-x-1 px-2 py-0.5 text-sm font-semibold bg-green-800 text-white rounded-md hover:bg-green-700 active:scale-95 duration-300 transition-all">
                                         
                                    Update {loadUpdate ? <><ButtonLoading /><TopBarProgress/> </>: <RxUpdate className="w-4 h-4" />} 
                                    </button>
                                  </div>
                                 </div>                                
                             
                               
                            </div>   */}

              <div className="w-full font-semibold text-gray-800 flex items-center justify-end">
                <button
                  type="submit"
                  disabled={loadUpdate}
                  className="flex items-center gap-x-1 px-2 py-0.5 text-sm font-semibold bg-green-800 text-white rounded-md hover:bg-green-700 active:scale-95 duration-300 transition-all"
                >
                  Update{" "}
                  {loadUpdate ? (
                    <>
                      <ButtonLoading />
                      <TopBarProgress />{" "}
                    </>
                  ) : (
                    <RxUpdate className="w-4 h-4" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default CashInHotelOrder;
