import api_token from "../../api/UserApi";
import { GET_CITY_HOTELS, GET_HOTELS_LIST } from "../types/types";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function requestHotelListSuccessfull(data) {
  return {
    type: GET_HOTELS_LIST,
    payload: data,
  };
}

function requestCityHotelsSuccessfull(data) {
  return {
    type: GET_CITY_HOTELS,
    payload: data,
  };
}

export default function requestHotelList(page, sortingOrder, callback, pageSize, city, area) {
  return async function (dispatch) {
    // Check if both city and area are provided
    if (!city || !area) {
      return; // Exit early if city or area is not available
    }

    try {
      const data = await api_token(token).get(
        `/en/hotels/api/v1/add-get-hotels/?page=${page}&page_size=${pageSize}&sorting_order=${sortingOrder}&cities=[${city}]&areas=[${area}]`,
      );
      callback(data);

      dispatch(requestHotelListSuccessfull(data));
    } catch (error) {
      if (error.response?.status === 400) {
        try {
          const data = await api_token(token).get(
            `/en/hotels/api/v1/add-get-hotels/?page=${page}&page_size=${pageSize}&sorting_order=${sortingOrder}&city=[${city}]&area=[${area}]`,
          );
          callback(data);
          dispatch(requestHotelListSuccessfull(data));
        } catch (secondError) {}
      } else {
      }
    }
  };
}

export function requestCityHotels(city, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/hotels/api/v1/add-get-hotels/?city=${city}`);
      dispatch(requestCityHotelsSuccessfull(response.data));
      callback(response);
    } catch (error) {}
  };
}
