import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import { getCountryHotelAction } from "../../../redux/actions/searchformAction";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { MdAdd, MdAttachEmail, MdOutlineMailOutline } from "react-icons/md";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { useNavigate } from "react-router";
import { RxUpdate } from "react-icons/rx";
import { addSmtpAction, deleteSmtpAction, getSmtpAction, updateSmtpAction } from "../../../redux/actions/emailAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useReloadPage } from "../../../customHooks/useReloadPage";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { RiDeleteBin6Fill } from "react-icons/ri";
import EmailWelcomeMessageForm from "./EmailWelcomeMessageForm";
import EmailSignatureMessageForm from "./EmailSignatureMessageForm";

const EmailSettings = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();
  const reloadPage = useReloadPage();
  const [load, setLoad] = useState(false);
  const [smtp, setSmtp] = useState();
  const [refetchData, setRefetchData] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [smtpHotels, setSmtpHotels] = useState();
  const [smtpLoad, setSmtpLoad] = useState();
  const [countryHotels, setCountryHotels] = useState();
  const [countryHotelsArray, setCountryHotelsArray] = useState();
  const [emailFlag, setEmailFlag] = useState(false);
  const [hostEmail, setHostEmail] = useState(null);
  const [emailHostUser, setEmailHostUser] = useState();
  const [selectFlag, setSelectFlag] = useState(false);
  const [countrySelect, setCountrySelect] = useState();
  const [countrySelectId, setCountrySelectId] = useState();
  const [id, setId] = useState();
  const { nationalities } = useSelector((state) => state.SearchFormReducer);
  const smtpOfSelectedCountry = smtp?.find((item) => item.country === countrySelect) || undefined;
  let countryList = countryHotelsArray
    ? countryHotelsArray.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  let testCountries = [
    { label: "Egypt", value: "egypt" },
    { label: "Egypt", value: "egypt" },
    { label: "Egypt", value: "egypt" },
    { label: "Egypt", value: "egypt" },
    { label: "Egypt", value: "egypt" },
    { label: "Egypt", value: "egypt" },
  ];
  useEffect(() => {
    setId(smtpOfSelectedCountry?.id);
  }, [smtpOfSelectedCountry?.id]);

  //
  useEffect(() => {
    dispatch(
      getSmtpAction(token, (result1) => {
        if (result1.status === 200) {
          let array = [];
          let array2 = [];
          setSmtpLoad(true);
          array2.push(...result1.data.map((item) => item.country));
          dispatch(
            getCountryHotelAction(token, (result) => {
              if (result.status === 200) {
                array.push(...result.data.map((item) => item.name));
                setLoad(true);
                setCountryHotels(array);
                setSmtp(result1.data);
                setSmtpHotels(array2);
                setCountryHotelsArray(result.data);
              }
            }),
          );
        }
      }),
    ).catch((error) => {
      NotificationManager.error(error?.response?.data[0]?.message || error.message || "Unknown Error");
    });
  }, [refetchData]);
  ////////Permission/////////////
  const permissionData = {
    permission_codes: ["room_booking.change_smtpsettings"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////////////////////////////
  //

  //

  const differenceArray = load && countryHotels.filter((item) => !smtpHotels.includes(item));

  //

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    setEmailFlag(true);
    const formData = new FormData(e.target);
    //
    formData.append("email_backend", "django.core.mail.backends.smtp.EmailBackend");
    formData.append("email_host", hostEmail ? hostEmail : "smtp.gmail.com");
    formData.append("email_from", formData.getAll("email_host_user"));
    formData.append("country", countrySelectId);
    dispatch(
      updateSmtpAction(token, formData, id, (result) => {
        if (result.status === 200) {
          NotificationManager.success("SMTP Email settings updated successfully", "Success", 1000);
        } else {
          throw new Error("Error updating SMTP Email", "Error", 1000);
        }
      }),
    )
      .then(() => {
        setRefetchData(!refetchData);
      })
      .catch((error) => {
        NotificationManager.error(error?.response?.data[0]?.message || error.message || "Unknown Error", "Error", 1000);
      })
      .finally(() => {
        setEmailFlag(false);
      });
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    setEmailFlag(true);
    const formData = new FormData(e.target);
    //
    formData.append("country", countrySelectId);
    formData.append("email_backend", "django.core.mail.backends.smtp.EmailBackend");
    formData.append("email_host", hostEmail ? hostEmail : "smtp.gmail.com");
    formData.append("email_from", formData.getAll("email_host_user"));

    dispatch(
      addSmtpAction(token, formData, (result) => {
        if (result.status === 200 || result.status === 201) {
          setEmailFlag(false);
          NotificationManager.success("SMTP Email settings added successfully", "Success", 1000);
        } else {
          setEmailFlag(false);
          throw new Error("Error adding SMTP Email settings", "Error", 1000);
        }
      }),
    )
      .then(() => {
        setRefetchData(!refetchData);
      })
      .catch((error) => {
        NotificationManager.error(error?.response?.data[0]?.message || error.message || "Unknown Error", "Error", 1000);
      })
      .finally(() => {
        setEmailFlag(false);
      });
  };

  const handleDeleteSmtp = (e) => {
    e.preventDefault();
    setIsDeleting(true);
    dispatch(
      deleteSmtpAction(token, id, (result) => {
        if (result.status === 204) {
          NotificationManager.success("SMTP Email settings deleted successfully", "Success", 1000);
        } else {
          throw new Error("Error deleting SMTP Email settings", "Error", 1000);
        }
      }),
    )
      .then(() => {
        setTimeout(() => {
          reloadPage();
        }, 2000);
      })
      .catch((error) => {
        NotificationManager.error(error?.response?.data[0]?.message || error.message || "Unknown Error", "Error", 1000);
      })
      .finally(() => {
        setIsDeleting(false);

        setEmailFlag(false);
      });
  };

  return (
    <div className="formPage-wrap min-h-screen">
      {permissionFlag ? (
        <div className="   ">
          <div className="flex flex-col items-center  w-full mb-6">
            <div className="w-full flex justify-center ">
              <h2 className="header-h2">
                <MdAttachEmail className="h-5 w-5" />
                Email Settings
              </h2>
            </div>
            <div className={`grid grid-cols-1  w-full h-full my-5 form-wrap `}>
              <div className="flex max-md:flex-col h-full items-center  border-solid px-6 border p-2 rounded-md bg-gray-50">
                <p className=" flex w-full text-black font-semibold  md:text-lg  sm:flex-[1] ">
                  Please Choose Country:
                </p>
                <Select
                  placeholder="Country Name"
                  name="bank_name"
                  type="text"
                  isSearchable
                  className=" w-full   h-full  rounded-lg  bg-white  md:flex-[2]  text-black z-50"
                  required
                  options={countryList}
                  onChange={(e) => {
                    setCountrySelect();
                    setCountrySelect(e.label);
                    setSelectFlag(true);
                    setCountrySelectId(e.value);
                  }}
                />
              </div>
            </div>
            {load && selectFlag && (
              <div className=" w-full md:px-6 px-4  border-gray-300 border rounded-lg p-5 " key={countrySelect}>
                <div className="w-full flex justify-center   ">
                  <h2 className="header-h2">
                    <MdOutlineMailOutline className="w-6 h-6 mt-1" />
                    {countrySelect || ""} SMTP Settings
                  </h2>
                </div>
                <form
                  className="mt-2 border  w-full bg-gray-50 p-4 rounded-md"
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    smtpOfSelectedCountry ? handleUpdateSubmit(e) : handleAddSubmit(e);
                  }}
                >
                  <div className="w-full flex flex-col justify-center items-center gap-y-2">
                    <div className="flex w-full gap-4 max-sm:flex-col">
                      <div className="flex-1 max-sm:w-full">
                        <label className=" text-center font-medium w-full text-gray-600">Email Host</label>
                        <input
                          // placeholder="smtp.gmail.com"
                          type="text"
                          defaultValue={smtpOfSelectedCountry?.email_host || ""}
                          onChange={(e) => setHostEmail(e.target.value)}
                          className=" w-full px-3 h-10  border rounded-lg bg-white "
                        />
                      </div>
                      <div className="flex-1 max-sm:w-full">
                        <label className=" text-center w-full font-medium text-gray-600">Email Port</label>
                        <input
                          placeholder="Email Port"
                          name="email_port"
                          type="number"
                          required
                          defaultValue={smtpOfSelectedCountry?.email_port || ""}
                          //   onChange={(e)=>setSourceName(e.target.value)}
                          className=" w-full px-3 h-10  border rounded-lg bg-white "
                        />
                      </div>
                    </div>
                    <div className="flex w-full gap-4 max-sm:flex-col">
                      <div className="flex-1 max-sm:w-full">
                        <label className=" text-center w-full text-gray-600 font-medium">Email Host User</label>
                        <input
                          placeholder="Email Host User"
                          name="email_host_user"
                          type="email"
                          defaultValue={smtpOfSelectedCountry?.email_from || ""}
                          required
                          onChange={(e) => setEmailHostUser(e.target.value)}
                          className=" w-full px-3 h-10  border rounded-lg bg-white "
                        />
                      </div>
                      <div className="flex-1 max-sm:w-full">
                        <label className=" text-center w-full text-gray-600 font-medium">Email Host Passsword</label>
                        <div className="w-full relative text-gray-600">
                          <input
                            placeholder="Email Host Passsword"
                            name="email_host_password"
                            defaultValue={smtpOfSelectedCountry?.email_host_password || ""}
                            type={"password"}
                            required
                            //   onChange={(e)=>setSourceName(e.target.value)}
                            className=" w-full px-3 h-10  border rounded-lg bg-white "
                          />
                        </div>
                      </div>

                      {/*{passwordCheck ? (*/}
                      {/*  <button*/}
                      {/*    type="button"*/}
                      {/*    onClick={() => setPasswordCheck(false)}*/}
                      {/*    className="absolute top-[11px] right-3 hover:text-black active:scale-90 duration-300 transition-all "*/}
                      {/*  >*/}
                      {/*    <VscEyeClosed className="w-6 h-6" />*/}
                      {/*  </button>*/}
                      {/*) : (*/}
                      {/*  <button*/}
                      {/*    type="button"*/}
                      {/*    onClick={() => setPasswordCheck(true)}*/}
                      {/*    className="absolute top-[11px] right-3 hover:text-black active:scale-90 duration-300 transition-all "*/}
                      {/*  >*/}
                      {/*    <VscEye className="w-6 h-6" />*/}
                      {/*  </button>*/}
                      {/*)}*/}
                    </div>
                    <label className="text-center w-full text-gray-600 font-medium mt-2">Email Use TLS</label>
                    <div className="flex items-center justify-evenly   border py-2  w-full   rounded-lg  ">
                      <div className="flex items-center sm:px-4 px-2 py-1.5 rounded-md bg-white border justify-center gap-x-3   ">
                        <label className="  text-gray-600   text-lg ">Yes</label>
                        <input
                          name="email_use_tls"
                          type="radio"
                          value="true"
                          defaultChecked={smtpOfSelectedCountry?.email_use_tls}
                          required
                          // onChange={(e)=>setcollection(e.target.value)}
                          className=" w-5 h-5  border rounded-lg bg-white "
                        />
                      </div>
                      <div className="flex items-center border sm:px-4 px-2 py-1.5 rounded-md bg-white justify-center gap-x-3 ">
                        <label className="text-gray-600 text-lg">No</label>
                        <input
                          name="email_use_tls"
                          type="radio"
                          value="false"
                          defaultChecked={!smtpOfSelectedCountry?.email_use_tls}
                          required
                          // onChange={(e)=>setcollection(e.target.value)}
                          className=" w-5 h-5  border rounded-lg bg-white  "
                        />
                      </div>
                    </div>

                    <div className="flex gap-x-2 mt-4">
                      <button
                        type="Submit"
                        onClick={() => setId(smtpOfSelectedCountry?.id)}
                        disabled={emailFlag}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        {smtpOfSelectedCountry ? "Update" : "Add"}{" "}
                        {emailFlag ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : smtpOfSelectedCountry ? (
                          <RxUpdate className="w-5 h-5" />
                        ) : (
                          <MdAdd className="w-5 h-5" />
                        )}
                      </button>

                      {smtpOfSelectedCountry && (
                        <button
                          type="button"
                          className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-red-700 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                          onClick={(e) => {
                            setId(smtpOfSelectedCountry?.id);
                            handleDeleteSmtp(e);
                          }}
                        >
                          Delete
                          {isDeleting ? (
                            <>
                              <TopBarProgress />
                              <ButtonLoading />
                            </>
                          ) : (
                            <RiDeleteBin6Fill />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>

          {load && selectFlag && <EmailWelcomeMessageForm smtpIdOfSelectedCountry={smtpOfSelectedCountry?.id} />}
          {load && selectFlag && <EmailSignatureMessageForm smtpIdOfSelectedCountry={smtpOfSelectedCountry?.id} />}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};

export default EmailSettings;
