import { useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { MdSubject } from "react-icons//md";
import { AiOutlineMail } from "react-icons/ai";
import Select from "react-select";
import { useState } from "react";
import { IoIosAdd, IoMdClose } from "react-icons/io";
import { Modal } from "flowbite-react";
import { validateEmail } from "../../../utils/validationUtils";
import { toast } from "react-toastify";
import { RiMailSendLine } from "react-icons/ri";
import ButtonLoading from "../../organisms/ButtonLoading/ButtonLoading";
import { useMutation, useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";

function SendMailModal({ showSendEmailModal, setShowSendEmailModal, selectedSingleRoom }) {
  const { id: roomId } = useParams();
  const { user_id } = jwtDecode(cookie.load("access_token"));

  const [emailType, setEmailType] = useState("automatic");

  const {
    isLoading: isLoadingEmailData,
    data: emailData,
    error: emailDataError,
  } = useQuery({
    queryKey: [`booking/api/v1/email-data/, ${roomId}`],
    queryFn: () => fetchDataQuery(`booking/api/v1/email-data/${roomId}/`),
  });

  const reservationEmails = Object.entries(emailData?.reservation_email[0] || {}).map(([_, value]) => ({
    label: value,
    value: value,
  }));

  const receptionEmails = Object.entries(emailData?.reception_email[0] || {}).map(([_, value]) => ({
    label: value,
    value: value,
  }));

  const [recipientEmails, setRecipientEmails] = useState([]);
  const [customEmails, setCustomEmails] = useState([]);
  const [customEmailInput, setCustomEmailInput] = useState("");
  const [isCustomEmailInputValid, setIsCustomEmailInputValid] = useState(true);
  const [reason, setReason] = useState("");
  const [manualEmailData, setManualEmailData] = useState("");
  const [selectAllReservationEmails, setSelectAllReservationEmails] = useState(false);
  const [selectAllReceptionEmails, setSelectAllReceptionEmails] = useState(false);
  const { mutate: sendEmail, isPending: isSendingEmail } = useMutation({
    retry: false,
    networkMode: "always",
    mutationFn: (data) => {
      return mutateDataQuery(`booking/api/v1/send-email/`, "post", data);
    },
    onSuccess: () => {
      toast.success("Email sent successfully");
      setRecipientEmails([]);
      setCustomEmails([]);
      setShowSendEmailModal(false);
    },
    onError: (error) => {
      toast.error(error.message || "Error sending email");
    },
  });

  const handleSendMail = (e) => {
    e.preventDefault();

    if (recipientEmails.length === 0) {
      toast.error("Please select at least one email");
      return;
    }

    const data = {
      hotel_name: selectedSingleRoom.hotel,
      check_in: selectedSingleRoom.from_date,
      check_out: selectedSingleRoom.to_date,
      booked_room_id: roomId,
      subject: emailData.subject,
      message: emailData.message.replace(" signature: ", ""),
      from_email: emailData.from_email,
      recipient_list: recipientEmails,
      sent_by_email: emailData.from_email,
      sent_by_username: user_id,
      reason: reason,
    };

    if (emailType === "automatic") {
      data.message = emailData?.message?.replace(" signature: ", "");
    } else if (emailType === "manual") {
      data.message = emailData.intro_mail + `\n${manualEmailData}\n\n` + emailData.signature_mail;
    } else {
      toast.error("Invalid email type");
      return;
    }

    sendEmail(data);
  };

  if (emailDataError) {
    toast.error(emailDataError.message || "Failed to retrieve data please try again later.");
    return null;
  }

  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <>
      {!emailDataError && (
        <Modal theme={modalTheme} dismissible show={showSendEmailModal} onClose={() => setShowSendEmailModal(false)}>
          {isLoadingEmailData ? (
            <Loading />
          ) : (
            <>
              <Modal.Header>
                <div className="w-full flex items-center justify-between ">
                  <p className="flex items-center gap-x-1 text-xl font-body text-black font-semibold">
                    <MdSubject className="w-5 h-5" />
                    {emailData?.subject || ""}
                  </p>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="flex justify-between items-center">
                  <h3 className="flex items-center gap-x-1 text-base md:text-xl mb-1 font-body text-black font-semibold mr-auto">
                    Select Email Type
                  </h3>

                  <Select
                    onChange={(item) => setEmailType(item.value)}
                    defaultValue={{ value: "automatic", label: "Automatic" }}
                    className=" w-80  h-auto  border rounded-lg  bg-white "
                    placeholder="Reservation Email"
                    options={[
                      { value: "automatic", label: "Automatic" },
                      { value: "manual", label: "Manual" },
                    ]}
                  />
                </div>
                {emailType === "automatic" ? (
                  <p className="text-sm text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                    {emailData?.message?.replace(" signature: ", "") || ""}
                  </p>
                ) : (
                  <>
                    <p className="text-sm text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                      {emailData?.intro_mail}
                    </p>
                    <form>
                      <textarea
                        value={manualEmailData}
                        onChange={(e) => setManualEmailData(e.target.value)}
                        className="` min-h-[300px] bg-gray-50 col-start-2  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full"
                      />
                    </form>
                    <p className="text-sm mt-2 text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                      {emailData?.signature_mail}
                    </p>
                  </>
                )}

                <form className="w-full flex flex-col gap-y-3" onSubmit={handleSendMail}>
                  <p className="flex items-center gap-x-1 text-md font-body text-black font-semibold">
                    From: <AiOutlineMail className="w-5 h-5 " /> {emailData?.from_email || ""}
                  </p>

                  <div className="w-full flex items-center justify-start gap-x-3">
                    <p className="flex items-center gap-x-1 text-base md:text-md font-body text-black font-semibold mr-auto">
                      <AiOutlineMail className="w-5 h-5 " /> Reservation Emails
                    </p>
                    <div className="flex gap-1 justify-between items-center">
                      <button
                        type="button"
                        onClick={() => {
                          if (!selectAllReservationEmails) {
                            setRecipientEmails((recipientEmails) =>
                              recipientEmails.filter((email) => {
                                return !reservationEmails.map((email) => email.value).includes(email);
                              }),
                            );
                            setRecipientEmails((recipientEmails) => [
                              ...recipientEmails,
                              ...reservationEmails.map((email) => email.value),
                            ]);
                            setSelectAllReservationEmails(true);
                          } else {
                            setRecipientEmails((recipientEmails) => {
                              return recipientEmails.filter((email) => {
                                return !reservationEmails.map((email) => email.value).includes(email);
                              });
                            });
                            setSelectAllReservationEmails(false);
                          }
                        }}
                        disabled={isSendingEmail}
                        className={` disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center gap-x-2  px-5 py-1 ${selectAllReservationEmails ? "bg-red-700 hover:bg-red-900" : "bg-green-600 hover:bg-green-900"} rounded-lg  active:scale-95 duration-300 transition-all text-white  `}
                      >
                        {selectAllReservationEmails ? "Deselect All" : "Select All"}
                      </button>
                      <Select
                        key={`select-${selectAllReservationEmails}`}
                        onChange={(items, context) => {
                          if (context.action === "remove-value" || context.action === "pop-value") {
                            setRecipientEmails((recipientEmails) =>
                              recipientEmails.filter((email) => email !== context.removedValue.value),
                            );
                          }

                          if (context.action === "select-option") {
                            setRecipientEmails((recipientEmails) => [...recipientEmails, context.option.value]);
                          }

                          if (context.action === "clear") {
                            context.removedValues.forEach((email) => {
                              setRecipientEmails((recipientEmails) => recipientEmails.filter((e) => e !== email.value));
                            });
                          }
                        }}
                        name="reservation_email"
                        options={
                          selectAllReservationEmails
                            ? {
                                label: "All emails selected",
                                value: "all_emails_selected",
                              }
                            : reservationEmails
                        }
                        isMulti
                        isDisabled={selectAllReservationEmails}
                        isSearchable
                        className=" w-52  h-auto  border rounded-lg  bg-white "
                        placeholder={selectAllReservationEmails ? "All emails selected" : "Reservation Email"}
                      />
                    </div>
                  </div>

                  <div className="w-full flex items-center justify-start gap-x-3">
                    <p className="flex items-center gap-x-1 text-md font-body text-black font-semibold mr-auto">
                      <AiOutlineMail className="w-5 h-5 " /> Reception Emails
                    </p>
                    <div className="flex gap-1 justify-between items-center">
                      <button
                        type="button"
                        onClick={() => {
                          if (!selectAllReceptionEmails) {
                            setRecipientEmails((recipientEmails) =>
                              recipientEmails.filter((email) => {
                                return !receptionEmails.map((email) => email.value).includes(email);
                              }),
                            );
                            setRecipientEmails((recipientEmails) => [
                              ...recipientEmails,
                              ...receptionEmails.map((email) => email.value),
                            ]);
                            setSelectAllReceptionEmails(true);
                          } else {
                            setRecipientEmails((recipientEmails) => {
                              return recipientEmails.filter((email) => {
                                return !receptionEmails.map((email) => email.value).includes(email);
                              });
                            });
                            setSelectAllReceptionEmails(false);
                          }
                        }}
                        disabled={isSendingEmail}
                        className={` disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center gap-x-2  px-5 py-1 ${selectAllReceptionEmails ? "bg-red-700 hover:bg-red-900" : "bg-green-600 hover:bg-green-900"} rounded-lg  active:scale-95 duration-300 transition-all text-white  `}
                      >
                        {selectAllReceptionEmails ? "Deselect All" : "Select All"}
                      </button>
                      <Select
                        key={`select-${selectAllReceptionEmails}`}
                        onChange={(items, context) => {
                          if (context.action === "remove-value" || context.action === "pop-value") {
                            setRecipientEmails((recipientEmails) =>
                              recipientEmails.filter((email) => email !== context.removedValue.value),
                            );
                          }

                          if (context.action === "select-option") {
                            setRecipientEmails((recipientEmails) => [...recipientEmails, context.option.value]);
                          }

                          if (context.action === "clear") {
                            context.removedValues.forEach((email) => {
                              setRecipientEmails((recipientEmails) => recipientEmails.filter((e) => e !== email.value));
                            });
                          }
                        }}
                        name="reception_email"
                        options={
                          selectAllReceptionEmails
                            ? {
                                label: "All emails selected",
                                value: "all_emails_selected",
                              }
                            : receptionEmails
                        }
                        isMulti
                        isDisabled={selectAllReceptionEmails}
                        isSearchable
                        className=" w-52  h-auto  border rounded-lg  bg-white "
                        placeholder={selectAllReceptionEmails ? "All emails selected" : "Reception Email"}
                      />
                    </div>
                  </div>

                  <div className="w-full flex items-start justify-start gap-x-3">
                    <p className="flex items-center gap-x-1 text-md font-body text-black font-semibold mr-auto">
                      <AiOutlineMail className="w-5 h-5 " /> Custom Emails
                    </p>
                    <div className="grid grid-cols-[auto_auto] grid-rows-[auto_auto_auto]">
                      <button
                        type="button"
                        onClick={() => {
                          if (!customEmailInput) {
                            setIsCustomEmailInputValid(false);
                            return;
                          }
                          if (!validateEmail(customEmailInput)) return setIsCustomEmailInputValid(false);
                          setIsCustomEmailInputValid(true);
                          setCustomEmails((customEmails) => [...customEmails, customEmailInput]);
                          setRecipientEmails((receptionEmails) => [...receptionEmails, customEmailInput]);
                          setCustomEmailInput("");
                        }}
                      >
                        <IoIosAdd className="w-6 h-6" />
                      </button>

                      <input
                        placeholder="Custom Email"
                        value={customEmailInput}
                        onChange={(e) => setCustomEmailInput(e.target.value)}
                        className={`bg-gray-50 col-start-2  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 ${!isCustomEmailInputValid && "border-red-300"}`}
                        type="email"
                      />

                      {!isCustomEmailInputValid && <p className="text-red-500 col-start-2 text-sm">Invalid email</p>}
                      <div className="col-start-2">
                        {customEmails.map((email, index) => (
                          <div className="flex justify-between items-center">
                            <p className="text-sm mt-0.5 mb-.5">{email}</p>
                            <button
                              type="button"
                              onClick={() => {
                                setCustomEmails(customEmails.filter((_, i) => i !== index));
                                setRecipientEmails((recipientEmails) =>
                                  recipientEmails.filter((recipientEmail) => email !== recipientEmail),
                                );
                              }}
                            >
                              <IoMdClose />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <label className="font-semibold text=gray-500 ">Reason</label>
                  <textarea
                    className="w-full border-gray-300 border rounded-md px-3  py-1"
                    placeholder=""
                    onChange={(e) => setReason(e.target.value)}
                  />
                  <div className="w-full flex items-center justify-end ">
                    <button
                      type="submit"
                      disabled={isSendingEmail}
                      className="disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center gap-x-2  px-5 py-1 bg-green-700 rounded-lg hover:bg-green-900 active:scale-95 duration-300 transition-all text-white  "
                    >
                      Send Email
                      {isSendingEmail ? (
                        <>
                          <ButtonLoading />
                        </>
                      ) : (
                        <RiMailSendLine className="w-5 h-5 " />
                      )}
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </>
          )}
        </Modal>
      )}
    </>
  );
}

export default SendMailModal;
