import { GET_HOTELS_LIST, GET_CITY_HOTELS } from "../types/types";

const defaultState = {};

export default function hotelListReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_HOTELS_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
export function cityHotelsReducer(state = [], action) {
  switch (action.type) {
    case GET_CITY_HOTELS:
      return action.payload;
    default:
      return state;
  }
}
