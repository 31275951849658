import React from "react";
import Loading from "../../organisms/Loading/Loading";
import { useNavigate } from "react-router";

const SearchUserGroup = ({ userData, isSuccess }) => {
  const navigate = useNavigate();
  return (
    <div className="px-6">
      <button className="text-white mb-8 bg-[#002244] px-4 rounded-md py-2" onClick={() => navigate(-1)}>
        &#10094; Back
      </button>

      <section className="max-w-5xl mx-auto w-full md:px-6 px-2  pb-4 md:my-16">
        {userData && isSuccess ? (
          <div className=" w-full relative h-full">
            {/* <div className="w-full bg-[#002244] h-48 rounded-md " /> */}
            <div className="  flex justify-center  ">
              <div className=" flex flex-col bg-gray-200 px-2 py-8 rounded-md shadow-xl  md:w-2/3 w-[90%] justify-center items-center">
                <div className=" flex flex-col gap-4 justify-center w-full items-center">
                  <div className=" flex  md:px-4 flex-col gap-2   w-full mx-auto md:gap-4   rounded-md md:py-4">
                    <div className=" w-full  flex justify-center  items-center  ">
                      <img
                        src={
                          userData?.profile?.avatar
                            ? userData?.profile?.avatar
                            : "https://as1.ftcdn.net/v2/jpg/05/52/37/18/500_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg"
                        }
                        alt="profile image"
                        title="profile image"
                        className=" w-36 h-36 object-fit object-top flex justify-center  items-center border-4   border-[#002244] rounded-full bg-white p-1"
                      />
                    </div>
                    <div className="flex flex-col   shadow-md rounded-md   gap-2 bg-gray-50 pt-2">
                      <div className="flex gap-2 w-full py-2 px-3 justify-between border-b max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">ID: </p>
                        <p className="max-md:text-sm"> {userData.profile?.user ? userData.profile?.user : "__"}</p>
                      </div>
                      <div className="flex gap-2 w-full py-2 px-3 justify-between border-b max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Usert Type: </p>
                        <p className="max-md:text-sm capitalize"> {userData?.user_type ? userData.user_type : "__"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1 w-full py-2 px-3 justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">user Name: </p>
                        <p className=" max-md:text-sm"> {userData?.username ? userData?.username : "ــ"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1 w-full py-2 px-3 justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">First Name: </p>
                        <p className=" max-md:text-sm"> {userData?.first_name ? userData?.first_name : "ــ"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1 w-full py-2 px-3 justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Last Name: </p>
                        <p className=" max-md:text-sm"> {userData?.last_name ? userData?.last_name : "ــ"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1 py-2 px-3 justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Email: </p>
                        <a
                          className=" text-blue-400 hover:underline hover:text-blue-500 max-md:text-sm"
                          href={`mailto:${userData?.email}`}
                        >
                          {userData?.email ? userData?.email : "ــ"}
                        </a>
                        {/* <p className=" max-md:text-sm">{userData?.email ? userData?.email : "ــ"}</p> */}
                      </div>
                      {/* <div className="flex flex-col md:gap-2 gap-1 py-2 px-3 justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Bio: </p>
                        <p className=" max-md:text-sm">{userData?.profile?.bio ? userData?.profile?.bio : "ــ"}</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </section>
    </div>
  );
};

export default SearchUserGroup;
