import api_token from "../../api/UserApi";
import {
  DELETE_BANK_TRANSFER_FLAG,
  GET_BANK_TRANSFER,
  GET_BANK_TRANSFER_FLAG,
  LIST_BANK_TRANSFER,
  LIST_BANK_TRANSFER_FLAG,
  UPDATE_BANK_TRANSFER_FLAG,
} from "../types/types";

export const getSelectedBankTransferAction = (token, id, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_BANK_TRANSFER_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`booking/api/v1/bank-transfer/${id}/`, { signal });
      dispatch({
        type: GET_BANK_TRANSFER,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_BANK_TRANSFER_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
let currentAbortController = null;
export const updateBankTransfrAction = (token, id, data, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      const controller = new AbortController();
      const signal = controller.signal;
      if (currentAbortController) {
        currentAbortController.abort();
      }
      currentAbortController = controller;
      dispatch({
        type: UPDATE_BANK_TRANSFER_FLAG,
        payload: true,
      });
      const response = await api_token(token).patch(`booking/api/v1/bank-transfer/${id}/`, data, { signal });
      if (response.status === 200) {
        dispatch({
          type: UPDATE_BANK_TRANSFER_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
    } finally {
      // Clear the global variable when the request is completed (whether it succeeds or fails)
      currentAbortController = null;
    }
  };
};
export const deleteBankTransferAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_BANK_TRANSFER_FLAG,
        payload: true,
      });
      const response = await api_token(token).delete(`booking/api/v1/bank-transfer/${id}/`);
      if (response.status === 204) {
        dispatch({
          type: DELETE_BANK_TRANSFER_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const bankTransferListAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: LIST_BANK_TRANSFER_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`booking/api/v1/bank-transfer-by-bank/?bank_id=${id}`);
      dispatch({
        type: LIST_BANK_TRANSFER,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: LIST_BANK_TRANSFER_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const confirmBankTransfer = (token, id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`booking/api/v1/bank-transfer/${id}/`, data);

      if (response.status === 200) {
        callback(response);
      }
    } catch (error) {}
  };
};
