import React from "react";
import { FcBusinessman, FcLink, FcTodoList } from "react-icons/fc";
import { RiKeyboardLine } from "react-icons/ri";

import { Link } from "react-router-dom";
import usePermissions from "../../../../customHooks/usePermissions";
import Loading from "../../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";

const CustomersLink = () => {
  const [permissions, permissionsFlag] = usePermissions([
    "link.add_link_customer",
    "link.change_link_customer",
    "link.delete_link_customer",
    "link.view_link_customer",
  ]);
  return (
    <div className="formPage-wrap min-h-screen ">
      {permissionsFlag ? (
        <div className="w-full">
          <div className="w-full flex justify-center items-center">
            <h2 className="header-h2">
              {" "}
              <RiKeyboardLine className="w-6 h-6" />
              Customers Link
            </h2>
          </div>

          <div className="grid grid-cols-2  form-wrap ">
            {permissions.some((item) => item.name === "link.add_link_customer" && item.value === true) && (
              <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                <Link
                  to={"./create"}
                  // onClick={() => navigate("./pos-machine")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full border border-sky-800 border-opacity-30 hover:bg-teal-700 bg-teal-900  text-white  active:scale-95 duration-500 transition-all"
                >
                  <div className="relative w-full flex justify-around items-center ">
                    <FcBusinessman className="w-[70px] h-[70px] mr-3" />
                    <FcLink className="absolute bottom-3 right-2  w-5 h-5" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Create New Link</p>
              </div>
            )}
            {permissions.some((item) => item.name !== "link.add_link_customer" && item.value === true) && (
              <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                <Link
                  to={"./link-list"}
                  // onClick={() => navigate("./pos-machine")}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full border border-sky-800 border-opacity-30  text-white hover:bg-sky-700 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <FcTodoList className="w-12 h-12" />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Links List</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default CustomersLink;
