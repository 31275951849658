import { set } from "date-fns";
import React from "react";
import { useState } from "react";
import { BiMinusCircle } from "react-icons/bi";
import { MdAddCircleOutline } from "react-icons/md";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
// this component should take
// 1) name: (string) will be put as the name and the id of the select element
// 2) text: (string) will be put as the text of the select element
// 3) type: (string ) to determine the type of input
// 4) Pattern: (regex) optional to show validation error
// 5) errMsg: (string) along with pattern to add custom error message
export default function DynamicFormInputText({ text, name, inputsNum, type, pattern, errMsg, placeholder }) {
  useRemoveScroll();
  // to allow setting the number of inputs upon calling the component
  let inputStateValue = [];
  for (let i = 0; i < inputsNum; i++) {
    inputStateValue.push(1);
  }

  let [input, setInput] = useState(inputStateValue);
  let [error, setError] = useState(false);
  function addInput() {
    if (input.length < 5) {
      setInput([...input, 1]);
    }
  }
  function removeInput() {
    if (input.length > 1) {
      let newArr = input.slice();
      newArr.pop();
      setInput(newArr);
    }
  }
  function check(e) {
    if (pattern && errMsg) {
      if (e.target.value === "") {
        setError(false);
      } else if (!pattern.test(e.target.value)) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }
  return (
    <div className="flex flex-col gap-y-1">
      <div className="w-full flex items-center gap-x-1 text-slate-800 font-semibold">
        <label htmlFor={name} className="text-slate-800 font-semibold">
          {text}
        </label>
      </div>

      {input.map((elem, index) => {
        return (
          <input
            type={type}
            id={`${name}-${index}`}
            name={name}
            key={index}
            className={`w-full px-3 h-10  border rounded-lg bg-white focus:outline-none focus:ring focus:border-blue-300 floating-input text-gray-800 font-semibold `}
            required
            placeholder={placeholder}
            onChange={(e) => check(e)}
          />
        );
      })}
      {error && <div className="error">{errMsg}</div>}
      <div className="w-full flex items-center justify-end gap-x-1 px-3">
        <button
          type="button"
          onClick={addInput}
          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800 "
        >
          <MdAddCircleOutline className="w-5 h-5 " />
        </button>

        <button
          type="button"
          onClick={removeInput}
          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800 "
        >
          <BiMinusCircle className="w-5 h-5 " />
        </button>
      </div>
    </div>
  );
}
