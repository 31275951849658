import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import Loading from "../../../Loading/Loading";
import Errorfetch from "../../../Errorfetch/Errorfetch";
import TicketReplyForm from "./TicketReplyForm";
import TicketReply from "./TicketReply";
import TicketHeader from "./TicketHeader";
import usePermissions from "../../../../../customHooks/usePermissions";
import NoPermission from "../../../NoPermission/NoPermission";

function ITTicketDetails() {
  const [permissions, permissionFlag, permissionsError] = usePermissions(["it_ticketing_system.change_ticketsupport"]);
  const { id } = useParams();

  const canEditTicket =
    permissions?.find((permission) => permission.name === "it_ticketing_system.change_ticketsupport")?.value === true;

  const {
    isLoading,
    data: ticket,
    error,
    refetch: refetchTicketDetails,
  } = useQuery({
    queryKey: ["ticket-details", id],
    queryFn: () => fetchDataQuery(`/it-ticketing-system/api/v1/tickets-support/${id}/`),
    enabled: canEditTicket,
  });

  if (isLoading || !permissionFlag) return <Loading />;

  if (permissionsError) {
    return <Errorfetch Error={"Error fetching permissions"} />;
  }

  if (error) return <Errorfetch Error={"Error fetching ticket details"} />;
  const details = {
    id: ticket?.id,
    createdAt: ticket?.created_at,
    type: ticket?.type_of_request?.name,
    details: ticket?.request_details,
    status: ticket?.status,
    createdBy: ticket?.created_by?.username,
    attachments: ticket?.attachments?.map((attachment) => attachment.file),
    duration_of_task_complete: ticket?.duration_of_task_complete,
    request_name: ticket?.request_name,
    website: ticket?.website.name,
    priority: ticket.priority,
  };
  const replies = ticket?.comments?.map((comment) => {
    return {
      username: comment?.user?.username,
      userId: comment?.user?.id,
      avatar: comment?.user?.avatar,
      createdAt: comment?.created_at,
      reply: comment?.comment,
      attachments: comment?.attachments?.map((attachment) => attachment.file),
    };
  });

  return canEditTicket ? (
    <div className="min-h-[100dvh]">
      <TicketHeader details={details} />
      <div className="space-y-2 mt-4 mb-4">
        {replies?.map((reply) => {
          return <TicketReply reply={reply} />;
        })}
        <TicketReplyForm refetchTicketDetails={refetchTicketDetails} />
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}

export default ITTicketDetails;
