import { Button, Modal, Table } from "flowbite-react";
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment-hijri";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";

const PriceDetailsModal = ({
  OpenpriceDetails,
  setOpenpriceDetails,
  daysDataList,
  currencySearch,
  currency,
  offerType,
}) => {
  const [t, i18n] = useTranslation();

  const {
    data: currencyRateData,
    error: currencyError,
    isLoading: currencyloading,
  } = useQuery({
    queryKey: ["exhange-rate", currency],
    queryFn: ({ queryKey }) => {
      const [_key, name] = currency;
      return fetchDataQuery(`/site-settings/api/exhange-rate?from_currency=${currency}&to_currency=${currencySearch}`);
    },
    enabled: !!OpenpriceDetails,
  });

  return (
    <div>
      <Modal size={"xl"} dismissible show={OpenpriceDetails} onClose={() => setOpenpriceDetails(false)}>
        <Modal.Header></Modal.Header>
        <Modal.Body dir={t("dir")}>
          {currencyloading ? (
            <>
              <Loading /> <TopBarProgress />
            </>
          ) : (
            <div className="h-[60vh] overflow-auto !p-0">
              <p className="px-6 py-2 text-cyan-900 font-semibold">{t("pricesDetails")}</p>
              <div className="overflow-x-auto">
                <Table striped dir={t("dir")} className={`${t("lan") === "en" ? " text-left" : "text-right"} `}>
                  <Table.Head>
                    <Table.HeadCell>{t("date")}</Table.HeadCell>
                    <Table.HeadCell>{t("Price")}</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {daysDataList?.map((day) => {
                      // Assuming there's at least one item in currencyRate.results
                      const rate = currencyRateData?.results[0] ? currencyRateData.results[0].rate : 1;
                      const mealsOffers = day.meals_offers;
                      // Assuming there is only one key in meals_offers, you can get it like this:
                      const mealType = Object.keys(mealsOffers)[0];
                      const mealDetails = mealsOffers[mealType];

                      const totalPrice = (
                        day.contract_meal_included === offerType
                          ? day.total_price * rate
                          : mealDetails.total_price * rate
                      ).toFixed(2);

                      return (
                        <Table.Row key={day.id} className="bg-white  dark:border-gray-700 dark:bg-gray-800">
                          <Table.Cell className="whitespace-nowrap font-medium text-cyan-700">
                            {moment(day.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                          </Table.Cell>

                          <Table.Cell className="whitespace-nowrap flex gap-1 font-medium text-green-700">
                            {totalPrice}
                            <span className="md:text-md text-sm font-semibold text-yellow-600">
                              {t(currencySearch)}
                            </span>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={() => setOpenpriceDetails(false)}>
            {t("exit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PriceDetailsModal;
