import { GET_POS_MACHINE_BATCH  , CREATE_NEW_BATCH , CREATE_NEW_BATCH_FLAG , GET_ALL_BATCH , GET_ALL_BATCH_FLAG , DELETE_BATCH_FLAG , GET_SELECTED_BATCH , GET_SELECTED_BATCH_FLAG} from "../types/types";

const initialState = {
    posMachineBatch : null,
    createNewBatch:null,
    createNewBatchFlag:false,
    getAllBatch:null,
    getAllBatchFlag:false,
    deleteBatchFlag:false,
    getSelcBatch:null,
    getSelcBatchFlag:false
   
}
export const newBatchReducer  = (state = initialState , action) => {
    switch(action.type){
        case GET_POS_MACHINE_BATCH:
            return{...state , posMachineBatch : action.payload }
        case CREATE_NEW_BATCH:
            return{...state , createNewBatch : action.payload }
        case CREATE_NEW_BATCH_FLAG:
            return{...state , createNewBatchFlag : action.payload }
        case GET_ALL_BATCH:
            return{...state , getAllBatch : action.payload }
        case GET_ALL_BATCH_FLAG:
            return{...state , getAllBatchFlag : action.payload }
        case DELETE_BATCH_FLAG:
            return{...state , deleteBatchFlag : action.payload }
        case GET_SELECTED_BATCH:
            return{...state , getSelcBatch : action.payload }
        case GET_SELECTED_BATCH_FLAG:
             return{...state , getSelcBatchFlag : action.payload }
        default:
            return state;
    }
}