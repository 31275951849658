import api_token from "../../api/UserApi";
import cookie from "react-cookies";
const token = cookie.load("access_token");

export const switchUserAction = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post(`/auth/api/v1/impersonate/`, id);

      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const chechSuperUserAction = (callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post(`/auth/api/v1/is_superuser/`);

      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
