import { GET_EMAIL_DATA, GET_EMAIL_DATA_FLAG , POST_EMAIL_DATA_FLAG } from "../types/types";

const initialState = {
    getMail:null,
    getMailFlag:false,
    postMailFlag:false,
}
export const bookingMailReducer = (state = initialState , action) => {
    switch(action.type)
    {
        case GET_EMAIL_DATA : 
             return{...state , getMail : action.payload}
        case GET_EMAIL_DATA_FLAG : 
             return{...state , getMailFlag : action.payload}
        case POST_EMAIL_DATA_FLAG : 
             return{...state , postMailFlag : action.payload}
         default:
             return state;
    }

}