import React, { useState, useEffect } from "react";

export default function DynamicCheckBox({ defaultChecked, name, onChange, room, date }) {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  useEffect(() => {
    setIsChecked(defaultChecked); // Initialize the state when defaultChecked changes
  }, [defaultChecked]);

  function change(e) {
    setIsChecked(!isChecked);
    if (onChange) {
      onChange(e, room, date);
    }
  }
  return <input type="checkbox" checked={isChecked} name={name} onChange={change} />;
}
