import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import { useNavigate } from "react-router";
import { IoAdd } from "react-icons/io5";
import { FaWpforms } from "react-icons/fa6";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
// import jwtDecode from 'jwt-decode';
import TopBarProgress from "react-topbar-progress-indicator";
import { addCashBoxAction } from "../../../redux/actions/addCashBoxAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const AddCashBox = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCurrencyAction(token));
  }, []);
  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { cashBoxFlag } = useSelector((state) => state.addCashBoxReducer);
  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    dispatch(
      addCashBoxAction(token, formData, (result) => {
        if (result.status === 201) {
          lightToast.success("Cash Box was completed Added successfully");
          navigate(-1);
        }
      }),
    );
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["box.add_box"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="formPage-wrap ">
      <div className="flex flex-col items-center justify-start h-full w-full">
        {currencyList && permissionFlag ? (
          permission.map((item) => {
            {
            }
            if (item.name === "box.add_box" && item.value === true) {
              return (
                <div className=" w-full ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      {" "}
                      <FaWpforms className="w-5 h-5" />
                      Add Cash Box
                    </h2>
                  </div>
                  <form className="form-wrap" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <div className="flex w-full max-sm:flex-col gap-4">
                        <div className="flex-1 max-sm:w-full">
                          <label className=" text-center w-full text-gray-600   ">Box Name</label>
                          <input
                            placeholder="Box Name"
                            name="name"
                            type="text"
                            required
                            // onChange={(e)=>setName(e.target.value)}
                            className=" w-full px-3 h-10  border rounded-lg bg-white "
                          />
                        </div>
                        <div className="flex-1 max-sm:w-full">
                          <label className=" flex text-gray-600 ">Currency</label>
                          <Select
                            name="currency_type"
                            options={CurrencyType}
                            isSearchable
                            required
                            className=" w-full  h-10  border rounded-lg  bg-white "
                            placeholder="Currency"
                          />
                        </div>
                      </div>

                      <label className=" text-center w-full text-gray-600   ">Location</label>
                      <input
                        placeholder="Location"
                        name="location"
                        type="text"
                        required
                        // onChange={(e)=>setName(e.target.value)}
                        className=" w-full px-3 h-10  border rounded-lg bg-white "
                      />
                      <div className="flex justify-center">
                        <button type="Submit" disabled={cashBoxFlag} className="addButton">
                          Add{" "}
                          {cashBoxFlag ? (
                            <>
                              <TopBarProgress />
                              <ButtonLoading />
                            </>
                          ) : (
                            <IoAdd className="w-5 h-5" />
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              );
            } else {
              return (
                <div className="w-full h-screen">
                  <NoPermission />
                </div>
              );
            }
          })
        ) : (
          <>
            <TopBarProgress /> <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default AddCashBox;
