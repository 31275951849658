import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export function getWhitelistIPs(successCallback) {
  return async function () {
    try {
      const response = await api_token(token).get(`/auth/api/v1/whitelisted-ips/`);
      if (response.status === 200) {
        successCallback(response.data);
      }
    } catch (error) {}
  };
}

export function deleteIPAddress(id, successCallback) {
  return async function () {
    try {
      const response = await api_token(token).delete(`/auth/api/v1/whitelisted-ips/${id}/`);
      if (response.status === 204) {
        successCallback();
      }
    } catch (error) {}
  };
}
