import api_token from "../../api/UserApi";
import { CONVERTING_CURRENCY_DATA, CONVERTING_CURRENCY_FLAG } from "../types/types";

export const convertingCurrencyAction = (token, from, to, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONVERTING_CURRENCY_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(
        `en/site-settings/api/exhange-rate?from_currency=${from}&to_currency=${to}`,
      );
      dispatch({
        type: CONVERTING_CURRENCY_DATA,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: CONVERTING_CURRENCY_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
