import api_token from "../../api/UserApi";
import { ADD_BANK, ADD_BANK_FLAG } from "../types/types";

export const addBankAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_BANK_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`accounting/api/v1/banks/`, data);
      dispatch({
        type: ADD_BANK,
        payload: response.data,
      });
      if (response.status === 201) {
        dispatch({
          type: ADD_BANK_FLAG,
          payload: false,
        });
      }
      callback(response);
    } catch (error) {}
  };
};

export const getAllBankAction = (token, callback, ErrorCallBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/banks/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
export const getBankByIdAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/banks/${id}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
export const UpdateBankByIdAction = (token, id, values, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`accounting/api/v1/banks/${id}/`, values);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const deleteBank = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`accounting/api/v1/banks/${id}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
