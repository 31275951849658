import React, { useEffect, useState } from "react";
import { getAllAirline, HarddeleteAirportAction } from "../../../redux/actions/airportServiceAction";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import {
  MdDeleteForever,
  MdFlight,
  MdInfoOutline,
  MdOutlineMiscellaneousServices,
  MdOutlineSettings,
} from "react-icons/md";
import { LiaCalendar } from "react-icons/lia";
import { GoPerson } from "react-icons/go";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import lightToast from "light-toast";
// import useHotelNames from "../../../customHooks/useHotelNames";
import { IoMdTime } from "react-icons/io";
import { SiEthiopianairlines } from "react-icons/si";
import usePermissions from "../../../customHooks/usePermissions";
import { TbListDetails, TbRestore } from "react-icons/tb";
import { getAllAssistAction } from "../../../redux/actions/meetAssistAction";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import ListFilters from "../../../utils/ListFilters";

const SoftDeleteList = ({ general }) => {
  const [load, setLoad] = useState(false);
  const [id, setId] = useState();
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();
  // const hotels = useHotelNames();
  const params = useParams();
  const [airlines, setAirlines] = useState();
  const [members, setMembers] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [listPageSize, setListPageSize] = useState(10);
  const [paramsFilter, setParamsFilter] = useState([]);
  const [passenger, setPassenger] = useState();
  const [flag, setFlag] = useState(false);

  const [permissions, permissionsFlag] = usePermissions([
    "airport_services.view_airportlimousineservice",
    "airport_services.change_airportlimousineservice",
    "airport_services.delete_airportlimousineservice",
    "permissions_management.meet_assist_list_soft_deleted",
  ]);

  const location = useLocation();
  // Access the pathname property to get the current path
  const currentPath = location.pathname;
  const parts = currentPath.split("/");
  const lastParams = parts[parts.length - 2];

  ////// today Date ///////////
  const todayDate = new Date().toISOString().slice(0, 10);
  /////////////////////////////

  const {
    data: airports,
    error,
    isLoading: airportsisLoading,
    refetch,
  } = useQuery({
    queryKey: ["get-soft-deleted"],
    queryFn: () => fetchDataQuery(`/airport-services/api/v1/limousine-services/get-soft-deleted/`),
  });

  const {
    mutate,
    isError,
    isPending: restoreisPending,
  } = useMutation({
    mutationFn: (id) => mutateDataQuery(`/airport-services/api/v1/limousine-services/${id}/restore/`, "post"),
    onError: (error) => {
      // An error happened!
      toast.error(`${error || "Your Group was not Edited"}  `);
    },
    onSuccess: () => {
      // Boom baby!
      refetch();
      toast.success(" restore successfully");
    },
  });

  useEffect(() => {
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ///////////////////////////////////////////////////////////

    dispatch(
      getAllAssistAction(signal, (result) => {
        if (result.status === 200) {
          setMembers(result.data);
        }
      }),
    );

    dispatch(
      getAllAirline(signal, (result) => {
        if (result.status === 200) {
          setAirlines(result.data);
        }
      }),
    );

    return () => {
      controller.abort();
      // window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [params.page, location, paramsFilter]);

  //Hiding all columns expect passenger name and operations

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    // Function to check if the screen size is small
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check
    checkScreenSize();

    // Event listener to handle screen size changes
    window.addEventListener("resize", checkScreenSize);

    // Clean up
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    const initialColumnVisibility = {};
    columns.forEach((column) => {
      if (isSmallScreen) {
        // Show only necessary columns for small screens
        initialColumnVisibility[column.key] = [
          "passengers_name",
          "representative_name",
          "time_of_arrival",
          "flight_number",
          "flight_date",
          "operations",
        ].includes(column.key);
      } else {
        // Show all columns for larger screens
        initialColumnVisibility[column.key] = true;
      }
    });
    setColumnVisibility(initialColumnVisibility);
  }, [isSmallScreen]);

  ////////// Rows /////////////////
  let rows;
  if (general) {
    rows = airports?.map((item, index) => {
      return {
        id: item.id,
        random_id: item.random_id,
        flight_date: item.time_of_arrival.slice(0, 10),
        service_type: item.service_type,
        passengers_name: item.passengers_name,
        representative_name: item.representative_name?.name,
        booking_order: item.booking_order,
        booking_id: item.booking_id,
        flight_number: item.flight_number,
        time_of_arrival: item.time_of_arrival.slice(11, 19),
        coming_from: item.coming_from,
        hotel_name: item.hotel_name,
        airlines_name: item.airline_name,
        order_type: item.order_type,
        status: item.status,
      };
    });
  } else {
    rows = airports?.map((item, index) => {
      return {
        id: item.id,
        random_id: item.random_id,
        flight_date: item.time_of_arrival.slice(0, 10),
        service_type: item.service_type,
        passengers_name: item.passengers_name,
        representative_name: item.representative_name?.name,
        booking_order: item.booking_order,
        booking_id: item.booking_id,
        flight_number: item.flight_number,
        time_of_arrival: item.time_of_arrival.slice(11, 19),
        coming_from: item.coming_from,
        hotel_name: item.hotel_name,
        airlines_name: item.airline_name,
        order_type: item.order_type,
        status: item.status,
      };
    });
  }

  /////// Coloumns //////////
  const columns = [
    {
      key: "flight_date",
      title: (
        <div className={`flex items-center gap-x-1 text-xs 	 `}>
          <LiaCalendar className="w-5 h-5  text-yellow-500 " />
          FLIGHT DATE
        </div>
      ),

      dataIndex: "flight_date",
      editable: false,
      render: (text, params) => {
        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.flight_date}
          </p>
        );
      },
    },
    {
      key: "service_type",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <MdOutlineMiscellaneousServices className="w-5 h-5  text-yellow-500" />
          SERVICE TYPE
        </div>
      ),

      dataIndex: "service_type",
      editable: false,
      render: (text, params) => {
        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.service_type}
          </p>
        );
      },
    },

    {
      key: "passengers_name",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <GoPerson className="w-5 h-5 text-yellow-500" />
          NAME
        </div>
      ),
      dataIndex: "passengers_name",
      editable: false,
      render: (text, params) => {
        let passengerLen = params?.passengers_name[0]?.split(" ");

        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {passengerLen && passengerLen.length >= 4
              ? passengerLen[1] === "بن" || passengerLen[1] === "بنت" || passengerLen[1] === "عبد"
                ? `${passengerLen[0]} ${passengerLen[1]} ${passengerLen[2]} ${passengerLen[passengerLen.length - 1]}`
                : `${passengerLen[0]} ${passengerLen[1]}  ${passengerLen[passengerLen.length - 1]}`
              : params?.passengers_name[0]}
          </p>
        );
      },
    },

    {
      key: "flight_number",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <MdFlight className="w-5 h-5  text-yellow-500" />
          FLIGHT
        </div>
      ),
      dataIndex: "flight_number",
      editable: false,
      render: (text, params) => {
        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.flight_number}
          </p>
        );
      },
    },
    {
      key: "time_of_arrival",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <IoMdTime className="w-5 h-5  text-yellow-500" />
          ARRIVAL TIME
        </div>
      ),

      dataIndex: "time_of_arrival",
      editable: false,
      render: (text, params) => {
        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.time_of_arrival}
          </p>
        );
      },
    },

    {
      key: "airlines_name",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <SiEthiopianairlines className="w-5 h-5  text-yellow-500" />
          AIRLINE
        </div>
      ),
      dataIndex: "airlines_name",
      editable: false,
      render: (text, params) => {
        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {/* {airlines.filter((item) => item.id === params?.airlines_name)[0]?.name} */}
            {params?.airlines_name?.name}
          </p>
        );
      },
    },

    {
      key: "representative_name",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <GoPerson className="w-5 h-5  text-yellow-500" />
          REP NAME
        </div>
      ),
      dataIndex: "representative_name",
      editable: false,
      render: (text, params) => {
        let repArr = params?.representative_name && params?.representative_name.split(" ");

        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {repArr?.length > 0 && `${repArr[0]} ${repArr[repArr.length - 1]}`}
          </p>
        );
      },
    },

    {
      key: "order_type",
      dataIndex: "order_type",

      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <MdInfoOutline className="w-5 h-5  text-yellow-500" />
          ORDER TYPE
        </div>
      ),

      editable: false,
      render: (text, params) => {
        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.order_type}
          </p>
        );
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <MdInfoOutline className="w-5 h-5  text-yellow-500" />
          STATUS
        </div>
      ),

      editable: false,
      render: (text, params) => {
        return (
          <p
            className={`w-full h-full flex items-center pl-2 ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.status}
          </p>
        );
      },
    },

    {
      key: "operations",
      filterable: false,
      sortable: false,

      dataIndex: "operations",

      title: (
        <div className=" flex items-center justify-center w-full text-xs uppercase ">
          <MdOutlineSettings className="w-5 h-5 ml-8 md:ml-4 text-yellow-500" />
          {/* {!isSmallScreen && "OPERATIONS"} */}Operations
        </div>
      ),

      render: (text, params) => {
        return (
          <div
            className={`${
              todayDate === params?.flight_date && "text-green-800"
            } flex items-center justify-start gap-x-1 md:gap-x-1.5 w-full `}
          >
            {/* {permissions.some((item) => item.name === "airport_services.view_airportlimousineservice" && item.value) &&
              (general ? (
                <Link
                  to={`/dashboard/meet-&-assist-service/all-meet-service/${lastParams}/${params?.id}/preview`}
                  className="flex items-center gap-x-1  bg-yellow-400 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                >
                  <AiOutlineEye className="w-5 h-5  text-gray-700 " />
                </Link>
              ) : (
                <Link
                  to={`/dashboard/meet-&-assist-service/all-meet-service/New/${params?.id}/preview`}
                  className="flex items-center gap-x-1  bg-yellow-400 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                >
                  <AiOutlineEye className="w-5 h-5 text-gray-700 " />
                </Link>
              ))}
            {permissions.some(
              (item) => item.name === "airport_services.change_airportlimousineservice" && item.value,
            ) &&
              (general ? (
                <Link
                  to={`/dashboard/meet-&-assist-service/all-meet-service/${lastParams}/${params?.id}/update`}
                  className="flex items-center gap-x-1 bg-green-700 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                >
                  <RxUpdate className="w-5 h-5 text-white " />
                </Link>
              ) : (
                <Link
                  to={`/dashboard/meet-&-assist-service/all-meet-service/New/${params?.id}/update`}
                  className="flex items-center gap-x-1 bg-green-700 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                >
                  <RxUpdate className="w-5 h-5 text-white" />
                </Link>
              ))} */}
            {permissions.some(
              (item) => item.name === "airport_services.delete_airportlimousineservice" && item.value,
            ) && (
              <button
                onClick={() => {
                  mutate(params?.id);
                }}
                className="flex items-center gap-x-1 bg-cyan-700 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-cyan-600 active:scale-95 duration-300 transition-all"
              >
                <TbRestore className="w-5 h-5 text-white" />
              </button>
            )}
            {permissions.some(
              (item) => item.name === "airport_services.delete_airportlimousineservice" && item.value,
            ) && (
              <button
                onClick={() => {
                  setId(params?.id);
                  setConfirm(true);
                  setPassenger(params?.passengers_name);
                }}
                className="flex items-center gap-x-1 bg-red-800 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all"
              >
                <MdDeleteForever className="w-5 h-5 text-white" />
              </button>
            )}
            {/* <Link
              to={`/workorder/${params.row.random_id}`}
              target="_blank" // Add this line to open the link in a new tab
              className="flex items-center gap-x-1 bg-cyan-900 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-cyan-700 active:scale-95 duration-300 transition-all"
            >
              <FaRegImage className="w-5 h-5 text-white" />
            </Link> */}
          </div>
        );
      },
    },
  ];

  //////// HANDLE DELETE /////////
  const handleDelete = () => {
    setLoad(false);
    dispatch(
      HarddeleteAirportAction(id, (result) => {
        if (result.status === 204) {
          lightToast.success("This Airport was completed Deleted");
          refetch();
        }
      }),
    );
  };

  const navigate = useNavigate();

  return (
    <>
      {confirm && (
        <div
          className={` fixed ${
            confirm ? "top-0 left-0 right-0 bottom-0 h-screen  " : ""
          }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${
              confirm
                ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <p className="text-2xl font-light  text-white text-center px-2 capitalize">
              Are You Sure you want to delete {passenger[0]} request ?
            </p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id);
                }}
                className="bg-green-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      <>
        <div className="w-full flex flex-col md:flex-row items-center justify-center md:justify-between gap-y-2 pt-3 pl-1 md:pl-5  md:pr-8 ">
          <div className=" flex flex-col md:flex-row items-center gap-2 md:gap-5">
            <Link
              to={
                general
                  ? "/dashboard/meet-&-assist-service"
                  : `/dashboard/booking-list-single-id/${params.short_id}/${params.id}/meet-service`
              }
              className="flex items-center gap-x-1  text-sm md:text-2xl font-normal text-[#001a35]  hover:text-yellow-700 duration-500 transition-all active:scale-95"
            >
              <TbListDetails className="w-3 h-3 md:w-5 md:h-5 text-yellow-600" />
              Soft Deleted List
            </Link>
          </div>
          <div className="w-full md:w-fit flex items-center justify-center gap-x-2">
            <div className="w-3 h-3 md:w-4 md:h-4 bg-green-900 rounded-sm"></div>
            <p className="text-xs md:text-sm font-semibold text-[#001a35] capitalize ">
              The green color indicates today's date
            </p>
          </div>
        </div>
      </>
      {airlines && members && permissionsFlag && !airportsisLoading && !restoreisPending ? (
        permissions?.some((item) => item.value) && (
          <div className="md:px-4 px-2">
            <ListFilters columns={columns} rows={rows} />
          </div>
          // <div className="w-full h-[calc(100vh - 100px)] overflow-x-hidden">
          //   <Box sx={{ height: "90%", width: "100%", backgroundColor: "white", overflowX: "hidden" }}>
          //     <div className="w-full relative h-[97%] overflow-x-auto ">
          //       <DataGrid
          //         rows={rows}
          //         columns={columns}
          //         // onSortModelChange={comparator}
          //         columnVisibilityModel={columnVisibility}
          //         onColumnVisibilityModelChange={(visibility) => {
          //           setColumnVisibility(visibility);
          //         }}
          //         pageSizeOptions={[1, 5, 10, 20, 25, 100]} // Specify the available page size options
          //         className=" xl:w-full  w-[250%]"
          //       />
          //     </div>
          //   </Box>
          // </div>
        )
      ) : (
        <>
          {" "}
          <TopBarProgress />
          <div className="w-full h-screen flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </>
  );
};

export default SoftDeleteList;
