import axios from "axios";
import cookie from "react-cookies";
import { base_URL } from "../urls";
const token = cookie.load("access_token");

const api = axios.create({
  baseURL: base_URL,
  headers: token, // Replace with your API's base URL
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (token) => {
  refreshSubscribers.forEach((subscriber) => subscriber(token));
  refreshSubscribers = [];
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the request fails due to an expired token
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshToken = cookie.load("refresh_token"); // Retrieve the refresh token from storage
          const response = await axios.post("/auth/api/v1/token/refresh/", {
            refreshToken,
          });

          const newAccessToken = response.data.accessToken;

          // Save the new access token to storage
          cookie.save("access_token", newAccessToken);

          // Call subscribers with the new token
          onRefreshed(newAccessToken);
        } catch (refreshError) {
          cookie.remove("access_token", { path: "/" });
          cookie.remove("refresh_token", { path: "/" });
        } finally {
          isRefreshing = false;
        }
      }

      const retryOriginalRequest = new Promise((resolve) => {
        refreshSubscribers.push((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  },
);

export default api;
