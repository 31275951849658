import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export default function editHotel(slug, data, callback) {
  return async function () {
    try {
      const response = await api_token(token).put(`/en/hotels/api/v1/get-put-delete-hotels/${slug}/`, data, {
        headers: {
          "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });

      if (callback) {
        callback();
      }
    } catch (error) {}
  };
}
