import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getSelectedCollectors } from "../../../redux/actions/collectorsAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const CollectorsDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const [collectionData, setCollectionData] = useState();
  useEffect(() => {
    setLoad(true);
    dispatch(
      getSelectedCollectors(token, params.id, (result) => {
        if (result.status === 200) {
          setLoad(false);
          setCollectionData(result.data);
        }
      }),
    );
  }, []);

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.view_collector"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full  overflow-x-auto">
      {load && !permissionFlag ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      ) : (
        collectionData &&
        permissionFlag &&
        permission?.map((item) => {
          if (item.name === "room_booking.view_collector" && item.value === true) {
            return (
              <section key={item.id} className="formPage-wrap">
                <div className="flex justify-center">
                  <h2 className="header-h2">Cash Box List </h2>
                </div>
                {collectionData ? (
                  <div className="">
                    <table className="min-w-full max-lg:hidden form-wrap">
                      <thead className="">
                        <tr className="bg-gray-50">
                          <th className="p-2 font-bold border-r border-b">ID</th>
                          <th className="p-2 font-bold border-r border-b">Name</th>
                          <th className="p-2 font-bold border-r border-b">Mobile</th>
                          <th className="p-2 font-bold border-r border-b">WhatsApp</th>
                          <th className="p-2 font-bold border-r border-b">Department</th>
                          <th className="p-2 font-bold border-r border-b">Joining Day</th>
                          <th className="p-2 font-bold border-r border-b">Notes</th>
                          <th className="p-2 font-bold border-r border-b">Created At</th>
                          <th className="p-2 font-bold border-b ">Updated At</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=" text-center">
                          <td className="p-2 border-r">{collectionData.id}</td>
                          <td className="p-2 border-r">{collectionData.collector_name}</td>
                          <td className="p-2 border-r">{collectionData.collector_mobile}</td>
                          <td className="p-2 border-r">{collectionData.collector_whatsapp}</td>
                          <td className="p-2 border-r">{collectionData.collector_department}</td>
                          <td className="p-2 border-r">{collectionData.joining_date}</td>
                          <td className="p-2 border-r">{collectionData.notes}</td>
                          <td className="p-2 border-r">{collectionData?.created_date?.slice(0, 10)}</td>
                          <td className="p-2 border-r">{collectionData?.updated_at?.slice(11, 19)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="min-w-full border lg:hidden form-wrap">
                      <tbody>
                        <tr className="border">
                          <td className="p-2 border-r font-bold">ID:</td>
                          <td className="p-2">{collectionData.id}</td>
                        </tr>
                        <tr className="bg-gray-100 border">
                          <td className="p-2 border-r font-bold">Name:</td>
                          <td className="p-2">{collectionData.collector_name}</td>
                        </tr>
                        <tr className="border">
                          <td className="p-2 border-r font-bold">Mobile:</td>
                          <td className="p-2">{collectionData.collector_mobile}</td>
                        </tr>
                        <tr className="bg-gray-100 border">
                          <td className="p-2 border-r font-bold">WhatsApp:</td>
                          <td className="p-2">{collectionData.collector_whatsapp}</td>
                        </tr>
                        <tr className="border">
                          <td className="p-2 border-r font-bold">Department:</td>
                          <td className="p-2">{collectionData.collector_department}</td>
                        </tr>
                        <tr className="bg-gray-100 border">
                          <td className="p-2 border-r font-bold">Joining Day:</td>
                          <td className="p-2">{collectionData.joining_date}</td>
                        </tr>
                        <tr className="border">
                          <td className="p-2 border-r font-bold">Notes:</td>
                          <td className="p-2">{collectionData.notes}</td>
                        </tr>
                        <tr className="bg-gray-100 border">
                          <td className="p-2 border-r font-bold">Created At:</td>
                          <td className="p-2">{collectionData?.created_date?.slice(0, 10)}</td>
                        </tr>
                        <tr className="border">
                          <td className="p-2 border-r font-bold">Updated At:</td>
                          <td className="p-2">{collectionData?.updated_at?.slice(11, 19)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Loading />
                )}
              </section>
            );
          } else {
            return (
              <div className="w-full h-screen ">
                <NoPermission />
              </div>
            );
          }
        })
      )}
    </div>
  );
};

export default CollectorsDetails;
