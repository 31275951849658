import React from "react";
import AddNewNoteModal from "./modals/AddNewNoteModal";
import NotesFilter from "./components/NotesFilter";
import Notes from "./components/Notes";

function NotesPage() {
  return (
    <div className="min-h-[80dvh] space-y-8 flex flex-col">
      <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between items-center mb-4 px-4">
        <NotesFilter />
        <AddNewNoteModal />
      </div>

      <Notes />
    </div>
  );
}

export default NotesPage;
