import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCollectionAction, getAllCollectionAction } from "../../../redux/actions/collectionAction";
import cookie from "react-cookies";
import lightToast from "light-toast";
import { AiFillDelete, AiOutlineEye, AiOutlineInfoCircle, AiOutlinePhone } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { MdOutlineAccessTime } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import { LiaCalendar } from "react-icons/lia";
import { BiTransfer } from "react-icons/bi";
import { PiDatabase, PiNoteFill } from "react-icons/pi";
import { GiRailRoad } from "react-icons/gi";
import { BsCollection } from "react-icons/bs";
import { RiSecurePaymentLine } from "react-icons/ri";
import ListFilters from "../../../utils/ListFilters";

const AllCollectionRequest = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const params = useParams();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [id, setId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [checkYes, setCheckYes] = useState(false);
  const [checkNo, setCheckNo] = useState(false);
  const { collectionData } = useSelector((state) => state.collectionReducer);
  const { collectionDeleteFlag } = useSelector((state) => state.collectionReducer);

  useEffect(() => {
    dispatch(
      getAllCollectionAction(token, params.page, params.sorting, (result) => {
        if (result.status === 200) {
          setCheck(true);
        }
      }),
    );
  }, []);

  const handleDelete = (id) => {
    dispatch(
      deleteCollectionAction(token, id, (result) => {
        if (result.status === 204) {
          lightToast.success("The Representative Collection was completed Deleted");
          dispatch(
            getAllCollectionAction(token, params.page, params.sorting, (result) => {
              if (result.status === 200) {
                setCheck(true);
              }
            }),
          );
        }
      }),
    );
  };
  const rows =
    collectionData &&
    collectionData.results.map((item) => {
      return {
        id: item.id,
        created_date: item.created_date.slice(0, 10),
        created_time: item.created_date.slice(11, 19),
        collection_purpose: item.collection_purpose,
        booking_order_no: item.booking_order_no,
        booking_id_no: item.booking_id_no,
        hotel_confirmation_number: item.hotel_confirmation_number,
        client_number: item.client_number,
        collection_type: item.collection_type,
        collection_date: item.collection_time.slice(0, 10),
        collection_time: item.collection_time.slice(11, 19),
        collection_address: item.collection_address,
        notes: item.notes,
        collection_satus: item.collection_satus,
        collector_name: item.collector_name,
        short_id: item.booking_order,
      };
    });
  const columns = [
    {
      key: "id",
      title: (
        <div className=" text-center  flex justify-center items-center gap-x-1  text-[11px] font-semibold">
          <PiDatabase className="w-4 h-4   text-yellow-500 mb-1" /> ID{" "}
        </div>
      ),
      width: 100,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 ml-4 h-full"
          >
            {params.id}
          </button>
        );
      },
    },

    {
      key: "created_date",
      title: (
        <div className="flex items-center gap-x-1   text-[11px] font-semibold">
          <LiaCalendar className="w-4 h-4  text-yellow-500" />
          CREATED DATE
        </div>
      ),
      width: 150,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.created_date}
          </button>
        );
      },
    },
    {
      key: "created_time",
      title: (
        <div className="flex items-center gap-x-1   text-[11px] font-semibold">
          <MdOutlineAccessTime className="w-4 h-4 text-yellow-500" />
          CREATED TIME
        </div>
      ),
      width: 150,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.created_time}
          </button>
        );
      },
    },
    {
      key: "collection_purpose",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <BsCollection className="w-4 h-4 text-yellow-500" />
          COLLECTION PURPOSE
        </div>
      ),
      width: 200,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.collection_purpose}
          </button>
        );
      },
    },
    {
      key: "booking_order_no",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          BOOKING ORDER{" "}
        </div>
      ),
      width: 150,
      editable: false,
      render: (text, params) => {
        return (
          <div className="flex items-center gap-x-2 ml-10 ">
            {params.booking_order_no}
            <button onClick={() => navigate(`/dashboard/booking-list-id/${params.short_id}`)}>
              <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
            </button>
          </div>
        );
      },
    },
    {
      key: "booking_id_no",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          BOOKING ID{" "}
        </div>
      ),
      width: 150,
      editable: false,
      render: (text, params) => {
        return (
          <button className="w-full flex items-center gap-x-2 px-8 h-full">
            {params.booking_id_no}
            <button onClick={() => navigate(`/dashboard/booking-list-id/${params.short_id}`)}>
              <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
            </button>
          </button>
        );
      },
    },
    {
      key: "hotel_confirmation_number",
      title: (
        <div className="flex items-center gap-x-1 text-[11px] font-semibold">
          <BiTransfer className="w-4 h-4  text-yellow-500" />
          HOTEL CONFIRMATION
        </div>
      ),
      width: 170,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.hotel_confirmation_number}
          </button>
        );
      },
    },
    {
      key: "client_number",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <AiOutlinePhone className="w-4 h-4 text-yellow-500" />
          CLIENT NUMBER{" "}
        </div>
      ),
      width: 170,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.client_number}
          </button>
        );
      },
    },
    {
      key: "collection_type",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <RiSecurePaymentLine className="w-4 h-4 text-yellow-500" />
          COLLECTION TYPE{" "}
        </div>
      ),
      width: 150,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full capitalize"
          >
            {params.collection_type}
          </button>
        );
      },
    },
    {
      key: "collection_date",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <LiaCalendar className="w-4 h-4 text-yellow-500" />
          COLLECTION DATE{" "}
        </div>
      ),
      width: 150,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.collection_date}
          </button>
        );
      },
    },
    {
      key: "collection_time",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <MdOutlineAccessTime className="w-4 h-4 text-yellow-500" />
          COLLECTION TIME{" "}
        </div>
      ),
      width: 170,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.collection_time}
          </button>
        );
      },
    },
    {
      key: "collection_address",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <GiRailRoad className="w-4 h-4 text-yellow-500" />
          COLLECTION ADDRESS{" "}
        </div>
      ),
      width: 170,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.collection_address}
          </button>
        );
      },
    },
    {
      key: "notes",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <PiNoteFill className="w-4 h-4 text-yellow-500" />
          NOTES
        </div>
      ),
      width: 120,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full"
          >
            {params.notes}
          </button>
        );
      },
    },
    {
      key: "collection_satus",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <AiOutlineInfoCircle className="w-4 h-4 text-yellow-500" />
          COLLECTION STATUS
        </div>
      ),
      width: 150,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 px-8 h-full capitalize"
          >
            {params.collection_satus}
          </button>
        );
      },
    },
    {
      key: "collector_name",
      title: (
        <div className="flex items-center gap-x-1  text-[11px] font-semibold">
          <AiOutlineInfoCircle className="w-4 h-4 text-yellow-500" />
          COLLECTION NAME
        </div>
      ),
      width: 150,
      editable: false,
      render: (text, params) => {
        return (
          <button
            onClick={() => navigate(`/dashboard/collection-requests/preview/${params.id}`)}
            className="w-full flex items-center gap-x-2 ml-12 h-full"
          >
            {params.collector_name}
          </button>
        );
      },
    },
    {
      key: "operations",
      width: 150,
      title: <div className="flex items-center gap-x-1  text-[11px] font-semibold">OPERATIONS</div>,
      render: (text, params) => {
        return (
          <div className="flex items-center  gap-x-3 ml-5">
            <Link
              to={`/dashboard/collection-requests/preview/${params.id}`}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-green-800 " />
            </Link>
            <button
              onClick={() => {
                setId(params.id);
                setConfirm(true);
              }}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <AiFillDelete className="w-5 h-5 text-red-700" />
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <div className="w-full formPage-wrap">
      {confirm && (
        <div
          className={` fixed ${
            confirm ? "top-0 left-0 right-0 bottom-0 h-screen  " : ""
          }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${
              confirm
                ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <p className="text-2xl font-light text-white">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id);
                }}
                className="bg-green-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div className=' flex justify-center mx-auto w-full border-[#002244] border text-[white] bg-[#002244] rounded-lg text-2xl py-2 mb-3'>
            <p className='flex items-center gap-x-3'>Representative Collection</p>
         </div> */}
      {collectionDeleteFlag ? (
        <div className="flex items-center justify-center w-full h-full">
          <TopBarProgress /> <Loading />
        </div>
      ) : collectionData ? (
        <>
          <div className="w-full overflow-auto">
            <ListFilters columns={columns} rows={rows} title="Collection Requests List" />
          </div>
        </>
      ) : (
        <div className="w-full h-full flex items-center justify-center ">
          <TopBarProgress /> <Loading />
        </div>
      )}
    </div>
    //
  );
};

export default AllCollectionRequest;
