import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { TbListDetails } from "react-icons/tb";
import { RxUpdate } from "react-icons/rx";
import lightToast from "light-toast";
import TopBarProgress from "react-topbar-progress-indicator";
import { getSelectedCollectionAction, updateCollectionAction } from "../../../redux/actions/collectionAction";
import { getAllCollectorsAction } from "../../../redux/actions/collectorsAction";
import "./CollectionRequestDetails.css";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const CollectionRequestDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const [collectorLoad, setCollectorLoad] = useState(false);
  const [statusToggle, setStatusToggle] = useState(false);
  const [status, setStatus] = useState();
  const [collectors, setCollectors] = useState();
  const [collectorName, setCollectorName] = useState();
  const [collectorId, setCollectorId] = useState();
  const [collectionData, setCollectionData] = useState();
  const [collectorNote, setCollectorNote] = useState();
  const statusList = ["new", "pending", "done"];
  const { updateCollectionFlag } = useSelector((state) => state.collectionReducer);
  useEffect(() => {
    setLoad(true);
    setCollectorLoad(true);
    dispatch(
      getSelectedCollectionAction(token, params.id, (result) => {
        if (result.status === 200) {
          setLoad(false);
          setCollectionData(result.data);
          setCollectorName(result.data.collector);
          setCollectorId(result.data.collector_name);
          setStatus(result.data.collection_satus);
          setCollectorNote(result.data.collector_note);
        }
        dispatch(
          getAllCollectorsAction(token, (result) => {
            if (result.status === 200) {
              setCollectorLoad(false);
              setCollectors(result.data.results);
            }
          }),
        );
      }),
    );
  }, []);

  let collectorsData = collectors
    ? collectors?.map((item) => {
        return { value: item.id, label: item.collector_name };
      })
    : [];
  const data = {
    client_name: collectionData && collectionData.client_name,
    collection_purpose: collectionData && collectionData.collection_purpose,
    booking_order_no: collectionData && collectionData.booking_order_no,
    booking_id_no: collectionData && collectionData.booking_id_no,
    hotel_confirmation_number: collectionData && collectionData.hotel_confirmation_number,
    client_number: collectionData && collectionData.client_number,
    collection_satus: collectionData && status,
    collection_type: collectionData && collectionData.collection_type,
    collection_time: collectionData && collectionData.collection_time,
    collection_address: collectionData && collectionData.collection_address,
    collector_name: collectionData && collectorId,
    collection_amount: collectionData && collectionData.collection_amount,
    amount_outstanding: collectionData && collectionData.amount_outstanding,
    total_amount: collectionData && collectionData.total_amount,
    notes: collectionData && collectionData.notes,
    collector_note: collectorNote,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateCollectionAction(token, data, params.id, (result) => {
        if (result.status === 200) {
          lightToast.success("Collection was Completed Updating");
        }
      }),
    );
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.change_collection", "room_booking.view_collection"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full  overflow-x-auto">
      {load && collectorLoad && !permissionFlag ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      ) : collectionData && permissionFlag && collectors && permission?.some((item) => item.value) ? (
        <div className="container border-gray-300 border rounded-lg  p-10 ">
          <div className="flex flex-col justify-center items-center ">
            <form className="flex flex-col    w-full  text-black rounded-md  " onSubmit={handleSubmit}>
              <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                <p className="flex items-center gap-x-3">
                  <TbListDetails className="w-5 h-5" />
                  Collection Details{" "}
                </p>
              </div>

              <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Created Date </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800 flex items-center gap-x-3">
                    <p className="flex items-center gap-x-1">{collectionData.created_date.slice(0, 10)} </p>
                    <span>/</span>
                    <p className="flex items-center gap-x-1"> {collectionData.created_date.slice(11, 19)}</p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collection Date</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800 flex items-center gap-x-3">
                    <p className="flex items-center gap-x-1">{collectionData.collection_time.slice(0, 10)}</p>
                    <span>/</span>
                    <p className="flex items-center gap-x-1"> {collectionData.collection_time.slice(11, 19)}</p>
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Client Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{collectionData.client_name}</p>
                  </div>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collection Address</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {collectionData.collection_address}</p>
                  </div>
                </div>
              </div>
              {/* Hotel id & id & persons*/}
              {/* <div className='flex w-full justify-between items-center  p-3  '>
                                        
                                        <div className='flex  justify-between items-center w-[50%] '>
                                            <div className='w-[33%]  '>
                                            <p className='flex items-center gap-x-1 font-bold'>ID</p>
                                            </div> 
                                            <div className='w-[70%] font-semibold text-gray-800'>
                                                <p className='flex items-center gap-x-1'>{collectionData.id}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center w-[50%]'>
                                            <div className='w-[33%]  '>
                                                <p className='flex items-center gap-x-1 font-bold'>Collector</p>
                                            </div> 
                                            <div className='w-[70%] font-semibold text-gray-800'>
                                            <p className='flex items-center gap-x-1'> {collectionData.collector}</p>
                                            </div>
                                        </div> 
                                        
                                        </div>  */}
              {/* Client & phone Number*/}
              <div className="flex w-full justify-between items-center  p-3 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collection Purpose </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{collectionData.collection_purpose}</p>
                  </div>
                </div>

                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collector Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800 capitalize">
                    <Select
                      placeholder="Collector Name"
                      type="text"
                      required
                      defaultValue={[{ value: collectorId, label: collectorName }]}
                      options={collectorsData}
                      onChange={(e) => setCollectorId(e.value)}
                      className="w-[80%]  rounded-md"
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Booking Order </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{collectionData.booking_order_no} </p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collection Amount</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-end gap-x-1">
                      {collectionData.collection_amount}
                      <span className="text-xs font-semibold text-gray-800">{collectionData.current_type}</span>{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Booking Id </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {collectionData.booking_id_no}</p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Notes</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{collectionData.notes} </p>
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold  ">Hotel Confiramtion Number </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{collectionData.hotel_confirmation_number}</p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collection Status</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <div className="relative w-full inline-block text-left">
                      <div>
                        <button
                          onClick={() => setStatusToggle(!statusToggle)}
                          type="button"
                          className={`flex justify-center items-center w-[80%] h-9 capitalize rounded-md border border-gray-300 shadow-sm ${
                            status === "pending"
                              ? "bg-yellow-300 bg-opacity-90 hover:bg-yellow-400  hover:bg-opacity-80  text-black"
                              : status === "done"
                                ? "bg-green-800 bg-opacity-90 hover:bg-green-800  font-semibold text-white"
                                : "bg-gray-100"
                          }  text-sm font-medium text-gray-700   active:scale-95 duration-300 transition-all`}
                        >
                          {status ? status : collectionData.collection_satus}
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>

                      {statusToggle && (
                        <div className="w-[80%] origin-top-right absolute  mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                          <div
                            className="py-1 w-full capitalize"
                            role="menu"
                            aria-orientation="horizontal"
                            aria-labelledby="options-menu"
                          >
                            {statusList.map((item, index) => {
                              return (
                                <button
                                  className="w-full flex items-center justify-around px-4 py-2  capitalize text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => {
                                    setStatusToggle(!statusToggle);
                                    setStatus(item);
                                  }}
                                  key={index}
                                >
                                  <p>{item}</p>
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* WhatsApp Number & Email*/}
              <div className="flex w-full justify-between items-center  p-3 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Client Number</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{collectionData.client_number} </p>
                  </div>
                </div>

                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collector Note</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <textarea
                      defaultValue={collectorNote}
                      onChange={(e) => setCollectorNote(e.target.value)}
                      className="w-[80%] px-1 p-1 h-10 border-gray-300 border rounded-md bg-gray-100"
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-between items-center  p-3 bg-gray-200  ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Collection Type</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{collectionData.collection_type} </p>
                  </div>
                </div>
              </div>
              {permission.map((item) => {
                if (item.name === "room_booking.change_collection" && item.value === true) {
                  return (
                    <div className="flex w-full justify-end items-center  p-3  ">
                      <button
                        type="submit"
                        disabled={updateCollectionFlag}
                        className="flex items-center gap-x-1 px-2 py-1 font-semibold font bg-green-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all rounded-md"
                      >
                        Update Collection{" "}
                        {updateCollectionFlag ? (
                          <>
                            <ButtonLoading />
                            <TopBarProgress />{" "}
                          </>
                        ) : (
                          <RxUpdate className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  );
                }
              })}

              {/* <div className='flex w-full justify-between items-center  p-3  '>
                                            <div className='flex  justify-between items-center w-[50%] '>
                                            <div className='w-[33%]  '>
                                                <p className='flex items-center gap-x-1 font-bold'>Total Amount </p>
                                            </div> 
                                            <div className='w-[70%] font-semibold text-gray-800'>
                                                <p className='flex items-center gap-x-1'>{collectionData.total_amount} </p>
                                            </div>
                                            </div>
                                            <div className='flex items-center w-[50%]'>
                                            <div className='w-[33%]  '>
                                                <p className='flex items-center gap-x-1 font-bold'>Other Amount</p>
                                            </div> 
                                            <div className='w-[70%] font-semibold text-gray-800'>
                                            <p className='flex items-center gap-x-1'> {collectionData.other_amount}</p>
                                            </div>
                                        </div>
                                        
                                        </div>   */}

              {/* <div className='flex w-full justify-between items-center  p-3 bg-gray-200  '>
                                           
                                        <div className='flex items-center w-[50%]'>
                                            <div className='w-[33%]  '>
                                                <p className='flex items-center gap-x-1 font-bold'>Amount Outstanding </p>
                                            </div> 
                                            <div className='w-[70%] font-semibold text-gray-800'>
                                            <p className='flex items-center gap-x-1'> {collectionData.amount_outstanding}</p>
                                            </div>
                                        </div>
                                        

                                        </div>   */}
            </form>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen">
          <NoPermission />
        </div>
      )}
    </div>
  );
};

export default CollectionRequestDetails;
