import React, { useEffect, useState } from "react";
import ListFilters from "../../../../../utils/ListFilters";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import { BiTransfer } from "react-icons/bi";
import DeletePopUp from "../../../../molecules/DeletePopUp/DeletePopUp";
import { useParams } from "react-router";
import Loading from "../../../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../../../NoPermission/NoPermission";
import { getPermissionAction } from "../../../../../redux/actions/permissionAction";
import { AiFillDelete, AiOutlineEye, AiOutlineLoading } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { Link, useSearchParams } from "react-router-dom";
import { MdOutlineSettings } from "react-icons/md";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import usePermissions from "../../../../../customHooks/usePermissions";
import { Button, Modal } from "flowbite-react";
import mutateDataQuery from "../../../../../react_query/mutateDataQuery";
import Errorfetch from "../../../Errorfetch/Errorfetch";
import { MdOutlinePermIdentity } from "react-icons/md";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { MdOutlineDescription } from "react-icons/md";
import { RiHotelFill } from "react-icons/ri";
import { MdOutlinePerson } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";

const ExtraOffersList = () => {
  const [data, setData] = useState([]);

  const [listPageSize, setListPageSize] = useState(20);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const totalPages = Math.ceil(data?.count / listPageSize);
  const {
    data: offer,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["offer", currentPage, listPageSize],
    queryFn: () =>
      fetchDataQuery(`/upgrade-tool/api/v1/hotel-upgrade-extra/?page=${currentPage}&page_size=${listPageSize}`),
    keepPreviousData: true, // Keep the previous data while loading new data
  });

  useEffect(() => {
    if (isSuccess) {
      setData(offer);
    }
  }, [offer, isSuccess, listPageSize, currentPage]);

  useEffect(() => {
    const searchParams = {
      page: currentPage,
      pageSize: listPageSize,
    };

    setSearchParams(searchParams);
  }, [currentPage, listPageSize, setSearchParams]);
  const offersData = data.results;

  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "upgrade_tool.view_hotelupgradeextraoffer",
    "upgrade_tool.delete_hotelupgradeextraoffer",
    "upgrade_tool.change_hotelupgradeextraoffer",
  ]);
  const isViewingOfferListAllowed =
    permissions?.find((permission) => permission.name === "upgrade_tool.view_hotelupgradeextraoffer")?.value === true;

  const isDeletingOfferAllowed =
    permissions?.find((permission) => permission.name === "upgrade_tool.delete_hotelupgradeextraoffer")?.value === true;

  const isEditingOfferAllowed =
    permissions?.find((permission) => permission.name === "upgrade_tool.change_hotelupgradeextraoffer")?.value === true;

  // this will delete the hotel stored in the state by passing it to the action then remove the popup

  if (!permissionFlag) {
    return <Loading />;
  }

  if (error || !offer) {
    <Errorfetch Error={error?.message || "Error Fetching Offers "} />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const handleTableChange = (page, size) => {
    setListPageSize(size);
    setCurrentPage(page);
  };

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
          >
            {params.id}
          </Link>
        );
      },
      dataIndex: "id",
    },
    {
      key: "name",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlineDriveFileRenameOutline className="w-5 h-5  text-yellow-500" />
          Offer Name
        </div>
      ),
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
          >
            {params.name}
          </Link>
        );
      },
      dataIndex: "name",
    },
    {
      key: "name_ar",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlineDriveFileRenameOutline className="w-5 h-5  text-yellow-500" />
          Offer Name(Arabic)
        </div>
      ),
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
          >
            {params.name_ar}
          </Link>
        );
      },
      dataIndex: "name_ar",
    },
    {
      key: "description",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlineDescription className="w-5 h-5  text-yellow-500" />
          Description
        </div>
      ),
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
            title="params.description"
          >
            {params.description.substr(0, 20) + "..."}
          </Link>
        );
      },
      dataIndex: "description",
    },
    {
      key: "hotel",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <RiHotelFill className="w-5 h-5  text-yellow-500" />
          Hotel
        </div>
      ),
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
          >
            {params.hotel}
          </Link>
        );
      },
      dataIndex: "hotel",
    },
    {
      key: "created_by",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePerson className="w-5 h-5  text-yellow-500" />
          Created by
        </div>
      ),
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
          >
            {params.created_by}
          </Link>
        );
      },
      dataIndex: "created_by",
    },
    {
      key: "created_at",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaRegCalendarAlt className="w-5 h-5  text-yellow-500" />
          Created At
        </div>
      ),
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
          >
            {params?.created_at?.slice(0, 10)}
          </Link>
        );
      },
      dataIndex: "created_at",
    },
    {
      key: "operations",

      title: (
        <div className=" flex items-center w-full justify-center gap-x-1 text-xs ">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            {isViewingOfferListAllowed && (
              <Link to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/${params.id}`}>
                <AiOutlineEye className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800" />
              </Link>
            )}

            {isEditingOfferAllowed && (
              <Link to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-form/${params.id}`}>
                <FiEdit
                  // onClick={() => navigate(`/dashboard/edit-hotel/${params.id}`)}
                  className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
                />
              </Link>
            )}

            {isDeletingOfferAllowed && <DeleteOfferModal offerId={params.id} offerName={params.name} />}
            {/* );
              }
            })} */}
          </div>
        );
      },
    },
  ];
  const rows =
    offersData &&
    offersData.map((offer) => {
      return {
        id: offer.id,
        name: offer.name,
        name_ar: offer.name_ar,
        description: offer.description,
        description_ar: offer.description_ar,
        hotel: offer.hotel_details.name,
        periods: offer.periods,
        created_by: offer.created_by.username,
        created_at: offer.created_at,
      };
    });
  return (
    <div className="formPage-wrap">
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loading />
        </div>
      ) : (
        <div className="!w-full">
          <ListFilters
            columns={columns}
            rows={rows}
            title="Hotel Offer List"
            handlePagination={handleTableChange}
            currentPage={currentPage}
            totalPages={totalPages}
            listPageSize={listPageSize}
          />
        </div>
      )}
    </div>
  );
};

export default ExtraOffersList;
const DeleteOfferModal = ({ offerId, offerName }) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  const { isPending, mutate: deleteOffer } = useMutation({
    mutationFn: () => mutateDataQuery(`/upgrade-tool/api/v1/hotel-upgrade-extra/${offerId}/`, "delete"),
    mutationKey: ["delete-offer", offerId],
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success(`Offer ${offerName} deleted successfully`);

      // Invalidate the query to refetch the list of offers
      queryClient.invalidateQueries(["offer"]);

      // Close the modal
      setShowModal(false);
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to delete offer");
    },
  });

  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <>
      <button onClick={() => setShowModal(true)} className="cursor-pointer">
        <AiFillDelete className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-700 hover:text-red-900" />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showModal}
        position={"center"}
        onClose={() => setShowModal(false)}
      >
        <Modal.Body className="flex flex-col py-4 bg-gray-100 rounded-lg gap-8">
          <h2 className="text-xl capitalize font-bold text-gray-900 mb-4 text-center">
            Do you want to delete Offer {offerName}?
          </h2>
          <div className="flex justify-around items-center">
            <Button
              isProcessing={isPending}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              disabled={isPending}
              onClick={() => deleteOffer()}
              color="failure"
            >
              Delete
            </Button>
            <Button disabled={isPending} onClick={() => setShowModal(false)} color="gray">
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
