import React, { useEffect, useState } from "react";
import SpecialRateForm from "./SpecialRateForm";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading";
import { FaInfoCircle } from "react-icons/fa";

const EditSpecialRateRequest = () => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState();
  const [errExist, setErr] = useState(false);
  const [validMsg, setValidMsg] = useState(false);

  const [taskData, setTaskData] = useState(null); // Ensure initial state is null to signify loading
  useEffect(() => {
    if (/department/i.test(errMsg)) {
      setValidMsg(true);
    } else {
      setValidMsg(false);
    }
  }, [errMsg]);
  const { id } = useParams();
  const {
    mutate: speicalTool,
    isLoading: isMutating,
    isPending,
  } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/sales-management/api/v1/special-rate-requests/${id}/`, "put", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: "speicalTool",
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Special Rate Updated Successfully");
      navigate(`/dashboard/special-rates-requests-list`);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
          setErrMsg(err.message);
          setErr(true);
        });
        return;
      }
      toast.error(error.message || "Failed to update special rate");
    },
  });

  const {
    data: task,
    error,
    isSuccess,
    isLoading: isFetching,
  } = useQuery({
    queryKey: ["task", id],
    queryFn: () => fetchDataQuery(`/sales-management/api/v1/special-rate-requests/${id}/`),
  });

  useEffect(() => {
    if (isSuccess && task) {
      setTaskData(task);
    }
  }, [task, isSuccess]);

  if (isFetching || !taskData) {
    return <Loading />;
  }
  const initialValues = {
    number_of_persons: taskData?.number_of_persons || null,
    city: taskData?.hotel?.city ? { label: taskData.hotel.city.name, value: taskData.hotel.city.id } : null,
    area: taskData?.hotel?.area ? { label: taskData.hotel.area.name, value: taskData.hotel.area.id } : null,
    hotel: taskData?.hotel ? { label: taskData.hotel.name, value: taskData.hotel.id } : null,
    room_type: taskData?.room_type ? taskData.room_type.map((room) => ({ label: room.room_name, value: room.id })) : [],
    methodOfRequest: null,
    check_in_date: taskData?.check_in_date || null,
    check_out_date: taskData?.check_out_date || null,
    client_name: taskData?.client_name || "",
    client_number: taskData?.client_number || null,
    request_details: taskData?.request_details || null,
    childrenAges: taskData?.persons_data?.children || [], // Added null check for `persons_data`
    adults: taskData?.adults || 0, // Ensure `adults` has a default
    status: taskData?.status || "New",
    persons_data: taskData?.persons_data?.children || [], // Added null check
    meal_plan: taskData?.meal_plan ? { label: taskData.meal_plan, value: taskData.meal_plan } : null,
  };

  const handleSubmit = async (values) => {
    try {
      const rooms_type = values.room_type.map((r) => r.value); // Array of room IDs
      const formattedData = {
        // method_of_request: values.methodOfRequest,
        hotel: values.hotel.value, // Assuming it's already the hotel ID
        room_type: rooms_type, // Array of integers (IDs)
        check_in_date: values.check_in_date,
        check_out_date: values.check_out_date,
        client_name: values.client_name,
        client_number: values.client_number,
        request_details: values.request_details,
        status: values.status, // Default status
        number_of_persons: values.number_of_persons, // Correct reference
        persons_data: { adults: values.number_of_persons, children: values.childrenAges }, // Corrected
        meal_plan: values.meal_plan.value,
      };
      // alert(JSON.stringify(formattedData));
      await speicalTool(formattedData);
    } catch (error) {
      toast.error(error.message || "Failed to update special rate request");
    }
  };

  return (
    <section className="formPage-wrap min-h-screen">
      {errExist && validMsg && (
        <div className="w-max bg-red-100 p-2 rounded-md flex gap-4 input-par">
          <div className="flex items-center gap-2 ">
            <FaInfoCircle />

            <span className="text-xs text-red-500 ">{errMsg} </span>
          </div>
          <Link to={"/dashboard/departments-management"} target="_blank" className="updateButton ">
            Department
          </Link>
        </div>
      )}
      <h2 className="header-h2">Update Special Rate Tool</h2>
      <SpecialRateForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        loader={isFetching}
        isLoading={isPending}
        edit={true}
      />
    </section>
  );
};

export default EditSpecialRateRequest;
