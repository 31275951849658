import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import LoginLoadingBtn from "../LoginLoadingBtn/LoginLoadingBtn";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { accessGuestAction, checkCodeAction } from "../../../redux/actions/guestAction";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useNavigate } from "react-router";
import cookie from "react-cookies";
import { CiBarcode } from "react-icons/ci";

const CodeForm = ({ setCodeFlag }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues: {
      unique_code: "",
    },
    validationSchema: yup.object().shape({
      unique_code: yup.string().required("Please enter your code"),
    }),
    onSubmit: (values) => {
      dispatch(
        checkCodeAction(values.unique_code, (result) => {
          if (result.status === 200) {
            if (result.data.verify === true) {
              dispatch(
                accessGuestAction((result) => {
                  if (result.status === 200) {
                    setSubmitting(false);
                    cookie.save("access_token", result.data.tokens.access, { path: "/" });
                    cookie.save("refresh_token", result.data.tokens.refresh, { path: "/" });
                    cookie.save("language", "ar", { path: "/" });
                    cookie.save("code", values.unique_code, { path: "/" });
                    cookie.save("currency", "USD", { path: "/" });
                    navigate(`/`);
                  }
                }),
              );
            } else {
              setSubmitting(false);
              NotificationManager.error("This link is expired");
              cookie.remove("code", { path: "/" });
            }
          } else {
            setSubmitting(false);
            cookie.remove("code", { path: "/" });
          }
        }),
      );
    },
  });
  return (
    <div className="w-full ">
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-5">
        <div className="w-full flex flex-col gap-y-1">
          <p className="text-[#002235]  font-medium">Enter your code</p>

          <input
            className="shadow appearance-none w-full border border-gray-300  rounded-xl font-semibold  h-10 lg:h-12 px-4 text-[#002235] leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="unique_code"
            placeholder="Enter your code"
            onChange={handleChange}
            value={values.unique_code}
            onBlur={handleBlur}
          />

          <span className="text-orange-600 font-semibold">
            {errors.unique_code && touched.unique_code && errors.unique_code}
          </span>
        </div>
        <button
          className="w-full bg-yellow-600 text-white font-semibold lg:px-5 lg:py-1.5 px-3 py-1.5   rounded-md  active:scale-95  hover:bg-yellow-700 duration-300 transition-all "
          type="submit"
          disabled={isSubmitting}
        >
          <div className="flex items-center justify-center gap-x-1">
            {isSubmitting ? (
              <LoginLoadingBtn />
            ) : (
              <>
                <CiBarcode className="w-5 h-5" /> <p>Login with code</p>{" "}
              </>
            )}
          </div>
        </button>
      </form>
      <button
        className="w-full mt-5 bg-[#002235] text-white font-semibold lg:px-5 text-sm lg:py-1.5 px-3 py-1.5  rounded-md  active:scale-95  hover:bg-[#002230] duration-300 transition-all "
        onClick={() => setCodeFlag(false)}
      >
        <div className="flex items-center justify-center gap-x-1">
          <IoIosArrowBack /> Back
        </div>
      </button>
      <NotificationContainer />
    </div>
  );
};

export default CodeForm;
