import React, { useEffect } from "react";
import cookie from "react-cookies";
import { Outlet, useNavigate } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useData from "../customHooks/useData";
const ReqAuth = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!cookie.load("access_token")) {
      navigate("/");
    }
  }, [cookie.load("access_token")]);
  const checkAccess = useData("");
  return cookie.load("access_token") ? checkAccess && <Outlet /> : <Navigate to="/login" />;
};

export default ReqAuth;
