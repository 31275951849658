import React from "react";
import { useEffect } from "react";
import { selectedHotelBank } from "../../../redux/actions/hotelBankDetails";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { TbListDetails } from "react-icons/tb";
const HotelBankDetailsListPreview = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [hotelBankData, setHotelBankData] = useState(null);

  useEffect(() => {
    dispatch(
      selectedHotelBank(params.id, (result) => {
        if (result.status === 200) {
          setHotelBankData(result.data);
        }
      })
    );
  }, []);
  return (
    <div className="w-full overflow-x-auto ">
      {hotelBankData ? (
        <div className="container border-gray-300 border rounded-lg  p-10 ">
          <div className="flex flex-col justify-center items-center ">
            <div className="flex flex-col    w-full  text-black rounded-md  ">
              <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                <p className="flex items-center gap-x-3">
                  <TbListDetails className="w-5 h-5" />
                  Hotel Bank Details{" "}
                </p>
              </div>
              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">ID </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{hotelBankData.id}</p>
                  </div>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Hotel ID</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {hotelBankData.hotel.id}</p>
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-between items-center  p-3">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Hotel Name </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{hotelBankData.hotel.name} </p>
                    <p className="flex items-center gap-x-1"></p>
                  </div>
                </div>

                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Bank Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{hotelBankData.bank_name}</p>
                  </div>
                </div>
              </div>
              {/* Client & phone Number*/}
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Account Number </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{hotelBankData.account_number}</p>
                  </div>
                </div>

                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Iban Number </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{hotelBankData.iban_number}</p>
                  </div>
                </div>
              </div>
              {/* WhatsApp Number & Email*/}
              <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Swift Code</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{hotelBankData.swift_code} </p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold text-sm">Currency</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{hotelBankData.currency} </p>
                    <p className="flex items-center gap-x-1"></p>
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Document</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    {hotelBankData.document ? (
                      <img src={hotelBankData.document} className="w-24 h-24" />
                    ) : (
                      <p className="flex items-center gap-x-1">No Document </p>
                    )}
                  </div>
                </div>
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold text-sm">Notes</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {hotelBankData.notes}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default HotelBankDetailsListPreview;
