import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import lightToast from "light-toast";
import ButtonLoading from "../../ButtonLoading/ButtonLoading";
import { BsFillCreditCardFill } from "react-icons/bs";
import { VscAdd } from "react-icons/vsc";
import { NotificationManager } from "react-notifications";

import { allPosMachineActionById, allPosMachineActionUpdate } from "../../../../redux/actions/posMachineAction";
import TopBarProgress from "react-topbar-progress-indicator";
import useRemoveScroll from "../../../../customHooks/useRemoveScroll";
import { addPOSMachineValidationSchema } from "../../CreateNewPosVisa/addPOSMachinevalidationSchema";
import { GrUpdate } from "react-icons/gr";
import { useFormik } from "formik";

const EditPosMachine = () => {
  const [checkId, setCheckId] = useState(false);
  const [checkOption, setCheckOption] = useState(false);
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [btnCheck, setBtnCheck] = useState(false);
  const [cardStatus, setCardStatus] = useState(false);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [date, setDate] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [file, setFile] = useState(null);
  const [selectedCurrencies, setSelectedCurrencies] = useState();
  const [selectedBank, setSelectedBank] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const { bankTransferFlag } = useSelector((state) => state.bookingListReducer);
  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  const { postMachineDataFlag } = useSelector((state) => state.posMachineReducer);
  useRemoveScroll();
  const Bank = bankData
    ? bankData?.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const CurrencyType = currencyList
    ? currencyList?.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  const bankName = Bank.map((item) => {
    if (item.value === selectedBank) {
      return item.label;
    }
  });

  // Form State
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      mid_number: "",
      currency: "",
      location: "",
      notes: "",
      bank_name: "",
    },
    validationSchema: addPOSMachineValidationSchema,
    onSubmit: submit,
  });
  useEffect(() => {
    if (params.id) {
      dispatch(
        allPosMachineActionById(token, params.id, (result) => {
          if (result.status === 200) {
            setFieldValue("bank", result.data.bank);
            setFieldValue("bank_name", result.data.bank.name);
            setSelectedBank(result.data.bank);

            setFieldValue("mid_number", result.data.mid_number);
            setFieldValue("location", result.data.location);
            setFieldValue("notes", result.data.notes);
            setFieldValue("currency", result.data.currency);

            setSelectedCurrencies([{ label: result.data.currency, value: result.data.currency }]);
          }
        }),
      );
    }
  }, []);

  function submit(data) {
    const newValues = {
      name: data.name,
      mid_number: data.mid_number,
      currency: data.currency,
      location: data.location,
      notes: data.notes,
    };
    if (params.id) {
      dispatch(
        allPosMachineActionUpdate(token, params.id, newValues, (result) => {
          if (result.status === 200) {
            setSubmitting(false);
            lightToast.success("POS Machine List Updated successfully");
            navigate(-1);
          } else {
            setSubmitting(false);

            NotificationManager.error(result?.response?.data[0]?.message);
          }
        }),
      );
    }
  }

  return (
    <div className="formPage-wrap">
      {bankData ? (
        <div className=" w-full   ">
          <div className="flex items-center justify-center gap-x-5  text-md">
            <h2 className="header-h2">
              <div className="relative flex flex-col">
                <BsFillCreditCardFill className="w-5 h-5" />
                <VscAdd className="absolute top-3 left-5 w-3 h-3" />
              </div>
              Update POS Visa{" "}
            </h2>
          </div>
          <form className="form-wrap w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <div className="input-par">
                <div className="input-chil">
                  {" "}
                  <label className=" flex text-gray-600   ">MID Number</label>
                  <input
                    placeholder="MID Numbers"
                    name="mid_number"
                    type="number"
                    value={values.mid_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    className={`w-full px-2 h-10  border rounded-lg bg-white ${
                      errors.mid_number && touched.mid_number && "border-red-900"
                    }`}
                  />
                  {errors.mid_number && touched.name && (
                    <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                      {errors.mid_number}
                    </div>
                  )}
                </div>
                <div className="input-chil">
                  {" "}
                  <label className=" flex text-gray-600 ">Currency</label>
                  <Select
                    name="currency"
                    options={CurrencyType}
                    onBlur={handleBlur}
                    value={values.currency ? [{ value: values.currency, label: values.currency }] : selectedCurrencies}
                    onChange={(e) => {
                      setCurrency(e.label);
                    }}
                    isSearchable
                    placeholder="Currency"
                    className={`w-full h-10  border rounded-lg bg-white ${
                      errors.currency && touched.currency && "border-red-900"
                    }`}
                  />
                  {errors.currency && touched.currency && (
                    <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                      {errors.currency}
                    </div>
                  )}
                </div>
              </div>

              <div className="input-par">
                <div className="input-chil">
                  <label className=" flex text-gray-600   ">Bank Name</label>
                  <Select
                    placeholder="Bank Name"
                    name="bank_name"
                    defaultValue={values.bank_name}
                    value={values.bank_name ? [{ value: values.bank_name, label: values.bank_name }] : []}
                    options={Bank}
                    onChange={(e) => setFieldValue("bank_name", e.label)}
                    isSearchable
                    className={`w-full  h-10  border rounded-lg bg-white ${
                      errors.bank_name && touched.bank_name && "border-red-900"
                    }`}
                  />
                  {errors.bank_name && touched.bank_name && (
                    <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                      {errors.bank_name}
                    </div>
                  )}
                </div>
                <div className="input-chil">
                  {" "}
                  <label className=" flex text-gray-600   ">Location (City)</label>
                  <input
                    placeholder="Location"
                    name="location"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.location}
                    required
                    // onChange={(e)=>setBank(e.target.value)}
                    className=" w-full h-10 px-2 border rounded-lg bg-white "
                  />
                </div>
              </div>

              <label className=" flex text-gray-600   ">Notes</label>
              <textarea
                name="notes"
                type="text"
                value={values.notes}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                // onChange={(e)=>setBank(e.target.value)}
                className={`w-full p-3   border rounded-lg bg-white ${
                  errors.notes && touched.notes && "border-red-900"
                }`}
              />
              {errors.notes && touched.notes && (
                <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.notes}</div>
              )}
              <button
                type="Submit"
                disabled={postMachineDataFlag}
                className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Update {postMachineDataFlag ? <ButtonLoading /> : <GrUpdate className="w-5 h-5" />}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <>
          <TopBarProgress /> <Loading />
        </>
      )}
    </div>
  );
};

export default EditPosMachine;
