import React, { useRef, useState } from "react";
import { IoAdd, IoEyeOffSharp } from "react-icons/io5";
import { sendData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import useData from "../../../customHooks/useData";
import Select from "react-select";
import lightToast from "light-toast";
import { NotificationContainer, NotificationManager } from "react-notifications";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { GoPerson } from "react-icons/go";
import { MdDone, MdOutlineMailOutline } from "react-icons/md";
import { BiUserCircle } from "react-icons/bi";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { useNavigate } from "react-router";

export default function AddUsers() {
  const dispatch = useDispatch();
  const [passType, setPassType] = useState(true);
  const [cPassType, setCPassType] = useState(true);
  const [error, setError] = useState("");
  const [password, setPassword] = useState(null);
  const [isEnabled, setIsEnabled] = useState(true);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [load, setLoad] = useState(false);
  const chosenGroup = useRef(null);
  const navigate = useNavigate();
  const groups = useData("permissions/api/v1/user-groups/");

  const groupsArr = groups ? groups.map((group) => ({ label: group.name, value: group.id })) : [];

  function handlePasswords(e) {
    if (e.target.value !== password) {
      setError("The passwords don't match");
      setIsDisabled(true);
    } else {
      setError("");
      setIsDisabled(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      username: e.target.username.value,
      email: e.target.email.value,
      password: password,
      is_enabled: isEnabled,
      is_whitelisted: isWhitelisted,
      group_id: chosenGroup.current,
    };

    setLoad(true);
    dispatch(
      sendData(
        "/auth/api/v1/register/",
        data,
        (successCallback) => {
          if (successCallback.status === 201) {
            setLoad(false);
            lightToast.success("the user was added successfully");
            navigate(-1);
          }
        },
        (error) => {
          setLoad(false);
          NotificationManager.error(` ${error?.response?.data[0]?.field_name}  ${error?.response?.data[0]?.message}`);
        },
      ),
    );
  }

  return (
    <div className="formPage-wrap min-h-screen">
      <div className="flex flex-col items-center h-full w-full">
        <div className=" w-full ">
          <div className=" flex justify-start  capitalize ">
            <h2 className="header-h2 form-wrap">
              {/* <PiUsersThreeFill className="w-5 h-5" /> */}
              Add Users
            </h2>
          </div>
          <form className="form-wrap !bg-gray-50 " onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="w-full flex flex-col justify-center py-4 items-center gap-y-8">
              <div className="input-par">
                <div className="relative input-chil">
                  <label className="  text-[#002244] font-semibold ">First Name</label>
                  <input
                    placeholder="First Name"
                    name="first_name"
                    type="text"
                    required
                    onChange={(e) => {}}
                    className="w-full pl-9 h-10 border rounded-lg bg-white"
                  />
                  <div className="absolute top-[33px] left-3">
                    <GoPerson className="w-5 h-5 text-[#002244]" />
                  </div>
                </div>
                <div className="relative input-chil">
                  <label className="  text-[#002244] font-semibold ">Last Name</label>
                  <input
                    placeholder="Last Name"
                    name="last_name"
                    type="text"
                    required
                    onChange={(e) => {}}
                    className="w-full pl-9 h-10 border rounded-lg bg-white"
                  />
                  <div className="absolute top-[33px] left-3">
                    <GoPerson className="w-5 h-5 text-[#002244]" />
                  </div>
                </div>
              </div>
              <div className="input-par">
                <div className="input-chil">
                  <label className="flex text-[#002244] font-semibold">User Name</label>
                  <div className="w-full relative">
                    <input
                      placeholder="User Name"
                      name="username"
                      type="text"
                      required
                      onChange={(e) => {}}
                      className="w-full pl-9 h-10  border rounded-lg bg-white "
                    />
                    <div className="absolute top-[10px] left-3">
                      <BiUserCircle className="w-5 h-5 text-[#002244]" />
                    </div>
                  </div>
                </div>
                <div className="input-chil">
                  <label className=" flex text-[#002244] font-semibold ">Email</label>
                  <div className="w-full relative">
                    <input
                      placeholder="Email"
                      name="email"
                      type="email"
                      required
                      onChange={(e) => {}}
                      className="w-full pl-9 h-10 border rounded-lg bg-white"
                    />
                    <div className="absolute top-[10px] left-3">
                      <MdOutlineMailOutline className="w-5 h-5 text-[#002244]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-par">
                <div className="input-chil">
                  <label className=" flex text-[#002244] font-semibold ">Password</label>
                  <div className="w-full relative">
                    <input
                      placeholder="password"
                      name="password"
                      type={passType ? "password" : "text"}
                      required
                      onChange={(e) => {}}
                      className=" w-full pl-9 h-10  border rounded-lg bg-white "
                      onFocus={() => setError("")}
                      onBlur={(e) => setPassword(e.target.value)}
                    />
                    <RiLockPasswordLine className="absolute top-3 left-3 cursor-pointer text-[#002244]" />
                    <IoEyeOffSharp
                      className="absolute top-[10px] right-[10px] cursor-pointer"
                      size={20}
                      onClick={() => setPassType(!passType)}
                    />
                  </div>
                </div>
                <div className="input-chil">
                  <label className=" flex text-[#002244] font-semibold ">Confirm Password</label>

                  <div className="w-full relative">
                    <input
                      placeholder="confirm password"
                      name="c_password"
                      type={cPassType ? "password" : "text"}
                      required
                      onChange={(e) => {}}
                      className=" w-full pl-9 h-10  border rounded-lg bg-white "
                      onBlur={(e) => handlePasswords(e)}
                      onFocus={() => setError("")}
                    />
                    <MdDone className="absolute top-3 left-3 cursor-pointer text-[#002244]" />
                    <IoEyeOffSharp
                      className="absolute top-[10px] right-[10px] cursor-pointer"
                      size={20}
                      onClick={() => setCPassType(!cPassType)}
                    />
                    {error && <div className="text-sm text-red-800 font-semibold">{error}</div>}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <label className=" flex text-[#002244] font-semibold ">Group</label>
                <div className="relative w-full border rounded-lg">
                  <Select
                    options={groupsArr}
                    className="w-full h-10    bg-white pl-9"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,

                        borderBottom: "none",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        boxShadow: "none",
                        borderRadius: "0",
                      }),
                    }}
                    required
                    onChange={(e) => (chosenGroup.current = e.value)}
                  />
                  <div className="absolute top-[10px] left-3">
                    <HiOutlineUserGroup className="w-5 h-5 text-[#002244]" />
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-around  items-center">
                <div className="px-2 py-1.5 flex gap-2 rounded-lg border items-center shadow-md">
                  <input
                    type="checkbox"
                    name="enabled"
                    className=""
                    checked={isEnabled}
                    onChange={() => setIsEnabled(!isEnabled)}
                  />
                  <label htmlFor="enabled">Active</label>
                </div>
                <div className="px-2 py-1.5 flex gap-2 items-center border rounded-lg shadow-md">
                  <input
                    type="checkbox"
                    name="whitlisted"
                    className=""
                    checked={isWhitelisted}
                    onChange={() => setIsWhitelisted(!isWhitelisted)}
                  />
                  <label htmlFor="whitelisted">Whitelisted</label>
                </div>
              </div>

              <div className="flex justify-center mt-6">
                <button type="Submit" disabled={isDisabled || load} className="addButton">
                  Add{" "}
                  {load ? (
                    <>
                      <ButtonLoading />
                      <TopBarProgress />
                    </>
                  ) : (
                    <IoAdd className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}
