import React from "react";
import Loading from "../Loading/Loading";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {MdOutlineDone} from 'react-icons/md'
import { TbListDetails } from "react-icons/tb";
import { FaRegCalendarAlt } from "react-icons/fa";
import {getSelectedPaymentTransAction,} from "../../../redux/actions/paymentTransactionAction";
import TopBarProgress from "react-topbar-progress-indicator";

const PaymentWalletDetails = ({wallet}) => {
    const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [servicesType, setServicesType] = useState([]);
  const [gateWays, setGateWays] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  return (
    <div className="w-full h-full overflow-x-auto ">
          <div className="flex flex-col justify-center items-center ">
            <div className="flex flex-col    w-full  text-black rounded-md  ">
              <div className=" flex items-center justify-center w-full border  rounded-lg text-xl text-black font-semibold h-16">
                <p className="flex items-center gap-x-3"><TbListDetails className='w-5 h-5' />Method OF Payment (Jumbo4Pay)</p>
              </div>
              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Total Amount</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.total_amount}<span>{wallet.currency_type}</span></p>
                  </div>
                </div>
                <hr />
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">ID</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.id}</p>
                  </div>
                </div>
              </div>
               {/* Hotel id & id & persons*/}
               <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Convertion Rate</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.conversion_rate}</p>
                  </div>
                </div>
                <hr />
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Creator</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.creator}</p>
                  </div>
                </div>
              </div>
               {/* Hotel id & id & persons*/}
               <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Convertion Price</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.conversion_rate_price}<span>{wallet.contract_currency}</span></p>
                  </div>
                </div>
                <hr />
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Invoice Number</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.invoice_number} </p>
                  </div>
                </div>
              </div>
              {/* Client & phone Number*/}
              <div className="flex w-full justify-between items-center  p-3">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Currency </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.currency_type}</p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Service Type</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.service_type} </p>
                    <p className="flex items-center gap-x-1"></p>
                  </div>
                </div>
              </div>
               {/* Client & phone Number*/}
               <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Contract Currency </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.contract_currency}</p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Booked Room </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.booked_room_name}</p>
                  </div>
                </div>
              </div>
             
              
              {/* Client text & Client Tag Request*/}
              <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Employee Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.employee_name}</p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">GateWay</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.gateway}</p>
                  </div>
                </div>
              </div>
              {/* Client text & Client Tag Request*/}
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">GateWay Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.gateway_name}</p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Service Type</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.service_type}</p>
                  </div>
                </div>
               
              </div>
              {/* Hotel & Room Name*/}
              <div className="flex w-full justify-between items-center  p-3">
              <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Transaction Date</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">{wallet.transaction_date.slice(0,10)}</p>
                  </div>
                </div>
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Created Date </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">
                      <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />
                      {wallet.transaction_date.slice(0,10)}
                    </p>
                  </div>
                </div>
               
              </div>
               {/* Hotel & Room Name*/}
               <div className="flex w-full justify-between items-center  p-3 bg-gray-200">

                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Transaction Status</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800 capitalize">
                  { wallet.transaction_status === "confirmed" ?
                         <p className='w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-green-700 font-semibold'>
                           {wallet.transaction_status} <MdOutlineDone className='w-4 h-4  bg-green-700 border text-white rounded-full ' /> 
                         </p>
                         :
                         <p className='w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-yellow-600 font-semibold'>
                           {wallet.transaction_status}....
                         </p>
                   }
                  </div>
                </div>
               
              </div>
            </div>
          </div>
    </div>
  )
}

export default PaymentWalletDetails