import api_token from "../../api/UserApi";
import { REFUND_FLAG, ALL_REFUND, DELETE_REFUND_FLAG, UPDATE_REFUND_FLAG, GET_REFUND_ID } from "../types/types";

export const postRefundAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REFUND_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`booking/api/v1/refunds/`, data);
      if (response.status === 201) {
        dispatch({
          type: REFUND_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const getAllRefundAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/refunds/`);
      if (response.status === 200) {
        dispatch({
          type: ALL_REFUND,
          payload: response.data,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const deleteRefundAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_REFUND_FLAG,
        payload: true,
      });
      const response = await api_token(token).delete(`booking/api/v1/refunds/${id}/`);
      if (response.status === 204) {
        dispatch({
          type: DELETE_REFUND_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const getRefundByIdAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/refunds/${id}/`);
      if (response.status === 200) {
        dispatch({
          type: GET_REFUND_ID,
          payload: response.data,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const updateRefundAction = (token, data, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_REFUND_FLAG,
        payload: true,
      });
      const response = await api_token(token).put(`booking/api/v1/refunds/${id}/`, data);
      if (response.status === 200) {
        dispatch({
          type: UPDATE_REFUND_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
