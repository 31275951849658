import { GET_MID_NUMBER, POST_POSVISA_FLAG , GET_CURRENCY_MID_NUMBER, GET_CURRENCY_MID_NUMBER_FLAG  , GET_SELECT_POS_VISA , DELETE_POS_VISA_FLAG , UPDATE_POS_VISA_FLAG , GET_POS_VISA_FLAG} from "../types/types";

const initialState = {
    postPosVisaFlag:false,
    midNumberData:null,
    currencyMidNumber:null,
    currencyMidNumberFlag:false,
    selectetdPosVisa :null,
    selectetdPosVisaFlag:false,
    deletePosVisaFlag:false,
    updatePosVisaFlag:false,
}

export const posVisaReducer = (state = initialState , action) => {
    switch(action.type)
    {
        case POST_POSVISA_FLAG:
            return{...state , postPosVisaFlag : action.payload }
        case GET_MID_NUMBER:
            return{...state , midNumberData : action.payload }
        case GET_CURRENCY_MID_NUMBER:
            return{...state , currencyMidNumber : action.payload }
        case GET_CURRENCY_MID_NUMBER_FLAG:
            return{...state , currencyMidNumberFlag : action.payload }
        case GET_SELECT_POS_VISA:
            return{...state , selectetdPosVisa : action.payload }
       case GET_POS_VISA_FLAG:
                return{...state , selectetdPosVisaFlag : action.payload }
        case DELETE_POS_VISA_FLAG:
            return{...state , deletePosVisaFlag : action.payload }
        case UPDATE_POS_VISA_FLAG:
            return{...state , updatePosVisaFlag : action.payload }
        default:
            return state;
    }
}