import React, { useState } from "react";
import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";
import DepartureForm from "./Departure/DepartureForm";
import ArrivalForm from "./Arrival/ArrivalForm";
import "./Meetpublic.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const MeetAssistFormPublic = () => {
  const [servicetypeState, setServiceTypeState] = useState("Arrival");
  const { lang } = useParams();

  const [t] = useTranslation();

  const handleTabClick = (tabName) => {
    setServiceTypeState(tabName);
  };

  return (
    <>
      {(lang && lang === "ar") || (lang && lang === "en") ? (
        <section className=" w-full ">
          <div className="space-y-5">
            <div className="border-b border-b-gray-200">
              <ul className="-mb-px flex items-center gap-4 text-sm font-medium">
                <li className="flex-1">
                  <p
                    onClick={() => handleTabClick("Departure")}
                    className={`relative flex items-center justify-center gap-2 px-1 py-3 
                after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full
                cursor-pointer
                lg:text-lg text-base
                ${
                  servicetypeState === "Departure"
                    ? "text-[#002244] after:bg-[#002244] hover:text-[#002244]"
                    : "text-gray-400"
                }
                `}
                  >
                    <FaPlaneDeparture />
                    {t("departure")}
                  </p>
                </li>
                <li className="flex-1">
                  <p
                    onClick={() => handleTabClick("Arrival")}
                    className={`relative cursor-pointer
                flex items-center justify-center gap-2 px-1 py-3 
                after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full
                lg:text-lg text-base
               ${
                 servicetypeState === "Arrival"
                   ? "text-[#002244] after:bg-[#002244] hover:text-[#002244]"
                   : "text-gray-400"
               }
                `}
                  >
                    <FaPlaneArrival />
                    {t("arrival")}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {servicetypeState === "Departure" && <DepartureForm servicetypeState={servicetypeState} />}
          {servicetypeState === "Arrival" && <ArrivalForm servicetypeState={servicetypeState} />}
        </section>
      ) : null}
    </>
  );
};

export default MeetAssistFormPublic;
