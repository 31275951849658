import React, { useEffect, useState } from "react";
import "./../AddHotelForm/DashboardForm.scss";
import { useDispatch, useSelector } from "react-redux";
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import StaticFromInput from "../../molecules/StaticFormInput/StaticFromInput";
import DynamicFormInputText from "../../molecules/DynamicFormInputText/DynamicFormInputText";
import TimeRange from "../../molecules/TimeRange/TimeRange";
import Select from "react-select";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import { getDepartmentChoices, sendHotelContactData } from "../../../redux/actions/hotelContact";
import { useNavigate } from "react-router";
import TopBarProgress from "react-topbar-progress-indicator";
import { LiaFileContractSolid } from "react-icons/lia";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { MdOutlinePostAdd } from "react-icons/md";
import lightToast from "light-toast";

const wDaysOpt = [
  { label: "saturday", value: "sat" },
  { label: "sunday", value: "sun" },
  { label: "monday", value: "mon" },
  { label: "tuesday", value: "tue" },
  { label: "wednesday", value: "wed" },
  { label: "thursday", value: "thu" },
  { label: "friday", value: "fri" },
];
export default function AddHotelContact() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeRange, setTimeRange] = useState({ from: null, to: null });
  const [workingDays, setWorkingDays] = useState([]);
  const hotelOptions = useSelector((state) => state.hotelNames);
  const departmentChoices = useSelector((state) => state.departmentChoices);
  const departmentChoicesArr = departmentChoices.map((choice) => ({ name: choice, value: choice }));

  useEffect(() => {
    dispatch(requestHotelNames());
    dispatch(getDepartmentChoices());
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const form = e.target;
    const formData = new FormData(form);
    const data = {
      title: form.title.value,
      contact_name: form.contact_name.value,
      depertment: form.department.value,
      telephone_number: {},
      email: {},
      notes: form.notes.value,
      working_hours: timeRange,
      working_days: {},
      hotel: form.hotel.value,
    };
    workingDays.forEach((day, index) => {
      data.working_days[index + 1] = day.value;
    });

    Array.from(formData.getAll("telephone_number")).forEach((elem, index) => {
      data.telephone_number[index + 1] = elem;
    });
    Array.from(formData.getAll("email")).forEach((elem, index) => {
      data.email[index + 1] = elem;
    });
    dispatch(
      sendHotelContactData(data, () => {
        lightToast.success("Hotel Contact Form was completed successfully");
      }),
    );

    navigate(`/dashboard/hotel-info-setup/hotel-contact-list/1/-id`);
  }
  return (
    <div className="formPage-wrap ">
      <div className=" flex items-center justify-center ">
        <h2 className="header-h2 ">
          {" "}
          <LiaFileContractSolid className="w-6 h-6" />
          Add Hotel Contact
        </h2>
      </div>
      <form className="form-wrap flex flex-col gap-y-2 " onSubmit={handleSubmit}>
        <div className="w-full gap-4 flex max-sm:flex-col items-start justify-center ">
          <div className="flex-1 max-sm:w-full">
            <StaticFromInput text="Contact Name" name="contact_name" placeholder="Contact Name" />
          </div>
          <div className="flex-1 max-sm:w-full">
            <StaticFromInput text="Job Title" name="title" placeholder="Job Title" />
          </div>
        </div>
        <div className="w-full gap-4 flex max-sm:flex-col items-start justify-center ">
          <div className="flex-1 max-sm:w-full">
            <StaticFormSelect text="Hotel Name" name="hotel" options={hotelOptions} placeholder="Choose Hotel" />
          </div>
          <div className="flex-1 max-sm:w-full">
            <StaticFormSelect
              text="Department"
              name="department"
              options={departmentChoicesArr}
              placeholder="Choose Department"
            />
          </div>
        </div>

        <div className="w-full gap-4 flex max-sm:flex-col items-start justify-center ">
          <div className="flex-1 max-sm:w-full">
            <DynamicFormInputText
              text="Telephone Number"
              name="telephone_number"
              type="number"
              placeholder="Telephone Number"
              inputsNum={1}
            />
          </div>
          <div className="flex-1 max-sm:w-full">
            <DynamicFormInputText text="Email" name="email" type="email" placeholder="Email " inputsNum={1} />
          </div>
        </div>

        <div className="w-full  flex max-md:flex-col items-start justify-center gap-4">
          <div className="flex-1 max-md:w-full">
            <label htmlFor="working_days" className="text-slate-800 font-semibold">
              Working Days
            </label>
            <Select
              options={wDaysOpt}
              value={workingDays}
              className="w-full"
              placeholder="Select Working Days"
              isMulti
              onChange={(e) => setWorkingDays(e)}
            />
          </div>

          <div className=" max-sm:w-full max-sm:flex-col flex sm:items-end sm:gap-x-3">
            <label htmlFor="" className="text-slate-800 font-semibold   mb-3">
              Working Hours:
            </label>
            <TimeRange timeRange={timeRange} setTimeRange={setTimeRange} />
          </div>
        </div>
        <div className="w-full">
          <label htmlFor="notes" className="text-slate-800 font-semibold">
            Notes
          </label>
          <textarea
            name="notes"
            id="notes"
            cols="30"
            rows="4"
            className="w-full border rounded-md p-3 focus:outline-none focus:ring focus:border-blue-300"
          ></textarea>
        </div>
        <div className="w-full flex justify-center items-center  mt-6">
          <button type="submit" disabled={isDisabled} className="addButton">
            Add
            {isDisabled ? (
              <>
                {" "}
                <TopBarProgress /> <ButtonLoading />
              </>
            ) : (
              <MdOutlinePostAdd className="w-5 h-5" />
            )}
          </button>
        </div>

        {/* {isDisabled ? (
          <>
            <TopBarProgress />
            <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
              <HashLoader size={30} color="white" />
              <div className="text-white font-bold my-2">Submitting...</div>
            </div>
          </>
        ) : null} */}
      </form>
    </div>
  );
}
