import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import requestHotelNames from "../redux/actions/requestHotelNames";

export default function useHotelNames() {
  const dispatch = useDispatch();
  const hotels = useSelector((state) => state.hotelNames);

  useEffect(() => {
    dispatch(requestHotelNames());

    return () => {};
  }, []);

  return hotels;
}
