import React, { useEffect } from "react";
import { FaWpforms } from "react-icons/fa6";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { useNavigate, useParams } from "react-router";
import { postRefundAction } from "../../../redux/actions/refundAction";
import { useState } from "react";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

const RequestRefund = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();
  const params = useParams();
  const [nights, setNights] = useState(0);
  const { refundFlag } = useSelector((state) => state.refundReducer);
  const refundData = {
    booked_room: params.id,
    refund_nights: nights,
  };
  useRemoveScroll();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      postRefundAction(token, refundData, (result) => {
        if (result.status === 201) {
          lightToast.success("The Refund Request was completed successfully");
          navigate(-1);
        }
      }),
    );
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.change_refund"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap ">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "room_booking.change_refund" && item.value === true) {
            return (
              <div className="flex flex-col items-center justify-center h-full w-full">
                <div className=" w-full  ">
                  <div className=" flex justify-center ">
                    <p className="header-h2">
                      <FaWpforms className="w-5 h-5" />
                      Refund Form{" "}
                    </p>
                  </div>
                  <form className="form-wrap  w-full flex" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" font-medium w-full text-gray-600   ">Refund Nights</label>
                      <input
                        placeholder="Refund Nights"
                        name="refund_nights"
                        type="Number"
                        required
                        onChange={(e) => setNights(e.target.value)}
                        className=" w-full px-3 h-10  border rounded-lg bg-white "
                      />
                      {permission.map((item) => {
                        if (item.name === "room_booking.change_refund" && item.value === true) {
                          return (
                            <button
                              type="Submit"
                              disabled={refundFlag}
                              className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all mt-5"
                            >
                              Send {refundFlag ? <ButtonLoading /> : <IoAdd className="w-5 h-5" />}
                            </button>
                          );
                        }
                      })}
                    </div>
                  </form>
                </div>
              </div>
            );
          } else {
            return (
              <div className="w-full h-screen">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default RequestRefund;
