import React from "react";
import { formatDate } from "../../../../../utils/dateUtils";
import AttachmentsIcons from "./AttachementsIcons";
import ChangeTicketStatusForm from "./ChangeTicketStatusForm";

function TicketHeader({ details }) {
  return (
    <div className="flex flex-col p-5 justify-center items-center">
      <ChangeTicketStatusForm status={details?.status} />

      <div className="w-full mx-7 bg-white py-6 px-4 shadow-md rounded  ">
        <div className=" w-full flex  md:items-center gap-1">
          <div className="flex flex-col gap-1 w-full ">
            <h2 className="text-3xl text-[#0B1931] capitalize"> Ticket #{details?.id} </h2>
            <p className="text-base font-medium">
              Title:
              <span className="text-sm text-gray-400 capitalize"> {details?.request_name}</span>
            </p>
            <p className="text-base font-medium capitalize">
              Priority: <span className="text-sm text-gray-400  capitalize">{details?.priority}</span>{" "}
            </p>
            <p className="text-base font-medium  ">
              Created At:
              <span className="text-sm text-gray-400  capitalize"> {formatDate(details?.createdAt)}</span>
            </p>
            <p className="text-base font-medium ">
              Created By:
              <span className="text-sm text-gray-400 capitalize"> {details?.createdBy}</span>
            </p>

            <p className="text-base font-medium">
              Website:
              <a
                href={details?.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-cyan-600 lowercase ml-1 "
              >
                {details?.website}
              </a>
            </p>

            <p className="text-base font-medium">
              Status:
              <span
                className={`text-sm ${details?.status === "closed" && "text-black-400"} ${details?.status === "resolved" && "text-green-400"}  ${details?.status === "open" && "text-green-400"} ${details?.status === "in_progress" && "text-yellow-400"} `}
              >
                {" "}
                {details?.status
                  ?.replace(/_/g, " ")
                  ?.split(" ")
                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  ?.join(" ")}
              </span>
            </p>

            <p className="text-base font-medium">
              Type:
              <span className="text-sm text-red-500 capitalize"> {details?.type}</span>
            </p>

            <p className="text-base font-medium flex gap-4 items-center">
              Attachments:{" "}
              {details?.attachments?.length > 0 ? (
                <AttachmentsIcons attachments={details?.attachments} />
              ) : (
                <span className="text-sm text-gray-400"> No attachments.</span>
              )}
            </p>
            <p className="text-base font-medium">
              Duration Of Task Complete:
              <span className="text-sm text-green-600">
                {" "}
                {details?.duration_of_task_complete ? (
                  details?.duration_of_task_complete
                ) : (
                  <span className="text-sm text-gray-400"> Not yet.</span>
                )}
              </span>
            </p>
            <p className="text-center  capitalize text-cyan-900  font-medium text-lg">Details</p>
            <div className=" w-full border-dashed border border-[#002244] rounded-lg p-5">
              <p dir="rtl" className="text-cyan-950 font-medium text-base">
                <div dangerouslySetInnerHTML={{ __html: details?.details }} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketHeader;
