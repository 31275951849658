import {
  ADD_CHILD_MAX_AGE_VALUE,
  ADD_CHILD_MIN_AGE_VALUE,
  ADD_CHILD_ORDER_VALUE,
  ADD_CHILD_PRICE_VALUE,
  ADD_DATE_VALUE,
  ADD_NEW_CHILD_TYPE_OBJ,
  ADD_NEW_DATE_RANGE,
  ADD_NEW_PRICE_TYPE_OBJ,
  ADD_NEW_ROOM_PACKAGE_OBJ,
  ADD_NEW_ROOM_VALIDITY,
  ADD_PRICE_VALUE,
  ADD_ROOM_PACKAGE_ID,
  ADD_ROOM_PACKAGE_PRICE,
  ADD_ROOM_VALUE,
  ADD_TYPE_VALUE,
  CLEAR_OLD_VALUES,
  DELETE_DATE_RANGE,
  DELETE_NEW_CHILD_TYPE_OBJ,
  DELETE_PRICE_TYPE_OBJ,
  DELETE_ROOM_PACKAGE_OBJ,
  DELETE_ROOM_VALIDITY,
  REFRESH_CONTRACT_VALIDITY,
  USE_DRAFT_VALUES,
  USE_EXISTING_VALUES,
} from "../types/types";
import { toast } from "react-toastify";
import moment from "moment";

export function addNewRoomValidity() {
  return async function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy.push({
      room: "",
      date_range: [
        {
          from_date: "",
          to_date: "",
          rooms_types: [{ price: "", type: "" }],
          validity_children_price: [],
          validity_room_packages: [{ package: "", price: 0 }],
        },
      ],
    });
    dispatch(
      (() => {
        return {
          type: ADD_NEW_ROOM_VALIDITY,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function deleteRoomValidity() {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy.pop();
    dispatch(
      (() => {
        return {
          type: DELETE_ROOM_VALIDITY,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addRoomValue(validity_index, room) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index] = {
      room: room,
      date_range: [
        {
          from_date: "",
          to_date: "",
          rooms_types: [{ price: "", type: "" }],
          validity_children_price: [],
          validity_room_packages: [{ package: "", price: 0 }],
        },
      ],
    };
    dispatch(
      (() => {
        return {
          type: ADD_ROOM_VALUE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addNewDateRange(validity_index) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range.push({
      from_date: "",
      to_date: "",
      rooms_types: [{ price: "", type: "" }],
      validity_children_price: [],
      validity_room_packages: [{ package: "", price: 0 }],
    });
    dispatch(
      (() => {
        return {
          type: ADD_NEW_DATE_RANGE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function deleteDateRange(validity_index) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range.pop();
    dispatch(
      (() => {
        return {
          type: DELETE_DATE_RANGE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addDateValue(validity_index, date_index, from_date, to_date) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].from_date = from_date;
    stateCopy[validity_index].date_range[date_index].to_date = to_date;

    dispatch(
      (() => {
        return {
          type: ADD_DATE_VALUE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addNewPriceTypeObj(validity_index, date_index) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].rooms_types.push({ price: "", type: "" });
    dispatch(
      (() => {
        return {
          type: ADD_NEW_PRICE_TYPE_OBJ,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addNewChildTypeObject(validity_index, date_index) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];

    stateCopy[validity_index].date_range[date_index].validity_children_price.push({
      start_age: "",
      end_age: "",
      ordering_child: "",
      price: "",
    });

    dispatch(
      (() => {
        return {
          type: ADD_NEW_CHILD_TYPE_OBJ,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addNewRoomPackageObj(validity_index, date_index) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];

    stateCopy[validity_index].date_range[date_index].validity_room_packages.push({
      package: "",
      price: 0,
    });

    dispatch(
      (() => {
        return {
          type: ADD_NEW_ROOM_PACKAGE_OBJ,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function deletePriceTypeObj(validity_index, date_index) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].rooms_types.pop();
    dispatch(
      (() => {
        return {
          type: DELETE_PRICE_TYPE_OBJ,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function deleteNewChildTypeObject(validity_index, date_index) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].validity_children_price.pop();
    dispatch(
      (() => {
        return {
          type: DELETE_NEW_CHILD_TYPE_OBJ,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function deleteRoomPackageObj(validity_index, date_index) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].validity_room_packages.pop();
    dispatch(
      (() => {
        return {
          type: DELETE_ROOM_PACKAGE_OBJ,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addPriceValue(validity_index, date_index, index, price) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].rooms_types[index].price = price;
    dispatch(
      (() => {
        return {
          type: ADD_PRICE_VALUE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addTypeValue(validity_index, date_index, index, type) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].rooms_types[index].type = type;
    dispatch(
      (() => {
        return {
          type: ADD_TYPE_VALUE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addChildOrderValue(validity_index, date_index, index, order) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].validity_children_price[index].ordering_child = order;
    dispatch(
      (() => {
        return {
          type: ADD_CHILD_ORDER_VALUE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addChildMinAgeValue(validity_index, date_index, index, age) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].validity_children_price[index].start_age = age;
    dispatch(
      (() => {
        return {
          type: ADD_CHILD_MIN_AGE_VALUE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addChildMaxAgeValue(validity_index, date_index, index, age) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].validity_children_price[index].end_age = age;
    dispatch(
      (() => {
        return {
          type: ADD_CHILD_MAX_AGE_VALUE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addChildPriceValue(validity_index, date_index, index, price) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].validity_children_price[index].price = price;
    dispatch(
      (() => {
        return {
          type: ADD_CHILD_PRICE_VALUE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addRoomPackageID(validity_index, date_index, index, package_id) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].validity_room_packages[index].package = package_id;
    dispatch(
      (() => {
        return {
          type: ADD_ROOM_PACKAGE_ID,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addRoomPackagePrice(validity_index, date_index, index, price) {
  return function (dispatch, getState) {
    let oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    stateCopy[validity_index].date_range[date_index].validity_room_packages[index].price = price;
    dispatch(
      (() => {
        return {
          type: ADD_ROOM_PACKAGE_PRICE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function clearOldValues() {
  return {
    type: CLEAR_OLD_VALUES,
    payload: [
      {
        room: "",
        date_range: [
          {
            from_date: "",
            to_date: "",
            rooms_types: [{ price: "", type: "" }],
            validity_children_price: [],
            validity_room_packages: [],
          },
        ],
      },
    ],
  };
}

// TODO:: implement this function
export function fromExistingValues(contract_validities) {
  return function (dispatch) {
    let formatedArr = contract_validities.map((contract_validity) => {
      return {
        room: contract_validity.id,
        date_range: contract_validity.date_range.map((range) => {
          return {
            from_date: range.from_date,
            to_date: range.to_date,
            rooms_types: range.validity_room_types,
            validity_children_price: range.validity_children_price.map((child) => {
              return {
                start_age: child?.start_age,
                end_age: child?.end_age,
                ordering_child: child?.ordering_child,
                price: child?.price,
              };
            }),
            validity_room_packages: range.validity_room_packages.map((roomPackage) => {
              return {
                package: roomPackage?.package?.id,
                price: roomPackage?.price,
              };
            }),
          };
        }),
      };
    });
    dispatch(
      (() => {
        return {
          type: USE_EXISTING_VALUES,
          payload: formatedArr,
        };
      })(),
    );
  };
}

export function fillContractValiditiesFromDraft(contract_validities) {
  return function (dispatch) {
    let formatedArr = contract_validities.map((contract_validity) => {
      return {
        room: contract_validity.room,
        date_range: contract_validity.date_range.map((range) => {
          return {
            from_date: range.from_date,
            to_date: range.to_date,
            rooms_types: range.rooms_types,
            validity_children_price: range.validity_children_price.map((child) => {
              return {
                start_age: child?.start_age,
                end_age: child?.end_age,
                ordering_child: child?.ordering_child,
                price: child?.price,
              };
            }),
            validity_room_packages: range.validity_room_packages.map((roomPackage) => {
              return {
                package: roomPackage?.package,
                price: roomPackage?.price,
              };
            }),
          };
        }),
      };
    });
    dispatch(
      (() => {
        return {
          type: USE_DRAFT_VALUES,
          payload: formatedArr,
        };
      })(),
    );
  };
}

export function addChildrenPolicy(rooms, children) {
  return function (dispatch, getState) {
    const oldState = getState().contractValidity;
    const stateCopy = [...oldState];
    let addSuccess = true;

    // Helper function to show error and set addSuccess to false
    const showErrorAndStop = (message) => {
      toast.error(message);
      addSuccess = false;
    };

    // Check 1 - If any room has a children policy added
    for (const room of rooms) {
      const roomValidity = stateCopy.find((validity) => Number(validity.room) === room.id);
      if (!roomValidity) continue;

      const isChildrenPolicyAdded = roomValidity.date_range.some(
        (dateRange) => dateRange.validity_children_price.length > 0,
      );

      if (isChildrenPolicyAdded) {
        showErrorAndStop(`Children policy already added for ${room.name} room`);
        break;
      }
    }

    if (!addSuccess) return false;

    // Check 2 - Validate max child and adult orders against room limits
    const maxChildOrder = Math.max(...children.map((child) => child.order));
    const maxAdultOrder = Math.max(...children.map((child) => child.adultOrder));

    for (const room of rooms) {
      if (room.maxChildren < maxChildOrder) {
        showErrorAndStop(`Max child order exceeds the max children (${room.maxChildren}) allowed in ${room.name} room`);
        break;
      }

      if (room.maxAdults < maxAdultOrder) {
        showErrorAndStop(`Max adult order exceeds the max adults (${room.maxAdults}) allowed in ${room.name} room`);
        break;
      }
    }

    if (!addSuccess) return false;

    // Check 3 - Verify that adult prices are set for all required adult orders
    for (const room of rooms) {
      const roomValidity = stateCopy.find((validity) => Number(validity.room) === room.id);
      if (!roomValidity) continue;

      const allAdultsOrder = children.map((child) => child.adultOrder);

      for (const dateRange of roomValidity.date_range) {
        const allAdults = dateRange.rooms_types.filter((type) => type.price !== "").map((type) => type.type);

        const missingAdults = allAdultsOrder.filter((adult) => !allAdults.includes(adult));
        if (missingAdults.length > 0) {
          showErrorAndStop(`Adults price missing for adult ${missingAdults.join(", ")} in ${room.name} room`);
          break;
        }
      }

      if (!addSuccess) break;
    }

    if (!addSuccess) return false;

    // Update the state with the new children policy
    for (const room of rooms) {
      const roomValidity = stateCopy.find((validity) => Number(validity.room) === room.id);
      if (!roomValidity) continue;

      for (const dateRange of roomValidity.date_range) {
        dateRange.validity_children_price = children.map((child) => {
          const adultPrice = dateRange.rooms_types.find((type) => type.type === child.adultOrder)?.price;

          return {
            start_age: child.minAge,
            end_age: child.maxAge,
            ordering_child: child.order,
            price: Math.ceil(adultPrice * (child.percentage / 100)),
          };
        });
      }
    }

    // Dispatch the updated state
    dispatch({
      type: REFRESH_CONTRACT_VALIDITY,
      payload: stateCopy,
    });

    return true;
  };
}

export function addContractPolicy({ rooms, validities, adults }) {
  return function (dispatch, getState) {
    const oldState = getState().contractValidity;
    let stateCopy = [...oldState];
    let addSuccess = true;

    // Helper function to show error and set addSuccess to false
    const showErrorAndStop = (message) => {
      toast.error(message);
      addSuccess = false;
    };

    // Check 1 - If any room already added before
    for (const room of rooms) {
      const roomValidity = stateCopy.find((validity) => Number(validity.room) === room.id);
      if (!roomValidity) continue;

      // const isAdultsPolicyAdded = roomValidity.date_range.some((dateRange) => dateRange.rooms_types.length > 0);

      if (roomValidity) {
        showErrorAndStop(`${room.name} room already added`);
        break;
      }
    }

    if (!addSuccess) return false;

    // Check 2 - Validate max adult orders against room limits
    const maxAdultOrder = Math.max(...adults);
    for (const room of rooms) {
      if (room.maxAdults < maxAdultOrder) {
        showErrorAndStop(
          `Max adult order (${maxAdultOrder}) exceeds the max adults (${room.maxAdults}) allowed in ${room.name} room`,
        );
        break;
      }
    }

    if (!addSuccess) return false;

    // Add the new contract policy
    for (const room of rooms) {
      const roomValidity = stateCopy.find((validity) => Number(validity.room) === room.id);
      if (!roomValidity) {
        stateCopy.push({
          room: room.id,
          date_range: validities.map((validity) => {
            return {
              from_date: moment(validity.startDate._d).format("YYYY-MM-DD"),
              to_date: moment(validity.endDate._d).format("YYYY-MM-DD"),
              rooms_types: adults.map((adult) => {
                return { price: "", type: adult };
              }),
              validity_children_price: [],
              validity_room_packages: [],
            };
          }),
        });
        stateCopy = stateCopy.filter((validity) => validity.room !== "");
      }
    }

    // Dispatch the updated state
    dispatch({
      type: REFRESH_CONTRACT_VALIDITY,
      payload: stateCopy,
    });

    return true;
  };
}
