import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { gateWayAction, getCurrencyAction, getServicesTypeAction } from "../../../redux/actions/bookingListAction";
import { useNavigate, useParams } from "react-router";
import { FaWpforms } from "react-icons/fa6";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
import jwtDecode from "jwt-decode";
import { RxUpdate } from "react-icons/rx";
import {
  getSelectedPaymentTransAction,
  updatePaymentTransaction,
} from "../../../redux/actions/paymentTransactionAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { useFormik } from "formik";
import * as Yup from "yup";

const UpdatePaymentTransaction = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [servicesType, setServicesType] = useState([]);
  const [gateWays, setGateWays] = useState([]);
  const [gatewayName, setGatewayName] = useState();
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const format = "HH:mm";
  const decodedToken = jwtDecode(token);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      employee_name: "",
      total_amount: "",
      currency_type: "",
      invoice_number: "",
      service_type: "",
      date: "",
      time: "",
      gateway: "",
    },
    validationSchema: Yup.object({
      employee_name: Yup.string()
        .required("Please enter employee name")
        .min(1, "Employee name must be greater than or equal to 2")
        .max(50, "Employee name must be smaller than or equal to 50"),
      total_amount: Yup.number()
        .required("Please enter an total amount")
        .positive(" Total_amount must be positive number")
        .integer(" Total_amount must be positive number")
        .min(0, " Total_amount must be greater than or equal to 0"),
      currency_type: Yup.string().required("Please select currency"),
      invoice_number: Yup.number()
        .required("Please enter an invoice number")
        .integer(" Invoice number must be positive number")
        .positive(" Invoice number must be positive number")
        .min(0, " Invoice number must be greater than or equal to 0"),
      service_type: Yup.string().required("Please select service type"),
      date: Yup.date().required("Please enter date"),
      time: Yup.string().required("Please enter time"),
      gateway: Yup.string().required("Please select gateway"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      const { date, time, service_type, gateway, ...restData } = values;
      const serviceCheck = servicesType.filter((item) => item.name_2 === service_type)[0].name_1;
      const gateWayCheck = gateWays.filter((item) => item.name === gateway)[0].id;
      Object.keys(restData).forEach((key) => {
        formData.append(key, restData[key]);
      });

      formData.append("booked_room", params.bookedroom);
      formData.append("creator", decodedToken.user_id);
      formData.append("transaction_date", `${date},${time}`);
      formData.append("service_type", serviceCheck);
      formData.append("gateway", gateWayCheck);
      formData.append("transaction_status", "confirmed");
      dispatch(
        updatePaymentTransaction(token, formData, params.id, (result) => {
          if (result.status === 200) {
            lightToast.success("The Jumbo4Pay Form was completed Updated");
            navigate(-1);
          }
        }),
      );
    },
  });
  useEffect(() => {
    //////////handle when reload page , alert show to make sure this i want reload ////////////
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ////////////////////////////////////////////////////////
    dispatch(
      getSelectedPaymentTransAction(
        token,
        params.id,
        (result) => {
          if (result.status === 200) {
            setFieldValue("employee_name", result.data.employee_name);
            setFieldValue("total_amount", result.data.total_amount);
            setFieldValue("currency_type", result.data.currency_type);
            setFieldValue("conversion_rate_price", result.data.conversion_rate_price);
            setFieldValue("conversion_rate", result.data.conversion_rate);
            setFieldValue("contract_currency", result.data.contract_currency);
            setFieldValue("invoice_number", result.data.invoice_number);
            setFieldValue("date", result.data.transaction_date.slice(0, 10));
            setFieldValue("time", result.data.transaction_date.slice(11, 19));
            setFieldValue("gateway", result.data.gateway_name);
            setFieldValue("service_type", result.data.service_type);
            setGatewayName(result.data.gateway_name);
            setToggle(true);
          }
        },
        signal,
      ),
    );
    dispatch(getCurrencyAction(token, signal));
    dispatch(
      getServicesTypeAction(
        token,
        (result) => {
          setServicesType(result);
        },
        signal,
      ),
    );
    dispatch(
      gateWayAction(
        token,
        (result) => {
          setGateWays(result);
        },
        signal,
      ),
    );
    return () => {
      controller.abort();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { getSelectePayment } = useSelector((state) => state.paymentTransactionReducer);
  const { getSelectePaymentFlag } = useSelector((state) => state.paymentTransactionReducer);

  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  let ServiceType = servicesType
    ? servicesType.map((item, index) => {
        return { value: item.name_1, label: item.name_2 };
      })
    : [];

  let gateway = gateWays
    ? gateWays.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const handleAmount = (e) => {
    setFieldValue("total_amount", e.target.value);
    if (values.conversion_rate) {
      setFieldValue("conversion_rate_price", e.target.value * values.conversion_rate);
    }
  };

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setFieldValue("time", `${hours1}:${minutes1}`);
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.change_payment"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {getSelectePaymentFlag && currencyList && servicesType && gateWays && !permissionFlag ? (
          <>
            <TopBarProgress />
            <Loading />
          </>
        ) : (
          permissionFlag &&
          toggle &&
          permission.map((item) => {
            if (item.name === "room_booking.change_payment" && item.value === true) {
              return (
                <div className=" w-[70%]  border-gray-300 border rounded-lg p-5 ">
                  <div className=" flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
                    <p className="flex items-center gap-x-3 text-md">
                      <FaWpforms className="w-5 h-5" />
                      Update Jumbo4pay Form{" "}
                    </p>
                  </div>
                  <form className="mt-2  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" text-center w-full text-gray-600   ">Employee Name</label>
                      <input
                        placeholder="Employee Name"
                        name="employee_name"
                        type="text"
                        defaultValue={values.employee_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.employee_name && errors.employee_name && "border border-red-800"
                        } `}
                      />
                      {touched.employee_name && errors.employee_name ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.employee_name}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600   ">Total Amount </label>
                      <input
                        placeholder="Total Amount"
                        name="total_amount"
                        type="number"
                        step="any"
                        defaultValue={values.total_amount}
                        onBlur={handleBlur}
                        onChange={handleAmount}
                        className={`w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.total_amount && errors.total_amount && "border border-red-800"
                        } `}
                      />
                      {touched.total_amount && errors.total_amount ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.total_amount}
                        </div>
                      ) : null}

                      <label className=" flex text-gray-600 ">Currency</label>
                      <Select
                        name="currency_type"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${touched.currency_type && errors.currency_type && "rgb(153 27 27)"}`,
                          }),
                        }}
                        options={CurrencyType}
                        defaultValue={[
                          {
                            label: values.currency_type,
                            value: values.currency_type,
                          },
                        ]}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("currency_type", e.label);
                          setLoadingToggle(true);

                          dispatch(
                            convertingCurrencyAction(token, e.label, values.contract_currency, (result) => {
                              if (result.status === 200) {
                                result.data.results.length !== 0
                                  ? setFieldValue(
                                      "conversion_rate_price",
                                      values.total_amount &&
                                        values.total_amount * result.data.results.map((item) => item.rate),
                                    )
                                  : setFieldValue(
                                      "conversion_rate_price",
                                      values.total_amount && values.total_amount * 1,
                                    );
                                result.data.results.length !== 0
                                  ? setFieldValue("conversion_rate", ...result.data.results.map((item) => item.rate))
                                  : setFieldValue("conversion_rate", 1);

                                setLoadingToggle(false);
                              }
                            }),
                          );
                        }}
                        isSearchable
                        className=" w-full  h-10  border rounded-lg  bg-white "
                        placeholder="Currency"
                      />
                      {touched.currency_type && errors.currency_type ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.currency_type}
                        </div>
                      ) : null}
                      {toggle && values.total_amount ? (
                        <>
                          <label className=" flex text-gray-600 ">
                            Amount With Currency Of Contract ({values.currency_type})
                          </label>
                          <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                            <div className="text-black flex items-end gap-x-1">
                              {values.conversion_rate_price}{" "}
                              <span className="text-sm font-semibold text-gray-700">{values.currency_type}</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        loadingToggle && (
                          <>
                            <ButtonLoading />
                          </>
                        )
                      )}

                      <label className=" flex text-gray-600   ">Jumbo invoice number</label>
                      <input
                        placeholder="Jumbo invoice number"
                        name="invoice_number"
                        type="text"
                        defaultValue={values.invoice_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.invoice_number && errors.invoice_number && "border border-red-800"
                        } `}
                      />
                      {touched.invoice_number && errors.invoice_number ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.invoice_number}
                        </div>
                      ) : null}

                      <label className=" flex text-gray-600 ">Service Type</label>
                      <Select
                        name="service_type"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${touched.service_type && errors.service_type && "rgb(153 27 27)"}`,
                          }),
                        }}
                        options={ServiceType}
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue("service_type", e.label)}
                        defaultValue={[
                          {
                            label: values.service_type,
                            value: values.service_type,
                          },
                        ]}
                        isSearchable
                        className=" w-full  h-10  border rounded-lg  bg-white "
                        placeholder="Service Type"
                      />
                      {touched.service_type && errors.service_type ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.service_type}
                        </div>
                      ) : null}
                      <div className="flex items-center gap-x-5 w-full">
                        <div className="w-[50%]">
                          <label className=" flex justify-center text-gray-600 ">Date</label>
                          <input
                            placeholder="Tranfer Date"
                            type="date"
                            name="date"
                            defaultValue={values.date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-full px-3 h-10  border rounded-lg floating-input bg-white ${
                              touched.date && errors.date && "border border-red-800"
                            } `}
                          />
                          {touched.date && errors.date ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.date}
                            </div>
                          ) : null}
                        </div>
                        <div className="w-[50%]">
                          <label className=" flex justify-center text-gray-600 ">Time</label>
                          <TimePicker
                            clearIcon={false}
                            name="time"
                            defaultValue={dayjs(
                              getSelectePayment && getSelectePayment.transaction_date.slice(11, 19),
                              format,
                            )}
                            format={format}
                            onBlur={handleBlur}
                            className={`w-full px-3 h-10  border rounded-lg floating-input bg-white ${
                              touched.time && errors.time && "border border-red-800"
                            } `}
                            onChange={handleTimeChange}
                          />
                          {touched.time && errors.time ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.time}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <label className=" flex text-gray-600 ">Gateway</label>
                      <Select
                        name="gateway"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${touched.gateway && errors.gateway && "rgb(153 27 27)"}`,
                          }),
                        }}
                        options={gateway}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("gateway", e.label);
                        }}
                        type="text"
                        defaultValue={[
                          {
                            label: gatewayName,
                            value: values.gateway,
                          },
                        ]}
                        isSearchable
                        className=" w-full  h-10  border rounded-lg  bg-white "
                        placeholder="Gateway"
                      />
                      {touched.gateway && errors.gateway ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.gateway}
                        </div>
                      ) : null}
                      <button
                        type="Submit"
                        disabled={isSubmitting}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Update
                        {isSubmitting ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <RxUpdate className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              );
            } else {
              return <NoPermission />;
            }
          })
        )}
      </div>
    </div>
  );
};

export default UpdatePaymentTransaction;
