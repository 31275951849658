import axios from "axios";
import api_token from "../../api/UserApi";
import { base_URL } from "../../urls";

export const accessGuestAction = (callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${base_URL}/auth/api/v1/impersonate/guest/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const checkCodeAction = (code, callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${base_URL}/link/api/check-link-customer/${code}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
