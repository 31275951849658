import React from "react";

import { MdOutlineHotelClass } from "react-icons/md";
import { Link } from "react-router-dom";
import { RiFileList3Fill } from "react-icons/ri";

const RoomTool = () => {
  return (
    <div className="formPage-wrap">
      <h2 className="header-h2">
        <MdOutlineHotelClass className="w-5  h-5" />
        Room Extra Offers
      </h2>
      <div className="form-wrap grid grid-cols-2 justify-center items-center">
        <div className="flex flex-col gap-2 justify-center items-center">
          <Link
            to="/dashboard/upgrade-tool/room-upgrade-tool/offer-form"
            className="border cursor-pointer w-24  h-24 rounded-full p-8 bg-[#002244] hover:scale-110 hover:shadow-sm transition-transform duration-200 ease-in-out"
          >
            <MdOutlineHotelClass className="h-8 w-8 text-white" />
          </Link>
          <Link to="/dashboard/upgrade-tool/room-upgrade-tool/offer-form" className="text-lg font-semibold text-center">
            Add Extra Offer
          </Link>
        </div>
        <div className="flex flex-col gap-2 justify-center items-center">
          <Link
            to="/dashboard/upgrade-tool/room-upgrade-tool/offer-list"
            className="border cursor-pointer w-24  h-24 rounded-full p-8 bg-[#002244] hover:scale-110 hover:shadow-sm transition-transform duration-200 ease-in-out"
          >
            <RiFileList3Fill className="w-8 h-8 text-white " />
          </Link>
          <Link to="/dashboard/upgrade-tool/room-upgrade-tool/offer-list" className="text-lg font-semibold text-center">
            Extra Offers List
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RoomTool;
