import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBankListAction } from "../../../redux/actions/cashAction";
import cookie from "react-cookies";
import Select from "react-select";
import { GiBank, GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { GoPerson } from "react-icons/go";
import { PiCreditCard } from "react-icons/pi";
import { BsCurrencyExchange, BsListUl } from "react-icons/bs";
import { LiaCalendar } from "react-icons/lia";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { bankTransferListAction, confirmBankTransfer } from "../../../redux/actions/bankTransferAction";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { MdOutlineAccessTime, MdOutlineDone } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import lightToast from "light-toast";
import { CiNoWaitingSign } from "react-icons/ci";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { ChequesStatusAction } from "../../../redux/actions/bankChequesActions";
import ListFilters from "../../../utils/ListFilters";

const BankTransferList = () => {
  const [bank, setBank] = useState("");
  const [bankId, setBankId] = useState("");
  // const [chequeId, setChequeId] = useState("");
  const [bankStatus, setBankStatus] = useState(false);
  const [chequeStatus, setChequeStatus] = useState(false);
  const [currency, setCurrency] = useState("");
  const [accountNumberBank, setAccountNumberBank] = useState("");
  const [load, setLoad] = useState(false);
  const [chequeTransfer, setCheque] = useState(false);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [id, setId] = useState();
  const [confirm, setConfirm] = useState(false);
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const { bankData } = useSelector((state) => state.cashReducer);
  const { bankTransferList } = useSelector((state) => state.bankTransferReducer);
  const { bankTransferListFlag } = useSelector((state) => state.bankTransferReducer);

  useEffect(() => {
    dispatch(getBankListAction(token, (res) => {}));
  }, []);

  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency, bankAccountNumber: item.account_number };
      })
    : [];

  const total = bankTransferList?.net_income === 0 ? "0" : bankTransferList?.net_income;
  // const chequeId = bankTransferList && bankTransferList?.transactions.map((item) => item?.id);
  // const location = useLocation();
  // const { hash, pathname, search } = location;
  //
  const rows =
    bankTransferList &&
    bankTransferList?.transactions.map((item, index) => {
      return {
        transferor_name: item.transferor_name,

        booked_room_id: item.booked_room_id,
        id: item.id,
        status: item.status,
        amount: item.amount,
        currency: item.currency,
        income: item.income,
        expenses: item.expenses,
        added_by: item.added_by,
        confirmed_by: item.confirmed_by,
        transaction_date: item.transaction_date,
        accountNumberBank: accountNumberBank,
        purpose: item.purpose,
      };
    });

  const columns = [
    {
      key: "transferor_name",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GoPerson className="w-4 h-4 text-yellow-500" />
          Transferor Name{" "}
        </div>
      ),

      dataIndex: "transferor_name",
      editable: false,
      render: (text, params) => {
        return (
          <div
            className={`w-full flex items-center justify-center gap-x-1 ${
              params.status === "confirmed" ? "text-green-700" : ""
            }`}
          >
            {params.transferor_name}
          </div>
        );
      },
    },

    {
      key: "accountNumberBank",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiMoneyStack className="w-4 h-4 text-yellow-500" />
          Bank Account Number
        </div>
      ),
      dataIndex: "accountNumberBank",
      editable: false,
      render: (text, params) => {
        return (
          <div
            className={`w-full flex items-center justify-center gap-x-1 ${
              params.status === "confirmed" ? "text-green-700" : ""
            }`}
          >
            {Number(params.accountNumberBank)}
          </div>
        );
      },
    },

    {
      key: "booked_room_id",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" /> purpose{" "}
        </div>
      ),
      dataIndex: "booked_room_id",
      editable: false,
      render: (text, params) => {
        return (
          <div
            className={`w-full flex items-center justify-center gap-x-1 ${
              params.status === "confirmed" ? "text-green-700" : ""
            }`}
          >
            <p className="W-[30%] ">{params.purpose}</p>
          </div>
        );
      },
    },
    // {
    //   key: "id",
    //   title: (
    //     <div className="flex items-center gap-x-1 text-xs">
    //       <BiTransfer className="w-4 h-4 text-yellow-500" /> purpose{" "}
    //     </div>
    //   ),
    //   flex: 0.7,
    //   editable: false,
    //   render: (text,params) => {
    //     return (
    //       <div
    //         className={`w-full flex items-center justify-center gap-x-1 ${
    //           params.status === "confirmed" ? "text-green-700" : ""
    //         }`}
    //       >
    //         <p className="W-[30%] ">{params.id}</p>
    //       </div>
    //     );
    //   },
    // },

    {
      key: "amount",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiTakeMyMoney className="w-4 h-4 text-yellow-500" />
          Amount
        </div>
      ),

      dataIndex: "amount",
      editable: false,
      render: (text, params) => {
        return (
          <div
            className={`w-full flex items-center justify-center gap-x-1 ${
              params.status === "confirmed" ? "text-green-700" : ""
            }`}
          >
            {params.amount}
          </div>
        );
      },
    },
    {
      key: "currency",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BsCurrencyExchange className="w-4 h-4 text-yellow-500" />
          Currency{" "}
        </div>
      ),
      dataIndex: "currency",
      editable: false,
      render: (text, params) => {
        return (
          <div
            className={`w-full flex items-center justify-center gap-x-1 ${
              params.status === "confirmed" ? "text-green-700" : ""
            }`}
          >
            {params.currency}
          </div>
        );
      },
    },
    {
      key: "income",
      title: (
        <div className="flex items-center gap-x-1 tetxt-xs">
          <PiCreditCard className="w-4 h-4 text-yellow-500" />
          Income
        </div>
      ),
      dataIndex: "income",
      editable: false,
      render: (text, params) => {
        return (
          <div
            className={`w-full flex items-center justify-center gap-x-1 mr-5  ${
              params.status === "confirmed" ? "text-green-700" : ""
            }`}
          >
            {params.income}
          </div>
        );
      },
    },

    {
      key: "expenses",
      title: (
        <div className="flex items-center  gap-x-1 text-xs">
          <GiTakeMyMoney className="w-4 h-4 text-yellow-500" />
          Expenses{" "}
        </div>
      ),
      dataIndex: "expenses",
      editable: false,
      render: (text, params) => {
        return (
          <div
            className={`w-full flex items-center justify-center gap-x-1 mr-5 ${
              params.status === "confirmed" ? "text-green-700" : ""
            }`}
          >
            {params.expenses}
          </div>
        );
      },
    },
    {
      key: "status",
      title: (
        <div className="flex items-center gap-x-1 text-xs ml-4">
          <AiOutlineInfoCircle className="w-4 h-4 text-yellow-500" />
          Status
        </div>
      ),
      dataIndex: "status",
      editable: false,
      render: (text, params) => {
        return (
          <>
            {params.status === "pending" ? (
              <button
                onClick={() => {
                  setConfirm(true);
                  setId(params.id);
                }}
                className="w-full flex items-center justify-center capitalize gap-x-1 mr-4 bg-yellow-300  text-gray-800 h-[70%] rounded-md hover:bg-yellow-400 hover:scale-105 active:scale-95 duration-300 transition-all "
              >
                {params.status}.....
              </button>
            ) : (
              <div className="w-full flex items-center capitalize justify-center gap-x-1 mr-4  h-[70%] rounded-md bg-green-700 bg-opacity-70 text-white font-semibold">
                <p>{params.status}</p> <MdOutlineDone className="w-4 h-4 mb-1 border-gray-300 border rounded-full " />
              </div>
            )}
          </>
        );
      },
    },

    {
      key: "added_by",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GoPerson className="w-4 h-4 text-yellow-500" />
          Add By{" "}
        </div>
      ),
      dataIndex: "added_by",
      editable: false,
      render: (text, params) => {
        return (
          <Tooltip
            title={
              <div className="tooltip-content ">
                <div className="w-full flex items-center gap-x-3 justify-around ">
                  <p className="flex items-center  ">
                    <LiaCalendar className="w-4 h-4 text-yellow-500 " />
                    {params.transaction_date.slice(0, 10)}
                  </p>
                  <p className="flex items-center  ">
                    <MdOutlineAccessTime className="w-4 h-4 text-yellow-500 " />
                    {params.transaction_date.slice(11, 19)}
                  </p>
                </div>
              </div>
            }
            arrow
          >
            <div
              className={`w-full flex items-center justify-center gap-x-1 ${
                params.status === "confirmed" ? "text-green-700" : ""
              }`}
            >
              <p>{params.added_by}</p>
            </div>
          </Tooltip>
        );
      },
    },

    {
      key: "confirmed_by",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GoPerson className="w-4 h-4  text-yellow-500" />
          Confirmed By{" "}
        </div>
      ),
      dataIndex: "confirmed_by",
      editable: false,
      render: (text, params) => {
        return (
          <Tooltip
            title={
              <div className="tooltip-content ">
                <div className="w-full flex items-center gap-x-2 justify-around ">
                  <p className="flex items-center  ">
                    <LiaCalendar className="w-4 h-4 text-yellow-500 " />
                    {params.transaction_date.slice(0, 10)}
                  </p>
                  <p className="flex items-center  ">
                    <MdOutlineAccessTime className="w-4 h-4 text-yellow-500 " />
                    {params.transaction_date.slice(11, 19)}
                  </p>
                </div>
              </div>
            }
            arrow
          >
            <div
              className={`w-full flex items-center justify-center gap-x-1 ${
                params.status === "confirmed" ? "text-green-700" : ""
              }`}
            >
              <p>{params.confirmed_by}</p>
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const chequePending = (e) => {
    e.preventDefault();
    setConfirmToggle(true);
    setConfirm(false);
    setLoad(true);
    setCheque(true);

    const data = {
      transaction_status: "confirmed",
    };
    dispatch(
      ChequesStatusAction(token, id, data, (result) => {
        // const url = await result.config.url;
        if (result.status === 200) {
          setCheque(true);

          setLoad(false);
          setConfirm(false);
          setConfirmToggle(false);
          setBankStatus(true);
          window.location.reload();
          lightToast.success("This Cheque Transfer was Confirmed");
        }
      }),
    );
  };
  const handlePending = (e) => {
    const data = {
      transaction_status: "confirmed",
    };
    setConfirmToggle(true);
    setConfirm(false);
    setLoad(true);
    dispatch(
      confirmBankTransfer(token, id, data, (result) => {
        if (result.status === 200) {
          setConfirm(false);
          setConfirmToggle(false);
          setLoad(false);
          lightToast.success("This Bank Transfer was Confirmed");
          dispatch(
            bankTransferListAction(token, bankId, (result) => {
              if (result.status === 200) {
                setLoad(false);
              } else if (result.status === 400) {
                setBankStatus(true);
              }
            }),
          );
        } else {
          setConfirmToggle(false);
        }
      }),
    );
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.view_bank"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission?.map((item, index) => {
          if (item.name === "accounting.view_bank" && item.value === true) {
            return (
              <div key={index} className="w-full">
                <div
                  className={` fixed ${
                    confirm ? "top-0 left-0 right-0 bottom-0 h-screen  " : ""
                  }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
                >
                  <div
                    className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-5  px-2    ${
                      confirm
                        ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                        : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
                    } `}
                  >
                    <p className="text-xl font-light text-white">Do You Want Confirm This Bank Transfer?</p>
                    <div className="w-full flex items-center justify-center gap-x-14 text-white text-sm">
                      <button
                        onClick={true ? chequePending : handlePending}
                        className="bg-green-700 rounded-md px-3 py-1.5 hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Yes,I'm Sure
                      </button>
                      <button
                        onClick={() => {
                          setConfirm(false);
                          setConfirmToggle(false);
                        }}
                        className="bg-red-700 rounded-md px-3 py-1.5 hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        No,take me back
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-full flex justify-center ">
                  <h2 className="header-h2 ">
                    <BsListUl className="w-6 h-6 mt-1" /> Bank Transfer List
                  </h2>
                </div>

                <form className="form-wrap">
                  <div className={`input-par flex-wrap `}>
                    <div className="input-chil">
                      <p className=" flex text-black font-semibold  text-lg ">Please Select Bank Name</p>
                      <Select
                        placeholder="Bank Name"
                        name="bank_name"
                        type="text"
                        isSearchable
                        className=" w-full  h-10  rounded-lg  bg-white font-semibold text-gray-800 z-50"
                        required
                        options={Bank}
                        onChange={(e) => {
                          setLoad(true);
                          setBankStatus(false);
                          // setChequeStatus(false);
                          setBank(e.label);
                          setBankId(e.value);
                          setCurrency(e.currency);
                          setAccountNumberBank(e.bankAccountNumber);
                          dispatch(
                            bankTransferListAction(token, e.value, (result) => {
                              if (result.status === 200) {
                                setLoad(false);
                              } else if (result.status === 400) {
                                setBankStatus(true);
                                // setChequeStatus(true);
                              }
                            }),
                          );
                        }}
                      />
                    </div>

                    <div className="input-chil">
                      <p className=" flex text-gray-800  font-bold  ">Currency </p>
                      <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                        <p className=" font-semibold text-gray-800">{currency}</p>
                      </div>
                    </div>
                    <div className="input-par">
                      <div className="input-chil">
                        <p className=" flex text-gray-800  font-bold  ">Account Number </p>
                        <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                          <p className=" font-semibold text-gray-800 ">{accountNumberBank}</p>
                        </div>
                      </div>
                      <div className=" input-chil flex flex-col  ">
                        <span className=" font-bold">Account Ballance </span>
                        {total ? (
                          <span className=" rounded-md  px-4 py-1.5 border w-full">{total}</span>
                        ) : (
                          <span className=" rounded-md  px-4 py-1.5 border w-full">0</span>
                        )}
                      </div>
                    </div>
                    {bank && !load && (
                      <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4 mt-11  border-solid">
                        <div
                          name="currency"
                          className=" w-full px-3 h-10  border rounded-md floating-input bg-yellow-300 bg-opacity-80 text-black"
                        >
                          <div className="text-black mt-2 flex items-center justify-around">
                            <p className="flex items-center gap-x-1 text-black font-semibold ">
                              <CiNoWaitingSign className="w-5 h-5 text-gray-700" />
                              Pending Transfer...
                            </p>
                            <p className="flex items-center justify-center  w-5 h-5  font-semibold  rounded-full text-xl text-gray-700 ">
                              {
                                bankTransferList?.transactions?.filter((item, index) => item.status === "pending")
                                  .length
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
                {load ? (
                  <>
                    {" "}
                    <TopBarProgress />
                    <div className="w-full h-[70%] flex items-center justify-center">
                      <Loading />
                    </div>
                  </>
                ) : bank ? (
                  <div className="w-full overflow-auto">
                    <ListFilters columns={columns} rows={rows} title="Bank Transfer List" />
                  </div>
                ) : (
                  <div className="w-full h-[70%] flex flex-col items-center justify-center ">
                    <GiBank className="w-48 h-48 text-gray-400 opacity-40" />
                    <p className="font-semibold text-center text-2xl text-gray-800 opacity-50">
                      No Bank Name Has Been Selected
                    </p>{" "}
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div key={index} className="w-full h-screen">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default BankTransferList;
