import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardCount from "../../organisms/Cart/CardCount";
import CardItem from "../../organisms/Cart/CardItem";
import CardEmpty from "../../organisms/Cart/CardEmpty";
import { statusCardAction, totalPirce } from "../../../redux/actions/bookingOrderAction";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WhatsAppMessage from "../../organisms/WhatsAppMessage/WhatsAppMessage";
import { FaWhatsapp } from "react-icons/fa6";
import { MdEmail, MdContentCopy } from "react-icons/md"; // Import copy icon
import EmailMessage from "../../organisms/EmailMessage/EmailMessage";
import { toast } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS
import cookie from "react-cookies";
import { Spinner } from "flowbite-react";

const BookingOrder = ({ permissionFlag, permissionswhatsUpemail }) => {
  const dispatch = useDispatch();
  const { cardStatus } = useSelector((state) => state.cardReducer);
  const { cardData } = useSelector((state) => state.cardReducer);
  const { cardTotalPrice } = useSelector((state) => state.cardReducer);
  const [cardToggle, setCardToggle] = useState(true);
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  const [openModal, setOpenModal] = useState(false);
  const [openModalEmail, setopenModalEmail] = useState(false);

  useEffect(() => {
    dispatch(totalPirce(cardData));
  }, [cardData]);

  const handleCopyData = async () => {
    try {
      // Start with the introduction message (only once, outside the loop)
      let formattedMessage = `*نقدم لحضراتكم*\n\n`;

      // Initialize variables to keep track of previous data
      let previousHotel = null;
      let previousPeriod = null; // New variable to track previous period
      let displayedPeriods = new Map(); // Map to track already displayed periods for each hotel

      // Loop through each item in cardData to generate the message for each hotel and room
      formattedMessage += cardData
        .map((item) => {
          const checkInDate = new Date(item?.formCheckIn_whats_app).toLocaleDateString(localeCode, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          const checkOutDate = new Date(item?.formCheckOut_whats_app).toLocaleDateString(localeCode, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          let message = "";

          // Construct the period string
          const period = `${checkInDate}-${checkOutDate}-${item.nights_whats_app}`;

          // Add a separator (********) only if the period changes
          if (previousPeriod && previousPeriod !== period) {
            message += "********\n\n";
          }

          // Show period only once per unique combination of dates and nights
          if (!displayedPeriods.has(period)) {
            message += `*اسعار الفترة من ${checkInDate} الى ${checkOutDate}*\n`;
            message += `*عدد الليالي:* ${item.nights_whats_app}\n\n`;
            displayedPeriods.set(period, new Set()); // Mark this period as shown
          }

          // Track if the hotel has already been shown for this period
          if (!displayedPeriods.get(period).has(item.hotel_AR)) {
            // Only show hotel details once per period
            message += `*أسم الفندق:* ${item.hotel_AR}\n\n`;
            displayedPeriods.get(period).add(item.hotel_AR); // Mark this hotel as shown for the period
          }

          // Always add the room, meal, and price details
          message += `*نوع الغرفة:* ${item.selectedroom}\n`;
          message += `*نوع الوجبات:* ${t(item.mealType)}\n`;
          message += `*عدد البالغين:* ${item.number_of_persons}\n`;
          message += `*عدد الأطفال:* ${item.children_num}\n`;

          if (item.children_ages?.length > 0) {
            message += `*أعمار الأطفال:* ${item.children_ages.map((age) => `(${age} سنوات)`).join(", ")}\n`;
          }

          message += `*المبلغ الإجمالي:* ${new Intl.NumberFormat(localeCode).format(item.price)} ${t(item.currency_type)}\n`;

          // Update the previous period
          previousPeriod = period;

          return message;
        })
        .join("\n");

      // Add the final part of the message
      formattedMessage += "\n*جميع الاسعار المذكورة أعلاه شاملة جميع الضرائب والرسوم المقررة*\n";

      // Copy formatted message to clipboard
      await navigator.clipboard.writeText(formattedMessage);

      toast.success("Copied message to clipboard!", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } catch (error) {
      toast.error("Failed to copy message!", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <div dir={t("dir")}>
      <div
        className={`fixed  ${cardStatus ? "top-0 left-0 right-0 bottom-0 " : ""} blur-effect-theme w-full opacity-100 z-[250] overflow-x-hidden`}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            dispatch(statusCardAction(!cardStatus));
          }
        }}
      >
        <div
          className={`bg-white  max-w-2xl w-full absolute right-0 ${
            cardStatus
              ? "opacity-100 visible right-0 transition-all duration-400 transform "
              : "opacity-0 invisible -right-full translate-x-8 transition-all duration-400 transform "
          }`}
        >
          <CardCount />
          {cardData?.length === 0 ? (
            <CardEmpty />
          ) : (
            <div className="bg-white h-full flex flex-col justify-between ">
              <div className="flex w-full justify-center">
                <div
                  dir={t("dir")}
                  className={`font-sans  max-w-2xl flex flex-col items-center justify-start mt-5 gap-y-7 lg:gap-y-5 px-2 
                    overflow-y-scroll ${permissionswhatsUpemail?.some((item) => item.value) ? "md:h-[60vh] h-[53vh]" : "md:h-[70vh] h-[65vh]"} w-full scroll-smooth md:py-3`}
                >
                  {cardData?.map((item, index) => (
                    <CardItem cardData={item} key={index} />
                  ))}
                </div>
              </div>

              <div className="bg-white flex flex-col mt-4 w-full gap-y-2 h-max p-2 ">
                <div className="flex items-center justify-start gap-x-5 ml-5">
                  <h1 className="text-sm sm:text-base font-semibold uppercase text-yellow-600">{t("subTotal")}</h1>
                  <div className="text-lg md:text-2xl rounded text-slate-100 px-2 py-0.5 bg-[#002244] flex items-end gap-x-1">
                    {new Intl.NumberFormat(localeCode).format(
                      cardData.reduce((accumulator, currentItem) => {
                        return accumulator + currentItem.price * currentItem.quantity;
                      }, 0),
                    )}
                    <span className="text-xs">{t(cardData[0].currency_type)}</span>
                  </div>
                </div>

                <Link
                  to={"/multireservation"}
                  onClick={() => {
                    dispatch(statusCardAction(!cardToggle));
                  }}
                  className="w-full mb-2 py-3 bg-[#002244] text-white text-center hover:bg-blue-900 active:scale-95 duration-300 transition-all rounded-md"
                >
                  {t("bookNow")}
                </Link>
                <div className="flex w-full justify-center items-center">
                  <div className="h-[2px] w-1/2 bg-gray-200" />
                </div>
                <button
                  onClick={handleCopyData}
                  className="flex items-center justify-center gap-2 py-2 px-4 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-md"
                >
                  <MdContentCopy className="text-xl" />
                  {t("copyData")}
                </button>

                {permissionFlag ? (
                  permissionswhatsUpemail?.some((item) => item.value) && (
                    <>
                      <button
                        onClick={() => setOpenModal(true)}
                        className="w-full py-3 bg-[#075e54] capitalize flex justify-center items-center gap-2 text-white text-center hover:bg-[#128c7e] active:scale-95 duration-300 transition-all rounded-md"
                      >
                        <FaWhatsapp className="text-2xl text-[#25d366]" />
                        {t("sendwhatsapp")}
                      </button>

                      <button
                        onClick={() => setopenModalEmail(true)}
                        className="w-full py-3 bg-indigo-800 capitalize hover:bg-indigo-900 flex justify-center items-center gap-2 text-white text-center active:scale-95 duration-300 transition-all rounded-md"
                      >
                        <MdEmail className="text-2xl text-white" />
                        {t("sendEmail")}
                      </button>
                    </>
                  )
                ) : (
                  <div className="w-full flex justify-center mb-[0.1rem]">
                    <Spinner aria-label="Default status example" />
                  </div>
                )}
              </div>
              {openModal && <WhatsAppMessage openModal={openModal} setOpenModal={setOpenModal} />}
              {openModalEmail && <EmailMessage openModalEmail={openModalEmail} setopenModalEmail={setopenModalEmail} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingOrder;
