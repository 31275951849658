import { Button, Modal } from "flowbite-react";
import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { useTranslation } from "react-i18next";

const RoomsExtraOffers = ({ OffersRoomInfo, setOffersRoomInfo, RoomOffersData, RoomOffersloading }) => {
  const [t, i18n] = useTranslation();

  return (
    <div>
      {OffersRoomInfo && (
        <Modal dismissible show={OffersRoomInfo} onClose={() => setOffersRoomInfo(false)}>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            {!RoomOffersloading ? (
              RoomOffersData &&
              RoomOffersData?.length > 0 && (
                <div dir={t("dir")}>
                  {RoomOffersData.map((Offer) => (
                    <div className="  flex justify-between flex-col">
                      <p className="mb-5 md:text-2xl text-xl font-bold text-[#002244]">
                        {" "}
                        {Offer?.room_upgrade_name} 🔥
                      </p>

                      <p className="mb-1 md:text-base text-sm flex gap-2 font-medium text-[#002244]">
                        <span className="text-red-700"> {t("fromRoom")} : </span>
                        {Offer?.room_name}
                      </p>
                      <p className=" mb-5 md:text-base text-sm flex gap-2 font-medium text-[#002244]">
                        <span className="text-green-700"> {t("toRoom")} : </span>
                        {Offer?.upgrade_room_name}
                      </p>

                      <p className=" md:text-base text-sm font-medium text-gray-600">
                        {Offer?.room_upgrade_description}
                      </p>
                    </div>
                  ))}
                </div>
              )
            ) : (
              <div className="ww-full flex h-full items-center justify-between ">
                <TopBarProgress />
                <Loading />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button color="gray" onClick={() => setOffersRoomInfo(false)}>
              {t("exit")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default RoomsExtraOffers;
