import * as yup from "yup";

export const addRoomValidationSchema = yup.object().shape({
  hotel_name: yup.string().required("This Field is Required"),
  room_name: yup.string().required("This Field is Required"),
  room_name_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),
  description: yup.string().required("This Field is Required"),
  description_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),
  space: yup.number("It must be a number").positive(`You can't put negative value`).required("This Field is Required"),
  max_adults: yup
    .number("It must be a number")
    .positive(`You can't put negative value`)
    .required("This Field is Required"),
  max_children: yup
    .number("It must be a number")
    .positive(`You can't put negative value`)
    .required("This Field is Required"),
  max_occupancy: yup
    .number("It must be a number")
    .positive(`You can't put negative value`)
    .required("This Field is Required"),
  view: yup.string().required("This Field is Required"),
  view_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),
  balcony: yup.string().required("This Field is Required"),
  features: yup.array().of(yup.string()).required("This Field is Required"),
  ordering: yup
    .number("It must be a number")
    .positive(`You can't put negative value`)
    .required("This Field is Required"),
  room_images: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required("ID is required"),
        file: yup.mixed().required("File is required"),
        name: yup.string().required("Name is required"),
        size: yup.number().required("Size is required"),
        type: yup.string().required("Type is required"),
        valid: yup.boolean().required("Valid status is required"),
      }),
    )
    .min(1, "At least one image is required")
    .required("This field is required"),
});
