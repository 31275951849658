import { LuUpload } from "react-icons/lu";
import { ErrorMessage, Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import { GoPerson } from "react-icons/go";
import { MdOutlineMail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaMinusCircle, FaPlusCircle, FaRegAddressCard } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { GrNotes } from "react-icons/gr";
import React from "react";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { flushSync } from "react-dom";
import { HiXMark } from "react-icons/hi2";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";

import {
  FieldErrorText,
  FormCheckboxInput,
  FormLabel,
  FormPhoneInput,
  FormSection,
  FormTextInput,
} from "../../../customComponents/FormComponents";

const ClientForm = ({ onSubmit, validationSchema, initialValues, image, setImage, isPending, submitButtonTitle }) => {
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      flushSync(() => {
        setImage((prev) => {
          return {
            ...prev,
            src: URL.createObjectURL(event.target.files[0]),
            file: event.target.files[0],
          };
        });
      });
      event.target.value = null;
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, setValues, errors }) => {
        return (
          <>
            <Form className="space-y-6 mt-2 w-full">
              <FormSection title="Basic Info">
                <div className="flex items-center relative justify-center">
                  <img src={image.src} className="rounded-full object-cover w-36 h-36 bg-gray-600 mt-2" alt={""} />
                  <input id={"image-upload"} type="file" onChange={onImageChange} className="hidden" />

                  <label
                    htmlFor={"image-upload"}
                    className="absolute cursor-pointer p-3 bg-[#002230] hover:bg-[#002244] cursor:pointer  flex items-center justify-center rounded-full  text-white bottom-0 left-[53%] hover:scale-105 active:scale-95 duration-300 transition-all  "
                  >
                    <LuUpload className="w-5 h-5 " />
                  </label>

                  {image.src && (
                    <button
                      onClick={() => {
                        setImage((prev) => {
                          return {
                            ...prev,
                            src: "",
                            file: null,
                          };
                        });
                      }}
                      className=" absolute rounded-full  top-[.5rem] left-[53%] hover:scale-105 active:scale-95 duration-300 transition-all cursor-pointer p-3 bg-red-900 hover:bg-red-700"
                    >
                      <HiXMark className=" text-white w-5 h-5" />
                    </button>
                  )}
                </div>
                <FormTextInput label="Name" name="name" type="text" placeholder="Client name" Icon={GoPerson} />
                <div className="flex gap-2">
                  <FormTextInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    Icon={GoPerson}
                  />
                  <FormTextInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    Icon={GoPerson}
                  />
                </div>
                <FormTextInput
                  label="Username"
                  name="username"
                  type="text"
                  placeholder="Client username"
                  Icon={GoPerson}
                />
                <FormTextInput
                  label="Nickname"
                  name="nickname"
                  type="text"
                  placeholder="Client nickname"
                  Icon={GoPerson}
                />
                <FormTextInput label="Email" name="email" type="text" placeholder="Client email" Icon={MdOutlineMail} />
                <FormTextInput
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Client password"
                  Icon={RiLockPasswordLine}
                />

                <FormTextInput
                  label="ID Number"
                  name="id"
                  type="text"
                  placeholder="Client ID number"
                  Icon={FaRegAddressCard}
                />

                <CountrySelectInput />

                <CitySelectInput />

                <div>
                  <FormLabel label={"Mobile Numbers"} htmlFor={"mobileNumbers"} />

                  <FieldArray
                    name="mobileNumbers"
                    render={(arrayHelpers) => (
                      <div>
                        <div role="group" aria-labelledby="checkbox-group">
                          {values.mobileNumbers && values.mobileNumbers.length > 0 ? (
                            values.mobileNumbers.map((mobileNumber, index) => (
                              <div key={index} className="flex items-center mb-2 justify-between gap-4 w-full">
                                <div className={"w-full"}>
                                  <FormPhoneInput
                                    placeholder={`Mobile number ${index + 1}`}
                                    name={`mobileNumbers[${index}].num`}
                                  />
                                </div>
                                <FormCheckboxInput
                                  onChange={() => {
                                    setValues({
                                      ...values,
                                      mobileNumbers: values.mobileNumbers.map((item, i) =>
                                        i === index
                                          ? {
                                              ...item,
                                              whatsapp: !item.whatsapp,
                                            }
                                          : item,
                                      ),
                                    });
                                  }}
                                  checked={mobileNumber.whatsapp}
                                  name={`mobileNumbers[${index}].whatsapp`}
                                  value={`mobileNumbers[${index}].whatsapp`}
                                >
                                  <IoLogoWhatsapp className=" cursor-pointer w-6 h-6 text-green-500" />
                                </FormCheckboxInput>

                                {index !== 0 && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }} // remove a mobile number from the list
                                  >
                                    <FaMinusCircle className="w-6 h-6 rounded-full text-red-700 hover:text-red-900" />
                                  </button>
                                )}
                              </div>
                            ))
                          ) : (
                            <></>
                          )}

                          {typeof errors.mobileNumbers === "string" ? (
                            <FieldErrorText message={errors.mobileNumbers} />
                          ) : null}

                          <div className="flex justify-end items-center  mt-2 ">
                            <button
                              className="rounded px-2 py-1  flex justify-center items-center gap-1  text-white bg-green-700 hover:bg-green-900 "
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  num: "+20",
                                  whatsapp: false,
                                })
                              }
                            >
                              <FaPlusCircle className="w-5 h-5 text-white" />
                              <p>New Mobile Number</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>

                <div className="w-full">
                  <FormLabel label={"Notes"} htmlFor={"notes"} />

                  <div className="w-full relative text-gray-600">
                    <Field
                      defaultValue={values.notes}
                      id={"notes"}
                      name="notes"
                      className=" w-full py-2 px-8 h-10 border rounded-lg bg-white"
                      as="textarea"
                    />
                    <div className="absolute top-[11px] left-2">
                      <GrNotes className="w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                </div>
                {/*<div className="flex justify-around items-center">*/}
                {/*  <FormCheckboxInput*/}
                {/*    name={"active"}*/}
                {/*    defaultChecked={values.active}*/}
                {/*    onChange={() => {*/}
                {/*      void setValues({*/}
                {/*        ...values,*/}
                {/*        active: !values.active,*/}
                {/*      });*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <p className="font-semibold text-gray-600"> Active </p>*/}
                {/*  </FormCheckboxInput>*/}

                {/*  <FormCheckboxInput*/}
                {/*    name={"whitelist"}*/}
                {/*    defaultChecked={values.whitelist}*/}
                {/*    onChange={() => {*/}
                {/*      void setValues({*/}
                {/*        ...values,*/}
                {/*        whitelist: !values.whitelist,*/}
                {/*      });*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <p className="font-semibold text-gray-600"> Whitelist </p>*/}
                {/*  </FormCheckboxInput>*/}
                {/*</div>*/}
              </FormSection>

              <FormSection title={"Hotels Booking Settings"}>
                <MarkupSelectInput />
                <UserGroupSelectInput />
                <>
                  <FormLabel label={"Client Account"} htmlFor={"clientAccount"} />
                  <Select
                    defaultValue={{
                      label: values.clientAccount
                        .split("_")
                        .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
                        .join(" "),
                      value: values.clientAccount,
                    }}
                    isSearchable
                    onChange={(newValue) => {
                      void setValues({
                        ...values,
                        clientAccount: newValue.value,
                      });
                    }}
                    name={"clientAccount"}
                    inputId={"clientAccount"}
                    options={[
                      {
                        label: "Pay first night",
                        value: "pay_first_night",
                      },
                      {
                        label: "Payment upon check-in",
                        value: "payment_upon_check_in",
                      },
                      {
                        label: "Full payment",
                        value: "full_payment",
                      },
                      {
                        label: "Hotel payment strategy",
                        value: "hotel_payment_strategy",
                      },
                    ]}
                  />
                </>
              </FormSection>
              <FormSection title={"Hotels preferences"}>
                <FavoriteCitiesSelectInput />
                <FavoriteHotelsSelectInput />
                <>
                  <FormLabel label={"Smoking Preferences"} htmlFor={"smokingPreferences"} />
                  <Select
                    onChange={(selectedOption, actionMeta) => {
                      if (actionMeta.action === "clear") {
                        void setValues({
                          ...values,
                          smokingPreferences: "",
                        });
                        return;
                      }
                      void setValues({
                        ...values,
                        smokingPreferences: selectedOption.value,
                      });
                    }}
                    defaultValue={
                      values.smokingPreferences === ""
                        ? null
                        : {
                            value: values.smokingPreferences,
                            label: values.smokingPreferences
                              .split("_")
                              .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
                              .join(" "),
                          }
                    }
                    isClearable
                    isSearchable
                    name={"smokingPreferences"}
                    inputId={"smokingPreferences"}
                    options={[
                      {
                        label: "Smoking",
                        value: "smoking",
                      },
                      {
                        label: "Non Smoking",
                        value: "non_smoking",
                      },
                    ]}
                  />
                </>
                <>
                  <FormLabel label={"Bed Preferences"} htmlFor={"bedPreferences"} />
                  <Select
                    onChange={(selectedOption, actionMeta) => {
                      if (actionMeta.action === "clear") {
                        void setValues({
                          ...values,
                          bedPreferences: "",
                        });
                        return;
                      }
                      void setValues({
                        ...values,
                        bedPreferences: selectedOption.value,
                      });
                    }}
                    defaultValue={
                      values.smokingPreferences === ""
                        ? null
                        : {
                            value: values.bedPreferences,
                            label: values.bedPreferences
                              .split("_")
                              .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
                              .join(" "),
                          }
                    }
                    isSearchable
                    isClearable
                    name={"bedPreferences"}
                    inputId={"bedPreferences"}
                    options={[
                      {
                        label: "King size bed",
                        value: "king_size_bed",
                      },
                      {
                        label: "Twin beds",
                        value: "twin_beds",
                      },
                    ]}
                  />
                </>

                <div className="w-full">
                  <FormLabel label={"Extra Info"} htmlFor={"extraInfo"} />

                  <div className="w-full relative text-gray-600">
                    <Field
                      defaultValue={values.extraInfo}
                      id={"extraInfo"}
                      name="extraInfo"
                      className=" w-full py-2 px-8 h-10 border rounded-lg bg-white"
                      as="textarea"
                    />
                    <div className="absolute top-[11px] left-2">
                      <GrNotes className="w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                </div>
              </FormSection>
              <div className="flex justify-center items-center">
                <Button
                  className="mb-4"
                  color="success"
                  isProcessing={isPending}
                  type="submit"
                  disabled={isPending}
                  processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
                >
                  {submitButtonTitle}
                </Button>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const CountrySelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingCountries,
    data: countries,
    error: errorFetchingCountries,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery("/hotels/api/v1/get-countries/");
    },
    queryKey: ["countries"],
  });

  // Define the desired order of countries to put at the top
  const topCountries = [
    "Saudi Arabia",
    "Kuwait",
    "Qatar",
    "UAE",
    "Bahrain",
    "Iraq",
    "Lebanon",
    "Jordan",
    "Libya",
    "Yemen",
    "Egypt",
  ];

  // Create a mapping of the desired order
  const topCountriesOrder = topCountries.reduce((acc, country, index) => {
    acc[country] = index;
    return acc;
  }, {});

  // Separate the countries into the top list and the rest
  const reorderedCountries = [];
  const remainingCountries = [];

  countries?.forEach((country) => {
    if (topCountries.includes(country.name)) {
      reorderedCountries.push(country);
    } else {
      remainingCountries.push(country);
    }
  });

  // Sort the reorderedCountries based on the defined order
  reorderedCountries.sort((a, b) => topCountriesOrder[a.name] - topCountriesOrder[b.name]);

  // Concatenate the reordered list with the remaining countries
  const finalList = reorderedCountries.concat(remainingCountries);

  return (
    <div>
      <FormLabel label={"Country"} htmlFor={"country"} />
      <Select
        name={"country"}
        inputId={"country"}
        isDisabled={isFetchingCountries}
        defaultValue={{
          value: values.country,
          label: values.countryName,
        }}
        placeholder={"Client Country"}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            country: selectedOption.value,
            city: "",
          });
        }}
        options={finalList?.map((country) => {
          return {
            value: country.id,
            label: country.name,
          };
        })}
      />
      <ErrorMessage name={"country"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingCountries && <FieldErrorText message={"Error Fetching Countries"} />}
    </div>
  );
};

const FavoriteCitiesSelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingCities,
    data: cities,
    error: errorFetchingCountries,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/search/api/v1/get-cities/`);
    },
    queryKey: ["hotels-cities"],
  });

  return (
    <>
      <FormLabel label={"Favorite Cities"} htmlFor={"favoriteCities"} />
      <Select
        value={values.favoriteCities?.map((city) => {
          return {
            value: city,
            label: cities?.find((c) => c.id === city)?.name,
          };
        })}
        name={"favoriteCities"}
        inputId={"favoriteCities"}
        onChange={(selectedOption, actionMeta) => {
          if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
            void setValues({
              ...values,
              favoriteCities: values.favoriteCities?.filter((city) => city !== actionMeta.removedValue.value),
              favoriteHotels: [],
            });
            return;
          }

          if (actionMeta.action === "clear") {
            void setValues({
              ...values,
              favoriteCities: [],
              favoriteHotels: [],
            });
            return;
          }

          void setValues({
            ...values,
            favoriteCities: [...values.favoriteCities, actionMeta.option.value],
            favoriteHotels: [],
          });
        }}
        isMulti
        isDisabled={isFetchingCities}
        isLoading={isFetchingCities}
        loadingMessage={() => {
          return "Loading Cities";
        }}
        options={cities?.map((city) => {
          return {
            label: city.name,
            value: city.id,
          };
        })}
      />
      <ErrorMessage name={"favoriteCities"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingCountries && <FieldErrorText message={"Error Fetching Hotels Cities"} />}
    </>
  );
};

const FavoriteHotelsSelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingHotels,
    data: hotels,
    error: errorFetchingHotels,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${values.favoriteCities.join(",")}]`);
    },
    queryKey: ["hotels", ...values.favoriteCities],
  });

  return (
    <>
      <FormLabel label={"Favorite Hotels"} htmlFor={"favoriteHotels"} />
      <Select
        value={values.favoriteHotels?.map((hotel) => {
          return {
            value: hotel,
            label: hotels?.find((h) => h.id === hotel)?.name,
          };
        })}
        name={"favoriteHotels"}
        inputId={"favoriteHotels"}
        onChange={(selectedOption, actionMeta) => {
          if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
            void setValues({
              ...values,
              favoriteHotels: values.favoriteHotels?.filter((hotel) => hotel !== actionMeta.removedValue.value),
            });
            return;
          }

          if (actionMeta.action === "clear") {
            void setValues({
              ...values,
              favoriteHotels: [],
            });
            return;
          }

          void setValues({
            ...values,
            favoriteHotels: [...values.favoriteHotels, actionMeta.option.value],
          });
        }}
        isMulti
        placeholder={values.favoriteCities.length === 0 ? "Select Cities First" : "Select Hotels"}
        isDisabled={isFetchingHotels}
        isLoading={isFetchingHotels}
        loadingMessage={() => {
          return "Loading Cities";
        }}
        options={hotels?.map((hotel) => {
          return {
            label: hotel.name,
            value: hotel.id,
          };
        })}
      />
      <ErrorMessage name={"favoriteHotels"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingHotels && <FieldErrorText message={"Error Fetching Hotels "} />}
    </>
  );
};

const CitySelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingCities,
    data: cities,
    error: errorFetchingCountries,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/hotels/api/v1/get-cities/new/${values.country}/`);
    },
    queryKey: ["cities", values.country],
  });

  return (
    <div>
      <FormLabel label={"City"} htmlFor={"city"} />
      <Select
        defaultValue={
          !values.city
            ? null
            : {
                value: values.city,
                label: values.cityName,
              }
        }
        isClearable
        key={values.country}
        inputId={"city"}
        name="city"
        isDisabled={isFetchingCities}
        placeholder={"Client City"}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            city: selectedOption === null ? "" : selectedOption.value,
          });
        }}
        options={cities?.map((city) => {
          return {
            value: city.id,
            label: city.name,
          };
        })}
      />
      <ErrorMessage name={"city"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingCountries && <FieldErrorText message={"Error Fetching Cities"} />}
    </div>
  );
};
const MarkupSelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingMarkups,
    data: markups,
    error: errorFetchingMarkups,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/markup/api/search/`);
    },
    queryKey: ["markups"],
  });

  return (
    <div>
      <FormLabel label={"Markup"} htmlFor={"markup"} />
      <Select
        value={
          !values.markup
            ? null
            : {
                value: values.markup,
                label: markups?.find((markup) => markup.id === values.markup)?.name,
              }
        }
        isSearchable
        loadingMessage={() => {
          return "Loading Markups";
        }}
        isLoading={isFetchingMarkups}
        name="markup"
        isDisabled={isFetchingMarkups}
        placeholder={"Select Markup"}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            markup: selectedOption.value,
          });
        }}
        options={markups?.map((markup) => {
          return {
            value: markup.id,
            label: markup.name,
          };
        })}
      />
      <ErrorMessage name={"markup"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingMarkups && <FieldErrorText message={"Error Fetching Markups"} />}
    </div>
  );
};

const UserGroupSelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingGroups,
    data: groups,
    error: errorFetchingGroups,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/permissions/api/v1/user-groups/`);
    },
    queryKey: ["user-groups"],
  });

  return (
    <div>
      <FormLabel label={"Group"} htmlFor={"groups"} />
      <Select
        value={values.groups?.map((group) => {
          return {
            value: group,
            label: groups?.find((g) => g.id === group)?.name,
          };
        })}
        isMulti
        isSearchable
        loadingMessage={() => {
          return "Loading Groups";
        }}
        isLoading={isFetchingGroups}
        name="groups"
        isDisabled={isFetchingGroups}
        placeholder={"Select a Group"}
        onChange={(selectedOption, actionMeta) => {
          if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
            void setValues({
              ...values,
              groups: values.groups?.filter((group) => group !== actionMeta.removedValue.value),
            });
            return;
          }

          if (actionMeta.action === "clear") {
            void setValues({
              ...values,
              groups: [],
            });
            return;
          }

          void setValues({
            ...values,
            groups: [...values.groups, actionMeta.option.value],
          });
        }}
        options={groups?.map((group) => {
          return {
            value: group.id,
            label: group.name,
          };
        })}
      />
      <ErrorMessage name={"groups"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingGroups && <FieldErrorText message={"Error Fetching User Groups"} />}
    </div>
  );
};

export default ClientForm;
