import React, { useEffect } from "react";
import "../ContractDetails/ContractDetails";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { clearSingleContract, getSingleContract } from "../../../redux/actions/getContracts";
import ContractTable from "../../molecules/ContractTable/ContractTable";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function reshapeText(text) {
  let firstReshape = text?.replace(/_/g, " ");
  let secondReshape = firstReshape[0]?.toUpperCase() + firstReshape?.slice(1);
  return secondReshape;
}

function formatContractName(name) {
  let newNameArr = name.toLowerCase().split(" ");
  let newNameArrFormatted = newNameArr.map((word) => {
    if (word) {
      return word[0].toUpperCase() + word.slice(1);
    }
  });
  let newName = newNameArrFormatted.join(" ");
  newName += " Contract";
  return newName;
}

function sortWeekDaysCustom() {
  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const customOrder = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

  weekDays.sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));
  return weekDays;
}

export default function ContractArchiveDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contract = useSelector((state) => state.getSingleContract);

  const cityDetails = contract?.hotel?.city_details;

  const cityTimeZone = cityDetails?.timezone;
  const city = cityDetails?.name;

  //
  const { id } = useParams();
  useEffect(() => {
    dispatch(getSingleContract(id));
    return () => {
      dispatch(clearSingleContract());
    };
  }, []);

  return (
    <div className="contractDetails text-center">
      {contract.id !== Number(id) ? (
        <div className="w-full flex items-center justify-center h-screen">
          <Loading /> <TopBarProgress />
        </div>
      ) : (
        <div className="btns">
          <h1 className="mainHeading">Archived Contract Details</h1>
          <div className="flex1">{/* <button>Import</button> */}</div>
          <div className="contract border shadow-md rounded-md px-6  flex flex-col py-8 bg-gray-100 ">
            <h1 className="contractHeading border-b flex flex-col bg-white rounded-md shadow-md px-6 py-2 ">
              {" "}
              <span className="flex gap-2">
                {formatContractName(contract.name)} <span className="text-red-600">(archived)</span>
              </span>
              <span className="capitalize text-sm text-gray-500">( {reshapeText(contract.contract_type)} )</span>{" "}
            </h1>
            <div className="generalDetails w-full flex flex-col gap-2 px-4 mt-4  shadow-md  rounded-md bg-white py-4">
              <h2 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                General Details
              </h2>
              <div className="w-full flex justify-center items-center flex-col gap-4 rounded-md  ">
                <table className="w-1/2  max-sm:w-full border-2  border-black rounded-md my-2">
                  <tbody>
                    <tr className=" border-b border-black">
                      <th className="md:p-2  p-1 border-r border-black">Market</th>
                      <td className="md:p-2 p-1 text-left">{contract.market.name}</td>
                    </tr>
                    <tr className="border-b border-black">
                      <th className="md:p-2 p-1  border-r border-black">Valid From</th>
                      <td className="md:p-2 p-1 border-r border-black text-left">
                        {contract.booking_valid_from &&
                          cityTimeZone &&
                          dayjs(contract.booking_valid_from).tz(cityTimeZone).format("DD-MM-YYYY - HH:mm") +
                            ` ${city} Time`}
                      </td>
                    </tr>
                    <tr className="border-b border-black">
                      <th className="md:p-2  p-1 border-r border-black">Valid To</th>
                      <td className="md:p-2 p-1 border-r border-black text-left">
                        {contract.booking_valid_to &&
                          cityTimeZone &&
                          dayjs(contract.booking_valid_to).tz(cityTimeZone).format("DD-MM-YYYY - HH:mm") +
                            ` ${city} Time`}
                      </td>
                    </tr>
                    <tr className="border-b border-black">
                      <th className="md:p-2  p-1 border-r border-black">Hotel Name</th>
                      <td className="md:p-2 p-1  border-r border-black text-left">{contract.hotel.name}</td>{" "}
                    </tr>
                  </tbody>
                </table>
                {/* } */}
              </div>

              <div className="w-full flex justify-center items-center flex-col md:gap-4 rounded-md">
                <table className="w-1/2  max-sm:w-full border-2  border-black rounded-md my-2">
                  <tbody>
                    <tr className=" border-b border-black">
                      <th className="md:p-2  p-1 border-r border-black">Commission Type</th>
                      <td className="md:p-2 p-1 border-r  border-black capitalize">
                        {contract.commission_type || "not specified"}
                      </td>
                    </tr>
                    <tr className="border-b border-black">
                      <th className=" p-1 border-r border-black">Commission Price</th>
                      <td className=" p-1 border-r border-black capitalize">
                        {contract.commission_price || "not specified"}
                      </td>
                    </tr>
                    <tr className="border-b border-black">
                      <th className=" p-1 border-r border-black">Offer Type</th>
                      <td className=" p-1 border-r border-black capitalize">{contract.offer_type.name}</td>
                    </tr>
                    <tr className="border-b border-black">
                      <th className=" p-1 border-r border-black">Meals Included</th>
                      <td className="  p-1 border-r border-black uppercase">{contract.meal_included}</td>
                    </tr>
                    <tr className="border-b border-black">
                      <th className=" p-1 border-r border-black">Currency Type</th>
                      <td className=" p-1 border-r border-black uppercase">{contract.currency_type}</td>
                    </tr>
                  </tbody>
                </table>
                {/* )} */}
              </div>
            </div>
            <div className=" flex w-full justify-center my-2"></div>
            <div className="contractValidities w-full flex flex-col gap-4 px-4 py-4  shadow-md  rounded-md bg-white">
              {/* <div className="flex justify-center items-center my-4">
                <div className="w-[200px]  h-[2px] bg-gray-400 shadow-md" />
              </div> */}
              <h2 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                Contract Validities
              </h2>
              {contract.validities.map((validity, index) => {
                return (
                  <div key={index} className=" flex flex-col w-full justify-center items-center">
                    <span
                      // target="_blank "
                      // href={`/dashboard/room-details/${validity.id}`}
                      className="capitalize text-left text-[#002244]"
                    >
                      {validity?.room_name}
                    </span>
                    {validity?.date_range?.map((range, index) => {
                      return (
                        <div className="validity-section   w-1/2 flex flex-col shadow-md border my-1 bg-gray-50 px-2 ">
                          <div className="flex gap-4 max-md:text-sm  p-2 text-center justify-center w-full">
                            <div>
                              <span>Valid From:</span> {range.from_date}
                            </div>
                            <div>
                              <span>Valid To:</span> {range.to_date}
                            </div>
                          </div>
                          <ContractTable
                            heading1="Adults"
                            heading2="Price"
                            heading3="Children"
                            heading4="Price"
                            arr={range.validity_room_types}
                            arr2={range.validity_children_price}
                            att1="type"
                            att2="price"
                            att3="start_age"
                            att4="end_age"
                            att5="ordering_child"
                          />
                          {range?.validity_room_packages.length > 0 && (
                            <div className="contractValidities flex flex-col gap-2 bg-white">
                              {range?.validity_room_packages.map((val, index) => (
                                <table
                                  key={index}
                                  style={{ border: "1px solid black", borderCollapse: "collapse", width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ border: "1px solid black" }}>Adults</th>
                                      <th style={{ border: "1px solid black" }}>Children</th>
                                      <th style={{ border: "1px solid black" }}>Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td style={{ border: "1px solid black" }}>{val?.package?.max_adults}</td>
                                      {val?.package?.children_room_package_occupancy?.length > 0 ? (
                                        <td style={{ padding: "0" }}>
                                          <div className="flex w-full ">
                                            <p className="flex-1 border-b border-r border-black">Child Order</p>
                                            <p className="flex-1 border-b border-black">Max Age</p>
                                          </div>

                                          {val?.package?.children_room_package_occupancy?.map((pac, idx) =>
                                            pac ? (
                                              <div key={idx} className=" flex w-full">
                                                <p className="flex-1 border-r border-black">{pac?.ordering_child}</p>
                                                <p className="flex-1 ">{pac?.max_age}</p>
                                              </div>
                                            ) : (
                                              "No Children"
                                            ),
                                          )}
                                        </td>
                                      ) : (
                                        <span className="text-gray-500">No Children</span>
                                      )}
                                      <td className="" style={{ border: "1px solid black" }}>
                                        {val.price}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              ))}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className=" flex w-full justify-center my-2" />
            {/* {contract.meal_supplements.length > 0 && (
              <div className=""> */}
            {contract.meal_supplements.length > 0 && (
              <div className="mealSup  w-full flex flex-col gap-2 px-4 py-4  shadow-md  rounded-md bg-white ">
                <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                  Meal Supplements
                </h1>
                <div className="flex justify-center items-center my-4 ">
                  <table className="w-1/2  max-sm:w-full border-2 border-black">
                    <thead>
                      <tr className="border-b-2 border-black">
                        <th className="p-1 font-bold border-r border-black">Meal</th>
                        <th className="p-1 font-bold border-r border-black">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contract.meal_supplements.map((sup, index) => (
                        <tr key={index} className="border-b border-black">
                          <td className="p-1 border-r border-black capitalize">
                            {sup.name === "soft_all" ? "Soft All " : sup.name}
                          </td>
                          <td className="p-1 border-r border-black">{sup.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className=" flex w-full justify-center my-2" />
            {contract.period_supplements.length > 0 && (
              <div className="periodSup w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                  Period Supplements
                </h1>
                <div className="flex justify-center items-center my-4">
                  <table className="w-1/2  max-sm:w-full border-2 border-black">
                    <thead>
                      <tr className="border-b-2 border-black">
                        <th className="p-1 border-black  border-r">Name</th>
                        <th className="p-1 border-black  border-r">Price</th>
                        <th className="p-1 border-black  border-r">Start Date</th>
                        <th className="p-1  border-black border-r">End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contract.period_supplements.map((sup, index) => (
                        <tr key={index} className="border-b">
                          <td className="p-1 border-black border-r capitalize">{sup.name}</td>
                          <td className="p-1 border-black border-r">
                            {sup.price} {contract.currency_type}
                          </td>
                          <td className="p-1 border-black border-r">{sup.start_date}</td>
                          <td className="p-1 border-black border-r">{sup.end_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className=" flex w-full justify-center my-2" />

            {contract.period_weekend_supplements.length > 0 && (
              <div className=" w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                  Period Weekend Supplements
                </h1>
                <div className="flex flex-col gap-2">
                  {contract.period_weekend_supplements.map((period, periodIndex) => (
                    <div key={periodIndex} className="flex flex-col gap-4 mt-4 justify-center items-center">
                      <div className="flex gap-4  p-1 text-center  w-[max-content] shadow-md rounded-lg border-gray-500">
                        <div className="flex gap-2  ">
                          <span>From:</span>
                          <span>{period.start_date}</span>
                        </div>
                        -
                        <div className="flex gap-2 ">
                          <span>To:</span>
                          <span>{period.end_date}</span>
                        </div>
                      </div>
                      <div className=" w-full flex flex-col justify-center items-center pb-4">
                        <table className="w-1/2  max-sm:w-full border-2 border-black">
                          <thead>
                            <tr className="border-b-2 border-black">
                              <th className="p-1 border-r border-black">Day</th>
                              <th className="p-1 border-r border-black">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {period.day_prices.map((sup, index) => (
                              <tr key={index} className="border-b border-black">
                                <td className="p-1 border-r border-black capitalize text-center">{sup.day}</td>
                                <td className="p-1 border-r border-black text-center">
                                  {sup.price ? `${sup.price} ` : "-"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              //   )}
              // </div>
            )}
            <div className=" flex w-full justify-center my-2" />
            {
              <div className=" flex flex-col gap-2">
                {
                  <div className="reservationPolicy w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                    <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                      Reservation Before Arrival
                    </h1>
                    <p className="text-left">
                      {contract.reservation_policy === null || contract.reservation_policy === 0
                        ? 0
                        : contract.reservation_policy}{" "}
                      Day/s
                    </p>
                  </div>
                }
                {contract.cancellation_policy && (
                  <div className="cancellationPolicy w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                    <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                      Cancellation Policy
                    </h1>
                    <div className="text-left">{contract.cancellation_policy}</div>
                  </div>
                )}
                {contract.weekend_supplements.length > 0 && (
                  <div className=" w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                    <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                      Weekend Supplements
                    </h1>
                    <div className="w-full flex flex-col justify-center items-center pb-4">
                      <table className="w-1/2  max-sm:w-full border-2 border-black">
                        <thead>
                          <tr className="border-b-2 border-black">
                            <th className="p-1 border-r border-black">Day</th>
                            <th className="p-1 border-r border-black">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract.weekend_supplements.map((suplement, index) => {
                            // const sortedWeekDays = sortWeekDaysCustom();
                            return (
                              <tr key={index} className="border-b border-black text-center">
                                <td className="p-1 border-r border-black capitalize">
                                  {/* {sortedWeekDays[index % sortedWeekDays.length]} */}
                                  {suplement.name ? `${suplement.name} ` : "-"}
                                </td>
                                <td className="p-1 border-r border-black">
                                  {suplement.price ? `${suplement.price} ` : "-"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {/* <div className=" flex w-full justify-center my-2">
              <div className="w-1/3 h-[2px] bg-black flex justify-center" />
            </div> */}
                {contract.one_day_supplements.length > 0 && (
                  <div className="oneDaySupplements w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                    <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                      One Day Supplements
                    </h1>
                    <div className="w-full flex flex-col justify-center items-center">
                      <table className="w-1/2  max-sm:w-full border-2 border-black pb-4">
                        <thead>
                          <tr className="border-b-2 border-black ">
                            <th className="p-1 border-r border-black">Name</th>
                            <th className="p-1 border-r border-black">Price</th>
                            <th className="p-1 border-r border-black">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract.one_day_supplements.map((suplement, index) => (
                            <tr key={index} className="border-b text-center">
                              <td className="p-1 border-r  border-black capitalize">{suplement.name}</td>
                              <td className="p-1 border-r  border-black ">{suplement.price}</td>
                              <td className="p-1 border-r  border-black ">{suplement.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {/* {contract.price_policy_maximum_days && contract.price_policy_minimum_days && (
              <div className=" flex w-full justify-center my-2">
                <div className="w-1/3 h-[2px] bg-black flex justify-center" />
              </div>
            )} */}
                {contract.price_policy_maximum_days && contract.price_policy_minimum_days && (
                  <div className="notes w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                    <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                      Min & Max Days
                    </h1>
                    <div className="flex flex-col justify-center items-center">
                      <table className="w-1/2  max-sm:w-full border-2 border-black pb-4">
                        <thead>
                          <tr className="border-b-2 border-black text-center">
                            <th className="p-1 border-r border-black">Min</th>
                            <th className="p-1 border-r border-black">Max</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="border-b border-black text-center">
                            <td className="p-1  border-black border-r  border-2 capitalize">
                              {contract.price_policy_minimum_days}
                            </td>
                            <td className="p-1 border-r  border-black capitalize">
                              {" "}
                              {contract.price_policy_maximum_days}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <span className="font-bold">Min:</span> {contract.price_policy_minimum_days} */}
                    </div>
                    {/* <div>
                  <span className="font-bold">Max:</span>
                 
                </div> */}
                  </div>
                )}
                {/* {contract.notes && (
              <div className=" flex w-full justify-center my-2">
                <div className="w-1/3 h-[2px] bg-black flex justify-center" />
              </div>
            )} */}
                {contract.notes && (
                  <div className="notes w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                    <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                      Notes
                    </h1>
                    <p className="text-left">{contract.notes}</p>
                  </div>
                )}
                {contract.price_document && (
                  <div className="pDoc w-full flex flex-col gap-2 px-4 py-4   shadow-md  rounded-md bg-white">
                    <h1 className="bg-gray-50 px-2 py-1.5 shadow-md rounded-md max-w-[fit-content] font-semibold text-left">
                      Contract Price Document
                    </h1>
                    <a href={contract.price_document} className="text-blue-500 text-underline text-left">
                      Click Here
                    </a>
                  </div>
                )}
              </div>
            }
            {/* {contract.price_document && (
              <div className=" flex w-full justify-center my-2">
                <div className="w-1/3 h-[2px] bg-black flex justify-center" />
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
}
