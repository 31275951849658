import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiFillDelete, AiOutlineLoading } from "react-icons/ai";
import { toast } from "react-toastify";
import { Button, Modal } from "flowbite-react";
import mutateDataQuery from "../react_query/mutateDataQuery";

const DeleteModal = ({ itemId, itemName, deleteUrl, entityName }) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  const { isPending, mutate: deleteItem } = useMutation({
    mutationFn: () => mutateDataQuery(deleteUrl, "delete"),
    mutationKey: [`delete-${itemName}`, itemId],
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success(`${entityName} ${itemName} Deleted Successfully`);
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || `Failed to delete ${entityName}`);
    },
    throwOnError: (error) => {
      toast.error(error.message || `Failed to delete ${entityName}`);
    },
  });

  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="cursor-pointer"
      >
        <AiFillDelete className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-700 hover:text-red-900" />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showModal}
        position="center"
        onClose={() => setShowModal(false)}
      >
        <Modal.Body>
          <h3 className="text-xl font-medium text-gray-900 mb-4 text-center mt-4">
            Do you want to delete {entityName} {itemName}?
          </h3>
          <div className="flex justify-around items-center">
            <Button
              isProcessing={isPending}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              disabled={isPending}
              onClick={() => {
                deleteItem(
                  {},
                  {
                    onSuccess: () => {
                      setShowModal(false);
                      void queryClient.refetchQueries({
                        queryKey: [`${entityName.toLowerCase()}s-list`],
                        type: "active",
                        exact: true,
                      });
                    },
                  },
                );
              }}
              color="failure"
            >
              Delete
            </Button>
            <Button disabled={isPending} onClick={() => setShowModal(false)} color="gray">
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
