import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function ClientNameFormEn({ setClientNameEn, numOfPerson, setSaveBtn }) {
  // const { cardData } = useSelector((state) => state.cardReducer);
  //

  // const [loopLength, setLoopLength] = useState(numOfPerson);
  const [loopData, setLoopData] = useState(Array(numOfPerson).fill(""));
  const [t] = useTranslation();
  // const handleLoopLengthChange = (event) => {
  //   const newLength = parseInt(event.target.value, 10);
  //   setLoopLength(newLength);
  //   setLoopData(Array(newLength).fill(""));
  // };

  const handleDataChange = (index, value) => {
    setLoopData((prevData) => {
      const newData = [...prevData];
      newData[index] = value;
      return newData;
    });
  };

  let clientNameArray = [];
  clientNameArray.push(loopData);

  clientNameArray.map((item) => setClientNameEn(item));

  return (
    <div>
      <ul>
        {loopData.map((item, index) => (
          <li key={index} className="w-full">
            <div className="flex flex-col items-start  w-full text-sm ">
              <label className="font-body">
                {t("clientName")}
                <span className="text-yellow-600">*</span>
              </label>
              <input
                //  placeholder="Enter your name"
                className="w-full h-6 border-gray-200 border-opacity-100 border-b-2  "
                type="text"
                dir="ltr"
                value={item}
                onChange={(event) => {
                  handleDataChange(index, event.target.value);
                  setSaveBtn(false);
                }}
                required
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ClientNameFormEn;
