import React, { useEffect, useRef, useState } from "react";
import "react-chat-elements/dist/main.css";
import "./Chat.scss";
import { MessageList } from "react-chat-elements";
import { IoMdSend } from "react-icons/io";
import { getAllChats, sendChat } from "../../../redux/actions/bookingRequests";
import { useDispatch, useSelector } from "react-redux";

export default function Chat({ id }) {
  const dispatch = useDispatch();
  const chats = useSelector((state) => state.bookingRequestsChats);
  const [currentMessage, setCurrentMessage] = useState("");
  const chatContainerRef = useRef(null); // Create a ref for the chat container
  useEffect(() => {
    dispatch(getAllChats(id));
    scrollToBottom(); // Scroll to the bottom when component mounts
  }, []);
  const messages = chats.map((chat) => {
    const mainSender = chats[0].sender;
    return {
      position: chat.sender === mainSender ? "left" : "right",
      type: "text",
      text: chat.content,
      date: chat.timestamp,
      customClass: "ddd",
    };
  });

  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom whenever new messages are received
  }, [chats]);

  function handleSend() {
    const data = {
      sender: 3,
      content: currentMessage,
      booking_request: id,
    };
    dispatch(sendChat(id, data, () => dispatch(getAllChats(id))));
    setCurrentMessage("");
  }

  function scrollToBottom() {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }

  return (
    <div className={`chatContainer rounded-lg w-[80%] mx-auto`}>
      <div className={`chatHeader flex items-center justify-center px-5  bg-blue-900 rounded-lg`}>
        <div className="text-lg text-center text-white">Messaging</div>
      </div>

      <div className="chatArea">
        <div className="overflow-y-scroll" ref={chatContainerRef}>
          <MessageList className="message-list" lockable={true} sx={{ width: "200px" }} dataSource={messages} />
        </div>
        <div className="send flex gap-3 items-center mx-auto w-[80%] my-2">
          <textarea
            className="w-[80%] border border-black rounded-lg"
            onChange={(e) => setCurrentMessage(e.target.value)}
            value={currentMessage}
          />
          <IoMdSend size={25} className="hover:text-green-400" onClick={handleSend} />
        </div>
      </div>
    </div>
  );
}
