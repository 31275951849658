import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  clearSingleMarkup,
  getMarkupPriceTypes,
  getSingleMarkup,
  getUserGroups,
  updateMarkup,
} from "../../../redux/actions/markupControl";
import { useDispatch, useSelector } from "react-redux";
import { FaHotel, FaWpforms } from "react-icons/fa6";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import { getHotelRooms } from "../../../redux/actions/getRooms";
import { requestMarketTypes } from "../../../redux/actions/addContract";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import moment from "moment";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import { RxUpdate } from "react-icons/rx";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import { NotificationContainer, NotificationManager } from "react-notifications";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";

export default function EditMarkup() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [arabicName, setArabicName] = useState();
  const [arabicError, setArabicError] = useState(false);
  const markup = useSelector((state) => state.singleMarkup);

  const { marketTypes } = useSelector((state) => state.addContract);
  const marketPriceTypes = useSelector((state) => state.markupPriceTypes);
  const hotels = useSelector((state) => state.hotelNames);
  const hotelRooms = useSelector((state) => state.hotelRooms);
  const userGroups = useSelector((state) => state.userGroups);

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [userChoice, setUserChoice] = useState(null);
  const [chosenMarketPriceTypes, setChosenMarketPriceTypes] = useState("");
  const [chosenMarket, setChosenMarket] = useState("");
  const [chosenRooms, setChosenRooms] = useState([]);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [chosenHotel, setChosenHotel] = useState([]);
  const [marketFlag, setMarketFlag] = useState(false);
  const [markupFlag, setMarkupFlag] = useState(false);
  const [hotelFlag, setHotelFlag] = useState(false);
  const [userFlag, setUserFlag] = useState(false);
  const [load, setLoad] = useState(false);
  const [roomFlag, setRoomFlag] = useState(false);
  const [roomsHotel, setroomsHotel] = useState(null);
  const [citiesArr, setCityNameVal] = useState(null);
  const [chosenCity, setChosenCity] = useState([]);
  const [dataHotel, setDataHotel] = useState([]);
  const [selectedHotelsIds, setSelectedHotelsIds] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState([]);
  const [combinedHotels, setCombinedHotels] = useState([]);
  const [chosenUserGroup, setChosenUserGroup] = useState("");
  const [cityIDS, setCitiesIds] = useState("");
  const [cityRoom, setCityRoom] = useState([]);
  const [citiesChosenArr, setCitiesChosenArr] = useState([]);
  const [hotelData, sethotelData] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const previousDefaultCity = useRef([]);
  const previousChosenRoomCity = useRef(null);
  const isFirstRun = useRef(true); // Add a ref to track the first run

  // const hotelsArrID = dataHotel ? dataHotel?.map((hotel) => ({ label: hotel.name, value: hotel.id })) : [];
  //
  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };

  const cityRoomIds = cityRoom.length > 0 && cityRoom?.map((i) => i?.city);

  const defaultCity =
    (markup &&
      markup.cities?.map((city) => {
        return { label: city.name, value: city.id };
      })) ||
    [];
  const allCities = chosenCity.length > 0 && chosenCity?.map((i) => i.label === "All")[0];

  useEffect(() => {
    const newCitiesChosenArr =
      chosenCity && allCities ? citiesArr?.map((i) => i.id) : chosenCity ? chosenCity?.map((i) => i.value) : [];

    setCitiesChosenArr(newCitiesChosenArr);
  }, [chosenCity, citiesArr]);

  const citiesArrIDs = citiesArr && citiesArr?.map((i) => i.id);
  const cityDataSelect = useMemo(() => citiesArr?.map((city) => ({ label: city.name, value: city.id })), [citiesArr]);
  const cityRoomVal = hotels && hotels?.filter((i) => i?.name === chosenHotel?.label && i?.city);
  const cityRoomValue = cityRoomVal?.map((i) => i.city)[0];

  const defCityRoom = markup?.rooms?.length > 0 ? citiesArr?.filter((i) => i?.id === cityRoomValue) : null;

  // Assign chosenRoomCity based on defCityRoom

  const hotelsArrID = dataHotel ? dataHotel?.map((hotel) => ({ label: hotel.name, value: hotel.id })) : [];

  // const hotelsArrID = hotels.map((hotelObj) => {
  //   return { label: hotelObj.name, value: hotelObj.id };
  // });
  useEffect(() => {}, [markup && markup.markup_price_markup]);
  useEffect(() => {
    if (chosenCity.length > 0) {
      setCitiesIds(chosenCity?.map((i) => i.value));
    } else {
      setCitiesIds(defaultCity && defaultCity?.map((i) => i.value));
    }
  }, [chosenCity]);

  const {
    data: cities,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["cities", citiesChosenArr],
    queryFn: () => fetchDataQuery(`/hotels/api/v1/hotel-filter/?cities=[${citiesChosenArr}]`),
    // enabled: !!citiesChosenArr,
  });

  useEffect(() => {
    if (isSuccess) {
      setDataHotel(cities);
    }
  }, [cities, citiesChosenArr, chosenCity]);

  const { data: cityName, isSuccess: sucess } = useQuery({
    queryKey: ["cityName", citiesArr],
    queryFn: () => fetchDataQuery("/search/api/v1/get-cities/"),
  });
  useEffect(() => {
    if (sucess) {
      setCityNameVal(cityName);
    }
  }, [sucess]);

  useRemoveScroll();
  const choiceOptions = [
    { label: "All", value: "All" },
    { label: "Hotels", value: "Hotels" },
    { label: "Rooms", value: "Rooms" },
  ];
  const marketTypesArr = marketTypes.map((type) => {
    return { label: type.name, value: type.id };
  });

  const marketPriceTypesArr = marketPriceTypes.map((elem) => {
    return { label: elem, value: elem };
  });

  const hotelRoomsArr = hotelRooms.map((room) => {
    return { label: room.room_name, value: room.id };
  });
  const userGroupsArr = userGroups.map((userGroup) => {
    return { label: userGroup.name, value: userGroup.id };
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  const chosenRoomCity =
    defCityRoom && defCityRoom.length > 0 ? { label: defCityRoom[0]?.name, value: defCityRoom[0]?.id } : null;

  // useEffect(() => {

  //   if (
  //     markup?.rooms?.length > 0 &&
  //     chosenRoomCity?.value &&
  //     !isEqual(chosenRoomCity, previousChosenRoomCity.current)
  //   ) {
  //     setCitiesChosenArr((prev) => [...new Set([...prev, chosenRoomCity.value])]);
  //     previousChosenRoomCity.current = chosenRoomCity;
  //   }
  // }, [markup?.rooms, chosenRoomCity]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    // const hasRooms = markup?.rooms?.length > 0;
    const hasChosenCity = defaultCity !== undefined;

    const hasHotels = markup && markup?.hotels?.length > 0;

    if (hasChosenCity && hasHotels) {
      setCitiesChosenArr((prev) => {
        const updatedArr = [...new Set([defaultCity.map((i) => i.value)][0])];

        return updatedArr;
      });
      previousDefaultCity.current = defaultCity;
    }
  }, [markup?.hotels]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    const hasRooms = markup?.rooms?.length > 0;
    const hasChosenCity = chosenRoomCity?.value !== undefined;

    if (hasRooms && hasChosenCity) {
      setCitiesChosenArr((prev) => {
        const updatedArr = [...new Set([...prev, chosenRoomCity.value])];
        return updatedArr;
      });
      previousChosenRoomCity.current = chosenRoomCity;
    } else {
    }
  }, [markup?.rooms, chosenRoomCity?.label]);

  //
  function handleHotelChange(e) {
    setChosenHotel(e);
    setRoomFlag(true);
    dispatch(
      getHotelRooms(e.value, (result) => {
        if (result.status === 200) {
          setroomsHotel(result.data);
          setRoomFlag(false);
        }
      }),
    );
    setChosenRooms([]);
  }

  useEffect(() => {
    dispatch(clearSingleMarkup());
    dispatch(
      getSingleMarkup(id, (result) => {
        if (result.status === 200) {
          if (result.data.all) {
            setUserChoice("All");
          } else if (result.data.rooms) {
            setUserChoice("Rooms");
          } else if (result.data.hotels) {
            setUserChoice("Hotels");
          }
          sethotelData(result?.data?.markup_price_markup);
          setLoad(true);
        }
      }),
    );
    dispatch(
      requestMarketTypes((result) => {
        setMarketFlag(true);
      }),
    );
    dispatch(
      getMarkupPriceTypes((result) => {
        setMarkupFlag(true);
      }),
    );
    dispatch(
      requestHotelNames((result) => {
        if (result) {
          setCityRoom(result);
          setHotelFlag(true);
        } else {
        }
      }),
    );
    dispatch(
      getUserGroups((result) => {
        setUserFlag(true);
      }),
    );
  }, []);

  useEffect(() => {
    if (load && formRef.current) {
      const form = formRef.current;
      setDateRange({
        startDate: moment(markup.from_date),
        endDate: moment(markup.to_date),
      });
      if (markup.all) {
        setUserChoice("All");
      } else if (markup.rooms && markup.rooms.length > 0) {
        setUserChoice("Rooms");
        const hotelId = markup.rooms[0].hotel_name.id;
        const hotelname = markup.rooms[0].hotel_name.name;
        dispatch(getHotelRooms(hotelId));
        setChosenHotel({ label: hotelname, value: hotelId });
        setChosenRooms(
          markup.rooms.map((obj) => {
            return { label: obj.room_name, value: obj.id };
          }),
        );
      } else if (markup.hotels && markup.hotels.length > 0) {
        setUserChoice("Hotels");
        setCombinedHotels(
          markup.hotels.map((obj) => {
            return { label: obj.name, value: obj.id };
          }),
        );
      }
      form ? (form.name.value = markup.name) : void 0;
      form ? (form.name_ar.value = markup.name_ar) : void 0;
      form && markup.all === true
        ? (form.price.value = Number(markup.price))
        : markup.rooms && markup.rooms.length > 0
          ? (form.price.value = Number(markup.price))
          : void 0;
      form.minNights.value = markup.min_nights;
      setChosenMarketPriceTypes(markup.type_price);
      markup.market ? setChosenMarket({ label: markup.market.name, value: markup.market.id }) : void 0;
      markup.user_category
        ? setChosenUserGroup({ label: markup.user_category.name, value: markup.user_category.id })
        : void 0;
    }
  }, [load, markup, setUserChoice, marketFlag, markupFlag, hotelFlag, userFlag]);

  // Synchronize hotelData with selectedHotels
  useEffect(() => {
    const initialHotelData = combinedHotels.map((hotel) => ({
      hotel: hotel.value,
      price: "", // Initialize price as an empty string
    }));
    sethotelData(initialHotelData);
  }, [chosenHotels]);

  // const handleFixedPrice = (index) => {
  //   setSelectedCheckbox((prevIndex) => (prevIndex === index ? null : index));
  //   if (selectedCheckbox !== index) {
  //     const priceHotelData = [...hotelData];
  //     const selectedFixedPrice = priceHotelData[index]?.price || "";
  //     sethotelData((prevData) => {
  //       const updatedData = prevData.map((item) => ({
  //         ...item,
  //         price: selectedFixedPrice,
  //       }));
  //       return updatedData;
  //     });
  //   }
  // };
  const handleFixedPrice = (hotelId) => {
    setSelectedCheckbox((prevId) => (prevId === hotelId ? null : hotelId));
    if (selectedCheckbox !== hotelId) {
      const selectedHotel = hotelData.find((hotel) => hotel.hotel === hotelId);
      const selectedFixedPrice = selectedHotel ? selectedHotel.price : "";
      sethotelData((prevData) =>
        prevData.map((item) => ({
          ...item,
          price: selectedFixedPrice,
        })),
      );
    }
  };
  const handleHotelData = (id, price) => {
    sethotelData((prevData) => prevData.map((item) => (item.hotel === id ? { ...item, price } : item)));
  };

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "All")) {
      const allHotels = hotelsArrID.map((hotel) => ({
        label: hotel.label,
        value: hotel.value,
      }));
      setCombinedHotels(allHotels);

      const allHotelData = hotelsArrID.map((hotel) => ({
        price: "",
        hotel: hotel.value,
      }));
      sethotelData(allHotelData);
    } else {
      const selectedValues = selectedOptions?.map((option) => option.value);

      // Filter hotelData to include only selected options
      const updatedHotelData = hotelData.filter((hotel) => selectedValues.includes(hotel.hotel));

      // Add new hotels that were not previously in the hotelData
      const newHotelData = selectedOptions
        .filter((option) => !hotelData.some((hotel) => hotel.hotel === option.value))
        .map((option) => ({ hotel: option.value, price: "" }));

      // Merge updated and new hotel data
      const finalHotelData = [...updatedHotelData, ...newHotelData];
      sethotelData(finalHotelData);
      // Create combined hotels with prices
      const combinedHotelsWithPrices = selectedOptions.map((option) => {
        const hotel = finalHotelData.find((data) => data?.hotel === option?.value);

        return {
          label: option.label,
          value: option.value,
          price: hotel ? hotel.price : null,
        };
      });

      setCombinedHotels(combinedHotelsWithPrices && combinedHotelsWithPrices);
    }
  };

  const handleInputChange = (e) => {
    const arabicRegex = /^[\u0600-\u06FF\s]+$/; // Regular expression for Arabic characters and spaces
    const userInput = e.target.value;

    if (arabicRegex.test(userInput)) {
      setArabicError(false);
      setArabicName(userInput);
    } else {
      setArabicError(true);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();

    if (arabicError) {
      alert("You Should enter arabic name");
    } else {
      const data = {
        name: e.target.name.value,
        name_ar: arabicName,
        type_price: chosenMarketPriceTypes,
        price:
          userChoice === "Hotels" && chosenHotels.length >= 0 && combinedHotels.length >= 0
            ? null
            : e.target.price.value,
        min_nights: e.target.minNights.value,
        from_date: dateRange.startDate.format("YYYY-MM-DD"),
        to_date: dateRange.endDate.format("YYYY-MM-DD"),
        market: chosenMarket.value,
        user_category: chosenUserGroup.value,
      };
      switch (userChoice) {
        case "All":
          data.all = true;
          data.rooms = [];
          data.hotels = [];
          data.markup_hotel_prices = [];
          break;
        case "Hotels":
          data.hotels = combinedHotels?.map((obj) => obj.value);
          data.markup_hotel_prices = hotelData;
          data.all = false;
          data.rooms = [];
          break;
        case "Rooms":
          data.rooms =
            chosenRooms?.map((i) => i.label)[0] === "All"
              ? hotelRoomsArr.map((i) => i.value)
              : chosenRooms.map((obj) => obj.value);
          // data.rooms = chosenRooms.map((obj) => obj.value);
          data.all = false;
          data.hotels = [];
          data.markup_hotel_prices = [];
          break;
      }
      setSubmitFlag(true);

      dispatch(
        updateMarkup(id, data, (result) => {
          if (result.status === 200) {
            setSubmitFlag(false);
            navigate(`/dashboard/markup-control/markups-list`);
          } else {
            setSubmitFlag(false);
            NotificationManager.error("There Is An Empty Field");
          }
        }),
      );
    }
  }

  //////////permission/////////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["markup.change_markup"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {markup && permissionFlag && marketFlag && markupFlag && hotelFlag && userFlag && load && userChoice ? (
          permission.some((item) => item.value) && (
            <div className=" w-full ">
              <div className=" flex justify-center ">
                <h2 className="header-h2">
                  <FaWpforms className="w-5 h-5" />
                  Edit Markup
                </h2>
              </div>
              <form className="form-wrap   w-full" onSubmit={handleSubmit} ref={formRef}>
                <div className="w-full flex flex-col   gap-y-8 py-4">
                  <div className="input-par ">
                    <div className="input-chil">
                      <label className=" flex text-[#002244] font-semibold ">From-To</label>
                      <DateRangePicker
                        startDate={dateRange.startDate}
                        startDateId="startDate"
                        endDate={dateRange.endDate}
                        endDateId="endDate"
                        onDatesChange={handleDateChange}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                        minimumNights={0} // Allow same-day selection
                        daySize={30} // Allow same-day selection
                        required
                      />
                    </div>
                    <div className="input-chil">
                      <label className=" flex text-[#002244] font-semibold ">Name</label>
                      <input
                        placeholder="Name"
                        name="name"
                        type="text"
                        required
                        onChange={(e) => {}}
                        className=" w-full px-3 h-10 rounded-lg border  bg-white "
                      />
                    </div>
                  </div>
                  <div className="input-par">
                    <div className="input-chil">
                      <label className=" font-semibold  flex text-[#002244] ">Arabic Name</label>
                      <div className="w-full">
                        {" "}
                        <input
                          placeholder="Arabic Name"
                          name="name_ar"
                          type="text"
                          required
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          className=" w-full px-3 h-10 rounded-lg  border  bg-white "
                        />
                        {arabicError && (
                          <p className="w-full text-red-900 text-xs font-semibold">Please Enter Arabic Name</p>
                        )}
                      </div>
                    </div>
                    <div className="input-chil">
                      <label className=" flex text-[#002244] font-semibold ">Price Type</label>

                      <Select
                        options={marketPriceTypesArr}
                        className="w-full h-10   bg-white  capitalize"
                        required
                        name="marketPriceType"
                        value={[{ label: chosenMarketPriceTypes, value: chosenMarketPriceTypes }]}
                        onChange={(e) => {
                          setChosenMarketPriceTypes(e.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="input-par">
                    <div className="input-chil">
                      <label className="flex text-[#002244] font-semibold ">Market</label>
                      <Select
                        options={marketTypesArr}
                        className="w-full h-10 bg-white capitalize "
                        name="marketTypes"
                        value={chosenMarket ? chosenMarket : null}
                        onChange={(e) => setChosenMarket(e)}
                      />
                    </div>
                    <div className="input-chil">
                      <label className="flex text-[#002244] font-semibold">User category</label>
                      <Select
                        options={userGroupsArr}
                        className="w-full h-10 bg-white  capitalize"
                        name="marketTypes"
                        value={chosenUserGroup ? chosenUserGroup : null}
                        onChange={(e) => setChosenUserGroup(e)}
                      />
                    </div>
                  </div>
                  <div className="input-par">
                    <div className="input-chil">
                      <label className=" flex text-[#002244] font-semibold ">Min Nights</label>
                      <input
                        placeholder="min nights"
                        name="minNights"
                        type="number"
                        required
                        onChange={(e) => {}}
                        className=" w-full px-3 h-10  border rounded-lg bg-white "
                      />
                    </div>
                    <div className="input-chil">
                      <label className="flex text-[#002244] font-semibold">Choose Option</label>
                      <Select
                        options={choiceOptions}
                        className="w-full h-full bg-white capitalize"
                        onChange={(e) => {
                          setUserChoice(e.value);
                          setChosenHotels([]);
                          sethotelData([]);
                          setSelectedCheckbox(null);
                        }}
                        required
                        menuPlacement="top"
                        value={[{ label: userChoice, value: userChoice }]}
                      />
                    </div>
                  </div>

                  {/* for choosing hotels */}
                  {userChoice === "Hotels" && (
                    <div className="w-full">
                      <div className="w-full flex flex-col gap-2">
                        <label className="  font-semibold w-full flex  gap-x-2 text-[#002244]">City</label>
                        <Select
                          required
                          className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                          options={[{ label: "All", value: "All" }, ...cityDataSelect]}
                          placeholder="Select City"
                          isMulti
                          onChange={(e) => {
                            setHotelFlag(true);
                            setChosenCity(e);
                          }}
                          defaultValue={defaultCity}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="w-full flex  text-[#002244] font-semibold"> Hotels</label>
                        <Select
                          options={[{ label: "All", value: "All" }, ...hotelsArrID]}
                          className={`w-full h-full resize-y bg-white capitalize `}
                          isMulti
                          required
                          placeholder="Select Hotel"
                          defaultValue={combinedHotels}
                          onChange={(e) => {
                            handleSelectChange(e);
                          }}
                          menuPlacement="top"
                        />
                      </div>
                    </div>
                  )}

                  <div className="w-full flex flex-col gap-4 ">
                    {combinedHotels.length > 0 &&
                      userChoice === "Hotels" &&
                      combinedHotels.map((item, index) => {
                        const hotel = hotelData && hotelData?.find((data) => data.hotel === item.value);
                        const price = hotel ? hotel.price : "";

                        //
                        return (
                          <div
                            key={item.label + item.value}
                            className="input-par border shadow-md p-2  gap-4 rounded-md bg-gray-50"
                          >
                            <div className="input-chil">
                              <label className=" font-semibold w-full flex  text-[#002244]">Hotel Name</label>
                              <div className="w-full px-3 h-10 flex items-center justify-start  border rounded-md  bg-white">
                                {item.label}
                              </div>
                            </div>

                            <div className="input-chil">
                              <div className="w-full flex justify-between text-[#002244]">
                                <p>Hotel Price</p>
                                <div className="flex items-center gap-x-1">
                                  <button
                                    type="button"
                                    className={`px-1 text-sm  border rounded ${selectedCheckbox === index ? "bg-blue-500 text-white" : "bg-white text-blue-500 border-blue-500"}`}
                                    onClick={() => handleFixedPrice(item.value)}
                                  >
                                    {selectedCheckbox === item.value ? "Fixed Price " : " Fixed Price"}
                                  </button>
                                </div>
                              </div>
                              <input
                                placeholder="Enter Hotel Price"
                                name="hotel_price"
                                type="number"
                                required
                                value={price}
                                defaultValue={markup && markup?.markup_price_markup?.map((i, idx) => i)}
                                onChange={(e) => handleHotelData(item.value, e.target.value)}
                                className="w-full px-3 h-10 border rounded-lg bg-white"
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  {/* for choosing rooms */}
                  {userChoice === "Rooms" && (
                    <div className="w-full">
                      <div className="w-full">
                        <label className="  font-semibold w-full flex  gap-x-2 text-[#002244]">City</label>
                        <Select
                          required
                          className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                          options={[{ label: "All", value: "All" }, ...cityDataSelect]}
                          placeholder="Select City"
                          isMulti
                          onChange={(e) => {
                            setHotelFlag(true);
                            setChosenCity(e);
                          }}
                          // defaultValue={chosenRoomCity}
                          value={chosenCity.length > 0 ? chosenCity : chosenRoomCity}
                        />
                      </div>
                      <label className="flex  text-[#002244] font-semibold">Hotel</label>

                      <Select
                        options={hotelsArrID}
                        className="w-full h-full bg-white capitalize"
                        onChange={handleHotelChange}
                        menuPlacement="top"
                        value={chosenHotel}
                        placeholder="Select Hotel"
                      />
                      {roomFlag ? (
                        <div className="w-full flex justify-center items-center my-2 ">
                          {" "}
                          <ButtonLoading />
                        </div>
                      ) : !roomsHotel ? (
                        <>
                          <label className="flex justify-center items-center text-[#002244] font-semibold">
                            {" "}
                            Rooms
                          </label>
                          <Select
                            options={[{ label: "All", value: "All" }, ...hotelRoomsArr]}
                            className="w-full h-full bg-white capitalize"
                            isMulti
                            required
                            value={chosenRooms}
                            onChange={(e) => setChosenRooms(e)}
                            menuPlacement="top"
                            placeholder="Select Room"
                          />
                        </>
                      ) : roomsHotel.length > 0 ? (
                        <>
                          <label className="flex  text-[#002244] font-semibold"> Rooms</label>
                          <Select
                            options={[{ label: "All", value: "All" }, ...hotelRoomsArr]}
                            className="w-full h-full bg-white capitalize"
                            isMulti
                            required
                            value={chosenRooms}
                            onChange={(e) => setChosenRooms(e)}
                            menuPlacement="top"
                            placeholder="Select Room"
                          />
                        </>
                      ) : (
                        <div className="w-full flex items-center justify-center gap-x-2 text-gray-700 bg-gray-300 h-10 mt-3 ">
                          <p className="font-semibold  ">No Rooms For This Hotel , Please Select Another Hotel</p>
                          <FaHotel className="w-5 h-5 " />
                        </div>
                      )}

                      <label className=" font-semibold w-full justify-center items-center flex text-[#002244] ">
                        Price
                      </label>
                      <input
                        placeholder="price"
                        name="price"
                        type="number"
                        required
                        defaultValue={markup && markup.price}
                        onChange={(e) => {}}
                        className=" w-full px-3 h-10  border rounded-lg bg-white "
                      />
                    </div>
                  )}
                  {/* for choosing All */}
                  {userChoice === "All" && (
                    <>
                      <label className=" flex text-[#002244] font-semibold ">Price</label>
                      <input
                        placeholder="price"
                        name="price"
                        type="number"
                        required
                        onChange={(e) => {}}
                        className=" w-full px-3 h-10  border rounded-lg bg-white "
                      />
                    </>
                  )}
                  <div className="flex justify-center mt-6">
                    <button type="Submit" disabled={submitFlag} className="updateButton">
                      Update{" "}
                      {submitFlag ? (
                        <>
                          <ButtonLoading />
                          <TopBarProgress />
                        </>
                      ) : (
                        <RxUpdate />
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )
        ) : (
          <>
            {" "}
            <Loading /> <TopBarProgress />{" "}
          </>
        )}
      </div>
      <NotificationContainer />
    </div>
  );
}
