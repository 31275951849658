import { GET_CURRENCY_RATES, GET_CURRENCY_RATES_HISTORY, GET_CURENCY_RATE_HISTORY_DETAIL } from "../types/types";

export function CurrencyRatesReducer(state = {}, action) {
  switch (action.type) {
    case GET_CURRENCY_RATES:
      return action.payload;
    default:
      return state;
  }
}

const historyDefaultState = {
  count: 0,
  results: [],
};
export function CurrencyRatesHistoryReducer(state = historyDefaultState, action) {
  switch (action.type) {
    case GET_CURRENCY_RATES_HISTORY:
      return action.payload;
    default:
      return state;
  }
}

export function CurrencyRatesHistoryDetailsReducer(state = [{}], action) {
  switch (action.type) {
    case GET_CURENCY_RATE_HISTORY_DETAIL:
      return action.payload;
    default:
      return state;
  }
}
