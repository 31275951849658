import React from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { VscAdd } from "react-icons/vsc";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { useParams } from "react-router";
import usePermissions from "../../../../customHooks/usePermissions";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import NoPermission from "../../NoPermission/NoPermission";

function ClientBookingHistory() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "auth_users.view_customerbookinghistory",
    "auth_users.add_customerbookinghistory",
  ]);

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const isViewCustomerBookingHistoryAllowed =
    permissions?.find((permission) => permission.name === "auth_users.view_customerbookinghistory")?.value === true;

  const isAddCustomerBookingHistoryAllowed =
    permissions?.find((permission) => permission.name === "auth_users.add_customerbookinghistory")?.value === true;

  return (
    <>
      {isViewCustomerBookingHistoryAllowed || isAddCustomerBookingHistoryAllowed ? (
        <div className="border-gray-300 border rounded-lg bg-white p-4 w-[95%] mx-auto">
          <div className="w-full flex justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 mt-3">
            <p className="flex items-center gap-x-3">
              <RiKeyboardLine className="w-5 h-5" />
              Client Booking History
            </p>
          </div>
          <div className="flex gap-4 justify-around items-center mt-12">
            {isAddCustomerBookingHistoryAllowed && (
              <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                <Link
                  to={"./add-booking"}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#0C4A6E] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <VscAdd className="w-12 h-12 " />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Add Booking History</p>
              </div>
            )}

            {isViewCustomerBookingHistoryAllowed && (
              <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                <Link
                  to={"./booking-list"}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <AiOutlineUnorderedList className="w-12 h-12  " />
                  </div>
                </Link>
                <p className="text-lg font-semibold">Booking History List</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <NoPermission />
      )}
    </>
  );
}

export default ClientBookingHistory;
