import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineManageHistory } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";

const SearchAnalyze = () => {
  return (
    <section className=" formPage-wrap ">
      <div className="form-wrap grid lg:grid-cols-2 grid-cols-1 justify-items-center">
        <Link to="">
          <div className="flex flex-col gap-2 items-center justify-center">
            <div className="border rounded-full p-8 bg-[#002244] hover:scale-110 hover:shadow-sm transition-transform duration-200 ease-in-out">
              <TbReportSearch className="w-8 h-8 text-white" />
            </div>

            <p className="text-lg font-semibold text-center">Search Reports</p>
          </div>
        </Link>

        <Link to="">
          <div className="flex flex-col gap-2 items-center justify-center">
            <div className="border rounded-full p-8 bg-[#002244] hover:scale-110 hover:shadow-sm transition-transform duration-200 ease-in-out">
              <MdOutlineManageHistory className="w-8 h-8 text-white" />
            </div>

            <p className="text-lg font-semibold text-center">Search History</p>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default SearchAnalyze;
