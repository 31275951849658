import axios from "axios";
import { base_URL } from "../../urls";

export const addAirportFormAction = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.post(`${base_URL}/airport-services/api/v1/limousine-services/`, data, {
        headers: {
          "Website-Resource": "true",
        },
      });

      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const getAllAirlineForm = (signal, callback, failureCallback) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`${base_URL}/airport-services/api/v1/airline-names-unauthorized/`, {
        signal,
      });
      callback(response);
    } catch (error) {
      if (failureCallback) {
        failureCallback(error);
      }
    }
  };
};

export const getHotelsByCityForm = (city, lang, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `${base_URL}/airport-services/api/v1/get_hotels_by_city_unauthorized/?city_id=${city}`,
        {
          headers: { "Accept-Language": lang },
          // { signal }
        },
      );
      callback(response);
    } catch (error) {}
  };
};

export const getAirportNameForm = (AirportName, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `${base_URL}/airport-services/api/v1/airport-unauthorized/?search=${AirportName}`,
      );
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
