import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { allPosMachineActionById } from "../../../../redux/actions/posMachineAction";
import { useParams } from "react-router";
import Loading from "../../Loading/Loading";

const DetailsPosMachine = () => {
  const [machinesData, setMachinesData] = useState();
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const params = useParams();

  const fetchData = () => {
    dispatch(
      allPosMachineActionById(token, params.id, (res) => {
        try {
          if (res.status === 200) {
            setMachinesData(res.data);
          }
        } catch (error) {}
      }),
    );
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <section className="formPage-wrap ">
      <div className="flex justify-center">
        <h2 className="header-h2">Pos Machines List</h2>
      </div>
      {machinesData ? (
        <div className="w-full flex flex-col   ">
          <div className=" ">
            <table className="max-md:hidden form-wrap font-semibold w-full">
              <thead>
                <tr className="rounded-md bg-gray-50">
                  <th className="p-3 border-r border-b font-bold md:text-base text-sm">ID</th>
                  <th className="p-3 border-r border-b font-bold md:text-base text-sm">BankName</th>
                  <th className="p-3 border-r border-b font-bold md:text-base text-sm">Location</th>
                  <th className="p-3 border-r border-b font-bold md:text-base text-sm">Mid Number</th>
                  <th className="p-3 border-r border-b font-bold md:text-base text-sm">Currency</th>
                  <th className="p-3 border-r border-b font-bold md:text-base text-sm">Created At</th>
                  <th className="p-3  border-b font-bold md:text-base text-sm">Updated At</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-3 md:text-base text-sm border-r">{machinesData.id}</td>
                  <td className="p-3 md:text-base text-sm border-r">{machinesData?.bank?.name}</td>
                  <td className="p-3 md:text-base text-sm border-r">{machinesData.location}</td>
                  <td className="p-3 md:text-base text-sm border-r">{machinesData.mid_number}</td>
                  <td className="p-3 md:text-base text-sm border-r">{machinesData.currency}</td>
                  <td className="p-3 md:text-base text-sm border-r">{machinesData?.created_at?.slice(0, 10)}</td>
                  <td className="p-3 md:text-base text-sm ">{machinesData?.updated_at?.slice(11, 19)}</td>
                </tr>
              </tbody>
            </table>
            <table className=" md:hidden form-wrap p-4 font-semibold">
              <tbody>
                <tr className="rounded-md">
                  <td className="p-3 border-r border-b font-bold md:text-base text-sm">ID</td>
                  <td className="p-3 md:text-base text-sm border-b">{machinesData.id}</td>
                </tr>
                <tr>
                  <td className="p-3 font-bold border-r border-b md:text-base text-sm">BankName</td>
                  <td className="p-3 md:text-base text-sm border-b">{machinesData?.bank?.name}</td>
                </tr>
                <tr>
                  <td className="p-3 font-bold border-r border-b md:text-base text-sm">Location</td>
                  <td className="p-3 md:text-base text-sm border-b">{machinesData.location}</td>
                </tr>
                <tr>
                  <td className="p-3 font-bold border-r border-b md:text-base text-sm">Mid Number</td>
                  <td className="p-3 md:text-base text-sm border-b">{machinesData.mid_number}</td>
                </tr>
                <tr>
                  <td className="p-3 font-bold border-r border-b md:text-base text-sm">Currency</td>
                  <td className="p-3 md:text-base text-sm border-b">{machinesData.currency}</td>
                </tr>
                <tr>
                  <td className="p-3 font-bold border-r border-b md:text-base text-sm">Created At</td>
                  <td className="p-3 md:text-base text-sm border-b">{machinesData?.created_at?.slice(0, 10)}</td>
                </tr>
                <tr>
                  <td className="p-3 font-bold border-r border-b md:text-base text-sm">Updated At</td>
                  <td className="p-3 md:text-base text-sm border-b">{machinesData?.updated_at?.slice(11, 19)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default DetailsPosMachine;
