import React from "react";
import { BsSafe } from "react-icons/bs";
import { Link } from "react-router-dom";

const AddCBoxRelated = () => {
  return (
    <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
      <Link
        to={"/dashboard/accounting-setting/cash-box"}
        // onClick={() => {
        //   navigate("./cash-box");
        // }}
        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
      >
        <div className="w-full flex justify-center items-center ">
          <BsSafe className="w-12 h-12   " />
        </div>
      </Link>
      <p className="text-lg font-semibold">Add Cash Box </p>
    </div>
  );
};

export default AddCBoxRelated;
