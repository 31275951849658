import React, { useEffect, useState } from "react";
import { removeSpaces } from "../AddMenus/AddMenus";
import useData from "../../../customHooks/useData";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { editData, getData } from "../../../redux/actions/sendDataAction";
import { menus } from "../AddMenus/menus";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router";

function MenuGroup({ icon, text, children, onCheck, parentCheck, existingMenu }) {
  const [hideChildren, setHideChildren] = useState(true);

  useEffect(() => {
    if (existingMenu) {
      if (existingMenu[removeSpaces(text)]) {
        setHideChildren(false);
      }
    }
  }, [existingMenu]);

  return (
    <div className="w-full flex flex-col  my-2 gap-4 items-center text-center">
      <div className=" w-full  flex gap-4 justify-between shadow-md rounded-md border px-3 py-1.5">
        <div className="flex gap-2">
          {icon}
          <div className="font-bold text-lg">{text}</div>
        </div>
        <input
          type="checkbox"
          name={removeSpaces(text)}
          onChange={(e) => {
            setHideChildren(!hideChildren);
            parentCheck(e, children);
          }}
          checked={existingMenu[removeSpaces(text)]}
        />
      </div>
      {!hideChildren && (
        <div className="  w-full border p-2 rounded-md">
          {children.map((child) => {
            return (
              <div className="flex justify-between items-center gap-2 shadow-md rounded-md px-3 py-1.5">
                <div>{child}</div>
                <input
                  type="checkbox"
                  name={removeSpaces(child)}
                  onChange={(e) => onCheck(e, child)}
                  checked={existingMenu[removeSpaces(child)]}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default function EditMenus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const groups = useData("permissions/api/v1/user-groups/");
  const [error, setError] = useState(false);
  const [menuId, setMenuId] = useState(null);
  const [menuJson, setMenuJson] = useState(null);
  const [chosenGroup, setChosenGroup] = useState(null);

  function onCheck(e) {
    const menuJsonCopy = { ...menuJson };
    if (e.target.checked) {
      menuJsonCopy[e.target.name] = true;
    } else {
      menuJsonCopy[e.target.name] = false;
    }
    setMenuJson(menuJsonCopy);
  }

  function parentCheck(e, children) {
    const menuJsonCopy = { ...menuJson };
    if (!menuJsonCopy[e.target.name]) {
      menuJsonCopy[e.target.name] = true;
    } else {
      menuJsonCopy[e.target.name] = false;
      const childrenModNames = children.map((child) => removeSpaces(child));
      childrenModNames.forEach((child) => {
        menuJsonCopy[child] = false;
      });
    }
    setMenuJson(menuJsonCopy);
  }

  function handleGroupChoice(e) {
    dispatch(
      getData(
        `/menu/api/menu/${e.value}/`,
        (resp) => {
          if (error) {
            setError(false);
          }
          setMenuJson(resp.data.json_menu.menu[0]);
          setMenuId(resp.data.id);
        },
        (err) => {
          setMenuJson(null);
          setError(true);
        },
      ),
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    const data = {
      json_menu: {
        menu: [menuJson],
      },
    };
    dispatch(
      editData(`/menu/api/v1/menu-groups/${menuId}/`, data, (resp) =>
        toast.success("Your menu was edited successfully"),
      ),
    ).then(() => {
      navigate("/");
      navigate("/dashboard");
    });
  }

  return (
    <div className="formPage-wrap min-h-screen">
      {!groups && <Loading />}
      {groups && (
        <div className="flex flex-col items-center  h-full w-full">
          <div className=" w-full  ">
            <div className="flex justify-center">
              <h2 className="header-h2">Edit Menu</h2>
            </div>
            <div className="w-full flex mt-6 max-w-2xl mx-auto bg-gray-50 rounded-lg border p-2 max-sm:flex-col  justify-center items-center gap-y-2">
              <label htmlFor="group" className="sm:flex-[1] w-full font-bold tracking-wide text-xl">
                Choose Group
              </label>
              <Select
                options={groups.map((group) => ({ label: group.name, value: group.id }))}
                className="w-full h-10 sm:flex-[3]  bg-white "
                onChange={(e) => handleGroupChoice(e)}
                required
              />
            </div>
            {menuJson && (
              <form onSubmit={handleSubmit} className="form-wrap">
                <>
                  <div className="grid  lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4  my-3">
                    {menus.map((menu, index) => {
                      return (
                        <MenuGroup
                          key={index}
                          icon={menu.icon}
                          text={menu.text}
                          children={menu.children}
                          onCheck={onCheck}
                          parentCheck={parentCheck}
                          existingMenu={menuJson}
                        />
                      );
                    })}
                  </div>
                  <div className=" flex justify-center">
                    <button type="submit" className="updateButton">
                      Edit
                    </button>
                  </div>
                </>
              </form>
            )}
          </div>
          {error && <div className="mx-auto w-max py-5 font-bold">There is no assigned Menu for this group yet !!</div>}
        </div>
      )}
    </div>
  );
}
