// base url
export const base_URL = "https://dev.testotelhero.com";
export const main_URL = "https://testotelhero.com";

//Accounting
export const getCurrencyTypes_URL = "contracts/api/currency-types/";
export const sendBankAccount_URL = "accounting/api/v1/banks/";

// hotels & Rooms
export const roomFeatures_URL = "/en/rooms/api/v1/get-features/";
export const addRoom_URL = "en/rooms/api/v1/add-room/";
export const editRoom_URL = "/en/rooms/api/v1/update-room-id/";

//////Transaction//////////
export const ADD_CASH_URL = "booking/api/v1/cash-payments-transaction/";
export const ADD_BANKTRANSFER_URL = "booking/api/v1/bank-transfer/";
export const ADD_POSVISA_URL = "accounting/api/v1/pos-machines/";
export const ADD_JUMBO4PAY_URL = "booking/api/v1/payments-transaction/";
