import React from "react";
import { BiCut } from "react-icons/bi";
import { PiHandshakeLight } from "react-icons/pi";
import { Link } from "react-router-dom";
const CreateLink = () => {
  return (
    <div className=" formPage-wrap">
      <div className="grid grid-cols-2  form-wrap">
        <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
          <Link className="flex items-center gap-x-1 w-[150px] h-[150px] rounded-full bg-gray-100 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
            <div className="w-full flex justify-center items-center ">
              <img src="/images/Capture1.PNG" className="w-full h-full rounded-full" />
            </div>
          </Link>
          <p className="text-lg font-semibold mt-2">Agent Link</p>
        </div>

        <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
          <Link
            to={"./customer-link"}
            className="flex items-center gap-x-1 w-[160px] h-[160px] rounded-full bg-gray-100 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
          >
            <div className="w-full flex justify-center items-center ">
              <img src="/images/Capture.PNG" className="w-full h-full rounded-full" />
            </div>
          </Link>
          <p className="text-lg font-semibold ">Customers Link </p>
        </div>
      </div>
    </div>
  );
};

export default CreateLink;
