import React, { useEffect, useRef, useState } from "react";
import { FaWpforms } from "react-icons/fa6";
import TopBarProgress from "react-topbar-progress-indicator";
import { useNavigate, useParams } from "react-router";
import { editData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import useData from "../../../customHooks/useData";
import Loading from "../Loading/Loading";
import { TfiWorld } from "react-icons/tfi";
import { GiNotebook } from "react-icons/gi";
import { RxUpdate } from "react-icons/rx";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { NotificationContainer, NotificationManager } from "react-notifications";
import lightToast from "light-toast";

export default function EditWhitelistIP() {
  const formRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const ip = useData(`/auth/api/v1/whitelisted-ips/${id}/`);
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    if (ip) {
      const form = formRef.current;
      form.ip.value = ip.ip_address;
      form.notes.value = ip.description;
      setIsActive(ip.active);
    }
  }, [ip]);

  function handelSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const data = {
      ip_address: e.target.ip.value,
      description: e.target.notes.value,
      active: isActive,
    };

    dispatch(
      editData(
        `/auth/api/v1/whitelisted-ips/${id}/`,
        data,
        () => {
          setIsDisabled(false);
          navigate("/dashboard/whitelist-ip/whitelist-ip-list");
          lightToast.success("Your IP was Edited Successfully");
        },
        (error) => {
          setIsDisabled(false);
          NotificationManager.error(error?.response?.data[0]?.message);
        },
      ),
    );
  }

  return (
    <div className="formPage-wrap ">
      {!ip && (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
      {ip && (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <div className=" w-full   ">
            <div className=" flex justify-center ">
              <h2 className="header-h2">
                <FaWpforms className="w-5 h-5" />
                Edit Whitelist IP
              </h2>
            </div>
            <form className="form-wrap  w-full" encType="multipart/form-data" onSubmit={handelSubmit} ref={formRef}>
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <label className=" flex text-gray-600 ">IP Address</label>
                <div className="w-full relative">
                  <input
                    placeholder="IP Address"
                    name="ip"
                    type="text"
                    required
                    className=" w-full pl-9 h-10  border rounded-lg bg-white "
                  />
                  <div className=" absolute top-3 left-2">
                    <TfiWorld className="w-5 h-5 text-[#002244]" />
                  </div>
                </div>
                <label className=" flex text-gray-600 ">Notes</label>
                <div className="w-full relative">
                  <textarea className="w-full  h-10  border rounded-lg bg-white pl-9 py-1" name="notes" />
                  <div className=" absolute top-3 left-2">
                    <GiNotebook className="w-5 h-5 text-[#002244]" />
                  </div>
                </div>
                <div className="flex gap-2">
                  <input type="checkbox" checked={isActive} onChange={() => setIsActive(!isActive)} />
                  <label className=" flex text-gray-600 ">Active</label>
                </div>

                <button disabled={isDisabled} type="Submit" className="updateButton">
                  Edit
                  {isDisabled ? (
                    <>
                      <TopBarProgress />
                      <ButtonLoading />
                    </>
                  ) : (
                    <RxUpdate className="w-5 h-5" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <NotificationContainer />
    </div>
  );
}
