import React from "react";
import { LuHotel } from "react-icons/lu";
import { Link } from "react-router-dom";
import { MdOutlineBedroomChild } from "react-icons/md";

const RoomUpgradeTool = () => {
  return (
    <Link to="/dashboard/upgrade-tool/room-upgrade-tool">
      <div className="flex flex-col gap-2 items-center justify-center">
        <div className="border rounded-full p-8 bg-[#002244] hover:scale-110 hover:shadow-sm transition-transform duration-200 ease-in-out">
          <MdOutlineBedroomChild className="w-8 h-8 text-white" />
        </div>

        <p className="text-lg font-semibold text-center">Room Extra Offers</p>
      </div>
    </Link>
  );
};

export default RoomUpgradeTool;
