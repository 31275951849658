import { Button } from "flowbite-react";
import React, { useState } from "react";
import { HiDocumentAdd } from "react-icons/hi";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import PackageModal from "./PackageModal";
import { findMissingNumberInArray } from "../../../utils/arrayUtils";

function AddNewPackageModal({ roomData }) {
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);

  const { isPending, mutate: addNewPackage } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/en/rooms/api/v1/room-package-occupancy/`, "post", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: ["add-new-room-package"],
    onSuccess: () => {
      setOpenModal(false);
      toast.success("Package added successfully");
      queryClient.refetchQueries({
        queryKey: ["room_packages", roomData.id],
      });
    },
  });

  function handleSubmit(values, helpers) {
    const { children_room_package_occupancy } = values;
    const childOrders = children_room_package_occupancy.map((child) => child.ordering_child);
    const missingNumber = findMissingNumberInArray(childOrders);

    if (missingNumber) {
      toast.error(`Please add child order ${missingNumber}`);
      return;
    }

    const data = {
      room: roomData.id,
      ...values,
    };

    addNewPackage(data, {
      onSuccess: helpers.resetForm,
    });
  }

  return (
    <>
      <Button color={"success"} onClick={() => setOpenModal(true)}>
        <HiDocumentAdd className="mr-2 h-5 w-5" />
        Add Package
      </Button>
      <PackageModal
        initialValues={{
          max_adults: "",
          children_room_package_occupancy: [],
        }}
        openModal={openModal}
        onSubmit={handleSubmit}
        roomData={roomData}
        isPending={isPending}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default AddNewPackageModal;
