import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import { RiAccountPinBoxLine } from "react-icons/ri";
import { BsCurrencyExchange } from "react-icons/bs";
import { LiaCalendar } from "react-icons/lia";
import { BiTransfer } from "react-icons/bi";
import { GiBank } from "react-icons/gi";
import { MdDeleteForever, MdOutlineSettings, MdOutlineSpeakerNotes } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import { deleteBank, getAllBankAction } from "../../../redux/actions/addBankAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { RxUpdate } from "react-icons/rx";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { AiOutlineEye } from "react-icons/ai";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import { toast } from "react-toastify";
import ListFilters from "../../../utils/ListFilters";

const BankList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const [bank, setBank] = useState("");
  const [type, setType] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [deletePopup, setDeletePopup] = useState("");
  const [permissionID, setPermissionID] = useState("");

  const [columnVisibility, setColumnVisibility] = useState({});
  const [transaction, setTransaction] = useState(null);

  const fetchData = () => {
    setLoad(true);
    dispatch(
      getAllBankAction(
        token,
        (result) => {
          if (result.status === 200) {
            setLoad(false);
            setBank(result.data);
          } else {
            toast.error(result.data.data || `Error in Fetching data, error status: ${result.status} `);
          }
        },
        (error) => {},
      ),
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  function handleDeletePopUp(params) {
    setDeletePopup(`Permission for ${params.name}`);
    setPermissionID(params.id);
  }

  const handleDelete = (permissionID) => {
    dispatch(
      deleteBank(token, permissionID, (result) => {
        if (result.status === 204) {
          fetchData();
        }
      }),
    );
    setDeletePopup(false);
  };

  const rows =
    bank &&
    bank.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        account_number: item.account_number,
        currency: item.currency,
        notes: item.notes,
        account_balance: item.account_balance,
        created_at: item.created_at.slice(0, 10),
        updated_at: item.updated_at,
      };
    });
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),

      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return <div className="ml-2">{params.id}</div>;
      },
    },

    {
      key: "name",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          NAME
        </div>
      ),
      dataIndex: "name",
      editable: false,
      render: (text, params) => {
        return <div className="">{params.name}</div>;
      },
    },

    {
      key: "account_number",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <RiAccountPinBoxLine className="w-5 h-5  text-yellow-500" />
          ACCOUNT NUMBER
        </div>
      ),
      dataIndex: "account_number",
      editable: false,
      render: (text, params) => {
        return <div className="">{params.account_number}</div>;
      },
    },

    {
      key: "currency",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BsCurrencyExchange className="w-5 h-5  text-yellow-500" />
          CURRENCY
        </div>
      ),

      dataIndex: "currency",
      editable: false,
      render: (text, params) => {
        return <div className="">{params.currency}</div>;
      },
    },
    {
      key: "notes",
      title: (
        <div className="flex items-center gap-x-1 text-xs ">
          <MdOutlineSpeakerNotes className="w-5 h-5  text-yellow-500" />
          NOTES
        </div>
      ),
      dataIndex: "notes",
      editable: false,
      render: (text, params) => {
        return <div className="flex items-center justify-start gap-x-5 ml-5">{params.notes}</div>;
      },
    },
    {
      key: "balance",
      title: (
        <div className="flex items-center gap-x-1 text-xs ">
          <CgArrowsExchangeAltV className="w-5 h-5  text-yellow-500" />
          <p className="uppercase">Account Balance</p>
        </div>
      ),
      dataIndex: "balance",
      editable: false,
      render: (text, params) => {
        return <div className="flex items-center justify-start gap-x-5 ml-5">{params.account_balance}</div>;
      },
    },
    {
      key: "created_at",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CREATED AT
        </div>
      ),
      flex: 1,
      editable: false,
      render: (text, params) => {
        return <div className="ml-3">{params.created_at}</div>;
      },
    },

    {
      key: "updated_at",
      title: (
        <div className="flex items-center gap-x-1 text-xs ml-5">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          UPDATE AT
        </div>
      ),
      dataIndex: "updated_at",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.updated_at}</div>;
      },
    },
    {
      key: "operations",
      title: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",
      editable: false,

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            <Link
              to={`/dashboard/account-list/banklist/${params.id}`}
              className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-gray-700 " />
            </Link>

            <Link
              to={`/dashboard/account-list/banklist/create/${params.id}/update`}
              className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
            >
              <RxUpdate className="w-5 h-5 text-white" />
            </Link>

            <button className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all">
              <MdDeleteForever onClick={() => handleDeletePopUp(params)} className="w-5 h-5 text-white" />
            </button>
          </div>
        );
      },
    },
  ];

  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.view_bank"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full formPage-wrap ">
      {bank && permissionFlag ? (
        permission?.map((item) => {
          if (item.name === "accounting.view_bank" && item.value === true) {
            return (
              <Box key={item.id}>
                <div className="w-full overflow-auto">
                  <ListFilters columns={columns} rows={rows} title="Bank List" />
                </div>{" "}
                {deletePopup && (
                  <DeletePopUp
                    name={deletePopup}
                    Func={() => handleDelete(permissionID)}
                    hideFunc={() => {
                      setDeletePopup(false);
                    }}
                  />
                )}
              </Box>
            );
          } else {
            return (
              <div className="w-full h-screen ">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
          <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default BankList;
