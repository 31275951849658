import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../../redux/actions/loginAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import { TfiWorld } from "react-icons/tfi";
import { IoLogOutOutline } from "react-icons/io5";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import TopBarProgress from "react-topbar-progress-indicator";
import { AiOutlineWifi } from "react-icons/ai";
import { FiWifiOff } from "react-icons/fi";
import { FaSearchLocation } from "react-icons/fa";
import DashboardSidebar from "../DashboardSidebar/DashboardSidebar";
import PopUp from "../PopUp/PopUp";
import { FcMenu } from "react-icons/fc";
import { flagListAction } from "../../../redux/actions/addCashBoxAction";
import usePermissions from "../../../customHooks/usePermissions";
import { CgNotes } from "react-icons/cg";
import { getUserProfileAction } from "../../../redux/actions/userProfileAction";
import jwtDecode from "jwt-decode";
import { base_URL } from "../../../urls";
import { PiUsersThreeBold } from "react-icons/pi";
import ActiveUsers from "../ActiveUsers/ActiveUsers";
import BreadCrumbs from "./BreadCrumbs";
import { BsPersonRaisedHand } from "react-icons/bs";
import { TbLetterB } from "react-icons/tb";

import "./taskbutton.css";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";

const DashboardNavbar = () => {
  const dispatch = useDispatch();
  const refreshToken = cookie.load("refresh_token");
  const accessToken = cookie.load("access_token");
  const location = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const [userFlag, setUserFlag] = useState(false);
  const [userData, setUserData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [taskCountData, setTaskCount] = useState(false);

  const {
    data: taskCount,
    error: errorCount,
    isSuccess: countSuccess,
    isLoadingTask: typeLoading,
    refetch, // Get the refetch function from useQuery
  } = useQuery({
    queryKey: ["taskCount"],
    queryFn: () => fetchDataQuery("/tasks-management/api/v1/task-assignments/new"),
  });
  useEffect(() => {
    if (countSuccess && taskCount) {
      setTaskCount(taskCount);
    }
  }, [taskCount, countSuccess]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch(); // Refetch data at a regular interval or based on other events
    }, 60000); // Refetch every 60 seconds (or any other condition can be added)

    return () => clearInterval(intervalId);
  }, [refetch]);

  function closeModal() {
    setIsOpen(false);
  }

  const logOuthandle = () => {
    setLoad(true);
    dispatch(
      logoutAction(accessToken, refreshToken, async (result) => {
        try {
          if (result.status === 205) {
            setLoad(false);
            // Clear cookies
            cookie.remove("access_token", { path: "/" });
            cookie.remove("refresh_token", { path: "/" });
            cookie.remove("currency", { path: "/" });
            cookie.remove("language", { path: "/" });
            cookie.remove("code", { path: "/" });
            // Navigate to login page
            navigate("/login");
          } else {
            // Handle other statuses or conditions if needed
            setLoad(false);
          }
        } catch (error) {}
      }),
    );
  };

  function handleOnline() {
    setIsConnected(true);
  }

  function handelOffline() {
    setIsConnected(false);
  }

  const token = cookie.load("access_token");
  const user = jwtDecode(token);
  useEffect(() => {
    //get user img
    dispatch(
      getUserProfileAction(user.user_id, (result) => {
        if (result.status === 200) {
          setUserData(result.data);
        }
      }),
    );
    const handleResize = () => {
      // Check if the screen width is "md" based on Tailwind CSS classes
      if (window.innerWidth < 1024) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);
    // Initial check when the component is mounted
    handleResize();
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handelOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handelOffline);
    };
  });
  //permissions
  const [permissions, permissionsFlag] = usePermissions([
    "permissions_management.search_platform",
    "meet_and_assist_button",
    "room_booking.view_bookedroom",
    "it_ticketing_system.add_ticketsupport",
    "it_ticketing_system.view_ticketsupport",
  ]);

  return (
    <>
      {userFlag && <ActiveUsers userFlag={userFlag} setUserFlag={setUserFlag} />}
      <nav className="w-full bg-white  dark:bg-gray-900  z-20 border-gray-200 border-b     flex  items-center justify-between mx-auto md:px-6 px-2 py-4    ">
        <div className="w-full flex flex-col md:flex-row items-center justify-between gap-x-4 md:order-2  z-20 ">
          <Link
            to={"/dashboard/user-profile"}
            className="hover:scale-105 active:scale-95 cursor-pointer duration-500 transition-all"
          >
            {userData?.avatar ? (
              <div className="flex items-center gap-x-3">
                <img src={`${base_URL}${userData.avatar}`} className="w-10 h-10 rounded-full" />
                <p className="text-md font-bold text-[#002244]">
                  {userData.first_name} {userData.last_name}
                </p>
              </div>
            ) : (
              <div className="flex items-center gap-x-3">
                <img src="/images/app.jpg" className="w-10 h-10 rounded-full" />
                <p className="text-md font-bold text-[#002244]">
                  {userData?.first_name} {userData?.last_name}
                </p>
              </div>
            )}
          </Link>
          {/* {open && (
            <div className=" flex items-center gap-x-2">
              <button
                onClick={() => {
                  setIsOpen(true);
                  dispatch(flagListAction(true));
                }}
                className="text-gray-800"
              >
                <FcMenu className="w-5 h-5" />
              </button>
            </div>
          )} */}

          {/* <div className="relative">
              <div
                onClick={toggleMenu}
                className="cursor-pointer p-3 bg-[#002244] rounded-md flex items-center justify-center"
              >
                <div className="space-y-1">
                  <span
                    className={`block w-6 h-0.5 bg-white transform transition duration-300 ${isOpen ? "rotate-45 translate-y-2" : ""}`}
                  />
                  <span className={`block w-6 h-0.5 bg-white transition duration-300 ${isOpen ? "opacity-0" : ""}`} />
                  <span
                    className={`block w-6 h-0.5 bg-white transform transition duration-300 ${isOpen ? "-rotate-45 -translate-y-2" : ""}`}
                  />
                </div>
              </div>

              <div
                className={`absolute top-12 left-0 bg-white shadow-lg rounded-md transition-all duration-300 ${isOpenMenu ? "max-h-screen" : "max-h-0 overflow-hidden"}`}
              >
                <div className="p-4 space-y-4">
                  {permissions?.some((item) => item.name === "room_booking.view_bookedroom" && item?.value) && (
                    <Link
                      to="/dashboard/booking-list/filter/1/-created_date"
                      className="flex items-center gap-2 bg-[#002244] text-white p-3 rounded-md hover:bg-[#002244cc] transition-all duration-150"
                    >
                      <TbLetterB size={24} />
                      <span>Booking List</span>
                    </Link>
                  )}

                  <div className="relative">
                    <span className="absolute top-[-5px] left-[-5px] bg-red-500 rounded-full animate-pulse w-5 h-5 z-50 flex justify-center items-center">
                      <span className="text-white z-50 text-xs md:text-sm">
                        {taskCountData?.count && taskCountData?.count}
                      </span>
                    </span>
                    <Link
                      to="/dashboard/task-list"
                      className="flex items-center gap-2 bg-[#002244] text-white p-3 rounded-md hover:bg-[#002244cc] transition-all duration-150"
                    >
                      <span>My Task</span>
                    </Link>
                  </div>

                  {permissions?.some((item) => item.name === "meet_and_assist_button" && item?.value) && (
                    <div
                      className="flex items-center gap-2 cursor-pointer hover:scale-105 transition-transform"
                      title="Meet & Assist"
                    >
                      <Link
                        to="/dashboard/meet-&-assist-service/New/1"
                        className="flex items-center gap-2 bg-[#002244] text-white p-3 rounded-md hover:bg-[#002244cc] transition-all duration-150"
                      >
                        <BsPersonRaisedHand size={24} />
                        <span>Meet & Assist</span>
                      </Link>
                    </div>
                  )}

           
                  <div
                    onClick={() => navigate("/dashboard/notes?pageSize=8&page=1&status=")}
                    className="flex items-center gap-2 cursor-pointer hover:scale-105 transition-transform"
                    title="Notes"
                  >
                    <div className="flex items-center gap-2 bg-[#002244] text-white p-3 rounded-md hover:bg-[#002244cc] transition-all duration-150">
                      <CgNotes size={24} />
                      <span>Notes</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          <div className="w-[80%] flex-wrap gap-y-2 flex items-center justify-around md:justify-end gap-x-2 md:order-2 max-md:mt-2  ">
            {open && (
              <div className=" flex items-center gap-x-2">
                <button
                  onClick={() => {
                    setIsOpen(true);
                    dispatch(flagListAction(true));
                  }}
                  className="text-gray-800"
                >
                  <FcMenu className="w-5 h-5" />
                </button>
              </div>
            )}
            {permissions?.some((item) => item.name === "room_booking.view_bookedroom" && item?.value) && (
              <Link
                to="/dashboard/booking-list/filter/1/-created_date"
                className="bg-[#002244] hover:shadow-sm p-2 flex justify-center items-center  hover:scale-[1.05]  text-white w-8 h-8 rounded-full group hover:bg-[#002244dc] transition-all duration-150 "
                title="Booking list"
              >
                <TbLetterB className="text-white sm:text-lg text-md " />
              </Link>
            )}
            <div className="text-center relative max-w-max">
              {taskCountData && taskCountData?.count !== 0 && (
                <span className="absolute top-[-5px] left-[-5px] bg-red-500 rounded-full animate-pulse w-5 h-5 z-50 flex justify-center items-center">
                  <span className="text-white z-50 text-xs md:text-sm">
                    {taskCountData?.count && taskCountData?.count}
                  </span>
                </span>
              )}
              <Link
                to="/dashboard/task-list"
                className="tasks-Button hover:shadow-sm w-full inline-flex items-center justify-center px-4 py-2"
              >
                <span className="spark__container">
                  <span className="spark" />
                </span>
                <span className="backdrop" />
                <span className="text text-xs md:text-sm lg:text-base">Tasks</span>
              </Link>
            </div>
            {permissions?.some((item) => item.name === "it_ticketing_system.view_ticketsupport" && item?.value) && (
              <Link
                to="/dashboard/tickets-support"
                className="bg-[#002244] p-2 hover:shadow-sm flex justify-center items-center  hover:scale-[1.05]  text-white w-8 h-8 rounded-full group hover:bg-[#002244dc] transition-all duration-150 "
                title="IT Tickets"
              >
                <span className="">IT</span>
              </Link>
            )}
            {permissions?.some((item) => item.name === "meet_and_assist_button" && item?.value) && (
              <div
                className=" flex items-center hover:shadow-sm gap-x-2 cursor-pointer hover:scale-110 transition-transform"
                title="meet & assist"
              >
                <Link
                  to="/dashboard/meet-&-assist-service/New/1"
                  className="flex items-center gap-2 text-[#002244] font-bold  w-full"
                >
                  <BsPersonRaisedHand size={20} />
                </Link>
              </div>
            )}
            <div
              onClick={() => {
                navigate("/dashboard/notes?pageSize=8&page=1&status=");
              }}
              className=" flex items-center gap-x-2 cursor-pointer hover:scale-110 transition-transform"
              title="Notes"
            >
              <div className="flex items-center gap-2 text-blue-700 font-bold  w-full">
                <CgNotes size={20} />
              </div>
            </div>
            {open ? (
              <div className=" flex items-center gap-x-2">
                {isConnected ? (
                  <div className="flex items-center gap-2 text-green-700 font-bold  w-full">
                    <AiOutlineWifi size={20} />
                  </div>
                ) : (
                  <div className="flex items-center gap-2 text-red-800 font-bold w-full">
                    <FiWifiOff size={20} />
                  </div>
                )}
              </div>
            ) : (
              <div className="">
                {isConnected ? (
                  <div className="flex items-center gap-2 text-green-800 font-bold  w-full">
                    <AiOutlineWifi size={20} />
                  </div>
                ) : (
                  <div className="flex items-center gap-2 text-red-800 font-bold w-full">
                    <FiWifiOff size={20} />
                  </div>
                )}
              </div>
            )}
            {open ? (
              <button
                onClick={() => setUserFlag(true)}
                className="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm bg-gray-100 text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white duration-500 transition-all"
              >
                <PiUsersThreeBold className="w-4 h-4 text-[#002244]" />{" "}
                <div className="flex items-center gap-x-2">
                  <div className="w-3 h-3 rounded-full bg-green-700"></div>
                </div>
              </button>
            ) : (
              <button
                onClick={() => setUserFlag(true)}
                className="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm bg-gray-100 text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white duration-500 transition-all"
              >
                <PiUsersThreeBold className="w-4 h-4 text-[#002244]" />{" "}
                <div className="flex items-center gap-x-2">
                  Active Users
                  {/* <div className="w-3 h-3 rounded-full bg-green-700"></div> */}
                </div>
              </button>
            )}
            {permissionsFlag ? (
              permissions.some((item) => item.value) && open ? (
                <Link to="/">
                  <FaSearchLocation className="w-4 h-4 text-gray-900" />
                </Link>
              ) : (
                permissions.some((item) => item.value) && (
                  <Link
                    to="/"
                    class="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <FaSearchLocation className="w-4 h-4 text-[#002244]" /> <p>Booking Search</p>
                  </Link>
                )
              )
            ) : (
              <ButtonLoading />
            )}
            {open ? (
              <button
                type="button"
                data-dropdown-toggle="language-dropdown-menu"
                className="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <TfiWorld className="w-4 h-4 text-gray-900" />
              </button>
            ) : (
              <button
                type="button"
                data-dropdown-toggle="language-dropdown-menu"
                className="inline-flex gap-x-1 items-center font-medium justify-center px-2 py-2 text-sm text-[#002244] dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <TfiWorld className="w-4 h-4 text-[#002244]" /> En
              </button>
            )}
            <button className="inline-block relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
              {/* <span class="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-green-400 bg-green-600"></span> */}
            </button>{" "}
            {open ? (
              <button
                disabled={load}
                type="button"
                onClick={() => logOuthandle()}
                className=" active:scale-95 text-black"
              >
                {load ? (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                ) : (
                  <IoLogOutOutline className="w-5 h-5" />
                )}
              </button>
            ) : (
              <button
                disabled={load}
                type="button"
                onClick={() => logOuthandle()}
                className="flex  items-center font-medium justify-center gap-x-2 px-3 py-2 text-sm text-white dark:text-white rounded-lg cursor-pointer bg-[#002244] active:scale-95"
              >
                Logout{" "}
                {load ? (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                ) : (
                  <IoLogOutOutline className="w-5 h-5" />
                )}
              </button>
            )}
          </div>
        </div>
        <PopUp close={closeModal} isOpen={isOpen} sidebar={<DashboardSidebar />} />
      </nav>
      <BreadCrumbs />
    </>
  );
};
export default DashboardNavbar;
