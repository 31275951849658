import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import React, { useEffect, useState } from "react";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ClientForm from "./ClientForm";
import usePermissions from "../../../customHooks/usePermissions";
import NoPermission from "../NoPermission/NoPermission";

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    if (!list) return true; // If the list is empty, skip the test

    const set = new Set(list.map(mapper));
    const isUnique = set.size === list.length;

    if (!isUnique) {
      return this.createError({
        path: this.path,
        message: message,
      });
    }

    return true;
  });
});

const EditClientValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .test("is-three-words", "The name must consist of at least three words", (value) => {
      if (value) {
        // Split the string by spaces and filter out empty strings
        const words = value.split(" ").filter((word) => word.trim().length > 0);
        return words.length >= 3;
      }
      return false;
    }),
  username: Yup.string()
    .required("Username is required")
    .test("no-spaces", "Username must not contain spaces", (value) => {
      // Ensure the value is defined and doesn't contain any spaces
      return value && !/\s/.test(value);
    }),
  firstName: Yup.string().notRequired(),
  lastName: Yup.string().notRequired(),
  nickname: Yup.string().required("Nickname is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().notRequired(),
  id: Yup.string().required("ID Number is required"),
  country: Yup.string().required("Country is required"),
  mobileNumbers: Yup.array()
    .of(
      Yup.object().shape({
        num: Yup.string()
          .trim()
          .matches(/^\+?[0-9]{5,}$/, "Please add a valid phone number with at least 5 digits")
          .min(5, "Please add at least 5 numbers")
          .required("This field is required"), // these constraints take precedence
        whatsapp: Yup.boolean().default(false), // these constraints take precedence
      }),
    )
    .required("Must Add at least one mobile number")
    .unique("Mobile numbers must be unique", (item) => item.num),
  markup: Yup.number().required("Please select a markup"),
  groups: Yup.array()
    .of(Yup.number())
    .min(1, "Please select at least one group")
    .required("Please select at least one group"),
});

function EditClientForm() {
  const queryClient = useQueryClient();
  const [permissions, permissionFlag, permissionsError] = usePermissions(["auth_users.change_profile_customer"]);

  const [image, setImage] = useState({
    src: "",
    file: null,
  });
  const { id } = useParams();
  const {
    data: client,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["client", id],
    queryFn: () => {
      return fetchDataQuery(`/auth/api/v1/user-customer/${id}/`);
    },
  });

  const { mutate: updateClient, isPending } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/auth/api/v1/user-customer/${id}/`, "patch", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Client Updated Successfully");
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to update Client");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to update Client");
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setImage({
        src: client?.profile_customer?.avatar || "",
        file: null,
      });
    }
  }, [client?.profile_customer?.avatar, isSuccess]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Errorfetch Error={error.message} />;
  }

  const formInitialValues = {
    name: client.profile_customer.customer_name,
    firstName: client.first_name,
    lastName: client.last_name,
    username: client.username,
    nickname: client.profile_customer.nickname,
    email: client.email,
    password: "",
    id: client.profile_customer.id_number,
    countryName: client.profile_customer.country_name,
    country: client.profile_customer.country,
    cityName: client.profile_customer.city,
    city: client.profile_customer.city,
    notes: client.profile_customer.notes,
    mobileNumbers: Object.values(client.profile_customer.mobile_numbers).map((number) => ({
      num: number,
      whatsapp: Object.values(client.profile_customer.whats_app_numbers).includes(number),
    })),
    markup: client.profile_customer.markup,
    groups: client.groups.map((group) => group.id),
    active: client.is_active,
    favoriteCities: client.profile_customer.preferences_cities.map((city) => city.id),
    favoriteHotels: client.profile_customer.preferences_hotels.map((hotel) => hotel.id),
    smokingPreferences: client.profile_customer.preferences_smoking,
    bedPreferences: client.profile_customer.preferences_bed,
    clientAccount: client.profile_customer.preferences_accounting,
    extraInfo: client.profile_customer.extra_info,
  };

  function handleSubmit(values) {
    const formData = new FormData();

    if (values.name !== client.profile_customer.customer_name) {
      formData.append("profile_customer[customer_name]", values.name);
    }

    if (values.firstName !== client.first_name) {
      formData.append("first_name", values.firstName);
    }

    if (values.lastName !== client.last_name) {
      formData.append("last_name", values.lastName);
    }

    if (values.username !== client.username) {
      formData.append("username", values.username);
    }

    if (values.email !== client.email) {
      formData.append("email", values.email);
    }

    if (values.password) {
      formData.append("password", values.password);
    }

    if (values.nickname !== client.profile_customer.nickname) {
      formData.append("profile_customer[nickname]", values.nickname);
    }

    if (values.id !== client.profile_customer.id_number) {
      formData.append("profile_customer[id_number]", values.id);
    }

    if (values.notes !== client.profile_customer.notes) {
      formData.append("profile_customer[notes]", values.notes);
    }

    if (values.country !== client.profile_customer.country) {
      formData.append("profile_customer[country]", values.country);
    }

    if (values.city !== client.profile_customer.city) {
      formData.append("profile_customer[city]", values.city ? values.city : "");
    }

    if (values.markup !== client.profile_customer.markup) {
      formData.append("profile_customer[markup]", values.markup);
    }

    if (values.active !== client.is_active) {
      formData.append("is_active", values.active);
    }

    formData.append("profile_customer[preferences_cities]", JSON.stringify(values.favoriteCities));

    formData.append("profile_customer[preferences_hotels]", JSON.stringify(values.favoriteHotels));

    if (values.smokingPreferences !== client.profile_customer.preferences_smoking) {
      formData.append("profile_customer[preferences_smoking]", values.smokingPreferences);
    }

    if (values.bedPreferences !== client.profile_customer.preferences_bed) {
      formData.append("profile_customer[preferences_bed]", values.bedPreferences);
    }

    if (values.clientAccount !== client.profile_customer.preferences_accounting) {
      formData.append("profile_customer[preferences_accounting]", values.clientAccount);
    }

    if (values.extraInfo !== client.profile_customer.extra_info) {
      formData.append("profile_customer[extra_info]", values.extraInfo);
    }

    const mobileObject = values.mobileNumbers.reduce((acc, number, index) => {
      acc[index + 1] = number.num;
      return acc;
    }, {});

    const mobileJson = JSON.stringify(mobileObject);
    formData.append("profile_customer[mobile_numbers]", mobileJson);

    const whatsappArray = values.mobileNumbers.filter((number) => number.whatsapp);

    const whatsappObject = whatsappArray.reduce((acc, number, index) => {
      acc[index + 1] = number.num;
      return acc;
    }, {});

    const whatsappJson = JSON.stringify(whatsappObject);
    formData.append("profile_customer[whats_app_numbers]", whatsappJson);

    if (image.file) {
      formData.append("profile_customer[avatar]", image.file);
    }

    if (!image.src) {
      formData.append("profile_customer[avatar]", "");
    }

    updateClient(formData, {
      onSuccess: () => {
        void queryClient.refetchQueries(
          {
            queryKey: ["client", id],
            type: "active",
            exact: true,
          },
          {
            throwOnError: (error) => {
              toast.error(error.message || "Failed to re-fetch Client");
            },
          },
        );
      },
    });
  }

  const isEditingClientAllowed =
    permissions?.find((permission) => permission.name === "auth_users.change_profile_customer")?.value === true;

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  return (
    <>
      {isEditingClientAllowed ? (
        <ClientForm
          submitButtonTitle={"Update Client"}
          validationSchema={EditClientValidationSchema}
          image={image}
          setImage={setImage}
          title={"Edit Client"}
          isPending={isPending}
          onSubmit={handleSubmit}
          initialValues={formInitialValues}
        />
      ) : (
        <NoPermission />
      )}
    </>
  );
}

export default EditClientForm;
