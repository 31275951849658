import api_token from "../../api/UserApi";
import {
  DELETE_CASH_FLAG,
  GET_SELECTED_CASH,
  GET_SELECTED_CASH_FLAG,
  UPDATE_CASH_FLAG,
  BANK_DATA,
  BOX_NAME,
  BOX_NAME_FLAG,
} from "../types/types";

export const getSelectedCash = (token, id, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_SELECTED_CASH_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`booking/api/v1/cash-payments-transaction/${id}/`, { signal });
      dispatch({
        type: GET_SELECTED_CASH,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_SELECTED_CASH_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
export const updateCashAction = (token, data, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_CASH_FLAG,
        payload: true,
      });
      const response = await api_token(token).patch(`booking/api/v1/cash-payments-transaction/${id}/`, data);
      if (response.status === 200) {
        dispatch({
          type: UPDATE_CASH_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const deleteCashAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_CASH_FLAG,
        payload: true,
      });
      const response = await api_token(token).delete(`booking/api/v1/cash-payments-transaction/${id}/`);
      if (response.status === 204) {
        dispatch({
          type: DELETE_CASH_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
export const getBankListAction = (token, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("accounting/api/v1/banks/", { signal });
      dispatch({
        type: BANK_DATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};
export const getBankChequesAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("accounting/api/v1/banks/");
      dispatch({
        type: BANK_DATA,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const getBoxNameAction = (token, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: BOX_NAME_FLAG,
        payload: true,
      });
      const response = await api_token(token).get("box/api/", { signal });
      if (response.status === 200) {
        dispatch({
          type: BOX_NAME_FLAG,
          payload: false,
        });

        dispatch({
          type: BOX_NAME,
          payload: response.data,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
