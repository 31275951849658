import cookie from "react-cookies";
import api_token from "../../api/UserApi";

const token = cookie.load("access_token");

export default function deleteHotelContract(id, ordering, page, callback) {
  return async function (dispatch) {
    try {
      let response = await api_token(token).patch(`/contracts/api/soft-deleted/${id}/`);
      callback(response);
      // if (response) {
      //   dispatch(getContracts(ordering, page));
      // }
    } catch (error) {}
  };
}

export const deleteContractId = (id, callback) => {
  return async function (dispatch) {
    try {
      let response = await api_token(token).delete(`/contracts/api/${id}`);
      callback(response);
    } catch (error) {}
  };
};

export const deleteContractApproval = (id, callback) => {
  return async function (dispatch) {
    try {
      let response = await api_token(token).patch(`/contracts/api/soft-deleted/${id}/`);
      callback(response);
    } catch (error) {}
  };
};
