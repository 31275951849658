import { GETBOOKEDROOMS , BOOKEDROOMID , SINGELBOOKEDROOM , UPDATEFLAG , BANKLIST , CURRENCY 
    , BANK_TRANSFER_FLAG , OPERATIONDATA , OPERATION_FLAG , OERATION_DATA_ID , BOOKINGTYPES ,
     BOOKINGSTATUS , OPERATION_UPDATA_FLAG , CONVERT_ADD_UPDATA , ADD_CASH_FLAG,  JUMBO_FLAG , DELETE_ORDER , SELECTED_BOOKED_ORDER_FLAG , ALL_BOOKING_STATUS ,
      GET_OPERATION_FLAG , GET_PAYMENT_LIST , GET_PAYMENT_LIST_FLAG , ALL_HOTELS_NAME} from "../types/types";
const initialState = {
    bookedHubRoomsStore:{
        results:[],
        count:0
    },
    selectedBookedRoom:[],
    selectedSingleRoom:{},
    selectBookingRoomFlag:false,
    bookingTypes:null,
    bookingStatus:null,
    allBookingStatus:null,
    allHotelsData:null,
    updateFlag : false,
    BankList : null,
    currencyList:null,
    bankTransferFlag:false,
    operationData : null,
    operationFlag : false,
    getOperationIdFlag:false,
    paymentList:null,
    paymentListFlag:false,
    operationDataId : null,
    deleteOrder:false,
    operationUpdataFlag : false,
    convertAddUpdata:false,
    addCashFlag:false,
    jumboFlag:false,


}

export const bookingListReducer = (state = initialState , action) => {
switch(action.type)
{
    case GETBOOKEDROOMS : 
         return{...state , bookedHubRoomsStore : action.payload}
    case BOOKEDROOMID : 
         return{...state , selectedBookedRoom : action.payload}
    case SINGELBOOKEDROOM : 
         return{...state , selectedSingleRoom : action.payload}
    case SELECTED_BOOKED_ORDER_FLAG : 
         return{...state , selectBookingRoomFlag : action.payload}
     case UPDATEFLAG : 
         return{...state , updateFlag : action.payload};
     case OPERATIONDATA : 
         return{...state , operationData : action.payload}
     case OPERATION_FLAG : 
         return{...state , operationFlag : action.payload}
     case OERATION_DATA_ID : 
         return{...state , operationDataId : action.payload};
     case GET_OPERATION_FLAG : 
         return{...state , getOperationIdFlag : action.payload};
    case GET_PAYMENT_LIST : 
         return{...state , paymentList : action.payload};
    case GET_PAYMENT_LIST_FLAG : 
         return{...state , paymentListFlag : action.payload};
    case DELETE_ORDER : 
         return{...state , deleteOrder : action.payload};
    case CONVERT_ADD_UPDATA : 
         return{...state , convertAddUpdata : action.payload};
     case OPERATION_UPDATA_FLAG: 
         return{...state , operationUpdataFlag : action.payload};
     case BOOKINGTYPES : 
         return{...state , bookingTypes : action.payload}
     case BOOKINGSTATUS : 
         return{...state , bookingStatus : action.payload}
    case ALL_BOOKING_STATUS : 
         return{...state , allBookingStatus : action.payload}
    case ALL_HOTELS_NAME : 
         return{...state , allHotelsData : action.payload}
     case BANKLIST : 
         return{...state , BankList : action.payload}
     case CURRENCY : 
         return{...state , currencyList : action.payload}
     case BANK_TRANSFER_FLAG : 
         return{...state , bankTransferFlag : action.payload}
    case ADD_CASH_FLAG : 
         return{...state , addCashFlag : action.payload}
    case JUMBO_FLAG : 
         return{...state , jumboFlag : action.payload}
    default:
         return state;
}
}

