import React, { useEffect } from "react";
import NoteCard from "./NoteCard";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Errorfetch from "../../Errorfetch/Errorfetch";
import { TbNotesOff } from "react-icons/tb";
import { NotesPagination } from "./NotesPagination";
import { useSearchParams } from "react-router-dom";
import Loading from "../../Loading/Loading";

function Notes() {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || 1;
  const pageSize = searchParams.get("pageSize") || 8;
  const status = searchParams.get("status") || "";

  useEffect(() => {
    const params = Object.fromEntries(searchParams);
    if (!params.status) {
      setSearchParams({ ...params, status: "" });
    }
    if (!params.page) {
      setSearchParams({ ...params, page: "1" });
    }
    if (!params.pageSize) {
      setSearchParams({ ...params, pageSize: "8" });
    }
  }, [searchParams, setSearchParams]);

  const { data, isLoading, error } = useQuery({
    queryFn: () =>
      fetchDataQuery(`/notes-management/api/v1/notes/?page=${page}&page_size=${pageSize}&status=${status}`),
    queryKey: ["notes", page, pageSize, status],
  });

  const notes = data?.results;
  const count = data?.count;

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Errorfetch Error={"Error Fetching Notes"} />;
  }

  if ((!notes?.length || !notes) && !isLoading) {
    return (
      <div className="flex flex-col justify-center items-center gap-2">
        <h1 className="text-xl font-bold">No Notes Found</h1>
        <TbNotesOff className="w-16 h-16 " />
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 xl:grid-cols-4 gap-4 px-4 content-start flex-grow">
        {notes?.map((note) => (
          <NoteCard note={note} key={note.id} />
        ))}
      </div>
      {!isLoading && <NotesPagination count={count} />}
    </>
  );
}

export default Notes;
