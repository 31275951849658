import React from 'react';
import { useParams } from 'react-router'
import BankTransferDetails from '../BankTransferDetails/BankTransferDetails';
import CashDetails from '../CashDetails/CashDetails';
import PaymentTransactionDetails from '../PaymentTransactionDetails/PaymentTransactionDetails';
import PosVisaDetails from '../PosVisaDetails/PosVisaDetails';

const TransactionsDetails = () => {
    const params = useParams();
    const handleShow = () =>{
        if(params.type === "Cash")
        {
           return  <CashDetails />
        }
        else if (params.type === "Jumbo4pay")
        {
             return <PaymentTransactionDetails />
        }
        else if (params.type === "Bank Transfer")
        {
          return <BankTransferDetails />
        }
        else if (params.type === "(POS) Visa")
        {
          return <PosVisaDetails />
        }
    }
  return (
    <div className='w-full'>
        {handleShow()}
    </div>
  )
}

export default TransactionsDetails