import React, { useEffect, useState } from "react";
import SpecialRateForm from "./SpecialRateForm";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import usePermissions from "../../../customHooks/usePermissions";
import NoPermission from "../NoPermission/NoPermission";
import { FaInfoCircle } from "react-icons/fa";

const AddSpecialRate = () => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState();
  const [errExist, setErr] = useState(false);
  const [validMsg, setValidMsg] = useState(false);

  const {
    mutate: speicalTool,
    isLoading,
    isPending,
  } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery("/sales-management/api/v1/special-rate-requests/", "post", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: "speicalTool",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Special Rate Created Successfully");
      navigate(`/dashboard/special-rates-requests-list`);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
          setErrMsg(err.message);
          setErr(true);
        });
        return;
      }
      toast.error(error.message || "Failed to create special rate");
    },
  });

  useEffect(() => {
    if (/department/i.test(errMsg)) {
      setValidMsg(true);
    } else {
      setValidMsg(false);
    }
  }, [errMsg]);
  const [permissions, permissionFlag, permissionsError] = usePermissions(["sales_management.view_specialraterequest"]);

  const isViewingSpeicalAddRequestAllowed =
    permissions?.find((permission) => permission.name === "sales_management.view_specialraterequest")?.value === true;
  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const initialValues = {
    number_of_persons: null,
    city: null,
    area: null,
    hotel: null,
    room_type: [],
    methodOfRequest: null,
    check_in_date: null,
    check_out_date: null,
    client_name: "",
    client_number: null,
    request_details: "",
    childrenAges: [],
    meals: "",
  };

  const handleSubmit = async (values) => {
    try {
      // Extract room types (IDs) from the selected room options
      const rooms_type = values.room_type.map((r) => r.value); // This creates an array of room IDs

      // Create the formatted data object
      const formattedData = {
        // contact_person: 1,

        method_of_request: values.methodOfRequest,
        hotel: values.hotel.value, // Assuming it's already the hotel ID
        room_type: rooms_type, // Directly as an array of integers
        number_of_persons: values.number_of_persons, // Sum of adults and children
        check_in_date: values.check_in_date,
        check_out_date: values.check_out_date,
        client_name: values.client_name,
        client_number: values.client_number,
        request_details: values.request_details,
        status: "New",
        persons_data: { adults: values.adultNum, children: values.childrenAges },
        meal_plan: values.meal_plan.value,
      };

      // Debugging: Display the formatted data before sending the request
      // alert(JSON.stringify(formattedData));

      // Send the data using specialTool
      await speicalTool(formattedData); // Ensure specialTool handles the object properly
    } catch (error) {
      toast.error(error.message || "Failed to create special rate request");
    }
  };
  return isViewingSpeicalAddRequestAllowed ? (
    <section className="formPage-wrap min-h-screen">
      {errExist && validMsg && (
        <div className="w-max bg-red-100 p-2 rounded-md flex gap-4 input-par">
          <div className="flex items-center gap-2 ">
            <FaInfoCircle />

            <span className="text-xs text-red-500 ">{errMsg} </span>
          </div>
          <Link to={"/dashboard/departments-management"} target="_blank" className="updateButton ">
            Department
          </Link>
        </div>
      )}
      <h2 className="header-h2">Add Special Rate Tool Request </h2>
      <SpecialRateForm initialValues={initialValues} onSubmit={handleSubmit} isLoading={isPending} />
    </section>
  ) : (
    <NoPermission />
  );
};
export default AddSpecialRate;
