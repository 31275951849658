import {
  ALL_HOTELS_INFO_LOADING,
  GET_ALL_HOTELS_INFO,
  GET_SINGLE_HOTEL_INFO,
  GET_SINGLE_HOTEL_INFO_BY_HOTEL_ID,
  SINGLE_HOTEL_INFO_LOADING,
} from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getAllHotelsInfoSuccess(data) {
  return {
    type: GET_ALL_HOTELS_INFO,
    payload: data,
  };
}

function getSingleHotelInfoSuccess(data) {
  return {
    type: GET_SINGLE_HOTEL_INFO,
    payload: data,
  };
}

export function sendMainHotelInfo(data, callback) {
  return async function () {
    try {
      const response = await api_token(token).post(`/en/hotels/api/main-hotel-info`, data);

      if (response) {
        callback();
      }
    } catch (error) {}
  };
}

export function getAllHotelsInfo(ordering = "id", page) {
  return async function (dispatch) {
    try {
      dispatch({ type: ALL_HOTELS_INFO_LOADING });
      const response = await api_token(token).get(`/en/hotels/api/main-hotel-info`);
      dispatch(getAllHotelsInfoSuccess(response.data));
    } catch (error) {}
  };
}

export function deleteHotelInfo(id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).delete(`/en/hotels/api/main-hotel-info/${id}/`);
      if (response) {
        callback();
      }
    } catch (error) {}
  };
}

export function getSingleHotelInfo(id) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/en/hotels/api/main-hotel-info/${id}/`);
      dispatch(getSingleHotelInfoSuccess(response.data));
    } catch (error) {}
  };
}

export function updateMainHotelInfo(id, data, callback) {
  return async function () {
    try {
      const response = await api_token(token).patch(`/en/hotels/api/main-hotel-info/${id}/`, data);

      if (response) {
        callback();
      }
    } catch (error) {}
  };
}

export function getSingleHotelInfoByHotelId(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: SINGLE_HOTEL_INFO_LOADING });
      const response = await api_token(token).get(`/en/hotels/api/main-hotel-info?hotel=${id}`);

      dispatch({ type: GET_SINGLE_HOTEL_INFO_BY_HOTEL_ID, payload: response.data[0] });
    } catch (error) {}
  };
}
