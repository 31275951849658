import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import TimeRange from "../../molecules/TimeRange/TimeRange";
import DynamicFormInputText from "../../molecules/DynamicFormInputText/DynamicFormInputText";
import { getSingleHotelInfo, updateMainHotelInfo } from "../../../redux/actions/HotelInfo";
import TopBarProgress from "react-topbar-progress-indicator";
import { LiaFileContractSolid } from "react-icons/lia";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { RxUpdate } from "react-icons/rx";
import lightToast from "light-toast";
import Loading from "../Loading/Loading";

export default function EditHotelMainInfo() {
  const formRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const hotelInfo = useSelector((state) => state.singleHotelInfo);

  const [isDisabled, setIsDisabled] = useState(false);
  const [allDaysTimeRange, setAllDaysTimeRange] = useState({ from: null, to: null });
  const [sundayTimeRange, setSundayTimeRange] = useState({ from: null, to: null });
  const [mondayTimeRange, setMondayTimeRange] = useState({ from: null, to: null });
  const [tuesdayTimeRange, setTuesdayTimeRange] = useState({ from: null, to: null });
  const [wednesdayTimeRange, setWednesdayTimeRange] = useState({ from: null, to: null });
  const [thursdayTimeRange, setThursdayTimeRange] = useState({ from: null, to: null });
  const [fridayTimeRange, setFridayTimeRange] = useState({ from: null, to: null });
  const [saturdayTimeRange, setSaturdayTimeRange] = useState({ from: null, to: null });

  const telephoneNums = Math.max(Object.keys(hotelInfo.telephone_numbers).length, 1);
  const dutyNums = Math.max(Object.keys(hotelInfo.duty_manager_numbers).length, 1);
  const reservationNums = Math.max(Object.keys(hotelInfo.reservation_emails).length, 1);
  const receptionNums = Math.max(Object.keys(hotelInfo.reception_emails).length, 1);

  useEffect(() => {
    dispatch(getSingleHotelInfo(id));
  }, []);

  useEffect(() => {
    if (allDaysTimeRange.from) {
      setSundayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setMondayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setTuesdayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setWednesdayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setThursdayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setFridayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setSaturdayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
    }
    if (allDaysTimeRange.to) {
      setSundayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setMondayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setTuesdayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setWednesdayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setThursdayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setFridayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setSaturdayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
    }
  }, [allDaysTimeRange]);

  useEffect(() => {
    const form = formRef.current;
    if (!hotelInfo.default && hotelInfo.id === Number(id) && form) {
      form["telephone-numbers"].length
        ? Array.from(form["telephone-numbers"]).forEach((input, index) => {
            input.value = hotelInfo.telephone_numbers[index + 1];
          })
        : (form["telephone-numbers"].value = hotelInfo.telephone_numbers[1]);

      form["reservation-emails"].length
        ? Array.from(form["reservation-emails"]).forEach((input, index) => {
            input.value = hotelInfo.reservation_emails[index + 1];
          })
        : (form["reservation-emails"].value = hotelInfo.reservation_emails[1]);

      form["hotel-duty-num"].length
        ? Array.from(form["hotel-duty-num"]).forEach((input, index) => {
            input.value = hotelInfo.duty_manager_numbers[index + 1];
          })
        : (form["hotel-duty-num"].value = hotelInfo.duty_manager_numbers[1]);

      form["reception-emails"].length
        ? Array.from(form["reception-emails"]).forEach((input, index) => {
            input.value = hotelInfo.reception_emails[index + 1];
          })
        : (form["reception-emails"].value = hotelInfo.reception_emails[1]);

      form.notes.value = hotelInfo.same_day_reservations_notes;

      setSundayTimeRange(hotelInfo.reservations_department_working_hours.sunday);
      setMondayTimeRange(hotelInfo.reservations_department_working_hours.monday);
      setTuesdayTimeRange(hotelInfo.reservations_department_working_hours.tuesday);
      setWednesdayTimeRange(hotelInfo.reservations_department_working_hours.wednesday);
      setThursdayTimeRange(hotelInfo.reservations_department_working_hours.thursday);
      setFridayTimeRange(hotelInfo.reservations_department_working_hours.friday);
      setSaturdayTimeRange(hotelInfo.reservations_department_working_hours.saturday);
    }
  }, [hotelInfo]);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const formData = new FormData(e.target);
    const workingHours = {
      sunday: sundayTimeRange,
      monday: mondayTimeRange,
      tuesday: tuesdayTimeRange,
      wednesday: wednesdayTimeRange,
      thursday: thursdayTimeRange,
      friday: fridayTimeRange,
      saturday: saturdayTimeRange,
    };
    const data = {
      telephone_numbers: {},
      duty_manager_numbers: {},
      reservation_emails: {},
      reception_emails: {},
      same_day_reservations_notes: e.target.notes.value,
      reservations_department_working_hours: workingHours,
    };

    Array.from(formData.getAll("telephone-numbers")).forEach((elem, index) => {
      data.telephone_numbers[index + 1] = elem;
    });
    Array.from(formData.getAll("hotel-duty-num")).forEach((elem, index) => {
      data.duty_manager_numbers[index + 1] = elem;
    });
    Array.from(formData.getAll("reception-emails")).forEach((elem, index) => {
      data.reception_emails[index + 1] = elem;
    });
    Array.from(formData.getAll("reservation-emails")).forEach((elem, index) => {
      data.reservation_emails[index + 1] = elem;
    });

    dispatch(
      updateMainHotelInfo(id, data, () => {
        lightToast.success("Hotel Main Info Form was completed successfully updated");
        navigate("/dashboard/hotel-main-info");
      }),
    );
  }

  return (
    <div className="formPage-wrap">
      {!hotelInfo.default && hotelInfo.id === Number(id) ? (
        <div className="w-full">
          <div className=" flex items-center justify-center ">
            <h2 className="header-h2 ">
              <LiaFileContractSolid className="w-6 h-6" />
              Edit Hotel Main Info
            </h2>
          </div>
          <form method="post" className="form-wrap" onSubmit={handleSubmit} ref={formRef}>
            <div className="input-par">
              <div className="input-chil">
                <DynamicFormInputText
                  name="telephone-numbers"
                  text="Add Telephone Numbers"
                  inputsNum={telephoneNums}
                  type={"number"}
                />
              </div>
              <div className="input-chil">
                <DynamicFormInputText
                  name="hotel-duty-num"
                  text="Hotel Duty Manager number"
                  inputsNum={dutyNums}
                  type={"number"}
                />
              </div>
            </div>
            <div className="input-par">
              <div className="input-chil">
                <DynamicFormInputText
                  name="reservation-emails"
                  text="Add reservations Emails"
                  inputsNum={reservationNums}
                  type={"email"}
                />{" "}
              </div>
              <div className="input-chil">
                <DynamicFormInputText
                  name="reception-emails"
                  text="Add reception Emails"
                  inputsNum={receptionNums}
                  type={"email"}
                />
              </div>
            </div>
            <div className="input-par">
              <div className="input-chil">
                <div className="w-full ">
                  <div>
                    <label htmlFor="notes" className="text-slate-800 font-semibold">
                      Same Day Reservation Notes
                    </label>{" "}
                    <textarea
                      name="notes"
                      id="notes"
                      className="w-full border rounded-md p-3 focus:outline-none focus:ring focus:border-blue-300"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="input-chil"></div>
            </div>

            <h2 className="text-slate-800 font-semibold">Add Reservation Departments Working hours</h2>
            <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 border rounded-md p-3">
              <div>
                <h2>All Days</h2>
                <TimeRange timeRange={allDaysTimeRange} setTimeRange={setAllDaysTimeRange} />
              </div>
              <div>
                <h2>Sunday</h2>
                <TimeRange timeRange={sundayTimeRange} setTimeRange={setSundayTimeRange} />
              </div>
              <div>
                <h2>Monday</h2>
                <TimeRange timeRange={mondayTimeRange} setTimeRange={setMondayTimeRange} />
              </div>
              <div>
                <h2>Tuesday</h2>
                <TimeRange timeRange={tuesdayTimeRange} setTimeRange={setTuesdayTimeRange} />
              </div>

              <div>
                <h2>Wednesday</h2>
                <TimeRange timeRange={wednesdayTimeRange} setTimeRange={setWednesdayTimeRange} />
              </div>
              <div>
                <h2>Thursday</h2>
                <TimeRange timeRange={thursdayTimeRange} setTimeRange={setThursdayTimeRange} />
              </div>
              <div>
                <h2>Friday</h2>
                <TimeRange timeRange={fridayTimeRange} setTimeRange={setFridayTimeRange} />
              </div>
              <div>
                <h2>Saturday</h2>
                <TimeRange timeRange={saturdayTimeRange} setTimeRange={setSaturdayTimeRange} />
              </div>
            </div>
            <br />

            <div className="w-full flex justify-center items-center  mt-6">
              <button type="submit" disabled={isDisabled} className="updateButton">
                Update
                {isDisabled ? (
                  <>
                    {" "}
                    <TopBarProgress /> <ButtonLoading />
                  </>
                ) : (
                  <RxUpdate className="w-5 h-5" />
                )}
              </button>
            </div>
            {/* 
            {isDisabled ? (
              <>
                <TopBarProgress />
                <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                  <HashLoader size={30} color="white" />
                  <div className="text-white font-bold my-2">Submitting...</div>
                </div>
              </>
            ) : null} */}
          </form>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
