import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { FaWpforms } from "react-icons/fa6";
import cookie from "react-cookies";
import { getCurrencyAction, getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import { createHotelPaymentOrder } from "../../../redux/actions/hotelPayments";
import toast from "react-hot-toast";
import TopBarProgress from "react-topbar-progress-indicator";
import HashLoader from "react-spinners/HashLoader";
import Loading from "../Loading/Loading";

const token = cookie.load("access_token");

export default function PaymnetLink() {
  const { short_id, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //states and reducers
  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const [chosenCurrency, setChosenCurrency] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  // form arrays
  const currencyListArr = currencyList ? currencyList.map((currency) => ({ label: currency, value: currency })) : [];
  let hotel = null;
  if (Object.keys(selectedSingleRoom).length > 0) {
    hotel = { label: selectedSingleRoom.hotel, value: selectedSingleRoom.hotel_id };
  }

  useEffect(() => {
    dispatch(getSelectedBookedRoomSingleAction(token, id, () => setLoading(false)));
    dispatch(getCurrencyAction(token));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const data = {
      amount: e.target.amount.value,
      currency_type: chosenCurrency.value,
      last_date_for_payment: e.target.payment_deadline.value,
      notes: e.target.notes.value,
      hotel: hotel.value,
      booking: id,
      booking_short_id: short_id,
    };
    data.method_payment = "payment_link";
    data.method_payment_detail = {
      payment_link: e.target.payment_link.value,
    };

    dispatch(
      createHotelPaymentOrder(data, () => {
        setIsDisabled(false);
        toast.success("Your data was submitted successfully");
      })
    );
  }

  return (
    <div className="w-full">
      {loading ? (
        <Loading />
      ) : (
        <div className="w-[80%]  border-gray-300 border rounded-lg p-5 my-3 mx-auto">
          <div className="flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
            <p className="flex items-center gap-x-3  text-md">
              <FaWpforms className="w-5 h-5" />
              Payment Link
            </p>
          </div>
          <form className="mt-2 w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <label className="flex text-gray-600 ">Amount</label>
              <input
                type="amount"
                className="w-full h-10 border rounded-lg bg-white px-2"
                name="amount"
                placeholder="Amount"
                required
              />

              <label className="flex text-gray-600 ">Currency</label>
              <Select
                className="w-full h-10 border rounded-lg bg-white"
                options={currencyListArr}
                value={chosenCurrency}
                onChange={(e) => setChosenCurrency(e)}
                required
              />

              <label className="flex text-gray-600 ">Payment Deadline</label>
              <input
                type="date"
                className="w-full h-10 border rounded-lg bg-white px-2"
                name="payment_deadline"
                required
              />

              <label className="flex text-gray-600 ">Hotel Name</label>
              <Select
                className="w-full h-10 border rounded-lg bg-white "
                options={hotel ? [hotel] : []}
                value={hotel}
                disabled={true}
              />

              <label className="flex text-gray-600 ">Payment Link</label>
              <input
                type="text"
                className="w-full h-10 border rounded-lg bg-white px-2"
                name="payment_link"
                placeholder="Payment Link"
                required
              />

              <label className="flex text-gray-600 ">Notes</label>
              <textarea
                name="notes"
                cols="30"
                rows="10"
                className="w-full h-20 border rounded-lg bg-white px-2"
              ></textarea>

              <button
                type="Submit"
                disabled={isDisabled}
                className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Submit
              </button>
            </div>
            {isDisabled ? (
              <>
                <TopBarProgress />
                <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                  <HashLoader size={30} color="white" />
                  <div className="text-white font-bold my-2">Submitting...</div>
                </div>
              </>
            ) : null}
          </form>
        </div>
      )}
    </div>
  );
}
