import React from "react";
import { Form, Formik } from "formik";
import { FormSection, FormTextInput } from "../../../../customComponents/FormComponents";
import { FcDepartment } from "react-icons/fc";
import { Button } from "flowbite-react";
import * as Yup from "yup";
import UsersSearchTextInput from "./Components/UsersSearchTextInput";
import GroupsSelector from "./Components/GroupsSelector";
import SelectedUsers from "./Components/SelectedUsers";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  users: Yup.array().required("Users are required").min(1, "At least one user is required"),
});

const DepartmentForm = ({ initialValues, handleSubmit, submitButtonText, title, isLoading }) => {
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {() => {
        return (
          <Form className="mb-4 min-h-[100dvh]">
            <FormSection title={title}>
              <FormTextInput name="name" label="Name" Icon={FcDepartment} placeholder="Enter Department Name" />
              <GroupsSelector />
              <UsersSearchTextInput />
              <SelectedUsers />
              <Button isProcessing={isLoading} color={"success"} type="submit" className="mt-4 mx-auto">
                {submitButtonText}
              </Button>
            </FormSection>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DepartmentForm;
