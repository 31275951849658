import api_token from "../../api/UserApi";
import {
  GET_CURRENCY_MID_NUMBER,
  GET_CURRENCY_MID_NUMBER_FLAG,
  GET_MID_NUMBER,
  GET_SELECT_POS_VISA,
  POST_POSVISA_FLAG,
  DELETE_POS_VISA_FLAG,
  UPDATE_POS_VISA_FLAG,
  GET_FLAG_POS_VISA,
  GET_POS_VISA_FLAG,
} from "../types/types";

export const getMidNumber = (token, signal) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/visa-machines-mid-num/`, { signal });
      dispatch({
        type: GET_MID_NUMBER,
        payload: response.data,
      });
    } catch (error) {}
  };
};

export const getCurrencyMidNumber = (token, midNumber, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CURRENCY_MID_NUMBER_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`accounting/api/v1/get-currency-bank/${midNumber}/`);
      if (response.status === 200) {
        dispatch({
          type: GET_CURRENCY_MID_NUMBER_FLAG,
          payload: false,
        });
      }
      dispatch({
        type: GET_CURRENCY_MID_NUMBER,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const postPosVisaAAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: POST_POSVISA_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`accounting/api/v1/pos-machines/`, data);
      if (response.status === 201) {
        dispatch({
          type: POST_POSVISA_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const getSelectedPosVisa = (token, id, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_POS_VISA_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`accounting/api/v1/pos-machines/${id}/`, { signal });
      dispatch({
        type: GET_SELECT_POS_VISA,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_POS_VISA_FLAG,
          payload: false,
        });
      }
      callback(response);
    } catch (error) {}
  };
};

export const deletePosVisaAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_POS_VISA_FLAG,
        payload: true,
      });
      const response = await api_token(token).delete(`accounting/api/v1/pos-machines/${id}/`);
      if (response.status === 204) {
        dispatch({
          type: DELETE_POS_VISA_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const updatePosVisaAction = (token, id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_POS_VISA_FLAG,
        payload: true,
      });
      const response = await api_token(token).patch(`accounting/api/v1/pos-machines/${id}/`, data);
      if (response.status === 200) {
        dispatch({
          type: UPDATE_POS_VISA_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
