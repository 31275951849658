import { COLLECTION_FLAG , GET_ALL_COLLECTION , GET_ALL_COLLECTION_FLAG , DELETE_COLLECTION  , UPDATE_COLLECTION_FLAG} from "../types/types";

const initialState = {
    collectionFlag : false,
    collectionData:null,
    collectionDataFlag:false,
    collectionDeleteFlag:false,
    updateCollectionFlag:false
}
export const collectionReducer = (state = initialState , action) => {
    switch(action.type)
    {
         case COLLECTION_FLAG : 
               return{...state , collectionFlag : action.payload}
        case GET_ALL_COLLECTION : 
               return{...state , collectionData : action.payload}
        case GET_ALL_COLLECTION_FLAG : 
               return{...state , collectionDataFlag : action.payload}
        case UPDATE_COLLECTION_FLAG : 
               return{...state , updateCollectionFlag : action.payload}
        case DELETE_COLLECTION : 
               return{...state , collectionDeleteFlag : action.payload}
         default:
               return state;
    }
}