// src/ImageSorter.js
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Button, CircularProgress, FilledInput } from "@mui/material";
import { MdCloudUpload } from "react-icons/md";
import { BsStars } from "react-icons/bs";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import mutateDataQuery from "../../../react_query/mutateDataQuery";

const AddBanner = () => {
  const [images, setImages] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);
  const navigate = useNavigate();

  const { mutate, isError, isPending } = useMutation({
    mutationFn: (newValues) =>
      mutateDataQuery(`/en/site-settings/api/v1/search-slider-images/upload_multiple/`, "post", newValues),
    onError: (error) => {
      // An error happened!
      toast.error(`${error?.message || "failed post data"}  `);
    },
    onSuccess: (result) => {
      // Boom baby!
      toast.success("banner is created successfully");
      navigate(`/`);
    },
  });

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files
      .map((file, index) => {
        if (file.size > 2 * 1024 * 1024) {
          // 1MB = 1 * 1024 * 1024 bytes
          toast.warning(`File ${file.name} is too large. Maximum file size is 2MB.`);
          return null;
        }
        return {
          file,
          url: URL.createObjectURL(file),
          sortOrder: images.length + index + 1, // default sort order
        };
      })
      .filter((img) => img !== null);

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleSortOrderChange = (e, index) => {
    const newSortOrder = parseInt(e.target.value);
    const newImages = images.map((img, imgIndex) => (imgIndex === index ? { ...img, sortOrder: newSortOrder } : img));
    setImages(newImages);
  };

  const deleteImage = (index) => {
    const newImages = images.filter((_, imgIndex) => imgIndex !== index);
    setImages(newImages);
  };

  const sortImages = () => {
    const sorted = [...images].sort((a, b) => a.sortOrder - b.sortOrder);
    setSortedImages(sorted);
  };

  const handleUpload = () => {
    const formData = new FormData();

    sortedImages.forEach((image, index) => {
      if (image.file) {
        formData.append(`images`, image.file);
      } else {
        formData.append(`images`, image.url);
      }
      formData.append(`orders`, image.sortOrder);
    });

    mutate(formData);
  };

  return (
    <div className="">
      <p className=" text-base text-green-600 font-semibold text-center">
        To get the best look, width 712px height 267px
      </p>

      <p className=" text-base text-red-600 font-semibold text-center">Maximum file size is 2MB.</p>
      <div className="extraOutline p-4   bg-whtie m-auto rounded-lg flex justify-center w-full">
        <div className="file_upload p-5 relative flex flex-col items-center border-4 border-dotted md:w-[30%] w-full border-gray-300 rounded-lg ">
          <svg
            className="text-[#002244] md:w-[30%] w-[30%] mb-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <div className="input_field flex flex-col w-max mx-auto text-center">
            <label>
              <input className="text-sm cursor-pointer w-36 hidden" type="file" multiple onChange={handleFileChange} />
              <div className="text bg-sky-900 text-white border border-gray-300 rounded font-semibold cursor-pointer p-1 px-8 hover:bg-[#002244]">
                Select Images
              </div>
            </label>

            {/* <div class="title text-[#002244] uppercase">or drop files here</div> */}
          </div>
        </div>
      </div>

      <div className=" flex flex-col items-center w-full  p-3">
        <div>
          {images.map((image, index) => (
            <div
              className="flex gap-5"
              key={index}
              style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
            >
              <img src={image.url} alt={`Uploaded #${index}`} className=" rounded-md h-[115px] w-[115px] " />
              <FilledInput
                type="number"
                value={image.sortOrder}
                onChange={(e) => handleSortOrderChange(e, index)}
                className="!h-10"
                style={{ marginLeft: "10px" }}
              />
              <IconButton onClick={() => deleteImage(index)} style={{ marginLeft: "10px" }}>
                <DeleteIcon color="error" />
              </IconButton>
            </div>
          ))}
        </div>
        {images?.length > 0 && (
          <Button variant="contained" className="!bg-emerald-800 !mt-5" onClick={sortImages}>
            Sort Images
          </Button>
        )}
      </div>

      {sortedImages?.length > 0 && (
        <>
          <div className=" flex flex-col items-center w-full  mt-10 p-2">
            <h2 className=" text-2xl font-bold text-cyan-800 mb-10 flex gap-1 ">
              <BsStars className=" text-amber-400" />
              Sorted Images
            </h2>
            <div className=" flex flex-wrap justify-center gap-5 ">
              {sortedImages.map((image, index) => (
                <img
                  key={index}
                  src={image.url}
                  alt={`Sorted #${index}`}
                  width={100}
                  className="mb-10 md:w-[200px] md:h-[200px] w-[115px] h-w-[115px] rounded-md "
                />
              ))}
            </div>
          </div>
          <div className="flex justify-center w-full mb-5">
            <Button
              onClick={handleUpload}
              variant="contained"
              className="!bg-sky-900 !mt-5"
              startIcon={<MdCloudUpload />}
              disabled={isPending}
            >
              {isPending ? <CircularProgress size={24} color="inherit" /> : "Submit Images Slider"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddBanner;
