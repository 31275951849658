import React, { useState } from "react";
import "./HotelDetailDynamicCard.scss";
import Map from "google-map-react";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Loading from "../../organisms/Loading/Loading";
import { IoLocationSharp, IoShareSocial } from "react-icons/io5";
import { MdOutlineRestaurant } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { RiMailSendLine, RiSecurePaymentLine } from "react-icons/ri";
import { VscMailRead } from "react-icons/vsc";
import { IoMdWifi } from "react-icons/io";
import { LiaHotelSolid } from "react-icons/lia";
import { GiRoad } from "react-icons/gi";
import { FiPlusCircle } from "react-icons/fi";
// options for the splide component
const splideOptionsMultiForms = {
  perPage: 1,
  perMove: 1,
  rewind: false,
  arrows: true,
  keyboard: "global",
  gap: "2rem",
  pagination: false,
  padding: "0",
};

export default function HotelDetailDynamicCard({ hotel }) {
  const Marker = ({ svg }) => svg;
  const [mapLoading, setMapLoading] = useState(true);
  const [chosen, setChosen] = useState({
    video: false,
    videoClass: "",
    services: true,
    servicesClass: "services",
    map: false,
    mapClass: "",
  });

  function chooseVideo() {
    setChosen({ video: true, videoClass: "video", services: false, servicesClass: "", map: false, mapClass: "" });
  }

  function chooseServices() {
    setChosen({ video: false, videoClass: "", services: true, servicesClass: "services", map: false, mapClass: "" });
  }

  function chooseMap() {
    setChosen({ video: false, videoClass: "", services: false, servicesClass: "", map: true, mapClass: "map" });
  }

  function handleLoad() {
    setMapLoading(false);
  }

  return (
    <div className="hotelDetailDynamicCard border">
      <div className="chooseHeaders">
        <h1 className={chosen.servicesClass} onClick={chooseServices}>
          Services & Facilities
        </h1>
        <h1 className={chosen.videoClass} onClick={chooseVideo}>
          Videos
        </h1>

        <h1 className={chosen.mapClass} onClick={chooseMap}>
          Map
        </h1>
      </div>
      {chosen.video && (
        <Splide options={splideOptionsMultiForms}>
          {hotel.videos.map((video, index) => {
            return (
              <SplideSlide key={index} className="mb-0.5">
                <video src={`${video.video_url}`} className="block w-full lg:h-[500px] rounded-lg" controls />
              </SplideSlide>
            );
          })}
        </Splide>
      )}
      {chosen.services && (
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="w-full  font-light">
                  <tbody>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <IoLocationSharp className="w-5 h-5 " /> Location
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">{`${hotel.area}, ${hotel.city}, ${hotel._country_name}`}</td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <IoShareSocial className="w-5 h-5 text-[#002244]" /> Features
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize overflow-x-auto">
                        <ul className="flex items-center gap-x-1">
                          {hotel.features.map((feature, index) => (
                            <li key={index}>
                              {feature.feature_name} {index + 1 === hotel.features.length - 1 && <span> /</span>}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    {hotel.extra_info.length > 0 && (
                      <tr className="border-b border-primary">
                        <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                          <FiPlusCircle className="w-5 h-5 text-[#002244]" /> Extra Info
                        </td>
                        <td className="px-6 py-4 font-semibold text-gray-600 capitalize  overflow-x-auto ">
                          <ul className="flex items-center gap-x-1">
                            {hotel.extra_info.map((info, index) => (
                              <li key={index}>
                                {info.name} {index + 1 === hotel.extra_info.length - 1 && <span>/</span>}
                              </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    )}
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <MdOutlineRestaurant className="w-5 h-5" /> Restaurants
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">
                        <ul>
                          {Object.values(hotel.restaurants).map((restaurant, index) => (
                            <li key={index}>{restaurant}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <FaPhoneAlt className="w-5 h-5" /> Phone Numbers
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">
                        <ul>
                          {Object.values(hotel.telephone).map((number, index) => (
                            <li key={index}>{number}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <FaPhoneVolume className="w-5 h-5" /> Reservation Numbers
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">
                        <ul>
                          {Object.values(hotel.reservation_telephone).map((number, index) => (
                            <li key={index}>{number}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <RiMailSendLine className="w-5 h-5" /> Reservation Mails
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">
                        <ul>
                          {Object.values(hotel.reservation_email).map((email, index) => (
                            <li key={index}>{email}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <VscMailRead className="w-5 h-5" />
                        Reception Mails
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">
                        <ul>
                          {Object.values(hotel.reception_email).map((email, index) => (
                            <li key={index}>{email}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <RiSecurePaymentLine className="w-5 h-5" /> Payment Type
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">{hotel.payment_type}</td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <IoMdWifi className="w-5 h-5" />
                        Internet
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">{hotel.internet}</td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <LiaHotelSolid className="w-5 h-5" /> Arabic Name
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">{hotel.name_ar}</td>
                    </tr>
                    <tr className="border-b border-primary">
                      <td className="flex items-center gap-x-2 px-6 py-4 font-medium bg-[#002244] bg-opacity-5  text-[#002244]">
                        <GiRoad className="w-5 h-5" /> Arabic Address
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-600 capitalize">{hotel.address_ar}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {chosen.map && (
        <div style={{ width: "100%", height: "500px" }}>
          {mapLoading && <Loading />}
          {hotel.hotel_map_address}
          <br />
          <Map
            bootstrapURLKeys={{
              key: "AIzaSyBP17fEEeHvC5egLp88y-vMpbavGvrnQcU",
              libraries: ["places"],
            }}
            defaultCenter={{ lat: Number(hotel.latitude), lng: Number(hotel.longitude) }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={handleLoad}
          >
            <Marker
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: "15px" }}>
                  <title>map-marker</title>
                  <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                </svg>
              }
            />
          </Map>
        </div>
      )}
    </div>
  );
}
