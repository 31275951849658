import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import { FieldErrorText, FormLabel } from "../../../../../customComponents/FormComponents";
import Select from "react-select";

function GroupsSelector() {
  const { values, setFieldValue } = useFormikContext();
  const { users } = values;

  const {
    isLoading: isFetchingGroups,
    data: groups,
    isError: errorFetchingGroups,
    isSuccess,
  } = useQuery({
    queryKey: ["groups"],
    queryFn: async () => {
      return fetchDataQuery("/permissions/api/v1/user-groups/");
    },
  });

  useEffect(() => {
    const userGroupIds = new Set(users?.flatMap((user) => user.groups));

    const selectedGroups = groups?.filter((group) => userGroupIds?.has(group.id));

    setFieldValue("groups", selectedGroups);
  }, [groups, isSuccess, setFieldValue]);

  return (
    <>
      <FormLabel label={"Choose groups"} htmlFor={"group"} />
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            marginTop: "4px !important",
          }),
        }}
        isDisabled={isFetchingGroups}
        onChange={(selectedOptions, actionMeta) => {
          if (actionMeta.action === "clear") {
            setFieldValue("groups", []);
          }

          if (actionMeta.action === "select-option") {
            const value = actionMeta.option;
            setFieldValue("groups", [
              ...values.groups,
              {
                id: value.value,
                name: value.label,
              },
            ]);
          }

          if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
            const value = actionMeta.removedValue.value;
            setFieldValue(
              "groups",
              values.groups.filter((group) => group.id !== value),
            );
          }
        }}
        options={groups?.map((group) => ({
          value: group.id,
          label: group.name,
        }))}
        isMulti
        name={"groups"}
        value={values.groups?.map((group) => ({
          value: group.id,
          label: group.name,
        }))}
      />
      {errorFetchingGroups && <FieldErrorText message={"Error fetching groups"} />}
    </>
  );
}

export default GroupsSelector;
