import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import Select from "react-select";
import { useFormik } from "formik";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../ButtonLoading/ButtonLoading";

import { FaHotel, FaMoneyBillWave, FaWpforms } from "react-icons/fa";
import { IoLink, IoLocationOutline, IoPersonAdd, IoPersonRemove, IoTimeOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getNationalityAction } from "../../../redux/actions/searchformAction";
import { TimePicker } from "antd";
import Loading from "../Loading/Loading";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import { getAllAssistAction } from "../../../redux/actions/meetAssistAction";
import {
  getAllAirline,
  getHotelsByCity,
  getIataCodeAction,
  getselectedAirportaction,
  updateAirportAction,
} from "../../../redux/actions/airportServiceAction";
import lightToast from "light-toast";
import useData from "../../../customHooks/useData";
import dayjs from "dayjs";
import { RxUpdate } from "react-icons/rx";
import { getCurrencyTypes_URL } from "../../../urls";
import { MdOutlineCurrencyExchange, MdOutlineLocalAirport } from "react-icons/md";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import usePermissions from "../../../customHooks/usePermissions";
import { SiEthiopianairlines, SiIata } from "react-icons/si";
import { GiWorld } from "react-icons/gi";
import { BiSolidCity, BiStreetView } from "react-icons/bi";
import { TbBus, TbRoad } from "react-icons/tb";
import { GoPerson } from "react-icons/go";
import { CiCalendar } from "react-icons/ci";
import { TfiWorld } from "react-icons/tfi";
import { RiFlightTakeoffLine } from "react-icons/ri";
import { IoIosSend, IoMdCloseCircleOutline, IoMdInformationCircleOutline } from "react-icons/io";
import { SlPeople } from "react-icons/sl";
import { CgNotes } from "react-icons/cg";
import { FaPerson } from "react-icons/fa6";
import FileUploader from "../FileUploader/FileUploader";
import { toast } from "react-toastify";

const UpdateAirportService = ({ general }) => {
  const [hotels, setHotels] = useState();
  const [hotelsList, setHotelsList] = useState();
  const [hotelFlag, setHotelFlag] = useState();
  const [airlineName, setAirlineName] = useState();
  const [airlineID, setAirlineID] = useState();
  const [ticketCopyUrls, setTicketCopyUrls] = useState([]);
  const [airLines, setAirlines] = useState();
  // const [airlineId, setAirlineId] = useState();
  const [members, setMembers] = useState();
  const [dataFlag, setDataFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comingFlag, setComingFlag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [cities, setCities] = useState();
  const [iataData, setiataData] = useState();
  const [iataFlag, setIataFlag] = useState();
  const [iataError, setIataError] = useState();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const { nationalities } = useSelector((state) => state.SearchFormReducer);
  const format = "HH:mm";
  useRemoveScroll();

  function closeModal() {
    setIsOpen(false);
  }

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      service_type: "",
      passengers_name: [""],
      booking_order: general ? null : "",
      booking_id: general ? null : params.id,
      flight_date: "",
      flight_number: "",
      nationality: [],
      time_of_arrival: "",
      rep_attendance_date: "",
      rep_attendance_time: "",
      iata_code: "",
      country_from: "",
      city_from: "",
      area_from: "",
      airport_name: "",
      number_of_pax: "",
      airline_name: "",
      hotel_name: "",
      hotel_name_other: "",
      address: "",
      order_type: "",
      amount: "",
      currency: "",
      // airport_sign: "",
      transfer_from_airport: "",
      notes: "",
      status: "New",
      representative_name: "",
      representative_notes: "",
      ticket_copy: [],
    },
    validationSchema: Yup.object({
      service_type: Yup.string().required("Please enter service type"),
      passengers_name: Yup.array().of(
        Yup.string()
          .matches(/^\S.*\S$/, "Name Must Have Not Spaces At The Start And End")
          .required("Please enter passenger name"),
      ),
      flight_number: Yup.string().required("Please enter flight number"),
      nationality: Yup.array().min(1, "Select at least one nationality"),
      time_of_arrival: Yup.string().required("Please enter time "),
      rep_attendance_time: Yup.string().when("service_type", {
        is: (val) => val === "Departure",
        then: (schema) => schema.required("Please enter time"),
        otherwise: (schema) => schema.notRequired(),
      }),
      rep_attendance_date: Yup.date().when("service_type", {
        is: (val) => val === "Departure",
        then: (schema) => schema.required("Please enter date"),
        otherwise: (schema) => schema.notRequired(),
      }),
      iata_code: Yup.string().required("Please enter IATA Code " || iataError),
      country_from: Yup.string().required("Please enter country name "),
      city_from: Yup.string().required("Please enter region name "),
      area_from: Yup.string().required("Please enter area name "),
      airport_name: Yup.string().required("Please enter airport name "),
      number_of_pax: Yup.number()
        .required("Please enter number of persons")
        .positive("Number of persons must be positive number")
        .integer("Number of persons must be positive number")
        .min(1, "Number of persons must be greater than  0"),
      airline_name: Yup.string().required("Please enter airline name "),
      // address: Yup.string().required("Please enter an address"),
      order_type: Yup.string().required("Please enter order type "),
      transfer_from_airport: Yup.bool().required("Please enter transfer from airport"),
      notes: Yup.string().required("Please enter your notes"),
      status: Yup.string().required("Please enter status "),
      // representative_name: Yup.string().required("Please enter representative name "),
      representative_notes: Yup.string().required("Please enter your representative notes"),
      ticket_copy: Yup.array(),

      hotel_name: Yup.string().when("transfer_from_airport", {
        is: true,
        then: () => Yup.string().required("Please enter hotel"),
        otherwise: () => Yup.string().notRequired(),
      }),

      hotel_name_other: Yup.string().when(["transfer_from_airport", "hotel_name"], {
        is: (transfer_from_airport, hotel_name) => {
          return transfer_from_airport && hotel_name === "Other";
        },
        then: () => Yup.string().required("Please enter hotel"),
        otherwise: () => Yup.string().notRequired(),
      }),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      const {
        airline_name,
        hotel_name,
        flight_date,
        time_of_arrival,
        ticket_copy,
        rep_attendance_time,
        rep_attendance_date,
        ...restData
      } = values;
      if (hotel_name === "Other" || values.transfer_from_airport === "false") {
        formData.delete("hotel_name");
      } else {
        formData.append("hotel_name", hotel_name);
      }
      formData.append("airline_name", values.airline_name);
      Object.keys(restData).forEach((key) => {
        if (key === "passengers_name" || key === "nationality") {
          formData.append(key, JSON.stringify(restData[key]));
        } else {
          formData.append(key, restData[key]);
        }
      });
      formData.append("time_of_arrival", `${flight_date},${time_of_arrival}`);
      // if (!restData.ticket_copy) {
      //   formData.delete("ticket_copy");
      // }

      if (ticket_copy.length > 0) {
        for (const file of ticket_copy) {
          if (!file.valid) {
            toast.error(`Image ${file.name} is not valid because ${file.errors[0]}`);
            setSubmitting(false);
            return;
          }
        }

        ticket_copy.forEach((file) => {
          formData.append("ticket_copy", file.file);
        });
      } else {
        formData.append("ticket_copy", "");
      }

      if (general) {
        formData.delete("booking_order");
        formData.delete("booking_id");
      }
      formData.delete("deleted_at");

      if (values.service_type === "Departure") {
        formData.append("representative_attendance_time", `${rep_attendance_date},${rep_attendance_time}`);
      }

      dispatch(
        updateAirportAction(formData, params.id, (result) => {
          if (result.status === 200) {
            lightToast.success("Airport Service Form was completed updated successfully");
            navigate(`/dashboard/meet-&-assist-service/all-meet-service/${params.id}/preview`);
            // navigate(-1);
          } else {
            setSubmitting(false);
          }
        }),
      );
    },
  });

  //////////Currency///////////////////
  const currencies = useData(getCurrencyTypes_URL);
  let currenciesArr = currencies ? currencies.map((currency) => ({ value: currency, label: currency })) : [];
  //////////////////////////////////
  useEffect(() => {
    //////////handle when reload page , alert show to make sure this i want reload ////////////
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ///////////////////////////////////////////////////////////
    dispatch(getNationalityAction(token));

    dispatch(
      getAllAssistAction(signal, (result) => {
        if (result.status === 200) {
          setMembers(result.data);
        }
      }),
    );

    dispatch(
      getselectedAirportaction(params.id, signal, (result) => {
        if (result.status === 200) {
          setDataFlag(true);
          Object.keys(result?.data).map((key) => {
            if (key === "ticket_copy") {
              if (result?.data[key]) {
                setTicketCopyUrls([result.data[key]]);
              }
            } else if (key === "time_of_arrival") {
              setFieldValue("flight_date", result.data[key]?.slice(0, 10));
              setFieldValue("time_of_arrival", result.data[key]?.slice(11, 19));
            } else if (key === "representative_attendance_time") {
              setFieldValue("rep_attendance_date", result.data[key]?.slice(0, 10));
              setFieldValue("rep_attendance_time", result.data[key]?.slice(11, 19));
            } else if (key === "representative_name") {
              if (result?.data[key]?.id) {
                setFieldValue("representative_name", result?.data[key]?.id);
              }
            } else if (key === "transfer_from_airport") {
              setFieldValue("transfer_from_airport", result?.data[key]?.toString());
            } else if (key === "hotel_name_other") {
              if (result.data[key]) {
                setFieldValue("hotel_name", "Other");
                setFieldValue("hotel_name_other", result?.data[key]);
              }
            } else if (key === "airline_name") {
              setFieldValue("airline_name", result?.data[key].id);
              setAirlineName(result?.data[key].name);
              setAirlineID(result?.data[key].id);
            } else {
              setFieldValue(key, result?.data[key]);
            }
          });
          dispatch(
            getHotelsByCity(922, (resultHotel) => {
              if (resultHotel.status === 200) {
                setHotels(resultHotel?.data);
              }
            }),
          );
          setLoading(true);
          // dispatch(
          //   getData(
          //     `/en/hotels/api/v1/get-cities/${result.data.country_flags_from_id}/`,
          //     (resp) => {
          //       if (resp.status === 200) {
          //         // toast.error("This Country has no Cities in the system Please choose another one");
          //         setCities(resp.data);
          //       }

          //       setLoading(false);
          //     },
          //     (err) => {
          //       // toast.error("there was an error");
          //       setLoading(false);
          //     }
          //   )
          // );
        }
      }),
    );
    dispatch(
      getAllAirline(signal, (result) => {
        if (result.status === 200) {
          setAirlines(result.data);
        }
      }),
    );

    return () => {
      controller.abort();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  ////////// Handle Add ////////////
  const handleInput = (valueInput, index) => {
    const updateValue = [...values.passengers_name];
    updateValue[index] = valueInput;
    setFieldValue("passengers_name", updateValue);
  };
  //////Handle Delete /////////////
  const handleAdd = () => {
    setFieldValue("passengers_name", [...values.passengers_name, ""]);
  };
  const handleDelete = (index) => {
    if (values.passengers_name.length > 1) {
      const newValue = [...values.passengers_name];
      newValue.splice(index, 1);
      setFieldValue("passengers_name", newValue);
    }
  };

  //////// flagData /////////////
  let flagData = nationalities
    ? nationalities.map((item) => {
        return { value: item.name_en, label: item.name, image: item.flag };
      })
    : [];
  ////////////// Default flag ///////////////
  let flagDataDefault = values.nationality
    ? values.nationality.map((item) => {
        return { value: item, label: item };
      })
    : [];

  ////////// destination /////////
  const citiesArr = cities ? cities.map((city) => ({ label: city.name, value: city.id })) : [];

  //////////// Hotels //////////////
  //   const hotels = useHotelNames();
  const hotelsArr = hotels
    ? hotels.map((hotel) => ({
        label: hotel.name,
        value: hotel.id,
        address: hotel.address,
        map_address: hotel.hotel_map_address,
      }))
    : [];

  //////////offer Type///////

  let orderTypeArr = [
    { label: "Company", value: "Company" },
    { label: "Private", value: "Private" },
  ];
  ///////// status ////////////
  let statusArr = [
    { label: "New", value: "New" },
    { label: "Pending", value: "Pending" },
    { label: "Done", value: "Done" },
  ];
  //////// representative_name/////////
  let membersArr = members
    ? members.map((item) => {
        return {
          label: item.representative_name,
          value: item.id,
        };
      })
    : [];
  ////////Airline //////////
  let airlinesArr = airLines
    ? airLines.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      })
    : [];

  /////////////////////////////////////
  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();

    return `${hours1}:${minutes1}`;
  };

  // PERMISSION

  const [permissions, permissionsFlag] = usePermissions(["airport_services.change_airportlimousineservice"]);

  //Select Style
  //Style
  const selectStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,

      borderBottom: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      boxShadow: "none",
      borderRadius: "0",
    }),
  };

  return (
    <div className="formPage-wrap min-h-screen ">
      {hotels && members && dataFlag && nationalities && airLines && permissionsFlag ? (
        permissions?.some((item) => item.value) && (
          <div className="flex flex-col items-center justify-start h-full w-full">
            <div className={`  w-full `}>
              <div className=" flex justify-center  ">
                <p className="header-h2">
                  {" "}
                  <FaWpforms className="md:w-5 md:h-5  w-4 h-4" />
                  Update Meet & Assist Service Form{" "}
                </p>
              </div>

              <form className="form-wrap  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                <div className="w-full flex flex-col justify-center items-center gap-y-5">
                  <div className="w-full flex flex-col items-center justify-center gap-y-2 ">
                    <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[17px] ">
                      Service Type{" "}
                    </label>
                    <div
                      className={` w-full px-3 h-10 flex items-center justify-center gap-x-1 md:justify-between  border rounded-lg bg-white floating-input text-xs md:text-base ${
                        touched.service_type && errors.service_type && "border border-red-800"
                      } `}
                    >
                      <TbBus className="w-5 h-5 text-[#002244]" />

                      <div className="flex items-center justify-center gap-x-3 w-full md:w-[50%]">
                        <label className="  text-gray-600    md:w-16 text-sm md:text-lg font-semibold ">Arrival</label>
                        <input
                          name="service_type "
                          type="radio"
                          value="Arrival"
                          defaultChecked={values.service_type === "Arrival"}
                          onChange={(e) => {
                            setFieldValue("service_type", "Arrival");
                          }}
                          onBlur={handleBlur}
                          className=" w-3 h-3 md:w-5 md:h-5  border rounded-lg bg-white "
                        />
                      </div>
                      <div className="flex items-center justify-center gap-x-3 w-full md:w-[50%]">
                        <label className="text-gray-600  md:w-20 text-sm md:text-lg font-semibold">Departure</label>
                        <input
                          name="service_type "
                          type="radio"
                          value="Departure"
                          defaultChecked={values.service_type === "Departure"}
                          onChange={(e) => {
                            setFieldValue("service_type", "Departure");
                          }}
                          onBlur={handleBlur}
                          className=" w-3 h-3 md:w-5 md:h-5  border rounded-lg bg-white  "
                        />
                      </div>
                    </div>{" "}
                    {errors.service_type && touched.service_type && (
                      <div className="w-full flex justify-start capitalize text-xs md:text-sm text-red-800 font-semibold">
                        {errors.service_type}
                      </div>
                    )}
                  </div>
                  <div className="w-full flex flex-col items-start justify-center gap-y-2 ">
                    <label className=" text-center w-full text-gray-700 font-semibold text-sm md:text-[16px] ">
                      Passenger Name
                    </label>
                    <div className="w-full flex flex-col border rounded-md p-1">
                      {values?.passengers_name.length > 0 &&
                        values.passengers_name.map((item, index) => {
                          return (
                            <div className="w-full flex flex-col gap-y-10 my-1 ">
                              <div className="w-full flex flex-col gap-y-1">
                                <div className="w-full flex items-center gap-x-1 relative">
                                  <input
                                    placeholder={`Passenger Name ${index + 1}`}
                                    name={`passengers_name`}
                                    type="text"
                                    onChange={(e) => handleInput(e.target.value, index)}
                                    value={item}
                                    onBlur={handleBlur}
                                    className={` w-full pl-7 md:pl-10 h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                                      touched.passengers_name && errors.passengers_name && "border border-red-800"
                                    } `}
                                  />
                                  <div className="absolute pl-2">
                                    <GoPerson className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                                  </div>
                                </div>
                                <div className="w-full flex justify-between ">
                                  {touched.passengers_name &&
                                  Array.isArray(errors.passengers_name) &&
                                  errors.passengers_name.length > 0 ? (
                                    <div className="w-full flex items-center gap-x-1 justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                                      {errors.passengers_name[index]}
                                    </div>
                                  ) : null}
                                  <div className=" w-full flex items-center justify-end gap-x-1">
                                    <button type="button" onClick={handleAdd}>
                                      <IoPersonAdd className="md:w-5 md:h-5  w-4 h-4 text-green-900 hover:scale-105 active:scale-95 duration-300 transition-all" />
                                    </button>
                                    {values.passengers_name.length > 1 && (
                                      <button type="button" onClick={() => handleDelete(index)}>
                                        <IoPersonRemove className="md:w-5 md:h-5  w-4 h-4 text-red-900 hover:scale-105 active:scale-95 duration-300 transition-all" />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row items-center gap-x-1 gap-y-5">
                    <div className="w-full md:w-[50%] flex flex-col items-center justify-start">
                      <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                        Flight Date
                      </label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <input
                          placeholder="Flight Date"
                          type="date"
                          name="flight_date"
                          onChange={handleChange}
                          // value={values.flight_date}
                          defaultValue={values.flight_date}
                          onBlur={handleBlur}
                          className={` w-full pl-7 md:pl-10 h-10  border rounded-lg bg-white floating-input text-xs md:text-base text-gray-600 ${
                            touched.flight_date && errors.flight_date && "border border-red-800"
                          } `}
                        />
                        <div className="absolute pl-2">
                          <CiCalendar className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {touched.flight_date && errors.flight_date ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.flight_date}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full md:w-[50%] flex flex-col items-center justify-start">
                      <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                        Flight Time
                      </label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <TimePicker
                          clearIcon={false}
                          name="time_of_arrival"
                          as={TimePicker}
                          format={format}
                          defaultValue={dayjs(values.time_of_arrival, format)}
                          className={` w-full py-2 pl-7 md:pl-10  border rounded-lg bg-white ${
                            touched.time_of_arrival && errors.time_of_arrival && "border border-red-800"
                          } `}
                          onChange={(newTime) => {
                            setFieldValue("time_of_arrival", handleTimeChange(newTime));
                          }}
                          onBlur={handleBlur}
                        />
                        <div className="absolute pl-2">
                          <IoTimeOutline className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {touched.time_of_arrival && errors.time_of_arrival ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.time_of_arrival}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {values.service_type === "Departure" && (
                    <div className="input-par">
                      <div className="input-chil">
                        <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                          Attendance Date
                        </label>
                        <div className="w-full relative flex items-center gap-x-1">
                          <input
                            placeholder="Arrival Date"
                            type="date"
                            name="rep_attendance_date"
                            onChange={handleChange}
                            value={values.rep_attendance_date}
                            onBlur={handleBlur}
                            className={` w-full ${"pl-7 md:pl-10 text-left"} h-10  border rounded-lg bg-white floating-input text-xs md:text-base text-gray-600 ${
                              touched.rep_attendance_date && errors.rep_attendance_date && "border border-red-800"
                            } `}
                          />
                          <div className={`absolute pl-2`}>
                            <CiCalendar className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                          </div>
                        </div>

                        {touched.rep_attendance_date && errors.rep_attendance_date ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.rep_attendance_date}
                          </div>
                        ) : null}
                      </div>
                      <div className="input-chil">
                        <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                          Attendance Time
                        </label>
                        <div className="w-full relative flex items-center gap-x-1">
                          <TimePicker
                            clearIcon={false}
                            name="rep_attendance_time"
                            as={TimePicker}
                            format={format}
                            defaultValue={values.rep_attendance_time && dayjs(values.rep_attendance_time, format)}
                            className={` w-full py-2 ${"pl-7 md:pl-10"}  border rounded-lg bg-white ${
                              touched.rep_attendance_time && errors.rep_attendance_time && "border border-red-800"
                            } `}
                            onChange={(newTime) => {
                              setFieldValue("rep_attendance_time", handleTimeChange(newTime));
                            }}
                            onBlur={handleBlur}
                          />
                          <div className={`absolute pl-2`}>
                            <IoTimeOutline className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                          </div>
                        </div>
                        {touched.rep_attendance_time && errors.rep_attendance_time ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.rep_attendance_time}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}

                  <div className="w-full">
                    <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                      Flight Number
                    </label>
                    <div className="w-full relative flex items-center gap-x-1">
                      <input
                        placeholder="Flight Date"
                        type="text"
                        name="flight_number"
                        onChange={handleChange}
                        value={values.flight_number}
                        onBlur={handleBlur}
                        className={` w-full pl-7 md:pl-10 h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                          touched.flight_number && errors.flight_number && "border border-red-800"
                        } `}
                      />
                      <div className="absolute pl-2">
                        <RiFlightTakeoffLine className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                      </div>
                    </div>
                    {touched.flight_number && errors.flight_number ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                        {errors.flight_number}
                      </div>
                    ) : null}
                  </div>

                  <div className="w-full">
                    <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                      Nationality
                    </label>
                    <div className="w-full relative flex items-center gap-x-1">
                      <Select
                        className={`w-full  pl-5 md:pl-8  border rounded-md text-sm md:text-base ${
                          touched.nationality && errors.nationality && "border-red-800"
                        } `}
                        name="nationality"
                        options={flagData}
                        onBlur={handleBlur}
                        defaultValue={flagDataDefault}
                        isMulti
                        styles={selectStyle}
                        onChange={(e) => {
                          setFieldValue(
                            "nationality",
                            e.map((item) => item.value),
                          );
                        }}
                        formatOptionLabel={(flagData) => (
                          <div className="flex justify-start items-center  gap-x-3 country-option ">
                            <img src={flagData.image} alt="country-image" className="w-5 h-3" />
                            <span>{flagData.label}</span>
                          </div>
                        )}
                        isSearchable
                        placeholder="Nationality"
                      />
                      <div className="absolute pl-2">
                        <TfiWorld className="md:w-5 md:h-5  w-3 h-3 text-[#002244]" />
                      </div>
                    </div>
                    {touched.nationality && errors.nationality ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                        {errors.nationality}
                      </div>
                    ) : null}
                  </div>
                  <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                    Coming From{" "}
                  </label>
                  <div className="w-full border rounded-md p-2">
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-1 gap-y-1">
                      <div className="w-full flex flex-col items-center justify-center">
                        <label className=" flex justify-center text-gray-700 font-semibold   text-sm md:text-[16px]">
                          IATA Code
                        </label>
                        <div className="w-full flex items-center gap-x-2 border rounded-md pr-1">
                          <div className="w-full relative  ">
                            <input
                              className={` w-full pl-8 md:pl-11 h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                                touched.iata_code && errors.iata_code && "border border-red-800"
                              } `}
                              type="text"
                              name="iata_code"
                              value={values.iata_code}
                              onChange={(e) => {
                                setFieldValue("iata_code", e.target.value);
                                setFieldValue("country_from", "");
                                setFieldValue("area_from", "");
                                setFieldValue("city_from", "");
                                setFieldValue("airport_name", "");
                              }}
                              onBlur={handleBlur}
                            />
                            <div className="absolute top-3 md:top-[10px] left-3">
                              <SiIata className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                            </div>
                          </div>
                          {iataFlag ? (
                            <ButtonLoading />
                          ) : (
                            <button
                              type="button"
                              disabled={iataFlag}
                              onClick={() => {
                                setIataFlag(true);
                                setIataError(null);
                                dispatch(
                                  getIataCodeAction(values.iata_code.toUpperCase(), (result) => {
                                    if (result.status === 200) {
                                      setIataFlag(false);
                                      setiataData(result.data);
                                      setFieldValue("country_from", result.data.country);
                                      setFieldValue("area_from", result.data.municipality);
                                      setFieldValue("city_from", result.data.region);
                                      setFieldValue("airport_name", result.data.name);
                                    } else if (result.status !== 200) {
                                      setIataFlag(false);
                                      setIataError(result.response.data[0].message);
                                      setFieldValue("country_from", "");
                                      setFieldValue("area_from", "");
                                      setFieldValue("city_from", "");
                                      setFieldValue("airport_name", "");
                                    }
                                  }),
                                );
                              }}
                              className=" gap-x-1 text-xs px-1 py-1  rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                            >
                              <IoIosSend className="md:w-5 md:h-5  w-4 h-4" />
                            </button>
                          )}
                        </div>
                        {iataError ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {iataError}
                          </div>
                        ) : touched.iata_code && errors.iata_code ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.iata_code}
                          </div>
                        ) : null}
                      </div>

                      <div className="w-full flex flex-col items-center justify-center">
                        <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                          Country{" "}
                        </label>
                        <div className="w-full relative  ">
                          <input
                            className={` w-full pl-8 md:pl-11 h-10  border rounded-lg bg-white floating-input text-xs md:text-base flex items-center ${
                              touched.country_from &&
                              errors.country_from &&
                              values.country_from === "" &&
                              "border border-red-800"
                            }  `}
                            type="text"
                            name="country_from"
                            disabled={true}
                            value={values.country_from}
                          />
                          <div className="absolute top-3 md:top-[10px] left-3">
                            <GiWorld className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                          </div>
                        </div>
                        {touched.country_from && errors.country_from && values.country_from === "" ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.country_from}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col items-center justify-center">
                        <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px] ">
                          Area
                        </label>
                        <div className="w-full relative  ">
                          <input
                            className={`  w-full pl-8 md:pl-11 h-10  border rounded-lg bg-white floating-input text-xs md:text-base flex items-center ${
                              touched.area_from &&
                              errors.area_from &&
                              values.area_from === "" &&
                              "border border-red-800"
                            }  `}
                            type="text"
                            name="area_from"
                            disabled={true}
                            value={values.area_from}
                          />
                          <div className="absolute top-3 md:top-[10px] left-3">
                            <BiSolidCity className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                          </div>
                        </div>
                        {touched.area_from && errors.area_from && values.area_from === "" ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.area_from}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col items-center justify-center">
                        <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                          Region
                        </label>
                        <div className="w-full relative  ">
                          <input
                            className={`  w-full pl-8 md:pl-11 h-10  border rounded-lg bg-white floating-input text-xs md:text-base flex items-center ${
                              touched.city_from &&
                              errors.city_from &&
                              values.city_from === "" &&
                              "border border-red-800"
                            }  `}
                            type="text"
                            name="city_from"
                            disabled={true}
                            value={values.city_from}
                          />
                          <div className="absolute top-3 md:top-[10px] left-3">
                            <BiStreetView className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                          </div>
                        </div>
                        {touched.city_from && errors.city_from && values.city_from === "" ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.city_from}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="w-full flex flex-col items-center justify-center">
                      <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                        Airport Name
                      </label>
                      <div className="w-full relative  ">
                        <input
                          className={` w-full pl-8 md:pl-11 h-10  border rounded-lg bg-white floating-input text-xs md:text-base flex items-center  ${
                            touched.airport_name &&
                            errors.airport_name &&
                            values.airport_name === "" &&
                            "border border-red-800"
                          }  `}
                          type="text"
                          name="airport_name"
                          disabled={true}
                          value={values.airport_name}
                        />
                        <div className="absolute top-3 md:top-[10px] left-3">
                          <MdOutlineLocalAirport className="md:w-6 md:h-6 w-4 h-4 text-[#002244]" />
                        </div>
                      </div>
                      {touched.airport_name && errors.airport_name && values.airport_name === "" ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                          {errors.airport_name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="w-full">
                    <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                      Number Of Persons
                    </label>
                    <div className="w-full relative flex items-center gap-x-1">
                      <input
                        placeholder="Number Of Persons"
                        type="number"
                        name="number_of_pax"
                        onChange={handleChange}
                        value={values.number_of_pax}
                        onBlur={handleBlur}
                        className={` w-full pl-8 md:pl-10   h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                          touched.number_of_pax && errors.number_of_pax && "border border-red-800"
                        } `}
                      />
                      <div className="absolute pl-2">
                        <SlPeople className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                      </div>
                    </div>
                    {touched.number_of_pax && errors.number_of_pax ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                        {errors.number_of_pax}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                      Airline Name
                    </label>
                    <div className="w-full relative flex items-center gap-x-1">
                      <Select
                        placeholder="Airline Name"
                        isSearchable
                        name="airline_name"
                        options={airlinesArr}
                        styles={selectStyle}
                        onChange={(e) => setFieldValue("airline_name", e.value)}
                        defaultValue={[
                          {
                            label:
                              airLines?.filter((item) => item.id === values.airline_name?.id)[0]?.name || airlineName,
                            value: airlineID,
                          },
                        ]}
                        onBlur={handleBlur}
                        className={`w-full  pl-5 md:pl-8 border rounded-md text-sm md:text-base ${
                          touched.airline_name && errors.airline_name && "border-red-800"
                        } `}
                      />
                      <div className="absolute pl-2">
                        <SiEthiopianairlines className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                      </div>
                    </div>
                    {touched.airline_name && errors.airline_name ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                        {errors.airline_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="w-full ">
                    <label className=" flex justify-center text-gray-700 font-semibold   text-sm md:text-[16px]">
                      Order Type
                    </label>
                    <div className="w-full relative flex items-center gap-x-1">
                      <Select
                        name="order_type"
                        className={`w-full  pl-5 md:pl-8 border rounded-md text-sm md:text-base ${
                          touched.order_type && errors.order_type && "border-red-800"
                        } `}
                        options={orderTypeArr}
                        onBlur={handleBlur}
                        styles={selectStyle}
                        defaultValue={[{ label: values.order_type, value: values.order_type }]}
                        onChange={(e) => {
                          setFieldValue("order_type", e.value);
                        }}
                        isSearchable
                        placeholder="Select Order Type"
                      />
                      <div className="absolute pl-2">
                        <IoMdInformationCircleOutline className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                      </div>
                    </div>
                    {touched.order_type && errors.order_type ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                        {errors.order_type}
                      </div>
                    ) : null}
                  </div>

                  <div className="w-full ">
                    <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                      Amount
                    </label>
                    {general ? (
                      <>
                        <div className="w-full relative flex items-center gap-x-1">
                          <input
                            name="amount"
                            type="number"
                            required
                            value={values.amount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={` w-full pl-8 md:pl-10 h-10  border rounded-lg bg-white floating-input text-xs md:text-base ${
                              touched.amount && errors.amount && "border border-red-800"
                            } `}
                          />
                          <div className="absolute pl-2">
                            <FaMoneyBillWave className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                          </div>
                        </div>
                        {touched.amount && errors.amount ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.amount}
                          </div>
                        ) : null}
                        <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center mt-4  text-sm md:text-[16px]">
                          Currency
                        </label>
                        <div className="w-full relative flex items-center gap-x-1">
                          <Select
                            name="currency"
                            options={currenciesArr}
                            isSearchable
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,

                                borderBottom: "none",
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                boxShadow: "none",
                                borderRadius: "0",
                              }),
                            }}
                            value={[{ label: values.currency, value: values.currency }]}
                            className={`w-full  pl-6 md:pl-8 border rounded-md text-sm md:text-base ${
                              touched.currency && errors.currency && "border-red-800"
                            } `}
                            onChange={(e) => setFieldValue("currency", e.value)}
                            onBlur={handleBlur}
                            placeholder="Currency"
                          />
                          <div className="absolute pl-2">
                            <MdOutlineCurrencyExchange className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                          </div>
                        </div>
                        {errors.currency && touched.currency && (
                          <div className="w-full flex justify-start text-xs md:text-sm text-red-800 font-semibold">
                            {errors.currency}
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        className={`flex items-center justify-start gap-x-1 w-full px-3 h-10  border rounded-lg bg-white`}
                      >
                        <div className="flex items-center gap-x-3">
                          <FaMoneyBillWave className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                          <p className="text-sm md:text-base">{values.amount}</p>{" "}
                        </div>{" "}
                        <span className="text-xs text-gray-700 font-semibold">{values.currency}</span>
                      </div>
                    )}
                  </div>
                  {/* <div className="w-full">
                  <label className="flex justify-center text-gray-600 font-semibold ">Airport Sign</label>
                  <input
                    placeholder="Airport Sign"
                    type="text"
                    name="airport_sign"
                    onChange={handleChange}
                    value={values.airport_sign}
                    onBlur={handleBlur}
                    className={` w-full px-3 h-10  border rounded-lg bg-white floating-input ${
                      touched.airport_sign && errors.airport_sign && "border border-red-800"
                    } `}
                  />
                  {touched.airport_sign && errors.airport_sign ? (
                    <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                      {errors.airport_sign}
                    </div>
                  ) : null}
                </div> */}
                  <div className="w-full">
                    <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                      Transfer From Airport{" "}
                    </label>
                    <div
                      className={` w-full px-3 h-10 flex items-center justify-between  border rounded-lg bg-white floating-input text-xs md:text-base ${
                        touched.transfer_from_airport && errors.transfer_from_airport && "border border-red-800"
                      } `}
                    >
                      <TbBus className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />

                      <div className="flex items-center justify-center gap-x-3  w-[50%]">
                        <label className="  text-gray-600    text-sm md:text-lg ">Yes</label>
                        <input
                          placeholder="Employee Name"
                          name="transfer_from_airport"
                          type="radio"
                          value="true"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultChecked={values.transfer_from_airport === "true" && true}
                          className=" md:w-5 md:h-5  w-4 h-4  border rounded-lg bg-white "
                        />
                      </div>
                      <div className="flex items-center justify-center gap-x-3 w-[50%]">
                        <label className="text-gray-600   text-sm md:text-lg">No</label>
                        <input
                          placeholder="Total Amount"
                          name="transfer_from_airport"
                          type="radio"
                          value="false"
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("hotel_name_other", "");
                            setFieldValue("hotel_name", "");
                            setFieldValue("address", "");
                            setFieldValue("hotel_map_address", "");
                          }}
                          onBlur={handleBlur}
                          defaultChecked={values.transfer_from_airport === "false" && true}
                          className=" md:w-5 md:h-5  w-4 h-4  border rounded-lg bg-white  "
                        />
                      </div>
                    </div>{" "}
                    {errors.transfer_from_airport && touched.transfer_from_airport && (
                      <div className="w-full flex justify-start capitalize text-xs md:text-sm text-red-800  font-semibold">
                        {errors.transfer_from_airport}
                      </div>
                    )}
                  </div>
                  {values.transfer_from_airport === "true" && (
                    <>
                      {values.hotel_name !== "Other" && (
                        <>
                          <div className="w-full ">
                            <label className=" flex justify-center text-gray-700 font-semibold  text-sm md:text-[16px]">
                              Hotel
                            </label>
                            <div className="w-full relative flex items-center gap-x-1">
                              <Select
                                className={`w-full  pl-5 md:pl-8 border rounded-md text-sm md:text-base ${
                                  touched.hotel_name && errors.hotel_name && "border-red-800"
                                } `}
                                name="hotel_name"
                                options={[{ label: "Other", value: "Other" }, ...hotelsArr]}
                                required
                                styles={selectStyle}
                                onBlur={handleBlur}
                                defaultValue={[
                                  {
                                    label: hotels?.filter((item) => item.id === values.hotel_name)[0]?.name,
                                    value: values.hotel_name,
                                  },
                                ]}
                                onChange={(e) => {
                                  setFieldValue("hotel_name", e.value);
                                  setFieldValue("address", e.address);
                                  setFieldValue("hotel_map_address", e.map_address);
                                }}
                                isSearchable
                                placeholder="Select Hotel"
                              />
                              <div className="absolute pl-2">
                                <FaHotel className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                              </div>
                            </div>
                            {errors.hotel_name && touched.hotel_name && (
                              <div className="w-full flex justify-start capitalize text-xs md:text-sm text-red-800  font-semibold">
                                {errors.hotel_name}
                              </div>
                            )}
                            {/* {touched.hotel_name && errors.hotel_name ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                            {errors.hotel_name}
                          </div>
                        ) : null} */}
                          </div>
                          <div className="w-full ">
                            <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                              Hotel Address
                            </label>
                            <div
                              className={`flex items-center justify-start gap-x-3 w-full px-2 md:px-3 py-1 md:h-10  border rounded-lg bg-white`}
                            >
                              <TbRoad className="md:w-5 md:h-5  w-4 h-4 text-[#002244] text-sm md:text-base" />{" "}
                              {values.address}
                            </div>
                          </div>
                          <div className="w-full ">
                            <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                              Hotel Map Address
                            </label>
                            <div
                              className={`flex items-center justify-start gap-x-1 w-full px-3 py-1 md:h-10  border rounded-lg bg-white`}
                            >
                              <IoLocationOutline className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                              <Link
                                target="_blank"
                                to={`https://www.google.com/maps/place/${encodeURIComponent(values.hotel_map_address)}`}
                                className="flex items-center gap-x-1 pl-1 text-sky-600 border-b-[1px] border-opacity-50 border-sky-600 text-sm md:text-base hover:text-sky-700 active:scale-95 duration-300 transition-all cursor-pointer"
                              >
                                {values.hotel_map_address}
                                <IoLink className="md:w-5 md:h-5  w-4 h-4 text-sky-600" />
                              </Link>
                              {/* {values.hotel_map_address} */}
                            </div>
                          </div>
                        </>
                      )}
                      {values.hotel_name === "Other" && (
                        <>
                          <IoMdCloseCircleOutline
                            onClick={() => {
                              setFieldValue("hotel_name_other", "");
                              setFieldValue("address", "");
                              setFieldValue("hotel_map_address", "");
                              setFieldValue("hotel_name", "");
                            }}
                            className="md:w-5 md:h-5  w-4 h-4 text-red-600"
                          />
                          <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                            Other Hotel
                          </label>
                          <div className="w-full relative flex items-center gap-x-1">
                            <input
                              name="hotel_name_other"
                              type="text"
                              value={values.hotel_name_other}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Other Hotel"
                              className={` w-full pl-8 md:pl-10 h-10  border rounded-lg bg-white floating-input text-xs md:text-base
                                ${touched.hotel_name_other && errors.hotel_name_other && "border-red-800"}  `}
                            />
                            <div className="absolute pl-2">
                              <FaHotel className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                            </div>
                          </div>
                          {errors.hotel_name_other && touched.hotel_name_other && (
                            <div className="w-full flex justify-start capitalize text-xs md:text-sm text-red-800  font-semibold">
                              {errors.hotel_name_other}
                            </div>
                          )}
                          <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                            Hotel Address
                          </label>
                          <div className="w-full relative flex items-center gap-x-1">
                            <input
                              name="address"
                              type="text"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Address"
                              className={` w-full pl-8 md:pl-10 h-10  border rounded-lg bg-white floating-input text-xs md:text-base `}
                            />
                            <div className="absolute pl-2">
                              <TbRoad className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                            </div>
                          </div>
                          <label className=" text-center w-full text-gray-700 font-semibold flex items-center justify-center  text-sm md:text-[16px]">
                            Hotel Map Address
                          </label>
                          <div className="w-full relative flex items-center gap-x-1">
                            <input
                              name="hotel_map_address"
                              type="text"
                              value={values.hotel_map_address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Hotel Map Address"
                              className={` w-full pl-8 md:pl-10 h-10  border rounded-lg bg-white floating-input text-xs md:text-base `}
                            />
                            <div className="absolute pl-2">
                              <IoLocationOutline className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className="w-full">
                    <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                      Notes
                    </label>
                    <div className="w-full relative flex items-center gap-x-1">
                      <textarea
                        name="notes"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notes}
                        className={` w-full pl-8 h-16 py-2  border rounded-lg bg-white floating-input text-xs md:text-base ${
                          touched.notes && errors.notes && "border border-red-800"
                        } `}
                      />
                      <div className="absolute pl-2">
                        <CgNotes className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                      </div>
                    </div>
                    {touched.notes && errors.notes ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                        {errors.notes}
                      </div>
                    ) : null}
                  </div>

                  <div className="w-full ">
                    <label className=" flex justify-center text-gray-700 font-semibold text-sm md:text-[16px]">
                      Rep Name
                    </label>
                    <div className="w-full relative flex items-center gap-x-1">
                      <Select
                        className={`w-full  pl-5 md:pl-8 border rounded-md text-sm md:text-base ${
                          touched.representative_name && errors.representative_name && "border-red-800"
                        } `}
                        name="representative_name"
                        options={membersArr}
                        onBlur={handleBlur}
                        styles={selectStyle}
                        defaultValue={[
                          {
                            label: members.filter((item) => item.id === values.representative_name)[0]
                              ?.representative_name,
                            value: values?.representative_name,
                          },
                        ]}
                        // value={values.representative_name}
                        onChange={(e) => {
                          setFieldValue("representative_name", e.value);
                        }}
                        isSearchable
                        placeholder="Select Representative Name"
                      />
                      <div className="absolute pl-2">
                        <FaPerson className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                      </div>
                    </div>
                    {touched.representative_name && errors.representative_name ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                        {errors.representative_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <label className="flex justify-center text-gray-700 font-semibold text-sm md:text-[16px] ">
                      Rep Notes
                    </label>
                    <div className="w-full relative flex items-center gap-x-1">
                      <textarea
                        name="representative_notes"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.representative_notes}
                        className={` w-full pl-8 h-16 py-2 border rounded-lg bg-white floating-input text-xs md:text-base ${
                          touched.representative_notes && errors.representative_notes && "border border-red-800"
                        } `}
                      />
                      <div className="absolute pl-2">
                        <CgNotes className="md:w-5 md:h-5  w-4 h-4 text-[#002244]" />
                      </div>
                    </div>
                    {touched.representative_notes && errors.representative_notes ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-xs md:text-sm capitalize font-semibold">
                        {errors.representative_notes}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <label className=" flex justify-center text-gray-600 font-semibold ">Choose A File</label>
                    <FileUploader
                      maxFiles={1}
                      maxFileSize={2}
                      accept={"image/*, .pdf, .docx, .doc, .rtf"}
                      files={values.ticket_copy}
                      setFiles={(files) => {
                        setFieldValue("ticket_copy", files);
                      }}
                      urls={ticketCopyUrls}
                    />

                    {touched.ticket_copy && errors.ticket_copy ? (
                      <div className="w-full flex items-center justify-start capitalize text-red-800 text-xs md:text-sm font-semibold">
                        {errors.ticket_copy}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex justify-center mt-6">
                    <button type="Submit" disabled={isSubmitting} className="updateButton">
                      Update{" "}
                      {isSubmitting ? (
                        <>
                          <TopBarProgress />
                          <ButtonLoading />
                        </>
                      ) : (
                        <RxUpdate className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/*<PopUp close={closeModal} isOpen={isOpen} image={ticket} header={"Ticket Copy"} />*/}
          </div>
        )
      ) : (
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress /> <Loading />
        </div>
      )}
    </div>
  );
};

export default UpdateAirportService;
