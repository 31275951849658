import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineDone } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { AiOutlineEye } from "react-icons/ai";

import { getSelectedBookedRoomAction } from "../../../redux/actions/bookingListAction";
import BookingListTableSingleId from "../BookingListTableSingleId/BookingListTableSingleId";

const BookingListTableIdOne = () => {
  const [isloading, setIsLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  useEffect(() => {
    dispatch(getSelectedBookedRoomAction(token, params.short_id));
  }, []);

  const { selectedBookedRoom } = useSelector((state) => state.bookingListReducer);
  const oneSelectedRoom = selectedBookedRoom.filter((info) => info.id === Number(params.id));
  const selectedBookedRooms = selectedBookedRoom.filter((info) => info.id !== Number(params.id));

  const rows = selectedBookedRooms.map((item) => {
    return {
      short_id: item.short_id,
      //  booking_group:item.booking_group,
      id: item.id,
      client_names: item.client_names.map((info) => info.arabic_name),
      hotel: item.hotel,
      room_name: item.room_name,
      room: item.room,
      arrival_time_from: item.arrival_time_from,
      arrival_time_to: item.arrival_time_to,
      from_date: item.from_date,
      to_date: item.to_date,
      nights: item.nights,
      total_price: item.total_price,
      number_of_persons: item.number_of_persons,
      phone_number: item.phone_number,
      whatsapp_number: item.whatsapp_number,
      email: item.email,
      booking_type: item.booking_type,
      client_requests_tags: item.client_requests_tags,
      client_requests_text: item.client_requests_text,
      created_date: item.created_date,
      last_updated_date: item.last_updated_date,
    };
  });

  const columns = [
    {
      field: "short_id",
      headerName: "BOOKING ORDER",
      width: 200,
    },
    // {
    //     field: 'booking_group',
    //     headerName: 'BOOKING ORDER',
    //     width: 200 ,

    // },
    {
      field: "id",
      headerName: "ID",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <div>
            <button
              onClick={() => navigate(`/dashboard/booking-list-id/${params.row.short_id}/${params.row.id}`)}
              className="flex items-center gap-x-1"
            >
              {params.row.id}
              <AiOutlineEye className="w-5 h-5" />
            </button>
          </div>
        );
      },
    },
    {
      field: "client_names",
      headerName: "CLIENT NAME ",
      width: 200,
      editable: false,
    },

    {
      field: "hotel",
      headerName: "HOTEL NAME",

      width: 200,
      editable: false,
    },
    {
      field: "room_name",
      headerName: "ROOM TYPE",
      editable: false,
      width: 200,
      //   valueGetter: (params) =>
      //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "room",
      headerName: "ROOM ID",
      width: 200,
      editable: false,
    },
    {
      field: "arrival_time_from",
      headerName: "ARRIVAL_TIME_FROM",

      width: 200,
      editable: false,
    },
    {
      field: "arrival_time_to",
      headerName: "ARRIVAL_TIME_TO",

      width: 200,
      editable: false,
    },
    {
      field: "from_date",
      headerName: "CHECK-IN",

      width: 200,
      editable: false,
    },
    {
      field: "to_date",
      headerName: "CHECK-OUT",

      width: 200,
      editable: false,
    },

    {
      field: "nights",
      headerName: "NIGHTS",

      width: 200,
      editable: false,
    },
    {
      field: "total_price",
      headerName: "TOTAL PRICE",

      width: 200,
      editable: false,
    },
    {
      field: "number_of_persons",
      headerName: "PERSONS",

      width: 200,
      editable: false,
    },
    {
      field: "email",
      headerName: "EMAIL",

      width: 200,
      editable: false,
    },
    {
      field: "phone_number",
      headerName: "PHONE NUMBER",

      width: 200,
      editable: false,
    },
    {
      field: "whatsapp_number",
      headerName: "WHATSAPP NUMBER",

      width: 200,
      editable: false,
    },

    {
      field: "booking_type",
      headerName: "VCR",

      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            {params.row.booking_type}
            {params.row.booking_type === "voucher" ? (
              <MdOutlineDone className="w-5 h-5  text-green-500" />
            ) : (
              <RxCross1 className="w-5 h-5  text-red-600" />
            )}
          </div>
        );
      },
    },
    {
      field: "client_requests_tags",
      headerName: "CLIENT REQUESTS TAGS",

      width: 200,
      editable: false,
    },
    {
      field: "client_requests_text",
      headerName: "CLIENT REQUESTS TEXT",

      width: 200,
      editable: false,
    },
    {
      field: "created_date",
      headerName: "CREATED DATE",

      width: 200,
      editable: false,
    },
    {
      field: "last_updated_date",
      headerName: "LAST UPDATED DATE",
      width: 200,
      editable: false,
    },

    {
      field: "paid",
      headerName: "PAID",

      width: 200,
      editable: false,
    },
    {
      field: "balance",
      headerName: "BALANCE",

      width: 200,
      editable: false,
    },
    {
      field: "Meal Plan",
      headerName: "MEAL PLAN",

      width: 200,
      editable: false,
    },
    {
      field: "adults",
      headerName: "ADULTS",

      width: 200,
      editable: false,
    },
    {
      field: "status",
      headerName: "STATUS",

      width: 200,
      editable: false,
    },
    {
      field: "booking type",
      headerName: "BOOKING TYPE",

      width: 200,
      editable: false,
    },
    {
      field: "option date",
      headerName: "OPTION DATE",

      width: 200,
      editable: false,
    },
    {
      field: "source",
      headerName: "SOURCE",

      width: 200,
      editable: false,
    },
  ];
  return (
    <>
      <div className="w-full overflow-x-scroll">
        <BookingListTableSingleId />
        <Box>
          <DataGrid rows={rows} columns={columns} />
        </Box>
      </div>
    </>
  );
};

export default BookingListTableIdOne;
