import React, { useCallback, useEffect, useMemo, useState } from "react";
import { deleteSelectedContact, getContactListAction } from "../../../redux/actions/hotelContact";
import { useDispatch } from "react-redux";
import { BsCurrencyExchange } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import { GiBank } from "react-icons/gi";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { RiHotelLine } from "react-icons/ri";
import { MdOutlineCommentBank, MdOutlineManageAccounts } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useLocation, useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import lightToast from "light-toast";
import cookie from "react-cookies";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import ListFilters from "../../../utils/ListFilters";
import CityAreaContact from "../../../customComponents/CityAreaContact/CityAreaContact";

const HotelContactList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState("");

  const [confirm, setConfirm] = useState(false);
  const [contactList, setContactList] = useState(null);
  const [isloading, setIsLoading] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [hotelData, setHotelData] = useState([]);
  const [listPageSize, setListPageSize] = useState(20);
  const [hotelName, setHotelName] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [cityArea, setCityArea] = useState([]);

  useEffect(() => {
    if (selectedArea && selectedCity && selectedHotel) {
      dispatch(
        getContactListAction(params.page, listPageSize, params.sorting, selectedHotel?.value, (result) => {
          if (result.status === 200) {
            setIsLoading(false);
            setContactList(result.data);
          }
        }),
      );
    }
    setIsLoading(false);
  }, [params.page, location, selectedHotel, listPageSize]);

  const currentPage = Number(params.page) || 1;
  const totalPages = Math.ceil(contactList?.count / listPageSize);
  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("grid-visibility-hotel-contact-list");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility), slug: false });
    }
  }, []);

  function handlePageChange(params, size) {
    setListPageSize(size);
    navigate(`/dashboard/hotel-info-setup/hotel-contact-list/${params.page + 1}/${params.sorting}`);

    setIsLoading(true);
  }

  const handleDelete = (id) => {
    setIsLoading(true);
    dispatch(
      deleteSelectedContact(id, (result) => {
        if (result.status === 204) {
          lightToast.success("This Hotel Contact was completed Deleted");
          dispatch(
            getContactListAction(params.page, params.sorting, (result) => {
              if (result.status === 200) {
                setIsLoading(false);
                setContactList(result.data);
              }
            }),
          );
        }
      }),
    );
  };
  useEffect(() => {
    if (!selectedArea && !selectedCity && !selectedHotel) {
      // Handle the case when no area, city, or hotel is selected
      // toast.warn("Please select an area, city, or hotel to proceed.");
      setContactList([]); // Clear the contact list or take any other appropriate action
    }
  }, [selectedArea, selectedCity, selectedHotel]);

  const filteredRows = useMemo(() => {
    return contactList
      ? contactList?.results?.map((item, index) => ({
          contact_name: item.contact_name,
          depertment: item.depertment,
          email: item.email[index + 1],
          hotelId: item.hotel.id,
          name: item.hotel.name,
          id: item.id,
          notes: item.notes,
          telephone_number: item.telephone_number[index + 1],
          working_days: item.working_days[index + 1],
          working_hours_from: item.working_hours.from,
          working_hours_to: item.working_hours.to,
        }))
      : [];
  }, [contactList]);
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          ID{" "}
        </div>
      ),
      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 ">{params.id}</div>;
      },
    },
    {
      key: "hotelId",
      title: (
        <div className="flex items-center gap-x-1 tetxt-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          HOTEL ID
        </div>
      ),
      dataIndex: "hotelId",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.hotelId}</div>;
      },
    },

    {
      key: "name",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <RiHotelLine className="w-4 h-4 text-yellow-500" />
          HOTEL NAME{" "}
        </div>
      ),
      dataIndex: "name",
      editable: false,
      render: (text, params) => {
        setHotelName(params.name);
        return <div className="w-full flex items-center justify-center gap-x-1">{params.name}</div>;
      },
    },

    {
      key: "contact_name",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          CONTACT NAME{" "}
        </div>
      ),
      dataIndex: "contact_name",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 ">{params.contact_name}</div>;
      },
    },
    {
      key: "depertment",
      title: (
        <div className="flex items-center gap-x-1 tetxt-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" />
          DEPARTMENT{" "}
        </div>
      ),
      dataIndex: "depertment",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.depertment}</div>;
      },
    },

    {
      key: "email",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <RiHotelLine className="w-4 h-4 text-yellow-500" />
          EMAIL{" "}
        </div>
      ),
      dataIndex: "email",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1">{params.email}</div>;
      },
    },

    {
      key: "telephone_number",
      title: (
        <div className="flex items-center gap-x-1 text-xs ">
          <MdOutlineManageAccounts className="w-4 h-4 text-yellow-500" />
          TELEPHONE NUMBER{" "}
        </div>
      ),

      dataIndex: "telephone_number",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-8">{params.telephone_number}</div>;
      },
    },

    {
      key: "working_days",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-4 h-4 text-yellow-500" />
          WORKING DAYS{" "}
        </div>
      ),
      dataIndex: "working_days",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1">{params.working_days}</div>;
      },
    },

    {
      key: "working_hours_from",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BsCurrencyExchange className="w-4 h-4 text-yellow-500" />
          WORKING HOURS FROM{" "}
        </div>
      ),
      dataIndex: "working_hours_from",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.working_hours_from}</div>;
      },
    },

    {
      key: "working_hours_to",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <MdOutlineCommentBank className="w-4 h-4 text-yellow-500" />
          WORKING HOURS TO{" "}
        </div>
      ),
      dataIndex: "working_hours_to",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.working_hours_to}</div>;
      },
    },

    {
      key: "operations",
      filterable: false,
      sortable: false,
      dataIndex: "operations",
      title: "OPERATIONS",
      render: (text, params) => {
        return (
          <div className="flex items-center justify-start gap-x-5">
            {permission.map((item) => {
              if (item.name === "hotels_management.change_hotelcontact" && item.value === true) {
                return (
                  <Link
                    to={`/dashboard/edit-hotel-contact/${params.id}`}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <FiEdit className="w-5 h-5 text-green-800 " />
                  </Link>
                );
              } else if (item.name === "hotels_management.delete_hotelcontact" && item.value === true) {
                return (
                  <button
                    onClick={() => {
                      setId(params.id);
                      setConfirm(true);
                    }}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <AiFillDelete className="w-5 h-5 text-red-800" />
                  </button>
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  const handleChange = (city, area, allArea, hotel) => {
    setIsLoading(true); // Start loading when city/area changes
    setSelectedCity(city); // Update city
    setSelectedArea(area);
    setSelectedHotel(hotel); // Update area
    setCityArea(allArea); // Update area
    const cityAreaData = {
      city,
      area,
      allArea,
    };
    localStorage.setItem("hotelListContact", JSON.stringify(cityAreaData)); // Update localStorage

    // Clear hotel data and stop loading if city or area changes
    setHotelData([]); // Clear the current hotel data
    navigate(`/dashboard/hotel-info-setup/hotel-contact-list/1/${params.sorting}`);

    setIsLoading(false); // Stop loading after the state update
  };
  useEffect(() => {
    const storedCityArea = localStorage.getItem("hotelListContact");
    // Check if storedCityArea exists and is valid JSON
    if (storedCityArea) {
      const parsedData = JSON.parse(storedCityArea);
      if (parsedData?.city) setSelectedCity(parsedData.city);
      if (parsedData?.hotel) setSelectedHotel(parsedData.hotel);
      if (parsedData.area ? parsedData.area : parsedData.Allarea)
        setSelectedArea(parsedData.area ? parsedData.area : parsedData.Allarea);
    }
  }, []);

  useEffect(() => {
    if (selectedCity || selectedArea) {
      localStorage.setItem("selectedCity", JSON.stringify(selectedCity || ""));
      localStorage.setItem("selectedArea", JSON.stringify(selectedArea || ""));
    }
  }, [selectedCity, selectedArea]);
  const handleDataSelectorChange = useCallback(
    (data) => {
      // Only update state if data is different
      setHotelData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(data)) {
          return data;
        }
        return prevData; // No change, so no re-render
      });
    },
    [setHotelData],
  );

  useEffect(() => {
    const storedCity = localStorage.getItem("selectedCity");
    const storedArea = localStorage.getItem("selectedArea");
    if (storedCity) setSelectedCity(JSON.parse(storedCity));
    if (storedArea) setSelectedArea(JSON.parse(storedArea));
  }, []);
  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "hotels_management.change_hotelcontact",
      "hotels_management.delete_hotelcontact",
      "hotels_management.view_hotelcontact",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap  ">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "hotels_management.view_hotelcontact" && item.value === true) {
            return (
              <div className="relative w-full overflow-x-auto  min-h-screen ">
                {confirm && (
                  <div className=" z-50 w-full  bg-black ">
                    <div className="absolute z-[51] flex flex-col items-center justify-center gap-y-2 w-max   p-4 bg-white border-gray-300 border  bg-center rounded-md left-[20%] top-10">
                      <p className="text-2xl font-light text-center">
                        Are You Sure you want to delete ? {hotelName} Contact
                      </p>
                      <div className="w-full flex items-center justify-center gap-x-5 text-white">
                        <button
                          onClick={() => {
                            setConfirm(false);
                            handleDelete(id);
                          }}
                          className="bg-green-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
                        >
                          Yes,I'm Sure
                        </button>
                        <button
                          onClick={() => setConfirm(false)}
                          className="bg-red-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
                        >
                          No,take me back
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="">
                  <CityAreaContact
                    render={({ city, area, allArea, hotel }) => handleChange(city, area, allArea, hotel)}
                    localStorageName={"hotelListContact"}
                  />
                </div>
                {isloading ? (
                  <>
                    <TopBarProgress />
                    <div className="w-full h-full flex items-center justify-center">
                      <Loading />
                    </div>
                  </>
                ) : (
                  permissionFlag && (
                    <div className="">
                      {contactList?.results?.length > 0 && selectedCity && selectedArea && selectedHotel ? (
                        <ListFilters
                          columns={columns}
                          rows={filteredRows}
                          title="Hotel Contact List"
                          handlePagination={handlePageChange}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          listPageSize={listPageSize}
                        />
                      ) : (
                        <div className="flex justify-center items-center h-full w-full ">
                          <p className="text-gray-400 text-xl font-semibold text-center w-full mt-10">
                            No Contacts Found in this Hotels
                          </p>
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            );
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default HotelContactList;
