import React, { useEffect, useState } from "react";
import {
  deleteAirportAction,
  getAllAirline,
  getAllMeetAssist,
  getSelectedAllAirportAction,
} from "../../../redux/actions/airportServiceAction";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { IoMdArrowDropdown, IoMdTime } from "react-icons/io";

import {
  MdDateRange,
  MdDeleteSweep,
  MdFlight,
  MdInfoOutline,
  MdOutlineAccessTimeFilled,
  MdOutlineMiscellaneousServices,
  MdOutlineSettings,
} from "react-icons/md";
import { IoAdd } from "react-icons/io5";
import { LiaCalendar } from "react-icons/lia";
import { GoPerson } from "react-icons/go";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import useHotelNames from "../../../customHooks/useHotelNames";
import { SiEthiopianairlines } from "react-icons/si";
import usePermissions from "../../../customHooks/usePermissions";
import { TbListDetails } from "react-icons/tb";
import { getAllAssistAction } from "../../../redux/actions/meetAssistAction";
import { HiOutlineViewColumns } from "react-icons/hi2";

import { FaRegImage } from "react-icons/fa6";
import { RxUpdate } from "react-icons/rx";
import FilterAirportService from "../FilterAirportService/FilterAirportService";
import { toast } from "react-toastify";
import { Checkbox, Dropdown, Menu, Select, Table } from "antd";

const AirportServiceList = ({ general }) => {
  const [airports, setAirports] = useState();
  const [load, setLoad] = useState(false);
  const [id, setId] = useState();
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();
  const hotels = useHotelNames();
  const params = useParams();
  const [airlines, setAirlines] = useState();
  const [members, setMembers] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  // const [listPageSize, setListPageSize] = useState(20);
  const [listPageSize, setListPageSize] = useState(() => {
    return localStorage.getItem("pageSize") ? parseInt(localStorage.getItem("pageSize"), 10) : 20;
  });
  const [paramsFilter, setParamsFilter] = useState([]);
  const [passenger, setPassenger] = useState();
  const [flag, setFlag] = useState(false);

  //TOTAL PAGES
  const totalPages = Math.ceil(airports?.count / listPageSize);

  const [permissions, permissionsFlag] = usePermissions([
    "airport_services.view_airportlimousineservice",
    "airport_services.change_airportlimousineservice",
    "airport_services.delete_airportlimousineservice",
  ]);

  const location = useLocation();
  // Access the pathname property to get the current path
  const currentPath = location.pathname;
  const parts = currentPath.split("/");
  const lastParams = parts[parts.length - 2];

  ////// today Date ///////////
  // const todayDate = new Date().toISOString().slice(0, 10);
  const now = new Date();

  // Use Intl.DateTimeFormat to format the date and time for Cairo time zone
  const cairoTime = new Intl.DateTimeFormat("en-US", {
    timeZone: "Africa/Cairo",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(now);
  const [datecairo, cairotime] = cairoTime.split(", ");

  function convertDateFormat(dateStr) {
    // Split the input date string by '/'

    const [month, day, year] = dateStr.split("/");

    // Rearrange and return the date string in the format YYYY-MM-DD
    return `${year}-${month}-${day}`;
  }

  // Example usage
  const todayDate = convertDateFormat(datecairo);

  /////////////////////////////
  const { Option } = Select;

  useEffect(() => {
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ///////////////////////////////////////////////////////////

    if (general) {
      dispatch(
        getAllMeetAssist(lastParams, params.page, listPageSize, paramsFilter, (result) => {
          if (result.status === 200) {
            setLoad(true);
            setFlag(false);
            setAirports(result.data);
          }
        }),
      );
    } else {
      dispatch(
        getSelectedAllAirportAction(params.id, (result) => {
          if (result.status === 200) {
            setLoad(true);
            setAirports(result.data);
          }
        }),
      );
    }

    dispatch(
      getAllAssistAction(signal, (result) => {
        if (result.status === 200) {
          setMembers(result.data);
        }
      }),
    );

    return () => {
      controller.abort();
      // window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [params.page, location, paramsFilter]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(
      getAllAirline(signal, (result) => {
        if (result.status) {
          setAirlines(result?.data);
        }
      }),
    );

    return () => {
      controller.abort();
    };
  }, []); // empty dependency array to run only once on mount

  //Hiding all columns expect passenger name and operations

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    // Function to check if the screen size is small
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check
    checkScreenSize();

    // Event listener to handle screen size changes
    window.addEventListener("resize", checkScreenSize);

    // Clean up
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    const initialColumnVisibility = {};
    columns.forEach((column) => {
      if (isSmallScreen) {
        // Show only necessary columns for small screens
        initialColumnVisibility[column.key] = [
          "passengers_name",
          "representative_name",
          "time_of_arrival",
          "flight_number",
          "flight_date",
          "operations",
        ].includes(column.key);
      } else {
        // Show all columns for larger screens
        initialColumnVisibility[column.key] = true;
      }
    });
    setColumnVisibility(initialColumnVisibility);
  }, [isSmallScreen]);

  ////////// Rows /////////////////
  let rows;
  if (general) {
    rows = airports?.results.map((item, index) => {
      return {
        id: item.id,
        random_id: item.random_id,
        flight_date: item.time_of_arrival.slice(0, 10),
        service_type: item.service_type,
        passengers_name: item.passengers_name,
        representative_name: item.representative_name?.name,
        booking_order: item.booking_order,
        booking_id: item.booking_id,
        flight_number: item.flight_number,
        time_of_arrival: item.time_of_arrival.slice(11, 19),
        attendance_time: item?.representative_attendance_time?.slice(11, 19),
        display_time: item?.display_time?.slice(11, 19),
        coming_from: item.coming_from,
        hotel_name: item.hotel_name,
        airlines_name: item.airline_name,
        order_type: item.order_type,
        status: item.status,
      };
    });
  } else {
    rows = airports?.map((item, index) => {
      return {
        id: item.id,
        random_id: item.random_id,
        flight_date: item.time_of_arrival.slice(0, 10),
        service_type: item.service_type,
        passengers_name: item.passengers_name,
        representative_name: item.representative_name?.name,
        attendance_time: item?.representative_attendance_time?.slice(11, 19),
        display_time: item?.display_time?.slice(11, 19),
        booking_order: item.booking_order,
        booking_id: item.booking_id,
        flight_number: item.flight_number,
        time_of_arrival: item.time_of_arrival.slice(11, 19),
        coming_from: item.coming_from,
        hotel_name: item.hotel_name,
        airlines_name: item.airline_name,
        order_type: item.order_type,
        status: item.status,
      };
    });
  }

  /////// Coloumns //////////
  const columns = [
    {
      key: "flight_date",

      sortDirections: ["ascend", "descend"],
      title: (
        <div className={`flex items-center gap-x-1 text-xs 	 `}>
          <LiaCalendar className="w-5 h-5  text-yellow-500 " />
          FLIGHT DATE
        </div>
      ),

      dataIndex: "flight_date",
      // sorter: {
      //   compare: (a, b) => new Date(a.flight_date) - new Date(b.flight_date),
      // },
      editable: false,
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),

      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full  items-centerh-full flex items-center  ${
              todayDate === params?.flight_date && "text-green-800"
            }`}
          >
            {params?.flight_date}
          </Link>
        );
      },
    },
    {
      key: "service_type",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <MdOutlineMiscellaneousServices className="w-5 h-5  text-yellow-500" />
          SERVICE TYPE
        </div>
      ),
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),
      dataIndex: "service_type",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full  items-centerh-full flex items-center  ${
              todayDate === params?.flight_date && "text-green-800"
            }`}
          >
            {params?.service_type}
          </Link>
        );
      },
    },

    {
      key: "passengers_name",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <GoPerson className="w-5 h-5 text-yellow-500" />
          NAME
        </div>
      ),
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),
      dataIndex: "passengers_name",
      editable: false,
      render: (text, params) => {
        let passengerLen = params?.passengers_name[0]?.split(" ");

        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full flex items-center  text-xs md:text-[13px] ${
              todayDate === params?.flight_date && "text-green-800"
            }`}
          >
            {passengerLen && passengerLen.length >= 4
              ? passengerLen[1] === "بن" || passengerLen[1] === "بنت" || passengerLen[1] === "عبد"
                ? `${passengerLen[0]} ${passengerLen[1]} ${passengerLen[2]} ${passengerLen[passengerLen.length - 1]}`
                : `${passengerLen[0]} ${passengerLen[1]}  ${passengerLen[passengerLen.length - 1]}`
              : params?.passengers_name[0]}
          </Link>
        );
      },
    },

    {
      key: "flight_number",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <MdFlight className="w-5 h-5  text-yellow-500" />
          FLIGHT
        </div>
      ),
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),
      dataIndex: "flight_number",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full flex items-center  ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.flight_number}
          </Link>
        );
      },
    },
    {
      key: "time_of_arrival",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <IoMdTime className="w-5 h-5  text-yellow-500" />
          FLIGHT TIME
        </div>
      ),
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),
      dataIndex: "time_of_arrival",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full flex items-center  ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.display_time || "----------"}
          </Link>
        );
      },
    },

    {
      key: "airlines_name",
      title: (
        <div className={`flex items-center justify-center gap-x-1 text-xs `}>
          <SiEthiopianairlines className="w-5 h-5  text-yellow-500" />
          AIRLINE
        </div>
      ),
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),
      dataIndex: "airlines_name",
      editable: false,

      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full flex items-center   ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {/* {airlines && airlines?.filter((item) => item?.id === params?.airlines_name)[0]?.name} */}
            {params?.airlines_name?.name || ""}
            {/* item?.id === params?.airlines_name)[0]?.name */}
          </Link>
        );
      },
    },

    {
      key: "representative_name",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <GoPerson className="w-5 h-5  text-yellow-500" />
          REP NAME
        </div>
      ),
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),
      dataIndex: "representative_name",
      editable: false,
      render: (text, params) => {
        let repArr = params?.representative_name && params?.representative_name.split(" ");

        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full flex items-center  ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {repArr?.length > 0 && `${repArr[0]} ${repArr[repArr.length - 1]}`}
          </Link>
        );
      },
    },

    {
      key: "order_type",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <MdInfoOutline className="w-5 h-5  text-yellow-500" />
          ORDER TYPE
        </div>
      ),
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),
      dataIndex: "order_type",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full flex items-center  ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.order_type}
          </Link>
        );
      },
    },
    {
      key: "status",
      title: (
        <div className={`flex items-center gap-x-1 text-xs `}>
          <MdInfoOutline className="w-5 h-5  text-yellow-500" />
          STATUS
        </div>
      ),
      onHeaderCell: (column) => ({
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }),
      dataIndex: "status",
      editable: false,
      visible: true,
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/meet-&-assist-service/New/${params?.id}/preview`}
            className={`w-full h-full flex items-center  ${todayDate === params?.flight_date && "text-green-800"}`}
          >
            {params?.status}
          </Link>
        );
      },
    },

    {
      key: "operations",
      title: (
        <div className="flex text-center !justify-center !items-center  w-full text-xs uppercase">
          <MdOutlineSettings className="w-5 h-5   text-yellow-500" />
          Operations
        </div>
      ),
      dataIndex: "operations",
      render: (text, record) => {
        return (
          <div
            className={`${
              todayDate === record?.flight_date && "text-green-800"
            } flex items-center justify-center text-center gap-x-1 md:gap-x-1.5 w-full `}
          >
            {permissions?.some((item) => item.name === "airport_services.view_airportlimousineservice" && item.value) &&
              (general ? (
                <Link
                  to={`/dashboard/meet-&-assist-service/${lastParams}/${record?.id}/preview`}
                  className="flex items-center gap-x-1  bg-yellow-400 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                >
                  <AiOutlineEye className="w-5 h-5  text-gray-700 " />
                </Link>
              ) : (
                <Link
                  to={`/dashboard/meet-&-assist-service/New/${record?.id}/preview`}
                  className="flex items-center gap-x-1  bg-yellow-400 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                >
                  <AiOutlineEye className="w-5 h-5 text-gray-700 " />
                </Link>
              ))}
            {permissions?.some(
              (item) => item.name === "airport_services.change_airportlimousineservice" && item.value,
            ) &&
              (general ? (
                <Link
                  to={`/dashboard/meet-&-assist-service/${lastParams}/${record?.id}/update`}
                  className="flex items-center gap-x-1 bg-green-700 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                >
                  <RxUpdate className="w-5 h-5 text-white " />
                </Link>
              ) : (
                <Link
                  to={`/dashboard/meet-&-assist-service/New/${record?.id}/update`}
                  className="flex items-center gap-x-1 bg-green-700 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                >
                  <RxUpdate className="w-5 h-5 text-white" />
                </Link>
              ))}
            {permissions?.some(
              (item) => item.name === "airport_services.delete_airportlimousineservice" && item.value,
            ) && (
              <button
                onClick={() => {
                  setId(record?.id);
                  setConfirm(true);
                  setPassenger(record?.passengers_name);
                }}
                className="flex items-center gap-x-1 bg-red-800 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all"
              >
                <MdDeleteSweep className="w-5 h-5 text-white" />
              </button>
            )}
            <Link
              to={`/workorder/${record.random_id}`}
              target="_blank" // Add this line to open the link in a new tab
              className="flex items-center gap-x-1 bg-cyan-900 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-cyan-700 active:scale-95 duration-300 transition-all"
            >
              <FaRegImage className="w-5 h-5 text-white" />
            </Link>
          </div>
        );
      },
    },
  ];
  const [visibleColumns, setVisibleColumns] = useState(columns.map((col) => ({ ...col, visible: true })));
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const handleToggleColumn = (key) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.map((col) => (col.key === key ? { ...col, visible: !col.visible } : col)),
    );
  };

  const handleRemoveColumn = (key) => {
    setVisibleColumns((prevColumns) => prevColumns.filter((col) => col.key !== key));
  };

  const filteredColumns = visibleColumns.filter((col) => col.visible);

  const handleSortColumnChange = (value) => {
    setSortColumn(value);
  };

  const handleSortOrderChange = (value) => {
    setSortOrder(value);
  };

  const getSortOrder = (column) => {
    if (sortColumn === column) {
      return sortOrder;
    }
    return null;
  };
  const sortedData = rows?.sort((a, b) => {
    if (!sortColumn || !sortOrder) return 0;
    if (sortOrder === "ascend") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    }
    return a[sortColumn] < b[sortColumn] ? 1 : -1;
  });

  const handleChange = (pagination, filters, sorter) => {};

  const columnsWithOperations = filteredColumns.map((col) => {
    if (col.key === "operations") {
      return {
        ...col,
        render: (text, record) => {
          return (
            <div
              className={`${
                todayDate === record?.flight_date && "text-green-800"
              } flex items-center justify-center gap-x-1 md:gap-x-1.5 w-full `}
            >
              {permissions.some(
                (item) => item.name === "airport_services.view_airportlimousineservice" && item.value,
              ) &&
                (general ? (
                  <Link
                    to={`/dashboard/meet-&-assist-service/${lastParams}/${record?.id}/preview`}
                    className="flex items-center gap-x-1  bg-yellow-400 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                  >
                    <AiOutlineEye className="w-5 h-5  text-gray-700 " />
                  </Link>
                ) : (
                  <Link
                    to={`/dashboard/meet-&-assist-service/New/${record?.id}/preview`}
                    className="flex items-center gap-x-1  bg-yellow-400 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                  >
                    <AiOutlineEye className="w-5 h-5 text-gray-700 " />
                  </Link>
                ))}
              {permissions.some(
                (item) => item.name === "airport_services.change_airportlimousineservice" && item.value,
              ) &&
                (general ? (
                  <Link
                    to={`/dashboard/meet-&-assist-service/${lastParams}/${record?.id}/update`}
                    className="flex items-center gap-x-1 bg-green-700 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                  >
                    <RxUpdate className="w-5 h-5 text-white " />
                  </Link>
                ) : (
                  <Link
                    to={`/dashboard/meet-&-assist-service/New/${record?.id}/update`}
                    className="flex items-center gap-x-1 bg-green-700 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                  >
                    <RxUpdate className="w-5 h-5 text-white" />
                  </Link>
                ))}
              {permissions.some(
                (item) => item.name === "airport_services.delete_airportlimousineservice" && item.value,
              ) && (
                <button
                  onClick={() => {
                    setId(record?.id);
                    setConfirm(true);
                    setPassenger(record?.passengers_name);
                  }}
                  className="flex items-center gap-x-1 bg-red-800 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all"
                >
                  <MdDeleteSweep className="w-5 h-5 text-white" />
                </button>
              )}
              <Link
                to={`/workorder/${record.random_id}`}
                target="_blank" // Add this line to open the link in a new tab
                className="flex items-center gap-x-1 bg-cyan-900 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-cyan-700 active:scale-95 duration-300 transition-all"
              >
                <FaRegImage className="w-5 h-5 text-white" />
              </Link>
            </div>
          );
        },
      };
    }
    return {
      ...col,
      sortOrder: getSortOrder(col.dataIndex),
    };
  });
  const toggleMenu = (
    <Menu>
      {visibleColumns.map((col) => (
        <Menu.Item key={col.key}>
          <Checkbox className="flex items-center" checked={col.visible} onChange={() => handleToggleColumn(col.key)}>
            {col.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );
  const overlayStyle = {
    width: "auto",
    maxWidth: "fit-content",
  };
  const handleDropdownVisibleChange = (flag) => {
    setVisibleDropdown(flag);
  };

  //////// HANDLE DELETE /////////
  const handleDelete = () => {
    setLoad(false);
    dispatch(
      deleteAirportAction(id, (result) => {
        if (result.status === 204) {
          toast.success("This Airport was completed go to Deleted list");
          navigate(`/dashboard/meet-&-assist-service/New/1`);
        }
      }),
    );
  };

  const navigate = useNavigate();

  //pagination
  function handlePageChange(para) {
    if (general) {
      setListPageSize(para.pageSize);
      setCurrentPage(para.page + 1);
      setLoad(false);
      navigate(`/dashboard/meet-&-assist-service/${lastParams}/${para.page + 1}`);
    }
  }

  function handlePagination(event, page) {
    if (general) {
      setCurrentPage(page);
      setLoad(false);
      navigate(`/dashboard/meet-&-assist-service/${lastParams}/${page}`);
    }
  }

  function handlePagination2(event, page) {
    if (general) {
      setLoad(false);
      setCurrentPage(event);
      setListPageSize(page);
      localStorage.setItem("pageSize", page);

      navigate(`/dashboard/meet-&-assist-service/${lastParams}/${event}`);
    }
  }

  useEffect(() => {
    const queryParams = params;
    const page = queryParams.page;
    // const size = queryParams.get("pageSize");

    if (page) {
      setCurrentPage(Number(page));
      // setListPageSize(Number(size));
    }
  }, [params, currentPage]);

  return (
    <div className="min-h-screen">
      {confirm && (
        <div
          className={` fixed ${
            confirm ? "top-0 left-0 right-0 bottom-0 min-h-screen  " : ""
          }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${
              confirm
                ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <p className="text-2xl font-light  text-white text-center px-2 capitalize">
              Are You Sure you want to delete {passenger[0]} request ?
            </p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id);
                }}
                className="bg-green-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      <div className=" flex  justify-center text-center mb-3 ">
        <div className=" bg-white rounded-md shadow-sm p-3  ">
          <span className="text-cyan-800 font-medium flex justify-center gap-4 ">
            <span className=" flex justify-center items-center gap-3">
              <span className=" flex justify-center items-center gap-1 text-[13px]">
                <MdDateRange /> {todayDate}
              </span>
              <span className=" flex justify-center items-center gap-1 text-[13px]">
                <MdOutlineAccessTimeFilled /> {cairotime}
              </span>
            </span>
          </span>
          <span className="text-[11px] font-medium"> Cairo time now based on your device.</span>
        </div>
      </div>

      <div className="px-2 md:px-6 ">
        {/* md:flex-row items-center justify-center md:justify-between gap-y-2 pt-3 pl-1 md:pl-5  md:pr-8 */}
        <div className="w-full flex xl:flex-row flex-col justify-between items-center sm:items-start gap-2 ">
          <div className="w-full flex flex-col md:flex-row  gap-2 max-sm:items-center ">
            <Link
              to={
                general
                  ? "/dashboard/meet-&-assist-service"
                  : `/dashboard/booking-list-single-id/${params.short_id}/${params.id}/meet-service`
              }
              className="flex items-center gap-x-1 text-2xl font-normal text-[#001a35]  hover:text-yellow-700 duration-500 transition-all active:scale-95"
            >
              <TbListDetails className="w-5 h-5 text-yellow-600" />
              {!general
                ? "Meet & Assist List"
                : lastParams === "New"
                  ? "New Meet & Assist List"
                  : lastParams === "Pending"
                    ? "Pending Meet & Assist List"
                    : lastParams === "UnderReview"
                      ? "Under Review  Meet & Assist List"
                      : "Archive Meet & Assist List"}
              {/* <FaHandPointer className="w-5 h-5 -rotate-45" /> */}
            </Link>{" "}
          </div>
          <div className=" flex  flex-col justify-end ">
            <div className="flex max-md:flex-col  gap-2 items-center sm:items-start">
              <div className="flex gap-2">
                {lastParams !== "UnderReview" && (
                  <Link
                    to={
                      general
                        ? "/dashboard/meet-&-assist-service/add-meet-service"
                        : `/dashboard/booking-list-single-id/${params.short_id}/${params.id}/meet-service/add-meet-service`
                    }
                    className=" w-fit flex items-center justify-end px-4 py-1 bg-green-500  rounded-md   text-white duration-500 transition-all   hover:bg-green-600 hover:text-#fff active:scale-95  "
                  >
                    Add <IoAdd className="w-5 h-5" />
                  </Link>
                )}
                {general && (
                  <FilterAirportService
                    paramsFilter={paramsFilter}
                    setParamsFilter={setParamsFilter}
                    flag={flag}
                    setFlag={setFlag}
                    setLoad={setLoad}
                    lastParams={lastParams}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </div>

              <div className="flex gap-2 items-center sm:items-start max-[550px]:flex-col ">
                <Dropdown
                  overlay={toggleMenu}
                  trigger={["click"]}
                  visible={visibleDropdown}
                  onVisibleChange={setVisibleDropdown}
                >
                  <button
                    className="bg-gray-100 font-medium px-3 py-1 text-[#002244]  hover:bg-gray-200  hover:shadow-sm duration-300 transition-all flex gap-2 items-center rounded-md w-max"
                    onClick={() => setVisibleDropdown(!visibleDropdown)}
                  >
                    <HiOutlineViewColumns className="h-5 w-5 text-yellow-600" />
                    Toggle Columns
                    <IoMdArrowDropdown className="h-5 w-5" />
                  </button>
                </Dropdown>
                <div className="flex items-start ">
                  <Select
                    placeholder="Sort by"
                    style={{ width: 150, marginRight: 8 }}
                    onChange={handleSortColumnChange}
                    className="sortOrder"
                  >
                    {visibleColumns
                      .filter((col) => col.key !== "operations")
                      .map((col) => (
                        <Option key={col.key} value={col.dataIndex} className="flex items-start flex-col ">
                          {col.title}
                        </Option>
                      ))}
                  </Select>
                  <Select
                    className="sortOrder"
                    placeholder="Sort Order"
                    style={{ width: "max-content" }}
                    onChange={handleSortOrderChange}
                  >
                    <Option value="ascend">Ascending</Option>
                    <Option value="descend">Descending</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex gap-2 mt-2 w-full items-center xl:justify-end  xl:text-end max-sm:justify-center">
              <div className="w-4 h-4 bg-green-900 rounded-sm"></div>
              <p className="text-xs justify-start md:text-sm font-semibold text-[#001a35] capitalize ">
                The green color indicates today's date
              </p>
            </div>
          </div>
        </div>
      </div>
      {airports && load && hotels.length > 0 && airlines && members && permissionsFlag ? (
        permissions?.some((item) => item.value) && (
          <div className="w-full h-full md:px-6 px-2 ">
            <div className="w-full   overflow-x-auto ">
              <div className="bg-gray-50  ">
                <Table
                  columns={columnsWithOperations}
                  dataSource={sortedData}
                  pagination={{
                    showQuickJumper: true,
                    showSizeChanger: true,

                    current: currentPage,
                    total: totalPages * listPageSize,
                    onChange: (page, size) => handlePagination2(page, size),
                    pageSize: listPageSize,
                  }}
                />
              </div>

              {/* <DataGrid
                  rows={rows}
                  columns={columns}
                  rowCount={airports.count}
                  autoHeight
                  // onSortModelChange={comparator}
                  columnVisibilityModel={columnVisibility}
                  onColumnVisibilityModelChange={(visibility) => {
                    setColumnVisibility(visibility);
                  }}
                  paginationModel={{ page: Number(params.page - 1), pageSize: listPageSize }}
                  onPaginationModelChange={handlePageChange}
                  paginationMode="server"
                  pageSizeOptions={[1, 5, 10, 20, 25, 100]} // Specify the available page size options
                  pageSize={listPageSize} // Assuming pageSize is a state variable that holds the current page size
                  className=" xl:w-full  w-[250%]"
                  // initialState={{
                  //   sorting: {
                  //     sortModel: [{ key: "flight_date", sort: "asc" }],
                  //   },
                  // }}
                /> */}
              {/* <div className="absolute -bottom-8 md:bottom-4 ">
                  {" "}
                  <Pagination count={totalPages} page={currentPage} onChange={handlePagination} color="primary" />
                </div> */}
            </div>
          </div>
        )
      ) : (
        <>
          {" "}
          <TopBarProgress />
          <div className="w-full h-screen flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default AirportServiceList;
