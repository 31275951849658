import React from "react";

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import { BiStats } from "react-icons/bi";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";

ChartJS.register(ArcElement, Tooltip, Legend);

function CancellationReport() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["cancellation-booking-statistics"],
    queryFn: () => fetchDataQuery(`/report/cancellation-booking-statistics/`),
  });

  const statistics = data?.statistics;

  const totalCancellations = statistics?.reduce((acc, statistic) => acc + statistic.num_cancellations, 0);

  const chartData = {
    labels: statistics?.map((statistic) => statistic.reason),
    datasets: [
      {
        data: statistics?.map((statistic) => statistic.num_cancellations),
        backgroundColor: [
          "#dc2626",
          "#65a30d",
          "#0891b2",
          "#7c3aed",
          "#475569",
          "#000",
          "#65451F",
          "#BFDB38",
          "#8FBDD3",
          "#FFCC1D",
          "#6D9886",
          "#28B5B5",
        ],
        highlightFill: "rgba(220,220,220,0.75)",
        borderColor: ["#fff"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "left",
        labels: {
          padding: 16,
        },
      },
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 60,
        right: 60,
        top: 0,
        bottom: 0,
      },
    },
  };

  if (isLoading) {
    return (
      <>
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress /> <Loading />
        </div>
      </>
    );
  }

  if (error) {
    return <Errorfetch Error={error}></Errorfetch>;
  }

  return (
    <div className="w-full  items-center  gap-8 p-8 max-md:h-full   ">
      <h2 className="text-2xl text-white underline font-medium my-8 text-center tracking-widest uppercase shadow items-center flex w-full justify-center gap-2 rounded-md px-8 py-2 bg-gray-900">
        {" "}
        <span>
          <BiStats />
        </span>{" "}
        <span>Cancellation report</span>{" "}
      </h2>

      <div className="flex flex-col mt-2 justify-around gap-6 ">
        <div className="mb-6 self-center space-y-3 w-full md:w-[800px] h-[300px]">
          <h3 className="py-2 mx-auto font-bold shadow-md rounded-md px-4 text-center max-w-[fit-content]">
            Number of total cancellations : &nbsp; <span className="font-normal">{totalCancellations}</span>{" "}
          </h3>
          <Pie options={chartOptions} data={chartData} />
        </div>

        <div className="relative  grow overflow-x-auto">
          <table className="w-full shadow-md rounded-md text-sm text-left rtl:text-right text-white ">
            <thead className="text-xs text-White uppercase  bg-gray-900 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Reason
                </th>
                <th scope="col" className="px-6 py-3">
                  Percentage
                </th>
              </tr>
            </thead>
            <tbody>
              {statistics?.map((statistic, index) => (
                <tr key={index} className="bg-white border-b  ">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    {statistic.reason} :
                  </th>
                  <td className="px-6 py-4 text-gray-900">
                    {Math.ceil((statistic.num_cancellations / totalCancellations) * 100)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CancellationReport;
