import React from "react";
import CBoxListRelated from "./CBoxListRelated";
import AddCBoxRelated from "./AddCBoxRelated";

const CashBoxRelated = () => {
  return (
    <div className="formPage-wrap">
      <div className="form-wrap  grid sm:grid-cols-2 grid-cols-1  ">
        <CBoxListRelated />
        <AddCBoxRelated />
      </div>
    </div>
  );
};

export default CashBoxRelated;
