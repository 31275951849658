import React from "react";
import { useDispatch } from "react-redux";

// this component should take
// 1) name: (string) will be put as the name and the id of the select element
// 2) text: (string) will be put as the text of the select element
// 3) options: (array of objects {id: value , name:value}) will be mapped and put as the list of options
// 4) action: (action) optional argument that if provided would be dispatched
// 5) func: (callback) optional argument  that if provided will take the label instead of the value
// 6) error: (boolean) optional argument to pass to add custom error
// 7) placeholder: (string) optional placeholder
// 8) required: (boolean) optional to determine if the input is required. the default is required

export default function StaticFormSelect({
  name,
  text,
  options,
  action,
  func,
  error,
  placeholder,
  required,
  callback,
  defaultValue,
  contractCallBack,
  icon,
  setHotelFlag,
  type,
  value,
  id,
  disabled,

  canDefaultValueZero = false,
}) {
  const dispatch = useDispatch();
  const random = Math.random();

  function handleChange(e) {
    if (action) {
      if (setHotelFlag) {
        setHotelFlag(true);
        dispatch(
          action(e.target.value, (result) => {
            if (result.status === 200) {
              setHotelFlag(false);
            }
          }),
        );
      } else {
        dispatch(action(e.target.value));
      }
    }
    if (func) {
      let index = e.target.selectedIndex;
      let data = e.target.options[index].textContent;
      func(data);
    }
    if (callback) {
      callback(e.target.value);
    }
    if (contractCallBack) {
      contractCallBack(e, e.target.value);
    }
  }

  return (
    <div className="flex flex-col gap-y-1=">
      {text && (
        <div className="w-full flex items-center gap-x-1 text-slate-800 font-semibold">
          <div className=" text-slate-600 ">{icon}</div>
          <label htmlFor={`${name}-${random}`} className="md:text-base text-sm">
            {text}
          </label>
        </div>
      )}

      <select
        value={value}
        disabled={disabled}
        className={`w-full px-3 h-10  border rounded-lg bg-white text-gray-600   placeholder-gray-200 focus:outline-none focus:ring focus:border-blue-300 capitalize ${disabled && "cursor-not-allowed !bg-stone-200"} `}
        name={name}
        id={id ? id : `${name}-${random}`}
        onChange={handleChange}
        required={required === undefined ? true : required}
        defaultValue={canDefaultValueZero && defaultValue === 0 ? 0 : defaultValue || ""}
      >
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {options &&
          options?.map((elem, index) => {
            return (
              <option value={elem.id} className="rounded-lg  border-none font-semibold" key={index}>
                {elem.name}
              </option>
            );
          })}
      </select>
      {error && <div className="error">Invalid input </div>}
    </div>
  );
}
