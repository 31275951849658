import React, { useEffect, useState } from "react";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import Loading from "../../../Loading/Loading";

const HotelOfferDetails = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const [modalImageSrc, setModalImageSrc] = useState("");

  const {
    data: offer,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["offer", id],
    queryFn: () => fetchDataQuery(`/upgrade-tool/api/v1/hotel-upgrade-extra/${id}/`),
  });

  useEffect(() => {
    if (isSuccess && offer) {
      setOfferData(offer);
    }
  }, [offer, isSuccess]);

  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return isLoading ? (
    <Loading />
  ) : offerData ? (
    <div className="formPage-wrap flex flex-col gap-8 p-6 rounded-lg shadow-md">
      <div className=" flex sm:flex-row flex-col sm:justify-between justify-center items-center sm:w-full w-max ">
        <h2 className="text-2xl font-bold text-gray-800">Hotel Offer Details</h2>
        <Link className="updateButton max-sm" to={`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-form/${id}`}>
          Edit
        </Link>
      </div>

      <div className="flex flex-col gap-4 form-wrap">
        {/* ID Section */}
        <p className="flex gap-2 items-center bg-gray-100 w-1/2 px-4 py-2 rounded-md shadow-sm">
          <span className="font-semibold text-gray-700">ID:</span>
          <span className="text-gray-600">{offerData?.id}</span>
        </p>

        {/* Name and Name(Arabic) Section */}
        <h3 className="text-xl font-semibold text-gray-700">Offer Details</h3>
        <div className="flex flex-col gap-4 md:flex-row">
          <p className="flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm flex-1">
            <span className="font-semibold text-gray-700">Offer Name:</span>
            <span className="text-gray-600">{offerData?.name}</span>
          </p>
          <p dir="rtl" className="flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm flex-1">
            <span className="font-semibold text-gray-700">إسم العرض:</span>
            <span className="text-gray-600">{offerData?.name_ar}</span>
          </p>
        </div>

        {/* Description and Description(Arabic) Section */}
        <div className="flex flex-col gap-4 md:flex-row">
          <p className="flex gap-2 items-start bg-gray-100 px-4 py-2 rounded-md shadow-sm flex-1">
            <span className="font-semibold text-gray-700">Description:</span>
            <span className="text-gray-600">{offerData?.description}</span>
          </p>
          <p dir="rtl" className="flex gap-2 items-start bg-gray-100 px-4 py-2 rounded-md shadow-sm flex-1">
            <span className="font-semibold text-gray-700">الوصف:</span>
            <span className="text-gray-600">{offerData?.description_ar}</span>
          </p>
        </div>

        {/* City, Area, and Hotel Section */}
        <h3 className="text-xl font-semibold text-gray-700">Hotel Details</h3>
        <div className="grid gap-4 md:grid-cols-3">
          <p className="flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm">
            <span className="font-semibold text-gray-700">City:</span>
            <span className="text-gray-600">{offerData?.hotel_details?.city?.name}</span>
          </p>
          <p className="flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm">
            <span className="font-semibold text-gray-700">Area:</span>
            <span className="text-gray-600">{offerData?.hotel_details?.area?.name}</span>
          </p>
          <p className="flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm">
            <span className="font-semibold text-gray-700">Hotel:</span>
            <span className="text-gray-600">{offerData?.hotel_details?.name}</span>
          </p>
        </div>

        {/* Periods Section */}
        {offerData?.periods?.length > 0 && (
          <div className="flex flex-col gap-4">
            <h3 className="text-xl font-semibold text-gray-700">Periods</h3>
            {offerData?.periods?.map((period, idx) => (
              <div key={period.id} className="p-4 bg-gray-50 rounded-md shadow-sm border border-gray-200">
                <p className="text-lg font-semibold text-gray-700">{`Period ${idx + 1}`}</p>
                <p className="text-gray-600">
                  <span className="font-semibold">Start Date:</span> {period.start_date}
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold">End Date:</span> {period.end_date}
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold">Days of Week:</span> {period.days_of_week.join(", ")}
                </p>
              </div>
            ))}
          </div>
        )}

        {/* Document Section */}
        <div className="flex flex-col gap-2">
          <span className="font-semibold text-gray-700">Document:</span>
          <div className="border bg-gray-100 p-2 max-w-max w-[350px] h-full rounded-md shadow-sm hover:shadow-md transition-shadow duration-200">
            {offerData?.upgrade_document ? (
              /\.(jpg|jpeg|png|gif)$/.test(offerData.upgrade_document) ? (
                <>
                  <img
                    src={offerData.upgrade_document}
                    alt="Document"
                    className="max-w-full h-auto rounded-md cursor-pointer"
                    onClick={() => openModal(offerData.upgrade_document)}
                  />
                  <Modal visible={isModalOpen} footer={null} onCancel={closeModal} centered bodyStyle={{ padding: 0 }}>
                    <img src={modalImageSrc} alt="Document Preview" className="max-w-full h-auto rounded-md" />
                  </Modal>
                </>
              ) : (
                <a
                  href={offerData.upgrade_document}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  Download Document
                </a>
              )
            ) : (
              <p>No document available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default HotelOfferDetails;
