import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { CashBoxActionById } from "../../../redux/actions/addCashBoxAction";
import Loading from "../Loading/Loading";

const CashBoxDetails = () => {
  const [boxList, setBoxList] = useState();
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const params = useParams();

  const fetchData = () => {
    dispatch(
      CashBoxActionById(token, params.id, (res) => {
        try {
          if (res.status === 200) {
            setBoxList(res.data);
          }
        } catch (error) {}
      }),
    );
  };
  useEffect(() => {
    if (!boxList) {
      fetchData();
    }
  }, [boxList]);
  return (
    <section className="formPage-wrap flex flex-col ">
      <div className="flex justify-center">
        <h2 className="header-h2">Cash Box List </h2>
      </div>
      {boxList ? (
        <div className="flex flex-col  items-center">
          <table className="max-md:hidden min-w-full  form-wrap  ">
            <thead className="bg-gray-50">
              <tr>
                <th className="p-2 font-bold border-r border-b">ID</th>
                <th className="p-2 font-bold border-r border-b">Balance</th>
                <th className="p-2 font-bold border-r border-b">Currency</th>
                <th className="p-2 font-bold border-r border-b">Location</th>
                <th className="p-2 font-bold border-r border-b">Created At</th>
                <th className="p-2 font-bold border-r ">Updated At</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border text-center">
                <td className="p-2 border-r border-b">{boxList.id}</td>
                <td className="p-2 border-r border-b">{boxList.balance}</td>
                <td className="p-2 border-r border-b">{boxList.currency_type}</td>
                <td className="p-2 border-r border-b">{boxList.location}</td>
                <td className="p-2 border-r border-b">{boxList?.created_at?.slice(0, 10)}</td>
                <td className="p-2 ">{boxList?.updated_at?.slice(11, 19)}</td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full  form-wrap md:hidden">
            <tbody>
              <tr className="border">
                <td className="p-2 border-r border-b font-bold">ID:</td>
                <td className="p-2 border-r border-b ">{boxList.id}</td>
              </tr>
              <tr className=" border">
                <td className="p-2 border-r border-b font-bold">Balance:</td>
                <td className="p-2 border-r border-b">{boxList.balance}</td>
              </tr>
              <tr className="border">
                <td className="p-2 border-r border-b font-bold">Currency:</td>
                <td className="p-2 border-r border-b">{boxList.currency_type}</td>
              </tr>
              <tr className="border">
                <td className="p-2 border-r border-b font-bold">Location:</td>
                <td className="p-2 border-r border-b">{boxList.location}</td>
              </tr>
              <tr className="border">
                <td className="p-2 border-r border-b font-bold">Created At:</td>
                <td className="p-2 border-r border-b">{boxList?.created_at?.slice(0, 10)}</td>
              </tr>
              <tr className="">
                <td className="p-2 border-r border-b font-bold">Updated At:</td>
                <td className="p-2 border-r border-b">{boxList?.updated_at?.slice(11, 19)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <p>
          <Loading />
        </p>
      )}
    </section>
  );
};

export default CashBoxDetails;
