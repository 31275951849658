import React, { useState } from "react";
import { FaWpforms } from "react-icons/fa6";
import TopBarProgress from "react-topbar-progress-indicator";
import { useNavigate } from "react-router";
import { sendData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import { GiNotebook } from "react-icons/gi";
import { TfiWorld } from "react-icons/tfi";
import { MdOutlineAddTask } from "react-icons/md";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { NotificationContainer, NotificationManager } from "react-notifications";
import lightToast from "light-toast";

export default function AddWhitelistIP() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  function handelSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const data = {
      ip_address: e.target.ip.value,
      description: e.target.notes.value,
      is_enabled: "true",
    };

    dispatch(
      sendData(
        "/auth/api/v1/whitelisted-ips/",
        data,
        () => {
          setIsDisabled(false);
          lightToast.success("Your IP was Submitted Successfully");
          navigate("/dashboard/whitelist-ip/whitelist-ip-list");
        },
        (error) => {
          setIsDisabled(false);
          NotificationManager.error(error?.response?.data[0]?.message);
        },
      ),
    );
  }

  return (
    <div className="w-full  md:px-6 px-2">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className=" w-full   rounded-lg p-5 ">
          <div className="w-full flex justify-center   ">
            <h2 className="header-h2">
              <FaWpforms className="w-5 h-5" />
              Add Whitelist IP
            </h2>
          </div>
          <form className="form-wrap" encType="multipart/form-data" onSubmit={handelSubmit}>
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <label className=" flex text-[#002244] font-semibold ">IP Address</label>
              <div className="w-full relative">
                <input
                  placeholder="IP Address"
                  name="ip"
                  type="text"
                  required
                  className=" w-full pl-9 h-10  border rounded-lg bg-white "
                />
                <div className=" absolute top-3 left-2">
                  <TfiWorld className="w-5 h-5 text-[#002244]" />
                </div>
              </div>

              <label className=" flex text-[#002244] font-semibold">Notes</label>
              <div className="w-full relative">
                <textarea cols={1} rows={5} className="w-full   border rounded-lg bg-white pl-9 py-1" name="notes" />
                <div className=" absolute top-3 left-2">
                  <GiNotebook className="w-5 h-5 text-[#002244]" />
                </div>
              </div>
              <div className="flex justify-center mt-6">
                <button disabled={isDisabled} type="Submit" className="addButton">
                  Add{" "}
                  {isDisabled ? (
                    <>
                      <TopBarProgress />
                      <ButtonLoading />
                    </>
                  ) : (
                    <MdOutlineAddTask className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}
