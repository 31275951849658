import {
  GET_CANCELLATION_POLICY_LIST,
  CLEAR_CANCELLATION_POLICY_LIST,
  GET_CANCELLATION_POLICY_ARCHIVE,
  CLEAR_CANCELLATION_POLICY_Archive,
} from "../types/types";

export function cancellationPolicyListReducer(state = [], action) {
  switch (action.type) {
    case GET_CANCELLATION_POLICY_LIST:
      return action.payload;
    case CLEAR_CANCELLATION_POLICY_LIST:
      return [];
    default:
      return state;
  }
}

export function cancellationPolicyArchiveReducer(state = [], action) {
  switch (action.type) {
    case GET_CANCELLATION_POLICY_ARCHIVE:
      return action.payload;
    case CLEAR_CANCELLATION_POLICY_Archive:
      return [];
    default:
      return state;
  }
}
