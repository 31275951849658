import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { useNavigate } from "react-router";
import { deleteIPAddress, getWhitelistIPs } from "../../../redux/actions/whitelist";
import Loading from "../Loading/Loading";
import cookie from "react-cookies";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { BiTransfer } from "react-icons/bi";
import { TfiWorld } from "react-icons/tfi";
import { MdOutlineDescription, MdOutlineSettings } from "react-icons/md";
import ListFilters from "../../../utils/ListFilters";

export default function WhitelistIPList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [IPList, setIPList] = useState([]);
  const [deleteIP, setDeleteIP] = useState({ name: null, id: null });
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["auth_users.change_whitelistedip", "auth_users.delete_whitelistedip"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      getWhitelistIPs((data) => {
        setIPList(data);
        setIsLoading(false);
      }),
    );
  }, []);
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
    },
    {
      key: "ip",
      title: (
        <div className="flex items-center gap-x-1">
          <TfiWorld className="w-5 h-5  text-yellow-500" />
          IP ADDRESS
        </div>
      ),
      dataIndex: "ip",
    },
    {
      key: "description",
      title: (
        <div className="flex items-center gap-x-1">
          <MdOutlineDescription className="w-5 h-5  text-yellow-500" />
          DESCRIPTION
        </div>
      ),
      dataIndex: "description",
    },
    {
      key: "actions",
      title: (
        <div className="flex items-center justify-center gap-x-1">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      render: (text, params) => {
        return (
          <div className="w-full flex items-center justify-center gap-x-2 pl-7">
            {permission.map((item) => {
              if (item.name === "auth_users.change_whitelistedip" && item.value === true) {
                return (
                  <Link to={`/dashboard/whitelist-ip/edit-whitelist/${params.id}`}>
                    <FiEdit className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800" />
                  </Link>
                );
              } else if (item.name === "auth_users.delete_whitelistedip" && item.value === true) {
                return (
                  <AiFillDelete
                    className="w-6 h-6 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
                    onClick={() => setDeleteIP({ name: params.ip, id: params.id })}
                  />
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  const rows = useMemo(
    () => IPList.map((IP) => ({ id: IP.id, ip: IP.ip_address, description: IP.description })),
    [IPList],
  );
  return (
    <div className="">
      {isLoading && !permissionFlag && (
        <div>
          <div className="w-full h-screen flex items-center justify-center">
            {" "}
            <Loading />
            <TopBarProgress />
          </div>
        </div>
      )}

      {!isLoading && permissionFlag && (
        <div className="formPage-wrap">
          <div className="!w-full">
            <ListFilters columns={columns} rows={rows} title={"Whitelisted IPS"} />
          </div>
        </div>
      )}

      {deleteIP.name && (
        <DeletePopUp
          name={deleteIP.name}
          Func={() => {
            dispatch(deleteIPAddress(deleteIP.id, () => dispatch(getWhitelistIPs((data) => setIPList(data)))));
            setDeleteIP({ name: null, id: null });
          }}
          hideFunc={() => setDeleteIP({ name: null, id: null })}
        />
      )}
    </div>
  );
}
