import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import * as Yup from "yup";
import { FaWpforms } from "react-icons/fa";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { getSelectedMeet, updateSelectedMeet } from "../../../redux/actions/meetAssistAction";
import { useNavigate, useParams } from "react-router";
import lightToast from "light-toast";
import { RxUpdate } from "react-icons/rx";
import Loading from "../Loading/Loading";
import { getData } from "../../../redux/actions/sendDataAction";
import Select from "react-select";

const UpdateRepresentativeMember = () => {
  useRemoveScroll();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [users, setUsers] = useState();
  const [load, setLoad] = useState(false);
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      representative_name: "",
      user: "",
      licenses_id_number: "",
      joined_date: "",
      mobile_number: "",
    },
    validationSchema: Yup.object({
      representative_name: Yup.string().required("Please select representative name"),
      licenses_id_number: Yup.string()
        .required("Please enter an licenses id number")
        // .integer(" licenses id number must be positive number")
        // .positive(" licenses id number must be positive number")
        // .min(0, " licenses id number must be greater than or equal to 0")
        .matches(/^[0-9/]+$/, "licenses id number must be a positive number or contain '/'")
        .test("slash-check", "licenses id number must be positive number", (value) => {
          if (!value) return true; // Skip if value is empty
          const numberPart = value.split("/")[0]; // Extract the numeric part
          return parseInt(numberPart) > 0; // Check if the numeric part is positive
        }),

      mobile_number: Yup.number().required("Please enter your mobile number"),
      joined_date: Yup.date().required("Please enter date"),
    }),
    onSubmit: (values) => {
      dispatch(
        updateSelectedMeet(values, params.id, (result) => {
          if (result.status === 200) {
            lightToast.success("New Meet Assist Member was completed Updated");
            navigate(-1);
          } else {
            setSubmitting(false);
          }
        }),
      );
    },
  });
  useEffect(() => {
    //////////// Cancellation Request ////////////
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(
      getSelectedMeet(params.id, signal, (result) => {
        if (result.status === 200) {
          setLoad(true);
          Object.keys(result.data).map((key) => {
            if (key === "created_date") {
              setFieldValue("joined_date", result.data[key].slice(0, 10));
            } else if (key === "user") {
              setFieldValue("user", result.data[key].id);
            } else {
              setFieldValue(key, result.data[key]);
            }
          });
        }
      }),
    );
    // Get Users
    dispatch(
      getData(
        `/auth/api/v1/users/?pagination=false`,
        (resp) => {
          if (resp.status === 200) {
            setUsers(resp.data);
          }
        },
        (err) => {},
      ),
    );
    return () => {
      controller.abort();
    };
  }, []);
  // userArr
  let userArr = users ? users.map((item) => ({ label: item.username, value: item.id })) : [];
  ////handle phone number //////
  const handlePhoneInputChange = (newPhoneNumber) => {
    setFieldValue("mobile_number", newPhoneNumber);
  };
  return (
    <div className="w-full h-screen">
      {load && users ? (
        <div className="flex flex-col items-center justify-start h-full w-full">
          <div className=" w-[70%]  border-gray-300 border rounded-lg p-5 ">
            <div className=" flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
              <p className="flex items-center gap-x-3 text-md">
                <FaWpforms className="w-5 h-5" />
                Update Representative Service
              </p>
            </div>
            <form className="mt-2  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <label className=" text-center w-full text-gray-600">Representative Name</label>
                <input
                  placeholder="Representative Name"
                  name="representative_name"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.representative_name}
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    touched.representative_name && errors.representative_name && "border border-red-800"
                  } `}
                />
                {touched.representative_name && errors.representative_name ? (
                  <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                    {errors.representative_name}
                  </div>
                ) : null}
                <label className=" flex text-gray-600">User</label>
                <Select
                  placeholder="Select user"
                  name="user"
                  options={userArr}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,

                      borderBottom: "none",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      boxShadow: "none",
                      borderRadius: "0",
                    }),
                  }}
                  defaultValue={[
                    { label: users.filter((item) => item.id === values.user)[0]?.username, value: values.user },
                  ]}
                  onBlur={handleBlur}
                  className={`w-full pl-1 h-10  border rounded-lg bg-white text-gray-600 ${
                    touched.user && errors.user && "border border-red-800"
                  } `}
                  onChange={(e) => setFieldValue("user", e.value)}
                />
                {touched.user && errors.user ? (
                  <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                    {errors.user}
                  </div>
                ) : null}
                <label className=" flex text-gray-600">Licenses ID Number</label>
                <input
                  placeholder="Licenses ID Number"
                  name="licenses_id_number"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.licenses_id_number}
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    touched.licenses_id_number && errors.licenses_id_number && "border border-red-800"
                  } `}
                />
                {touched.licenses_id_number && errors.licenses_id_number ? (
                  <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                    {errors.licenses_id_number}
                  </div>
                ) : null}
                <div className="flex items-center gap-x-5 w-full">
                  <div className="flex flex-col justify-start w-[50%]">
                    <label className=" flex justify-center text-gray-600 ">Joined Date</label>
                    <input
                      placeholder="Tranfer Date"
                      name="joined_date"
                      type="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.joined_date}
                      className={`w-full px-3 h-10  border rounded-lg floating-input bg-white ${
                        touched.joined_date && errors.joined_date && "border border-red-800"
                      } `}
                    />
                    {touched.joined_date && errors.joined_date ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                        {errors.joined_date}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col justify-start w-[50%]">
                    <label className=" flex justify-center text-gray-600 ">Mobile Number</label>
                    <PhoneInput
                      // placeholder="Enter phone number"
                      name="mobile_number"
                      type="text"
                      required
                      dir="ltr"
                      defaultCountry="EG"
                      value={values.mobile_number}
                      onBlur={handleBlur}
                      className={`w-full px-3 h-10  border rounded-lg floating-input bg-white ${
                        touched.mobile_number && errors.mobile_number && "border border-red-800"
                      } `}
                      onChange={handlePhoneInputChange}
                    />
                    {touched.mobile_number && errors.mobile_number ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                        {errors.mobile_number}
                      </div>
                    ) : null}
                  </div>
                </div>
                <button
                  type="Submit"
                  disabled={isSubmitting}
                  className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  Update{" "}
                  {isSubmitting ? (
                    <>
                      <TopBarProgress />
                      <ButtonLoading />
                    </>
                  ) : (
                    <RxUpdate className="w-5 h-5" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress /> <Loading />
        </div>
      )}{" "}
    </div>
  );
};

export default UpdateRepresentativeMember;
