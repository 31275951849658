import React, { useEffect, useState } from "react";
import "./TimeRange.scss";
import { TimePicker } from "antd";
import toast from "react-hot-toast";
import moment from "moment";

export default function TimeRange({ timeRange, setTimeRange }) {
  const [Time, setTime] = useState({ from: null, to: null });
  const [toDateDisabled, setToDateDisabled] = useState(true);

  useEffect(() => {
    if (timeRange.from) {
      setToDateDisabled(false);
      setTime((prev) => ({ ...prev, from: moment(timeRange.from, "HH:mm") }));
    }
    if (timeRange.to) {
      setTime((prev) => ({ ...prev, to: moment(timeRange.to, "HH:mm") }));
    }
  }, [timeRange]);

  //functions
  function fromChange(time, timeString) {
    if (timeRange.to && timeRange.to < timeString) {
      setTime({ ...Time, from: null });
      toast.error("to time must be larger than from time");
    } else {
      setTime({ ...Time, from: time });
      setTimeRange({ ...timeRange, from: timeString });
    }
  }

  function toChange(time, timeString) {
    if (timeString > timeRange.from) {
      setTime({ ...Time, to: time });
      setTimeRange({ ...timeRange, to: timeString });
    } else {
      toast.error("to time must be larger than from time");
      setTime({ ...Time, to: null });
    }
  }

  return (
    <div className="TimeRange my-1">
      <div>
        <label htmlFor="from">From:</label>
        <TimePicker required format="HH:mm" className="w-full" onChange={fromChange} value={Time.from} />
      </div>
      <div>
        <label htmlFor="to">To:</label>
        <TimePicker
          required
          format="HH:mm"
          className="w-full"
          onChange={toChange}
          disabled={toDateDisabled}
          value={Time.to}
        />
      </div>
    </div>
  );
}
