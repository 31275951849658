import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Modal } from "flowbite-react";

export const ColumnHeader = ({ label, Icon }) => {
  return (
    <div className="flex items-center gap-x-1">
      <Icon className="w-5 h-5  text-yellow-500" />
      {label}
    </div>
  );
};

export const DetailsModal = ({ title, children }) => {
  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="cursor-pointer"
      >
        <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showModal}
        position={"center"}
        onClose={() => setShowModal(false)}
      >
        <Modal.Header className="border-b border-b-gray-300" />
        <Modal.Body>
          <div className="mt-6">
            <h5 className="font-medium text-xl text-white bg-gray-900 py-2 px-2 rounded mb-2"> {title} </h5>
            <div className="border-gray-200 border p-2 rounded text-sm text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
              {children}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
