import React from "react";
import DepartmentForm from "./DepartmentForm";

import { useMutation } from "@tanstack/react-query";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import usePermissions from "../../../../customHooks/usePermissions";
import NoPermission from "../../NoPermission/NoPermission";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";


const initialValues = {
  name: "",
  groups: [],
  users: [],
};

const CreateDepartmentForm = () => {

  const navigate = useNavigate();
  const [permissions, permissionFlag, permissionsError] = usePermissions(["department.add_department"]);

  const canCreateDepartment =
    permissions?.find((permission) => permission.name === "department.add_department")?.value === true;

  const { mutate: createDepartment, isPending } = useMutation({
    mutationKey: ["createDepartment"],
    mutationFn: async (data) => {
      return mutateDataQuery("/department/api/v1/department", "post", data);
    },
    onSuccess: () => {
      toast.success("Department Created Successfully");
      navigate("/dashboard/departments-management/departments-list");
    },
  });

  const handleSubmit = (values) => {
    createDepartment({
      name: values.name,
      user: values.users.map((user) => user.id),
    });
  };

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={"Failed to Fetch Permissions"} />;

  return canCreateDepartment ? (
    <DepartmentForm
      initialValues={initialValues}
      title={"Create Department"}
      handleSubmit={handleSubmit}
      isLoading={isPending}
      submitButtonText={"Create Department"}
    />
  ) : (
    <NoPermission />
  );

};

export default CreateDepartmentForm;
