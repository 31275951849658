import React from "react";
import VoucherForm from "./VoucherForm";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";

import * as Yup from "yup";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { getCurrentUserId } from "../../../utils/utils";
import usePermissions from "../../../customHooks/usePermissions";
import NoPermission from "../NoPermission/NoPermission";

const createVoucherValidationSchema = Yup.object().shape({
  voucherType: Yup.string().required("Required"),
  hotelName: Yup.string().required("Required"),
  guestNames: Yup.array()
    .of(Yup.string().test("not-empty", "Guest name is required", (value) => value && value.trim() !== ""))
    .min(1, "At least one guest name is required")
    .required("Required"),
  date: Yup.string().required("Required"),
  personsNum: Yup.number().required("Required"),
  childrenAges: Yup.array()
    .of(
      Yup.number()
        .typeError("Age must be a number") // Ensures the value is a number
        .min(0, "Age must be greater than or equal to 0"), // No negative ages allowed
    )
    .test("is-sorted", "Each child's age must be greater than or equal to the previous child's age", function (ages) {
      if (!Array.isArray(ages)) return false; // Ensure it's an array
      for (let i = 1; i < ages.length; i++) {
        if (ages[i] < ages[i - 1]) {
          return false; // Check if current age is smaller than the previous age
        }
      }
      return true;
    })
    .required("Required"),
  roomType: Yup.string().required("Required"),
  mealPlan: Yup.string().required("Required"),
  checkInDate: Yup.string().required("Required"),
  checkOutDate: Yup.string().required("Required"),
  remarks: Yup.string(),
  totalPrice: Yup.number().required("Required"),
});

function CreateVoucherForm() {
  const [permissions, permissionFlag, permissionsError] = usePermissions(["room_booking.add_evoucher"]);

  const isAddingVoucherAllowed =
    permissions?.find((permission) => permission.name === "room_booking.add_evoucher")?.value === true;

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: booking,
    isLoading: isGettingBooking,
    error: bookingError,
  } = useQuery({
    queryKey: ["booked-room", id],
    queryFn: () => {
      return fetchDataQuery(`/booking/api/v1/booked-room/${id}/`);
    },
  });

  const { mutate: createVoucher, data: voucher } = useMutation({
    mutationKey: ["create-voucher"],
    mutationFn: (newVoucher) => {
      return mutateDataQuery("/booking/api/v1/e-vouchers/", "post", newVoucher);
    },
  });

  function handleSubmit(values, helpers) {
    const newVoucher = {
      voucher_type: values.voucherType,
      voucher_form: values.voucherForm,
      hotel_confirmation_number: values.hotelConfirmationNumber,
      system_confirmation_number: values.systemConfirmationNumber,
      date: values.date,
      hotel_name: values.hotelName,
      guest_name: values.guestNames,
      number_of_persons: values.personsNum,
      number_of_children: values.childrenAges.length,
      children_age: values.childrenAges,
      room_type: values.roomType,
      meal_plan: values.mealPlan,
      check_in_date: values.checkInDate,
      check_out_date: values.checkOutDate,
      nationality: values.nationality,
      remarks: values.remarks,
      total_price: values.totalPrice,
      net_price: values.netPrice,
      currency: values.currency,
      booked_room_id: values.bookedRoomId,
      issued_by_id: getCurrentUserId(),
      created_by_id: getCurrentUserId(),
      net_price_json:
        Object.keys(booking?.additional_data).length > 0
          ? booking.additional_data.map(({ date, net_price }) => ({
              date,
              price: Number(net_price),
            }))
          : booking?.net_price_data.map(({ date, net_price }) => ({
              date,
              price: Number(net_price),
            })),
    };

    createVoucher(newVoucher, {
      onSuccess: (data) => {
        helpers.resetForm();
        navigate(`/booking-voucher/${data.random_id}`);
      },
    });
  }

  const formInitialValues = {
    voucherType: "New Reservation",
    voucherForm: "Auto Voucher",
    hotelConfirmationNumber: booking?.hotel_reservation_id,
    systemConfirmationNumber: booking?.id,
    date: new Date().toISOString().split("T")[0],
    hotelName: booking?.hotel,
    guestNames: booking?.client_names?.map((name) => name?.english_name),
    personsNum: booking?.persons_data?.adults,
    childrenAges: booking?.persons_data?.children || [],
    roomType: booking?.room_name,
    mealPlan: booking?.contract_meal,
    checkInDate: booking?.from_date,
    checkOutDate: booking?.to_date,
    currency: booking?.currency_type,
    nationality: booking?.nationality,
    remarks: "",
    netPrice: booking?.net_price,
    totalPrice: booking?.total_price,
    bookedRoomId: booking?.id,
  };

  if (isGettingBooking || !permissionFlag) {
    return <Loading />;
  }

  if (bookingError) {
    return <Errorfetch Error={"Error fetching booking"} />;
  }

  if (permissionsError) {
    return <Errorfetch Error={`Error Fetching permissions ${permissionsError.message}`} />;
  }

  return isAddingVoucherAllowed ? (
    <VoucherForm
      initialValues={formInitialValues}
      validationSchema={createVoucherValidationSchema}
      onSubmit={handleSubmit}
    />
  ) : (
    <NoPermission />
  );
}

export default CreateVoucherForm;
