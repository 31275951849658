import React from "react";
import useData from "../../../customHooks/useData";
import { useParams } from "react-router";
import Loading from "../Loading/Loading";

export default function OfferDetails() {
  const { id } = useParams();
  const offer = useData(`/offers/api/offer/${id}/`);

  return (
    <div>
      {offer ? (
        <div className="border w-[80%] mx-auto rounded-xl my-3">
          <h1 className="w-full bg-[#002244] text-white font-bold p-2 rounded-xl text-center">{offer.name}</h1>
          <div className="flex bg-gray-200 py-3">
            <div className="w-[40%] font-bold px-2">Contract Name</div>
            <div className="w-[60%] px-2">{offer.contract.name}</div>
          </div>
          <div className="flex py-3">
            <div className="w-[40%] font-bold px-2">Offer Type</div>
            <div className="w-[60%] px-2">{offer.type}</div>
          </div>
          <div className="flex bg-gray-200 py-3">
            <div className="w-[40%] font-bold px-2">From Date</div>
            <div className="w-[60%] px-2">{offer.from_date}</div>
          </div>
          <div className="flex py-3">
            <div className="w-[40%] font-bold px-2">To Date</div>
            <div className="w-[60%] px-2">{offer.to_date}</div>
          </div>
          <div className="flex bg-gray-200 py-3">
            <div className="w-[40%] font-bold px-2">Nights</div>
            <div className="w-[60%] px-2">{offer.nights_number}</div>
          </div>
          <div className="flex py-3">
            <div className="w-[40%] font-bold px-2">Gift</div>
            <div className="w-[60%] px-2">{offer.gift}</div>
          </div>
          <div className="flex bg-gray-200 py-3">
            <div className="w-[40%] font-bold px-2">Created At</div>
            <div className="w-[60%] px-2">{offer.created_at}</div>
          </div>
        </div>
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </div>
  );
}
