import { GET_HOTEL_DETAILS } from "../types/types";

const defaultState = {
  name: "",
  country: "",
  city: "",
  area: "",
  address: "",
  latitude: "",
  longitude: "",
  reception_email: {},
  restaurants: {},
  telephone: {},
  reservation_telephone: {},
  reservation_email: {},
  internet: "",
  images: [],
  videos: [],
};

export default function hotelDetailsReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_HOTEL_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
