import api_token from "../../api/UserApi";
import cookie from "react-cookies";
// import jwtDecode from "jwt-decode";

const token = cookie.load("access_token");
// let decodedToken;
// token ? (decodedToken = jwtDecode(token)) : void 0;

export function editContract(id, data, callback) {
  return async function () {
    try {
      const response = await api_token(token).patch(`/contracts/api/${id}`, data, {
        headers: {
          "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });

      if (response) {
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
}

export const editDeletedContract = (id, data, callback) => {
  return async function () {
    try {
      const response = await api_token(token).patch(`/contracts/api/soft-deleted/edit/${id}/`, data, {
        headers: {
          "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });

      if (response) {
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};
