import { GET_BANK_TRANSFER, GET_BANK_TRANSFER_FLAG, UPDATE_BANK_TRANSFER , UPDATE_BANK_TRANSFER_FLAG , DELETE_BANK_TRANSFER_FLAG ,
    LIST_BANK_TRANSFER , LIST_BANK_TRANSFER_FLAG } from "../types/types";
const initialState = {
    selectedBankTransfer:null,
    selectedBankTransferFlag:false,
    updataBankTransferFlag:false,
    deleteBankTransferFlag:false,
    bankTransferList:null,
    bankTransferListFlag:false,
}
export const bankTransferReducer  = (state = initialState , action) =>{
    switch(action.type)
    {
        case GET_BANK_TRANSFER : 
            return{...state , selectedBankTransfer : action.payload}
        case GET_BANK_TRANSFER_FLAG : 
            return{...state , selectedBankTransferFlag : action.payload}
        case UPDATE_BANK_TRANSFER_FLAG : 
            return{...state , updataBankTransferFlag : action.payload}
        case DELETE_BANK_TRANSFER_FLAG : 
            return{...state , deleteBankTransferFlag : action.payload}
        case LIST_BANK_TRANSFER : 
            return{...state , bankTransferList : action.payload}
        case LIST_BANK_TRANSFER_FLAG : 
            return{...state , bankTransferListFlag : action.payload}
        default:
            return state;
    }
}