import React, { useState } from "react";
import { ChevronDoubleLeftIcon, TrashIcon } from "@heroicons/react/24/outline";
import { clearBookingOrder, statusCardAction } from "../../../redux/actions/bookingOrderAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearDisplayMultiReservationData } from "../../../redux/actions/MultiReservationAction";
import { clearDisplayReservationData } from "../../../redux/actions/reservationAction";
import { useTranslation } from "react-i18next";

const CardCount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cardStatus, setCardStatus] = useState(true);
  const params = useParams();
  const { cardTotalAmount } = useSelector((state) => state.cardReducer);
  const { cardData } = useSelector((state) => state.cardReducer);
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");

  const clearCard = () => {
    dispatch(clearBookingOrder());
    dispatch(statusCardAction(!cardStatus));
    dispatch(clearDisplayReservationData());
    dispatch(clearDisplayMultiReservationData());
    navigate(`/`);
  };

  return (
    <>
      <div className="h-16 flex items-center justify-between px-3  sticky top-0 left-0 right-0 w-full bg-white shadow-md rounded-md z-50 ">
        <div className="flex items-center gap-3">
          <div className="grid items-center cursor-pointer ">
            <ChevronDoubleLeftIcon
              className={`icon-style   w-5 h-5 text-yellow-600 stroke-[2] ${t(
                "dir" === "en" ? "rotate-0" : " rotate-180",
              )}`}
              onClick={() => dispatch(statusCardAction(!cardStatus))}
            />
          </div>
          <div className="grid items-center">
            <h1 className=" font-medium text-yellow-600 text-xs md:text-md lg:text-base">
              {t("yourBookingOrder")}
              <span
                className={`bg-[#002244] rounded px-1 py-1 md:p-1 text-slate-100  ${t(
                  "dir" === "en" ? "ml-5" : " mr-5",
                )} font-normal text-xs`}
              >
                {new Intl.NumberFormat(localeCode).format(cardTotalAmount)} {t("rooms")}
              </span>
            </h1>
          </div>
        </div>
        <div className="flex items-center ">
          <button type="button" className="rounded bg-red-700 active:scale-90 px-2 py-[.3rem] " onClick={clearCard}>
            <TrashIcon className="w-8 h-5 text-white  " />
          </button>
        </div>
      </div>
    </>
  );
};

export default CardCount;
