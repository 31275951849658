import { useEffect } from "react";

export default function useRemoveScroll(dependencyArr) {
  useEffect(() => {
    function removeScroll(e) {
      e.preventDefault();
    }
    let inputNums = Array.from(document.querySelectorAll('input[type="number"]'));
    inputNums.forEach((input) => {
      input.addEventListener("wheel", removeScroll, { passive: false });
    });
    return () => {
      inputNums.forEach((input) => {
        input.removeEventListener("wheel", removeScroll);
      });
    };
  }, dependencyArr);
}
