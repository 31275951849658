import { DESTINATIONDATA , AREADATA , HOTELDATA , NATIONALITY } from "../types/types";

const initailState = {
    destinationData : [],
    areaData : [],
    hotelData : [],
    nationalities:null
} 


export const SearchFormReducer = (state = initailState , action) =>{
    switch(action.type) {
        case DESTINATIONDATA:
            return {...state , destinationData : action.payload};
        case AREADATA : 
            return {...state , areaData : action.payload }
        case HOTELDATA : 
            return {...state , hotelData : action.payload }
        case NATIONALITY : 
            return {...state , nationalities : action.payload }
        default :
        return state;
    }
}