import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const Footer = () => {
  const [t, i18n] = useTranslation();
  return (
    <div className={` w-full flex items-center justify-center h-10 bg-[#002244] text-white sm:text-md text-xs`}>
      <p className="flex items-center ">{t("copyRight")}</p>
    </div>
  );
};

export default Footer;
