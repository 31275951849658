import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChildren, FaMapLocationDot, FaTreeCity } from "react-icons/fa6";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { MdLocalHotel } from "react-icons/md";
import { IoReturnUpBackOutline, IoReturnUpForwardOutline } from "react-icons/io5";
import { IoIosPerson } from "react-icons/io";
import { GetDataFromSearchForReseve } from "../../../redux/actions/reservationAction";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { clearDataOfRoom, selectedSearchAction } from "../../../redux/actions/SelectedSearchAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { useNavigate } from "react-router";
import { Transition } from "@headlessui/react";
import { MdHistory } from "react-icons/md";
import { Button, Modal } from "antd";
import { CloseOutlined } from "@material-ui/icons";

const PreviousDataSearch = ({ setHandlers }) => {
  const { setSearchFlag, setStar, setValuesArray, setValue } = setHandlers;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const h4Ref = useRef(null); // Ref for the h4 element

  const togglePopup = () => setIsOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) && // Click is outside dropdown
      h4Ref.current &&
      !h4Ref.current.contains(event.target) // Click is outside h4 as well
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const [t, i18n] = useTranslation();
  const DataForReseveAgain = JSON.parse(localStorage.getItem("DataForReseveAgain"));
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();

  const {
    data: citiesData,
    error: citiesError,
    isLoading: citiesloading,
  } = useQuery({
    queryKey: ["get-cities", t("lan")],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`/search/api/v1/get-cities/`, { "Accept-Language": name });
    },
    enabled: !!t("lan"),
  });

  const {
    data: areasData,
    error: areasError,
    isLoading: areasloading,
  } = useQuery({
    queryKey: ["load-areas", t("lan")],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`/search/api/load-areas/?city_name=${"all"}`, { "Accept-Language": name });
    },
    enabled: !!t("lan"),
  });

  const {
    data: hotelData,
    error: hotelError,
    isLoading: hotelloading,
  } = useQuery({
    queryKey: ["hotel-names", t("lan")],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`/${name}/hotels/api/v1/get-hotel-names/`, { "Accept-Language": name });
    },
    enabled: !!t("lan"),
  });

  if (citiesloading || areasloading || hotelloading) {
    return (
      <div className="ww-full flex h-full items-center justify-between ">
        <TopBarProgress />
        <Loading />
      </div>
    );
  }

  const handleClick = async (dataSearch, dataForm) => {
    try {
      await dispatch(clearDataOfRoom());
      await dispatch(selectedSearchAction(token, dataSearch, t("lan")));
      await dispatch(GetDataFromSearchForReseve(dataForm));
      setSearchFlag(true);
      setValue([0, 0]);
      setValuesArray([]);
      setStar();
    } catch (error) {}
  };
  return (
    <section className="">
      <div className="flex   justify-center items-center h-[100%] max-lg:hidden ">
        <div className="w-full ">
          <div className="block  mx-auto border-b border-slate-300  max-w-[360px]">
            <p className=" w-full px-4 py-2 text-center text-2xl font-bold text-[#0e5592] ">{t("SearchAgain")}</p>
          </div>
          <div className="w-full flex justify-center flex-wrap gap-5  ">
            {/* <!-- Centering wrapper --> */}
            {DataForReseveAgain?.map((ReseveAgain, index) => {
              const dataSearch = ReseveAgain.selectedData;
              const dataForm = ReseveAgain.selectedDataForReseve;

              const areaValue = parseInt(dataSearch.area?.replace(/\[|\]/g, ""), 10);
              const peopleArray = JSON.parse(dataSearch.people);

              return (
                <div
                  key={index}
                  className="hover:shadow-md cursor-pointer transition duration-700 ease-in-out relative flex flex-col mt-6 text-gray-700 bg-white boxShadow rounded-xl w-[16rem] gap-3 "
                  onClick={() => {
                    handleClick(dataSearch, dataForm);
                    document.getElementById("hotels")?.scrollIntoView({
                      behavior: "smooth", // smooth scrolling effect
                      block: "start", // scrolls to the start of the element
                    });
                  }}
                >
                  <div className="p-6  relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className={`w-8 h-5 mb-4 text-gray-900  absolute top-[-6px] ${t("lan") === "en" ? "right-[-6px] " : "left-[-6px] rotate-[-85deg]"}`}
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
                        clipRule="evenodd"
                      ></path>
                      <path d="M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z"></path>
                    </svg>
                    {index === 0 && (
                      <div
                        className={`bg-[#0e5592] text-sm rounded-sm p-[0.15rem] text-white absolute top-[-6px] ${t("lan") === "en" ? "left-[-6px] " : "right-[-6px] "}`}
                      >
                        {t("lan") === "en" ? "Last Search" : "أخر بحث"}
                      </div>
                    )}

                    <div className="overflow-hidden flex flex-col gap-1">
                      <div className=" flex items-center gap-5">
                        <div>
                          {" "}
                          <FaTreeCity className=" text-lg text-[#0e5592]" />
                        </div>
                        <h5
                          dir={t("dir")}
                          className="whitespace-nowrap	flex justify-start items-center gap-3  text-md  font-semibold text-[#002244] "
                        >
                          {t("lan") === "en" ? "City" : "المدينة"}
                          {t("lan") === "en" ? <HiArrowNarrowRight /> : <HiArrowNarrowLeft />}
                          {(() => {
                            const citiesName = citiesData?.find((item) => item.id === dataSearch.city)?.name;
                            return citiesName?.length > 9 ? citiesName.slice(0, 9) + "..." : citiesName;
                          })()}
                        </h5>
                      </div>
                      <div className=" flex items-center gap-5">
                        <div>
                          <FaMapLocationDot className=" text-lg text-[#0e5592]" />
                        </div>

                        <h5
                          dir={t("dir")}
                          className="whitespace-nowrap flex justify-start items-center gap-3  text-md  font-semibold text-[#002244] "
                        >
                          {t("lan") === "en" ? "Area" : "المنطقة"}{" "}
                          {t("lan") === "en" ? <HiArrowNarrowRight /> : <HiArrowNarrowLeft />}{" "}
                          {dataSearch.area === "all"
                            ? t("lan") === "en"
                              ? "all"
                              : "الكل"
                            : (() => {
                                const areaName = areasData?.find((item) => item.id === areaValue)?.name;
                                return areaName?.length > 8 ? areaName.slice(0, 8) + "..." : areaName;
                              })()}
                        </h5>
                      </div>
                      <div className=" flex items-center gap-5">
                        <div>
                          <MdLocalHotel className=" text-lg text-[#0e5592]" />
                        </div>

                        <h5
                          dir={t("dir")}
                          className="whitespace-nowrap flex justify-start items-center gap-3  text-md  font-semibold text-[#002244] "
                        >
                          {t("lan") === "en" ? "Hotel" : "الفندق"}{" "}
                          {t("lan") === "en" ? <HiArrowNarrowRight /> : <HiArrowNarrowLeft />}{" "}
                          {dataSearch.hotel === null
                            ? t("lan") === "en"
                              ? "all"
                              : "الكل"
                            : (() => {
                                const hotelName = hotelData?.find((item) => item.id === dataSearch.hotel)?.name;
                                return hotelName?.length > 9 ? hotelName.slice(0, 9) + "..." : hotelName;
                              })()}
                        </h5>
                      </div>
                      <div className=" flex items-center gap-5">
                        <div>
                          <IoReturnUpBackOutline className=" text-lg text-[#0e5592]" />
                        </div>
                        <h5
                          dir={t("dir")}
                          className="whitespace-nowrap flex justify-start items-center  text-sm  font-semibold  text-[#002244]"
                        >
                          {dataSearch.from_date}
                        </h5>
                      </div>
                      <div className=" flex items-center gap-5">
                        <IoReturnUpForwardOutline className=" text-lg text-[#0e5592]" />
                        <h5
                          dir={t("dir")}
                          className="whitespace-nowrap flex justify-start items-center  text-sm  font-semibold text-[#002244]"
                        >
                          {dataSearch.to_date}
                        </h5>
                      </div>
                      <div className="flex items-center gap-5">
                        <p className="flex items-center gap-1 text-[#002244]">
                          <IoIosPerson className="text-xl text-[#0e5592]" /> {peopleArray[0].adults}
                        </p>

                        <p className="flex items-center gap-1 text-[#002244]">
                          <FaChildren className="text-xl text-[#0e5592]" /> {peopleArray[0].children.length}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="lg:hidden relative w-full">
        <h4
          ref={h4Ref} // Attach ref to the h4 element
          className="w-full mx-auto text-center relative justify-center p-2 flex items-center gap-2 rounded-md border-2 border-[#002244] bg-white text-gray-500 cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={togglePopup}
        >
          <MdHistory className={`text-black text-xl absolute ${t("lan") === "ar" ? "right-1" : "left-1"}`} />
          <span className="text-[#002244] text-center flex justify-center items-center font-bold">
            {t("lan") === "en" ? "Recent Searches" : " ابحث مجددا"}
          </span>
        </h4>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 -translate-y-5"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-5"
          className="absolute z-[999] mx-auto w-full bg-white shadow-lg border rounded-md overflow-hidden"
          ref={dropdownRef}
        >
          <div className="relative max-h-[100dvh] overflow-y-auto p-4">
            <div className="flex flex-col gap-4 w-full py-2">
              <h2 dir={t("dir")} className="text-[#002244] font-semibold">
                {t("lan") === "en" ? "Your Recent searches" : " ابحث مجددا"}
              </h2>
              {/* Render the dropdown items here */}
              {DataForReseveAgain?.map((ReseveAgain, idx) => {
                const dataSearch = ReseveAgain?.selectedData;
                const dataForm = ReseveAgain.selectedDataForReseve;
                const areaValue = parseInt(dataSearch.area?.replace(/\[|\]/g, ""), 10);
                const areaName = areasData?.find((item) => item?.id === areaValue)?.name;
                const cityName = citiesData?.find((item) => item.id === dataSearch.city)?.name;
                const hotelName = hotelData?.find((item) => item?.id === dataSearch?.hotel)?.name;
                const placeOutput =
                  dataSearch.hotel &&
                  dataSearch.hotel !== "all" &&
                  dataSearch.hotel !== "الكل" &&
                  dataSearch.hotel !== undefined &&
                  dataSearch.hotel !== null
                    ? hotelName
                    : dataSearch.area && dataSearch.area !== "all" && dataSearch.area !== "الكل"
                      ? areaName
                      : cityName;

                const locale = t("lan") === "ar" ? "ar-EG" : "en-US";
                const formattedFromDate = new Date(dataSearch?.from_date).toLocaleDateString(locale, {
                  day: "numeric",
                  month: "short",
                });
                const formattedToDate = new Date(dataSearch?.to_date).toLocaleDateString(locale, {
                  day: "numeric",
                  month: "short",
                });
                return (
                  <div
                    key={idx}
                    className="flex gap-2 items-center w-full cursor-pointer"
                    onClick={() => {
                      handleClick(dataSearch, dataForm);
                      setIsOpen(false);
                      document.getElementById("hotels")?.scrollIntoView({
                        behavior: "smooth", // smooth scrolling effect
                        block: "start", // scrolls to the start of the element
                      });
                    }}
                  >
                    <MdHistory className="text-gray-500 text-xl" />

                    <div className="text-gray-500 gap-1 text-sm flex flex-col font-semibold w-full">
                      <h3 dir={t("dir")} className="w-full capitalize text-[#002244]">
                        {placeOutput}
                      </h3>
                      {/* Additional information like date, people, etc. */}
                      <div className="w-full flex flex-wrap gap-1">
                        <div className="flex items-center">
                          <h5>{dataSearch.from_date}</h5>&nbsp;-&nbsp;<h5>{dataSearch.to_date}</h5>
                        </div>
                        <div className="h-5 w-[2px] bg-gray-200" />
                        <div className="flex">
                          <span>
                            {dataSearch?.nights} {t("nights")}
                          </span>
                        </div>
                        <div className="h-5 w-[2px] bg-gray-200" />
                        <div className="flex gap-2">
                          <span>
                            {JSON.parse(dataSearch.people)[0]?.adults} {t("adults")}
                          </span>
                          <span>
                            - {JSON.parse(dataSearch.people)[0]?.children.length || 0} {t("children")}
                          </span>
                        </div>
                        <div className="h-5 w-[2px] bg-gray-200" />
                        <div className="flex gap-2">
                          {t("Nationality")}: {dataSearch?.country}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Transition>
      </div>
    </section>
  );
};

export default PreviousDataSearch;
