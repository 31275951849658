import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import { GrUpdate } from "react-icons/gr";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { PiNoteFill } from "react-icons/pi";
import lightToast from "light-toast";
import { BsCalendarWeek, BsCollection } from "react-icons/bs";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { FaWpforms } from "react-icons/fa6";
import TopBarProgress from "react-topbar-progress-indicator";
import { GoPerson } from "react-icons/go";
import PhoneInput from "react-phone-number-input";
import { getSelectedCollectors, updateCollectors } from "../../../redux/actions/collectorsAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { NotificationManager } from "react-notifications";
import { collectorValidation } from "./collectorValidation";
import { useFormik } from "formik";

const CollectorsEdit = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const { bankTransferFlag } = useSelector((state) => state.bookingListReducer);
  const department = [
    { value: "all", name: "All" },
    { value: "hotels_booking", name: "Hotels Booking" },
    { value: "airlines_ticketing ", name: "Airlines Ticketing" },
  ];
  const [selectedCurrencies, setSelectedCurrencies] = useState();

  let departmentAll = department.map((item) => {
    return { value: item.value, label: item.name };
  });

  const params = useParams();
  // Form State
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      collector_name: "",
      collector_department: "",
      collector_mobile: "",
      collector_whatsapp: "",
      notes: "",
      joining_date: "",
    },
    validationSchema: collectorValidation,
    onSubmit: submit,
  });
  useEffect(() => {
    if (params.id) {
      dispatch(
        getSelectedCollectors(token, params.id, (result) => {
          if (result.status === 200) {
            setFieldValue("collector_name", result.data.collector_name);
            setFieldValue("collector_department", result.data.collector_department);
            setSelectedCurrencies([
              { label: result.data.collector_department, value: result.data.collector_department },
            ]);

            setFieldValue("collector_mobile", result.data.collector_mobile);
            setFieldValue("collector_whatsapp", result.data.mobile);
            setFieldValue("joining_date", result.data.joining_date);
            setFieldValue("notes", result.data.notes);
          }
        }),
      );
    }
  }, []);

  function submit(data) {
    const newValues = {
      collector_name: data.collector_name,
      collector_department: data.collector_department,
      collector_mobile: data.collector_mobile,
      notes: data.notes,
    };
    if (params.id) {
      dispatch(
        updateCollectors(token, params.id, newValues, (result) => {
          if (result.status === 200) {
            setSubmitting(false);
            lightToast.success("Collectors List Updated successfully");
            navigate(-1);
          } else {
            setSubmitting(false);

            NotificationManager.error(result?.response?.data[0]?.message);
          }
        }),
      );
    }
  }

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.add_collector"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////

  return (
    <div className="formPage-wrap ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {selectedSingleRoom && permissionFlag ? (
          permission.map((item) => {
            if (item.name === "room_booking.add_collector" && item.value === true) {
              return (
                <div className=" w-[100%]  ">
                  <div className=" flex justify-center ">
                    <p className="header-h2">
                      {" "}
                      <FaWpforms className="w-5 h-5" />
                      Update Collectors Form
                    </p>
                  </div>
                  <form className="form-wrap w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <div className="input-par">
                        <div className="input-chil">
                          <label className=" text-center w-full text-gray-600   ">Collector Name</label>
                          <div className="w-full relative text-gray-600">
                            <input
                              placeholder="Collector Name"
                              name="collector_name"
                              type="text"
                              required
                              value={values.collector_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`w-full pl-8 h-10  border rounded-lg bg-white ${
                                errors.collector_name && touched.collector_name && "border-red-900"
                              }`}
                            />
                            {errors.collector_name && touched.collector_name && (
                              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                                {errors.collector_name}
                              </div>
                            )}
                            <div className="absolute top-[11px] left-2">
                              <GoPerson className="w-5 h-5 text-gray-400" />
                            </div>
                          </div>
                        </div>
                        <div className="input-chil">
                          {" "}
                          <label className=" flex text-gray-600 ">Collector Department</label>
                          <div className="w-full relative text-gray-600">
                            <Select
                              name="collector_department"
                              placeholder="Collector Department"
                              onBlur={handleBlur}
                              value={
                                values.collector_department
                                  ? [{ value: values.collector_department, label: values.collector_department }]
                                  : selectedCurrencies
                              }
                              options={departmentAll}
                              onChange={(e) => {
                                setFieldValue("collector_department", e.value);
                              }}
                              required
                              isSearchable
                              className={`w-full pl-6 h-10  border rounded-lg bg-white ${
                                errors.collector_department && touched.collector_department && "border-red-900"
                              }`}
                            />
                            {errors.collector_department && touched.collector_department && (
                              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                                {errors.collector_department}
                              </div>
                            )}
                            <div className="absolute top-[11px] left-0">
                              <BsCollection className="w-5 h-5 text-gray-400" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="input-par">
                        <div className="input-chil">
                          <label className=" flex text-gray-600 ">Collector Mobile</label>

                          <PhoneInput
                            placeholder="Phone Number"
                            type="text"
                            name="collector_mobile"
                            required
                            value={values.collector_mobile}
                            defaultCountry="EG"
                            // onChange={setPhoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-full px-3 h-10  border rounded-lg bg-white ${
                              errors.collector_mobile && touched.collector_mobile && "border-red-900"
                            }`}
                          />
                          {/* <label className=" flex text-gray-600 ">Collector Whatsapp</label>
                      <div className=" w-full flex flex-col justify-center items-start px-2 h-10  border rounded-md floating-input bg-white ">
                        <div className=" flex items-center gap-x-1  ">
                          <FaWhatsapp className="w-5 h-5 text-gray-400" />
                          {phoneNumber ? phoneNumber : <p className="text-gray-600"> {values.collector_mobile}</p>}
                        </div>
                      </div> */}
                          {errors.collector_mobile && touched.collector_mobile && (
                            <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                              {errors.collector_mobile}
                            </div>
                          )}
                        </div>
                        <div className="input-chil">
                          <label className="flex justify-center text-gray-600 ">Joining Date</label>
                          <div className="w-full relative ">
                            <input
                              placeholder="Joining Date"
                              name="joining_date"
                              type="date"
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.joining_date}
                              className=" w-full px-9 h-10  border rounded-lg floating-input bg-white text-gray-600"
                            />
                            <div className="absolute top-[11px] left-2">
                              <BsCalendarWeek className="w-5 h-5 text-gray-400" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <label className=" flex text-gray-600 ">Notes *</label>
                      <div className="w-full relative ">
                        <textarea
                          name="notes"
                          type="text"
                          required
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className=" w-full px-8  py-2 h-12  border rounded-lg bg-white "
                        />
                        <div className="absolute top-[11px] left-2">
                          <PiNoteFill className="w-5 h-5 text-gray-400" />
                        </div>
                      </div>
                      <button
                        type="Submit"
                        disabled={load}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Update{" "}
                        {load ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <GrUpdate className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              );
            } else {
              return (
                <div className="w-full h-screen ">
                  <NoPermission />
                </div>
              );
            }
          })
        ) : (
          <>
            <TopBarProgress /> <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default CollectorsEdit;
