import React, { useEffect } from "react";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";

function PeriodSelector() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Check if `period` exists in search params
    if (searchParams.get("period")) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("period", searchParams.get("period"));
        return newParams;
      });
    }

    // If `period` is missing, set it to an empty string
    if (!searchParams.get("period")) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("period", "");
        return newParams;
      });
    }
  }, [searchParams, setSearchParams]);

  return (
    <Select
      placeholder={"Filter by Period"}
      onChange={(selectedOption) => {
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.set("period", selectedOption.value);
          return newParams;
        });
      }}
      defaultValue={
        searchParams.get("period")
          ? {
              label: searchParams.get("period")[0].toUpperCase() + searchParams.get("period").slice(1),
              value: searchParams.get("period"),
            }
          : {
              label: "Daily",
              value: "",
            }
      }
      options={[
        { label: "Daily", value: "" },
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Yearly", value: "yearly" },
      ]}
    />
  );
}

export default PeriodSelector;
