import React, { useEffect, useState } from "react";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import { useNavigate, useParams } from "react-router";
import { IoAdd } from "react-icons/io5";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { AiOutlinePercentage } from "react-icons/ai";
import lightToast from "light-toast";
import { getCurrencyMidNumber, getMidNumber } from "../../../redux/actions/posVisaAction";
import { getBankListAction } from "../../../redux/actions/cashAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import { addPosVisaWalletAction } from "../../../redux/actions/walletAction";

const PosVisaWallet = ({ amountWallet, commissionWallet, currencyWallet, subTotal, agent, methodOfPayment }) => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const format = "HH:mm";
  const params = useParams();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [mid, setMid] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [amount, setAmount] = useState(null);
  const [commission, setCommission] = useState(null);
  const [balance, setBalance] = useState("");
  const [currency, setCurrency] = useState("");
  const [bank, setBank] = useState("");
  const [convertAmount, setConvertAmount] = useState(0);
  const [convertRate, setConvertRate] = useState();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [boxName, setBoxName] = useState("");
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    dispatch(getSelectedBookedRoomSingleAction(token, params.id));
    dispatch(getMidNumber(token));
    dispatch(getBankListAction(token));
  }, []);
  useEffect(() => {
    if (currency) {
      dispatch(
        convertingCurrencyAction(token, currency, currencyWallet, (result) => {
          if (result.status === 200) {
            result.data.results.length !== 0
              ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
              : setConvertAmount(amount && amount * 1);
            result.data.results.length !== 0
              ? setConvertRate(...result.data.results.map((item) => item.rate))
              : setConvertRate(1);
          }
        }),
      );
    }
  }, [currencyWallet]);
  const { postPosVisaFlag } = useSelector((state) => state.posVisaReducer);
  const { midNumberData } = useSelector((state) => state.posVisaReducer);
  const { currencyMidNumber } = useSelector((state) => state.posVisaReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  const { currencyMidNumberFlag } = useSelector((state) => state.posVisaReducer);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const { addWalletFlag } = useSelector((state) => state.walletReducer);

  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  let midNumber = midNumberData
    ? midNumberData.map((item, index) => {
        return { value: item.id, label: item.mid_number };
      })
    : [];

  const handleAmount = (e) => {
    setAmount(e.target.value);
    if (commission) {
      const calc = (e.target.value * commission) / 100;
      setBalance(e.target.value - calc);
      setConvertAmount((e.target.value - calc) * convertRate);
    }
  };

  const handleInputChange = (e) => {
    // Use a regular expression to check if the input is a number with up to two digits.
    if (e.target.value <= 9) {
      const isValidInput = e.target.value;
      setCommission(isValidInput); // Change 1 to your desired maximum length

      const calc = (amount * isValidInput) / 100;
      setBalance(amount - calc);
      if (convertRate) {
        setConvertAmount((amount - calc) * convertRate);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!amountWallet || !commission || !currencyWallet || !subTotal || !agent) {
      alert("You Have Empty Field"); // You can replace this with your validation logic
    } else {
      const bankSelect = Bank?.filter((item) => item.label === bank);

      const formData = new FormData(e.target);

      // const transaction_date = `${date},${time}`;
      // const inputDate = moment(transaction_date, "YYYY-MM-DD,HH:mm");
      // const outputFormat = "YYYY-MM-DD[T]HH:mm:ss[Z]";
      // const outputDateString = inputDate.format(outputFormat);
      formData.append("amount", amountWallet);
      formData.append("agent_name", agent);
      formData.append("commission", Number(commissionWallet).toFixed(2));
      formData.append("currency", currencyWallet);
      formData.append("method_of_payment", "visa_pos");
      formData.append("subtotal", Number(subTotal).toFixed(2));
      formData.append("amount_used", 500.0);
      formData.append("rest_of_payment", 500.0);
      formData.append("service_description", "visa_pos");
      formData.append("system_confirmation", "12323");
      formData.append(`pos_machine_transaction[${"bank"}]`, bankSelect[0].value);
      formData.append(`pos_machine_transaction[${"contract_currency"}]`, "USD");
      formData.append(`pos_machine_transaction[${"name"}]`, "visa pos");
      formData.append(`pos_machine_transaction[${"source_name"}]`, sourceName);
      formData.append(`pos_machine_transaction[${"mid_number"}]`, mid);
      formData.append(`pos_machine_transaction[${"currency"}]`, currency);
      formData.append(`pos_machine_transaction[${"amount"}]`, amount);
      formData.append(`pos_machine_transaction[${"commission"}]`, Number(commission).toFixed(2));
      formData.append(`pos_machine_transaction[${"balance"}]`, Number(balance).toFixed(2));
      formData.append(`pos_machine_transaction[${"conversion_rate_price"}]`, convertAmount.toFixed(2));
      formData.append(`pos_machine_transaction[${"conversion_rate"}]`, convertRate);
      formData.append(`pos_machine_transaction[${"transaction_status"}]`, "confirmed");

      dispatch(
        addPosVisaWalletAction(token, formData, (result) => {
          if (result.status === 201) {
            lightToast.success("The Wallet Of Pos Visa Form was completed successfully");
            navigate(-1);
          }
        }),
      );
    }
  };
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {midNumberData ? (
          <div className=" w-full ">
            <form className="mt-2  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <label className=" text-center w-full text-gray-600">Source Name</label>
                <input
                  placeholder="Source Name"
                  type="text"
                  required
                  onChange={(e) => setSourceName(e.target.value)}
                  className=" w-full px-3 h-10  border rounded-md bg-white "
                />

                <label className=" flex text-gray-600 ">MID Number</label>
                <Select
                  options={midNumber}
                  onChange={(e) => {
                    setMid(e.label);
                    setToggleLoading(true);
                    setToggle(false);
                    dispatch(
                      getCurrencyMidNumber(token, e.label, (result) => {
                        if (result.status === 200) {
                          setToggle(true);
                          setToggleLoading(false);
                          setCurrency(result.data.currency);
                          setBank(result.data.bank);
                          dispatch(
                            convertingCurrencyAction(token, result.data.currency, currencyWallet, (result) => {
                              if (result.status === 200) {
                                result.data.results.length !== 0
                                  ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
                                  : setConvertAmount(amount && amount * 1);
                                result.data.results.length !== 0
                                  ? setConvertRate(...result.data.results.map((item) => item.rate))
                                  : setConvertRate(1);
                              }
                            }),
                          );
                        }
                      }),
                    );
                  }}
                  isSearchable
                  className=" w-full  h-10   rounded-md  bg-white "
                  placeholder="Mid Number"
                />
                {toggle ? (
                  <>
                    <label className=" flex text-gray-600 ">Currency</label>
                    <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                      <p className="text-black mt-2">{currencyMidNumber && currencyMidNumber.currency}</p>
                    </div>

                    <label className=" flex text-gray-600 ">Bank Name</label>
                    <div name="bank" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                      <p className="text-black mt-2">{currencyMidNumber && currencyMidNumber.bank}</p>
                    </div>
                  </>
                ) : (
                  toggleLoading && (
                    <div className=" w-full px-3 h-10 flex items-center justify-center  ">
                      <ButtonLoading />
                    </div>
                  )
                )}

                <label className=" flex text-gray-600">Amount</label>
                <input
                  placeholder="Amount"
                  type="Number"
                  step="any"
                  onChange={handleAmount}
                  required
                  className=" w-full px-3 h-10  border shadow-md shadow-gray-100 rounded-md bg-white "
                />
                {amount ? (
                  <>
                    <label className=" flex text-gray-600 ">Commission</label>
                    <div className="w-full relative text-gray-600">
                      <input
                        placeholder="commission"
                        type="number"
                        step="any" // set the step to allow up to two decimal places
                        value={commission}
                        onChange={handleInputChange}
                        required
                        className=" w-full px-8 h-10  border rounded-md floating-input bg-white "
                      />
                      <div className="absolute top-3 left-2">
                        <AiOutlinePercentage className="w-5 h-5 text-gray-400" />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <label className=" flex text-gray-600 ">Commission</label>
                    <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                      <p className="text-gray-400 "> Enter Amount First</p>
                    </div>
                  </>
                )}
                <label className=" flex text-gray-600">Balance</label>
                <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                  {commission && amount ? (
                    <p className="text-black"> {balance}</p>
                  ) : (
                    <p className="text-gray-400 "> Balance</p>
                  )}
                </div>

                {commission && currency && amount && (
                  <>
                    <label className=" flex text-gray-600 ">Amount With Currency Of Contract ({currencyWallet})</label>
                    <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                      <div className="text-black flex items-end gap-x-1">
                        {convertAmount} <span className="text-sm font-semibold text-gray-700">{currencyWallet}</span>
                      </div>
                    </div>
                  </>
                )}

                <label className=" flex text-gray-600 ">Visa Copy</label>
                <input
                  placeholder="Visa Copy"
                  name="pos_machine_transaction[transaction_receipt]"
                  type="file"
                  className=" w-full px-2 py-1 h-10   rounded-md bg-white  border"
                  required
                />
                <button
                  type="Submit"
                  disabled={addWalletFlag}
                  className="flex items-center gap-x-1 px-3 py-1 rounded-md bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  Add{" "}
                  {addWalletFlag ? (
                    <>
                      <TopBarProgress />
                      <ButtonLoading />
                    </>
                  ) : (
                    <IoAdd className="w-5 h-5" />
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="mt-5">
            <ButtonLoading />
          </div>
        )}
      </div>
    </div>
  );
};

export default PosVisaWallet;
