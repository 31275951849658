import {
  GET_HOTEL_BANK_ACCOUNTS,
  GET_HOTELS_WITH_BANK_INFO,
  RESET_BANK_ACCOUNTS,
  GET_PAYMENT_DETAILS_SINGLE_BOOKING,
} from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export function getHotelsWithBankAccounts() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/en/hotels/api/hotels-in-bank-account/`);
      dispatch({ type: GET_HOTELS_WITH_BANK_INFO, payload: response.data });
    } catch (error) {}
  };
}

export function getHotelBankAccounts(id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/en/hotels/api/hotel-account-bank?hotel=${id}&pagination=false`);
      dispatch({ type: GET_HOTEL_BANK_ACCOUNTS, payload: response.data });
      if (response.status === 200 && callback) {
        callback();
      }
    } catch (error) {}
  };
}

export function resetBankAccounts() {
  return { type: RESET_BANK_ACCOUNTS };
}

export function createHotelPaymentOrder(data, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).post(`/booking/api/v1/hotel-payment-order`, data, {
        headers: {
          "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });
      if (response.status === 201) {
        callback();
      }
    } catch (error) {}
  };
}

export const getMethodsPayment = (callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`booking/api/v1/hotel-payment-order/methods_payment/`);
      callback(response);
    } catch (error) {}
  };
};

export function getPaymentDetailsSingleBooking(booking_id) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/booking/api/v1/hotel-payment-order?booking=${booking_id}`);
      dispatch({ type: GET_PAYMENT_DETAILS_SINGLE_BOOKING, payload: response.data });
    } catch (error) {}
  };
}

export const getSelectedMethodPayment = (method, callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`booking/api/v1/hotel-payment-order?method_payment=${method}`);
      callback(response);
    } catch (error) {}
  };
};
export const getSelectedPaymentHotel = (id, callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`booking/api/v1/hotel-payment-order/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const getSelectedHotelBankAccount = (id, callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`en/hotels/api/hotel-account-bank/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const updatePaymentCashDeposit = (id, data, callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).patch(`booking/api/v1/hotel-payment-order/${id}/`, data);
      callback(response);
    } catch (error) {}
  };
};

export const updateCashInHotel = (id, data, callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).patch(`booking/api/v1/hotel-payment-order/${id}/`, data);
      callback(response);
    } catch (error) {}
  };
};

export const updatePaymentLink = (id, data, callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).patch(`booking/api/v1/hotel-payment-order/${id}/`, data);
      callback(response);
    } catch (error) {}
  };
};

export const updateOnlineBank = (id, data, callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).patch(`booking/api/v1/hotel-payment-order/${id}/`, data);
      callback(response);
    } catch (error) {}
  };
};
