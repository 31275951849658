import React from "react";
import { MdOutlineHotelClass } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaHotel } from "react-icons/fa";

const PreferedHotels = () => {
  return (
    <div className="formPage-wrap">
      <div className="form-wrap flex justify-center items-center ">
        <div className="flex justify-center items-center">
          <Link to="/dashboard/addPreferedHotels" className="flex  w-max flex-col gap-2 justify-center items-center ">
            <div className="w-max p-2 bg-[#002244] rounded-full">
              <FaHotel className="w-24 h-24 p-4 text-white hover:scale-105 transition-transform duration-300" />
            </div>
            <span className="font-bold text-[#002244]  transition-transform duration-300">
              Add/Edit Prefered Hotels
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PreferedHotels;
