import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import DeleteButton from "../../../../customComponents/DeleteButton";

function DeleteNoteModal({ id, title }) {
  const queryClient = useQueryClient();

  const { isPending, mutate: deleteNote } = useMutation({
    mutationFn: () => {
      return mutateDataQuery(`/notes-management/api/v1/notes/${id}/`, "delete");
    },
    mutationKey: "deleteNote",
    networkMode: "always",
    retry: false,
    onSuccess: (agent) => {
      toast.success("Note Deleted Successfully");
    },
  });

  const [openModal, setOpenModal] = useState(false);

  const handleDeleteNote = () => {
    deleteNote(null, {
      onSuccess: () => {
        setOpenModal(false);
        void queryClient.refetchQueries({
          queryKey: ["notes"],
        });
      },
    });
  };

  return (
    <>
      <DeleteButton onDelete={() => setOpenModal(true)} />

      <Modal dismissible show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete {title}?
            </h3>
            <div className="flex justify-center gap-4">
              <Button isProcessing={isPending} disabled={isPending} color="failure" onClick={handleDeleteNote}>
                Yes
              </Button>
              <Button disabled={isPending} color="gray" onClick={() => setOpenModal(false)}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteNoteModal;
