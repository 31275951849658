import { Alert } from "flowbite-react";
import React from "react";
import { HiInformationCircle } from "react-icons/hi2";

const Errorfetch = ({ Error }) => {
  return (
    <div className=" w-full flex justify-center p-10">
      {Error ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">Info alert!</span> {Error}.
        </Alert>
      ) : (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">Info alert!</span> failed to retrieve data please try again later.
        </Alert>
      )}
    </div>
  );
};

export default Errorfetch;
