import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteSelectedMeet, getAllAssistAction } from "../../../redux/actions/meetAssistAction";
import { BiTransfer } from "react-icons/bi";
import { GoPerson } from "react-icons/go";
import { LiaCalendar } from "react-icons/lia";
import { Link } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdOutlineSettings } from "react-icons/md";
import lightToast from "light-toast";
import ListFilters from "../../../utils/ListFilters";

const RepresentativeMemberlist = () => {
  const [members, setMembers] = useState();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [id, setId] = useState();
  const [confirm, setConfirm] = useState(false);
  useEffect(() => {
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ///////////////////////////////////////////////////////////
    dispatch(
      getAllAssistAction(signal, (result) => {
        if (result.status === 200) {
          setMembers(result.data);
        }
      }),
    );
    return () => {
      controller.abort();
    };
  }, [load]);
  //////////Rows//////////////
  const rows = members?.map((item, index) => {
    return {
      id: item.id,
      representative_name: item.representative_name,
      licenses_id_number: item.licenses_id_number,
      mobile_number: item.mobile_number,
      created_date: item.created_date,
    };
  });
  //////////Columns/////////////////
  const columns = [
    {
      key: "representative_name",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          REPRESENTATIVE NAME
        </div>
      ),

      dataIndex: "representative_name",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="w-full  pl-2">
            {params.representative_name}
          </Link>
        );
      },
    },

    {
      key: "licenses_id_number",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          LINCENSES ID NUMBER
        </div>
      ),
      dataIndex: "licenses_id_number",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="w-full">
            {params.licenses_id_number}
          </Link>
        );
      },
    },

    {
      key: "mobile_number",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          MOBILE NUMBER
        </div>
      ),
      dataIndex: "mobile_number",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="w-full">
            {params.mobile_number}
          </Link>
        );
      },
    },
    {
      key: "created_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          CREATD DATE
        </div>
      ),
      dataIndex: "created_date",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="w-full pl-3">
            {params.created_date.slice(0, 10)}
          </Link>
        );
      },
    },

    {
      key: "operations",
      filterable: false,
      sortable: false,
      dataIndex: "operations",
      title: (
        <div className=" flex items-center w-full text-xs ">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      render: (text, params) => {
        return (
          <div className="flex items-center justify-start gap-x-3 w-full ">
            <Link
              to={`./${params.id}/preview`}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-slate-900 " />
            </Link>
            <Link
              to={`./${params.id}/update`}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <FiEdit className="w-5 h-5 text-green-800 " />
            </Link>
            <button
              onClick={() => {
                setId(params.id);
                setConfirm(true);
              }}
              className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
            >
              <AiFillDelete className="w-5 h-5 text-red-800" />
            </button>
          </div>
        );
      },
    },
  ];
  //////////handel Delete ////////////
  const handleDelete = () => {
    dispatch(
      deleteSelectedMeet(id, (result) => {
        if (result.status === 204) {
          setMembers(null);
          setLoad(!load);
          lightToast.success("This Meet Service was completed Deleted");
        }
      }),
    );
  };
  return (
    <>
      {confirm && (
        <div
          className={` fixed ${
            confirm ? "top-0 left-0 right-0 bottom-0 h-screen  " : ""
          }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${
              confirm
                ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <p className="text-2xl font-light  text-white">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id);
                }}
                className="bg-green-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      {members ? (
        <div className="w-full formPage-wrap min-h-screen">
          <div className="w-full overflow-auto">
            <ListFilters columns={columns} rows={rows} title="Representitive Member List" />
          </div>
        </div>
      ) : (
        <>
          {" "}
          <TopBarProgress />
          <div className="w-full h-screen flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </>
  );
};

export default RepresentativeMemberlist;
