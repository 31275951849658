import React, { useEffect, useState } from "react";
import "./ContractList.scss";
import { useDispatch, useSelector } from "react-redux";
import { archiveContract, getContractsHotel, getHotelCountry } from "../../../redux/actions/getContracts";
import { useLocation, useNavigate, useParams } from "react-router";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import deleteHotelContract from "../../../redux/actions/deleteContract";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import Select from "react-select";
import { LiaFileContractSolid } from "react-icons/lia";
import { destinationAction } from "../../../redux/actions/searchformAction";
import { RiArchive2Line } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import ListFilters from "../../../utils/ListFilters";
import { splitAndCapitalize } from "../../../utils/stringUtils";
export default function ContractList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [hotelId, setHotelId] = useState();
  const [ContractsData, setContractsData] = useState();
  const { sorting, page } = useParams();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [deletePopup, setDeletePopup] = useState("");
  const [deletecontractId, setDeleteContractId] = useState(null);
  const [archiveContractId, setArchiveContractId] = useState(null);
  const [listPageSize, setListPageSize] = useState(20);
  const { destinationData } = useSelector((state) => state.SearchFormReducer);
  const contracts = useSelector((state) => state.getAllContracts);
  ///// Destination /////////
  let destination = destinationData
    ? destinationData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];
  //////// Hotels ///////////
  let hotelsArr = hotels
    ? hotels.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];
  const currentPage = Number(page) || 1;
  const totalPages = Math.ceil(contracts?.count / listPageSize);
  ////////////////////////
  // useEffect(() => {
  //   dispatch(getContractsHotel(sorting, Number(page),e.value, () => setIsLoading(false)));
  // }, [page, location]);
  useEffect(() => {
    dispatch(destinationAction(token, "en"));
    const currentColumnVisibility = localStorage.getItem("contract-grid-visibility");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility) });
    }
    return () => {
      setHotelId(null);
    };
  }, []);
  const columns = [
    {
      key: "id",
      title: "ID",
      render: (text, params) => {
        return <Link to={`/dashboard/contract-details/${params?.id}`}>{params?.id}</Link>;
      },
      dataIndex: "id",
    },
    {
      key: "status",
      title: "Status",
      render: (text, params) => {
        return <Link to={`/dashboard/contract-details/${params?.id}`}>{params?.status}</Link>;
      },
      dataIndex: "status",
    },
    {
      key: "hotelName",
      title: "Hotel Name",
      render: (text, params) => {
        return <Link to={`/dashboard/contract-details/${params?.id}`}>{params?.hotelName}</Link>;
      },
      dataIndex: "hotelName",
    },
    {
      key: "name",
      title: "Contract Name",
      render: (text, params) => {
        return <Link to={`/dashboard/contract-details/${params?.id}`}>{params?.name}</Link>;
      },
      dataIndex: "name",
    },
    {
      key: "contractType",
      title: "Contract Type",
      dataIndex: "contractType",
    },
    {
      key: "offerType",
      title: "offer Type",
      render: (text, params) => {
        return <Link to={`/dashboard/contract-details/${params?.id}`}>{params?.offerType}</Link>;
      },
      dataIndex: "offerType",
    },
    {
      key: "durationTime",
      title: "Duration Time",
      dataIndex: "durationTime",
    },
    {
      key: "createdBy",
      title: "Created By",
      dataIndex: "createdBy",
    },
    {
      key: "approvedBy",
      title: "Action by",
      dataIndex: "approvedBy",
    },
    {
      key: "created_date",
      title: "Created Date",
      dataIndex: "created_date",
    },
    {
      key: "approvedDate",
      title: "Action Date",
      dataIndex: "approvedDate",
    },
    {
      key: "actions",
      filterable: false,
      sortable: false,
      title: "Actions",
      dataIndex: "actions",
      render: (text, params) => {
        return (
          <div className="w-full flex items-center gap-x-1">
            {permission.map((item) => {
              if (item.name === "contracts_management.view_contract" && item.value === true) {
                return (
                  <Link to={`/dashboard/contract-details/${params.id}`}>
                    <AiOutlineEye
                      className="w-6 h-6 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800"
                      // onClick={() => navigate(`/dashboard/contract-details/${params.id}`)}
                    />
                  </Link>
                );
              } else if (item.name === "contracts_management.change_contract" && item.value === true) {
                return (
                  <div className=" flex items-center gap-x-1">
                    <Link to={`/dashboard/edit-contract/${params.id}`}>
                      {" "}
                      <FiEdit
                        className="w-6 h-6 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
                        // onClick={() => navigate(`/dashboard/edit-contract/${params.id}`)}
                      />
                    </Link>
                    <RiArchive2Line
                      className="w-6 h-6 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-sky-800"
                      onClick={() => {
                        setArchiveContractId(params.id);
                        setDeletePopup(params.name);
                      }}
                    />
                  </div>
                );
              } else if (item.name === "contracts_management.delete_contract" && item.value === true) {
                return (
                  <MdDelete
                    className="w-6 h-6 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
                    onClick={() => {
                      setDeleteContractId(params.id);
                      setDeletePopup(params.name);
                    }}
                  />
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (contracts) {
      const rows = contracts?.results?.map((contract) => {
        let time, hours, mins, secs;
        if (contract.created_time) {
          time = contract.created_time;
          hours = Math.floor(time / 3600);
          mins = Math.floor((time - hours * 3600) / 60);
          secs = time - mins * 60;
        }
        return {
          id: contract.id,
          status: contract.status,
          hotelName: contract.hotel.name,
          name: contract.name,
          contractType: splitAndCapitalize(contract.contract_type, "_"),
          offerType: contract.offer_type.name,
          durationTime: time
            ? `${hours.toString().padStart(2, 0)}:${mins.toString().padStart(2, 0)}:${secs.toString().padStart(2, 0)}`
            : "----",
          createdBy: contract.created_by ? contract.created_by.username : "----",
          approvedBy: contract.user_action ? contract.user_action.username : "----",
          created_date: contract.created_date.split("T")[0],
          approvedDate: contract.action_date ? contract.action_date.split("T")[0] : "----",
        };
      });
      setContractsData(rows);
    }
  }, [contracts]);
  function handlePageChange(params, size) {
    setListPageSize(size);
    navigate(`/dashboard/contract-list/${params}/${sorting}`);
    // setIsLoading(true);
  }
  function comparator([params]) {
    if (params) {
      if (params.sort === "desc") {
        navigate(`/dashboard/contract-list/${page}/-${params.key}`);
      } else {
        navigate(`/dashboard/contract-list/${page}/${params.key}`);
      }
    } else {
      navigate(-1);
    }
  }
  // handle delete contract
  function handleDelete() {
    setIsLoading(true);
    dispatch(
      deleteHotelContract(deletecontractId, sorting, page, (result) => {
        if (result.status === 200) {
          dispatch(
            getContractsHotel(sorting, Number(page), hotelId, () => {
              setIsLoading(false);
              setDeleteContractId(null);
            }),
          );
        }
      }),
    );
    setDeletePopup("");
  }
  // handle Archive Contarct
  function handleaArchive() {
    setIsLoading(true);
    dispatch(
      archiveContract(archiveContractId, (result) => {
        if (result.status === 200) {
          dispatch(
            getContractsHotel(sorting, Number(page), hotelId, () => {
              setIsLoading(false);
              setArchiveContractId(null);
            }),
          );
        }
      }),
    );
    setDeletePopup("");
  }
  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "contracts_management.view_contract",
      "contracts_management.change_contract",
      "contracts_management.delete_contract",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  const handleCityChange = (e) => {
    cookie.save("CityContract", e, { path: "/" });
    cookie.remove("HotelsContract", { path: "/" });
    setContractsData(null);
    setFlag(true);
    dispatch(
      getHotelCountry(e.value, (result) => {
        if (result.status === 200) {
          setFlag(false);
          setHotels(result.data);
        }
      }),
    );
  };
  const handleHotelChange = (e) => {
    setIsLoading(true);
    setHotelId(e.value);
    cookie.save("HotelsContract", e, { path: "/" });
    dispatch(getContractsHotel(sorting, Number(page), e.value, () => setIsLoading(false)));
    navigate("/dashboard/contract-list/1/rejected");
  };
  const city = cookie.load("CityContract");
  const Hotels = cookie.load("HotelsContract");
  useEffect(() => {
    if (Hotels) {
      setIsLoading(true);
      setHotelId(Hotels.value);
      dispatch(getContractsHotel(sorting, Number(page), Hotels.value, () => setIsLoading(false)));
    }
    if (city) {
      setFlag(true);
      dispatch(
        getHotelCountry(city.value, (result) => {
          if (result.status === 200) {
            setFlag(false);
            setHotels(result.data);
          }
        }),
      );
    }
  }, [page]);

  return (
    <div className="formPage-wrap ">
      {permissionFlag && destination.length > 0 ? (
        <div className="w-full min-h-screen  ">
          <div className="w-full ">
            <div className=" flex items-center justify-center  ">
              <h2 className="header-h2 ">
                {" "}
                <LiaFileContractSolid className="w-6 h-6" />
                Contract List
              </h2>
            </div>
            <div className="form-wrap">
              <div className="input-par">
                <div className="input-chil ">
                  <label htmlFor="hotel" className="w-full flex  text-slate-950 font-semibold md:text-xl text-md">
                    Choose City First
                  </label>
                  <Select
                    options={destination}
                    id="hotel"
                    placeholder="Select City"
                    className="text-gray-900 h-full font-semibold capitalize z-50"
                    isSearchable
                    onChange={(e) => {
                      handleCityChange(e);
                    }}
                    defaultValue={city}
                  />
                </div>
                <div className="input-chil ">
                  <label htmlFor="hotel" className="w-full flex  text-slate-950 font-semibold md:text-xl text-md">
                    Choose Hotel
                  </label>
                  {flag ? (
                    <div className="w-full  flex items-center justify-center">
                      <ButtonLoading /> <TopBarProgress />{" "}
                    </div>
                  ) : (
                    <Select
                      options={hotelsArr}
                      id="contract"
                      className="text-gray-900  h-full font-semibold capitalize "
                      isSearchable
                      placeholder="Choose Hotel"
                      onChange={(e) => {
                        handleHotelChange(e);
                      }}
                      defaultValue={Hotels}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-screen">
              <Loading /> <TopBarProgress />
            </div>
          ) : (
            hotelId &&
            ContractsData && (
              <div className="w-full overflow-x-auto mt-6">
                <ListFilters
                  columns={columns}
                  rows={ContractsData}
                  handlePagination={handlePageChange}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  listPageSize={listPageSize}
                  title="Contracts List"
                />
                {/* <DataGrid
                  // className="data-grid"
                  columns={columns}
                  rowCount={contracts.count}
                  rows={ContractsData}
                  slots={{
                    Toolbar: GridToolbar,
                  }}
                  paginationModel={{ page: Number(page - 1), pageSize: 20 }}
                  onPaginationModelChange={handlePageChange}
                  paginationMode="server"
                  onSortModelChange={comparator}
                  columnVisibilityModel={columnVisibility}
                  onColumnVisibilityModelChange={(visibility) => {
                    setColumnVisibility(visibility);
                    localStorage.setItem("contract-grid-visibility", JSON.stringify(visibility));
                  }}
                  className=" lg:w-full  w-[300%]"
                /> */}
              </div>
            )
          )}
          {deletePopup && (
            <DeletePopUp
              name={deletePopup}
              Func={() => {
                archiveContractId ? handleaArchive() : handleDelete();
              }}
              status="Archive"
              hideFunc={() => {
                setDeletePopup("");
              }}
            />
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
