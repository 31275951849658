import React from "react";
import PeriodSelector from "./Components/PeriodSelector";
import RangeSelector from "./Components/RangeSelector";
import UserSelector from "./Components/UserSelector";
import ReportList from "./Components/ReportList";
import usePermissions from "../../../../customHooks/usePermissions";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import NoPermission from "../../NoPermission/NoPermission";

function ContractDepartmentReport() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "permissions_management.view_contract_department_report",
  ]);

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch Error={"Error Fetching Permissions"} />;
  }

  const canViewContractDepartmentReport =
    permissions?.find((permission) => permission.name === "permissions_management.view_contract_department_report")
      ?.value === true;

  return canViewContractDepartmentReport ? (
    <div className="min-h-[100dvh]">
      <div className="flex flex-col gap-8 px-8">
        <div className="flex justify-between flex-col lg:flex-row  gap-2">
          <div className="flex gap-2">
            <PeriodSelector />
            <UserSelector />
          </div>
          <RangeSelector />
        </div>
        <ReportList />
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}

export default ContractDepartmentReport;
