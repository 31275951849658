import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import {
  gateWayAction,
  getCurrencyAction,
  getSelectedBookedRoomSingleAction,
  getServicesTypeAction,
} from "../../../redux/actions/bookingListAction";
import { useNavigate, useParams } from "react-router";
import { IoAdd } from "react-icons/io5";
import { FaWpforms } from "react-icons/fa6";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
// import jwtDecode from 'jwt-decode';
import { TimePicker } from "antd";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendData } from "../../../redux/actions/sendDataAction";
import { ADD_JUMBO4PAY_URL } from "../../../urls";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import { NotificationContainer, NotificationManager } from "react-notifications";

const AddJumbo = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [servicesType, setServicesType] = useState([]);
  const [gateWays, setGateWays] = useState([]);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [amount, setAmount] = useState("");
  const [convertAmount, setConvertAmount] = useState();
  const [convertRate, setConvertRate] = useState();
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const format = "HH:mm";
  useRemoveScroll();
  // const decodedToken = jwtDecode(token);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      employee_name: "",
      total_amount: "",
      currency_type: "",
      invoice_number: "",
      service_type: "",
      date: "",
      time: "",
      gateway: "",
    },
    validationSchema: Yup.object({
      employee_name: Yup.string()
        .required("Please enter employee name")
        .min(1, "Employee name must be greater than or equal to 2")
        .max(50, "Employee name must be smaller than or equal to 50"),
      total_amount: Yup.number()
        .required("Please enter an total amount")
        .positive(" Total_amount must be positive number")
        .integer(" Total_amount must be positive number")
        .min(0, " Total_amount must be greater than or equal to 0"),
      currency_type: Yup.string().required("Please select currency"),
      invoice_number: Yup.number()
        .required("Please enter an invoice number")
        .integer(" Invoice number must be positive number")
        .positive(" Invoice number must be positive number")
        .min(0, " Invoice number must be greater than or equal to 0"),
      service_type: Yup.string().required("Please select service type"),
      date: Yup.date().required("Please enter date"),
      time: Yup.string().required("Please enter time"),
      gateway: Yup.string().required("Please select gateway"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData();
      const { date, time, ...restData } = values;
      Object.keys(restData).forEach((key) => {
        formData.append(key, restData[key]);
      });
      formData.append("booked_room", params.id);
      formData.append("creator", 1);
      formData.append("transaction_date", `${values.date},${values.time}`);
      formData.append("transaction_status", "confirmed");
      formData.append("contract_currency", selectedSingleRoom.currency_type);
      formData.append("conversion_rate_price", convertAmount.toFixed(2));
      formData.append("conversion_rate", convertRate);
      setSubmitting(true);
      dispatch(
        sendData(
          ADD_JUMBO4PAY_URL,
          formData,
          (result) => {
            if (result.status === 201) {
              setSubmitting(false);
              lightToast.success("The Jumbo4pay Form was completed successfully");
              navigate(-1);
            }
          },
          (failure) => {
            setSubmitting(false);
            NotificationManager.error("Jumbo Invoice Number Has Already Been Added Before");
          },
        ),
      );
    },
  });

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.add_payment"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);

  useEffect(() => {
    //////////handle when reload page , alert show to make sure this i want reload ////////////
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ////////////////////////////////////////////////////////

    dispatch(getSelectedBookedRoomSingleAction(token, params.id, (result) => {}, signal));
    dispatch(getCurrencyAction(token, signal));
    dispatch(
      getServicesTypeAction(
        token,
        (result) => {
          setServicesType(result);
        },
        signal,
      ),
    );
    dispatch(
      gateWayAction(
        token,
        (result) => {
          setGateWays(result);
        },
        signal,
      ),
    );
    dispatch(
      getPermissionAction(
        token,
        permissionData,
        (result) => {
          if (result.status === 200) {
            setpermissionFlag(true);
            setpermission(result.data);
          }
        },
        signal,
      ),
    );
    return () => {
      controller.abort();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { jumboFlag } = useSelector((state) => state.bookingListReducer);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  let ServiceType = servicesType
    ? servicesType.map((item, index) => {
        return { value: item.name_1, label: item.name_2 };
      })
    : [];

  let gateway = gateWays
    ? gateWays.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const handleAmount = (e) => {
    setAmount(e.target.value);
    setFieldValue("total_amount", e.target.value);
    if (convertRate) {
      setConvertAmount(e.target.value * convertRate);
    }
  };

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setTime(`${hours1}:${minutes1}`);
    setFieldValue("time", `${hours1}:${minutes1}`);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (time === "") {
  //     alert("Please select a time."); // You can replace this with your validation logic
  //     return;
  //   } else {
  //     const transaction_date = `${date},${time}`;
  //     const formData = new FormData(e.target);
  //     formData.append("booked_room", params.id);
  //     formData.append("creator", 1);
  //     formData.append("transaction_date", transaction_date);
  //     formData.append("transaction_status", "confirmed");
  //     formData.append("contract_currency", selectedSingleRoom.currency_type);
  //     formData.append("conversion_rate_price", convertAmount.toFixed(2));
  //     formData.append("conversion_rate", convertRate);
  //     dispatch(
  //       jumboPayAction(token, formData, (result) => {
  //         if (result.status === 201) {
  //           lightToast.success("The Jumbo4pay Form was completed successfully");
  //           navigate(-1);
  //         }
  //       })
  //     );
  //   }
  // };

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "room_booking.add_payment" && item.value === true) {
            return (
              <div className="w-full">
                <div className="flex flex-col items-center justify-start h-full w-full">
                  <div className=" w-full ">
                    <div className=" flex justify-center ">
                      <p className="header-h2">
                        <FaWpforms className="w-5 h-5" />
                        Jumbo4pay Form{" "}
                      </p>
                    </div>
                    <form className="form-wrap w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                      <div className="w-full flex flex-col justify-center items-center gap-y-2">
                        <label className=" text-center w-full text-gray-600   ">Employee Name</label>
                        <input
                          placeholder="Employee Name"
                          name="employee_name"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className={`w-full px-3 h-10  border rounded-lg bg-white ${
                            touched.employee_name && errors.employee_name && "border border-red-800"
                          } `}
                        />
                        {touched.employee_name && errors.employee_name ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {errors.employee_name}
                          </div>
                        ) : null}
                        <label className=" flex text-gray-600   ">Total Amount </label>
                        <input
                          placeholder="Total Amount"
                          name="total_amount"
                          type="number"
                          step="any"
                          onBlur={handleBlur}
                          onChange={handleAmount}
                          className={`w-full px-3 h-10  border rounded-lg bg-white ${
                            touched.total_amount && errors.total_amount && "border border-red-800"
                          } `}
                        />
                        {touched.total_amount && errors.total_amount ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {errors.total_amount}
                          </div>
                        ) : null}
                        <label className=" flex text-gray-600 ">Currency</label>
                        <Select
                          name="currency_type"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: `${touched.currency_type && errors.currency_type && "rgb(153 27 27)"}`,
                            }),
                          }}
                          options={CurrencyType}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("currency_type", e.label);
                            setLoadingToggle(true);
                            setToggle(false);
                            dispatch(
                              convertingCurrencyAction(token, e.label, selectedSingleRoom.currency_type, (result) => {
                                if (result.status === 200) {
                                  result.data.results.length !== 0
                                    ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
                                    : setConvertAmount(amount && amount * 1);
                                  result.data.results.length !== 0
                                    ? setConvertRate(...result.data.results.map((item) => item.rate))
                                    : setConvertRate(1);
                                  setToggle(true);
                                  setLoadingToggle(false);
                                }
                              }),
                            );
                          }}
                          isSearchable
                          className=" w-full  h-10  border rounded-lg  bg-white "
                          placeholder="Currency"
                        />
                        {touched.currency_type && errors.currency_type ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {errors.currency_type}
                          </div>
                        ) : null}
                        {toggle && amount ? (
                          <>
                            <label className=" flex text-gray-600 ">
                              Amount With Currency Of Contract ({selectedSingleRoom.currency_type})
                            </label>
                            <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                              <div className="text-black flex items-end gap-x-1">
                                {convertAmount}{" "}
                                <span className="text-sm font-semibold text-gray-700">
                                  {selectedSingleRoom.currency_type}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          loadingToggle && (
                            <>
                              <ButtonLoading />
                            </>
                          )
                        )}

                        <label className=" flex text-gray-600   ">Jumbo invoice number</label>
                        <input
                          placeholder="Jumbo invoice number"
                          name="invoice_number"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`w-full px-3 h-10  border rounded-lg bg-white ${
                            touched.invoice_number && errors.invoice_number && "border border-red-800"
                          } `}
                        />
                        {touched.invoice_number && errors.invoice_number ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {errors.invoice_number}
                          </div>
                        ) : null}
                        <label className=" flex text-gray-600 ">Service Type</label>
                        <Select
                          name="service_type"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: `${touched.service_type && errors.service_type && "rgb(153 27 27)"}`,
                            }),
                          }}
                          options={ServiceType}
                          onChange={(e) => setFieldValue("service_type", e.value)}
                          onBlur={handleBlur}
                          isSearchable
                          placeholder="Service Type"
                          className=" w-full  h-10  border rounded-lg  bg-white "
                        />
                        {touched.service_type && errors.service_type ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {errors.service_type}
                          </div>
                        ) : null}
                        <div className="flex items-center gap-x-5 w-full">
                          <div className="w-[50%]">
                            <label className=" flex justify-center text-gray-600 ">Date</label>
                            <input
                              placeholder="Tranfer Date"
                              type="date"
                              name="date"
                              onChange={(e) => {
                                handleChange(e);
                                setDate(e.target.value);
                              }}
                              onBlur={handleBlur}
                              className={`w-full px-3 h-10  border rounded-lg floating-input bg-white ${
                                touched.date && errors.date && "border border-red-800"
                              } `}
                            />
                            {touched.date && errors.date ? (
                              <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                {errors.date}
                              </div>
                            ) : null}
                          </div>
                          <div className="w-[50%]">
                            <label className=" flex justify-center text-gray-600 ">Time</label>
                            <TimePicker
                              clearIcon={false}
                              name="time"
                              format={format}
                              className={`w-full px-3 h-10  border rounded-lg floating-input bg-white ${
                                touched.time && errors.time && "border border-red-800"
                              } `}
                              onChange={handleTimeChange}
                              onBlur={handleBlur}
                            />
                            {touched.time && errors.time ? (
                              <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                {errors.time}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <label className=" flex text-gray-600 ">Gateway</label>
                        <Select
                          name="gateway"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: `${touched.gateway && errors.gateway && "rgb(153 27 27)"}`,
                            }),
                          }}
                          options={gateway}
                          onChange={(e) => setFieldValue("gateway", e.value)}
                          onBlur={handleBlur}
                          type="text"
                          isSearchable
                          className=" w-full  h-10  border rounded-lg  bg-white "
                          placeholder="Gateway"
                        />
                        {touched.gateway && errors.gateway ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {errors.gateway}
                          </div>
                        ) : null}
                        <button
                          type="Submit"
                          disabled={isSubmitting}
                          className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                        >
                          Add{" "}
                          {isSubmitting ? (
                            <>
                              <TopBarProgress />
                              <ButtonLoading />
                            </>
                          ) : (
                            <IoAdd className="w-5 h-5" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            );
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};
export default AddJumbo;
