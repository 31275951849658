import api_token from "../../api/UserApi";
import {
  ALL_TRANSCATIONS,
  ALL_TRANSCATIONS_FLAG,
  SELECTED_TRANSCATIONS,
  SELECTED_TRANSCATIONS_FLAG,
} from "../types/types";

export const getAllTransactionAction = (token, page, sorting) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_TRANSCATIONS_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(
        `booking/api/v1/transaction-table/?ordering=${sorting}&page=${page}&page_size=10`,
      );
      dispatch({
        type: ALL_TRANSCATIONS,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: ALL_TRANSCATIONS_FLAG,
          payload: false,
        });
      }
    } catch (error) {}
  };
};

export const getSelectedTransactionAction = (token, id, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SELECTED_TRANSCATIONS_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(
        `booking/api/v1/transaction-table/?booked_room_id=${id}&ordering=-id&page=1&page_size=100`,
        { signal },
      );

      dispatch({
        type: SELECTED_TRANSCATIONS,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: SELECTED_TRANSCATIONS_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
