import React, { useState } from "react";
import { menuJsonState, menus } from "./menus";
import useData from "../../../customHooks/useData";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { sendData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

export function removeSpaces(sentence) {
  let arr = sentence.toLowerCase().split(" ");
  return arr.join("_");
}

export function MenuGroup({ icon, text, children, onCheck, parentCheck }) {
  const [hideChildren, setHideChildren] = useState(true);

  return (
    <div className="w-full flex flex-col  my-2 gap-4 items-center text-center">
      <div className=" w-full  flex gap-2 items-center justify-between shadow-md rounded-md border px-3 py-1.5">
        <div className="flex gap-2 ">
          {icon}
          <div className="font-bold text-lg">{text}</div>
        </div>
        <input
          type="checkbox"
          name={removeSpaces(text)}
          onChange={(e) => {
            setHideChildren(!hideChildren);
            parentCheck(e, children);
          }}
          className="w-4 h-4   cursor-pointer"
        />
      </div>
      {!hideChildren && (
        <div className="  w-full flex flex-col gap-2 border p-2 rounded-md">
          {children.map((child) => {
            return (
              <div className="flex justify-between text-base items-center gap-4 shadow-md rounded-md px-3 py-1.5">
                <div>{child}</div>
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  name={removeSpaces(child)}
                  onChange={(e) => onCheck(e)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default function AddMenus() {
  const dispatch = useDispatch();
  const groups = useData("/menu/api/v1/unassigned-groups/");
  const unassigned_groups = groups ? groups.unassigned_groups : [];
  const [menuJson, setMenuJson] = useState(menuJsonState);
  const [chosenGroup, setChosenGroup] = useState(null);

  function onCheck(e) {
    const menuJsonCopy = { ...menuJson };
    if (e.target.checked) {
      menuJsonCopy[e.target.name] = true;
    } else {
      menuJsonCopy[e.target.name] = false;
    }
    setMenuJson(menuJsonCopy);
  }

  function parentCheck(e, children) {
    const menuJsonCopy = { ...menuJson };
    if (e.target.checked) {
      menuJsonCopy[e.target.name] = true;
    } else {
      menuJsonCopy[e.target.name] = false;
      const childrenModNames = children.map((child) => removeSpaces(child));
      childrenModNames.forEach((child) => {
        menuJsonCopy[child] = false;
      });
    }
    setMenuJson(menuJsonCopy);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const data = {
      group_permissions: chosenGroup.value,
      json_menu: {
        menu: [menuJson],
      },
    };
    dispatch(sendData("/menu/api/v1/menu-groups/", data, () => toast.success("Your menu was added successfully")));
  }

  return (
    <div className="formPage-wrap">
      {!groups && <Loading />}
      {groups && (
        <div className="flex flex-col items-center  h-full w-full">
          <div className=" w-full  ">
            <div className="flex justify-center">
              <h2 className="header-h2">Add Menu</h2>
            </div>
            <div className="w-full flex mt-6 max-w-2xl mx-auto bg-gray-50 rounded-lg border p-2 max-sm:flex-col  justify-center items-center gap-y-2">
              <label htmlFor="group" className="sm:flex-[1] w-full font-bold tracking-wide text-xl">
                Choose Group:
              </label>
              <Select
                options={unassigned_groups.map((group) => ({ label: group.name, value: group.id }))}
                className="w-full h-10 sm:flex-[3]   bg-white "
                onChange={(e) => setChosenGroup(e)}
                required
              />
            </div>
            <form onSubmit={handleSubmit} className="form-wrap">
              <div className="grid  lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4  my-3">
                {menus.map((menu) => {
                  return (
                    <MenuGroup
                      icon={menu.icon}
                      text={menu.text}
                      children={menu.children}
                      onCheck={onCheck}
                      parentCheck={parentCheck}
                    />
                  );
                })}
              </div>
              <div className="w-full flex justify-center items-center">
                <button type="submit" className="addButton">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
