import "react-phone-number-input/style.css";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ClientForm from "./ClientForm";
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import NoPermission from "../NoPermission/NoPermission";
import { useNavigate } from "react-router";

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    if (!list) return true; // If the list is empty, skip the test

    const set = new Set(list.map(mapper));
    const isUnique = set.size === list.length;

    if (!isUnique) {
      return this.createError({
        path: this.path,
        message: message,
      });
    }

    return true;
  });
});

const AddClientValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .test("is-three-words", "The name must consist of at least three words", (value) => {
      if (value) {
        // Split the string by spaces and filter out empty strings
        const words = value.split(" ").filter((word) => word.trim().length > 0);
        return words.length >= 3;
      }
      return false;
    }),
  firstName: Yup.string().notRequired(),
  lastName: Yup.string().notRequired(),
  username: Yup.string()
    .required("Username is required")
    .test("no-spaces", "Username must not contain spaces", (value) => {
      // Ensure the value is defined and doesn't contain any spaces
      return value && !/\s/.test(value);
    }),
  nickname: Yup.string().required("Nickname is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  id: Yup.string().required("ID Number is required"),
  country: Yup.string().required("Country is required"),
  mobileNumbers: Yup.array()
    .of(
      Yup.object().shape({
        num: Yup.string()
          .trim()
          .matches(/^\+?[0-9]{5,}$/, "Please add a valid phone number with at least 5 digits")
          .min(5, "Please add at least 5 numbers")
          .required("This field is required"), // these constraints take precedence
        whatsapp: Yup.boolean().default(false), // these constraints take precedence
      }),
    )
    .required("Must Add at least one mobile number")
    .unique("Mobile numbers must be unique", (item) => item.num),
  markup: Yup.number().required("Please select a markup"),
  groups: Yup.array()
    .of(Yup.number())
    .min(1, "Please select at least one group")
    .required("Please select at least one group"),
});

const AddClientForm = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState({
    src: "",
    file: null,
  });

  const { isPending, mutate: createAgent } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery("/auth/api/v1/user-customer/", "post", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: (client) => {
      toast.success("Client Created Successfully");
      navigate(`/dashboard/permissions/search-permission/create/${client.id}`);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to create Client");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to create Client");
    },
  });

  function handleSubmit(values, helpers) {
    const formData = new FormData();

    if (image.file) formData.append("profile_customer[avatar]", image.file);

    formData.append("profile_customer[customer_name]", values.name);

    formData.append("first_name", values.name);

    formData.append("last_name", values.name);

    formData.append("username", values.username);

    formData.append("profile_customer[nickname]", values.nickname);

    formData.append("email", values.email);

    formData.append("password", values.password);

    formData.append("profile_customer[id_number]", values.id);

    formData.append("profile_customer[country]", values.country);

    if (values.city !== "") {
      formData.append("profile_customer[city]", values.city);
    }

    const mobileObject = values.mobileNumbers.reduce((acc, number, index) => {
      acc[index + 1] = number.num;
      return acc;
    }, {});

    const mobileJson = JSON.stringify(mobileObject);
    formData.append("profile_customer[mobile_numbers]", mobileJson);

    const whatsappArray = values.mobileNumbers.filter((number) => number.whatsapp);

    const whatsappObject = whatsappArray.reduce((acc, number, index) => {
      acc[index + 1] = number.num;
      return acc;
    }, {});

    const whatsappJson = JSON.stringify(whatsappObject);
    formData.append("profile_customer[whats_app_numbers]", whatsappJson);

    formData.append("profile_customer[notes]", values.notes);
    formData.append("is_active", values.active);

    formData.append("profile_customer[markup]", values.markup);

    formData.append("groups", JSON.stringify(values.groups));

    formData.append("profile_customer[preferences_accounting]", values.clientAccount);
    formData.append("profile_customer[preferences_hotels]", JSON.stringify(values.favoriteHotels));
    formData.append("profile_customer[preferences_cities]", JSON.stringify(values.favoriteCities));
    formData.append("profile_customer[preferences_smoking]", values.smokingPreferences);
    formData.append("profile_customer[preferences_bed]", values.bedPreferences);
    formData.append("profile_customer[extra_info]", values.extraInfo);

    const user = jwtDecode(cookie.load("access_token"));

    formData.append("profile_customer[created_by]", user.user_id);

    createAgent(formData, {
      onSuccess: () => {
        helpers.resetForm();
        setImage({
          src: "",
          file: null,
        });
      },
    });
  }

  const [permissions, permissionFlag, permissionsError] = usePermissions(["auth_users.add_profile_customer"]);

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const isAddClientAllowed =
    permissions?.find((permission) => permission.name === "auth_users.add_profile_customer")?.value === true;

  return (
    <>
      {isAddClientAllowed ? (
        <ClientForm
          submitButtonTitle={"Add Client and Create Search Permission"}
          validationSchema={AddClientValidationSchema}
          image={image}
          setImage={setImage}
          title={"Add Client"}
          isPending={isPending}
          onSubmit={handleSubmit}
          initialValues={{
            name: "",
            firstName: "",
            lastName: "",
            username: "",
            nickname: "",
            email: "",
            password: "",
            id: "",
            countryName: "Saudi Arabia",
            country: 194,
            groups: [],
            city: null,
            cityName: null,
            notes: "",
            mobileNumbers: [
              {
                num: "+20",
                whatsapp: false,
              },
            ],
            markup: null,
            active: true,
            whitelist: true,
            favoriteCities: [],
            favoriteHotels: [],
            clientAccount: "pay_first_night",
            smokingPreferences: "",
            bedPreferences: "",
            extraInfo: "",
          }}
        />
      ) : (
        <NoPermission />
      )}
    </>
  );
};

export default AddClientForm;
