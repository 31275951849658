import api_token from "../../api/UserApi";
import cookie from "react-cookies";

export const postAllBankChequesAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post("accounting/api/v1/chequebooks/", data);
      callback(response);
    } catch (error) {}
  };
};

export const getAllBankChequesAction = (token, callback, ErrorCallBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/chequebooks/`);
      callback(response);
    } catch (error) {
      ErrorCallBack(error.message);
    }
  };
};
export const getBankChequesIdAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/chequebooks/${id}/`);
      callback(response);
    } catch (error) {}
  };
};
export const updateBankChequesIdAction = (token, id, values, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).put(`accounting/api/v1/chequebooks/${id}/`, values);
      callback(response);
    } catch (error) {}
  };
};
export const deleteBankChequesIdAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`accounting/api/v1/chequebooks/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

//  cheques Actions

export const getAllChequesAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/cheques/`);
      callback(response);
    } catch (error) {}
  };
};
export const getAllChequesforCheckBookAction = (token, id, page, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/cheques-by-chequebook/${id}/`, {
        params: { page: page, page_size: 10 },
      });
      callback(response);
    } catch (error) {}
  };
};
export const getChequesActionId = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/cheques/${id}/`);
      callback(response);
    } catch (error) {}
  };
};
export const UpdateChequesAction = (token, id, values, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`accounting/api/v1/cheques/${id}/`, values);
      callback(response);
    } catch (error) {}
  };
};
export const ChequesStatusAction = (token, id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const res = await api_token(token).patch(`accounting/api/v1/cheques/${id}/`, data);
      callback(res);
    } catch (error) {}
  };
};
export const DeleteChequesAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`accounting/api/v1/cheques/${id}/`);
      callback(response);
    } catch (error) {}
  };
};
