import { GET_COUNTRIES, GET_CITIES, GET_AREAS } from "../types/types";

const defaultState = {
  countries: [],
  cities: [{ name: "please pick a country first to get its cities" }],
  areas: [{ name: "please select a city first" }],
};

export default function formCountriesReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      return { ...state, countries: action.payload };
    case GET_CITIES:
      return { ...state, cities: action.payload };
    case GET_AREAS:
      return { ...state, areas: action.payload };

    default:
      return state;
  }
}
