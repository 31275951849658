import { ErrorMessage, useFormikContext } from "formik";
import { FieldErrorText, FormLabel } from "../../../../../customComponents/FormComponents";
import Select from "react-select";
import React from "react";

function SelectedUsers() {
  const { values, setFieldValue } = useFormikContext();

  const customComponents = {
    DropdownIndicator: () => null, // Removing the default arrow
    IndicatorSeparator: () => null, // Optional: Remove the separator between the arrow and the input
  };

  return (
    <>
      <FormLabel label={"Selected Users"} htmlFor={"users"} />
      <Select
        components={customComponents}
        openMenuOnClick={false}
        openMenuOnFocus={false}
        c
        styles={{
          container: (provided) => ({
            ...provided,
            marginTop: "4px !important",
          }),
        }}
        placeholder={`${values.users?.length === 0 && "No users selected"}`}
        value={values.users?.map((user) => ({
          value: user.id,
          label: `${user.first_name} ${user.last_name} (${user.username})`,
        }))}
        isMulti
        options={values.users?.map((user) => ({
          value: user.id,
          label: `${user.first_name} ${user.last_name} (${user.username})`,
        }))}
        isDisabled={values.users?.length === 0}
        onChange={(selectedOptions, actionMeta) => {
          const { action, option, removedValue } = actionMeta;

          switch (action) {
            case "remove-value":
            case "pop-value": {
              const value = removedValue.value;
              setFieldValue(
                "users",
                values.users.filter((user) => user.id !== value),
              );
              break;
            }

            case "clear": {
              setFieldValue("users", []);
              break;
            }

            case "select-option": {
              const value = option.value;
              setFieldValue("users", [...values.users, value]);
              break;
            }

            default:
              break;
          }
        }}
      />
      <ErrorMessage name={"users"} render={(msg) => <FieldErrorText message={msg} />} />
    </>
  );
}

export default SelectedUsers;
