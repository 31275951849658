import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { MdOutlineDone } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { FaRegCalendarAlt } from "react-icons/fa";
import { getSelectedPaymentTransAction } from "../../../redux/actions/paymentTransactionAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const PaymentTransactionDetails = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [servicesType, setServicesType] = useState([]);
  const [gateWays, setGateWays] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  // const decodedToken = jwtDecode(token);

  useEffect(() => {
    dispatch(getSelectedPaymentTransAction(token, params.id, (result) => {}));
  }, []);
  const { getSelectePayment } = useSelector((state) => state.paymentTransactionReducer);
  const { getSelectePaymentFlag } = useSelector((state) => state.paymentTransactionReducer);

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.view_payment"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full h-full overflow-x-auto ">
      {getSelectePayment && !getSelectePaymentFlag && permissionFlag ? (
        permissionFlag &&
        permission.map((item) => {
          if (item.name === "room_booking.view_payment" && item.value === true) {
            return (
              <div className="container border-gray-300 border rounded-lg  p-10 ">
                <div className="flex flex-col justify-center items-center ">
                  <div className="flex flex-col    w-full  text-black rounded-md  ">
                    <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                      <p className="flex items-center gap-x-3">
                        <TbListDetails className="w-5 h-5" />
                        Jumbo4Pay Details{" "}
                      </p>
                    </div>
                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Total Amount</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">
                            {" "}
                            {getSelectePayment.total_amount}
                            <span>{getSelectePayment.currency_type}</span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">ID</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1"> {getSelectePayment.id}</p>
                        </div>
                      </div>
                    </div>
                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3  ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Convertion Rate</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1"> {getSelectePayment.conversion_rate}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Creator</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1"> {getSelectePayment.creator}</p>
                        </div>
                      </div>
                    </div>
                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Convertion Price</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">
                            {" "}
                            {getSelectePayment.conversion_rate_price}
                            <span>{getSelectePayment.contract_currency}</span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Invoice Number</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.invoice_number} </p>
                        </div>
                      </div>
                    </div>
                    {/* Client & phone Number*/}
                    <div className="flex w-full justify-between items-center  p-3">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Currency </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.currency_type}</p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Service Type</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.service_type} </p>
                          <p className="flex items-center gap-x-1"></p>
                        </div>
                      </div>
                    </div>
                    {/* Client & phone Number*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Contract Currency </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.contract_currency}</p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Booked Room </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.booked_room_name}</p>
                        </div>
                      </div>
                    </div>

                    {/* Client text & Client Tag Request*/}
                    <div className="flex w-full justify-between items-center  p-3  ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Employee Name</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.employee_name}</p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">GateWay</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.gateway}</p>
                        </div>
                      </div>
                    </div>
                    {/* Client text & Client Tag Request*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">GateWay Name</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.gateway_name}</p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Created Date </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">
                            <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />
                            {getSelectePayment.transaction_date.slice(0, 10)}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Hotel & Room Name*/}
                    <div className="flex w-full justify-between items-center  p-3">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Transaction Date</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">{getSelectePayment.transaction_date.slice(0, 10)}</p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Transaction Status</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800 capitalize">
                          {getSelectePayment.transaction_status === "confirmed" ? (
                            <p className="w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-green-700 font-semibold">
                              {getSelectePayment.transaction_status}{" "}
                              <MdOutlineDone className="w-4 h-4  bg-green-700 border text-white rounded-full " />
                            </p>
                          ) : (
                            <p className="w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-yellow-600 font-semibold">
                              {getSelectePayment.transaction_status}....
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return <NoPermission />;
          }
        })
      ) : (
        <>
          <TopBarProgress />
          <div className="w-full h-full flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentTransactionDetails;
