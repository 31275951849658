import React, { useEffect, useState } from "react";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import TicketReply from "../ITDepartment/Tickets/ITTicketDetails/TicketReply";
import SpeicalRateReplyForm from "./SpecialRateComments";
import { Link } from "react-router-dom";
import ChangeStatusForm from "./ChangeStatus";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import NoPermission from "../NoPermission/NoPermission";
import usePermissions from "../../../customHooks/usePermissions";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";

const SpecialRateView = () => {
  const { id } = useParams();

  const [specialRate, setSpecialRateData] = useState();
  const [comments, setComments] = useState();
  const [clicked, setClicked] = useState(false);

  const {
    data: rate,
    error,
    isSuccess,

    isLoading: rateLoading,
  } = useQuery({
    queryKey: ["rate", id],
    queryFn: () => fetchDataQuery(`/sales-management/api/v1/special-rate-requests/${id}/`),
  });

  const {
    data: rateComment,
    error: errorComment,
    isSuccess: successComment,
    isLoading: commentLoading,
    refetch: refetchRateDetails,
  } = useQuery({
    queryKey: ["rateComment", id],
    queryFn: () => fetchDataQuery(`/sales-management/api/v1/special-rate-comments/?special_rate_id=${id}`),
  });
  const {
    mutate: sendToHotel,
    isLoading,
    isPending,
  } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(" /sales-management/api/v1/send-special-rate-message-to-hotel/", "post", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: "speicalTool",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Special Rate Created Successfully");
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to create special rate");
    },
  });

  // useEffect(() => {
  //   if (clicked) {
  //     const sendData = async () => {
  //       try {
  //         await sendToHotel({ special_rate_request_id: id });
  //         toast.success("Data sent to hotel");
  //       } catch (err) {
  //         toast.error(err.message || "Can't send data to hotel");
  //       }
  //     };
  //     sendData(); // Invoke the async function here
  //   }
  // }, [clicked, id, sendToHotel]);

  useEffect(() => {
    if (isSuccess && rate) {
      setSpecialRateData(rate);
    }
  }, [rate, isSuccess]);
  useEffect(() => {
    if (successComment && rateComment) {
      setComments(rateComment);
    }
  }, [rateComment, successComment]);
  const replies = comments?.map((comment) => {
    return {
      username: comment?.created_by?.username,
      userId: comment?.created_by?.id,
      avatar: comment?.created_by?.avatar,
      createdAt: comment?.created_at,
      reply: comment?.comment,
      attachments: comment?.attachments?.map((attachment) => attachment.file),
    };
  });
  const [permissions, permissionFlag, permissionsError] = usePermissions(["sales_management.view_specialraterequest"]);
  const isViewingrequestListAllowed =
    permissions?.find((permission) => permission.name === "sales_management.view_specialraterequest")?.value === true;

  // this will delete the hotel stored in the state by passing it to the action then remove the popup

  if (!permissionFlag) {
    return <Loading />;
  }

  if (error || !rate) {
    <Errorfetch Error={error?.message || "Error Fetching Special rate request "} />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;
  const adults = specialRate?.number_of_persons;
  const children = specialRate?.persons_data.children.length;

  // Generate child age data
  const childrenDetails = specialRate?.persons_data.children?.map((age, index) => {
    return `${age}`;
  });

  if (!specialRate) {
    return (
      <div className="flex w-full h-full justify-center items-center p-4">
        <p className="text-xl text-gray-500 form-wrap text-center">No Data Available</p>
      </div>
    );
  }
  if (rateLoading || commentLoading) {
    return <Loading />;
  }

  if (!isViewingrequestListAllowed) {
    return <NoPermission />;
  }
  return (
    <section className="formPage-wrap  flex flex-col gap-4">
      {/* {true && (
            <button className="addButton " onClick={() => setClicked(true)}>
              Send to Hotel
            </button>
          )} */}
      {isViewingrequestListAllowed && (
        <div className="w-full justify-center items-center flex">
          <ChangeStatusForm status={specialRate?.status} />
        </div>
      )}
      <div className="shadow-lg p-4 !max-w-full !w-full   flex flex-col">
        <h2 className="text-2xl font-bold text-gray-800 ">Special Rate Tool #{id} Details</h2>
        <div className="    w-full flex flex-col mt-4 gap-2 ">
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold ">City:</span>
            <span className="text-gray-500 text-sm font-bold rounded-md">{specialRate?.hotel.city.name}</span>
          </div>
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold">Hotel:</span>
            <span className="text-gray-500 text-sm font-bold rounded-md">{specialRate?.hotel.name}</span>
          </div>
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold">Rooms Type :</span>
            <span className="text-gray-500 rounded-md flex gap-2">
              {specialRate?.room_type?.map((i, idx) => (
                <p key={idx} className="flex gap-2 text-sm font-bold bg-gray-100 p-2 rounded-md">
                  {i?.room_name}
                </p>
              ))}
            </span>
          </div>
          {/* <div className="flex gap-2 items-center  w-full">
                <span className="">Number of Persons:</span>
                <span className="text-gray-500 rounded-md">{specialRate?.number_of_persons}</span>
              </div> */}
          <div className="flex gap-2 items-center   w-full">
            <div>
              {/* <h2 style={{ color: "#333", marginBottom: "10px" }}>Person Data</h2> */}
              <div className="flex gap-2 items-center">
                <span className="font-bold">Number of adults: </span>
                <span className="text-gray-500 text-sm font-bold "> {adults}</span>
              </div>
              <div className="  flex gap-2  items-center">
                <div className="flex gap-2 items-center">
                  <span className="font-bold">Number of children: </span>
                  <span className="text-gray-500 text-sm font-bold">{childrenDetails?.length}</span>
                </div>
                <ul className="flex gap-1 items-center">
                  {childrenDetails?.map((age, index) => (
                    <li className="" key={index}>
                      <span className="text-gray-500 text-sm font-bold">({age}y)</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold">Meals Plan:</span>
            <span className="text-gray-500 rounded-md text-sm font-bold">{specialRate?.meal_plan}</span>
          </div>
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold">Check in Date:</span>
            <span className="text-gray-500 rounded-md text-sm font-bold">{specialRate?.check_in_date}</span>
          </div>
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold">Check out Date:</span>
            <span className="text-gray-500 rounded-md text-sm font-bold">{specialRate?.check_out_date}</span>
          </div>
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold">Client name:</span>
            <span className="text-gray-500 rounded-md text-sm font-bold">{specialRate?.client_name}</span>
          </div>
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold">Client Number:</span>
            <span className="text-gray-500 rounded-md text-sm font-bold">{specialRate?.client_number}</span>
          </div>
          <div className="flex gap-2 items-center  w-full">
            <span className="font-bold"> Status:</span>
            <span
              className={`text-sm font-bold ${specialRate?.status === "New" ? "text-green-500" : specialRate?.status === "Pending" ? "text-blue-400" : "text-black"} rounded-md`}
            >
              {specialRate?.status}
            </span>
          </div>
          <div className="flex flex-col gap-2   w-full">
            <span className="text-center"> Details:</span>
            <span dir="rtl" className=" w-full p-4 border border-dashed border-[#000]  rounded-md">
              {/* {specialRate?.request_details} */}
              <div dangerouslySetInnerHTML={{ __html: specialRate?.request_details }} />
            </span>
          </div>
        </div>
      </div>
      <div className="min-h-max">
        <div className="space-y-2 mt-4 mb-4">
          {replies?.map((reply, idx) => {
            return <TicketReply key={idx} reply={reply} />;
          })}
          <SpeicalRateReplyForm refetchRateDetails={refetchRateDetails} />
        </div>
      </div>
    </section>
  );
};

export default SpecialRateView;
