import { useState, useEffect } from "react";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { getPermissionAction } from "../redux/actions/permissionAction";

export default function usePermissions(permissionsArr) {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: permissionsArr,
  };
  const [permissions, setpermissions] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  const [permissionsError, setpermissionsError] = useState();
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermissions(result.data);
        } else {
          setpermissionsError(result);
        }
      }),
    );
  }, []);

  return [permissions, permissionFlag, permissionsError];
}
