import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import { FaDatabase, FaGear } from "react-icons/fa6";
import { MdOutlineDateRange } from "react-icons/md";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router";
import { IoMdPerson } from "react-icons/io";
import { FaHotel } from "react-icons/fa";
import { ColumnHeader, DetailsModal } from "../../../../customComponents/TableComponents";
import { BiMessageAltError } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiFillDelete, AiOutlineFieldNumber, AiOutlineLoading } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { Button, Modal } from "flowbite-react";
import usePermissions from "../../../../customHooks/usePermissions";
import NoPermission from "../../NoPermission/NoPermission";

function ClientBookingHistoryList(props) {
  const { clientID } = useParams();

  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "auth_users.view_customerbookinghistory",
    "auth_users.change_customerbookinghistory",
    "auth_users.delete_customerbookinghistory",
  ]);

  const isViewCustomerBookingHistoryAllowed =
    permissions?.find((permission) => permission.name === "auth_users.view_customerbookinghistory")?.value === true;

  const isEditCustomerBookingHistoryAllowed =
    permissions?.find((permission) => permission.name === "auth_users.change_customerbookinghistory")?.value === true;

  const isDeleteCustomerBookingHistoryAllowed =
    permissions?.find((permission) => permission.name === "auth_users.delete_customerbookinghistory")?.value === true;

  const {
    isLoading: isFetchingBookingHistory,
    data: historyList,
    error: errorFetchingHistoryList,
  } = useQuery({
    queryKey: ["client-booking-history"],
    queryFn: () => {
      return fetchDataQuery(`/auth/api/v1/customer-booking-history/?customer_id=${clientID}`);
    },
  });

  if (isFetchingBookingHistory || !permissionFlag) {
    return <Loading />;
  }

  if (errorFetchingHistoryList || !historyList) {
    <Errorfetch Error={errorFetchingHistoryList.message} />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const rows = historyList?.map((history) => {
    return {
      id: history.id,
      date: history.date,
      hotel: history.hotels.name,
      numberNights: history.number_nights,
      notes: history.notes,
      clientName: history.customer.customer_name,
      rooms: history.rooms,
    };
  });

  const columns = [
    {
      field: "id",
      renderHeader: () => <ColumnHeader label={"ID"} Icon={FaDatabase} />,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "date",
      renderHeader: () => <ColumnHeader label={"Date"} Icon={MdOutlineDateRange} />,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "clientName",
      renderHeader: () => <ColumnHeader label={"Client Name"} Icon={IoMdPerson} />,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "hotel",
      renderHeader: () => <ColumnHeader label={"Hotel"} Icon={FaHotel} />,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "numberNights",
      renderHeader: () => <ColumnHeader label={"Number of Nights"} Icon={AiOutlineFieldNumber} />,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "rooms",
      renderHeader: () => <ColumnHeader label={"Rooms"} Icon={FaHotel} />,
      renderCell: (params) => {
        if (!params.row.rooms) {
          return "No rooms";
        }
        return (
          <DetailsModal title="Rooms">
            {params.row.rooms.map((room) => {
              return (
                <p key={room.id} className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                  {room.room_name}
                </p>
              );
            })}
          </DetailsModal>
        );
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "notes",
      renderHeader: () => <ColumnHeader label={"Notes"} Icon={BiMessageAltError} />,
      renderCell: (params) => {
        if (!params.row.notes) {
          return "No notes";
        }
        return (
          <DetailsModal title="Notes">
            <p className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">{params.row.notes}</p>
          </DetailsModal>
        );
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "operations",
      renderHeader: () => <ColumnHeader label={"Operations"} Icon={FaGear} />,
      renderCell: (params) => {
        return (
          <div className="flex gap-.5">
            {isEditCustomerBookingHistoryAllowed && (
              <Link to={`/dashboard/client-booking-history/${clientID}/edit-booking/${params.row.id}`}>
                <FiEdit className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800" />
              </Link>
            )}
            {isDeleteCustomerBookingHistoryAllowed && <DeleteClientBookingModal bookingID={params.row.id} />}
          </div>
        );
      },
      flex: 1,
      minWidth: 130,
    },
  ];

  return (
    <>
      {isViewCustomerBookingHistoryAllowed ? (
        <div className="h-screen w-full overflow-scroll">
          <h2 className="text-center py-2 my-2 rounded-lg bg-[#002244] text-white font-bold text-uppercase">
            {"Client Booking History".toUpperCase()}
          </h2>
          <h3 className=" text-2xl text-center font-semibold">
            Number of Bookings: <span className="font-normal">{rows?.length > 0 ? rows.length : 0}</span>
          </h3>
          <div className="min-w-[0]" style={{ height: "auto", width: "100%" }}>
            <DataGrid
              className="data-grid"
              columns={columns}
              rows={rows}
              initialState={{
                ...rows?.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[5, 10, 25]}
            />
          </div>
        </div>
      ) : (
        <NoPermission />
      )}
    </>
  );
}

const DeleteClientBookingModal = ({ bookingID }) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const { isPending, mutate: deleteAgent } = useMutation({
    mutationFn: () => mutateDataQuery(`/auth/api/v1/customer-booking-history/${bookingID}/`, "delete"),
    mutationKey: ["delete-booking", bookingID],
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success(`Booking Deleted Successfully`);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to delete Booking");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to delete Booking");
    },
  });

  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="cursor-pointer"
      >
        <AiFillDelete
          onClick={() => {}}
          className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-700 hover:text-red-900"
        />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showModal}
        position={"center"}
        onClose={() => setShowModal(false)}
      >
        <Modal.Body>
          <h3 className="text-xl font-medium text-gray-900 mb-4 text-center">Do you want to delete booking?</h3>
          <div className="flex justify-around items-center">
            <Button
              isProcessing={isPending}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              disabled={isPending}
              onClick={() => {
                deleteAgent(
                  {},
                  {
                    onSuccess: () => {
                      setShowModal(false);
                      void queryClient.refetchQueries({
                        queryKey: ["client-booking-history"],
                        type: "active",
                        exact: true,
                      });
                    },
                  },
                );
              }}
              color="failure"
            >
              Delete
            </Button>
            <Button disabled={isPending} onClick={() => setShowModal(false)} color="gray">
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientBookingHistoryList;
