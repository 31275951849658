import api_token from "../../api/UserApi";

export const addCollectorAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post(`booking/api/v1/collectors/`, data);
      callback(response);
    } catch (error) {}
  };
};
export const getAllCollectorsAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/collectors/`);
      callback(response);
    } catch (error) {}
  };
};

export const getSelectedCollectors = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/collectors/${id}/`);
      callback(response);
    } catch (error) {}
  };
};
export const updateCollectors = (token, id, values, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`booking/api/v1/collectors/${id}/`, values);
      callback(response);
    } catch (error) {}
  };
};
export const deleteCollectors = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`booking/api/v1/collectors/${id}/`);
      callback(response);
    } catch (error) {}
  };
};
