import { Label, Modal, Spinner, TextInput } from "flowbite-react";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import moment from "moment";
import "moment-hijri";
import PhoneInput from "react-phone-number-input";

const WhatsAppMessage = ({ openModal, setOpenModal }) => {
  const [htmlData, sethtmlData] = useState();
  const [Alldata, setAlldata] = useState();
  const boldWithLine = document.querySelector(".bold-with-line");
  const htmlString = boldWithLine?.textContent;

  const AllDataWhatsApp = useSelector((state) => state.dataOfWhatsAppReducer.data);

  const { cardData } = useSelector((state) => state.cardReducer);
  const [t] = useTranslation();
  const [formValue, setFormValue] = useState({ clientname: "", clientnumber: "" });
  const localeCode = t("localeCode");

  useLayoutEffect(() => {
    if (htmlString) {
      sethtmlData(htmlString);
    }

    // const uniqueHotelsMap = new Map();

    // cardData?.forEach((item) => {
    //   const key = `${item.hotel_AR}-${item?.formCheckOut_whats_app}-${item?.formCheckIn_whats_app}`;
    //   if (!uniqueHotelsMap.has(key)) {
    //     uniqueHotelsMap.set(key, item);
    //   }
    // });

    // Create a Set to track displayed hotels based on id and hotel
    const displayedHotels = new Set();

    const processedData = cardData?.map((item) => {
      const key = `${item.hotel_AR}-${item?.formCheckOut_whats_app}-${item?.formCheckIn_whats_app}`;
      if (displayedHotels.has(key)) {
        // Return a new object with the hotel name removed for duplicate entries
        return { ...item, hotel_AR: "" };
      } else {
        displayedHotels.add(key);
        return item;
      }
    });

    setAlldata(processedData);
  }, [htmlString, formValue, AllDataWhatsApp, cardData]);

  const { clientnumber, clientname } = formValue;
  const onChange = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  function handleChangeNumber(value) {
    setFormValue({
      ...formValue,
      clientnumber: value,
    });
  }

  const { mutate, isError, isPending } = useMutation({
    mutationFn: (newValues) =>
      mutateDataQuery(`/social-media-messages/api/v1/send-whatsapp-message/search/`, "post", newValues),
    onError: (error) => {
      // An error happened!

      toast.error(`${error?.response.data.message || "failed post data"}`);
    },
    onSuccess: (result) => {
      // Boom baby!
      if (result.status === "error") {
        toast.error(`${result?.message || "failed post data"}  `);
      } else {
        toast.success("sent to whatsapp successfully");
        setOpenModal(false);
      }
    },
  });

  const handelsubmit = (e) => {
    e.preventDefault();

    if (htmlData && clientname && clientnumber) {
      const alldata = {
        clients_whatsapp_numbers: [formValue.clientnumber],
        data: htmlData,
      };

      mutate(alldata);
    }
  };
  return (
    <section>
      <Modal className="z-[100]" dismissible show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <form onSubmit={handelsubmit} className="flex w-full flex-col gap-4" dir={t("dir")}>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name1" value={t("clientname")} />
              </div>
              <TextInput
                onChange={onChange}
                id="name1"
                type="text"
                name="clientname"
                required
                style={{ outline: "0px" }}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="namuber1" value={t("clientnumber")} />
              </div>
              <PhoneInput
                international={true}
                defaultCountry="EG"
                withCountryCallingCode={true}
                style={{ outline: "0px", direction: "ltr", textAlign: t("lan") === "ar" ? "end" : "start" }}
                onChange={handleChangeNumber}
                required
              />
            </div>

            <button
              disabled={isPending}
              type="submit"
              className=" w-full py-3 bg-[#075e54] flex justify-center items-center gap-2   text-white text-center  hover:bg-[#128c7e] active:scale-95 duration-300 transition-all rounded-md"
            >
              {isPending ? (
                <Spinner aria-label="Medium sized spinner example" size="md" className=" text-green-500" />
              ) : (
                <>
                  <FaWhatsapp className=" text-2xl" />
                  {t("sendwhatsapp")}
                </>
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>

      <div className="bold-with-line hidden ">
        <p> *عناية الاستاذ/* </p>
        <p> {clientname}</p>

        {(() => {
          const seenCheckOutDates = new Set();
          const seenCheckInDates = new Set();
          const seenHotels = new Set();
          const seenNights = new Set();

          return Alldata?.map((item, index) => {
            const checkInDate = moment(item?.formCheckIn_whats_app, "YYYY-MM-DD").format("DD-MM-YYYY");
            const checkOutDate = moment(item?.formCheckOut_whats_app, "YYYY-MM-DD").format("DD-MM-YYYY");
            const hotelName = item?.hotel_AR;
            const nights = item?.nights_whats_app;

            const checkInDateDisplayed = seenCheckInDates.has(checkInDate);
            const checkOutDateDisplayed = seenCheckOutDates.has(checkOutDate);
            const hotelDisplayed = seenHotels.has(hotelName);
            const nightsDisplayed = seenNights.has(nights);

            if (!checkInDateDisplayed) {
              seenCheckInDates.add(checkInDate);
            }
            if (!checkOutDateDisplayed) {
              seenCheckOutDates.add(checkOutDate);
            }
            if (!hotelDisplayed) {
              seenHotels.add(hotelName);
            }
            if (!nightsDisplayed) {
              seenNights.add(nights);
            }

            return (
              <span key={index} dir={"rtl"}>
                {(() => {
                  if (!checkInDateDisplayed && !checkOutDateDisplayed && index === 0) {
                    return (
                      <>
                        <p> \n </p>
                        <p>نقدم لحضراتكم اسعار الفترة من </p>
                      </>
                    );
                  } else if (!checkInDateDisplayed || !checkOutDateDisplayed) {
                    return (
                      <>
                        <p> \n </p>
                        <p>********</p>
                        <p> \n </p>
                        <p> \n </p>
                        <p> اسعار الفترة من </p>
                      </>
                    );
                  }
                })()}
                {!checkInDateDisplayed || !checkOutDateDisplayed ? (
                  <>
                    <p> {checkInDate} </p>
                    <p> الى </p>
                    <p>{checkOutDate}</p>
                    <p> \n </p>
                  </>
                ) : (
                  ""
                )}
                {!checkInDateDisplayed || !checkOutDateDisplayed ? (
                  <>
                    <p>*عدد الليالي :* </p>
                    <p> {new Intl.NumberFormat("ar-EG").format(nights)} </p>
                    <p> \n </p>
                  </>
                ) : (
                  ""
                )}
                {/* {hotelDisplayed && !checkInDateDisplayed && !checkOutDateDisplayed ? (
                  <>
                    <p> \n </p>
                    <p>*أسم الفندق:* </p>
                    <p> {item?.hotel_AR} </p>
                    <p> \n </p>
                  </>
                ) : (
                  !hotelDisplayed && (
                    <>
                      <p> \n </p>
                      <p>*أسم الفندق:* </p>
                      <p> {item?.hotel_AR} </p>
                      <p> \n </p>
                    </>
                  )
                )} */}
                {item?.hotel_AR && (
                  <>
                    <p> \n </p>
                    <p>*أسم الفندق:* </p>
                    <p> {item?.hotel_AR} </p>
                    <p> \n </p>
                  </>
                )}
                <p> \n </p>
                <p>*نوع الغرفة:* </p>
                <p> {item?.selectedroom} </p>
                <p> \n </p>
                <p>*نوع الوجبات:* </p>
                <p> {t(item.mealType)} </p>
                <p> \n </p>
                <p>*عدد البالغين:* </p>
                <p> {new Intl.NumberFormat("ar-EG").format(item?.number_of_persons)} </p>
                <p> \n </p>
                <p>*عدد الأطفال:* </p>
                <p> {new Intl.NumberFormat("ar-EG").format(item?.children_num)} </p>

                {item?.children_ages?.length > 0 && (
                  <>
                    {item?.children_ages.map((child) => (
                      <span>({new Intl.NumberFormat("ar-EG").format(child)} سنوات) </span>
                    ))}
                  </>
                )}

                <p> \n </p>
                <p>*المبلغ الإجمالي:* </p>
                <p>
                  {new Intl.NumberFormat("ar-EG").format(item.price)} {t(item.currency_type)}
                </p>
                <p>\n</p>
              </span>
            );
          });
        })()}
        <p> \n </p>
        <p>********</p>
        <p> \n </p>
        <p> \n </p>
        <p>*جميع الاسعار المذكورة أعلاه شاملة جميع الضرائب والرسوم المقررة*</p>
      </div>
    </section>
  );
};

export default WhatsAppMessage;
