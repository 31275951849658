import React, { useEffect } from "react";
import { Button } from "flowbite-react";
import { HiClipboard, HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";

function NotesFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status") || "";

  function handleChangeStatus(status) {
    setSearchParams({ ...Object.fromEntries(searchParams), status, page: 1, pageSize: 8 });
  }

  return (
    <Button.Group>
      <Button
        color={`${status === "" ? "info" : "gray"}`}
        onClick={() => {
          handleChangeStatus("");
        }}
      >
        <HiClipboard className="mr-2 h-4 w-4" />
        All
      </Button>
      <Button
        color={`${status === "open" ? "info" : "gray"}`}
        onClick={() => {
          handleChangeStatus("open");
        }}
      >
        <HiOutlineLockOpen className="mr-2 h-4 w-4 scale-x-[-1]" />
        Opened
      </Button>
      <Button
        color={`${status === "closed" ? "info" : "gray"}`}
        onClick={() => {
          handleChangeStatus("closed");
        }}
      >
        <HiOutlineLockClosed className="mr-2 h-4 w-4" />
        Closed
      </Button>
    </Button.Group>
  );
}

export default NotesFilter;
