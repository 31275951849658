import {  legacy_createStore as createStore , compose  , applyMiddleware} from "redux";
import reducers from "./redux/reducers";
import reduxthunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// mount it on the Store
const store = createStore(reducers , composeEnhancers(applyMiddleware(reduxthunk)));

export default store;
