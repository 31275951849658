import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Errorfetch from "../../Errorfetch/Errorfetch";
import Loading from "../../Loading/Loading";
import { ColumnHeader } from "../../../../customComponents/TableComponents";
import { FaDatabase } from "react-icons/fa6";
import { DataGrid } from "@mui/x-data-grid";
import { IoMdPerson } from "react-icons/io";

import usePermissions from "../../../../customHooks/usePermissions";
import NoPermission from "../../NoPermission/NoPermission";
import { CiCalendarDate } from "react-icons/ci";

function ClientFamilyList() {
  const [permissions, permissionFlag, permissionsError] = usePermissions(["auth_users.view_customerfriends"]);

  const isViewingFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.view_customerfriends")?.value === true;

  const { clientID } = useParams();

  const {
    data: family,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["family-list", clientID],
    queryFn: () => fetchDataQuery(`/auth/api/v1/customer-friends/${clientID}/`),
  });

  if (isLoading || !permissionFlag) {
    return <Loading />;
  }

  if (error || !family) {
    return <Errorfetch Error={error.message} />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const rows = family?.map((member) => {
    return {
      id: member?.id,
      arabicName: member?.name_ar,
      englishName: member?.name_en,
      addedBy: member?.added_by?.username,
    };
  });

  const columns = [
    {
      field: "id",
      renderHeader: () => <ColumnHeader label={"ID"} Icon={FaDatabase} />,
      minWidth: 100,
    },
    {
      field: "arabicName",
      renderHeader: () => <ColumnHeader label={"Arabic Name"} Icon={IoMdPerson} />,
      minWidth: 100,
    },
    {
      field: "englishName",
      renderHeader: () => <ColumnHeader label={"English Name"} Icon={IoMdPerson} />,
      minWidth: 100,
    },
    {
      field: "addedBy",
      renderHeader: () => <ColumnHeader label={"Added By"} Icon={CiCalendarDate} />,
      minWidth: 100,
    },
  ].map((column) => {
    return {
      ...column,
      flex: 1,
    };
  });

  return isViewingFamilyAllowed ? (
    <div className="h-screen w-full overflow-scroll">
      <h2 className="text-center py-2 my-2 rounded-lg bg-[#002244] text-white font-bold text-uppercase">Family List</h2>
      <div className="min-w-[0]" style={{ height: "auto", width: "100%" }}>
        <DataGrid
          className="data-grid"
          columns={columns}
          rows={rows}
          initialState={{
            ...rows?.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}

export default ClientFamilyList;
