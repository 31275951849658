import React, { useEffect, useState } from "react";
import { clearSingleContract, getHotelContracts, getSingleContract } from "../../../redux/actions/getContracts";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import ContractPage from "../../molecules/ContractPage/ContractPage";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import TopBarProgress from "react-topbar-progress-indicator";
import { Link } from "react-router-dom";
import { LiaFileContractSolid } from "react-icons/lia";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { FaFileContract } from "react-icons/fa6";

export default function ImportContract() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const hotelNames = useSelector((state) => state.hotelNames);
  const contracts = useSelector((state) => state.hotelContracts);
  const chosenContract = useSelector((state) => state.getSingleContract);

  const hotelsArr = hotelNames.map((hotelObj) => ({ label: hotelObj.name, value: hotelObj.id }));
  useEffect(() => {
    dispatch(requestHotelNames());
    return () => {
      dispatch(clearSingleContract());
    };
  }, []);
  const contractsArr = contracts.map((contract) => ({ label: contract.name, value: contract.id }));

  function handleHotelChange(e) {
    setFlag(true);
    dispatch(
      getHotelContracts(e.value, (result) => {
        if (result.status === 200) {
          setFlag(false);
        } else {
          setFlag(false);
        }
      }),
    );
  }

  function handleChosenContract(e) {
    setIsLoading(true);
    dispatch(getSingleContract(e.value, () => setIsLoading(e.value)));
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["contracts_management.change_contract"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="md:px-6 px-2 py-8">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "contracts_management.change_contract" && item.value === true) {
            return (
              <div className="w-full h-full">
                <div className="w-full flex items-center justify-center ">
                  <h2 className="header-h2 ">
                    {" "}
                    <LiaFileContractSolid className="w-6 h-6 text-[#002442] " />
                    Import Contract
                  </h2>
                </div>

                <div className="input-par form-wrap">
                  <div className="input-chil">
                    <label htmlFor="hotel" className="w-full flex  text-slate-950 font-semibold text-xl">
                      Please Choose Hotel First
                    </label>
                    <Select
                      options={hotelsArr}
                      id="hotel"
                      placeholder="Select Hotel"
                      className="text-gray-900 font-semibold capitalize"
                      isSearchable
                      onChange={(e) => handleHotelChange(e)}
                    />
                  </div>
                  <div className=" input-chil">
                    <label htmlFor="hotel" className="w-full flex  text-slate-950 font-semibold text-xl">
                      Choose Contract
                    </label>
                    {flag ? (
                      <div className="w-full flex items-center justify-center">
                        <ButtonLoading /> <TopBarProgress />{" "}
                      </div>
                    ) : (
                      <Select
                        options={contractsArr}
                        id="contract"
                        className="text-gray-900 font-semibold capitalize"
                        isSearchable
                        placeholder="Select Contract"
                        onChange={(e) => handleChosenContract(e)}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  {chosenContract.id && chosenContract.id === isLoading ? (
                    <div className="flex flex-col items-center py-5">
                      <Link
                        to={`./${chosenContract.id}`}
                        className="p-2 rounded-lg bg-[#002244] mb-4 text-white font-bold mx-auto hover:scale-105 active:scale-95 duration-300 transition-all"
                        // onClick={() => navigate(`./${chosenContract.id}`)}
                      >
                        Import Contract
                      </Link>
                      <ContractPage contract={chosenContract} />
                    </div>
                  ) : isLoading ? (
                    <div className="flex items-center justify-center w-full h-screen">
                      <Loading /> <TopBarProgress />
                    </div>
                  ) : (
                    <div className="w-full flex flex-col gap-y-2 opacity-20 items-center justify-start h-full mt-48 ">
                      <FaFileContract className="w-48 h-48 " />

                      <div className="text-lg font-semibold text-gray-900">No Contract Chosen Yet</div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
