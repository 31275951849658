import { Dropzone, FileMosaic, FullScreen, ImagePreview, VideoPreview } from "@files-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { arraysEqual } from "../../../utils/arrayUtils";
import { createFileFromUrl, isImage } from "../../../utils/utils";

export function FileUploader({
  accept,
  files,
  setFiles,
  urls = [],
  maxFiles = 100,
  maxFileSize = 2,
  label = "Drag and drop files here or click to browse",
}) {
  const [imageSrc, setImageSrc] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [imageUrls, setImageUrls] = useState(urls.filter(Boolean));
  const refUrls = useRef(urls.filter(Boolean));

  useEffect(() => {
    if (!arraysEqual(urls, refUrls.current)) {
      refUrls.current = urls;
      setImageUrls(urls);
    }
  }, [urls]);

  const updateFiles = useCallback(
    (incomingFiles) => {
      const filteredFiles = incomingFiles?.filter((file) => {
        if (file.name.length > 75) {
          toast.error(`File ${file.name} is not valid because max chars 75 or more`);
          return false;
        }
        if (!file.valid) {
          toast.error(`File ${file.name} is not valid because ${file?.errors[0]}`);
          return false;
        }
        return true;
      });
      setFiles(filteredFiles);
    },
    [setFiles],
  );

  const handleDelete = useCallback((id) => setFiles(files.filter((file) => file.id !== id)), [files, setFiles]);
  const handleSee = useCallback((imageSource) => setImageSrc(imageSource), []);
  const handleWatch = useCallback((videoSource) => setVideoSrc(videoSource), []);

  useEffect(() => {
    const loadImageFiles = async () => {
      const fileObjects = await Promise.all(
        imageUrls.map((url, index) =>
          createFileFromUrl(url, `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`),
        ),
      );
      const validFiles = fileObjects.filter(Boolean);
      if (validFiles.length > 0) {
        updateFiles(validFiles);
      }
      setImageUrls([]);
    };

    if (imageUrls.length > 0) {
      loadImageFiles();
    }
  }, [imageUrls, updateFiles]);

  return (
    <>
      <Dropzone
        onChange={updateFiles}
        minHeight="195px"
        value={files}
        accept={accept}
        maxFiles={maxFiles}
        maxFileSize={maxFileSize * 1024 * 1024}
        label={label}
      >
        {files?.map((file) => (
          <FileMosaic
            {...file}
            key={`${file.id}-${file.name}`}
            onDelete={handleDelete}
            onSee={isImage(file.name) ? handleSee : undefined}
            downloadUrl={file.imageUrl}
            onWatch={handleWatch}
            resultOnTooltip
            alwaysActive
            preview
            info
          />
        ))}
      </Dropzone>

      <FullScreen open={!!imageSrc} onClose={() => setImageSrc(null)}>
        <ImagePreview src={imageSrc} />
      </FullScreen>

      <FullScreen open={!!videoSrc} onClose={() => setVideoSrc(null)}>
        <VideoPreview src={videoSrc} autoPlay controls />
      </FullScreen>
    </>
  );
}

export default FileUploader;
