import React from "react";
import { Form, Formik } from "formik";
import CitySelectInput from "./room/CitySelectInput";
import HotelSelectInput from "./room/HotelSelectInput";
import RoomSelectInput from "./room/RoomSelectInput";
import UpgradeRoom from "./upgrade_room/Upgrade_room";

import { BsBookmarkStarFill } from "react-icons/bs";

function CityHotelRoomSelector({ roomValue, roomUpgradeValue, setRoomUpgradeValue, setRoomValue, offerData }) {
  return (
    <Formik
      initialValues={{
        city: offerData?.room_details?.hotel?.city?.name ? offerData?.room_details?.hotel?.city?.name : "",
        hotel: offerData?.room_details?.hotel?.name ? offerData?.room_details?.hotel?.name : "",
        room: roomValue,
        upgrade_room: roomUpgradeValue,
      }}
      onSubmit={() => {}}
    >
      {({ values }) => {
        return (
          <>
            <Form className="flex flex-col gap-2 h-full p-2">
              <div className="input-par ">
                <div className="input-chil">
                  <CitySelectInput offerData={offerData} />
                </div>
                <div className="input-chil">
                  <HotelSelectInput offerData={offerData} />
                </div>
              </div>
              <div className="input-par ">
                <div className="input-chil">
                  <RoomSelectInput roomValue={roomValue} setRoomValue={setRoomValue} offerData={offerData} />
                </div>
                <div className="input-chil">
                  <UpgradeRoom
                    roomUpgradeValue={roomUpgradeValue}
                    setRoomUpgradeValue={setRoomUpgradeValue}
                    offerData={offerData}
                  />
                </div>
              </div>
            </Form>
            {/* {render && render(values)} */}
          </>
        );
      }}
    </Formik>
  );
}

export default CityHotelRoomSelector;
