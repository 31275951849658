import React from "react";
import "./DashClockCard.scss";
import Clock from "react-live-clock";
import { BsClockHistory } from "react-icons/bs";

export default function DashClockCard(props) {
  const { country, timezone, bg } = props.props;
  return (
    <div
      className={`w-full md:h-24 h-20 flex bg-gray-50 items-center justify-center gap-x-5  shadow-lg rounded-lg px-3   `}
    >
      <div className="md:w-[60%] w-[80%] flex items-center justify-start gap-x-1 ">
        <BsClockHistory className="md:w-6 md:h-6 w-5 h-5" />
        <span className="md:text-xl text-md font-bold text-gray-800">{country}</span>
      </div>

      <Clock
        format={"HH:mm:ss"}
        ticking={true}
        timezone={timezone}
        className="md:text-3xl text-2xl font-semibold text-gray-800 "
      />
    </div>
  );
}
