import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { FaWpforms } from "react-icons/fa6";
import { getBankChequesAction } from "../../../redux/actions/cashAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { useFormik } from "formik";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import { NotificationManager } from "react-notifications";
import {
  getBankChequesIdAction,
  postAllBankChequesAction,
  updateBankChequesIdAction,
} from "../../../redux/actions/bankChequesActions";
import { BankChequesValidations } from "./BankChequesValidations";
import usePermissions from "../../../customHooks/usePermissions";

const AddEditBankCheques = ({ method, bankTranfer }) => {
  const [selectedBank, setSelectedBank] = useState();
  const [currency, setCurrency] = useState("");
  const [convertRate, setConvertRate] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [accountNumberBank, setAccountNumberBank] = useState("");
  const [bank, setBank] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  useRemoveScroll();
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  // Form State
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      bank: "",
      bank_account_number: "",
      bank_currency: "",
      first_cheque_number: "",
      number_of_cheques: "",
    },
    validationSchema: BankChequesValidations,
    onSubmit: submit,
  });
  useEffect(() => {
    if (params.id) {
      dispatch(
        getBankChequesIdAction(token, params.id, (result) => {
          if (result.status === 200) {
            setFieldValue("bank", result.data.bank.name);
            setBank(result.data.bank.name);

            setFieldValue("bank_account_number", result.data.account_number);
            setFieldValue("bank_currency", result.data.bank_currency);
            setCurrency(result.data.bank_currency);
            setFieldValue("first_cheque_number", result.data.first_cheque_number);
            setFieldValue("number_of_cheques", result.data.number_of_cheques);
          }
        }),
      );
    }
  }, []);

  function submit(data) {
    const newValues = {
      bank: data.bank,
      bank_account_number: data.bank_account_number,
      bank_currency: data.bank_currency,
      first_cheque_number: data.first_cheque_number,
      number_of_cheques: data.number_of_cheques,
    };
    if (params.id) {
      dispatch(
        updateBankChequesIdAction(token, params.id, newValues, (result) => {
          if (result.status === 200) {
            setSubmitting(false);
            lightToast.success("Bank List Updated successfully");
            navigate(-1);
          } else {
            setSubmitting(false);

            NotificationManager.error(result?.response?.data[0]?.message);
          }
        }),
      );
    } else {
      dispatch(
        postAllBankChequesAction(token, data, (res) => {
          if (res.status === 201) {
            setSubmitting(false);
            lightToast.success("Bank is completed Added successfully");
            navigate(-1);
          } else {
            setSubmitting(false);

            lightToast.fail("There was an error submitting the form try again later");
          }
        }),
      );
    }
  }

  const { bankData } = useSelector((state) => state.cashReducer);
  let Bank = bankData
    ? bankData?.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency, bankAccountNumber: item.account_number };
      })
    : [];

  useEffect(() => {
    dispatch(getBankChequesAction(token, (res) => {}));
  }, []);

  /////permission/////////

  const [permissions, permissionsFlag] = usePermissions(["accounting.add_chequebook"]);
  return (
    <div className="formPage-wrap  ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {selectedSingleRoom && permissionsFlag ? (
          permissions.map((item, idx) => {
            if (item.name === "accounting.add_chequebook" && item.value === true) {
              return (
                <div key={idx} className={`w-full   `}>
                  <div className=" flex justify-center ">
                    {params.id ? (
                      <h2 className="header-h2">
                        {" "}
                        <FaWpforms className="w-5 h-5" />
                        Update Bank Cheque
                      </h2>
                    ) : (
                      <h2 className="header-h2">
                        {" "}
                        <FaWpforms className="w-5 h-5" />
                        Add Bank Cheque
                      </h2>
                    )}
                  </div>

                  <form className="form-wrap" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <div className="w-full flex flex-col justify-start items-center">
                        <label className=" flex text-gray-600   ">Bank Name</label>
                        <Select
                          placeholder="Bank Name"
                          type="text"
                          name="bank"
                          defaultValue={selectedBank}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: `${touched.bank && errors.bank && "rgb(153 27 27)"}`,
                            }),
                          }}
                          options={Bank}
                          value={bank ? [{ label: bank, value: bank }] : null}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("bank", e.value);
                            setFieldValue("bank_account_number", e.bankAccountNumber);
                            setFieldValue("bank_currency", e.currency);
                            setBank(e.label);
                            setCurrency(e.currency);
                            setAccountNumberBank(e.bankAccountNumber);
                            setLoadingToggle(false);
                            setToggle(true);
                          }}
                          isSearchable
                          className=" w-full  h-10  rounded-lg  bg-white "
                        />
                        {touched.bank && errors.bank ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {errors.bank}
                          </div>
                        ) : null}
                      </div>

                      {currency && toggle ? (
                        <div className="input-par">
                          <div className="input-chil">
                            <label className=" flex text-gray-600 ">Currency</label>
                            <div
                              name="currency"
                              className=" w-full px-3 h-10  border rounded-md floating-input bg-white"
                            >
                              <p className="text-black mt-2">{currency}</p>
                            </div>
                          </div>
                          <div className="input-chil">
                            <label className=" flex text-gray-600 ">Account Number</label>
                            <div
                              name="currency"
                              className=" w-full px-3 h-10  border rounded-md floating-input bg-white"
                            >
                              <p className="text-black mt-2">{accountNumberBank}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        loadingToggle && (
                          <>
                            <ButtonLoading />
                          </>
                        )
                      )}

                      <div className="input-par">
                        <div className="input-chil">
                          <label className=" flex text-gray-600 "> First Cheque Number</label>
                          <input
                            placeholder="first cheque number"
                            name="first_cheque_number"
                            type="number"
                            step="any"
                            onWheel={(e) => e.target.blur()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_cheque_number}
                            className={` w-full px-3 h-10  border rounded-lg bg-white ${
                              touched.first_cheque_number && errors.first_cheque_number && "border border-red-800"
                            } `}
                          />
                          {touched.first_cheque_number && errors.first_cheque_number ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.first_cheque_number}
                            </div>
                          ) : null}
                        </div>
                        <div className="input-chil">
                          <label className=" flex text-gray-600 "> Number of cheques</label>
                          <input
                            placeholder="Number of cheques"
                            name="number_of_cheques"
                            type="number"
                            step="any"
                            onWheel={(e) => e.target.blur()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.number_of_cheques}
                            className={` w-full px-3 h-10  border rounded-lg bg-white ${
                              touched.number_of_cheques && errors.number_of_cheques && "border border-red-800"
                            } `}
                          />
                          {touched.number_of_cheques && errors.number_of_cheques ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.number_of_cheques}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex justify-center mt-6">
                        {params.id ? (
                          <button type="Submit" disabled={isSubmitting} className="updateButton">
                            update{" "}
                            {isSubmitting ? (
                              <>
                                <TopBarProgress />
                                <ButtonLoading />
                              </>
                            ) : (
                              <IoAdd className="w-5 h-5" />
                            )}
                          </button>
                        ) : (
                          <button type="Submit" disabled={isSubmitting} className="addButton">
                            Add{" "}
                            {isSubmitting ? (
                              <>
                                <TopBarProgress />
                                <ButtonLoading />
                              </>
                            ) : (
                              <IoAdd className="w-5 h-5" />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              );
            }
          })
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <TopBarProgress /> <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEditBankCheques;
