import { GET_INVENTORY_LIST } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getInventoryListSuccessfull(data) {
  return {
    type: GET_INVENTORY_LIST,
    payload: data,
  };
}

export function getInventoryList(year, month, hotel, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        `/inventory/api/?date__year=${year}&date__month=${Number(month) + 1}&find=${hotel}`,
      );
      callback(response);
      dispatch(getInventoryListSuccessfull(response.data));
    } catch (error) {}
  };
}

export function sendInventoryData(data, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).post(`/inventory/api/`, data);
      if (response) {
        callback();
      }
    } catch (error) {}
  };
}

export function deleteInventoryData(id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).delete(`/inventory/api/${id}/`);
      if (response) {
        callback();
      }
    } catch (error) {}
  };
}

export function createBulk(data) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).post("/inventory/api/create-bulk/", data);
    } catch (error) {}
  };
}

export function deleteBulk(data) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).delete("/inventory/api/delete-many/", { data: data });
    } catch (error) {}
  };
}
