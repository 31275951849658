import * as yup from "yup";
export const CustomerValidation = yup.object().shape({
  // link_status: yup.string().required("Please select status"),
  expired_date: yup.date().required("Please select expired date"),
  time: yup.string().required("Please select expired time"),
  hotels: yup.array().min(1, "Please select hotel"),
  city: yup.array().min(1, "Please select city"),
  allowed_contoury: yup.string().when("restricted_contoury", {
    is: (restricted_contoury) => restricted_contoury.length === 0,
    then: () => yup.array().min(1, "Please select allowed country"),
    otherwise: () => yup.array(),
  }),
  restricted_contoury: yup.array(),

  markup: yup.string().required("Please select markup"),
});
