import React, { useMemo, useState } from "react";
import { FaWpforms } from "react-icons/fa6";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import useHotelNames from "../../../customHooks/useHotelNames";
import { createOffer, getHotelActiveContracts } from "../../../redux/actions/offers";
import { useDispatch } from "react-redux";
import useData from "../../../customHooks/useData";
import TopBarProgress from "react-topbar-progress-indicator";
import { useNavigate } from "react-router";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import { MdPostAdd } from "react-icons/md";
import { toast } from "react-toastify";

function labelName(choice) {
  switch (choice) {
    case "Pay & Get":
      return "Get Nights ";
    case "Percentage":
      return "Discount Percentage";
    case "Fixed Discount":
      return "Fixed  Discount Amount";
  }
  return null;
}

export default function CreateOffer() {
  useRemoveScroll();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hotels = useHotelNames();
  const offerTypes = useData("/offers/api/offers-type/");
  const [contracts, setContracts] = useState([]);
  const [contractFlag, setContractFlag] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };
  const [chosenHotel, setChosenHotel] = useState(null);
  const [chosenContract, setChosenContract] = useState(null);
  const [chosenOfferType, setChosenOfferType] = useState({ label: null, value: null });
  const label = labelName(chosenOfferType.value);
  const hotelsArr = useMemo(() => {
    return hotels.map((hotel) => ({ label: hotel.name, value: hotel.id }));
  }, [hotels]);
  const contractsArr = useMemo(() => {
    return contracts.map((contract) => ({ label: contract.name, value: contract.id }));
  }, [contracts]);
  const offerTypesArr = offerTypes ? offerTypes.map((type) => ({ label: type, value: type })) : [];

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);

    const data = {
      name: e.target.offerName.value,
      type: chosenOfferType.value,
      nights_number: e.target.nights.value,
      gift: e.target.gift.value,
      from_date: dateRange.startDate.format("YYYY-MM-DD"),
      to_date: dateRange.endDate.format("YYYY-MM-DD"),
      contract: chosenContract.value,
    };

    if (chosenOfferType.value === "Pay & Get") {
      if (Number(e.target.gift.value) >= Number(e.target.nights.value)) {
        toast.error("Get Nights Can't Be More Than Pay Nights");
        setIsDisabled(false);
        return;
      }
    }

    dispatch(
      createOffer(data, (response) => {
        setIsDisabled(false);
        toast.success("Your Offer Was Created Successfully");
        navigate(`/dashboard/offers/${response.data.id}`);
      }),
    );
  }

  return (
    <div className="formPage-wrap  ">
      <div className="flex flex-col items-center justify-start h-screen w-full">
        <div className=" w-full   ">
          <div className=" flex justify-center ">
            <h2 className="header-h2">
              <FaWpforms className="w-5 h-5" />
              Create An Offer
            </h2>
          </div>
          <form className="form-wrap" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <div className="flex w-full max-sm:flex-col gap-4">
                <div className="flex-1 max-sm:w-full">
                  {" "}
                  <label className="  text-gray-600 font-semibold ">Offer Name</label>
                  <input
                    placeholder="Offer Name"
                    name="offerName"
                    type="text"
                    required
                    className=" w-full px-3 h-10  border rounded-lg bg-white "
                  />
                </div>
                <div className="flex-1 max-sm:w-full">
                  <label className="  text-gray-600 font-semibold ">Offer Hotel</label>
                  <Select
                    options={hotelsArr}
                    className="w-full h-10   bg-white "
                    required
                    name="hotel"
                    placeholder="Offer Hotel"
                    value={chosenHotel}
                    onChange={(e) => {
                      setContractFlag(true);
                      dispatch(
                        getHotelActiveContracts(
                          e.value,
                          (data) => setContracts(data),
                          (result) => {
                            if (result.status === 200) {
                              setContractFlag(false);
                            } else {
                              setContractFlag(false);
                            }
                          },
                        ),
                      );
                      setChosenHotel(e);
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full max-sm:flex-col gap-4">
                <div className="flex-1 max-sm:w-full">
                  <label className="  text-gray-600 font-semibold ">Offer Contract</label>
                  {contractFlag ? (
                    <div className="w-full flex items-center justify-center ">
                      <ButtonLoading /> <TopBarProgress />
                    </div>
                  ) : (
                    <Select
                      options={contractsArr}
                      className="w-full h-10   bg-white "
                      required
                      placeholder="Offer Contract"
                      name="contract"
                      value={chosenContract}
                      onChange={(e) => setChosenContract(e)}
                    />
                  )}
                </div>
                <div className="flex-1 max-sm:w-full">
                  <label className="  text-gray-600 font-semibold ">Offer Type</label>
                  <Select
                    options={offerTypesArr}
                    className="w-full h-10 "
                    required
                    name="type"
                    placeholder="Offer Type"
                    value={chosenOfferType}
                    onChange={(e) => setChosenOfferType(e)}
                  />
                </div>
              </div>

              <div className="w-full flex max-sm:flex-col gap-4 items-center justify-center ">
                <div className="flex-1 max-sm:w-full flex flex-col rounded-md">
                  <label className=" text-gray-600 font-semibold">From-To</label>
                  <DateRangePicker
                    startDate={dateRange.startDate}
                    startDateId="startDate"
                    endDate={dateRange.endDate}
                    endDateId="endDate"
                    onDatesChange={handleDateChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                    minimumNights={0} // Allow same-day selection
                    required
                    daySize={30}
                  />
                </div>
                <div className="flex-1 max-sm:w-full">
                  <label className="  text-gray-600 font-semibold ">
                    {chosenOfferType.label === "Pay & Get" ? "Pay Nights" : "Minimum of Stay"}
                  </label>
                  <input
                    placeholder={chosenOfferType.label === "Pay & Get" ? "Pay Nights" : "Minimum of Stay"}
                    name="nights"
                    type="number"
                    required
                    className=" w-full px-3 h-11  border  bg-white rounded-md"
                  />
                </div>
              </div>

              {label && (
                <>
                  <label className=" text-gray-600 font-semibold">{label}</label>
                  <input
                    placeholder={label}
                    name="gift"
                    type="number"
                    required
                    className=" w-full px-3 h-10  border rounded-lg bg-white "
                  />
                </>
              )}

              <div className="flex justify-center mt-6">
                <button disabled={isDisabled} type="Submit" className="addButton">
                  Create
                  {isDisabled ? (
                    <>
                      <ButtonLoading /> <TopBarProgress />
                    </>
                  ) : (
                    <MdPostAdd className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>
            {/* {isDisabled ? (
              <>
                <TopBarProgress />
                <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                  <HashLoader size={30} color="white" />
                  <div className="text-white font-bold my-2">Submitting...</div>
                </div>
              </>
            ) : null} */}
          </form>
        </div>
      </div>
    </div>
  );
}
