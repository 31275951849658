import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../../../redux/actions/userProfileAction";
import lightToast from "light-toast";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { GiConfirmed } from "react-icons/gi";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../ButtonLoading/ButtonLoading";

import { NotificationContainer, NotificationManager } from "react-notifications";
const PasswordPage = ({ setPasswordFlag }) => {
  const [currentFlag, setCurrentFlag] = useState(false);
  const [newFlag, setNewFlag] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);
  const [currentError, setCurrentError] = useState();
  const dispatch = useDispatch();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      old_password: "",
      new_password1: "",
      new_password2: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Please Enter Current Password "),
      new_password1: Yup.string().required("Please Enter New Password"),
      new_password2: Yup.string()
        .required("Please Enter Confirm Password")
        .test("passwords-match", "Passwords must match", function (value) {
          return values.new_password1 === value;
        }),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      dispatch(
        changePassword(formData, (result) => {
          if (result.status === 200) {
            setPasswordFlag(false);
            lightToast.success("Your Password Was Updated");
          } else {
            setSubmitting(false);

            setCurrentError(result.response && Object.values(result.response?.data).map((item) => item));
          }
        })
      );
    },
  });

  return (
    <div className="w-full">
      <form className="flex flex-col  justify-start items-center gap-y-3 border rounded-lg p-5" onSubmit={handleSubmit}>
        <img src="/images/imagesww.jfif" className="w-64 h-6w-64" />
        {/*Current Password*/}
        <div className="w-full">
          <label className="text-slate-900 font-semibold text-sm">Current Password</label>
          <div className="w-full relative">
            <input
              value={values.old_password}
              name="old_password"
              placeholder="Current Password"
              type={currentFlag ? "text" : "password"}
              className={`w-full p-1 border rounded-md text-start text-[#002244] text-sm bg-white ${
                touched.old_password && errors.old_password && "border border-red-800"
              }  `}
              onChange={(e) => {
                setFieldValue("old_password", e.target.value);
                setCurrentError("");
              }}
              onBlur={handleBlur}
            />
            <button type="button" onClick={() => setCurrentFlag(!currentFlag)} className=" absolute top-1 right-3">
              {currentFlag ? (
                <VscEye className="w-5 h-5 text-gray-500" />
              ) : (
                <VscEyeClosed className=" w-5 h-5 text-gray-500" />
              )}
            </button>
          </div>
          {touched.old_password && errors.old_password ? (
            <div className="w-full flex items-center justify-start capitalize text-red-800 text-xs font-semibold">
              {errors.old_password}
            </div>
          ) : null}
        </div>
        {/*New Password*/}

        <div className="w-full">
          <label className="text-slate-900 font-semibold text-sm">New Password</label>
          <div className="w-full relative">
            <input
              name="new_password1"
              disabled={values.old_password === ""}
              placeholder={values.old_password ? "New Password" : "Please Enter Current Password"}
              type={newFlag ? "text" : "password"}
              className={`w-full p-1 border rounded-md text-start text-[#002244] text-sm bg-white  ${
                touched.new_password1 && errors.new_password1 && "border border-red-800"
              } `}
              onChange={(e) => {
                setFieldValue("new_password1", e.target.value);
                setCurrentError("");
              }}
              onBlur={handleBlur}
            />
            <button type="button" onClick={() => setNewFlag(!newFlag)} className=" absolute top-1 right-3">
              {newFlag ? (
                <VscEye className="w-5 h-5 text-gray-500" />
              ) : (
                <VscEyeClosed className=" w-5 h-5 text-gray-500" />
              )}
            </button>
          </div>
          {touched.new_password1 && errors.new_password1 ? (
            <div className="w-full flex items-center justify-start capitalize text-red-800 text-xs font-semibold">
              {errors.new_password1}
            </div>
          ) : null}
        </div>

        {/*Confirm Password*/}

        <div className="w-full">
          <label className="text-slate-900 font-semibold text-sm">Confirm Password</label>
          <div className="w-full relative">
            <input
              name="new_password2"
              disabled={values.new_password1 === ""}
              placeholder={values.old_password ? "Confirm Password" : "Please Enter New Password"}
              type={confirmFlag ? "text" : "password"}
              className={`w-full p-1 border rounded-md text-start text-[#002244] text-sm bg-white  ${
                touched.new_password2 && errors.new_password2 && "border border-red-800"
              } `}
              onChange={(e) => {
                setFieldValue("new_password2", e.target.value);
                setCurrentError("");
              }}
              onBlur={handleBlur}
            />
            <button type="button" onClick={() => setConfirmFlag(!confirmFlag)} className=" absolute top-1 right-3">
              {confirmFlag ? (
                <VscEye className="w-5 h-5 text-gray-500" />
              ) : (
                <VscEyeClosed className=" w-5 h-5 text-gray-500" />
              )}
            </button>
          </div>
          {touched.new_password2 && errors.new_password2 ? (
            <div className="w-full flex items-center justify-start capitalize text-red-800 text-xs font-semibold">
              {errors.new_password2}
            </div>
          ) : null}
        </div>
        <div className="w-full flex items-center justify-start text-red-800  text-sm font-semibold ">
          <p>{currentError}</p>
        </div>
        <button
          type="Submit"
          disabled={isSubmitting}
          className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002230] hover:bg-[#002244] text-white active:scale-95 duration-300 transition-all"
        >
          Confirm{" "}
          {isSubmitting ? (
            <>
              <TopBarProgress />
              <ButtonLoading />
            </>
          ) : (
            <GiConfirmed className="w-5 h-5" />
          )}
        </button>
      </form>
      <NotificationContainer />
    </div>
  );
};

export default PasswordPage;
