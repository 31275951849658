import React from "react";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import * as Yup from "yup";
import ClientFamilyForm from "./ClientFamilyForm";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";
import usePermissions from "../../../../customHooks/usePermissions";
import NoPermission from "../../NoPermission/NoPermission";

function EditClientFamilyForm() {
  const { clientID } = useParams();
  const navigate = useNavigate();
  const user = jwtDecode(cookie.load("access_token"));
  const userID = user.user_id;

  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "auth_users.change_customerfriends",
    "auth_users.delete_customerfriends",
  ]);

  const isEditingFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.change_customerfriends")?.value === true;

  const isDeletingFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.delete_customerfriends")?.value === true;

  const {
    data: family,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["family-list", clientID],
    queryFn: () => fetchDataQuery(`/auth/api/v1/customer-friends/${clientID}/`),
  });

  const { isPending, mutate: editFamily } = useMutation({
    queryKey: ["add-family"],
    mutationFn: (data) => {
      return mutateDataQuery(`/auth/api/v1/${clientID}/friends/bulk-update/`, "put", data, {
        "Content-Type": "application/json",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Family added Successfully");
      navigate(`/dashboard/client-family/${clientID}/family-list`);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to add Family");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to add Family");
    },
  });

  if (isLoading || !permissionFlag) {
    return <Loading />;
  }

  if (error || !family) {
    return <Errorfetch Error={error.message} />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const personSchema = Yup.object().shape({
    name_en: Yup.string().required("English name is required"),
    name_ar: Yup.string().required("Arabic name is required"),
  });

  const validationSchema = Yup.object().shape({
    family: Yup.array()
      .of(personSchema)
      .required("Family is required")
      .min(1, "At least one family member is required"),
  });

  const initialValues = {
    family: family.map((member) => {
      return {
        name_en: member.name_en,
        name_ar: member.name_ar,
      };
    }),
  };

  const handleSubmit = (values) => {
    values.family.map((familyMember) => {
      familyMember.added_by = userID;
      familyMember.customer = Number(clientID);
      return familyMember;
    });

    const data = JSON.stringify(values.family);

    editFamily(data);
  };

  return isEditingFamilyAllowed && isDeletingFamilyAllowed ? (
    <ClientFamilyForm
      onSubmitButtonTitle="Edit Family & Friends"
      isPending={isPending}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      title="Edit Client Family & Friends"
      validationSchema={validationSchema}
    />
  ) : (
    <NoPermission />
  );
}

export default EditClientFamilyForm;
