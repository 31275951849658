import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MdDelete } from "react-icons/md";
import ButtonLoading from "../../../ButtonLoading/ButtonLoading";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import mutateDataQuery from "../../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FileUploader from "../../../FileUploader/FileUploader";

const fetchCities = () => fetchDataQuery("/search/api/v1/get-cities/");
const fetchAreas = (city) => fetchDataQuery(`/search/api/load-areas/?city_name=${city}`);
const fetchHotels = (city, area) => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]&areas=[${area}]`);

const AddEditHotelTool = () => {
  const [toolDocument, setToolDcoument] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [chosenHotelVal, setChosenHotelsVal] = useState([]);
  const [adding, setAdding] = useState(false);
  const [inputPairs, setInputPairs] = useState([
    { id: 1, stay_period_start: null, stay_period_end: null, checkedDays: {} },
  ]);
  const navigate = useNavigate();
  const { mutate: upgradeTool, isLoading } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery("/upgrade-tool/api/v1/hotel-upgrade-extra/", "post", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    mutationKey: "upgradeTool",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Offer Created Successfully");
      navigate(`/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list`);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to create new offer");
    },
  });
  const hotelOptions = useMemo(() => chosenHotels, [chosenHotels]);

  const addDateRange = () => {
    setClicked(true);
    if (inputPairs[inputPairs.length - 1].stay_period_start && inputPairs[inputPairs.length - 1].stay_period_end) {
      setInputPairs([
        ...inputPairs,
        { id: Date.now(), stay_period_start: null, stay_period_end: null, checkedDays: {} },
      ]);
      setClicked(false);
    }
  };

  const removeDateRange = (index) => {
    const updatedRanges = inputPairs.filter((_, i) => i !== index);
    setInputPairs(updatedRanges);
  };

  const isDateDisabled = (date, currentIndex, isStart) => {
    return inputPairs.some((range, index) => {
      if (index === currentIndex) return false;
      if (isStart) {
        return date >= range.stay_period_start && date <= range.stay_period_end;
      } else {
        return date >= range.stay_period_start && date <= range.stay_period_end;
      }
    });
  };

  const days = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const handleDaysChange = (pairId, day) => {
    setInputPairs((prevInputPairs) =>
      prevInputPairs.map((pair) => {
        if (pair.id === pairId) {
          return {
            ...pair,
            checkedDays: {
              ...pair.checkedDays,
              [day]: !pair.checkedDays[day],
            },
          };
        }
        return pair;
      }),
    );
  };

  const {
    data: cities,
    isLoading: loadingCities,
    error: citiesError,
  } = useQuery({
    queryKey: "cities",
    queryFn: fetchCities,
  });

  const chosenCityVal = chosenCity && chosenCity.value;

  const {
    data: areas,
    isLoading: loadingAreas,
    error: areasError,
    refetch: refetchAreas,
  } = useQuery({
    queryKey: ["areas", chosenCityVal],
    queryFn: () => fetchAreas(chosenCityVal),
    enabled: !!chosenCityVal && chosenCityVal !== "All",
  });

  const {
    data: hotels,
    isLoading: loadingHotels,
    error: hotelsError,
    refetch: refetchHotels,
  } = useQuery({
    queryKey: ["hotels", chosenCityVal, chosenArea],
    queryFn: () => {
      const areaValue = chosenArea
        ? chosenArea.value === "All"
          ? areas.map((area) => area.id)
          : chosenArea.value
        : "All";
      return fetchHotels(chosenCityVal, areaValue);
    },
    enabled: !!chosenCityVal && (!!chosenArea || chosenCityVal === "All"),
  });

  useEffect(() => {
    if (chosenCity) {
      if (chosenCity.value !== "All") {
        refetchAreas();
      } else {
        setChosenArea({ label: "All", value: "All" });
      }
    }
  }, [chosenCity, refetchAreas]);

  useEffect(() => {
    if (chosenArea) {
      refetchHotels();
    }
  }, [chosenArea, refetchHotels]);

  useEffect(() => {
    if (hotels) {
      setChosenHotels(hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })));
    }
  }, [hotels]);

  const cityOptions = useMemo(() => cities?.map((city) => ({ label: city.name, value: city.id })), [cities]);
  const areaOptions = useMemo(
    () => [{ label: "All", value: "All" }, ...(areas?.map((area) => ({ label: area.name, value: area.id })) || [])],
    [areas],
  );
  const arabicRegex = /^[\u0600-\u06FF\u0660-\u0669\d\s]+$/;
  const checkUniqueDates = (pairs, field) => {
    const dates = pairs.map((pair) => pair[field]).filter((date) => date);
    const uniqueDates = new Set(dates);
    return dates.length === uniqueDates.size;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Offer name is required"),
    name_ar: Yup.string()
      .matches(arabicRegex, "Offer name (Arabic) must be in Arabic")
      .required("Offer name (Arabic) is required"),
    description: Yup.string().required("Description is required"),
    description_ar: Yup.string()
      .matches(arabicRegex, "Description (Arabic) must be in Arabic")
      .required("Description (Arabic) is required"),
    inputPairs: Yup.array().of(
      Yup.object().shape({
        stay_period_start: Yup.date()
          .required("Start date is required")
          .nullable()
          .test("unique-start-date", "This start date is already chosen", function (value) {
            return checkUniqueDates(this.parent, "stay_period_start");
          }),
        stay_period_end: Yup.date()
          .required("End date is required")
          .nullable()
          .min(Yup.ref("stay_period_start"), "End date cannot be before start date")
          .test("unique-end-date", "This end date is already chosen", function (value) {
            return checkUniqueDates(this.parent, "stay_period_end");
          }),
      }),
    ),
    // upgrade_document: Yup.mixed().required("Upgrade document is required"),
    hotel: Yup.string().required("Hotel selection is required"),
  });

  const dayMap = {
    Saturday: "Sat",
    Sunday: "Sun",
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
  };

  return (
    <div className="formPage-wrap ">
      <h2 className="text-2xl font-semibold mb-4">Add Hotel extra offer</h2>
      <Formik
        initialValues={{
          city: "",
          area: "",
          name: "",
          name_ar: "",
          description: "",
          description_ar: "",
          upgrade_document: null,
          hotel: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setAdding(true);

          try {
            const formData = new FormData();
            formData.append("city", values.city);
            formData.append("area", values.area);
            formData.append("name", values.name);
            formData.append("name_ar", values.name_ar);
            formData.append("description", values.description);
            formData.append("description_ar", values.description_ar);
            formData.append("hotel", values.hotel);

            if (toolDocument.length > 0) {
              for (let doc of toolDocument) {
                if (!doc.valid) {
                  toast.error(`File ${doc.name} is not valid because ${doc.errors[0]}`);
                  return;
                }
                formData.append("upgrade_document", doc.file);
              }
            }
            // formData.append("upgrade_document", values.upgrade_document);
            inputPairs?.forEach((pair, index) => {
              formData.append(`period_start_${index}`, pair.stay_period_start || "");
              // .toISOString().split("T")[0]
              formData.append(`period_end_${index}`, pair.stay_period_end || "");
              formData.append(
                `days_of_week_${index}`,
                `[${Object.keys(pair.checkedDays)
                  .filter((day) => pair.checkedDays[day])
                  .map((day) => `"${dayMap[day]}"`)
                  .join(",")}]`,
              );
            });
            // Use the FormData for the API call
            await upgradeTool(formData);
          } catch (error) {
            // Handle error

            toast.error(error.message || "Failed to create new offer");
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit, isSubmitting, handleBlur }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-6 form-wrap">
            <div className=" border p-2 grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 rounded-md">
              <div className="input-chil">
                <label className="font-semibold w-full flex gap-x-2 text-[#002244]">City</label>
                <Select
                  required
                  className="w-full h-10 text-gray-800 font-semibold border rounded-lg bg-white"
                  options={cityOptions}
                  placeholder="Select City"
                  onChange={(e) => {
                    setChosenCity(e);
                    setFieldValue("city", e);
                  }}
                  value={chosenCity}
                  isLoading={loadingCities}
                />
                {citiesError && <p className="text-red-500">{citiesError.message}</p>}
              </div>
              <div className="input-chil">
                <label className="w-full text-gray-600 font-semibold flex items-center">Area</label>
                <Select
                  name="area"
                  options={areaOptions}
                  isSearchable
                  value={chosenArea}
                  isDisabled={chosenCityVal ? loadingAreas : true}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: errors.area && touched.area && "rgb(135 27 27)",
                    }),
                  }}
                  className="w-full h-10 rounded-lg bg-white"
                  placeholder="Area"
                  onChange={(e) => {
                    setChosenArea(e);
                    setFieldValue("area", e);
                  }}
                  onBlur={handleBlur}
                />
                {errors.area && touched.area && (
                  <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                    {errors.area}
                  </div>
                )}
              </div>
              <div className="input-chil">
                <label className="w-full text-gray-600 font-semibold flex items-center">Hotel</label>
                <Select
                  name="hotel"
                  options={hotelOptions}
                  isSearchable
                  isDisabled={chosenCity && chosenArea ? false : true}
                  value={chosenHotelVal}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: errors.hotel && touched.hotel && "rgb(135 27 27)",
                    }),
                  }}
                  className="w-full  rounded-lg bg-white"
                  placeholder="Hotel"
                  onChange={(e) => {
                    setFieldValue("hotel", e.value);
                    setChosenHotelsVal(e);
                  }}
                  onBlur={handleBlur}
                />
                {errors.hotel && touched.hotel && (
                  <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                    {errors.hotel}
                  </div>
                )}
              </div>
            </div>

            <div className="input-par">
              <div className="input-chil">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Offer Name
                </label>
                <input
                  id="name"
                  type="text"
                  className="mt-1 block w-full border p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
                {errors.name && touched.name && <div className="text-red-600 text-sm">{errors.name}</div>}
              </div>
              <div className="input-chil">
                <label dir="rtl" htmlFor="name_ar" className="block  text-sm font-medium text-gray-700">
                  إسم العرض
                </label>
                <input
                  id="name_ar"
                  dir="rtl"
                  type="text"
                  className="mt-1 block border p-2 w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={values.name_ar}
                  onChange={(e) => setFieldValue("name_ar", e.target.value)}
                />
                {errors.name_ar && touched.name_ar && <div className="text-red-600 text-sm">{errors.name_ar}</div>}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                rows={5}
                dir="ltr"
                id="description"
                className="mt-1 border p-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />
              {errors.description && touched.description && (
                <div className="text-red-600 text-sm">{errors.description}</div>
              )}
            </div>
            <div className="form-group">
              <label dir="rtl" htmlFor="description_ar" className="block text-sm font-medium text-gray-700">
                الوصف
              </label>
              <textarea
                rows={5}
                dir="rtl"
                id="description_ar"
                className="mt-1 block w-full border p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={values.description_ar}
                onChange={(e) => setFieldValue("description_ar", e.target.value)}
              />
              {errors.description_ar && touched.description_ar && (
                <div className="text-red-600 text-sm">{errors.description_ar}</div>
              )}
            </div>
            <div className="border  p-2 rounded-lg ">
              <label
                htmlFor="validities"
                className="text-center !text-lg font-semi-bold header-h2 justify-center flex w-full"
              >
                Validities
              </label>
              <div className="flex flex-col gap-2">
                {inputPairs.map((pair, index) => (
                  <div key={pair.id} className="border p-4 rounded-lg bg-gray-50 space-y-4 relative">
                    <label className="block text-lg font-semibold">Stay Period {index + 1}</label>
                    <div className="input-par">
                      <div className="input-chil">
                        <label htmlFor={`start_date_${pair.id}`} className="block text-sm font-medium text-gray-700">
                          Start Date
                        </label>
                        <input
                          id={`start_date_${pair.id}`}
                          type="date"
                          className="mt-1 block w-full py-1.5 px-2 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm floating-input"
                          value={pair.stay_period_start || ""}
                          onChange={(e) => {
                            const startDate = e.target.value;
                            setInputPairs((prevInputPairs) =>
                              prevInputPairs.map((p) =>
                                p.id === pair.id ? { ...p, stay_period_start: startDate } : p,
                              ),
                            );
                          }}
                          min={inputPairs[index - 1]?.stay_period_end || ""}
                          disabled={isDateDisabled(new Date(pair.stay_period_start), index, true)}
                          required
                        />
                      </div>
                      <div className="input-chil">
                        <label htmlFor={`end_date_${pair.id}`} className="block text-sm font-medium text-gray-700">
                          End Date
                        </label>
                        <input
                          id={`end_date_${pair.id}`}
                          type="date"
                          className="mt-1 block w-full py-1.5 px-2 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm floating-input"
                          value={pair.stay_period_end || ""}
                          onChange={(e) => {
                            const endDate = e.target.value;
                            setInputPairs((prevInputPairs) =>
                              prevInputPairs.map((p) => (p.id === pair.id ? { ...p, stay_period_end: endDate } : p)),
                            );
                          }}
                          min={pair.stay_period_start || ""}
                          disabled={isDateDisabled(new Date(pair.stay_period_end), index, false)}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <label htmlFor="activeDays"> Active Days</label>
                        <div className="grid lg:grid-cols-7 md:grid-cols-4 grid-cols-2  w-full">
                          {days.map((day, index) => (
                            <div key={index} className="flex w-full">
                              <label key={day} className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  checked={pair.checkedDays[day] || false}
                                  onChange={() => handleDaysChange(pair.id, day)}
                                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                                <span className="text-sm text-gray-700">{day}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className=" mt-4">
                        {clicked && (
                          <p className="text-red-700 text-sm">
                            {pair?.stay_period_start === null || pair?.stay_period_end === null
                              ? "Please select a start date and end date"
                              : ""}
                          </p>
                        )}
                      </div>
                    </div>
                    {inputPairs?.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeDateRange(index)}
                        className="absolute top-0 right-[3%] flex items-center bg-red-600 hover:bg-red-700 text-sm bg text-white rounded-full p-3"
                      >
                        <MdDelete size={20} className="rounded-full" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
              <div className="mt-4 flex justify-center">
                <button
                  type="button"
                  onClick={addDateRange}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add Date Range
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="upgrade_document" className="block text-sm font-medium text-gray-700">
                Upgrade Document
              </label>
              <FileUploader
                maxFiles={1}
                maxFileSize={2}
                accept={"image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .csv"}
                setFiles={setToolDcoument}
                files={toolDocument}
                // onChange={(e) => onImageChange(e, setFieldValue)}
              />
              {/* <input
                id="upgrade_document"
                type="file"
                className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border file:border-gray-300 file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100"
                onChange={(e) => onImageChange(e, setFieldValue)}
              /> */}
              {errors.upgrade_document && touched.upgrade_document && (
                <div className="text-red-600 text-sm">{errors.upgrade_document}</div>
              )}
              {imagePreview && <img src={imagePreview} alt="Preview" className="mt-2 w-32 h-32 object-cover" />}
            </div>
            <div className="mt-6 flex justify-center items-center">
              <button type="submit" disabled={isSubmitting} className="addButton">
                {adding ? (
                  <div className="flex items-center gap-2">
                    {" "}
                    Adding...
                    <ButtonLoading />
                  </div>
                ) : (
                  "Add"
                )}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddEditHotelTool;
