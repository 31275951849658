import React from "react";
import { useParams } from "react-router";
import PaymentCashDeposit from "../PaymentCashDeposit/PaymentCashDeposit";
import CashInHotelOrder from "../CashInHotelOrder/CashInHotelOrder";
import PaymentLink from "../PaymentLink/PaymentLink";
import OnlineBankTransfer from "../OnlineBankTransfer/OnlineBankTransfer";

const HotelPaymentOrderDetails = () => {
  const params = useParams();
  const handlePagesNavigation = () => {
    if (params.title === "payment_cash_deposit") {
      return <PaymentCashDeposit />;
    } else if (params.title === "cash_in_hotel") {
      return <CashInHotelOrder />;
    } else if (params.title === "payment_link") {
      return <PaymentLink />;
    } else if (params.title === "online_bank_transfer") {
      return <OnlineBankTransfer />;
    }
  };

  return <>{handlePagesNavigation()}</>;
};

export default HotelPaymentOrderDetails;
