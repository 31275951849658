import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");
export default function addHotel(data, callback) {
  return async function () {
    try {
      const response = await api_token(token).post("/en/hotels/api/v1/add-get-hotels/", data, {
        headers: {
          "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });

      if (callback) {
        callback(response);
      }
    } catch (error) {}
  };
}
