import React, { useEffect, useState } from "react";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import AccountUserCustomer from "./AccountUserCustomer";
import AccountUserAgent from "./AccountUserAgent";
import Loading from "../../organisms/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import SearchUserGroup from "./SearchUserGroup";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import NoPermission from "../../organisms/NoPermission/NoPermission";

const AccountUser = () => {
  const [data, setData] = useState([]);
  const uri = useSelector((state) => state.uriReducer);
  const [debouncedURI, setDebouncedURI] = useState("");
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  const { id } = useParams();
  const token = cookie.load("access_token");

  // permissions_management.users_profile
  const dispatch = useDispatch();
  const permissionData = {
    permission_codes: [" permissions_management.users_profile"],
  };
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (uri) {
        setDebouncedURI(uri);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [uri]);

  const {
    data: user,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["user", id],
    queryFn: () => fetchDataQuery(`/auth/api/v1/profile/${id}/`),
    // enabled: !!debouncedURI, // Ensure the query only runs if debouncedURI is not empty
  });
  useEffect(() => {
    if (isSuccess) {
      setData(user);
    }
  }, [id, debouncedURI, user, uri]);
  //   if (isLoading) {
  //     <Loading />;
  //   }

  {
    return data && data.user_type && permission?.map((i) => i?.value) ? (
      <>
        {data && data.user_type && permission && permission?.map((i) => i?.value === true) ? (
          data.user_type === "b2c" || (uri === "user_customer" && isSuccess && data) ? (
            <AccountUserCustomer data={data} isSuccess={isSuccess} />
          ) : data.user_type === "b2b" || (uri === "user_agent" && isSuccess && data) ? (
            <AccountUserAgent data={data} isSuccess={isSuccess} />
          ) : data.user_type === "staff" || (uri === "users" && isSuccess && data) ? (
            <SearchUserGroup userData={data} isSuccess={isSuccess} />
          ) : (
            // <div className="text-center font-bold h-[50vh] flex justify-center capitalize items-center md:text-xl">
            //   <span className="bg-gray-100 text-gray-500 px-4 py-8 rounded-md shadow-md">
            //     this user with id <span className=" border-black border-b-2 text-black ">{id}</span> Has No Data{" "}
            //   </span>
            // </div>
            <Loading />
          )
        ) : (
          <NoPermission />
        )}
      </>
    ) : (
      <Loading />
    );
  }
};

export default AccountUser;
