import { SET_URI } from "../types/types";

const initialState = "";

export const uriReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_URI:
      return action.payload;
    default:
      return state;
  }
};
