import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoIosStats } from "react-icons/io";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import NoPermission from "../NoPermission/NoPermission";

const ContractsStatistics = () => {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "permissions_management.view_contract_department_report",
  ]);

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch Error={"Error Fetching Permissions"} />;
  }

  const canViewContractDepartmentReport =
    permissions?.find((permission) => permission.name === "permissions_management.view_contract_department_report")
      ?.value === true;

  const allPermissions = canViewContractDepartmentReport;

  return allPermissions ? (
    <div className="border-gray-300 border rounded-lg bg-white p-4 w-[95%] mx-auto">
      <div className="w-full flex justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 mt-3">
        <p className="flex items-center gap-x-3">
          <IoIosStats className="w-5 h-5" />
          Contracts Statistics
        </p>
      </div>
      <div className="flex flex-wrap gap-4 justify-around items-center mt-12">
        <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
          <Link
            to={"./contract-department-report"}
            className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
          >
            <div className="w-full flex justify-center items-center ">
              <HiOutlineDocumentReport className="w-12 h-12  " />
            </div>
          </Link>
          <p className="text-lg font-semibold">Contract Department Report</p>
        </div>
      </div>
    </div>
  ) : (
    <NoPermission />
  );
};

export default ContractsStatistics;
