import React from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { IoIosAdd, IoIosDoneAll } from "react-icons/io";
import { CiCircleList } from "react-icons/ci";
import { MdDelete, MdOutlinePending } from "react-icons/md";
import usePermissions from "../../../../customHooks/usePermissions";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import NoPermission from "../../NoPermission/NoPermission";
import { IoLockClosedOutline } from "react-icons/io5";

function ITTickets() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "it_ticketing_system.add_ticketsupport",
    "it_ticketing_system.view_ticketsupport",
  ]);

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch Error={"Error fetching permissions"} />;
  }

  const cadAddTicket =
    permissions?.find((permission) => permission.name === "it_ticketing_system.add_ticketsupport")?.value === true;

  const canViewTickets =
    permissions?.find((permission) => permission.name === "it_ticketing_system.view_ticketsupport")?.value === true;

  return canViewTickets || cadAddTicket ? (
    <div className="border-gray-300 border rounded-lg bg-white p-4 w-[95%] mx-auto">
      <div className="w-full flex justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 mt-3">
        <p className="flex items-center gap-x-3">
          <RiKeyboardLine className="w-5 h-5" />
          IT DEPARTMENT - TICKETS
        </p>
      </div>
      <div className="flex flex-wrap gap-4 justify-around items-center mt-12">
        {cadAddTicket && (
          <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"./add-ticket"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <IoIosAdd className="w-12 h-12  " />
              </div>
            </Link>
            <p className="text-lg font-semibold">Add New Ticket</p>
          </div>
        )}

        {canViewTickets && (
          <>
            <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={"./tickets?status=open"}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-emerald-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <CiCircleList className="w-12 h-12  " />
                </div>
              </Link>
              <p className="text-lg font-semibold">Open Tickets List</p>
            </div>

            <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={"./tickets?status=in_progress"}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-yellow-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <MdOutlinePending className="w-12 h-12  " />
                </div>
              </Link>
              <p className="text-lg font-semibold">In Progress Tickets List</p>
            </div>

            <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={"./tickets?status=resolved"}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-green-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <IoIosDoneAll className="w-12 h-12  " />
                </div>
              </Link>
              <p className="text-lg font-semibold">Resolved Tickets List</p>
            </div>

            <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={"./tickets?status=closed"}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-black text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <IoLockClosedOutline className="w-12 h-12  " />
                </div>
              </Link>
              <p className="text-lg font-semibold">Closed Tickets List</p>
            </div>

            <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={"./tickets/soft-deleted"}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <MdDelete className="w-12 h-12  " />
                </div>
              </Link>
              <p className="text-lg font-semibold">Deleted Tickets List</p>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}

export default ITTickets;
