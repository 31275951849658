import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUnderReviewContracts } from "../../../redux/actions/getContracts";
import { useLocation, useNavigate, useParams } from "react-router";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import NoPermission from "../NoPermission/NoPermission";
import { AiOutlineEye } from "react-icons/ai";
import { BiSolidOffer, BiTransfer } from "react-icons/bi";
import { TbBuildingEstate } from "react-icons/tb";
import { MdInfoOutline, MdOutlinePriceCheck, MdOutlineSettings } from "react-icons/md";
import { FaRegFileAlt } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import { GoPerson } from "react-icons/go";
import { CiCalendar } from "react-icons/ci";
import { Link } from "react-router-dom";
import ListFilters from "../../../utils/ListFilters";
import { splitAndCapitalize } from "../../../utils/stringUtils";

export default function ContractApproval() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { sorting, page } = useParams();
  const [listPageSize, setListPageSize] = useState(20);
  const [columnVisibility, setColumnVisibility] = useState({});
  const params = useParams();

  const contracts = useSelector((state) => state.underReviewContracts);
  const currentPage = Number(page) || 1;
  const totalPages = Math.ceil(contracts?.count / listPageSize);

  useEffect(() => {
    dispatch(getUnderReviewContracts(sorting, currentPage, () => setIsLoading(false), listPageSize));
  }, [sorting, currentPage, location]);
  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("contract-Appr-grid-visibility");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility) });
    }
  }, []);

  const rows = contracts.results.map((contract) => {
    let time, hours, mins, secs;
    if (contract.created_time) {
      time = contract.created_time;
      hours = Math.floor(time / 3600);
      mins = Math.floor((time - hours * 3600) / 60);
      secs = time - mins * 60;
    }
    return {
      id: contract.id,
      status: contract.status,
      hotelName: contract.hotel.name,
      name: contract.name,
      offerType: contract.offer_type.name,
      contractType: splitAndCapitalize(contract.contract_type, "_"),
      durationTime: time
        ? `${hours.toString().padStart(2, 0)}:${mins.toString().padStart(2, 0)}:${secs.toString().padStart(2, 0)}`
        : "----",
      createdBy: contract.created_by ? contract.created_by.username : "----",
      approvedBy: "----",
      created_date: contract.created_date.split("T")[0],
      approvedDate: "----",
      //price tool
      city: contract?.hotel?.city,
      area: contract?.hotel?.area,
      // hotelName: contract?.hotel.id,
      contractName: contract?.name,
    };
  });

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
      render: (text, params) => {
        return (
          <div key={params.id} className="w-full flex items-center gap-x-1 ">
            {permission.map((item) => {
              if (item.name === "contracts_management.view_contract" && item.value === true) {
                return (
                  <div
                    className="w-full cursor-pointer"
                    onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
                  >
                    {params.id}{" "}
                  </div>
                );
              }
            })}
          </div>
        );
      },
    },
    {
      key: "status",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdInfoOutline className="w-5 h-5  text-yellow-500" />
          Status
        </div>
      ),
      //   minWidth: 180,
      dataIndex: "status",
      render: (text, params) => {
        //

        return (
          <Link
            to={`/dashboard/contract-approval/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 `}
            // onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.status}
          </Link>
        );
      },
    },
    {
      key: "hotelName",
      title: (
        <div className=" flex  items-center gap-x-1 text-xs">
          <TbBuildingEstate className="w-5 h-5  text-yellow-500 mb-1" />
          HOTEL NAME
        </div>
      ),
      dataIndex: "hotelName",
      render: (text, params) => {
        //

        return (
          <Link
            to={`/dashboard/contract-approval/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 `}
            onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.hotelName}
          </Link>
        );
      },
    },
    {
      key: "name",
      title: (
        <div className=" flex  items-center gap-x-1 text-xs">
          <FaRegFileAlt className="w-5 h-5  text-yellow-500 mb-1" />
          Contract NAME
        </div>
      ),
      dataIndex: "name",

      render: (text, params) => {
        //

        return (
          <Link
            to={`/dashboard/contract-approval/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 `}
            onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.name}
          </Link>
        );
      },
    },
    {
      key: "contractType",
      title: (
        <div className=" flex  items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500 mb-1" />
          Contract Type
        </div>
      ),
      dataIndex: "contractType",
    },
    {
      key: "offerType",
      title: (
        <div className=" flex  items-center gap-x-1 text-xs">
          <BiSolidOffer className="w-5 h-5  text-yellow-500 mb-1" />
          Offer Type
        </div>
      ),
      //   minWidth: 200,
      dataIndex: "offerType",
      render: (text, params) => {
        //

        return (
          <Link
            to={`/dashboard/contract-approval/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 `}
            onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.offerType}
          </Link>
        );
      },
    },
    {
      key: "durationTime",
      title: (
        <div className=" flex  items-center gap-x-1 text-xs">
          <GiDuration className="w-5 h-5  text-yellow-500 mb-1" />
          Duration Time
        </div>
      ),
      //   minWidth: 150,
      dataIndex: "durationTime",
      render: (text, params) => {
        //

        return (
          <Link
            to={`/dashboard/contract-approval/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 `}
            onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.durationTime}
          </Link>
        );
      },
    },
    {
      key: "createdBy",
      title: (
        <div className=" flex  items-center gap-x-1 text-xs">
          <GoPerson className="w-5 h-5  text-yellow-500 mb-1" />
          Created By
        </div>
      ),
      //   minWidth: 150,
      dataIndex: "createdBy",
      render: (text, params) => {
        //

        return (
          <Link
            to={`/dashboard/contract-approval/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 `}
            onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.createdBy}
          </Link>
        );
      },
    },
    {
      key: "created_date",
      title: (
        <div className=" flex  items-center gap-x-1 text-xs">
          <CiCalendar className="w-5 h-5  text-yellow-500 mb-1" />
          Created Date
        </div>
      ),
      //   minWidth: 250,
      dataIndex: "created_date",
      render: (text, params) => {
        //

        return (
          <Link
            to={`/dashboard/contract-approval/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 `}
            onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.created_date}
          </Link>
        );
      },
    },
    {
      key: "operations",
      title: (
        <div className="flex text-center !justify-center !items-center  w-full text-xs uppercase">
          <MdOutlineSettings className="w-5 h-5   text-yellow-500" />
          Operations
        </div>
      ),
      dataIndex: "operations",
      render: (text, record) => {
        return (
          <div className={` flex items-center justify-center text-center gap-x-1 md:gap-x-1.5 w-full `}>
            <Link
              to={`/dashboard/price-test-tool?cityQuery=${record?.city}&areaQuery=${record?.area}&hotelNameQuery=${record?.hotelName}&contractNameQuery=${record?.contractName}`}
              target="_blank"
              className="flex items-center gap-x-1 bg-green-700 md:px-1.5 md:py-1 p-1 rounded-md hover:bg-green-600 active:scale-95 duration-300 transition-all"
            >
              <MdOutlinePriceCheck className="w-5 h-5  text-white " />
            </Link>
          </div>
        );
      },
    },
  ];

  function renderId(params) {
    return (
      <div className="w-full flex items-center gap-x-1 ">
        <div className="w-[50%]">{params.id} </div>
        {permission.map((item) => {
          if (item.name === "contracts_management.view_contract" && item.value === true) {
            return (
              <AiOutlineEye
                className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800"
                onClick={() => navigate(`/dashboard/contract-approval/${params.id}`)}
              />
            );
          }
        })}
      </div>
    );
  }

  function handlePageChange(params, size) {
    setListPageSize(size);

    navigate(`/dashboard/contract-approval/${params}/${sorting}`);
    setIsLoading(true);
  }

  function comparator([params]) {
    if (params) {
      if (params.sort === "desc") {
        navigate(`/dashboard/contract-approval/${page}/-${params.key}`);
      } else {
        navigate(`/dashboard/contract-approval/${page}/${params.key}`);
      }
    } else {
      navigate(-1);
    }
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["contracts_management.view_contract"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap h-full">
      <div className=" w-full overflow-x-auto min-h-screen">
        {isLoading || !permissionFlag ? (
          <div className="flex items-center justify-center w-full h-screen">
            <Loading /> <TopBarProgress />
          </div>
        ) : permissionFlag && permission.some((item) => item.value) ? (
          <div className="w-full overflow-auto">
            <ListFilters
              columns={columns}
              rows={rows}
              title="Contract Approval List"
              handlePagination={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
              listPageSize={listPageSize}
            />
          </div>
        ) : (
          // <Box sx={{ height: "100%", flex: "100%", backgroundColor: "white" }} className="w-full max-md:w-[350%] ">
          //   <DataGrid
          //     columns={columns}
          //     rowCount={contracts.count}
          //     autoHeight
          //     rows={rows}
          //     slots={{
          //       Toolbar: GridToolbar,
          //     }}
          //     paginationModel={{ page: Number(page - 1), pageSize: 5 }}
          //     onPaginationModelChange={handlePageChange}
          //     paginationMode="server"
          //     onSortModelChange={comparator}
          //     columnVisibilityModel={columnVisibility}
          //     onColumnVisibilityModelChange={(visibility) => {
          //       setColumnVisibility(visibility);
          //       localStorage.setItem("contract-Appr-grid-visibility", JSON.stringify(visibility));
          //     }}
          //   />
          // </Box>
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )}
      </div>
    </div>
  );
}
