import React from "react";
import DeleteNoteModal from "../modals/DeleteNoteModal";
import EditNoteModal from "../modals/EditNoteModal";
import { formatDate } from "../../../../utils/dateUtils";
import ChangeNoteStatus from "./ChangeNoteStatus";
import NoteDetailsModal from "../modals/NoteDetailsModal";
import { Tooltip } from "flowbite-react";

const isArabic = (char) => {
  const arabicRange = /^[\u0600-\u06FF]$/;
  return arabicRange.test(char);
};

function NoteCard({ note }) {
  const { id, title, content, status, created_at: createdAt, updated_at: updatedAt } = note;

  const isTitleArabic = isArabic(title.charAt(0));
  const isContentArabic = isArabic(content.charAt(0));
  const createdAtFormatted = formatDate(createdAt).slice(0, -3);
  const updatedAtFormatted = formatDate(updatedAt).slice(0, -3);

  return (
    <div
      className={`${status === "open" ? "bg-green-50 shadow-green-100" : "bg-gray-100"} flex h-48 sm:h-64  flex-col shadow-md p-4 rounded-xl border border-gray-400`}
    >
      <div className={`flex justify-between items-center ${isTitleArabic && "flex-row-reverse"}`}>
        <h4
          className={`text-gray-800 font-bold mb-2 ${isTitleArabic ? "text-right" : "text-left"}`}
          style={{ direction: isTitleArabic ? "rtl" : "ltr" }}
        >
          {title}
        </h4>
        <ChangeNoteStatus id={id} status={status} />
      </div>
      <p
        className={`flex-grow text-gray-800 dark:text-gray-100 text-sm ${content.length > 180 && "mb-1"}  ${isContentArabic ? "text-right" : "text-left"}`}
        style={{ direction: isContentArabic ? "rtl" : "ltr" }}
      >
        {content.length <= 180 ? content : `${content.slice(0, 180)}... `}
        {content.length > 180 && <NoteDetailsModal note={note} />}
      </p>
      <div className="flex justify-between items-center">
        {formatDate(createdAt) === formatDate(updatedAt) ? (
          <p className="text-sm">
            <span className="font-medium">Created At: </span>
            {createdAtFormatted}
          </p>
        ) : (
          <Tooltip content={`Created At: ${createdAtFormatted}`}>
            <p className="text-sm">
              <span className="font-medium">Updated At: </span>
              {updatedAtFormatted}
            </p>
          </Tooltip>
        )}

        <div className="flex gap-2">
          {status !== "closed" && <EditNoteModal note={note} />}
          <DeleteNoteModal id={id} title={title} />
        </div>
      </div>
    </div>
  );
}

export default NoteCard;
