import * as yup from "yup";

export const addHotelValidationSchema = yup.object().shape({
  name: yup.string().required("This Field is Required"),
  name_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),
  _country: yup.string().required("This Field is Required"),
  city: yup.string().required("This Field is Required"),
  area: yup.string().required("This Field is Required"),
  address: yup.string().required("This Field is Required"),
  address_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),

  hotelMapAddress: yup.string(),
  payment_type: yup.string().required("This Field is Required"),
  internet: yup.string().required("This Field is Required"),
  description: yup.string().required("This Field is Required"),
  description_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),
  telephone: yup.array().of(yup.number("This field must be a number").required("This Field is Required")),
  reservationTelephone: yup.array().of(yup.number("This field must be a number").required("This Field is Required")),
  reservationEmail: yup.array().of(yup.string("This field must be a text").required("This Field is Required")),
  receptionEmail: yup.array().of(yup.string("This field must be a text").required("This Field is Required")),
  restaurants: yup.array().of(yup.string("This field must be a text").required("This Field is Required")),
  images: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required("ID is required"),
        file: yup.mixed().required("File is required"),
        name: yup.string().required("Name is required"),
        size: yup.number().required("Size is required"),
        type: yup.string().required("Type is required"),
        valid: yup.boolean().required("Valid status is required"),
      }),
    )
    .min(1, "At least one image is required")
    .required("This field is required"),
  videos: yup.array(),
  rate: yup.number().required("This Field is Required"),
  features: yup.array().of(yup.string("This field must be a text")).min(1, "This Field is Required"),
  // extra_info: yup.array().of(yup.string("This field must be a text")).min(1, "This Field is Required"),
});
