import api_token from "../../api/UserApi";

export const getPermissionAction = (token, permission, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post(`auth/api/v1/check-permissions/`, permission, { signal });

      if (response.status === 200) {
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};

export const getPaymentPermissionAction = (token, permission, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post(`auth/api/v1/check-permissions-new/`, permission);

      if (response.status === 200) {
        callback(response);
      }
    } catch (error) {}
  };
};
