import { GET_DEPARTMENT_CHOICES, GET_SINGLE_HOTEL_CONTACT, SINGLE_HOTEL_CONTACT_LOADING } from "../types/types";

export function departmentChoicesReducer(state = [], action) {
  switch (action.type) {
    case GET_DEPARTMENT_CHOICES:
      return action.payload;
    default:
      return state;
  }
}

const singleHotelContactInitialState = {
  loading: false,
  data: {},
};
export function singleHotelContactReducer(state = singleHotelContactInitialState, action) {
  switch (action.type) {
    case SINGLE_HOTEL_CONTACT_LOADING:
      return { ...state, loading: true };
    case GET_SINGLE_HOTEL_CONTACT:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}
