import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import DashboardSidebar from "../DashboardSidebar/DashboardSidebar";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { flagListAction } from "../../../redux/actions/addCashBoxAction";

export default function PopUp({ close, isOpen, image, sidebar, video, header }) {
  const { listFlag } = useSelector((state) => state.addCashBoxReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(flagListAction(true));
  }, []);
  return (
    <>
      {/* <div className="fixed inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={open}
          className="rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
        >
          Open dialog
        </button>
      </div> */}

      <Transition appear show={isOpen && listFlag} as={Fragment} className="w-full z-50">
        <Dialog as="div" className="relative z-50 " onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto z-50">
            <div className="flex min-h-full items-center justify-center p-4 text-center w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className=" w-full flex justify-between items-center text-lg font-medium  text-gray-900"
                  >
                    {sidebar ? "Sidebar" : header ? header : "The Transaction Receipt"}
                    <button onClick={close} className="cursor-pointer">
                      {" "}
                      <IoCloseCircleOutline className="w-5 h-5" />
                    </button>
                  </Dialog.Title>
                  <div className="w-full flex justify-center items-center mt-5">
                    {sidebar ? (
                      <DashboardSidebar />
                    ) : video ? (
                      <video src={video} className="shadow-lg rounded-lg w-full " controls />
                    ) : (
                      <img src={image} className="w-[60%]" />
                    )}
                  </div>

                  {/* <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={close}
                    >
                      thanks!
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
