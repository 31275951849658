import React from "react";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Select from "react-select";
import { FieldErrorText, FormLabel } from "../../../customComponents/FormComponents";
import { useFormikContext } from "formik";

function CitySelectInput() {
  const { setValues } = useFormikContext();

  const { isLoading, data, error } = useQuery({
    queryKey: "city",
    queryFn: () => fetchDataQuery("/search/api/v1/get-cities/"),
  });

  return (
    <div>
      <FormLabel label={"City"} htmlFor={"city"} />
      <Select
        name={"city"}
        id={"city"}
        isLoading={isLoading}
        isDisabled={isLoading}
        onChange={(newValue) => {
          setValues({
            city: newValue.value,
            hotel: "",
            room: "",
            roomData: "",
          });
        }}
        loadingMessage={() => "Getting cities"}
        placeholder="Choose City"
        options={data?.map((city) => {
          return {
            value: city.id,
            label: city.name,
          };
        })}
      />
      {error && <FieldErrorText message={"Error fetching cities"} />}
    </div>
  );
}

export default CitySelectInput;
