import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { getData } from "../../../redux/actions/sendDataAction";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import { BiStats } from "react-icons/bi";

ChartJS.register(ArcElement, Tooltip, Legend);

function StatisticsPage() {
  const [statistcis, setStatistics] = useState([]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(
      getData(
        `/report/link-customer/`,
        (resp) => {
          if (resp.status === 200) {
            setStatistics(resp.data);
          }
        },
        (err) => {},
      ),
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = {
    labels: ["New", "Open", "Expired", "Booked"],
    datasets: [
      {
        data: [statistcis["new"], statistcis["open"], statistcis["expired"], statistcis["booked"]],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const newPercent = Math.ceil((statistcis["new"] / statistcis["total"]) * 100);
  const openPercent = Math.ceil((statistcis["open"] / statistcis["total"]) * 100);
  const bookedPercent = Math.ceil((statistcis["booked"] / statistcis["total"]) * 100);
  const expiredPercent = Math.ceil((statistcis["expired"] / statistcis["total"]) * 100);
  return (
    <div className="formPage-wrap   ">
      <div className="flex justify-center">
        <h2 className="header-h2">
          {" "}
          <span>
            <BiStats />
          </span>{" "}
          <span>Statistics</span>{" "}
        </h2>
      </div>
      <div className="form-wrap flex justify-between max-md:flex-col">
        <div className="flex flex-col  gap-6 ">
          <p className="p-2 shadow-md rounded-md  bg-white flex justify-between gap-4">
            Number of total links : &nbsp;{statistcis["total"]}{" "}
          </p>
          <p className="p-2 shadow-md rounded-md bg-white  flex justify-between">
            <span>New links : &nbsp; {statistcis["new"]}</span>
            <span>{newPercent}%</span>
            <span className="w-4 h-6 border-[#ffaebd] border-2 bg-[#FFE0E6]"></span>
          </p>
          <p className="p-2 shadow-md rounded-md bg-white  flex justify-between">
            <span>Opened links :&nbsp; {statistcis["open"]}</span>
            <span>{openPercent}%</span>
            <span className="w-4 h-6 border-[#acd1ec] border-2 bg-[#D7ECFB]"></span>
          </p>
          <p className="p-2 shadow-md rounded-md bg-white  flex justify-between">
            <span>Expired links :&nbsp; {statistcis["expired"]}</span>
            <span>{bookedPercent}%</span>
            <span className="w-4 h-6 border-[#f8e3b2] border-2 bg-[#FFF5DD]"></span>
          </p>
          <p className="p-2 shadow-md bg-white rounded-md flex justify-between">
            <span>Booked links :&nbsp; {statistcis["booked"]}</span>

            <span>{expiredPercent}%</span>
            <span className="w-4 h-6 border-[#a6f6f6] border-2 bg-[#DBF2F2]"></span>
          </p>
        </div>
        <div className=" p-4 border md:w-[50%] max-md:my-4 w-full flex shadow-md rounded-md items-center justify-center bg-white">
          <Pie data={data} />
        </div>
      </div>
    </div>
  );
}

export default StatisticsPage;
