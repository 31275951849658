// Login
export const ISLOGIN = "ISLOGIN";
export const LOGIN_FLAG = "LOGIN_FLAG";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const LOGINERROR = "LOGINERROR";
export const LOGOUT = "LOGOUT";
export const DESTINATIONDATA = "DESTINATIONDATA";
export const AREADATA = "AREADATA";
export const HOTELDATA = "HOTELDATA";
export const HOTEL_CITY_DATA = "HOTEL_CITY_DATA";
export const NATIONALITY = "NATIONALITY";

// night mode types
export const TOGGLE_NIGHT_MODE = "TOGGLE_NIGHT_MODE";

// dashboard hotel pages requests
export const GET_BOOKING_HUB_LIST = "GET_BOOKING_HUB_LIST";
export const GET_HOTELS_LIST = "GET_HOTELS_LIST";
export const GET_CITY_HOTELS = "GET_CITY_HOTELS";
export const DELETE_HOTEL = "DELETE_HOTEL";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CITIES = "GET_CITIES";
export const GET_FEATURES = "GET_FEATURES";
export const GET_AREAS = "GET_AREAS";
export const GET_HOTEL_DETAILS = "GET_HOTEL_DETAILS";
export const GET_HOTEL_NAMES = "GET_HOTEL_NAMES";
export const GET_ALL_ROOMS = "GET_ALL_ROOMS";
export const GET_HOTEL_ROOMS = "GET_HOTEL_ROOMS";
export const GET_ROOM_DETAILS = "GET_ROOM_DETAILS";
export const CLEAR_HOTEL_ROOMS = "CLEAR_HOTEL_ROOMS";

// dashboard contract pages types
export const GET_MARKET_TYPES = "GET_MARKET_TYPES";
export const GET_OFFER_TYPES = "GET_OFFER_TYPES";
export const GET_MEALS_INCLUDED = "GET_MEALS_INCLUDED";
export const GET_CURRENCY_TYPES = "GET_CURRENCY_TYPES";
export const ADD_NEW_ROOM_VALIDITY = "ADD_NEW_ROOM_VALIDITY";
export const DELETE_ROOM_VALIDITY = "DELETE_ROOM_VALIDITY";
export const ADD_ROOM_VALUE = "ADD_ROOM_VALUE";
export const DELETE_ROOM_DATE_RANGE = "DELETE_ROOM_DATE_RANGE";
export const ADD_NEW_DATE_RANGE = "ADD_NEW_DATE_RANGE";
export const DELETE_DATE_RANGE = "DELETE_DATE_RANGE";
export const ADD_DATE_VALUE = "ADD_DATE_VALUE";
export const ADD_NEW_PRICE_TYPE_OBJ = "ADD_NEW_PRICE_TYPE_OBJ";
export const ADD_NEW_CHILD_TYPE_OBJ = "ADD_NEW_CHILD_TYPE_OBJ";
export const ADD_NEW_ROOM_PACKAGE_OBJ = "ADD_NEW_ROOM_PACKAGE_OBJ";
export const DELETE_PRICE_TYPE_OBJ = "DELETE_PRICE_TYPE_OBJ";
export const DELETE_NEW_CHILD_TYPE_OBJ = "DELETE_NEW_CHILD_TYPE_OBJ";
export const DELETE_ROOM_PACKAGE_OBJ = "DELETE_ROOM_PACKAGE_OBJ";
export const ADD_TYPE_VALUE = "ADD_TYPE_VALUE";
export const ADD_PRICE_VALUE = "ADD_PRICE_VALUE";
export const ADD_CHILD_ORDER_VALUE = "ADD_CHILD_ORDER_VALUE";
export const ADD_CHILD_MIN_AGE_VALUE = "ADD_CHILD_MIN_AGE_VALUE";
export const ADD_CHILD_MAX_AGE_VALUE = "ADD_CHILD_MAX_AGE_VALUE";
export const ADD_CHILD_PRICE_VALUE = "ADD_CHILD_PRICE_VALUE";
export const ADD_ROOM_PACKAGE_ID = "ADD_ROOM_PACKAGE_ID";
export const ADD_ROOM_PACKAGE_PRICE = "ADD_ROOM_PACKAGE_PRICE";
export const CLEAR_OLD_VALUES = "CLEAR_OLD_VALUES";
export const USE_EXISTING_VALUES = "USE_EXISTING_VALUES";
export const REFRESH_CONTRACT_VALIDITY = "REFRESH_CONTRACT_VALIDITY";
export const USE_DRAFT_VALUES = "USE_DRAFT_VALUES";

export const GET_ALL_CONTRACTS = "GET_ALL_CONTRACTS";
export const GET_SINGLE_CONTRACT = "GET_SINGLE_CONTRACT";
export const GET_INVENTORY_LIST = "GET_INVENTORY_LIST";
export const GET_CONTRACT_STATUSES = "GET_CONTRACT_STATUSES";
export const GET_UNDER_REVIEW_CONTRACTS = "GET_UNDER_REVIEW_CONTRACTS";
export const GET_ARCHIVED_CONTRACTS = "GET_ARCHIVED_CONTRACTS";
export const GET_HOTEL_CONTRACTS = "GET_HOTEL_CONTRACTS";
export const CLEAR_SINGLE_CONTRACT = "CLEAR_SINGLE_CONTRACT";

// Selected Search Page
export const SELECTEDDATAFROMSEARCHFORM = "SELECTEDDATAFROMSEARCHFORM";
export const ROOMDETIALS = "ROOMDETIALS";
export const FLAG = "FLAG";
export const HOTELSTATUS = "HOTELSTATUS";
export const DEFAULT_AREA = "DEFAULT_AREA";
export const MARKUP_CHECK = "MARKUP_CHECK";
export const DEFAULT_HOTEL = "DEFAULT_HOTEL";

//Filter Type
export const BEDONLY = "BEDONLY";
export const BREAKFAST = "BREAKFAST";
export const HALFBOARD = "HALFBOARD";
export const FULLBOARD = "FULLBOARD";
export const ALLINCLUSIVE = "ALLINCLUSIVE";
export const SET_URI = "SET_URI";

//Reservation Data
export const RESEVE_DATA_FROM_SEARCH = "RESEVE_DATA_FROM_SEARCH";
export const CLIENTREQUEST = "CLIENTREQUEST";
export const DISPLAYRESERVATION = "DISPLAYRESERVATION";
export const RESRVATION_NOTIF_FLAG = "RESRVATION_NOTIF_FLAG";
export const BOOKROOM = "BOOKROOM";
export const SELECTEDHOTEL = "SELECTEDHOTEL";
export const SELECTEDHOTELFILTER = "SELECTEDHOTELFILTER";
export const STOREHOTELS = "STOREHOTELS";
export const MOREROOMS = "MOREROOMS";
export const SELECT_ROOM_FLAG = "SELECT_ROOM_FLAG";
export const SINGLEROOMS = "SINGLEROOMS";

//Card of Booking Order
export const CARDSTATUS = "CARDSTATUS";
export const DATAOFBOOKINGORDER = "DATAOFBOOKINGORDER";
export const DELETEDATAOFBOOKINGORDER = "DELETEDATAOFBOOKINGORDER";
export const INCREASEQTY = "INCREASEQTY";
export const DECREASEQTY = "DECREASEQTY";
export const TOTALPRICE = "TOTALPRICE";
export const TOTOALAMOUNT = "TOTALAMOUNT";
export const CLEARBOOKINGORDER = "CLEARBOOKINGORDER";

//Multi Reservation
export const MULTIRESERVATIONDATA = "MULTIRESERVATIONDATA";
export const DISPLAYMULTIRESERVATION = "DISPLAYMULTIRESERVATION";
export const BTN_SAVE_FLAG = "BTN_SAVE_FLAG";

//ERROR
export const ERROR_SEARCH = "ERROR_SEARCH";

//////BookingList Room
export const GETBOOKEDROOMS = "GETBOOKEDROOMS";
export const HOTELNAME = "HOTELNAME";
export const SORTBOOKINGLIST = "SORTBOOKINGLIST";
export const BOOKEDROOMID = "BOOKEDROOMID";
export const SELECTED_BOOKED_ORDER_FLAG = "SELECTED_BOOKED_ORDER_FLAG";
export const UPDATEBOOKEDROOM = "UPDATEBOOKEDROOM";
export const SINGELBOOKEDROOM = "SINGELBOOKEDROOM";
export const UPDATEFLAG = "UPDATEFLAG";
export const OPERATIONDATA = "OPERATIONDATA";
export const OPERATION_FLAG = "OPERATION_FLAG";
export const GET_OPERATION_FLAG = "GET_OPERATION_FLAG";
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const GET_PAYMENT_LIST_FLAG = "GET_PAYMENT_LIST_FLAG";
export const OERATION_DATA_ID = "OERATION_DATA_ID";
export const DELETE_ORDER = "DELETE_ORDER";
export const CONVERT_ADD_UPDATA = "CONVERT_ADD_UPDATA";
export const BOOKINGTYPES = "BOOKINGTYPES";
export const BOOKINGSTATUS = "BOOKINGSTATUS";
export const ALL_BOOKING_STATUS = "ALL_BOOKING_STATUS";
export const OPERATION_UPDATA_FLAG = "OPERATION_UPDATA_FLAG";

//////Payment
export const BANKLIST = "BANKLIST";
export const CURRENCY = "CURRENCY";
export const BANK_TRANSFER_FLAG = "BANK_TRANSFER_FLAG";
export const ADD_CASH_FLAG = "ADD_CASH_FLAG";
export const JUMBO_FLAG = "JUMBO_FLAG";

///////transactions
export const GET_JUMBO = "GET_JUMBO";

////collection Request
export const COLLECTION_FLAG = "COLLECTION_FLAG";
export const UPDATE_COLLECTION_FLAG = "UPDATE_COLLECTION_FLAG";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const GET_ALL_COLLECTION = "GET_ALL_COLLECTION";
export const GET_ALL_COLLECTION_FLAG = "GET_ALL_COLLECTION_FLAG";

/////refund
export const REFUND_FLAG = "REFUND_FLAG";
export const ALL_REFUND = "ALL_REFUND";
export const GET_REFUND_ID = "GET_REFUND_ID";
export const DELETE_REFUND_FLAG = "DELETE_REFUND_FLAG";
export const UPDATE_REFUND_FLAG = "UPDATE_REFUND_FLAG";

//// Transactions
export const ALL_TRANSCATIONS = "ALL_TRANSCATIONS";
export const ALL_TRANSCATIONS_FLAG = "ALL_TRANSCATIONS_FLAG";
export const SELECTED_TRANSCATIONS = "SELECTED_TRANSCATIONS";
export const SELECTED_TRANSCATIONS_FLAG = "SELECTED_TRANSCATIONS_FLAG";

//////GET CASH
export const GET_SELECTED_CASH = "GET_SELECTED_CASH";
export const GET_SELECTED_CASH_FLAG = "GET_SELECTED_CASH_FLAG";
export const UPDATE_CASH_FLAG = "UPDATE_CASH_FLAG";
export const DELETE_CASH_FLAG = "DELETE_CASH_FLAG";
export const BANK_DATA = "BANK_DATA";
export const BOX_NAME = "BOX_NAME";
export const BOX_NAME_FLAG = "BOX_NAME_FLAG";

/////// GET PAYMENT TRANSACTION
export const GET_SELECTED_PAYMENT_TRANSACTION = "GET_SELECTED_PAYMENT_TRANSACTION";
export const GET_SELECTED_PAYMENT_TRANSACTION_FLAG = "GET_SELECTED_PAYMENT_TRANSACTION_FLAG";

/////// UPDATE PAYMENT TRANSACTION
export const UPDATE_PAYMENT_TRANSACTION = "UPDATE_PAYMENT_TRANSACTION";
export const UPDATE_PAYMENT_TRANSACTION_FLAG = "UPDATE_PAYMENT_TRANSACTION_FLAG";
export const DELETE_PAYMENT_TRANSACTION_FLAG = "DELETE_PAYMENT_TRANSACTION_FLAG";

///// BANK TRANSFER
export const GET_BANK_TRANSFER = "GET_BANK_TRANSFER";
export const GET_BANK_TRANSFER_FLAG = "GET_BANK_TRANSFER_FLAG";
export const UPDATE_BANK_TRANSFER_FLAG = "UPDATE_BANK_TRANSFER_FLAG";
export const DELETE_BANK_TRANSFER_FLAG = "DELETE_BANK_TRANSFER_FLAG";
export const LIST_BANK_TRANSFER = "LIST_BANK_TRANSFER";
export const LIST_BANK_TRANSFER_FLAG = "LIST_BANK_TRANSFER_FLAG";

export const CONTRACT_FLAG = "CONTRACT_FLAG";

/////Banks
export const ADD_BANK = "ADD_BANK";
export const ADD_BANK_FLAG = "ADD_BANK_FLAG";

//////BOOKING MAIL
export const GET_EMAIL_DATA = "GET_EMAIL_DATA";
export const GET_EMAIL_DATA_FLAG = "GET_EMAIL_DATA_FLAG";
export const POST_EMAIL_DATA = "POST_EMAIL_DATA";
export const POST_EMAIL_DATA_FLAG = "POST_EMAIL_DATA_FLAG";

// Booking
export const GET_BOOKING_REQUESTS_LIST = "GET_BOOKING_REQUESTS_LIST";
export const GET_ALL_BOOKING_REQUESTS = "GET_ALL_BOOKING_REQUESTS";
export const GET_BOOKING_REQUESTS_TYPES_CHOICE = "GET_BOOKING_REQUESTS_TYPES_CHOICE";
export const GET_BOOKING_REQUEST_DETAILS = "GET_BOOKING_REQUEST_DETAILS";
export const GET_ALL_CHATS = "GET_ALL_CHATS";
export const GET_REQUESTS_STATS = "GET_REQUESTS_STATS";

// Markup types
export const GET_ALL_MARKUPS = "GET_ALL_MARKUPS";
export const GET_MARKUP_PRICE_TYPES = "GET_MARKUP_PRICE_TYPES";
export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const GET_SINGLE_MARKUP = "GET_SINGLE_MARKUP";
export const CLEAR_SINGLE_MARKUP = "CLEAR_SINGLE_MARKUP";

//// POS MACHINE
export const POST_MAHCINE = "POST_MAHCINE";
export const POST_MAHCINE_FLAG = "POST_MAHCINE_FLAG";
export const ALL_MACHINE = "ALL_MACHINE";
export const ALL_MACHINE_FLAG = "ALL_MACHINE_FLAG";

//////POS VISA
export const GET_MID_NUMBER = "GET_MID_NUMBER";
export const GET_CURRENCY_MID_NUMBER = "GET_CURRENCY_MID_NUMBER";
export const GET_CURRENCY_MID_NUMBER_FLAG = "GET_CURRENCY_MID_NUMBER_FLAG";
export const POST_POSVISA_FLAG = "POST_POSVISA_FLAG";
export const GET_SELECT_POS_VISA = "GET_SELECT_POS_VISA";
export const GET_POS_VISA_FLAG = "GET_POS_VISA_FLAG";
export const DELETE_POS_VISA_FLAG = "DELETE_POS_VISA_FLAG";
export const UPDATE_POS_VISA_FLAG = "UPDATE_POS_VISA_FLAG";

//////New Batch
export const GET_POS_MACHINE_BATCH = "GET_POS_MACHINE_BATCH";
export const CREATE_NEW_BATCH = "CREATE_NEW_BATCH";
export const CREATE_NEW_BATCH_FLAG = "CREATE_NEW_BATCH_FLAG";
export const GET_ALL_BATCH = "GET_ALL_BATCH";
export const GET_ALL_BATCH_FLAG = "GET_ALL_BATCH_FLAG";
export const DELETE_BATCH_FLAG = "DELETE_BATCH_FLAG";
export const GET_SELECTED_BATCH = "GET_SELECTED_BATCH";
export const GET_SELEC_OPERATION = "GET_SELEC_OPERATION";
export const GET_SELECTED_BATCH_FLAG = "GET_SELECTED_BATCH_FLAG";

// currency rates
export const GET_CURRENCY_RATES = "GET_CURRENCY_RATES";
export const GET_CURRENCY_RATES_HISTORY = "GET_CURRENCY_RATES_HISTORY";
export const GET_CURENCY_RATE_HISTORY_DETAIL = "GET_CURENCY_RATE_HISTORY_DETAIL";

////CONVERTING CURRENCY
export const CONVERTING_CURRENCY_DATA = "CONVERTING_CURRENCY_DATA";
export const CONVERTING_CURRENCY_FLAG = "CONVERTING_CURRENCY_FLAG";
// more on hotels
export const GET_ALL_HOTELS_INFO = "GET_ALL_HOTELS_INFO";
export const GET_SINGLE_HOTEL_INFO = "GET_SINGLE_HOTEL_INFO";
export const ALL_HOTELS_INFO_LOADING = "ALL_HOTELS_INFO_LOADING";
export const GET_SINGLE_HOTEL_INFO_BY_HOTEL_ID = "GET_SINGLE_HOTEL_INFO_BY_HOTEL_ID";
export const SINGLE_HOTEL_INFO_LOADING = "SINGLE_HOTEL_INFO_LOADING";

//hotel contact
export const GET_DEPARTMENT_CHOICES = "GET_DEPARTMENT_CHOICES";
export const GET_SINGLE_HOTEL_CONTACT = "GET_SINGLE_HOTEL_CONTACT";
export const SINGLE_HOTEL_CONTACT_LOADING = "SINGLE_HOTEL_CONTACT_LOADING";

//// Booking List Filter
export const FILTER_BOOKING_TYPE = "FILTER_BOOKING_TYPE";
export const ALL_HOTELS_NAME = "ALL_HOTELS_NAME";

///// CASH BOX
export const ADD_CASH_BOX_FLAG = "ADD_CASH_BOX_FLAG";
export const CASH_BOX_DATA = "CASH_BOX_DATA";
export const CASH_BOX_STATMENT_DATA = "CASH_BOX_STATMENT_DATA";

// hotel payments types
export const GET_HOTEL_BANK_ACCOUNTS = "GET_HOTEL_BANK_ACCOUNTS";
export const GET_HOTELS_WITH_BANK_INFO = "GET_HOTELS_WITH_BANK_INFO";
export const RESET_BANK_ACCOUNTS = "RESET_BANK_ACCOUNTS";
export const GET_PAYMENT_DETAILS_SINGLE_BOOKING = "GET_PAYMENT_DETAILS_SINGLE_BOOKING";

/////Wallet
export const ADD_WALLET_FLAG = "ADD_WALLET_FLAG";
export const ADD_TRANSFER_AMOUNT_FLAG = "ADD_TRANSFER_AMOUNT_FLAG";

export const LIST_FLAG = "LIST_FLAG";

//cancellation policy
export const GET_CANCELLATION_POLICY_LIST = "GET_CANCELLATION_POLICY_LIST";
export const CLEAR_CANCELLATION_POLICY_LIST = "CLEAR_CANCELLATION_POLICY_LIST";
export const GET_CANCELLATION_POLICY_ARCHIVE = "GET_CANCELLATION_POLICY_ARCHIVE";
export const CLEAR_CANCELLATION_POLICY_Archive = "CLEAR_CANCELLATION_POLICY_Archive";

// Contract Children Meals
export const ADD_NEW_CHILD_MEAL = "ADD_NEW_CHILD_MEAL";
export const REMOVE_CHILD_MEAL = "REMOVE_CHILD_MEAL";
export const Add_MEAL_CHILD_ORDER = "Add_MEAL_CHILD_ORDER";
export const ADD_MEAL_CHILD_START_AGE = "ADD_MEAL_CHILD_START_AGE";
export const ADD_MEAL_CHILD_END_AGE = "ADD_MEAL_CHILD_END_AGE";
export const ADD_CHILD_MEAL = "ADD_CHILD_MEAL";
export const CLEAR_CHILDREN_MEALS_OLD_VALUES = "CLEAR_CHILDREN_MEALS_OLD_VALUES";
export const REMOVE_MEAL_NAME = "REMOVE_MEAL_NAME";
export const USE_EXISTING_MEALS_VALUES = "USE_EXISTING_MEALS_VALUES";
