import React, { useState } from "react";

import { FcCancel } from "react-icons/fc";
import Loading from "../../../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import usePermissions from "../../../../../customHooks/usePermissions";
import { RxUpdate } from "react-icons/rx";
import { MdDeleteForever, MdOutlineSettings } from "react-icons/md";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { FaHotel } from "react-icons/fa6";
import { IoIosLink } from "react-icons/io";
import { LiaCalendar } from "react-icons/lia";
import NoPermission from "../../../NoPermission/NoPermission";
import DeletePopUp from "../../../../molecules/DeletePopUp/DeletePopUp";
import { BiSolidCity, BiTransferAlt } from "react-icons/bi";
import { SiLetsencrypt } from "react-icons/si";
import { FaMapMarkedAlt } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import Errorfetch from "../../../Errorfetch/Errorfetch";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import mutateDataQuery from "../../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import ListFilters from "../../../../../utils/ListFilters";

const CustomerLinkList = () => {
  const [uniqueCode, setUniqueCode] = useState();
  const [deleteFlag, setDeleteFlag] = useState(false);

  const {
    data: dataLink,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["linkapi"],
    queryFn: () => fetchDataQuery(`/link/api/`),
  });

  const { mutate, isError, isPending } = useMutation({
    mutationFn: () => mutateDataQuery(`/link/api/code/${uniqueCode}/`, "delete"),
    onError: (error) => {
      // An error happened!
      toast.error(`${error || "Your Group was not Edited"}  `);
    },
    onSuccess: () => {
      // Boom baby!
      refetch();
      toast.success("This link was deleted successfully");
    },
  });

  const rows = dataLink?.map((item) => {
    return {
      id: item.id,
      created_by: item?.created_by?.username,
      cities: item?.city[0]?.name,
      Hotels: item?.hotels[0]?.name,
      Nationality: item?.allowed_contoury[0]?.name,

      unique_code: item?.unique_code,
      link_status: item?.link_status,
      expired_date: item?.expired_date,
      updated_at: item?.updated_at,
    };
  });

  const columns = [
    {
      key: "id",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <BiTransferAlt className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),

      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="flex items-center gap-x-1  justify-start w-full h-full pl-3">
            {params?.id}
          </Link>
        );
      },
    },
    {
      key: "created_by",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <CgProfile className="w-5 h-5  text-yellow-500" />
          CREATED BY
        </div>
      ),

      dataIndex: "created_by",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="flex items-center gap-x-1  justify-start w-full h-full pl-3">
            {params?.created_by}
          </Link>
        );
      },
    },
    {
      key: "cities",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <BiSolidCity className="w-5 h-5  text-yellow-500" />
          CITY
        </div>
      ),

      dataIndex: "cities",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="flex items-center gap-x-1  justify-start w-full h-full pl-3">
            {params.cities ? params?.cities : "All Cities"}...
          </Link>
        );
      },
    },
    // hotels
    {
      key: "Hotels",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <FaHotel className="w-5 h-5  text-yellow-500" />
          HOTELS
        </div>
      ),

      dataIndex: "Hotels",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="flex items-center gap-x-1  justify-start w-full h-full pl-3">
            {params?.Hotel ? params?.Hotel : "all hotels"}...
          </Link>
        );
      },
    },
    // nationality
    {
      key: "Nationality",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <FaMapMarkedAlt className="w-5 h-5  text-yellow-500" />
          NATIONALITY
        </div>
      ),

      dataIndex: "Nationality",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="flex items-center gap-x-1  justify-start w-full h-full pl-3">
            {params?.Nationality}...
          </Link>
        );
      },
    },
    {
      key: "unique_code",
      title: (
        <div className=" flex items-center gap-x-1 text-[10px]">
          <SiLetsencrypt className="w-5 h-5  text-yellow-500" />
          UNIQUE CODE
        </div>
      ),

      dataIndex: "unique_code",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`./${params.id}/preview`}
            className="flex items-center gap-x-1  justify-start w-full h-full pl-3 capitalize"
          >
            {params?.unique_code}
          </Link>
        );
      },
    },
    {
      key: "link_status",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <IoIosLink className="w-5 h-5  text-yellow-500" />
          LINK STATUS
        </div>
      ),

      dataIndex: "link_status",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`./${params.id}/preview`}
            className="flex items-center gap-x-1  justify-start w-full h-full pl-3 capitalize"
          >
            {params?.link_status}
          </Link>
        );
      },
    },
    {
      key: "expired_date",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <FcCancel className="w-5 h-5  text-yellow-500" />
          EXPIRED DATE / TIME
        </div>
      ),

      dataIndex: "expired_date",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./${params.id}/preview`} className="flex items-center gap-x-1  justify-start w-full pl-3">
            {params?.expired_date?.slice(0, 10)} / {params?.expired_date?.slice(11, 19)}
          </Link>
        );
      },
    },
    {
      key: "updated_at",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          UPDATED DATE
        </div>
      ),

      dataIndex: "updated_at",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`./${params.id}/preview`}
            className="flex items-center gap-x-1  justify-start w-full h-full pl-3 capitalize"
          >
            {params?.updated_at?.slice(0, 10)} / {params?.updated_at?.slice(11, 19)}
          </Link>
        );
      },
    },

    {
      key: "operations",
      title: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",
      editable: false,

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            {permissions?.map((item) => {
              if (item.name === "link.view_link_customer" && item.value === true) {
                return (
                  <Link
                    to={`./${params.id}/preview`}
                    className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
                  >
                    <AiOutlineEye className="w-5 h-5 text-gray-700 " />
                  </Link>
                );
              } else if (item.name === "link.change_link_customer" && item.value === true) {
                return (
                  <Link
                    to={`./${params.id}/update`}
                    className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
                  >
                    <RxUpdate className="w-5 h-5 text-white" />
                  </Link>
                );
              } else if (item.name === "link.delete_link_customer" && item.value === true) {
                return (
                  <button
                    onClick={() => {
                      setDeleteFlag(true);
                      setUniqueCode(params.unique_code);
                    }}
                    className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all"
                  >
                    <MdDeleteForever className="w-5 h-5 text-white" />
                  </button>
                );
              }
            })}
          </div>
        );
      },
    },
  ];

  //Permission
  const [permissions, permissionsFlag] = usePermissions([
    "link.view_link_customer",
    "link.change_link_customer",
    "link.delete_link_customer",
  ]);

  const handleDelete = async () => {
    setDeleteFlag(false);
    mutate();
  };

  if (isLoading || isPending || permissionsFlag === false) {
    return (
      <div className="w-full flex items-center justify-center h-screen">
        <TopBarProgress /> <Loading />
      </div>
    );
  }

  if (error || isError) {
    return <Errorfetch Error={error} />;
  }

  return (
    <div className="w-full  formPage-wrap ">
      {dataLink && permissionsFlag && (
        <>
          {permissions.some((item) => item.value) ? (
            <>
              <div className="w-full flex  gap-x-5 gap-y-2 items-center justify-start pt-5 px-5 ">
                <Link
                  className="max-w-[max-content] shadow-md rounded-md px-4 py-2 bg-[#001a35] text-white hover:scale-[1.01] hover:shadow-lg "
                  to="/dashboard/create-link-statistics "
                >
                  Statistics
                </Link>
              </div>
              <div className="w-full overflow-auto">
                <ListFilters columns={columns} rows={rows} title="Customer Link List" />
              </div>
            </>
          ) : (
            <div className="w-full h-screen flex items-center justify-center">
              <NoPermission />
            </div>
          )}
        </>
      )}
      {deleteFlag && (
        <DeletePopUp
          name={"This Link"}
          Func={() => handleDelete()}
          hideFunc={() => {
            setDeleteFlag(false);
          }}
        />
      )}
    </div>
  );
};

export default CustomerLinkList;
