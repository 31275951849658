import React from "react";
import { CiEdit } from "react-icons/ci";
import { FaListUl, FaUserCheck } from "react-icons/fa";
import { PiHandshakeLight } from "react-icons/pi";
import { RiKeyboardLine, RiUserSearchFill } from "react-icons/ri";
import { VscAdd } from "react-icons/vsc";
import { Link } from "react-router-dom";
import usePermissions from "../../../customHooks/usePermissions";
import NoPermission from "../NoPermission/NoPermission";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";

const SearchPermission = () => {
  //Permission
  const [permissions, permissionsFlag] = usePermissions([
    "permissions_management.add_searchpermission",
    "permissions_management.change_searchpermission",
    "permissions_management.view_searchpermission",
    "permissions_management.delete_searchpermission",
  ]);

  return (
    <>
      {permissionsFlag ? (
        permissions.some((item) => item.value) ? (
          <div className="formPage-wrap">
            <div className="w-full flex justify-center ">
              <h2 className="header-h2">
                <RiUserSearchFill className="w-5 h-5" />
                Search Permissions
              </h2>
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1  form-wrap">
              {permissions?.map((item, index) => {
                if (item.name === "permissions_management.add_searchpermission" && item.value === true) {
                  return (
                    <div
                      key={index}
                      class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid"
                    >
                      <Link
                        to={"create"}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center">
                          <div className="relative flex flex-col">
                            <FaUserCheck className="w-12 h-12" />
                          </div>
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Add Permissions</p>
                    </div>
                  );
                }
              })}

              {permissions.some((item) => item.value) && (
                <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                  <Link
                    to={"./permissions-list"}
                    // onClick={() => navigate("./edit-group-permissions")}
                    className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                  >
                    <div className="w-full flex justify-center items-center ">
                      <FaListUl className="w-12 h-12" />
                    </div>
                  </Link>

                  <p className="text-lg font-semibold"> Permissions List </p>
                </div>
              )}
            </div>{" "}
          </div>
        ) : (
          <div className="w-full h-screen flex items-center justify-center">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </>
  );
};

export default SearchPermission;
