import { CONVERTING_CURRENCY_DATA, CONVERTING_CURRENCY_FLAG } from "../types/types";

const initialState = {
    convertingCurrencyData : null,
    convertingCurrencyFlag:false,
 }

export const convertingCurrencyReducer = (state = initialState , action) =>{
    switch(action.type)
    {
        case CONVERTING_CURRENCY_DATA : 
             return{...state , convertingCurrencyData : action.payload}
        case CONVERTING_CURRENCY_FLAG : 
             return{...state , convertingCurrencyFlag : action.payload}
        default:
             return state;
    }
}