import React from "react";
import AddBankRelated from "./AddBankRelated";
import BankListRelated from "./BankListRelated";

const BankRelated = () => {
  return (
    <div className="formPage-wrap">
      <div className="form-wrap  grid sm:grid-cols-2 grid-cols-1  ">
        <BankListRelated />
        <AddBankRelated />
      </div>
    </div>
  );
};

export default BankRelated;
