import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { MdAdd, MdOutlineMailOutline } from "react-icons/md";
import cookie from "react-cookies";
import { NotificationManager } from "react-notifications";
import TopBarProgress from "react-topbar-progress-indicator";
import Errorfetch from "../Errorfetch/Errorfetch";
import {
  createWelcomeMessage,
  deleteWelcomeMessage,
  getAllWelcomeMessages,
  updateWelcomeMessage,
} from "../../../redux/actions/emailAction";
import { RxUpdate } from "react-icons/rx";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { RiDeleteBin6Fill } from "react-icons/ri";

function EmailWelcomeMessageForm({ smtpIdOfSelectedCountry }) {
  //

  const token = cookie.load("access_token");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModifying, setIsModifying] = useState(false);
  const [welcomeMessages, setWelcomeMessages] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const welcomeMessageOfSelectedCountry = welcomeMessages.find(
    (message) => message.smtp_settings === smtpIdOfSelectedCountry,
  );

  useEffect(() => {
    async function fetchAllWelcomeMessages() {
      try {
        setIsLoading(true);
        const data = await getAllWelcomeMessages(token);

        setWelcomeMessages(data);
      } catch (e) {
        // NotificationManager.error("Error getting welcome messages", "Error", 1000);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }

    fetchAllWelcomeMessages();
  }, [refetch, token]);

  async function handleCreateWelcomeMessage(e) {
    e.preventDefault();
    try {
      setIsModifying(true);
      const data = {
        message: e.target[0].value,
        smtp_settings: smtpIdOfSelectedCountry,
      };

      await createWelcomeMessage(token, data);
      NotificationManager.success("Welcome message created successfully", "Success", 3000);

      setRefetch(!refetch);
    } catch (error) {
      NotificationManager.error(error?.response?.data[0]?.message || error?.message || "Unknown Error", "Error", 1000);
      // setError(e);
    } finally {
      setIsModifying(false);
    }
  }

  async function handleUpdateWelcomeMessage(e) {
    e.preventDefault();
    try {
      setIsModifying(true);
      const data = {
        message: e.target[0].value,
        smtp_settings: smtpIdOfSelectedCountry,
      };

      await updateWelcomeMessage(token, data, welcomeMessageOfSelectedCountry.id);
      NotificationManager.success("Welcome message updating successfully", "Success", 3000);

      setRefetch(!refetch);
    } catch (error) {
      NotificationManager.error(error?.response?.data[0]?.message || error?.message || "Unknown Error", "Error", 1000);
      // setError(e);
    } finally {
      setIsModifying(false);
    }
  }

  async function handleDeleteWelcomeMessage() {
    try {
      setIsModifying(true);
      await deleteWelcomeMessage(token, welcomeMessageOfSelectedCountry.id);
      NotificationManager.success("Welcome message deleted successfully", "Success", 3000);

      setRefetch(!refetch);
    } catch (error) {
      NotificationManager.error(error?.response?.data[0]?.message || error?.message || "Unknown Error", "Error", 1000);
      // setError(e);
    } finally {
      setIsModifying(false);
    }
  }

  if (isLoading) return <TopBarProgress />;

  if (error) return <Errorfetch error={error} />;

  return (
    !isLoading && (
      <div
        className=" flex flex-col gap-y-2 mb-6 mx-auto border-gray-300 border rounded-lg px-5 pb-5 py-3"
        key={smtpIdOfSelectedCountry}
      >
        <div className="w-full flex justify-center    ">
          <h2 className="header-h2">
            <MdOutlineMailOutline className="w-6 h-6 mt-1" />
            Welcome Message
          </h2>
        </div>
        <form
          onSubmit={welcomeMessageOfSelectedCountry ? handleUpdateWelcomeMessage : handleCreateWelcomeMessage}
          className="flex flex-col justify-center items-center gap-y-2"
        >
          <TextArea
            required
            disabled={!smtpIdOfSelectedCountry}
            defaultValue={welcomeMessageOfSelectedCountry?.message || ""}
            rows={6}
            placeholder={smtpIdOfSelectedCountry ? "Enter welcome message" : "Add Smtp settings first"}
          />

          <div className="flex gap-x-2">
            <button
              disabled={isModifying || !smtpIdOfSelectedCountry}
              className=" disabled:cursor-not-allowed flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              {welcomeMessageOfSelectedCountry ? (
                <>
                  Update
                  {isModifying ? (
                    <>
                      <TopBarProgress />
                      <ButtonLoading />
                    </>
                  ) : (
                    <RxUpdate className="w-5 h-5" />
                  )}
                </>
              ) : (
                <>
                  Add
                  {isModifying ? (
                    <>
                      <TopBarProgress />
                      <ButtonLoading />
                    </>
                  ) : (
                    <MdAdd className="w-5 h-5" />
                  )}
                </>
              )}
            </button>

            {welcomeMessageOfSelectedCountry && (
              <button
                disabled={isModifying}
                type="button"
                className=" disabled:cursor-not-allowed flex items-center gap-x-1 px-3 py-1 rounded-lg bg-red-700 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                onClick={handleDeleteWelcomeMessage}
              >
                Delete
                {isModifying ? (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                ) : (
                  <RiDeleteBin6Fill />
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    )
  );
}

export default EmailWelcomeMessageForm;
