import React from 'react'
import { ADD_TRANSFER_AMOUNT_FLAG, ADD_WALLET_FLAG } from '../types/types';

const initialState = {
    addWalletFlag:false,
    addTransferAmountFlag:false,
}
export const walletReducer = (state = initialState , action) =>{
    switch(action.type)
    {
     case ADD_WALLET_FLAG : 
        return{...state , addWalletFlag : action.payload}
     case ADD_TRANSFER_AMOUNT_FLAG : 
        return{...state , addTransferAmountFlag : action.payload}
     default:
        return state;
    }
}