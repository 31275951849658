import React from "react";
import { useParams } from "react-router";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import ClientBookingHistoryForm from "./ClientBookingHistoryForm";
import usePermissions from "../../../../customHooks/usePermissions";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import NoPermission from "../../NoPermission/NoPermission";

const initialValues = {
  hotel: null,
  nightsNum: 0,
  notes: "",
  date: "",
  rooms: [],
};

const AddClientBookingHistoryValidationSchema = Yup.object().shape({
  hotel: Yup.number().required("Hotel is required"),
  nightsNum: Yup.number()
    .min(1, "Number of nights must be greater than or equal to 1")
    .required("Number of nights is required"),
  date: Yup.string().required("Date is required"),
  rooms: Yup.array()
    .of(Yup.number())
    .min(1, "Please select at least one room")
    .required("Please select at least one room"),
});

function AddClientBookingHistoryForm() {
  const { clientID } = useParams();

  const [permissions, permissionFlag, permissionsError] = usePermissions(["auth_users.add_customerbookinghistory"]);

  const { isPending, mutate: createBookingHistory } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/auth/api/v1/customer-booking-history/`, "post", data, {
        "Content-Type": "application/json",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Client Booking History Created Successfully");
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to create Client Booking History");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to create Client Booking History");
    },
  });

  const handleSubmit = (values, helpers) => {
    const data = JSON.stringify({
      customer: clientID,
      hotels: values.hotel,
      number_nights: values.nightsNum,
      notes: values.notes,
      date: values.date,
      rooms: values.rooms,
    });

    createBookingHistory(data, {
      onSuccess: () => {
        helpers.resetForm();
      },
    });
  };

  const isAddCustomerBookingHistoryAllowed =
    permissions?.find((permission) => permission.name === "auth_users.add_customerbookinghistory")?.value === true;

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  return (
    <>
      {isAddCustomerBookingHistoryAllowed ? (
        <ClientBookingHistoryForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={AddClientBookingHistoryValidationSchema}
          isPending={isPending}
          title={"Add Client Booking History"}
          onSubmitButtonTitle={"Add Booking"}
        />
      ) : (
        <NoPermission />
      )}
    </>
  );
}

export default AddClientBookingHistoryForm;
