import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const rangePresets = [
  {
    label: <span aria-label="Current Time to End of Day">Now ~ EOD</span>,
    value: () => [dayjs().startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Yesterday",
    value: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().subtract(7, "day").startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().subtract(14, "day").startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().subtract(30, "day").startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().subtract(90, "day").startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "This Month",
    value: [dayjs().startOf("month"), dayjs().endOf("month")],
  },
  {
    label: "Last Month",
    value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
  },
  {
    label: "Last 6 Months",
    value: [dayjs().subtract(6, "month").startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "This Year",
    value: [dayjs().startOf("year"), dayjs().endOf("year")],
  },
  {
    label: "All Time",
    value: [dayjs("1970-01-01").startOf("day"), dayjs().endOf("day")],
  },
];

const DateRangeSelector = ({ onRangeChange, format = "DD/MM/YYYY", defaultValue, ...props }) => {
  return (
    <RangePicker
      presets={rangePresets}
      format={format}
      onChange={onRangeChange}
      defaultValue={[
        defaultValue?.startDate ? dayjs(defaultValue.startDate) : null,
        defaultValue?.endDate ? dayjs(defaultValue.endDate) : null,
      ]}
      {...props}
    />
  );
};

export default DateRangeSelector;
