import { ALL_TRANSCATIONS  , ALL_TRANSCATIONS_FLAG , SELECTED_TRANSCATIONS , SELECTED_TRANSCATIONS_FLAG} from "../types/types";

const initialState = {
allTransactionsData : {
    results:[],
    count:0
},
allTransactionsFlag : false,
selectedTransaction:null,
selectedTransactionFlag:false,
}

export const allTransactionsReducer = (state = initialState , action) =>{
    switch(action.type)
    {
        case ALL_TRANSCATIONS:
            return { ...state, allTransactionsData: action.payload };
        case ALL_TRANSCATIONS_FLAG:
            return { ...state, allTransactionsFlag: action.payload };
        case SELECTED_TRANSCATIONS:
            return { ...state, selectedTransaction: action.payload };
        case SELECTED_TRANSCATIONS_FLAG:
            return { ...state, selectedTransactionFlag: action.payload };
        default:
            return state;
    }
}