import {
  GET_BOOKING_REQUESTS_LIST,
  GET_ALL_BOOKING_REQUESTS,
  GET_BOOKING_REQUESTS_TYPES_CHOICE,
  GET_BOOKING_REQUEST_DETAILS,
  GET_ALL_CHATS,
  GET_REQUESTS_STATS,
} from "../types/types";

const defaultState = [];

export function bookingRequestsReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_BOOKING_REQUESTS_LIST:
      return action.payload;
    default:
      return state;
  }
}

const allBookingRequestsInitialState = {
  count: 0,
  results: [],
};
export function allBookingRequestsReducer(state = allBookingRequestsInitialState, action) {
  switch (action.type) {
    case GET_ALL_BOOKING_REQUESTS:
      return action.payload;
    default:
      return state;
  }
}

export function bookingRequestTypeChoiceReducer(state = [], action) {
  switch (action.type) {
    case GET_BOOKING_REQUESTS_TYPES_CHOICE:
      return action.payload;
    default:
      return state;
  }
}

export function bookingRequestDetailsReducer(state = {}, action) {
  switch (action.type) {
    case GET_BOOKING_REQUEST_DETAILS:
      return action.payload;
    default:
      return state;
  }
}

export function bookingRequestsChatsReducer(state = [], action) {
  switch (action.type) {
    case GET_ALL_CHATS:
      return action.payload;
    default:
      return state;
  }
}

export function bookingsStatsReducer(state = {}, action) {
  switch (action.type) {
    case GET_REQUESTS_STATS:
      return action.payload;
    default:
      return state;
  }
}
