import React, { useEffect, useState } from "react";
import "./../AddHotelForm/DashboardForm.scss";
import { useDispatch, useSelector } from "react-redux";
import DynamicFormInputText from "../../molecules/DynamicFormInputText/DynamicFormInputText";
import TimeRange from "../../molecules/TimeRange/TimeRange";
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import { getAllHotelsInfo, sendMainHotelInfo } from "../../../redux/actions/HotelInfo";
import { useNavigate } from "react-router";
import TopBarProgress from "react-topbar-progress-indicator";
import cookie from "react-cookies";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import NoPermission from "../NoPermission/NoPermission";
import { LiaFileContractSolid } from "react-icons/lia";
import { LuCalendarDays } from "react-icons/lu";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { MdOutlinePostAdd } from "react-icons/md";
import lightToast from "light-toast";

export default function AddHotelMainInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const hotelNames = useSelector((state) => state.hotelNames);
  const existingHotels = useSelector((state) => state.allHotelsInfo);
  //
  const [allDaysTimeRange, setAllDaysTimeRange] = useState({ from: null, to: null });
  const [sundayTimeRange, setSundayTimeRange] = useState({ from: null, to: null });
  const [mondayTimeRange, setMondayTimeRange] = useState({ from: null, to: null });
  const [tuesdayTimeRange, setTuesdayTimeRange] = useState({ from: null, to: null });
  const [wednesdayTimeRange, setWednesdayTimeRange] = useState({ from: null, to: null });
  const [thursdayTimeRange, setThursdayTimeRange] = useState({ from: null, to: null });
  const [fridayTimeRange, setFridayTimeRange] = useState({ from: null, to: null });
  const [saturdayTimeRange, setSaturdayTimeRange] = useState({ from: null, to: null });

  const existingHotelsIds = existingHotels.data.map((obj) => obj.hotel.id);
  const filteredHotels = hotelNames.filter((hotel) => {
    return !existingHotelsIds.includes(hotel.id);
  });

  useEffect(() => {
    dispatch(requestHotelNames());
    dispatch(getAllHotelsInfo());
  }, []);

  useEffect(() => {
    if (allDaysTimeRange.from) {
      setSundayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setMondayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setTuesdayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setWednesdayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setThursdayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setFridayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
      setSaturdayTimeRange((prev) => ({ ...prev, from: allDaysTimeRange.from }));
    }
    if (allDaysTimeRange.to) {
      setSundayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setMondayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setTuesdayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setWednesdayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setThursdayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setFridayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
      setSaturdayTimeRange((prev) => ({ ...prev, to: allDaysTimeRange.to }));
    }
  }, [allDaysTimeRange]);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const formData = new FormData(e.target);
    const workingHours = {
      sunday: sundayTimeRange,
      monday: mondayTimeRange,
      tuesday: tuesdayTimeRange,
      wednesday: wednesdayTimeRange,
      thursday: thursdayTimeRange,
      friday: fridayTimeRange,
      saturday: saturdayTimeRange,
    };
    const data = {
      telephone_numbers: {},
      duty_manager_numbers: {},
      reservation_emails: {},
      reception_emails: {},
      same_day_reservations_notes: e.target.notes.value,
      reservations_department_working_hours: workingHours,
      hotel: Number(e.target.hotel.value),
    };

    Array.from(formData.getAll("telephone-numbers")).forEach((elem, index) => {
      data.telephone_numbers[index + 1] = elem;
    });
    Array.from(formData.getAll("hotel-duty-num")).forEach((elem, index) => {
      data.duty_manager_numbers[index + 1] = elem;
    });
    Array.from(formData.getAll("reception-emails")).forEach((elem, index) => {
      data.reception_emails[index + 1] = elem;
    });
    Array.from(formData.getAll("reservation-emails")).forEach((elem, index) => {
      data.reservation_emails[index + 1] = elem;
    });

    dispatch(
      sendMainHotelInfo(data, () => {
        lightToast.success("Hotel Main Info Form was completed successfully");
        navigate("/dashboard/hotel-main-info");
      }),
    );
  }

  /////permission/////////
  const permissionData = {
    permission_codes: ["hotels_management.add_mainhotelinfo"],
  };
  const token = cookie.load("access_token");
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "hotels_management.add_mainhotelinfo" && item.value === true) {
            return (
              <div className="w-full">
                <div className=" flex items-center justify-center ">
                  <p className="header-h2">
                    <LiaFileContractSolid className="w-6 h-6" />
                    Hotel Main Info
                  </p>
                </div>

                <form method="post" className="form-wrap flex flex-col gap-y-2  rounded-md" onSubmit={handleSubmit}>
                  <div className="w-full flex max-sm:flex-col items-start justify-center gap-x-10">
                    <div className="flex-1 max-sm:w-full">
                      <StaticFormSelect
                        name="hotel"
                        text="ChooseHotel"
                        options={filteredHotels}
                        placeholder="Choose Hotel"
                      />
                    </div>
                    <div className="flex-1 max-sm:w-full">
                      <DynamicFormInputText
                        name="telephone-numbers"
                        text="Add Telephone Numbers"
                        inputsNum={1}
                        type={"number"}
                        placeholder="Telephone Number"
                      />
                    </div>
                  </div>
                  <div className="w-full flex max-sm:flex-col items-start justify-center gap-x-10">
                    <div className="flex-1 max-sm:w-full">
                      <DynamicFormInputText
                        name="hotel-duty-num"
                        text="Hotel Duty Manager number"
                        inputsNum={1}
                        type={"number"}
                        placeholder="Hotel Duty Manager number"
                      />
                    </div>
                    <div className="flex-1 max-sm:w-full">
                      <DynamicFormInputText
                        name="reservation-emails"
                        text="Add reservations Emails"
                        inputsNum={1}
                        type={"email"}
                        placeholder=" Reservations Emails"
                      />
                    </div>
                  </div>
                  <div className="w-full flex max-sm:flex-col items-start justify-center gap-x-10">
                    <div className="flex-1 max-sm:w-full">
                      <DynamicFormInputText
                        name="reception-emails"
                        text="Add reception Emails"
                        inputsNum={1}
                        type={"email"}
                        placeholder=" Reception Emails"
                      />
                    </div>

                    <div className="flex-1 max-sm:w-full">
                      <div className="w-full ">
                        <label htmlFor="notes" className="text-slate-800 font-semibold">
                          Same Day Reservation Notes
                        </label>{" "}
                        <br />
                        <textarea
                          name="notes"
                          id="notes"
                          className="w-full border rounded-md p-3 focus:outline-none focus:ring focus:border-blue-300"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <h2 className="text-slate-800 font-semibold">Add Reservation Departments Working hours</h2>
                  <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 border rounded-md p-3">
                    <div>
                      <h2 className="flex items-center gap-x-1 text-slate-800 font-semibold">
                        <LuCalendarDays className="w-4 h-4" /> All Days
                      </h2>
                      <TimeRange timeRange={allDaysTimeRange} setTimeRange={setAllDaysTimeRange} />
                    </div>
                    <div>
                      <h2>Sunday</h2>
                      <TimeRange timeRange={sundayTimeRange} setTimeRange={setSundayTimeRange} />
                    </div>
                    <div>
                      <h2>Monday</h2>
                      <TimeRange timeRange={mondayTimeRange} setTimeRange={setMondayTimeRange} />
                    </div>
                    <div>
                      <h2>Tuesday</h2>
                      <TimeRange timeRange={tuesdayTimeRange} setTimeRange={setTuesdayTimeRange} />
                    </div>

                    <div>
                      <h2>Wednesday</h2>
                      <TimeRange timeRange={wednesdayTimeRange} setTimeRange={setWednesdayTimeRange} />
                    </div>
                    <div>
                      <h2>Thursday</h2>
                      <TimeRange timeRange={thursdayTimeRange} setTimeRange={setThursdayTimeRange} />
                    </div>
                    <div>
                      <h2>Friday</h2>
                      <TimeRange timeRange={fridayTimeRange} setTimeRange={setFridayTimeRange} />
                    </div>
                    <div>
                      <h2>Saturday</h2>
                      <TimeRange timeRange={saturdayTimeRange} setTimeRange={setSaturdayTimeRange} />
                    </div>
                  </div>

                  <div className="w-full flex justify-center items-center  mt-6">
                    <button type="submit" disabled={isDisabled} className="addButton">
                      Add
                      {isDisabled ? (
                        <>
                          {" "}
                          <TopBarProgress /> <ButtonLoading />
                        </>
                      ) : (
                        <MdOutlinePostAdd className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  {/* {isDisabled ? (
                    <>
                      <TopBarProgress />
                      <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                        <HashLoader size={30} color="white" />
                        <div className="text-white font-bold my-2">Submitting...</div>
                      </div>
                    </>
                  ) : null} */}
                </form>
              </div>
            );
          } else {
            return <NoPermission />;
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
