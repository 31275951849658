import React, { useState } from "react";
import { ErrorMessage, FieldArray, Form, Formik, useFormikContext } from "formik";
import {
  FieldErrorText,
  FormDatePicker,
  FormLabel,
  FormSection,
  FormSelectInput,
  FormTextAreaInput,
  FormTextInput,
} from "../../../customComponents/FormComponents";
import { GoPerson } from "react-icons/go";

import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { RiHotelLine, RiStickyNoteLine } from "react-icons/ri";
import DeleteButton from "../../../customComponents/DeleteButton";
import { Button } from "flowbite-react";
import { MdNumbers, MdOutlineBedroomChild, MdOutlinePriceChange } from "react-icons/md";
import dayjs from "dayjs";

function VoucherForm({ initialValues, onSubmit, validationSchema }) {
  const [isAutomatic, setIsAutomatic] = useState(true);
  const [showEditButton, setShowEditButton] = useState(true);

  const {
    isLoading: isLoadingMeals,
    data: meals,
    isError: isFetchingMealsFailed,
  } = useQuery({
    queryKey: ["meals"],
    queryFn: () => {
      return fetchDataQuery("/contracts/api/meals/");
    },
  });

  const {
    isLoading: isLoadingCurrencies,
    data: currencies,
    isError: isFetchingCurrenciesFailed,
  } = useQuery({
    queryKey: ["currencies"],
    queryFn: () => {
      return fetchDataQuery("/contracts/api/currency-types/");
    },
  });

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values, setFieldValue, errors, isSubmitting }) => (
        <Form className="relative">
          <FormSection title={"Create Voucher"}>
            {showEditButton && (
              <Button
                className="absolute top-[33px] right-[32px] md:right-[76px]"
                color={"success"}
                onClick={() => {
                  setIsAutomatic(false);
                  setShowEditButton(false);
                  setFieldValue("voucherForm", "Manual Voucher");
                }}
              >
                Edit
              </Button>
            )}
            <FormSelectInput
              label="Voucher Type"
              name="voucherType"
              options={[
                { label: "New Reservation", value: "New Reservation" },
                { label: "Extension", value: "Extension" },
                { label: "Updated Voucher", value: "Updated Voucher" },
              ]}
              defaultValue={{ label: values.voucherType, value: values.voucherType }}
              styles={{ container: (provided) => ({ ...provided, marginTop: "4px !important" }) }}
            />
            <FormTextInput
              disabled={isAutomatic}
              label="Hotel Confirmation Number"
              name="hotelConfirmationNumber"
              type="text"
              placeholder="Enter Hotel Confirmation Number"
              Icon={MdNumbers}
            />
            <FormDatePicker
              disabled={true}
              id={"date"}
              label={"Date"}
              name={"date"}
              defaultValue={dayjs(values.date)}
              onSelectDate={(date, dateString) => {
                setFieldValue("date", dateString);
              }}
            />
            <FormTextInput
              disabled
              label="Hotel Name"
              name="hotelName"
              type="text"
              placeholder="Enter Hotel Name"
              Icon={RiHotelLine}
            />
            <FieldArray name={"guestNames"}>
              {({ push, remove }) => (
                <div className={"flex flex-col gap-2"}>
                  <FormLabel label="Guest Names" htmlFor="guestNames" />
                  {values.guestNames?.map((guestName, index) => (
                    <div key={index} className="flex justify-between items-center gap-4">
                      <div className="grow">
                        <div className="w-full relative text-gray-600">
                          <FormTextInput
                            placeholder={`Enter Guest Name ${index + 1}`}
                            disabled={isAutomatic}
                            Icon={GoPerson}
                            name={`guestNames.${index}`}
                          />
                        </div>
                      </div>
                      {index !== 0 && <DeleteButton type="button" onDelete={() => remove(index)} />}
                    </div>
                  ))}
                  {!isAutomatic && (
                    <Button className="self-center" type="button" onClick={() => push("")}>
                      Add Guest Name
                    </Button>
                  )}
                </div>
              )}
            </FieldArray>
            <NationalitySelectInput isDisabled={isAutomatic} />
            <FieldArray name={"childrenAges"}>
              {({ push, remove }) => (
                <div className={"flex flex-col gap-2"}>
                  <FormLabel label="Children Ages" htmlFor="childrenAges" />
                  {values.childrenAges?.length > 0 ? (
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                      {values.childrenAges?.map((age, index) => (
                        <div key={index} className="flex justify-between items-center gap-4">
                          <div className="flex flex-col mb-4 w-full">
                            <FormLabel label={`Child ${index + 1} Age`} htmlFor={`childrenAges.${index}`} />
                            <div className="flex justify-between items-center gap-2">
                              <FormSelectInput
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                  }),
                                }}
                                isDisabled={isAutomatic}
                                defaultValue={{ label: age, value: age }}
                                placeholder={`Child ${index + 1} Age`}
                                name={`childrenAges.${index}`}
                                options={Array.from({ length: 12 }, (_, i) => ({ label: i, value: i }))}
                              />
                              {!isAutomatic && (
                                <DeleteButton type="button" onDelete={() => remove(index)} className="w-9" />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="font-semibold  text-gray-600 text-center">No children ages added</p>
                  )}

                  {errors.childrenAges && <FieldErrorText message={errors.childrenAges} />}

                  {!isAutomatic && (
                    <Button
                      className="self-center"
                      type="button"
                      onClick={() => {
                        push(values.childrenAges.at(-1) || 0);
                      }}
                    >
                      Add Child Age
                    </Button>
                  )}
                </div>
              )}
            </FieldArray>
            <FormTextInput
              disabled={isAutomatic}
              label="Number of Adults"
              name="personsNum"
              type="number"
              placeholder="Enter Number of Persons"
              Icon={GoPerson}
            />
            <FormTextInput
              disabled={isAutomatic}
              label="Room Type"
              name="roomType"
              type="text"
              placeholder="Enter Room Type"
              Icon={MdOutlineBedroomChild}
            />
            <FormTextInput
              disabled={isAutomatic}
              label="Total Price"
              name="totalPrice"
              type="Number"
              placeholder="Enter Total Price"
              Icon={MdOutlinePriceChange}
            />
            <FormTextInput
              disabled={isAutomatic}
              label="Net Price"
              name="netPrice"
              type="Number"
              placeholder="Enter Net Price"
              Icon={MdOutlinePriceChange}
            />
            <>
              <FormLabel label="Meal Plan" htmlFor="mealPlan" />
              <Select
                isDisabled={isAutomatic}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    "margin-top": "4px !important",
                  }),
                }}
                defaultValue={{
                  label: values.mealPlan?.toUpperCase(),
                  value: values.mealPlan,
                }}
                onChange={(newValue) => {
                  setFieldValue("mealPlan", newValue.value);
                }}
                name={"mealPlan"}
                id={"mealPlan"}
                isLoading={isLoadingMeals}
                options={meals?.map((meal) => {
                  return {
                    label: meal?.toUpperCase(),
                    value: meal,
                  };
                })}
              />
              <ErrorMessage name={"mealPlan"}>
                {(msg) => {
                  return <FieldErrorText message={msg} />;
                }}
              </ErrorMessage>
              {isFetchingMealsFailed && <FieldErrorText message={"Failed to fetch meals"} />}
            </>
            <>
              <FormLabel label="Currency" htmlFor="currency" />
              <Select
                isDisabled={isAutomatic}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    "margin-top": "4px !important",
                  }),
                }}
                defaultValue={{
                  label: values.currency?.toUpperCase(),
                  value: values.currency,
                }}
                name={"currency"}
                id={"currency"}
                isLoading={isLoadingCurrencies}
                options={currencies?.map((currency) => {
                  return {
                    label: currency?.toUpperCase(),
                    value: currency,
                  };
                })}
              />
              {isFetchingCurrenciesFailed && <FieldErrorText message={"Failed to fetch currencies"} />}
            </>
            <FormDatePicker
              disabled={isAutomatic}
              defaultValue={values.checkInDate}
              label={"Check In Date"}
              name={"checkInDate"}
              id={"checkInDate"}
              onSelectDate={(date, dateString) => {
                setFieldValue("checkInDate", dateString);
              }}
            />
            <FormDatePicker
              disabled={isAutomatic}
              defaultValue={values.checkOutDate}
              label={"Check Out Date"}
              name={"checkOutDate"}
              id={"checkOutDate"}
              onSelectDate={(date, dateString) => {
                setFieldValue("checkOutDate", dateString);
              }}
            />
            <FormTextAreaInput
              rows={5}
              label={"Remarks"}
              name="remarks"
              placeholder="Enter Remarks"
              Icon={RiStickyNoteLine}
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              isProcessing={isSubmitting}
              color="success"
              className={"mx-auto"}
            >
              Create Voucher
            </Button>
          </FormSection>
        </Form>
      )}
    </Formik>
  );
}

const NationalitySelectInput = ({ isDisabled }) => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingCountries,
    data: countries,
    error: errorFetchingCountries,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery("/hotels/api/v1/get-countries/");
    },
    queryKey: ["countries"],
  });

  // Define the desired order of countries to put at the top
  const topCountries = [
    "Saudi Arabia",
    "Kuwait",
    "Qatar",
    "UAE",
    "Bahrain",
    "Iraq",
    "Lebanon",
    "Jordan",
    "Libya",
    "Yemen",
    "Egypt",
  ];

  // Create a mapping of the desired order
  const topCountriesOrder = topCountries.reduce((acc, country, index) => {
    acc[country] = index;
    return acc;
  }, {});

  // Separate the countries into the top list and the rest
  const reorderedCountries = [];
  const remainingCountries = [];

  countries?.forEach((country) => {
    if (topCountries.includes(country.name)) {
      reorderedCountries.push(country);
    } else {
      remainingCountries.push(country);
    }
  });

  // Sort the reorderedCountries based on the defined order
  reorderedCountries.sort((a, b) => topCountriesOrder[a.name] - topCountriesOrder[b.name]);

  // Concatenate the reordered list with the remaining countries
  const finalList = reorderedCountries.concat(remainingCountries);

  return (
    <>
      <FormLabel label={"Nationality"} htmlFor={"nationality"} />
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            "margin-top": "4px !important",
          }),
        }}
        name={"nationality"}
        inputId={"nationality"}
        isDisabled={isFetchingCountries || isDisabled}
        defaultValue={{
          value: values.nationality,
          label: values.nationality,
        }}
        placeholder={"Nationality"}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            nationality: selectedOption.value,
          });
        }}
        options={finalList?.map((country) => {
          return {
            value: country.name,
            label: country.name,
          };
        })}
      />
      <ErrorMessage name={"country"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingCountries && <FieldErrorText message={"Error Fetching Nationalities"} />}
    </>
  );
};

export default VoucherForm;
