import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export function getHotelOffers(hotelId, successCallback) {
  return async function () {
    try {
      const response = await api_token(token).get(`/offers/api/offer?hotel=${hotelId}`);
      if (response.status === 200) {
        successCallback(response.data);
      }
    } catch (error) {}
  };
}

export function getContractOffers(contractId, successCallback) {
  return async function () {
    try {
      const response = await api_token(token).get(`/offers/api/offer?contract=${contractId}`);
      if (response.status === 200) {
        successCallback(response.data);
      }
    } catch (error) {}
  };
}

export function getHotelActiveContracts(hotelId, successCallback, callback) {
  return async function () {
    try {
      const response = await api_token(token).get(`/contracts/api/?hotel=${hotelId}&pagination=false`);
      if (response.status === 200) {
        successCallback(response.data);
        callback(response);
      }
    } catch (error) {}
  };
}

export function createOffer(data, successCallback) {
  return async function () {
    try {
      const response = await api_token(token).post(`/offers/api/offer`, data);
      if (response) {
        successCallback(response);
      }
    } catch (error) {}
  };
}

export function deleteOffer(id, successCallback) {
  return async function () {
    try {
      const response = await api_token(token).delete(`/offers/api/offer/${id}/`);
      if (response.status === 204) {
        successCallback();
      }
    } catch (error) {}
  };
}

export function editOffer(id, data, successCallback) {
  return async function () {
    try {
      const response = await api_token(token).patch(`/offers/api/offer/${id}/`, data);
      if (response) {
        successCallback(response);
      }
    } catch (error) {}
  };
}
