import React from "react";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import NoPermission from "../NoPermission/NoPermission";
import PackagesList from "./PackagesList";
import AddNewPackageModal from "./AddNewPackageModal";
import { Form, Formik } from "formik";
import CitySelectInput from "./CitySelectInput";
import HotelSelectInput from "./HotelSelectInput";
import RoomSelectInput from "./RoomSelectInput";

function RoomPackages() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "rooms_management.add_roompackageoccupancy",
    "rooms_management.view_roompackageoccupancy",
    "rooms_management.delete_roompackageoccupancy",
    "rooms_management.change_roompackageoccupancy",
  ]);

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const isAddingPackageAllowed =
    permissions?.find((permission) => permission.name === "rooms_management.add_roompackageoccupancy")?.value === true;

  const isViewingPackageAllowed =
    permissions?.find((permission) => permission.name === "rooms_management.view_roompackageoccupancy")?.value === true;

  const isDeletingPackageAllowed =
    permissions?.find((permission) => permission.name === "rooms_management.delete_roompackageoccupancy")?.value ===
    true;

  const isEditingPackageAllowed =
    permissions?.find((permission) => permission.name === "rooms_management.change_roompackageoccupancy")?.value ===
    true;

  return isViewingPackageAllowed ? (
    <div className="min-h-[100dvh]">
      <Formik
        initialValues={{
          city: "",
          hotel: "",
          room: "",
          roomData: "",
        }}
        onSubmit={() => {}}
      >
        {({ values, setValues }) => {
          const { roomData } = values;
          return (
            <>
              <Form>
                <div className="grid grid-cols-3 px-4 gap-8 h-32 ">
                  <CitySelectInput />
                  <HotelSelectInput />
                  <RoomSelectInput />
                </div>
              </Form>
              <div className={"mx-4"}>
                <div className="p-4 bg-white rounded-xl shadow-md">
                  <div className="flex justify-between items-center">
                    <h4 className="text-2xl font-bold ">Packages</h4>
                    {roomData && isAddingPackageAllowed && <AddNewPackageModal roomData={roomData} />}
                  </div>

                  {roomData ? (
                    <PackagesList
                      isEditingPackageAllowed={isEditingPackageAllowed}
                      isDeletingPackageAllowed={isDeletingPackageAllowed}
                      roomData={values.roomData}
                    />
                  ) : (
                    <div className="text-center text-gray-500">Please select a room to view packages</div>
                  )}
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  ) : (
    <NoPermission />
  );
}

export default RoomPackages;
