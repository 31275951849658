import {
  GET_ALL_BOOKING_REQUESTS,
  GET_ALL_CHATS,
  GET_BOOKING_REQUEST_DETAILS,
  GET_BOOKING_REQUESTS_LIST,
  GET_BOOKING_REQUESTS_TYPES_CHOICE,
  GET_REQUESTS_STATS,
} from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export default function addBookingRequest(data, callback) {
  return async function () {
    try {
      const response = await api_token(token).post(`/booking/api/v1/booking-requests/`, data);

      if (response) {
        callback();
      }
    } catch (error) {}
  };
}

function getBookingRequestsListSuccess(data) {
  return {
    type: GET_BOOKING_REQUESTS_LIST,
    payload: data,
  };
}

function getAllBookingRequestsListSuccess(data) {
  return {
    type: GET_ALL_BOOKING_REQUESTS,
    payload: data,
  };
}

function getBookingRequestTypeChoicesSuccess(data) {
  return {
    type: GET_BOOKING_REQUESTS_TYPES_CHOICE,
    payload: data,
  };
}

function getBookingRequestDetailsSuccess(data) {
  return {
    type: GET_BOOKING_REQUEST_DETAILS,
    payload: data,
  };
}

function getAllChatsSuccess(data) {
  return {
    type: GET_ALL_CHATS,
    payload: data,
  };
}

export function getBookingRequestsList(id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`booking/api/v1/booking-requests-by-room/${id}/`);
      dispatch(getBookingRequestsListSuccess(response.data));
      if (response && callback) {
        callback();
      }
    } catch (error) {}
  };
}

export function getAllBookingRequestsList(currentPage, pageSize, status, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        `/booking/api/v1/booking-requests/?booking_status=${status}&ordering=-id&page=${currentPage}&page_size=${pageSize}`,
      );
      dispatch(getAllBookingRequestsListSuccess(response.data));
      if (response && callback) {
        callback(response);
      }
    } catch (error) {}
  };
}

export function getBookingRequestTypeChoices() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`booking/api/v1/request-type-choices/`);
      dispatch(getBookingRequestTypeChoicesSuccess(response.data));
    } catch (error) {}
  };
}

export function getBookingRequestDetails(id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/booking/api/v1/booking-requests/${id}/`);

      dispatch(getBookingRequestDetailsSuccess(response.data));
      // if (response && callback) {
      //   callback();
      // }
    } catch (error) {}
  };
}

export function getAllChats(id) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/booking/api/v1/chatt-tickets/${id}/messages/`);
      dispatch(getAllChatsSuccess(response.data));
    } catch (error) {}
  };
}

export function sendChat(id, data, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).post(`/booking/api/v1/tickets/${id}/messages/`, data);
      if (response) {
        callback();
      }
    } catch (error) {}
  };
}

export function updateBookingStatus(id, data, callback) {
  return async function () {
    try {
      const response = await api_token(token).put(`/booking/api/v1/booking-requests/${id}/`, data);

      if (response && callback) {
        callback();
      }
    } catch (error) {}
  };
}

export function getBookingStats() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/booking/api/v1/booking-request-status-statistics/`);
      dispatch({ type: GET_REQUESTS_STATS, payload: response.data });
    } catch (error) {}
  };
}
