import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import lightToast from "light-toast";
import ButtonLoading from "../../ButtonLoading/ButtonLoading";
import { FaWpforms } from "react-icons/fa6";
import { getBankChequesAction } from "../../../../redux/actions/cashAction";
// import { TimePicker } from "antd";
// import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import TopBarProgress from "react-topbar-progress-indicator";
// import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useFormik } from "formik";
// import { sendData } from "../../../redux/actions/sendDataAction";
// import { ADD_BANKTRANSFER_URL } from "../../../urls";
import useRemoveScroll from "../../../../customHooks/useRemoveScroll";
import { NotificationManager } from "react-notifications";
import { getChequesActionId, UpdateChequesAction } from "../../../../redux/actions/bankChequesActions";
import usePermissions from "../../../../customHooks/usePermissions";
import { ChequesValidations } from "./ChequeValidation";
import useHotelNames from "../../../../customHooks/useHotelNames";
import { SlCalender } from "react-icons/sl";

const UpdateCheque = ({ method, bankTranfer }) => {
  const [selectedBank, setSelectedBank] = useState();
  const [currency, setCurrency] = useState("");
  const [convertRate, setConvertRate] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [accountNumberBank, setAccountNumberBank] = useState("");
  const [bank, setBank] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTransacion, setSelectedTransacion] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const [selectedHotel, setSelectedHotel] = useState();
  const [selectedOther, setSelectedOther] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const hotels = useHotelNames();
  const hotelsArr = hotels ? hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })) : [];

  useRemoveScroll();
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  const purposeOptions = [
    { label: "Hotel Booking", value: "Hotel Booking" },
    { label: "Other", value: "Other" },
  ];
  // const ChequeStatus = [
  //   { label: "Not Used", value: "Not Used" },
  //   { label: "Delivered", value: "Delivered" },
  //   { label: "Deducted", value: "Deducted" },
  // ];
  // const Transactions = [
  //   { label: "pending", value: "pending" },
  //   { label: "confirmed", value: "confirmed" },
  //   { label: "rejected", value: "rejected" },
  // ];
  const To = [
    { label: "Hotel", value: "Hotel" },
    { label: "Other", value: "Other" },
  ];
  // Form State
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      bank: "",
      to: "",
      bank_account_number: "",
      cheque_book: "",
      cheque_number: "",
      cheque_status: "",
      amount: "",
      delivered_to: "",
      date_time_deliver: "",
      due_date: "",
      details: "",
      hotel: null,
      other: "",
      purpose: "",
      issued_date: "",
      transaction_status: "pending",
    },
    validationSchema: ChequesValidations,
    onSubmit: submit,
  });
  useEffect(() => {
    if (params.id) {
      dispatch(
        getChequesActionId(token, params.id, (result) => {
          if (result.status === 200) {
            setFieldValue("bank", result.data.bank.name);
            setBank(result.data.bank.name);

            // setFieldValue("added_by", result.data.added_by);
            // setFieldValue("bank_currency", result.data.bank_currency);
            // setCurrency(result.data.bank_currency);
            setFieldValue("bank_account_number", result.data.bank_account_number);
            setFieldValue("amount", result.data.amount);
            setFieldValue("cheque_book", result.data.cheque_book);
            setFieldValue("cheque_number", result.data.cheque_number);
            setFieldValue("cheque_status", result.data.cheque_status);
            setFieldValue(
              "cheque_status",
              result.data.length === 0 ? ["Not Used"] : result.data.cheque_status.map((item) => item.id),
            );
            // setSelectedStatus(
            //   result.data.cheque_status.length === 0
            //     ? { label: "Not Used", value: "Not Used" }
            //     : result.data.cheque_status.map((item) => ({ label: item.name, value: item.id }))
            // ); // to set default value of select
            setFieldValue("confirmed_by", result.data.confirmed_by);
            setFieldValue("created_date", result.data.created_date);
            setFieldValue("date_time_deliver", result.data.date_time_deliver);
            setFieldValue("delivered_to", result.data.delivered_to);
            setFieldValue("details", result.data.details);
            setFieldValue("due_date", result.data.due_date);

            setFieldValue("other", result.data.other === null ? null : result.data.other);
            setFieldValue(
              "purpose",
              result.data.puprose.length === 0 ? ["Other"] : result.data.purpose.map((item) => item.id),
            );
            setSelectedPurpose(
              result.data.purpose.length === 0
                ? { label: "Other", value: "Other" }
                : result.data.purpose.map((item) => ({ label: item.name, value: item.id })),
            ); // to set default value of select
            setFieldValue("to", result.data.to.length === 0 ? [""] : result.data.hotel.map((item) => item.id));
            setSelectedTo(
              result.data.to.length === 0
                ? { label: "Other", value: "Other" }
                : result.data.hotel.map((item) => ({ label: item.name, value: item.id })),
            );

            setFieldValue("hotel", result.data === null ? null : hotelsArr);
            setSelectedHotel(
              result.data.hotel
                ? { label: "hotel", value: "hotel" }
                : result.data.hotel.map((item) => ({ label: item.name, value: item.id })),
            ); // to set default value of select
            // setFieldValue("other", result.data.other === null ? "" : result.data.other);
          }
        }),
      );
    }
  }, []);

  //

  function submit(data) {
    let toValue;
    if (data.to === "Hotel") {
      toValue = selectedHotel;
    } else if (data.to === "Other") {
      toValue = selectedOther;
    } else {
      // Handle other cases if needed
      toValue = data.to;
    }

    // Determine if hotel data should be sent
    // let hotelData = data.to !== "Other" && data.hotel && data.hotel.length > 0 ? data.hotel : data.other;
    //
    const formData = new FormData();
    formData.append("bank", data.bank);
    formData.append("to", toValue);
    // formData.append("added_by", data.added_by);
    formData.append("bank_account_number", data.bank_account_number);
    formData.append("cheque_book", data.cheque_book);
    formData.append("cheque_number", data.cheque_number);
    formData.append("account_balance", data.account_balance);
    formData.append("cheque_status", "Deducted");
    formData.append("amount", data.amount);
    formData.append("delivered_to", data.delivered_to);
    formData.append("date_time_deliver", data.date_time_deliver);
    formData.append("due_date", data.due_date);
    formData.append("details", data.details);
    if (data?.other !== null) {
      formData.append("other", data.other);
    }
    if (data.hotel === null || data.hotel === "") {
      formData.delete("hotel");
    } else {
      formData.append("hotel", data.hotel);
    }
    formData.append("purpose", data.purpose);
    formData.append("issued_date", data.issued_date);
    // formData.append("confirmed_by", data.confirmed_by);
    formData.append("transaction_status", data.transaction_status);

    // Now you can submit 'formData'

    // const newValues = {
    //   bank: data.bank,
    //   to: data.to === "Hotel" ? selectedHotel : selectedOther,
    //   added_by: data.added_by,
    //   bank_account_number: data.bank_account_number,
    //   cheque_book: data.cheque_book,
    //   cheque_number: data.cheque_number,
    //   account_balance: data.account_balance,
    //   cheque_status: "Deducted",
    //   amount: data.amount,
    //   delivered_to: data.delivered_to,
    //   date_time_deliver: data.date_time_deliver,
    //   due_date: data.due_date,
    //   details: data.details,
    //   other: data?.other === null ? null : data?.other,
    //   hotel: data?.hotel,
    //   purpose: data.purpose,
    //   issued_date: data.issued_date,
    //   confirmed_by: data.confirmed_by,
    //   transaction_status: data.transaction_status,
    // };

    dispatch(
      UpdateChequesAction(token, params.id, formData, (result) => {
        if (result.status === 200 || result.status === 201) {
          setSubmitting(false);
          setSelectedStatus("Deducted");
          //
          navigate("/dashboard/bank-transfers");
          lightToast.success(" Cheque Updated successfully");
          // navigate(-1);
        } else {
          setSubmitting(false);

          NotificationManager.error(result?.response?.data[0]?.message);
        }
      }),
    );
  }

  const { bankData } = useSelector((state) => state.cashReducer);
  let Bank = bankData
    ? bankData?.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency, bankAccountNumber: item.account_number };
      })
    : [];

  useEffect(() => {
    dispatch(getBankChequesAction(token, (res) => {}));
  }, []);

  /////permission/////////

  const [permissions, permissionsFlag] = usePermissions(["accounting.change_cheque"]);
  return (
    <div className="w-full  ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {selectedSingleRoom && permissionsFlag ? (
          permissions.map((item, idx) => {
            if (item.name === "accounting.change_cheque" && item.value === true) {
              return (
                <div key={idx} className={`w-full  max-w-5xl mx-auto px-6 my-12   rounded-lg  `}>
                  <div className=" flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
                    <p className="flex items-center gap-x-3  text-md">
                      {" "}
                      <FaWpforms className="w-5 h-5" />
                      Update Cheque
                    </p>
                  </div>

                  <form
                    className="mt-2 rounded-md shadow-md border px-4 my-8 py-8 w-full"
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" flex text-gray-600 "> Bank Name</label>
                      <Select
                        placeholder="Bank Name"
                        name="bank"
                        defaultValue={bank}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${touched.bank && errors.bank && "rgb(153 27 27)"}`,
                          }),
                        }}
                        options={Bank}
                        value={bank ? [{ label: bank, value: bank }] : null}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("bank", e.value);

                          setBank(e.label);
                        }}
                        isSearchable
                        className=" w-full  h-10  rounded-lg  bg-white "
                      />
                      {touched.bank && errors.bank ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.bank}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600 ">Bank Account Number </label>
                      <input
                        placeholder="first cheque number"
                        name="bank_account_number"
                        type="number"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bank_account_number}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.bank_account_number && errors.bank_account_number && "border border-red-800"
                        } `}
                      />
                      {touched.bank_account_number && errors.bank_account_number ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.bank_account_number}
                        </div>
                      ) : null}
                      {/* <label className=" flex text-gray-600 ">Cheque Book</label>
                      <input
                        placeholder="first cheque number"
                        name="cheque_book"
                        type="number"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cheque_book}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.cheque_book && errors.cheque_book && "border border-red-800"
                        } `}
                      />
                      {touched.cheque_book && errors.cheque_book ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.cheque_book}
                        </div>
                      ) : null} */}
                      <label className=" flex text-gray-600 ">Cheque Number </label>
                      <input
                        placeholder="cheque_number"
                        name="cheque_number"
                        type="number"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cheque_number}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.cheque_number && errors.cheque_number && "border border-red-800"
                        } `}
                      />
                      {touched.cheque_number && errors.cheque_number ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.cheque_number}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600 "> to</label>
                      <Select
                        placeholder="to"
                        name="to"
                        step="any"
                        onBlur={handleBlur}
                        options={To}
                        // defaultValue={hotelsArr}
                        value={
                          values.to === "Other"
                            ? [{ label: selectedOther, value: selectedOther }]
                            : [{ label: selectedHotel, value: selectedHotel }]
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          setFieldValue("to", e.label);
                        }}
                        className={` w-full  h-10  border rounded-lg bg-white ${
                          touched.to && errors.to && "border border-red-800"
                        } `}
                      />
                      {touched.to && errors.to ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.to}
                        </div>
                      ) : null}
                      {values.to === "Hotel" ? (
                        <>
                          <label className=" text-center w-full text-gray-600 font-semibold">Hotel Name</label>
                          <Select
                            name="hotel"
                            options={hotelsArr}
                            isSearchable
                            className="w-full  h-10  rounded-lg  bg-white capitalize"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: `${errors.hotel && touched.hotel && "rgb(153 27 27)"}`,
                              }),
                            }}
                            placeholder="Hotel Name"
                            onChange={(e) => {
                              setFieldValue("hotel", e.value !== null ? e.value : "hotel");
                              setSelectedHotel(e.label);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.hotel && touched.hotel && (
                            <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                              {errors.hotel}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <label className=" flex text-gray-600 "> Other</label>
                          <input
                            placeholder="other"
                            type="text"
                            name="other"
                            step="any"
                            onBlur={handleBlur}
                            value={values.other}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              setFieldValue("other", e.target.value);
                              setSelectedOther(e.target.value);
                            }}
                            className={` w-full px-3 h-10  border rounded-lg bg-white ${
                              touched.other && errors.other && "border border-red-800"
                            } `}
                          />
                          {touched.other && errors.other ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.other}
                            </div>
                          ) : null}
                        </>
                      )}
                      <label className=" flex text-gray-600 "> Issued Date</label>
                      <div
                        className={`w-full rounded-md border px-2 flex items-center ${
                          touched.issued_date && errors.issued_date && "border border-red-800"
                        } `}
                      >
                        <input
                          placeholder="issued_date"
                          name="issued_date"
                          type="date"
                          onWheel={(e) => e.target.blur()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.issued_date}
                          className={` w-full px-3 h-10 floating-input outline-none rounded-lg bg-white  `}
                        />
                        <SlCalender />
                      </div>
                      {touched.issued_date && errors.issued_date ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.issued_date}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600 "> Due Date</label>
                      <div
                        className={`w-full rounded-md border px-2 flex items-center ${
                          touched.issued_date && errors.issued_date && "border border-red-800"
                        } `}
                      >
                        <input
                          placeholder="due_date"
                          name="due_date"
                          type="date"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.due_date}
                          className={` w-full px-3 h-10  floating-input outline-none rounded-lg bg-white `}
                        />
                        <SlCalender />
                      </div>
                      {touched.due_date && errors.due_date ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.due_date}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600 "> Amount</label>
                      <input
                        placeholder="amount"
                        name="amount"
                        type="number"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.amount}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.amount && errors.amount && "border border-red-800"
                        } `}
                      />
                      {touched.amount && errors.amount ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.amount}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600 "> Purpose</label>
                      <Select
                        placeholder="purpose"
                        name="purpose"
                        options={purposeOptions}
                        defaultValue={selectedPurpose}
                        value={values.purpose === "Other" ? [{ label: "Other", value: "Other" }] : selectedPurpose}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          setFieldValue("purpose", e.value);
                        }}
                        onBlur={handleBlur}
                        className={` w-full h-10  border rounded-lg bg-white ${
                          touched.purpose && errors.purpose && "border border-red-800"
                        } `}
                      />
                      {touched.purpose && errors.purpose ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.purpose}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600 "> Details</label>
                      <input
                        placeholder="details"
                        name="details"
                        type="text"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.details}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.details && errors.details && "border border-red-800"
                        } `}
                      />
                      {touched.details && errors.details ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.details}
                        </div>
                      ) : null}
                      {/* <label className=" flex text-gray-600 "> Cheque Status</label>
                      <Select
                        placeholder="cheque_status"
                        name="cheque_status"
                        onWheel={(e) => e.target.blur()}
                        options={ChequeStatus}
                        defaultValue={selectedStatus}
                        onChange={(e) => setFieldValue("cheque_status", e.value)}
                        onBlur={handleBlur}
                        value={
                          values.cheque_status === "Not Used"
                            ? [{ label: "Not Used", value: "Not Used" }]
                            : values.cheque_status === "Delivered"
                            ? [{ label: "Delivered", value: "Delivered" }]
                            : [{ label: "Deducted", value: "Deducted" }]
                        }
                        className={` w-full  h-10  border rounded-lg bg-white ${
                          touched.cheque_status && errors.cheque_status && "border border-red-800"
                        } `}
                      />
                      {touched.cheque_status && errors.cheque_status ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.cheque_status}
                        </div>
                      ) : null} */}
                      <label className=" flex text-gray-600 "> Delivered to</label>
                      <input
                        placeholder="delivered_to"
                        name="delivered_to"
                        type="text"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.delivered_to}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.delivered_to && errors.delivered_to && "border border-red-800"
                        } `}
                      />
                      {touched.delivered_to && errors.delivered_to ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.delivered_to}
                        </div>
                      ) : null}
                      {/* <label className=" flex text-gray-600 "> confirmed_by</label>
                      <input
                        placeholder="confirmed_by"
                        name="confirmed_by"
                        type="text"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmed_by}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.confirmed_by && errors.confirmed_by && "border border-red-800"
                        } `}
                      />
                      {touched.confirmed_by && errors.confirmed_by ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.confirmed_by}
                        </div>
                      ) : null} */}
                      <label className=" flex text-gray-600 "> Date&Time of Deliver</label>
                      <div
                        className={`w-full rounded-md border px-2 flex items-center ${
                          touched.issued_date && errors.issued_date && "border border-red-800"
                        } `}
                      >
                        <input
                          placeholder="date_time_deliver"
                          name="date_time_deliver"
                          type="date"
                          step="any"
                          onWheel={(e) => e.target.blur()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.date_time_deliver}
                          className={` w-full px-3 h-10 floating-input outline-none rounded-lg bg-white  `}
                        />
                        <SlCalender />
                      </div>
                      {touched.date_time_deliver && errors.date_time_deliver ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.date_time_deliver}
                        </div>
                      ) : null}

                      {/* <label className=" flex text-gray-600 "> details</label>
                      <input
                        placeholder="details"
                        name="details"
                        type="text"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.details}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.details && errors.details && "border border-red-800"
                        } `}
                      />
                      {touched.details && errors.details ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.details}
                        </div>
                      ) : null} */}

                      {/* <label className=" flex text-gray-600 "> expenses</label>
                      <input
                        placeholder="expenses"
                        name="expenses"
                        type="text"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.expenses}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.expenses && errors.expenses && "border border-red-800"
                        } `}
                      />
                      {touched.expenses && errors.expenses ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.expenses}
                        </div>
                      ) : null} */}
                      {/* <label className=" flex text-gray-600 "> hotel</label>
                      <input
                        placeholder="hotel"
                        name="hotel"
                        type="number"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.hotel}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.hotel && errors.hotel && "border border-red-800"
                        } `}
                      />
                      {touched.hotel && errors.hotel ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.hotel}
                        </div>
                      ) : null} */}
                      {/* <label className=" flex text-gray-600 "> income</label>
                      <input
                        placeholder="income"
                        name="income"
                        type="number"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.income}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.income && errors.income && "border border-red-800"
                        } `}
                      />
                      {touched.income && errors.income ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.income}
                        </div>
                      ) : null} */}

                      {/* <label className=" flex text-gray-600 "> other</label>
                      <input
                        placeholder="other"
                        name="other"
                        type="text"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.other}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.other && errors.other && "border border-red-800"
                        } `}
                      />
                      {touched.other && errors.other ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.other}
                        </div>
                      ) : null} */}
                      {/* <label className=" flex text-gray-600 "> Purpose</label>
                      <Select
                        placeholder="purpose"
                        name="purpose"
                        options={purposeOptions}
                        defaultValue={selectedPurpose}
                        value={values.purpose === "All" ? [{ label: "All", value: "All" }] : selectedPurpose}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          setFieldValue("purpose", e.value);
                        }}
                        onBlur={handleBlur}
                        className={` w-full h-10  border rounded-lg bg-white ${
                          touched.purpose && errors.purpose && "border border-red-800"
                        } `}
                      />
                      {touched.purpose && errors.purpose ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.purpose}
                        </div>
                      ) : null} */}

                      {/* <label className=" flex text-gray-600 "> transaction_status</label>
                      <Select
                        placeholder="transaction_status"
                        name="transaction_status"
                        onWheel={(e) => e.target.blur()}
                        options={Transactions}
                        defaultValue={selectedTransacion}
                        onChange={(e) => setFieldValue("transaction_status", e.value)}
                        onBlur={handleBlur}
                        value={
                          values.transaction_status === "pending"
                            ? [{ label: "pending", value: "pending" }]
                            : values.transaction_status === "confirmed"
                            ? [{ label: "confirmed", value: "confirmed" }]
                            : [{ label: "rejected", value: "rejected" }]
                        }
                        className={` w-full h-10  border rounded-lg bg-white ${
                          touched.transaction_status && errors.transaction_status && "border border-red-800"
                        } `}
                      />
                      {touched.transaction_status && errors.transaction_status ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.transaction_status}
                        </div>
                      ) : null} */}

                      <button
                        type="Submit"
                        disabled={isSubmitting}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        update{" "}
                        {isSubmitting ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <IoAdd className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              );
            }
          })
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <TopBarProgress /> <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateCheque;
