import api_token from "../../api/UserApi";
import Errorfetch from "../../components/organisms/Errorfetch/Errorfetch";
import { ALL_MACHINE, ALL_MACHINE_FLAG, POST_MAHCINE, POST_MAHCINE_FLAG } from "../types/types";

export const postPosMachineAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: POST_MAHCINE_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`accounting/api/v1/visa-machines/`, data);
      dispatch({
        type: POST_MAHCINE,
        payload: response.data,
      });
      if (response.status === 201) {
        dispatch({
          type: POST_MAHCINE_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
export const allPosMachineAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_MACHINE_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`accounting/api/v1/visa-machines/`);
      dispatch({
        type: ALL_MACHINE,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: ALL_MACHINE_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      <Errorfetch />;
    }
  };
};
export const allPosMachineActionById = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_MACHINE_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`accounting/api/v1/visa-machines/${id}/`);
      dispatch({
        type: ALL_MACHINE,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: ALL_MACHINE_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      <Errorfetch />;
    }
  };
};
export const allPosMachineActionUpdate = (token, id, values, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_MACHINE_FLAG,
        payload: true,
      });
      const response = await api_token(token).patch(`accounting/api/v1/visa-machines/${id}/`, values);
      dispatch({
        type: ALL_MACHINE,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: ALL_MACHINE_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      <Errorfetch />;
    }
  };
};
export const allPosMachineActionDelete = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_MACHINE_FLAG,
        payload: true,
      });
      const response = await api_token(token).delete(`accounting/api/v1/visa-machines/${id}/`);
      dispatch({
        type: ALL_MACHINE,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: ALL_MACHINE_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      <Errorfetch />;
    }
  };
};
