import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { TbListDetails } from "react-icons/tb";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../../NoPermission/NoPermission";
import Loading from "../../Loading/Loading";
import { getSearchByIdAction } from "../../../../redux/actions/searchPermissionAction";
import usePermissions from "../../../../customHooks/usePermissions";

const UpdateSearchPermission = () => {
  const [searchList, setSearchList] = useState();

  const dispatch = useDispatch();
  const params = useParams();

  //Permission
  const [permissions, permissionsFlag] = usePermissions([
    "permissions_management.change_searchpermission",
    "permissions_management.view_searchpermission",
    "permissions_management.delete_searchpermission",
  ]);

  useEffect(() => {
    dispatch(
      getSearchByIdAction(params.id, (result) => {
        if (result.status === 200) {
          setSearchList(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap text-black ">
      {permissions && permissionsFlag && searchList ? (
        permissions.some((item) => item.value) ? (
          permissions?.map(
            (item) =>
              item.name === "permissions_management.view_searchpermission" &&
              item.value === true && (
                <div className=" ">
                  <div className="flex flex-col   ">
                    <div className="flex items-center justify-center ">
                      <h2 className="header-h2">
                        <TbListDetails className="w-5 h-5" />
                        Search Permission Details
                      </h2>
                    </div>
                    <table className="max-md:hidden form-wrap   font-semibold">
                      <thead>
                        <tr className="bg-gray-50">
                          <th className="p-3 border-r border-b md:text-base text-sm">Id</th>
                          <th className="p-3 border-r border-b md:text-base text-sm">Username</th>
                          <th className="p-3 border-r border-b md:text-base text-sm">Email</th>
                          <th className="p-3 border-r border-b md:text-base text-sm">Hotels</th>
                          <th className="p-3 border-r border-b md:text-base text-sm">City</th>
                          <th className="p-3 border-r border-b md:text-base text-sm">Nationality</th>
                          <th className="p-3  border-b md:text-base text-sm">Markup</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          <td className="p-3 border-r  md:text-base text-sm">{searchList.id}</td>
                          <td className="p-3 border-r  md:text-base text-sm break-all">{searchList.user.username}</td>
                          <td className="p-3 border-r  md:text-base text-sm break-all">{searchList.user.email}</td>
                          <td className="p-3 border-r  md:text-base text-sm">
                            {searchList.allowed_hotels.length === 0 && searchList.all_hotels === true ? (
                              <p>All Hotels</p>
                            ) : (
                              searchList.allowed_hotels.map((item, index) => (
                                <p key={index} className="md:text-base text-sm">
                                  {item.name}
                                </p>
                              ))
                            )}
                          </td>
                          <td className="p-3 md:text-base text-sm border-r ">
                            {searchList.allowed_city.length === 0 && searchList.all_city === true ? (
                              <p>All City</p>
                            ) : (
                              searchList.allowed_city.map((item, index) => (
                                <p key={index} className="md:text-base text-sm border-r ">
                                  {item.name}
                                </p>
                              ))
                            )}
                          </td>
                          <td className="p-3 md:text-base text-sm border-r ">
                            {searchList.allowed_contoury.length === 0 && searchList.all_contoury === true ? (
                              <p>All Nationality</p>
                            ) : (
                              searchList.allowed_contoury.map((item, index) => (
                                <p key={index} className="md:text-base text-sm border-r ">
                                  {item.name}
                                </p>
                              ))
                            )}
                          </td>
                          <td className="p-3 md:text-base text-sm  ">
                            {searchList.allowed_markup.length === 0 && searchList.all_markup === true ? (
                              <p>All Markup</p>
                            ) : (
                              searchList.allowed_markup.map((item, index) => (
                                <p key={index} className="md:text-base text-sm  ">
                                  {item.name}
                                </p>
                              ))
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="md:hidden form-wrap  font-semibold">
                      <tbody>
                        <tr className=" rounded-md">
                          <td className="p-3 border-r   border-b   font-bold md:text-base text-sm">Id</td>
                          <td className="p-3 md:text-base text-sm border-b">{searchList.id}</td>
                        </tr>
                        <tr className="">
                          <td className="p-3 font-bold border-r border-b   md:text-base text-sm">Username</td>
                          <td className="p-3 md:text-base text-sm break-all border-b">{searchList.user.username}</td>
                        </tr>
                        <tr className="">
                          <td className="p-3 font-bold border-r border-b   md:text-base text-sm">Email</td>
                          <td className="p-3 md:text-base text-sm break-all border-b">{searchList.user.email}</td>
                        </tr>
                        <tr className="">
                          <td className="p-3 font-bold border-r border-b   md:text-base text-sm">Hotels</td>
                          <td className="p-3 md:text-base text-sm border-b">
                            {searchList.allowed_hotels.length === 0 && searchList.all_hotels === true ? (
                              <p>All Hotels</p>
                            ) : (
                              searchList.allowed_hotels.map((item, index) => (
                                <p key={index} className="md:text-base text-sm">
                                  {item.name}
                                </p>
                              ))
                            )}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="p-3 font-bold border-r border-b   md:text-base text-sm">City</td>
                          <td className="p-3 md:text-base text-sm border-b">
                            {searchList.allowed_city.length === 0 && searchList.all_city === true ? (
                              <p>All City</p>
                            ) : (
                              searchList.allowed_city.map((item, index) => (
                                <p key={index} className="md:text-base text-sm border-b">
                                  {item.name}
                                </p>
                              ))
                            )}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="p-3 font-bold border-r border-b   md:text-base text-sm">Nationality</td>
                          <td className="p-3 md:text-base text-sm border-b">
                            {searchList.allowed_contoury.length === 0 && searchList.all_contoury === true ? (
                              <p>All Nationality</p>
                            ) : (
                              searchList.allowed_contoury.map((item, index) => (
                                <p key={index} className="md:text-base text-sm">
                                  {item.name}
                                </p>
                              ))
                            )}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="p-3 font-bold border-r border-b   md:text-base text-sm">Markup</td>
                          <td className="p-3 md:text-base text-sm ">
                            {searchList.allowed_markup.length === 0 && searchList.all_markup === true ? (
                              <p>All Markup</p>
                            ) : (
                              searchList.allowed_markup.map((item, index) => (
                                <p key={index} className="md:text-base text-sm">
                                  {item.name}
                                </p>
                              ))
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
          )
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default UpdateSearchPermission;
