import api from "../../api/LoginApi";
import { ISLOGIN, LOGINERROR, LOGIN_FLAG } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

export const loginAction = (Data, callback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOGINERROR,
      payload: "",
    });

    try {
      dispatch({
        type: LOGIN_FLAG,
        payload: true,
      });
      const response = await api.post("/auth/api/v1/login/", Data);
      if (response.status === 200) {
        dispatch({
          type: LOGIN_FLAG,
          payload: false,
        });
      }
      callback(response);
      dispatch({
        type: ISLOGIN,
        payload: response.data.user.id,
      });
    } catch (error) {
      callback(error);
      dispatch({
        type: LOGIN_FLAG,
        payload: false,
      });

      dispatch({
        type: LOGINERROR,
        payload: error?.response?.data[0]?.message,
      });
    }
  };
};
export const logoutAction = (token, refresh, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post("/auth/api/v1/logout/", { refresh });

      callback(response);
    } catch (error) {}
  };
};
