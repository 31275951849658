import React from "react";
import AddPos from "./AddPos";
import PosList from "./PosList";

const PosMAchineRelated = () => {
  return (
    <div className="formPage-wrap">
      <div className="form-wrap  grid sm:grid-cols-2 grid-cols-1  ">
        <PosList />
        <AddPos />
      </div>
    </div>
  );
};

export default PosMAchineRelated;
