import React from "react";
import { useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { FieldErrorText, FormLabel } from "../../../customComponents/FormComponents";
import Select from "react-select";

function RoomSelectInput() {
  const { values, setValues } = useFormikContext();

  const {
    isLoading,
    data: rooms,
    error,
  } = useQuery({
    queryKey: ["rooms", values.city, values.hotel],
    queryFn: () => fetchDataQuery(`/en/rooms/api/v1/get-rooms-in-hotel/${values.hotel}/`),
    enabled: !!values.city && !!values.hotel,
  });

  return (
    <div>
      <FormLabel label={"Room"} htmlFor={"room"} />
      <Select
        key={values.hotel}
        name={"room"}
        id={"room"}
        isLoading={isLoading}
        defaultValue={values.room}
        onChange={(newValue, actionMeta) => {
          setValues((prevValues) => {
            return {
              ...prevValues,
              room: newValue.value,
              roomData: rooms.find((room) => room.id === newValue.value),
            };
          });
        }}
        isDisabled={isLoading || !values.city || !values.hotel}
        loadingMessage={() => "Getting Rooms"}
        placeholder="Choose Room"
        options={rooms?.map((room) => {
          return {
            value: room.id,
            label: room.room_name,
          };
        })}
      />
      {error && <FieldErrorText message={"Error fetching Rooms"} />}
    </div>
  );
}

export default RoomSelectInput;
