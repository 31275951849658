import React from "react";
import AddSpecialRate from "./AddSpecialRate";
import { TbDeviceIpadDollar } from "react-icons/tb";
import { SiReaddotcv } from "react-icons/si";
import { Link } from "react-router-dom";
import { MdContacts } from "react-icons/md";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import usePermissions from "../../../customHooks/usePermissions";

const SpecialRate = () => {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "sales_management.add_specialratetoolsetup",
    "sales_management.add_specialraterequest",
    "tasks_management.delete_taskassignment",
    "tasks_management.change_taskassignment",
  ]);
  const isViewingSpeicalSetupAllowed =
    permissions?.find((permission) => permission.name === "sales_management.add_specialratetoolsetup")?.value === true;

  const isViewingSpeicalRequestAllowed =
    permissions?.find((permission) => permission.name === "sales_management.add_specialraterequest")?.value === true;

  // this will delete the hotel stored in the state by passing it to the action then remove the popup

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  return (
    <section className=" formPage-wrap ">
      <div className="form-wrap grid lg:grid-cols-3 grid-cols-1 justify-items-center">
        <div className="w-max text-center flex flex-col items-center">
          {isViewingSpeicalRequestAllowed && (
            <Link
              to="/dashboard/add-special-rates-requests"
              className="flex rounded-full flex-col gap-6 items-center w-full"
            >
              <div className="h-24 w-24 rounded-full bg-[#002244] relative text-center">
                <TbDeviceIpadDollar className="absolute w-14 h-14 text-white top-1/2 left-1/2 -translate-x-[50%]  -translate-y-[50%] hover:scale-110 duration-150 transition-all" />
              </div>
              <p className="text-lg font-semibold text-[#002244]">Add Special Rate Request </p>
            </Link>
          )}
        </div>

        <div className="w-max text-center  flex flex-col items-center">
          <Link
            to="/dashboard/special-rates-requests-list"
            className="flex flex-col gap-6 items-center rounded-full w-full"
          >
            <div className="h-24 w-24 rounded-full bg-[#002244] relative">
              <SiReaddotcv className="absolute  w-14 h-14 text-white top-1/2 left-1/2 -translate-x-[50%]  -translate-y-[50%] hover:scale-110 duration-150 transition-all" />
            </div>
            <p className="text-lg font-semibold text-[#002244]"> Special Rate Request List</p>
          </Link>
        </div>
        <div className="w-max text-center flex flex-col items-center">
          {isViewingSpeicalSetupAllowed && (
            <Link
              to="/dashboard/add-special-rates-setup"
              className="flex rounded-full flex-col gap-6 items-center w-full"
            >
              <div className="h-24 w-24 rounded-full bg-[#002244] relative text-center">
                <MdContacts className="absolute w-14 h-14 text-white top-1/2 left-1/2 -translate-x-[50%]  -translate-y-[50%] hover:scale-110 duration-150 transition-all" />
              </div>
              <p className="text-lg font-semibold text-[#002244]"> Special Rate Setup </p>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default SpecialRate;
