import React from "react";
import { FieldArray, Form, Formik } from "formik";
import { FieldErrorText, FormSection, FormTextInput } from "../../../../customComponents/FormComponents";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import { GoPerson } from "react-icons/go";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { SiGoogletranslate } from "react-icons/si";
import { translateTextToArabic, translateTextToEnglish } from "../../../../redux/actions/translationActions";

function ClientFamilyForm({ title, initialValues, validationSchema, onSubmit, isPending, onSubmitButtonTitle }) {
  const { clientID } = useParams();

  const {
    data: client,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["user-customer-specific", clientID],
    queryFn: () => {
      return fetchDataQuery(`/auth/api/v1/user-customer-specific/${clientID}/`);
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error || !client) {
    return <Errorfetch Error={error.message} />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values, setFieldValue, errors }) => {
        return (
          <Form className="space-y-6 mt-2 w-full">
            <FormSection title={title}>
              <h3 className=" flex justify-center gap-1 font-semibold text-gray-600">
                Client name: <span className="font-normal "> {client.customer_name} </span>
              </h3>

              <FieldArray name="family">
                {({ remove, push }) => (
                  <div>
                    {values.family.length > 0 &&
                      values.family.map((familyMember, index) => (
                        <div className="flex gap-2 justify-center items-center mb-2" key={index}>
                          <FormTextInput
                            type={"text"}
                            name={`family.${index}.name_en`}
                            placeholder="Ahmed Mohsen"
                            Icon={GoPerson}
                          />

                          <SiGoogletranslate
                            onClick={async () => {
                              if (familyMember.name_en.length >= 3 && familyMember.name_ar.length === 0) {
                                const translatedText = await translateTextToArabic(familyMember.name_en);
                                await setFieldValue(`family.${index}.name_ar`, translatedText);
                              } else if (familyMember.name_ar.length >= 3 && familyMember.name_en.length === 0) {
                                const translatedText = await translateTextToEnglish(familyMember.name_ar);
                                await setFieldValue(`family.${index}.name_en`, translatedText);
                              }
                            }}
                            className="w-16 h-16 cursor-pointer"
                          />

                          <FormTextInput
                            name={`family.${index}.name_ar`}
                            placeholder="احمد محسن"
                            type="text"
                            Icon={GoPerson}
                          />

                          <button
                            type="button"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <FaMinusCircle className="w-6 h-6 rounded-full text-red-700 hover:text-red-900" />
                          </button>
                        </div>
                      ))}

                    {typeof errors.family === "string" ? <FieldErrorText message={errors.family} /> : null}

                    <div className="flex justify-end items-center  mt-2 ">
                      <button
                        className="rounded px-2 py-1  flex justify-center items-center gap-1  text-white bg-green-700 hover:bg-green-900 "
                        type="button"
                        onClick={() => push({ name_en: "", name_ar: "" })}
                      >
                        <FaPlusCircle className="w-5 h-5 text-white" />
                        <p>New Member</p>
                      </button>
                    </div>
                  </div>
                )}
              </FieldArray>
            </FormSection>
            <div className="flex justify-center items-center">
              <Button
                className="mb-4"
                color="success"
                isProcessing={isPending}
                type="submit"
                disabled={isPending}
                processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              >
                {onSubmitButtonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ClientFamilyForm;
