import React from "react";
import HotelUpgradeTool from "./upgradeHotelTool/HotelUpgradeTool";
import RoomUpgradeTool from "./upgradeRoomTool/RoomUpgradeTool";
import { GiUpgrade } from "react-icons/gi";

const UpgradeTool = () => {
  return (
    <div className="formPage-wrap">
      <h2 className="header-h2">
        <GiUpgrade className="h-5 w-5" />
        Upgrade Tool
      </h2>
      <div className="form-wrap grid grid-cols-2">
        <HotelUpgradeTool />
        <RoomUpgradeTool />
      </div>
    </div>
  );
};

export default UpgradeTool;
