import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { FaWpforms } from "react-icons/fa6";
import cookie from "react-cookies";
import { getCurrencyAction, getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import { createHotelPaymentOrder, getHotelBankAccounts, resetBankAccounts } from "../../../redux/actions/hotelPayments";
import toast from "react-hot-toast";
import TopBarProgress from "react-topbar-progress-indicator";
import HashLoader from "react-spinners/HashLoader";
import Loading from "../Loading/Loading";

const token = cookie.load("access_token");

export default function BankCashDeposit() {
  const { short_id, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //states and reducers
  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const hotelsWithBankAccounts = useSelector((state) => state.hotelsWithBankAccounts);
  const hotelBankAccounts = useSelector((state) => state.hotelBankAccounts);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  const [chosenCurrency, setChosenCurrency] = useState(null);
  const [chosenBankAccount, setChosenBankAccount] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  // form arrays
  const currencyListArr = currencyList ? currencyList.map((currency) => ({ label: currency, value: currency })) : [];
  let hotel = null;
  if (Object.keys(selectedSingleRoom).length > 0) {
    hotel = { label: selectedSingleRoom.hotel, value: selectedSingleRoom.hotel_id };
  }

  const hotelBankAccountsArr = hotelBankAccounts.map((obj) => ({
    label: `${obj.bank_name} (${obj.account_number})`,
    value: obj.id,
  }));

  useEffect(() => {
    dispatch(resetBankAccounts());
    dispatch(getSelectedBookedRoomSingleAction(token, id));
    dispatch(getCurrencyAction(token));
  }, []);

  useEffect(() => {
    if (hotel) {
      dispatch(getHotelBankAccounts(hotel.value, () => setLoading(false)));
    }
  }, [selectedSingleRoom]);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const data = {
      amount: e.target.amount.value,
      currency_type: chosenCurrency.value,
      last_date_for_payment: e.target.payment_deadline.value,
      notes: e.target.notes.value,
      hotel: hotel.value,
      booking: id,
      booking_short_id: short_id,
    };
    const formData = new FormData(e.target);
    data.method_payment = "payment_cash_deposit";
    data.method_payment_detail = JSON.stringify({
      bank_account: chosenBankAccount.value,
    });
    data.attachment = formData.get("file");

    dispatch(
      createHotelPaymentOrder(data, () => {
        setIsDisabled(false);
        toast.success("Your data was submitted successfully");
      }),
    );
  }

  return (
    <div className="w-full">
      {loading ? (
        <>
          {" "}
          <Loading /> <TopBarProgress />
        </>
      ) : hotelBankAccountsArr.length > 0 ? (
        <div className="w-[80%]  border-gray-300 border rounded-lg p-5 my-3 mx-auto">
          <div className="flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
            <p className="flex items-center gap-x-3  text-md">
              <FaWpforms className="w-5 h-5" />
              Bank Cash Deposit
            </p>
          </div>
          <form className="mt-2 w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <label className="flex text-gray-600 ">Amount</label>
              <input type="amount" className="w-full h-10 border rounded-lg bg-white" name="amount" required />

              <label className="flex text-gray-600 ">Currency</label>
              <Select
                className="w-full h-10 border rounded-lg bg-white"
                options={currencyListArr}
                value={chosenCurrency}
                onChange={(e) => setChosenCurrency(e)}
                required
              />

              <label className="flex text-gray-600 ">Payment Deadline</label>
              <input type="date" className="w-full h-10 border rounded-lg bg-white" name="payment_deadline" required />

              <label className="flex text-gray-600 ">Hotel Name</label>
              <Select
                className="w-full h-10 border rounded-lg bg-white"
                options={hotel ? [hotel] : []}
                value={hotel}
                disabled={true}
              />

              <label className="flex text-gray-600 ">Bank Name</label>
              <Select
                className="w-full h-10 border rounded-lg bg-white"
                options={hotelBankAccountsArr}
                value={chosenBankAccount}
                onChange={(e) => setChosenBankAccount(e)}
                required
              />

              <label className="flex text-gray-600 ">Proforma Invoice Attachment</label>
              <input type="file" className="w-full h-10 border rounded-lg bg-white" name="file" required />

              <label className="flex text-gray-600 ">Notes</label>
              <textarea name="notes" cols="30" rows="10" className="w-full h-20 border rounded-lg bg-white"></textarea>

              <button
                type="Submit"
                disabled={isDisabled}
                className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Submit
              </button>
            </div>
            {isDisabled ? (
              <>
                <TopBarProgress />
                <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                  <HashLoader size={30} color="white" />
                  <div className="text-white font-bold my-2">Submitting...</div>
                </div>
              </>
            ) : null}
          </form>
        </div>
      ) : (
        <div className="w-full mx-auto flex flex-col">
          <div className="font-bold mx-auto">
            There are no available Bank accounts for this hotel Please choose other method
          </div>
          <div className="mx-auto flex justify-around w-[60%]">
            <span
              className="text-[#002244] hover:border-b-2 cursor-pointer border-[#002244]"
              onClick={() =>
                navigate(`/dashboard/booking-list-single-id/${short_id}/${id}/payment-hotels/payment-link`)
              }
            >
              Payment Link
            </span>
            <span
              className="text-[#002244] hover:border-b-2 cursor-pointer border-[#002244]"
              onClick={() =>
                navigate(`/dashboard/booking-list-single-id/${short_id}/${id}/payment-hotels/cash-in-hotel`)
              }
            >
              Cash In Hotel
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
