import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import { useNavigate, useParams } from "react-router";
import { FaWpforms } from "react-icons/fa6";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
// import jwtDecode from 'jwt-decode';
import TopBarProgress from "react-topbar-progress-indicator";
import { CashBoxActionById, CashBoxActionUpdate } from "../../../redux/actions/addCashBoxAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { GrUpdate } from "react-icons/gr";
import { useFormik } from "formik";
import { cashBoxValidation } from "./cashBoxValidation";

const EditCashBoxList = () => {
  const [selectedCurrencies, setSelectedCurrencies] = useState();

  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCurrencyAction(token));
  }, []);
  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { cashBoxFlag } = useSelector((state) => state.addCashBoxReducer);
  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  const params = useParams();
  /////permission/////////
  const permissionData = {
    permission_codes: ["box.add_box"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  // Form State
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      balance: "",
      currency_type: "",
      location: "",
      name: "",
    },
    validationSchema: cashBoxValidation,
    onSubmit: submit,
  });
  useEffect(() => {
    if (params.id) {
      dispatch(
        CashBoxActionById(token, params.id, (result) => {
          if (result.status === 200) {
            setFieldValue("name", result.data.name);
            setFieldValue("balance", result.data.balance);
            setFieldValue("location", result.data.location);
            setFieldValue("currency_type", result.data.currency_type);

            setSelectedCurrencies([{ label: result.data.currency_type, value: result.data.currency_type }]);
          }
        }),
      );
    }
  }, []);

  function submit(data) {
    const newValues = {
      name: data.name,
      balance: data.balance,
      currency_type: data.currency_type,
      location: data.location,
    };
    if (params.id) {
      dispatch(
        CashBoxActionUpdate(token, params.id, newValues, (result) => {
          if (result.status === 200) {
            setSubmitting(false);
            lightToast.success("Cash Box List Updated successfully");
            navigate(-1);
          } else {
            setSubmitting(false);

            NotificationManager.error(result?.response?.data[0]?.message);
          }
        }),
      );
    }
  }

  /////permission/////////

  return (
    <div className="formPage-wrap ">
      <div className="flex flex-col items-center justify-start h-full w-full">
        {currencyList && permissionFlag ? (
          permission.map((item) => {
            if (item.name === "box.add_box" && item.value === true) {
              return (
                <div className=" w-full ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      {" "}
                      <FaWpforms className="w-5 h-5" />
                      Update Cash Box
                    </h2>
                  </div>
                  <form className="form-wrap w-full" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" text-center w-full text-gray-600   ">Box Name</label>
                      <input
                        placeholder="Box Name"
                        name="name"
                        type="text"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        // onChange={(e)=>setName(e.target.value)}
                        className={`w-full px-3 h-10  border rounded-lg bg-white ${
                          errors.name && touched.name && "border-red-900"
                        }`}
                      />
                      {errors.name && touched.name && (
                        <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                          {errors.name}
                        </div>
                      )}
                      <label className=" flex text-gray-600 ">Currency</label>
                      <Select
                        name="currency_type"
                        options={CurrencyType}
                        isSearchable
                        required
                        onChange={(e) => setFieldValue("currency_type", e.value)}
                        onBlur={handleBlur}
                        deafultValue={selectedCurrencies}
                        className={`w-full  h-10  rounded-lg  bg-white ${
                          errors.currency && touched.currency && "border border-red-900"
                        }`}
                        value={
                          values.currency_type
                            ? [{ value: values.currency_type, label: values.currency_type }]
                            : selectedCurrencies
                        }
                        placeholder="Currency"
                      />
                      {errors.currency_type && touched.currency_type && (
                        <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                          {errors.currency_type}
                        </div>
                      )}

                      <label className=" text-center w-full text-gray-600   ">Location</label>
                      <input
                        placeholder="Location"
                        name="location"
                        type="text"
                        value={values.location}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // onChange={(e)=>setName(e.target.value)}
                        className={`w-full px-3 h-10  border rounded-lg bg-white ${
                          errors.location && touched.location && "border-red-900"
                        }`}
                      />
                      {errors.location && touched.location && (
                        <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                          {errors.location}
                        </div>
                      )}

                      <button
                        type="Submit"
                        disabled={cashBoxFlag}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Update{" "}
                        {cashBoxFlag ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <GrUpdate className="w-3 h-3" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              );
            } else {
              return (
                <div className="w-full h-screen">
                  <NoPermission />
                </div>
              );
            }
          })
        ) : (
          <>
            <TopBarProgress /> <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default EditCashBoxList;
