import api_token from "../../api/UserApi";
import { GET_HOTEL_NAMES } from "../types/types";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function requestHotelNamesSuccessfull(data) {
  return {
    type: GET_HOTEL_NAMES,
    payload: data,
  };
}

export default function requestHotelNames(callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/hotels/api/v1/get-hotel-names/");
      dispatch(requestHotelNamesSuccessfull(response.data));
      callback(response);
    } catch (error) {}
  };
}
