import React, { useEffect, useState } from "react";
import ContractFormDynamicCardGrandChild from "../ContractFormDynamicCardGrandChild/ContractFormDynamicCardGrandChild";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import moment from "moment";
import { useDispatch } from "react-redux";
import { addDateValue, addNewDateRange, deleteDateRange } from "../../../redux/actions/contractValidity";
import { toast } from "react-hot-toast";
import { MdAddCircleOutline, MdDateRange } from "react-icons/md";
import { BiMinusCircle } from "react-icons/bi";
import AddNewPackageModal from "../../organisms/RoomPackages/AddNewPackageModal";

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export default function ContractFormDynamicComponentChild({
  validity_index,
  range,
  maxOccupancy,
  maxAdults,
  maxChildren,
  roomID,
  contractType,
  key,
  isAddingPackageAllowed,
}) {
  const dispatch = useDispatch();
  let [input, setInput] = useState([1]);
  // dateRange state is an array to keep track of the multiple dynamic date elements

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
    },
  ]);

  useEffect(() => {
    if (range) {
      let ranges = Array.from({ length: range.length }, () => 1);
      let dateRanges = range.map((range) => {
        if (!range.from_date || !range.to_date) {
          return { startDate: null, endDate: null };
        }
        return { startDate: moment(range.from_date), endDate: moment(range.to_date) };
      });

      setDateRange(dateRanges);
      setInput(ranges);
    }
  }, [range]);
  // focused input has to be an array to keep track of the inputs of dynamic dateRangePicker components
  const [focusedInputs, setFocusedInput] = useState([null]);
  // with every new dynamic date element created an object with null values must be pushed to the dateRange state
  // because the endDate and startDate props of dateRangePicker is set to dateRange[index].startDate & dateRange[index].endDate respectively
  // also the focusedInputs state must get a new null value pushed because  the focusedInput props is set to focusedInput={focusedInputs[index]}
  function addInput() {
    if (dateRange[dateRange.length - 1].endDate) {
      input.length < 500 && setInput([...input, 1]);
      setDateRange([...dateRange, { startDate: null, endDate: null }]);
      setFocusedInput([...focusedInputs, null]);

      dispatch(addNewDateRange(validity_index));
    } else {
      toast.error("Fill in the date before adding more inputs");
    }
  }

  //  with the removal of a dynamic element the objects added to the dateRange and focusedInputs will be removed
  function removeInput() {
    if (input.length > 1) {
      let newArr = input.slice();
      newArr.pop();
      setInput(newArr);
      const dateRangeCopy = dateRange.slice();
      dateRangeCopy.pop();
      setDateRange(dateRangeCopy);

      const focusedInputsCopy = focusedInputs.slice();
      focusedInputsCopy.pop();
      setFocusedInput(focusedInputsCopy);

      dispatch(deleteDateRange(validity_index));
    }
  }

  const handleFocusChange = (focusedInput, index) => {
    const focusedInputsCopy = [...focusedInputs];
    focusedInputsCopy[index] = focusedInput;
    setFocusedInput(focusedInputsCopy);
  };

  const handleDateChange = ({ startDate, endDate }, date_index) => {
    // checks if this is the first date it will put any date
    // otherwise the date must be larger than the preceding date endDate
    if (date_index === 0 || startDate > dateRange[date_index - 1].endDate) {
      let dateRangeCopy = [...dateRange];
      dateRangeCopy[date_index] = { startDate, endDate };
      setDateRange(dateRangeCopy);
      let start = new Date(startDate);
      let end = new Date(endDate);

      dispatch(addDateValue(validity_index, date_index, formatDate(start), formatDate(end)));
    } else {
      toast.error("You must choose a date after the previous date");
    }
  };

  return (
    <div className={`w-full flex flex-col   ${key}`}>
      {input.map((elem, index) => (
        <>
          <div key={index} className=" w-full border rounded-md">
            <div className="w-full flex items-center justify-start gap-x-5 p-5">
              <h2 className="text-gray-800 font-semibold">Room Validity</h2>
              <div className="flex  items-center justify-start gap-x-3 ">
                <DateRangePicker
                  required
                  startDate={dateRange[index].startDate}
                  startDateId={`startDate-${index}`}
                  endDate={dateRange[index].endDate}
                  endDateId={`endDate-${index}`}
                  onDatesChange={(dateObj) => handleDateChange(dateObj, index)}
                  focusedInput={focusedInputs[index]}
                  onFocusChange={(focusedInput) => handleFocusChange(focusedInput, index)}
                  minimumNights={0} // Allow same-day selection
                  displayFormat={"DD/MM/YYYY"}
                  className="w-full"
                  small
                  daySize={30}
                />
                {index > 0 && (
                  <div className=" text-red-800 font-semibold">
                    Select a date that is subsequent to the end date of the previous validity.
                  </div>
                )}
              </div>
              {contractType === "per_room" && isAddingPackageAllowed && (
                <AddNewPackageModal
                  roomData={{
                    id: roomID,
                    max_adults: maxAdults,
                    max_children: maxChildren,
                    max_occupancy: maxOccupancy,
                  }}
                />
              )}
            </div>

            <div className="w-full ">
              <ContractFormDynamicCardGrandChild
                maxOccupancy={maxOccupancy}
                maxAdults={maxAdults}
                maxChildren={maxChildren}
                validity_index={validity_index}
                date_index={index}
                room_types={range && range[index] ? range[index].validity_room_types || range[index].rooms_types : null}
                roomID={roomID}
                contractType={contractType}
                // children_room_types={range && range[index] ? range[index].validity_children_price : null}
              />
            </div>
          </div>
          <div className="w-full flex text-center justify-end gap-x-2 px-7 mt-3">
            <MdAddCircleOutline
              className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-green-800"
              onClick={addInput}
            />

            <MdDateRange className="text-gray-800 w-5 h-5" />
            <BiMinusCircle
              className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-red-800"
              onClick={removeInput}
            />
          </div>
        </>
      ))}
    </div>
  );
}
