import React, { useEffect, useState } from "react";
import "./CurrencyHistoryDetails.scss";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyHistoryRatesDetails } from "../../../redux/actions/currencyRates";

export default function CurrencyHistoryDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [detail] = useSelector((state) => state.CurrencyRatesHistoryDetails);

  useEffect(() => {
    dispatch(getCurrencyHistoryRatesDetails(id, () => setIsLoading(false)));
  }, []);
  return (
    <div className="flex flex-col currencyHistoryDetails">
      {isLoading ? (
        <img src="/images/loading-air.gif" alt="loading" style={{ marginLeft: "30vw", marginTop: "40vh" }} />
      ) : (
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm font-light">
                <thead className=" font-medium dark:border-neutral-500">
                  <tr className=" bg-[#002244] text-white">
                    <th scope="col" className="px-6 py-4">
                      From
                    </th>
                    <th scope="col" className="px-6 py-4">
                      To
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Rate
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detail.data && detail.id === Number(id) && (
                    <tr className="">
                      <td className="whitespace-nowrap px-6 py-4">{detail.data.from_currency}</td>
                      <td className="whitespace-nowrap px-6 py-4">{detail.data.to_currency}</td>
                      <td className="whitespace-nowrap px-6 py-4">{detail.data.rate}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
