import React, { useEffect, useState } from "react";
// import "./ContractList.scss";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  deleteDeletedContract,
  getDeletedContract,
  restoreDeletedContract,
} from "../../../redux/actions/deletedContractAction";

import { FaUndo } from "react-icons/fa";
import ListFilters from "../../../utils/ListFilters";
import { splitAndCapitalize } from "../../../utils/stringUtils";

const DeletedContract = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { page } = useParams();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [deletePopup, setDeletePopup] = useState("");
  const [deletecontractId, setDeleteContractId] = useState(null);
  const [restorcontractId, setRestorContractId] = useState(null);
  const [status, setStatus] = useState(null);
  const [contracts, setContracts] = useState();
  const [listPageSize, setListPageSize] = useState(20);

  const currentPage = Number(page) || 1;
  const totalPages = contracts && Math.ceil(contracts?.count / listPageSize);

  useEffect(() => {
    dispatch(
      getDeletedContract(Number(page), listPageSize, (result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setContracts(result.data);
        }
      }),
    );
  }, [page, location]);

  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("contract-grid-visibility");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility) });
    }
  }, []);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
    },
    {
      key: "hotelName",
      title: "HotelName",
      dataIndex: "hotelName",
    },
    {
      key: "name",
      title: "Contract Name",
      dataIndex: "name",
    },
    {
      key: "contractType",
      title: "Contract Type",
      dataIndex: "contractType",
    },
    {
      key: "offerType",
      title: "Offer Type",
      dataIndex: "offerType",
    },
    {
      key: "durationTime",
      title: "Duration Time",
      dataIndex: "durationTime",
    },

    {
      key: "createdBy",
      title: "Created By",
      dataIndex: "createdBy",
    },
    {
      key: "approvedBy",
      title: "Action by",
      dataIndex: "approvedBy",
    },
    {
      key: "created_date",
      title: "Created Date",
      dataIndex: "created_date",
    },
    {
      key: "approvedDate",
      title: "Action Date",
      dataIndex: "approvedDate",
    },
    {
      key: "actions",
      filterable: false,
      sortable: false,
      title: "Actions",
      dataIndex: "actions",
      render: (text, params) => {
        return (
          <div className="w-full flex items-center gap-x-1">
            {permission.map((item) => {
              if (item.name === "contracts_management.view_contract" && item.value === true) {
                return (
                  <Link to={`/dashboard/deleted-contract/${params.id}/details`}>
                    <AiOutlineEye
                      className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800"
                      // onClick={() => navigate(`/dashboard/contract-details/${params.id}`)}
                    />
                  </Link>
                );
              } else if (item.name === "contracts_management.change_contract" && item.value === true) {
                return (
                  <div className="flex items-center gap-x-1">
                    <Link to={`/dashboard/deleted-contract/${params.id}/edit`}>
                      {" "}
                      <FiEdit
                        className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
                        // onClick={() => navigate(`/dashboard/edit-contract/${params.id}`)}
                      />
                    </Link>
                    <FaUndo
                      className="w-4 h-4 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-sky-800"
                      onClick={() => {
                        setRestorContractId(params.id);
                        setDeletePopup(params.name);
                        setStatus("restore");
                      }}
                    />
                  </div>
                );
                // permissions_management.contract_hard_deleted;
              }
              // else if (item.name === "contracts_management.delete_contract" && item.value === true) {
              else if (item.name === "permissions_management.contract_hard_deleted" && item.value === true) {
                return (
                  <AiFillDelete
                    className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
                    onClick={() => {
                      setDeleteContractId(params.id);
                      setDeletePopup(params.name);
                    }}
                  />
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  const rows = contracts?.results?.map((contract) => {
    let time, hours, mins, secs;
    if (contract.created_time) {
      time = contract.created_time;
      hours = Math.floor(time / 3600);
      mins = Math.floor((time - hours * 3600) / 60);
      secs = time - mins * 60;
    }
    return {
      id: contract.id,
      status: contract.status,
      hotelName: contract.hotel.name,
      name: contract.name,
      contractType: splitAndCapitalize(contract.contract_type, "_"),
      offerType: contract.offer_type.name,
      durationTime: time
        ? `${hours.toString().padStart(2, 0)}:${mins.toString().padStart(2, 0)}:${secs.toString().padStart(2, 0)}`
        : "----",
      createdBy: contract.created_by ? contract.created_by.username : "----",
      approvedBy: contract.user_action ? contract.user_action.username : "----",
      created_date: contract.created_date.split("T")[0],
      approvedDate: contract.action_date ? contract.action_date.split("T")[0] : "----",
    };
  });

  function handlePageChange(params, size) {
    setListPageSize(size);
    navigate(`/dashboard/deleted-contract/${params}`);
    setIsLoading(true);
  }

  function comparator([params]) {
    if (params) {
      if (params.sort === "desc") {
        navigate(`/dashboard/deleted-contract/${page}}`);
      } else {
        navigate(`/dashboard/deleted-contract/${page}`);
      }
    } else {
      navigate(-1);
    }
  }

  function handleDelete() {
    setIsLoading(true);
    dispatch(
      deleteDeletedContract(deletecontractId, (result) => {
        if (result.status === 204) {
          dispatch(
            getDeletedContract(Number(page), listPageSize, (result) => {
              if (result.status === 200) {
                setIsLoading(false);
                setContracts(result.data);
              }
            }),
          );
        }
      }),
    );
    setDeletePopup("");
  }

  ////// handle restore //////////

  const handleRestore = () => {
    setIsLoading(true);
    dispatch(
      restoreDeletedContract(restorcontractId, (result) => {
        if (result.status === 200) {
          dispatch(
            getDeletedContract(Number(page), listPageSize, (result) => {
              if (result.status === 200) {
                setIsLoading(false);
                /////// put restore id  and status null because handle function in delete popup condintion function /////////
                setRestorContractId(null);
                setStatus(null);
                setContracts(result.data);
              }
            }),
          );
        }
      }),
    );
    setDeletePopup("");
  };

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "contracts_management.view_contract",
      "contracts_management.change_contract",
      "contracts_management.delete_contract",
      "permissions_management.contract_hard_deleted",
    ],
  };

  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        <div className="w-full ">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-screen">
              <Loading /> <TopBarProgress />
            </div>
          ) : (
            <div className="w-full ">
              <ListFilters
                columns={columns}
                rows={rows}
                title="Deleted contracts List"
                handlePagination={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
                listPageSize={listPageSize}
              />
              {/* <DataGrid
                // className="data-grid"
                columns={columns}
                rowCount={contracts.count}
                rows={rows}
                slots={{
                  Toolbar: GridToolbar,
                }}
                paginationModel={{ page: Number(page - 1), pageSize: 20 }}
                onPaginationModelChange={handlePageChange}
                paginationMode="server"
                onSortModelChange={comparator}
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={(visibility) => {
                  setColumnVisibility(visibility);
                  localStorage.setItem("contract-grid-visibility", JSON.stringify(visibility));
                }}
              /> */}
            </div>
          )}
          {deletePopup && (
            <DeletePopUp
              name={deletePopup}
              status={status}
              Func={() => (restorcontractId ? handleRestore() : handleDelete())}
              hideFunc={() => {
                setDeletePopup("");
              }}
            />
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default DeletedContract;
