import React from "react";
import { Modal } from "flowbite-react";
import { useState } from "react";

function NoteDetailsModal({ note }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
      >
        see more
      </button>
      <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>{note.title}</Modal.Header>
        <Modal.Body>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">{note.content}</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NoteDetailsModal;
