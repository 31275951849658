import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const EmailTemplate = ({ sethtmlDataEmail, nameClientEmail }) => {
  const emailtempRef = useRef(null);
  const { cardData } = useSelector((state) => state.cardReducer);

  const [t] = useTranslation();

  useEffect(() => {
    if (emailtempRef.current) {
      sethtmlDataEmail(emailtempRef.current.outerHTML);
    }
  }, [emailtempRef, sethtmlDataEmail, nameClientEmail]);

  return (
    <div
      ref={emailtempRef}
      className=" hidden"
      style={{
        direction: "rtl",
        fontFamily: "Noto Sans, sans-serif",
        backgroundColor: "#f5f5f5",
        margin: 0,
        padding: 0,
        borderRadius: "10px",
      }}
    >
      <section>
        <p
          style={{
            color: "rgb(0, 34, 68)",
            fontWeight: "bold",
            fontSize: "15px",
            padding: "20px",
            fontFamily: "Noto Kufi Arabic, sans-serif",
            fontWeight: 700,
          }}
        >
          عناية الاستاذ / {nameClientEmail} ,
        </p>

        <p
          style={{
            color: "rgb(0, 34, 68)",
            fontWeight: "normal",
            fontSize: "15px",
            padding: "15px",
          }}
        >
          نتمنى أن تكون بخير. نحن نود أن نقدم لكم باقة متنوعة ومميزة من عروض الفنادق المتاحة لدينا، تناسب احتياجاتكم
          الخاصة وتوقعاتكم الرفيعة. تضمن عروضنا أفضل الخدمات والأسعار التنافسية، مع التركيز على تقديم تجربة إقامة
          استثنائية.
        </p>

        <p
          style={{
            color: "rgb(0, 34, 68)",
            fontWeight: "normal",
            fontSize: "15px",
            padding: "15px",
          }}
        >
          تجدون هنا نماذج للفنادق لبعض العروض المميزة التي نقدمها، ونأمل أن تنال إعجابكم. نتطلع إلى فرصة لمناقشة تفاصيل
          أكثر ولتحديد ما يناسبكم بشكل أفضل.
        </p>

        <div style={{ textAlign: "center" }}>
          <div>
            {cardData?.map((item, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  overFlow: "hidden",
                  margin: " 10px auto 25px 60px ",
                  padding: " 4px",
                  border: " 2px solid #F3F5F6",
                  // float: "left",
                  boxSizing: "border-box",
                  width: "330px",
                  textAlign: "right", // Ensures text inside the card is aligned left
                  display: "inline-block", // Ensures the card behaves as a block element within the wrapper
                }}
              >
                <div style={{ position: "relative", width: "100%" }}>
                  <img
                    src={item?.roomImage}
                    alt="Image"
                    style={{ width: "100%", height: "200px", borderRadius: "10px" }}
                  />
                </div>
                <div style={{ padding: "20px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          textAlign: "center",
                          backgroundColor: "#002244",
                          width: "11rem",
                          borderRadius: "1rem",
                          paddingTop: "0.25rem",
                          paddingBottom: "0.25rem",
                          color: "#ffffff",
                          fontWeight: "500",
                          fontSize: "0.75rem",
                          textTransform: "capitalize",
                          margin: "auto",
                        }}
                      >
                        {t(item.mealType)}
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <strong className=""> {item.hotel_AR}</strong>
                      </div>
                      <div style={{ display: "flex", gap: "20px", alignItems: "center", height: "30px" }}>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px",
                            fontWeight: "bolder",
                            fontSize: "20px",
                            fontFamily: "Aref Ruqaa, serif",
                            fontWeight: 400,
                          }}
                        >
                          <span> {Intl.NumberFormat("ar-EG").format(item?.number_of_persons)} 👤 </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px",
                            fontWeight: "bolder",
                            fontSize: "17px",
                            fontFamily: "Aref Ruqaa, serif",
                            fontWeight: 400,
                          }}
                        >
                          <span> {Intl.NumberFormat("ar-EG").format(item?.children_num)} 👶🏻 </span>
                        </span>

                        <span
                          role="img"
                          aria-label="person"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bolder",
                            fontSize: "17px",
                          }}
                        >
                          <span> {Intl.NumberFormat("ar-EG").format(item?.nights_whats_app)} &#127769;</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                      marginBottom: "5px",
                      fontWeight: "bolder",
                      fontSize: "17px",
                      fontFamily: "Aref Ruqaa, serif",
                      fontWeight: 400,
                    }}
                  >
                    {item?.children_ages?.length > 0 && (
                      <>
                        <strong className=""> 👶🏻 أعمار الأطفال :</strong>
                        {item?.children_ages.map((child) => (
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "5px",
                              fontWeight: "bolder",
                              fontSize: "17px",
                              fontFamily: "Aref Ruqaa, serif",
                              fontWeight: 400,
                            }}
                          >
                            ({new Intl.NumberFormat("ar-EG").format(child)} سنوات){" "}
                          </span>
                        ))}
                      </>
                    )}
                  </span>

                  <div>
                    <span
                      style={{
                        display: "flex",
                        color: "rgb(22, 101, 52)",
                        fontWeight: "bolder",
                        fontSize: "18px",
                        letterSpacing: "1px",
                      }}
                    >
                      {moment(item?.formCheckIn_whats_app, "YYYY-MM-DD").format("DD-MM-YYYY")} &#8592;{" "}
                      {moment(item?.formCheckOut_whats_app, "YYYY-MM-DD").format("DD-MM-YYYY")}
                    </span>
                    <span style={{ color: "rgb(0, 34, 68)", fontWeight: "700" }}>{item?.selectedroom} </span>
                  </div>
                  <div
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "10px",
                      color: "rgb(22, 101, 52)",
                      paddingTop: "10px",
                    }}
                  >
                    {new Intl.NumberFormat("ar-EG").format(item.price)} {t(item.currency_type)}
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      backgroundColor: "#f5f5f5",
                      borderTop: "1px solid #ddd",
                    }}
                  >
                    {/* <p style={{ color: "rgb(220 38 38)" }}>{item.contractOfferType}</p> */}
                    <strong>شامل جميع الضرائب والرسوم</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <table
          align="center"
          style={{
            textAlign: "center",
            verticalAlign: "top",
            width: "100%",
            backgroundColor: "rgb(0, 34, 68)",
            padding: "10px",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "400px",
                  verticalAlign: "top",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  margin: 0,
                  padding: 0,
                  padding: "15px",
                }}
              >
                <img
                  style={{
                    width: "150px",
                    maxWidth: "180px",
                    textAlign: "center",
                    color: "#ffffff",
                    margin: "auto",
                    paddingBottom: "10px",
                  }}
                  alt="Logo"
                  src="https://dev.testotelhero.com/media/avatars/logo2bg.png"
                  align="center"
                />

                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "24px",
                    fontFamily: "'Helvetica', Arial, sans-serif",
                    fontWeight: 600,
                    textDecoration: "none",
                    color: "#ffffff",
                    paddingBottom: "10px",
                  }}
                >
                  سعداء بتواصلكم معنا
                </p>

                {/* <p
                  style={{
                    marginBottom: 0,
                    fontSize: "13px",
                    lineHeight: "24px",
                    fontFamily: "'Helvetica', Arial, sans-serif",
                    fontWeight: 400,
                    textDecoration: "none",
                    color: "#ffffff",
                    paddingBottom: "10px",
                  }}
                >
                  <a
                    target="_blank"
                    style={{ textDecoration: "underline", color: "#ffffff" }}
                    href="https://otelhero.com/"
                  >
                    otelhero.com
                  </a>
                </p> */}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default EmailTemplate;
