import React from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { PiHandshakeLight } from "react-icons/pi";
import { VscAdd } from "react-icons/vsc";
import { IoIosList } from "react-icons/io";
import { MdWorkOutline } from "react-icons/md";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const MeetAssistService = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full border-gray-300 border rounded-lg bg-white">
      <div className="w-[98%] flex justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 mt-3">
        <p className="flex items-center gap-x-3">
          <RiKeyboardLine className="w-5 h-5" />
          Airport Control
        </p>
      </div>

      <div className="grid grid-cols-2  gap-y-5 mt-12">
        {/* <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
          <Link
            to={"./add-representative-service"}
            className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
          >
            <div className="w-full flex justify-center items-center ">
              <div className="relative flex flex-col">
                <PiHandshakeLight className="w-12 h-12  " />
                <VscAdd className="absolute top-10 left-10 w-4 h-4" />
              </div>
            </div>
          </Link>
          <p className="text-lg font-semibold">Add Representative Service</p>
        </div>

        <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
          <Link
            to={"./all-representative-service"}
            className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
          >
            <div className="w-full flex justify-center items-center ">
              <IoIosList className="w-12 h-12   " />
            </div>
          </Link>
          <p className="text-lg font-semibold">Representative Service List</p>
        </div> */}

        <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
          <Link className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
            <div className="w-full flex justify-center items-center ">
              <div className="relative flex flex-col">
                <MdWorkOutline className="w-12 h-12  " />
                <VscAdd className="absolute top-10 left-11 w-4 h-4" />
              </div>
            </div>
          </Link>
          <p className="text-lg font-semibold">Add Work-Order</p>
        </div>

        <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
          <Link
            // onClick={() => navigate("./work-order-list")}
            className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
          >
            <div className="w-full flex justify-center items-center ">
              <HiOutlineClipboardDocumentList className="w-12 h-12   " />
            </div>
          </Link>
          <p className="text-lg font-semibold">Work-Order List</p>
        </div>
      </div>
    </div>
  );
};

export default MeetAssistService;
