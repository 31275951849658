import React, { useEffect, useState } from "react";
import useData from "../../../customHooks/useData";
import Loading from "../Loading/Loading";
import { editData, getData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import TopBarProgress from "react-topbar-progress-indicator";
import { FiEdit3 } from "react-icons/fi";

function reshapeText(text) {
  let firstReshape = text?.replace(/_/g, " ");
  let secondReshape = firstReshape[0]?.toUpperCase() + firstReshape?.slice(1);
  return secondReshape;
}

function PermissionsGroup({ group, title, chosenPermissions, handleCheck, setChosenPermissions }) {
  function selectAll(e) {
    e.preventDefault();
    let allIds = new Set();

    Object.entries(group)?.map(([arrayName, arrayData], index) =>
      arrayData?.forEach((perm) => {
        if (!perm.tablename) {
          if (!chosenPermissions?.includes(perm.id)) {
            allIds.add(perm.id);
          }
          setChosenPermissions([...chosenPermissions, ...allIds]);
        }
      }),
    );
  }

  const selectRow = (arrayData, e) => {
    e.preventDefault();

    let allIds = new Set();

    arrayData?.forEach((perm, index) => {
      if (!perm.tablename) {
        if (!chosenPermissions?.includes(perm.id)) {
          allIds.add(perm.id);
        }
        setChosenPermissions([...chosenPermissions, ...allIds]);
      }
    });
  };

  return (
    <div className="mt-5 formPage-wrap">
      <div className=" w-full flex justify-center mb-5">
        <div className="w-[80%]  flex  gap-5  items-center justify-between ">
          <div className=" font-bold md:text-lg text-base capitalize">{reshapeText(title)} </div>
          <button
            className="px-5 py-2  rounded-xl bg-sky-900 text-white font-semibold text-sm transition duration-300 ease-in-out 
          hover:bg-sky-700"
            onClick={(e) => selectAll(e)}
          >
            Select All
          </button>
        </div>
      </div>

      {title !== "permissions_management" ? (
        <div class="flex justify-center w-full mb-20">
          <div class="overflow-x-auto w-[80%]  shadow-lg">
            <table class="w-full  whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden">
              <thead class="bg-gray-900">
                <tr class="text-white text-left">
                  <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> Name</th>
                  <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> Add</th>
                  <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> Edit</th>
                  <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> delete</th>
                  <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> view</th>
                  <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> select row</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                {Object.entries(group)?.map(([arrayName, arrayData], index) => (
                  <tr>
                    {arrayData?.map((item, i) => (
                      <>
                        {i === 0 ? (
                          <td class="px-5 py-4 w-[50%] capitalize "> {item.tablename} </td>
                        ) : (
                          <td class="px-4 py-4 text-start">
                            <div className=" w-30 text-start  relative  ">
                              <input
                                type="checkbox"
                                name={item.name}
                                onChange={(e) => handleCheck(e, item.id)}
                                checked={chosenPermissions?.includes(item.id) || false}
                                className="mr-2 w-full "
                              />
                            </div>
                          </td>
                        )}
                      </>
                    ))}
                    <td class="px-2 py-2  text-center">
                      <button
                        className="px-2 py-2  rounded-lg bg-green-700 text-white font-semibold text-sm transition duration-300 ease-in-out 
                            hover:bg-green-600"
                        onClick={(e) => selectRow(arrayData, e)}
                      >
                        Select row
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        Object.entries(group)?.map(([arrayName, arrayData], index) =>
          arrayName !== "searchpermission" ? (
            <div class="flex justify-center w-full mb-20">
              <div class="overflow-x-auto w-[80%]  shadow-lg">
                <table class="w-full  whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden">
                  <thead class=" bg-cyan-800">
                    <tr class="text-white text-left">
                      <th class="font-semibold text-sm capitalize px-6 py-4 text-center"> Global Permission</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {Object.entries(group)?.map(
                      ([arrayName, arrayData], index) =>
                        arrayName !== "searchpermission" && (
                          <React.Fragment key={index}>
                            <tr className="">
                              <td className="px-5 py-2 w-[100%] flex justify-between capitalize font-bold">
                                {arrayData[0]?.tablename}
                                <button
                                  className="px-2 py-2 rounded-lg bg-green-700 text-white font-semibold text-sm transition duration-300 ease-in-out hover:bg-green-600"
                                  onClick={(e) => selectRow(arrayData, e)}
                                >
                                  Select All
                                </button>
                              </td>
                            </tr>
                            <div className="w-full grid xl:grid-cols-2 lg:grid-cols-1 grid-cols-1">
                              {arrayData.map(
                                (item, i) =>
                                  i !== 0 && (
                                    <tr key={item.id}>
                                      <td className="grid xl:grid-cols-2 lg:grid-cols-1 grid-cols-1 w-full px-4 py-2">
                                        <div className="w-full flex gap-2 items-center">
                                          <input
                                            type="checkbox"
                                            name={item.name}
                                            onChange={(e) => handleCheck(e, item.id)}
                                            checked={chosenPermissions?.includes(item.id) || false}
                                            className=" "
                                          />
                                          <p className="capitalize">{item.name}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  ),
                              )}
                            </div>
                          </React.Fragment>
                        ),
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div class="flex justify-center w-full mb-10">
              <div class="overflow-x-auto w-[80%]  shadow-lg">
                <table class="w-full  whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden">
                  <thead class="bg-cyan-800">
                    <tr class="text-white text-left">
                      <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> Name</th>
                      <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> Add</th>
                      <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> Edit</th>
                      <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> delete</th>
                      <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> view</th>
                      <th class="font-semibold text-sm uppercase px-6 py-4 text-center"> select row</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    {Object.entries(group)?.map(
                      ([arrayName, arrayData], index) =>
                        arrayName === "searchpermission" && (
                          <tr>
                            {arrayData?.map((item, i) => (
                              <>
                                {i === 0 ? (
                                  <td class="px-5 py-4 w-[50%] capitalize "> {item.tablename} </td>
                                ) : (
                                  <td class="px-4 py-4 text-start">
                                    <div className=" w-30 text-start  relative  ">
                                      <input
                                        type="checkbox"
                                        name={item.name}
                                        onChange={(e) => handleCheck(e, item.id)}
                                        checked={chosenPermissions?.includes(item.id) || false}
                                        className="mr-2 w-full "
                                      />
                                    </div>
                                  </td>
                                )}
                              </>
                            ))}
                            <td class="px-2 py-2  text-center">
                              <button
                                className="px-2 py-2  rounded-lg bg-green-700 text-white font-semibold text-sm transition duration-300 ease-in-out 
                              hover:bg-green-600"
                                onClick={(e) => selectRow(arrayData, e)}
                              >
                                Select row
                              </button>
                            </td>
                          </tr>
                        ),
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ),
        )
      )}
    </div>
  );
}

export default function EditGroupPermissions() {
  const dispatch = useDispatch();
  const [chosenGroup, setChosenGroup] = useState(null);
  const [showPermissions, setShowPermissions] = useState(false);
  const [loadGroup, setLoadGroup] = useState(false);
  const [sorted, setSorted] = useState(false);
  const groups = useData("permissions/api/v1/user-groups/");
  const models = useData("/permissions/api/v1/permissions/models");
  // const permissions = useData("permissions/api/v1/permissions/");

  useEffect(() => {
    if (models) {
      // Exclude permissions_management from destructuring
      const { permissions_management, ...rest } = models;

      // Convert the rest of the properties to an array of key-value pairs
      const keyValuePairs = Object.entries(rest);

      // Add permissions_management key-value pair at the end of the array
      keyValuePairs?.push(["permissions_management", permissions_management]);

      setSorted(keyValuePairs);
    }
  }, [models]);

  const [chosenPermissions, setChosenPermissions] = useState([]);

  function handleCheck(e, id) {
    if (e.target.checked) {
      setChosenPermissions([...chosenPermissions, id]);
    } else {
      setChosenPermissions(chosenPermissions?.filter((i) => i !== id));
    }
  }

  const groupsArr = groups ? groups?.map((group) => ({ label: group.name, value: group.id })) : [];

  function getGroupPermissions(groupId) {
    setLoadGroup(true);
    dispatch(
      getData(`permissions/api/v1/user-groups/${groupId}/`, (resp) => {
        setChosenPermissions(resp.data.permissions);
        setShowPermissions(true);
        setLoadGroup(false);
      }),
    );
  }

  function handleSubmit(e) {
    setLoadGroup(true);
    e.preventDefault();
    const data = {
      permissions: chosenPermissions,
    };
    dispatch(
      editData(
        `permissions/api/v1/user-groups/${chosenGroup.value}/`,
        data,
        () => {
          toast.success("Your Group was Edited Successfully");
          setLoadGroup(false);
        },
        (error) => {
          toast.error(`${error || "Your Group was not Edited"}  `);
          setLoadGroup(false);
        },
      ),
    );
  }

  return (
    <div className="formPage-wrap w-full min-h-[100vh]  ">
      {(!groups || !models || !sorted) && <Loading />}
      {groups && models && sorted && (
        <form onSubmit={handleSubmit} className="py-5">
          <div className="flex justify-center">
            <h2 className="header-h2">Edit Group Permissions</h2>
          </div>

          <div className="w-full flex mx-auto border mt-6 !max-w-2xl form-wrap items-center md:flex-row flex-col">
            <label htmlFor="group" className="sm:flex-[1] w-full text-sky-950 text-base font-medium">
              Choose Group:
            </label>
            <Select
              className="sm:flex-[3] w-full"
              options={groupsArr}
              id="group"
              isSearchable
              onChange={(e) => {
                setChosenGroup(e);
                getGroupPermissions(e.value);
              }}
            />
          </div>

          {showPermissions && loadGroup === false ? (
            <>
              <div className=" mt-10">
                {models !== null &&
                  sorted &&
                  sorted?.map(([nameArray, array]) => {
                    if (
                      nameArray === "django_celery_beat" ||
                      nameArray === "django_celery_results" ||
                      nameArray === "cities_light" ||
                      nameArray === "contenttypes" ||
                      nameArray === "sessions" ||
                      nameArray === "admin" ||
                      nameArray === "token_blacklist"
                    ) {
                      return;
                    }
                    return (
                      <PermissionsGroup
                        group={array}
                        title={nameArray}
                        chosenPermissions={chosenPermissions}
                        setChosenPermissions={setChosenPermissions}
                        handleCheck={handleCheck}
                      />
                    );
                  })}
              </div>
              <div className=" flex justify-center w-full mb-10">
                <button type="submit" className="updateButton " disabled={loadGroup}>
                  Edit Group
                  <FiEdit3 />
                </button>
              </div>
            </>
          ) : (
            loadGroup === true && (
              <div className="w-full flex items-center justify-center ">
                <TopBarProgress /> <Loading />
              </div>
            )
          )}
        </form>
      )}
    </div>
  );
}
