import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import html2canvas from "html2canvas";
import { main_URL } from "../../../urls";
import QRCode from "qrcode";
import Loading from "../../organisms/Loading/Loading";
import Errorfetch from "../../organisms/Errorfetch/Errorfetch";

const DetailsRow = ({ title, value, isTitleHasWidth = true }) => {
  return (
    <div
      style={{
        fontSize: "20px",
        display: "flex",
        alignItems: "center",
        gap: "4px",
        direction: "rtl",
      }}
    >
      <div className="flex gap-1">
        {title && <p className={` ${isTitleHasWidth ? "w-28" : ""}`}>{`${title}`}</p>}
        {title && <span className="ml-3">:</span>}
      </div>
      <p
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: value ? "-30px" : "",
          flexGrow: 1,
        }}
      >
        {value && (
          <span
            style={{
              color: "#000",
            }}
          >
            {value}
          </span>
        )}
        <span
          style={{
            marginTop: value ? "-4px" : "",
            borderBottomWidth: "2px",
            borderStyle: "dotted",
            borderColor: "#465695",
            width: "100%",
            height: "4px",
          }}
        />
      </p>
    </div>
  );
};

const WorkOrderHtml = () => {
  const { id } = useParams();
  const location = useLocation();
  const htmlContent = useRef(null);

  const {
    data: meetData,
    error: meetError,
    isLoading: isLoadingMeetData,
  } = useQuery({
    queryKey: [`meet-and-assist`, id],
    queryFn: () => fetchDataQuery(`/airport-services/api/v1/meet-and-assist-services/${id}/`),
  });

  const [path, setPath] = useState("");
  const [qrcode, setQrcode] = useState("");
  const url = `${main_URL}/workorder/${id}`;

  useEffect(() => {
    if (location.pathname && main_URL) {
      QRCode.toDataURL(url)
        .then((url) => {
          setQrcode(url);
        })
        .catch((err) => {});
    }
  }, [location.pathname, url]);

  useLayoutEffect(() => {
    if (htmlContent.current) {
      const convertToPng = async () => {
        if (qrcode && htmlContent.current && meetData) {
          html2canvas(htmlContent.current, {
            allowTaint: true,
            useCORS: true,
            scrollX: 0,
            scrollY: 0,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight,
          }).then((canvas) => {
            const image = canvas.toDataURL("image/png");
            setPath(image);
          });
        }
      };

      if (meetData) {
        convertToPng();
      }
    }
  }, [qrcode, meetData]);

  if (isLoadingMeetData || !meetData || !qrcode) {
    return <Loading />;
  }

  if (meetError) {
    return <Errorfetch Error={`Error Fetching voucher`} />;
  }

  const {
    meetDataId,
    passengersName,
    numberOfPax,
    nationality,
    flightNumber,
    timeOfArrival,
    serviceType,
    representativeAgentDetails,
    representativeAttendanceTime,
  } = {
    meetDataId: meetData?.id,
    passengersName: meetData?.passengers_name,
    numberOfPax: Number(meetData?.number_of_pax) + 1,
    flightNumber: meetData?.flight_number,
    nationality: meetData?.nationality,
    timeOfArrival: `${meetData?.time_of_arrival?.slice(11, 19)} / ${meetData?.time_of_arrival?.slice(0, 10)}`,
    serviceType: meetData?.service_type,
    representativeAgentDetails: meetData?.representative_name,
    representativeAttendanceTime: `${meetData?.representative_attendance_time ? meetData?.representative_attendance_time?.slice(11, 19) : ""} / ${meetData?.representative_attendance_time ? meetData?.representative_attendance_time?.slice(0, 10) : ""}`,
  };

  return (
    <div className={" w-full h-[100dvh] flex "}>
      {!path ? (
        <div className="w-full relative">
          <div className="bg-white w-full absolute top-0 left-0 bottom-0 right-0 z-10"></div>
          <div
            key={"htmlContent"}
            ref={htmlContent}
            className="w-[800px] h-[578px] p-1 text-2xl font-medium mx-auto bg-white overflow-scroll text-black font-beinAr"
          >
            <div className="relative w-full h-full p-8 ">
              <div className="flex justify-between items-center">
                <img
                  src={"/images/nugget-tours-black-arabic-logo.png"}
                  alt="Nugget Tours Logo"
                  className="w-[150px] h-[105px] mr-14"
                />
                <div className="flex flex-col gap-6 items-center">
                  <h3 className="text-[#DC2626]">{meetDataId}</h3>
                  <div className="w-[250px] h-[60px] border mr-9 border-black rounded-xl flex justify-center items-center">
                    <p>امر تشغيل استقبال وتوديع</p>
                  </div>
                </div>
                <div className={"flex gap-1 text-lg font-normal"}>
                  <img src={"/images/phone-icon.png"} className="w-6 h-6  inline-block" alt="Call us" />
                  <div className="flex gap-1 text-base font-normal">
                    <span>01090556255</span>
                    <span>-</span>
                    <span>01005373070</span>
                  </div>
                </div>
              </div>
              <div className="mt-8 flex gap-3 flex-col ">
                <DetailsRow title={"اسم العميل"} value={passengersName?.at(0)} />
                <div className="flex gap-4 ">
                  <div className="flex-1">
                    <DetailsRow title={"عدد الافراد"} isTitleHasWidth={false} value={numberOfPax} />
                  </div>
                  <div className="flex-1">
                    <DetailsRow title={"الجنسية"} value={nationality?.at(0)} />
                  </div>
                </div>
                <DetailsRow title={"رقم الرحلة"} value={flightNumber} />
                <div className="flex gap-4 ">
                  {serviceType === "Departure" && (
                    <div className="basis-[53%]">
                      <DetailsRow
                        title={"ميعاد الحضور في المطار"}
                        isTitleHasWidth={false}
                        value={meetData?.representative_attendance_time ? representativeAttendanceTime : ""}
                      />
                    </div>
                  )}
                  <div className="flex-1">
                    <DetailsRow title={"ميعاد الرحلة"} value={timeOfArrival} />
                  </div>
                </div>
                {serviceType === "Arrival" && (
                  <DetailsRow title={"بيان التشغيل"} value={"استقبال من المطار وعمل اللازم"} />
                )}

                {serviceType === "Departure" && (
                  <DetailsRow title={"بيان التشغيل"} value={"تسفير من المطار وعمل اللازم"} />
                )}
                <DetailsRow title={" "} value={`رقم تصريح المندوب ${representativeAgentDetails?.licenses_id_number}`} />
              </div>

              <div className="flex gap-6 mt-2 justify-around">
                <div className="flex flex-col gap-7 justify-center items-center">
                  <h4>مدير الحركة</h4>
                  <DetailsRow value={"محمد محمود طايع محمد"} />
                </div>
                <div className="flex flex-col gap-7 justify-center items-center">
                  <h4>اسم المندوب</h4>
                  <DetailsRow value={representativeAgentDetails?.name} />
                </div>
                <img src={qrcode} alt={url} className={"w-[85px] h-[85px]"} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div key={"image"} className="flex justify-center items-center w-full">
          <img src={path} alt="امر تشغيل استقبال وتوديع" />
        </div>
      )}
    </div>
  );
};

export default WorkOrderHtml;
