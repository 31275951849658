import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { IoBedOutline, IoCloudyNightOutline, IoFastFoodOutline, IoPersonAdd, IoPersonRemove } from "react-icons/io5";
import { MdGTranslate, MdOutlineAccessTime, MdOutlineDone, MdOutlineWhatsapp } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { TiCancel } from "react-icons/ti";
import { GoPerson } from "react-icons/go";
import { FaArchive, FaRegCalendarAlt } from "react-icons/fa";
import { AiFillFileAdd, AiOutlineMail, AiOutlinePhone, AiOutlinePrinter } from "react-icons/ai";
import { CgExtensionAdd, CgNotes } from "react-icons/cg";
import { TbBuildingEstate, TbListDetails } from "react-icons/tb";
import { LiaWpforms } from "react-icons/lia";
import { BsChatSquareText } from "react-icons/bs";
import { CiSquareQuestion } from "react-icons/ci";
import { GiConfirmed, GiHotMeal, GiPriceTag } from "react-icons/gi";
import { PiDatabase } from "react-icons/pi";
import ReactToPrint from "react-to-print";
import { FiEdit } from "react-icons/fi";
import { LuSend } from "react-icons/lu";
import { toast } from "react-hot-toast";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./BookingListTableSingle.css";
import Select from "react-select";

import {
  cancelUpdateConvertButton,
  getBookingStatusAction,
  getBookingTypeAction,
  getPaymentListAction,
  getSelectedBookedRoomSingleAction,
  operationAction,
  updateAction,
  updateOperationAction,
} from "../../../redux/actions/bookingListAction";
import Payments from "../Payments/Payments";
import lightToast from "light-toast";
import { differenceInMilliseconds } from "date-fns";
// import jwtDecode from "jwt-decode";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import TopBarProgress from "react-topbar-progress-indicator";
import { getSelectedTransactionAction } from "../../../redux/actions/allTransactionsAction";
import { cancelBooking, confirmCancelBooking, getCancelationReasons } from "../../../redux/actions/cancellation";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useReloadPage } from "../../../customHooks/useReloadPage";
import { archiveBooking } from "../../../redux/actions/archiveAction";
import { translateTextToArabic, translateTextToEnglish } from "../../../redux/actions/translationActions";
import { Button, Modal } from "flowbite-react";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useMutation, useQuery } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";

const BookingListTableSingleId = () => {
  const reloadPage = useReloadPage();
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  const { operationData } = useSelector((state) => state.bookingListReducer);
  const [checkId, setCheckId] = useState(false);
  const [checkOption, setCheckOption] = useState(false);
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [optionDate, setOptionDate] = useState(null);
  const [optionTime, setOptionTime] = useState(null);
  const [mustOptionDate, setMustOptionDate] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [currentDateShow, setCurrentDateShow] = useState("");
  const [currentTimeShow, setCurrentTimeShow] = useState("");
  const [responseTime, setResponseTime] = useState("");
  const [responseTimeShow, setResponseTimeShow] = useState("");
  const [paymentType, setPaymentType] = useState(selectedSingleRoom?.payment_status || "voucher");
  const [btnCheck, setBtnCheck] = useState(false);
  const [paymentFlag, setPaymentFlag] = useState(false);
  const [notes, setNotes] = useState("");
  const [opId, setOpId] = useState(null);
  const format = "HH:mm";
  //
  useEffect(() => {
    if (selectedSingleRoom?.payment_status) {
      setPaymentType(selectedSingleRoom.payment_status);
    }
  }, [selectedSingleRoom]);
  const print = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const location = useLocation();
  const [confirm, setConfirm] = useState(false);
  const [bookingLoad, setBookingLoad] = useState(false);

  const [nameToggleAr, setNameToggleAr] = useState(false);
  const [nameToggleEn, setNameToggleEn] = useState(false);
  const [whatsToggle, setWhatsToggle] = useState(false);
  const [phoneToggle, setPhoneToggle] = useState(false);
  const [clientTextToggle, setClientTextToggle] = useState(false);
  const [clientTagToggle, setClientTagToggle] = useState(false);
  const [emailToggle, setEmailToggle] = useState(false);
  const [arrivalFromToggle, setArrivalFromToggle] = useState(false);
  const [arrivalToToggle, setArrivalToToggle] = useState(false);
  const [editNameAr, setEditNameAr] = useState();
  const [editNameEn, setEditNameEn] = useState();
  const [Whats, setWhats] = useState();
  const [phone, setPhone] = useState();
  const [clientText, setClientText] = useState();
  const [clientTag, setClientTag] = useState();
  const [ClientTagOptions, setClientTagOptions] = useState();
  const [email, setEmail] = useState();
  const [arrivalFrom, setArrivalFrom] = useState();
  const [arrivalTo, setArrivalTo] = useState();
  const [repeatClient, setRepeatClient] = useState(1);
  const [rowStates, setRowStates] = useState([""]);
  const [rowStatesEn, setRowStatesEn] = useState([""]);
  const [rowStatesIndex, setRowStatesIndex] = useState([false]);
  const [rowStatesIndexEn, setRowStatesIndexEn] = useState([false]);
  const [clientNameData, setClientNameData] = useState();
  // const decodedToken = jwtDecode(token && token);
  const { deleteOrder } = useSelector((state) => state.bookingListReducer);
  const { selectedTransactionFlag } = useSelector((state) => state.allTransactionsReducer);
  // const { paymentList } = useSelector((state) => state.bookingListReducer);
  const { paymentListFlag } = useSelector((state) => state.bookingListReducer);
  const [bookingTypeState, setBookingTypeState] = useState();
  const [bookingTypeId, setBookingTypeId] = useState();
  const [bookingStatusState, setBookingStatusState] = useState();
  const [bookingStatusId, setBookingStatusId] = useState();
  const inputRef = useRef(null);
  const [cancelPopup, setCancelPopUp] = useState(false);
  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false);
  const [archivePopup, setArchivePopup] = useState(false);

  const [cancellationReasons, setCancellationReasons] = useState([]);
  const [selectedCancelationReason, setSelectedCancellationReason] = useState();
  const cancellationReasonsArr = cancellationReasons?.map((reason) => ({ label: reason.reason, value: reason.id }));
  const [isCancelling, setIsCancelling] = useState(false);
  const [isConfirmingCancelling, setIsConfirmingCancelling] = useState(false);

  const [operationBookingStatus, setOperationBookingStatus] = useState("");

  const [isArchiving, setIsArchiving] = useState(false);
  // collected booking modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [collectedCheck, setCollected] = useState(false);

  const { mutate: collectedBooking, isLoading: bookingLoading } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(
        `/booking/api/v1/collect-booked-room/${selectedSingleRoom.id}/
`,
        "post",
        data,
        {
          "Content-Type": "application/json",
        },
      );
    },
    mutationKey: "collectedtBooking",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("collected Booking Successfully");
      // navigate(``);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to Collect this Booking");
    },
  });
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    collectedBooking(selectedSingleRoom);
    // Handle the "Yes" action (e.g., collect the booking)
    setIsModalVisible(false);
    setCollected(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //
  useEffect(() => {
    dispatch(
      getSelectedBookedRoomSingleAction(token, params.id, (result) => {
        if (result.status === 200) {
          setBookingLoad(true);
          setRowStates(result.data.client_names.map((item) => item.arabic_name));
          setRowStatesEn(result.data.client_names.map((item) => item.english_name));
          setClientNameData(result.data.client_names);
          setWhats(result.data.whatsapp_number);
          setPhone(result.data.phone_number);
          setClientText(result.data.client_requests_text);
          setClientTag(result.data.client_requests_tags);
          setClientTagOptions(result.data.client_requests_tags);
          setEmail(result.data.email);
          setArrivalFrom(result.data.arrival_time_from);
          setArrivalTo(result.data.arrival_time_to);

          setOperationBookingStatus(
            result.data.last_operation_status ? result.data?.last_operation_status?.name.toLowerCase() : null,
          );
          if (result.data.operations.length > 0) {
            // setPaymentType(result.data.operations[0].payment_type);
          } else {
            dispatch(
              getPaymentListAction(token, result.data.payment_type, (result) => {
                setPaymentFlag(true);

                // setPaymentType(result.data[0]);
              }),
            );
          }
        }
      }),
    );
    dispatch(getSelectedTransactionAction(token, params.id));

    // dispatch(getOptionByIdAction(token, params.id));
    dispatch(cancelUpdateConvertButton());
  }, []);
  const { selectedBookedRoom } = useSelector((state) => state.bookingListReducer);
  const { selectedTransaction } = useSelector((state) => state.allTransactionsReducer);
  const { updateFlag } = useSelector((state) => state.bookingListReducer);
  const { bookingTypes } = useSelector((state) => state.bookingListReducer);
  const { bookingStatus } = useSelector((state) => state.bookingListReducer);
  const { operationFlag } = useSelector((state) => state.bookingListReducer);
  const { operationUpdataFlag } = useSelector((state) => state.bookingListReducer);
  const { convertAddUpdata } = useSelector((state) => state.bookingListReducer);
  const { getOperationIdFlag } = useSelector((state) => state.bookingListReducer);

  const [operationDataId, setOperationId] = useState();

  const [buttonFlag, setButtonFlag] = useState(false);
  // const testtt = async () => {
  //   try {
  //     const response = await api_token(token).get(`booking/api/v1/get-operation-booked-room/${params.id}/`);
  //     if (response.status === 200) {
  //       setButtonFlag(true);
  //       setOperationId(response.data);

  //       setNotes(response.data.note);
  //       //
  //     } else if (response.status === 404) {
  //       setButtonFlag(false);
  //       setOperationId(null);
  //     }
  //   } catch (error) {
  //     //
  //   }
  // };

  useEffect(() => {
    // testtt();

    dispatch(
      getCancelationReasons((data) => {
        setCancellationReasons(data);
      }),
    );
  }, []);

  //
  //
  //

  let createTime = new Date(selectedSingleRoom.created_date);
  // let createDateTime = `${createTime.toLocaleDateString()} ${createTime.toLocaleTimeString()}`;

  let lastUpDateTime = new Date(selectedSingleRoom.last_updated_date);
  // let lastUpdateDateTime = `${lastUpDateTime.toLocaleDateString()} ${lastUpDateTime.toLocaleTimeString()}`;

  let ClientArray = [];

  const newObject = {
    id: clientNameData && clientNameData[clientNameData.length - 1]?.id,
    arabic_name: "",
    english_name: "",
    booking: selectedSingleRoom && selectedSingleRoom.id,
  };
  const handleRepeat = (index111) => {
    if (clientNameData.length < selectedSingleRoom.number_of_persons) {
      setRowStatesIndex([...rowStatesIndex, ""]);
      const dataw = [...clientNameData, newObject];

      const updatedRowStatesId = [...dataw];
      updatedRowStatesId[updatedRowStatesId.length - 1].id = updatedRowStatesId[updatedRowStatesId.length - 1].id + 1;
      setClientNameData(updatedRowStatesId);
    }
  };
  const handleRepeatEn = () => {
    if (clientNameData.length < selectedSingleRoom.number_of_persons) {
      setRowStatesIndexEn([...rowStatesIndexEn, ""]);
      const dataw = [...clientNameData, newObject];

      const updatedRowStatesId = [...dataw];
      updatedRowStatesId[updatedRowStatesId.length - 1].id = updatedRowStatesId[updatedRowStatesId.length - 1].id + 1;
      setClientNameData(updatedRowStatesId);
    }
  };

  const handleRowChangeToggle = (value, index) => {
    const updatedRowStatesToggle = [...rowStatesIndex];
    updatedRowStatesToggle[index] = value;
    setRowStatesIndex(updatedRowStatesToggle);
  };
  const handleRowChangeToggleEn = (value, index) => {
    const updatedRowStatesToggleEn = [...rowStatesIndexEn];
    updatedRowStatesToggleEn[index] = value;
    setRowStatesIndexEn(updatedRowStatesToggleEn);
  };

  const handleRowChange = (value, index) => {
    const updatedRowStates = [...clientNameData];
    updatedRowStates[index].arabic_name = value;
    setClientNameData(updatedRowStates);
  };

  const handleRowChangeEn = (value, index) => {
    const updatedRowStatesEn = [...clientNameData];
    updatedRowStatesEn[index].english_name = value;
    setClientNameData(updatedRowStatesEn);
  };

  function handleTranslateClientNames(index) {
    const updatedRowStates = [...clientNameData];
    if (updatedRowStates[index].arabic_name.length >= 3 && updatedRowStates[index].english_name === "") {
      translateTextToEnglish(updatedRowStates[index].arabic_name).then((translatedText) => {
        updatedRowStates[index].english_name = translatedText;
        setClientNameData(updatedRowStates);
      });
    }

    if (updatedRowStates[index].english_name.length >= 3 && updatedRowStates[index].arabic_name === "") {
      translateTextToArabic(updatedRowStates[index].english_name).then((translatedText) => {
        updatedRowStates[index].arabic_name = translatedText;
        setClientNameData(updatedRowStates);
      });
    }
  }

  const handleRemoveInput = (index) => {
    if (clientNameData.length >= selectedSingleRoom.number_of_persons) {
      const updatedRowStates = [...clientNameData];
      updatedRowStates.splice(index, 1);
      setClientNameData(updatedRowStates);
    }
  };

  const handleArrivalFromTime = (arrivalFromTime) => {
    const date = new Date(arrivalFromTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setArrivalFrom(`${hours1}:${minutes1}`);
  };

  const handleArrivalToTime = (arrivalToTime) => {
    const date = new Date(arrivalToTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setArrivalTo(`${hours1}:${minutes1}`);
  };
  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setOptionTime(`${hours1}:${minutes1}`);
  };

  const handleDateTime = () => {
    const now = new Date();
    const formattedDateTime = now.toISOString();
    setCurrentDateTime(formattedDateTime);
    const date1 = new Date(selectedSingleRoom.created_date);
    const date2 = new Date(formattedDateTime);
    const timeDifference = date2 - date1;
    const timeDifference2 = differenceInMilliseconds(date2, date1);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    const isoDuration = `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    const average = `P${days}DT${hours}H${minutes}M`;
    setResponseTime(average);
    setResponseTimeShow(isoDuration);

    //
  };
  //
  const handleDateTimeShow = () => {
    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - 0 * 60 * 60 * 1000);
    const formattedDate = oneHourAgo.toLocaleDateString();
    const formattedTime = oneHourAgo.toLocaleTimeString();
    setCurrentDateShow(formattedDate);
    setCurrentTimeShow(formattedTime);
  };

  const handleEdit = () => {
    if (optionDate) {
      setCheckId(true);
      setBtnCheck(false);
    } else {
      setMustOptionDate(true);
    }
  };

  const [isOpenType, setIsOpenType] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenPayment, setIsOpenPayment] = useState(false);

  //
  const toggleDropdownType = () => {
    setIsOpenType(!isOpenType);
  };
  const toggleDropdownStatus = () => {
    setIsOpenStatus(!isOpenStatus);
  };
  const togglePaymentList = () => {
    setIsOpenPayment(!isOpenPayment);
  };
  //   ];
  // const handleHotelId = () =>{
  //   const newData = selectedSingleBookedRoom.map((item) =>{
  //     return {...item , hotel_reservation_id : hotelId}
  //   })
  // }

  // const formData = new FormData();
  // formData.append('file', file);

  const handleUpdate = (e) => {
    e.preventDefault();
    const clientCheck = false;
    const checkOnClientData = clientNameData.every((item) => {
      return item.arabic_name && item.english_name;
    });
    //
    if (checkOnClientData) {
      const clientData = {
        client_names: clientNameData,
      };
      const clientRequest = {
        client_requests_tags: clientTag,
      };
      const roomData = {
        phone_number: phone,
        whatsapp_number: Whats,
        room: selectedSingleRoom.room,
        operations: [],
        from_date: selectedSingleRoom.from_date,
        to_date: selectedSingleRoom.to_date,
        nights: selectedSingleRoom.nights,
        hotel: selectedSingleRoom.hotel,
        client_requests_text: clientText,
        email: email,
        arrival_time_from: arrivalFrom,
        arrival_time_to: arrivalTo,
        client_requests_tags: clientTag,
        payment_status: paymentType,
      };

      dispatch(
        updateAction(token, clientData, clientRequest, roomData, params.id, (result) => {
          if (result.status === 200) {
            setBookingLoad(false);
            dispatch(
              getSelectedBookedRoomSingleAction(token, params.id, (result) => {
                if (result.status === 200) {
                  setBookingLoad(true);
                  setEditNameAr(...result.data.client_names.map((item) => item.arabic_name));
                  setEditNameEn(...result.data.client_names.map((item) => item.english_name));
                  setWhats(result.data.whatsapp_number);
                  setPhone(result.data.phone_number);
                  setClientText(result.data.client_requests_text);
                  setClientTag(result.data.client_requests_tags);
                  setClientTagOptions(result.data.client_requests_tags);
                  setEmail(result.data.email);
                  setArrivalFrom(result.data.arrival_time_from);
                  setArrivalTo(result.data.arrival_time_to);
                  dispatch(
                    getPaymentListAction(token, result?.data.payment_type, (result) => {
                      setPaymentFlag(true);
                      // setPaymentType(result.data[0]);
                    }),
                  );
                }
              }),
            );
            dispatch(getSelectedTransactionAction(token, params.id));
            dispatch(
              getBookingTypeAction(token, (result) => {
                if (result.status === 200) {
                  setBookingTypeState(result.data[0].name);
                  setBookingTypeId(result.data[0].id);
                  dispatch(
                    getBookingStatusAction(token, result.data[0].id, (result) => {
                      if (result.status === 200) {
                        setBookingStatusState(result.data[0].name);
                        setBookingStatusId(result.data[0].id);
                      }
                    }),
                  );
                }
              }),
            );
            // dispatch(getOptionByIdAction(token, params.id));
            dispatch(cancelUpdateConvertButton());
            lightToast.success("Booking Info was completed Updated");
          }
        }),
      );
    } else {
      // NotificationManager.error('Client Name is Empty', 'Click me!', 5000, () => {
      //   inputRef.current.blur();
      // });
      NotificationManager.error("There Is An Empty Field");
    }
  };

  const operationOfData = {
    booked_room: Number(params.id),
    employee_name: 1,
    total_paid:
      selectedTransaction && selectedTransaction.results.reduce((acc, balc) => acc + parseFloat(balc.amount), 0),
    outstanding_amount:
      selectedTransaction &&
      selectedSingleRoom.total_price -
        selectedTransaction.results.reduce((acc, balc) => acc + parseFloat(balc.amount), 0),
    status: "new",
    // booking_type: "voucher",
    booking_type_for_status: bookingTypeId,
    booking_status: bookingStatusId,
    source: 1,
    transport_from_airport_to_hotel: "yes",
    hotel_reservation_id: hotelId,
    option_date: `${optionDate},${optionTime}`,
    time_of_hotel_confirmation: currentDateTime,
    time_average_response: responseTime,
    total_net_price: selectedSingleRoom.net_price,
    total_profit: +selectedSingleRoom.total_price - +selectedSingleRoom.net_price,
    special_request: "Late check-in",
    airport_service: "yes",
    cars: "yes",
    currency_type: selectedSingleRoom.currency_type,
    payment_type: paymentType,
    note: notes,
  };

  const handleOperation = () => {
    if (hotelId && optionDate && optionTime && bookingStatusId && bookingTypeId) {
      dispatch(
        operationAction(token, operationOfData, (result) => {
          setOpId(result.data.id);
        }),
      ).then(() => {
        reloadPage();
      });
    } else {
      toast.error("Hotel ID Or Option Date is Empty ");
    }
  };

  const handleUpdateOperation = () => {
    if (hotelId && optionDate && optionTime) {
      dispatch(
        updateOperationAction(token, opId, operationOfData, (result) => {
          reloadPage();

          //
          // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}/${result.id}`)
        }),
      );
    } else {
      toast.error("Hotel ID Or Option Date is Empty ");
    }
  };
  //
  const mealPopUp =
    selectedSingleRoom && selectedSingleRoom.meal_plan === "Bed Only"
      ? "b.o"
      : selectedSingleRoom.meal_plan === "breakfast included"
        ? "b.b"
        : selectedSingleRoom.meal_plan === "half board"
          ? "h.b"
          : selectedSingleRoom.meal_plan === "full board"
            ? "f.b"
            : "";

  //

  /////permission/////////
  const permissionData = {
    permission_codes: [
      "room_booking.view_clientname",
      "site_settings.view_roombookingtag",
      "room_booking.change_bookedroom",
      "permissions_management.collected_booking_button",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  async function handleCancellingBooking() {
    try {
      setIsCancelling(true);

      const cancelledRoom = await cancelBooking(selectedCancelationReason.value, Number(params.id));

      if (cancelledRoom.id) {
        setIsCancelling(false);
        setCancelPopUp(false);
        reloadPage();
      } else {
        throw new Error("Error Cancelling order");
      }
    } catch (e) {
      setIsCancelling(false);
      setCancelPopUp(false);
      NotificationManager.error(e.message);
    }
  }

  async function handleConfirmCancellingBooking() {
    try {
      setIsConfirmingCancelling(true);

      const confirmationMessage = await confirmCancelBooking(Number(params.id));

      if (confirmationMessage.message === "Status updated successfully") {
        setIsConfirmingCancelling(false);
        setConfirmCancelPopup(false);
        navigate("/dashboard/booking-list");
      } else {
        throw new Error("Error Confirm Cancelling order");
      }
    } catch (e) {
      setIsConfirmingCancelling(false);
      setConfirmCancelPopup(false);
      NotificationManager.error(e.message);
    }
  }

  async function handleArchivingBooking() {
    try {
      setIsArchiving(true);

      const archivingMessage = (await archiveBooking(Number(params.id))).message;
      const archivingStatus = (await archiveBooking(Number(params.id))).status;

      if (archivingStatus === "success") {
        setIsArchiving(false);
        navigate("/dashboard/booking-list");
      } else if (archivingStatus === "fail") {
        throw new Error(archivingMessage);
      } else {
        throw new Error("Error Confirm Cancelling order");
      }
      setArchivePopup(false);
    } catch (e) {
      setArchivePopup(false);
      setIsArchiving(false);
      NotificationManager.error(e.message);
    }
  }

  // useEffect(() => {
  //   if (selectedSingleRoom.id) {
  //     dispatch(
  //       getBookingTypeAction(token, (result) => {
  //         if (result.status === 200) {
  //           const BookingTypeData = selectedSingleRoom.operations[0].booking_type_for_status
  //             ? result?.data?.find((item) => item.name === selectedSingleRoom.operations[0].booking_type_for_status)
  //             : result?.data?.find((item) => item.name === "New Booking");

  //           setBookingTypeState(BookingTypeData?.name);
  //           setBookingTypeId(BookingTypeData?.id);
  //           setselectedBookingType(BookingTypeData);
  //           setDataTypeArray(result?.data);
  //           //BookingStatusState
  //           console.log(result, "BookingTypeDataBookingTypeData");

  //           if (BookingTypeData.id) {
  //             dispatch(
  //               getBookingStatusAction(token, BookingTypeData?.id, (result) => {
  //                 if (result.status === 200) {
  //                   const BookingstatusData = selectedSingleRoom.operations[0].booking_status
  //                     ? result.data?.find((item) => item.name === selectedSingleRoom.operations[0].booking_status)
  //                     : result.data?.find((item) => item.name === "New");

  //                   console.log(BookingstatusData, "setselectedBookingTypes");

  //                   setBookingStatusState(BookingstatusData.name);
  //                   setBookingStatusId(BookingstatusData.id);
  //                 }
  //               }),
  //             );
  //           }
  //         }
  //       }),
  //     );
  //   }
  // }, [selectedSingleRoom]);

  const {
    data: tagsData,
    error: tagsError,
    isLoading,
  } = useQuery({
    queryKey: [`get-booking-tags`],
    queryFn: () => fetchDataQuery(`/booking/api/v1/get-booking-tags/`),
  });

  //////// flagData /////////////
  let tagsDataArr = tagsData
    ? tagsData?.map((item) => {
        return { value: item.tag, label: item.tag };
      })
    : [];

  ////////  bookingtypes /////////////

  const {
    data: bookingtypesData,
    error: bookingtypesError,
    isLoading: bookingtypesisLoading,
  } = useQuery({
    queryKey: [`booking-types`],
    queryFn: () => fetchDataQuery(`booking/api/v1/booking-types/`),
  });

  let bookingtypesArr = bookingtypesData
    ? bookingtypesData?.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  useEffect(() => {
    if (bookingtypesData && selectedSingleRoom.id) {
      const BookingTypeDataFilter = selectedSingleRoom.operations[0]
        ? bookingtypesData?.find((item) => item.name === selectedSingleRoom.operations[0].booking_type_for_status)
        : bookingtypesData?.find((item) => item.name === "New Booking");

      if (BookingTypeDataFilter && Object.keys(BookingTypeDataFilter).length > 0) {
        setBookingTypeState(BookingTypeDataFilter?.name);
        setBookingTypeId(BookingTypeDataFilter?.id);
      }
    }
  }, [selectedSingleRoom.id, bookingtypesData]);

  ////////  bookingstatus /////////////

  const {
    data: bookingstatusData,
    error: bookingstatusError,
    isLoading: bookingstatusLoading,
  } = useQuery({
    queryKey: ["booking-status", bookingTypeId],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`booking/api/v1/booking-status/?booking_type_id=${name}`);
    },
    enabled: !!bookingTypeId,
  });

  let bookingstatusDataArr = bookingstatusData
    ? bookingstatusData?.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  useEffect(() => {
    if (bookingstatusData && selectedSingleRoom.id) {
      const BookingstatusDataFilter = selectedSingleRoom.operations[0]
        ? bookingstatusData?.find((item) => item.name === selectedSingleRoom.operations[0].booking_status)
        : bookingstatusData?.find((item) => item.name === "New");

      if (BookingstatusDataFilter && Object.keys(BookingstatusDataFilter).length > 0) {
        setBookingStatusState(BookingstatusDataFilter.name);
        setBookingStatusId(BookingstatusDataFilter.id);
      }
    }
  }, [selectedSingleRoom.id, bookingstatusData]);
  // outstanding amount
  const remainingAmount =
    selectedTransaction?.results &&
    selectedSingleRoom?.total_price -
      selectedTransaction?.results
        ?.filter((item) => item.transaction_status === "confirmed")
        ?.reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0);

  const paymentList = [
    { label: "voucher", value: "voucher" },
    { label: "received", value: "received" },
    { label: "sent", value: "sent" },
  ];
  // vcr payment options
  const paymentOptions = paymentList && paymentList?.map((item) => item);
  // useEffect(() => {
  //   setPaymentType(selectedSingleRoom?.payment_status || "voucher");
  // }, [selectedSingleRoom.payment_status]);
  useEffect(() => {}, [selectedSingleRoom?.collected, selectedSingleRoom.payment_status, paymentType]);
  return (
    <>
      <div className="w-full  overflow-x-auto ">
        {bookingLoad && permissionFlag && bookingStatusId && bookingTypeId ? (
          <>
            {confirm && (
              <Modal size={"2xl"} dismissible show={confirm} onClose={() => setConfirm(false)}>
                <Modal.Header>
                  <p className="text-xl w-full text-[#002244] flex items-center justify-center gap-x-1 mt-1 rounded-md p-2 font-semibold ">
                    <TbListDetails className="w-5 h-5 text-[#002244]" />
                    Hotel Net Price Info
                  </p>
                </Modal.Header>
                <Modal.Body>
                  <div className="w-full flex flex-col items-center justify-center gap-x-14 text-white text-sm p-5">
                    {selectedSingleRoom.net_price_data &&
                      selectedSingleRoom.net_price_data.length > 0 &&
                      selectedSingleRoom.net_price_data?.map((item) => {
                        return (
                          <div className="flex flex-col gap-y-3 items-center  w-full mt-4">
                            <div className="flex items-center justify-center gap-x-10 w-full text-md font-semibold  text-black">
                              <p className="flex items-center gap-x-3 text-md">
                                <span className="flex items-center gap-x-1 font-bold ">
                                  <FaRegCalendarAlt className="w-3 h-3 text-gray-600" />
                                  Date
                                </span>
                                <span className="text-gray-600">{item.date}</span>
                              </p>
                              <p className="flex items-center gap-x-3 text-md">
                                <span className="flex items-center gap-x-1 font-bold ">
                                  <GiPriceTag className="w-3 h-3 text-gray-600" />
                                  Price
                                </span>
                                <p className="flex items-end gap-x-1 text-gray-600">
                                  {item.net_price}
                                  <span className="text-xs font-bold">
                                    {selectedSingleRoom && selectedSingleRoom.currency_type}
                                  </span>
                                </p>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    {selectedSingleRoom.additional_data &&
                      Object.keys(selectedSingleRoom.additional_data).length > 0 &&
                      selectedSingleRoom &&
                      selectedSingleRoom.additional_data?.map((item) => {
                        return (
                          <div className="flex flex-col gap-y-3 items-center  w-full mt-4">
                            <div className="flex items-center justify-center gap-x-10 w-full text-md font-semibold  text-black">
                              <p className="flex items-center gap-x-3 text-md">
                                <span className="flex items-center gap-x-1 font-bold ">
                                  <FaRegCalendarAlt className="w-3 h-3 text-gray-600" />
                                  Date
                                </span>
                                <span className="text-gray-600">{item.date}</span>
                              </p>
                              <p className="flex items-center gap-x-3 text-md">
                                <span className="flex items-center gap-x-1 font-bold ">
                                  <GiPriceTag className="w-3 h-3 text-gray-600" />
                                  Price
                                </span>
                                {selectedSingleRoom && selectedSingleRoom.contract_meal === mealPopUp ? (
                                  <p className="flex items-end gap-x-1 text-gray-600">
                                    {" "}
                                    {item.total_price}{" "}
                                    <span className="text-xs font-bold">
                                      {" "}
                                      {selectedSingleRoom && selectedSingleRoom.currency_type}
                                    </span>
                                  </p>
                                ) : (
                                  <p className="flex items-end gap-x-1 text-gray-600">
                                    {item.meals_offers !== undefined &&
                                      Object.values(item.meals_offers).map((info) => {
                                        if (info.offer_type === mealPopUp) {
                                          return info.total_price;
                                        }
                                      })}
                                    <span className="text-xs font-bold">
                                      {" "}
                                      {selectedSingleRoom && selectedSingleRoom.currency_type}
                                    </span>
                                  </p>
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    {selectedSingleRoom &&
                      selectedSingleRoom.additional_data &&
                      Object.keys(selectedSingleRoom.additional_data).length > 0 &&
                      selectedSingleRoom &&
                      selectedSingleRoom.additional_data.slice(0, 1)?.map((item2) => {
                        return (
                          <div className="flex flex-col gap-y-3 items-center  w-full mt-4 text-black">
                            <div className="flex items-center gap-x-3 text-md">
                              <p className="flex items-center gap-x-1 font-bold ">
                                <CgExtensionAdd className="w-4 h-4 text-gray-600" /> Weekended Supplement
                              </p>
                              <p className="flex items-end gap-x-1 text-gray-600">
                                {item2.weekend_supplement_price}
                                <span className="text-xs font-bold">
                                  {" "}
                                  {selectedSingleRoom && selectedSingleRoom.currency_type}
                                </span>
                              </p>
                            </div>
                            <div className="flex items-center gap-x-3 text-md">
                              <span className="flex items-center gap-x-1 font-bold  ">
                                <IoFastFoodOutline className="w-4 h-4 text-gray-600" /> Meal Plan Per Day
                              </span>
                              <p className="flex items-end gap-x-1 text-gray-600">
                                {Object.values(item2.meals_offers).map((info2) => {
                                  if (info2.offer_type === mealPopUp) {
                                    return info2.meals_prices;
                                  }
                                })}
                                <span className="text-xs font-bold">
                                  {" "}
                                  {selectedSingleRoom && selectedSingleRoom.currency_type}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button color="failure" onClick={() => setConfirm(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            <div className="container border-gray-300 border rounded-lg  p-2 ">
              <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                <p className="flex items-center gap-x-3">
                  Booking Order<span>({selectedSingleRoom.short_id})</span>{" "}
                </p>
              </div>
              <div className="flex items-center justify-between mt-2">
                <p className="text-3xl font-semibold text-gray-800  ">Booking Info</p>
                {permission.map((item) => {
                  if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                    return (
                      <ReactToPrint
                        trigger={() => (
                          <button className="flex items-center gap-x-2 bg-yellow-600 rounded-md text-white font-semibold px-3 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all">
                            Print
                            <AiOutlinePrinter className="w-5 h-5" />
                          </button>
                        )}
                        content={() => print.current}
                      />
                    );
                  }
                })}
              </div>

              <>
                <div className="w-full flex flex-col  shadow-xl shadow-slate-200   gap-y-5 bg-gray-100   mt-3">
                  <div className="flex flex-col h-full justify-center items-center ">
                    {getOperationIdFlag && paymentListFlag && !bookingTypeState && !paymentFlag ? (
                      <div className="flex flex-col h-screen justify-center items-center ">
                        <Loading />
                        <TopBarProgress />
                      </div>
                    ) : (
                      <div className="w-full flex flex-col   justify-start  items-start  break-inside-avoid-page ">
                        <div
                          ref={print}
                          className="flex flex-col  center-content  w-full h-full text-black rounded-md  "
                        >
                          <form
                            className="w-full border-gray-300 border rounded-lg pb-2 px-1 pt-3"
                            onSubmit={handleUpdate}
                          >
                            {/* Hotel id & id & persons*/}
                            <div className="flex w-full justify-between items-center input-par  p-3 bg-gray-200 ">
                              <div className="flex items-center justify-between input-chil">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <PiDatabase className="w-4 h-4 text-[#002244] " />
                                  Booking Order
                                </p>
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600">
                                  {" "}
                                  {selectedSingleRoom.short_id}
                                </p>
                              </div>
                              <hr />
                              <div className="flex items-center justify-between input-chil">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <PiDatabase className="w-4 h-4 text-[#002244] " />
                                  ID
                                </p>
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600">
                                  {" "}
                                  {selectedSingleRoom.id}
                                </p>
                              </div>
                            </div>

                            {/* Client & phone Number*/}
                            {clientNameData.map((clientInfo, index) => {
                              return (
                                <div className="flex w-full justify-between items-center input-par  p-3" key={index}>
                                  <div className="flex  justify-between items-center input-chil flex-col ">
                                    <div className="w-full  ">
                                      <p className="flex items-center gap-x-2 font-bold">
                                        <GoPerson className="w-4 h-4 text-[#002244] " />
                                        Client Name (AR)
                                      </p>
                                    </div>
                                    <div className="w-full input-chil">
                                      <p className="flex items-center gap-x-1">
                                        <div className="w-full flex flex-col font-semibold text-gray-600">
                                          {" "}
                                          <div className="w-full  flex items-center ">
                                            <div className="w-full flex items-center justify-start   ">
                                              {rowStatesIndex[index] ? (
                                                <div className="w-full flex items-center  gap-x-2 ml-auto mr-1">
                                                  <input
                                                    className=" flex items-center gap-x-1 w-full bg-white rounded-md h-5 px-1"
                                                    disabled={!rowStatesIndex[index]}
                                                    defaultValue={clientInfo.arabic_name}
                                                    value={clientInfo.arabic_name}
                                                    required
                                                    ref={inputRef}
                                                    onChange={(e) => handleRowChange(e.target.value, index)}
                                                    onBlur={() => handleRowChangeToggle(false, index)}
                                                  />
                                                  {permission.map((item) => {
                                                    if (
                                                      item.name === "room_booking.view_clientname" &&
                                                      item.value === true
                                                    ) {
                                                      return (
                                                        <div className=" flex items-center gap-x-1 ml-auto mr-1">
                                                          <button
                                                            type="button"
                                                            onClick={() => handleRowChangeToggle(false, index)}
                                                          >
                                                            <FiEdit className="w-4 h-4 ml-4 text-[#002244] " />
                                                          </button>

                                                          {selectedSingleRoom.number_of_persons > 1 && (
                                                            <>
                                                              <button
                                                                type="button"
                                                                onClick={() =>
                                                                  handleRepeat(index, clientNameData[0].id)
                                                                }
                                                              >
                                                                <IoPersonAdd className="w-4 h-4 text-[#002244] " />
                                                              </button>

                                                              <button
                                                                type="button"
                                                                onClick={() => handleRemoveInput(index)}
                                                              >
                                                                <IoPersonRemove className="w-4 h-4 text-[#002244] " />
                                                              </button>
                                                            </>
                                                          )}
                                                          <button
                                                            type="button"
                                                            className=""
                                                            onClick={() => {
                                                              handleTranslateClientNames(index);
                                                            }}
                                                          >
                                                            <MdGTranslate className="ml-1" size={24} fill="#002244" />
                                                          </button>
                                                        </div>
                                                      );
                                                    }
                                                  })}
                                                </div>
                                              ) : (
                                                <div className="w-full flex items-center gap-x-2 ml-auto mr-1">
                                                  <input
                                                    className=" flex items-center gap-x-1 w-full bg-gray-100  rounded-md h-5 px-1"
                                                    disabled={!rowStatesIndex[index]}
                                                    defaultValue={clientInfo.arabic_name}
                                                    value={clientInfo.arabic_name}
                                                    required
                                                    ref={inputRef}
                                                    onChange={(e) => handleRowChange(e.target.value, index)}
                                                    onBlur={() => handleRowChangeToggle(true, index)}
                                                  />
                                                  {permission.map((item) => {
                                                    if (
                                                      item.name === "room_booking.view_clientname" &&
                                                      item.value === true
                                                    ) {
                                                      return (
                                                        <div className=" flex items-center gap-x-1 ml-auto mr-1">
                                                          <button
                                                            type="button"
                                                            onClick={() => handleRowChangeToggle(true, index)}
                                                          >
                                                            <FiEdit className="w-4 h-4 ml-4 text-[#002244] " />
                                                          </button>
                                                          {selectedSingleRoom.number_of_persons > 1 && (
                                                            <>
                                                              <button
                                                                type="button"
                                                                onClick={() =>
                                                                  handleRepeat(index, clientNameData[0].id)
                                                                }
                                                              >
                                                                <IoPersonAdd className="w-4 h-4 text-[#002244] " />
                                                              </button>

                                                              <button
                                                                type="button"
                                                                onClick={() => handleRemoveInput(index)}
                                                              >
                                                                <IoPersonRemove className="w-4 h-4 text-[#002244] " />
                                                              </button>
                                                            </>
                                                          )}
                                                          <button
                                                            className=""
                                                            type="button"
                                                            onClick={() => {
                                                              handleTranslateClientNames(index);
                                                            }}
                                                          >
                                                            <MdGTranslate className="ml-1" size={24} fill="#002244" />
                                                          </button>
                                                        </div>
                                                      );
                                                    }
                                                  })}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex  justify-between items-center input-chil flex-col ">
                                    <div className="w-full  ">
                                      <p className="flex items-center gap-x-2 font-bold">
                                        <GoPerson className="w-4 h-4 text-[#002244] " />
                                        Client Name (EN)
                                      </p>
                                    </div>
                                    <div className="w-full">
                                      <p className="flex items-center gap-x-1">
                                        <div className="w-full flex flex-col font-semibold text-gray-600">
                                          {" "}
                                          <div className="w-full  flex items-center ">
                                            <div className="w-full flex items-center justify-start   ">
                                              {rowStatesIndexEn[index] ? (
                                                <div className="w-full flex items-center  gap-x-2 ml-auto mr-1">
                                                  <input
                                                    className=" flex items-center gap-x-1 bg-white  rounded-md w-full h-5 px-1"
                                                    disabled={!rowStatesIndexEn[index]}
                                                    defaultValue={clientInfo.english_name}
                                                    value={clientInfo.english_name}
                                                    required
                                                    onChange={(e) => handleRowChangeEn(e.target.value, index)}
                                                    onBlur={() => handleRowChangeToggleEn(false, index)}
                                                  />
                                                  {permission.map((item) => {
                                                    if (
                                                      item.name === "room_booking.view_clientname" &&
                                                      item.value === true
                                                    ) {
                                                      return (
                                                        <div className=" flex items-center gap-x-1">
                                                          <button
                                                            type="button"
                                                            onClick={() => handleRowChangeToggleEn(false, index)}
                                                          >
                                                            <FiEdit className="w-4 h-4 text-[#002244] " />
                                                          </button>

                                                          {selectedSingleRoom.number_of_persons > 1 && (
                                                            <>
                                                              <button type="button" onClick={handleRepeatEn}>
                                                                <IoPersonAdd className="w-4 h-4 text-[#002244] " />
                                                              </button>

                                                              <button
                                                                type="button"
                                                                onClick={() => handleRemoveInput(index)}
                                                              >
                                                                <IoPersonRemove className="w-4 h-4 text-[#002244] " />
                                                              </button>
                                                            </>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  })}
                                                </div>
                                              ) : (
                                                <div className="w-full flex items-center gap-x-2">
                                                  <input
                                                    className=" flex items-center gap-x-1 bg-gray-100  rounded-md w-full h-5 px-1"
                                                    disabled={!rowStatesIndexEn[index]}
                                                    defaultValue={clientInfo.english_name}
                                                    value={clientInfo.english_name}
                                                    required
                                                    onChange={(e) => handleRowChangeEn(e.target.value, index)}
                                                    onBlur={() => handleRowChangeToggleEn(true, index)}
                                                  />
                                                  {permission.map((item) => {
                                                    if (
                                                      item.name === "room_booking.view_clientname" &&
                                                      item.value === true
                                                    ) {
                                                      return (
                                                        <div className=" flex items-center gap-x-1">
                                                          <button
                                                            type="button"
                                                            onClick={() => handleRowChangeToggleEn(true, index)}
                                                          >
                                                            <FiEdit className="w-4 h-4 text-[#002244] " />
                                                          </button>

                                                          {selectedSingleRoom.number_of_persons > 1 && (
                                                            <>
                                                              <button type="button" onClick={handleRepeatEn}>
                                                                <IoPersonAdd className="w-4 h-4 text-[#002244] " />
                                                              </button>

                                                              <button
                                                                type="button"
                                                                onClick={() => handleRemoveInput(index)}
                                                              >
                                                                <IoPersonRemove className="w-4 h-4 text-[#002244] " />
                                                              </button>
                                                            </>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  })}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {/* Client & phone Number*/}

                            {/* WhatsApp Number & Email*/}
                            <div className="flex w-full justify-between items-center input-par  p-3 bg-gray-200 ">
                              <div className="flex  justify-between items-center input-chil flex-col">
                                <div className="w-full  ">
                                  <p className="flex items-center gap-x-2 font-bold">
                                    <MdOutlineWhatsapp className="w-4 h-4 text-[#002244] " />
                                    WhatsApp Number
                                  </p>
                                </div>
                                <div className="w-full">
                                  <p className="flex items-center gap-x-1">
                                    <div className="w-full  flex items-center gap-x-4 font-semibold text-gray-600">
                                      {whatsToggle ? (
                                        <div className="w-full flex items-center  justify-start gap-x-2">
                                          <input
                                            className=" flex items-center gap-x-1 bg-white  rounded-md w-full h-5 px-1"
                                            disabled={!whatsToggle}
                                            defaultValue={Whats}
                                            onChange={(e) => setWhats(e.target.value)}
                                            onBlur={() => setWhatsToggle(!whatsToggle)}
                                          />
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button type="button" onClick={() => setWhatsToggle(!whatsToggle)}>
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      ) : (
                                        <div className="w-full flex items-center justify-start gap-x-2">
                                          <input
                                            className=" flex items-center gap-x-1   rounded-md w-full h-5 px-1"
                                            disabled={!whatsToggle}
                                            defaultValue={Whats}
                                            onChange={(e) => setWhats(e.target.value)}
                                            onBlur={() => setWhatsToggle(!whatsToggle)}
                                          />
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button type="button" onClick={() => setWhatsToggle(!whatsToggle)}>
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      )}
                                    </div>
                                  </p>
                                </div>
                              </div>
                              <div className="flex  justify-between items-center input-chil flex-col">
                                <div className="w-full ">
                                  <p className="flex items-center gap-x-2 font-bold">
                                    <AiOutlinePhone className="w-4 h-4 text-[#002244] " />
                                    Phone Number{" "}
                                  </p>
                                </div>
                                <div className="w-full">
                                  <p className="flex items-center gap-x-1">
                                    <div className="w-full flex items-center gap-x-4 font-semibold text-gray-600">
                                      {phoneToggle ? (
                                        <div className="w-full flex items-center  justify-start gap-x-2">
                                          <input
                                            className=" flex items-center gap-x-1 bg-white  rounded-md w-full h-5 px-1"
                                            disabled={!phoneToggle}
                                            defaultValue={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            onBlur={() => setPhoneToggle(!phoneToggle)}
                                          />
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button type="button" onClick={() => setPhoneToggle(!phoneToggle)}>
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      ) : (
                                        <div className="w-full flex items-center justify-start gap-x-2">
                                          <input
                                            className=" flex items-center gap-x-1   rounded-md w-full h-5 px-1"
                                            disabled={!phoneToggle}
                                            defaultValue={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            onBlur={() => setPhoneToggle(!phoneToggle)}
                                          />
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button type="button" onClick={() => setPhoneToggle(!phoneToggle)}>
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      )}
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* Client text & Client Tag Request*/}
                            <div className="flex w-full justify-between items-center input-par   p-3  ">
                              <div className="flex  justify-between items-center input-chil flex-col">
                                <div className="w-full  ">
                                  <p className="flex items-center gap-x-2 font-bold">
                                    <CiSquareQuestion className="w-4 h-4 text-[#002244]" />
                                    Client Request Tag{" "}
                                  </p>
                                </div>
                                <div className="w-full">
                                  <div className="flex items-center gap-x-1">
                                    <div className="w-full  flex items-center gap-x-4 font-semibold text-gray-600">
                                      <div className="w-full flex items-center  justify-between gap-x-2">
                                        {/* <input
                                          className={` flex items-center gap-x-1 ${
                                            clientTagToggle ? "bg-white" : ""
                                          }   rounded-md w-full h-5 px-1`}
                                          disabled={!clientTagToggle}
                                          defaultValue={clientTag}
                                          onChange={(e) => setClientTag(e.target.value)}
                                          onBlur={() => setClientTagToggle(!clientTagToggle)}
                                        /> */}

                                        {!clientTagToggle &&
                                          clientTag?.map((tag) => <p className="text-base font-semibold">{tag}</p>)}

                                        {clientTagToggle && (
                                          <Select
                                            name="client_requests_tags"
                                            onChange={(e) => setClientTag(e.map((item) => item.value))}
                                            isSearchable
                                            options={tagsDataArr}
                                            isMulti
                                            value={
                                              clientTag.length > 0
                                                ? clientTag?.map((tag) => ({
                                                    value: tag,
                                                    label: tag,
                                                  }))
                                                : []
                                            }
                                          />
                                        )}

                                        {permission.some(
                                          (item) =>
                                            item.name === "site_settings.view_roombookingtag" && item.value === true,
                                        ) ? (
                                          <button
                                            type="button"
                                            onClick={() => setClientTagToggle((prev) => !prev)} // Toggle clientTagToggle state
                                          >
                                            <FiEdit className="w-4 h-4 text-[#002244]" />
                                          </button>
                                        ) : null}

                                        {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex  justify-between items-center input-chil flex-col ">
                                <div className="w-full  ">
                                  <p className="flex items-center gap-x-2 font-bold">
                                    <BsChatSquareText className="w-4 h-4 text-[#002244]" />
                                    Client Request Text{" "}
                                  </p>
                                </div>
                                <div className="w-full">
                                  <p className="flex items-center gap-x-1">
                                    <div className="w-full flex items-center gap-x-4 font-semibold text-gray-600">
                                      <div className="w-full flex items-center  justify-between gap-x-2">
                                        <input
                                          className={` flex items-center gap-x-1 ${
                                            clientTextToggle ? "bg-white" : ""
                                          }  rounded-md w-full h-5 px-1`}
                                          disabled={!clientTextToggle}
                                          defaultValue={clientText}
                                          onChange={(e) => setClientText(e.target.value)}
                                          onBlur={() => setClientTextToggle(!clientTextToggle)}
                                        />
                                        {permission.map((item) => {
                                          if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                            return (
                                              <button
                                                type="button"
                                                onClick={() => setClientTextToggle(!clientTextToggle)}
                                              >
                                                <FiEdit className="w-4 h-4 text-[#002244] " />
                                              </button>
                                            );
                                          }
                                        })}

                                        {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                      </div>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* Check In & Check Out*/}
                            <div className="flex w-full justify-between items-center input-par  p-3 bg-gray-200">
                              <div className="flex  justify-between items-center input-chil flex-col ">
                                <div className="w-full  ">
                                  <p className="flex items-center gap-x-2 font-bold">
                                    <AiOutlineMail className="w-4 h-4 text-[#002244] " />
                                    Email
                                  </p>
                                </div>
                                <div className="w-full">
                                  <div className="flex items-center gap-x-1">
                                    <div className="w-full  flex items-center gap-x-4 font-semibold text-gray-600">
                                      {emailToggle ? (
                                        <div className="w-full flex items-center  justify-between gap-x-2">
                                          <input
                                            className=" flex items-center gap-x-1 bg-white  rounded-md w-full h-5 px-1"
                                            disabled={!emailToggle}
                                            defaultValue={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={() => setEmailToggle(!emailToggle)}
                                          />
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button type="button" onClick={() => setEmailToggle(!emailToggle)}>
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      ) : (
                                        <div className="w-full flex items-center justify-start gap-x-2">
                                          <input
                                            className=" flex items-center gap-x-1   rounded-md w-full h-5 px-1"
                                            disabled={!emailToggle}
                                            defaultValue={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={() => setEmailToggle(!emailToggle)}
                                          />
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button type="button" onClick={() => setEmailToggle(!emailToggle)}>
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flex  justify-between items-center input-chil flex-col ">
                                <div className="w-full ">
                                  <p className="flex items-center gap-x-1 font-bold text-sm">
                                    {" "}
                                    <MdOutlineAccessTime className="w-4 h-4 text-[#002244]" />
                                    Arrival Time From / To
                                  </p>
                                </div>
                                <div className="w-full">
                                  <div className="flex ">
                                    <div className="w-full  flex items-center gap-x-2 font-semibold text-gray-600">
                                      {arrivalFromToggle ? (
                                        <div className="w-full flex items-center justify-start gap-x-2">
                                          <TimePicker
                                            clearIcon={false}
                                            defaultValue={dayjs(arrivalFrom ? arrivalFrom : "00:00", format)}
                                            format={format}
                                            className=" flex items-center gap-x-1 bg-white  rounded-md w-full h-5 "
                                            disabled={!arrivalFromToggle}
                                            onChange={handleArrivalFromTime}
                                            onBlur={() => setArrivalFromToggle(!arrivalFromToggle)}
                                          />
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button
                                                  type="button"
                                                  onClick={() => setArrivalFromToggle(!arrivalFromToggle)}
                                                >
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      ) : (
                                        <div className="w-full flex items-center  justify-start gap-x-2">
                                          <div className=" flex items-center gap-x-1   rounded-md w-full h-5 px-1">
                                            <MdOutlineAccessTime className="w-4 h-4 text-green-800" /> {arrivalFrom}
                                          </div>
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button
                                                  type="button"
                                                  className="w-full"
                                                  onClick={() => setArrivalFromToggle(!arrivalFromToggle)}
                                                >
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      )}
                                      /
                                      {arrivalToToggle ? (
                                        <div className="w-full flex items-center justify-start gap-x-2">
                                          <TimePicker
                                            clearIcon={false}
                                            defaultValue={dayjs(arrivalTo ? arrivalTo : "00:00", format)}
                                            format={format}
                                            className=" flex items-center gap-x-1 bg-white  rounded-md w-full h-5 px-1"
                                            disabled={!arrivalToToggle}
                                            onChange={handleArrivalToTime}
                                            onBlur={() => setArrivalToToggle(!arrivalToToggle)}
                                          />

                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button
                                                  type="button"
                                                  onClick={() => setArrivalToToggle(!arrivalToToggle)}
                                                >
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      ) : (
                                        <div className="w-full flex items-center  justify-start gap-x-2">
                                          <div className=" flex items-center gap-x-1   rounded-md w-full h-5 px-1">
                                            <MdOutlineAccessTime className="w-4 h-4 text-red-800" /> {arrivalTo}
                                          </div>
                                          {permission.map((item) => {
                                            if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                              return (
                                                <button
                                                  type="button"
                                                  className="w-full"
                                                  onClick={() => setArrivalToToggle(!arrivalToToggle)}
                                                >
                                                  <FiEdit className="w-4 h-4 text-[#002244] " />
                                                </button>
                                              );
                                            }
                                          })}

                                          {/* <button><IoPersonAdd className="w-4 h-4 text-[#002244] " /></button> */}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full flex items-center justify-end mt-3">
                              {permission.map((item) => {
                                if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                  return (
                                    !(
                                      operationBookingStatus === "confirmed cancelation" ||
                                      operationBookingStatus === "archived" ||
                                      operationBookingStatus === "collected"
                                    ) && (
                                      <button
                                        type="submit"
                                        disabled={updateFlag}
                                        className="flex items-center gap-x-1 px-2 py-0.5 text-sm font-semibold bg-green-800 text-white rounded-md hover:bg-green-700 active:scale-95 duration-300 transition-all"
                                      >
                                        Update{" "}
                                        {updateFlag ? (
                                          <>
                                            <ButtonLoading />
                                            <TopBarProgress />{" "}
                                          </>
                                        ) : (
                                          <RxUpdate className="w-4 h-4" />
                                        )}
                                      </button>
                                    )
                                  );
                                }
                              })}
                            </div>
                          </form>

                          <div className="flex w-full justify-between items-center p-3 input-par  ">
                            <div className="flex  justify-between items-center input-chil  ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <GiHotMeal className="w-4 h-4 text-[#002244] " />
                                  Meal Plan{" "}
                                </p>
                              </div>
                              <div className="w-full">
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600 capitalize">
                                  {selectedSingleRoom.meal_plan}
                                </p>
                              </div>
                            </div>
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <GoPerson className="w-4 h-4 text-[#002244] " />
                                  Person{" "}
                                </p>
                              </div>
                              <div className="w-full">
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600">
                                  {selectedSingleRoom.number_of_persons}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* Hotel & Room Name*/}
                          <div className="flex w-full justify-between items-center input-par p-3 bg-gray-200">
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <TbBuildingEstate className="w-4 h-4 text-[#002244] " />
                                  Hotel Name{" "}
                                </p>
                              </div>
                              <div className="w-full">
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600 capitalize">
                                  {selectedSingleRoom.hotel}
                                </p>
                              </div>
                            </div>
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <IoBedOutline className="w-4 h-4 text-[#002244] " />
                                  Room Type{" "}
                                </p>
                              </div>
                              <div className="w-full">
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600 capitalize">
                                  {selectedSingleRoom.room_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* Check In & Check Out*/}
                          <div className="flex w-full justify-between items-center input-par  p-3  ">
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  {" "}
                                  <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />
                                  Check-In{" "}
                                </p>
                              </div>
                              <div className="w-full">
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600">
                                  <FaRegCalendarAlt className="w-4 h-4 text-green-800 " />{" "}
                                  {selectedSingleRoom.from_date}
                                </p>
                              </div>
                            </div>
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />
                                  Check-Out{" "}
                                </p>
                              </div>
                              <div className="w-full">
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600">
                                  <FaRegCalendarAlt className="w-4 h-4 text-red-800" /> {selectedSingleRoom.to_date}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* Arrivatk Time & Nights*/}
                          <div className="flex w-full justify-between items-center p-3 input-par bg-gray-200">
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-1 font-bold">
                                  <CgNotes className="w-4 h-4 text-[#002244]" />
                                  Hotel Net Price Info{" "}
                                </p>
                              </div>
                              <div className="w-full">
                                <div className="flex items-center gap-x-4">
                                  <button
                                    onClick={() => {
                                      setConfirm(!confirm);
                                    }}
                                    className="flex justify-center items-center gap-x-2 px-2 w-36 h-9 rounded-md border border-gray-300 shadow-sm  bg-gray-100 text-sm font-medium text-gray-700 hover:bg-gray-200  active:scale-95 duration-300 transition-all"
                                  >
                                    <TbListDetails className="w-4 h-4 text-[#002244]" /> Details
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <IoCloudyNightOutline className="w-4 h-4 text-[#002244]" /> Nights{" "}
                                </p>
                              </div>
                              <div className="w-full">
                                <p className="flex items-center gap-x-1 font-semibold text-gray-600">
                                  {selectedSingleRoom.nights}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/*Person & VCR*/}
                          <div className="flex w-full justify-between items-center  p-3 input-par ">
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <GiPriceTag className="w-4 h-4 text-[#002244] " />
                                  Total Price
                                </p>
                              </div>
                              <div className="w-full flex items-center gap-x-1 font-semibold text-gray-600">
                                <p className="flex items-center gap-x-1">{selectedSingleRoom.total_price}</p>
                                <p className="flex items-center gap-x-1">{selectedSingleRoom.currency_type}</p>
                              </div>
                            </div>
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <GiPriceTag className="w-4 h-4 text-[#002244] " />
                                  Payment Type
                                </p>
                              </div>
                              <div className="w-full flex items-center gap-x-1 font-semibold text-gray-600">
                                <p className="flex items-center gap-x-1">{selectedSingleRoom.payment_type}</p>
                              </div>
                            </div>
                          </div>

                          <div className="flex w-full justify-between  items-center p-3  bg-gray-200 ">
                            {selectedSingleRoom.payment_type === "cash" && (
                              <div className="flex w-full gap-3 items-center ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <LiaWpforms className="w-4 h-4 text-[#002244] " />
                                  pay type
                                </p>

                                <p className=" flex items-center gap-x-2 font-bold text-gray-600 ">
                                  {selectedSingleRoom.pay_donotpay ? "Pay" : "Donot Pay"}
                                </p>
                              </div>
                            )}

                            {selectedSingleRoom.payment_type === "credit" && (
                              <div className="flex  justify-between sm:gap-3 items-center max-sm:flex-col ">
                                <div className="w-full  ">
                                  <p className="flex items-center gap-x-2 font-bold">
                                    <LiaWpforms className="w-4 h-4 text-[#002244] " />
                                    VCR
                                  </p>
                                </div>

                                {selectedSingleRoom?.voucher ? (
                                  <div className="w-full">
                                    <Select
                                      className="w-48"
                                      value={paymentOptions?.find((option) => option.value === paymentType)}
                                      onChange={(selectedOption) =>
                                        setPaymentType(selectedOption.value || selectedSingleRoom.payment_status)
                                      }
                                      options={paymentOptions}
                                      placeholder="Select payment type"
                                      isSearchable={false} // Disable search if you don't need it
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          borderColor: "gray",
                                          boxShadow: "none",
                                          "&:hover": { borderColor: "gray" },
                                        }),
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <p className="w-full flex items-center gap-x-2 font-bold">No voucher</p>
                                )}
                              </div>
                            )}
                          </div>

                          {/* Total Price &  Created Date*/}
                          <div className="flex w-full justify-between items-center p-3 input-par ">
                            <div className="flex  justify-between items-center input-chil flex-col ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <RxUpdate className="w-4 h-4 text-[#002244] " />
                                  LastUpdated Date
                                </p>
                              </div>
                              <div className="w-full">
                                <div className="flex items-center gap-x-4 font-semibold text-gray-600">
                                  <p className="flex items-center gap-x-1">
                                    <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />{" "}
                                    {lastUpDateTime.toLocaleDateString()}{" "}
                                  </p>
                                  /
                                  <p className="flex items-center gap-x-1">
                                    <MdOutlineAccessTime className="w-4 h-4 text-[#002244]" />{" "}
                                    {new Date(lastUpDateTime.getTime() - 0 * 60 * 60 * 1000).toLocaleTimeString()}{" "}
                                  </p>
                                </div>
                                <p className="flex items-center gap-x-1"></p>
                              </div>
                            </div>
                            <div className="flex  justify-between items-center input-chil flex-col ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-2 font-bold">
                                  <AiFillFileAdd className="w-4 h-4 text-[#002244] " />
                                  Created Date
                                </p>
                              </div>
                              <div className="w-full">
                                <div className="flex items-center gap-x-4 font-semibold text-gray-600">
                                  <p className="flex items-center gap-x-1">
                                    <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />{" "}
                                    {createTime.toLocaleDateString()}{" "}
                                  </p>
                                  /
                                  <p className="flex items-center gap-x-1">
                                    <MdOutlineAccessTime className="w-4 h-4 text-[#002244]" />{" "}
                                    {new Date(createTime.getTime() - 0 * 60 * 60 * 1000).toLocaleTimeString()}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Notes*/}
                          <div className="flex w-full justify-between items-center p-3 input-par ">
                            <div className="flex  justify-between items-center input-chil ">
                              <div className="w-full  ">
                                <p className="flex items-center gap-x-1 font-bold">
                                  <CgNotes className="w-4 h-4 text-[#002244]" />
                                  Notes
                                </p>
                              </div>
                              <div className="w-full">
                                <div className="flex items-center gap-x-4">
                                  <textarea
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    className="w-[80%] bg-gray-100 border-gray-300 border rounded-lg px-2 py-1 h-10 text-gray-800 font-semibold"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="flex  justify-end items-center input-chil ">
                              {permission.map((item) => {
                                if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                  return (
                                    <div className="flex gap-2">
                                      {permission?.map((item) => {
                                        const isPermissionAllowed =
                                          item.name === "permissions_management.collected_booking_button" &&
                                          item.value === true;
                                        const isPaymentReceived =
                                          remainingAmount <= 0 && selectedSingleRoom.payment_status === "received";
                                        if (isPermissionAllowed && isPaymentReceived) {
                                          return (
                                            <Button
                                              color="success"
                                              className="px-2 py-0.8 rounded-md text-white"
                                              onClick={showModal}
                                              disabled={collectedCheck || selectedSingleRoom?.collected}
                                            >
                                              {collectedCheck || selectedSingleRoom?.collected
                                                ? "Collected"
                                                : "Collected Booking"}
                                            </Button>
                                          );
                                        }
                                      })}

                                      <Modal show={isModalVisible} size="md" popup={true} onClose={handleCancel}>
                                        <Modal.Header />
                                        <Modal.Body>
                                          <div className="text-center">
                                            <h3 className="mb-5 text-lg font-normal text-gray-500">
                                              Are you sure you want to collect this booking?
                                            </h3>
                                            <div className="flex justify-center gap-4">
                                              <Button color="success" onClick={handleOk}>
                                                Yes
                                              </Button>
                                              <Button color="gray" onClick={handleCancel}>
                                                No
                                              </Button>
                                            </div>
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                      {!operationBookingStatus && (
                                        <button
                                          className="flex items-center gap-x-1 py-0.5 px-2 rounded-md  bg-red-900 text-white "
                                          onClick={() => setCancelPopUp(true)}
                                        >
                                          <TiCancel className="w-5 h-5" /> Cancel Booking
                                        </button>
                                      )}

                                      {operationBookingStatus === "pending cancelation" && (
                                        <button
                                          className="flex items-center gap-x-1 py-0.5 px-2 rounded-md  border-red-900 bg-white  text-red-900 "
                                          onClick={() => setConfirmCancelPopup(true)}
                                        >
                                          <GiConfirmed className="w-5 h-5" /> Confirm Cancel
                                        </button>
                                      )}

                                      {operationBookingStatus === "collected" && (
                                        <button
                                          className="flex items-center gap-x-2 bg-yellow-600 rounded-md text-white font-semibold px-3 py-0.5 "
                                          onClick={() => setArchivePopup(true)}
                                        >
                                          <FaArchive className="w-5 h-5" /> Archive Booking
                                        </button>
                                      )}
                                    </div>
                                  );
                                } else {
                                  // TODO:: handle no permission allowed
                                  return null;
                                }
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="w-full mt-10 ">
                          <p className="text-3xl font-light text-white  flex justify-center w-full bg-[#002244] h-12 ">
                            Operation Info
                          </p>
                          <div className="flex flex-col   gap-y-5 w-full bg-gray-300 text-black rounded-md  p-4">
                            {/* Hotel id & Booking Type*/}
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Booking Type </p>
                                </div>
                                <div className="w-full">
                                  {/* <div>
                                        <button
                                          onClick={toggleDropdownType}
                                          type="button"
                                          className="flex justify-center items-center w-48 h-9 rounded-md border border-gray-300 shadow-sm  bg-gray-100 text-sm font-medium text-gray-700 hover:bg-gray-200  active:scale-95 duration-300 transition-all"
                                        >
                                          {bookingTypeState ? bookingTypeState : " Booking Types"}
                                          <svg
                                            className="-mr-1 ml-2 h-5 w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                        </button>
                                      </div> */}
                                  <div className="w-full">
                                    <Select
                                      onChange={(e) => {
                                        setBookingTypeState(e.label);
                                        setBookingStatusState("");
                                        setBookingTypeId(e.value);
                                        dispatch(getBookingStatusAction(token, e.value));
                                      }}
                                      isSearchable
                                      options={bookingtypesArr}
                                      className="w-full"
                                      // Set the default value from datacome
                                      value={{
                                        value: bookingTypeId,
                                        label: bookingTypeState,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center input-chil">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Booking Status</p>
                                </div>
                                <div className="w-full">
                                  <div className="w-full">
                                    <Select
                                      onChange={(e) => {
                                        setBookingStatusState(e.label);
                                        setBookingStatusId(e.value);
                                      }}
                                      isSearchable
                                      isLoading={bookingstatusLoading}
                                      isDisabled={bookingstatusLoading}
                                      options={bookingstatusDataArr}
                                      className="w-full"
                                      // Set the default value from datacome
                                      value={{
                                        value: bookingStatusId,
                                        label: bookingStatusState,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/* Booking Status & Option Date*/}
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex items-center input-chil">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Hotel Reservation ID</p>
                                </div>
                                <div>
                                  {selectedSingleRoom?.operations?.some(
                                    (operation) => operation.hotel_reservation_id,
                                  ) ? (
                                    <div id="hotel_reservation_id " className="w-full pl-4">
                                      {selectedSingleRoom?.operations.map((operation) => (
                                        <p className=" text-base font-medium ">{operation.hotel_reservation_id}</p>
                                      ))}
                                    </div>
                                  ) : (
                                    <div id={"new"}>
                                      {checkId ? (
                                        <div className="flex items-center gap-x-1">
                                          <input
                                            className=" flex items-center gap-x-1 bg-white  rounded-md w-40 h-[24px] p-2 font-semibold text-gray-700"
                                            defaultValue={hotelId}
                                            onChange={(e) => setHotelId(e.target.value)}
                                          />
                                          <button
                                            className="bg-[#002244] text-white rounded-md hover:scale-105 hover:bg-sky-600 active:scale-95 duration-300 transition-all p-0.5"
                                            onClick={() => {
                                              setCheckId(false);
                                              setMustOptionDate(false);
                                              handleDateTime();
                                              handleDateTimeShow();
                                            }}
                                          >
                                            <MdOutlineDone className="w-4 h-4" />
                                          </button>
                                        </div>
                                      ) : (
                                        <p className="flex items-center gap-x-1 font-semibold text-slate-900 ">
                                          {hotelId}
                                          <button
                                            className="bg-[#002244] text-white rounded-md hover:scale-105 hover:bg-sky-600 active:scale-95 duration-300 transition-all p-1"
                                            onClick={handleEdit}
                                          >
                                            <FiEdit className="w-4 h-4" />
                                          </button>
                                          {mustOptionDate && (
                                            <p className="text-sm  text-red-500">You Should Enter Option Date First</p>
                                          )}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p
                                    className={`flex items-center gap-x-1 font-bold ${
                                      mustOptionDate ? "  scale-110  text-xl transform duration-300 ease-in-out " : ""
                                    }`}
                                  >
                                    Option Date
                                  </p>
                                </div>
                                <div className="w-full">
                                  {selectedSingleRoom?.operations?.some(
                                    (operation) => operation.hotel_reservation_id,
                                  ) ? (
                                    <div id="hotel_reservation_id " className="w-full pl-4">
                                      {selectedSingleRoom?.operations.map((operation) => {
                                        const formattedDate = dayjs(operation.option_date).format("YYYY-MM-DD HH:mm");

                                        return <p className=" text-base font-medium ">{formattedDate}</p>;
                                      })}
                                    </div>
                                  ) : (
                                    <div>
                                      {checkOption ? (
                                        <div className="flex items-center gap-x-1">
                                          <input
                                            type="date"
                                            placeholder=""
                                            className="floating-input text-sm flex items-center gap-x-1 bg-white  rounded-md w-32 h-[24px] p-2"
                                            defaultValue={optionDate}
                                            onChange={(e) => setOptionDate(e.target.value)}
                                          />
                                          <TimePicker
                                            clearIcon={false}
                                            defaultValue={dayjs(optionTime ? optionTime : "00:00", format)}
                                            format={format}
                                            onChange={handleTimeChange}
                                            className="h-[24px]"
                                          />
                                          {/* <input type='time' pattern="(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)" className='floating-input flex items-center gap-x-1  bg-gray-100 border-gray-500 border rounded-md w-36 h-6' defaultValue={optionTime} onChange={(e)=>setOptionTime(e.target.value)}/> */}
                                          <button
                                            className="bg-[#002244] text-white rounded-md hover:scale-105 hover:bg-sky-600 active:scale-95 duration-300 transition-all p-0.5"
                                            onClick={() => {
                                              setCheckOption(false);
                                              setMustOptionDate(false);
                                            }}
                                          >
                                            <MdOutlineDone className="w-4 h-4" />
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="flex items-center gap-x-4">
                                          {optionDate ? (
                                            <p className="flex items-center gap-x-1 font-semibold text-slate-900 ">
                                              <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />
                                              {optionDate}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                          {optionTime ? (
                                            <p className="flex items-center gap-x-1 font-semibold text-slate-900 ">
                                              <MdOutlineAccessTime className="w-5 h-5 text-[#002244]" />
                                              {optionTime}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                          <button
                                            className="bg-[#002244] text-white rounded-md hover:scale-105 hover:bg-sky-600 active:scale-95 duration-300 transition-all p-1"
                                            onClick={() => {
                                              setCheckOption(true);
                                              setBtnCheck(false);
                                            }}
                                          >
                                            <FiEdit className="w-4 h-4" />
                                          </button>{" "}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>{" "}
                            <hr />
                            {/* Paid & Total Net Price*/}
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Hotel Net Price</p>
                                </div>
                                <div className="w-full">
                                  <div className="flex items-center gap-x-1 font-semibold text-slate-900">
                                    {selectedSingleRoom.net_price_data &&
                                    Object.keys(selectedSingleRoom.net_price_data).length > 0 ? (
                                      (() => {
                                        const totalNetPrice = selectedSingleRoom?.net_price_data?.reduce(
                                          (total, item) => {
                                            const price = parseFloat(item.net_price) || 0;
                                            return total + price;
                                          },
                                          0,
                                        );

                                        return (
                                          <>
                                            <p>{totalNetPrice}</p>
                                            <p className="text-sm">{selectedSingleRoom.currency_type}</p>
                                          </>
                                        );
                                      })()
                                    ) : (
                                      <>
                                        <p>{selectedSingleRoom.net_price}</p>
                                        <p className="text-sm">{selectedSingleRoom.currency_type}</p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Status</p>
                                </div>
                                <div className="w-full font-semibold text-slate-900 ">
                                  <p className="flex items-center gap-x-1">New</p>
                                </div>
                              </div>
                            </div>{" "}
                            <hr />
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Paid </p>
                                </div>
                                <div className="w-full">
                                  <div className="flex items-center gap-x-1 font-semibold text-slate-900 ">
                                    <p>
                                      {" "}
                                      {selectedTransaction?.results &&
                                        selectedTransaction?.results
                                          .filter((item) => item.transaction_status === "confirmed")
                                          .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0)}{" "}
                                    </p>
                                    <p className="text-sm">{selectedSingleRoom.currency_type}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 font-semibold text-slate-900  ">
                                  <p className="flex items-center gap-x-1 font-bold">Cars</p>
                                </div>
                                <div className="w-full">
                                  <p className="flex items-center gap-x-1 font-semibold text-slate-900 ">
                                    Yes <MdOutlineDone className="w-5 h-5  text-green-500" />
                                  </p>
                                </div>
                              </div>
                            </div>{" "}
                            <hr />
                            {/* Total Profit & Booking Status*/}
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Total Profit </p>
                                </div>
                                <div className="w-full">
                                  <div className="flex items-center gap-x-1 font-semibold text-slate-900 ">
                                    <p>
                                      {" "}
                                      {Number(selectedSingleRoom.total_price) -
                                        (Number(selectedSingleRoom.net_price)
                                          ? Number(selectedSingleRoom.net_price)
                                          : selectedSingleRoom.net_price_data
                                              ?.map((price_data) => Number(price_data.net_price))
                                              ?.reduce((acc, price) => {
                                                return acc + price;
                                              }, 0))}
                                    </p>
                                    <p className="text-sm">{selectedSingleRoom.currency_type}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300  ">
                                  <p className="flex items-center gap-x-1 font-bold">Meet & Assist Service</p>
                                </div>
                                <div className="w-full">
                                  <p className="flex items-center gap-x-1 font-semibold text-slate-900 ">
                                    Done <MdOutlineDone className="w-5 h-5  text-green-500" />
                                  </p>
                                </div>
                              </div>
                            </div>{" "}
                            <hr />
                            {/* Balance & Special Request*/}
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Outstanding Amount </p>
                                </div>
                                <div className="w-full">
                                  <div className="flex items-center gap-x-1 font-semibold text-slate-900">
                                    <p className="text-green-700  ">
                                      <p className={remainingAmount > 0 ? "text-red-700" : "text-green-700"}>
                                        {remainingAmount}
                                      </p>
                                    </p>
                                    <p className="text-sm">{selectedSingleRoom.currency_type}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Special Request</p>
                                </div>
                                <div className="w-full font-semibold text-slate-900 ">
                                  <p className="flex items-center gap-x-1">Done</p>
                                </div>
                              </div>
                            </div>{" "}
                            <hr />
                            {/*Transport To Hotel & Time Of Hotel Confirmation*/}
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold ">Transport To Hotel</p>
                                </div>
                                <div className="w-full">
                                  <p className="flex items-center gap-x-1 font-semibold text-slate-900">
                                    {" "}
                                    Yes <MdOutlineDone className="w-5 h-5  text-green-500" />{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Time Of Hotel Confirmation</p>
                                </div>
                                <div className="w-full">
                                  {selectedSingleRoom?.operations?.some(
                                    (operation) => operation.hotel_reservation_id,
                                  ) ? (
                                    <div className="flex items-center gap-x-1 font-semibold text-slate-900">
                                      {selectedSingleRoom?.operations.map((operation) => {
                                        const formattedDate = dayjs(operation?.time_of_hotel_confirmation).format(
                                          "YYYY-MM-DD HH:mm",
                                        );

                                        return <p className=" text-base font-medium ">{formattedDate}</p>;
                                      })}
                                    </div>
                                  ) : (
                                    <div className="flex items-center gap-x-1 font-semibold text-slate-900">
                                      {hotelId && optionDate ? (
                                        <div className="flex items-center gap-x-4 ">
                                          <p className="flex items-center gap-x-1">
                                            <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />
                                            {currentDateShow}
                                          </p>
                                          <p className="flex items-center gap-x-1">
                                            <MdOutlineAccessTime className="w-5 h-5 text-[#002244]" />
                                            {currentTimeShow}
                                          </p>
                                        </div>
                                      ) : (
                                        "Hotel Id Or Option Date is Empty"
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>{" "}
                            <hr />
                            {/* Time Average Response*/}
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Response Time :</p>
                                </div>
                                <div className="w-full font-semibold text-slate-900">
                                  {selectedSingleRoom?.operations?.some(
                                    (operation) => operation.hotel_reservation_id,
                                  ) ? (
                                    <div id="hotel_reservation_id " className="w-full pl-4">
                                      {selectedSingleRoom?.operations.map((operation) => {
                                        function formatTimeAverageResponse(timeResponse) {
                                          // Split the input by spaces to separate the day and time
                                          const parts = timeResponse.split(" ");

                                          // If there are two parts, the first part is the day, and the second part is the time
                                          if (parts.length === 2) {
                                            return `${parts[0]} days ${parts[1]}`;
                                          }

                                          // If there's only one part, it's just the time, so assume 0 days
                                          return `0 days ${parts[0]}`;
                                        }

                                        return (
                                          <p className=" text-base font-medium ">
                                            {formatTimeAverageResponse(operation?.time_average_response)}
                                          </p>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <p className="flex items-center gap-x-1">{responseTimeShow}</p>
                                  )}
                                </div>
                              </div>

                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Source Channel : </p>
                                </div>
                                <div className="w-full font-semibold text-slate-900">
                                  <p className="flex items-center gap-x-1">{selectedSingleRoom?.source_channel} </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Source : </p>
                                </div>
                                <div className="w-full font-semibold text-slate-900">
                                  <p className="flex items-center gap-x-1">{selectedSingleRoom?.user_type} </p>
                                </div>
                              </div>

                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">User Name :</p>
                                </div>
                                <div className="w-full font-semibold text-slate-900">
                                  <p className="flex items-center gap-x-1">{selectedSingleRoom?.booked_by} </p>
                                </div>
                              </div>
                            </div>{" "}
                            <hr />
                            {/* Time Average Response*/}
                            <div className="flex w-full justify-between items-center input-par">
                              <div className="flex  justify-between items-center input-chil ">
                                <div className="w-full bg-gray-300 ">
                                  <p className="flex items-center gap-x-1 font-bold">Refund Amount</p>
                                </div>
                                <div className="w-full font-semibold text-slate-900">
                                  {/* <p className="flex items-center gap-x-1">{responseTimeShow}</p> */}
                                </div>
                              </div>
                            </div>{" "}
                            <hr />
                            {permission.map((item) => {
                              if (item.name === "room_booking.change_bookedroom" && item.value === true) {
                                return (
                                  <div className="flex items-center justify-end gap-x-2 p-2">
                                    {operationBookingStatus === "pending cancelation" || !operationBookingStatus ? (
                                      convertAddUpdata ? (
                                        <button
                                          disabled={operationUpdataFlag}
                                          onClick={handleUpdateOperation}
                                          className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                                        >
                                          {operationUpdataFlag ? (
                                            <>
                                              <TopBarProgress />
                                              <ButtonLoading />
                                            </>
                                          ) : (
                                            <RxUpdate className="w-4 h-4" />
                                          )}{" "}
                                          Update Operation
                                        </button>
                                      ) : (
                                        <button
                                          disabled={operationFlag}
                                          onClick={handleOperation}
                                          className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                                        >
                                          {operationFlag ? (
                                            <>
                                              <TopBarProgress />
                                              <ButtonLoading />
                                            </>
                                          ) : (
                                            <LuSend className="w-4 h-4" />
                                          )}{" "}
                                          Add Operation
                                        </button>
                                      )
                                    ) : null}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <button onClick={()=>setBtnCheck(true)}
                                className='px-5 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all'>
                          {btnCheck ? " Saved": "Save" } 
                          </button>    */}
                  </div>
                </div>{" "}
              </>

              <div className="mt-5">
                <Payments />
              </div>
            </div>
          </>
        ) : (
          <div className="w-full h-screen flex items-center justify-center">
            <>
              <TopBarProgress /> <Loading />
            </>
          </div>
        )}
        <div className="p-10 mx-auto">
          {/* {selectedSingleRoom.length > 0 
    ?  <Box>    
        <DataGrid
            rows={rows}
            columns={columns}
        />
    </Box>
    : ""} */}
        </div>
      </div>
      <NotificationContainer />

      {/* Cancel Order PopUp */}
      {
        <div
          className={` fixed ${
            cancelPopup ? "top-0 left-0 right-0 bottom-0 " : ""
          }  blur-effect-theme   w-full opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-white  max-w-lg w-full absolute right-0   p-5  ${
              cancelPopup
                ? "opacity-100 visible  top-56 right-[550px] transition-all duration-500 transform border-gray-300 border rounded-lg"
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <label className="text-gray-800 font-semibold ">Cancellation Reason</label>

            <Select
              onChange={setSelectedCancellationReason}
              options={cancellationReasonsArr}
              placeholder="Choose your cancellation reason"
              className="mt-3"
              isDisabled={isCancelling}
            />

            <div className="flex justify-around m-2">
              <button
                onClick={handleCancellingBooking}
                disabled={!selectedCancelationReason}
                className=" flex gap-1 disabled:cursor-not-allowed disabled:bg-gray-400 py-1 px-2 bg-green-800 hover:bg-green-900 duration-300 transition-all text-white  font-semibold rounded-md"
              >
                Submit{" "}
                {isCancelling && (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                )}
              </button>
              <button
                className="py-1 px-2 bg-red-800 hover:bg-red-900 duration-300 transition-all text-white  font-semibold rounded-md"
                onClick={() => setCancelPopUp(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      }

      {/* 
      
          Confirm Cancel PopUp
      
      */}

      {
        <div
          className={` fixed ${
            confirmCancelPopup ? "top-0 left-0 right-0 bottom-0 " : ""
          }  blur-effect-theme   w-full opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-white  max-w-lg w-full absolute right-0   p-5  ${
              confirmCancelPopup
                ? "opacity-100 visible  top-[30%] right-[50%] translate-x-[50%] translate-y-[50%] transition-all duration-500 transform border-gray-300 border rounded-lg"
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <h5 className="text-gray-800 text-center font-semibold ">Confirm cancelation</h5>

            <div className="flex justify-around m-2">
              <button
                onClick={handleConfirmCancellingBooking}
                className=" flex gap-1 disabled:cursor-not-allowed disabled:bg-gray-400 py-1 px-2 bg-green-800 hover:bg-green-900 duration-300 transition-all text-white  font-semibold rounded-md"
              >
                Confirm{" "}
                {isConfirmingCancelling && (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                )}
              </button>
              <button
                className="py-1 px-2 bg-red-800 hover:bg-red-900 duration-300 transition-all text-white  font-semibold rounded-md"
                onClick={() => setConfirmCancelPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      }

      {
        <div
          className={` fixed ${
            archivePopup ? "top-0 left-0 right-0 bottom-0 " : ""
          }  blur-effect-theme   w-full opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-white  max-w-lg w-full absolute right-0   p-5  ${
              archivePopup
                ? "opacity-100 visible  top-[30%] right-[50%] translate-x-[50%] translate-y-[50%] transition-all duration-500 transform border-gray-300 border rounded-lg"
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <h5 className="text-gray-800 text-center font-semibold ">Archive Booking</h5>

            <div className="flex justify-around m-2">
              <button
                onClick={handleArchivingBooking}
                className=" flex gap-1 disabled:cursor-not-allowed disabled:bg-gray-400 py-1 px-2 bg-green-800 hover:bg-green-900 duration-300 transition-all text-white  font-semibold rounded-md"
              >
                Confirm{" "}
                {isArchiving && (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                )}
              </button>
              <button
                className="py-1 px-2 bg-red-800 hover:bg-red-900 duration-300 transition-all text-white  font-semibold rounded-md"
                onClick={() => setArchivePopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default BookingListTableSingleId;
