import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading.js";
import { useQuery } from "@tanstack/react-query";
import ChangeSTatus from "./ChangeSTatus";
import usePermissions from "../../../customHooks/usePermissions.js";
function stripHTMLTags(input) {
  // Use a regular expression to remove any HTML tags
  return input.replace(/<\/?[^>]+(>|$)/g, "");
}
const ViewTask = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [taskData, setTaskData] = useState("");
  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "tasks_management.view_taskassignment",
    "tasks_management.delete_taskassignment",
    "tasks_management.change_taskassignment",
  ]);
  const { id } = useParams();
  const isEditingOfferAllowed =
    permissions?.find((permission) => permission.name === "tasks_management.change_taskassignment")?.value === true;
  const {
    data: Task,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["Task", id],
    queryFn: () => fetchDataQuery(`/tasks-management/api/v1/task-assignments/${id}/`),
  });

  useEffect(() => {
    if (isSuccess && Task) {
      setTaskData(Task);
    }
  }, [Task, isSuccess]);
  const userIds = taskData?.user?.map((i) => i.id);
  // const {
  //   data: departmentData,
  //   error: departmentErr,
  //   isSuccess: departmentDataSuccess,
  //   isLoadingTask: deparmentLoading,
  // } = useQuery({
  //   queryKey: ["departmentData"],
  //   queryFn: () => fetchDataQuery(`/department/api/v1/department?pagination=false&users=[${userIds}]`),
  //   enabled: userIds?.length > 0,
  // });
  // const departmentOptions = useMemo(
  //   () => departmentData?.map((departmen) => ({ label: departmen.name, value: departmen.id })),
  //   [departmentData],
  // );
  const closeModal = () => setIsModalOpen(false);
  return isSuccess && taskData ? (
    <section className="formPage-wrap flex flex-col gap-8 p-6 rounded-lg shadow-md">
      <div className=" flex sm:flex-row flex-col sm:justify-between justify-center items-center sm:w-full w-max ">
        <h2 className="text-2xl font-bold text-gray-800">Task #{id} Details</h2>
        {isEditingOfferAllowed && (
          <Link className="updateButton max-sm" to={`/dashboard/Edit-task/${id}/`}>
            Edit
          </Link>
        )}
      </div>
      {true && (
        <div className="w-full justify-center items-center flex">
          <ChangeSTatus />
        </div>
      )}
      <div className="form-wrap gap-4 flex flex-col ">
        <div className="input-par">
          <span className="input-chil flex gap-2 justify-between bg-gray-100 px-4 py-2 rounded-md shadow-sm">
            <label htmlFor="" className="text-gray-700">
              Task Name:
            </label>
            <span>{taskData?.name}</span>
          </span>
          <span className="input-chil flex gap-2 justify-between bg-gray-100 px-4 py-2 rounded-md shadow-sm">
            <label htmlFor="" className="text-gray-700">
              Task Type:
            </label>{" "}
            <span>{taskData?.task_type?.name}</span>{" "}
          </span>
        </div>
        <div className="input-par">
          <span className="input-chil flex gap-2 justify-between bg-gray-100 px-4 py-2 rounded-md shadow-sm">
            <label htmlFor="" className="text-gray-700">
              {taskData.department?.length > 1 ? "Departments:" : "Department:"}
            </label>
            <span className="flex gap-2 flex-wrap">
              {taskData?.department?.map((d) => (
                <p className="bg-gray-50 w-max h-max px-2 py-1.5 rounded-md" key={d.id}>
                  {d?.name}
                </p>
              ))}
            </span>
          </span>
          <span className="input-chil flex gap-2 justify-between bg-gray-100 px-4 py-2 rounded-md shadow-sm">
            <label htmlFor="user" className="text-gray-700">
              {taskData?.user.length > 1 ? "Users:" : "User:"}{" "}
            </label>
            <span className="flex gap-2 flex-wrap">
              {taskData?.user?.map((u) => (
                <p className="bg-gray-50 w-max h-max px-2 py-1.5 rounded-md" key={u.id}>
                  {u?.first_name + u?.last_name + "(" + u?.username + ")"}
                </p>
              ))}
            </span>{" "}
          </span>
        </div>
        <div className="input-par">
          <span className="w-max flex gap-2 justify-between bg-gray-100 px-4 py-2 rounded-md shadow-sm">
            <label htmlFor="" className="text-gray-700">
              Task Status :
            </label>
            <span>{taskData?.status}</span>
          </span>
        </div>
        <span className="w-full flex gap-2 justify-between bg-gray-100 px-4 py-2 rounded-md shadow-sm">
          <label htmlFor="" className="text-gray-700">
            Details:{" "}
          </label>{" "}
          <span className="text-justify">{<div dangerouslySetInnerHTML={{ __html: taskData?.task_details }} />}</span>{" "}
        </span>
        <div className="flex flex-col gap-2">
          <span className="font-semibold text-gray-700">Document:</span>
          <div className="border bg-gray-100 p-2 max-w-max w-[350px] h-full rounded-md shadow-sm hover:shadow-md transition-shadow duration-200">
            {taskData?.attachment ? (
              /\.(jpg|jpeg|png|gif)$/.test(taskData?.attachment) ? (
                <>
                  <img
                    src={taskData?.attachment}
                    alt="Document"
                    className="max-w-full h-auto rounded-md cursor-pointer"
                    onClick={() => openModal(taskData?.attachment)}
                  />
                  <Modal visible={isModalOpen} footer={null} onCancel={closeModal} centered bodyStyle={{ padding: 0 }}>
                    <img src={modalImageSrc} alt="Document Preview" className="max-w-full h-auto rounded-md" />
                  </Modal>
                </>
              ) : (
                <a
                  href={taskData?.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  Download Document
                </a>
              )
            ) : (
              <p>No document available</p>
            )}
          </div>
        </div>
      </div>
    </section>
  ) : (
    <Loading />
  );
};

export default ViewTask;
