import React from "react";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import Select from "react-select";
import { Link, useSearchParams } from "react-router-dom";
import { Flex } from "antd";
import { Button } from "flowbite-react";
import { FieldErrorText } from "../../../../../customComponents/FormComponents";

const UserSelector = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, data, isError } = useQuery({
    queryKey: ["contracting-users"],
    queryFn: async () => {
      return fetchDataQuery("/department/api/v1/users/?pagination=false&specific_department=Contracting");
    },
  });

  if (data?.error) {
    return (
      <Flex wrap gap={"middle"} align={"center"}>
        <p className="text-red-800 font-bold">{"To Filter by users create Contracting Department"}</p>
        <Link to={"/dashboard/departments-management/create-department"}>
          <Button color={"success"}>Create Department</Button>
        </Link>
      </Flex>
    );
  }

  const selectedUser = data?.find((user) => user.id === parseInt(searchParams.get("created_by")));

  return (
    <>
      <Select
        isClearable={true}
        placeholder={"Filter by contracting users"}
        value={
          searchParams.get("created_by") && selectedUser
            ? {
                label: `${selectedUser?.first_name} ${selectedUser?.last_name} (${selectedUser?.username})`,
                value: selectedUser?.id,
              }
            : null
        }
        onChange={(selectedOption, actionMeta) => {
          if (!selectedOption) {
            setSearchParams((prev) => {
              const newParams = new URLSearchParams(prev);
              newParams.delete("created_by");
              return newParams;
            });
            return;
          }
          setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev);
            newParams.set("created_by", selectedOption.value);
            return newParams;
          });
        }}
        isLoading={isLoading}
        isDisabled={isLoading}
        options={data?.map((user) => {
          return { label: `${user?.first_name} ${user?.last_name} (${user?.username})`, value: user?.id };
        })}
      />
      {isError && <FieldErrorText message={"Error fetching contracting users"} />}
    </>
  );
};

export default UserSelector;
