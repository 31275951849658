import React from "react";
import { BsFillCreditCardFill } from "react-icons/bs";
import { VscAdd } from "react-icons/vsc";
import { Link } from "react-router-dom";

const AddPost = () => {
  return (
    <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
      <Link
        to={"/dashboard/accounting-setting/pos-machine"}
        // onClick={() => navigate("./pos-machine")}
        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
      >
        <div className="w-full flex justify-center items-center ">
          <div className="relative flex flex-col">
            <BsFillCreditCardFill className="w-12 h-12  " />
            <VscAdd className="absolute top-10 left-12 w-4 h-4" />
          </div>
        </div>
      </Link>
      <p className="text-lg font-semibold text">Create POS Machine</p>
    </div>
  );
};

export default AddPost;
