import React, { useState } from "react";
import { FaWpforms } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router";
import { updateCollectionAction } from "../../../redux/actions/collectionAction";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
import { RxUpdate } from "react-icons/rx";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";

const CollectionRequestUpdate = () => {
  const [collection, setcollection] = useState("");
  const [check, setCheck] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();
  const location = useLocation();
  const { bookedRoom } = location.state;
  const { collectionRequest } = location.state;
  const { collectionFlag } = useSelector((state) => state.collectionReducer);
  const { collectionData } = useSelector((state) => state.collectionReducer);
  const { collectionDataFlag } = useSelector((state) => state.collectionReducer);

  const updateCollectionData = {
    booked_room: bookedRoom,
    representative_collection: collection,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateCollectionAction(token, updateCollectionData, params.id, (result) => {
        if (result.status === 200) {
          lightToast.success("The Collection Request was completed successfully");
          navigate(-1);
        }
      }),
    );
  };

  return (
    <div className="w-full flex flex-col mt-10 border-gray-300 border rounded-lg px-2">
      <div className="flex flex-col items-center justify-center h-full w-full ">
        {collectionData ? (
          <div className=" w-[70%]  border-gray-300 border rounded-lg p-5 ">
            <div className=" flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
              <p className="flex items-center gap-x-3 text-md">
                <FaWpforms className="w-5 h-5" />
                Request a representative
              </p>
            </div>
            <form className="mt-2  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
              <div className="w-full flex flex-col justify-center items-center gap-y-7">
                <div className="flex items-center justify-between w-full mt-5">
                  <div className="flex items-center  gap-x-1 w-[50%] ">
                    <label className=" text-center w-full text-gray-600   text-lg ">First night collection</label>
                    <input
                      placeholder="Employee Name"
                      name="check"
                      type="radio"
                      value="first_night_collection"
                      required
                      onChange={(e) => setcollection(e.target.value)}
                      className=" w-full h-6  border rounded-lg bg-white "
                    />
                  </div>
                  <div className="flex items-center gap-x-1 w-[50%]">
                    <label className=" flex text-gray-600  w-full text-lg ">All Stay Collection </label>
                    <input
                      placeholder="Total Amount"
                      name="check"
                      type="radio"
                      value="all_stay_collection"
                      required
                      onChange={(e) => setcollection(e.target.value)}
                      className=" w-full h-6 border rounded-lg bg-white "
                    />
                  </div>
                </div>

                <button
                  type="Submit"
                  className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  Update {collectionFlag ? <ButtonLoading /> : <RxUpdate className="w-5 h-5" />}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <>
            <TopBarProgress /> <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default CollectionRequestUpdate;
