import React from "react";
import fetchDataQuery from "../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { FieldErrorText, FormLabel } from "../FormComponents";

const CoustomHotel = ({ onChange, city, area, Allarea, Getlocal = {} }) => {
  const {
    data: HotelData,
    error: HotelError,
    isLoading: Hotelloading,
  } = useQuery({
    queryKey: ["hotel-filter", city, area, Allarea],
    queryFn: ({ queryKey }) => {
      const [_key, city, area, Allarea] = queryKey;

      const areasToSend = Array.isArray(Allarea) && Allarea?.length > 0 ? Allarea : area && [area];

      const AreasStorage =
        Array.isArray(Getlocal?.Allarea) && Getlocal?.Allarea?.length > 0
          ? Getlocal?.Allarea
          : Getlocal?.area?.value && [Getlocal?.area?.value];

      return fetchDataQuery(
        Getlocal?.city?.value
          ? `/en/hotels/api/v1/hotel-filter/?cities=${JSON.stringify([Getlocal?.city?.value])}&areas=${JSON.stringify(AreasStorage)}`
          : areasToSend &&
              `/en/hotels/api/v1/hotel-filter/?cities=${JSON.stringify([city])}&areas=${JSON.stringify(areasToSend)}`,
      );
    },
    enabled:
      (!!city && !!area) ||
      (!!city && !!Allarea) ||
      (!!Getlocal?.city?.value && !!Getlocal?.Allarea) ||
      (!!Getlocal?.city?.value && !!Getlocal?.area?.value),
  });

  const dataSelector = HotelData
    ? HotelData?.map((Hotel) => ({
        value: Hotel.id,
        label: Hotel.name,
        payment_type: Hotel.payment_type, // New field added
      }))
    : [];

  return (
    <div className="w-full">
      {" "}
      <FormLabel label={"Hotel"} htmlFor={"Hotel"} />
      <Select
        key={city}
        name="hotel"
        id="hotel"
        isLoading={Hotelloading}
        isDisabled={Hotelloading}
        onChange={(e) => {
          onChange("hotel", e);
        }}
        loadingMessage={() => "Getting hotel"}
        placeholder="Choose hotel"
        options={dataSelector}
        defaultValue={!city && Getlocal?.hotel}
      />
      {HotelError && <FieldErrorText message={"Error fetching cities"} />}
    </div>
  );
};

export default CoustomHotel;
