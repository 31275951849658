import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookingRequestsList, updateBookingStatus } from "../../../redux/actions/bookingRequests";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router";
import "./BookingRequestsList.scss";
import StarRating from "../../atoms/StarRating/StarRating";
import { AiFillEye } from "react-icons/ai";
import cookie from "react-cookies";
import { formatMilliSeconds } from "../BookingHubTable/BookingHubTable";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
import { Link } from "react-router-dom";

export default function BookingRequestsList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [popup, setPopup] = useState(null);
  const bookingRequests = useSelector((state) => state.bookingRequests);

  useEffect(() => {
    dispatch(getBookingRequestsList(id, () => setIsLoading(false)));
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      renderCell: renderId,
    },
    {
      field: "hotelName",
      headerName: "Hotel Name",
      minWidth: 100,
      flex: 2,
    },
    {
      field: "title",
      headerName: "Request Type",
      minWidth: 100,
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "date",
      headerName: "Created Date",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1.5,
      renderCell: renderStatusCell,
    },
    {
      field: "priority",
      headerName: "Priority",
      minWidth: 150,
      flex: 2,
      renderCell: renderPriorityCell,
    },
    {
      field: "responseRateing",
      headerName: "Response Rating",
      minWidth: 100,
      flex: 1,
      renderCell: renderRatingCell,
    },
    {
      field: "responseTime",
      headerName: "Response Time",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "avgTime",
      headerName: "Average Time (day/hour/min)",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "closeRequest",
      headerName: "Close Request",
      minWidth: 150,
      flex: 1,
      renderCell: renderCloseRequest,
    },
    {
      field: "assignee",
      headerName: "Assignee",
      minWidth: 100,
      flex: 1,
    },
  ];

  const rows = bookingRequests.map((request) => {
    let avgTime = null;
    if (request.booking_status === "closed") {
      let beginDate = new Date(request.created_date);
      let endDate = new Date(request.closed_timestamp);
      avgTime = endDate - beginDate;
    }
    return {
      id: request.id,
      hotelName: request.hotel_name,
      title: request.other_note ? `Other: ${request.other_note}` : request.request_type.types,
      description: request.description,
      date: request.created_date.split("T")[0],
      status: request.booking_status,
      priority: request.request_priority,
      responseRateing: request.response_rating || "----",
      responseTime: request.response_time.split("T")[0],
      avgTime: avgTime ? formatMilliSeconds(avgTime) : "----",
      assignee: request.first_admin_response_to_initial_message
        ? request.first_admin_response_to_initial_message.sender_name
        : "----",
    };
  });

  function closeRequest(params) {
    const data = { booking_status: "closed" };
    dispatch(updateBookingStatus(params.row.id, data, () => dispatch(getBookingRequestsList(id))));
  }

  function renderCloseRequest(params) {
    return (
      <button className="p-2 text-white bg-blue-900 rounded-lg" onClick={() => closeRequest(params)}>
        Close request
      </button>
    );
  }

  function renderId(params) {
    return (
      <div className="flex items-center">
        {params.value}{" "}
        <Link to={`/dashboard/booking-request-details/${params.row.id}`}>
          <AiFillEye
            className="w-5 h-5"
            // onClick={() => navigate(`/dashboard/booking-request-details/${params.row.id}`)}
          />
        </Link>
      </div>
    );
  }

  function renderStatusCell(params) {
    const status = params.value;

    let backgroundColor = ""; // Default background color

    if (status === "new") {
      backgroundColor = "#00ce6c"; // Background color for new status
    } else if (status === "closed") {
      backgroundColor = "#ADD8E6"; // Background color for old status
    } else if (status === "pending") {
      backgroundColor = "#faab3a";
    }

    return (
      <div
        style={{
          backgroundColor,
          padding: "8px",
          borderRadius: "4px",
          width: "100%",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setPopup(params);
        }}
      >
        {params.value}
      </div>
    );
  }

  function renderPriorityCell(params) {
    const prioty = params.value;

    let backgroundColor = ""; // Default background color

    if (prioty === "urgent_priority") {
      backgroundColor = "red"; // Background color for new status
    }

    return (
      <div style={{ backgroundColor, padding: "8px", borderRadius: "4px", width: "100%", textAlign: "center" }}>
        {params.value}
      </div>
    );
  }

  function renderRatingCell(params) {
    return <StarRating starsCount={Number(5)} starSize={25} width="80px" />;
  }

  function handleUpdate(e) {
    e.preventDefault();
    const data = {
      booking_status: e.target.status.value,
      note: e.target.note.value,
    };
    dispatch(updateBookingStatus(popup.row.id, data, () => dispatch(getBookingRequestsList(id, () => setPopup(null)))));
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["room_booking.add_bookingrequest", "room_booking.view_bookingrequest"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div>
      {isLoading && !permissionFlag ? (
        <>
          <Loading />
          <TopBarProgress />{" "}
        </>
      ) : (
        <>
          {permissionFlag && permission.some((item) => item.value) ? (
            <DataGrid
              className="data-grid"
              columns={columns}
              rowCount={bookingRequests ? bookingRequests.length : 5}
              rows={rows}
              slots={{
                Toolbar: GridToolbar,
              }}
            />
          ) : (
            <div className="w-full h-screen">
              <NoPermission />
            </div>
          )}

          {popup && (
            <div className="border border-black rounded-xl w-max p-10 fixed top-[20vh] left-[45vw] bg-white">
              <p className="text-base ">Hotel Name: {popup.row.hotelName}</p>
              <p className="text-base">Title: {popup.row.title}</p>
              <p className="text-base">Date: {popup.row.date}</p>
              <p className="text-base">Priority: {popup.row.priority}</p>
              <p className="text-base">Description: {popup.row.description}</p>
              <form onSubmit={handleUpdate}>
                <div className="flex gap-5">
                  <label htmlFor="status" className="text-base">
                    Status
                  </label>
                  <select name="status" id="status" className="my-1 border border-black rounded-lg">
                    <option value="new" className="test-base">
                      New
                    </option>
                    <option value="pending" className="test-base">
                      Pending
                    </option>
                    <option value="closed" className="test-base">
                      Closed
                    </option>
                  </select>
                </div>
                <div className="flex gap-5">
                  <label htmlFor="note" className="text-base">
                    Note
                  </label>
                  <textarea type="text" name="note" id="note" className="my-1 border border-black rounded-lg" />
                </div>
                <br />
                <div className="flex justify-between">
                  <input type="submit" value="Update" className="text-base p-2 bg-blue-900 rounded-xl text-white" />
                  <button
                    className="text-base p-2 bg-red-900 rounded-xl text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      setPopup(null);
                    }}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          )}
        </>
      )}
    </div>
  );
}
