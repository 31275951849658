import { GET_ALL_ROOMS, GET_HOTEL_ROOMS, GET_ROOM_DETAILS, CLEAR_HOTEL_ROOMS } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getAllRoomsSuccessfull(rooms) {
  return {
    type: GET_ALL_ROOMS,
    payload: rooms,
  };
}

function getHotelRoomsSuccessfull(rooms) {
  return {
    type: GET_HOTEL_ROOMS,
    payload: rooms,
  };
}

function getRoomDetailsSuccessfull(room) {
  return {
    type: GET_ROOM_DETAILS,
    payload: room,
  };
}

export function getAllRooms() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/en/rooms/api/v1/rooms-list/");
      dispatch(getAllRoomsSuccessfull(response.data.results));
    } catch (error) {}
  };
}

export function getHotelRooms(hotelId, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/en/rooms/api/v1/get-rooms-in-hotel/${hotelId}/`);
      dispatch(getHotelRoomsSuccessfull(response.data));
      callback(response);
    } catch (error) {}
  };
}

export function getRoomDetails(id, callBack) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/en/rooms/api/v1/room-detail-id/${id}/`);
      dispatch(getRoomDetailsSuccessfull(response.data));
      callBack(response);
    } catch (error) {}
  };
}

export function deleteRoom(id, callback) {
  return async function () {
    try {
      const response = await api_token(token).delete(`/en/rooms/api/v1/delete-room-id/${id}/`);
      callback(response);
    } catch (error) {}
  };
}

export function clearHotelRooms() {
  return {
    type: CLEAR_HOTEL_ROOMS,
    payload: [],
  };
}
