import { LuUpload } from "react-icons/lu";

import { ErrorMessage, Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import { GoPerson } from "react-icons/go";
import { MdOutlineMail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaMinusCircle, FaMoneyBillAlt, FaPlusCircle, FaRegAddressCard } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { GrNotes } from "react-icons/gr";
import React from "react";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { flushSync } from "react-dom";
import { HiXMark } from "react-icons/hi2";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Select from "react-select";
import {
  FieldErrorText,
  FormCheckboxInput,
  FormLabel,
  FormPhoneInput,
  FormSection,
  FormTextInput,
} from "../../../../customComponents/FormComponents";

const AgentForm = ({
  title,
  onSubmit,
  validationSchema,
  initialValues,
  image,
  setImage,
  isPending,
  submitButtonTitle,
}) => {
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      flushSync(() => {
        setImage((prev) => {
          return {
            ...prev,
            src: URL.createObjectURL(event.target.files[0]),
            file: event.target.files[0],
          };
        });
      });
      event.target.value = null;
    }
  };

  // const [marketTypes, setMarket] = useState([]);
  // const marketTypes = marketTypesVal.map((val) => {
  //   return { label: val.name, value: val.id };
  // });

  // const { marketTypes } = useSelector((state) => state.addContract);
  // en/site-settings/api/market
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({ values, setValues, errors }) => {
        return (
          <div className="formPage-wrap">
            <Form className="space-y-6 mt-2 w-full">
              <FormSection title="Basic Info">
                <div className="flex items-center relative justify-center">
                  <img src={image.src} className="rounded-full object-cover w-36 h-36 bg-gray-600 mt-2" alt={""} />
                  <input id={"image-upload"} type="file" onChange={onImageChange} className="hidden" />

                  <label
                    htmlFor={"image-upload"}
                    className="absolute cursor-pointer p-3 bg-[#002230] hover:bg-[#002244] cursor:pointer  flex items-center justify-center rounded-full  text-white bottom-0 left-[53%] hover:scale-105 active:scale-95 duration-300 transition-all  "
                  >
                    <LuUpload className="w-5 h-5 " />
                  </label>

                  {image.src && (
                    <button
                      onClick={() => {
                        setImage((prev) => {
                          return {
                            ...prev,
                            src: "",
                            file: null,
                          };
                        });
                      }}
                      className=" absolute rounded-full  top-[.5rem] left-[53%] hover:scale-105 active:scale-95 duration-300 transition-all cursor-pointer p-3 bg-red-900 hover:bg-red-700"
                    >
                      <HiXMark className=" text-white w-5 h-5" />
                    </button>
                  )}
                </div>
                {/* <FormTextInput label="Name" name="name" type="text" placeholder="Agent name" Icon={GoPerson} /> */}
                <div className="flex gap-2">
                  <FormTextInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    placeholder="Agent first name"
                    Icon={GoPerson}
                  />
                  <FormTextInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                    placeholder="Agent last name"
                    Icon={GoPerson}
                  />
                </div>

                <FormTextInput
                  label="Username"
                  name="username"
                  type="text"
                  placeholder="Agent username"
                  Icon={GoPerson}
                />
                <FormTextInput
                  label="Nickname"
                  name="nickname"
                  type="text"
                  placeholder="Agent nickname"
                  Icon={GoPerson}
                />
                <FormTextInput label="Email" name="email" type="text" placeholder="Agent email" Icon={MdOutlineMail} />
                <FormTextInput
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Agent password"
                  Icon={RiLockPasswordLine}
                />

                <FormTextInput
                  label="ID Number"
                  name="id"
                  type="text"
                  placeholder="Agent ID number"
                  Icon={FaRegAddressCard}
                />

                <CountrySelectInput />

                <CitySelectInput />

                <div>
                  <FormLabel label={"Mobile Numbers"} htmlFor={"mobileNumbers"} />

                  <FieldArray
                    name="mobileNumbers"
                    render={(arrayHelpers) => (
                      <div>
                        <div role="group" aria-labelledby="checkbox-group">
                          {values.mobileNumbers && values.mobileNumbers.length > 0 ? (
                            values.mobileNumbers.map((mobileNumber, index) => (
                              <div key={index} className="flex items-center mb-2 justify-between gap-4 w-full">
                                <div className={"w-full"}>
                                  <FormPhoneInput
                                    placeholder={`Mobile number ${index + 1}`}
                                    name={`mobileNumbers[${index}].num`}
                                  />
                                </div>
                                <FormCheckboxInput
                                  onChange={() => {
                                    setValues({
                                      ...values,
                                      mobileNumbers: values.mobileNumbers.map((item, i) =>
                                        i === index
                                          ? {
                                              ...item,
                                              whatsapp: !item.whatsapp,
                                            }
                                          : item,
                                      ),
                                    });
                                  }}
                                  checked={mobileNumber.whatsapp}
                                  name={`mobileNumbers[${index}].whatsapp`}
                                  value={`mobileNumbers[${index}].whatsapp`}
                                >
                                  <IoLogoWhatsapp className=" cursor-pointer w-6 h-6 text-green-500" />
                                </FormCheckboxInput>

                                {index !== 0 && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }} // remove a mobile number from the list
                                  >
                                    <FaMinusCircle className="w-6 h-6 rounded-full text-red-700 hover:text-red-900" />
                                  </button>
                                )}
                              </div>
                            ))
                          ) : (
                            <></>
                          )}

                          {typeof errors.mobileNumbers === "string" ? (
                            <FieldErrorText message={errors.mobileNumbers} />
                          ) : null}

                          <div className="flex justify-end items-center  mt-2 ">
                            <button
                              className="rounded px-2 py-1  flex justify-center items-center gap-1  text-white bg-green-700 hover:bg-green-900 "
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  num: "+20",
                                  whatsapp: false,
                                })
                              }
                            >
                              <FaPlusCircle className="w-5 h-5 text-white" />
                              <p>New Mobile Number</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>

                <div>
                  <FormLabel label={"Email Addresses"} htmlFor={"emailAddresses"} />

                  <FieldArray
                    name="emailAddresses"
                    render={(arrayHelpers) => (
                      <div>
                        <div role="group" aria-labelledby="checkbox-group">
                          {values.emailAddresses && values.emailAddresses.length > 0 ? (
                            values.emailAddresses.map((emailAddress, index) => (
                              <div key={index} className="flex items-center mb-2 justify-between gap-4 w-full">
                                <FormTextInput
                                  type="email"
                                  placeholder={`Email ${index + 1}`}
                                  name={`emailAddresses.${index}`}
                                  Icon={MdOutlineMail}
                                />

                                {index !== 0 && (
                                  <button type="button" onClick={() => arrayHelpers.remove(index)}>
                                    <FaMinusCircle className="w-6 h-6 rounded-full text-red-700 hover:text-red-900" />
                                  </button>
                                )}
                              </div>
                            ))
                          ) : (
                            <></>
                          )}

                          {typeof errors.emailAddresses === "string" ? (
                            <FieldErrorText message={errors.emailAddresses} />
                          ) : null}

                          <div className="flex justify-end items-center  mt-2 ">
                            <button
                              className="rounded px-2 py-1  flex justify-center items-center gap-1  text-white bg-green-700 hover:bg-green-900 "
                              type="button"
                              onClick={() => arrayHelpers.push()}
                            >
                              <FaPlusCircle className="w-5 h-5 text-white" />
                              <p>New Email</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>

                {/*<div className="flex justify-around items-center">*/}
                {/*  <FormCheckboxInput*/}
                {/*    name={"active"}*/}
                {/*    defaultChecked={values.active}*/}
                {/*    onChange={() => {*/}
                {/*      void setValues({*/}
                {/*        ...values,*/}
                {/*        active: !values.active,*/}
                {/*      });*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <p className="font-semibold text-gray-600"> Active </p>*/}
                {/*  </FormCheckboxInput>*/}

                {/*  <FormCheckboxInput*/}
                {/*    name={"whitelist"}*/}
                {/*    defaultChecked={values.whitelist}*/}
                {/*    onChange={() => {*/}
                {/*      void setValues({*/}
                {/*        ...values,*/}
                {/*        whitelist: !values.whitelist,*/}
                {/*      });*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <p className="font-semibold text-gray-600"> Whitelist </p>*/}
                {/*  </FormCheckboxInput>*/}
                {/*</div>*/}
              </FormSection>

              <FormSection title="Hotels Department Settings">
                <MarketSelectInput />
                <MarkupSelectInput />
                <UserGroupSelectInput />
              </FormSection>

              <FormSection title="Hotels operation control">
                <NotificationPreferences />

                <div className="w-full">
                  <FormLabel label={"Notes"} htmlFor={"notes"} />

                  <div className="w-full relative text-gray-600">
                    <Field
                      id={"notes"}
                      name="notes"
                      className=" w-full py-2 px-8 h-10 border rounded-lg bg-white"
                      as="textarea"
                    />
                    <div className="absolute top-[11px] left-2">
                      <GrNotes className="w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                  <ErrorMessage name="notes" />
                </div>
              </FormSection>
              <FormSection title="Accounting Setting">
                <>
                  <FormLabel label={"Payment Method"} htmlFor={"paymentMethod"} />
                  <Select
                    value={{
                      value: values.paymentMethod,
                      label: values.paymentMethod === "cash" ? "Cash" : "Credit",
                    }}
                    onChange={(selectedOption) => {
                      void setValues({
                        ...values,
                        paymentMethod: selectedOption.value,
                      });
                    }}
                    options={[
                      { value: "cash", label: "Cash" },
                      { value: "credit", label: "Credit" },
                    ]}
                  />
                </>
                <FormTextInput
                  label="Credit Limit"
                  name="creditLimit"
                  type="number"
                  placeholder="Agent credit limit"
                  Icon={FaMoneyBillAlt}
                />

                <CurrencySelectInput />

                <div className="w-full">
                  <FormLabel label={"Notes"} htmlFor={"accountingNotes"} />

                  <div className="w-full relative text-gray-600">
                    <Field
                      id={"accountingNotes"}
                      name="accountingNotes"
                      className=" w-full py-2 px-8 h-10 border rounded-lg bg-white"
                      as="textarea"
                    />
                    <div className="absolute top-[11px] left-2">
                      <GrNotes className="w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                  <ErrorMessage name="accountingNotes" />
                </div>
              </FormSection>
              <div className="flex  justify-center items-center">
                <Button
                  className="mb-4"
                  color="success"
                  isProcessing={isPending}
                  type="submit"
                  disabled={isPending}
                  processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
                >
                  {submitButtonTitle}
                </Button>
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

const CurrencySelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingCurrencies,
    data: currencies,
    error: errorFetchingCurrencies,
  } = useQuery({
    queryKey: ["currencies"],
    queryFn: () => fetchDataQuery("/contracts/api/currency-types/"),
  });

  return (
    <div>
      <FormLabel label={"Credit Limit Currency"} htmlFor={"creditLimitCurrency"} />
      <Select
        id={"creditLimitCurrency"}
        value={{
          value: values.creditLimitCurrency,
          label: values.creditLimitCurrency,
        }}
        isSearchable
        loadingMessage={() => {
          return "Loading Currencies";
        }}
        isLoading={isFetchingCurrencies}
        name="creditLimitCurrency"
        isDisabled={isFetchingCurrencies}
        placeholder={"Select Credit Limit Currency"}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            creditLimitCurrency: selectedOption.value,
          });
        }}
        options={currencies?.map((currency) => {
          return {
            value: currency,
            label: currency,
          };
        })}
      />
      <ErrorMessage name={"creditLimitCurrency"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingCurrencies && <FieldErrorText message={"Error Fetching Currencies"} />}
    </div>
  );
};

const NotificationPreferences = () => {
  return (
    <>
      <FormLabel label={"Notifications Preferences"} htmlFor={"notification"} />
      <div className="flex gap-4 items-center justify-around">
        <div>
          <label className="flex gap-1 items-center justify-center cursor-pointer">
            <Field className="cursor-pointer" name="notificationPreferences" value="email" type="checkbox" />
            <p className="font-semibold text-gray-600"> Email </p>
          </label>
        </div>

        <div>
          <label className="flex gap-1 items-center justify-center cursor-pointer">
            <Field className="cursor-pointer" name="notificationPreferences" value="whatsapp" type="checkbox" />
            <p className="font-semibold text-gray-600"> Whatsapp </p>
          </label>
        </div>
      </div>
      <ErrorMessage
        name={"notificationPreferences"}
        render={(msg) => {
          return (
            <div className="flex justify-center items-center">
              <FieldErrorText message={msg} />
            </div>
          );
        }}
      />
    </>
  );
};

const CountrySelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingCountries,
    data: countries,
    error: errorFetchingCountries,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery("/hotels/api/v1/get-countries/");
    },
    queryKey: ["countries"],
  });

  // Define the desired order of countries to put at the top
  const topCountries = [
    "Saudi Arabia",
    "Kuwait",
    "Qatar",
    "UAE",
    "Bahrain",
    "Iraq",
    "Lebanon",
    "Jordan",
    "Libya",
    "Yemen",
    "Egypt",
  ];

  // Create a mapping of the desired order
  const topCountriesOrder = topCountries.reduce((acc, country, index) => {
    acc[country] = index;
    return acc;
  }, {});

  // Separate the countries into the top list and the rest
  const reorderedCountries = [];
  const remainingCountries = [];

  countries?.forEach((country) => {
    if (topCountries.includes(country.name)) {
      reorderedCountries.push(country);
    } else {
      remainingCountries.push(country);
    }
  });

  // Sort the reorderedCountries based on the defined order
  reorderedCountries.sort((a, b) => topCountriesOrder[a.name] - topCountriesOrder[b.name]);

  // Concatenate the reordered list with the remaining countries
  const finalList = reorderedCountries.concat(remainingCountries);

  return (
    <>
      <FormLabel label={"Country"} htmlFor={"country"} />
      <Select
        name={"country"}
        inputId={"country"}
        isDisabled={isFetchingCountries}
        defaultValue={{
          value: values.country,
          label: values.countryName,
        }}
        placeholder={"Client Country"}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            country: selectedOption.value,
            city: "",
          });
        }}
        options={finalList?.map((country) => {
          return {
            value: country.id,
            label: country.name,
          };
        })}
      />
      <ErrorMessage name={"country"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingCountries && <FieldErrorText message={"Error Fetching Countries"} />}
    </>
  );
};

const CitySelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingCities,
    data: cities,
    error: errorFetchingCountries,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/hotels/api/v1/get-cities/new/${values.country}/`);
    },
    queryKey: ["cities", values.country],
  });

  return (
    <div>
      <FormLabel label={"City"} htmlFor={"city"} />
      <Select
        defaultValue={
          !values.city
            ? null
            : {
                value: values.city,
                label: values.cityName,
              }
        }
        isClearable
        key={values.country}
        inputId={"city"}
        name="city"
        isDisabled={isFetchingCities}
        placeholder={"Client City"}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            city: selectedOption === null ? "" : selectedOption.value,
          });
        }}
        options={cities?.map((city) => {
          return {
            value: city.id,
            label: city.name,
          };
        })}
      />
      <ErrorMessage name={"city"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingCountries && <FieldErrorText message={"Error Fetching Cities"} />}
    </div>
  );
};
const MarketSelectInput = () => {
  const { values, setValues } = useFormikContext();
  const {
    data: markets,
    isLoading: isFetchingMarkets,
    error: errorFetchingMarkets,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/site-settings/api/market`);
    },
    queryKey: ["markets"],
  });

  return (
    <>
      <FormLabel label={"Market"} htmlFor={"market"} />
      <Select
        id={"market"}
        value={values.market?.map((val) => {
          return {
            value: val,
            label: markets?.find((g) => g.id === val)?.name,
          };
        })}
        isMulti
        isSearchable
        loadingMessage={() => {
          return "Loading Markets";
        }}
        isLoading={isFetchingMarkets}
        name="Market"
        isDisabled={isFetchingMarkets}
        placeholder={"Select Market"}
        onChange={(selectedOption, actionMeta) => {
          if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
            void setValues({
              ...values,
              market: values.market?.filter((val) => val !== actionMeta.removedValue.value),
            });
            return;
          }

          if (actionMeta.action === "clear") {
            void setValues({
              ...values,
              market: [],
            });
            return;
          }

          void setValues({
            ...values,
            market: [...values.market, actionMeta.option.value],
          });
        }}
        options={markets?.map((market) => {
          return {
            value: market.id,
            label: market.name,
          };
        })}
      />
      <ErrorMessage name={"market"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingMarkets && (
        <FieldErrorText message={errorFetchingMarkets.response.data.message || "Error Fetching Market"} />
      )}
    </>
  );
};
const MarkupSelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingMarkups,
    data: markups,
    error: errorFetchingMarkups,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/markup/api/search/`);
    },
    queryKey: ["markups"],
  });

  return (
    <>
      <FormLabel label={"Markup"} htmlFor={"markup"} />
      <Select
        id={"markup"}
        value={{
          value: values.markup,
          label: markups?.find((markup) => markup.id === values.markup)?.name,
        }}
        isSearchable
        loadingMessage={() => {
          return "Loading Markups";
        }}
        isLoading={isFetchingMarkups}
        name="markup"
        isDisabled={isFetchingMarkups}
        placeholder={"Select Markup"}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            markup: selectedOption.value,
          });
        }}
        options={markups?.map((markup) => {
          return {
            value: markup.id,
            label: markup.name,
          };
        })}
      />
      <ErrorMessage name={"markup"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingMarkups && (
        <FieldErrorText message={errorFetchingMarkups.response.data.message || "Error Fetching Markups"} />
      )}
    </>
  );
};

const UserGroupSelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    isLoading: isFetchingGroups,
    data: groups,
    error: errorFetchingGroups,
  } = useQuery({
    queryFn: () => {
      return fetchDataQuery(`/permissions/api/v1/user-groups/`);
    },
    queryKey: ["user-groups"],
  });

  return (
    <div>
      <FormLabel label={"Group"} htmlFor={"groups"} />
      <Select
        id={"groups"}
        value={values.groups?.map((group) => {
          return {
            value: group,
            label: groups?.find((g) => g.id === group)?.name,
          };
        })}
        isMulti
        isSearchable
        loadingMessage={() => {
          return "Loading Groups";
        }}
        isLoading={isFetchingGroups}
        name="groups"
        isDisabled={isFetchingGroups}
        placeholder={"Select a Group"}
        onChange={(selectedOption, actionMeta) => {
          if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
            void setValues({
              ...values,
              groups: values.groups?.filter((group) => group !== actionMeta.removedValue.value),
            });
            return;
          }

          if (actionMeta.action === "clear") {
            void setValues({
              ...values,
              groups: [],
            });
            return;
          }

          void setValues({
            ...values,
            groups: [...values.groups, actionMeta.option.value],
          });
        }}
        options={groups?.map((group) => {
          return {
            value: group.id,
            label: group.name,
          };
        })}
      />
      <ErrorMessage name={"groups"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingGroups && <FieldErrorText message={"Error Fetching User Groups"} />}
    </div>
  );
};

export default AgentForm;
