async function translateText(query, targetLanguage, sourceLanguage) {
  try {
    const response = await fetch(
      "https://translation.googleapis.com/language/translate/v2?" +
        new URLSearchParams({
          q: query,
          target: targetLanguage,
          source: sourceLanguage,
          format: "text",
          key: "AIzaSyCykn4G8hx24qD_L_SKO1DQKLbl2_BRP8c",
        }),
    );

    const data = await response.json();

    return data.data.translations[0].translatedText;
  } catch (error) {
    throw error;
  }
}

export async function translateTextToArabic(query) {
  return await translateText(query, "ar", "en");
}

export async function translateTextToEnglish(query) {
  return await translateText(query, "en", "ar");
}
