import React, { useEffect, useState } from "react";
import "./DashboardPage.scss";
import DashboardSidebar from "../../organisms/DashboardSidebar/DashboardSidebar";
import DashboardNavbar from "../../organisms/DashboardNavbar/DashboardNavbar";
import usePermissions from "../../../customHooks/usePermissions";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../../organisms/NoPermission/NoPermission";
import Loading from "../../organisms/Loading/Loading";
import { useNavigate } from "react-router";
import { NotificationContainer } from "react-notifications";
import cookie from "react-cookies";

export default function DashboardPage({ children }) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  // permission
  const [permissions, permissionsFlag, permissionsError] = usePermissions(["permissions_management.main_dashboard"]);

  if (permissionsError?.response?.data) {
    cookie.remove("access_token", { path: "/" });
    cookie.remove("refresh_token", { path: "/" });
    cookie.remove("currency", { path: "/" });
    cookie.remove("language", { path: "/" });
    navigate("/login", {
      state: "error",
    });
  }

  if (permissions?.some((item) => item.value) === false) {
    navigate("/");
  }
  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is "md" based on Tailwind CSS classes
      if (window.innerWidth < 1024) {
        setShow(false);
      } else {
        setShow(true);
      }
    };

    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);

    // Initial check when the component is mounted
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="bg-gray-50">
      {permissionsFlag ? (
        permissions.some((item) => item.value) ? (
          <div className="w-full h-full flex items-start justify-start relative  ">
            {show && <DashboardSidebar />}
            <div className=" w-full overflow-y-scroll ">
              <DashboardNavbar />
              {children}
            </div>
          </div>
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full flex  items-center justify-center h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
      <NotificationContainer />
    </div>
  );
}
