import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Loading from '../Loading/Loading';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getwalletAction, updateBankTransfrAction } from '../../../redux/actions/bankTransferAction';
import {TbListDetails} from 'react-icons/tb';
import {MdOutlineDone} from 'react-icons/md';
import TopBarProgress from "react-topbar-progress-indicator";
import { getBankListAction } from '../../../redux/actions/cashAction';

const BankTransferWalletDetails = ({wallet}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const token = cookie.load("access_token");
    const {bankData} = useSelector((state) => state.cashReducer);

    useEffect(()=>{
        dispatch(getBankListAction(token))
        },[])

        let BankFilter = bankData && wallet ? bankData.filter((item)=>item.id === wallet.bank_name):[];
       
  return (
<div className='flex flex-col justify-center items-center '>
             <div className='flex flex-col    w-full  text-black rounded-md  '> 
              <div className=' flex items-center justify-center w-full border  rounded-lg text-xl text-black font-semibold h-16'>
                  <p className='flex items-center gap-x-3'><TbListDetails className='w-5 h-5' />Method Of Payment (Bank Transfer) </p>   
                </div>
                                  {/* Hotel id & id & persons*/}
                                <div className='flex w-full justify-between items-center  p-3 bg-gray-200 '>
                                 
                                <div className='flex  justify-between items-center w-[50%] '>
                                    <div className='w-[33%]  '>
                                      <p className='flex items-center gap-x-1 font-bold'>Transfer Amount </p>
                                    </div> 
                                    <div className='w-[70%] font-semibold text-gray-800'>
                                        <p className='flex items-center gap-x-1'>{wallet.transfer_amount}<span>{wallet.currency_type}</span></p>
                                    </div>
                                  </div>
                                 <div className='flex items-center w-[50%]'>
                                    <div className='w-[33%]  '>
                                        <p className='flex items-center gap-x-1 font-bold'>ID</p>
                                    </div> 
                                    <div className='w-[70%] font-semibold text-gray-800'>
                                    <p className='flex items-center gap-x-1'> {wallet.id}</p>
                                    </div>
                                  </div> 
                                
                                </div> 
                                <div className='flex w-full justify-between items-center  p-3'>
                                <div className='flex  justify-between items-center w-[50%] '>
                                      <div className='w-[33%]  '>
                                        <p className='flex items-center gap-x-1 font-bold'>Conversion Rate</p>
                                      </div> 
                                      <div className='w-[70%] font-semibold text-gray-800'>
                                      <p className='flex items-center gap-x-1'>{wallet.conversion_rate} </p>
                                          <p className='flex items-center gap-x-1'></p>
                                      </div>
                                    </div>
                                 
                                      <div className='flex  justify-between items-center w-[50%] '>
                                    <div className='w-[33%]  '>
                                      <p className='flex items-center gap-x-1 font-bold'>Booked Room</p>
                                    </div> 
                                    <div className='w-[70%] font-semibold text-gray-800'>
                                        <p className='flex items-center gap-x-1'>{wallet.booked_room}</p>
                                    </div>
                                  </div>
                                  
                                </div>  
                                    {/* Client & phone Number*/}
                                <div className='flex w-full justify-between items-center  p-3 bg-gray-200'>
                                <div className='flex  justify-between items-center w-[50%] '>
                                    <div className='w-[33%]  '>
                                      <p className='flex items-center gap-x-1 font-bold'>Convertion Price  </p>
                                    </div> 
                                    <div className='w-[70%] font-semibold text-gray-800'>
                                        <p className='flex items-center gap-x-1'>{wallet.conversion_rate_price}<span>{wallet.contract_currency}</span></p>
                                    </div>
                                  </div>
                                 
                             
                                  <div className='flex  justify-between items-center w-[50%] '>
                                    <div className='w-[33%]  '>
                                      <p className='flex items-center gap-x-1 font-bold'>Transferor Name </p>
                                    </div> 
                                    <div className='w-[70%] font-semibold text-gray-800'>
                                        <p className='flex items-center gap-x-1'>{wallet.transferor_name}</p>
                                    </div>
                                  </div>
                                 
                                </div> 
                                {/* WhatsApp Number & Email*/}
                                <div className='flex w-full justify-between items-center  p-3  '>
                                    <div className='flex  justify-between items-center w-[50%] '>
                                      <div className='w-[33%]  '>
                                        <p className='flex items-center gap-x-1 font-bold'>Currency</p>
                                      </div> 
                                      <div className='w-[70%] font-semibold text-gray-800'>
                                        <p className='flex items-center gap-x-1'>{wallet.currency_type} </p>
                                      </div>
                                    </div>
                                    <div className='flex  justify-between items-center w-[50%] '>
                                      <div className='w-[33%]  '>
                                        <p className='flex items-center gap-x-1 font-bold text-sm'>Transfer Account Number</p>
                                      </div> 
                                      <div className='w-[70%] font-semibold text-gray-800'>
                                      <p className='flex items-center gap-x-1'>{wallet.transfer_acc_number} </p>
                                          <p className='flex items-center gap-x-1'></p>
                                      </div>
                                    </div>
                                </div>  

                                <div className='flex w-full justify-between items-center  p-3 bg-gray-200 '>
                                    <div className='flex  justify-between items-center w-[50%] '>
                                      <div className='w-[33%]  '>
                                        <p className='flex items-center gap-x-1 font-bold'>Contract Currency</p>
                                      </div> 
                                      <div className='w-[70%] font-semibold text-gray-800'>
                                        <p className='flex items-center gap-x-1'>{wallet.contract_currency} </p>
                                      </div>
                                    </div>
                                    <div className='flex items-center w-[50%]'>
                                    <div className='w-[33%]  '>
                                        <p className='flex items-center gap-x-1 font-bold text-sm'>Bank Account Number</p>
                                    </div> 
                                    <div className='w-[70%] font-semibold text-gray-800'>
                                      <p className='flex items-center gap-x-1'> {wallet.bank_account_number}</p>
                                    </div>
                                  </div>
                                   
                                </div>  
                                  {/* Hotel & Room Name*/}
                              
                                {/* Client text & Client Tag Request*/}
                                
                                <div className='flex w-full justify-between items-center  p-3  '>
                                <div className='flex  justify-between items-center w-[50%] '>
                                      <div className='w-[33%]  '>
                                        <p className='flex items-center gap-x-1 font-bold'>Transactions Status</p>
                                      </div> 
                                      <div className='w-[70%] font-semibold text-gray-800 capitalize'>
                                      {wallet.transaction_status === "confirmed" ?
                                        <p className='w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-green-700 font-semibold'>
                                          {wallet.transaction_status} <MdOutlineDone className='bg-green-700 border text-white rounded-full ' /> 
                                        </p>
                                        :
                                        <p className='w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-yellow-600 font-semibold'>
                                          {wallet.transaction_status}....
                                        </p>
                                      }
                                      </div>
                                    </div>
                               
                                    <div className='flex items-center w-[50%]'>
                                    <div className='w-[33%]  '>
                                        <p className='flex items-center gap-x-1 font-bold'>Bank Name</p>
                                    </div> 
                                    <div className='w-[70%] font-semibold text-gray-800'>
                                      <p className='flex items-center gap-x-1'> {BankFilter && BankFilter[0].name}</p>
                                    </div>
                                  </div>                          
                                 
                                </div> 


                                <div className='flex w-full justify-between items-center  p-3 bg-gray-200 '>
                                <div className='flex  justify-between items-center w-[50%] '>
                                      <div className='w-[33%]  '>
                                          <p className='flex items-center gap-x-1 font-bold'>Transaction Date </p>
                                      </div> 
                                      <div className='w-[70%] font-semibold text-gray-800'>
                                          <p className='flex items-center gap-x-1'>{wallet.transaction_date.slice(0,10)} / {wallet.transaction_date.slice(11,19)}</p>
                                      </div>
                                      </div>
                                      
                                      <div className='flex  justify-between items-center w-[50%] '>
                                      <div className='w-[33%]  '>
                                          <p className='flex items-center gap-x-1 font-bold'>Creation Date </p>
                                      </div> 
                                      <div className='w-[70%] font-semibold text-gray-800'>
                                          <p className='flex items-center gap-x-1'>{wallet.created_date.slice(0,10)} / {wallet.created_date.slice(11,19)}</p>
                                      </div>
                                     </div>                                
                                 
                                   
                                </div>  
                                <div className='flex w-full justify-between items-center  p-3  '>
                                <div className='flex  justify-between items-center w-[50%] '>
                                      <div className='w-[33%]  '>
                                          <p className='flex items-center gap-x-1 font-bold'>Tranfer Receipt </p>
                                      </div> 
                                      <div className='w-[70%] font-semibold text-gray-800'>
                                        {
                                          wallet.transfer_receipt&&   
                                          <img src={wallet.transfer_receipt} className='w-36 h-36' />
                                        }
                                      </div>
                                      </div>
                                      
                                                      
                                 
                                   
                                </div>  
                                
                                
             </div>
          </div>
  )
}

export default BankTransferWalletDetails