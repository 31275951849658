import React, { useEffect, useState } from "react";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction, getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import { useNavigate, useParams } from "react-router";
import { IoAdd } from "react-icons/io5";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
import jwtDecode from "jwt-decode";
import { TimePicker } from "antd";
import { getBoxNameAction } from "../../../redux/actions/cashAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import { addNewCashWalletAction } from "../../../redux/actions/walletAction";

const CashWallet = ({ amountWallet, commission, currencyWallet, subTotal, agent, methodOfPayment }) => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const format = "HH:mm";
  const params = useParams();
  const navigate = useNavigate();
  const decodedToken = jwtDecode(token);
  const [sourceName, setSourceName] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [boxNameForm, setBoxNameForm] = useState("");
  const [convertAmount, setConvertAmount] = useState();
  const [convertRate, setConvertRate] = useState(1);

  useEffect(() => {
    dispatch(getSelectedBookedRoomSingleAction(token, params.id));
    dispatch(getCurrencyAction(token));
    dispatch(getBoxNameAction(token));
  }, []);
  useEffect(() => {
    if (currency) {
      dispatch(
        convertingCurrencyAction(token, currency, currencyWallet, (result) => {
          if (result.status === 200) {
            result.data.results.length !== 0
              ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
              : setConvertAmount(amount && amount * 1);
            result.data.results.length !== 0
              ? setConvertRate(...result.data.results.map((item) => item.rate))
              : setConvertRate(1);
          }
        }),
      );
    }
  }, [currencyWallet]);

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { addCashFlag } = useSelector((state) => state.bookingListReducer);
  const { boxNameData } = useSelector((state) => state.cashReducer);
  const { boxFlag } = useSelector((state) => state.cashReducer);
  const { addWalletFlag } = useSelector((state) => state.walletReducer);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  // let boxNames = boxNameData ? boxNameData.map((item,index) =>{
  //     return{value:item.id , label:item.name}
  //   }) : []

  let boxNames = boxNameData
    ? boxNameData.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency_type };
      })
    : [];

  const handleWheel = (e) => {
    e.preventDefault();
  };
  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setTime(`${hours1}:${minutes1}`);
  };
  const handleAmount = (e) => {
    setAmount(e.target.value);
    if (convertRate) {
      setConvertAmount(e.target.value * convertRate);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (time === "" || !amountWallet || !commission || !currencyWallet || !subTotal || !agent) {
      alert("You Have Empty Field"); // You can replace this with your validation logic
    } else {
      const formData = new FormData(e.target);

      const data = {
        amount: amountWallet,
        agent_name: agent,
        commission: commission,
        currency: currencyWallet,
        method_of_payment: "cash",
        subtotal: subTotal,
        amount_used: 500.0,
        rest_of_payment: 500.0,
        service_description: "cash",
        system_confirmation: 1254862,
        cash_payment: {
          creator: decodedToken.user_id,
          source_name: sourceName,
          amount: amount,
          currency_type: currency,
          box_name: boxNameForm,
          transaction_date: `${date},${time}`,
          service_type: "hotel_booking",
          transaction_status: "confirmed",
        },
      };
      // const transaction_date = `${date},${time}`
      // formData.append("booked_room" , params.id)
      // formData.append("creator" , 2)
      // formData.append("transaction_status" , "confirmed")
      // formData.append("transaction_date" , transaction_date)
      // formData.append("currency_type",currency)
      // formData.append("contract_currency",currencyWallet)
      // formData.append("conversion_rate_price",convertAmount.toFixed(2))
      // formData.append("conversion_rate",convertRate)
      dispatch(
        addNewCashWalletAction(token, data, (result) => {
          if (result.status === 201) {
            lightToast.success("The Wallet Of CashPayment was completed successfully");
            navigate(-1);
          }
        }),
      );
    }
  };
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className=" w-full">
          <form className="mt-2  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <label className=" flex text-gray-600 ">Box Name</label>
              <Select
                name="box_name"
                options={boxNames}
                onChange={(e) => {
                  setBoxNameForm(e.value);
                  setCurrency(e.currency);
                  dispatch(
                    convertingCurrencyAction(token, e.currency, currencyWallet, (result) => {
                      if (result.status === 200) {
                        result.data.results.length !== 0
                          ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
                          : setConvertAmount(amount && amount * 1);
                        result.data.results.length !== 0
                          ? setConvertRate(...result.data.results.map((item) => item.rate))
                          : setConvertRate(1);
                      }
                    }),
                  );
                }}
                required
                isSearchable
                className=" w-full  h-10  border rounded-lg  bg-white "
                placeholder="Box Name"
              />
              {currency && (
                <>
                  <label className=" flex text-gray-600 ">Currency</label>
                  <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                    <p className="text-black mt-2">{currency}</p>
                  </div>
                  {/* <label className=" flex text-gray-600 ">Currency</label>
                                      <Select
                                                name="currency_type"
                                                options={CurrencyType}
                                                onChange={(e) => {
                                                setCurrency(e.label)
                                                }}
                                                required
                                                isSearchable
                                                className =" w-full  h-10  border rounded-lg  bg-white "
                                                placeholder="Currency" /> */}
                </>
              )}
              <label className=" text-center w-full text-gray-600">Source Name</label>
              <input
                placeholder="Source Name"
                name="source_name"
                type="text"
                required
                onChange={(e) => setSourceName(e.target.value)}
                className=" w-full px-3 h-10  border rounded-lg bg-white "
              />

              <label className=" flex text-gray-600">Amount</label>
              <input
                placeholder="Amount"
                name="amount"
                type="Number"
                step="any"
                required
                onWheel={handleWheel}
                onChange={handleAmount}
                className=" w-full px-3 h-10  border rounded-lg bg-white "
              />
              {amount && currency && (
                <>
                  <label className=" flex text-gray-600 ">Amount With Currency Of Contract ({currencyWallet})</label>
                  <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                    <div className="text-black flex items-end gap-x-1">
                      {convertAmount} <span className="text-sm font-semibold text-gray-700">{currencyWallet}</span>
                    </div>
                  </div>
                </>
              )}

              <div className="flex items-center gap-x-5 w-full">
                <div className="w-[50%]">
                  <label className=" flex justify-center text-gray-600 ">Date</label>
                  <input
                    placeholder="Tranfer Date"
                    name="date"
                    type="date"
                    required
                    onChange={(e) => setDate(e.target.value)}
                    className=" w-full px-3 h-10  border rounded-lg floating-input bg-white "
                  />
                </div>
                <div className="w-[50%]">
                  <label className=" flex justify-center text-gray-600 ">Time</label>
                  <TimePicker
                    name="time"
                    type="time"
                    clearIcon={false}
                    required
                    format={format}
                    className="w-full"
                    onChange={handleTimeChange}
                  />
                </div>
              </div>

              {/* <input
                                placeholder="Box Name"
                                name="box_name"
                                type="text"
                                required
                                onChange={(e)=>setBoxNameForm(e.target.value)}
                                className=" w-full px-3 h-10  border rounded-lg floating-input bg-white "
                                            />        */}

              <button
                type="Submit"
                disabled={addWalletFlag}
                className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Add{" "}
                {addWalletFlag ? (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                ) : (
                  <IoAdd className="w-5 h-5" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CashWallet;
