import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { getSelectedBankTransferAction } from "../../../redux/actions/bankTransferAction";
import { TbListDetails } from "react-icons/tb";
import { MdOutlineDone } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import PopUp from "../PopUp/PopUp";

const BankTransferDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const { bankData } = useSelector((state) => state.cashReducer);
  const { selectedBankTransfer } = useSelector((state) => state.bankTransferReducer);
  const { selectedBankTransferFlag } = useSelector((state) => state.bankTransferReducer);

  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    dispatch(getBankListAction(token));
    dispatch(getSelectedBankTransferAction(token, params.id));
  }, []);

  let BankFilter =
    bankData && selectedBankTransfer ? bankData.filter((item) => item.id === selectedBankTransfer.bank_name) : [];

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.view_banktransfer"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full h-screen overflow-x-auto">
      {selectedBankTransfer && !selectedBankTransferFlag && bankData && permissionFlag ? (
        permission.some((item) => item.value) ? (
          <div className="container border-gray-300 border rounded-lg  p-10 ">
            <div className="flex flex-col justify-center items-center ">
              <div className="flex flex-col    w-full  text-black rounded-md  ">
                <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                  <p className="flex items-center gap-x-3">
                    <TbListDetails className="w-5 h-5" />
                    Bank Transfer Details{" "}
                  </p>
                </div>
                {/* Hotel id & id & persons*/}
                <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Transfer Amount </p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">
                        {selectedBankTransfer.transfer_amount}
                        <span>{selectedBankTransfer.currency_type}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center w-[50%]">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">ID</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1"> {selectedBankTransfer.id}</p>
                    </div>
                  </div>
                </div>
                <div className="flex w-full justify-between items-center  p-3">
                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Conversion Rate</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">{selectedBankTransfer.conversion_rate} </p>
                      <p className="flex items-center gap-x-1"></p>
                    </div>
                  </div>

                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Booked Room</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">{selectedBankTransfer.booked_room}</p>
                    </div>
                  </div>
                </div>
                {/* Client & phone Number*/}
                <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Convertion Price </p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">
                        {selectedBankTransfer.conversion_rate_price}
                        <span>{selectedBankTransfer.contract_currency}</span>
                      </p>
                    </div>
                  </div>

                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Transferor Name </p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">{selectedBankTransfer.transferor_name}</p>
                    </div>
                  </div>
                </div>
                {/* WhatsApp Number & Email*/}
                <div className="flex w-full justify-between items-center  p-3  ">
                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Currency</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">{selectedBankTransfer.currency_type} </p>
                    </div>
                  </div>
                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold text-sm">Transfer Account Number</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">{selectedBankTransfer.transfer_acc_number} </p>
                      <p className="flex items-center gap-x-1"></p>
                    </div>
                  </div>
                </div>

                <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Contract Currency</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">{selectedBankTransfer.contract_currency} </p>
                    </div>
                  </div>
                  <div className="flex items-center w-[50%]">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold text-sm">Bank Account Number</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1"> {selectedBankTransfer.bank_account_number}</p>
                    </div>
                  </div>
                </div>
                {/* Hotel & Room Name*/}

                {/* Client text & Client Tag Request*/}

                <div className="flex w-full justify-between items-center  p-3  ">
                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Transactions Status</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800 capitalize">
                      {selectedBankTransfer.transaction_status === "confirmed" ? (
                        <p className="w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-green-700 font-semibold">
                          {selectedBankTransfer.transaction_status}{" "}
                          <MdOutlineDone className="bg-green-700 border text-white rounded-full " />
                        </p>
                      ) : (
                        <p className="w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-yellow-600 font-semibold">
                          {selectedBankTransfer.transaction_status}....
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center w-[50%]">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Bank Name</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1"> {BankFilter && BankFilter[0].name}</p>
                    </div>
                  </div>
                </div>

                <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Tranfer Receipt </p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <button onClick={() => setIsOpen(true)} type="button" className="w-full">
                        {selectedBankTransfer.transfer_receipt && (
                          <img src={selectedBankTransfer.transfer_receipt} className="w-36 h-36" />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="flex  justify-between items-center w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 font-bold">Date </p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1">{selectedBankTransfer.transaction_date.slice(0, 10)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
      <PopUp close={closeModal} isOpen={isOpen} image={selectedBankTransfer?.transfer_receipt} />
    </div>
  );
};

export default BankTransferDetails;
