import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import {
  filterBookingAction,
  getAllHotels,
  getBookedRoomsAction,
  getPaymentListAction,
  getSelectedBookedRoomSingleAction,
} from "../../../redux/actions/bookingListAction";
import { BiTransfer } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineAccessTime } from "react-icons/md";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./BookingListTable.css";
import { GoPerson } from "react-icons/go";
import { TbBuildingEstate } from "react-icons/tb";
import { IoBedOutline, IoCloudyNightOutline, IoPersonOutline } from "react-icons/io5";
import { LiaCalendar, LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { GiHotMeal, GiPriceTag } from "react-icons/gi";
import { PiDatabase } from "react-icons/pi";
import TopBarProgress from "react-topbar-progress-indicator";
import { getSelectedTransactionAction } from "../../../redux/actions/allTransactionsAction";
import { VscFilter } from "react-icons/vsc";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import ListFilters from "../../../utils/ListFilters";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";

// type= 'all', 'archived', 'confirmed canceled'

const CollectedBookingListTable = ({ lastOperationStatus = "", listType = "booking-list" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const token = cookie.load("access_token");
  const [isloading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { bookedHubRoomsStore } = useSelector((state) => state.bookingListReducer);
  const [filterLoad, setFilterLoad] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [filterName, setFilterName] = useState();
  const [amounts, setAmounts] = useState([]);
  const [booking, setBooking] = useState([]);
  const [bookingId, setBookingId] = useState();
  const [bookingTypeFilter, setBookingTypeFilter] = useState();
  const [filterColumn, setFilterColumn] = useState("");
  const [paymentType, setPaymentType] = useState();
  const { selectedTransaction } = useSelector((state) => state.allTransactionsReducer);
  // const { bookingTypes } = useSelector((state) => state.bookingListReducer);
  // const { allBookingStatus } = useSelector((state) => state.bookingListReducer);
  const { allHotelsData } = useSelector((state) => state.bookingListReducer);
  const [collectedData, setCollectedData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const currentPage = Number(params.page || 1);
  const totalPages = collectedData && Math.ceil(collectedData?.count / pageSize);
  useEffect(() => {
    // dispatch(getBookingTypeAction(token));
    // dispatch(getAllBookingStatus(token));
    dispatch(getAllHotels(token));
    dispatch(
      getPaymentListAction(token, "credit", (result) => {
        setPaymentType(result.data);
      }),
    );
    dispatch(
      getBookedRoomsAction(
        token,
        params.filter,
        "",
        currentPage,
        pageSize,
        params.sortingOrder,
        lastOperationStatus,
        (result) => {
          //
          if (result && result.status === 200) {
            setFilterLoad(false);
            setIsLoading(false);
            const newAmounts = [];
            const newBooking = [];
            result?.data?.results?.map((item) => {
              dispatch(
                getSelectedTransactionAction(token, item.id, (result) => {
                  if (result.status === 200) {
                    newAmounts.push(...result.data.results);
                  }
                }),
              );
            });
            setAmounts(newAmounts);
            setBooking(newBooking);
          }
        },
      ),
    );
  }, [currentPage, location]);

  const [paids, setPaids] = useState();
  //
  //

  const BookingFilter = (type, bookingID) => {
    setIsLoading(true);
    setBookingId(bookingID);
    setBookingTypeFilter(type);
    //
    dispatch(
      filterBookingAction(
        token,
        type,
        bookingID,
        currentPage,
        pageSize,
        params.sortingOrder,
        lastOperationStatus,
        (result) => {
          if (result && result.status === 200) {
            setFilterLoad(false);
            setIsLoading(false);
            setLoadingFilter(false);
            const newAmounts = [];
            const newBooking = [];
            result.data.results.map((item) => {
              dispatch(
                getSelectedTransactionAction(token, item.id, (result) => {
                  if (result.status === 200) {
                    newAmounts.push(...result.data.results);
                  }
                }),
              );
            });
            setAmounts(newAmounts);
            setBooking(newBooking);

            navigate(`/dashboard/${listType}/${type}=${bookingID}/${currentPage}/${params.sortingOrder}`);
          }
        },
      ),
    );
  };

  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("grid-visibility-booking-list");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility), slug: false });
    }
  }, []);

  const [columnVisibility, setColumnVisibility] = useState({});

  const handleTableChange = (page, size) => {
    setPageSize(size);
    navigate(`/dashboard/${listType}/${params.filter}/${page}/${params.sortingOrder}`);
  };

  function handlePageChange(para) {
    //
    setIsLoading(true);
  }

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Africa/Cairo", // Specify Egypt's timezone
  };

  const {
    data: collected,
    error: errorCollected,
    isSuccess: CollectedSuccess,
    isLoadingTask: taskLoading,
  } = useQuery({
    queryKey: ["collectedBooking"],
    queryFn: () =>
      fetchDataQuery(
        `/booking/api/v1/get-booked-rooms/?page=${currentPage}&page_size=${pageSize}&sorting_order=-created_date&collected=true`,
      ),
  });
  useEffect(() => {
    if (CollectedSuccess && collected) {
      setCollectedData(collected);
    }
  }, [collected, CollectedSuccess]);

  const rows = collectedData?.results?.map((item, index) => {
    return {
      short_id: item.short_id,
      //  booking_group:item.booking_group,
      id: item.id,
      client_names: item.client_names.map((info) => info.arabic_name),
      client_names_english: item.client_names.map((info) => info.english_name),
      hotel: item.hotel,
      room_name: item.room_name,
      room: item.room,
      arrival_time_from: item.arrival_time_from,
      arrival_time_to: item.arrival_time_to,
      from_date: item.from_date,
      to_date: item.to_date,
      nights: item.nights,
      currency_type: item.currency_type,
      total_price: item.total_price,
      number_of_persons: item.number_of_persons,
      phone_number: item.phone_number,
      whatsapp_number: item.whatsapp_number,
      email: item.email,
      option_date: item.operations?.map((item) => item.option_date?.slice(0, 10)),
      payment_type: item.payment_type,
      // payment_type: item.payment_type,
      client_requests_tags: item.client_requests_tags,
      client_requests_text: item.client_requests_text,
      meal_plan: item.meal_plan,
      adults: item.adults,
      created_date: new Date(item.created_date).toLocaleDateString(),
      created_time: item.created_date.slice(11, 19),
      last_updated_date: new Date(item.last_updated_date).toLocaleDateString(),
      paid: 1,
      inventory_status: item.inventory_status,
      num: index,
      source: item.user_type,
      booking_type_for_status: item.operations?.map((item) => item.booking_type_for_status),
      booking_status: item.operations?.map((item) => item.booking_status),
    };
  });
  //  useEffect(()=>{
  //   bookedHubRoomsStore && bookedHubRoomsStore.results.map((item)=>{
  //       dispatch(getSelectedTransactionAction(token ,item.id , (result)=>{
  //           // //         }))
  //     })
  //  },[bookedHubRoomsStore])

  const [operationDataId, setOperationId] = useState();
  const { getselecoperation } = useSelector((state) => state.paymentTransactionReducer);
  //

  const dataExists = (array) => {
    // Check if data exists in the array
    return array.length > 0;
  };

  //
  //   const filteredRows = rows.filter((row) =>
  //     Object.values(row).some((value) => value.toString().toLowerCase().includes(searchQuery.toLowerCase())),
  //   );

  function comparator([para]) {
    //
    setIsLoading(true);
    if (para) {
      if (para.sort === "asc") {
        //    dispatch(sortBookingListAction(token,params.key))
        navigate(`/dashboard/${listType}/${params.filter}/${params.page}/${para.key}`);
      } else {
        //  dispatch(sortBookingListAction(token,"-"+params.key))
        navigate(`/dashboard/${listType}/${params.filter}/${params.page}/-${para.key}`);
      }
    } else {
      // dispatch(sortBookingListAction(token,params.key))
      navigate(-1);
    }
  }

  //
  const columns = [
    {
      key: "short_id",
      title: (
        <div className="flex items-center gap-x-1">
          <PiDatabase className="w-5 h-5  text-yellow-500 " /> BOOKING ORDER{" "}
        </div>
      ),
      dataIndex: "short_id",
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/booking-list-id/${params.short_id}`}
            // onClick={() => {
            //   navigate(`/dashboard/booking-list-id/${params.short_id}`);
            // }}
            className={`w-full h-full flex items-center gap-x-1 ${!params.inventory_status && "text-yellow-600"}`}
          >
            <div className="w-24 ">{params.short_id}</div>
            <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
          </Link>
        );
      },
    },
    //   {

    //     key: 'booking_group',
    //     title: 'BOOKING  ORDER',
    //     width: 200 ,
    // },
    {
      key: "id",
      title: (
        <div className="w-full flex items-center gap-x-1">
          <PiDatabase className="w-5 h-5   text-yellow-500 " /> ID
          <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>{" "}
        </div>
      ),
      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
            className={`w-full h-full flex items-center gap-x-1 ${!params.inventory_status && "text-yellow-600"}`}
          >
            {params.id}
            <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35] " />
          </Link>
        );
      },
    },
    {
      key: "client_names",
      title: (
        <div className=" flex items-center gap-x-1">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          CLIENT NAME (AR)
          <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>{" "}
        </div>
      ),
      dataIndex: "client_names",
      editable: false,
      render: (text, params) => {
        //
        return (
          <div className="flex flex-col gap-y-2 items-center justify-center w-full h-[98%]">
            <Link
              to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
              className={`w-full h-full items-center gap-x-1  ${!params.inventory_status && "text-yellow-600"}`}
              onClick={() => {
                // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
                dispatch(getSelectedBookedRoomSingleAction(token, params.id));
              }}
            >
              <p className="flex items-center justify-start w-full h-full gap-x-1 pl-1">{params.client_names[0]}</p>
            </Link>
          </div>
        );
      },
    },
    {
      key: "client_names_english",
      title: (
        <div className="  flex items-center gap-x-1 ">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          CLIENT NAME (EN)
          <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>{" "}
        </div>
      ),
      dataIndex: "client_names_english",
      editable: false,
      render: (text, params) => {
        return (
          <div className="flex flex-col gap-y-2 w-full items-center justify-center h-[98%]">
            <Link
              to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
              className={`w-full h-full   items-center gap-x-1  ${!params.inventory_status && "text-yellow-600"}`}
              onClick={() => {
                // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
                dispatch(getSelectedBookedRoomSingleAction(token, params.id));
              }}
            >
              <p className="flex items-center justify-start w-full h-full gap-x-1 pl-1">
                {params.client_names_english[0]}
              </p>
            </Link>
          </div>
        );
      },
    },
    {
      key: "hotel",
      title: (
        <div className=" flex  items-center gap-x-1">
          <TbBuildingEstate className="w-5 h-5  text-yellow-500 mb-1" />
          HOTEL NAME
          <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>{" "}
        </div>
      ),
      dataIndex: "hotel",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-2 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <TbBuildingEstate className='w-5 h-5 text-sky-600' /> */}
            {params.hotel}
          </Link>
        );
      },
    },
    {
      key: "room_name",
      title: (
        <div className=" flex items-center gap-x-1">
          <IoBedOutline className="w-5 h-5  text-yellow-500 mb-1" />
          ROOM TYPE
          <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),
      editable: false,
      dataIndex: "room_name",
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-5 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <IoBedOutline className='w-5 h-5 text-yellow-600' /> */}
            {params.room_name}
          </Link>
        );
      },
    },
    //   {
    //   key: 'room',
    //   title: <div className='flex items-center gap-x-1'><IoKeyOutline className='w-5 h-5  text-yellow-500' />ROOM ID</div>,
    //   width: 150,
    //   editable: false,
    //   render: (text,params) => {
    //     // //

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <IoKeyOutline className='w-5 h-5 text-teal-600' /> */}
    //                 {params.room}
    //         </button>

    //     );
    //   },
    //  },
    {
      key: "arrival_time_from",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <MdOutlineAccessTime className="w-5 h-5  text-yellow-500" />
          ARRIVAL TIME FROM <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "arrival_time_from",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            <MdOutlineAccessTime className="w-5 h-5 text-green-700" />
            {params.arrival_time_from}
          </Link>
        );
      },
    },
    {
      key: "arrival_time_to",
      title: (
        <div className="  flex items-center gap-x-1 text-xs">
          <MdOutlineAccessTime className="w-5 h-5  text-yellow-500" />
          ARRIVAL TIME TO <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "arrival_time_to",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            <MdOutlineAccessTime className="w-5 h-5 text-red-700" />
            {params.arrival_time_to}
          </Link>
        );
      },
    },
    {
      key: "from_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CHECK-IN <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "from_date",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-2 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            <LiaCalendar className="w-5 h-5 text-green-700" />
            {params.from_date}
          </Link>
        );
      },
    },
    {
      key: "to_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CHECK-OUT <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "to_date",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-2 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            <LiaCalendar className="w-5 h-5 text-red-700" />
            {params.to_date}
          </Link>
        );
      },
    },

    {
      key: "nights",
      title: (
        <div className=" flex items-center gap-x-1">
          <IoCloudyNightOutline className="w-5 h-5  text-yellow-500" />
          NIGHTS <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "nights",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <IoCloudyNightOutline className='w-5 h-5 text-yellow-500' /> */}
            {params.nights}
          </Link>
        );
      },
    },
    {
      key: "total_price",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <GiPriceTag className="w-5 h-5  text-yellow-500" />
          TOTAL PRICE <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "total_price",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-5 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <GiPriceTag className='w-5 h-5 text-teal-700' /> */}
            {params.total_price} {params.currency_type}
          </Link>
        );
      },
    },
    {
      key: "paid",
      dataIndex: "paid",

      title: (
        <div className=" flex items-center gap-x-1 ">
          <GiPriceTag className="w-5 h-5  text-yellow-500 text-xs" />
          PAID <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>{" "}
        </div>
      ),

      render: (text, params) => {
        //
        //  const paids = selectedTransaction && selectedTransaction.results.map((item)=>item.booked_room_id  === 699)
        const paidFilter = amounts.filter((item) => item.booked_room_id === params.id);

        //

        const visa = paidFilter.filter((item) => item.transaction_type === "(POS) Visa");
        const notvisa = paidFilter.filter((item) => item.transaction_type !== "(POS) Visa");
        //
        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex justify-center items-center gap-x-1  ${
              !params.inventory_status && "text-yellow-600"
            }`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {paidFilter.length > 0
              ? paidFilter
                  .filter((item) => item.transaction_status === "confirmed")
                  .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0)
              : 0.0}
            <span className="text-blue-950">{params.currency_type}</span>
          </Link>
        );
      },
    },
    {
      key: "balance",
      title: (
        <div className="w-full flex items-center gap-x-1 text-xs">
          <GiPriceTag className="w-5 h-5  text-yellow-500" />
          OUTSTANDING AMOUNT <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "balance",
      editable: false,
      render: (text, params) => {
        // //
        const paidFilter = amounts.filter((item) => item.booked_room_id === params.id);
        const visa = paidFilter.filter((item) => item.transaction_type === "(POS) Visa");
        const notvisa = paidFilter.filter((item) => item.transaction_type !== "(POS) Visa");
        //
        return (
          <div className="w-full h-full f">
            <Link
              to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
              className={`w-full h-full flex justify-center items-center gap-x-1   ${
                !params.inventory_status && "text-yellow-600"
              }`}
              onClick={() => {
                // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
                dispatch(getSelectedBookedRoomSingleAction(token, params.id));
              }}
            >
              {paidFilter.length > 0 ? (
                +params.total_price -
                  paidFilter
                    .filter((item) => item.transaction_status === "confirmed")
                    .reduce(
                      (acc, balc) => acc + parseFloat(balc.conversion_rate_price && balc.conversion_rate_price),
                      0,
                    ) >
                0 ? (
                  <p className="text-red-700 flex items-center gap-x-1 ">
                    {+params.total_price -
                      paidFilter
                        .filter((item) => item.transaction_status === "confirmed")
                        .reduce(
                          (acc, balc) => acc + parseFloat(balc.conversion_rate_price && balc.conversion_rate_price),
                          0,
                        )}
                    <span className="text-blue-950">{params.currency_type}</span>{" "}
                  </p>
                ) : (
                  <p className="text-green-700 flex items-center gap-x-1">
                    {+params.total_price -
                      paidFilter
                        .filter((item) => item.transaction_status === "confirmed")
                        .reduce(
                          (acc, balc) => acc + parseFloat(balc.conversion_rate_price && balc.conversion_rate_price),
                          0,
                        )}
                    <span className="text-blue-950">{params.currency_type}</span>
                  </p>
                )
              ) : (
                <p className="text-red-700 flex items-center gap-x-1">
                  {+params.total_price}
                  <span>{params.currency_type}</span>
                </p>
              )}
            </Link>
          </div>
        );
      },
    },

    {
      key: "number_of_persons",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <IoPersonOutline className="w-5 h-5  text-yellow-500" />
          PERSONS <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "number_of_persons",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <IoPersonOutline className='w-5 h-5 text-blue-900' /> */}
            {params.number_of_persons}
          </Link>
        );
      },
    },
    // {
    //   key: 'email',
    //   title: <div className=' flex items-center gap-x-1'><AiOutlineMail className='w-5 h-5  text-yellow-500' />EMAIL</div>,

    //   width: 200,
    //   editable: false,
    //   render: (text,params) => {
    //     // //

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <AiOutlineMail className='w-5 h-5 text-amber-800' /> */}
    //                 {params.email}
    //         </button>

    //     );
    //   },
    // },
    // {
    //   key: 'phone_number',
    //   title: <div className=' flex items-center gap-x-1'><AiOutlinePhone className='w-5 h-5  text-yellow-500' />PHONE NUMBER</div>,

    //   width: 200,
    //   editable: false,
    //   render: (text,params) => {
    //     // //

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <AiOutlinePhone className='w-5 h-5 text-orange-600' /> */}
    //                 {params.phone_number}
    //         </button>

    //     );
    //   },
    // },
    // {
    //   key: 'whatsapp_number',
    //   title: <div className='flex items-center gap-x-1'><MdOutlineWhatsapp className='w-5 h-5  text-yellow-500' />WHATSAPP NUMBER</div>,

    //   width: 300,
    //   editable: false,
    //   render: (text,params) => {
    //     // //

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <MdOutlineWhatsapp className='w-5 h-5 text-sky-600' /> */}
    //                 {params.whatsapp_number}
    //         </button>

    //     );
    //   },
    // },
    {
      key: "payment_type",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaFileInvoiceDollarSolid className="w-5 h-5 mb-1 text-yellow-500" />
          VCR <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),
      dataIndex: "payment_type",
      editable: false,
      render: (text, params) => {
        const bookingOperation = booking
          ? booking.filter((item) => item.booked_room_id === JSON.stringify(params.id))
          : [];
        return params.payment_type === "cash" ? (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-5 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {bookingOperation.length > 0
              ? bookingOperation.map((item) => {
                  if (item.payment_type) {
                    return item.payment_type;
                  } else {
                    return "cash";
                  }
                })
              : "cash"}
          </Link>
        ) : (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-5 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {bookingOperation.length > 0
              ? bookingOperation.map((item) => {
                  if (item.payment_type) {
                    return item.payment_type;
                  } else {
                    return "voucher";
                  }
                })
              : "voucher"}
          </Link>
        );
      },
    },
    {
      key: "meal_plan",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiHotMeal className="w-5 h-5 mb-1 text-yellow-500" />
          MEAL PLAN <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>{" "}
        </div>
      ),
      dataIndex: "meal_plan",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.meal_plan}
          </Link>
        );
      },
    },
    {
      key: "adults",
      title: (
        <div className="flex items-center gap-x-1 text-xs ">
          <IoPersonOutline className="w-5 h-5  text-yellow-500" />
          ADULTS <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),

      dataIndex: "adults",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.adults}
          </Link>
        );
      },
    },
    // {
    //   key: 'client_requests_tags',
    //   title: 'CLIENT REQUESTS TAGS',

    //   width: 200,
    //   editable: false,
    //   render: (text,params) => {

    //     return (
    //       <button className='w-full h-full  flex items-center gap-x-1 ml-10 ' onClick={()=>{navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);   dispatch(getSelectedBookedRoomSingleAction(token , params.id));}}  >
    //                 {/* <FaRegCalendarAlt className='w-5 h-5 text-green-600' /> */}
    //                 {/* {params.created_date} */}
    //         </button>

    //     );
    //   }
    // },
    // {
    //   key: 'client_requests_text',
    //   title: 'CLIENT REQUESTS TEXT',

    //   width: 200,
    //   editable: false,
    // },
    {
      key: "created_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CREATED DATE <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>{" "}
        </div>
      ),
      dataIndex: "created_date",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <FaRegCalendarAlt className='w-5 h-5 text-green-600' /> */}
            {params.created_date}
          </Link>
        );
      },
    },
    {
      key: "created_time",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <MdOutlineAccessTime className="w-5 h-5  text-yellow-500" />
          CREATED TIME <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>{" "}
        </div>
      ),
      dataIndex: "created_time",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.created_time}
          </Link>
        );
      },
    },
    {
      key: "last_updated_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          LAST UPDATE DATE <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),
      dataIndex: "last_updated_date",
      editable: false,
      render: (text, params) => {
        // //

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.last_updated_date}
          </Link>
        );
      },
    },

    {
      key: "booking_type_for_status",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          BOOKING TYPE <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),
      dataIndex: "booking_type_for_status",
      editable: false,
      render: (text, params) => {
        // //
        // const bookingOperation = booking
        //   ? booking.filter((item) => item.booked_room_id === JSON.stringify(params.id))
        //   : [];
        //
        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-4 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              // dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {params.booking_type_for_status?.length > 0 ? params.booking_type_for_status[0] : "New bookings "}
          </Link>
        );
      },
    },
    {
      key: "status",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          BOOKING STATUS <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),
      dataIndex: "status",
      editable: false,
      render: (text, params) => {
        const bookingOperation = booking
          ? booking.filter((item) => item.booked_room_id === JSON.stringify(params.id))
          : [];

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-12 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* {bookingOperation.length > 0 ? bookingOperation.map((item) => item.booking_status) : "new"} */}
            {params.booking_status?.length > 0 ? params.booking_status[0] : "new"}
          </Link>
        );
      },
    },
    {
      key: "option_date",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          OPTION DATE <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),
      dataIndex: "option_date",
      editable: false,
      render: (text, params) => {
        //
        const bookingOperation = booking
          ? booking.filter((item) => item.booked_room_id === JSON.stringify(params.id))
          : [];
        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              // navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {/* <MdOutlineAccessTime className='w-5 h-5 text-red-600' /> */}
            {params.option_date}
          </Link>
        );
      },
    },

    {
      key: "source",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          USER TYPE <button className="hover:scale-110 active:scale-95 duration-300 transition-all "></button>
        </div>
      ),
      dataIndex: "source",
      editable: false,
      render: (text, params) => {
        const paidFilter = amounts ? amounts.filter((item) => item.booked_room_id === JSON.stringify(params.id)) : [];

        return (
          <Link
            to={`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`}
            className={`w-full h-full flex items-center gap-x-1 ml-10 ${!params.inventory_status && "text-yellow-600"}`}
            onClick={() => {
              navigate(`/dashboard/booking-list-single-id/${params.short_id}/${params.id}`);
              dispatch(getSelectedBookedRoomSingleAction(token, params.id));
            }}
          >
            {paidFilter.length > 0 ? paidFilter[0].user_type : " "}
            {params.source}
          </Link>
        );
      },
    },
  ];

  // const handleFilterChange = (event) => {
  //   setFilterColumn(event.target.value);
  //   setIsLoading(true);
  //   navigate(`/dashboard/booking-list/${params.page}/-${event.target.value}`);
  // };

  const filteredData = filterColumn
    ? bookedHubRoomsStore.results.filter((row) => row[filterColumn] !== undefined)
    : bookedHubRoomsStore;

  /////permission////////
  const permissionData = {
    permission_codes: ["room_booking.view_bookedroom"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  const filters = (
    <div className="bg-gray-50">
      <div className="w-full flex flex-col md:flex-row gap-x-5  items-center px-5 ">
        <div className="group inline-block z-50 ">
          <button className="outline-none focus:outline-none border px-4 py-1   bg-gray-100 rounded-lg flex items-center ">
            <span className="flex items-center gap-x-1  font-semibold text-[#001a35] flex-1">
              <VscFilter className="w-4 h-4  text-yellow-600" />
              {filterName ? filterName : "Filter"}
            </span>
            <span>
              <svg
                className="fill-current h-4 w-4 transform group-hover:-rotate-180
                            transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </span>
          </button>
          <ul className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 w-52 py-1">
            <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 text-md text-gray-600 font-semibold">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">Hotels </span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
                                transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
                      transition duration-150 ease-in-out origin-top-left
                      min-w-32 w-52 py-1
                      "
              >
                {allHotelsData?.map((item) => {
                  return (
                    <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                      <button
                        className="w-full text-left capitalize"
                        onClick={() => {
                          BookingFilter("hotel_name", item.name);
                          setFilterName(item.name);
                        }}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </li>

            <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 text-md text-gray-600 font-semibold">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">Check In</span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
                                transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
                      transition duration-150 ease-in-out origin-top-left
                      min-w-32 w-44 py-1
                      "
              >
                <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      BookingFilter("check_in_date", "arrival_today");
                      setFilterName("Arrival Today");
                    }}
                  >
                    Arrival Today
                  </button>
                </li>
                <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      BookingFilter("check_in_date", "arrival_tomorrow");
                      setFilterName("Arrival Tomorrow");
                    }}
                  >
                    Arrival Tomorrow
                  </button>
                </li>
                <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      BookingFilter("check_in_date", "arrival_yesterday");
                      setFilterName("Arrival Yesterday");
                    }}
                  >
                    Arrival Yesterday
                  </button>
                </li>
                <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      BookingFilter("check_in_date", "next_7_days_arrival");
                      setFilterName("Next 7 Days Arrival");
                    }}
                  >
                    Next 7 Days Arrival
                  </button>
                </li>
                <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100">
                  <button className="w-full text-left flex items-center outline-none focus:outline-none">
                    <span className="pr-1 flex-1">Custom Date</span>
                    <span className="mr-auto">
                      <svg
                        className="fill-current h-4 w-4
                                    transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </button>
                  <ul
                    className="bg-white border rounded-sm absolute top-0 right-0 
                          transition duration-150 ease-in-out origin-top-left
                          min-w-32 w-64
                          "
                  >
                    <li className="flex items-center gap-x-1 px-3 py-1 hover:bg-gray-100">
                      <input
                        onChange={(e) => {
                          BookingFilter("check_in_date=custom_date&start_date", e.target.value);
                          setFilterName(e.target.value);
                        }}
                        type="date"
                        className="w-full px-2  floating-input bg-white border-gray-300 border rounded-lg h-8 placeholder-shown:font-thin"
                        placeholder="Check-In Date "
                      />
                      {/* <button  type='button' 
                                          onClick={()=>BookingFilter("start_date" , "" )}
                                          className='px-1 py-0.5 text-sm text-white bg-[#001a35] border-gray-100 border rounded-md hover:scale-105 active:scale-95 duration-300 transition-all ring-white outline-none'>
                                      Search
                                  </button> */}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 text-md text-gray-600 font-semibold">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">Check Out</span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
                                transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
                      transition duration-150 ease-in-out origin-top-left
                      min-w-32 w-44 py-1
                      "
              >
                <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      BookingFilter("check_out_date", "today");
                      setFilterName("Today");
                    }}
                  >
                    Today
                  </button>
                </li>
                <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      BookingFilter("check_out_date", "tommorow");
                      setFilterName("Tomorrow");
                    }}
                  >
                    Tomorrow
                  </button>
                </li>
                <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100">
                  <button className="w-full text-left flex items-center outline-none focus:outline-none">
                    <span className="pr-1 flex-1">Custom Date</span>
                    <span className="mr-auto">
                      <svg
                        className="fill-current h-4 w-4
                                    transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </button>
                  <ul
                    className="bg-white border rounded-sm absolute top-0 right-0 
                          transition duration-150 ease-in-out origin-top-left
                          min-w-32 w-64
                          "
                  >
                    <li className="flex items-center gap-x-1 px-3 py-1 hover:bg-gray-100">
                      <input
                        onChange={(e) => {
                          BookingFilter("check_out_date=custom_date&start_date", e.target.value);
                          setFilterName(e.target.value);
                        }}
                        type="date"
                        className="w-full px-2  floating-input bg-white border-gray-300 border rounded-lg h-8 placeholder-shown:font-thin"
                        placeholder="Check-In Date "
                      />
                      {/* <button className='px-1 py-0.5 text-sm text-white bg-[#001a35] border-gray-100 border rounded-md hover:scale-105 active:scale-95 duration-300 transition-all ring-white outline-none'>
                                      Search
                                  </button> */}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 text-md text-gray-600 font-semibold">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">Option Date</span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
                                transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
                      transition duration-150 ease-in-out origin-top-left
                      min-w-32 w-44
                      "
              >
                <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      BookingFilter("option_date", "today");
                      setFilterName("Today");
                    }}
                  >
                    Today
                  </button>
                </li>
                <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                  <button
                    className="w-full text-left"
                    onClick={() => {
                      BookingFilter("option_date", "tomorrow");
                      setFilterName("Tomorrow");
                    }}
                  >
                    Tomorrow
                  </button>
                </li>
                <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100">
                  <button className="w-full text-left flex items-center outline-none focus:outline-none">
                    <span className="pr-1 flex-1">Custom Date</span>
                    <span className="mr-auto">
                      <svg
                        className="fill-current h-4 w-4
                                    transition duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </button>
                  <ul
                    className="bg-white border rounded-sm absolute top-0 right-0 
                          transition duration-150 ease-in-out origin-top-left
                          min-w-32 w-64
                          "
                  >
                    <li className="flex items-center gap-x-1 px-3 py-1 hover:bg-gray-100">
                      <input
                        onChange={(e) => {
                          BookingFilter("option_date=custom_date&start_date", e.target.value);
                          setFilterName(e.target.value);
                        }}
                        type="date"
                        className="w-full px-2  floating-input bg-white border-gray-300 border rounded-lg h-8 placeholder-shown:font-thin"
                        placeholder="Check-In Date "
                      />
                      {/* <button className='px-1 py-0.5 text-sm text-white bg-[#001a35] border-gray-100 border rounded-md hover:scale-105 active:scale-95 duration-300 transition-all ring-white outline-none'>
                                      Search
                                  </button> */}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 text-md text-gray-600 font-semibold">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">VCR </span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
                                transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
                      transition duration-150 ease-in-out origin-top-left
                      min-w-32 w-52 py-1
                      "
              >
                {paymentType?.map((item) => {
                  return (
                    <li className="px-3 py-1 hover:bg-gray-100 text-md ">
                      <button
                        className="w-full text-left capitalize"
                        onClick={() => {
                          BookingFilter("payment_type", item);
                          setFilterName(item);
                        }}
                      >
                        {item}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gray-600  font-semibold">
              <button className="w-full text-left">Source</button>
            </li>
          </ul>
        </div>
      </div>

      {/* <Box sx={{ height: "85%", width: "100%", padding: "5px" }}>
                        <DataGrid
                          //  className="custom-data-grid"

                          rows={rows}
                          columns={columns}
                          rowCount={bookedHubRoomsStore.count}
                          onSortModelChange={comparator}
                          columnVisibilityModel={columnVisibility}
                          onColumnVisibilityModelChange={(visibility) => {
                            setColumnVisibility(visibility);
                            localStorage.setItem("grid-visibility-booking-list", JSON.stringify(visibility));
                          }}
                          paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
                          onPaginationModelChange={handlePageChange}
                          paginationMode="server"
                        />
                      </Box>{" "} */}
    </div>
  );

  return (
    <div className="formPage-wrap min-h-screen ">
      {permissionFlag &&
        permission.map((item) => {
          if (item.name === "room_booking.view_bookedroom" && item.value === true) {
            return (
              <div className="w-full overflow-x-scroll bg-white ">
                {isloading || !permissionFlag ? (
                  <div className="w-full  flex items-center justify-center">
                    <Loading />
                    <TopBarProgress />{" "}
                  </div>
                ) : (
                  permissionFlag && (
                    <div className="w-full overflow-auto">
                      <ListFilters
                        columns={columns}
                        rows={rows}
                        title={"Collected Booking List"}
                        filters={filters}
                        handlePagination={handleTableChange}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        listPageSize={pageSize}
                      />
                    </div>
                  )
                )}
              </div>
            );
          } else {
            return (
              <div className="w-full h-screen">
                <NoPermission />
              </div>
            );
          }
        })}
    </div>
  );
};

export default CollectedBookingListTable;
