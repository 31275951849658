import api_token from "../../api/UserApi";
import cookie from "react-cookies";
const token = cookie.load("access_token");

export const createLinkAction = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post("/link/api/", data);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const getCustomerLinksAction = (callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("/link/api/");
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const getSelectedCustomerLink = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/link/api/id/${id}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const getSelectedCustomeByCode = (code, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/link/api/code/${code}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const updateCustomerLink = (id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`/link/api/id/${id}/`, data);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const deleteCustomerLink = (code, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`/link/api/code/${code}/`);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};
