import { NavLink, useLocation } from "react-router-dom";
import "./breadcrumb.css";

const BreadCrumbs = () => {
  const location = useLocation();

  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crums) => crums !== "")
    .map((crumb, index) => {
      currentLink += `/${crumb}`;
      return (
        <div key={index} className="crumb">
          <NavLink
            className={({ isActive, isPending }) => (isPending ? "" : isActive ? "underline" : "")}
            to={currentLink}
          >
            {crumb}
          </NavLink>
        </div>
      );
    });
  return (
    <div
      className="breadcrumbs  pl-1 pr-2 py-1.5 md:ml-6 ml-2 my-6 rounded-lg  capitalize flex items-start w-[fit-content]  max-w-full flex-wrap"
      key={crumbs}
    >
      {crumbs}
    </div>
  );
};

export default BreadCrumbs;
