import React, { useEffect, useState } from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { getJumboAction } from "../../../redux/actions/transactionListAction";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { GoTasklist } from "react-icons/go";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";

const PosVisa = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [isOpen, setIsOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const TransactionArray = ["Cash", "(POS) Visa", "Bank Transfer", "Jumbo4pay"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChoose = (item) => {
    if (item === "Jumbo4pay") {
      dispatch(getJumboAction(token, (result) => {}));
    }
  };

  /////permission/////////
  const permissionData = {
    permission_codes: [
      "accounting.view_posmachinetransaction",
      "accounting.view_batch",
      "accounting.add_batch",
      "accounting.change_batch",
      "accounting.delete_batch",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission.some((item) => item.value) ? (
          <div className="w-full ">
            <div className="w-full flex justify-center   ">
              <h2 className="header-h2">
                {" "}
                <RiKeyboardLine className="w-5 h-5" />
                POS VISA
              </h2>
            </div>

            <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 text-center sm:grid-cols-1 grid-cols-1 form-wrap">
              {/* <div class = "flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
              <button  onClick={()=>navigate("./pos-machine")}
                      className='flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all'>
              <div className='w-full flex justify-center items-center '>
                <div className='relative flex flex-col'>
                    <BsFillCreditCardFill className='w-12 h-12' />
                    <VscAdd className='absolute top-10 left-12 w-4 h-4' />
                </div>
              </div>   
              </button>
              <p className='text-lg font-semibold'>Create POS Machine</p>
        </div> */}
              {permission.map((item) => {
                if (item.name === "accounting.view_posmachinetransaction" && item.value === true) {
                  return (
                    <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                      <Link
                        to={"./pos-(visa)-list"}
                        // onClick={() => navigate("./pos-(visa)-list")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full  bg-red-900 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <AiOutlineUnorderedList className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold "> POS(Visa) List </p>
                    </div>
                  );
                } else if (item.name === "accounting.add_batch" && item.value === true) {
                  {
                    return (
                      <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                        <Link
                          to={"./new-batch"}
                          // onClick={() => navigate("./new-batch")}
                          className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-cyan-900 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                        >
                          <div className="w-full flex justify-center items-center ">
                            <HiOutlineViewGridAdd className="w-12 h-12  " />
                          </div>
                        </Link>
                        <p className="text-lg font-semibold">Create New Batch</p>
                      </div>
                    );
                  }
                }
              })}
              {permission.some((item) => item.value) && (
                <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
                  <Link
                    to={"./batch-list"}
                    // onClick={() => navigate("./batch-list")}
                    className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-green-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                  >
                    <div className="w-full flex justify-center items-center ">
                      <GoTasklist className="w-12 h-12  " />
                    </div>
                  </Link>
                  <p className="text-lg font-semibold"> Batch List </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default PosVisa;
