import React from "react";
import "./RoomsDynamicCards.scss";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useNavigate } from "react-router";
import { base_URL } from "../../../urls";

const splideOptionsMultiForms = {
  perPage: 1,
  perMove: 1,
  arrows: true,
  keyboard: "global",
  rewind: false,
  gap: "2rem",
  pagination: false,
  padding: "0",
};

// function to divid my rooms to arrays of four
function divideToArrOfFour(rooms) {
  let copy = rooms.slice(0);
  let AllRooms = [];
  for (let i = 0; i < Math.ceil(rooms.length / 4); i++) {
    let fourRooms = copy.splice(0, 4);
    AllRooms.push(fourRooms);
  }
  return AllRooms;
}

export default function RoomsDynamicCards({ rooms }) {
  const navigate = useNavigate();
  let AllRooms = divideToArrOfFour(rooms);
  return (
    <Splide options={splideOptionsMultiForms} className="roomsDynamicCards">
      {AllRooms.map((roomArr, index) => {
        return (
          <SplideSlide key={index} className="mb-0.5">
            <div className="fourRoomsCard ">
              {roomArr.map((room, index) => {
                return (
                  <div key={index} className="oneRoomCard">
                    {room.room_images[0] && <img src={`${base_URL}${room.room_images[0].image}`} alt="" width={100} />}
                    <div>
                      <h2
                        onClick={() => {
                          navigate(`/dashboard/room-details/${room.id}`);
                        }}
                      >
                        {room.room_name}
                      </h2>
                      <p>
                        Space: {room.space} m<sup>2</sup>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </SplideSlide>
        );
      })}
    </Splide>
  );
}
