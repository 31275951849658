import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillCreditCardFill } from "react-icons/bs";
import { VscAdd } from "react-icons/vsc";
import { GiBank } from "react-icons/gi";
import { RiKeyboardLine } from "react-icons/ri";
import { BsSafe } from "react-icons/bs";
import { AiOutlineWallet } from "react-icons/ai";
import { IoIosList } from "react-icons/io";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

const EWallet = () => {
  const navigate = useNavigate();
  return (
    <div className="formPage-wrap">
      <div className="w-full ">
        <div className="w-full flex justify-center  ">
          <h2 className="header-h2">
            {" "}
            <RiKeyboardLine className="w-5 h-5" />
            E-Wallet
          </h2>
        </div>

        <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  border mb-4  gap-y-5 form-wrap">
          <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
            <Link
              to={"./new-wallet"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <div className="relative flex flex-col">
                  <AiOutlineWallet className="w-12 h-12  " />
                  <VscAdd className="absolute top-10 left-12 w-4 h-4" />
                </div>
              </div>
            </Link>
            <p className="text-lg font-semibold">Add New E-Wallet </p>
          </div>

          <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"./wallet-list"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <IoIosList className="w-12 h-12   " />
              </div>
            </Link>
            <p className="text-lg font-semibold">E-Wallet List</p>
          </div>

          <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"./transfer-amount"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <FaMoneyBillTransfer className="w-12 h-12   " />
              </div>
            </Link>
            <p className="text-lg font-semibold">Transfer Amount</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EWallet;
