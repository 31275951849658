import cookie from "react-cookies";
import { base_URL } from "../../../../urls";
import axios from "axios";
import { toast } from "react-toastify";

const token = cookie.load("access_token");

// CustomUploadAdapterPlugin.js
export default function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          if (token) {
            const data = new FormData();
            loader.file.then((file) => {
              data.append("image", file);

              // Show loading toast
              const loadingToastId = toast.loading("Uploading image...");

              axios
                .post(`${base_URL}/it-ticketing-system/api/v1/editor-images/`, data, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((result) => {
                  // Update the loading toast to success
                  toast.update(loadingToastId, {
                    render: "Image uploaded successfully!",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 5000,
                  });

                  resolve({
                    default: result.data.map((item) => item.image), // URL from your server response
                  });
                })
                .catch((error) => {
                  // Update the loading toast to error
                  toast.update(loadingToastId, {
                    render: "Image upload failed!",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000,
                  });

                  reject(error);
                });
            });
          }
        });
      },
    };
  };
}
