import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBookingRequestsList,
  getBookingStats,
  updateBookingStatus,
} from "../../../redux/actions/bookingRequests";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import StarRating from "../../atoms/StarRating/StarRating";
import { useNavigate } from "react-router";
import { AiFillEye } from "react-icons/ai";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
// import { Button, Checkbox, Dropdown, Menu, Select } from "antd";
import ListFilters from "../../../utils/ListFilters";
import { ImStatsDots } from "react-icons/im";
// import { Radio, Tabs } from "antd";
// import { Button } from "flowbite-react";
import { VscGitPullRequestNewChanges } from "react-icons/vsc";
import { MdPendingActions } from "react-icons/md";
import { IoLockClosedSharp } from "react-icons/io5";
import { Button, Label, Modal, Select, Textarea } from "flowbite-react";
import { toast } from "react-toastify";

export function formatMilliSeconds(time) {
  let timeInSecs = time / 1000;
  if (timeInSecs >= 86400) {
    let days = Math.floor(timeInSecs / 86400);
    let hours = Math.floor((timeInSecs - days * 86400) / 3600);
    let minutes = Math.floor((timeInSecs - (days * 86400 + hours * 3600)) / 60);
    let hoursStr = `${hours}`.padStart(2, 0);
    let minsStr = `${minutes}`.padStart(2, 0);
    return `${days}:${hoursStr}:${minsStr}`;
  } else {
    let days = 0;
    let hours = Math.floor((timeInSecs - days * 86400) / 3600);
    let minutes = Math.floor((timeInSecs - (days * 86400 + hours * 3600)) / 60);
    let hoursStr = `${hours}`.padStart(2, 0);
    let minsStr = `${minutes}`.padStart(2, 0);
    return `${days}:${hoursStr}:${minsStr}`;
  }
}

const BookingHubTable = () => {
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "room_booking.change_bookingrequest",
      "room_booking.view_bookingrequest",
      "room_booking.delete_bookingrequest",
    ],
  };
  const [permission, setPermission] = useState();
  const [permissionFlag, setPermissionFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [popup, setPopup] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [activeStatus, setActiveStatus] = useState("new");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [bookingRequests, setBookingReq] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const bookingsStats = useSelector((state) => state.bookingsStats);
  const total = Math.ceil(bookingRequests?.count / pageSize);

  function reshapeText(text) {
    let firstReshape = text?.replace(/_/g, " ");
    let secondReshape = firstReshape[0]?.toUpperCase() + firstReshape?.slice(1);
    return secondReshape;
  }

  const {
    data: stats,
    refetch,
    isSuccess,
  } = useQuery({
    queryKey: ["stats", activeStatus],
    queryFn: () => fetchDataQuery(`/booking/api/v1/booking-request-status-statistics/`),
  });
  useEffect(() => {
    if (isSuccess) {
      setStatsData(stats);
    }
  }, [activeStatus, isSuccess, stats, bookingRequests.results]);

  useEffect(() => {
    if (stats) {
      refetch();
    }
  }, [stats, refetch, bookingRequests.results, activeStatus]);
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setPermissionFlag(true);
          setPermission(result.data);
          setIsLoading(false);
        }
      }),
    );
  }, [token, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getAllBookingRequestsList(currentPage, pageSize, activeStatus, (res) => {
        if (res.status === 200) {
          setBookingReq(res?.data);
        }
        setIsLoading(false);
      }),
    );
    dispatch(getBookingStats());
  }, [dispatch, currentPage, pageSize, activeStatus]);

  const handleTableChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const columns = [
    { key: "id", title: "ID", dataIndex: "id", render: (text, params) => renderId(params) },
    { key: "hotelName", title: "Hotel Name", dataIndex: "hotelName" },
    { key: "title", title: "Request Type", dataIndex: "title" },
    { key: "description", title: "Description", dataIndex: "description" },
    { key: "date", title: "Created Date", dataIndex: "date" },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (text, params) => renderStatusCell(params),
    },
    { key: "priority", title: "Priority", dataIndex: "priority", render: (text, params) => renderPriorityCell(params) },
    {
      key: "responseRating",
      title: "Response Rating",
      dataIndex: "responseRating",
      render: (text, params) => renderRatingCell(params),
    },
    { key: "responseTime", title: "Response Time", dataIndex: "responseTime" },
    { key: "avgTime", title: "Average Time (day/hour/min)", dataIndex: "avgTime" },
    {
      key: "closeRequest",
      title: "Close Request",
      dataIndex: "closeRequest",
      render: (text, params) => renderCloseRequest(params),
    },
    { key: "assignee", title: "Assignee", dataIndex: "assignee" },
  ];

  const rows = (requests) => {
    return requests?.map((request) => {
      let avgTime = null;
      if (request.booking_status === "closed") {
        let beginDate = new Date(request.created_date);
        let endDate = new Date(request.closed_timestamp);
        avgTime = endDate - beginDate;
      }
      return {
        id: request.id,
        hotelName: request?.hotel_name,
        title: request.other_note ? `Other: ${request.other_note}` : request.request_type.types,
        description: request.description,
        date: request.created_date.split("T")[0],
        status: request.booking_status,
        priority: request.request_priority,
        responseRating: request.response_rating || "----",
        responseTime: request.response_time.split("T")[0],
        avgTime: avgTime ? formatMilliSeconds(avgTime) : "----",
        assignee: request.first_admin_response_to_initial_message
          ? request.first_admin_response_to_initial_message.sender_name
          : "----",
      };
    });
  };

  useEffect(() => {
    const initialRequests = bookingRequests?.results?.filter((request) => request?.booking_status === activeStatus);
    const initialRows = rows(initialRequests);
    setFilteredData(rows(initialRequests || initialRows));
  }, [bookingRequests?.results, activeStatus]);

  async function closeRequest(params) {
    setIsLoading(true);

    const data = { booking_status: "closed" };

    try {
      await dispatch(updateBookingStatus(params, data));
      // Fetch the updated booking requests list after updating status
      await dispatch(
        getAllBookingRequestsList(currentPage, pageSize, activeStatus, (res) => {
          if (res.status === 200) {
            setBookingReq(res?.data);
          }
          setIsLoading(false);
        }),
      );
      toast.success("Closed Successfully");
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  function renderCloseRequest(params) {
    return (
      <button type="button" className="p-2 text-white bg-blue-900 rounded-lg" onClick={() => closeRequest(params.id)}>
        Close request
      </button>
    );
  }

  function renderId(params) {
    return (
      <div className="flex items-center gap-x-1">
        {params.id}
        <AiFillEye className="w-5 h-5" onClick={() => navigate(`/dashboard/booking-request-details/${params.id}`)} />
      </div>
    );
  }

  const renderStatusCell = (params) => {
    const status = params?.status;
    let backgroundColor = "";

    if (status === "new") {
      backgroundColor = "#00ce6c";
    } else if (status === "closed") {
      backgroundColor = "#ADD8E6";
    } else if (status === "pending") {
      backgroundColor = "#faab3a";
    }

    return (
      <button
        type="button"
        style={{
          backgroundColor,
          padding: "8px",
          borderRadius: "4px",
          width: "100%",
          textAlign: "center",
          cursor: "pointer",
          overflow: "hidden",
        }}
        onClick={() => {
          setPopup(params);
        }}
        className="capitalize"
      >
        {params?.status}
      </button>
    );
  };

  function renderPriorityCell(params) {
    const priority = params.priority;
    let backgroundColor = "";

    if (priority === "urgent_priority") {
      backgroundColor = "red";
    }

    return (
      <div
        className="capitalize"
        style={{ backgroundColor, padding: "8px", borderRadius: "4px", width: "100%", textAlign: "center" }}
      >
        {reshapeText(params.priority)}
      </div>
    );
  }

  function renderRatingCell(params) {
    return <StarRating starsCount={Number(5)} starSize={25} width="100%" />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!permissionFlag) {
    return <NoPermission />;
  }

  async function handleUpdate(e) {
    e.preventDefault();
    setIsLoading(true);
    setPopup(null);

    const data = {
      booking_status: e.target.status.value,
      note: e.target.note.value,
    };

    try {
      await dispatch(updateBookingStatus(popup.id, data));
      // Fetch the updated booking requests list after updating status
      await dispatch(
        getAllBookingRequestsList(currentPage, pageSize, activeStatus, (res) => {
          if (res.status === 200) {
            setBookingReq(res?.data);
          }
          setIsLoading(false);
        }),
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
      toast.success("Status changed Successfuly");
    }
  }

  const handleChange = (value) => {
    setActiveStatus(value);
    setCurrentPage(1);
    const filteredRequests = bookingRequests?.results?.filter(
      // const filteredRequests = allRequests?.filter(
      (request) => request.booking_status === value.toLowerCase(),
    );
    setFilteredData(rows(filteredRequests));
  };

  return (
    <div className="formPage-wrap min-h-screen">
      {isLoading && !permissionFlag ? (
        <>
          <Loading />
          <TopBarProgress />
        </>
      ) : permissionFlag && permission.some((item) => item.value) ? (
        <div className="flex flex-col justify-evenly">
          {Object.keys(bookingsStats).length > 0 && (
            <table className="w-full mx-auto my-5 table-auto rounded-md">
              <thead className="">
                <th
                  className=" text-[#002244] text-xl flex items-center gap-2 font-bold text-left py-3 rounded-t-md w-max"
                  colSpan={4}
                >
                  <ImStatsDots className="w-5 h-5" />
                  Requests Stats
                </th>
              </thead>
              <tbody>
                <tr className="w-full p-5">
                  <td className="bg-gray-300 text-black font-bold px-2 p-3  border-r">New Requests</td>
                  <td className="bg-gray-300 text-black font-bold px-2 p-3  border-r">Pending Requests</td>
                  <td className="bg-gray-300 text-black font-bold px-2 p-3  border-r">Closed Requests</td>
                </tr>
                <tr className="w-full p-5">
                  <td className="bg-gray-100  px-3  border-r">{statsData?.new_count}</td>
                  <td className="bg-gray-100  px-3  border-r">{statsData?.pending_count}</td>
                  <td className="bg-gray-100  px-3  border-r">{statsData?.closed_count}</td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="   w-max  ">
            <div className="w-full flex flex-row max-md:flex-col gap-2 justify-center items-center  ">
              <Button.Group className="flex flex-wrap">
                <Button color={activeStatus === "new" ? "info" : "gray"} onClick={() => handleChange("new")}>
                  <span className="flex items-center gap-2">
                    <VscGitPullRequestNewChanges />
                    New Requests
                  </span>
                </Button>
                <Button color={activeStatus === "pending" ? "info" : "gray"} onClick={() => handleChange("pending")}>
                  <span className="flex items-center gap-2">
                    <MdPendingActions />
                    Pending Requests
                  </span>
                </Button>
                <Button color={activeStatus === "closed" ? "info" : "gray"} onClick={() => handleChange("closed")}>
                  <span className="flex items-center gap-2">
                    <IoLockClosedSharp />
                    Closed Requests
                  </span>
                </Button>
              </Button.Group>
            </div>
          </div>
          {filteredData ? (
            <ListFilters
              columns={columns}
              rows={filteredData || []}
              title="Booking HUB"
              currentPage={currentPage}
              totalPages={total}
              listPageSize={pageSize}
              handlePagination={handleTableChange}
              rowKey="id"
              Loading={<Loading />}
            />
          ) : (
            <Loading />
          )}

          {}
          {popup && (
            <Modal dismissible show={popup} size="md" popup={true} onClose={() => setPopup(null)}>
              <Modal.Header className="px-6 border-b">Update Information</Modal.Header>
              <Modal.Body>
                <div className=" flex flex-col gap-2 my-4">
                  <p className="text-base font-medium">
                    Hotel Name: <span className="font-normal">{popup.hotelName}</span>
                  </p>
                  <p className="text-base font-medium">
                    Title: <span className="font-normal">{popup.title}</span>{" "}
                  </p>
                  <p className="text-base font-medium">
                    Date: <span className="font-normal">{popup.date}</span>
                  </p>
                  <p className="text-base font-medium">
                    Priority: <span className="font-normal capitalize">{reshapeText(popup.priority)}</span>
                  </p>
                  <p className="text-base font-medium">
                    Description: <span className="font-normal">{popup.description}</span>
                  </p>
                </div>
                <form onSubmit={handleUpdate}>
                  <div className="flex flex-col gap-4">
                    <div>
                      <Label htmlFor="status" value="Status" className="text-base" />
                      <Select name="status" id="status" className="my-1 border border-black rounded-lg">
                        <option value="new" className="test-base">
                          New
                        </option>
                        <option value="pending" className="test-base">
                          Pending
                        </option>
                        <option value="closed" className="test-base">
                          Closed
                        </option>
                      </Select>
                    </div>
                    <div>
                      <Label htmlFor="note" value="Note" className="text-base" />
                      <Textarea
                        name="note"
                        id="note"
                        rows={5}
                        className="my-1 py-1 px-2 border border-black rounded-lg"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <Button type="submit" color="blue">
                      Update
                    </Button>
                    <Button color="red" onClick={() => setPopup(null)}>
                      Close
                    </Button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          )}
        </div>
      ) : (
        <div className="w-full h-screen">
          <NoPermission />
        </div>
      )}
    </div>
  );
};

export default BookingHubTable;
