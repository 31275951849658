import { toast } from "react-toastify";
import api_token from "../../api/UserApi";
import { RESEVE_DATA_FROM_SEARCH, CLIENTREQUEST, DISPLAYRESERVATION, RESRVATION_NOTIF_FLAG } from "../types/types";

export const GetDataFromSearchForReseve = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: RESEVE_DATA_FROM_SEARCH,
      payload: data,
    });
  };
};

export const getClientRequestTag = (token) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("/booking/api/v1/get-booking-tags/");
      dispatch({
        type: CLIENTREQUEST,
        payload: response.data,
      });
    } catch (error) {
      toast.error(error || "error fetch data");
    }
  };
};
export const postReservationRoomAction = (token, data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DISPLAYRESERVATION,
        payload: data,
      });
    } catch (error) {}
  };
};

export const reservationRoomAction = (token, data, callback, failureCallback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post("/booking/api/v1/add-book-room/", data);

      dispatch({
        type: DISPLAYRESERVATION,
        payload: response.data,
      });

      if (response.status === 201) {
        dispatch({
          type: RESRVATION_NOTIF_FLAG,
          payload: true,
        });
        setTimeout(() => {
          dispatch({
            type: RESRVATION_NOTIF_FLAG,
            payload: false,
          });
        }, [30000]);
        callback(response);
      } else {
        failureCallback(response.data);
      }
    } catch (error) {
      failureCallback(error);
    }
  };
};
export const notifToggleAction = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: RESRVATION_NOTIF_FLAG,
      payload: false,
    });
  };
};

export const clearDisplayReservationData = (token, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DISPLAYRESERVATION,
      payload: [],
    });
  };
};
