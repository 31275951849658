import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction, getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { FaWpforms } from "react-icons/fa6";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { TimePicker } from "antd";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendData } from "../../../redux/actions/sendDataAction";
import { ADD_BANKTRANSFER_URL } from "../../../urls";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

const AddBankTransfer = ({ method, bankTranfer }) => {
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [convertAmount, setConvertAmount] = useState();
  const [convertRate, setConvertRate] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [date, setDate] = useState("");
  const [accountNumberBank, setAccountNumberBank] = useState("");
  const [bank, setBank] = useState("");
  const [bankId, setBankId] = useState("");
  const [time, setTime] = useState("");
  const format = "HH:mm";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  useRemoveScroll();
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  const formik = useFormik({
    initialValues: {
      bank_name: "",
      transfer_amount: "",
      date: "",
      time: "",
      transferor_name: "",
      transfer_acc_number: "",
      transfer_receipt: null,
    },
    validationSchema: Yup.object({
      bank_name: Yup.string().required("Please select bank name"),
      transfer_amount: Yup.number()
        .required("Please enter a transfer amount")
        .positive("Transfer amount must be positive number")
        .integer("Transfer amount must be positive number")
        .min(0, "Transfer amount must be greater than or equal to 0"),
      date: Yup.date().required("Please enter transaction date"),
      time: Yup.string().required("Please enter transaction time"),
      transferor_name: Yup.string()
        .required("Please enter a transfer name")
        .min(2, "Transfer name must be greater than or equal to 2")
        .max(50, "Transfer name must be smaller than or equal to 50"),
      transfer_acc_number: Yup.number()
        .required("Please enter a transfer account number")
        .positive("Transfer account number must be positive number")
        .integer("Transfer account number must be positive number")
        .min(1, "Transfer account number must be greater than  0"),
      transfer_receipt: Yup.mixed().required("Please upload a transfer receipt"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      const { date, time, ...restData } = values;
      Object.keys(restData).forEach((key) => {
        formData.append(key, restData[key]);
      });
      formData.append("booked_room", params.id);
      formData.append("transaction_date", `${date},${time}`);
      formData.append("transaction_status", "pending");
      formData.append("currency_type", currency);
      formData.append("bank_account_number", accountNumberBank);
      formData.append("contract_currency", selectedSingleRoom.currency_type);
      formData.append("conversion_rate_price", convertAmount.toFixed(2));
      formData.append("conversion_rate", convertRate);
      dispatch(
        sendData(ADD_BANKTRANSFER_URL, formData, (result) => {
          if (result.status === 201) {
            lightToast.success("Bank Transfer Form was completed successfully");
            navigate(-1);
          }
        }),
      );
    },
  });

  useEffect(() => {
    //////////handle when reload page , alert show to make sure this i want reload ////////////
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ///////////////////////////////////////////////////////////
    dispatch(getSelectedBookedRoomSingleAction(token, params.id, (result) => {}, signal));
    dispatch(getBankListAction(token, (result) => {}, signal));
    dispatch(getCurrencyAction(token, signal));
    return () => {
      controller.abort();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { bankData } = useSelector((state) => state.cashReducer);
  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency, bankAccountNumber: item.account_number };
      })
    : [];

  const handleAmount = (e) => {
    // setAmount(e.target.value);
    formik.setFieldValue("transfer_amount", e.target.value);

    if (convertRate) {
      setConvertAmount(e.target.value * convertRate);
    }
  };

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setTime(`${hours1}:${minutes1}`);
    formik.setFieldValue("time", `${hours1}:${minutes1}`);
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.add_banktransfer"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {selectedSingleRoom && permissionFlag ? (
          permission.map((item, index) => {
            if (item.name === "room_booking.add_banktransfer" && item.value === true) {
              return (
                <div key={index} className="w-full ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      {" "}
                      <FaWpforms className="w-5 h-5" />
                      Bank Transfer Form{" "}
                    </h2>
                  </div>

                  <form className="form-wrap  w-full" onSubmit={formik.handleSubmit} encType="multipart/form-data">
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <div className="w-full flex flex-col justify-start items-center">
                        <label className=" flex text-gray-600   ">Bank Name</label>
                        <Select
                          placeholder="Bank Name"
                          type="text"
                          name="bank_name"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: `${formik.touched.bank_name && formik.errors.bank_name && "rgb(153 27 27)"}`,
                            }),
                          }}
                          options={Bank}
                          value={bank ? [{ label: bank, value: bank }] : null}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue("bank_name", e.value);
                            setBank(e.label);
                            setBankId(e.value);
                            setCurrency(e.currency);
                            setAccountNumberBank(e.bankAccountNumber);
                            setLoadingToggle(true);
                            setToggle(false);
                            dispatch(
                              convertingCurrencyAction(
                                token,
                                e.currency,
                                selectedSingleRoom.currency_type,
                                (result) => {
                                  if (result.status === 200) {
                                    result.data.results.length !== 0
                                      ? setConvertAmount(
                                          amount && amount * result.data.results.map((item) => item.rate),
                                        )
                                      : setConvertAmount(amount && amount * 1);
                                    result.data.results.length !== 0
                                      ? setConvertRate(...result.data.results.map((item) => item.rate))
                                      : setConvertRate(1);
                                    setToggle(true);
                                    setLoadingToggle(false);
                                  }
                                },
                              ),
                            );
                          }}
                          isSearchable
                          className=" w-full  h-10  rounded-lg  bg-white "
                        />
                        {formik.touched.bank_name && formik.errors.bank_name ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {formik.errors.bank_name}
                          </div>
                        ) : null}
                      </div>

                      {currency && toggle ? (
                        <>
                          <label className=" flex text-gray-600 ">Currency</label>
                          <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{currency}</p>
                          </div>
                          <label className=" flex text-gray-600 ">Account Number</label>
                          <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{accountNumberBank}</p>
                          </div>
                        </>
                      ) : (
                        loadingToggle && (
                          <>
                            <ButtonLoading />
                          </>
                        )
                      )}

                      <label className=" flex text-gray-600 ">The Amount</label>
                      <input
                        placeholder="The Amount"
                        name="transfer_amount"
                        type="number"
                        step="any"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleAmount}
                        onBlur={formik.handleBlur}
                        value={formik.values.transfer_amount}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          formik.touched.transfer_amount && formik.errors.transfer_amount && "border border-red-800"
                        } `}
                      />
                      {formik.touched.transfer_amount && formik.errors.transfer_amount ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {formik.errors.transfer_amount}
                        </div>
                      ) : null}

                      {amount && (
                        <>
                          <label className=" flex text-gray-600 ">
                            Amount With Currency Of Contract ({selectedSingleRoom.currency_type})
                          </label>
                          <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                            <div className="text-black flex items-end gap-x-1">
                              {convertAmount}{" "}
                              <span className="text-sm font-semibold text-gray-700">
                                {selectedSingleRoom.currency_type}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="input-par">
                        <div className="input-chil">
                          <label className="flex justify-center text-gray-600 "> Date</label>
                          <input
                            placeholder="Tranfer Date"
                            type="date"
                            name="date"
                            onChange={(e) => {
                              setDate(e.target.value);
                              formik.setFieldValue("date", e.target.value);
                            }}
                            value={formik.values.date}
                            onBlur={formik.handleBlur}
                            className={` w-full px-3 h-10  border rounded-lg bg-white floating-input ${
                              formik.touched.date && formik.errors.date && "border border-red-800"
                            } `}
                          />
                          {formik.touched.date && formik.errors.date ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {formik.errors.date}
                            </div>
                          ) : null}
                        </div>
                        <div className="input-chil">
                          <label className=" flex justify-center text-gray-600 ">Time</label>
                          <TimePicker
                            clearIcon={false}
                            name="time"
                            as={TimePicker}
                            format={format}
                            className={` w-full py-2  border rounded-lg bg-white ${
                              formik.touched.time && formik.errors.time && "border border-red-800"
                            } `}
                            onChange={handleTimeChange}
                            // value={formik.values.time}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.time && formik.errors.time ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {formik.errors.time}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <label className=" text-center w-full text-gray-600   ">Transferor Name</label>
                      <input
                        placeholder="Transferor Name"
                        name="transferor_name"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.transferor_name}
                        onBlur={formik.handleBlur}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          formik.touched.transferor_name && formik.errors.transferor_name && "border border-red-800"
                        } `}
                      />
                      {formik.touched.transferor_name && formik.errors.transferor_name ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {formik.errors.transferor_name}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600   ">Sender Bank Account Number</label>
                      <input
                        placeholder="Sender Bank Account Number"
                        name="transfer_acc_number"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.transfer_acc_number}
                        onBlur={formik.handleBlur}
                        className={` w-full px-3 h-10  border rounded-lg bg-white ${
                          formik.touched.transfer_acc_number &&
                          formik.errors.transfer_acc_number &&
                          "border border-red-800"
                        } `}
                      />
                      {formik.touched.transfer_acc_number && formik.errors.transfer_acc_number ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {formik.errors.transfer_acc_number}
                        </div>
                      ) : null}
                      {/* <input
                                  placeholder="Bank Name"
                                  name="bank_name"
                                  type="text"
                                  required
                                  onChange={(e)=>setBank(e.target.value)}
                                  className=" w-full px-3 h-10  border rounded-lg bg-white "
                                              />       */}
                      <label className=" flex text-gray-600   ">Choose A File</label>
                      <input
                        placeholder="Bank Name"
                        name="transfer_receipt"
                        type="file"
                        onChange={(e) => formik.setFieldValue("transfer_receipt", e.target.files[0])}
                        onBlur={formik.handleBlur}
                        className={` w-full px-3 h-10 py-1 border rounded-lg bg-white ${
                          formik.touched.transfer_receipt && formik.errors.transfer_receipt && "border border-red-800"
                        } `}
                      />
                      {formik.touched.transfer_receipt && formik.errors.transfer_receipt ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {formik.errors.transfer_receipt}
                        </div>
                      ) : null}
                      <div className="w-full flex items-center justify-center">
                        <img
                          src={formik.values.transfer_receipt && URL.createObjectURL(formik.values.transfer_receipt)}
                          className="w-52"
                        />
                      </div>
                      <button
                        type="Submit"
                        disabled={formik.isSubmitting}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Add{" "}
                        {formik.isSubmitting ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <IoAdd className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              );
            }
          })
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <TopBarProgress /> <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddBankTransfer;
