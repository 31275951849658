import {
  GET_ALL_HOTELS_INFO,
  GET_SINGLE_HOTEL_INFO,
  ALL_HOTELS_INFO_LOADING,
  GET_SINGLE_HOTEL_INFO_BY_HOTEL_ID,
  SINGLE_HOTEL_INFO_LOADING,
} from "../types/types";

const allHotelsInitialState = {
  data: [],
  loading: false,
};

export function allHotelsInfoReducer(state = allHotelsInitialState, action) {
  switch (action.type) {
    case GET_ALL_HOTELS_INFO:
      return { loading: false, data: action.payload };

    case ALL_HOTELS_INFO_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
}

const SHDefaultState = {
  default: true,
  telephone_numbers: {},
  duty_manager_numbers: {},
  reservation_emails: {},
  reception_emails: {},
};
export function singleHotelInfoReducer(state = SHDefaultState, action) {
  switch (action.type) {
    case GET_SINGLE_HOTEL_INFO:
      return action.payload;
    default:
      return state;
  }
}

const singleHotelInfoInitialState = {
  data: [],
  loading: false,
};

export function getSibgleHotelInfoByIdReducer(state = singleHotelInfoInitialState, action) {
  switch (action.type) {
    case SINGLE_HOTEL_INFO_LOADING:
      return { ...state, loading: true };
    case GET_SINGLE_HOTEL_INFO_BY_HOTEL_ID:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}
