import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { useParams } from "react-router";
import Loading from "../../Loading/Loading";
import { getChequesActionId } from "../../../../redux/actions/bankChequesActions";

const ChequeDetail = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const params = useParams();
  const fetchData = () => {
    dispatch(
      getChequesActionId(token, params.id, (res) => {
        if (res.status === 201 || res.status === 200) {
          setData(res.data);
        }
      }),
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="w-full px-6 my-16">
      {data && params.id ? (
        <div className=" max-w-4xl mx-auto  my-8 flex flex-col gap-2 shadow-md border rounded-md ">
          <h2 className="text-xl   font-bold flex justify-center items-center w-full text-white rounded-md py-2  bg-[#002244]">
            {" "}
            Cheque No {data?.cheque_number} operation
          </h2>
          <div className="flex gap-2 px-2 pt-2 font-bold">
            <span className="text-gray-800">ID :</span>
            <span>{data?.id}</span>
          </div>
          {/* <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span>Bank Name :</span>
            <span>{data?.bank?.name}</span>
          </div> */}
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">cheque_number :</span>
            <span>{data?.cheque_number}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold">
            <span className="text-gray-800">Bank :</span>
            <span>{data?.bank?.name}</span>
          </div>
          <div className="flex gap-2 pl-2 font-bold">
            <span className="text-gray-800">Bank Account Number :</span>
            <span>{data?.bank_account_number}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold">
            <span className="text-gray-800">Amount :</span>
            <span>{data?.amount}</span>
          </div>
          <div className=" flex  gap-2 pl-2 font-bold">
            <span className="text-gray-800">Added by :</span>
            <span>{data?.added_by}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">Cheque Book :</span>
            <span>{data?.cheque_book}</span>
          </div>

          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">cheque_status :</span>
            <span>{data?.cheque_status}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">confirmed_by :</span>
            <span>{data?.confirmed_by}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">Cheque Book :</span>
            <span>{data?.cheque_book}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">details :</span>
            <span>{data?.details}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">due_date :</span>
            <span>{data?.due_date}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">expenses :</span>
            <span>{data?.expenses}</span>
          </div>
          {data?.hotel !== null && (
            <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
              <span className="text-gray-800">hotel :</span>
              <span>{data?.hotel?.name}</span>
            </div>
          )}
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span>income :</span>
            <span>{data?.income}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">issued_date :</span>
            <span>{data?.issued_date}</span>
          </div>
          {data?.other !== null && (
            <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
              <span className="text-gray-800">other :</span>
              <span>{data?.other}</span>
            </div>
          )}
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">purpose :</span>
            <span>{data?.purpose}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">delivered_to :</span>
            <span>{data?.delivered_to}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">created_date :</span>
            <span>{data?.created_date?.slice(0, 10)}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">date_time_deliver :</span>
            <span>{data?.date_time_deliver?.slice(0, 10)}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">transaction_status :</span>
            <span>{data?.transaction_status}</span>
          </div>
          <div className="flex gap-2 bg-gray-100 p-2 font-bold ">
            <span className="text-gray-800">updated_at :</span>
            <span>{data?.updated_at?.slice(11, 19)}</span>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default ChequeDetail;
