import React, { useEffect, useRef, useState } from "react";
import "./../AddHotelForm/DashboardForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import cookie from "react-cookies";
// import jwtDecode from "jwt-decode";
// actions imports
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import {
  requestCurrencyTypes,
  requestMarketTypes,
  requestMealsIncluded,
  requestOfferTypes,
} from "../../../redux/actions/addContract";
import { clearHotelRooms, getHotelRooms } from "../../../redux/actions/getRooms";
import { clearOldValues, fromExistingValues } from "../../../redux/actions/contractValidity";
import { clearSingleContract, getSingleContract, getSingleDeletedContract } from "../../../redux/actions/getContracts";
import { editContract, editDeletedContract } from "../../../redux/actions/editContract";
// components imports
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import StaticFromInput from "../../molecules/StaticFormInput/StaticFromInput";
import DynamicCheckbox from "../../molecules/DynamicCheckbox/DynamicCheckbox";
import MultiDynamicInputs from "../../molecules/MultiDynamicInputs/MultiDynamicInputs";
import ContractFormDynamicComponentParent from "../../molecules/ContractFormRoomDynamicComponentParent/ContractFormDynamicComponentParent";
import "react-dates/lib/css/_datepicker.css";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import HashLoader from "react-spinners/HashLoader";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
// import { NotificationContainer, NotificationManager } from "react-notifications";
import { LiaFileContractSolid } from "react-icons/lia";
import { IoCalendarSharp, IoDocumentTextSharp, IoNewspaper } from "react-icons/io5";
import { FaHotel, FaPercent } from "react-icons/fa";
import { BiMinusCircle, BiSolidOffer } from "react-icons/bi";
import { FaLocationCrosshairs, FaTreeCity } from "react-icons/fa6";
import { GiMeal } from "react-icons/gi";
import { BsCurrencyExchange } from "react-icons/bs";
import { RiRestaurantFill } from "react-icons/ri";
import {
  MdAddCircleOutline,
  MdDateRange,
  MdDescription,
  MdNightsStay,
  MdOutlineBedroomChild,
  MdOutlineDescription,
  MdOutlinePriceChange,
} from "react-icons/md";
import { TbBrandDaysCounter } from "react-icons/tb";
import { FcCancel } from "react-icons/fc";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { CgNotes } from "react-icons/cg";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { RxUpdate } from "react-icons/rx";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import {
  addChildMeal,
  addMealChildEndAgeValue,
  addMealChildOrder,
  addMealChildStartAgeValue,
  addNewChildMeal,
  clearChildrenMealOldValues,
  deleteMealByName,
  fillChildrenMealsFromExistingValues,
  removeChildMeal,
} from "../../../redux/actions/contractChildrenMeals";
import { toast } from "react-toastify";
import FileUploader from "../FileUploader/FileUploader";
import usePermissions from "../../../customHooks/usePermissions";
import Errorfetch from "../Errorfetch/Errorfetch";
import NoPermission from "../NoPermission/NoPermission";
import ChildrenPolicyAssistantTool from "../AddContractForm/AssistanceTools/ChildrenPolicyAssistantTool/ChildrenPolicyAssistantTool";
import ContractAssistanceTool from "../AddContractForm/AssistanceTools/ContractAssistantTool/ContractAssistanceTool";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

let childRoomTypes = [];

for (let i = 1; i < 5; i++) {
  childRoomTypes.push({ id: i, name: i });
}

const countUniqueAdults = (arr) => {
  const uniqueAdults = new Set();
  let counter = 0;

  arr.forEach((item) => {
    const { num_adults } = item;
    if (!uniqueAdults.has(num_adults)) {
      uniqueAdults.add(num_adults);
      counter++;
    }
  });

  return counter;
};

const groupByNumAdults = (weekendSupplementsPerPerson) => {
  return weekendSupplementsPerPerson.reduce((acc, current) => {
    const { num_adults, day, price } = current;

    // Check if there's already an entry for this number of adults
    if (!acc[num_adults]) {
      acc[num_adults] = [];
    }

    // Add the day-price pair to the array
    acc[num_adults].push({ day, price });

    return acc;
  }, {});
};

export default function EditContract() {
  const location = useLocation();
  const [contractDocuments, setContractDocuments] = useState([]);
  const [contractDocumentsUrls, setContractDocumentsUrls] = useState([]);

  const [breakfastChecked, setBreakfastChecked] = useState(false);
  const [lunchChecked, setLunchChecked] = useState(false);
  const [dinnerChecked, setDinnerChecked] = useState(false);
  const [softAllChecked, setSoftAllChecked] = useState(false);

  const [mealIncluded, setMealIncluded] = useState("");

  const contractChildrenMeals = useSelector((state) => state.contractChildrenMeals);
  const ageSelectOptions = Array.from({ length: 12 }, (_, i) => ({ id: i, name: i }));

  // Access the pathname property to get the current path
  const currentPath = location.pathname;
  const lastParams = currentPath.substring(currentPath.lastIndexOf("/") + 1);

  // function constants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const form = formRef.current;
  const { id } = useParams();
  useRemoveScroll();

  // local states and redux
  const [isDisabled, setIsDisabled] = useState(false);
  const [addFlag, setaddFlag] = useState(false);
  const hotelNames = useSelector((state) => state.hotelNames);
  const contractValidity = useSelector((state) => state.contractValidity);
  const contract = useSelector((state) => state.getSingleContract);

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const { marketTypes, offerTypes, mealsIncluded, currencyTypes } = useSelector((state) => state.addContract);

  const hotelRooms = useSelector((state) => state.hotelRooms);

  const [isAmountReq, setIsAmountReq] = useState(false);

  const [breakfastInitialValue, setBreakfastInitialValue] = useState("");
  const [lunchInitialValue, setLunchInitialValue] = useState("");
  const [dinnerInitialValue, setDinnerInitialValue] = useState("");
  const [softAllInitialValue, setSoftAllInitialValue] = useState("");
  const [roomFlag, setRoomFlag] = useState(false);
  const [roomBasedHotel, setRoomBasedHotel] = useState(null);
  const specificDayNums = Math.max(contract.one_day_supplements.length, 0);
  const periodSupplementInputsNum = Math.max(contract?.period_supplements?.length, 0);
  const periodWeekendSupplementInputsNum = Math.max(contract?.period_weekend_supplements?.length, 0);

  const weekendSupplementsPerPersonInputsNum = Math.max(
    countUniqueAdults(contract?.weekend_supplements_per_person || []),
    0,
  );

  const periodWeekendSupplementPerPersonInputsNum = Math.max(
    contract?.period_weekend_supplements_per_person?.length,
    0,
  );

  const contractType = contract?.contract_type || "per_person";

  const [childrenMealsInput, setChildrenMealsInput] = useState(0);
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "contracts_management.change_contract",
    "rooms_management.add_roompackageoccupancy",
  ]);
  const isAddingPackageAllowed =
    permissions?.find((permission) => permission.name === "rooms_management.add_roompackageoccupancy")?.value === true;

  const isEditingContractAllowed =
    permissions?.find((permission) => permission.name === "contracts_management.change_contract")?.value === true;

  const childrenMeals = useSelector((state) => state.contractChildrenMeals);

  // convert state arrays to compatible data with the staticFormSelect component
  const mealsIncludedArr = mealsIncluded.map((elem) => {
    return { id: elem, name: elem };
  });
  const currencyTypesArr = currencyTypes.map((elem) => {
    return { id: elem, name: elem };
  });
  const hotelRoomsArr = hotelRooms.map((roomObj) => {
    return {
      id: roomObj.id,
      name: roomObj.room_name,
      maxAdults: roomObj.max_adults,
      maxChildren: roomObj.max_children,
      maxOccupancy: roomObj.max_occupancy,
    };
  });

  useEffect(() => {
    if (!breakfastChecked) {
      dispatch(deleteMealByName("breakfast"));
    }
  }, [breakfastChecked, dispatch]);

  useEffect(() => {
    if (!lunchChecked) {
      dispatch(deleteMealByName("lunch"));
    }
  }, [lunchChecked, dispatch]);

  useEffect(() => {
    if (!dinnerChecked) {
      dispatch(deleteMealByName("dinner"));
    }
  }, [dinnerChecked, dispatch]);

  useEffect(() => {
    if (!softAllChecked) {
      dispatch(deleteMealByName("soft_all"));
    }
  }, [softAllChecked, dispatch]);

  useEffect(() => {
    if (!breakfastChecked && !dinnerChecked && !lunchChecked && !softAllChecked) {
      setChildrenMealsInput(0);
      dispatch(clearChildrenMealOldValues());
    }
  }, [breakfastChecked, dinnerChecked, dispatch, lunchChecked, softAllChecked]);

  // get data for the form
  useEffect(() => {
    if (lastParams === "edit") {
      dispatch(getSingleDeletedContract(id));
    } else {
      dispatch(getSingleContract(id));
    }

    dispatch(requestHotelNames());
    dispatch(requestMarketTypes());
    dispatch(requestOfferTypes());
    dispatch(requestMealsIncluded());
    dispatch(requestCurrencyTypes());

    return () => {
      dispatch(clearSingleContract());
      dispatch(clearHotelRooms());
      dispatch(clearOldValues());
      dispatch(clearChildrenMealOldValues());
    };
  }, []);

  useEffect(() => {
    if (contract.booking_valid_from !== "") {
      dispatch(getHotelRooms(contract.hotel.id));
    }
    return () => {
      dispatch(clearHotelRooms());
    };
  }, [contract]);

  useEffect(() => {
    if (contract && contract.validities) {
      dispatch(fromExistingValues(contract.validities));
    }

    if (contract && contract.meal_supplements_children) {
      dispatch(fillChildrenMealsFromExistingValues(contract.meal_supplements_children));
    }

    if (contract && contract.price_document) {
      setContractDocumentsUrls([contract.price_document]);
    }
  }, [contract]);

  useEffect(() => {
    if (form && contract.booking_valid_from !== "" && hotelNames.length > 0) {
      if (contract.meal_supplements) {
        for (let sup of contract.meal_supplements) {
          switch (sup.name) {
            case "breakfast":
              setBreakfastChecked(true);
              setBreakfastInitialValue(Number(sup.price));
              break;
            case "lunch":
              setLunchChecked(true);
              setLunchInitialValue(Number(sup.price));
              break;
            case "dinner":
              setDinnerChecked(true);
              setDinnerInitialValue(Number(sup.price));
              break;
            case "soft_all":
              setSoftAllChecked(true);
              setSoftAllInitialValue(Number(sup.price));
              break;
          }
        }
      }
    }
  }, [form, contract.booking_valid_from, hotelNames.length]);

  // to set form values
  useEffect(() => {
    if (form && contract.booking_valid_from !== "" && hotelNames.length > 0 && marketTypes.length > 0) {
      form.name.value = contract.name;
      form.hotel.value = contract.hotel.id;
      form.market.value = contract.market.id;
      form.offer_type.value = contract.offer_type.id;

      form.meal_included.value = contract.meal_included;
      setMealIncluded(contract.meal_included);

      setChildrenMealsInput(contract?.meal_supplements_children?.length);

      form.currency_type.value = contract.currency_type;
      form.commission_type.value = contract.commission_type;
      contract.commission_price ? (form.commission_price.value = Number(contract.commission_price)) : void 0;
      // form.max.value = contract.price_policy_maximum_days;
      // form.min.value = contract.price_policy_minimum_days;
      form.cancellation_policy.value = contract.cancellation_policy;
      form.notes.value = contract.notes;

      // Weekend Supplements
      if (contract.weekend_supplements) {
        for (let weekSup of contract.weekend_supplements) {
          form[`weekend_supplements_${weekSup.name.toLowerCase()}`].value = Number(weekSup.price);
        }
      }

      //  Weekend Supplements by person
      if (form.weekend_supplements_per_person_sat) {
        const groupedData = groupByNumAdults(contract.weekend_supplements_per_person);

        Object.keys(groupedData).forEach((numAdults, index) => {
          if (form[`weekend_supplements_per_person_sat`][index]) {
            form[`weekend_supplements_per_person_adults`][index].value = numAdults;

            groupedData[numAdults].forEach((entry) => {
              switch (entry.day) {
                case "sat":
                  form[`weekend_supplements_per_person_sat`][index].value = entry.price;
                  break;
                case "sun":
                  form[`weekend_supplements_per_person_sun`][index].value = entry.price;
                  break;
                case "mon":
                  form[`weekend_supplements_per_person_mon`][index].value = entry.price;
                  break;
                case "tue":
                  form[`weekend_supplements_per_person_tue`][index].value = entry.price;
                  break;
                case "wed":
                  form[`weekend_supplements_per_person_wed`][index].value = entry.price;
                  break;
                case "thu":
                  form[`weekend_supplements_per_person_thu`][index].value = entry.price;
                  break;
                case "fri":
                  form[`weekend_supplements_per_person_fri`][index].value = entry.price;
                  break;
                default:
                  break;
              }
            });
          } else {
            // Handle case when there's no initial data (empty form)
            form[`weekend_supplements_per_person_adults`].value = numAdults;

            groupedData[numAdults].forEach((entry) => {
              form[`weekend_supplements_per_person_${entry.day}`].value = entry.price;
            });
          }
        });
      }

      // Weekend Supplement per person
      if (form.period_weekend_supplements_per_person_start_date) {
        if (form.period_weekend_supplements_per_person_start_date[0]) {
          contract.period_weekend_supplements_per_person.forEach((entry, index) => {
            form.period_weekend_supplements_per_person_start_date[index].value = entry.start_date;
            form.period_weekend_supplements_per_person_end_date[index].value = entry.end_date;

            entry.day_prices_per_person.forEach((dayPrice) => {
              form["period_weekend_supplements_per_person_adults"][index].value = dayPrice.num_adults;

              switch (dayPrice.day) {
                case "sat":
                  form[`period_weekend_supplements_per_person_sat`][index].value = dayPrice.price;
                  break;
                case "sun":
                  form[`period_weekend_supplements_per_person_sun`][index].value = dayPrice.price;
                  break;
                case "mon":
                  form[`period_weekend_supplements_per_person_mon`][index].value = dayPrice.price;
                  break;
                case "tue":
                  form[`period_weekend_supplements_per_person_tue`][index].value = dayPrice.price;
                  break;
                case "wed":
                  form[`period_weekend_supplements_per_person_wed`][index].value = dayPrice.price;
                  break;
                case "thu":
                  form[`period_weekend_supplements_per_person_thu`][index].value = dayPrice.price;
                  break;
                case "fri":
                  form[`period_weekend_supplements_per_person_fri`][index].value = dayPrice.price;
                  break;
                default:
                  break;
              }
            });
          });
        } else {
          const entry = contract.period_weekend_supplements_per_person[0];
          form.period_weekend_supplements_per_person_start_date.value = entry.start_date;
          form.period_weekend_supplements_per_person_end_date.value = entry.end_date;

          entry.day_prices_per_person.forEach((dayPrice) => {
            form["period_weekend_supplements_per_person_adults"].value = dayPrice.num_adults;

            switch (dayPrice.day) {
              case "sat":
                form[`period_weekend_supplements_per_person_sat`].value = dayPrice.price;
                break;
              case "sun":
                form[`period_weekend_supplements_per_person_sun`].value = dayPrice.price;
                break;
              case "mon":
                form[`period_weekend_supplements_per_person_mon`].value = dayPrice.price;
                break;
              case "tue":
                form[`period_weekend_supplements_per_person_tue`].value = dayPrice.price;
                break;
              case "wed":
                form[`period_weekend_supplements_per_person_wed`].value = dayPrice.price;
                break;
              case "thu":
                form[`period_weekend_supplements_per_person_thu`].value = dayPrice.price;
                break;
              case "fri":
                form[`period_weekend_supplements_per_person_fri`].value = dayPrice.price;
                break;
              default:
                break;
            }
          });
        }
      }

      if (form.supplement) {
        let supplement, supPrice, supDate;
        if (form.supplement[0]) {
          supplement = Array.from(form.supplement);
          supPrice = Array.from(form.supplement_price);
          supDate = Array.from(form.supplement_date);
        } else {
          supplement = [form.supplement];
          supPrice = [form.supplement_price];
          supDate = [form.supplement_date];
        }
        supplement.forEach((elem, index) => {
          contract.one_day_supplements[index] ? (elem.value = contract.one_day_supplements[index].name) : void 0;
        });
        supPrice.forEach((elem, index) => {
          contract.one_day_supplements[index]
            ? (elem.value = Number(contract.one_day_supplements[index].price))
            : void 0;
        });
        supDate.forEach((elem, index) => {
          contract.one_day_supplements[index] ? (elem.value = contract.one_day_supplements[index].date) : void 0;
        });
      }

      if (form.period_supplement) {
        let supplement, supPrice, startDate, endDate;
        if (form.period_supplement[0]) {
          supplement = Array.from(form.period_supplement);
          supPrice = Array.from(form.period_supplement_price);
          startDate = Array.from(form.period_supplement_start_date);
          endDate = Array.from(form.period_supplement_end_date);
        } else {
          supplement = [form.period_supplement];
          supPrice = [form.period_supplement_price];
          startDate = [form.period_supplement_start_date];
          endDate = [form.period_supplement_end_date];
        }
        supplement.forEach((elem, index) => {
          contract.period_supplements[index] ? (elem.value = contract.period_supplements[index].name) : void 0;
        });
        supPrice.forEach((elem, index) => {
          contract.period_supplements[index] ? (elem.value = Number(contract.period_supplements[index].price)) : void 0;
        });
        startDate.forEach((elem, index) => {
          contract.period_supplements[index] ? (elem.value = contract.period_supplements[index].start_date) : void 0;
        });

        endDate.forEach((elem, index) => {
          contract.period_supplements[index] ? (elem.value = contract.period_supplements[index].end_date) : void 0;
        });
      }

      if (form.period_weekend_supplement_start_date) {
        let startDates, endDates, satPrices, sunPrices, monPrices, tuePrices, wedPrices, thuPrices, friPrices;
        if (form.period_weekend_supplement_start_date[0]) {
          startDates = Array.from(form.period_weekend_supplement_start_date);
          endDates = Array.from(form.period_weekend_supplement_end_date);
          satPrices = Array.from(form.period_weekend_supplement_sat);
          sunPrices = Array.from(form.period_weekend_supplement_sun);
          monPrices = Array.from(form.period_weekend_supplement_mon);
          tuePrices = Array.from(form.period_weekend_supplement_tue);
          wedPrices = Array.from(form.period_weekend_supplement_wed);
          thuPrices = Array.from(form.period_weekend_supplement_thu);
          friPrices = Array.from(form.period_weekend_supplement_fri);
        } else {
          startDates = [form.period_weekend_supplement_start_date];
          endDates = [form.period_weekend_supplement_end_date];
          satPrices = [form.period_weekend_supplement_sat];
          sunPrices = [form.period_weekend_supplement_sun];
          monPrices = [form.period_weekend_supplement_mon];
          tuePrices = [form.period_weekend_supplement_tue];
          wedPrices = [form.period_weekend_supplement_wed];
          thuPrices = [form.period_weekend_supplement_thu];
          friPrices = [form.period_weekend_supplement_fri];
        }

        startDates.forEach((elem, index) => {
          if (contract.period_weekend_supplements[index]) {
            elem.value = contract.period_weekend_supplements[index].start_date;
          }
        });

        endDates.forEach((elem, index) => {
          if (contract.period_weekend_supplements[index]) {
            elem.value = contract.period_weekend_supplements[index].end_date;
          }
        });

        const dayMapping = {
          sat: satPrices,
          sun: sunPrices,
          mon: monPrices,
          tue: tuePrices,
          wed: wedPrices,
          thu: thuPrices,
          fri: friPrices,
        };

        const daysOfWeek = ["sat", "sun", "mon", "tue", "wed", "thu", "fri"];

        daysOfWeek.forEach((day) => {
          const priceArray = dayMapping[day];
          priceArray.forEach((elem, index) => {
            if (contract.period_weekend_supplements[index]) {
              const dayPrice = contract.period_weekend_supplements[index].day_prices.find((price) => price.day === day);
              if (dayPrice) {
                elem.value = dayPrice.price;
              }
            }
          });
        });
      }

      if (contract && contract.reservation_policy) {
        form.reservation_policy.value = contract.reservation_policy;
      }
      if (contract && contract.same_day_reservation_price) {
        form.same_day_reservation_price.value = Number(contract.same_day_reservation_price);
      }
    }
  }, [
    contract,
    contract.reservation_policy,
    contract.same_day_reservation_price,
    form,
    hotelNames,
    marketTypes,
    contract.one_day_supplements,
    contract.booking_valid_from,
    contract.booking_valid_from,
  ]);

  // functions
  function setRooms(datahotel) {
    if (datahotel?.value) {
      setRoomFlag(true);
      dispatch(
        getHotelRooms(datahotel.value, (result) => {
          if (result.status === 200) {
            setRoomFlag(false);
            setRoomBasedHotel(result.data);
          }
        }),
      );
      // set the values of rooms to empty strings if the user chose a hotel so that if the user changed
      // the hotel he will have to set the rooms again
      if (roomBasedHotel?.length > 0) {
        formRef.current.room.value
          ? (formRef.current.room.value = "")
          : Array.from(formRef.current.room).forEach((elem) => (elem.value = ""));
      }
    }
  }

  function makeAmountRequired(data) {
    if (data) {
      setIsAmountReq(true);
    }
  }

  function getMaxOrderingChild(contractValidity) {
    let maxOrderingChild = 0;

    contractValidity.forEach((room) => {
      room.date_range.forEach((dateRange) => {
        dateRange.validity_children_price.forEach((childPrice) => {
          if (childPrice.ordering_child > maxOrderingChild) {
            maxOrderingChild = childPrice.ordering_child;
          }
        });
      });
    });

    return maxOrderingChild;
  }

  function checkChildrenMealsCoverage(contractChildrenMeals, minStartAge, maxEndAge, maxOrderingChild) {
    for (let order = 1; order <= maxOrderingChild; order++) {
      let coveredAges = new Set();

      contractChildrenMeals.forEach((meal) => {
        if (meal.ordering_child === order) {
          for (let age = meal.start_age; age <= meal.end_age; age++) {
            coveredAges.add(age);
          }
        }
      });

      for (let age = minStartAge; age <= maxEndAge; age++) {
        if (!coveredAges.has(age)) {
          toast.error(`Missing period for child order ${order} at age ${age} in children meals`);
          return false;
        }
      }
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (contractType === "per_person" && (breakfastChecked || dinnerChecked || lunchChecked || softAllChecked)) {
      const maxOrderingChild = getMaxOrderingChild(contractValidity);

      if (!checkChildrenMealsCoverage(contractChildrenMeals, 0, 11, maxOrderingChild)) {
        return;
      }
    }

    // Check if number of adults in weekend supplements per person or
    // period weekend supplements per person equal max adults in contract validity
    if (contractType === "per_person") {
      const adultsOrdersInContractValidity = Array.from(
        new Set(
          contractValidity.flatMap((room) =>
            room.date_range.flatMap((date) => date.rooms_types.map((roomType) => roomType.type)),
          ),
        ),
      );

      const getNumericValuesFromElements = (selector) =>
        Array.from(document.querySelectorAll(selector)).map((elem) => Number(elem.value));

      const weekendSupplementsPerPersonAdultsOrders = getNumericValuesFromElements(
        '[name^="weekend_supplements_per_person_adults"]',
      );
      const periodWeekendSupplementsPerPersonAdultsOrders = getNumericValuesFromElements(
        '[name^="period_weekend_supplements_per_person_adults"]',
      );

      if (weekendSupplementsPerPersonAdultsOrders.length > 0) {
        for (const order of adultsOrdersInContractValidity) {
          if (!weekendSupplementsPerPersonAdultsOrders.includes(order)) {
            toast.error(`Missing adult ${order} in weekend supplements per person`);
            return;
          }
        }
      }

      if (periodWeekendSupplementsPerPersonAdultsOrders.length > 0) {
        for (const order of adultsOrdersInContractValidity) {
          if (!periodWeekendSupplementsPerPersonAdultsOrders.includes(order)) {
            toast.error(`Missing adult ${order} in period weekend supplements per person`);
            return;
          }
        }
      }
    }

    setaddFlag(true);

    const finalData = new FormData();
    // variables to hold the checkboxes of meal supplements
    const breakfast = e.target.breakfast?.checked;
    const lunch = e.target.lunch?.checked;
    const dinner = e.target.dinner?.checked;
    const softAll = e.target.softAll?.checked;
    const data = {
      name: e.target.name.value,
      hotel: e.target.hotel.value,
      market: e.target.market.value,
      offer_type: e.target.offer_type.value,
      meal_included: e.target.meal_included.value,
      currency_type: e.target.currency_type.value,
    };
    // put basic data inside th finalData
    for (let elem in data) {
      finalData.append(`${elem}`, data[elem]);
    }

    const token = cookie.load("access_token");
    // const decodedToken = jwtDecode(token);
    // const user = decodedToken.user_id;
    if (contractType === "per_person") {
      // check meal supplements and put them in finalData if they exist
      breakfast
        ? finalData.append(
            "meal_supplements",
            JSON.stringify({ name: "breakfast", price: e.target.breakfastPrice.value }),
          )
        : void 0;
      lunch
        ? finalData.append("meal_supplements", JSON.stringify({ name: "lunch", price: e.target.lunchPrice.value }))
        : void 0;
      dinner
        ? finalData.append("meal_supplements", JSON.stringify({ name: "dinner", price: e.target.dinnerPrice.value }))
        : void 0;
      softAll
        ? finalData.append("meal_supplements", JSON.stringify({ name: "soft_all", price: e.target.softAllPrice.value }))
        : void 0;
    }
    // check oneDaySupplement and add it
    if (e.target.supplement) {
      if (e.target.supplement[0]) {
        Array.from(e.target.supplement).map((elem, index) => {
          finalData.append(
            "one_day_supplements",
            JSON.stringify({
              name: elem.value,
              price: e.target.supplement_price[index].value,
              date: e.target.supplement_date[index].value,
            }),
          );
        });
      } else {
        finalData.append(
          "one_day_supplements",
          JSON.stringify({
            name: e.target.supplement.value,
            price: e.target.supplement_price.value,
            date: e.target.supplement_date.value,
          }),
        );
      }
    }

    // Check for period_supplement and add it
    if (e.target.period_supplement) {
      if (e.target.period_supplement[0]) {
        Array.from(e.target.period_supplement).map((elem, index) => {
          finalData.append(
            "period_supplements",
            JSON.stringify({
              name: elem.value,
              price: e.target.period_supplement_price[index].value,
              start_date: e.target.period_supplement_start_date[index].value,
              end_date: e.target.period_supplement_end_date[index].value,
            }),
          );
        });
      } else {
        finalData.append(
          "period_supplements",
          JSON.stringify({
            name: e.target.period_supplement.value,
            price: e.target.period_supplement_price.value,
            start_date: e.target.period_supplement_start_date.value,
            end_date: e.target.period_supplement_end_date.value,
          }),
        );
      }
    }

    // check weekend supplements and add them
    e.target.weekend_supplements_mon.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "mon",
            price: Number(e.target.weekend_supplements_mon.value),
          }),
        )
      : void 0;
    e.target.weekend_supplements_tue.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "tue",
            price: Number(e.target.weekend_supplements_tue.value),
          }),
        )
      : void 0;
    e.target.weekend_supplements_wed.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "wed",
            price: Number(e.target.weekend_supplements_wed.value),
          }),
        )
      : void 0;
    e.target.weekend_supplements_thu.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "thu",
            price: Number(e.target.weekend_supplements_thu.value),
          }),
        )
      : void 0;
    e.target.weekend_supplements_fri.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "fri",
            price: Number(e.target.weekend_supplements_fri.value),
          }),
        )
      : void 0;
    e.target.weekend_supplements_sun.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "sun",
            price: Number(e.target.weekend_supplements_sun.value),
          }),
        )
      : void 0;
    e.target.weekend_supplements_sat.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "sat",
            price: Number(e.target.weekend_supplements_sat.value),
          }),
        )
      : void 0;

    // check period weekend supplements and add them
    if (e.target.period_weekend_supplement_start_date) {
      if (e.target.period_weekend_supplement_start_date[0]) {
        Array.from(e.target.period_weekend_supplement_start_date).map((elem, index) => {
          finalData.append(
            "period_weekend_supplements",
            JSON.stringify({
              start_date: elem.value,
              end_date: e.target.period_weekend_supplement_end_date[index].value,
              day_prices: [
                e.target.period_weekend_supplement_sat[index].value
                  ? { day: "sat", price: e.target.period_weekend_supplement_sat[index].value }
                  : void 0,
                e.target.period_weekend_supplement_sun[index].value
                  ? { day: "sun", price: e.target.period_weekend_supplement_sun[index].value }
                  : void 0,
                e.target.period_weekend_supplement_mon[index].value
                  ? { day: "mon", price: e.target.period_weekend_supplement_mon[index].value }
                  : void 0,
                e.target.period_weekend_supplement_tue[index].value
                  ? { day: "tue", price: e.target.period_weekend_supplement_tue[index].value }
                  : void 0,
                e.target.period_weekend_supplement_wed[index].value
                  ? { day: "wed", price: e.target.period_weekend_supplement_wed[index].value }
                  : void 0,
                e.target.period_weekend_supplement_thu[index].value
                  ? { day: "thu", price: e.target.period_weekend_supplement_thu[index].value }
                  : void 0,
                e.target.period_weekend_supplement_fri[index].value
                  ? { day: "fri", price: e.target.period_weekend_supplement_fri[index].value }
                  : void 0,
              ].filter((elem) => {
                return elem !== void 0;
              }),
            }),
          );
        });
      } else {
        finalData.append(
          "period_weekend_supplements",
          JSON.stringify({
            start_date: e.target.period_weekend_supplement_start_date.value,
            end_date: e.target.period_weekend_supplement_end_date.value,
            day_prices: [
              e.target.period_weekend_supplement_sat.value
                ? { day: "sat", price: e.target.period_weekend_supplement_sat.value }
                : void 0,
              e.target.period_weekend_supplement_sun.value
                ? { day: "sun", price: e.target.period_weekend_supplement_sun.value }
                : void 0,
              e.target.period_weekend_supplement_mon.value
                ? { day: "mon", price: e.target.period_weekend_supplement_mon.value }
                : void 0,
              e.target.period_weekend_supplement_tue.value
                ? { day: "tue", price: e.target.period_weekend_supplement_tue.value }
                : void 0,
              e.target.period_weekend_supplement_wed.value
                ? { day: "wed", price: e.target.period_weekend_supplement_wed.value }
                : void 0,
              e.target.period_weekend_supplement_thu.value
                ? { day: "thu", price: e.target.period_weekend_supplement_thu.value }
                : void 0,
              e.target.period_weekend_supplement_fri.value
                ? { day: "fri", price: e.target.period_weekend_supplement_fri.value }
                : void 0,
            ].filter((elem) => {
              return elem !== void 0;
            }),
          }),
        );
      }
    }

    // Weekend Supplements Per Person
    if (e.target.weekend_supplements_per_person_sat) {
      if (e.target.weekend_supplements_per_person_sat[0]) {
        Array.from(e.target.weekend_supplements_per_person_sat).forEach((elem, index) => {
          e.target.weekend_supplements_per_person_sat[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "sat",
                  price: Number(e.target.weekend_supplements_per_person_sat[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_sun[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "sun",
                  price: Number(e.target.weekend_supplements_per_person_sun[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_mon[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "mon",
                  price: Number(e.target.weekend_supplements_per_person_mon[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_tue[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "tue",
                  price: Number(e.target.weekend_supplements_per_person_tue[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_wed[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "wed",
                  price: Number(e.target.weekend_supplements_per_person_wed[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_thu[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "thu",
                  price: Number(e.target.weekend_supplements_per_person_thu[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_fri[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "fri",
                  price: Number(e.target.weekend_supplements_per_person_fri[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;
        });
      } else {
        e.target.weekend_supplements_per_person_sat.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "sat",
                price: Number(e.target.weekend_supplements_per_person_sat.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_sun.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "sun",
                price: Number(e.target.weekend_supplements_per_person_sun.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_mon.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "mon",
                price: Number(e.target.weekend_supplements_per_person_mon.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_tue.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "tue",
                price: Number(e.target.weekend_supplements_per_person_tue.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_wed.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "wed",
                price: Number(e.target.weekend_supplements_per_person_wed.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_thu.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "thu",
                price: Number(e.target.weekend_supplements_per_person_thu.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_fri.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "fri",
                price: Number(e.target.weekend_supplements_per_person_fri.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;
      }
    }

    // Check if period weekend supplements per person are present and add them
    if (e.target.period_weekend_supplements_per_person_start_date) {
      if (e.target.period_weekend_supplements_per_person_start_date[0]) {
        Array.from(e.target.period_weekend_supplements_per_person_start_date).map((elem, index) => {
          finalData.append(
            "period_weekend_supplements_per_person",
            JSON.stringify({
              start_date: elem.value,
              end_date: e.target.period_weekend_supplements_per_person_end_date[index].value,
              day_prices_per_person: [
                e.target.period_weekend_supplements_per_person_sat[index].value
                  ? {
                      day: "sat",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_sat[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_sun[index].value
                  ? {
                      day: "sun",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_sun[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_mon[index].value
                  ? {
                      day: "mon",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_mon[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_tue[index].value
                  ? {
                      day: "tue",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_tue[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_wed[index].value
                  ? {
                      day: "wed",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_wed[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_thu[index].value
                  ? {
                      day: "thu",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_thu[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_fri[index].value
                  ? {
                      day: "fri",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_fri[index].value,
                    }
                  : void 0,
              ].filter((elem) => {
                return elem !== void 0;
              }),
            }),
          );
        });
      } else {
        finalData.append(
          "period_weekend_supplements_per_person",
          JSON.stringify({
            start_date: e.target.period_weekend_supplements_per_person_start_date.value,
            end_date: e.target.period_weekend_supplements_per_person_end_date.value,
            day_prices_per_person: [
              e.target.period_weekend_supplements_per_person_sat.value
                ? {
                    day: "sat",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_sat.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_sun.value
                ? {
                    day: "sun",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_sun.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_mon.value
                ? {
                    day: "mon",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_mon.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_tue.value
                ? {
                    day: "tue",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_tue.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_wed.value
                ? {
                    day: "wed",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_wed.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_thu.value
                ? {
                    day: "thu",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_thu.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_fri.value
                ? {
                    day: "fri",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_fri.value,
                  }
                : void 0,
            ].filter((elem) => {
              return elem !== void 0;
            }),
          }),
        );
      }
    }

    //check commission type and add them
    if (e.target.commission_type.value) {
      finalData.append("commission_type", e.target.commission_type.value);
      finalData.append("commission_price", e.target.commission_price.value);
    }

    // add booking window
    // let startDate = new Date(dateRange.startDate);
    // let endDate = new Date(dateRange.endDate);

    finalData.append("booking_valid_from", dateRange.startDate);
    finalData.append("booking_valid_to", dateRange.endDate);

    // check reservation policy and add it
    e.target.reservation_policy.value
      ? finalData.append("reservation_policy", e.target.reservation_policy.value)
      : void 0;

    // add price_policy_minimum_days & price_policy_maximum_days
    finalData.append("price_policy_minimum_days", e.target.min.value);
    finalData.append("price_policy_maximum_days", e.target.max.value);

    // check cancellation policy existence
    e.target.cancellation_policy.value
      ? finalData.append("cancellation_policy", e.target.cancellation_policy.value)
      : void 0;

    // check same_day_reservation_price
    e.target.same_day_reservation_price.value
      ? finalData.append("same_day_reservation_price", e.target.same_day_reservation_price.value)
      : void 0;

    // check notes
    e.target.notes.value ? finalData.append("notes", e.target.notes.value) : void 0;

    // add documents
    if (contractDocuments.length > 0) {
      for (let doc of contractDocuments) {
        if (!doc.valid) {
          toast.error(`File ${doc.name} is not valid because ${doc.errors[0]}`);
          setaddFlag(false);
          return;
        }
        finalData.append("price_document", doc.file);
      }
    } else {
      finalData.append("price_document", "");
    }

    contractValidity.forEach((roomValidity) => {
      if (contractType === "per_person") {
        roomValidity.date_range.forEach((date) => {
          date.validity_room_packages = [];
        });
      }

      if (contractType === "per_room") {
        roomValidity.date_range.forEach((date) => {
          date.validity_children_price = [];
          date.rooms_types = [];
        });
      }
      finalData.append("data_rooms", JSON.stringify(roomValidity));
    });

    if (contractType === "per_person") {
      contractChildrenMeals.map((childMeal) => {
        finalData.append("meal_supplements_children", JSON.stringify(childMeal));
      });
    }

    finalData.append("contract_type", contractType);

    // finalData.append("updated_by", user);
    if (lastParams === "edit") {
      dispatch(
        editDeletedContract(id, finalData, (result) => {
          if (result.status === 200) {
            setaddFlag(false);
            navigate(`/dashboard/deleted-contract/${id}/details`);
          } else {
            setaddFlag(false);
            // NotificationManager.error(result.response?.data[0]?.message);
          }
        }),
      );
    } else {
      dispatch(
        editContract(id, finalData, (result) => {
          if (result.status === 200) {
            setaddFlag(false);
            navigate(`/dashboard/contract-details/${id}`);
          } else {
            setaddFlag(false);
            // NotificationManager.error(result.response?.data[0]?.message);
          }
        }),
      );
    }
  }

  //fetch data

  const [chosenHotels, setChosenHotels] = useState([]);
  const [CitiesChoice, setCitiesChoice] = useState();

  const {
    data: hotelfilter,
    error: hotelfilterError,
    isLoading: hotelfilterLoading,
    refetch: hotelfilterFun,
  } = useQuery({
    queryKey: ["hotel-filter"],
    queryFn: () => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=${CitiesChoice || ""}`),
    enabled: false, // Start with disabled query
  });

  //fetch data
  const {
    data: Cities,
    error: errorCities,
    isLoading: loadCities,
  } = useQuery({
    queryKey: ["get-cities"],
    queryFn: () => fetchDataQuery(`/search/api/v1/get-cities/`),
  });

  const selectedCityTimeZone = Cities?.find((city) => {
    const chosenCityId = CitiesChoice?.slice(0, -1);

    return city?.id === Number(chosenCityId);
  })?.timezone;

  useEffect(() => {
    setDateRange({
      startDate: dayjs(contract.booking_valid_from).tz(selectedCityTimeZone).format(),
      endDate: dayjs(contract.booking_valid_to).tz(selectedCityTimeZone).format(),
    });
  }, [contract.booking_valid_from, contract.booking_valid_to, selectedCityTimeZone]);

  const {
    data: hotelnames,
    error: errorhotelnames,
    isLoading: loadhotelnames,
    refetch,
  } = useQuery({
    queryKey: ["get-hotelnames"],
    queryFn: () => fetchDataQuery(`/en/hotels/api/v1/get-hotel-names/`),
  });

  const getIdByName = hotelnames?.find((item) => item.id === contract?.hotel?.id);
  const getIdcity = Cities?.find((item) => item.id === getIdByName?.city);

  const CitiesArr = Cities?.map((city) => {
    return { label: city.name, value: city.id };
  });

  const hotelfiltersArr = hotelfilter?.map((hotel) => {
    return { label: hotel.name, value: hotel.id };
  });

  useEffect(() => {
    if (CitiesChoice) {
      hotelfilterFun();
    }
  }, [CitiesChoice]);

  useEffect(() => {
    if (getIdcity) {
      setCitiesChoice(getIdcity?.id + ",");
    }
  }, [getIdcity]);

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch Error={"Error fetching permissions"} />;
  }

  return isEditingContractAllowed ? (
    <div className="formPage-wrap">
      {contract.contractFlag && !getIdcity ? (
        <div className="w-full h-screen flex item-center justify-center">
          <Loading />
          <TopBarProgress />
        </div>
      ) : (
        getIdcity && (
          <>
            <div className="w-full ">
              <div className=" flex items-center justify-center  ">
                <h2 className="header-h2 ">
                  {" "}
                  <LiaFileContractSolid className="w-6 h-6" />
                  Edit Contract
                </h2>
              </div>
            </div>

            <form
              action=""
              method="post"
              className="w-full mt-1 flex flex-col gap-y-10 py-3 px-5 border bg-gray-50 p-2 rounded-md"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <div className="w-full flex flex-col gap-y-4">
                <div className="w-full grid items-center justify-center gap-x-10 grid-cols-1 lg:grid-cols-3 sm:grid-cols-2">
                  <div className="input-chil">
                    <StaticFromInput
                      name="name"
                      text="Contract Name"
                      type="text"
                      placeholder="Enter contract name"
                      icon={<IoNewspaper className="w-5 h-5" />}
                    />{" "}
                  </div>

                  <div className="input-chil">
                    <label className=" font-semibold flex items-center text-center gap-1 text-[#002244]">
                      <FaTreeCity className="w-4 h-4" /> Select City
                    </label>
                    <Select
                      options={CitiesArr}
                      className="w-full h-10 bg-white capitalize"
                      onChange={(e) => {
                        const selectedCityTimeZone = Cities?.find((city) => {
                          const chosenCityId = e.value;

                          return city?.id === Number(chosenCityId);
                        })?.timezone;

                        setDateRange((dateRange) => {
                          return {
                            startDate: dateRange.startDate
                              ? dayjs(dateRange.startDate).tz(selectedCityTimeZone).format()
                              : null,
                            endDate: dateRange.endDate
                              ? dayjs(dateRange.endDate).tz(selectedCityTimeZone).format()
                              : null,
                          };
                        });
                        setCitiesChoice(e.value + ",");
                      }}
                      required
                      placeholder="Select Option"
                      defaultValue={{ label: getIdcity?.name, value: getIdcity?.id }}
                    />
                  </div>

                  <div className="input-chil">
                    <label className=" font-semibold flex items-center text-center gap-1 text-[#002244]">
                      <FaHotel className="w-4 h-4" /> Hotels
                    </label>

                    {/* <Resizable height={height} onResize={(e, data) => setHeight(data.size.height)}> */}
                    <Select
                      options={
                        !hotelfiltersArr ? [] : hotelfiltersArr
                        // if select all , options will be hidden
                      }
                      className={`w-full ${chosenHotels?.length >= 7 ? "h-24" : "h-10"}  resize-y bg-white capitalize `}
                      required
                      onChange={(e) => {
                        setChosenHotels(e);
                        setRooms(e);
                      }}
                      isSearchable
                      placeholder="Choose Hotel name"
                      name="hotel"
                      text="Hotel name"
                      isLoading={hotelfilterLoading}
                      defaultValue={{ label: contract?.hotel?.name, value: contract?.hotel?.id }}
                    />
                  </div>
                </div>
                <div className="input-par">
                  <div className="input-chil">
                    <StaticFormSelect
                      name="market"
                      text="Market Type"
                      options={marketTypes}
                      placeholder="Choose Market Type"
                      icon={<FaLocationCrosshairs className="w-5 h-5" />}
                    />
                  </div>
                  <div className="input-chil">
                    <StaticFormSelect
                      name="offer_type"
                      text="Offer Type"
                      options={offerTypes}
                      placeholder="Choose Offer Type"
                      icon={<BiSolidOffer className="w-5 h-5" />}
                    />{" "}
                  </div>
                </div>
                <div className="input-par">
                  <div className="input-chil">
                    <StaticFormSelect
                      contractCallBack={(e, meal) => {
                        setMealIncluded(meal);
                        if (meal === "b.b") {
                          setBreakfastChecked(false);
                        }

                        if (meal === "h.b") {
                          setBreakfastChecked(false);
                          setDinnerChecked(false);
                        }

                        if (meal === "f.b") {
                          setBreakfastChecked(false);
                          setLunchChecked(false);
                          setDinnerChecked(false);
                        }

                        if (meal === "s.a") {
                          setBreakfastChecked(false);
                          setLunchChecked(false);
                          setDinnerChecked(false);
                          setSoftAllChecked(false);
                        }
                      }}
                      name="meal_included"
                      text="Meal Included"
                      options={mealsIncludedArr}
                      placeholder="Choose included meal"
                      icon={<GiMeal className="w-5 h-5 mb-1" />}
                    />
                  </div>
                  <div className="input-chil">
                    <StaticFormSelect
                      name="currency_type"
                      text="Currency Type"
                      options={currencyTypesArr}
                      placeholder="Choose currency type"
                      icon={<BsCurrencyExchange className="w-5 h-5" />}
                    />
                  </div>
                </div>
                <div>
                  <StaticFormSelect
                    disabled
                    defaultValue={contractType}
                    name={"contract_type"}
                    text={"Contract Type"}
                    options={[
                      {
                        id: contractType,
                        name: contractType
                          .split("_")
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" "),
                      },
                    ]}
                    icon={<IoDocumentTextSharp className="w-5 h-5" />}
                  />
                </div>

                {contractType === "per_person" && (
                  <div className="w-full flex flex-col gap-y-1 items-start ">
                    <div className="w-full flex  items-center gap-x-1 text-slate-900 font-semibold">
                      <RiRestaurantFill className="w-5 h-5 text-slate-800" /> <p>Prices For Meals</p>
                    </div>
                    <div className="w-full  items-center justify-center gap-x-10 border rounded-md p-3 grid md:grid-cols-4 grid-cols-2">
                      <div className="">
                        <DynamicCheckbox
                          disabled={
                            mealIncluded === "b.b" ||
                            mealIncluded === "h.b" ||
                            mealIncluded === "f.b" ||
                            mealIncluded === "s.a" ||
                            mealIncluded === ""
                          }
                          name="breakfast"
                          text="Breakfast"
                          checkedByParent={breakfastChecked}
                          setCheckedByParent={setBreakfastChecked}
                          value={breakfastInitialValue}
                        />
                      </div>
                      <div className="">
                        <DynamicCheckbox
                          disabled={mealIncluded === "f.b" || mealIncluded === "s.a" || mealIncluded === ""}
                          name="lunch"
                          text="Lunch"
                          checkedByParent={lunchChecked}
                          setCheckedByParent={setLunchChecked}
                          value={lunchInitialValue}
                        />
                      </div>
                      <div className="">
                        <DynamicCheckbox
                          disabled={
                            mealIncluded === "h.b" ||
                            mealIncluded === "f.b" ||
                            mealIncluded === "s.a" ||
                            mealIncluded === ""
                          }
                          name="dinner"
                          text="Dinner"
                          checkedByParent={dinnerChecked}
                          setCheckedByParent={setDinnerChecked}
                          value={dinnerInitialValue}
                        />
                      </div>
                      <div className="">
                        <DynamicCheckbox
                          disabled={mealIncluded === "s.a" || mealIncluded === ""}
                          name="softAll"
                          text="Soft All"
                          checkedByParent={softAllChecked}
                          setCheckedByParent={setSoftAllChecked}
                          value={softAllInitialValue}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {contractType === "per_person" && (
                <div className="w-full flex flex-col gap-y-2">
                  <div className="w-full flex flex-col items-start gap-x-1 ">
                    <div className="flex gap-1">
                      <MdDescription className="w-5 h-5 text-slate-900" />
                      <p className="text-slate-800 font-semibold">Prices for Children Meals</p>
                      <div className="flex items-center gap-x-1 ">
                        <button
                          type="button"
                          onClick={() => {
                            if (!breakfastChecked && !lunchChecked && !dinnerChecked && !softAllChecked) {
                              toast.error("Please select at least one meal in order to add children meals");
                              return;
                            }

                            setChildrenMealsInput((c) => c + 1);
                            dispatch(addNewChildMeal());
                          }}
                          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800 "
                        >
                          <MdAddCircleOutline className="w-5 h-5 " />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setChildrenMealsInput((c) => {
                              if (c === 0) return;
                              return c - 1;
                            });
                            dispatch(removeChildMeal());
                          }}
                          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800 "
                        >
                          <BiMinusCircle className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                    {Array.from({ length: childrenMealsInput }, (_, i) => i).map((elem, index) => {
                      return (
                        <div className="mt-4 w-full flex flex-col gap-4 ">
                          <div className=" w-full flex justify-between items-center ">
                            <StaticFormSelect
                              name="child_order"
                              text="Child Order"
                              options={childRoomTypes}
                              placeholder="Select Child Order"
                              defaultValue={contractChildrenMeals[index]?.ordering_child}
                              contractCallBack={(e, order) => {
                                // Clear the input fields if the user tries to change the order
                                e.target.parentElement.parentElement.querySelector("[name='child_start_age']").value =
                                  null;
                                e.target.parentElement.parentElement.querySelector("[name='child_end_age']").value =
                                  null;

                                dispatch(addMealChildStartAgeValue(index, ""));
                                dispatch(addMealChildEndAgeValue(index, ""));

                                const parentElement = e.target.parentElement.parentElement.parentElement.parentElement;
                                const orders = parentElement.querySelectorAll("[name='child_order']");
                                const ordersArray = Array.from(orders)
                                  .map((orderElement) => Number(orderElement.value))
                                  .filter((o) => o !== 0);

                                const orderNum = Number(order);

                                if (orderNum !== 1 && !ordersArray.includes(orderNum - 1)) {
                                  toast.error(`You must add the previous child order first`);
                                  e.target.value = null;
                                  e.target.parentElement.parentElement.querySelector("[name='child_start_age']").value =
                                    null;
                                  e.target.parentElement.parentElement.querySelector("[name='child_end_age']").value =
                                    null;
                                  dispatch(addMealChildStartAgeValue(index, ""));
                                  dispatch(addMealChildEndAgeValue(index, ""));
                                  dispatch(addMealChildOrder(index, ""));

                                  return;
                                }

                                dispatch(addMealChildOrder(index, Number(order)));
                              }}
                            />

                            <StaticFormSelect
                              options={ageSelectOptions}
                              defaultValue={contractChildrenMeals[index]?.start_age}
                              disabled={!contractChildrenMeals[index]?.ordering_child}
                              name="child_start_age"
                              text="Min age"
                              type="number"
                              canDefaultValueZero={true}
                              placeholder="Min age"
                              contractCallBack={(e, age) => {
                                const maxAge = contractChildrenMeals[index].end_age;

                                // 1) Validation num 1
                                // Min age must be less than max age

                                if (age >= maxAge && maxAge !== null && maxAge !== "") {
                                  toast.error("The minimum age must be less than the maximum age.");
                                  e.target.value = null;
                                  dispatch(addMealChildStartAgeValue(index, ""));

                                  return;
                                }

                                // 2) Validation num 2
                                // No conflict with the same child order
                                const order = contractChildrenMeals[index].ordering_child;
                                const conflictingAge = contractChildrenMeals.find(
                                  (meal) =>
                                    meal.ordering_child === order && age >= meal.start_age && age <= meal.end_age,
                                );

                                if (conflictingAge) {
                                  toast.error(
                                    "The minimum age conflicts with an existing range for the same child order.",
                                  );
                                  // TODO: Reset the max age as well
                                  e.target.value = null;
                                  // e.target.parentElement.querySelector("[name='child_end_age']");

                                  dispatch(addMealChildStartAgeValue(index, ""));
                                  dispatch(addMealChildEndAgeValue(index, ""));

                                  const parentElement = e.target.parentElement.parentElement;
                                  const maxAgeInput = parentElement.querySelector("[name='child_end_age']");

                                  if (maxAgeInput) {
                                    maxAgeInput.value = null;
                                  }

                                  return;
                                }

                                dispatch(addMealChildStartAgeValue(index, Number(age)));
                              }}
                            />

                            <StaticFormSelect
                              defaultValue={contractChildrenMeals[index]?.end_age}
                              disabled={
                                !contractChildrenMeals[index]?.ordering_child ||
                                contractChildrenMeals[index]?.start_age === ""
                              }
                              options={ageSelectOptions}
                              name="child_end_age"
                              text="Max age"
                              type="number"
                              placeholder="Max age"
                              contractCallBack={(e, age) => {
                                const order = contractChildrenMeals[index].ordering_child;

                                // 1) Validation num 1
                                // Max age must be greater than min age
                                const minAge = contractChildrenMeals[index].start_age;

                                if (age <= minAge && minAge !== null && minAge !== "") {
                                  toast.error("The maximum age must be greater than the minimum age.");
                                  e.target.value = null;
                                  dispatch(addMealChildEndAgeValue(index, ""));

                                  return;
                                }

                                // 2) Validation num 2
                                // No conflict with the same child order
                                const conflictingAge = contractChildrenMeals.find(
                                  (child) =>
                                    child.ordering_child === order && age >= child.start_age && age <= child.end_age,
                                );

                                if (conflictingAge) {
                                  toast.error(
                                    "The maximum age conflicts with an existing range for the same child order.",
                                  );
                                  e.target.value = null;
                                  dispatch(addMealChildEndAgeValue(index, ""));
                                  dispatch(addMealChildStartAgeValue(index, ""));
                                  const parentElement = e.target.parentElement.parentElement;
                                  const maxAgeInput = parentElement.querySelector("[name='child_start_age']");
                                  if (maxAgeInput) {
                                    maxAgeInput.value = null;
                                  }
                                  return;
                                }
                                dispatch(addMealChildEndAgeValue(index, Number(age)));
                              }}
                              canDefaultValueZero={true}
                            />
                          </div>
                          <div className="w-full gap-2 grid md:grid-cols-4 grid-cols-2 items-center justify-around  border rounded-md p-3">
                            <StaticFromInput
                              defaultValue={
                                contractChildrenMeals[index]?.meal_supplements_children_period?.find(
                                  (meal) => meal.name === "breakfast",
                                )?.price
                              }
                              placeholder="Breakfast"
                              name="child_breakfast"
                              text="Child Breakfast"
                              type="number"
                              disabled={!breakfastChecked}
                              contractCallBack={(e, price) => {
                                dispatch(addChildMeal(index, "breakfast", Number(price)));
                              }}
                            />

                            <StaticFromInput
                              defaultValue={
                                contractChildrenMeals[index]?.meal_supplements_children_period?.find(
                                  (meal) => meal.name === "lunch",
                                )?.price
                              }
                              placeholder="Lunch"
                              name="child_lunch"
                              text="Child Lunch"
                              type="number"
                              disabled={!lunchChecked}
                              contractCallBack={(e, price) => {
                                dispatch(addChildMeal(index, "lunch", Number(price)));
                              }}
                            />

                            <StaticFromInput
                              defaultValue={
                                contractChildrenMeals[index]?.meal_supplements_children_period?.find(
                                  (meal) => meal.name === "dinner",
                                )?.price
                              }
                              placeholder="Dinner"
                              name="child_dinner"
                              text="Child Dinner"
                              type="number"
                              disabled={!dinnerChecked}
                              contractCallBack={(e, price) => {
                                dispatch(addChildMeal(index, "dinner", Number(price)));
                              }}
                            />

                            <StaticFromInput
                              defaultValue={
                                contractChildrenMeals[index]?.meal_supplements_children_period?.find(
                                  (meal) => meal.name === "soft_all",
                                )?.price
                              }
                              placeholder="Soft All"
                              name="child_soft_all"
                              text="Child Soft All"
                              type="number"
                              disabled={!softAllChecked}
                              contractCallBack={(e, price) => {
                                dispatch(addChildMeal(index, "soft_all", Number(price)));
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="w-full flex flex-col gap-y-2">
                <div className="w-full flex items-start gap-x-1 ">
                  <MdDescription className="w-5 h-5 text-slate-900" />
                  <MultiDynamicInputs
                    inputsNum={specificDayNums}
                    heading="Specific Day Supplement"
                    inputsList={[
                      <StaticFromInput name="supplement" text="Supplement" type="text" />,
                      <StaticFromInput name="supplement_price" text="Price" type="number" />,
                      <StaticFromInput name="supplement_date" text="From Date" type="date" />,
                    ]}
                  />
                </div>
              </div>

              <div className="w-full flex flex-col gap-y-2">
                <div className="w-full flex items-start gap-x-1 ">
                  <MdDescription className="w-5 h-5 text-slate-900" />
                  <MultiDynamicInputs
                    inputsNum={periodSupplementInputsNum}
                    heading="Period Supplement"
                    inputsList={[
                      <StaticFromInput
                        name="period_supplement"
                        text="Supplement"
                        type="text"
                        placeholder="Supplement"
                      />,
                      <StaticFromInput name="period_supplement_price" text="Price" type="number" placeholder="Price" />,
                      <StaticFromInput
                        name="period_supplement_start_date"
                        text="From Date"
                        type="date"
                        placeholder="DD/MM/YYYY"
                      />,
                      <StaticFromInput
                        name="period_supplement_end_date"
                        text="To Date"
                        type="date"
                        placeholder="DD/MM/YYYY"
                      />,
                    ]}
                    icon={<MdOutlineDescription className="w-5 h-5 text-slate-800" />}
                  />{" "}
                </div>
              </div>

              <div className="w-full  flex flex-col gap-y-2">
                <div className="w-full flex justify-between items-end">
                  <div className="flex items-center gap-x-1 grow text-slate-900">
                    <MdOutlineBedroomChild className="w-5 h-5 text-slate-800" />
                    <h1 className="text-gray-800 font-semibold">Room Contract</h1>
                  </div>

                  {contractType === "per_person" && (
                    <div className="flex flex-col gap-1">
                      <ContractAssistanceTool hotelRooms={hotelRoomsArr} />
                      <ChildrenPolicyAssistantTool hotelRooms={hotelRoomsArr} />
                    </div>
                  )}
                </div>
                <ContractFormDynamicComponentParent
                  hotelRoomsArr={hotelRoomsArr}
                  contractValidity={contractValidity}
                  validities={contract.validities}
                  contractType={contractType}
                  isAddingPackageAllowed={isAddingPackageAllowed}
                />
              </div>

              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <IoCalendarSharp className="w-5 h-5 text-slate-800 " />
                  <h2 className=" font-semibold">Weekend Supplements</h2>
                </div>
                <div className="grid grid-cols-7 gap-4 border rounded-md p-2">
                  <StaticFromInput
                    name="weekend_supplements_sat"
                    text="Sat"
                    type="number"
                    required={false}
                    className="i15vw"
                  />
                  <StaticFromInput
                    name="weekend_supplements_sun"
                    text="Sun"
                    type="number"
                    required={false}
                    className="i15vw"
                  />
                  <StaticFromInput
                    name="weekend_supplements_mon"
                    text="Mon"
                    type="number"
                    required={false}
                    className="i15vw"
                  />
                  <StaticFromInput
                    name="weekend_supplements_tue"
                    text="Tue"
                    type="number"
                    required={false}
                    className="i15vw"
                  />
                  <StaticFromInput
                    name="weekend_supplements_wed"
                    text="Wed"
                    type="number"
                    required={false}
                    className="i15vw"
                  />
                  <StaticFromInput
                    name="weekend_supplements_thu"
                    text="Thu"
                    type="number"
                    required={false}
                    className="i15vw"
                  />
                  <StaticFromInput
                    name="weekend_supplements_fri"
                    text="Fri"
                    type="number"
                    required={false}
                    className="i15vw"
                  />
                </div>
              </div>

              <div className="w-full flex flex-col gap-y-2">
                <div className="w-full flex items-start gap-x-1 ">
                  <MdDescription className="w-5 h-5 text-slate-900" />
                  <MultiDynamicInputs
                    inputsNum={periodWeekendSupplementInputsNum}
                    heading="Period Weekend Supplements"
                    inputsList={[
                      <div className="flex gap-3 items-center">
                        <div className="flex gap-4 items-center">
                          <StaticFromInput
                            name="period_weekend_supplement_start_date"
                            text="From Date"
                            type="date"
                            placeholder="DD/MM/YYYY"
                          />

                          <StaticFromInput
                            name="period_weekend_supplement_end_date"
                            text="To Date"
                            type="date"
                            placeholder="DD/MM/YYYY"
                          />
                        </div>

                        <div className="grid grid-cols-7 gap-4 border rounded-md p-2">
                          <StaticFromInput
                            name="period_weekend_supplement_sat"
                            text="Sat"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplement_sun"
                            text="Sun"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplement_mon"
                            text="Mon"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplement_tue"
                            text="Tue"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplement_wed"
                            text="Wed"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplement_thu"
                            text="Thu"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplement_fri"
                            text="Fri"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                        </div>
                      </div>,
                    ]}
                    icon={<MdOutlineDescription className="w-5 h-5 text-slate-800" />}
                  />{" "}
                </div>
              </div>

              {contractType === "per_person" && (
                <div className="w-full flex flex-col gap-y-2">
                  <div className="w-full flex items-start gap-x-1 ">
                    <MdDescription className="w-5 h-5 text-slate-900" />
                    <MultiDynamicInputs
                      inputsNum={weekendSupplementsPerPersonInputsNum}
                      heading="Weekend Supplements Per Person"
                      inputsList={[
                        <div className="flex gap-6 items-center">
                          <div className="w-[235px]">
                            <StaticFormSelect
                              name="weekend_supplements_per_person_adults"
                              text="Adults Order"
                              options={Array.from({ length: 12 }, (_, i) => ({ id: i + 1, name: i + 1 }))}
                              placeholder="Adults Order"
                            />
                          </div>
                          <StaticFromInput
                            name="weekend_supplements_per_person_sat"
                            text="Sat"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="weekend_supplements_per_person_sun"
                            text="Sun"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="weekend_supplements_per_person_mon"
                            text="Mon"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="weekend_supplements_per_person_tue"
                            text="Tue"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="weekend_supplements_per_person_wed"
                            text="Wed"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="weekend_supplements_per_person_thu"
                            text="Thu"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="weekend_supplements_per_person_fri"
                            text="Fri"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                        </div>,
                      ]}
                      icon={<MdOutlineDescription className="w-5 h-5 text-slate-800" />}
                    />{" "}
                  </div>
                </div>
              )}
              {contractType === "per_person" && (
                <div className="w-full flex flex-col gap-y-2">
                  <div className="w-full flex items-start gap-x-1 ">
                    <MdDescription className="w-5 h-5 text-slate-900" />
                    <MultiDynamicInputs
                      inputsNum={periodWeekendSupplementPerPersonInputsNum}
                      heading="Period Weekend Supplements Per Person"
                      inputsList={[
                        <div className="flex gap-3 items-center">
                          <div className="flex gap-4 items-center">
                            <div className="w-[180px]">
                              <StaticFormSelect
                                name="period_weekend_supplements_per_person_adults"
                                text="Adults Order"
                                options={Array.from({ length: 12 }, (_, i) => ({ id: i + 1, name: i + 1 }))}
                                placeholder="Adults Order"
                              />
                            </div>

                            <StaticFromInput
                              name="period_weekend_supplements_per_person_start_date"
                              text="From Date"
                              type="date"
                              placeholder="DD/MM/YYYY"
                            />

                            <StaticFromInput
                              name="period_weekend_supplements_per_person_end_date"
                              text="To Date"
                              type="date"
                              placeholder="DD/MM/YYYY"
                            />
                          </div>

                          <div className="grid grid-cols-7 gap-4 border rounded-md p-2">
                            <StaticFromInput
                              name="period_weekend_supplements_per_person_sat"
                              text="Sat"
                              type="number"
                              required={false}
                              className="i15vw"
                            />
                            <StaticFromInput
                              name="period_weekend_supplements_per_person_sun"
                              text="Sun"
                              type="number"
                              required={false}
                              className="i15vw"
                            />
                            <StaticFromInput
                              name="period_weekend_supplements_per_person_mon"
                              text="Mon"
                              type="number"
                              required={false}
                              className="i15vw"
                            />
                            <StaticFromInput
                              name="period_weekend_supplements_per_person_tue"
                              text="Tue"
                              type="number"
                              required={false}
                              className="i15vw"
                            />
                            <StaticFromInput
                              name="period_weekend_supplements_per_person_wed"
                              text="Wed"
                              type="number"
                              required={false}
                              className="i15vw"
                            />
                            <StaticFromInput
                              name="period_weekend_supplements_per_person_thu"
                              text="Thu"
                              type="number"
                              required={false}
                              className="i15vw"
                            />
                            <StaticFromInput
                              name="period_weekend_supplements_per_person_fri"
                              text="Fri"
                              type="number"
                              required={false}
                              className="i15vw"
                            />
                          </div>
                        </div>,
                      ]}
                      icon={<MdOutlineDescription className="w-5 h-5 text-slate-800" />}
                    />{" "}
                  </div>
                </div>
              )}
              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <FaPercent className="w-4 h-4 text-slate-800" />
                  <h2 className=" font-semibold">Commission</h2>
                </div>
                <div className="grid grid-cols-2 gap-4 border rounded-md p-2">
                  <StaticFormSelect
                    name="commission_type"
                    text="Type"
                    options={[
                      { id: "fixed price", name: "Fixed Price" },
                      { id: "percentage", name: "Percentage" },
                    ]}
                    placeholder="Choose a commission type"
                    func={makeAmountRequired}
                    required={false}
                  />
                  <StaticFromInput
                    name="commission_price"
                    text="Amount"
                    type="number"
                    placeholder="Amount"
                    required={isAmountReq}
                  />
                </div>
              </div>
              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <MdDateRange className="w-5 h-5 text-slate-800 " />
                  <h2 className="text-slate-900 font-semibold">Booking Window Date</h2>
                </div>

                {dateRange.startDate && selectedCityTimeZone && (
                  <div className="w-[30%]" key={selectedCityTimeZone}>
                    <RangePicker
                      required
                      showTime={{
                        format: "HH",
                      }}
                      format="YYYY-MM-DD HH"
                      defaultValue={[
                        dayjs(dateRange.startDate).tz(selectedCityTimeZone), // Adjusts to UTC+3 for initial display
                        dayjs(dateRange.endDate).tz(selectedCityTimeZone),
                      ]}
                      onChange={(dates) => {
                        setDateRange({
                          startDate: dates?.at(0) ? dayjs(dates.at(0)).tz(selectedCityTimeZone).format() : null,
                          endDate: dates?.at(1) ? dayjs(dates.at(1)).tz(selectedCityTimeZone).format() : null,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <MdNightsStay className="w-5 h-5 " />
                  <h2 className="text-slate-800 font-semibold">Reservation Before Arrival</h2>
                </div>
                <div className="border rounded-md p-3">
                  <StaticFromInput name="reservation_policy" text="Number of Days" type="number" required={false} />
                </div>
              </div>
              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <TbBrandDaysCounter className="w-5 h-5  text-slate-800" />
                  <h2 className="text-slate-900 font-semibold">Min & Max Days</h2>
                </div>
                <div className="grid grid-cols-2 gap-4 border rounded-md p-3">
                  <StaticFromInput
                    name="min"
                    text="Min"
                    type="number"
                    defaultValue={contract.price_policy_minimum_days}
                    required={false}
                  />
                  <StaticFromInput
                    name="max"
                    text="Max"
                    type="number"
                    defaultValue={contract.price_policy_maximum_days}
                    required={false}
                  />
                </div>
              </div>
              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <FcCancel className="w-5 h-5  text-slate-800" />
                  <h2 className="text-slate-900 font-semibold">Cancellation Policy</h2>
                </div>
                <textarea
                  name="cancellation_policy"
                  cols="30"
                  rows="5"
                  className="border rounded-md p-3 focus:outline-none focus:ring focus:border-blue-300"
                ></textarea>
              </div>
              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <MdOutlinePriceChange className="w-5 h-5 text-slate-800" />
                  <h2 className="text-slate-900 font-semibold">Same Day Booking Supplement</h2>
                </div>
                <div className="border rounded-md p-3">
                  <StaticFromInput name="same_day_reservation_price" text="Amount" type="number" required={false} />
                </div>
              </div>
              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <HiClipboardDocumentCheck className="w-5 h-5 text-slate-800" />
                  <h2 className="text-slate-900 font-semibold">Contract Document</h2>
                </div>
                <FileUploader
                  files={contractDocuments}
                  setFiles={setContractDocuments}
                  maxFiles={1}
                  accept={"image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .csv"}
                  urls={contractDocumentsUrls}
                />
              </div>
              <div className="w-full  flex flex-col gap-y-2 ">
                <div className="w-full flex items-center gap-x-1 text-slate-900">
                  <CgNotes className="w-5 h-5 text-slate-800" />
                  <h2 className="text-slate-900 font-semibold">Notes</h2>
                </div>
                <textarea
                  name="notes"
                  className="border rounded-md p-3 focus:outline-none focus:ring focus:border-blue-300"
                ></textarea>
              </div>
              <div className="w-full flex justify-center items-center mt-6 ">
                <button
                  type="submit"
                  disabled={addFlag}
                  className={`updateButton disabled:cursor-not-allowed disabled:grayscale`}
                >
                  Edit Contract{" "}
                  {addFlag ? (
                    <>
                      {" "}
                      <TopBarProgress /> <ButtonLoading />
                    </>
                  ) : (
                    <RxUpdate className="w-5 h-5 text-white" />
                  )}
                </button>
              </div>
            </form>
          </>
        )
      )}
      {roomFlag && (
        <div
          className={` fixed ${"top-0 left-0 right-0 bottom-0 h-screen  "}  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${"opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "} `}
          >
            <HashLoader size={30} color="white" />
            <div className="text-white">Please wait while data is fetched from the server</div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <NoPermission />
  );
}
