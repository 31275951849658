import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";
import { FaDatabase, FaGear, FaReceipt } from "react-icons/fa6";
import { IoMdPerson } from "react-icons/io";
import { MdEmail, MdLocationCity, MdOutlineFamilyRestroom } from "react-icons/md";
import { BiMessageAltError } from "react-icons/bi";
import { AiFillDelete, AiOutlineEye, AiOutlineLoading } from "react-icons/ai";
import { Button, Modal } from "flowbite-react";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { PiIdentificationCardLight } from "react-icons/pi";
import usePermissions from "../../../customHooks/usePermissions";
import NoPermission from "../NoPermission/NoPermission";
import { DetailsModal } from "../../../customComponents/TableComponents";
import ListFilters from "../../../utils/ListFilters";

export default function ClientsList() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "auth_users.view_profile_customer",
    "auth_users.delete_profile_customer",
    "auth_users.change_profile_customer",
    "auth_users.view_customerbookinghistory",
    "auth_users.add_customerbookinghistory",
    "auth_users.add_customerfriends",
    "auth_users.view_customerfriends",
    "permissions_management.users_profile",
  ]);

  const isViewingClientsListAllowed =
    permissions?.find((permission) => permission.name === "auth_users.view_profile_customer")?.value === true;

  const isDeletingClientAllowed =
    permissions?.find((permission) => permission.name === "auth_users.delete_profile_customer")?.value === true;

  const isEditingClientAllowed =
    permissions?.find((permission) => permission.name === "auth_users.change_profile_customer")?.value === true;

  const isViewingUserProfileAllowed =
    permissions?.find((permission) => permission.name === "permissions_management.users_profile")?.value === true;

  const isAddingBookingHistoryAllowed =
    permissions?.find((permission) => permission.name === "auth_users.add_customerbookinghistory")?.value === true;

  const isViewingBookingHistoryAllowed =
    permissions?.find((permission) => permission.name === "auth_users.view_customerbookinghistory")?.value === true;

  const isAddingFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.add_customerfriends")?.value === true;

  const isViewingFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.view_customerfriends")?.value === true;

  const {
    data: clients,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["clients-list"],
    queryFn: () => {
      return fetchDataQuery("/auth/api/v1/user-customer/");
    },
  });

  if (isLoading || !permissionFlag) {
    return <Loading />;
  }

  if (error || !clients) {
    <Errorfetch Error={error.message} />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const rows = clients?.map((client) => {
    return {
      name: client?.profile_customer?.customer_name,
      id: client?.id,
      username: client?.username,
      email: client?.email,
      nickname: client?.profile_customer?.nickname,
      country: client.profile_customer?.country_name,
      city: client?.profile_customer?.city,
      idNumber: client?.profile_customer?.id_number,
      mobileNumbers: client?.profile_customer?.mobile_numbers,
      whatsAppNumbers: client?.profile_customer?.whats_app_numbers,
      profileID: client?.profile_customer?.id,
      notes: client?.profile_customer?.notes,
    };
  });

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <FaDatabase className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
    },
    {
      key: "name",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <IoMdPerson className="w-5 h-5  text-yellow-500" />
          Name
        </div>
      ),
      render: (text, params) => {
        if (!params.name) {
          return "No name";
        }
        return params.name;
      },
      dataIndex: "name",
    },
    {
      key: "username",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <IoMdPerson className="w-5 h-5  text-yellow-500" />
          Username
        </div>
      ),
      dataIndex: "username",
    },
    {
      key: "email",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdEmail className="w-5 h-5  text-yellow-500" />
          Email
        </div>
      ),
      dataIndex: "email",
    },
    {
      key: "nickname",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <IoMdPerson className="w-5 h-5  text-yellow-500" />
          Nickname
        </div>
      ),
      dataIndex: "nickname",
    },
    {
      key: "idNumber",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <PiIdentificationCardLight className="w-5 h-5  text-yellow-500" />
          ID Number
        </div>
      ),
      dataIndex: "idNumber",
    },
    {
      key: "country",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdLocationCity className="w-5 h-5  text-yellow-500" />
          Country
        </div>
      ),
      render: (text, params) => {
        if (!params.country) {
          return "No Country";
        }
        return params.country;
      },
      dataIndex: "country",
    },
    {
      key: "city",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdLocationCity className="w-5 h-5  text-yellow-500" />
          City
        </div>
      ),
      render: (text, params) => {
        if (!params.city) {
          return "No city";
        }
        return params.city;
      },
      dataIndex: "city",
    },
    {
      key: "mobileNumbers",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdEmail className="w-5 h-5  text-yellow-500" />
          Mobile Numbers
        </div>
      ),
      render: (text, params) => {
        if (!params.mobileNumbers) {
          return "No mobile numbers";
        }

        return (
          <DetailsModal title="Mobile Numbers">
            {Object.values(params.mobileNumbers).map((mobileNumber, index) => {
              return (
                <ul key={index}>
                  <li className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">{mobileNumber}</li>
                </ul>
              );
            })}
          </DetailsModal>
        );
      },
      dataIndex: "mobileNumbers",
    },

    {
      key: "whatsAppNumbers",

      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdEmail className="w-5 h-5  text-yellow-500" />
          WhatsApp Numbers
        </div>
      ),
      render: (text, params) => {
        if (!params.whatsAppNumbers || Object.values(params.whatsAppNumbers).length === 0) {
          return "No WhatsApp numbers";
        }
        return (
          <DetailsModal title="WhatsApp Numbers">
            {Object.values(params.whatsAppNumbers).map((whatsAppNumber, index) => {
              return (
                <ul key={index}>
                  <li className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                    {whatsAppNumber}
                  </li>
                </ul>
              );
            })}
          </DetailsModal>
        );
      },
      dataIndex: "whatsAppNumbers",
    },
    {
      key: "notes",

      title: (
        <div className="flex items-center gap-x-1 w-full">
          <BiMessageAltError className="w-5 h-5  text-yellow-500" />
          Notes
        </div>
      ),
      render: (text, params) => {
        if (!params.notes) {
          return "No notes";
        }
        return (
          <DetailsModal title="Notes">
            <p className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">{params.notes}</p>
          </DetailsModal>
        );
      },
      dataIndex: "notes",
    },
    {
      key: "operations",
      title: (
        <div className="flex items-center justify-center gap-x-1 w-full">
          <FaGear className="w-5 h-5  text-yellow-500" />
          Operations
        </div>
      ),
      render: (text, params) => {
        return (
          <div className="flex gap-1 justify-center">
            {isViewingUserProfileAllowed && (
              <Link to={`/dashboard/user/${params.id}`}>
                {" "}
                <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
              </Link>
            )}
            {isEditingClientAllowed && (
              <Link to={`/dashboard/edit-client/${params.id}`}>
                <FiEdit className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800" />
              </Link>
            )}
            {isDeletingClientAllowed && <DeleteClientModal clientId={params.id} clientUsername={params.username} />}

            {(isViewingBookingHistoryAllowed || isAddingBookingHistoryAllowed) && (
              <Link to={`/dashboard/client-booking-history/${params.profileID}`}>
                <FaReceipt className="w-5 h-5 cursor-pointer hover:text-blue-600 hover:scale-105 active:scale-95 duration-300 transition-all text-blue-800" />
              </Link>
            )}

            {(isAddingFamilyAllowed || isViewingFamilyAllowed) && (
              <Link to={`/dashboard/client-family/${params.profileID}`}>
                <MdOutlineFamilyRestroom className="w-5 h-5 cursor-pointer hover:text-yellow-600 hover:scale-105 active:scale-95 duration-300 transition-all text-yellow-800" />
              </Link>
            )}
          </div>
        );
      },
      dataIndex: "operations",
    },
  ];

  return isViewingClientsListAllowed ? (
    <div className="min-h-screen w-full formPage-wrap">
      {/* <h2 className="text-center py-2 my-2 rounded-lg bg-[#002244] text-white font-bold text-uppercase">
        {"Clients List".toUpperCase()}
      </h2> */}
      <div className="w-full overflow-auto">
        <ListFilters columns={columns} rows={rows} title={"Clients List"} />
        {/* <DataGrid
          className="data-grid"
          columns={columns}
          rows={rows}
          initialState={{
            ..s?.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: {
              sortModel: [{ key: "id", sort: "desc" }],
            },
          }}
          pageSizeOptions={[5, 10, 25]}
        /> */}
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}

const ColumnHeader = ({ label, Icon }) => {
  return (
    <div className="flex items-center gap-x-1">
      <Icon className="w-5 h-5  text-yellow-500" />
      {label}
    </div>
  );
};

const DeleteClientModal = ({ clientId, clientUsername }) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const { isPending, mutate: deleteAgent } = useMutation({
    mutationFn: () => mutateDataQuery(`/auth/api/v1/user-customer/${clientId}/`, "delete"),
    mutationKey: ["delete-client", clientId],
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success(`Client ${clientUsername} Deleted Successfully`);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to delete Client");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to delete Client");
    },
  });

  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="cursor-pointer"
      >
        <AiFillDelete
          onClick={() => {}}
          className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-700 hover:text-red-900"
        />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showModal}
        position={"center"}
        onClose={() => setShowModal(false)}
      >
        <Modal.Body>
          <h3 className="text-xl font-medium text-gray-900 mb-4 text-center">
            Do you want to delete client {clientUsername}?
          </h3>
          <div className="flex justify-around items-center">
            <Button
              isProcessing={isPending}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              disabled={isPending}
              onClick={() => {
                deleteAgent(
                  {},
                  {
                    onSuccess: () => {
                      setShowModal(false);
                      void queryClient.refetchQueries({
                        queryKey: ["clients-list"],
                        type: "active",
                        exact: true,
                      });
                    },
                  },
                );
              }}
              color="failure"
            >
              Delete
            </Button>
            <Button disabled={isPending} onClick={() => setShowModal(false)} color="gray">
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
