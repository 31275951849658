import React, { useEffect, useState } from "react";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import Loading from "../../../Loading/Loading";
import { Link } from "react-router-dom";
import { Modal } from "antd"; // Importing Modal from Ant Design

const RoomExtraToolDetails = () => {
  const { id } = useParams();

  const [offerData, setOfferData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility
  const [imageSrc, setImageSrc] = useState(""); // State to store the image source

  const {
    data: offer,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["offer", id],
    queryFn: () => fetchDataQuery(`/upgrade-tool/api/v1/room-upgrade-tool/${id}/`),
  });

  useEffect(() => {
    if (isSuccess && offer) {
      setOfferData(offer);
    }
  }, [offer, isSuccess]);

  // Function to handle image click and open the modal
  const handleImageClick = (src) => {
    setImageSrc(src);
    setIsModalOpen(true);
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setIsModalOpen(false);
    setImageSrc("");
  };
  const firstUpgrade = offerData?.upgrades?.find((r) => r?.room?.hotel_name?.name);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="formPage-wrap flex flex-col gap-8 p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between w-full">
        <h2 className="text-2xl font-bold text-gray-800">Hotel Offer Details</h2>
        <Link className="updateButton max-md:w-max" to={`/dashboard/upgrade-tool/room-upgrade-tool/offer-form/${id}`}>
          Edit
        </Link>
      </div>

      <div className="flex flex-col gap-4 form-wrap">
        {/* ID Section */}
        <p className="flex gap-2 md:w-[49%]  items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm">
          <span className="font-semibold text-gray-700">ID:</span>
          <span className="text-gray-600">{offerData?.id}</span>
        </p>

        {/* Name and Name(Arabic) Section */}
        <h3 className="text-xl font-semibold text-gray-700">Offer Details</h3>
        <div className="flex flex-col gap-4 md:flex-row">
          <p className="flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm flex-1">
            <span className="font-semibold text-gray-700">Name:</span>
            <span className="text-gray-600">{offerData?.name}</span>
          </p>
          <p dir="rtl" className="flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm flex-1">
            <span className="font-semibold text-gray-700  ">إسم العرض:</span>
            <span className="text-gray-600 ">{offerData?.name_ar}</span>
          </p>
        </div>

        {/* Description and Description(Arabic) Section */}
        <div className="flex flex-col gap-4 md:flex-row">
          <p className="flex gap-2 items-start bg-gray-100 px-4 py-2 rounded-md shadow-sm flex-1">
            <span className="font-semibold text-gray-700">Description:</span>
            <span className="text-gray-600">{offerData?.description}</span>
          </p>
          <p dir="rtl" className="flex gap-2 items-start bg-gray-100 px-4 py-2 rounded-md shadow-sm flex-1">
            <span dir="rtl" className="font-semibold text-gray-700 ">
              الوصف:
            </span>
            <span dir="rtl" className="text-gray-600 ">
              {offerData?.description_ar}
            </span>
          </p>
        </div>

        {/* City, Area, and Hotel Section */}
        <h3 className="text-xl font-semibold text-gray-700">Room Details</h3>
        <div className="flex flex-col gap-4">
          <div className="input-par">
            <p className="input-chil flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm">
              <span className="font-semibold text-gray-700">City:</span>
              <span className="text-gray-600"> {firstUpgrade?.room?.hotel_name?.city.name}</span>
            </p>
            <p className="input-chil flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm">
              <span className="font-semibold text-gray-700">Hotel:</span>
              <span className="text-gray-600"> {firstUpgrade?.room?.hotel_name?.name}</span>
            </p>
          </div>
          <div className="input-par">
            <p className="input-chil flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm">
              <span className="font-semibold text-gray-700">Room:</span>
              <span className="text-gray-600">
                {offerData?.upgrades?.map((i, idx) => (
                  <span key={idx} className="flex gap-2 flex-wrap">
                    {i?.room?.room_name}
                  </span>
                ))}
              </span>
            </p>
            <p className="input-chil flex gap-2 items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm">
              <span className="font-semibold text-gray-700">Upgraded Room:</span>
              <span className="text-gray-600">
                {offerData?.upgrades?.map((i, idx) => (
                  <span key={idx} className="flex gap-2 flex-wrap">
                    {i?.upgrade_room?.room_name}
                  </span>
                ))}
              </span>
            </p>
          </div>
        </div>

        {/* Periods Section */}
        {offerData?.periods?.length > 0 && (
          <div className="flex flex-col gap-4">
            <h3 className="text-xl font-semibold text-gray-700">Periods</h3>
            {offerData?.periods?.map((period, idx) => (
              <div key={period.id} className="p-4 bg-gray-50 rounded-md shadow-sm border border-gray-200">
                <p className="text-lg font-semibold text-gray-700">{`Period ${idx + 1}`}</p>
                <p className="text-gray-600">
                  <span className="font-semibold">Start Date:</span> {period.start_date}
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold">End Date:</span> {period.end_date}
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold">Days of Week:</span> {period.days_of_week.join(", ")}
                </p>
              </div>
            ))}
          </div>
        )}

        {/* Document Section */}
        <div className="flex flex-col gap-2">
          <span className="font-semibold text-gray-700">Document:</span>
          <div className="border bg-gray-100 p-2 max-w-max w-[350px] h-full rounded-md shadow-sm hover:shadow-md transition-shadow duration-200">
            {offerData?.upgrade_document ? (
              /\.(jpg|jpeg|png|gif)$/.test(offerData.upgrade_document) ? (
                // Render as image if the file is an image
                <img
                  src={offerData.upgrade_document}
                  alt="Document"
                  className="max-w-full h-auto rounded-md cursor-pointer"
                  onClick={() => handleImageClick(offerData.upgrade_document)}
                />
              ) : (
                // Render as a download link if it's not an image
                <a
                  href={offerData.upgrade_document}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  Download Document
                </a>
              )
            ) : (
              <p>No document available</p>
            )}
          </div>
        </div>
      </div>

      {/* Modal for image preview */}
      <Modal visible={isModalOpen} onCancel={handleModalClose} footer={null} centered className="w-auto h-auto">
        <img src={imageSrc} alt="Preview" className="max-w-full h-auto rounded-md" />
      </Modal>
    </div>
  );
};

export default RoomExtraToolDetails;
