import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";
import { MdOutlinePermIdentity } from "react-icons/md";
import ListFilters from "../../../../utils/ListFilters";
import { FcDepartment } from "react-icons/fc";
import { DetailsModal } from "../../../../customComponents/TableComponents";
import { FaGear } from "react-icons/fa6";
import { HiMiniUsers } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import DeleteModal from "../../../../customComponents/DeleteModal";
import usePermissions from "../../../../customHooks/usePermissions";
import Errorfetch from "../../Errorfetch/Errorfetch";
import NoPermission from "../../NoPermission/NoPermission";

const DepartmentsList = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "department.view_department",
    "department.delete_department",
    "department.change_department",
  ]);

  const canViewDepartment =
    permissions?.find((permission) => permission.name === "department.view_department")?.value === true;

  const canDeleteDepartment =
    permissions?.find((permission) => permission.name === "department.delete_department")?.value === true;

  const canChangeDepartment =
    permissions?.find((permission) => permission.name === "department.change_department")?.value === true;

  const { isLoading: isLoadingDepartments, data: departments } = useQuery({
    queryKey: ["departments-list", page, pageSize],
    queryFn: () => {
      return fetchDataQuery(`/department/api/v1/department?page=${page}&page_size=${pageSize}`);
    },
    enabled: canViewDepartment,
  });

  if (isLoadingDepartments || !permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) return <Errorfetch error={"Failed to Fetch Permissions"} />;

  if (!canViewDepartment) {
    return <NoPermission />;
  }

  const rows = departments?.results?.map((department) => {
    return {
      id: department?.id,
      name: department?.name,
      users: department?.user,
    };
  });

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
    },
    {
      key: "name",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FcDepartment className="w-5 h-5  text-yellow-500" />
          Name
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "users",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <HiMiniUsers className="w-5 h-5  text-yellow-500" />
          Users
        </div>
      ),
      render: (text, params) => {
        return (
          <DetailsModal title={"users"}>
            {params?.users?.map((user, index) => {
              return (
                <ul key={index}>
                  <li className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                    {`${user?.first_name} ${user?.last_name} (${user?.username})`}
                  </li>
                </ul>
              );
            })}
          </DetailsModal>
        );
      },
      dataIndex: "users",
    },

    {
      key: "operations",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <FaGear className="w-5 h-5  text-yellow-500" />
          Operations
        </div>
      ),
      dataIndex: "operations",
      render: (_, params) => {
        return (
          <div>
            <div className="flex gap-1 items-center justify-center">
              {canChangeDepartment && (
                <Link to={`/dashboard/departments-management/edit-department/${params.id}`}>
                  <FiEdit className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800" />
                </Link>
              )}

              {canDeleteDepartment && (
                <DeleteModal
                  deleteUrl={`/department/api/v1/department/${params.id}/`}
                  entityName="Department"
                  itemId={params.id}
                  itemName={params.name}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <ListFilters
      handlePagination={(page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
      }}
      currentPage={page}
      listPageSize={pageSize}
      totalPages={departments?.count / pageSize}
      title="Departments List"
      rows={rows}
      columns={columns}
    />
  );
};

export default DepartmentsList;
