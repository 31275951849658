import React, { useEffect, useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../../../ButtonLoading/ButtonLoading";
import usePermissions from "../../../../../customHooks/usePermissions";
import Loading from "../../../Loading/Loading";
import { useFormik } from "formik";
import { FaWpforms } from "react-icons/fa6";
import { CustomerValidation } from "../CreateCustomerLink/CustomerValidation";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { getData } from "../../../../../redux/actions/sendDataAction";
import { getSelectedCustomerLink } from "../../../../../redux/actions/linkAction";
import { FcCalendar, FcClock, FcComboChart, FcDam, FcDepartment, FcGlobe } from "react-icons/fc";
import { useLocation, useNavigate, useParams } from "react-router";
import NoPermission from "../../../NoPermission/NoPermission";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { RxUpdate } from "react-icons/rx";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import mutateDataQuery from "../../../../../react_query/mutateDataQuery";

const UpdateCustomerLink = () => {
  const [status, setStatus] = useState();
  const [hotels, setHotels] = useState();
  const [hotelsFlag, setHotelsFlag] = useState(false);
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [markup, setMarkup] = useState();
  const [customerLink, setCustomerLink] = useState();
  const [hotelChoosen, setHotelChoosen] = useState([]);
  const [cityChoosen, setCityChoosen] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const format = "HH:mm";
  const { id } = useParams();

  const [permissions, permissionsFlag] = usePermissions(["link.change_link_customer"]);

  const { mutate, isError, isPending } = useMutation({
    mutationFn: (newValues) => mutateDataQuery(`/link/api/id/${id}/`, "patch", newValues),
    onError: (error) => {
      // An error happened!
      toast.error(`${error?.message || "failed post data"}  `);
    },
    onSuccess: (result) => {
      // Boom baby!

      toast.success("Link is updated successfully");
      navigate(`/dashboard/create-link/customer-link/link-list/${id}/preview`, {
        state: { unique_code: values.unique_code },
      }); // to navigate customer link details
    },
  });

  const { handleChange, handleBlur, handleSubmit, setFieldValue, setSubmitting, touched, errors, values } = useFormik({
    initialValues: {
      link_status: "new",
      expired_date: "",
      time: "",
      created_by: "",
      hotels: [],
      city: [],
      allowed_contoury: [],
      restricted_contoury: [],
      markup: "",
    },
    validationSchema: CustomerValidation,

    onSubmit: (values) => {
      const { time, expired_date, hotels, city, ...restData } = values;
      const hotelsData = hotels[0] === "All" ? [] : hotels;
      const cityData = city[0] === "All" ? [] : city;
      const newValues = { ...restData, expired_date: `${expired_date},${time}`, hotels: hotelsData, city: cityData };

      mutate(newValues);
    },
  });

  useEffect(() => {
    //Get selected cutomer link
    dispatch(
      getSelectedCustomerLink(id, (result) => {
        if (result.status === 200) {
          setCustomerLink(result.data);
          setFieldValue("unique_code", result.data.unique_code);
          setFieldValue("created_by", result.data.created_by.id);
          setFieldValue("link_status", result.data.link_status);
          setFieldValue(
            "hotels",
            result.data.hotels.length === 0 ? ["All"] : result.data.hotels.map((item) => item.id),
          );
          setHotelChoosen(result.data.hotels.map((item) => ({ label: item.name, value: item.id }))); // to set default value of select
          setFieldValue("city", result.data.city.length === 0 ? ["All"] : result.data.city.map((item) => item.id));
          setCityChoosen(result.data.city.map((item) => ({ label: item.name, value: item.id }))); // to set default value of select
          setFieldValue(
            "allowed_contoury",
            result.data.allowed_contoury.map((item) => item?.id),
          );
          setFieldValue(
            "restricted_contoury",
            result.data.restricted_contoury.map((item) => item?.id),
          );
          setFieldValue("markup", result.data.markup.id);
          setFieldValue("expired_date", result.data.expired_date.slice(0, 10));
          setFieldValue("time", result.data.expired_date.slice(11, 19));
        }
      }),
    );

    //link status
    dispatch(
      getData(
        `/link/api/link-status/`,
        (resp) => {
          if (resp.status === 200) {
            setStatus(resp.data);
          }
        },
        (err) => {},
      ),
    );

    //City
    dispatch(
      getData(
        `/search/api/v1/get-cities/`,
        (resp) => {
          if (resp.status === 200) {
            setCity(resp.data);
            // hotels
            const citiesId = resp.data.map((item) => item.id);
            dispatch(
              getData(
                `/hotels/api/v1/hotel-filter/?cities=${JSON.stringify(citiesId)}`,
                (resp) => {
                  if (resp.status === 200) {
                    setHotels(resp.data);
                  }
                },
                (err) => {},
              ),
            );
          }
        },
        (err) => {},
      ),
    );
    //Country
    dispatch(
      getData(
        `site-settings/api/countries-flags`,
        (resp) => {
          if (resp.status === 200) {
            setCountry(resp.data);
          }
        },
        (err) => {},
      ),
    );
    //Markup
    dispatch(
      getData(
        `/markup/api/`,
        (resp) => {
          if (resp.status === 200) {
            setMarkup(resp.data);
          }
        },
        (err) => {},
      ),
    );
  }, []);

  //Status Arr
  let statusArr = status ? status?.map((item) => ({ label: item, value: item })) : [];
  //Hotels Arr
  let hotelsArr = hotels ? hotels?.map((item) => ({ label: item.name, value: item.id })) : [];
  //City Arr
  let cityArr = city ? city?.map((item) => ({ label: item.name, value: item.id })) : [];

  //Country Arr
  let countryArr = country ? country?.map((item) => ({ label: item.name, value: item.country, flag: item.flag })) : [];

  //Markup Arr
  let markupArr = markup ? markup?.map((item) => ({ label: item.name, value: item.id })) : [];

  //Style
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,

      borderBottom: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      boxShadow: "none",
      borderRadius: "0",
    }),
  };
  // handle time
  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setFieldValue("time", `${hours1}:${minutes1}`);
  };

  return (
    <div className="w-full ">
      <div className="flex flex-col items-center justify-start min-h-screen w-full">
        {permissionsFlag && status && hotels && city && country && markup ? (
          permissions.some((item) => item.value) ? (
            <div className=" w-full ">
              <div className=" flex justify-center ">
                <h2 className="header-h2">
                  <FaWpforms className="w-5 h-5 text-yellow-600" />
                  Update Customer Link{" "}
                </h2>
              </div>
              <form className="form-wrap" onSubmit={handleSubmit}>
                <div className="w-full flex flex-col justify-center items-center gap-y-4">
                  {/* <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">Link Status</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="link_status"
                        options={statusArr}
                        defaultValue={[{ label: customerLink.link_status, value: customerLink.link_status }]}
                        onChange={(e) => {
                          setFieldValue("link_status", e.value);
                          setFieldValue("created_by", user.user_id); //set user id
                        }}
                        styles={style}
                        placeholder="Link Status"
                        onBlur={handleBlur}
                      />
                      <FcLink className="absolute top-3 left-3 w-5 h-5" />
                    </div>

                    {errors.link_status && touched.link_status && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.link_status}
                      </div>
                    )}
                  </div> */}

                  <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">Markup</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="markup"
                        styles={style}
                        options={markupArr}
                        defaultValue={
                          customerLink.markup
                            ? [{ label: customerLink.markup.name, value: customerLink.markup.id }]
                            : null
                        }
                        onChange={(e) => {
                          setFieldValue("markup", e.value);
                        }}
                        placeholder="Select Markup"
                        onBlur={handleBlur}
                      />
                      <FcComboChart className="absolute top-3 left-3 w-5 h-5" />
                    </div>
                    {errors.markup && touched.markup && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.markup}
                      </div>
                    )}
                  </div>

                  <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">City</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="city"
                        styles={style}
                        options={
                          values.city.some((item) => item === "All") ? [] : [{ label: "All", value: "All" }, ...cityArr]
                        }
                        value={
                          values.city.some((item) => item === "All") ? [{ label: "All", value: "All" }] : cityChoosen
                          // if select all , previous will be hidden expected All
                        }
                        onChange={(e) => {
                          // Delete hotel value
                          setHotelChoosen(null);
                          setFieldValue("hotels", []);
                          setFieldValue(
                            "city",
                            e.some((item) => item.value === "All") ? ["All"] : e.map((item) => item.value),
                          );
                          setCityChoosen(e);
                          // hotels
                          setHotelsFlag(true);
                          dispatch(
                            getData(
                              e.some((item) => item.value === "All")
                                ? `/hotels/api/v1/hotel-filter/?cities=${JSON.stringify(city.map((item) => item.id))}`
                                : `/hotels/api/v1/hotel-filter/?cities=${JSON.stringify(e.map((item) => item.value))}`,
                              (resp) => {
                                if (resp.status === 200) {
                                  setHotelsFlag(false);
                                  setHotels(resp.data);
                                }
                              },
                              (err) => {
                                setHotelsFlag(false);
                              },
                            ),
                          );
                        }}
                        placeholder="Select City"
                        onBlur={handleBlur}
                        isMulti
                      />
                      <FcDam className="absolute top-3 left-3 w-5 h-5" />
                    </div>
                    {errors.city && touched.city && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.city}</div>
                    )}
                  </div>
                  {hotelsFlag ? (
                    <div className="w-full flex justify-center ">
                      <ButtonLoading /> <TopBarProgress />
                    </div>
                  ) : (
                    <div className="w-full flex flex-col items-center justify-center">
                      <label className="font-semibold text-[#002244]">Hotels</label>
                      <div className="relative w-full">
                        <Select
                          className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                          name="hotels"
                          styles={style}
                          options={
                            values.hotels.some((item) => item === "All")
                              ? []
                              : [{ label: "All", value: "All" }, ...hotelsArr]
                            // if select all , options will be hidden
                          }
                          value={
                            values.hotels.some((item) => item === "All")
                              ? [{ label: "All", value: "All" }]
                              : hotelChoosen
                            // if select all , previous will be hidden expected All
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "hotels",
                              e.some((item) => item.value === "All") ? ["All"] : e.map((item) => item.value),
                            );
                            setHotelChoosen(e);
                          }}
                          placeholder="Select Hotels"
                          onBlur={handleBlur}
                          isMulti
                        />
                        <FcDepartment className="absolute top-3 left-3 w-5 h-5" />
                      </div>
                      {errors.hotels && touched.hotels && (
                        <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                          {errors.hotels}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">Allowed Country</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="allowed_contoury"
                        options={countryArr}
                        defaultValue={customerLink.allowed_contoury.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        styles={style}
                        // isDisabled={values.restricted_contoury?.length > 0}
                        onChange={(e) => {
                          setFieldValue("allowed_contoury", [e.value]);
                        }}
                        formatOptionLabel={(flagData) => (
                          <div className="flex justify-start items-center  gap-x-3 country-option  ">
                            <img src={flagData.flag} alt="country-image" className="w-5 h-3" />
                            <span>{flagData.label}</span>
                          </div>
                        )}
                        placeholder=" Allowed Country"
                        onBlur={handleBlur}
                      />
                      <FcGlobe className="absolute top-3 left-3 w-5 h-5" />
                    </div>
                    {errors.allowed_contoury && touched.allowed_contoury && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.allowed_contoury}
                      </div>
                    )}
                  </div>
                  {/* <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">Restricted Country</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="restricted_contoury"
                        options={countryArr}
                        defaultValue={customerLink.restricted_contoury.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        styles={style}
                        isDisabled={values.allowed_contoury?.length > 0}
                        onChange={(e) => {
                          setFieldValue(
                            "restricted_contoury",
                            e.map((item) => item.value)
                          );
                        }}
                        formatOptionLabel={(flagData) => (
                          <div className="flex justify-start items-center  gap-x-3 country-option  ">
                            <img src={flagData.flag} alt="country-image" className="w-5 h-3" />
                            <span>{flagData.label}</span>
                          </div>
                        )}
                        placeholder=" Restricted  Country"
                        onBlur={handleBlur}
                        isMulti
                      />
                      <FcCancel className="absolute top-3 left-3 w-5 h-5" />
                    </div>
                    {errors.restricted_contoury && touched.restricted_contoury && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.restricted_contoury}
                      </div>
                    )}
                  </div> */}

                  <div className="w-full flex  items-center justify-center gap-x-1">
                    <div className="w-[50%] flex flex-col items-center justify-center ">
                      <label className="font-semibold text-[#002244]">Expired Date</label>
                      <div className="relative w-full">
                        <input
                          placeholder="Tranfer Date"
                          type="date"
                          name="expired_date"
                          defaultValue={customerLink.expired_date.slice(0, 10)}
                          onChange={(e) => {
                            setFieldValue("expired_date", e.target.value);
                          }}
                          onBlur={handleBlur}
                          className={` w-full pl-10 h-10  border rounded-lg bg-white floating-input  text-gray-700  `}
                        />
                        <FcCalendar className="absolute top-3 left-3 w-5 h-5" />
                      </div>
                      {touched.expired_date && errors.expired_date ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.expired_date}
                        </div>
                      ) : null}{" "}
                    </div>
                    <div className="w-[50%] flex flex-col items-center justify-center ">
                      <label className="font-semibold text-[#002244] ">Expired Time</label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <TimePicker
                          clearIcon={false}
                          name="time"
                          as={TimePicker}
                          format={format}
                          defaultValue={dayjs(customerLink.expired_date.slice(11, 19), format)}
                          className={` w-full py-2 pl-10  border rounded-lg bg-white `}
                          onChange={handleTimeChange}
                          onBlur={handleBlur}
                        />
                        <div className="absolute pl-2">
                          <FcClock className="w-5 h-5 text-[#002244]" />
                        </div>
                      </div>
                      {touched.time && errors.time ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                          {errors.time}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex justify-center mt-6">
                    <button type="Submit" disabled={isPending} className="updateButton">
                      Update{" "}
                      {isPending ? (
                        <>
                          <TopBarProgress />
                          <ButtonLoading />
                        </>
                      ) : (
                        <RxUpdate className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-full flex items-center justify-center h-screen">
              <NoPermission />
            </div>
          )
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <Loading /> <TopBarProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateCustomerLink;
