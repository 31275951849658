import { Label, Modal, Spinner, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import "moment-hijri";
import { MdEmail } from "react-icons/md";
import EmailTemplate from "./EmailTemp";
import Select from "react-select";
import fetchDataQuery from "../../../react_query/fetchDataQuery";

const WhatsAppMessage = ({ openModalEmail, setopenModalEmail }) => {
  const [t] = useTranslation();
  const [formValue, setFormValue] = useState({ clientname: "", recipient_list: "", smtp_setting_id: "", subject: "" });
  const [htmlDataEmail, sethtmlDataEmail] = useState();

  const { clientname } = formValue;

  const onChange = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeSelect = (selectedOption, actionMeta) => {
    // Get the name of the Select component
    const selectName = actionMeta.name;

    setFormValue({
      ...formValue,
      [selectName]: selectedOption?.value,
    });
  };

  const {
    data: smtp,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["smtp-settings"],
    queryFn: () => fetchDataQuery(`/booking/api/v1/smtp-settings/`),
  });

  const { mutate, isError, isPending } = useMutation({
    mutationFn: (newValues) => mutateDataQuery(`/social-media-messages/api/v1/send-email/search/`, "post", newValues),
    onError: (error) => {
      // An error happened!

      toast.error(`${error?.response.data.message || "failed post data"}`);
    },
    onSuccess: (result) => {
      // Boom baby!
      if (result.status === "error") {
        toast.error(`${result?.message || "failed post data"}  `);
      } else {
        toast.success("sent to Email successfully");
        setopenModalEmail(false);
      }
    },
  });

  const handelsubmit = (e) => {
    e.preventDefault();
    const datasend = {
      subject: formValue.subject,
      message: `${htmlDataEmail}`,
      recipient_list: [formValue.recipient_list],
      smtp_setting_id: formValue.smtp_setting_id,
    };

    mutate(datasend);
  };

  //smtp Arr
  let smtpArr = smtp ? smtp?.map((item) => ({ label: item.email_from, value: item.id })) : [];

  //Style
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,

      borderBottom: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      boxShadow: "none",
      borderRadius: "0",
      backgroundColor: "rgb(249 250 251)",
    }),
  };

  return (
    <section>
      <Modal
        className="z-[100]"
        // size={"6xl"}
        dismissible
        show={openModalEmail}
        onClose={() => setopenModalEmail(false)}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <form onSubmit={handelsubmit} className=" bg-white flex w-full flex-col gap-4" dir={t("dir")}>
            <div className="w-full flex flex-col  ">
              <div className="mb-2 block">
                <Label htmlFor="name1" className="font-semibold text-[#002244]" value={t("emailFrom")} />
              </div>
              <div className="relative w-full">
                <Select
                  className="w-full text-gray-700 border rounded-md"
                  name="smtp_setting_id"
                  styles={style}
                  options={smtpArr}
                  onChange={onChangeSelect}
                  required
                />
              </div>
            </div>

            <div>
              <div className="mb-2 block">
                <Label className="font-semibold text-[#002244]" htmlFor="name1" value={t("clientname")} />
              </div>
              <TextInput
                onChange={onChange}
                id="name1"
                type="text"
                name="clientname"
                required
                style={{ outline: "0px" }}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label className="font-semibold text-[#002244]" htmlFor="subject" value={t("subject")} />
              </div>
              <TextInput
                onChange={onChange}
                id="subject"
                type="text"
                name="subject"
                required
                style={{ outline: "0px" }}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label className="font-semibold text-[#002244]" htmlFor="email1" value={t("clientemail")} />
              </div>
              <TextInput
                onChange={onChange}
                id="email1"
                type="email"
                name="recipient_list"
                required
                style={{ outline: "0px" }}
              />
            </div>

            <button
              disabled={isPending}
              type="submit"
              className=" w-full py-3 bg-indigo-800 hover:bg-indigo-900 flex justify-center items-center gap-2   text-white text-center   active:scale-95 duration-300 transition-all rounded-md"
            >
              {isPending ? (
                <Spinner aria-label="Medium sized spinner example" size="md" className=" text-white" />
              ) : (
                <>
                  <MdEmail className=" text-2xl" />
                  {t("sendEmail")}
                </>
              )}
            </button>
          </form>
          <EmailTemplate sethtmlDataEmail={sethtmlDataEmail} nameClientEmail={clientname} />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default WhatsAppMessage;
