import React, { useEffect, useState } from "react";
import "./CurrencyHistory.scss";
import { useDispatch, useSelector } from "react-redux";
import { AiFillEye } from "react-icons/ai";
import { useNavigate, useParams } from "react-router";
import { getCurrencyRatesHistory } from "../../../redux/actions/currencyRates";
import moment from "moment";
import ListFilters from "../../../utils/ListFilters";
import Loading from "../Loading/Loading";

export default function CurrencyHistory() {
  const { page } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const history = useSelector((state) => state.CurrencyRatesHistory);

  const currentPage = Number(page) || 1;
  const totalPages = history && Math.ceil(history?.count / pageSize);

  useEffect(() => {
    dispatch(getCurrencyRatesHistory(page, pageSize, () => setIsLoading(false)));
  }, [page, pageSize]);
  const columns = [
    { key: "id", title: "ID", dataIndex: "id" },
    { key: "name", title: "Name", dataIndex: "name" },
    { key: "rate", title: "Rate", dataIndex: "rate" },
    { key: "startTime", title: "Start Time", dataIndex: "startTime" },
    { key: "createdBy", title: "Created By", dataIndex: "createdBy" },
    { key: "duration", title: "Duration", dataIndex: "duration" },
    { key: "finishedTime", title: "Finished Time", dataIndex: "finishedTime" },
    {
      key: "viewRates",
      title: "View Rates",
      dataIndex: "viewRates",
      render: (text, params) => {
        return <AiFillEye size={25} onClick={() => navigate(`./${params.id}`)} />;
      },
    },
  ];

  const rows = history.results.map((obj) => {
    let startDate = moment(obj.start_time);
    let endDate = obj.finish_time ? moment(obj.finish_time) : null;
    return {
      id: obj.id,
      name: obj.object_name,
      rate: obj.data.rate,
      startTime: startDate.format("YYYY-MM-DD / hh:mm A"),
      createdBy: obj.user.username,
      duration: endDate ? `${Math.floor((endDate - startDate) / 3600000)} hrs` : "Present",
      finishedTime: endDate ? endDate.format("YYYY-MM-DD / hh:mm A") : "Present",
    };
  });

  function handlePageChange(params, size) {
    setPageSize(size);
    navigate(`/dashboard/currency-rate/history/${params}`);
    setIsLoading(true);
  }

  return (
    <div className="formPage-wrap">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-full ">
          <ListFilters
            columns={columns}
            rows={rows}
            title="Currency Rate History"
            handlePagination={handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
            listPageSize={pageSize}
          />
        </div>
        //   className="data-grid"
        //   columns={columns}
        //   rowCount={history.count}
        //   rows={rows}
        //   slots={{
        //     Toolbar: GridToolbar,
        //   }}
        //   paginationModel={{ page: Number(page) - 1, pageSize: 5 }}
        //   onPaginationModelChange={handlePageChange}
        //   paginationMode="server"
        //   //   onSortModelChange={comparator}
        //   //   columnVisibilityModel={columnVisibility}
        //   //   onColumnVisibilityModelChange={(visibility) => {
        //   //     setColumnVisibility(visibility);
        //   //     localStorage.setItem("contract-grid-visibility", JSON.stringify(visibility));
        //   //   }}
        // />
      )}
    </div>
  );
}
