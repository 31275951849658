import React, { useEffect, useState } from "react";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import {
  gateWayAction,
  getCurrencyAction,
  getSelectedBookedRoomSingleAction,
  getServicesTypeAction,
} from "../../../redux/actions/bookingListAction";
import { useNavigate, useParams } from "react-router";
import { IoAdd } from "react-icons/io5";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
// import jwtDecode from 'jwt-decode';
import { TimePicker } from "antd";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import TopBarProgress from "react-topbar-progress-indicator";
import { addJumboWalletAction } from "../../../redux/actions/walletAction";
import { NotificationContainer } from "react-notifications";

const JumboWallet = ({ amountWallet, commission, currencyWallet, subTotal, agent, methodOfPayment }) => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [servicesType, setServicesType] = useState([]);
  const [gateWays, setGateWays] = useState([]);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [amount, setAmount] = useState("");
  const [convertAmount, setConvertAmount] = useState();
  const [convertRate, setConvertRate] = useState();
  const [currency, setCurrency] = useState("");
  const [currencyLabel, setCurrencyLabel] = useState("");
  const [invoice, setInvoice] = useState("");
  const [service, setService] = useState("");
  const [name, setName] = useState("");
  const [gateWay, setGateWay] = useState("");
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const format = "HH:mm";
  const { addWalletFlag } = useSelector((state) => state.walletReducer);
  // const decodedToken = jwtDecode(token);

  useEffect(() => {
    dispatch(getSelectedBookedRoomSingleAction(token, params.id));
    dispatch(getCurrencyAction(token));
    dispatch(
      getServicesTypeAction(token, (result) => {
        setServicesType(result);
      }),
    );
    dispatch(
      gateWayAction(token, (result) => {
        setGateWays(result);
      }),
    );
  }, []);
  useEffect(() => {
    if (currencyLabel) {
      setLoadingToggle(true);
      setToggle(false);
      dispatch(
        convertingCurrencyAction(token, currencyLabel, currencyWallet, (result) => {
          if (result.status === 200) {
            result.data.results.length !== 0
              ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
              : setConvertAmount(amount && amount * 1);
            result.data.results.length !== 0
              ? setConvertRate(...result.data.results.map((item) => item.rate))
              : setConvertRate(1);
            setToggle(true);
            setLoadingToggle(false);
          }
        }),
      );
    }
  }, [currencyWallet]);

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { jumboFlag } = useSelector((state) => state.bookingListReducer);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  let ServiceType = servicesType
    ? servicesType.map((item, index) => {
        return { value: item.name_1, label: item.name_2 };
      })
    : [];

  let gateway = gateWays
    ? gateWays.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const handleAmount = (e) => {
    setAmount(e.target.value);
    if (convertRate) {
      setConvertAmount(e.target.value * convertRate);
    }
  };

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setTime(`${hours1}:${minutes1}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((time === "" && !amountWallet) || !commission || !currencyWallet || !subTotal || !agent) {
      alert("You Have Empty Field"); // You can replace this with your validation logic
    } else {
      const data = {
        amount: amountWallet,
        agent_name: agent,
        commission: commission,
        currency: currencyWallet,
        method_of_payment: "payment",
        subtotal: subTotal,
        amount_used: 500.0,
        rest_of_payment: 500.0,
        service_description: "payment",
        system_confirmation: 1254862,
        payment: {
          total_amount: amount,
          currency_type: currency,
          invoice_number: invoice,
          service_type: service,
          transaction_date: `${date},${time}`,
          gateway: gateWay,
          employee_name: name,
          transaction_status: "confirmed",
        },
      };

      const transaction_date = `${date},${time}`;
      const formData = new FormData(e.target);
      formData.append("booked_room", params.id);
      formData.append("creator", 1);
      formData.append("transaction_date", transaction_date);
      formData.append("transaction_status", "confirmed");
      formData.append("contract_currency", currencyWallet);
      formData.append("conversion_rate_price", convertAmount.toFixed(2));
      formData.append("conversion_rate", convertRate);
      dispatch(
        addJumboWalletAction(token, data, (result) => {
          if (result.status === 201) {
            lightToast.success("The Wallet Of Jumbo4pay was completed successfully");
            navigate(-1);
          }
        }),
      );
    }
  };
  return (
    <div className="w-full ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className=" w-full">
          <form className="mt-2  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <label className=" text-center w-full text-gray-600   ">Employee Name</label>
              <input
                placeholder="Employee Name"
                name="employee_name"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                className=" w-full px-3 h-10  border rounded-lg bg-white "
              />
              <label className=" flex text-gray-600   ">Total Amount </label>
              <input
                placeholder="Total Amount"
                name="total_amount"
                type="number"
                step="any"
                required
                onChange={handleAmount}
                className=" w-full px-3 h-10  border rounded-lg bg-white "
              />

              <label className=" flex text-gray-600 ">Currency</label>
              <Select
                name="currency_type"
                options={CurrencyType}
                onChange={(e) => {
                  setLoadingToggle(true);
                  setToggle(false);
                  setCurrency(e.value);
                  setCurrencyLabel(e.label);
                  dispatch(
                    convertingCurrencyAction(token, e.label, currencyWallet, (result) => {
                      if (result.status === 200) {
                        result.data.results.length !== 0
                          ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
                          : setConvertAmount(amount && amount * 1);
                        result.data.results.length !== 0
                          ? setConvertRate(...result.data.results.map((item) => item.rate))
                          : setConvertRate(1);
                        setToggle(true);
                        setLoadingToggle(false);
                      }
                    }),
                  );
                }}
                isSearchable
                className=" w-full  h-10  border rounded-lg  bg-white "
                placeholder="Currency"
              />
              {toggle && amount ? (
                <>
                  <label className=" flex text-gray-600 ">Amount With Currency Of Contract ({currencyWallet})</label>
                  <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                    <div className="text-black flex items-end gap-x-1">
                      {convertAmount} <span className="text-sm font-semibold text-gray-700">{currencyWallet}</span>
                    </div>
                  </div>
                </>
              ) : (
                loadingToggle && (
                  <>
                    <ButtonLoading />
                  </>
                )
              )}

              <label className=" flex text-gray-600   ">Jumbo invoice number</label>
              <input
                placeholder="Jumbo invoice number"
                name="invoice_number"
                type="text"
                required
                onChange={(e) => setInvoice(e.target.value)}
                className=" w-full px-3 h-10  border rounded-lg bg-white "
              />
              <label className=" flex text-gray-600 ">Service Type</label>
              <Select
                name="service_type"
                options={ServiceType}
                onChange={(e) => {
                  setService(e.value);
                }}
                isSearchable
                className=" w-full  h-10  border rounded-lg  bg-white "
                placeholder="Service Type"
              />

              <div className="flex items-center gap-x-5 w-full">
                <div className="w-[50%]">
                  <label className=" flex justify-center text-gray-600 ">Date</label>
                  <input
                    placeholder="Tranfer Date"
                    type="date"
                    required
                    onChange={(e) => setDate(e.target.value)}
                    className=" w-full px-3 h-10  border rounded-lg floating-input bg-white "
                  />
                </div>
                <div className="w-[50%]">
                  <label className=" flex justify-center text-gray-600 ">Time</label>
                  <TimePicker
                    clearIcon={false}
                    required
                    format={format}
                    className="w-full"
                    onChange={handleTimeChange}
                  />
                </div>
              </div>
              <label className=" flex text-gray-600 ">Gateway</label>
              <Select
                name="gateway"
                options={gateway}
                onChange={(e) => {
                  setGateWay(e.value);
                }}
                type="text"
                isSearchable
                className=" w-full  h-10  border rounded-lg  bg-white "
                placeholder="Gateway"
              />

              <button
                type="Submit"
                disabled={addWalletFlag}
                className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Add{" "}
                {addWalletFlag ? (
                  <>
                    <TopBarProgress />
                    <ButtonLoading />
                  </>
                ) : (
                  <IoAdd className="w-5 h-5" />
                )}
              </button>
            </div>
          </form>
        </div>
        <NotificationContainer />
      </div>
    </div>
  );
};

export default JumboWallet;
