import api_token from "../../api/UserApi";
import { ADD_CASH_BOX_FLAG, CASH_BOX_DATA, CASH_BOX_STATMENT_DATA, LIST_FLAG } from "../types/types";

export const addCashBoxAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_CASH_BOX_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`box/api/`, data);
      if (response.status === 201) {
        dispatch({
          type: ADD_CASH_BOX_FLAG,
          payload: false,
        });
      }
      callback(response);
    } catch (error) {}
  };
};

export const getAllCashBox = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`box/api/`);
      if (response.status === 200) {
        dispatch({
          type: CASH_BOX_DATA,
          payload: response.data,
        });
      }
      callback(response);
    } catch (error) {}
  };
};
export const CashBoxActionById = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`box/api/${id}/`);
      if (response.status === 200) {
        dispatch({
          type: CASH_BOX_DATA,
          payload: response.data,
        });
      }
      callback(response);
    } catch (error) {}
  };
};
export const CashBoxActionUpdate = (token, id, values, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`box/api/${id}/`, values);
      if (response.status === 200) {
        dispatch({
          type: CASH_BOX_DATA,
          payload: response.data,
        });
      }
      callback(response);
    } catch (error) {}
  };
};
export const CashBoxActionDelete = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`box/api/${id}/`);
      if (response.status === 200) {
        dispatch({
          type: CASH_BOX_DATA,
          payload: response.data,
        });
      }
      callback(response);
    } catch (error) {}
  };
};

export const getCashBoxStatment = (token, id, page, sorting, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(
        `booking/api/v1/cash-payments-transaction/?page_size=10&page=${page}&ordering=${sorting}&box_name=${id}`,
      );
      if (response.status === 200) {
        dispatch({
          type: CASH_BOX_STATMENT_DATA,
          payload: response.data,
        });
      }
      callback(response);
    } catch (error) {}
  };
};

export const flagListAction = (header) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: LIST_FLAG,
        payload: header,
      });
    } catch (error) {}
  };
};
