import React from "react";
import { MdDelete } from "react-icons/md";

function DeleteButton({ onDelete, className, ...props }) {
  return (
    <button
      onClick={onDelete}
      {...props}
      className={`w-8 h-8 rounded-full   bg-red-800 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-red-800 ${className}`}
    >
      <MdDelete />
    </button>
  );
}

export default DeleteButton;
