import { ISLOGIN, LOGINERROR , LOGIN_FLAG } from "../types/types";

const initialState = {
    userId : '',
    loginError:null,
    loginFlag:false,
}


export const loginReducerUser = (state = initialState, action) => {
  switch (action.type) {
    case ISLOGIN:
      return { ...state, userId: action.payload };
    case LOGINERROR:
      return { ...state, loginError: action.payload };
    case LOGIN_FLAG:
        return { ...state, loginFlag: action.payload };
    default:
      return state;
  }
};
