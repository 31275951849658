import React, { useEffect, useState } from "react";
import { FaWpforms } from "react-icons/fa";
import { FcComboChart, FcDam, FcDepartment, FcGlobe, FcManager } from "react-icons/fc";
import { useFormik } from "formik";
import { getData } from "../../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import lightToast from "light-toast";
import Loading from "../../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import Select from "react-select";
import ButtonLoading from "../../ButtonLoading/ButtonLoading";
import { IoAdd } from "react-icons/io5";
import {
  createSearchPermission,
  getSearchByIdAction,
  UpdateSearchByIdAction,
} from "../../../../redux/actions/searchPermissionAction";
import { NotificationContainer, NotificationManager } from "react-notifications";
import usePermissions from "../../../../customHooks/usePermissions";
import { RxUpdate } from "react-icons/rx";
import * as yup from "yup";

const CreateSearchPermission = () => {
  const [users, setUsers] = useState();
  const [markup, setMarkup] = useState();
  const [hotels, setHotels] = useState([]);
  const [hotelsLoading, setHotelsLoading] = useState(false);
  const [city, setCity] = useState();
  const [country, setCountry] = useState();

  //choosen in select  All if do not slected all data

  const [markupChoosen, setmarkupChoosen] = useState();
  const [hotelsChoosen, sethotelsChoosen] = useState();
  const [cityChoosen, setcityChoosen] = useState();
  const [contouryChoosen, setcontouryChoosen] = useState();

  const params = useParams();
  //dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const baseSchema = {
    user: yup.string().required("Please select user"),
    allowed_contoury: yup.array().min(1, "Please select nationality"),
    allowed_city: yup.array().min(1, "Please select city"),
    allowed_hotels: yup.array().min(1, "Please select hotel"),
  };

  // Check if params.id exists and add the conditional field
  const CustomerValidation = () => {
    if (!params.userID) {
      return yup.object().shape({
        ...baseSchema,
        allowed_markup: yup.array().min(1, "Please select markup"),
      });
    } else {
      return yup.object().shape({
        ...baseSchema,
        allowed_markup: yup.array(), // No minimum requirement if params.id does not exist
      });
    }
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      user: params.userID || "",
      allowed_markup: [],
      allowed_hotels: [],
      allowed_city: [],
      allowed_contoury: [],
      all_markup: !!params.userID,
      all_hotels: false,
      all_city: false,
      all_contoury: false,
    },
    validationSchema: CustomerValidation,

    onSubmit: (values) => {
      const {
        allowed_markup,
        allowed_hotels,
        allowed_city,
        allowed_contoury,
        all_markup,
        all_hotels,
        all_city,
        all_contoury,
        ...restData
      } = values;
      //markup
      const newall_markup = allowed_markup[0] === "All" ? true : all_markup;
      const newmarkup = allowed_markup[0] === "All" ? [] : allowed_markup;

      //hotels
      const newall_hotels = allowed_hotels[0] === "All" ? true : all_hotels;
      const newhotels = allowed_hotels[0] === "All" ? [] : allowed_hotels;

      //city
      const newall_city = allowed_city[0] === "All" ? true : all_city;
      const newcity = allowed_city[0] === "All" ? [] : allowed_city;

      //contoury
      const newall_contoury = allowed_contoury[0] === "All" ? true : all_contoury;
      const newcontoury = allowed_contoury[0] === "All" ? [] : allowed_contoury;

      // const newAllowedHotels = allowed_hotels[0] === "All" ? hotels.map((item) => item.id) : allowed_hotels;
      // const newAllowedCity = allowed_city[0] === "All" ? city.map((item) => item.id) : allowed_city;
      // const newAllowedContoury = allowed_contoury[0] === "All" ? country.map((item) => item.id) : allowed_contoury;

      const newValues = {
        ...restData,
        //markup
        all_markup: newall_markup,
        allowed_markup: newmarkup,
        //hotels
        all_hotels: newall_hotels,
        allowed_hotels: newhotels,
        //city
        all_city: newall_city,
        allowed_city: newcity,
        //contoury
        all_contoury: newall_contoury,
        allowed_contoury: newcontoury,
      };

      //update
      if (params.id) {
        dispatch(
          UpdateSearchByIdAction(params.id, newValues, (result) => {
            if (result.status === 200) {
              setSubmitting(false);
              lightToast.success("Search Permission Update successfully");
              navigate(-1);
            } else {
              setSubmitting(false);
              NotificationManager.error(result.response.data[0]?.message);
            }
          }),
        );
      } else {
        //create
        dispatch(
          createSearchPermission(newValues, (result) => {
            if (result.status === 201) {
              setSubmitting(false);
              lightToast.success("Search Permission create successfully");
              navigate(-1);
            } else {
              setSubmitting(false);
              NotificationManager.error(result.response.data[0]?.message);
            }
          }),
        );
      }
    },
  });

  //get data for update select
  const [Update_markup, setUpdate_markup] = useState();
  const [Update_hotels, setUpdate_hotels] = useState();
  const [Update_city, setUpdate_city] = useState();
  const [Update_contoury, setUpdate_contoury] = useState();
  const [Update_user, setUpdate_User] = useState();

  useEffect(() => {
    //check if update and get data  for select

    if (params.id) {
      //get data by ID

      dispatch(
        getSearchByIdAction(params.id, (result) => {
          if (result.status === 200) {
            //user
            setFieldValue("user", result.data.user.id);
            setUpdate_User({ label: result.data.user.username, value: result.data.user.id }); // to set default value of select

            //allowed_markup
            setFieldValue(
              "allowed_markup",
              result.data.allowed_markup.length === 0 ? ["All"] : result.data.allowed_markup.map((item) => item.id),
            );
            setUpdate_markup(
              result.data.allowed_markup.length === 0
                ? { label: "All", value: "All" }
                : result.data.allowed_markup.map((item) => ({ label: item.name, value: item.id })),
            ); // to set default value of select

            //allowed_hotels

            setFieldValue(
              "allowed_hotels",
              result.data.allowed_hotels.length === 0 ? ["All"] : result.data.allowed_hotels.map((item) => item.id),
            );
            setUpdate_hotels(
              result.data.allowed_hotels.length === 0
                ? { label: "All", value: "All" }
                : result.data.allowed_hotels.map((item) => ({ label: item.name, value: item.id })),
            ); // to set default value of select

            //allowed_city

            setFieldValue(
              "allowed_city",
              result.data.allowed_city.length === 0 ? ["All"] : result.data.allowed_city.map((item) => item.id),
            );
            setUpdate_city(
              result.data.allowed_city.length === 0
                ? { label: "All", value: "All" }
                : result.data.allowed_city.map((item) => ({ label: item.name, value: item.id })),
            ); // to set default value of select

            //allowed_contoury

            setFieldValue(
              "allowed_contoury",
              result.data.allowed_contoury.length === 0 ? ["All"] : result.data.allowed_contoury.map((item) => item.id), // to set default value of select
            );
            setUpdate_contoury(
              result.data.allowed_contoury.length === 0
                ? { label: "All", value: "All" }
                : result.data.allowed_contoury.map((item) => ({ label: item.name, value: item.id })),
            );

            // get data hotels for select

            const citiesId = result.data.allowed_city.map((item) => item.id);
            setHotelsLoading(true);
            dispatch(
              getData(
                `/hotels/api/v1/hotel-filter/?cities=${JSON.stringify(citiesId)}`,
                (resp) => {
                  if (resp.status === 200) {
                    setHotelsLoading(false);
                    setHotels(resp.data);
                  }
                },
                (err) => {
                  setHotelsLoading(false);
                },
              ),
            );
          }
        }),
      );
    }

    /// get data  for select

    //users
    dispatch(
      getData(
        `/auth/api/v1/users-without-search-permissions/`,
        (resp) => {
          if (resp.status === 200) {
            setUsers(resp.data);
          }
        },
        (err) => {},
      ),
    );
    //Markup
    dispatch(
      getData(
        `/markup/api/`,
        (resp) => {
          if (resp.status === 200) {
            setMarkup(resp.data);
          }
        },
        (err) => {},
      ),
    );
    //City
    dispatch(
      getData(
        `/search/api/v1/get-cities/`,
        (resp) => {
          if (resp.status === 200) {
            setCity(resp.data);
          }
        },
        (err) => {},
      ),
    );
    //Country
    dispatch(
      getData(
        `site-settings/api/countries-flags`,
        (resp) => {
          if (resp.status === 200) {
            setCountry(resp.data);
          }
        },
        (err) => {},
      ),
    );
  }, []);

  //Users Arr

  let UsersArr = users ? users.map((item) => ({ label: item.username, value: item.id })) : [];
  //Status Arr
  let markupArr = markup ? markup.map((item) => ({ label: item.name, value: item.id })) : [];
  //Hotels Arr
  let hotelsArr = hotels ? hotels.map((item) => ({ label: item.name, value: item.id })) : [];
  //City Arr
  let cityArr = city ? city.map((item) => ({ label: item.name, value: item.id })) : [];
  //Country Arr
  let countryArr = country ? country.map((item) => ({ label: item.name, value: item.country, flag: item.flag })) : [];

  //Style
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,

      borderBottom: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      boxShadow: "none",
      borderRadius: "0",
    }),
  };

  //Permission
  const [permissions, permissionsFlag] = usePermissions(["permissions_management.add_searchpermission"]);

  return (
    <div className="formPage-wrap">
      {permissionsFlag && markup && country && city && permissions.some((item) => item.value) ? (
        <div className="w-full pt-3">
          <div className="flex flex-col items-center justify-start min-h-screen w-full">
            <div className=" w-full  ">
              <div className=" flex justify-center ">
                <h2 className="header-h2">
                  <FaWpforms className="w-5 h-5 text-yellow-600" />
                  {params.id ? "Update" : "Create"} Search permission
                </h2>
              </div>
              <form className="w-full mt-3 form-wrap" onSubmit={handleSubmit}>
                <div className="w-full flex flex-col  gap-y-4">
                  <div className="input-par">
                    <div className="input-chil">
                      <div className="w-full flex flex-col ">
                        <label className="font-semibold text-[#002244]">User</label>
                        <div className="relative w-full">
                          <Select
                            className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                            name="user"
                            onChange={(e) => {
                              setFieldValue("user", e.value);
                            }}
                            styles={style}
                            options={UsersArr}
                            defaultValue={
                              params.userID
                                ? UsersArr.filter((user) => Number(user.value) === Number(params.userID))[0]
                                : Update_user
                            }
                            isDisabled={!!params.userID}
                            placeholder="Select user"
                            onBlur={handleBlur}
                          />
                          <FcManager className="absolute top-3 left-3 w-5 h-5" />
                        </div>

                        {errors.user && touched.user && (
                          <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                            {errors.user}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="input-chil">
                      {" "}
                      {!params.userID && (
                        <div className="w-full flex flex-col ">
                          <label className="font-semibold text-[#002244]">Markup</label>
                          <div className="relative w-full">
                            <Select
                              className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                              name="allowed_markup"
                              value={
                                values.allowed_markup.some((item) => item === "All")
                                  ? [{ label: "All", value: "All" }]
                                  : markupChoosen
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "allowed_markup",
                                  e.some((item) => item.value === "All") ? ["All"] : e.map((item) => item.value),
                                );
                                setmarkupChoosen(e);
                              }}
                              options={
                                values.allowed_markup.some((item) => item === "All")
                                  ? []
                                  : [{ label: "All", value: "All" }, ...markupArr]
                              }
                              isMulti
                              isDisabled={!!params.userID}
                              styles={style}
                              defaultValue={params.userID ? { label: "All", value: "All" } : Update_markup}
                              placeholder="Select Markup"
                              onBlur={handleBlur}
                            />
                            <FcComboChart className="absolute top-3 left-3 w-5 h-5" />
                          </div>

                          {errors.allowed_markup && touched.allowed_markup && (
                            <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                              {errors.allowed_markup}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="input-par">
                    <div className="input-chil">
                      <div className="w-full flex flex-col ">
                        <label className="font-semibold text-[#002244]">City</label>
                        <div className="relative w-full">
                          <Select
                            className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                            name="allowed_city"
                            value={
                              values.allowed_city.some((item) => item === "All")
                                ? [{ label: "All", value: "All" }]
                                : cityChoosen
                              // if select all , previous will be hidden expected All
                            }
                            onChange={(e) => {
                              setFieldValue("allowed_hotels", []);
                              sethotelsChoosen(null);
                              setFieldValue(
                                "allowed_city",
                                e.some((item) => item.value === "All") ? ["All"] : e.map((item) => item.value),
                              );
                              setcityChoosen(e);

                              // get hotels by cities
                              setHotelsLoading(true);
                              dispatch(
                                getData(
                                  e.some((item) => item.value === "All")
                                    ? `/hotels/api/v1/hotel-filter/?cities=${JSON.stringify(city.map((item) => item.id))}`
                                    : `/hotels/api/v1/hotel-filter/?cities=${JSON.stringify(e.map((item) => item.value))}`,
                                  (resp) => {
                                    if (resp.status === 200) {
                                      setHotelsLoading(false);
                                      setHotels(resp.data);
                                    }
                                  },
                                  (err) => {
                                    setHotelsLoading(false);
                                  },
                                ),
                              );
                            }}
                            options={
                              values.allowed_city.some((item) => item === "All")
                                ? []
                                : [{ label: "All", value: "All" }, ...cityArr]
                            }
                            isMulti
                            styles={style}
                            defaultValue={Update_city}
                            placeholder="Select city"
                            onBlur={handleBlur}
                          />
                          <FcDam className="absolute top-3 left-3 w-5 h-5" />
                        </div>

                        {errors.allowed_city && touched.allowed_city && (
                          <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                            {errors.allowed_city}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="input-chil">
                      {hotelsLoading ? (
                        <div className="w-full flex justify-center">
                          <ButtonLoading /> <TopBarProgress />
                        </div>
                      ) : (
                        <div className="w-full flex flex-col ">
                          <label className="font-semibold text-[#002244]">Hotel</label>
                          <div className="relative w-full">
                            <Select
                              className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                              name="allowed_hotels"
                              value={
                                values.allowed_city.length === 0
                                  ? []
                                  : values.allowed_hotels.some((item) => item === "All")
                                    ? [{ label: "All", value: "All" }]
                                    : hotelsChoosen
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "allowed_hotels",
                                  e.some((item) => item.value === "All") ? ["All"] : e.map((item) => item.value),
                                );
                                sethotelsChoosen(e);
                              }}
                              options={
                                hotels.length === 0
                                  ? []
                                  : values.allowed_hotels.some((item) => item === "All")
                                    ? []
                                    : [{ label: "All", value: "All" }, ...hotelsArr]
                              }
                              isMulti
                              styles={style}
                              defaultValue={Update_hotels}
                              placeholder={`${hotels.length > 0 ? "Select Hotel" : "Please select city "}`}
                              onBlur={handleBlur}
                            />
                            <FcDepartment className="absolute top-3 left-3 w-5 h-5" />
                          </div>

                          {errors.allowed_hotels && touched.allowed_hotels && (
                            <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                              {errors.allowed_hotels}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full flex flex-col ">
                    <label className="font-semibold text-[#002244]">Nationality</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="allowed_contoury"
                        value={
                          values.allowed_contoury.some((item) => item === "All")
                            ? [{ label: "All", value: "All" }]
                            : contouryChoosen
                          // if select all , previous will be hidden expected All
                        }
                        onChange={(e) => {
                          setFieldValue(
                            "allowed_contoury",
                            e.some((item) => item.value === "All") ? ["All"] : e.map((item) => item.value),
                          );
                          setcontouryChoosen(e);
                        }}
                        options={
                          values.allowed_contoury.some((item) => item === "All")
                            ? []
                            : [{ label: "All", value: "All" }, ...countryArr]
                        }
                        isMulti
                        styles={style}
                        defaultValue={Update_contoury}
                        placeholder="Select Nationality"
                        onBlur={handleBlur}
                        formatOptionLabel={(flagData) => (
                          <div className="flex justify-start items-center  gap-x-3 country-option  ">
                            <img src={flagData.flag} alt="country-image" className="w-5 h-3" />
                            <span>{flagData.label}</span>
                          </div>
                        )}
                      />
                      <FcGlobe className="absolute top-3 left-3 w-5 h-5" />
                    </div>

                    {errors.allowed_contoury && touched.allowed_contoury && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.allowed_contoury}
                      </div>
                    )}
                  </div>
                  <div className="flex justify-center mt-6">
                    <button
                      type="Submit"
                      disabled={isSubmitting}
                      className={params.id ? "  updateButton" : " addButton "}
                    >
                      {params.id ? " Update" : " Create "}

                      {isSubmitting ? (
                        <>
                          <TopBarProgress />
                          <ButtonLoading />
                        </>
                      ) : (
                        <>{params.id ? <RxUpdate className="w-5 h-5" /> : <IoAdd className="w-5 h-5" />}</>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <NotificationContainer />
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default CreateSearchPermission;
