import api_token from "../../api/UserApi";
import {
  COLLECTION_FLAG,
  DELETE_COLLECTION,
  GET_ALL_COLLECTION,
  GET_ALL_COLLECTION_FLAG,
  UPDATE_COLLECTION_FLAG,
} from "../types/types";

export const collectionAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: COLLECTION_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`booking/api/v1/collections/`, data);
      if (response.status === 201) {
        dispatch({
          type: COLLECTION_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
export const getSelectedCollectionAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/collections/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const updateCollectionAction = (token, data, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_COLLECTION_FLAG,
        payload: true,
      });
      const response = await api_token(token).put(`booking/api/v1/collections/${id}/`, data);
      if (response.status === 200) {
        // dispatch({
        //   type:GET_ALL_COLLECTION,
        //   payload:response.data
        // })
        dispatch({
          type: UPDATE_COLLECTION_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const deleteCollectionAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_COLLECTION,
        payload: true,
      });
      const response = await api_token(token).delete(`booking/api/v1/collections/${id}/`);
      if (response.status === 204) {
        dispatch({
          type: DELETE_COLLECTION,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const getAllCollectionAction = (token, page, sorting, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_COLLECTION_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(
        `booking/api/v1/collections/?ordering=${sorting}&page=${page}&page_size=100`,
      );
      if (response.status === 200) {
        dispatch({
          type: GET_ALL_COLLECTION,
          payload: response.data,
        });
        dispatch({
          type: GET_ALL_COLLECTION_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const getCollectionRoomId = (token, roomId, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/collections-by-room/${roomId}/`);
      callback(response);
    } catch (error) {}
  };
};
