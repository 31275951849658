import { GET_FEATURES } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getFeaturesSuccessfull(features) {
  return {
    type: GET_FEATURES,
    payload: features,
  };
}

export default function getFeatures() {
  return async function (dispatch) {
    const response = await api_token(token).get("/hotels/api/v1/get-features/");
    dispatch(getFeaturesSuccessfull(response.data));
  };
}
