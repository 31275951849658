import React from "react";
import { useEffect } from "react";
import { BsListUl } from "react-icons/bs";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { TbListDetails } from "react-icons/tb";
import { useState } from "react";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { destinationAction, getHotelCityAction } from "../../../redux/actions/searchformAction";
import { getSelectedHotelBankNumber } from "../../../redux/actions/hotelBankDetails";
import TopBarProgress from "react-topbar-progress-indicator";
import { getDepartmentContact } from "../../../redux/actions/hotelContact";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { MdOutlineContactPhone } from "react-icons/md";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const FrontDeskContact = () => {
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const [city, setCity] = useState();
  const [hotelBankData, setHotelBankData] = useState();
  const [receptionContact, setreceptionContact] = useState();
  const [load, setLoad] = useState(false);
  const [loadSelect, setLoadSelect] = useState(false);
  const { destinationData } = useSelector((state) => state.SearchFormReducer);

  let destination = destinationData
    ? destinationData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];
  let hotels = hotelBankData
    ? hotelBankData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  useEffect(() => {
    dispatch(destinationAction(token));
  }, []);

  /////permission/////////
  const permissionData = {
    permission_codes: ["hotels_management.view_hotelcontact"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////

  return (
    <div className="formPage-wrap">
      {destinationData.length > 0 && permissionFlag ? (
        permission.map((item) => {
          if (item.name === "hotels_management.view_hotelcontact" && item.value === true) {
            return (
              <div className="w-full   min-h-screen">
                <div className="w-full flex justify-center ">
                  <p className="header-h2 ">
                    <BsListUl className="w-6 h-6 mt-1" /> Front Desk Contact{" "}
                  </p>
                </div>
                <form className="form-wrap">
                  <div className={`input-par `}>
                    <div className="input-chil">
                      <label className=" flex text-black font-semibold  text-lg ">Please Choose City</label>
                      <Select
                        placeholder="City Name"
                        name="bank_name"
                        type="text"
                        isSearchable
                        className=" w-full  h-10  rounded-lg  bg-white  text-black z-50"
                        required
                        options={destination}
                        onChange={(e) => {
                          setCity(e.label);
                          setLoadSelect(true);
                          dispatch(
                            getHotelCityAction(token, e.value, (result) => {
                              if (result.status === 200) {
                                setHotelBankData(result.data);
                                setLoadSelect(false);
                              }
                            }),
                          );
                        }}
                      />
                    </div>

                    <div className="input-chil">
                      <label className=" flex text-black font-semibold  text-lg  ">Select Hotel Name </label>
                      {loadSelect ? (
                        <ButtonLoading />
                      ) : (
                        <Select
                          placeholder="Hotel Name"
                          name="bank_name"
                          type="text"
                          isSearchable
                          className=" w-full  h-10  rounded-lg  bg-white  text-black z-50"
                          required
                          options={hotels}
                          onChange={(e) => {
                            setLoad(true);
                            dispatch(
                              getDepartmentContact(e.value, "reception", (result) => {
                                if (result.status === 200) {
                                  setreceptionContact(result.data.results);
                                  setLoad(false);
                                }
                              }),
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </form>
                {load ? (
                  <div className="flex items-center justify-center h-full w-full">
                    <TopBarProgress /> <Loading />
                  </div>
                ) : receptionContact ? (
                  receptionContact.length > 0 ? (
                    receptionContact.map((item) => {
                      return (
                        <div className="container mx-auto  border-gray-300 border rounded-lg  p-10  ">
                          <div className="flex flex-col justify-center items-center  ">
                            <div className=" flex items-center justify-center w-full bg-[#001a35]  rounded-lg text-xl text-white h-16">
                              <p className="flex items-center gap-x-3">
                                <TbListDetails className="w-5 h-5" />
                                Hotel Contact Details{" "}
                              </p>
                            </div>
                            {/* Hotel id & id & persons*/}
                            <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Department </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.depertment}</p>
                                </div>
                              </div>
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Contact Name </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.contact_name}</p>
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full justify-between items-center  p-3  ">
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Title </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.title}</p>
                                </div>
                              </div>

                              <div className="flex items-center w-[50%]">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Telephone Number </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800 flex items-center gap-x-4">
                                  {Object.values(item.telephone_number).map((info, index) => {
                                    return (
                                      <p className="flex items-center gap-x-1">
                                        <span>({index + 1})</span>
                                        {info}
                                      </p>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Email </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  {Object.values(item.email).map((info, index) => {
                                    return <p className="flex items-center gap-x-3">{info}</p>;
                                  })}
                                </div>
                              </div>

                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Working Days</p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800 flex items-center gap-x-3">
                                  {Object.values(item.working_days).map((info, index) => {
                                    return <p className="flex items-center gap-x-3">{info}</p>;
                                  })}
                                </div>
                              </div>
                            </div>
                            {/* Client & phone Number*/}
                            <div className="flex w-full justify-between items-center  p-3 ">
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold text-sm">
                                    Working Hours From / To{" "}
                                  </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">
                                    {item.working_hours.from} / {item.working_hours.to}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex items-center justify-center h-full w-full">No Hotel Bank Contact Details</div>
                  )
                ) : (
                  <div className="w-full h-[70%] flex flex-col items-center justify-center  ">
                    <MdOutlineContactPhone className="w-48 h-48 text-gray-400 opacity-40" />
                    <p className="font-semibold text-2xl text-gray-800 opacity-50">No Contacts</p>{" "}
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div className="w-full h-screen">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default FrontDeskContact;
