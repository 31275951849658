import React, { useState } from "react";
import useData from "../../../customHooks/useData";
import Loading from "../Loading/Loading";
import { sendData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

function reshapeText(text) {
  let firstReshape = text?.replace(/_/g, " ");
  let secondReshape = firstReshape[0]?.toUpperCase() + firstReshape?.slice(1);
  return secondReshape;
}

function PermissionsGroup({ group, title, chosenPermissions, handleCheck, setChosenPermissions }) {
  function selectAll(e) {
    e.preventDefault();
    let allIds = new Set();

    Object.entries(group)?.map(([arrayName, arrayData], index) =>
      arrayData?.forEach((perm) => {
        if (!perm.tablename) {
          if (!chosenPermissions?.includes(perm.id)) {
            allIds.add(perm.id);
          }
          setChosenPermissions([...chosenPermissions, ...allIds]);
        }
      }),
    );
  }

  const selectRow = (arrayData, e) => {
    e.preventDefault();

    let allIds = new Set();

    arrayData?.forEach((perm, index) => {
      if (!perm.tablename) {
        if (!chosenPermissions?.includes(perm.id)) {
          allIds.add(perm.id);
        }
        setChosenPermissions([...chosenPermissions, ...allIds]);
      }
    });
  };

  return (
    <div className="mt-5 formPage-wrap">
      <div className=" w-full flex justify-center mb-5">
        <div className="w-full  flex  gap-5  items-center justify-between ">
          <div className=" font-bold md:text-lg text-base capitalize">{reshapeText(title)} </div>
          <button
            className="px-5 py-2  rounded-xl bg-sky-900 text-white font-semibold text-sm transition duration-300 ease-in-out 
          hover:bg-sky-700"
            onClick={(e) => selectAll(e)}
          >
            Select All
          </button>
        </div>
      </div>

      {title !== "permissions_management" ? (
        <div className="flex justify-center w-full mb-20">
          <div className="overflow-x-auto w-full  shadow-lg">
            <table className="w-full  whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden">
              <thead className="bg-gray-900">
                <tr className="text-white text-left">
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Name</th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Add</th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Edit</th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> delete</th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> view</th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> select row</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {Object.entries(group)?.map(([arrayName, arrayData], index) => (
                  <tr key={index}>
                    {arrayData?.map((item, i) => (
                      <React.Fragment key={item.id}>
                        {i === 0 ? (
                          <td className="px-5 py-4 w-[50%] capitalize "> {item.tablename} </td>
                        ) : (
                          <td className="px-4 py-4 text-start">
                            <div className=" w-30 text-start  relative  ">
                              <input
                                type="checkbox"
                                name={item.name}
                                onChange={(e) => handleCheck(e, item.id)}
                                checked={chosenPermissions?.includes(item.id) || false}
                                className="mr-2 w-full "
                              />
                            </div>
                          </td>
                        )}
                      </React.Fragment>
                    ))}
                    <td className="px-2 py-2  text-center">
                      <button
                        className="px-2 py-2  rounded-lg bg-green-700 text-white font-semibold text-sm transition duration-300 ease-in-out 
                            hover:bg-green-600"
                        onClick={(e) => selectRow(arrayData, e)}
                      >
                        Select row
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        Object.entries(group)?.map(([arrayName, arrayData], index) =>
          arrayName !== "searchpermission" ? (
            <div className="flex justify-center w-full mb-20" key={index}>
              <div className="overflow-x-auto w-full  shadow-lg">
                <table className="w-full  whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden">
                  <thead className=" bg-cyan-800">
                    <tr className="text-white text-left">
                      <th className="font-semibold text-sm capitalize px-6 py-4 text-center"> Global Permission</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {Object.entries(group)?.map(
                      ([arrayName, arrayData], index) =>
                        arrayName !== "searchpermission" && (
                          <React.Fragment key={index}>
                            <tr className="">
                              <td className="px-5 py-2 w-[100%] flex justify-between capitalize font-bold">
                                {arrayData[0]?.tablename}
                                <button
                                  className="px-2 py-2 rounded-lg bg-green-700 text-white font-semibold text-sm transition duration-300 ease-in-out hover:bg-green-600"
                                  onClick={(e) => selectRow(arrayData, e)}
                                >
                                  Select All
                                </button>
                              </td>
                            </tr>
                            <div className="w-full grid xl:grid-cols-2 lg:grid-cols-1 grid-cols-1">
                              {arrayData.map(
                                (item, i) =>
                                  i !== 0 && (
                                    <tr key={item.id}>
                                      <td className="grid xl:grid-cols-2 lg:grid-cols-1 grid-cols-1 w-full px-4 py-2">
                                        <div className="flex gap-2 items-center">
                                          <input
                                            type="checkbox"
                                            name={item.name}
                                            onChange={(e) => handleCheck(e, item.id)}
                                            checked={chosenPermissions?.includes(item.id) || false}
                                            className="mr-2 "
                                          />
                                          <p className="capitalize">{item.name}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  ),
                              )}
                            </div>
                          </React.Fragment>
                        ),
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex justify-center w-full mb-10" key={index}>
              <div className="overflow-x-auto w-full  shadow-lg">
                <table className="w-full  whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden">
                  <thead className="bg-cyan-800">
                    <tr className="text-white text-left">
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Name</th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Add</th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Edit</th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> delete</th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> view</th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> select row</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {Object.entries(group)?.map(
                      ([arrayName, arrayData], index) =>
                        arrayName === "searchpermission" && (
                          <tr key={index}>
                            {arrayData?.map((item, i) => (
                              <React.Fragment key={item.id}>
                                {i === 0 ? (
                                  <td className="px-5 py-4 w-[50%] capitalize "> {item.tablename} </td>
                                ) : (
                                  <td className="px-4 py-4 text-start">
                                    <div className=" w-30 text-start  relative  ">
                                      <input
                                        type="checkbox"
                                        name={item.name}
                                        onChange={(e) => handleCheck(e, item.id)}
                                        checked={chosenPermissions?.includes(item.id) || false}
                                        className="mr-2 w-full "
                                      />
                                    </div>
                                  </td>
                                )}
                              </React.Fragment>
                            ))}
                            <td className="px-2 py-2  text-center">
                              <button
                                className="px-2 py-2  rounded-lg bg-green-700 text-white font-semibold text-sm transition duration-300 ease-in-out 
                              hover:bg-green-600"
                                onClick={(e) => selectRow(arrayData, e)}
                              >
                                Select row
                              </button>
                            </td>
                          </tr>
                        ),
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ),
        )
      )}
    </div>
  );
}

export default function AddGroupPermissions() {
  const dispatch = useDispatch();
  const permissions = useData("permissions/api/v1/permissions/models");
  const [chosenPermissions, setChosenPermissions] = useState([]);
  const permissionsKeys = permissions ? Object.keys(permissions) : [];

  function handleCheck(e, id) {
    if (e.target.checked) {
      setChosenPermissions([...chosenPermissions, id]);
    } else {
      setChosenPermissions(chosenPermissions.filter((i) => i !== id));
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const data = {
      name: e.target.group.value,
      permissions: chosenPermissions,
    };

    dispatch(
      sendData("/permissions/api/v1/user-groups/", data, () => toast.success("Your Group was added Successfully")),
    );
  }

  return (
    <div className="w-full md:px-6 px-2 my-8">
      {!permissions && <Loading />}
      {permissions && (
        <form action="post" onSubmit={handleSubmit} className="py-4 p-2 ">
          <div className="flex justify-center">
            <h2 className="header-h2">Add Permissions</h2>
          </div>

          <div className="w-full flex justify-center max-w-2xl  rounded-md  mx-auto">
            <div className="form-wrap flex md:flex-row flex-col items-center">
              <label htmlFor="group" className="sm:flex-[1] w-full font-medium">
                Group Name:
              </label>
              <input type="text" name="group" className="w-full sm:flex-[3] px-3 h-10  border rounded-lg bg-white" />
            </div>
          </div>

          <div>
            {permissionsKeys
              .filter(
                (key) =>
                  ![
                    "django_celery_beat",
                    "django_celery_results",
                    "cities_light",
                    "contenttypes",
                    "sessions",
                    "admin",
                    "token_blacklist",
                    "permissions_management",
                    "globalpermission",
                  ].includes(key),
              )
              .map((key) => {
                return (
                  <PermissionsGroup
                    key={key}
                    group={permissions[key]}
                    title={key}
                    chosenPermissions={chosenPermissions}
                    setChosenPermissions={setChosenPermissions}
                    handleCheck={handleCheck}
                  />
                );
              })}
            {permissions["permissions_management"] && (
              <PermissionsGroup
                group={permissions["permissions_management"]}
                title="permissions_management"
                chosenPermissions={chosenPermissions}
                setChosenPermissions={setChosenPermissions}
                handleCheck={handleCheck}
              />
            )}
            {permissions["globalpermission"] && (
              <PermissionsGroup
                group={permissions["globalpermission"]}
                title="globalpermission"
                chosenPermissions={chosenPermissions}
                setChosenPermissions={setChosenPermissions}
                handleCheck={handleCheck}
              />
            )}
          </div>

          <div className="flex justify-center">
            <button type="submit" className="addButton">
              Create Group
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
