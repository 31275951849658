import React, { useRef } from "react";
import VoucherImage from "../VoucherImage/VoucherImage";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { FieldErrorText, FormLabel, FormSection, FormTextInput } from "../../../customComponents/FormComponents";
import Select from "react-select";
import DeleteButton from "../../../customComponents/DeleteButton";
import { Button } from "flowbite-react";
import { MdOutlineEmail } from "react-icons/md";
import * as Yup from "yup";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { base64ToFile } from "../../../utils/utils";
import usePermissions from "../../../customHooks/usePermissions";
import Errorfetch from "../Errorfetch/Errorfetch";
import NoPermission from "../NoPermission/NoPermission";

const VoucherEmailForm = () => {
  const { voucherRandomId, id } = useParams();
  const emailBodyRef = useRef();
  const emailAttachmentRef = useRef();

  const [permissions, permissionFlag, permissionsError] = usePermissions(["room_booking.change_evoucher"]);

  const isChangingVoucherAllowed =
    permissions?.find((permission) => permission.name === "room_booking.change_evoucher")?.value === true;

  const {
    data: voucher,
    isLoading: isGettingVoucher,
    isError: isGettingVoucherError,
  } = useQuery({
    queryKey: ["voucher", voucherRandomId],
    queryFn: () => {
      return fetchDataQuery(`booking/api/v1/e-voucher-random-id/${voucherRandomId}/`);
    },
    enabled: isChangingVoucherAllowed,
  });

  const {
    data: bookingEmailData,
    isLoading: isGettingBookingEmailData,
    isError: isGettingBookingEmailError,
  } = useQuery({
    queryKey: ["booking-emails", id],
    queryFn: () => {
      return fetchDataQuery(`booking/api/v1/e-voucher-email-data/${id}/`);
    },
    enabled: isChangingVoucherAllowed,
  });

  const { isPending: isSendingEmail, mutate: sendEmail } = useMutation({
    mutationKey: ["send-email"],
    mutationFn: (emailData) => {
      return mutateDataQuery("/booking/api/v1/e-voucher-send-email/", "post", emailData, {
        "Content-Type": "multipart/form-data",
      });
    },
    onSuccess: () => {
      toast.success("Email sent successfully");
    },
  });

  const { reservation_email: reservationEmails, reception_email: receptionEmails } = bookingEmailData || [];

  function handleSendEmail(values) {
    const recipientEmails = [...values.reservationEmails, ...values.receptionEmails, ...values.customEmails];

    if (recipientEmails.length === 0) {
      toast.error("Please add at least one email");
      return;
    }

    const htmlImageSrc = emailAttachmentRef.current.querySelector("img").src;

    const imageFile = base64ToFile(htmlImageSrc, "image.png");

    const emailBody = emailBodyRef.current;

    const message = `${emailBody.innerHTML}`;

    const formData = new FormData();

    const emailSubject = voucher?.hotel_confirmation_number
      ? `Voucher of confirmation number ${voucher?.hotel_confirmation_number}`
      : `Voucher of ${voucher?.guest_name[0]}`;

    formData.append("subject", emailSubject);
    formData.append("message", message);
    formData.append("recipient_list", JSON.stringify(recipientEmails));
    formData.append("attachment", imageFile);

    sendEmail(formData);
  }

  if (isGettingVoucher || isGettingBookingEmailData || !permissionFlag) {
    return <Loading />;
  }

  if (isGettingVoucherError || permissionsError || isGettingBookingEmailError) {
    return <Errorfetch Error={`Error Fetching data`} />;
  }

  return isChangingVoucherAllowed ? (
    <div>
      <Formik
        onSubmit={handleSendEmail}
        validationSchema={Yup.object().shape({
          customEmails: Yup.array().of(Yup.string().email("Invalid email address").required("Email cannot be empty")),
        })}
        initialValues={{
          allReservationEmails: false,
          allReceptionEmails: false,
          reservationEmails: [],
          receptionEmails: [],
          customEmails: [],
        }}
      >
        {({ setFieldValue, values, errors }) => {
          return (
            <Form>
              <FormSection title={"Voucher Email"}>
                <>
                  <FormLabel label={"Email Body"} name={"emailBody"} />
                  <div
                    ref={emailBodyRef}
                    style={{
                      padding: "10px",
                      border: "1px solid #465695",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        lineHeight: "1.5",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      Dear Reception Representative,
                      <br />
                      Warm Greetings from Nugget Tours.
                      <br />
                      <br />
                      Kindly find the attached voucher of {voucher?.guest_name[0]} <br />
                      {voucher?.hotel_confirmation_number && `Confirmation No : ${voucher?.hotel_confirmation_number} `}
                      <br />
                      <br />
                      Please accept it as an original and kindly confirm me back when you receive it.
                      <br />
                      <br />
                      Thanks & Best Regards,
                      <br />
                      <br />
                      {voucher?.issued_by?.first_name + " " + voucher?.issued_by?.last_name}
                      <br />
                      Reservations Department.
                      <br />
                      Nugget Tours.
                      <br />
                      01111188796 / 01005373070
                      <br />
                      reservation1@nuggettours.com
                      <br />
                      Masbero Mall - Abdelmonem Riyadh Square - downtown Cairo / Egypt.
                    </p>
                  </div>
                </>
                <>
                  <FormLabel label={"Reservation Emails"} name={"reservationEmails"} />
                  <Select
                    styles={{
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        marginTop: "0 !important",
                      }),
                    }}
                    value={
                      values.allReservationEmails
                        ? [{ label: "All Reservation Emails", value: "all" }]
                        : values.reservationEmails.map((email) => ({
                            value: email,
                            label: email,
                          }))
                    }
                    isMulti
                    onChange={(value, actionMeta) => {
                      const { action, option, removedValue } = actionMeta;

                      switch (action) {
                        case "select-option": {
                          const value = option.value;
                          if (value === "all") {
                            setFieldValue("reservationEmails", Object.values(reservationEmails?.at(0)));
                            setFieldValue("allReservationEmails", true);
                          } else {
                            setFieldValue("reservationEmails", [...values.reservationEmails, value]);
                          }
                          break;
                        }

                        case "remove-value":
                        case "pop-value": {
                          const value = removedValue.value;
                          if (value === "all") {
                            setFieldValue("reservationEmails", []);
                            setFieldValue("allReservationEmails", false);
                          } else {
                            setFieldValue(
                              "reservationEmails",
                              values.reservationEmails.filter((email) => email !== value),
                            );
                          }
                          break;
                        }

                        case "clear": {
                          setFieldValue("allReservationEmails", false);
                          setFieldValue("reservationEmails", []);
                          break;
                        }

                        default:
                          break;
                      }
                    }}
                    options={
                      values.allReservationEmails
                        ? [{ label: "All Reservation Emails", value: "all" }]
                        : [
                            { value: "all", label: "All Reservation Emails" },
                            ...Object.values(reservationEmails?.at(0))?.map((email) => ({
                              value: email,
                              label: email,
                            })),
                          ]
                    }
                  />
                </>
                <>
                  <FormLabel label={"Reception Emails"} name={"receptionEmails"} />
                  <Select
                    styles={{
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        marginTop: "0 !important",
                      }),
                    }}
                    value={
                      values.allReceptionEmails
                        ? [{ label: "All Reception Emails", value: "all" }]
                        : values.receptionEmails.map((email) => ({
                            value: email,
                            label: email,
                          }))
                    }
                    isMulti
                    onChange={(value, actionMeta) => {
                      const { action, option, removedValue } = actionMeta;

                      switch (action) {
                        case "select-option": {
                          const value = option.value;
                          if (value === "all") {
                            setFieldValue("receptionEmails", Object.values(receptionEmails?.at(0)));
                            setFieldValue("allReceptionEmails", true);
                          } else {
                            setFieldValue("receptionEmails", [...values.receptionEmails, value]);
                          }
                          break;
                        }

                        case "remove-value":
                        case "pop-value": {
                          const value = removedValue.value;
                          if (value === "all") {
                            setFieldValue("receptionEmails", []);
                            setFieldValue("allReceptionEmails", false);
                          } else {
                            setFieldValue(
                              "receptionEmails",
                              values.receptionEmails.filter((email) => email !== value),
                            );
                          }
                          break;
                        }

                        case "clear": {
                          setFieldValue("allReceptionEmails", false);
                          setFieldValue("receptionEmails", []);
                          break;
                        }

                        default:
                          break;
                      }
                    }}
                    options={
                      values.allReceptionEmails
                        ? [{ label: "All Reception Emails", value: "all" }]
                        : [
                            { value: "all", label: "All Reception Emails" },
                            ...Object.values(receptionEmails?.at(0))?.map((email) => ({
                              value: email,
                              label: email,
                            })),
                          ]
                    }
                  />
                </>
                <>
                  <FormLabel label={"Custom Emails"} name={"customEmails"} />
                  <FieldArray name={"customEmails"}>
                    {({ push, remove }) => (
                      <div className="flex flex-col items-center space-y-4">
                        {values.customEmails.length > 0 ? (
                          values.customEmails.map((email, index) => {
                            return (
                              <div key={index} className=" flex items-center w-full justify-between gap-4">
                                <div className="w-full">
                                  <FormTextInput
                                    name={`customEmails[${index}]`}
                                    id={`customEmails[${index}]`}
                                    Icon={MdOutlineEmail}
                                    placeholder="Enter email"
                                  />
                                  <ErrorMessage name={`customEmails[${index}]`} component={FieldErrorText} />
                                </div>
                                <DeleteButton onDelete={() => remove(index)} />
                              </div>
                            );
                          })
                        ) : (
                          <p className="font-medium text-center"> No Custom Emails Added </p>
                        )}
                        <Button className={"mx-auto"} type={"button"} onClick={() => push("")}>
                          Add Custom Email
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </>
                <>
                  <FormLabel label={"Email Attachment"} name={"emailAttachment"} />
                  <VoucherImage ref={emailAttachmentRef} />
                  <Button
                    isProcessing={isSendingEmail}
                    disabled={isSendingEmail}
                    type={"submit"}
                    className="mx-auto"
                    color={"success"}
                  >
                    Send Email
                  </Button>
                </>
              </FormSection>
            </Form>
          );
        }}
      </Formik>
    </div>
  ) : (
    <NoPermission />
  );
};

export default VoucherEmailForm;
