import * as Yup from "yup";
export const ChequesValidations = Yup.object({
  bank: Yup.string().required("Please select bank name"),
  bank_account_number: Yup.number().required("   Account Number field required"),
  cheque_number: Yup.number().required("Cheque Number required"),
  to: Yup.string().required("Destination  required"),
  // hotel: Yup.string().required("Destination Hotel  required"),
  // other: Yup.string().required("Other Destination   required"),
  issued_date: Yup.date().required("Issued Date    required"),
  due_date: Yup.date().required("Due Date    required"),
  amount: Yup.number().required("amount   required"),
  purpose: Yup.string().required("purpose   required"),
  details: Yup.string().required("details   required"),
  // cheque_status: Yup.string().required("cheque status   required"),
  delivered_to: Yup.string().required("delivered to  required"),
  date_time_deliver: Yup.date().required("Date Time Deliver  required"),
});
