import React, { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { clearCancellationPolicyArchive, getCancellationPolicyArchive } from "../../../redux/actions/cancellation";
import { FiArchive } from "react-icons/fi";
import { MdOutlineCancel, MdOutlineDateRange, MdOutlineLocalOffer } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { BsLightningCharge } from "react-icons/bs";
import { GoPerson } from "react-icons/go";

export default function CancellationPolicyArchive() {
  const dispatch = useDispatch();
  const cancellationPolicies = useSelector((state) => state.cancellationPolicyArchive);

  useEffect(() => {
    dispatch(getCancellationPolicyArchive());

    return () => {
      dispatch(clearCancellationPolicyArchive());
    };
  }, []);
  const columns = [
    {
      field: "id",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
    },
    {
      field: "from",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineDateRange className="w-5 h-5  text-yellow-500" />
          FROM
        </div>
      ),
      flex: 1,
    },
    {
      field: "to",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineDateRange className="w-5 h-5  text-yellow-500" />
          TO
        </div>
      ),
      flex: 1,
    },
    {
      field: "offerType",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineLocalOffer className="w-5 h-5  text-yellow-500" />
          OFFER TYPE
        </div>
      ),
      flex: 1,
    },
    {
      field: "cancellationBefore",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineCancel className="w-5 h-5  text-yellow-500" />
          CANCELLATION BEFORE
        </div>
      ),
      flex: 1,
    },
    {
      field: "charge",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <BsLightningCharge className="w-5 h-5  text-yellow-500" />
          CHARGE
        </div>
      ),
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineDateRange className="w-5 h-5  text-yellow-500" />
          CREATION DATE
        </div>
      ),
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <GoPerson className="w-5 h-5  text-yellow-500" />
          CREATED BY
        </div>
      ),
      flex: 1,
    },
  ];
  const rows = cancellationPolicies.map((policy) => {
    return {
      id: policy.id,
      from: policy.cancellation_valid_from.split("T")[0],
      to: policy.cancellation_valid_to.split("T")[0],
      offerType: policy.offer_type,
      cancellationBefore: policy.cancellation_before,
      charge: policy.charge,
      creationDate: policy.created_at,
      createdBy: "",
    };
  });
  return (
    <div className="h-screen">
      <div className="w-full flex flex-col md:flex-row gap-x-5 gap-y-2 items-center justify-start pt-5 px-5 ">
        <p className="flex items-center gap-x-1  text-3xl font-normal text-[#001a35] ">
          {" "}
          <FiArchive className="w-5 h-5 text-yellow-600" /> Cancellation Policy Archive
        </p>
      </div>
      <DataGrid
        className="data-grid"
        columns={columns}
        rowCount={rows.length}
        rows={rows}
        slots={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
