import { GET_FEATURES } from "../types/types";

const defaultState = [];

export default function formFeaturesReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_FEATURES:
      return action.payload;
    default:
      return state;
  }
}
