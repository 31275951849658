import React from "react";
import { useNavigate } from "react-router-dom";
import { RiKeyboardLine } from "react-icons/ri";
import { CiViewList } from "react-icons/ci";
import { GiExpense } from "react-icons/gi";
import { IoIosList } from "react-icons/io";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { HiOutlineCollection } from "react-icons/hi";

const Expenses = () => {
  const navigate = useNavigate();
  return (
    <div className="formPage-wrap">
      <div className="w-full ">
        <div className="w-full flex justify-center  ">
          <h2 className="header-h2">
            {" "}
            <RiKeyboardLine className="w-5 h-5" />
            Expenses
          </h2>
        </div>

        <div className="w-full grid grid-cols-2 border mb-4  gap-y-5 form-wrap max-sm:grid-cols-1">
          <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
            <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
              <div className="w-full flex justify-center items-center ">
                <CiViewList className="w-12 h-12   " />
              </div>
            </button>
            <p className="text-lg font-semibold">Payment Order List </p>
          </div>

          <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
              <div className="w-full flex justify-center items-center ">
                <GiExpense className="w-12 h-12   " />
              </div>
            </button>
            <p className="text-lg font-semibold">Other Expense Order</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
