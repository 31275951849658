import api_token from "../../api/UserApi";
import { ADD_TRANSFER_AMOUNT_FLAG, ADD_WALLET_FLAG, ALL_WALLET_FLAG } from "../types/types";
import { NotificationContainer, NotificationManager } from "react-notifications";

export const addNewWalletBankTransferAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_WALLET_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`ewallet/api/v1/wallets/create_wallet_with_bank_transfer/`, data);

      if (response.status === 201) {
        dispatch({
          type: ADD_WALLET_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      dispatch({
        type: ADD_WALLET_FLAG,
        payload: false,
      });
    }
  };
};

export const addNewCashWalletAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_WALLET_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`ewallet/api/v1/wallets/create_wallet_with_cash_payment/`, data);

      if (response.status === 201) {
        dispatch({
          type: ADD_WALLET_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      dispatch({
        type: ADD_WALLET_FLAG,
        payload: false,
      });
    }
  };
};
export const addJumboWalletAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_WALLET_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`ewallet/api/v1/wallets/create_wallet_with_payment/`, data);

      if (response.status === 201) {
        dispatch({
          type: ADD_WALLET_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      dispatch({
        type: ADD_WALLET_FLAG,
        payload: false,
      });
      NotificationManager.error("Jumbo Invoice Number Has Already Been Added Before");
    }
  };
};

export const addPosVisaWalletAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_WALLET_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(
        `ewallet/api/v1/wallets/create_wallet_with_pos_machine_transaction/`,
        data,
      );

      if (response.status === 201) {
        dispatch({
          type: ADD_WALLET_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      dispatch({
        type: ADD_WALLET_FLAG,
        payload: false,
      });
    }
  };
};

export const getAllWalletAction = (token, page, sorting, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(
        `ewallet/api/v1/wallets/?page_size=10&page=${page}&ordering=${sorting}`,
      );
      callback(response);
    } catch (error) {}
  };
};

export const getSelectedWallet = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`ewallet/api/v1/wallets/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const getAllWalletTransferAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`ewallet/api/v1/wallets/?page_size=100000`);
      callback(response);
    } catch (error) {}
  };
};

export const postTransferAmount = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_TRANSFER_AMOUNT_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`ewallet/api/v1/transfers/`, data);
      if (response.status === 200) {
        dispatch({
          type: ADD_TRANSFER_AMOUNT_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      NotificationManager.error("Insufficient Amount In The Source Wallet");
      dispatch({
        type: ADD_TRANSFER_AMOUNT_FLAG,
        payload: false,
      });
    }
  };
};
