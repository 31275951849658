import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { getSelectedMethodPayment } from "../../../redux/actions/hotelPayments";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { GiMoneyStack } from "react-icons/gi";
import { GoPerson } from "react-icons/go";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsCashCoin, BsCurrencyExchange } from "react-icons/bs";
import { LiaCalendar, LiaHotelSolid } from "react-icons/lia";
import { CgNotes } from "react-icons/cg";
import { ImAttachment } from "react-icons/im";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import ListFilters from "../../../utils/ListFilters";

const HotelPaymentOrdersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [load, setload] = useState(false);
  const [payments, setPayments] = useState();
  const [hotels, setHotels] = useState();
  const token = cookie.load("access_token");
  useEffect(() => {
    dispatch(
      getSelectedMethodPayment(params.title, (result) => {
        if (result.status === 200) {
          dispatch(
            requestHotelNames((result1) => {
              if (result.status === 200) {
                setload(true);
                setPayments(result.data);
                setHotels(result1.data);
              }
            }),
          );
        }
      }),
    );
  }, []);

  const rows =
    payments &&
    payments.map((item) => {
      return {
        id: item.id,
        hotel: item.hotel,
        amount: item.amount,
        currency_type: item.currency_type,
        method_payment: item.method_payment,
        attachment: item.attachment,
        method_payment_detail: item.method_payment_detail?.bank_account || item.method_payment_detail?.payment_link,
        last_date_for_payment: item.last_date_for_payment.slice(0, 10),
        last_time_for_payment: item.last_date_for_payment.slice(11, 19),
        created_date: item.created_date.slice(0, 10),
        created_time: item.created_date.slice(11, 19),
        notes: item.notes,
        creator: item.creator,
      };
    });

  const columns = [
    {
      key: "hotel",
      title: (
        <div className="flex items-center gap-x-1 ml-5 text-[10px]">
          <LiaHotelSolid className="w-4 h-4  text-yellow-500" />
          HOTELS
        </div>
      ),

      dataIndex: "hotel",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center">
            {hotels.map((item) => {
              if (item.id === params.hotel) {
                return item.name;
              }
            })}
          </Link>
        );
      },
    },

    {
      key: "amount",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <GiMoneyStack className="w-4 h-4  text-yellow-500" />
          AMOUNT
        </div>
      ),
      dataIndex: "amount",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            {Number(params.amount)}
          </Link>
        );
      },
    },

    {
      key: "currency_type",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <BsCurrencyExchange className="w-4 h-4  text-yellow-500" />
          CURRENCY
        </div>
      ),

      dataIndex: "currency_type",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            {params.currency_type}
          </Link>
        );
      },
    },

    {
      key: "method_payment",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <BsCashCoin className="w-4 h-4  text-yellow-500" />
          METHOD PAYMENT
        </div>
      ),
      dataIndex: "method_payment",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            {params.method_payment}
          </Link>
        );
      },
    },

    {
      key: "attachment",
      title: (
        <div className="flex items-center gap-x-1 text-[10px] ">
          <ImAttachment className="w-4 h-4  text-yellow-500" />
          ATTACHMENT
        </div>
      ),
      dataIndex: "attachment",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            <img src={params.attachment} className="w-10 h-10" />
          </Link>
        );
      },
    },

    {
      key: "method_payment_detail",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <AiOutlineInfoCircle className="w-4 h-4  text-yellow-500" />
          PAYMENT DETAILS
        </div>
      ),
      dataIndex: "method_payment_detail",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            {params.method_payment_detail}
          </Link>
        );
      },
    },
    {
      key: "last_date_for_payment",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <LiaCalendar className="w-4 h-4  text-yellow-500" />
          LAST PAYMENT DATE
        </div>
      ),
      dataIndex: "last_date_for_payment",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            {params.last_date_for_payment} / {params.last_time_for_payment}
          </Link>
        );
      },
    },

    {
      key: "created_date",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <LiaCalendar className="w-4 h-4  text-yellow-500" />
          CREATED DATE
        </div>
      ),
      dataIndex: "created_date",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            {params.created_date}
          </Link>
        );
      },
    },
    {
      key: "created_time",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <LiaCalendar className="w-4 h-4  text-yellow-500" />
          CREATED TIME
        </div>
      ),
      dataIndex: "created_time",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            {params.created_time}
          </Link>
        );
      },
    },
    {
      key: "notes",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <CgNotes className="w-4 h-4  text-yellow-500" />
          NOTES
        </div>
      ),
      dataIndex: "notes",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center">
            {params.notes}
          </Link>
        );
      },
    },
    {
      key: "creator",
      title: (
        <div className="flex items-center gap-x-1 text-[10px]">
          <GoPerson className="w-4 h-4  text-yellow-500" />
          CREATOR
        </div>
      ),
      dataIndex: "creator",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`./details/${params.id}`} className="w-full h-full flex items-center justify-center ">
            {params.creator}
          </Link>
        );
      },
    },
  ];

  return (
    <div className="w-full formPage-wrap">
      {load ? (
        <div className="w-full overflow-auto">
          <ListFilters columns={columns} rows={rows} title="Hotel Payment order List" />
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <TopBarProgress /> <Loading />
        </div>
      )}
    </div>
  );
};

export default HotelPaymentOrdersList;
