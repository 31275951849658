import React, { useEffect, useState } from "react";
import { FaWpforms } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { useLocation, useNavigate, useParams } from "react-router";
import { getRefundByIdAction, updateRefundAction } from "../../../redux/actions/refundAction";
import lightToast from "light-toast";
import { RxUpdate } from "react-icons/rx";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const UpdateRefund = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { getRefundId } = useSelector((state) => state.refundReducer);
  const { updateRefundFlag } = useSelector((state) => state.refundReducer);
  const [nights, setNights] = useState(
    location.state && location.state.nights !== undefined ? location.state.nights : "",
  );

  useEffect(() => {
    dispatch(
      getRefundByIdAction(token, params.id, (result) => {
        setNights(result.refund_nights);
      }),
    );
  }, [location]);

  const handleUpdate = (e) => {
    e.preventDefault();
    const refundData = {
      id: params.id,
      booked_room: location.state.bookedRoom,
      refund_nights: e.target.refund_nights.value,
    };
    dispatch(
      updateRefundAction(token, refundData, params.id, (result) => {
        if (result.status === 200) {
          lightToast.success("The Refund Form was completed Updated successfully");
          navigate(-1);
        }
      }),
    );
  };
  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.change_refund"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "room_booking.change_refund" && item.value === true) {
            return (
              <div className="flex flex-col items-center justify-center h-full w-full">
                <div className=" w-[70%]  border-gray-300 border rounded-lg p-5 flex flex-col gap-y-5 ">
                  <div className=" flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
                    <p className="flex items-center gap-x-3 text-md">
                      <FaWpforms className="w-5 h-5" />
                      Refund Form{" "}
                    </p>
                  </div>
                  <form className="mt-2  w-full flex" encType="multipart/form-data" onSubmit={handleUpdate}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" text-center w-full text-gray-600   ">Refund Nights</label>
                      <input
                        placeholder="Employee Name"
                        name="refund_nights"
                        type="Number"
                        defaultValue={
                          location.state && location.state.nights !== undefined ? location.state.nights : ""
                        }
                        required
                        onChange={(e) => {
                          setNights(e.target.value);
                        }}
                        className=" w-full px-3 h-10  border rounded-lg bg-white "
                      />
                      <button
                        type="Submit"
                        disabled={updateRefundFlag}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all mt-5"
                      >
                        Update{" "}
                        {updateRefundFlag ? (
                          <>
                            <ButtonLoading />
                            <TopBarProgress />
                          </>
                        ) : (
                          <RxUpdate className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            );
          } else {
            return (
              <div className="w-full h-screen">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default UpdateRefund;
