import React from "react";
import usePermissions from "../../../../../customHooks/usePermissions";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import Loading from "../../../Loading/Loading";
import Errorfetch from "../../../Errorfetch/Errorfetch";
import ITTickestListTable from "./ITTickestListTable";

function SoftDeleteTicketsList() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "it_ticketing_system.view_ticketsupport",
    "it_ticketing_system.delete_ticketsupport",
  ]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const canViewTickets =
    permissions?.find((permission) => permission.name === "it_ticketing_system.view_ticketsupport")?.value === true;

  const canDeleteTickets =
    permissions?.find((permission) => permission.name === "it_ticketing_system.delete_ticketsupport")?.value === true;

  const {
    isLoading: isFetchingTicketsList,
    error: isFetchingError,
    data,
  } = useQuery({
    queryFn: () => fetchDataQuery(`/it-ticketing-system/api/v1/tickets-support/soft_deleted/`),
    queryKey: ["tickets-soft-delete", paginationModel.page + 1, paginationModel.pageSize],
    enabled: canViewTickets,
  });

  const tickets = data?.results;
  const count = data?.count;

  if (isFetchingTicketsList || !permissionFlag) {
    return <Loading />;
  }

  if (isFetchingError) {
    return <Errorfetch Error={`Error fetching soft deleted tickets list`} />;
  }

  if (permissionsError) {
    return <Errorfetch Error={"Error fetching permissions"} />;
  }

  return (
    <ITTickestListTable
      softDelete={false}
      canViewTickets={canViewTickets}
      canDeleteTickets={canDeleteTickets}
      count={count}
      tickets={tickets}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
    />
  );
}

export default SoftDeleteTicketsList;
