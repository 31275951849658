// ErrorBoundary.js
import React, { Component } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { LuFileWarning } from "react-icons/lu";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      errorLocation: "",
      errorTime: "",
      networkError: false,
      networkErrorMessage: "",
      showDetails: false, // Track whether to show error details or not
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error details including location and time
    this.setState({
      errorLocation: errorInfo.componentStack,
      errorTime: new Date().toLocaleString(),
    });
  }

  componentDidMount() {
    window.addEventListener("error", this.handleNetworkError);
  }

  componentWillUnmount() {
    window.removeEventListener("error", this.handleNetworkError);
  }

  handleNetworkError = (event) => {
    if (event && event.error && !event.error.message) {
      this.setState({ networkError: true, networkErrorMessage: "Network error occurred" });
    }
  };

  toggleDetails = () => {
    this.setState((prevState) => ({
      showDetails: !prevState.showDetails,
    }));
  };

  handleReload = () => {
    window.location.reload();
  };

  render() {
    const errorStyle = {
      backgroundColor: "#000", // Redish background
      color: "#ffffff", // White text
      padding: "20px",
      borderRadius: "5px",
      position: "fixed",
      top: "3%", // Fixed on top 5%
      right: "3%", // Fixed on right 5%
      width: "100%", // Full width by default
      maxWidth: "30%", // Max width 30% on big screens
      zIndex: 1000,
      overflow: "auto",
      maxHeight: "95vh",
    };

    const smallScreenMediaQuery = window.matchMedia("(max-width: 768px)");
    if (smallScreenMediaQuery.matches) {
      // If the screen is small, set the width to full
      errorStyle.width = "100%";
      errorStyle.maxWidth = "60%";
    }

    const detailsStyle = {
      maxHeight: "95vh",
      overflowY: "auto",
      marginTop: "10px",
    };

    if (this.state.hasError || this.state.networkError) {
      return (
        <div className="error-popup" style={errorStyle}>
          <div className="flex items-center gap-2">
            <LuFileWarning className="bg-red-500 text-white rounded-full p-1 text-2xl" />
            <h2>Oops! Something went wrong. </h2>
          </div>
          <p>{this.state.errorMessage || this.state.networkErrorMessage}</p>
          <a href="/dashboard" className="underline font-bold text-white">
            Back Home
          </a>
          <button onClick={this.toggleDetails} className="bg-none flex gap-2 items-center my-4 text-sm outline-none">
            <CiCirclePlus />
            <span className="text-blue-400 underline">Details</span>
          </button>
          {this.state.showDetails && (
            <div className="w-full border-t-2">
              <h3 className="underline font-bold">Error Details:</h3>
              <p>Time: {this.state.errorTime}</p>
              <p>Location: {this.state.errorLocation}</p>
              <pre className="w-full border-t-2" style={detailsStyle}>
                {JSON.stringify(
                  {
                    errorMessage: this.state.errorMessage || this.state.networkErrorMessage,
                    networkError: this.state.networkError,
                  },
                  null,
                  2,
                )}
              </pre>
            </div>
          )}
          <button
            onClick={this.handleReload}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
          >
            Reload
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
