import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import SpecialRateSetupForm from "./SpecialRateSetupForm";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import ButtonLoading from "../../ButtonLoading/ButtonLoading";
import Loading from "../../Loading/Loading";
function reshapeText(text) {
  let firstReshape = text?.replace("_", " ");
  let secondReshape = firstReshape[0]?.toUpperCase() + firstReshape?.slice(1);
  return secondReshape;
}

const AddSpecialRateSetup = () => {
  const [specialRate, setSpecialRateData] = useState();

  const {
    mutate: specialTool,
    isLoading,
    isPending,
  } = useMutation({
    mutationFn: (data) =>
      mutateDataQuery("/sales-management/api/v1/update-preferred-special-rate-tool-setup/", "post", data, {
        "Content-Type": "application/json",
      }),
    mutationKey: "specialTool",
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Special Rate Setup Created Successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to create special rate setup");
    },
  });

  // Base URL
  let baseUrl = "/sales-management/api/v1/special-rate-tool-setup/";

  // Fetch rate data
  const {
    data: rate,
    isSuccess: isRateSuccess,
    isLoading: rateLoading,
  } = useQuery({
    queryKey: ["rate"],
    queryFn: () => fetchDataQuery(baseUrl),
  });
  useEffect(() => {
    if (isRateSuccess && rate) {
      setSpecialRateData(rate);
    }
  }, [rate, isRateSuccess]);
  const methodLabel = specialRate?.results[0] && Object.values(specialRate?.results[0]?.preferred_contact_method);
  const methodValue = specialRate?.results[0] && Object.keys(specialRate?.results[0]?.preferred_contact_method);
  const initialValues =
    specialRate?.results && specialRate?.results?.length > 0
      ? {
          city: null,
          area: null,
          hotel: null,
          whatsapp_number: null,
          whatsapp_group: null,
          email: null,
          contact_person: specialRate?.results[0]?.contact_person
            ? {
                label: specialRate?.results[0]?.contact_person?.name,
                value: specialRate?.results[0]?.contact_person?.id,
              }
            : "",
          preferred_contact_method:
            {
              name: methodLabel + reshapeText(` (${methodValue})`),
              label: methodLabel,

              value: `${methodValue}`,
            } || "",
        }
      : {};

  const handleSubmit = async (values) => {
    try {
      const formatedData = {
        hotel: values?.hotel,
        contact_person: values.contact_person?.value,
        whatsapp_number:
          values.preferred_contact_method.value === "whatsapp_number" ? values.preferred_contact_method.label : "", // WhatsApp  as array
        email: values.preferred_contact_method.value === "email" ? values.preferred_contact_method.label : "", // Emails as array
        whatsapp_group:
          values.preferred_contact_method.value === "whatsapp_group" ? values.preferred_contact_method.label : "", // WhatsApp groups as array
        preferred_contact_method: values.preferred_contact_method.value,

        // Conditional logic for preferred contact method
        preferred_email: values.preferred_contact_method.value === "email" ? true : false,
        preferred_whatsapp_number:
          values.preferred_contact_method.value === "whatsapp_number" && !values.email && !values.whatsapp_group
            ? true
            : false,
        preferred_whatsapp_group:
          values.preferred_contact_method.value === "whatsapp_group" && !values.email && !values.whatsapp_number
            ? true
            : false,
      };

      // Optional: You can log formatted data for further inspection
      // alert(JSON.stringify(formatedData, null, 2));
      await specialTool(formatedData);
    } catch (error) {
      // Show error in a toast notification
      toast.error(`Submission failed: ${error.message || "An unexpected error occurred."}`);
    }
  };

  if (isLoading || rateLoading) return <Loading />;

  return (
    <section className="formPage-wrap min-h-screen">
      <h2 className="header-h2">Special Rate Tool Setup</h2>

      <SpecialRateSetupForm initialValues={initialValues} onSubmit={handleSubmit} isLoading={isPending} />
    </section>
  );
};

export default AddSpecialRateSetup;
