import React, { useEffect } from "react";
import cookie from "react-cookies";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accessGuestAction, checkCodeAction } from "../redux/actions/guestAction";
import { NotificationContainer, NotificationManager } from "react-notifications";

const SearchAuth = () => {
  const dispatch = useDispatch();

  //Get last params to check if user or guest
  const location = useLocation();
  const currentPath = location.pathname;
  const parts = currentPath.split("/");
  const lastParams = parts[parts.length - 1];
  const navigate = useNavigate();
  useEffect(() => {
    if (
      lastParams !== "login" &&
      currentPath !== "/" &&
      lastParams !== "dashboard" &&
      lastParams !== "multireservation"
    ) {
      // Clear cookies
      cookie.remove("access_token", { path: "/" });
      cookie.remove("refresh_token", { path: "/" });
      cookie.remove("currency", { path: "/" });
      cookie.remove("language", { path: "/" });
      cookie.remove("code", { path: "/" });
      navigate(`/`);
      if (!cookie.load("access_token")) {
        cookie.save("code", lastParams, { path: "/" });

        dispatch(
          checkCodeAction(cookie.load("code"), (result) => {
            if (result.status === 200) {
              if (result.data.verify === true) {
                dispatch(
                  accessGuestAction((result) => {
                    if (result.status === 200) {
                      cookie.save("access_token", result.data.tokens.access, { path: "/" });
                      cookie.save("refresh_token", result.data.tokens.refresh, { path: "/" });
                      cookie.save("language", "ar", { path: "/" });
                      cookie.save("currency", "USD", { path: "/" });
                      navigate(`/`);
                    }
                  }),
                );
              } else {
                NotificationManager.error("This link is expired");
                cookie.remove("code", { path: "/" });
              }
            } else {
              cookie.remove("code", { path: "/" });
            }
          }),
        );
      }
    }
  }, []);
  <NotificationContainer />;

  return cookie.load("access_token") ? <Outlet /> : <Navigate to="/login" />;
};

export default SearchAuth;
