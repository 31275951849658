import React from "react";
import Loading from "../Loading/Loading";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { MdOutlineDone } from "react-icons/md";
import { getSelectedCash } from "../../../redux/actions/cashAction";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineAccessTime } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import TopBarProgress from "react-topbar-progress-indicator";
import { GoPerson } from "react-icons/go";
import { BsCurrencyExchange } from "react-icons/bs";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { MdOutlineLocationOn } from "react-icons/md";

const CashDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  useEffect(() => {
    dispatch(getCurrencyAction(token));
    dispatch(getSelectedCash(token, params.id));
  }, []);
  const { cashSelectedData } = useSelector((state) => state.cashReducer);
  const { cashSelectedDataFlag } = useSelector((state) => state.cashReducer);

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.view_cashpaymenttransaction"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      })
    );
  }, []);

  return (
    <div className="w-full overflow-x-auto h-screen">
      {cashSelectedData && !cashSelectedDataFlag && permissionFlag ? (
        permissionFlag &&
        permission.map((item) => {
          if (item.name === "room_booking.view_cashpaymenttransaction" && item.value === true) {
            return (
              <div className="container border-gray-300 border rounded-lg  p-10 ">
                <div className="flex flex-col justify-center items-center ">
                  <div className="flex flex-col    w-full  text-black rounded-md  ">
                    <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                      <p className="flex items-center gap-x-3">
                        <TbListDetails className="w-5 h-5" />
                        Cash Details{" "}
                      </p>
                    </div>

                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Amount</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">
                            {" "}
                            {cashSelectedData.amount}
                            <span>{cashSelectedData.currency_type}</span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">ID</p>
                        </div>
                        <div>
                          <p className="flex items-center gap-x-1"> {cashSelectedData.id}</p>
                        </div>
                      </div>
                    </div>
                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3  ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Conversion Rate</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1"> {cashSelectedData.conversion_rate}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Booked Room</p>
                        </div>
                        <div>
                          <p className="flex items-center gap-x-1"> {cashSelectedData.booked_room}</p>
                        </div>
                      </div>
                    </div>

                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Conversion Price</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">
                            {" "}
                            {cashSelectedData.conversion_rate_price}
                            <span>{cashSelectedData.contract_currency}</span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Creator</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">
                            {" "}
                            <GoPerson className="w-5 h-5 text-[#002244] " />
                            {cashSelectedData.creator}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3  ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Currency </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1"> {cashSelectedData.currency_type}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Source Name</p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">
                            <GoPerson className="w-5 h-5 text-[#002244] " />
                            {cashSelectedData.source_name}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Contract Currency </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1"> {cashSelectedData.contract_currency}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Booked Room Name </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1"> {cashSelectedData.booked_room_name}</p>
                        </div>
                      </div>
                    </div>

                    {/* Hotel & Room Name*/}
                    <div className="flex w-full justify-between items-center  p-3">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Date </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">
                            <FaRegCalendarAlt className="w-4 h-4 text-[#002244]" />
                            {cashSelectedData.transaction_date.slice(0, 10)}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Time </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-2">
                            <MdOutlineAccessTime className="w-5 h-5 text-[#002244]" />
                            {cashSelectedData.transaction_date.slice(11, 19)}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Client text & Client Tag Request*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex  justify-between items-start w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-2 font-bold">Box Details </p>
                        </div>
                        <div className="flex flex-col gap-y-3 w-[70%] font-semibold text-gray-800">
                          <p className="flex items-center gap-x-1">
                            <p className="flex items-center gap-x-2 w-28 font-semibold ">Name</p>{" "}
                            <GoPerson className="w-5 h-5 text-[#002244] " /> {cashSelectedData.box_name.name}
                          </p>
                          <p className="flex items-center gap-x-1">
                            <p className="flex items-center gap-x-2 w-28 font-semibold ">Currency</p>
                            <BsCurrencyExchange className="w-5 h-5 text-[#002244]" />{" "}
                            {cashSelectedData.box_name.currency_type}
                          </p>
                          <p className="flex items-center gap-x-1">
                            <p className="flex items-center gap-x-2 w-28 font-semibold ">location</p>
                            <MdOutlineLocationOn className="w-5 h-5 text-[#002244]" />{" "}
                            {cashSelectedData.box_name.location}
                          </p>
                        </div>
                      </div>

                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Transaction Status </p>
                        </div>
                        <div className="w-[70%] font-semibold text-gray-800 capitalize">
                          {cashSelectedData.transaction_status === "confirmed" ? (
                            <p className="w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-green-700 font-semibold">
                              {cashSelectedData.transaction_status}{" "}
                              <MdOutlineDone className="bg-green-700 border text-white rounded-full " />
                            </p>
                          ) : (
                            <p className="w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-yellow-600 font-semibold">
                              {cashSelectedData.transaction_status}....
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return <NoPermission />;
          }
        })
      ) : (
        <>
          {" "}
          <TopBarProgress />
          <div className="w-full h-full flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default CashDetails;
