import React, { useEffect, useState } from "react";
import { BsListUl } from "react-icons/bs";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { TbListDetails } from "react-icons/tb";
import Select from "react-select";
import { GiBank } from "react-icons/gi";
import Loading from "../Loading/Loading";
import { destinationAction, getHotelCityAction } from "../../../redux/actions/searchformAction";
import { getSelectedHotelBankNumber } from "../../../redux/actions/hotelBankDetails";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const HotelBankAccountNumber = () => {
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const [city, setCity] = useState();
  const [hotelBankData, setHotelBankData] = useState();
  const [BankData, setBankData] = useState();
  const [load, setLoad] = useState(false);
  const [loadSelect, setLoadSelect] = useState(false);
  const { destinationData } = useSelector((state) => state.SearchFormReducer);

  let destination = destinationData
    ? destinationData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];
  let hotels = hotelBankData
    ? hotelBankData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  useEffect(() => {
    dispatch(destinationAction(token));
  }, []);

  /////permission/////////
  const permissionData = {
    permission_codes: ["hotels_management.view_hotelbankaccountnumber"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="formPage-wrap min-h-screen">
      {destinationData.length > 0 && permissionFlag ? (
        permission.map((item) => {
          if (item.name === "hotels_management.view_hotelbankaccountnumber" && item.value == true) {
            return (
              <>
                <div className="w-full flex justify-center ">
                  <h2 className="header-h2 ">
                    <BsListUl className="w-6 h-6 mt-1" /> Hotel Bank Account Number
                  </h2>
                </div>
                <div className={`mb-4 form-wrap `}>
                  <div className="input-par ">
                    <div className="input-chil">
                      <label className=" flex text-black font-semibold  text-lg ">Please Choose City</label>
                      <Select
                        placeholder="City Name"
                        name="bank_name"
                        type="text"
                        isSearchable
                        className=" w-full  h-10  rounded-lg  bg-white  text-black z-50"
                        required
                        options={destination}
                        onChange={(e) => {
                          setCity(e.label);
                          setLoadSelect(true);
                          dispatch(
                            getHotelCityAction(token, e.value, (result) => {
                              if (result.status === 200) {
                                setLoadSelect(false);
                                setHotelBankData(result.data);
                              }
                            }),
                          );
                        }}
                      />
                    </div>
                    <div className="input-chil">
                      <label className=" flex text-black font-semibold  text-lg  ">Select Hotel Name </label>
                      {loadSelect ? (
                        <ButtonLoading />
                      ) : (
                        <Select
                          placeholder="Hotel Name"
                          name="bank_name"
                          type="text"
                          isSearchable
                          className=" w-full  h-10  rounded-lg  bg-white  text-black z-50"
                          required
                          options={hotels}
                          onChange={(e) => {
                            setLoad(true);
                            dispatch(
                              getSelectedHotelBankNumber(e.value, (result) => {
                                if (result.status === 200) {
                                  setBankData(result.data.results);
                                  setLoad(false);
                                }
                              }),
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {load ? (
                  <div className="flex items-center justify-center h-full w-full">
                    <TopBarProgress /> <Loading />
                  </div>
                ) : BankData ? (
                  BankData.length > 0 ? (
                    BankData.map((item) => {
                      return (
                        <div className="container mx-auto  border-gray-300 border rounded-lg  p-10  ">
                          <div className="flex flex-col justify-center items-center  ">
                            <div className=" flex items-center justify-center ">
                              <h2 className="header-h2">
                                <TbListDetails className="w-5 h-5" />
                                Hotel Bank Details{" "}
                              </h2>
                            </div>
                            {/* Hotel id & id & persons*/}
                            <div className="flex w-full justify-between items-center  mt-3 p-3 bg-gray-200 ">
                              <div className="flex   items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">ID: </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.id}</p>
                                </div>
                              </div>
                              <div className="flex items-center w-[50%]">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Hotel ID:</p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1"> {item.hotel.id}</p>
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full justify-between items-center  p-3">
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Hotel Name: </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.hotel.name} </p>
                                  <p className="flex items-center gap-x-1"></p>
                                </div>
                              </div>

                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Bank Name:</p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.bank_name}</p>
                                </div>
                              </div>
                            </div>
                            {/* Client & phone Number*/}
                            <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Account Number: </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.account_number}</p>
                                </div>
                              </div>

                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Iban Number: </p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.iban_number}</p>
                                </div>
                              </div>
                            </div>
                            {/* WhatsApp Number & Email*/}
                            <div className="flex w-full justify-between items-center  p-3  ">
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Swift Code:</p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.swift_code} </p>
                                </div>
                              </div>
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Currency:</p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.currency} </p>
                                  <p className="flex items-center gap-x-1"></p>
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full justify-between items-center  p-3  bg-gray-200">
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Attachment:</p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  {item.document ? (
                                    <img src={item.document} className="w-24 h-24" />
                                  ) : (
                                    <p className="flex items-center gap-x-1">No Document: </p>
                                  )}
                                </div>
                              </div>
                              <div className="flex  justify-between items-center w-[50%] ">
                                <div className="w-[33%]  ">
                                  <p className="flex items-center gap-x-1 font-bold">Notes:</p>
                                </div>
                                <div className="w-[70%] font-semibold text-gray-800">
                                  <p className="flex items-center gap-x-1">{item.notes} </p>
                                  <p className="flex items-center gap-x-1"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex items-center justify-center h-full w-full">
                      Not Found Hotel Bank Account Number
                    </div>
                  )
                ) : (
                  <div className="w-full h-[70%] flex flex-col items-center justify-center  ">
                    <GiBank className="w-48 h-48 text-gray-400 opacity-40" />
                    <p className="font-semibold text-2xl text-gray-800 opacity-50">No Hotel Has Been Selected</p>{" "}
                  </div>
                )}
              </>
            );
          } else {
            return (
              <div className="w-full h-screen ">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="flex items-center justify-center h-full w-full">
          <TopBarProgress /> <Loading />
        </div>
      )}
    </div>
  );
};

export default HotelBankAccountNumber;
