import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { HiWifi } from "react-icons/hi";
import { GiMoneyStack } from "react-icons/gi";
import { BsFillCreditCardFill } from "react-icons/bs";
import { IoLocation } from "react-icons/io5";
import { CiLollipop } from "react-icons/ci";
import { FaCity } from "react-icons/fa6";
import { PiRoadHorizonDuotone } from "react-icons/pi";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../../organisms/Loading/Loading";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { FcDam, FcInfo } from "react-icons/fc";
import { FiExternalLink } from "react-icons/fi";
import { Button, Modal, Popover } from "flowbite-react";

const HotelPopUp = ({ detailsFlag, setdetailsFlag, hotelsDetails, setOpen, open, setHotelsDetails }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [t, i18n] = useTranslation();

  const defaultCenter = {
    lat: Number(detailsFlag && hotelsDetails.latitude),
    lng: Number(detailsFlag && hotelsDetails.longitude),
  };
  const mapStyles = {
    height: "30vh",
    width: "100%",
  };
  const googleMapsUrl = `https://www.google.com/maps?q=${defaultCenter.lat},${defaultCenter.lng}`;

  const splideOptions = {
    perPage: 1,
    perMove: 1,
    arrows: true,
    keyboard: "global",
    rewind: true, // Set rewind to true for looping
    pagination: false, // Enable pagination if needed
    direction: t("dir") === "en" ? "ltr" : "rtl",
    autoplay: true, // Enable autoplay
    interval: 3000, // Set interval for autoplay in milliseconds (optional)
    speed: 500, // Set transition speed if desired (in milliseconds)
  };

  return (
    <>
      <Modal
        size={"6xl"}
        dismissible
        show={open}
        onClose={() => {
          setdetailsFlag(false);
          setOpen(false);
        }}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body dir={t("dir")}>
          {detailsFlag ? (
            <div className="flex md:flex-row flex-col justify-between">
              <div className="  flex flex-col  justify-start items-start bg-white md:w-[50%] w-full  h-full  ">
                <Splide options={splideOptions}>
                  {hotelsDetails &&
                    hotelsDetails.images.map((item) => (
                      <SplideSlide>
                        <div className="w-full">
                          <img src={item.image_url} className="w-full lg:h-[340px] h-[200px] rounded-xl" />
                        </div>
                      </SplideSlide>
                    ))}
                </Splide>
                <div className="w-full flex flex-col  items-center justify-center ">
                  {hotelsDetails.features.map((item, index) => {
                    return (
                      <p className=" font-semibold capitalize text-black  " key={index}>
                        {item.feature_name}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className=" flex flex-col justify-center items-center md:items-start gap-y-3 bg-white md:mt-8 mt-3 md:mr-10 md:w-[50%] ">
                <div className="w-full flex flex-col md:flex-row gap-y-2 justify-between items-center ">
                  <div className=" flex flex-col  gap-y-1 justify-start items-start w-full ">
                    <div className="flex md:flex-row flex-col gap-y-4  items-center justify-between  w-full ">
                      <p className="flex items-center gap-x-1 md:text-xl text-md text-[#002244] font-semibold  ">
                        <FcDam className="w-7 h-7" /> {hotelsDetails.name}
                      </p>

                      <div className="flex items-center justify-between gap-x-3">
                        {hotelsDetails.internet === "yes" && (
                          <div className="w-full flex  justify-start items-center gap-x-2  font-semibold text-black capitalize ">
                            <div className="flex gap-y-1 justify-center items-center rounded-md border-gray-300 border w-10 h-10  ">
                              <HiWifi className="w-6 h-6 opacity-70 text-[#002244]" />
                            </div>
                          </div>
                        )}
                        <div className="w-full flex flex-col  justify-start items-center gap-x-2  font-semibold text-black capitalize ">
                          {hotelsDetails.payment_type === "cash" ? (
                            <div className="flex gap-y-1 justify-center items-center rounded-md border-gray-300 border w-10 h-10  ">
                              <GiMoneyStack className="w-8 h-8 opacity-50 text-[#002244]" />
                            </div>
                          ) : (
                            <div className="flex gap-y-1 justify-center items-center rounded-md border-gray-300 border w-10 h-10  ">
                              <BsFillCreditCardFill className="w-7 h-7 opacity-50" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex items-center gap-x-1 capitalize">
                  {hotelsDetails.extra_info?.map((item) =>
                    item.details_en || item.details_ar ? (
                      <Popover
                        content={
                          <div className="z-10 w-64 text-sm text-gray-500 dark:text-gray-400">
                            <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                              <h3 className="font-semibold text-gray-900 dark:text-white">{t("details")}</h3>
                            </div>
                            <div className="px-3 py-2">
                              <p>{t("lan") === "en" ? item.details_en : item.details_ar}</p>
                            </div>
                          </div>
                        }
                      >
                        <p className="cursor-pointer text-black text-xs bg-yellow-500 p-1 rounded-md">
                          {t("lan") === "en" ? item.name_en : item.name_ar}
                        </p>
                      </Popover>
                    ) : (
                      <p className=" text-black text-xs bg-yellow-500 p-1 rounded-md">
                        {t("lan") === "en" ? item.name_en : item.name_ar}
                      </p>
                    ),
                  )}
                </div>
                <p className="flex items-center gap-x-1 line-clamp-none md:h-full  md:px-0 px-2 overflow-y-auto text-gray-700  ">
                  <FcInfo className="w-5 h-5" />
                  {hotelsDetails.description}
                </p>

                <div className="flex flex-col  items-center justify-center w-full gap-y-5    ">
                  <div className="w-full flex flex-col md:flex-row justify-between">
                    {hotelsDetails.videos.length > 0 && (
                      <div className="w-full flex justify-center items-center ">
                        <video
                          className="shadow-lg rounded-lg w-full sm:h-80"
                          controls
                          src={hotelsDetails.videos[0]?.video_url} // Replace with your video source URL
                        ></video>
                      </div>
                    )}

                    <div className="flex flex-col gap-y-2 w-full  justify-start  items-center   ">
                      <div className="w-full text-center">
                        <p className="text-xl font-bold ">{t("hotelDetails")}</p>
                      </div>

                      <div className=" grid md:grid-cols-1 grid-cols-1 gap-y-2 w-[95%] justify-center  items-center  pl-6 ">
                        <p className="w-full flex  justify-start items-center gap-x-2 font-bold">
                          <div className="border-gray-300 border rounded-lg p-1 ">
                            <IoLocation className="w-5 h-5 text-red-900" />
                          </div>
                          {hotelsDetails._country_name}
                        </p>
                        <p className="w-full flex  justify-start items-center gap-x-2 font-bold">
                          <div className="border-gray-300 border rounded-lg p-1 ">
                            {" "}
                            <CiLollipop className="w-5 h-5 text-red-900" />{" "}
                          </div>
                          {hotelsDetails.city}{" "}
                        </p>
                        <p className="w-full flex  justify-start items-center gap-x-2 font-bold">
                          <div className="border-gray-300 border rounded-lg p-1 ">
                            {" "}
                            <FaCity className="w-5 h-5 text-red-900" />{" "}
                          </div>
                          {hotelsDetails.area}
                        </p>
                        <p className="w-full flex  justify-start items-center gap-x-2 font-bold">
                          <div className="border-gray-300 border rounded-lg p-1 ">
                            {" "}
                            <PiRoadHorizonDuotone className="w-5 h-5 text-red-900" />{" "}
                          </div>

                          {hotelsDetails.address}
                        </p>
                      </div>
                    </div>
                  </div>

                  <GoogleMap
                    key={selectedDate.toISOString()}
                    mapContainerStyle={mapStyles}
                    zoom={13}
                    center={defaultCenter}
                  >
                    <Marker position={defaultCenter} />
                  </GoogleMap>
                  <a
                    className=" text-base font-medium text-blue-500 text-center flex items-center gap-2 "
                    href={googleMapsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit this location on Google Maps:
                    <FiExternalLink className=" text-base font-medium text-blue-500" />
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="ww-full flex h-full items-center justify-between ">
              <TopBarProgress />
              <Loading />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="gray"
            onClick={() => {
              setdetailsFlag(false);
              setOpen(false);
            }}
          >
            {t("nocancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HotelPopUp;
