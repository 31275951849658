import React from "react";
import "./DeletePopUp.scss";

export default function DeletePopUp({ name, Func, hideFunc, status }) {
  return (
    <>
      <div
        className={` fixed 
       "top-0 left-0 right-0 bottom-0 h-screen  " 
    }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
      >
        <div
          className={`bg-black/70  max-w-2xl px-2 w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${"opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "} `}
        >
          <p className="text-2xl font-light text-center  text-white capitalize ">
            Are you sure you want to {status ? status : "delete"} {name} ?
          </p>
          <div className="w-full flex items-center justify-center gap-x-5 text-white">
            <button
              onClick={(action) => {
                Func(action);
              }}
              className="bg-green-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              Yes,I'm Sure
            </button>
            <button
              onClick={() => hideFunc()}
              className="bg-red-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              No,take me back
            </button>
          </div>
        </div>
      </div>
      {/* <div className="deleteCard">
      <div>Are you sure you want to delete {name}</div>
      <div className="deleteCardButtons">
        <button className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600" onClick={() => hideFunc()}>
          Cancel
        </button>
        <button
          className="px-6 py-2 text-white bg-red-500 rounded-md hover:bg-red-600"
          onClick={(action) => Func(action)}
        >
          Delete
        </button>
      </div>
    </div> */}
    </>
  );
}
