import React from "react";

import { useNavigate } from "react-router";
import Loading from "../../organisms/Loading/Loading";

const AccountUserAgent = ({ data, isSuccess }) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   getURI("user-agent");
  // }, [getURI]);

  const emailsAddress = data?.profile?.email_address;
  const mobileNumbers = data?.profile?.mobile_numbers;
  const whatsAppNumbers = data?.profile?.whats_app_numbers;

  return (
    <div className="formPage-wrap">
      {/* <button className="text-white mb-8 bg-[#002244] px-4 rounded-md py-2" onClick={() => navigate(-1)}>
        &#10094; Back
      </button> */}
      <section className="  w-full ">
        <div className="w-full flex flex-col justify-center items-center">
          <h2 className="header-h2 !mb-0">Agent Account</h2>
        </div>
        {data ? (
          <div className=" w-full relative h-full">
            {/* <div className="w-full bg-[#002244] h-48 rounded-md " /> */}
            <div className="  flex w-full  ">
              <div className=" flex flex-col  px-2 py-8  w-full  ">
                <div className=" flex  gap-4  w-full max-sm:flex-col ">
                  <div className="flex justify-start flex-col flex-[1] form-wrap h-max">
                    <div className="w-full flex justify-center">
                      <div className=" w-36 h-36 rounded-full border-4   border-[#002244]">
                        <img
                          src={
                            data?.profile?.avatar
                              ? data?.profile?.avatar
                              : "https://as1.ftcdn.net/v2/jpg/05/52/37/18/500_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg"
                          }
                          alt="profile image"
                          title="profile image"
                          className=" w-full h-full object-fit object-top rounded-full bg-white p-1"
                        />
                      </div>
                    </div>
                    <div className=" flex flex-col gap-4">
                      <div className="flex gap-2 w-full  justify-between  max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">ID: </p>
                        <p className="max-md:text-sm"> {data?.id ? data?.id : "__"}</p>
                      </div>
                      {/* <div className="flex md:gap-2 gap-1 w-full  justify-between  capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">username: </p>
                        <p className=" max-md:text-sm"> {data?.username ? data?.username : "__"}</p>
                      </div> */}
                      <div className="flex md:gap-2 gap-1  justify-between  capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Email: </p>
                        <a
                          className="text-blue-400 hover:underline hover:text-blue-500 max-md:text-sm"
                          href={`mailto:${data?.email}`}
                        >
                          {data?.email ? data?.email : "__"}
                        </a>
                      </div>
                      <div className="flex md:gap-2 gap-1 w-full  justify-between  capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">First Name: </p>
                        <p className=" max-md:text-sm"> {data?.first_name ? data?.first_name : "__"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1 w-full  justify-between  capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Last Name: </p>
                        <p className=" max-md:text-sm"> {data?.last_name ? data?.last_name : "__"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1 w-full  justify-between  capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Group: </p>
                        <p className=" max-md:text-sm flex flex-col">
                          {" "}
                          {data?.groups ? data?.groups?.map((i, idx) => <div key={idx}>{i?.name}</div>) : "__"}
                        </p>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between  capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">user type: </p>

                        <p className=" max-md:text-sm uppercase"> {data?.user_type ? data?.user_type : "__"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between  capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Active Status: </p>

                        <p className=" max-md:text-sm uppercase"> {data?.is_active ? "Active" : "Not Active"}</p>
                      </div>
                      {/* <div className="flex md:gap-2 gap-1  justify-between  capitalize max-[280px]:flex-col">
                      <p className="font-bold max-md:text-sm">active Status:</p>
                      <p className=" max-md:text-sm">{data?.is_active ? "Active" : "Not Active"}</p>
                    </div> */}
                    </div>
                  </div>
                  <div className=" form-wrap flex-[4]">
                    <div className="flex flex-col  gap-4   ">
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Nick Name:</p>
                        <p className=" max-md:text-sm">{data?.profile?.nickname ? data?.profile?.nickname : "__"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">agent Name:</p>
                        <p className="  max-md:text-sm">
                          {data?.profile.agent_name ? data?.profile?.agent_name : "__"}
                        </p>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">ID Number:</p>
                        <p className=" max-md:text-sm">{data?.profile?.id_number ? data?.profile?.id_number : "__"}</p>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Country Name:</p>
                        <p className=" max-md:text-sm">
                          {data?.profile?.country_name ? data?.profile?.country_name : "__"}
                        </p>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">City Name:</p>
                        <p className=" max-md:text-sm">{data?.profile?.city ? data?.profile?.city : "__"}</p>
                      </div>
                      {/* <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Market :</p>
                        <p className=" max-md:text-sm">
                          {data?.profile?.market.length > 0 ? data?.profile?.market : "__"}
                        </p>
                      </div> */}
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Notifications Prefered:</p>
                        <p className=" max-md:text-sm">
                          {data?.profile?.notifications_preferred
                            ? data?.profile?.notifications_preferred.map((i) => (i.length > 0 ? i : "__"))
                            : "__"}
                        </p>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">mobile numbers:</p>
                        <div className="md:flex flex flex-col ">
                          {data
                            ? data?.profile &&
                              mobileNumbers &&
                              isSuccess &&
                              Object.entries(mobileNumbers)
                                ?.map((i) => i[1])
                                ?.map((item, idx) => (
                                  <a
                                    className=" text-blue-400 hover:underline hover:text-blue-500 max-md:text-sm"
                                    key={idx}
                                    href={`tel:${item}`}
                                  >
                                    {item}
                                  </a>
                                ))
                            : "__"}
                        </div>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between items-start capitalize border-b max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm capitalize">whats app numbers:</p>
                        <div className="md:flex flex flex-col items-start  ">
                          {data
                            ? data?.profile &&
                              isSuccess &&
                              Object.entries(whatsAppNumbers)
                                ?.map((i) => i[1])
                                ?.map((item, idx) => (
                                  <a
                                    key={idx}
                                    className=" text-blue-400 hover:underline hover:text-blue-500 max-md:text-sm"
                                    href={`https://wa.me/${item}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item}
                                  </a>
                                ))
                            : "__"}
                        </div>
                      </div>
                      <div className="flex md:gap-2 gap-1   justify-between items-start capitalize border-b max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Emails Address:</p>
                        <div className="md:flex flex flex-col items-start  ">
                          {data
                            ? data?.profile &&
                              emailsAddress &&
                              isSuccess &&
                              Object.entries(emailsAddress)
                                ?.map((i) => i[1])
                                ?.map((item, idx) => (
                                  <a
                                    key={idx}
                                    className="text-blue-400 hover:underline hover:text-blue-500  max-md:text-sm"
                                    href={`mailto:${item}`}
                                  >
                                    {item ? item : "__"}
                                  </a>

                                  // <p key={idx} className="  max-md:text-sm">
                                  //   {item ? item : "__"}
                                  // </p>
                                ))
                            : "__"}
                        </div>
                      </div>
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Payment Method:</p>
                        <p className=" max-md:text-sm">
                          {data?.profile?.method_of_payment ? data?.profile?.method_of_payment : "__"}
                        </p>
                      </div>
                      {/* <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Credit Limit:</p>
                        <p className=" max-md:text-sm">
                          {data?.profile?.credit_limit ? data?.profile?.credit_limit : "__"}
                        </p>
                      </div> */}
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Currency :</p>
                        <p className=" max-md:text-sm">{data?.profile?.currency ? data?.profile?.currency : "__"}</p>
                      </div>
                      {/* <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Credit Info :</p>
                        <p className=" max-md:text-sm">
                          {data?.profile?.credit_info ? data?.profile?.credit_info : "__"}
                        </p>
                      </div> */}
                      <div className="flex md:gap-2 gap-1  justify-between border-b capitalize max-[280px]:flex-col">
                        <p className="font-bold max-md:text-sm">Accounting Notes :</p>
                        <p className=" max-md:text-sm">
                          {data?.profile?.accounting_department_note ? data?.profile?.accounting_department_note : "__"}
                        </p>
                      </div>
                      <div className=" flex  flex-col md:gap-2 gap-1  justify-between items-start">
                        <p className="font-bold max-md:text-sm">Notes:</p>
                        <p className=" max-md:text-sm">{data?.profile?.notes || "No Notes"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </section>
    </div>
  );
};

export default AccountUserAgent;
