import React, { useEffect, useState } from "react";
import "./HotelDetailCard.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import getHotelDetails from "../../../redux/actions/hotelDetails";
import StarRating from "../../atoms/StarRating/StarRating";
import HotelDetailDynamicCard from "../../molecules/HotelDetailDynamicCard/HotelDetailDynamicCard";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { getHotelRooms } from "../../../redux/actions/getRooms";
import RoomsDynamicCards from "../../molecules/RoomsDynamicCards/RoomsDynamicCards";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FiEdit } from "react-icons/fi";

// function that capitalize the first letter of each word
export function makeNamesUpperCase(string) {
  let InitialArr = string.split(" ");
  let arr = InitialArr.filter((elem) => elem !== "");
  let UpperCaseArr = arr.map((elem) => {
    let WordArr = elem.split("");
    WordArr.shift();
    let restOfWord = WordArr.join("");
    return elem[0].toUpperCase() + restOfWord;
  });
  return UpperCaseArr.join(" ");
}

// options for the splide component
const splideOptionsMultiForms = {
  perPage: 1,
  perMove: 1,
  arrows: true,
  keyboard: "global",
  rewind: false,
  gap: "2rem",
  pagination: false,
  padding: "0",
};

export default function HotelDetailCard() {
  const navigate = useNavigate();
  const hotel = useSelector((state) => state.hotelDetails);
  const dispatch = useDispatch();
  const { id } = useParams();
  const hotelRooms = useSelector((state) => state.hotelRooms);
  let hotelName = "";
  if (hotel.name) {
    hotelName = hotel.name;
  }

  useEffect(() => {
    dispatch(getHotelDetails(id));
    dispatch(getHotelRooms(id));
  }, []);

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "rooms_management.add_rooms",
      "rooms_management.change_rooms",
      "rooms_management.delete_rooms",
      "rooms_management.view_rooms",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        <div className="hotelDetailsCard">
          {((hotel.name === "" || Number(hotel.id) !== Number(id)) && <Loading />) || (
            <div>
              <div className="hotelNameCard">
                <Splide options={splideOptionsMultiForms}>
                  {hotel.images.map((image, index) => {
                    return (
                      <SplideSlide key={index} className="mb-0.5">
                        <img
                          src={`${image.image_url}`}
                          className="block w-full lg:h-[500px] rounded-lg"
                          alt="Wild Landscape"
                        />
                      </SplideSlide>
                    );
                  })}
                </Splide>

                <div className="w-full flex max-sm:flex-col max-sm:justify-center">
                  <div>
                    <div className="hotelName">{hotelName}</div>
                    <StarRating starsCount={Number(hotel.rate)} />
                  </div>
                  <div className="flex-btns">
                    <button className="action-btn" onClick={() => navigate(`/dashboard/add-hotel`)}>
                      <IoIosAddCircleOutline className="w-5 h-5 text-white" />
                      <span>Add Hotel</span>
                    </button>
                    <button className="action-btn-edit" onClick={() => navigate(`/dashboard/edit-hotel/${id}`)}>
                      <FiEdit className="w-5 h-5 text-white" />
                      <span>Edit Hotel </span>
                    </button>
                  </div>
                </div>
              </div>

              <HotelDetailDynamicCard hotel={hotel} />
              {hotelRooms.length !== 0 &&
                permission.map((item) => {
                  if (item.name === "rooms_management.view_rooms" && item.value === true) {
                    return (
                      <div className="hotelRoomsCard">
                        <h1 className="font-bold text-lg">Hotel Rooms</h1>
                        <RoomsDynamicCards rooms={hotelRooms} />
                      </div>
                    );
                  }
                })}
              <div className="aboutCard">
                <h1 className="font-bold text-lg">About</h1>
                <div className="flex justify-between">
                  <p>{hotel.description}</p>
                  <p>{hotel.description_ar}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
