import React from "react";
import { FaStar } from "react-icons/fa";

export default function StarRating({ starsCount, starSize, width }) {
  const stars = [];
  for (let i = 0; i < starsCount; i++) {
    stars.push(1);
  }
  return (
    <div style={{ display: "flex", width }}>
      {stars.map((elem, index) => (
        <FaStar color="#d69429" size={starSize || 25} key={index} />
      ))}
    </div>
  );
}
