import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction, getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import { useNavigate, useParams } from "react-router";
import { IoAdd } from "react-icons/io5";
import { FaWpforms } from "react-icons/fa6";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
// import jwtDecode from 'jwt-decode';
import { TimePicker } from "antd";
import { getBoxNameAction } from "../../../redux/actions/cashAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendData } from "../../../redux/actions/sendDataAction";
import { ADD_CASH_URL } from "../../../urls";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

const AddCash = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const format = "HH:mm";
  const params = useParams();
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [boxNameForm, setBoxNameForm] = useState("");
  const [convertAmount, setConvertAmount] = useState();
  const [convertRate, setConvertRate] = useState(1);
  useRemoveScroll();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      box_name: "",
      source_name: "",
      amount: "",
      date: "",
      time: "",
    },
    validationSchema: Yup.object({
      box_name: Yup.string().required("Please select box name"),
      source_name: Yup.string()
        .required("Please enter source name")
        .min(1, "source name must be greater than or equal to 2")
        .max(50, "source name must be smaller than or equal to 50"),
      amount: Yup.number()
        .required("Please enter a  Amount")
        .positive(" Amount must be positive number")
        .integer(" Amount must be positive number")
        .min(0, " Amount must be greater than or equal to 0"),
      date: Yup.date().required("Please enter date"),
      time: Yup.string().required("Please enter time"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      const { date, time, ...restData } = values;
      Object.keys(restData).forEach((key) => {
        formData.append(key, restData[key]);
      });
      formData.append("booked_room", params.id);
      formData.append("creator", 2);
      formData.append("transaction_status", "confirmed");
      formData.append("transaction_date", `${values.date},${values.time}`);
      formData.append("currency_type", currency);
      formData.append("contract_currency", selectedSingleRoom.currency_type);
      formData.append("conversion_rate_price", convertAmount.toFixed(2));
      formData.append("conversion_rate", convertRate);
      dispatch(
        sendData(ADD_CASH_URL, formData, (result) => {
          if (result.status === 201) {
            lightToast.success("The Add Cash Form was completed successfully");
            navigate(-1);
          } else {
            lightToast.fail("Error happened please try again");
          }
        }),
      );
    },
  });

  useEffect(() => {
    //////////handle when reload page , alert show to make sure this i want reload ////////////
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ////////////////////////////////////////////////////////
    dispatch(getSelectedBookedRoomSingleAction(token, params.id, (result) => {}, signal));
    dispatch(getCurrencyAction(token, signal));
    dispatch(getBoxNameAction(token, (result) => {}, signal));
    dispatch(
      getPermissionAction(
        token,
        permissionData,
        (result) => {
          if (result.status === 200) {
            setpermissionFlag(true);
            setpermission(result.data);
          }
        },
        signal,
      ),
    );
    return () => {
      controller.abort();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { addCashFlag } = useSelector((state) => state.bookingListReducer);
  const { boxNameData } = useSelector((state) => state.cashReducer);
  const { boxFlag } = useSelector((state) => state.cashReducer);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  let boxNames = boxNameData
    ? boxNameData.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency_type };
      })
    : [];

  const handleWheel = (e) => {
    e.preventDefault();
  };
  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setTime(`${hours1}:${minutes1}`);
    setFieldValue("time", `${hours1}:${minutes1}`);
  };
  const handleAmount = (e) => {
    setAmount(e.target.value);
    setFieldValue("amount", e.target.value);
    if (convertRate) {
      setConvertAmount(e.target.value * convertRate);
    }
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.add_cashpaymenttransaction"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);

  return (
    <div className="formPage-wrap ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {boxNameData && selectedSingleRoom && permissionFlag ? (
          permission.map((item, index) => {
            if (item.name === "room_booking.add_cashpaymenttransaction" && item.value === true) {
              return (
                <div key={index} className=" w-full   ">
                  <div className=" flex justify-center">
                    <p className="header-h2">
                      <FaWpforms className="w-5 h-5" />
                      Add Cash Form{" "}
                    </p>
                  </div>
                  <form className="form-wrap w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" flex text-gray-600 ">Box Name</label>
                      <Select
                        name="box_name"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${touched.box_name && errors.box_name && "rgb(153 27 27)"}`,
                          }),
                        }}
                        onBlur={handleBlur}
                        options={boxNames}
                        onChange={(e) => {
                          setFieldValue("box_name", e.value);
                          setBoxNameForm(e.value);
                          setCurrency(e.currency);
                          setLoadingToggle(true);
                          setToggle(false);
                          dispatch(
                            convertingCurrencyAction(token, e.currency, selectedSingleRoom.currency_type, (result) => {
                              if (result.status === 200) {
                                result.data.results.length !== 0
                                  ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
                                  : setConvertAmount(amount && amount * 1);
                                result.data.results.length !== 0
                                  ? setConvertRate(...result.data.results.map((item) => item.rate))
                                  : setConvertRate(1);
                                setToggle(true);
                                setLoadingToggle(false);
                              }
                            }),
                          );
                        }}
                        isSearchable
                        className=" w-full  h-10  border rounded-lg  bg-white "
                        placeholder="Box Name"
                      />
                      {touched.box_name && errors.box_name ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.box_name}
                        </div>
                      ) : null}
                      {currency && toggle ? (
                        <>
                          <label className=" flex text-gray-600 ">Currency</label>
                          <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{currency}</p>
                          </div>
                        </>
                      ) : (
                        loadingToggle && (
                          <>
                            <ButtonLoading />
                          </>
                        )
                      )}
                      <label className=" text-center w-full text-gray-600">Source Name</label>
                      <input
                        placeholder="Source Name"
                        name="source_name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={`w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.source_name && errors.source_name && "border border-red-800"
                        } `}
                      />
                      {touched.source_name && errors.source_name ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.source_name}
                        </div>
                      ) : null}
                      <label className=" flex text-gray-600">Amount</label>
                      <input
                        placeholder="Amount"
                        name="amount"
                        type="Number"
                        step="any"
                        onWheel={handleWheel}
                        onChange={handleAmount}
                        onBlur={handleBlur}
                        value={values.amount}
                        className={`w-full px-3 h-10  border rounded-lg bg-white ${
                          touched.amount && errors.amount && "border border-red-800"
                        } `}
                      />
                      {touched.amount && errors.amount ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.amount}
                        </div>
                      ) : null}
                      {amount && currency && (
                        <>
                          <label className=" flex text-gray-600 ">
                            Amount With Currency Of Contract ({selectedSingleRoom.currency_type})
                          </label>
                          <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                            <div className="text-black flex items-end gap-x-1">
                              {convertAmount}{" "}
                              <span className="text-sm font-semibold text-gray-700">
                                {selectedSingleRoom.currency_type}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="input-par">
                        <div className="input-chil">
                          <label className=" flex justify-center text-gray-600 ">Date</label>
                          <input
                            placeholder="Tranfer Date"
                            name="date"
                            type="date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-full px-3 h-10  border rounded-lg floating-input bg-white ${
                              touched.amount && errors.amount && "border border-red-800"
                            } `}
                          />
                          {touched.date && errors.date ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.date}
                            </div>
                          ) : null}
                        </div>
                        <div className="input-chil">
                          <label className=" flex justify-center text-gray-600 ">Time</label>
                          <TimePicker
                            name="time"
                            type="time"
                            clearIcon={false}
                            format={format}
                            onBlur={handleBlur}
                            className={`w-full px-3 h-10  border rounded-lg floating-input bg-white ${
                              touched.time && errors.time && "border border-red-800"
                            } `}
                            onChange={handleTimeChange}
                          />
                          {touched.time && errors.time ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.time}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* <input
                                    placeholder="Box Name"
                                    name="box_name"
                                    type="text"
                                    required
                                    onChange={(e)=>setBoxNameForm(e.target.value)}
                                    className=" w-full px-3 h-10  border rounded-lg floating-input bg-white "
                                                />        */}

                      <button
                        type="Submit"
                        disabled={isSubmitting}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Add{" "}
                        {isSubmitting ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <IoAdd className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              );
            }
          })
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <TopBarProgress /> <Loading />
          </div>
        )}
      </div>
    </div>
  );
};
export default AddCash;
