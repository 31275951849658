import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { getData } from "../../../redux/actions/sendDataAction";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import app from "./app.jpg";
import "./ActiveUsers.css";

const ActiveUsers = ({ userFlag, setUserFlag }) => {
  const dispatch = useDispatch();

  const [userData, setUserData] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  // Define a function to fetch data from the API
  const fetchData = async () => {
    dispatch(
      getData(
        `/auth/api/v1/online-users/`,
        (resp) => {
          if (resp.status === 200) {
            setUserData(resp.data);
          }
        },
        (err) => {},
      ),
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Search input
  const filterArrays = (array) => {
    return array.filter(
      (item) =>
        item.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.username.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.request_ip.toLowerCase().includes(searchInput.toLowerCase()),
      // item.email.toLowerCase().includes(searchInput.toLowerCase()) ||
    );
  };

  return (
    <div className="w-full">
      <div
        className={`fixed ${
          userFlag ? "top-0 left-0 right-0 bottom-0 " : ""
        } blur-effect-theme w-full opacity-100 z-[250] overflow-x-hidden`}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setUserFlag(false);
          }
        }}
      >
        <div
          className={`bg-white h-screen max-w-2xl w-full absolute right-0 ${
            userFlag
              ? "opacity-100 visible right-0 transition-all duration-500 transform"
              : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform"
          }`}
        >
          <div className="w-full flex flex-col items-center justify-between p-3">
            <div className="w-full flex items-center justify-between p-3">
              <p className="text-[#002244] font-medium">Active Users</p>
              <button onClick={() => setUserFlag(false)}>
                <RxCrossCircled className="w-5 h-5 hover:bg-[#002244] hover:text-white duration-500 active:scale-95 transition-all rounded-full" />
              </button>
            </div>
          </div>
          {userData ? (
            <div className="w-full flex flex-col items-center justify-between p-3">
              <div className="w-full">
                <div className="p-4 w-full bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex md:flex-row flex-col md:gap-0 gap-4 justify-between items-center mb-4">
                    <p className="md:text-xl text-base font-bold leading-none text-gray-900 dark:text-white">
                      Latest Users
                    </p>
                    <div className="flex items-center max-w-xs bg-white rounded-lg border-2">
                      <div className="w-full">
                        <input
                          type="search"
                          className="w-full px-4 py-1 text-gray-800 rounded-full focus:outline-none"
                          placeholder="Search"
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </div>
                      <div>
                        <button className="flex items-center bg-[#002244] justify-center w-12 h-[2.2rem] text-white rounded-r-lg">
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flow-root">
                    <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                      {userData &&
                        filterArrays(userData)?.map((item, index) => (
                          <li key={index} className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                              <div className="flex-shrink-0">
                                <img
                                  className="w-8 h-8 rounded-full"
                                  src={item?.avatar ? item?.avatar : app}
                                  alt="Neil image"
                                />
                              </div>
                              <div className="flex-1 min-w-0">
                                <p className="capitalize text-sm font-medium text-gray-900 truncate dark:text-white">
                                  {item.first_name && item.last_name ? item.first_name + item.last_name : "no name"}
                                </p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">@{item.username}</p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">{item.request_ip}</p>
                              </div>
                              <div className="inline-flex items-center text-base font-semibold text-[#65a30d] dark:text-white">
                                Active <div className="flashit ml-1 w-3 h-3 rounded-full bg-green-700"></div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex items-center justify-center h-screen">
              <Loading /> <TopBarProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveUsers;
