import * as yup from "yup";

export const addBankValidationSchema = yup.object().shape({
  name: yup.string().required("name field is required"),
  account_number: yup.number().required("account number field is required").positive("Number Must be Positive"),
  currency: yup.string().required("currencey field is required"),
  notes: yup.string(),
  is_active: yup.bool().required("This field is required"),
  account_balance: yup.number().required("account balance field is required"),
});
