import React, { useState, useMemo, useEffect, useRef } from "react";
import { FaWpforms } from "react-icons/fa6";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import useHotelNames from "../../../customHooks/useHotelNames";
import { editOffer, getHotelActiveContracts } from "../../../redux/actions/offers";
import { useDispatch } from "react-redux";
import useData from "../../../customHooks/useData";
import toast from "react-hot-toast";
import TopBarProgress from "react-topbar-progress-indicator";
import HashLoader from "react-spinners/HashLoader";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import Loading from "../Loading/Loading";
import moment from "moment";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { RxUpdate } from "react-icons/rx";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

function labelName(choice) {
  switch (choice) {
    case "Pay & Get":
      return "Free Nights";
    case "Percentage":
      return "Discount Percentage";
    case "Fixed Discount":
      return "Discount Amount";
  }
  return null;
}

export default function EditOffer() {
  useRemoveScroll();
  const formRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hotels = useHotelNames();
  const offer = useData(`/offers/api/offer/${id}/`);
  const offerTypes = useData("/offers/api/offers-type/");
  const [contracts, setContracts] = useState([]);
  const [contractFlag, setContractFlag] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };
  const [chosenHotel, setChosenHotel] = useState(null);
  const [chosenContract, setChosenContract] = useState(null);
  const [chosenOfferType, setChosenOfferType] = useState({ label: null, value: null });
  const label = labelName(chosenOfferType.value);

  const hotelsArr = useMemo(() => {
    return hotels.map((hotel) => ({ label: hotel.name, value: hotel.id }));
  }, [hotels]);
  const contractsArr = useMemo(() => {
    return contracts.map((contract) => ({ label: contract.name, value: contract.id }));
  }, [contracts]);
  const offerTypesArr = offerTypes ? offerTypes.map((type) => ({ label: type, value: type })) : [];

  useEffect(() => {
    if (offer && hotels.length > 0 && offerTypes) {
      setDateRange({
        startDate: moment(offer.from_date),
        endDate: moment(offer.to_date),
      });
      dispatch(getHotelActiveContracts(offer.contract.hotel.id, (data) => setContracts(data)));
      setChosenHotel({ label: offer.contract.hotel.name, value: offer.contract.hotel.id });
      setChosenOfferType({ label: offer.type, value: offer.type });
      const form = formRef.current;
      form.offerName.value = offer.name;
      form.nights.value = Number(offer.nights_number);
    }
  }, [offer, hotels, offerTypes]);

  useEffect(() => {
    if (label) {
      const form = formRef.current;
      form.gift.value = Number(offer.gift);
    }
  }, [label]);

  useEffect(() => {
    if (contracts.length > 0) {
      setChosenContract({ label: offer.contract.name, value: offer.contract.id });
    }
  }, [contracts]);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const data = {
      name: e.target.offerName.value,
      type: chosenOfferType.value,
      nights_number: e.target.nights.value,
      gift: e.target.gift.value,
      from_date: dateRange.startDate.format("YYYY-MM-DD"),
      to_date: dateRange.endDate.format("YYYY-MM-DD"),
      contract: chosenContract.value,
    };
    dispatch(
      editOffer(id, data, (response) => {
        setIsDisabled(false);
        navigate(`/dashboard/offers/${response.data.id}`);
        toast.success("Your Offer Was Edited Successfully");
      })
    );
  }

  return (
    <div className="w-full ">
      {offer && hotels.length > 0 && offerTypes ? (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <div className=" w-[70%]  border-gray-300 border rounded-lg p-5 ">
            <div className=" flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
              <p className="flex items-center gap-x-3  text-md">
                <FaWpforms className="w-5 h-5" />
                Edit An Offer
              </p>
            </div>
            <form className="mt-2  w-full" encType="multipart/form-data" onSubmit={handleSubmit} ref={formRef}>
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <label className=" flex text-gray-600 ">Offer Name</label>
                <input
                  placeholder="Offer Name"
                  name="offerName"
                  type="text"
                  required
                  className=" w-full px-3 h-10  border rounded-lg bg-white "
                />

                <label className=" flex text-gray-600 ">Offer Hotel</label>
                <Select
                  options={hotelsArr}
                  className="w-full h-10   bg-white "
                  required
                  name="hotel"
                  value={chosenHotel}
                  onChange={(e) => {
                    setContractFlag(true);
                    dispatch(
                      getHotelActiveContracts(
                        e.value,
                        (data) => setContracts(data),
                        (result) => {
                          if (result.status === 200) {
                            setContractFlag(false);
                          } else {
                            setContractFlag(false);
                          }
                        }
                      )
                    );
                    setChosenHotel(e);
                  }}
                />

                <label className=" flex text-gray-600 ">Offer Contract</label>
                {contractFlag ? (
                  <div className="w-full flex items-center justify-center ">
                    <ButtonLoading /> <TopBarProgress />
                  </div>
                ) : (
                  <Select
                    options={contractsArr}
                    className="w-full h-10   bg-white "
                    required
                    name="contract"
                    value={chosenContract}
                    onChange={(e) => setChosenContract(e)}
                  />
                )}

                <label className=" flex text-gray-600 ">Offer Type</label>
                <Select
                  options={offerTypesArr}
                  className="w-full h-10   bg-white "
                  required
                  name="type"
                  value={chosenOfferType}
                  onChange={(e) => setChosenOfferType(e)}
                />

                <div className="w-full flex items-center justify-center ">
                  <div className="w-[35%]">
                    <label className="flex text-gray-600">From-To</label>
                    <DateRangePicker
                      startDate={dateRange.startDate}
                      startDateId="startDate"
                      endDate={dateRange.endDate}
                      endDateId="endDate"
                      onDatesChange={handleDateChange}
                      focusedInput={focusedInput}
                      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                      minimumNights={0} // Allow same-day selection
                      required
                    />
                  </div>
                  <div className="w-[65%]">
                    <label className=" flex text-gray-600 ">Minimum Nights</label>
                    <input
                      placeholder="nights"
                      name="nights"
                      type="number"
                      required
                      className=" w-full px-3 h-11  border  bg-white "
                    />
                  </div>
                </div>
                {label && (
                  <>
                    <label className="flex text-gray-600">{label}</label>
                    <input
                      placeholder={label}
                      name="gift"
                      type="number"
                      required
                      className=" w-full px-3 h-10  border rounded-lg bg-white "
                    />
                  </>
                )}

                <button
                  disabled={isDisabled}
                  type="Submit"
                  className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  Edit
                  {isDisabled ? (
                    <>
                      <ButtonLoading /> <TopBarProgress />
                    </>
                  ) : (
                    <RxUpdate className="w-5 h-5" />
                  )}
                </button>
              </div>
              {/* {isDisabled ? (
                <>
                  <TopBarProgress />
                  <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                    <HashLoader size={30} color="white" />
                    <div className="text-white font-bold my-2">Submitting...</div>
                  </div>
                </>
              ) : null} */}
            </form>
          </div>
        </div>
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </div>
  );
}
