import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FaEye } from "react-icons/fa";
import { BsFillTrash3Fill } from "react-icons/bs";
import useData from "../../../customHooks/useData";
import Loading from "../Loading/Loading";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { useDispatch } from "react-redux";
import { deleteData } from "../../../redux/actions/sendDataAction";
import ListFilters from "../../../utils/ListFilters";

export default function PermissionsGroupsList() {
  const dispatch = useDispatch();
  const [rerenderGroups, setRerenderGroups] = useState(0);
  const groups = useData("permissions/api/v1/user-groups/", rerenderGroups);
  const [deleteGroup, setDeleteGroup] = useState({ name: null, id: null });
  const columns = [
    { key: "id", title: "Group ID", dataIndex: "id" },
    { key: "name", title: "Group Name", dataIndex: "name" },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, params) => {
        return (
          <div className="flex justify-center items-center gap-1">
            <FaEye size={20} onClick={() => {}} />
            <BsFillTrash3Fill size={20} onClick={() => setDeleteGroup({ name: params.name, id: params.id })} />
          </div>
        );
      },
    },
  ];
  const rows = groups
    ? groups.map((group) => {
        return {
          id: group.id,
          name: group.name,
        };
      })
    : [];
  return (
    <div className="formPage-wrap min-h-screen">
      {!groups && <Loading />}
      {groups && (
        <div className="w-full overflow-auto">
          <ListFilters columns={columns} rows={rows} title={"Permission Group List"} />
        </div>
        // <DataGrid
        //   className="data-grid"
        //   columns={columns}
        //   rowCount={rows.length}
        //   rows={rows}
        //   slots={{
        //     Toolbar: GridToolbar,
        //   }}
        // />
      )}
      {deleteGroup.name && (
        <DeletePopUp
          name={deleteGroup.name}
          Func={() => {
            dispatch(
              deleteData(`permissions/api/v1/user-groups/${deleteGroup.id}/`, () =>
                setRerenderGroups(rerenderGroups + 1),
              ),
            );
            setDeleteGroup({ name: null, id: null });
          }}
          hideFunc={() => setDeleteGroup({ name: null, id: null })}
        />
      )}
    </div>
  );
}
