import React, { useEffect, useMemo, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Select from "react-select";
import useHotelNames from "../../../customHooks/useHotelNames";
import { deleteOffer, getContractOffers, getHotelActiveContracts } from "../../../redux/actions/offers";
import { useDispatch } from "react-redux";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { useNavigate } from "react-router";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { FaFileContract, FaWpforms } from "react-icons/fa6";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { BiTransfer } from "react-icons/bi";
import { LiaFileContractSolid } from "react-icons/lia";
import { MdOutlineDriveFileRenameOutline, MdOutlineSettings } from "react-icons/md";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";

export default function OffersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hotels = useHotelNames();
  const [contracts, setContracts] = useState([]);
  const [contractFlag, setContractFlag] = useState(false);
  const [chosenContract, setChosenContract] = useState(null);
  const [offers, setOffers] = useState([]);
  const [deleteOfferData, setDeleteOfferData] = useState({ name: null, id: null });

  const hotelsArr = useMemo(() => {
    return hotels.map((hotel) => ({ label: hotel.name, value: hotel.id }));
  }, [hotels]);

  const contractsArr = useMemo(() => {
    return contracts.map((contract) => ({ label: contract.name, value: contract.id }));
  }, [contracts]);

  const columns = [
    {
      field: "id",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
    },
    {
      field: "name",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineDriveFileRenameOutline className="w-5 h-5  text-yellow-500" />
          NAME
        </div>
      ),
      flex: 1,
    },
    {
      field: "contract",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <LiaFileContractSolid className="w-5 h-5  text-yellow-500" />
          CONTRACT NAME
        </div>
      ),
      flex: 1,
    },
    {
      field: "type",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <FaFileContract className="w-4 h-4  text-yellow-500" />
          TYPE
        </div>
      ),
      flex: 1,
    },
    {
      field: "actions",
      headerName: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <MdOutlineSettings className="w-4 h-4  text-yellow-500" />
          ACTIONS
        </div>
      ),
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="w-full flex items-center ml-4 gap-x-2">
            {permission.map((item) => {
              if (item.name === "offers.view_offer" && item.value === true) {
                return (
                  <Link to={`/dashboard/offers/${params.row.id}`}>
                    <AiFillEye className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800" />
                  </Link>
                );
              } else if (item.name === "offers.change_offer" && item.value === true) {
                return (
                  <Link to={`/dashboard/offers/edit-offer/${params.row.id}`}>
                    <FiEdit className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800" />
                  </Link>
                );
              } else if (item.name === "offers.delete_offer" && item.value === true) {
                return (
                  <AiFillDelete
                    className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
                    onClick={() => setDeleteOfferData({ name: params.row.name, id: params.row.id })}
                  />
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  const rows = useMemo(() => {
    return offers.map((offer) => {
      return {
        id: offer.id,
        name: offer.name,
        contract: offer.contract.name,
        type: offer.type,
      };
    });
  }, [offers]);

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["offers.view_offer", "offers.change_offer", "offers.delete_offer"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        <div className="w-full">
          <div className=" flex justify-center ">
            <h2 className="header-h2">
              <FaWpforms className="w-5 h-5" />
              Offer List
            </h2>
          </div>
          <div className="form-wrap">
            <div className="input-par">
              <div className="input-chil">
                <label htmlFor="hotel" className="text-slate-800 font-bold">
                  Please Choose Hotel First
                </label>
                <Select
                  options={hotelsArr}
                  id="hotel"
                  placeholder="Select Hotel"
                  className="text-gray-900 font-semibold capitalize"
                  isSearchable
                  onChange={(e) => {
                    setContractFlag(true);
                    dispatch(
                      getHotelActiveContracts(
                        e.value,
                        (data) => setContracts(data),
                        (result) => {
                          if (result.status === 200) {
                            setContractFlag(false);
                          } else {
                            setContracts(false);
                          }
                        },
                      ),
                    );
                    setOffers([]);
                    setChosenContract(null);
                  }}
                />
              </div>

              <div className="input-chil">
                <label htmlFor="contract" className="text-slate-800 font-bold">
                  Choose Contract
                </label>
                {contractFlag ? (
                  <div className="w-full flex items-center justify-center">
                    <ButtonLoading /> <TopBarProgress />
                  </div>
                ) : (
                  <Select
                    options={contractsArr}
                    id="contract"
                    isSearchable
                    className="text-gray-900 font-semibold capitalize"
                    placeholder="Select Contract"
                    value={chosenContract}
                    onChange={(e) => {
                      dispatch(
                        getContractOffers(e.value, (data) => {
                          setOffers(data);
                          setChosenContract(e);
                        }),
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {rows.length === 0 && (
            <div className="w-full flex flex-col gap-y-2 opacity-40 items-center justify-start h-full mt-48  ">
              <FaFileContract className="w-48 h-48 " />
              <div className="text-lg font-semibold text-gray-900">
                {chosenContract ? "This Contract Has No Offers Yet" : "No Contract Chosen Yet"}
              </div>
            </div>
          )}

          {rows.length > 0 && (
            <DataGrid
              columns={columns}
              rowCount={rows.length}
              rows={rows}
              slots={{
                Toolbar: GridToolbar,
              }}
            />
          )}
          {deleteOfferData.name && (
            <DeletePopUp
              name={deleteOfferData.name}
              Func={() => {
                dispatch(
                  deleteOffer(deleteOfferData.id, () => {
                    let filteredOffers = offers.filter((offer) => offer.id !== deleteOfferData.id);
                    setOffers(filteredOffers);
                  }),
                );
                setDeleteOfferData({ name: null, id: null });
              }}
              hideFunc={() => setDeleteOfferData({ name: null, id: null })}
            />
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
