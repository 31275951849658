import { RESEVE_DATA_FROM_SEARCH  , CLIENTREQUEST , DISPLAYRESERVATION , RESRVATION_NOTIF_FLAG} from "../types/types"

const initialState = {
        dataFromSearchForReseve : [],
        clientRequestData : [],
        displayReservationData:[],
        notificationResveFlag:false,
}

export  const reservationReducer = (state = initialState , action) => {
    switch(action.type){
        case RESEVE_DATA_FROM_SEARCH:
            return{...state , dataFromSearchForReseve : action.payload}
        case CLIENTREQUEST:
            return{...state , clientRequestData : action.payload  }
        case DISPLAYRESERVATION:
            return{...state , displayReservationData : action.payload  }
        case RESRVATION_NOTIF_FLAG:
            return{...state , notificationResveFlag : action.payload  }
        default:
            return state;
    }
}
