import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { MdOutlineDone } from "react-icons/md";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { TbListDetails } from "react-icons/tb";

const PosVisaWalletDetails = ({ wallet }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(getBankListAction(token));
  }, []);
  const { cashSelectedData } = useSelector((state) => state.cashReducer);
  const { cashSelectedDataFlag } = useSelector((state) => state.cashReducer);
  const { bankData } = useSelector((state) => state.cashReducer);

  let BankFilter = bankData && wallet ? bankData.filter((item) => item.id === wallet?.bank) : [];
  return (
    <div className="flex flex-col    w-full  text-black rounded-md  ">
      <div className=" flex items-center justify-center w-full border  rounded-lg text-xl font-semibold text-black h-16">
        <p className="flex items-center gap-x-3">
          <TbListDetails className="w-5 h-5" />
          Method OF Payment (POS(Visa))
        </p>
      </div>
      {/* Hotel id & id & persons*/}

      {/* WhatsApp Number & Email*/}
      <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Amount</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-1 ">
              {wallet.amount}
              <span>{wallet.currency}</span>
            </p>
          </div>
        </div>
        <div className="flex items-center w-[50%]">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">ID</p>
          </div>
          <div>
            <p className="flex items-center gap-x-1"> {wallet.id}</p>
          </div>
        </div>
      </div>
      {/* Client text & Client Tag Request*/}
      <div className="flex w-full justify-between items-center  p-3  ">
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Commission</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">{wallet.commission} </p>
            <p className="flex items-center gap-x-1"></p>
          </div>
        </div>
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">MID Number</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">{wallet.mid_number}</p>
          </div>
        </div>
      </div>
      {/* Hotel & Room Name*/}
      <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Balance</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">
              {wallet.balance}
              <span>{wallet.currency}</span>
            </p>
          </div>
        </div>

        <div className="flex items-center w-[50%]">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Booked Room</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-1">{wallet.booked_room}</p>
          </div>
        </div>
      </div>
      {/* Client text & Client Tag Request*/}
      <div className="flex w-full justify-between items-center  p-3  ">
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Convertion Price </p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">
              {wallet.conversion_rate_price}
              <span>{wallet.contract_currency}</span>
            </p>
          </div>
        </div>
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Source Name </p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">{wallet.source_name}</p>
          </div>
        </div>
      </div>
      {/* Client text & Client Tag Request*/}
      <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Currency</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">{wallet.currency} </p>
            <p className="flex items-center gap-x-1"></p>
          </div>
        </div>

        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Bank </p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">{BankFilter && BankFilter[0].name}</p>
            <p className="flex items-center gap-x-1"></p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between items-center  p-3 ">
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Contract Currency</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">{wallet.contract_currency} </p>
          </div>
        </div>

        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Created Date</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">{wallet.created_at.slice(0, 10)} </p>
            <p className="flex items-center gap-x-1"></p>
          </div>
        </div>
      </div>
      {/* Client & phone Number*/}
      <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Convertion Rate </p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            <p className="flex items-center gap-x-2">{wallet.conversion_rate}</p>
          </div>
        </div>

        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Transactions Receipt</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800">
            {wallet.transaction_receipt ? (
              <img src={wallet.transaction_receipt} className="w-36 h-36" />
            ) : (
              <p className="font-semibold text-sm">No Attachment</p>
            )}
          </div>
        </div>
      </div>
      {/* Client & phone Number*/}
      <div className="flex w-full justify-between items-center  p-3 ">
        <div className="flex  justify-between items-center w-[50%] ">
          <div className="w-[33%]  ">
            <p className="flex items-center gap-x-1 font-bold">Transaction Status</p>
          </div>
          <div className="w-[70%] font-semibold text-gray-800 capitalize">
            {wallet.transaction_status === "confirmed" ? (
              <p className="w-full flex items-center  justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-green-700 font-semibold">
                {wallet.transaction_status} <MdOutlineDone className="bg-green-700 border text-white rounded-full  " />
              </p>
            ) : (
              <p className="w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-yellow-600 font-semibold">
                {wallet.transaction_status}....
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosVisaWalletDetails;
