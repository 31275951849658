import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { FaWpforms } from "react-icons/fa6";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { AiOutlinePercentage } from "react-icons/ai";
import lightToast from "light-toast";
import {
  getCurrencyMidNumber,
  getMidNumber,
  getSelectedPosVisa,
  updatePosVisaAction,
} from "../../../redux/actions/posVisaAction";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { RxUpdate } from "react-icons/rx";
import TopBarProgress from "react-topbar-progress-indicator";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import { useFormik } from "formik";
import * as Yup from "yup";
import PopUp from "../PopUp/PopUp";

const UpdatePosVisa = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const { selectetdPosVisa } = useSelector((state) => state.posVisaReducer);
  const params = useParams();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [bank, setBank] = useState("");
  const [bankDataFilter, setBankDataFilter] = useState("");
  const [posFlag, setPosFlag] = useState(false);
  const [imgFile, setImgFile] = useState();
  const [bankId, setBankId] = useState();
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const { bankData } = useSelector((state) => state.cashReducer);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      source_name: "",
      amount: "",
      commission: "",
      transaction_receipt: "",
      mid_number: "",
    },
    validationSchema: Yup.object({
      source_name: Yup.string()
        .required("Please enter source name")
        .min(1, "source name must be greater than or equal to 2")
        .max(50, "source name must be smaller than or equal to 50"),
      mid_number: Yup.string().required("Please select MID Number"),
      amount: Yup.number()
        .required("Please enter an amount")
        .positive(" Amount must be positive number")
        .integer(" Amount must be positive number")
        .min(0, " Amount must be greater than or equal to 0"),
      commission: Yup.number()
        .required("Please enter an commission")
        .positive(" Commission must be positive number")
        .min(0, " Commission must be greater than or equal to 0")
        .max(9.99, "Commission must be smaller than 10%")
        .transform((originalValue, originalObject) => {
          // Ensure the number has at most 2 decimal places
          return parseFloat(originalValue.toFixed(2));
        })
        .typeError("Invalid number format. Please enter a valid number with at most 2 decimal places"),
      // transaction_receipt: Yup.mixed().required("Please upload a transaction receipt"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      const { commission, conversion_rate_price, conversion_rate, balance, ...restData } = values;
      Object.keys(restData).forEach((key) => {
        formData.append(key, restData[key]);
      });
      formData.append("commission", Number(commission).toFixed(2));
      formData.append("conversion_rate_price", Number(conversion_rate_price).toFixed(2));
      formData.append("conversion_rate", Number(conversion_rate).toFixed(2));
      formData.append("balance", Number(balance).toFixed(2));
      formData.append("booked_room", params.bookedroom);
      formData.append("bank", bankId);
      setSubmitting(true);
      dispatch(
        updatePosVisaAction(token, params.id, formData, (result) => {
          if (result.status === 200) {
            setSubmitting(false);
            lightToast.success("The POS Visa Form was completed Updated");
            navigate(-1);
          } else {
            setSubmitting(false);
          }
        }),
      );
    },
  });

  useEffect(() => {
    //////////handle when reload page , alert show to make sure this i want reload ////////////
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    //////////////////////////////////////////////////////
    dispatch(getMidNumber(token, signal));
    dispatch(
      getBankListAction(
        token,
        (resultBank) => {
          if (resultBank.status === 200) {
            setBankDataFilter(resultBank.data);
            dispatch(
              getSelectedPosVisa(
                token,
                params.id,
                (result) => {
                  if (result.status === 200) {
                    const bankFilter = resultBank.data.filter((item) => item.id === result.data.bank);
                    const bankForm = bankFilter.map((item) => item.name);
                    const bankFormId = bankFilter.map((item) => item.id);

                    setFieldValue("source_name", result.data.source_name);
                    setFieldValue("currency", result.data.currency);
                    setBank(...bankForm);
                    setBankId(...bankFormId);
                    setFieldValue("mid_number", result.data.mid_number);
                    setFieldValue("amount", result.data.amount);
                    setFieldValue("commission", result.data.commission);
                    setFieldValue("balance", result.data.balance);
                    setFieldValue("conversion_rate_price", result.data.conversion_rate_price);
                    setFieldValue("conversion_rate", result.data.conversion_rate);
                    setFieldValue("contract_currency", result.data.contract_currency);
                    setPosFlag(true);
                    setToggle(true);
                  }
                },
                signal,
              ),
            );
          }
        },
        signal,
      ),
    );
    return () => {
      controller.abort();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const { midNumberData } = useSelector((state) => state.posVisaReducer);

  let Bank = bankData && selectetdPosVisa ? bankData.filter((item, index) => item.id === selectetdPosVisa.bank) : [];

  let midNumber = midNumberData
    ? midNumberData.map((item, index) => {
        return { value: item.id, label: item.mid_number };
      })
    : [];

  const handleAmount = (e) => {
    setFieldValue("amount", e.target.value);
    if (values.commission) {
      const calc = (e.target.value * values.commission) / 100;
      setFieldValue("balance", e.target.value - calc);
      setFieldValue("conversion_rate_price", (e.target.value - calc) * values.conversion_rate);
    }
  };

  const handleInputChange = (e) => {
    setFieldValue("commission", e.target.value);
    const calc = (values.amount * e.target.value) / 100;
    setFieldValue("balance", values.amount - calc);
    if (values.conversion_rate) {
      setFieldValue("conversion_rate_price", (values.amount - calc) * values.conversion_rate);
    }
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const bankSelect = bankDataFilter.filter((item) => item.name === bank);
  //
  //
  //   const formData = new FormData(e.target);
  //   formData.append("booked_room", params.bookedroom);
  //   formData.append("mid_number", mid);
  //   formData.append("currency", currency);
  //   formData.append("bank", bankSelect[0].id);
  //   formData.append("commission", Number(commission).toFixed(2));
  //   formData.append("balance", Number(balance).toFixed(2));
  //   formData.append("contract_currency", contractCurrency);
  //   formData.append("conversion_rate_price", Number(convertAmount).toFixed(2));
  //   formData.append("conversion_rate", convertRate);
  //   dispatch(
  //     updatePosVisaAction(token, params.id, formData, (result) => {
  //       if (result.status === 200) {
  //         lightToast.success("The POS Visa Form was completed Updated");
  //         navigate(-1);
  //       }
  //     })
  //   );
  // };
  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.change_posmachinetransaction"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {midNumberData && selectetdPosVisa && permissionFlag ? (
          permissionFlag &&
          permission.map((item) => {
            if (item.name === "accounting.change_posmachinetransaction" && item.value === true) {
              return (
                <div className=" w-full ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      <FaWpforms className="w-5 h-5" />
                      Update POS Visa Amount Form
                    </h2>
                  </div>
                  <form className="form-wrap  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <label className=" text-center w-full text-gray-600">Source Name</label>
                      <input
                        placeholder="Source Name"
                        name="source_name"
                        type="text"
                        defaultValue={values.source_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`w-full px-3 h-10  border rounded-md  bg-white ${
                          touched.source_name && errors.source_name && "border border-red-800"
                        } `}
                      />
                      {touched.source_name && errors.source_name ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.source_name}
                        </div>
                      ) : null}

                      <label className=" flex text-gray-600 ">MID Number</label>
                      <Select
                        name="mid_number"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${touched.mid_number && errors.mid_number && "rgb(153 27 27)"}`,
                          }),
                        }}
                        options={midNumber}
                        defaultValue={[
                          {
                            label: selectetdPosVisa && selectetdPosVisa.mid_number,
                            value: selectetdPosVisa && selectetdPosVisa.mid_number,
                          },
                        ]}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("mid_number", e.label);
                          setToggleLoading(true);
                          setToggle(false);
                          dispatch(
                            getCurrencyMidNumber(token, e.label, (result) => {
                              if (result.status === 200) {
                                setToggle(true);
                                setToggleLoading(false);
                                setFieldValue("currency", result.data.currency);
                                setBank(result.data.bank);
                                setBankId(Bank.filter((item) => item.label === bank));

                                dispatch(
                                  convertingCurrencyAction(
                                    token,
                                    result.data.currency,
                                    values.contract_currency,
                                    (result) => {
                                      if (result.status === 200) {
                                        result.data.results.length !== 0
                                          ? setFieldValue(
                                              "conversion_rate_price",
                                              values.amount &&
                                                values.amount * result.data.results.map((item) => item.rate),
                                            )
                                          : setFieldValue("conversion_rate_price", values.amount && values.amount * 1);
                                        result.data.results.length !== 0
                                          ? setFieldValue(
                                              "conversion_rate",
                                              ...result.data.results.map((item) => item.rate),
                                            )
                                          : setFieldValue("conversion_rate", 1);
                                      }
                                    },
                                  ),
                                );
                              }
                            }),
                          );
                        }}
                        isSearchable
                        className=" w-full  h-10   rounded-md  bg-white "
                        placeholder="Mid Number"
                      />
                      {touched.mid_number && errors.mid_number ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.mid_number}
                        </div>
                      ) : null}
                      {toggle ? (
                        <>
                          <label className=" flex text-gray-600 ">Currency</label>
                          <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{values.currency}</p>
                          </div>

                          <label className=" flex text-gray-600 ">Bank Name</label>
                          <div name="bank" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{bank}</p>
                          </div>
                        </>
                      ) : (
                        toggleLoading && (
                          <div className=" w-full px-3 h-10 flex items-center justify-center  ">
                            <ButtonLoading />
                          </div>
                        )
                      )}

                      <label className=" flex text-gray-600">Amount</label>
                      <input
                        placeholder="Amount"
                        name="amount"
                        type="Number"
                        step="any"
                        defaultValue={values.amount}
                        onChange={handleAmount}
                        onBlur={handleBlur}
                        className={`w-full px-3 h-10  border rounded-md  bg-white ${
                          touched.amount && errors.amount && "border border-red-800"
                        } `}
                      />
                      {touched.amount && errors.amount ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.amount}
                        </div>
                      ) : null}
                      {values.amount && (
                        <>
                          <label className=" flex text-gray-600 ">Commission</label>
                          <div className="w-full relative text-gray-600">
                            <input
                              placeholder="commission"
                              name="commission"
                              type="number"
                              step="any" // set the step to allow up to two decimal places
                              defaultValue={values.commission}
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              className={`w-full px-8 h-10  border rounded-md  bg-white ${
                                touched.commission && errors.commission && "border border-red-800"
                              } `}
                            />
                            <div className="absolute top-3 left-2">
                              <AiOutlinePercentage className="w-5 h-5 text-gray-400" />
                            </div>
                          </div>
                          {touched.commission && errors.commission ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.commission}
                            </div>
                          ) : null}
                        </>
                      )}
                      {values.commission && 9.99 >= values.commission && values.commission >= 0 && (
                        <>
                          <label className=" flex text-gray-600">Balance</label>
                          <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                            {values.commission && 9.99 >= values.commission && values.commission >= 0 && (
                              <p className="text-black "> {values.balance}</p>
                            )}
                          </div>
                        </>
                      )}
                      {values.commission && 9.99 >= values.commission && values.commission >= 0 && values.currency && (
                        <>
                          <label className=" flex text-gray-600 ">
                            Amount With Currency Of Contract ({values.contract_currency})
                          </label>
                          <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                            <div className="text-black flex items-end gap-x-1">
                              {values.conversion_rate_price}{" "}
                              <span className="text-sm font-semibold text-gray-700">{values.contract_currency}</span>
                            </div>
                          </div>
                        </>
                      )}

                      <label className=" flex text-gray-600 ">Visa Copy</label>
                      <input
                        placeholder="Visa Copy"
                        name="transaction_receipt"
                        type="file"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("transaction_receipt", e.target.files[0]);
                          setImgFile(URL.createObjectURL(e.target.files[0]));
                        }}
                        className={` w-full px-3 h-10 py-1 border rounded-lg bg-white ${
                          touched.transaction_receipt && errors.transaction_receipt && "border border-red-800"
                        } `}
                      />
                      {/* {touched.transaction_receipt && errors.transaction_receipt ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.transaction_receipt}
                        </div>
                      ) : null} */}

                      {imgFile ? (
                        <div className="w-full flex justify-center items-center">
                          <img src={imgFile} className="w-48 h-48 cursor-pointer" onClick={() => setIsOpen(true)} />
                        </div>
                      ) : (
                        selectetdPosVisa?.transaction_receipt &&
                        !values.transaction_receipt && (
                          <div className="w-full flex justify-center items-center">
                            <img
                              src={selectetdPosVisa?.transaction_receipt}
                              className="w-48 h-48 cursor-pointer"
                              onClick={() => setIsOpen(true)}
                            />
                          </div>
                        )
                      )}
                      <button
                        type="Submit"
                        disabled={isSubmitting}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-md bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Update{" "}
                        {isSubmitting ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <RxUpdate className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                  <PopUp
                    close={closeModal}
                    isOpen={isOpen}
                    image={imgFile ? imgFile : selectetdPosVisa?.transaction_receipt}
                  />
                </div>
              );
            } else {
              return <NoPermission />;
            }
          })
        ) : (
          <>
            <TopBarProgress /> <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default UpdatePosVisa;
