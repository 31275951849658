import React, { useEffect, useMemo, useRef, useState } from "react";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonLoading from "../../ButtonLoading/ButtonLoading";

const validationSchema = Yup.object().shape({
  city: Yup.object().nullable().required("City is required"),
  area: Yup.object().nullable().required("Area is required"),
  hotel: Yup.string().required("Hotel is required"),
  contact_person: Yup.object().nullable().required("Contact person is required"),
  preferred_contact_method: Yup.object().nullable().required("Method of request is required"),
});

const fetchCities = () => fetchDataQuery("/search/api/v1/get-cities/");
const fetchAreas = (city) => fetchDataQuery(`/search/api/load-areas/?city_name=${city}`);
const fetchHotels = (city, area) => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]&areas=[${area}]`);
const fetchContacts = (hotel) => fetchDataQuery(`/hotels/api/hotel-contact/new?hotel=${hotel}`);
const fetchPreferedContact = (hotel, contact) =>
  fetchDataQuery(`/sales-management/api/v1/special-rate-tool-setup/?contact_person=${contact}&hotel=${hotel}`);

const SpecialRateSetupForm = ({ initialValues, onSubmit, isLoading }) => {
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [chosenHotelVal, setChosenHotelsVal] = useState(null);
  const [preferedMethod, setPreferedMethod] = useState(null);

  const [contactPersonData, setContactPersonData] = useState(null);
  // const [contactPerson, setContactPerson] = useState(null);

  const formikRef = useRef(null); // Ref to access Formik instance
  const {
    data: rateContact,
    isSuccess: isContactSuccess,
    isLoading: contactLoading,
    refetch: refetchContact,
  } = useQuery({
    queryKey: ["rateContact", chosenHotelVal?.value],
    queryFn: () => fetchDataQuery(`/sales-management/api/v1/special-rate-tool-setup/?hotel=${chosenHotelVal?.value}`),
    enabled: !!chosenHotelVal?.value, // Fetch only if  and hotel exists
  });

  useEffect(() => {
    if (rateContact && isContactSuccess) {
      setContactPersonData(rateContact);
    }
  }, [rateContact, isContactSuccess]);
  useEffect(() => {
    if (rateContact && chosenHotelVal?.value && contactPersonData) {
      refetchContact();
    }
  }, [rateContact, chosenHotelVal?.value, refetchContact, contactPersonData]);
  // const {
  //   data: preferedcontacts,
  //   isLoading: loadingpreferedContacts,
  //   isSuccess: preferedcontactSuccess,
  //   error: preferedcontactError,
  //   refetch: refetchpreferedContacts,
  // } = useQuery({
  //   queryKey: ["contacts", chosenHotelVal?.value && contactPerson?.value],
  //   queryFn: () => fetchPreferedContact(chosenHotelVal?.value, contactPerson?.value),
  //   enabled: !!chosenHotelVal?.value && !!contactPerson?.value,
  // });
  // useEffect(() => {
  //   if (chosenHotelVal?.value && contactPerson?.value) {
  //     refetchpreferedContacts();
  //   }
  // }, [chosenHotelVal?.value, contactPerson?.value, refetchpreferedContacts]);
  const {
    data: cities,
    isLoading: loadingCities,
    error: citiesError,
  } = useQuery({ queryKey: "cities", queryFn: fetchCities });

  const {
    data: areas,
    isLoading: loadingAreas,
    error: areasError,
    refetch: refetchAreas,
  } = useQuery({
    queryKey: ["areas", chosenCity?.value],
    queryFn: () => fetchAreas(chosenCity?.value),
    enabled: !!chosenCity?.value && chosenCity?.value !== "All",
  });

  const {
    data: hotels,
    isLoading: loadingHotels,
    error: hotelsError,
    refetch: refetchHotels,
  } = useQuery({
    queryKey: ["hotels", chosenCity?.value, chosenArea],
    queryFn: () => {
      const areaValue = chosenArea?.value === "All" ? areas.map((area) => area.id) : chosenArea?.value;
      return fetchHotels(chosenCity?.value, areaValue);
    },
    enabled: !!chosenCity?.value && (!!chosenArea || chosenCity?.value === "All"),
  });

  useEffect(() => {
    if (chosenCity) {
      if (chosenCity?.value !== "All") {
        refetchAreas();
      } else {
        setChosenArea({ label: "All", value: "All" });
      }
    }
  }, [chosenCity, refetchAreas]);

  useEffect(() => {
    if (chosenArea) {
      refetchHotels();
    }
  }, [chosenArea, refetchHotels]);

  useEffect(() => {
    if (hotels) {
      setChosenHotels(hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })));
    }
  }, [hotels]);

  const cityOptions = useMemo(() => cities?.map((city) => ({ label: city.name, value: city.id })), [cities]);
  const areaOptions = useMemo(
    () => [{ label: "All", value: "All" }, ...(areas?.map((area) => ({ label: area.name, value: area.id })) || [])],
    [areas],
  );

  const hotelOptions = useMemo(() => [...chosenHotels], [chosenHotels]);

  useEffect(() => {
    if (contactPersonData) {
      const selectedPerson = contactPersonData.contacts.find(
        (contact) => contact.contact_id === initialValues.contact_person?.value,
      );
      if (selectedPerson) {
        // Function to update the preferred contact method options
        const updatePreferredContactMethods = (person) => {
          const filteredTelephoneOptions = person.telephone_number
            ? Object.entries(person.telephone_number).map(([key, value]) => ({
                label: value,
                value,
              }))
            : [];

          const filteredEmailOptions = person.email
            ? Object.entries(person.email).map(([key, value]) => ({
                label: value,
                value,
              }))
            : [];

          const filteredWhatsGroupOptions = person.whatsapp_group
            ? Object.entries(person.whatsapp_group).map(([key, value]) => ({
                label: value,
                value,
              }))
            : [];

          // Update Formik's state for preferred contact method options
          setPreferedMethod({
            telephoneOptions: filteredTelephoneOptions,
            emailOptions: filteredEmailOptions,
            whatsGroupOptions: filteredWhatsGroupOptions,
          });
        };

        // Update the preferred contact methods if the selected person changes
        updatePreferredContactMethods(selectedPerson);
      }
    }
  }, [contactPersonData, initialValues.contact_person?.value]);


  return (
    <section className="formPage-wrap">
      <Formik
        enableReinitialize={true}
        innerRef={formikRef} // Access Formik instance via ref
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form className="form-wrap gap-4 grid">
            {/* City and Area */}
            <div className="input-par">
              <div className="input-chil">
                <label className="font-semibold w-full flex gap-x-2 text-[#002244]">City</label>
                <Select
                  className="w-full h-10 text-gray-800 font-semibold border rounded-lg bg-white"
                  options={cityOptions}
                  placeholder="Select City"
                  onChange={(e) => {
                    setChosenCity(e);
                    setFieldValue("city", e);
                    setFieldValue("hotel", "");

                    setFieldValue("area", "");
                    setChosenArea("");
                    setChosenHotelsVal("");
                  }}
                  value={chosenCity}
                  isLoading={loadingCities}
                />
                {citiesError && <p className="text-red-500">{citiesError.message}</p>}
              </div>
              <div className="input-chil">
                <label className="w-full text-gray-600 font-semibold flex items-center">Area</label>
                <Select
                  name="area"
                  options={areaOptions}
                  isSearchable
                  value={chosenArea}
                  isDisabled={!chosenCity?.value}
                  className="w-full h-10 rounded-lg bg-white"
                  placeholder="Select Area"
                  onChange={(e) => {
                    setChosenArea(e);
                    setFieldValue("area", e);
                    setFieldValue("hotel", "");
                    setChosenHotelsVal("");
                  }}
                />
                <ErrorMessage name="area" component="div" className="text-sm text-red-500" />
              </div>
            </div>
            {/* Hotel and Contact Person */}
            {
              <div className="input-par">
                <div className="input-chil">
                  <label className="w-full text-gray-600 font-semibold flex items-center">Hotel</label>
                  <Select
                    name="hotel"
                    options={hotelOptions} // Assuming hotelOptions is defined
                    isSearchable
                    isDisabled={chosenCity && chosenArea ? false : true}
                    value={chosenHotelVal} // The selected hotel value
                    className="w-full rounded-lg bg-white"
                    placeholder="Choose Hotel"
                    onChange={(e) => {
                      // Set the selected hotel value in Formik first
                      setFieldValue("hotel", e?.value);
                      setChosenHotelsVal(e);

                      // Log to confirm the current state of contactPersonData

                      // Ensure that contactPersonData is not null or undefined
                      if (contactPersonData) {
                        const selectedPerson = contactPersonData.contacts.find(
                          (contact) => contact.contact_id === values.contact_person?.value,
                        );

                        if (selectedPerson) {
                          // Function to update the preferred contact method options
                          const updatePreferredContactMethods = (person) => {
                            const filteredTelephoneOptions = person.telephone_number
                              ? Object.entries(person.telephone_number).map(([key, value]) => ({
                                  label: value,
                                  value,
                                }))
                              : [];

                            const filteredEmailOptions = person.email
                              ? Object.entries(person.email).map(([key, value]) => ({
                                  label: value,
                                  value,
                                }))
                              : [];

                            const filteredWhatsGroupOptions = person.whatsapp_group
                              ? Object.entries(person.whatsapp_group).map(([key, value]) => ({
                                  label: value,
                                  value,
                                }))
                              : [];

                            // Update Formik's state for preferred contact method options
                            setPreferedMethod({
                              telephoneOptions: filteredTelephoneOptions,
                              emailOptions: filteredEmailOptions,
                              whatsGroupOptions: filteredWhatsGroupOptions,
                            });
                          };

                          // Update the preferred contact methods if the selected person changes
                          updatePreferredContactMethods(selectedPerson);
                        }
                      }

                      // Clear contact person and preferred contact method if hotel is cleared
                      if (!e) {
                        setFieldValue("contact_person", null);
                        setFieldValue("preferred_contact_method", null);
                        setPreferedMethod({
                          telephoneOptions: [],
                          emailOptions: [],
                          whatsGroupOptions: [],
                        });
                      }
                    }}
                  />

                  <ErrorMessage name="hotel" component="div" className="text-sm text-red-500" />
                </div>
                {chosenHotelVal && contactPersonData?.contacts?.length > 0 && (
                  <div className="input-chil">
                    <label className="w-full text-gray-600 font-semibold flex items-center">Contact Person</label>
                    <Select
                      name="contact_person"
                      options={
                        contactPersonData
                          ? contactPersonData?.contacts?.map((contact) => ({
                              label: contact.contact_name,
                              value: contact.contact_id,
                            }))
                          : []
                      }
                      value={chosenHotelVal?.value && values?.contact_person}
                      isSearchable
                      isDisabled={!chosenHotelVal}
                      className="w-full rounded-lg bg-white"
                      placeholder="Select Contact"
                      onChange={(selectedContact) => {
                        setFieldValue("contact_person", selectedContact);
                        // setContactPerson(selectedContact);
                        setFieldValue("preferred_contact_method", null); // Clear preferred contact method on contact change

                        const selectedPerson = contactPersonData?.contacts?.find(
                          (contact) => contact.contact_id === selectedContact?.value,
                        );
                        // Filter the preferred contact methods based on the selected contact
                        const filteredTelephoneOptions = selectedPerson?.telephone_number
                          ? Object.entries(selectedPerson?.telephone_number)?.map(([key, value]) => ({
                              label: value,
                              value,
                            }))
                          : [];

                        const filteredEmailOptions = selectedPerson?.email
                          ? Object.entries(selectedPerson?.email)?.map(([key, value]) => ({
                              label: value,
                              value,
                            }))
                          : [];

                        const filteredWhatsGroupOptions = selectedPerson?.whatsapp_group
                          ? Object.entries(selectedPerson?.whatsapp_group).map(([key, value]) => ({
                              label: value,
                              value,
                            }))
                          : [];

                        // Update Formik's state for preferred contact method
                        // setFieldValue("preferred_contact_method", null); // Reset the selected method
                        // Update the local state for contact method options
                        setPreferedMethod({
                          telephoneOptions: filteredTelephoneOptions,
                          emailOptions: filteredEmailOptions,
                          whatsGroupOptions: filteredWhatsGroupOptions,
                        });
                      }}
                    />
                    <ErrorMessage name="contact_person" component="div" className="text-sm text-red-500" />
                  </div>
                )}
              </div>
            }
            {/* WhatsApp (Telephone Numbers) */}
            {chosenHotelVal && contactPersonData?.contacts.length > 0 ? (
              <div className="input-par">
                <div className="input-chil">
                  <label className="w-full text-gray-600 font-semibold flex items-center">Method of Request</label>

                  <Select
                    name="preferred_contact_method"
                    options={[
                      {
                        label: "WhatsApp",
                        options: preferedMethod?.telephoneOptions || [], // Filtered phone numbers for WhatsApp
                      },
                      {
                        label: "Email",
                        options: preferedMethod?.emailOptions || [], // Filtered email options
                      },
                      {
                        label: "WhatsApp Group", // Title for WhatsApp Group
                        options: preferedMethod?.whatsGroupOptions || [], // Filtered WhatsApp group options
                      },
                    ]}
                    // defaultValue={chosenHotelVal?.value && values?.preferred_contact_method}
                    value={
                      values?.preferred_contact_method?.name
                        ? {
                            label: values?.preferred_contact_method.name,
                            value: values?.preferred_contact_method.value,
                          }
                        : values?.preferred_contact_method
                    } // Formik's controlled value
                    isSearchable
                    isDisabled={!values?.contact_person || !chosenHotelVal?.value}
                    className="w-full rounded-lg bg-white"
                    placeholder="Select Method of Request"
                    onChange={(selectedOption) => {
                      // Find the parent group label by looking through the filtered options
                      let parentLabel = "";
                      const allOptions = [
                        {
                          label: "whatsapp_number",
                          options: preferedMethod?.telephoneOptions || [],
                        },
                        {
                          label: "email",
                          options: preferedMethod?.emailOptions || [],
                        },
                        {
                          label: "whatsApp_group",
                          options: preferedMethod?.whatsGroupOptions || [],
                        },
                      ];

                      allOptions.forEach((group) => {
                        if (group.options.some((option) => option.value === selectedOption.value)) {
                          parentLabel = group.label;
                        }
                      });

                      // Update Formik's form state
                      const updatedMethod = {
                        // name: parentLabel, // Store the type (whatsapp_number, email, etc.)
                        label: selectedOption.label, // Display label
                        value: parentLabel,
                      };
                      setFieldValue("preferred_contact_method", updatedMethod);
                    }}
                  />

                  <ErrorMessage name="preferred_contact_method" component="div" className="text-sm text-red-500" />
                </div>
              </div>
            ) : (
              <p className="text-gray-500 text-lg text-center form-wrap">No Contacts Available</p>
            )}
            {chosenHotelVal && contactPersonData?.contacts.length > 0 && (
              <div className="w-full flex justify-center items-center gap-x-4 px-1 my-6">
                <button type="submit" className="addButton" disabled={isLoading}>
                  {isLoading ? (
                    <span className="flex items-center gap-2">
                      {" "}
                      Adding... <ButtonLoading />{" "}
                    </span>
                  ) : (
                    "Edit Special Rate Setup"
                  )}
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default SpecialRateSetupForm;
