import { TOGGLE_NIGHT_MODE } from "../../redux/types/types";

const defaultState = false;

export  function isNightModeReducer(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_NIGHT_MODE:
      return !state;
    default:
      return state;
  }
}
