// Utility function to check if two arrays are equal
export const arraysEqual = (arr1, arr2) =>
  arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);

// Utility function to get the missing number in an array
export function findMissingNumberInArray(arr) {
  // Remove duplicates by converting to a Set and back to an array
  const uniqueArr = [...new Set(arr)];

  // Sort the array
  uniqueArr.sort((a, b) => a - b);

  // Loop through the uniqueArr to find the missing number
  for (let i = 0; i < uniqueArr.length - 1; i++) {
    if (uniqueArr[i + 1] !== uniqueArr[i] + 1) {
      return uniqueArr[i] + 1; // Return the missing number
    }
  }

  // If no missing number is found, return null or an appropriate message
  return null; // or return 'No missing number';
}
