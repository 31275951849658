import React, { useRef, useState } from "react";
import { useParams } from "react-router";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ErrorMessage, Form, Formik } from "formik";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "ckeditor5/ckeditor5.css";
import CustomUploadAdapterPlugin from "../ITDepartment/Tickets/CustomUploadAdapterPlugin";
import { FieldErrorText } from "../../../customComponents/FormComponents";
import mutateDataQuery from "../../../react_query/mutateDataQuery";

function SpeicalRateReplyForm({ refetchRateDetails }) {
  const { id } = useParams();
  const [editorData, setEditorData] = useState("");
  const [editorInstance, setEditorInstance] = useState(null);
  const [editorKey, setEditorKey] = useState(0);
  const fileInputRef = useRef(null);

  const { isPending: isAddingReply, mutate: addReply } = useMutation({
    mutationKey: ["add-rate"],
    mutationFn: (newRate) => {
      return mutateDataQuery("/sales-management/api/v1/special-rate-comments/", "post", newRate, {
        "Content-Type": "multipart/form-data",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Reply Added Successfully");
    },
    onError: () => {},
  });

  function handleSubmit(values, helpers) {
    const formDate = new FormData();

    formDate.append("special_rate", id);
    formDate.append("comment", editorData);

    values.attachments.forEach((attachment) => {
      formDate.append("attachments", attachment);
    });

    addReply(formDate, {
      onSuccess: () => {
        helpers.resetForm();
        refetchRateDetails();
        if (editorInstance) {
          editorInstance.setData("");
          setEditorKey((prevKey) => prevKey + 1); // Change key to force re-render
        }
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear the file input
        }
      },
    });
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const editorConfig = {
    extraPlugins: [CustomUploadAdapterPlugin],
    language: {
      // The UI will be English.
      ui: "en",
      // But the content will be edited in Arabic.
      content: "ar",
    },
    toolbar: {
      items: [
        "undo",
        "redo",
        "fontfamily",
        "fontsize",
        "|",
        "bold",
        "italic",
        "|",
        "link",
        "uploadImage",
        "|",
        "alignment", // Add the alignment option to the toolbar
      ],
      shouldNotGroupWhenFull: true,
    },
    alignment: {
      options: ["left", "center", "right", "justify"],
    },
  };

  return (
    <Formik
      initialValues={{
        attachments: [],
      }}
      onSubmit={handleSubmit}
    >
      {({ errors, setFieldValue, values }) => {
        return (
          <Form className="flex flex-col mt-4 justify-center items-center">
            <div className=" px-6 w-full">
              <div className="w-full relative text-gray-600">
                {/* <Field
                  id={"reply"}
                  rows={5}
                  name="reply"
                  className={`w-full py-2 px-8 ${errors.details && "border-red-500"} border rounded-lg bg-white`}
                  as="textarea"
                  placeholder="Add reply to this ticket"
                /> */}

                <CKEditor
                  key={editorKey} // Force re-render by changing key
                  editor={ClassicEditor}
                  data={editorData}
                  onChange={handleEditorChange}
                  config={editorConfig}
                  onReady={(editor) => {
                    setEditorInstance(editor);
                  }}
                />
              </div>
              <ErrorMessage name={"reply"}>
                {(msg) => {
                  return <FieldErrorText message={msg} />;
                }}
              </ErrorMessage>
              {/* <div className="w-full mt-5 flex flex-col   ">
                <p className="py-2 text-base font-bold text-cyan-900">attachments</p>
                <input
                  id="attachments"
                  name="attachments"
                  type="file"
                  multiple
                  onChange={(event) => {
                    const files = event.currentTarget.files;
                    let myFiles = Array.from(files);
                    void setFieldValue("attachments", myFiles);
                  }}
                  ref={fileInputRef} // Reference the file input
                />
              </div> */}
            </div>

            <div className="flex mt-4 justify-center items-end">
              <Button
                className="mb-4"
                isProcessing={isAddingReply}
                type="submit"
                disabled={isAddingReply}
                processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              >
                Reply
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SpeicalRateReplyForm;
