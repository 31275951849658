import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import lightToast from "light-toast";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import { deleteRefundAction, getAllRefundAction } from "../../../redux/actions/refundAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { LiaCalendar } from "react-icons/lia";
import { MdOutlineAccessTime } from "react-icons/md";
import { IoCloudyNightOutline } from "react-icons/io5";
import { PiDatabase } from "react-icons/pi";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import ListFilters from "../../../utils/ListFilters";

const AllRefundRequest = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [id, setId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const { deleteRefundFlag } = useSelector((state) => state.refundReducer);
  const { allRefundData } = useSelector((state) => state.refundReducer);

  useEffect(() => {
    dispatch(
      getAllRefundAction(token, (result) => {
        if (result.status === 200) {
          setCheck(true);
        }
      }),
    );
  }, []);

  const handleDelete = (id) => {
    dispatch(
      deleteRefundAction(token, id, (result) => {
        if (result.status === 204) {
          lightToast.success("The Refund Request was completed Deleted");
          dispatch(
            getAllRefundAction(token, (result) => {
              if (result.status === 200) {
                setCheck(true);
              }
            }),
          );
        }
      }),
    );
  };
  const rows =
    allRefundData &&
    allRefundData.map((item) => {
      return {
        id: item.id,
        booked_room: item.booked_room,
        refund_nights: item.refund_nights,
        created_date: new Date(item.created_date).toLocaleDateString(),
        created_time: item.created_date.slice(11, 19),
      };
    });

  const columns = [
    {
      key: "id",
      title: (
        <div className=" text-center  flex justify-center items-center gap-x-1">
          <PiDatabase className="w-5 h-5   text-yellow-500 mb-1" /> ID{" "}
        </div>
      ),
      flex: 1,
      editable: false,
      render: (text, params) => {
        return <div className="flex items-center gap-x-2 ml-5  ">{params.id}</div>;
      },
    },
    {
      key: "refund_nights",
      title: (
        <div className="  flex justify-center items-center gap-x-1">
          <IoCloudyNightOutline className="w-5 h-5   text-yellow-500 mb-1" /> REFUND NIGHTS{" "}
        </div>
      ),
      flex: 1,
      editable: false,
      render: (text, params) => {
        return <div className="flex items-center gap-x-2 ml-16   ">{params.refund_nights}</div>;
      },
    },

    {
      key: "created_date",
      title: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CREATED DATE
        </div>
      ),
      flex: 1,
      editable: false,
      render: (text, params) => {
        return <div className="flex items-center gap-x-2 ml-10  ">{params.created_date}</div>;
      },
    },
    {
      key: "created_time",
      title: (
        <div className="flex items-center gap-x-1">
          <MdOutlineAccessTime className="w-5 h-5  text-yellow-500" />
          CREATED TIME
        </div>
      ),
      flex: 1,
      editable: false,
      render: (text, params) => {
        return <div className="flex items-center gap-x-2 ml-10 ">{params.created_time}</div>;
      },
    },
    {
      key: "operations",
      flex: 1,
      title: "OPERATIONS",
      render: (text, params) => {
        return (
          <div className="flex items-center  gap-x-3 ml-5">
            {permission.map((item, index) => {
              if (item.name === "room_booking.change_refund" && item.value === true) {
                return (
                  <Link
                    key={index}
                    to={`./update/${params.id}`}
                    state={{ bookedRoom: params.booked_room, nights: params.refund_nights }}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <FiEdit className="w-5 h-5 text-green-800 " />
                  </Link>
                );
              } else if (item.name === "room_booking.delete_refund" && item.value === true) {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      setId(params.id);
                      setConfirm(true);
                    }}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <AiFillDelete className="w-5 h-5 text-red-700" />
                  </button>
                );
              }
            })}
          </div>
        );
      },
    },
  ];

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.view_refund", "room_booking.change_refund", "room_booking.delete_refund"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="w-full formPage-wrap   ">
      {confirm && (
        <div className="relative z-50">
          <div className="absolute flex flex-col items-center justify-center gap-y-2 w-[300px] h-36 bg-white border-gray-300 border top-96 left-[500px] bg-center rounded-md ">
            <p className="text-2xl font-light">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id);
                }}
                className="bg-green-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div className='w-full flex flex-col md:flex-row gap-y-2 items-center justify-between pt-5 px-5'>
             <p className='flex items-center gap-x-1  text-2xl font-normal text-[#001a35] '><TbListDetails className='w-5 h-5 text-yellow-600' /> Refund List</p>
         </div> */}
      {deleteRefundFlag && !permissionFlag ? (
        <div className="flex items-center justify-center w-full h-full">
          <TopBarProgress /> <Loading />
        </div>
      ) : (
        permissionFlag &&
        permission.map((item) => {
          if (item.name === "room_booking.view_refund" && item.value === true) {
            return allRefundData ? (
              <div className="w-full overflow-auto">
                <ListFilters columns={columns} rows={rows} title="Refund Request List" />
              </div>
            ) : (
              <div className="w-full h-full flex items-center justify-center ">
                <TopBarProgress /> <Loading />
              </div>
            );
          } else {
            return (
              <div key={index} className="w-full h-screen">
                <NoPermission />
              </div>
            );
          }
        })
      )}
    </div>
  );
};

export default AllRefundRequest;
