import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export function getData(url, successCallback, failureCallback, lang) {
  return async function () {
    try {
      const response = await api_token(token).get(url, {
        headers: { "Accept-Language": lang },
      });
      if (response.status === 200 && successCallback) {
        successCallback(response);
      }
    } catch (error) {
      if (failureCallback) {
        failureCallback(error);
      }
    }
  };
}

export function sendData(url, data, successCallback, failureCallback) {
  return async function () {
    try {
      const response = await api_token(token).post(url, data);
      if (response.status === 201 && successCallback) {
        successCallback(response);
      }
    } catch (error) {
      failureCallback(error);
    }
  };
}

export function sendFormData(url, formData, successCallback, failureCallback) {
  return async function () {
    try {
      const response = await api_token(token).post(url, formData, {
        headers: {
          "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });
      if (response.status >= 200 && response.status < 300 && successCallback) {
        successCallback(response);
      }
    } catch (error) {
      if (failureCallback) {
        failureCallback(error);
      }
    }
  };
}

export function editData(url, data, successCallback, failureCallback) {
  return async function () {
    try {
      const response = await api_token(token).patch(url, data);
      if (response.status === 200 && successCallback) {
        successCallback();
      }
    } catch (error) {
      if (failureCallback) {
        failureCallback(error);
      }
    }
  };
}

export function editFormData(url, formData, successCallback, failureCallback) {
  return async function () {
    try {
      const response = await api_token(token).put(url, formData, {
        headers: {
          "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });

      if (response.status >= 200 && response.status < 300 && successCallback) {
        successCallback(response);
      }
    } catch (error) {
      if (failureCallback) {
        failureCallback(error);
      }
    }
  };
}

export function deleteData(url, successCallback, failureCallback) {
  return async function () {
    try {
      const response = await api_token(token).delete(url);
      if (response.status === 204 && successCallback) {
        successCallback(response);
      }
    } catch (error) {
      if (failureCallback) {
        failureCallback(error);
      }
    }
  };
}

export function deleteFormData(url, data, successCallback, failureCallback) {
  return async function () {
    try {
      const response = await api_token(token).delete(url, data);

      if (response.status >= 200 && response.status < 300 && successCallback) {
        successCallback(response);
      }
    } catch (error) {
      if (failureCallback) {
        failureCallback(error);
      }
    }
  };
}
