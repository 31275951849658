import { GET_SELECTED_PAYMENT_TRANSACTION, GET_SELECTED_PAYMENT_TRANSACTION_FLAG  , UPDATE_PAYMENT_TRANSACTION , UPDATE_PAYMENT_TRANSACTION_FLAG , GET_SELEC_OPERATION,  DELETE_PAYMENT_TRANSACTION_FLAG} from "../types/types";

const initialState = {
    getSelectePayment: null,
    getSelectePaymentFlag:false,
    updatePaymentFlag:false,
    deletePaymentFlag:false,
    getselecoperation:[],
}

export const paymentTransactionReducer= (state = initialState , action) => {
    switch(action.type)
    {
        case GET_SELECTED_PAYMENT_TRANSACTION:
            return{...state , getSelectePayment : action.payload }
        case GET_SELECTED_PAYMENT_TRANSACTION_FLAG:
            return{...state , getSelectePaymentFlag : action.payload }
        case UPDATE_PAYMENT_TRANSACTION_FLAG:
            return{...state , updatePaymentFlag : action.payload }
        case DELETE_PAYMENT_TRANSACTION_FLAG:
             return{...state , deletePaymentFlag : action.payload }
        case GET_SELEC_OPERATION:
            return{...state , getselecoperation : action.payload }
        default:
            return state;
    }
}