import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import React, { useEffect, useState } from "react";
import AgentForm from "./AgentForm";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import * as Yup from "yup";
import usePermissions from "../../../../customHooks/usePermissions";
import NoPermission from "../../NoPermission/NoPermission";

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    if (!list) return true; // If the list is empty, skip the test

    const set = new Set(list.map(mapper));
    const isUnique = set.size === list.length;

    if (!isUnique) {
      return this.createError({
        path: this.path,
        message: message,
      });
    }

    return true;
  });
});

const EditAgentValidationSchema = Yup.object().shape({
  // name: Yup.string()
  //   .required("Name is required")
  //   .test("is-three-words", "The name must consist of at least three words", (value) => {
  //     if (value) {
  //       // Split the string by spaces and filter out empty strings
  //       const words = value.split(" ").filter((word) => word.trim().length > 0);
  //       return words.length >= 3;
  //     }
  //     return false;
  //   }),
  username: Yup.string()
    .required("Username is required")
    .test("no-spaces", "Username must not contain spaces", (value) => {
      // Ensure the value is defined and doesn't contain any spaces
      return value && !/\s/.test(value);
    }),
  nickname: Yup.string().required("Nickname is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().notRequired(),
  id: Yup.string().required("ID Number is required"),
  mobileNumbers: Yup.array()
    .of(
      Yup.object().shape({
        num: Yup.string()
          .trim()
          .matches(/^\+?[0-9]{5,}$/, "Please add a valid phone number with at least 5 digits")
          .min(5, "Please add at least 5 numbers")
          .required("This field is required"), // these constraints take precedence
        whatsapp: Yup.boolean().default(false), // these constraints take precedence
      }),
    )
    .required("Must Add at least one mobile number")
    .unique("Mobile numbers must be unique", (item) => item.num),
  emailAddresses: Yup.array()
    .of(Yup.string().email("Invalid Email"))
    .required("Must Add at least one email")
    .unique("Emails must be unique"),
  market: Yup.array()
    .of(Yup.number())
    .min(1, "Please select at least one market")
    .required("Please select at least one market"),
  markup: Yup.number().required("Please select a markup"),
  notificationPreferences: Yup.array()
    .of(Yup.string())
    .min(1, "Please select a notification preference")
    .required("Please select a notification preference"),
  groups: Yup.array()
    .of(Yup.number())
    .min(1, "Please select at least one group")
    .required("Please select at least one group"),
  paymentMethod: Yup.string().required("Please select a payment method"),
  creditLimit: Yup.number().required("Please enter a credit limit").min(1, "Credit limit must be greater than 0"),
  creditLimitCurrency: Yup.string().required("Please select a currency"),
});

function EditAgentForm() {
  const queryClient = useQueryClient();
  const [permissions, permissionFlag, permissionsError] = usePermissions(["auth_users.change_profile_agent"]);

  const [image, setImage] = useState({
    src: "",
    file: null,
  });

  const { id } = useParams();

  const {
    data: agent,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["agent", id],
    queryFn: () => {
      return fetchDataQuery(`/auth/api/v1/user-agent/${id}/`);
    },
  });

  const { mutate: updateAgent, isPending } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/auth/api/v1/user-agent/${id}/`, "patch", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Agent Updated Successfully");
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to update Agent");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to update Agent");
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setImage({
        src: agent?.profile_agent?.avatar || "",
        file: null,
      });
    }
  }, [agent?.profile_agent?.avatar, isSuccess]);

  if (error) {
    return <Errorfetch Error={error.message || "Error fetching data"} />;
  }

  if (isLoading) {
    return <Loading />;
  }
  const formInitialValues = {
    name: agent.profile_agent.agent_name,
    firstName: agent.first_name,
    lastName: agent.last_name,
    username: agent.username,
    email: agent.email,
    password: "",
    nickname: agent.profile_agent.nickname,
    id: agent.profile_agent.id_number,
    notes: agent.profile_agent.notes,
    emailAddresses: Object.values(agent.profile_agent.email_address),
    mobileNumbers: Object.values(agent.profile_agent.mobile_numbers).map((number) => ({
      num: number,
      whatsapp: Object.values(agent.profile_agent.whats_app_numbers).includes(number),
    })),
    countryName: agent.profile_agent.country_name,
    country: agent.profile_agent.country,
    cityName: agent.profile_agent.city,
    city: agent.profile_agent.city,
    market: agent.profile_agent?.market?.map((i) => i?.id),
    markup: agent.profile_agent.markup,
    active: agent.is_active,
    notificationPreferences: agent.profile_agent.notifications_preferred,
    groups: agent.groups.map((group) => group.id),
    paymentMethod: agent.profile_agent.payment_method,
    creditLimit: agent.profile_agent.credit_info.credit_limit,
    creditLimitCurrency: agent.profile_agent.credit_info.currency,
    accountingNotes: agent.profile_agent.accounting_department_note,
  };

  function handleSubmit(values, helpers) {
    const formData = new FormData();

    if (values.name !== agent.profile_agent.agent_name) {
      formData.append("profile_agent[agent_name]", values.name);
    }

    if (values.username !== agent.username) {
      formData.append("username", values.username);
    }

    if (values.email !== agent.email) {
      formData.append("email", values.email);
    }

    if (values.password) {
      formData.append("password", values.password);
    }

    if (values.nickname !== agent.profile_agent.nickname) {
      formData.append("profile_agent[nickname]", values.nickname);
    }

    if (values.country !== agent.profile_agent.country) {
      formData.append("profile_agent[country]", values.country);
    }

    if (values.city !== agent.profile_agent.city) {
      formData.append("profile_agent[city]", values.city ? values.city : "");
    }

    if (values.id !== agent.profile_agent.id_number) {
      formData.append("profile_agent[id_number]", values.id);
    }

    if (values.notes !== agent.profile_agent.notes) {
      formData.append("profile_agent[notes]", values.notes);
    }

    if (values.markup !== agent.profile_agent.markup) {
      formData.append("profile_agent[markup]", values.markup);
    }

    if (values.active !== agent.is_active) {
      formData.append("is_active", values.active);
    }

    const emailObject = values.emailAddresses.reduce((acc, email, index) => {
      acc[index + 1] = email;
      return acc;
    }, {});

    const emailJson = JSON.stringify(emailObject);

    formData.append("profile_agent[email_address]", emailJson);

    const mobileObject = values.mobileNumbers.reduce((acc, number, index) => {
      acc[index + 1] = number.num;
      return acc;
    }, {});

    const mobileJson = JSON.stringify(mobileObject);
    formData.append("profile_agent[mobile_numbers]", mobileJson);

    const whatsappArray = values.mobileNumbers.filter((number) => number.whatsapp);

    const whatsappObject = whatsappArray.reduce((acc, number, index) => {
      acc[index + 1] = number.num;
      return acc;
    }, {});

    const whatsappJson = JSON.stringify(whatsappObject);
    formData.append("profile_agent[whats_app_numbers]", whatsappJson);

    if (image.file) {
      formData.append("profile_agent[avatar]", image.file);
    }

    if (!image.src) {
      formData.append("profile_agent[avatar]", "");
    }

    formData.append("profile_agent[notifications_preferred]", JSON.stringify(values.notificationPreferences));

    formData.append("groups", JSON.stringify(values.groups));

    formData.append("profile_agent[payment_method]", values.paymentMethod);

    formData.append("credit_info[credit_limit]", values.creditLimit);

    formData.append("credit_info[currency]", values.creditLimitCurrency);

    formData.append("profile_agent[accounting_department_note]", values.accountingNotes);

    formData.append("credit_info[agent]", agent.id);

    updateAgent(formData, {
      onSuccess: () => {
        void queryClient.refetchQueries(
          {
            queryKey: ["agent", id],
            type: "active",
            exact: true,
          },
          {
            throwOnError: (error) => {
              toast.error(error.message || "Failed to re-fetch Agent");
            },
          },
        );
      },
    });
  }

  const isEditingAgentAllowed =
    permissions?.find((permission) => permission.name === "auth_users.change_profile_agent")?.value === true;

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  return (
    <>
      {isEditingAgentAllowed ? (
        <AgentForm
          submitButtonTitle={"Update Agent"}
          validationSchema={EditAgentValidationSchema}
          image={image}
          setImage={setImage}
          isPending={isPending}
          onSubmit={handleSubmit}
          initialValues={formInitialValues}
        />
      ) : (
        <NoPermission />
      )}
    </>
  );
}

export default EditAgentForm;
