import React, { useEffect, useState } from "react";
import { FaMoneyBillTransfer, FaWpforms } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { MdAddTask } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import { collectionAction } from "../../../redux/actions/collectionAction";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import lightToast from "light-toast";
import Select from "react-select";
import { BiTimeFive, BiTransfer } from "react-icons/bi";
import { GoPerson } from "react-icons/go";
import PhoneInput from "react-phone-number-input";
import { TimePicker } from "antd";
import { BsCalendarWeek, BsCollection } from "react-icons/bs";
import { PiNoteFill } from "react-icons/pi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { FcMoneyTransfer } from "react-icons/fc";
import { getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { getSelectedTransactionAction } from "../../../redux/actions/allTransactionsAction";
import { getAllCollectorsAction } from "../../../redux/actions/collectorsAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

const CollectionRequest = () => {
  useRemoveScroll();
  const [amountType, setAmountType] = useState("");
  const [collection, setcollection] = useState("");
  const [load, setLoad] = useState(false);
  const [loadCollector, setLoadCollector] = useState(false);
  const [transactionLoad, setTransactionLoad] = useState(false);
  const format = "HH:mm";
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bookingOrder, setBookingOrder] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [hotelConfirm, setHotelConfirm] = useState("");
  const [collectionAmount, setCollectionAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [collectors, setCollectors] = useState();
  const params = useParams();
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const navigate = useNavigate();
  const { collectionFlag } = useSelector((state) => state.collectionReducer);
  const { collectionData } = useSelector((state) => state.collectionReducer);
  const { collectionDataFlag } = useSelector((state) => state.collectionReducer);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const { selectedTransaction } = useSelector((state) => state.allTransactionsReducer);

  useEffect(() => {
    setLoad(true);
    setLoadCollector(true);
    setTransactionLoad(true);
    dispatch(
      getAllCollectorsAction(token, (result) => {
        if (result.status === 200) {
          setLoadCollector(false);
          setCollectors(result.data);
        }
      }),
    );
    dispatch(
      getSelectedBookedRoomSingleAction(token, params.id, (result) => {
        if (result.status === 200) {
          setLoad(false);
          setBookingOrder(result.data.short_id);
          setBookingId(result.data.id);
          setHotelConfirm(result.data.operations.map((item) => item.hotel_reservation_id));
          setCollectionAmount(result.data.first_night);
          setCurrency(result.data.currency_type);
          setTotalPrice(result.data.total_price);
        }
      }),
    );
    dispatch(
      getSelectedTransactionAction(token, params.id, (result) => {
        if (result.status === 200) {
          setTransactionLoad(false);
        }
      }),
    );
  }, []);

  let allCollectors = collectors
    ? collectors.results.map((item) => {
        return { value: item.id, label: item.collector_name };
      })
    : [];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (time === "") {
      alert("Please select a time."); // You can replace this with your validation logic
    } else {
      const formData = new FormData(e.target);
      formData.append("collection_purpose", "Hotel Booking");
      formData.append("booking_order_no", selectedSingleRoom && selectedSingleRoom.booking_group);
      formData.append("booking_id_no", bookingId);
      formData.append("client_number", phoneNumber);
      formData.append("booking_order", bookingOrder);
      formData.append("collection_satus", "new");
      formData.append("currency_type", currency);
      formData.append("hotel_confirmation_number", hotelConfirm);
      formData.append("collection_time", `${date},${time}`);
      formData.append("collection_amount", collectionAmount);
      formData.append(
        "amount_outstanding",
        selectedTransaction &&
          selectedSingleRoom &&
          selectedSingleRoom.total_price -
            selectedTransaction.results
              .filter((item) => item.transaction_status === "confirmed")
              .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0),
      );
      formData.append("total_amount", totalPrice);
      formData.append("collection_amount_type", amountType);
      dispatch(
        collectionAction(token, formData, (result) => {
          lightToast.success("The Collection Request was completed successfully");
          navigate(-1);
        }),
      );
    }
  };

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setTime(`${hours1}:${minutes1}`);
  };

  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.add_collection"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap  ">
      <div className="flex flex-col items-center justify-center h-full w-full ">
        {load && transactionLoad && loadCollector && !permissionFlag ? (
          <>
            <TopBarProgress /> <Loading />
          </>
        ) : (
          permissionFlag &&
          permission.map((item) => {
            if (item.name === "room_booking.add_collection" && item.value === true) {
              return (
                <div className=" w-full ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      <FaWpforms className="w-5 h-5" />
                      Collection Request Form
                    </h2>
                  </div>
                  <form className="form-wrap  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      {/* <div className='flex items-center gap-x-5 w-full'>
                                                <div className='w-[50%]'>
                                                    <label className="flex justify-center text-gray-600 "> Date</label>
                                                    <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white " >
                                                        <div className="text-black flex items-end gap-x-1">{date}</div>
                                                    </div>
                                                    
                                                </div>
                                                <div className='w-[50%]'>
                                                    <label className=" flex justify-center text-gray-600 ">Time</label>
                                                    <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white " >
                                                        <div className="text-black flex items-end gap-x-1">{time}</div>
                                                    </div>
                                                    
                                                </div>
                                            </div>     */}
                      <label className=" flex text-gray-600 font-semibold">Client Name *</label>
                      <div className="w-full relative text-gray-600">
                        <input
                          placeholder="Client Name"
                          name="client_name"
                          type="text"
                          required
                          className=" w-full px-8 h-10  border rounded-lg bg-white "
                        />
                        <div className="absolute top-[11px] left-2">
                          <GoPerson className="w-5 h-5 text-gray-400" />
                        </div>
                      </div>
                      <label className=" flex text-gray-600 font-semibold">Collection Purpose</label>
                      <div className=" w-full flex flex-col justify-center items-start px-2 h-10  border rounded-md floating-input bg-white ">
                        <div className="text-black flex items-center gap-x-2">
                          <span className="text-gray-400">
                            <BsCollection className="w-5 h-5" />
                          </span>
                          Hotel Booking
                        </div>
                      </div>

                      <label className=" flex text-gray-600 font-semibold">Booking Order</label>
                      <div className=" w-full flex flex-col justify-center items-start px-2 h-10  border rounded-md floating-input bg-white ">
                        <div className="text-black flex items-end gap-x-2">
                          <span className="text-gray-400">
                            <BiTransfer className="w-5 h-5" />
                          </span>
                          {bookingOrder}
                        </div>
                      </div>

                      <label className=" flex text-gray-600 font-semibold">Booking ID</label>
                      <div className=" w-full flex flex-col justify-center items-start px-2 h-10  border rounded-md floating-input bg-white ">
                        <div className="text-black flex items-end gap-x-2">
                          <span className="text-gray-400">
                            <BiTransfer className="w-5 h-5" />
                          </span>
                          {bookingId}
                        </div>
                      </div>

                      <label className=" flex text-gray-600 font-semibold">Hotel Confirmation Number</label>
                      <div className=" w-full flex flex-col justify-center items-start px-2 h-10  border rounded-md floating-input bg-white ">
                        <div className="text-black flex items-end gap-x-2">
                          <span className="text-gray-400">
                            <BiTransfer className="w-5 h-5" />
                          </span>
                          {hotelConfirm}
                        </div>
                      </div>
                      <label className=" flex text-gray-600 font-semibold">Client Number</label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        type="text"
                        required
                        defaultCountry="EG"
                        className=" w-full px-3 h-10  border rounded-lg bg-white "
                        onChange={setPhoneNumber}
                      />
                      <label className=" flex text-gray-600 font-semibold">Collection Type</label>
                      <div className="relative w-full text-gray-600">
                        <div className="flex items-center justify-between w-full border rounded-lg bg-white  px-3 h-10 ">
                          <div className="flex items-center justify-center gap-x-3 w-[50%]">
                            <label className=" text-center  text-gray-600   text-lg mb-1 ">Cash</label>
                            <input
                              placeholder="Employee Name"
                              name="collection_type"
                              type="radio"
                              value="cash"
                              required
                              onChange={(e) => setcollection(e.target.value)}
                              className=" w-4 h-4  border rounded-lg bg-white  "
                            />
                          </div>
                          <div className="flex items-center justify-center gap-x-3 w-[50%]">
                            <label className=" flex text-gray-600   text-lg mb-1">Visa</label>
                            <input
                              placeholder="Total Amount"
                              name="collection_type"
                              type="radio"
                              value="visa"
                              required
                              onChange={(e) => setcollection(e.target.value)}
                              className=" w-4 h-4  border rounded-lg bg-white  "
                            />
                          </div>
                          <div className="absolute top-[11px]  left-3 text-gray-400">
                            <RiSecurePaymentLine className="w-5 h-5" />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center gap-x-5 w-full">
                        <div className="w-[50%]">
                          <label className="flex justify-center text-gray-600 font-semibold"> Date</label>
                          <div className="relative w-full text-gray-600">
                            <input
                              placeholder="Tranfer Date"
                              defaultValue={date}
                              type="date"
                              required
                              onChange={(e) => setDate(e.target.value)}
                              className=" w-full px-8 h-10  border rounded-lg floating-input bg-white "
                            />
                            <div className="absolute top-3  left-3 text-gray-400">
                              <BsCalendarWeek className="w-4 h-4" />
                            </div>
                          </div>
                        </div>
                        <div className="w-[50%]">
                          <label className=" flex justify-center text-gray-600 font-semibold">Time</label>
                          <div className="relative w-full text-gray-600">
                            <TimePicker
                              clearIcon={false}
                              required
                              format={format}
                              className="w-full px-8 h-[37px]"
                              onChange={handleTimeChange}
                            />
                            <div className="absolute top-[11px]  left-3  text-gray-400">
                              <BiTimeFive className="w-4 h-4" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <label className=" flex text-gray-600 font-semibold">Collection Address *</label>
                      <div className="relative w-full text-gray-600">
                        <input
                          placeholder="Collection Address "
                          name="collection_address"
                          type="text"
                          required
                          className=" w-full px-10 h-10  border rounded-lg bg-white "
                        />
                        <div className="absolute top-[11px]  left-3">
                          <IoLocationOutline className="w-5 h-5" />
                        </div>
                      </div>

                      <label className=" flex text-gray-600 font-semibold">Collector Name *</label>
                      <div className="relative w-full text-gray-600 border-l rounded-md">
                        <Select
                          name="collector_name"
                          placeholder="Collector name"
                          options={allCollectors}
                          required
                          isSearchable
                          className=" w-full pl-8 h-10  bg-white capitalize"
                        />
                        <div className="absolute top-[11px] left-2">
                          <GoPerson className="w-5 h-5 text-gray-400" />
                        </div>
                      </div>

                      <label className=" flex text-gray-600 font-semibold">First Night</label>
                      <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                        <div className="flex items-center gap-x-2">
                          <FcMoneyTransfer className="w-5 h-5 opacity-70" />
                          <div className="text-black flex items-end gap-x-1">
                            {collectionAmount}
                            <span className="text-xs font-bold text-gray-600">{currency}</span>
                          </div>
                        </div>
                      </div>
                      <label className=" flex text-gray-600 font-semibold">Total Amount</label>
                      <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                        <div className="flex items-center gap-x-2">
                          <FcMoneyTransfer className="w-5 h-5 opacity-70" />
                          <div className="text-black flex items-end gap-x-1">
                            {selectedTransaction &&
                              selectedSingleRoom.total_price -
                                selectedTransaction.results
                                  .filter((item) => item.transaction_status === "confirmed")
                                  .reduce((acc, balc) => acc + parseFloat(balc.conversion_rate_price), 0)}
                            <span className="text-xs font-bold text-gray-600">{currency}</span>
                          </div>
                        </div>
                      </div>

                      <label className=" flex text-gray-600 font-semibold">Amount Type</label>
                      <div className="relative w-full text-gray-600 border-l rounded-md">
                        <Select
                          name="amount_type"
                          placeholder="Select Amount Type"
                          options={[
                            { value: "First Night", label: "First Night" },
                            { value: "Total Amount", label: "Total Amount" },
                            { value: "Other Amount", label: "Other Amount" },
                          ]}
                          required
                          onChange={(e) => setAmountType(e.value)}
                          isSearchable
                          className=" w-full pl-8 h-10  bg-white capitalize"
                        />
                        <div className="absolute top-[11px] left-2">
                          <FaMoneyBillTransfer className="w-5 h-5" />
                        </div>
                      </div>

                      {amountType === "Other Amount" && (
                        <>
                          <label className=" flex text-gray-600 font-semibold">Other Amount</label>
                          <div className="relative w-full text-gray-600">
                            <input
                              placeholder="Amount"
                              name="other_amount"
                              type="Number"
                              step="any"
                              required
                              className=" w-full px-11 h-10  border rounded-lg bg-white "
                            />
                            <div className="absolute top-3 left-3 font-bold text-xs text-gray-600">{currency}</div>
                          </div>
                        </>
                      )}

                      <label className=" flex text-gray-600 font-semibold">Notes *</label>
                      <div className="relative w-full text-gray-600">
                        <textarea
                          name="notes"
                          type="text"
                          required
                          className=" w-full px-8 h-12 py-2  border rounded-lg bg-white "
                        />
                        <div className="absolute top-3 left-2">
                          <PiNoteFill className="w-5 h-5" />
                        </div>
                      </div>
                      {permission?.map((item) => {
                        if (item.name === "room_booking.add_collection" && item.value === true) {
                          return (
                            <button
                              type="Submit"
                              disabled={collectionFlag}
                              className="flex items-center gap-x-2 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                            >
                              Add {collectionFlag ? <ButtonLoading /> : <MdAddTask className="w-5 h-5" />}
                            </button>
                          );
                        }
                      })}
                    </div>
                  </form>
                </div>
              );
            } else {
              return (
                <div className="w-full h-screen">
                  <NoPermission />
                </div>
              );
            }
          })
        )}
      </div>
    </div>
  );
};

export default CollectionRequest;
