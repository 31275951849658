import React, { useEffect, useMemo, useState } from "react";
import "./AddMarkup.scss";
import { FaWpforms } from "react-icons/fa6";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { requestMarketTypes } from "../../../redux/actions/addContract";
import { getMarkupPriceTypes, getUserGroups, sendMarkup } from "../../../redux/actions/markupControl";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import { getHotelRooms } from "../../../redux/actions/getRooms";
import { useNavigate } from "react-router";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import { MdAddTask } from "react-icons/md";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { FaHotel } from "react-icons/fa";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";

export default function AddMarkup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { marketTypes } = useSelector((state) => state.addContract);
  const marketPriceTypes = useSelector((state) => state.markupPriceTypes);
  const hotels = useSelector((state) => state.hotelNames);
  const hotelRooms = useSelector((state) => state.hotelRooms);
  const userGroups = useSelector((state) => state.userGroups);
  const { destinationData } = useSelector((state) => state?.SearchFormReducer);

  //
  const [arabicName, setArabicName] = useState();
  const [arabicError, setArabicError] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [userChoice, setUserChoice] = useState(null);
  const [chosenMarketPriceTypes, setChosenMarketPriceTypes] = useState("");
  const [chosenMarket, setChosenMarket] = useState("");
  const [chosenRooms, setChosenRooms] = useState([]);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [roomFlag, setRoomFlag] = useState(false);
  const [chosenUserGroup, setChosenUserGroup] = useState("");
  const [hotelData, sethotelData] = useState([]);
  const [addFlag, setAddFlag] = useState(false);
  const [marketFlag, setMarketFlag] = useState(false);
  const [markupFlag, setMarkupFlag] = useState(false);
  const [hotelFlag, setHotelFlag] = useState(false);
  const [userFlag, setUserFlag] = useState(false);
  const [chosenCity, setChosenCity] = useState(null);
  const [dataHotel, setData] = useState();
  const [currentValue, setCurrentValue] = useState("");
  const [selectedHotels, setSelectedHotels] = useState([]);
  const [selectedHotelsIds, setSelectedHotelsIds] = useState([]);
  const [pricesVal, setPriceVal] = useState();
  const [prices, setPrices] = useState({});
  const [cityNameVal, setCityNameVal] = useState([]);
  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };
  // const citiesArr = useMemo(
  //   () => destinationData?.map((city) => ({ label: city.name, value: city.id })),
  //   [destinationData],
  // );
  const allCities = chosenCity && chosenCity?.map((i) => i.label === "All")[0];
  const citiesChosenArr = chosenCity && allCities ? cityNameVal?.map((i) => i.id) : chosenCity?.map((i) => i.value);

  const cityDataSelect = useMemo(
    () => cityNameVal && cityNameVal?.map((city) => ({ label: city.name, value: city.id })),
    [cityNameVal],
  );

  const {
    data: cities,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["cities", citiesChosenArr],
    queryFn: () => fetchDataQuery(`/hotels/api/v1/hotel-filter/?cities=[${citiesChosenArr || 0}]`),
    // enabled: !!citiesChosenArr,
  });
  const { data: cityName, isSuccess: sucess } = useQuery({
    queryKey: ["cityName", cities],
    queryFn: () => fetchDataQuery("/search/api/v1/get-cities/"),
  });
  useEffect(() => {
    if (isSuccess) {
      setData(cities);
    }
  }, [cities, chosenCity, citiesChosenArr, cityNameVal, allCities]);

  useEffect(() => {
    if (sucess) {
      setCityNameVal(cityName);
    }
  }, [cityNameVal, cityName, sucess]);

  const hotelsArrID = dataHotel ? dataHotel?.map((hotel) => ({ label: hotel.name, value: hotel.id })) : [];
  //
  //  hotels.map((hotelObj) => {
  //   return { label: hotelObj.name, value: hotelObj.id };
  // });

  // const handlePriceChange = (item, value, index) => {
  //
  //   setPrices((prevPrices) => ({
  //     ...prevPrices,
  //     [item]: value,
  //   }));
  // };

  // teeest
  // const handlePriceChange = (value) => {
  //
  //   setCurrentValue(value);
  // };
  //

  // const setFixedPrice = () => {
  //   setPrices((prevPrices) => {
  //     const newPrices = {};
  //     for (let key in prevPrices) {
  //       newPrices[key] = currentValue;
  //     }
  //     return newPrices;
  //   });
  // };
  const items = Object.entries(prices); // or however you get your items

  //
  useRemoveScroll();
  const choiceOptions = [
    { label: "All", value: "All" },
    { label: "Hotels", value: "Hotels" },
    { label: "Rooms", value: "Rooms" },
  ];
  const marketTypesArr = marketTypes.map((type) => {
    return { label: type.name, value: type.id };
  });

  const marketPriceTypesArr = marketPriceTypes.map((elem) => {
    return { label: elem, value: elem };
  });
  // const hotelsArrID = hotels.map((hotelObj) => {
  //   return { label: hotelObj.name, value: hotelObj.id };
  // });
  const hotelRoomsArr = hotelRooms.map((room) => {
    return { label: room.room_name, value: room.id };
  });
  const userGroupsArr = userGroups.map((userGroup) => {
    return { label: userGroup.name, value: userGroup.id };
  });
  //
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [roomsHotel, setroomsHotel] = useState(null);

  function handleHotelChange(e) {
    //
    setRoomFlag(true);
    dispatch(
      getHotelRooms(e.value, (result) => {
        //
        if (result.status === 200) {
          setroomsHotel(result.data);
          setRoomFlag(false);
        }
      }),
    );
    setChosenRooms([]);
  }

  const handlehotelData = (id, price, indexx) => {
    sethotelData((prevData) => {
      const updatedData = [...prevData];
      updatedData[indexx].price = price;
      updatedData[indexx].hotel = id;
      //
      return updatedData;
    });
  };

  const handleSelectChange = (selectedOptions) => {
    //
    if (selectedOptions.some((option) => option.value === "All")) {
      setSelectedHotels(hotelsArrID?.map((option) => option.label));
      setSelectedHotelsIds(hotelsArrID?.map((option) => option.value));
    } else {
      setSelectedHotels(selectedOptions.map((option) => option.label));
      setSelectedHotelsIds(selectedOptions.map((option) => option.value));
    }
  };

  const handlePriceChange = (hotel, value) => {
    setPrices((prevPrices) => ({
      ...prevPrices,
      [hotel]: value,
    }));
  };
  const setFixedPrice = (price) => {
    const newPrices = {};
    const priceVal = "";
    selectedHotels.forEach((hotel) => {
      newPrices[hotel] = price;
    });
    setPrices(newPrices);
    setPriceVal(priceVal);
  };
  //

  //
  const handleFixedPrice = (index) => {
    //
    setSelectedCheckbox((prevIndex) => (prevIndex === index ? null : index));
    if (selectedCheckbox !== index) {
      const priceHotelData = [...hotelData];
      //
      const selectedFixedPrice = priceHotelData[index]?.price;
      //

      sethotelData((prevData) => {
        const updatedData = prevData.map((item) => ({
          ...item,
          price: selectedFixedPrice,
        }));
        //
        return updatedData;
      });
      //
    }
  };
  // const handleFixedPriceAll = (index) => {
  //   setSelectedCheckbox((prevIndex) => (prevIndex === index ? null : index));
  //   if (selectedCheckbox !== index) {
  //     const priceHotelData = [...hotelData];
  //
  //     const selectedFixedPrice = priceHotelData[index]?.price;
  //

  //     sethotelData((prevData) => {
  //       const updatedData = prevData.map((item) => ({
  //         ...item,
  //         price: selectedFixedPrice,
  //       }));
  //
  //       return updatedData;
  //     });
  //
  //   }
  // };

  const allChosenHotels = chosenHotels?.map((i) => i?.label === "All");

  const chosenHotelsVal = allChosenHotels[0] ? hotelsArrID : chosenHotels;
  //
  useEffect(() => {
    dispatch(
      requestMarketTypes((result) => {
        setMarketFlag(true);
      }),
    );
    dispatch(
      getMarkupPriceTypes((result) => {
        setMarkupFlag(true);
      }),
    );
    dispatch(
      requestHotelNames((reuslt) => {
        setHotelFlag(true);
      }),
    );
    dispatch(
      getUserGroups((result) => {
        setUserFlag(true);
      }),
    );
  }, []);
  useEffect(() => {
    if (chosenHotels.length === 0) {
      sethotelData([]);
      setSelectedCheckbox(null);
    }
  }, [chosenHotels]);

  const handleInputChange = (e) => {
    const arabicRegex = /^[\u0600-\u06FF\s]+$/; // Regular expression for Arabic characters and spaces
    const userInput = e.target.value;

    if (arabicRegex.test(userInput)) {
      setArabicError(false);
      setArabicName(userInput);
    } else {
      setArabicError(true);
    }
  };
  const allChosenRooms = chosenRooms?.map((i) => i.label)[0] === "All";

  const chosenAllRooms = allChosenRooms ? hotelRoomsArr : chosenRooms;

  const rightPrice =
    items &&
    items?.map((n, i) => {
      return { price: n[1] };
    });

  const leftHotel =
    selectedHotelsIds &&
    selectedHotelsIds?.map((obj) => {
      return {
        hotel: obj,
      };
    });
  const combined = [];
  useEffect(() => {
    for (let i = 0; i < leftHotel.length && i < rightPrice.length; i++) {
      combined.push({
        hotel: leftHotel[i].hotel,
        price: rightPrice[i].price,
      });
    }
  }, [leftHotel, rightPrice]);
  //
  //
  function handleSubmit(e) {
    //
    e.preventDefault();
    if (arabicError) {
      alert("You Should enter arabic name");
    } else {
      const data = {
        name: e.target.name.value,
        name_ar: arabicName,
        type_price: chosenMarketPriceTypes,
        price: selectedHotels.length === 0 ? e.target.price.value : null,
        min_nights: e.target.minNights.value,
        from_date: dateRange.startDate.format("YYYY-MM-DD"),
        to_date: dateRange.endDate.format("YYYY-MM-DD"),
        market: chosenMarket,
        user_category: chosenUserGroup,
      };
      // ;

      switch (userChoice) {
        case "All":
          data.all = true;
          data.markup_hotel_prices = [];
          break;
        case "Hotels":
          data.hotels = selectedHotelsIds?.map((obj) => obj);
          data.markup_hotel_prices = combined;
          break;
        case "Rooms":
          data.rooms =
            chosenRooms?.map((i) => i.label)[0] === "All"
              ? hotelRoomsArr.map((i) => i.value)
              : chosenRooms.map((obj) => obj.value);
          data.markup_hotel_prices = [];
          // data.hotels = selectedHotels?.map((obj) => obj);
          break;
      }

      setAddFlag(true);
      dispatch(
        sendMarkup(data, (result) => {
          // ;
          if (result.status === 201) {
            //
            setAddFlag(false);
            navigate(`/dashboard/markup-control/markups-list`);
          } else {
            setAddFlag(false);
          }
        }),
      );
    }
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["markup.add_markup"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  const [height, setHeight] = useState(200);
  return (
    <div className="formPage-wrap text-left">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {permissionFlag && marketFlag && markupFlag && hotelFlag && userFlag ? (
          permission.map((item) => {
            if (item.name === "markup.add_markup" && item.value === true) {
              return (
                <div className=" w-full  grid rounded-lg p-5  gap-4 ">
                  <div className="w-full flex justify-center   ">
                    <h2 className="header-h2">
                      <FaWpforms className="w-5 h-5" />
                      Add Markup
                    </h2>
                  </div>
                  <form className="form-wrap " onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="w-full flex flex-col justify-center items-center gap-y-8">
                      <div className="w-full flex max-sm:flex-col gap-4  items-center ">
                        <div className="flex-1 rounded-md max-md:w-full">
                          <label className=" font-semibold  flex text-[#002244] ">From-To</label>
                          <DateRangePicker
                            startDate={dateRange.startDate}
                            startDateId="startDate"
                            endDate={dateRange.endDate}
                            endDateId="endDate"
                            onDatesChange={handleDateChange}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                            minimumNights={0}
                            daySize={30} // Allow same-day selection
                            required
                            className="rounded-md"
                          />
                        </div>
                        <div className="flex-1 max-md:w-full">
                          <label className=" font-semibold  flex text-[#002244] ">Name</label>
                          <input
                            placeholder="Name"
                            name="name"
                            type="text"
                            required
                            onChange={(e) => {}}
                            className=" w-full px-3 h-11  border  bg-white rounded-md"
                          />
                        </div>
                      </div>
                      <div className="flex w-full gap-4 max-sm:flex-col">
                        <div className="flex flex-col flex-1">
                          <label className=" font-semibold  flex text-[#002244] ">Arabic Name</label>

                          <div className="w-full">
                            {" "}
                            <input
                              placeholder="Arabic Name"
                              name="name_ar"
                              type="text"
                              required
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                              className=" w-full px-3 h-10 rounded-md  border  bg-white "
                            />
                            {arabicError && (
                              <p className="w-full text-red-900 text-xs font-semibold">Please Enter Arabic Name</p>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col flex-1">
                          <label className=" font-semibold  flex text-[#002244] ">Price Type</label>
                          <Select
                            options={marketPriceTypesArr}
                            className="w-full h-10   bg-white capitalize "
                            required
                            placeholder="Price Type"
                            name="marketPriceType"
                            onChange={(e) => setChosenMarketPriceTypes(e.value)}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 w-full max-sm:flex-col">
                        <div className="flex-1">
                          <label className=" font-semibold flex text-[#002244]">Market</label>
                          <Select
                            options={marketTypesArr}
                            className="w-full h-10 bg-white capitalize"
                            name="marketTypes"
                            placeholder="Market"
                            required
                            onChange={(e) => setChosenMarket(e.value)}
                          />
                        </div>
                        <div className="flex-1">
                          <label className=" font-semibold flex text-[#002244]">User category</label>
                          <Select
                            options={userGroupsArr}
                            className="w-full h-10 bg-white capitalize"
                            name="marketTypes"
                            placeholder="User category"
                            required
                            onChange={(e) => setChosenUserGroup(e.value)}
                          />
                        </div>
                      </div>
                      <div className="input-par">
                        <div className="input-chil">
                          <label className=" font-semibold  flex text-[#002244] ">Min Nights</label>
                          <input
                            placeholder="Min nights"
                            name="minNights"
                            type="number"
                            required
                            onChange={(e) => {}}
                            className=" w-full px-3 h-10  border border-black border-opacity-30 rounded-lg bg-white "
                          />
                        </div>
                        <div className="input-chil">
                          <label className=" font-semibold flex text-[#002244]">Choose Option</label>
                          <Select
                            options={choiceOptions}
                            className="w-full h-10 bg-white capitalize"
                            onChange={(e) => {
                              setUserChoice(e.value);
                              // setChosenHotels([]);
                              sethotelData([]);
                              setSelectedCheckbox(null);
                            }}
                            required
                            placeholder="Select Option"
                            menuPlacement="top"
                          />
                        </div>
                      </div>
                      {/* for choosing hotels */}
                      {userChoice === "Hotels" && (
                        <>
                          <div className="w-full">
                            <label className="  font-semibold w-full flex justify-center items-center gap-x-2 text-[#002244]">
                              City
                            </label>
                            <Select
                              required
                              className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                              options={[{ label: "All", value: "All" }, ...cityDataSelect]}
                              placeholder="Select City"
                              isMulti
                              onChange={(e) => {
                                setHotelFlag(true);
                                setChosenCity(e);
                              }}
                              value={chosenCity}
                            />
                          </div>

                          <div className="w-[100%] h-full">
                            <label className=" font-semibold w-full flex justify-center items-center gap-x-1 text-[#002244]">
                              Hotels <FaHotel className="w-4 h-4" />
                            </label>
                            {/* <Resizable height={height} onResize={(e, data) => setHeight(data.size.height)}> */}
                            <Select
                              options={[{ value: "All", label: "All" }, ...hotelsArrID]}
                              // options={[{ label: "All", value: "All" }, ...hotelsArrID]}
                              className={`w-full 
                             
                              resize-y bg-white capitalize `}
                              isMulti
                              // value={selectedHotels}
                              onChange={
                                // setChosenHotels(e);
                                handleSelectChange
                                // sethotelData([{ hotel: e.value, price: e.price }, ...hotelData]);
                              }
                              isClearable
                              menuPlacement="top"
                              placeholder="Select Hotels"
                            />
                            {/* </Resizable> */}
                          </div>
                        </>
                        // <div className="w-[100%]">
                        //   <label className=" font-semibold w-full flex justify-center items-center gap-x-1 text-[#002244]">
                        //     Hotels <FaHotel className="w-4 h-4" />
                        //   </label>
                        //   {/* <Resizable height={height} onResize={(e, data) => setHeight(data.size.height)}> */}
                        //   <Select
                        //     options={hotelsArrID}
                        //     className={`w-full ${
                        //       chosenHotels.length >= 7 ? "h-24" : "h-10"
                        //     }  resize-y bg-white capitalize `}
                        //     isMulti
                        //     required
                        //     value={chosenHotels}
                        //     onChange={(e) => {
                        //       setChosenHotels(e);
                        //       sethotelData([...hotelData, { hotel: e.value, price: e.price }]);
                        //     }}
                        //     isClearable
                        //     menuPlacement="top"
                        //     placeholder="Select Hotels"
                        //   />
                        //   {/* </Resizable> */}
                        // </div>
                      )}{" "}
                      <div className="w-full ">
                        {selectedHotels.length > 0 &&
                          userChoice === "Hotels" &&
                          selectedHotels?.map((item, index) => {
                            return (
                              <div className="w-full flex items-center justify-between p-2 my-1 rounded-md shadow-md border ">
                                <div className="w-[48%] flex flex-col justify-center items-center ">
                                  <label className=" font-semibold w-full flex justify-center items-center gap-x-2 text-[#002244]">
                                    Hotel Name
                                  </label>
                                  <div className="w-full px-3 h-10 flex items-center justify-start  border rounded-md  bg-white text-gray-800 font-semibold">
                                    {item}
                                  </div>
                                </div>

                                <div className="w-[48%] flex flex-col justify-center items-center">
                                  <div className="w-full flex justify-between text-[#002244]">
                                    <p>Hotel Price</p>
                                    <button
                                      type="button"
                                      onClick={() => setFixedPrice(prices[item])}
                                      className="capitalize text-xs font-bold border bg-green-700 p-1 rounded-md text-white my-1 hover:bg-green-800 hover:shadow-md hover:scale-[1.01]"
                                    >
                                      fixed price
                                    </button>
                                  </div>
                                  <input
                                    placeholder="Enter Hotel Price"
                                    name="hotel_price"
                                    type="number"
                                    required
                                    value={prices[item] || ""}
                                    onChange={(e) => handlePriceChange(item, e.target.value)}
                                    className=" w-full px-3 h-10  border rounded-lg bg-white "
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {/* for choosing rooms */}
                      {userChoice === "Rooms" && (
                        <div className="w-[100%]">
                          <>
                            <div className="w-full">
                              <label className="  font-semibold w-full flex justify-center items-center gap-x-2 text-[#002244]">
                                City
                              </label>
                              <Select
                                required
                                className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                                options={[{ label: "All", value: "All" }, ...cityDataSelect]}
                                placeholder="Select City"
                                isMulti
                                onChange={(e) => {
                                  setHotelFlag(true);
                                  setChosenCity(e);
                                }}
                                value={chosenCity}
                              />
                            </div>

                            <div className="w-[100%] h-full">
                              <label className=" font-semibold w-full flex justify-center items-center gap-x-1 text-[#002244]">
                                Hotels <FaHotel className="w-4 h-4" />
                              </label>
                              {/* <Resizable height={height} onResize={(e, data) => setHeight(data.size.height)}> */}
                              <Select
                                options={hotelsArrID}
                                className="w-full h-10 bg-white capitalize"
                                onChange={handleHotelChange}
                                menuPlacement="top"
                                placeholder="Select Hotels"
                              />
                              {/* </Resizable> */}
                            </div>
                          </>

                          {roomFlag ? (
                            <div className="w-full flex justify-center items-center my-2 ">
                              {" "}
                              <ButtonLoading />
                            </div>
                          ) : !roomsHotel ? (
                            <>
                              <label className="flex justify-center items-center text-[#002244] font-semibold">
                                {" "}
                                Rooms
                              </label>
                              <Select
                                options={[{ label: "All", value: "All" }, ...hotelRoomsArr]}
                                className="w-full h-10 bg-white capitalize"
                                isMulti
                                required
                                value={chosenAllRooms}
                                onChange={(e) => {
                                  setChosenRooms(e);
                                }}
                                menuPlacement="top"
                                placeholder="Select Rooms"
                              />{" "}
                            </>
                          ) : roomsHotel.length > 0 ? (
                            <>
                              <label className="flex justify-center items-center text-[#002244] font-semibold">
                                {" "}
                                Rooms
                              </label>
                              <Select
                                options={[{ label: "All", value: "All" }, ...hotelRoomsArr]}
                                className="w-full h-10 bg-white capitalize"
                                isMulti
                                required
                                value={chosenAllRooms}
                                onChange={(e) => {
                                  setChosenRooms(e);
                                }}
                                menuPlacement="top"
                                placeholder="Select Rooms"
                              />{" "}
                            </>
                          ) : (
                            <div className="w-full flex items-center justify-center gap-x-2 text-gray-700 bg-gray-300 h-10 mt-3 ">
                              <p className="font-semibold  ">No Rooms For This Hotel , Please Select Another Hotel</p>
                              <FaHotel className="w-5 h-5 " />
                            </div>
                          )}
                          {hotelRoomsArr.length > 0 && (
                            <>
                              <label className=" font-semibold w-full justify-center items-center flex text-[#002244] ">
                                Price
                              </label>
                              <input
                                placeholder="price"
                                name="price"
                                type="number"
                                required
                                onChange={(e) => {}}
                                className=" w-full px-3 h-10  border rounded-lg bg-white "
                              />
                            </>
                          )}
                        </div>
                      )}
                      {userChoice === "All" && (
                        <div className="w-full">
                          <label className=" font-semibold w-full justify-center items-center flex text-[#002244] ">
                            Price
                          </label>
                          <input
                            placeholder="price"
                            name="price"
                            type="number"
                            required
                            onChange={(e) => {}}
                            className=" w-full px-3 h-10  border rounded-lg bg-white "
                          />
                        </div>
                      )}
                      <div className="flex justify-center mt-6">
                        <button type="Submit" disabled={addFlag} className="addButton ">
                          Add{" "}
                          {addFlag ? (
                            <>
                              <ButtonLoading /> <TopBarProgress />
                            </>
                          ) : (
                            <MdAddTask className="w-5 h-5" />
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              );
            } else {
              return (
                <div className="w-full h-screen">
                  <NoPermission />
                </div>
              );
            }
          })
        ) : (
          <>
            {" "}
            <Loading /> <TopBarProgress />{" "}
          </>
        )}
      </div>
    </div>
  );
}
