import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import ChequeList from "./Cheques/ChequeList";
import { getBankChequesIdAction } from "../../../redux/actions/bankChequesActions";
import { useParams } from "react-router";
import Loading from "../Loading/Loading";

const BankChequesDetails = () => {
  const [chequeData, setChequeData] = useState();
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const params = useParams();
  const fetchData = () => {
    dispatch(
      getBankChequesIdAction(token, params.id, (res) => {
        if (res?.status === 200) {
          setChequeData(res?.data);
        }
      }),
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  // const ChequeComponents = Array.from({ length: data?.number_of_cheques }, (_, value) => value + 1);
  // const Cheque = ({ value }) => {
  //   return (
  //     <div className="">
  //       <ChequeDetails />;
  //     </div>
  //   );
  // };
  return (
    <section className="formPage-wrap">
      <div className="flex justify-center">
        <h2 className="header-h2"> Bank Cheque Details</h2>
      </div>
      {chequeData && params.id ? (
        <div className="">
          <table className="min-w-full  md:hidden form-wrap">
            <tbody>
              <tr className="bg-gray-100">
                <td className="p-2 font-bold border-r border-b">ID :</td>
                <td className="p-2  border-r border-b">{chequeData?.id}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border-r border-b">Bank :</td>
                <td className="p-2 border-r border-b">{chequeData?.bank?.name}</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="p-2 font-bold border-r border-b">Bank Account Number :</td>
                <td className="p-2 border-r border-b">{chequeData?.bank_account_number}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border-r border-b">Bank Currency :</td>
                <td className="p-2 border-r border-b">{chequeData?.bank_currency}</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="p-2 font-bold  border-r border-b">First Cheque Number :</td>
                <td className="p-2 border-r border-b">{chequeData?.first_cheque_number}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border-r ">Number of Cheques :</td>
                <td className="p-2 ">{chequeData?.number_of_cheques}</td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full  form-wrap max-md:hidden">
            <thead className="bg-gray-100">
              <tr>
                <th className="p-2 font-bold border-r  border-b">ID</th>
                <th className="p-2 font-bold border-r  border-b">Bank</th>
                <th className="p-2 font-bold border-r  border-b">Bank Account Number</th>
                <th className="p-2 font-bold border-r  border-b">Bank Currency</th>
                <th className="p-2 font-bold border-r  border-b">First Cheque Number</th>
                <th className="p-2 font-bold  border-b">Number of Cheques</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td className="p-2 border-r">{chequeData?.id}</td>
                <td className="p-2 border-r">{chequeData?.bank?.name}</td>
                <td className="p-2 border-r">{chequeData?.bank_account_number}</td>
                <td className="p-2 border-r">{chequeData?.bank_currency}</td>
                <td className="p-2 border-r">{chequeData?.first_cheque_number}</td>
                <td className="p-2 ">{chequeData?.number_of_cheques}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
      <div className=" gap-4 flex flex-col border-t pt-16">
        <ChequeList />;
      </div>
    </section>
  );
};

export default BankChequesDetails;
