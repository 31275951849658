import { GET_AREAS, GET_CITIES, GET_COUNTRIES } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getFormCountriesSuccessfull(countries) {
  return {
    type: GET_COUNTRIES,
    payload: countries,
  };
}

function getFormCitiesSuccessful(cities) {
  return {
    type: GET_CITIES,
    payload: cities,
  };
}

function getFormAreasSuccessfull(areas) {
  return {
    type: GET_AREAS,
    payload: areas,
  };
}

function getFormCountries() {
  return async function (dispatch) {
    try {
      // const response = await api_token(token).get(`/en/hotels/api/v1/get-countries/`);
      const response = await api_token(token).get(`site-settings/api/countries-flags`);
      const countriesObjs = response.data;
      dispatch(getFormCountriesSuccessfull(countriesObjs));
    } catch (error) {}
  };
}

function getFormCities(country_id) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).post("/en/hotels/api/v1/get-cities/", { country_id });
      const citiesObjs = response.data;
      dispatch(getFormCitiesSuccessful(citiesObjs));
    } catch (error) {
      dispatch(getFormCitiesSuccessful([{ name: "No cities are fount for this country" }]));
    }
  };
}

function getFormAreas(city_name) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).post("/en/hotels/api/v1/city-areas/", { city: city_name });
      dispatch(getFormAreasSuccessfull(response.data));
    } catch (error) {
      dispatch(getFormAreasSuccessfull([error.response.data.error]));
    }
  };
}

export { getFormCountries, getFormCities, getFormAreas };
