import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../Loading/Loading";
import React, { useState } from "react";
import { MdEmail, MdSubject } from "react-icons/md";
import { BiMessageAltError } from "react-icons/bi";
import { LiaCalendar } from "react-icons/lia";
import { IoMdPerson } from "react-icons/io";
import { AiOutlineEye } from "react-icons/ai";
import { Modal } from "flowbite-react";
import { TbBuildingEstate, TbProgress } from "react-icons/tb";
import Errorfetch from "../Errorfetch/Errorfetch";

function BookingEmailList() {
  const { id: roomId } = useParams();

  const {
    data: emailHistory,
    error,
    loading,
  } = useQuery({
    queryFn: () => fetchDataQuery(`/booking/api/v1/email-history/?booked_room_id=${roomId}`),
    queryKey: ["bookingEmails", roomId],
  });

  if (loading || !emailHistory) {
    return <Loading />;
  }

  if (error) {
    <Errorfetch Error={error.message} />;
  }

  if (emailHistory.length === 0) {
    return (
      <div className="flex justify-center flex-col gap-1 items-center">
        <MdEmail className="w-24 h-24 text-gray-900 " />
        <p className="text-lg font-medium"> No email history found</p>
      </div>
    );
  }
  const rows = emailHistory?.map((email) => {
    return {
      id: email.id,
      date: email.created_date,
      subject: email.subject,
      hotelName: email.hotel_name,
      checkIn: email.check_in,
      checkOut: email.check_out,
      reason: email.reason,
      sentBy: email.sent_by.name,
      message: email.message,
      status: email.status_type,
      recipientList: email.recipient,
    };
  });

  const columns = [
    {
      field: "date",
      headerName: "Date",
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <LiaCalendar className="w-5 h-5  text-yellow-500" />
            Date
          </div>
        );
      },
      valueGetter: (params) => {
        return new Date(params.row.date).toLocaleString("en-GB");
      },
      flex: 1,
    },
    {
      field: "subject",
      headerName: "Subject",
      resizable: true,
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <MdSubject className="w-5 h-5  text-yellow-500 mb-1" /> Subject
          </div>
        );
      },
      flex: 1,
    },

    {
      field: "hotelName",
      headerName: "Hotel Name",
      resizable: true,
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <TbBuildingEstate className="w-5 h-5  text-yellow-500 mb-1" />
            Hotel Name
          </div>
        );
      },
      valueGetter: (params) => {
        return params.row.hotelName || "No hotel name";
      },
      flex: 1,
    },

    {
      field: "checkIn",
      headerName: "Check In",
      resizable: true,
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <LiaCalendar className="w-5 h-5  text-yellow-500" />
            Check In
          </div>
        );
      },
      valueGetter: (params) => {
        if (params.row.checkIn) return new Date(params.row.checkIn).toLocaleDateString("en-GB");

        return "No time";
      },
      flex: 1,
    },
    {
      field: "checkOut",
      headerName: "Check Out",
      resizable: true,
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <LiaCalendar className="w-5 h-5  text-yellow-500" />
            Check Out
          </div>
        );
      },
      valueGetter: (params) => {
        if (params.row.checkOut) return new Date(params.row.checkOut).toLocaleDateString("en-GB");

        return "No time";
      },
      flex: 1,
    },

    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <BiMessageAltError className="w-5 h-5  text-yellow-500 mb-1" /> Reason
          </div>
        );
      },
      valueGetter: (params) => {
        return params.row.reason || "No reason";
      },
    },
    {
      field: "sentBy",
      headerName: "Sent By",
      flex: 1,
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <IoMdPerson className="w-5 h-5  text-yellow-500 mb-1" /> Sent By
          </div>
        );
      },
    },
    {
      field: "message",
      headerName: "Sent By",
      flex: 1,
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <MdEmail className="w-5 h-5  text-yellow-500 mb-1" /> Email View
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <EmailMessageModal
            message={params.row.message}
            recipientList={params.row.recipientList}
            reason={params.row.reason}
          />
        );
      },
    },

    {
      field: "status",
      headerName: "Email Status",
      flex: 1,
      renderHeader: (params) => {
        return (
          <div className="flex items-center gap-x-1">
            <TbProgress className="w-5 h-5  text-yellow-500 mb-1" /> Email Status
          </div>
        );
      },
    },
  ];
  return (
    <div className="min-w-[0]" style={{ height: "auto", width: "100%" }}>
      <DataGrid
        data={rows}
        rows={rows}
        columns={columns}
        initialState={{
          ...rows.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </div>
  );
}

function EmailMessageModal({ message, recipientList, reason }) {
  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  const [showEmailMessageModal, setShowEmailMessageModal] = useState(false);
  // Removing the brackets and single quotes from the string
  const cleanedString = recipientList.replace(/[\[\]']/g, "");

  // Splitting the string by comma and trimming each email
  const emailsArray = cleanedString.split(",").map((email) => email.trim());
  return (
    <>
      <button
        onClick={() => {
          setShowEmailMessageModal(true);
        }}
        className="cursor-pointer"
      >
        <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showEmailMessageModal}
        position={"center"}
        onClose={() => setShowEmailMessageModal(false)}
      >
        <Modal.Header className="border-b border-b-gray-300 mb-6" />
        <Modal.Body>
          <div className="mb-2">
            <h5 className="font-medium text-xl text-white bg-gray-900 py-2 px-2 rounded mb-2"> Recipient List </h5>
            {emailsArray?.length === 0 ? (
              <p>No recipient found</p>
            ) : (
              <ol className="  border border-gray-200 p-2 rounded ">
                {emailsArray?.map((recipient, index) => {
                  return (
                    <li
                      className=" hover:underline hover:cursor-pointer hover:underline-offset-1 text-sm text-gray-500 font-semibold text-left "
                      key={recipient.id}
                    >
                      <a href={`mailto:${recipient}`}>{recipient}</a>
                    </li>
                  );
                })}
              </ol>
            )}
          </div>
          <div>
            <h5 className="font-medium text-xl text-white bg-gray-900 py-2 px-2 rounded mb-2"> Email Details </h5>
            <p className="border-gray-200 border p-2 rounded text-sm text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
              {message}
            </p>
          </div>

          {reason && (
            <div>
              <h5 className="font-medium text-xl text-white bg-gray-900 py-2 px-2 rounded mb-2"> Reason </h5>
              <p className="border-gray-200 border p-2 rounded text-sm text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                {reason}
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BookingEmailList;
