import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function ClientNameFormAr({ setClientNameAr, numOfPerson, setSaveBtn }) {
  // const [loopLength, setLoopLength] = useState(numOfPerson);
  const [loopData, setLoopData] = useState(Array(numOfPerson).fill(""));
  const [t] = useTranslation();

  // const handleLoopLengthChange = (event) => {
  //   const newLength = parseInt(event.target.value, 10);
  //   setLoopLength(newLength);
  //   setLoopData(Array(newLength).fill(""));
  // };

  const handleDataChange = (index, value) => {
    setLoopData((prevData) => {
      const newData = [...prevData];
      newData[index] = value;
      return newData;
    });
  };

  let clientNameArray = [];
  clientNameArray.push(loopData);

  clientNameArray.map((item) => setClientNameAr(item));

  return (
    <div>
      <ul>
        {loopData.map((item, index) => (
          <li key={index} className="w-full">
            <div className="flex flex-col   text-end w-full text-sm" dir="ltr">
              <label className="text-sm font-body">
                {" "}
                <span className="text-yellow-600">*</span>
                {t("clientNameAr")}
              </label>
              <input
                // placeholder='ادخل اسمك'
                className="w-full h-6 text-right  border-gray-200  border-opacity-100 border-b-2"
                type="text"
                value={item}
                lang="ar"
                onChange={(event) => {
                  handleDataChange(index, event.target.value);
                  setSaveBtn(false);
                }}
                required
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ClientNameFormAr;
