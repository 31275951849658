import React from "react";

export default function ContractTable({ heading1, heading2, heading3, arr, arr2, att1, att2, att3, att4, att5 }) {
  return (
    <div className="flex  flex-col justify-center gap-2 items-center w-full">
      <div className=" w-full ">
        <div className=" w-full py-2 ">
          <div className=" flex flex-col justify-center items-center ">
            {arr.length > 0 && (
              <table className="w-full  max-sm:w-full border-2 border-black text-center text-sm font-light">
                <thead className="w-full border-b-2 border-black  font-medium text-black">
                  <tr>
                    <th scope="col" className="  py-1 border-r border-black">
                      {heading1}
                    </th>
                    <th scope="col" className=" px-6 py-1">
                      {heading2}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {}
                  {arr?.map((elem) => {
                    return (
                      <tr className="border-black border">
                        <td className="border-black border">{elem[att1]}</td>
                        <td>{elem[att2]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {arr2?.length > 0 && (
              <table className="w-full mt-4 max-sm:w-full border-2 border-black text-center text-sm font-light">
                <thead className="w-full border-b-2 border-black  font-medium text-black">
                  <tr>
                    {/* <th scope="col" class="  py-1 border-r border-black">
                    {heading3}
                  </th> */}
                    <th scope="col" className="  py-1 border-r border-black">
                      {"Child Order"}
                    </th>
                    <th scope="col" className=" px-6 border-r  border-black py-1">
                      {"Min Age"}
                    </th>
                    <th scope="col" className=" px-6 border-r border-black py-1">
                      {"Max Age"}
                    </th>
                    <th scope="col" className=" px-6 border-r border-black py-1">
                      {heading2}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {arr2 &&
                    arr2?.map((elem) => {
                      return (
                        <tr className="border-black border">
                          <td className="border-black border">{elem[att5]}</td>
                          <td className="border-black border">{elem[att3]}</td>
                          <td className="border-black border">{elem[att4]}</td>

                          <td>{elem[att2]}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
