import React, { useEffect, useState } from "react";
import { BsCurrencyExchange, BsListUl } from "react-icons/bs";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
// import jwtDecode from 'jwt-decode';
import { getBoxNameAction } from "../../../redux/actions/cashAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCashBoxStatment } from "../../../redux/actions/addCashBoxAction";
import { GiTakeMyMoney } from "react-icons/gi";
import { GoPerson } from "react-icons/go";
import { LiaCalendar } from "react-icons/lia";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import Loading from "../Loading/Loading";
import { FcSafe } from "react-icons/fc";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import ListFilters from "../../../utils/ListFilters";

const CashBoxesStatments = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const params = useParams();
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [cashId, setCashId] = useState(false);
  const navigate = useNavigate();
  const [loadButton, setLoadButton] = useState(false);
  const [cashStatment, setCashStatment] = useState();
  const [currency, setCurrency] = useState();
  const [currentBalance, setCurrentBalance] = useState();
  const [columnVisibility, setColumnVisibility] = useState({});
  const { boxNameData } = useSelector((state) => state.cashReducer);
  const { cashBoxStatmentData } = useSelector((state) => state.addCashBoxReducer);

  useEffect(() => {
    dispatch(getBoxNameAction(token));
  }, []);

  let boxNames = boxNameData
    ? boxNameData.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency_type };
      })
    : [];

  useEffect(() => {
    if (cashId) {
      setLoad(true);
      setLoad(true);
      setLoadButton(true);
      dispatch(
        getCashBoxStatment(token, cashId, params.page, params.sorting, (result) => {
          if (result.status === 200) {
            setLoad(false);
            setLoadButton(false);
            setCashStatment(result.data);
            setCurrency(result.data.results[0].box_name.currency_type);
            setCurrentBalance(result.data.results[0].box_name.balance);
          }
        }),
      );
    }
  }, [params.page, location]);

  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("grid-visibility-cash-box-list");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility), slug: false });
    }
  }, []);

  function comparator([para]) {
    if (para) {
      if (para.sort === "asc") {
        //    dispatch(sortBookingListAction(token,params.key))
        navigate(`/dashboard/cash-boxes-statment/${params.page}/${para.key}`);
      } else {
        //  dispatch(sortBookingListAction(token,"-"+params.key))
        navigate(`/dashboard/cash-boxes-statment/${params.page}/-${para.key}`);
      }
    } else {
      // dispatch(sortBookingListAction(token,params.key))
      navigate(-1);
    }
  }

  function handlePageChange(para) {
    setLoad(true);
    navigate(`/dashboard/cash-boxes-statment/${para.page + 1}/${params.sorting}`);
  }

  const rows =
    cashStatment &&
    cashStatment.results.map((item, index) => {
      return {
        created_date: item.created_date.slice(0, 10),
        created_time: item.created_date.slice(11, 19),
        booked_room: item.booked_room,
        id: item.id,
        service_type: item.service_type,
        currency_type: item.currency_type,
        amount: item.amount,
        source_name: item.source_name,
        created_by: item.box_name.created_by,
        balance: item.box_name.balance,
      };
    });
  const columns = [
    {
      key: "created_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-4 h-4 text-yellow-500" />
          CREATED DATE
        </div>
      ),

      dataIndex: "created_date",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1">{params.created_date}</div>;
      },
    },

    {
      key: "created_time",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <AiOutlineInfoCircle className="w-4 h-4 text-yellow-500" />
          CREATED TIME
        </div>
      ),
      dataIndex: "created_time",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1">{params.created_time}</div>;
      },
    },

    {
      key: "booked_room",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" /> BOOKING ID{" "}
        </div>
      ),
      dataIndex: "booked_room",
      editable: false,
      render: (text, params) => {
        return (
          <div className="w-full flex flex-col gap-y-1  justify-center items-center  mr-5 ">
            <p className="W-[30%] text-[#001a35]">{params.booked_room}</p>
          </div>
        );
      },
    },
    {
      key: "service_type",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-4 h-4 text-yellow-500" /> SERVICE TYPE{" "}
        </div>
      ),
      dataIndex: "service_type",
      editable: false,
      render: (text, params) => {
        return (
          <div className="w-full flex flex-col gap-y-1  justify-center items-center  mr-5 ">
            <p className="W-[30%] text-[#001a35]">{params.service_type}</p>
          </div>
        );
      },
    },

    {
      key: "currency_type",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BsCurrencyExchange className="w-4 h-4 text-yellow-500" />
          Currency{" "}
        </div>
      ),
      dataIndex: "currency_type",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.currency_type}</div>;
      },
    },

    {
      key: "amount",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiTakeMyMoney className="w-4 h-4 text-yellow-500" />
          AMOUNT
        </div>
      ),

      dataIndex: "amount",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.amount}</div>;
      },
    },

    {
      key: "source_name",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GoPerson className="w-4 h-4 text-yellow-500" />
          SOURCE NAME
        </div>
      ),
      dataIndex: "source_name",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.source_name}</div>;
      },
    },

    {
      key: "created_by",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GoPerson className="w-4 h-4 text-yellow-500" />
          CASHIER NAME{" "}
        </div>
      ),
      dataIndex: "created_by",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.created_by}</div>;
      },
    },
    {
      key: "balance",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiTakeMyMoney className="w-4 h-4 text-yellow-500" />
          BALANCE
        </div>
      ),
      dataIndex: "balance",
      editable: false,
      render: (text, params) => {
        return <div className="w-full flex items-center justify-center gap-x-1 mr-5">{params.balance}</div>;
      },
    },
  ];
  /////permission/////////
  const permissionData = {
    permission_codes: ["room_booking.view_cashpaymenttransaction"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="formPage-wrap px-4">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "room_booking.view_cashpaymenttransaction" && item.value === true) {
            return (
              <div className="w-full   bg-white  ">
                {boxNameData ? (
                  <div className="formPage-wrap ">
                    <div className="w-full flex justify-center ">
                      <h2 className="header-h2 ">
                        <BsListUl className="w-6 h-6 mt-1" /> Cash Box Total Statment
                      </h2>
                    </div>

                    <div className={`grid form-wrap md:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-full  `}>
                      <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                        <p className=" flex text-black font-semibold  text-lg ">Please Select Cash Box Name</p>
                        <Select
                          name="box_name"
                          options={boxNames}
                          onChange={(e) => {
                            setLoad(true);
                            setLoadButton(true);
                            setCashId(e.value);
                            setCurrency("");
                            setCurrentBalance("");
                            dispatch(
                              getCashBoxStatment(token, e.value, params.page, params.sorting, (result) => {
                                if (result.status === 200) {
                                  setLoad(false);
                                  setLoadButton(false);
                                  setCashStatment(result.data);
                                  setCurrency(result.data.results[0]?.box_name.currency_type);
                                  setCurrentBalance(result.data.results[0]?.box_name.balance);
                                }
                              }),
                            );
                          }}
                          required
                          isSearchable
                          className=" w-full  h-10  border rounded-lg  bg-white "
                          placeholder="Box Name"
                        />
                      </div>
                      {loadButton ? (
                        <div className=" w-full flex flex-col gap-y-4 items-center justify-between  px-3 pt-4 mt-14 border-solid">
                          <ButtonLoading />
                        </div>
                      ) : (
                        <>
                          <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                            <p className=" flex text-gray-600   ">Current Balance </p>
                            <div
                              name="currency"
                              className=" w-full px-3 h-10  border rounded-md floating-input bg-white"
                            >
                              <p className="text-black mt-2">{currentBalance && currentBalance}</p>
                            </div>
                          </div>

                          <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                            <p className=" flex text-gray-600   ">Currency </p>
                            <div
                              name="currency"
                              className=" w-full px-3 h-10  border rounded-md floating-input bg-white"
                            >
                              <p className="text-black mt-2">{currency && currency}</p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {" "}
                    <TopBarProgress />
                    <div className="w-full h-[70%] flex items-center justify-center">
                      <Loading />
                    </div>
                  </>
                )}
                {load ? (
                  <>
                    {" "}
                    <TopBarProgress />
                    <div className="w-full h-[70%] flex items-center justify-center">
                      <Loading />
                    </div>
                  </>
                ) : cashStatment ? (
                  cashStatment.count > 0 ? (
                    <div className="w-full overflow-auto">
                      <ListFilters columns={columns} rows={rows} title="CashBox statments List" />
                    </div>
                  ) : (
                    // <Box sx={{ height: "100%", width: "100%", backgroundColor: "white", overflowX: "auto" }}>
                    //   <DataGrid
                    //     rows={rows}
                    //     columns={columns}
                    //     autoHeight
                    //     rowCount={cashStatment.count}
                    //     onSortModelChange={comparator}
                    //     columnVisibilityModel={columnVisibility}
                    //     onColumnVisibilityModelChange={(visibility) => {
                    //       setColumnVisibility(visibility);
                    //       localStorage.setItem("grid-visibility-cash-box-list", JSON.stringify(visibility));
                    //     }}
                    //     paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
                    //     onPaginationModelChange={handlePageChange}
                    //     paginationMode="server"
                    //     className="w-[120%] max-md:w-[450%]"
                    //   />
                    // </Box>
                    <div className="w-full flex flex-col gap-y-1 h-[70%]  items-center justify-center opacity-60">
                      <img src="/images/safe_open_empty.png" className=" w-96 h-96" />
                      <p className="font-semibold text-gray-800 text-lg">Cash Box is empty</p>
                    </div>
                  )
                ) : (
                  <div className="w-full h-[70%] flex flex-col items-center justify-center  ">
                    <FcSafe className="w-48 h-48 text-gray-400 opacity-40" />
                    <p className="font-semibold text-2xl text-gray-800 opacity-50">
                      No Cash Box Has Been Selected
                    </p>{" "}
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div className="w-full h-screen">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default CashBoxesStatments;
