import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import cookie from "react-cookies";
import Loading from "../../Loading/Loading";
import { GiBank } from "react-icons/gi";
import { MdDeleteForever, MdOutlineSettings, MdOutlineSpeakerNotes } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import { getPermissionAction } from "../../../../redux/actions/permissionAction";
import NoPermission from "../../NoPermission/NoPermission";
import { RxUpdate } from "react-icons/rx";
// import usePermissions from "../../../customHooks/usePermissions";
import DeletePopUp from "../../../molecules/DeletePopUp/DeletePopUp";
import { AiOutlineEye } from "react-icons/ai";
import { DeleteChequesAction, getAllChequesforCheckBookAction } from "../../../../redux/actions/bankChequesActions";
import ListFilters from "../../../../utils/ListFilters";
// import { deletePermisssion, getSearchListAction } from "../../../redux/actions/searchPermissionAction";
const ChequeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});

  const params = useParams();
  const { page } = params;
  const [type, setType] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [deletePopup, setDeletePopup] = useState("");
  const [permissionID, setPermissionID] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [cheque, setCheque] = useState([]);
  const [navigated, setNavigated] = useState(true);

  function handlePageChange(para) {
    // if (!para.page) {
    //   para.page = 1;
    // }
    navigate(`/dashboard/accounting-setting/bankcheque/${params?.id}/${para?.page + 1}`);
    setLoad(true);
  }

  const fetchData = () => {
    // setLoad(false);
    dispatch(
      getAllChequesforCheckBookAction(token, params?.id, params?.page, (result) => {
        if (result?.status === 200) {
          setLoad(false);

          setCheque(result?.data);
        }
      }),
    );
  };
  useEffect(() => {
    fetchData();
  }, [page]);
  useEffect(() => {
    setLoad(false);
    const currentColumnVisibility = localStorage?.getItem("grid-visibility-cheque-list");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility), slug: false });
    }
  }, [page]);

  function handleDeletePopUp(params) {
    setDeletePopup(`Permission for ${params.name}`);
    setPermissionID(params.id);
  }

  const handleDelete = (permissionID) => {
    dispatch(
      DeleteChequesAction(token, permissionID, (result) => {
        if (result.status === 204) {
          fetchData();
        }
      }),
    );
    setDeletePopup(false);
  };

  // function handlePageChange(para) {
  //   navigate(`/dashboard/accounting/accounting-setting/bankcheque/${para.page + 1}/${params.sorting}`);
  //   // setIsLoading(false);
  // }
  // const handleNavigation = () => {
  //   if (navigated) {
  //     navigate(`/dashboard/accounting/accounting-setting/chequelist/${params.id}/update`);
  //   } else {
  //     navigate(`/dashboard/accounting/accounting-setting/chequelist/${params.id}`);
  //   }
  // };
  // useEffect(() => {
  //   cheque &&
  //     cheque.map((item) => {
  //
  //       if (
  //         item.id !== null
  //         // item.to !== ""
  //         // item.cheque_number !== "" ||
  //         // item.cheque_status !== "" ||
  //         // item.amount !== "" ||
  //         // item.due_date !== "" ||
  //         // item.details !== "" ||
  //         // item.purpose !== "" ||
  //         // item.issued_date !== ""
  //       ) {
  //         setNavigated(false);
  //       }
  //     });
  // }, []);
  const rows =
    cheque?.results && cheque?.results?.length > 0
      ? cheque?.results?.map((item) => {
          return {
            id: item?.id,
            to: item?.to,
            cheque_number: item?.cheque_number,
            cheque_status: item?.cheque_status,
            amount: item?.amount,
            due_date: item?.due_date,
            details: item?.details,
            purpose: item?.purpose,
            issued_date: item?.issued_date,
            confirmed_by: item?.confirmed_by,
          };
        })
      : "";

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1 text-xs ">
          <MdOutlineSpeakerNotes className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className="flex items-center justify-start gap-x-5 ml-5"
          >
            {params.id}
          </Link>
        );
      },
    },
    {
      key: "cheque_number",
      title: (
        <div className="flex items-center gap-x-1 text-xs ">
          <MdOutlineSpeakerNotes className="w-5 h-5  text-yellow-500" />
          Cheque Number
        </div>
      ),
      dataIndex: "cheque_number",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className="flex items-center justify-start gap-x-5 ml-5"
          >
            {params.cheque_number}
          </Link>
        );
      },
    },
    {
      key: "to",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          to
        </div>
      ),
      dataIndex: "to",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className=""
          >
            {params.to}
          </Link>
        );
      },
    },

    {
      key: "issued_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          Issued Date
        </div>
      ),
      dataIndex: "issued_date",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className=""
          >
            {params.issued_date}
          </Link>
        );
      },
    },
    {
      key: "due_date",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          Due Date
        </div>
      ),
      dataIndex: "due_date",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className=""
          >
            {params.due_date}
          </Link>
        );
      },
    },
    {
      key: "amount",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          Amount
        </div>
      ),
      dataIndex: "amount",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className=""
          >
            {params.amount}
          </Link>
        );
      },
    },
    {
      key: "purpose",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          Purpose
        </div>
      ),
      dataIndex: "purpose",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className=""
          >
            {params.purpose}
          </Link>
        );
      },
    },
    {
      key: "details",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          Details
        </div>
      ),
      dataIndex: "details",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className=""
          >
            {params.details}
          </Link>
        );
      },
    },
    {
      key: "cheque_status",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          Cheque Status
        </div>
      ),
      dataIndex: "cheque_status",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={
              navigated
                ? `/dashboard/accounting-setting/chequelist/${params.id}/update`
                : `/dashboard/accounting-setting/chequelist/${params.id}`
            }
            className=""
          >
            {params.cheque_status}
          </Link>
        );
      },
    },

    // {
    //   key: "created_at",
    //   title: (
    //     <div className="flex items-center gap-x-1 text-xs">
    //       <LiaCalendar className="w-5 h-5  text-yellow-500" />
    //       CREATED AT
    //     </div>
    //   ),
    //   flex: 1,
    //   editable: false,
    //   render: (text,params) => {
    //     return <div className="ml-3">{params.created_at}</div>;
    //   },
    // },

    // {
    //   key: "updated_at",
    //   title: (
    //     <div className="flex items-center gap-x-1 text-xs ml-5">
    //       <LiaCalendar className="w-5 h-5  text-yellow-500" />
    //       UPDATE AT
    //     </div>
    //   ),
    //   flex: 1,
    //   editable: false,
    //   render: (text,params) => {
    //     return <div className="ml-10">{params.updated_at}</div>;
    //   },
    // },
    {
      key: "operations",
      title: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",
      editable: false,

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            <Link
              to={`/dashboard/accounting-setting/cheque-details/${params.id}`}
              className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-gray-700 " />
            </Link>

            <Link
              to={`/dashboard/accounting-setting/chequelist/${params.id}/update`}
              className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
            >
              <RxUpdate className="w-5 h-5 text-white" />
            </Link>

            <button className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all">
              <MdDeleteForever onClick={() => handleDeletePopUp(params)} className="w-5 h-5 text-white" />
            </button>
          </div>
        );
      },
    },
  ];

  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.view_cheque"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full overflow-x-hidden h-screen">
      {load ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
          <TopBarProgress />
        </div>
      ) : (
        permissionFlag &&
        permission?.map((item) => {
          if (item.name === "accounting.view_cheque" && item.value === true) {
            return (
              <Box key={item.id}>
                <div className="w-full overflow-auto">
                  <ListFilters columns={columns} rows={rows} title="Cheques List" />
                </div>
                {/* <DataGrid
                  rows={rows}
                  columns={columns}
                  slots={{
                    Toolbar: GridToolbar,
                  }}
                  // rowCount={cheque?.count}
                  rowCount={cheque ? cheque?.count : 2}
                  // onSortModelChange={comparator}
                  columnVisibilityModel={columnVisibility}
                  onColumnVisibilityModelChange={(visibility) => {
                    setColumnVisibility(visibility);
                    localStorage?.setItem("grid-visibility-cheque-list", JSON.stringify(visibility));
                  }}
                  paginationModel={{ page: Number(params?.page - 1), pageSize: 10 }}
                  onPaginationModelChange={handlePageChange}
                  paginationMode="server"
                /> */}
                {deletePopup && (
                  <DeletePopUp
                    name={deletePopup}
                    Func={() => handleDelete(permissionID)}
                    hideFunc={() => {
                      setDeletePopup(false);
                    }}
                  />
                )}
              </Box>
            );
          } else {
            return (
              <div className="w-full h-screen ">
                <NoPermission />
              </div>
            );
          }
        })
      )}
    </div>
  );
};

export default ChequeList;
