import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getBookingRequestDetails } from "../../../redux/actions/bookingRequests";
import { TbListDetails } from "react-icons/tb";
import Chat from "../../molecules/Chat/Chat";
import { getAllChats } from "../../../redux/actions/bookingRequests";

export default function BookingRequestDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector((state) => state.bookingRequestDetails);

  useEffect(() => {
    dispatch(getBookingRequestDetails(id));
  }, []);

  return (
    <div className="w-full">
      {Object.keys(details).length > 0 ? (
        <>
          <div className="w-[80%] mx-auto my-2 shadow-xl">
            <h1 className="bg-blue-900 text-white text-lg font-bold border-white p-5 rounded-md flex justify-center gap-5 items-center">
              <TbListDetails size={30} />
              Booking Request Details
            </h1>
            <table className="w-full">
              <tbody>
                <tr className="w-full p-5">
                  <td className="bg-gray-300 text-black font-bold px-2 p-3 w-[30%]">Hotel Name</td>
                  <td className="bg-gray-300  px-3">{details.hotel_name}</td>
                  <td className="bg-gray-300 text-black font-bold px-2 p-3 ">Description</td>
                  <td className="bg-gray-300  px-3">{details.description}</td>
                </tr>
                <tr className="w-full p-5">
                  <td className="bg-gray-100 text-black font-bold px-2 p-3 ">Booking Status</td>
                  <td className="bg-gray-100  px-3">{details.booking_status}</td>
                  <td className="bg-gray-100 text-black font-bold px-2 p-3 ">Request Type</td>
                  <td className="bg-gray-100  px-3">
                    {details.other_note ? details.other_note : details.request_type.types}
                  </td>
                </tr>
                <tr className="w-full p-5">
                  <td className="bg-gray-300 text-black font-bold px-2 p-3 ">Client Name</td>
                  <td className="bg-gray-300  px-3">{details.client_name}</td>
                  <td className="bg-gray-300 text-black font-bold px-2 p-3 ">Booked Room</td>
                  <td className="bg-gray-300  px-3">{details.booked_room}</td>
                </tr>
                <tr className="w-full p-5">
                  <td className="bg-gray-100 text-black font-bold px-2 p-3 ">Created Date</td>
                  <td className="bg-gray-100  px-3">{details.created_date.split("T")[0]}</td>
                  <td className="bg-gray-100 text-black font-bold px-2 p-3 ">Request Priority</td>
                  <td className="bg-gray-100  px-3">{details.request_priority}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Chat id={id} />
        </>
      ) : (
        <img
          src="/images/loading-air.gif"
          alt="loading"
          className="loadingGif"
          style={{ marginLeft: " 30vw", marginTop: "40vh" }}
        />
      )}
    </div>
  );
}
