// src/ImageSorter.js
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Button, FilledInput } from "@mui/material";
import { MdCloudUpload } from "react-icons/md";
import { BsStars } from "react-icons/bs";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { Banner } from "flowbite-react";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";

const AddBanner = () => {
  const [images, setImages] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);
  const navigate = useNavigate();

  const {
    data: dataImages,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["search-slider-images"],
    queryFn: () => fetchDataQuery(`/en/site-settings/api/v1/search-slider-images/`),
  });

  useEffect(() => {
    // Replace with your API call
    if (dataImages) {
      const formattedImages = dataImages?.map((imgObj, index) => {
        return { file: null, url: imgObj.image, sortOrder: index + 1, urlid: imgObj.id };
      });
      setImages(formattedImages);
    }
  }, [dataImages]);

  const { mutate, isError, isPending } = useMutation({
    mutationFn: (newValues) =>
      mutateDataQuery(`/en/site-settings/api/v1/search-slider-images/update_multiple/`, "patch", newValues),
    onError: (error) => {
      // An error happened!
      toast.error(`${error?.message || "failed post data"}  `);
    },
    onSuccess: (result) => {
      // Boom baby!
      toast.success("banner is edit successfully");
      navigate(`/`);
    },
  });

  const { mutate: mutateDelete } = useMutation({
    mutationFn: (urlid) => mutateDataQuery(`/en/site-settings/api/v1/search-slider-images/${urlid}/`, "delete"),
    onError: (error) => {
      // An error happened!
      toast.error(`${error || "Your image was not Delete"}  `);
    },
    onSuccess: () => {
      // Boom baby!
      refetch();
      toast.success("This image was deleted successfully");
    },
  });

  const handleSortOrderChange = (e, index) => {
    const newSortOrder = parseInt(e.target.value);
    const newImages = images.map((img, imgIndex) => (imgIndex === index ? { ...img, sortOrder: newSortOrder } : img));
    setImages(newImages);
  };

  const deleteImage = (index, urlid) => {
    const newImages = images.filter((_, imgIndex) => imgIndex !== index);
    setImages(newImages);
    mutateDelete(urlid);
  };

  const sortImages = () => {
    const sorted = [...images].sort((a, b) => a.sortOrder - b.sortOrder);
    setSortedImages(sorted);
  };

  const handleUpload = () => {
    const values = sortedImages?.reduce(
      (acc, image) => {
        acc.image_ids.push(image.urlid);
        acc.orders.push(image.sortOrder);
        return acc;
      },
      { image_ids: [], orders: [] },
    );

    mutate(values);
  };

  if (isLoading) {
    return (
      <div className="w-full flex items-center justify-center h-screen">
        <Loading /> <TopBarProgress />
      </div>
    );
  }
  return (
    <div>
      <div className=" flex flex-col items-center w-full  p-3">
        <div>
          {images.map((image, index) => (
            <div
              className="flex gap-5"
              key={index}
              style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
            >
              <img
                src={image.url}
                alt={`Uploaded #${index}`}
                className=" rounded-md md:w-[200px] md:h-[115px] w-[115px] h-w-[115px] "
              />
              <FilledInput
                type="number"
                value={image.sortOrder}
                onChange={(e) => handleSortOrderChange(e, index)}
                className="!h-10"
                style={{ marginLeft: "10px" }}
              />
              <IconButton onClick={() => deleteImage(index, image?.urlid)} style={{ marginLeft: "10px" }}>
                <DeleteIcon color="error" />
              </IconButton>
            </div>
          ))}
        </div>
        {images?.length > 0 && (
          <Button variant="contained" className="!bg-emerald-800 !mt-5" onClick={sortImages}>
            Sort Images
          </Button>
        )}
      </div>

      {sortedImages?.length > 0 && (
        <>
          <div className=" flex flex-col items-center w-full  mt-10 p-2">
            <h2 className=" text-2xl font-bold text-cyan-800 mb-10 flex gap-1 ">
              <BsStars className=" text-amber-400" />
              Sorted Images
            </h2>
            <div className=" flex flex-wrap justify-center gap-5 ">
              {sortedImages.map((image, index) => (
                <img
                  key={index}
                  src={image.url}
                  alt={`Sorted #${index}`}
                  width={100}
                  className="mb-10 md:w-[200px] md:h-[200px] w-[115px] h-w-[115px] rounded-md "
                />
              ))}
            </div>
          </div>
          <div className="flex justify-center w-full mb-5">
            <Button
              onClick={handleUpload}
              variant="contained"
              className="!bg-sky-900 !mt-5"
              startIcon={<MdCloudUpload />}
            >
              Submit Images Slider
            </Button>
          </div>
        </>
      )}

      {sortedImages?.length === 0 && images?.length === 0 && !isLoading && (
        <Banner className=" p-5">
          <div className=" p-5 flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50  dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-red-600 dark:text-white">
                No Items Found Go to Add Images{" "}
              </h2>
            </div>
            <div className="flex shrink-0 items-center">
              <Link
                to={"/dashboard/Banner/addBanner"}
                className="mr-2 inline-flex items-center justify-center rounded-lg bg-cyan-700 px-3 py-2 text-xs font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
              >
                Add Images
                <HiArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
          </div>
        </Banner>
      )}
    </div>
  );
};

export default AddBanner;
