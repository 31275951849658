import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import Loading from "../../../Loading/Loading";
import { MdAccessTimeFilled, MdDateRange } from "react-icons/md";
import ListFilters from "../../../../../utils/ListFilters";
import { LiaFileContractSolid } from "react-icons/lia";
import moment from "moment";
import { FaCircleInfo } from "react-icons/fa6";
import Errorfetch from "../../../Errorfetch/Errorfetch";

function convertSecondsToHMS(seconds) {
  const duration = moment.duration(seconds, "seconds");

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const secs = duration.seconds();

  return `${hours}h ${minutes}m ${secs}s`;
}

function ReportList() {
  const [searchParams] = useSearchParams();

  const createdBy = searchParams.get("created_by") || "";
  const period = searchParams.get("period") || "";
  const startDate = searchParams.get("start_date") || "";
  const endDate = searchParams.get("end_date") || "";

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { isLoading, data, isError } = useQuery({
    queryKey: ["contracting-report", createdBy, period, startDate, endDate],
    queryFn: async () => {
      return fetchDataQuery(
        `/report/department-contract-report/?period=${period}&start_date=${startDate}&end_date=${endDate}&created_by=${createdBy}&page=1&page_size=10`,
      );
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Errorfetch error={"Failed to Fetch Report"} />;
  }

  const rows = data?.results?.map((item) => {
    return {
      periodGroup: item.period_group,
      contractCount: item.contract_count,
      totalCreatedTime: item.total_created_time,
    };
  });

  const columns = [
    {
      key: "periodGroup",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdDateRange className="w-5 h-5  text-yellow-500" />
          Period
        </div>
      ),
      dataIndex: "periodGroup",
    },
    {
      key: "contractCount",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <LiaFileContractSolid className="w-5 h-5  text-yellow-500" />
          Contract Count
        </div>
      ),
      dataIndex: "contractCount",
    },
    {
      key: "totalCreatedTime",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdAccessTimeFilled className="w-5 h-5  text-yellow-500" />
          Total Created Time
        </div>
      ),
      render: convertSecondsToHMS,
      dataIndex: "totalCreatedTime",
    },
  ];

  return (
    <div>
      <div className="flex flex-col md:flex-row items-center gap-4">
        <div className="bg-white p-4 flex gap-2 rounded items-center justify-between">
          <FaCircleInfo className="text-[#002244] w-5 h-5" />
          <div>
            <span className="font-semibold">Average Creation Time : </span>
            <span>{convertSecondsToHMS(data?.statistics2?.average_creation_time)}</span>
          </div>
        </div>
        <div className="bg-white p-4 flex gap-2 rounded items-center justify-between">
          <FaCircleInfo className="text-[#002244] w-5 h-5" />
          <div>
            <span className="font-semibold">Daily Average Upload : </span>
            <span>{convertSecondsToHMS(data?.statistics2?.daily_average_upload)}</span>
          </div>
        </div>
      </div>
      <ListFilters
        handlePagination={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        currentPage={page}
        listPageSize={pageSize}
        totalPages={data?.count / pageSize}
        title="Contract Department Report"
        rows={rows}
        columns={columns}
      />
    </div>
  );
}

export default ReportList;
