import * as yup from "yup";

export const editRoomValidationSchema = yup.object().shape({
  hotel_name: yup.string().required("This Field is Required"),
  room_name: yup.string().required("This Field is Required"),
  room_name_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),
  description: yup.string().required("This Field is Required"),
  description_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),
  space: yup.number("It must be a number").positive(`You can't put negative value`).required("This Field is Required"),
  max_adults: yup
    .number("It must be a number")
    .positive(`You can't put negative value`)
    .required("This Field is Required"),
  max_children: yup
    .number("It must be a number")
    .positive(`You can't put negative value`)
    .required("This Field is Required"),
  max_occupancy: yup
    .number("It must be a number")
    .positive(`You can't put negative value`)
    .required("This Field is Required"),
  view: yup.string().required("This Field is Required"),
  view_ar: yup
    .string()
    .required("This Field is Required")
    .matches(/^[\u0600-\u06FF0-9\s,()./*-]+$/, {
      message: "Please enter Arabic text only",
    }),
  balcony: yup.string().required("This Field is Required"),
  ordering: yup
    .number("It must be a number")
    .positive(`You can't put negative value`)
    .required("This Field is Required"),
  features: yup.array().required("This Field is Required"),
  room_images: yup.array(),
});
