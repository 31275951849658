import { ADD_CASH_BOX_FLAG, CASH_BOX_DATA, CASH_BOX_STATMENT_DATA, LIST_FLAG } from "../types/types";

const initialState = {
  cashBoxFlag: false,
  cashBoxData: null,
  cashBoxStatmentData: null,
  listFlag: true,
};

export const addCashBoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CASH_BOX_FLAG:
      return { ...state, cashBoxFlag: action.payload };
    case CASH_BOX_DATA:
      return { ...state, cashBoxData: action.payload };
    case CASH_BOX_STATMENT_DATA:
      return { ...state, cashBoxStatmentData: action.payload };
    case LIST_FLAG:
      return { ...state, listFlag: action.payload };
    default:
      return state;
  }
};
