import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { deleteBankChequesIdAction, getAllBankChequesAction } from "../../../redux/actions/bankChequesActions";
import { BiTransfer } from "react-icons/bi";
import { GiBank } from "react-icons/gi";
import { RiAccountPinBoxLine } from "react-icons/ri";
import { BsCurrencyExchange } from "react-icons/bs";
import { MdDeleteForever, MdOutlineSettings, MdOutlineSpeakerNotes } from "react-icons/md";
import { LiaCalendar } from "react-icons/lia";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";
import usePermissions from "../../../customHooks/usePermissions";
import NoPermission from "../NoPermission/NoPermission";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { Box } from "@mui/material";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { toast } from "react-toastify";
import ListFilters from "../../../utils/ListFilters";

const BankChequesList = () => {
  const [data, setData] = useState();
  const [load, setLoad] = useState(false);
  const [deletePopup, setDeletePopup] = useState("");
  const [permissionID, setPermissionID] = useState("");
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const { page, sortingOrder } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [statusError, setStatusError] = useState();
  const [errorMessage, setErrorMessage] = useState();

  function handlePageChange(params) {
    // navigate(`/dashboard/accounting-setting/bankcheque/1`);
    // setLoad(true);
  }

  const fetchData = () => {
    setLoad(true);
    dispatch(
      getAllBankChequesAction(
        token,
        (result) => {
          if (result && result.status === 200) {
            setLoad(false);
            setData(result.data.results);
            // setStatusError(result.status);
          } else {
            // setStatusError(result.status);
            // toast.error(result.data.data || `Error in Fetching data, error status: ${result.status} `);
          }
        },
        (error) => {
          toast.error(error);
          setErrorMessage(error);
        },
      ),
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  function handleDeletePopUp(params) {
    setDeletePopup(`Permission for ${params?.bank}`);
    setPermissionID(params.id);
  }

  const handleDelete = (permissionID) => {
    dispatch(
      deleteBankChequesIdAction(token, permissionID, (result) => {
        if (result.status === 204) {
          fetchData();
        }
      }),
    );
    setDeletePopup(false);
  };
  // function comparator([params]) {
  //
  //   if (params) {
  //     if (params.sort === "desc") {
  //       navigate(`/dashboard/accounting-setting/bankcheque/${page}/-${params.key}`);
  //       return;
  //     } else {
  //       navigate(`/dashboard/accounting-setting/bankcheque/${page}/${params.key}`);
  //     }
  //   } else {
  //     navigate(-1);
  //   }
  // }

  const rows =
    data &&
    data?.map((item, index) => {
      return {
        id: item.id,
        bank: item?.bank?.name,
        first_cheque_number: item.first_cheque_number,
        number_of_cheques: item.number_of_cheques,
        bank_account_number: item.bank_account_number,
        bank_currency: item.bank_currency,
        created_at: item.created_date.slice(0, 10),
        updated_at: item.updated_at.slice(11, 19),
      };
    });
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),

      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`/dashboard/accounting-setting/bankcheque/${params.id}/1`} className="ml-2">
            {params.id}
          </Link>
        );
      },
    },

    {
      key: "name",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <GiBank className="w-5 h-5  text-yellow-500" />
          bank
        </div>
      ),
      dataIndex: "name",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`/dashboard/accounting-setting/bankcheque/${params.id}/1`} className="">
            {params?.bank}
          </Link>
        );
      },
    },

    {
      key: "bank_account_number",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <BsCurrencyExchange className="w-5 h-5  text-yellow-500" />
          Bank Account Number
        </div>
      ),

      dataIndex: "bank_account_number",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`/dashboard/accounting-setting/bankcheque/${params.id}/1`} className="">
            {params.bank_account_number}
          </Link>
        );
      },
    },
    {
      key: "bank_currency",
      title: (
        <div className="flex items-center gap-x-1 text-xs ">
          <MdOutlineSpeakerNotes className="w-5 h-5  text-yellow-500" />
          Bank Currency
        </div>
      ),
      dataIndex: "bank_currency",
      editable: false,
      render: (text, params) => {
        return (
          <Link
            to={`/dashboard/accounting-setting/bankcheque/${params.id}/1`}
            className="flex items-center justify-start gap-x-5 ml-5"
          >
            {params.bank_currency}
          </Link>
        );
      },
    },
    {
      key: "first_cheque_number",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <RiAccountPinBoxLine className="w-5 h-5  text-yellow-500" />
          First Cheque Number
        </div>
      ),
      dataIndex: "first_cheque_number",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`/dashboard/accounting-setting/bankcheque/${params.id}/1`} className="">
            {params.first_cheque_number}
          </Link>
        );
      },
    },
    {
      key: "number_of_cheques",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <RiAccountPinBoxLine className="w-5 h-5  text-yellow-500" />
          Number of Cheques{" "}
        </div>
      ),
      dataIndex: "number_of_cheques",
      editable: false,
      render: (text, params) => {
        return (
          <Link to={`/dashboard/accounting-setting/bankcheque/${params.id}/1`} className="">
            {params.number_of_cheques}
          </Link>
        );
      },
    },
    {
      key: "created_at",
      title: (
        <div className="flex items-center gap-x-1 text-xs">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          CREATED AT
        </div>
      ),
      dataIndex: "created_at",
      editable: false,
      render: (text, params) => {
        return <div className="ml-3">{params.created_at}</div>;
      },
    },

    {
      key: "updated_at",
      title: (
        <div className="flex items-center gap-x-1 text-xs ml-5">
          <LiaCalendar className="w-5 h-5  text-yellow-500" />
          UPDATE AT
        </div>
      ),
      dataIndex: "updated_at",
      editable: false,
      render: (text, params) => {
        return <div className="ml-10">{params.updated_at}</div>;
      },
    },
    {
      key: "operations",
      // title: "Operations",
      title: (
        <div className=" flex items-center gap-x-1 text-xs ml-5">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",
      editable: false,

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            <Link
              to={`/dashboard/accounting-setting/bankcheque/${params.id}/1`}
              className="flex items-center gap-x-1  bg-yellow-400 px-1.5 py-1 rounded-md hover:bg-yellow-500 active:scale-95 duration-300 transition-all"
            >
              <AiOutlineEye className="w-5 h-5 text-gray-700 " />
            </Link>

            <Link
              to={`/dashboard/account-setting/bankcheque/create/${params.id}/update`}
              className="flex items-center gap-x-1 bg-green-700 px-1.5 py-1 rounded-md hover:bg-green-800 active:scale-95 duration-300 transition-all"
            >
              <RxUpdate className="w-5 h-5 text-white" />
            </Link>

            <button className="flex items-center gap-x-1 bg-red-800 px-1.5 py-1 rounded-md hover:bg-red-700 active:scale-95 duration-300 transition-all">
              <MdDeleteForever onClick={() => handleDeletePopUp(params)} className="w-5 h-5 text-white" />
            </button>
          </div>
        );
      },
    },
  ];
  const [permissions, permissionsFlag] = usePermissions(["accounting.view_chequebook"]);

  return (
    <div className="w-full formPage-wrap ">
      {data && permissionsFlag ? (
        permissions?.map((item) => {
          if (item.name === "accounting.view_chequebook" && item.value === true) {
            return (
              <Box key={item.id}>
                <div className="w-full overflow-auto">
                  <ListFilters columns={columns} rows={rows} title="BankCheque List" />
                </div>{" "}
                {/* <DataGrid
                  rows={rows}
                  columns={columns}
                  autoHeight 
                  // rowCount={ allTransactionsData.count}
                  // onSortModelChange={comparator}
                  // columnVisibilityModel={columnVisibility}
                  // onColumnVisibilityModelChange={(visibility) => {
                  //   setColumnVisibility(visibility);
                  //   localStorage.setItem("grid-visibility-transaction-list", JSON.stringify(visibility));
                  // }}
                  // paginationModel={{ page: Number(1), pageSize: 10 }}
                  // onPaginationModelChange={handlePageChange}
                  // paginationMode="server"
                  // onSortModelChange={comparator}
                />*/}
                {deletePopup && (
                  <DeletePopUp
                    name={deletePopup}
                    Func={() => handleDelete(permissionID)}
                    hideFunc={() => {
                      setDeletePopup(false);
                    }}
                  />
                )}
              </Box>
            );
          } else {
            return (
              <div className="w-full h-screen ">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
          <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default BankChequesList;
