import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import cookie from "react-cookies";
import { FaWpforms } from "react-icons/fa6";
import Loading from "../Loading/Loading";
import { FiSend } from "react-icons/fi";
import lightToast from "light-toast";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import TopBarProgress from "react-topbar-progress-indicator";
import { getAllBankAction } from "../../../redux/actions/addBankAction";
import {
  getAllWalletAction,
  getAllWalletTransferAction,
  postTransferAmount,
} from "../../../redux/actions/walletAction";
import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import ButtonLoading from "../ButtonLoading/ButtonLoading";

const TransferAmount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currency, setCurrency] = useState("");
  const [tranferToList, setTranferToList] = useState();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const [wallet, setWallet] = useState("");

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { addTransferAmountFlag } = useSelector((state) => state.walletReducer);
  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];
  useEffect(() => {
    dispatch(getCurrencyAction(token));
    dispatch(
      getAllWalletTransferAction(token, (result) => {
        if (result.status === 200) {
          setLoad(true);
          setWallet(result.data);
        }
      })
    );
  }, []);
  let walletNumber = wallet
    ? wallet.results.map((item) => {
        return { value: item.id, label: item.wallet_number };
      })
    : [];

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    dispatch(
      postTransferAmount(token, formData, (result) => {
        if (result.status === 200) {
          lightToast.success("E-Wallet Transfer Form was completed successfully");
          navigate(-1);
        }
      })
    );
  };
  //  const handleFilterFromWallet = (id)=>{
  //     const tranferToFilter = wallet && walletNumber.filter((item)=>item.value !== id)
  //     setTranferToList(tranferToFilter)
  //  }
  const handleFilterToWallet = (id) => {
    const tranferToFilter = wallet && walletNumber.filter((item) => item.value !== id);
    setTranferToList(tranferToFilter);
  };

  return (
    <div className="w-full ">
      {load ? (
        <div className="flex flex-col items-center justify-start h-screen w-full ">
          <div className=" w-[70%]  border-gray-300 border rounded-lg p-5 ">
            <div className=" flex justify-center mx-auto w-full bg-[#002244]  text-white  rounded-lg text-2xl  h-12 mt-3">
              <p className="flex items-center gap-x-3  text-md">
                {" "}
                <FaWpforms className="w-5 h-5" />
                E-Wallet Transfer Form
              </p>
            </div>
            <form className="mt-2  w-full" onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <label className=" flex text-gray-600  ">Transfer From</label>
                <Select
                  name="from_wallet"
                  options={walletNumber}
                  onChange={(e) => {
                    handleFilterToWallet(e.value);
                  }}
                  isSearchable
                  required
                  className=" w-full  h-10  border rounded-lg  bg-white "
                  placeholder="Transfer From"
                />
                {/* <label className=" flex text-gray-600  ">Currency</label>
                          <Select
                                    name="currency"
                                    options={CurrencyType}
                                    onChange={(e) => {
                                      setCurrency(e.label)
                                    }}
                                    
                                    isSearchable
                                    className =" w-full  h-10  border rounded-lg  bg-white "
                                    placeholder="Currency" /> */}
                <label className=" flex text-gray-600 ">Transfer Amount</label>
                <input
                  placeholder="Transfer Amount"
                  name="transfer_amount"
                  type="number"
                  step="any"
                  required
                  className=" w-full px-3 h-10  border rounded-lg bg-white "
                />

                <label className=" flex text-gray-600  ">Transfer To</label>
                <Select
                  name="to_wallet"
                  options={tranferToList}
                  isSearchable
                  required
                  className=" w-full  h-10  border rounded-lg  bg-white "
                  placeholder="Transfer To"
                />
                <button
                  type="Submit"
                  disabled={addTransferAmountFlag}
                  className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  Send{" "}
                  {addTransferAmountFlag ? (
                    <>
                      <TopBarProgress />
                      <ButtonLoading />
                    </>
                  ) : (
                    <FiSend className="w-5 h-5" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
          <TopBarProgress />
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};

export default TransferAmount;
