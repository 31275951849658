import api_token from "../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export default async function mutateDataQuery(url, method = "post", data, headers) {
  const response = await api_token(token)({
    method: method,
    url: url,
    headers: headers,
    data: data,
  });
  return response.data;
}
