import {
  ADD_CHILD_MEAL,
  ADD_MEAL_CHILD_END_AGE,
  Add_MEAL_CHILD_ORDER,
  ADD_MEAL_CHILD_START_AGE,
  ADD_NEW_CHILD_MEAL,
  CLEAR_CHILDREN_MEALS_OLD_VALUES,
  REMOVE_CHILD_MEAL,
  USE_EXISTING_MEALS_VALUES,
  USE_EXISTING_VALUES,
} from "../types/types";

export function addNewChildMeal() {
  return function (dispatch, getState) {
    let oldState = getState().contractChildrenMeals;
    let stateCopy = [...oldState];

    stateCopy.push({
      start_age: "",
      end_age: "",
      ordering_child: "",
      meal_supplements_children_period: [],
    });

    dispatch(
      (() => {
        return {
          type: ADD_NEW_CHILD_MEAL,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function removeChildMeal() {
  return function (dispatch, getState) {
    let oldState = getState().contractChildrenMeals;
    let stateCopy = [...oldState];
    stateCopy.pop();
    dispatch(
      (() => {
        return {
          type: REMOVE_CHILD_MEAL,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addMealChildOrder(index, order) {
  return function (dispatch, getState) {
    let oldState = getState().contractChildrenMeals;
    let stateCopy = [...oldState];

    stateCopy[index].ordering_child = order;

    dispatch(
      (() => {
        return {
          type: Add_MEAL_CHILD_ORDER,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addMealChildStartAgeValue(index, value) {
  return function (dispatch, getState) {
    let oldState = getState().contractChildrenMeals;
    let stateCopy = [...oldState];
    stateCopy[index].start_age = value;
    dispatch(
      (() => {
        return {
          type: ADD_MEAL_CHILD_START_AGE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addMealChildEndAgeValue(index, value) {
  return function (dispatch, getState) {
    let oldState = getState().contractChildrenMeals;
    let stateCopy = [...oldState];
    stateCopy[index].end_age = value;
    dispatch(
      (() => {
        return {
          type: ADD_MEAL_CHILD_END_AGE,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function addChildMeal(index, meal, value) {
  return (dispatch, getState) => {
    const { contractChildrenMeals } = getState();
    const updatedMeals = [...contractChildrenMeals];

    if (!updatedMeals[index].meal_supplements_children_period) {
      updatedMeals[index].meal_supplements_children_period = [];
    }

    const existingMeal = updatedMeals[index].meal_supplements_children_period.find((item) => item.name === meal);

    if (existingMeal) {
      existingMeal.price = value;
    } else {
      updatedMeals[index].meal_supplements_children_period.push({ name: meal, price: value });
    }

    dispatch({
      type: ADD_CHILD_MEAL,
      payload: updatedMeals,
    });
  };
}

export function deleteMealByName(mealName) {
  return function (dispatch, getState) {
    let oldState = getState().contractChildrenMeals;
    let stateCopy = [...oldState];

    stateCopy.forEach((element) => {
      element.meal_supplements_children_period.forEach((item, index) => {
        if (item.name === mealName) {
          element.meal_supplements_children_period.splice(index, 1);
        }
      });
    });

    dispatch(
      (() => {
        return {
          type: REMOVE_CHILD_MEAL,
          payload: stateCopy,
        };
      })(),
    );
  };
}

export function fillChildrenMealsFromExistingValues(meal_supplements_children) {
  return function (dispatch) {
    let newState;

    if (meal_supplements_children) {
      newState = meal_supplements_children.map((item) => {
        return {
          start_age: item.start_age,
          end_age: item.end_age,
          ordering_child: item.ordering_child,
          meal_supplements_children_period: item.meal_supplements_children_period,
        };
      });
    } else {
      newState = [];
    }

    dispatch(
      (() => {
        return {
          type: USE_EXISTING_MEALS_VALUES,
          payload: newState,
        };
      })(),
    );
  };
}

export function clearChildrenMealOldValues() {
  return function (dispatch, getState) {
    dispatch(
      (() => {
        return {
          type: CLEAR_CHILDREN_MEALS_OLD_VALUES,
          payload: [],
        };
      })(),
    );
  };
}
