import React from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { VscAdd, VscEdit } from "react-icons/vsc";
import { AiOutlineUnorderedList } from "react-icons/ai";
import usePermissions from "../../../../customHooks/usePermissions";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import NoPermission from "../../NoPermission/NoPermission";

function ClientFamily() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "auth_users.add_customerfriends",
    "auth_users.view_customerfriends",
    "auth_users.change_customerfriends",
  ]);

  const isAddingFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.add_customerfriends")?.value === true;

  const isViewFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.view_customerfriends")?.value === true;

  const isEditingFamilyAllowed =
    permissions?.find((permission) => permission.name === "auth_users.change_customerfriends")?.value === true;

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  return isAddingFamilyAllowed || isViewFamilyAllowed || isEditingFamilyAllowed ? (
    <div className="border-gray-300 border rounded-lg bg-white p-4 w-[95%] mx-auto">
      <div className="w-full flex  justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 mt-3">
        <p className="flex items-center gap-x-3">
          <RiKeyboardLine className="w-5 h-5" />
          Client Family & Friends
        </p>
      </div>
      <div className="flex gap-4 flex-wrap justify-around items-center mt-12">
        {isAddingFamilyAllowed && (
          <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"./add-family"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <VscAdd className="w-12 h-12  " />
              </div>
            </Link>
            <p className="text-lg font-semibold">Add Family and Friends</p>
          </div>
        )}

        {isEditingFamilyAllowed && (
          <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"./edit-family"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-yellow-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <VscEdit className="w-12 h-12  " />
              </div>
            </Link>
            <p className="text-lg font-semibold">Edit Family and Friends</p>
          </div>
        )}

        {isViewFamilyAllowed && (
          <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"./family-list"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-green-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <AiOutlineUnorderedList className="w-12 h-12  " />
              </div>
            </Link>
            <p className="text-lg font-semibold">View Family and Friends</p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}

export default ClientFamily;
