import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { ArrowLeftIcon  } from '@heroicons/react/24/outline';
import { statusCardAction } from '../../../redux/actions/bookingOrderAction';
import { useTranslation } from 'react-i18next';

const CardEmpty = () => {
  const dispatch = useDispatch()
  const [cardStatus , setCardStatus] = useState(true);
  const [t , i18n] = useTranslation();
  
  return (
   <>
      <div className='flex items-center justify-center flex-col h-screen px-11 text-center gap-7'>
        {/* <img
          src={emptybag}
          alt="emptybag/img"
          className='w-40 lg:w-36 sm:w-28 h-auto object-fill transition-all duration-300 hover:scale-110'
        /> */}
        <button  onClick={()=>dispatch(statusCardAction(!cardStatus))} type='button'   className='button-theme bg-gradient-to-b bg-[#002244]  shadow-lg
                                          flex items-center justify-center text-white md:py-2
                                          gap-3 text-sm md:px-5 font-semibold active:scale-110' >
            <ArrowLeftIcon className='w-5 h-5 text-white' />
            <span className='text-xs'>{t("backHotels")}</span>
        </button>
      </div>
   </>
  )
}

export default CardEmpty