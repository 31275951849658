import React, { useEffect, useState } from "react";
import "./ContractFormDynamicComponentParent.scss";
import ContractFormDynamicComponentChild from "../ContractFormDynamicComponentChild/ContractFormDynamicComponentChild";
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import { addNewRoomValidity, addRoomValue, deleteRoomValidity } from "../../../redux/actions/contractValidity";
import { useDispatch } from "react-redux";
import { FaBed } from "react-icons/fa";
import toast from "react-hot-toast";
import { MdAddCircleOutline } from "react-icons/md";
import { BiMinusCircle } from "react-icons/bi";

export default function ContractFormDynamicComponent({
  hotelRoomsArr,
  contractValidity,
  validities,
  contractType,
  roomFlag,
  roomsHotel,
  isAddingPackageAllowed,
}) {
  const dispatch = useDispatch();
  let [input, setInput] = useState([1]);

  useEffect(() => {
    if (validities) {
      let numOfRooms = Array.from({ length: validities.length }, () => 1);
      setInput(numOfRooms);
    }
  }, [validities]);

  function addInput() {
    input.length < 500 ? setInput([...input, 1]) : void 0;
    dispatch(addNewRoomValidity());
  }

  function removeInput() {
    if (input.length > 1) {
      let newArr = input.slice();
      newArr.pop();
      setInput(newArr);
      dispatch(deleteRoomValidity());
    }
  }

  function getRoomName(roomName, index) {
    dispatch(addRoomValue(index, roomName));
  }

  return (
    <div key={contractType} className="w-full flex flex-col  py-2 gap-y-5 ">
      {input.map((elem, index) => {
        const roomID = contractValidity[index] && contractValidity[index].room;
        const maxAdults = hotelRoomsArr.find((e) => Number(e.id) === Number(roomID))?.maxAdults;
        const maxChildren = hotelRoomsArr.find((e) => Number(e.id) === Number(roomID))?.maxChildren;
        const maxOccupancy = hotelRoomsArr.find((e) => Number(e.id) === Number(roomID))?.maxOccupancy;

        return (
          <div className="w-full border  rounded-md p-3" key={index}>
            <h1 className="text-gray-800 font-semibold">Select Room</h1>
            {validities ? (
              <StaticFormSelect
                name="room"
                text=""
                options={hotelRoomsArr}
                placeholder="Choose a room"
                contractCallBack={(e, roomName) => {
                  let rooms = contractValidity.map((elem) => elem.room);
                  if (rooms.includes(roomName) || rooms.includes(Number(roomName))) {
                    toast.error("This Room was already chosen");
                    e.target.value = null;
                    getRoomName("", index);
                  } else {
                    getRoomName(roomName, index);
                  }

                  // dispatch(deleteRoomDateRange(index, Number(roomName)));
                }}
                value={contractValidity[index]?.room}
                defaultValue={
                  validities && hotelRoomsArr && validities[index]
                    ? Number(validities[index].id || validities[index].room)
                    : null
                }
              />
            ) : (
              <StaticFormSelect
                name="room"
                text=""
                options={hotelRoomsArr}
                placeholder="Choose a room"
                contractCallBack={(e, roomName) => {
                  let rooms = contractValidity.map((elem) => elem.room);
                  if (rooms.includes(roomName) || rooms.includes(Number(roomName))) {
                    toast.error("This Room was already chosen");
                    e.target.value = null;
                    getRoomName("", index);
                  } else {
                    getRoomName(roomName, index);
                  }
                  // dispatch(deleteRoomDateRange(index, Number(roomName)));
                }}
              />
            )}
            {contractValidity[index] && contractValidity[index].room ? (
              <div className="w-full mt-5">
                <ContractFormDynamicComponentChild
                  key={contractValidity[index].room + contractType}
                  maxAdults={maxAdults}
                  maxChildren={maxChildren}
                  maxOccupancy={maxOccupancy}
                  contractType={contractType}
                  roomID={roomID}
                  validity_index={index}
                  range={validities && validities[index] ? validities[index].date_range : null}
                  isAddingPackageAllowed={isAddingPackageAllowed}
                />
              </div>
            ) : (
              <p className={"text-center font-bold mt-4 text-xl"}> Please select room first </p>
            )}
          </div>
        );
      })}
      <div className="w-full flex text-center justify-end gap-x-2 px-10">
        <MdAddCircleOutline
          className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-green-800"
          onClick={addInput}
        />

        <FaBed className="text-gray-800 w-5 h-5" />
        <BiMinusCircle
          className="w-5 h-5 cursor-pointer duration-300 transition-all hover:scale-105 active:scale-95 text-red-800"
          onClick={removeInput}
        />
      </div>
    </div>
  );
}
