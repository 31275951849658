import { ADD_BANK, ADD_BANK_FLAG } from "../types/types";
const initialState = {
  bankDashBoardData: null,
  addBankFlag: false,
};
export const addBankReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BANK:
      return { ...state, bankDashBoardData: action.payload };
    case ADD_BANK_FLAG:
      return { ...state, addBankFlag: action.payload };
    default:
      return state;
  }
};
