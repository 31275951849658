import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export const addNewMeetAction = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post("/airport-services/api/v1/meet-assist-members/", data);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const getAllAssistAction = (signal, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get("/airport-services/api/v1/meet-assist-members/", { signal });
      callback(response);
    } catch (error) {}
  };
};

export const getSelectedMeet = (id, signal, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/airport-services/api/v1/meet-assist-members/${id}/`, { signal });
      callback(response);
    } catch (error) {}
  };
};

export const updateSelectedMeet = (data, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`/airport-services/api/v1/meet-assist-members/${id}/`, data);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const deleteSelectedMeet = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`/airport-services/api/v1/meet-assist-members/${id}/`);
      callback(response);
    } catch (error) {}
  };
};
