import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction, getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { TimePicker } from "antd";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import TopBarProgress from "react-topbar-progress-indicator";
import { addNewWalletBankTransferAction } from "../../../redux/actions/walletAction";
import moment from "moment";

const BankTransferWallet = ({ amountWallet, commission, currencyWallet, subTotal, agent, methodOfPayment }) => {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [convertAmount, setConvertAmount] = useState();
  const [convertRate, setConvertRate] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [date, setDate] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberBank, setAccountNumberBank] = useState("");
  const [bank, setBank] = useState("");
  const [bankId, setBankId] = useState("");
  const [file, setFile] = useState(null);
  const [time, setTime] = useState("");
  const format = "HH:mm";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const { addWalletFlag } = useSelector((state) => state.walletReducer);

  useEffect(() => {
    dispatch(getSelectedBookedRoomSingleAction(token, params.id));
    dispatch(getCurrencyAction(token));
    dispatch(getBankListAction(token));
  }, []);
  useEffect(() => {
    if (currency) {
      setLoadingToggle(true);
      dispatch(
        convertingCurrencyAction(token, currency, currencyWallet, (result) => {
          if (result.status === 200) {
            result.data.results.length !== 0
              ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
              : setConvertAmount(amount && amount * 1);
            result.data.results.length !== 0
              ? setConvertRate(...result.data.results.map((item) => item.rate))
              : setConvertRate(1);
            setLoadingToggle(false);
          }
        }),
      );
    }
  }, [currencyWallet]);

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];
  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name, currency: item.currency, bankAccountNumber: item.account_number };
      })
    : [];
  const handleFileChange = (e) => {
    setFile(e.target.value);
  };
  const handleAmount = (e) => {
    setAmount(e.target.value);

    if (convertRate) {
      setConvertAmount(e.target.value * convertRate);
    }
  };

  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setTime(`${hours1}:${minutes1}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (time === "" || !amountWallet || !commission || !currencyWallet || !subTotal || !agent) {
      alert("You Have Empty Field"); // You can replace this with your validation logic
    } else {
      const formData = new FormData(e.target);
      const transaction_date = `${date},${time}`;
      const inputDate = moment(transaction_date, "YYYY-MM-DD,HH:mm");
      const outputFormat = "YYYY-MM-DD[T]HH:mm:ss[Z]";
      const outputDateString = inputDate.format(outputFormat);
      formData.append("amount", amountWallet);
      formData.append("agent_name", agent);
      formData.append("commission", Number(commission).toFixed(2));
      formData.append("currency", currencyWallet);
      formData.append("method_of_payment", "bank_transfer");
      formData.append("subtotal", Number(subTotal).toFixed(2));
      formData.append("amount_used", 500.0);
      formData.append("rest_of_payment", 500.0);
      formData.append("service_description", "Bank Transfer");
      formData.append("system_confirmation", "12323");

      //  var bankTransferData = {
      //     confirmed_by: 'admin',
      //     bank_name: 'admin',
      //     booked_room: bankId,
      //     transaction_date: transaction_date,
      //     transaction_status: 'pending',
      //     currency_type: currency,
      //     transferor_name: name,
      //     transfer_amount: Number(convertAmount).toFixed(2),
      //     booked_room:910,
      //     bank_account_number: accountNumberBank,
      //     transfer_acc_number: accountNumber,
      //     contract_currency: currencyWallet,
      //     conversion_rate: convertRate,

      // };
      // Object.keys(bankTransferData).forEach(key => {
      //   formData.append(`bank_transfer[${key}]`, bankTransferData[key]);
      // });
      formData.append(`bank_transfer[${"confirmed_by"}]`, "admin");
      formData.append(`bank_transfer[${"bank_name"}]`, bankId);
      formData.append(`bank_transfer[${"transaction_date"}]`, outputDateString);
      formData.append(`bank_transfer[${"transaction_status"}]`, "pending");
      formData.append(`bank_transfer[${"currency_type"}]`, currency);
      formData.append(`bank_transfer[${"transferor_name"}]`, name);
      formData.append(`bank_transfer[${"transfer_amount"}]`, Number(convertAmount).toFixed(2));
      formData.append(`bank_transfer[${"bank_account_number"}]`, accountNumberBank);
      formData.append(`bank_transfer[${"transfer_acc_number"}]`, accountNumber);
      formData.append(`bank_transfer[${"contract_currency"}]`, currencyWallet);
      formData.append(`bank_transfer[${"conversion_rate"}]`, convertRate);

      dispatch(
        addNewWalletBankTransferAction(token, formData, (result) => {
          if (result.status === 201) {
            lightToast.success("The Wallet Of Bank Transfer was completed successfully");
            navigate(-1);
          }
          // else
          // {
          //     toast.success(`The bank transfer was completed successfully`)
          // }
        }),
      );
    }
  };

  return (
    <div className="w-full ">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {selectedSingleRoom ? (
          <div className={`w-full  `}>
            <form className="mt-2  w-full" onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <label className=" flex text-gray-600   ">Bank Name</label>
                <Select
                  placeholder="Bank Name"
                  type="text"
                  required
                  options={Bank}
                  onChange={(e) => {
                    setBank(e.label);
                    setBankId(e.value);
                    setCurrency(e.currency);
                    setAccountNumberBank(e.bankAccountNumber);
                    setLoadingToggle(true);
                    setToggle(false);
                    dispatch(
                      convertingCurrencyAction(token, e.currency, currencyWallet, (result) => {
                        if (result.status === 200) {
                          result.data.results.length !== 0
                            ? setConvertAmount(amount && amount * result.data.results.map((item) => item.rate))
                            : setConvertAmount(amount && amount * 1);
                          result.data.results.length !== 0
                            ? setConvertRate(...result.data.results.map((item) => item.rate))
                            : setConvertRate(1);
                          setToggle(true);
                          setLoadingToggle(false);
                        }
                      }),
                    );
                  }}
                  isSearchable
                  className=" w-full  h-10  rounded-lg  bg-white "
                />

                {currency && toggle ? (
                  <>
                    <label className=" flex text-gray-600 ">Currency</label>
                    <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                      <p className="text-black mt-2">{currency}</p>
                    </div>
                    <label className=" flex text-gray-600 ">Account Number</label>
                    <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                      <p className="text-black mt-2">{accountNumberBank}</p>
                    </div>
                  </>
                ) : (
                  loadingToggle && (
                    <>
                      <ButtonLoading />
                    </>
                  )
                )}

                <label className=" flex text-gray-600 ">The Amount</label>
                <input
                  placeholder="The Amount"
                  name="transfer_amount"
                  type="number"
                  step="any"
                  required
                  onChange={handleAmount}
                  className=" w-full px-3 h-10  border rounded-lg bg-white "
                />

                {amount ? (
                  <>
                    <label className=" flex text-gray-600 ">Amount With Currency Of Contract ({currencyWallet})</label>
                    <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                      <div className="text-black flex items-end gap-x-1">
                        {convertAmount} <span className="text-sm font-semibold text-gray-700">{currencyWallet}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  loadingToggle && (
                    <>
                      <ButtonLoading />
                    </>
                  )
                )}

                <div className="flex items-center gap-x-5 w-full">
                  <div className="w-[50%]">
                    <label className="flex justify-center text-gray-600 "> Date</label>
                    <input
                      placeholder="Tranfer Date"
                      type="date"
                      required
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      className=" w-full px-3 h-10  border rounded-lg floating-input bg-white "
                    />
                  </div>
                  <div className="w-[50%]">
                    <label className=" flex justify-center text-gray-600 ">Time</label>
                    <TimePicker
                      clearIcon={false}
                      required
                      format={format}
                      className="w-full"
                      onChange={handleTimeChange}
                    />
                  </div>
                </div>

                <label className=" text-center w-full text-gray-600   ">Transferor Name</label>
                <input
                  placeholder="Transferor Name"
                  type="text"
                  required
                  onChange={(e) => setName(e.target.value)}
                  className=" w-full px-3 h-10  border rounded-lg bg-white "
                />

                <label className=" flex text-gray-600   ">Sender Bank Account Number</label>
                <input
                  placeholder="Sender Bank Account Number"
                  type="number"
                  required
                  onChange={(e) => setAccountNumber(e.target.value)}
                  className=" w-full px-3 h-10  border rounded-lg bg-white "
                />

                <label className=" flex text-gray-600   ">Choose A File</label>
                <input
                  placeholder="Bank Name"
                  name="bank_transfer[transfer_receipt]"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  className=" w-full px-2 py-1 h-10   border rounded-lg bg-white "
                />

                <button
                  type="Submit"
                  disabled={addWalletFlag}
                  className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  Add{" "}
                  {addWalletFlag ? (
                    <>
                      <TopBarProgress />
                      <ButtonLoading />
                    </>
                  ) : (
                    <IoAdd className="w-5 h-5" />
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <>
            <TopBarProgress /> <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default BankTransferWallet;
