import React from 'react'
import { GET_JUMBO } from '../types/types';

const initialState = {
    jumboData:null,
}
export const transactionReducer = (state = initialState , action) => {
    switch(action.type)
    {
     case GET_JUMBO : 
        return{...state , jumboData : action.payload}
     default:
        return state;
    }

}



