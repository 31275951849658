import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import { BsClipboardData } from "react-icons/bs";
import { getSingleHotelInfoByHotelId } from "../../../redux/actions/HotelInfo";
import Loading from "../../organisms/Loading/Loading";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
import { LiaFileContractSolid } from "react-icons/lia";

export default function HotelMainInfoDetails() {
  const dispatch = useDispatch();
  const [chosenHotel, setChosenHotel] = useState(null);
  const hotels = useSelector((state) => state.hotelNames);
  const hotelsArr = hotels.map((hotel) => ({ label: hotel.name, value: hotel.id }));
  const hotelInfo = useSelector((state) => state.getSibgleHotelInfoById);

  useEffect(() => {
    dispatch(requestHotelNames());
  }, []);

  const getInfo = (e) => {
    dispatch(getSingleHotelInfoByHotelId(e.value));
  };

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["hotels_management.view_mainhotelinfo"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////

  return (
    <div className="formPage-wrap min-h-screen">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "hotels_management.view_mainhotelinfo" && item.value === true) {
            return (
              <div className="w-full ">
                <div className=" flex items-center justify-center  ">
                  <h2 className="header-h2 ">
                    <LiaFileContractSolid className="w-6 h-6" />
                    Hotel Main Information{" "}
                  </h2>
                </div>
                <div className="form-wrap">
                  <div className="flex    rounded-md max-sm:flex-col  items-center  w-full  ">
                    <label htmlFor="hotel" className="md:flex-[1] w-full flex  text-slate-950  text-xl">
                      Please Choose Hotel First
                    </label>
                    <Select
                      className="w-full text-gray-900 md:flex-[1]  capitalize"
                      placeholder="Select Hotel"
                      options={hotelsArr}
                      isSearchable
                      onChange={(e) => {
                        getInfo(e);
                        setChosenHotel(e.value);
                      }}
                    />
                  </div>

                  {hotelInfo.loading ? (
                    <div className="w-full flex items-center justify-center h-screen">
                      {" "}
                      <Loading />
                      <TopBarProgress />
                    </div>
                  ) : hotelInfo.data ? (
                    hotelInfo.data.hotel &&
                    hotelInfo.data.hotel.id === chosenHotel && (
                      <div className="overflow-auto">
                        <table className="w-full mx-auto my-5 border border-[#001a35] table-auto overflow-auto">
                          <thead className="bg-[#001a35]">
                            <th className="text-white font-bold text-center py-3" colSpan={4}>
                              {hotelInfo.data.hotel.name}
                            </th>
                          </thead>
                          <tbody>
                            <tr className="w-full p-2 ">
                              <td className="bg-gray-300 border-r text-black font-bold px-2 p-3 ">
                                Hotel Telephone Numbers
                              </td>
                              <td className="bg-gray-300 border-r  px-3 ">
                                {Object.values(hotelInfo.data.telephone_numbers).map((elem) => (
                                  <li>{elem}</li>
                                ))}
                              </td>
                            </tr>
                            <tr className="w-full p-2 ">
                              <td className="bg-gray-100 border-r text-black font-bold px-2 p-3 ">
                                Reservations Emails
                              </td>
                              <td className="bg-gray-100  border-r px-3 ">
                                {Object.values(hotelInfo.data.reservation_emails).map((elem) => (
                                  <li>{elem}</li>
                                ))}
                              </td>
                            </tr>
                            <tr className="w-full p-2">
                              <td className="bg-gray-300 border-r text-black font-bold px-2 p-3 ">Reception Emails</td>
                              <td className="bg-gray-300  border-r px-3 ">
                                {Object.values(hotelInfo.data.reception_emails).map((elem) => (
                                  <li>{elem}</li>
                                ))}
                              </td>
                            </tr>
                            <tr className="w-full p-2">
                              <td className="bg-gray-100 border-r text-black font-bold px-2 p-3 ">
                                Same Day Reservation Notes
                              </td>
                              <td className="bg-gray-100 border-r  px-3">
                                {hotelInfo.data.same_day_reservations_notes}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="w-full mx-auto my-5 rounded-t-md border border-[#001a35] table-auto">
                          <thead className="bg-[#001a35] rounded-t-md">
                            <th className="text-white font-bold text-center py-3" colSpan={2}>
                              Reservation Departments Working hours
                            </th>
                          </thead>
                          <tbody>
                            {Object.keys(hotelInfo.data.reservations_department_working_hours).map((day, index) => {
                              return (
                                <tr className="w-full p-2">
                                  <td
                                    className={`${
                                      index % 2 === 0 ? "bg-gray-300" : "bg-gray-100"
                                    } text-black font-bold px-2 p-3  border-r`}
                                  >
                                    {day}
                                  </td>
                                  <td className={`${index % 2 === 0 ? "bg-gray-300" : "bg-gray-100"} border-r px-3 `}>
                                    <span>From: {hotelInfo.data.reservations_department_working_hours[day].from}</span>
                                    <span> to: {hotelInfo.data.reservations_department_working_hours[day].to}</span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )
                  ) : (
                    <div className="w-full flex flex-col items-center justify-start gap-y-2 h-screen mt-48 opacity-10 ">
                      <BsClipboardData className="w-48 h-48" />
                      <p className="text-xl text-gray-800 font-semibold">No available data for this hotel</p>
                    </div>
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <div className="w-full h-screen ">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
