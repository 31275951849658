import React, { useEffect, useMemo, useState } from "react";
import "./HotelListTable.scss";
import { useDispatch, useSelector } from "react-redux";
import requestHotelList from "../../../redux/actions/requestHotelList";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteHotel } from "../../../redux/actions/deleteHotel";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import cookie from "react-cookies";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
import { TbBuildingEstate, TbWorld } from "react-icons/tb";
import { FaStreetView } from "react-icons/fa6";
import { BiTransfer } from "react-icons/bi";
import { AiFillDelete, AiOutlineEye, AiOutlinePhone } from "react-icons/ai";
import { FaCity } from "react-icons/fa";
import { MdOutlineSettings, MdOutlineStarRate, MdStarRate } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import ListFilters from "../../../utils/ListFilters";
import CityAreaFilter from "../../../customComponents/CityAreaFilter/CityAreaFilter";
import { useReloadPage } from "../../../customHooks/useReloadPage";

export default function HotelListTable() {
  const location = useLocation();
  const [isloading, setIsLoading] = useState(true);
  const reload = useReloadPage();
  const { page, sortingOrder } = useParams();
  const [deletePopup, setDeletePopup] = useState("");
  const [deleteHotelID, setDeleteHotelID] = useState(null);
  const [listPageSize, setListPageSize] = useState(20);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(false); // Add a loading state
  const [hotelData, setHotelData] = useState([]);
  const [cityArea, setCityArea] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.hotelList);
  const currentPage = Number(page);
  const totalPages = Math.ceil(data?.count / listPageSize);

  // Handle changes in city and area
  const handleChange = (city, area, allArea) => {
    setLoading(true); // Start loading when city/area changes
    setSelectedCity(city); // Update city
    setSelectedArea(area); // Update area
    setCityArea(allArea); // Update area
    const cityAreaData = {
      city,
      area,
      allArea,
    };
    localStorage.setItem("CITYAREA", JSON.stringify(cityAreaData)); // Update localStorage

    // Clear hotel data and stop loading if city or area changes
    setHotelData([]); // Clear the current hotel data
    navigate(`/dashboard/hotel-list/1/${sortingOrder}`);

    setLoading(false); // Stop loading after the state update
  };
  useEffect(() => {
    const storedCityArea = localStorage.getItem("CITYAREA");
    // Check if storedCityArea exists and is valid JSON
    if (storedCityArea) {
      const parsedData = JSON.parse(storedCityArea);
      if (parsedData?.city) setSelectedCity(parsedData.city);
      if (parsedData.area ? parsedData.area : parsedData.Allarea)
        setSelectedArea(parsedData.area ? parsedData.area : parsedData.Allarea);
    }
  }, []); // Only run on component mount
  useEffect(() => {
    if (selectedCity && selectedArea) {
      setLoading(true); // Set loading when fetching hotels
      dispatch(
        requestHotelList(
          Number(currentPage),
          sortingOrder,
          (res) => {
            if (res && res.data.results.length > 0) {
              setHotelData(res.data);
            } else {
              setHotelData([]); // No results found
            }
            setLoading(false); // Stop loading
          },
          listPageSize,
          selectedCity?.value,
          selectedArea?.value || selectedArea,
        ),
      );
    } else {
      setLoading(false); // Stop loading if no city is selected
    }
  }, [sortingOrder, currentPage, dispatch, listPageSize, selectedCity?.value, selectedArea?.value, selectedArea]);
  useEffect(() => {
    dispatch(
      requestHotelList(
        Number(currentPage),
        sortingOrder,
        (res) => {
          if (res) setHotelData(res.data);
          setLoading(false);
        },
        listPageSize,
        selectedCity?.value,
        selectedArea?.value || selectedArea,
      ),
    );
  }, [sortingOrder, currentPage, dispatch, listPageSize, selectedCity?.value, selectedArea?.value, selectedArea]);
  const filteredRows = useMemo(() => {
    return hotelData
      ? hotelData?.results?.map((elem) => ({
          id: elem.id,
          name: elem.name,
          telephone: Object.values(elem.telephone)[0],
          country: elem._country_name,
          city: elem.city,
          area: elem.area,
          rate: elem.rate,
          slug: elem.slug,
        }))
      : [];
  }, [hotelData, selectedArea?.value, selectedCity?.value]);

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full justify-center  items-center gap-x-1 text-xs">
          <BiTransfer className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
      render: (text, params) => {
        return <button className="w-full h-full  flex items-center gap-x-1 ml-5 ">{params.id}</button>;
      },
      sortComparator: () => {},
    },
    {
      key: "name",
      title: (
        <div className=" flex  items-center gap-x-1 text-xs">
          <TbBuildingEstate className="w-5 h-5  text-yellow-500 mb-1" />
          HOTEL NAME
        </div>
      ),

      dataIndex: "name",
      sortComparator: () => {},
    },
    {
      key: "telephone",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <AiOutlinePhone className="w-5 h-5  text-yellow-500" />
          TELEPHONE
        </div>
      ),

      dataIndex: "telephone",
    },
    {
      key: "country",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <TbWorld className="w-5 h-5  text-yellow-500" />
          COUNTRY
        </div>
      ),

      dataIndex: "country",
    },
    {
      key: "city",
      dataIndex: "city",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <FaCity className="w-5 h-5  text-yellow-500" />
          CITY
        </div>
      ),
    },
    {
      key: "area",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <FaStreetView className="w-5 h-5  text-yellow-500" />
          AREA
        </div>
      ),
      dataIndex: "area",
    },
    // { key: "slug", title: "slug" },
    {
      key: "rate",
      title: (
        <div className=" flex items-center gap-x-1 text-xs">
          <MdOutlineStarRate className="w-5 h-5  text-yellow-500" />
          RATE
        </div>
      ),
      dataIndex: "rate",
      render: (text, params) => {
        return (
          <button className="w-full h-full  flex items-center  ">
            {/* <AiOutlineMail className='w-5 h-5 text-amber-800' /> */}
            {Array.from({ length: Number(Number(params.rate)) }, (_, indedx) => (
              <MdStarRate className="w-6 h-6 text-yellow-500 " />
            ))}
            {/* {params.rate} */}
          </button>
        );
      },
    },
    {
      key: "operations",

      title: (
        <div className=" flex items-center w-full justify-center gap-x-1 text-xs ">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            {permission?.map((item) => {
              if (item.name === "hotels_management.view_hotels" && item.value === true) {
                return (
                  <Link to={`/dashboard/hotel-list/hotel-details/${params.id}`}>
                    <AiOutlineEye
                      // onClick={() => handleView(params)}
                      className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800"
                    />
                  </Link>
                );
              } else if (item.name === "hotels_management.change_hotels" && item.value === true) {
                return (
                  <Link to={`/dashboard/edit-hotel/${params.id}`}>
                    <FiEdit
                      // onClick={() => navigate(`/dashboard/edit-hotel/${params.id}`)}
                      className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
                    />
                  </Link>
                );
              } else if (item.name === "hotels_management.delete_hotels" && item.value === true) {
                return (
                  <AiFillDelete
                    onClick={() => handleDeletePopUp(params)}
                    className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
                  />
                );
              }
            })}
          </div>
        );
      },
    },
  ];

  function handleView(params) {
    navigate(`/dashboard/hotel-list/hotel-details/${params.id}`);
  }

  // this will show the popup message to confirm delete and will store the slug to be deleted in the hotel slug state
  function handleDeletePopUp(params) {
    setDeletePopup(params.name);
    setDeleteHotelID(params.id);
  }

  // this will delete the hotel stored in the state by passing it to the action then remove the popup
  function handleDelete(page) {
    setIsLoading(true);
    dispatch(
      deleteHotel(deleteHotelID, page, sortingOrder, (result) => {
        if (result.status === 200) {
          setIsLoading(false);
          reload();
        }
      }),
    );
    setDeletePopup("");
  }

  function handlePageChange(params, size) {
    setListPageSize(size);
    navigate(`/dashboard/hotel-list/${params}/${sortingOrder}`);
    setIsLoading(true);
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "hotels_management.view_hotels",
      "hotels_management.change_hotels",
      "hotels_management.delete_hotels",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  const xx = permission?.map((item) => {
    if (item.name === "hotels_management.view_hotels" && item.value === true) {
      return 1;
    }
  });
  return (
    <div className="formPage-wrap min-h-screen ">
      {permissionFlag ? (
        permissionFlag ? (
          <div className="overflow-x-auto min-h-screen">
            <CityAreaFilter
              localStorageName="CITYAREA"
              render={({ city, area, allArea }) => handleChange(city, area, allArea)}
            />

            {loading ? (
              <div className="w-full flex items-center justify-center h-screen">
                <Loading />
                <TopBarProgress />
              </div>
            ) : (
              <div className="!w-full">
                {/* Check if both selectedCity and selectedArea are not null */}
                {selectedCity && selectedArea && hotelData?.results?.length > 0 ? (
                  <ListFilters
                    loading={loading}
                    columns={columns}
                    rows={filteredRows}
                    title="Hotels List"
                    handlePagination={handlePageChange}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    listPageSize={listPageSize}
                  />
                ) : (
                  // Show "No Hotels Found" if city and area are not selected
                  <div className="flex justify-center items-center h-full w-full ">
                    <p className="text-gray-400 text-xl font-semibold text-center w-full mt-10">No Hotels Found</p>
                  </div>
                )}
              </div>
            )}
            {deletePopup && (
              <DeletePopUp
                name={deletePopup}
                Func={() => handleDelete(Number(page))}
                hideFunc={() => setDeletePopup("")}
              />
            )}
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
