import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { FormLabel } from "../../../customComponents/FormComponents";
import CityAreaHotel from "../../../customComponents/CityAreaHotelCustom/CityAreaHotel";
import { FaPlusCircle } from "react-icons/fa";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useMutation, useQuery } from "@tanstack/react-query";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import PresonChild from "../PriceTestTool/PresonChild";
import { DateRangePicker } from "react-dates";
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { Button } from "flowbite-react";
import DeleteButton from "../../../customComponents/DeleteButton";
import Loading from "../Loading/Loading";
import { SiGoogletranslate } from "react-icons/si";
import { useNavigate } from "react-router";

function AddManualBooking() {
  const [HotelId, setHotelId] = useState();
  const [paymentType, setPaymentType] = useState();
  const [formSuccess, setFormSuccess] = useState();
  const user = jwtDecode(cookie.load("access_token"));
  const navigate = useNavigate();

  const {
    data: userinfo,
    error: errorLoading,
    isLoading: Userloading,
  } = useQuery({
    queryKey: ["user", user?.user_id],
    queryFn: () => fetchDataQuery(`/auth/api/v1/profile/${user?.user_id}/`),
  });

  const { mutate, isError, isPendingbookroom } = useMutation({
    mutationFn: (newValues) => mutateDataQuery(`/booking/api/v1/add-book-room/`, "post", newValues),
  });

  const initialValue = {
    hotel: "",
    room_id: "",
    client_names: [{ arabic_name: "", english_name: "" }],
    meal_plan: "",
    nationality: "",
    phone_number: "",
    whatsapp_number: "",
    number_of_persons: 1,
    payment_type: "",
    persons_data: "",
    adults: 1,
    from_date: "",
    to_date: "",
    nights: "",
    email: "",
    total_price: "",
    currency_type: "",
    arrival_time_from: "",
    arrival_time_to: "",
    voucher: "",
    pay_donotpay: "",
    net_price_data: [],
    inventory_status: "True",
    // booked_by: userinfo?.username,
    // user_type: userinfo?.user_type,
    agent_id: userinfo?.user_type === "b2b" ? userinfo?.profile?.credit_info?.agent_id : null,
    source_channel: "Manual Booking",
    client_requests_tags: [],
    client_requests_text: "",
    // payment_status: paymentType,
  };
  /// validationpayment type
  const validation = Yup.object({
    hotel: Yup.string().required("Hotel Required"),
    room_id: Yup.string().required("Room Required"),
    meal_plan: Yup.string().required("Meal Plan Required"),
    phone_number: Yup.number().required("phone number  Required"),
    whatsapp_number: Yup.number().required("whatsapp number  Required"),
    number_of_persons: Yup.number().required("number_of persons  Required"),
    voucher: Yup.boolean().required("voucher type  Required"),
    pay_donotpay: Yup.boolean().required("pay type  Required"),
    nationality: Yup.string().required("nationality  Required"),
    from_date: Yup.string().required("from date is required"),
    to_date: Yup.string().required("To date is required"),
    nights: Yup.number().required("Nights are required"),
    currency_type: Yup.string().required("currency is required"),
    arrival_time_from: Yup.string().required("arrival time from is required"),
    arrival_time_to: Yup.string().required("arrival time to is required"),
    total_price: Yup.string().required("total price is required"),
    email: Yup.string().required("email Required"),

    // client_requests_tags: Yup.array()
    //   .of(Yup.string())
    //   .min(1, "Please select at least one tag")
    //   .required("Please select at least one tag"),

    // client_requests_text: Yup.string().required("client requests text is required"),

    /////////////////////////////Array///////////////////////////////////
    client_names: Yup.array()
      .of(
        Yup.object().shape({
          arabic_name: Yup.string().required("Arabic name is required"),
          english_name: Yup.string().required("English name is required"),
        }),
      )
      .required("At least one client name is required")
      .min(1, "At least one client name is required"),

    net_price_data: Yup.array()
      .of(
        Yup.object().shape({
          date: Yup.string().required("date is required"),
          net_price: Yup.string().required("net price is required"),
        }),
      )
      .required("At least one net price data is required")
      .min(1, "At least one net price data is required"),
  });

  const onSubmit = (values, helpers, setSubmitting) => {
    // handle form submission
    // setFormSuccess(true);
    // helpers.resetForm();

    mutate(values, {
      onSuccess: (data) => {
        helpers.resetForm();
        setFormSuccess(true);
        toast.success("manual booking is successfully");
        helpers.setSubmitting(false); // Stop loading when submission fails
        navigate(`/dashboard/booking-list-single-id/${data.short_id}/${data.bookings.id}`);
      },
      onError: (error) => {
        // An error happened!
        toast.error(`${error?.message || "failed post data"}  `);
        helpers.setSubmitting(false); // Stop loading when submission fails
      },
    });
  };

  if (Userloading) {
    return <Loading />;
  }

  return (
    <div className="formPage-wrap ">
      <h2 className="text-2xl font-bold mb-4">Add Manual Booking</h2>
      <Formik initialValues={initialValue} validationSchema={validation} onSubmit={onSubmit}>
        {({ setFieldValue, values, isSubmitting, setValues }) => (
          <Form className="space-y-6 mt-2 w-full form-wrap">
            <ArrayClint />
            <div className=" w-full lg:flex-row flex-col flex gap-2 ">
              <div className="w-full">
                <CityAreaHotel
                  render={(hotel) => {
                    if (hotel) {
                      setHotelId(hotel.value);
                      setPaymentType(hotel.payment_type);
                      void setValues({
                        ...values,
                        hotel: hotel.label,
                        payment_type: hotel.payment_type,
                        voucher: hotel.payment_type === "cash" ? false : "",
                        pay_donotpay: hotel.payment_type === "cash" ? "" : false,
                      });
                    }
                  }}
                  reset={formSuccess}
                />
                <div className="w-full flex mt-2 justify-center items-center text-end">
                  <ErrorMessage name="hotel" component="div" className="text-base text-red-500" />
                </div>
              </div>
              <RoomsSelect HotelId={HotelId} setHotelId={setHotelId} />
            </div>
            <VoucherSelect paymentType={paymentType} />
            <div className=" w-full lg:flex-row flex-col flex gap-2 ">
              <div className=" w-full lg:flex-row flex-col flex gap-2 ">
                <MealPlanSelect />
                <NationalitySelect />
              </div>
            </div>
            <div className=" w-full lg:flex-row flex-col flex gap-2 ">
              <div className="w-full">
                <FormLabel label={"Phone Number"} />
                <PhoneInput
                  placeholder="Enter phone number"
                  name="phone_number"
                  style={{ outline: "0px", direction: "ltr" }}
                  international={true}
                  withCountryCallingCode={true}
                  value={values.phone_number}
                  className={`w-full border-gray-200   border-b-2  `}
                  onChange={(selectedOption) => {
                    setFieldValue("phone_number", selectedOption);
                  }}
                />
                <ErrorMessage name="phone_number" component="div" className="text-base text-red-500" />
              </div>

              <div className="w-full">
                <FormLabel label={"whatsapp number"} />
                <PhoneInput
                  placeholder="Enter whatsapp number"
                  name="whatsapp_number"
                  style={{ outline: "0px", direction: "ltr" }}
                  international={true}
                  withCountryCallingCode={true}
                  value={values.whatsapp_number}
                  className={`w-full border-gray-200   border-b-2  `}
                  onChange={(selectedOption) => {
                    setFieldValue("whatsapp_number", selectedOption);
                  }}
                />
                <ErrorMessage name="whatsapp_number" component="div" className="text-base text-red-500" />
              </div>
            </div>
            <div className=" w-full">
              <Personsdata />
            </div>
            <div className=" w-full">
              <DateNightFun />
              <FieldArray name="net_price_data">
                {({ remove }) => (
                  <div>
                    {values.net_price_data?.length > 0 &&
                      values.net_price_data?.map((client, index) => (
                        <div key={index} className="flex space-x-2 justify-center items-end w-full mt-3">
                          <div className="w-full">
                            <label>Date</label>
                            <Field
                              name={`net_price_data.${index}.date`}
                              className="w-full py-2 px-3 h-10 border rounded-lg bg-white"
                              disabled // Date fields should be non-editable
                            />
                            <ErrorMessage
                              name={`net_price_data.${index}.date`}
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="w-full">
                            <label>Net Price</label>
                            <Field
                              name={`net_price_data.${index}.net_price`}
                              className="w-full py-2 px-3 h-10 border rounded-lg bg-white"
                            />
                            <ErrorMessage
                              name={`net_price_data.${index}.net_price`}
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </FieldArray>
            </div>
            <TotalPriceAndProfit />
            <div className=" w-full lg:flex-row flex-col flex gap-2 ">
              <GetCurrencyTypes />
              <div className="w-full">
                <FormLabel label={"Email"} />
                <div className="w-full relative text-gray-600">
                  <Field name="email" type="email" className=" w-full py-2 px-3 h-10 border rounded-lg bg-white" />
                </div>
                <ErrorMessage name="email" component="div" className="text-base text-red-500" />
              </div>
            </div>
            <div className=" w-full lg:flex-row flex-col flex gap-2 ">
              <TimeFunction />
            </div>
            <ClientRequestsText />
            <Button
              type="submit"
              disabled={isSubmitting}
              isProcessing={isSubmitting}
              color="success"
              className={"mx-auto"}
            >
              {isSubmitting ? "Submitting..." : "Create Manual Booking"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddManualBooking;

const RoomsSelect = ({ HotelId, setHotelId }) => {
  const { values, setValues } = useFormikContext();

  const {
    data: roomsData,
    error: roomsError,
    isLoading,
  } = useQuery({
    queryKey: ["get-rooms-in-hotel", HotelId],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`/en/rooms/api/v1/get-rooms-in-hotel/${name}/`);
    },
    enabled: !!HotelId,
  });

  useEffect(() => {
    if (!values.room_id) {
      setHotelId("");
    }
  }, [values.room_id]);

  return (
    <div className="lg:w-1/2 w-full">
      <FormLabel label={"Room"} htmlFor={"room_id"} />
      <Select
        id={"room_id"}
        isLoading={isLoading}
        isDisabled={isLoading}
        value={{ value: values.room_id, label: roomsData?.find((room) => room.id === values.room_id)?.room_name }}
        name="room_id"
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            room_id: selectedOption.value,
          });
        }}
        options={
          HotelId
            ? roomsData?.map((room) => {
                return {
                  value: room.id,
                  label: room.room_name,
                };
              })
            : []
        }
        isSearchable
        placeholder="Select Room"
      />

      <ErrorMessage name="room_id" component="div" className="text-base text-red-500" />
    </div>
  );
};

const MealPlanSelect = () => {
  const { values, setValues } = useFormikContext();

  const arr = ["Bed Only", "breakfast included", "half board", "full board", "Soft All"];

  return (
    <div className="w-full">
      <FormLabel label={"Meal Plan"} htmlFor={"meal_plan"} />
      <Select
        id={"meal_plan"}
        value={{
          value: values.meal_plan,
          label: values.meal_plan,
        }}
        isSearchable
        name="meal_plan"
        placeholder="Select meal plan"
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            meal_plan: selectedOption.value,
          });
        }}
        options={arr?.map((MealPlan) => {
          return {
            value: MealPlan,
            label: MealPlan,
          };
        })}
      />

      <ErrorMessage name="meal_plan" component="div" className="text-base text-red-500" />
    </div>
  );
};

const NationalitySelect = () => {
  const { values, setValues } = useFormikContext();

  const {
    data: countriesData,
    error: countriesError,
    isLoading,
  } = useQuery({
    queryKey: [`countries-flags`],
    queryFn: () => fetchDataQuery(`site-settings/api/countries-flags`),
  });

  //////// flagData /////////////
  let flagData = countriesData
    ? countriesData.map((item) => {
        return { value: item.name_en, label: item.name };
      })
    : [];

  return (
    <div className="w-full">
      <FormLabel label={"Nationality"} htmlFor={"nationality"} />

      <Select
        id="nationality"
        name="nationality"
        options={flagData}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            nationality: selectedOption.value,
          });
        }}
        isLoading={isLoading}
        isDisabled={isLoading}
        isSearchable
        placeholder="Select Nationality"
        value={{
          value: values.nationality,
          label: values.nationality,
        }}
      />

      <ErrorMessage name="nationality" component="div" className="text-base text-red-500" />
    </div>
  );
};

const Personsdata = () => {
  const { values, setValues, resetForm } = useFormikContext();

  const [roomDetails, setRoomDetails] = useState([{ adults: 1, children: [] }]);

  // useEffect to update Formik values when roomDetails change
  useEffect(() => {
    setValues({
      ...values,
      persons_data: roomDetails[0],
      number_of_persons: roomDetails[0]?.adults,
      adults: roomDetails[0]?.adults,
    });
  }, [roomDetails, setValues, values.persons_data]);

  useEffect(() => {
    if (!values.persons_data) {
      setValues({ ...values, persons_data: "" });
      setRoomDetails([{ adults: 1, children: [] }]);
    }
  }, [values.persons_data]);

  return (
    <div>
      <PresonChild setRoomDetails={setRoomDetails} roomDetails={roomDetails} />
      <ErrorMessage name="persons_data" component="div" className="text-base text-red-500" />
    </div>
  );
};

const DateNightFun = () => {
  const { values, setValues } = useFormikContext();

  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [nights, setNights] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);

  // functions
  const getDateRange = (fromDate, toDate) => {
    const start = new Date(fromDate);
    const end = new Date(toDate);
    const dates = [];
    // Decrease the toDate by 1 day
    end.setDate(end.getDate() - 1);

    let current = start;

    while (current <= end) {
      dates.push({
        date: current.toISOString().split("T")[0], // Format date to YYYY-MM-DD
        net_price: "",
      });
      current.setDate(current.getDate() + 1); // Move to next day
    }

    return dates;
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    if (startDate && endDate) {
      setNights(Math.floor((endDate - startDate) / 86400000));
    }
  };

  function handleNightsChange(e) {
    if (e.target.value >= 1) {
      setNights(e.target.value);
      let diff = e.target.value - nights;
      if (diff < 0) {
        setDateRange({ ...dateRange, endDate: dateRange.endDate.subtract(diff * -1, "days") });
      } else {
        setDateRange({ ...dateRange, endDate: dateRange.endDate.add(diff, "days") });
      }
    }
  }

  // useEffect to update Formik values when roomDetails change
  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      // Format dates to YYYY-MM-DD
      const formattedStartDate = dateRange.startDate.format("YYYY-MM-DD");
      const formattedEndDate = dateRange.endDate.format("YYYY-MM-DD");

      // Calculate the net price data range based on the formatted dates
      const dateRangeForPrice = getDateRange(formattedStartDate, formattedEndDate);

      // Update Formik values
      setValues({
        ...values,
        nights: nights,
        from_date: formattedStartDate,
        to_date: formattedEndDate,
        net_price_data: dateRangeForPrice,
      });
    }
  }, [dateRange.startDate, dateRange.endDate]);

  const isEndDateBlocked = (day) => {
    return dateRange?.startDate && day.isSame(dateRange?.startDate, "day");
  };

  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      try {
        // Check if the screen width is "md" based on Tailwind CSS classes
        if (window.innerWidth < 1024) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      } catch (error) {}
    };
    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);
    // Initil check when the component is mounted
    handleResize();
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Effect to reset DateRangePicker when arrival_time_from and arrival_time_to are empty
  useEffect(() => {
    if (!values.from_date && !values.to_date) {
      // Reset the date range and nights count
      setDateRange({ startDate: null, endDate: null });
      setNights("");

      // Explicitly reset Formik values
      setValues({
        ...values,
        from_date: "",
        to_date: "",
        nights: "",
        net_price_data: [],
      });
    }
  }, [values.from_date, values.to_date, setValues]);

  return (
    <div className="w-full gap-4 flex flex-col  mt-5 ">
      <div className="w-full gap-4 flex max-sm:flex-col mt-5 ">
        <div className="flex-1 max-sm:w-full flex flex-col gap-y-1">
          <label className="flex   text-gray-600  mr-10 ">Date Range</label>
          <DateRangePicker
            startDate={dateRange.startDate}
            startDateId="startDate"
            endDate={dateRange.endDate}
            endDateId="endDate"
            onDatesChange={handleDateChange}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            minimumNights={0} // Allow same-day selection
            verticalBorderSpacing={50} // Adjust the spacing between monthsW
            autofocus
            orientation={open ? "vertical" : "horizontal"}
            numberOfMonths={open ? 6 : 2}
            daySize={open ? 42 : 40}
            verticalHeight={800}
            // weekDayFormat={t("lan") === "en" ? "ddd" : "dddd"}
            weekDayFormat="ddd"
            noNavButtons
            isDayBlocked={isEndDateBlocked}
          />
        </div>
        <div className="flex-1 max-sm:w-full flex flex-col gap-y-1">
          <label className="flex w-full  text-gray-600  ">Nights</label>
          <input
            type="number"
            placeholder="Nights"
            className="w-full  h-10 px-3 border rounded-lg bg-white text-gray-800"
            value={nights}
            disabled={!dateRange.startDate}
            onChange={(e) => handleNightsChange(e)}
          />
        </div>
      </div>
      <div className="w-full gap-5 flex ">
        <ErrorMessage name="from_date" component="div" className="text-base text-red-500" />
        <ErrorMessage name="to_date" component="div" className="text-base text-red-500" />
        <ErrorMessage name="nights" component="div" className="text-base text-red-500" />
      </div>
    </div>
  );
};

const GetCurrencyTypes = () => {
  const { values, setValues } = useFormikContext();

  const {
    data: currencyData,
    error: currencyError,
    isLoading,
  } = useQuery({
    queryKey: [`contracts`],
    queryFn: () => fetchDataQuery(`contracts/api/currency-types/`),
  });

  return (
    <div className=" w-full">
      <FormLabel label={"currency type"} htmlFor={"currency_type"} />
      <Select
        id={"currency_type"}
        isLoading={isLoading}
        name="currency_type"
        isDisabled={isLoading}
        onChange={(selectedOption) => {
          void setValues({
            ...values,
            currency_type: selectedOption.value,
          });
        }}
        options={currencyData?.map((curr) => {
          return {
            value: curr,
            label: curr,
          };
        })}
        isSearchable
        placeholder="Select currency"
        value={{
          value: values.currency_type,
          label: values.currency_type,
        }}
      />

      <ErrorMessage name="currency_type" component="div" className="text-base text-red-500" />
    </div>
  );
};

const TimeFunction = () => {
  const { values, setValues } = useFormikContext();

  // const handleTimeChange = (newTime) => {
  //   if (!newTime || !newTime.$d) return ""; // Ensure newTime is valid

  //   const date = new Date(newTime.$d);
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();
  //   const hoursFormatted = hours < 10 ? `0${hours}` : hours.toString();
  //   const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes.toString();

  //   const formattedTime = `${hoursFormatted}:${minutesFormatted}`;
  //   return formattedTime; // Return the formatted time
  // };

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour.toString().padStart(2, "0");
      times.push({
        label: `${formattedHour}:00`,
        value: `${formattedHour}:00`,
      });
    }
    return times;
  };
  const timeOptions = generateTimeOptions();

  return (
    <div className=" w-full lg:flex-row flex-col flex gap-2 ">
      <div className="w-full">
        <FormLabel label={"arrival time from"} />

        <Select
          name="arrival_time_from"
          onChange={(selectedOption) => {
            void setValues({
              ...values,
              arrival_time_from: selectedOption.value,
            });
          }}
          value={{
            label: values.arrival_time_from,
            value: values.arrival_time_from,
          }}
          options={timeOptions}
          placeholder="Select time"
          isSearchable
        />

        <ErrorMessage name="arrival_time_from" component="div" className="text-base text-red-500" />
      </div>

      <div className="w-full">
        <FormLabel label={"arrival time to"} />

        <Select
          name="arrival_time_to"
          onChange={(selectedOption) => {
            void setValues({
              ...values,
              arrival_time_to: selectedOption.value,
            });
          }}
          value={{
            label: values.arrival_time_to,
            value: values.arrival_time_to,
          }}
          options={timeOptions}
          placeholder="Select time"
          isSearchable
        />
        <ErrorMessage name="arrival_time_to" component="div" className="text-base text-red-500" />
      </div>
    </div>
  );
};

const TotalPriceAndProfit = () => {
  const { values, setValues } = useFormikContext();
  const [Profit, setProfit] = useState("");

  // Helper function to sum net_price from net_price_data
  const calculateTotalNetPrice = (netPriceData) => {
    return netPriceData.reduce((total, item) => {
      const price = parseFloat(item.net_price) || 0;
      return total + price;
    }, 0);
  };

  useEffect(() => {
    if (!values.total_price) {
      setProfit("");
      return;
    }

    const totalNetPrice = calculateTotalNetPrice(values.net_price_data);
    const totalProfit = values.total_price - totalNetPrice;
    setProfit(totalProfit);
  }, [values.net_price_data, values.total_price]);

  return (
    <div className="w-full lg:flex-row flex-col flex gap-2">
      <div className="w-full">
        <label>Total Price</label>
        <div className="w-full relative text-gray-600">
          <Field name="total_price" type="number" className="w-full py-2 px-3 h-10 border rounded-lg bg-white" />
        </div>
        <ErrorMessage name="total_price" component="div" className="text-base text-red-500" />
      </div>
      <div className="w-full">
        <label>Total Profit</label>
        <div className="w-full relative text-gray-600">
          <input
            name="total_profit"
            type="number"
            value={Profit}
            disabled
            className="w-full py-2 px-3 h-10 border rounded-lg bg-white"
          />
        </div>
      </div>
    </div>
  );
};

const VoucherSelect = ({ paymentType }) => {
  const { values, setValues } = useFormikContext();

  return (
    <>
      {paymentType && (
        <div className="w-full">
          {paymentType !== "cash" && (
            <>
              <FormLabel label={"Voucher Type"} />
              <Select
                name="voucher"
                onChange={(selectedOption) => {
                  void setValues({
                    ...values,
                    voucher: selectedOption.value,
                  });
                }}
                options={[
                  {
                    value: true,
                    label: "Voucher",
                  },
                  {
                    value: false,
                    label: "No Voucher",
                  },
                ]}
                isSearchable
                placeholder="Select voucher type"
                value={{
                  value: values.voucher,
                  label: values.voucher === true ? "Voucher" : values.voucher === false && "No Voucher",
                }}
              />

              <ErrorMessage name="voucher" component="div" className="text-base text-red-500" />
            </>
          )}

          {paymentType === "cash" && (
            <>
              <FormLabel label={"Pay Type"} />
              <Select
                name="pay_donotpay"
                onChange={(selectedOption) => {
                  void setValues({
                    ...values,
                    pay_donotpay: selectedOption.value,
                  });
                }}
                options={[
                  {
                    value: true,
                    label: "pay",
                  },
                  {
                    value: false,
                    label: "donot pay",
                  },
                ]}
                isSearchable
                placeholder="Select Pay type"
                value={{
                  value: values.pay_donotpay,
                  label: values.pay_donotpay === true ? "pay" : values.pay_donotpay === false && "donot pay",
                }}
              />

              <ErrorMessage name="pay_donotpay" component="div" className="text-base text-red-500" />
            </>
          )}
        </div>
      )}
    </>
  );
};

const ClientRequestsText = () => {
  const { values, setValues } = useFormikContext();

  const {
    data: tagsData,
    error: tagsError,
    isLoading,
  } = useQuery({
    queryKey: [`get-booking-tags`],
    queryFn: () => fetchDataQuery(`/booking/api/v1/get-booking-tags/`),
  });

  //////// flagData /////////////
  let tagsDataArr = tagsData
    ? tagsData.map((item) => {
        return { value: item.tag, label: item.tag };
      })
    : [];

  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-5    w-full ">
      <div className="w-full">
        <label className="font-body">Client Request Tag</label>
        <Select
          name="client_requests_tags"
          options={tagsDataArr}
          onChange={(e) => {
            void setValues({
              ...values,
              client_requests_tags: e.map((item) => item.value),
            });
          }}
          isSearchable
          isMulti
          placeholder="Select client Request Tags"
          isLoading={isLoading}
          isDisabled={isLoading}
          value={
            values.client_requests_tags.length
              ? values.client_requests_tags.map((tag) => ({
                  value: tag,
                  label: tag,
                }))
              : []
          }
        />

        {/* <ErrorMessage name="client_requests_tags" component="div" className="text-base text-red-500" /> */}
      </div>

      <div className="w-full">
        <label className="font-body">Client Other Requests</label>
        <textarea
          name="client_requests_text"
          type="text"
          className="w-full  lg:h-9 border-gray-200  border-opacity-100 border-b-2 "
          onChange={(selectedOption) => {
            void setValues({
              ...values,
              client_requests_text: selectedOption.target.value,
            });
          }}
          value={values.client_requests_text}
        />
        {/* <ErrorMessage name="client_requests_text" component="div" className="text-base text-red-500" /> */}
      </div>
    </div>
  );
};

const ArrayClint = () => {
  const { values, setValues } = useFormikContext();

  async function translateText(query, targetLanguage, sourceLanguage) {
    try {
      const response = await fetch(
        "https://translation.googleapis.com/language/translate/v2?" +
          new URLSearchParams({
            q: query,
            target: targetLanguage,
            source: sourceLanguage,
            format: "text",
            key: "AIzaSyCykn4G8hx24qD_L_SKO1DQKLbl2_BRP8c",
          }),
      );

      const data = await response.json();

      return data.data.translations[0].translatedText;
    } catch (error) {
      throw error;
    }
  }

  const translateTextToArabic = async (query) => {
    return await translateText(query, "ar", "en");
  };

  const translateTextToEnglish = async (query) => {
    return await translateText(query, "en", "ar");
  };

  // Add this function to detect language and trigger translation
  const handleTranslation = async (index, values) => {
    const arabicName = values.client_names[index].arabic_name;
    const englishName = values.client_names[index].english_name;

    if (arabicName && !englishName) {
      // Translate Arabic to English
      const translatedText = await translateTextToEnglish(arabicName);
      setValues({
        ...values,
        client_names: values.client_names.map((client, idx) =>
          idx === index ? { ...client, english_name: translatedText } : client,
        ),
      });
    } else if (englishName && !arabicName) {
      // Translate English to Arabic
      const translatedText = await translateTextToArabic(englishName);
      setValues({
        ...values,
        client_names: values.client_names.map((client, idx) =>
          idx === index ? { ...client, arabic_name: translatedText } : client,
        ),
      });
    }
  };

  return (
    <FieldArray name="client_names">
      {({ insert, remove, push }) => (
        <div>
          {values.client_names?.length > 0 &&
            values.client_names?.map((client, index) => (
              <div key={index} className="flex justify-between items-center gap-4">
                <div className="grow">
                  <div className="w-full relative text-gray-600">
                    <label>English Name</label>
                    <Field
                      name={`client_names.${index}.english_name`}
                      className="w-full py-2 px-3 h-10 border rounded-lg bg-white"
                    />
                    <ErrorMessage
                      name={`client_names.${index}.english_name`}
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="grow">
                  <div className="w-full relative text-gray-600">
                    <label>Arabic Name</label>
                    <Field
                      dir={"rtl"}
                      name={`client_names.${index}.arabic_name`}
                      className="w-full py-2 px-3 h-10 border rounded-lg bg-white"
                    />
                    <ErrorMessage name={`client_names.${index}.arabic_name`} component="div" className="text-red-500" />
                  </div>
                </div>
                <div className=" flex gap-2 mt-[22px]">
                  {/* Translation icon */}
                  <button
                    type="button"
                    className="p-2 bg-gray-200 hover:bg-gray-300 rounded"
                    onClick={() => handleTranslation(index, values)}
                  >
                    <SiGoogletranslate className="w-5 h-5 text-gray-700" />
                  </button>

                  {index !== 0 && <DeleteButton type="button" onDelete={() => remove(index)} />}
                </div>
              </div>
            ))}
          <button
            type="button"
            onClick={() => push({ arabic_name: "", english_name: "" })}
            className="mt-4 rounded px-2 py-1 flex justify-center items-center gap-1 text-white bg-green-700 hover:bg-green-900"
          >
            <FaPlusCircle className="w-5 h-5 text-white" />
            <p> Add Client</p>
          </button>
        </div>
      )}
    </FieldArray>
  );
};
