import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import { FaDatabase, FaGear } from "react-icons/fa6";
import { IoMdPerson, IoMdPersonAdd } from "react-icons/io";
import { MdEmail, MdLocationCity } from "react-icons/md";
import { BiMessageAltError } from "react-icons/bi";
import { AiFillDelete, AiOutlineEye, AiOutlineLoading } from "react-icons/ai";
import { Button, Modal } from "flowbite-react";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { HiOutlineStatusOnline } from "react-icons/hi";
import usePermissions from "../../../../customHooks/usePermissions";
import NoPermission from "../../NoPermission/NoPermission";
import { DetailsModal } from "../../../../customComponents/TableComponents";
import ListFilters from "../../../../utils/ListFilters";
import { PiIdentificationCardLight } from "react-icons/pi";

export default function AgentsList() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "auth_users.view_profile_agent",
    "auth_users.delete_profile_agent",
    "auth_users.change_profile_agent",
    "permissions_management.users_profile",
  ]);

  const isViewingAgentsListAllowed =
    permissions?.find((permission) => permission.name === "auth_users.view_profile_agent")?.value === true;

  const isDeletingAgentAllowed =
    permissions?.find((permission) => permission.name === "auth_users.delete_profile_agent")?.value === true;

  const isEditingAgentAllowed =
    permissions?.find((permission) => permission.name === "auth_users.change_profile_agent")?.value === true;

  const isViewingUserProfileAllowed =
    permissions?.find((permission) => permission.name === "permissions_management.users_profile")?.value === true;

  const {
    data: agents,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["agents-list"],
    queryFn: () => fetchDataQuery("/auth/api/v1/user-agent/"),
  });

  if (isLoading || !permissionFlag) {
    return <Loading />;
  }

  if (error || !agents) {
    <Errorfetch Error={error.message || "Error Fetching Agents"} />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const rows = agents?.map((agent) => {
    return {
      // name: agent?.profile_agent?.agent_name,
      id: agent?.id,
      username: agent?.username,
      email: agent?.email,
      nickname: agent?.profile_agent?.nickname,
      idNumber: agent?.profile_agent?.id_number,
      country: agent?.profile_agent?.country_name,
      city: agent?.profile_agent?.city,
      mobileNumbers: agent?.profile_agent?.mobile_numbers,
      whatsAppNumbers: agent?.profile_agent?.whats_app_numbers,
      emailAddresses: agent?.profile_agent?.email_address,
      market: agent?.profile_agent?.market,

      markupName: agent?.profile_agent?.markup_name,
      addedBy: agent?.profile_agent?.created_by?.username,
      notes: agent?.profile_agent?.notes,
      agentStatus: agent?.is_active,
      creditLimit: agent?.profile_agent?.credit_info?.credit_limit,
      currency: agent?.profile_agent?.credit_info?.currency,
      creditUsed: agent?.profile_agent?.credit_info?.credit_used,
      creditAvailable: agent?.profile_agent?.credit_info?.credit_available,
      outstandingAmount: agent?.profile_agent?.credit_info?.outstanding_amount,
    };
  });
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <FaDatabase className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
    },
    // {
    //   key: "name",
    //   title: () => <ColumnHeader label={"Name"} Icon={IoMdPerson} />,
    //   render: (text,params) => {
    //     if (!params.name) {
    //       return "No name";
    //     }
    //     return params.name;
    //   },
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      key: "username",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <IoMdPersonAdd className="w-5 h-5  text-yellow-500" />
          Username
        </div>
      ),
      dataIndex: "username",
    },
    {
      key: "nickname",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <IoMdPerson className="w-5 h-5  text-yellow-500" />
          Nickname
        </div>
      ),
      dataIndex: "nickname",
    },

    {
      key: "country",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdLocationCity className="w-5 h-5  text-yellow-500" />
          Country
        </div>
      ),
      render: (text, params) => {
        if (!params.country) {
          return "No Country";
        }
        return params.country;
      },
      dataIndex: "country",
    },
    {
      key: "city",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdLocationCity className="w-5 h-5  text-yellow-500" />
          City
        </div>
      ),
      render: (text, params) => {
        if (!params.city) {
          return "No city";
        }
        return params.city;
      },
      dataIndex: "city",
    },

    {
      key: "agentStatus",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <HiOutlineStatusOnline className="w-5 h-5  text-yellow-500" />
          Agent Status
        </div>
      ),
      render: (text, params) => {
        if (params.agentStatus === null) {
          return "No status";
        }
        return params.agentStatus ? "Active" : "Stopped";
      },
      dataIndex: "agentStatus",
    },
    {
      key: "marketName",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <HiOutlineStatusOnline className="w-5 h-5  text-yellow-500" />
          Market
        </div>
      ),
      render: (text, params) => {
        if (params.market.name === null) {
          return "No Market";
        }
        return (
          <div className="flex gap-2">
            {params?.market?.length > 0 ? (
              <p className="flex gap-2 ">
                {params.market?.map((i) => (
                  <span className="" key={i.id}>
                    {i?.name}
                  </span>
                ))}
              </p>
            ) : (
              "no market"
            )}
          </div>
        );
      },
      dataIndex: "marketName",
    },
    {
      key: "addedBy",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <IoMdPerson className="w-5 h-5  text-yellow-500" />
          Added By
        </div>
      ),
      dataIndex: "addedBy",
    },
    {
      key: "email",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdEmail className="w-5 h-5  text-yellow-500" />
          Email
        </div>
      ),
      dataIndex: "email",
    },
    {
      key: "idNumber",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <PiIdentificationCardLight className="w-5 h-5  text-yellow-500" />
          ID Number
        </div>
      ),
      dataIndex: "idNumber",
    },
    // {
    //   key: "creditLimit",
    //   title: () => <ColumnHeader label={"Credit Limit"} Icon={RiMoneyDollarCircleFill} />,
    //   render: (text,params) => {
    //     if (!params.creditLimit) {
    //       return "No credit limit";
    //     }
    //     return params.creditLimit;
    //   },
    //   flex: 1,
    //   minWidth: 150,
    // },
    // {
    //   key: "creditUsed",
    //   title: () => <ColumnHeader label={"Credit Used"} Icon={RiMoneyDollarCircleFill} />,
    //   render: (text,params) => {
    //     if (!params.creditUsed) {
    //       return "No credit used";
    //     }
    //     return params.creditUsed;
    //   },
    //   flex: 1,
    //   minWidth: 150,
    // },
    // {
    //   key: "outstandingAmount",
    //   title: () => <ColumnHeader label={"Outstanding Amount"} Icon={RiMoneyDollarCircleFill} />,
    //   render: (text,params) => {
    //     if (!params.outstandingAmount) {
    //       return "No amount";
    //     }
    //     return params.outstandingAmount;
    //   },
    //   flex: 1,
    //   minWidth: 200,
    // },
    // {
    //   key: "creditAvailable",
    //   title: () => <ColumnHeader label={"Credit Available"} Icon={RiMoneyDollarCircleFill} />,
    //   render: (text,params) => {
    //     if (!params.creditAvailable) {
    //       return "No amount";
    //     }
    //     return params.creditAvailable;
    //   },
    //   flex: 1,
    //   minWidth: 150,
    // },
    // {
    //   key: "currency",
    //   title: () => <ColumnHeader label={"Currency"} Icon={RiMoneyDollarCircleFill} />,
    //   render: (text,params) => {
    //     if (!params.currency) {
    //       return "No currency";
    //     }
    //     return params.currency;
    //   },
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      key: "mobileNumbers",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdEmail className="w-5 h-5  text-yellow-500" />
          Mobile Numbers
        </div>
      ),
      render: (text, params) => {
        if (!params.mobileNumbers) {
          return "No mobile numbers";
        }

        return (
          <DetailsModal title="Mobile Numbers">
            {Object?.values(params.mobileNumbers)?.map((mobileNumber, index) => {
              return (
                <ul key={index}>
                  <li className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">{mobileNumber}</li>
                </ul>
              );
            })}
          </DetailsModal>
        );
      },
      dataIndex: "mobileNumbers",
    },
    {
      key: "whatsAppNumbers",

      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdEmail className="w-5 h-5  text-yellow-500" />
          WhatsApp Numbers
        </div>
      ),
      render: (text, params) => {
        if (!params.whatsAppNumbers || Object?.values(params.whatsAppNumbers)?.length === 0) {
          return "No WhatsApp numbers";
        }
        return (
          <DetailsModal title="WhatsApp Numbers">
            {Object?.values(params.whatsAppNumbers)?.map((whatsAppNumber, index) => {
              return (
                <ul key={index}>
                  <li className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                    {whatsAppNumber}
                  </li>
                </ul>
              );
            })}
          </DetailsModal>
        );
      },
      dataIndex: "whatsAppNumbers",
    },
    {
      key: "emailAddresses",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdEmail className="w-5 h-5  text-yellow-500" />
          Email Addresses
        </div>
      ),
      render: (text, params) => {
        if (!params.emailAddresses) {
          return "No email addresses";
        }
        return (
          <DetailsModal title="Email Addresses">
            {Object?.values(params.emailAddresses)?.map((emailAddress, index) => {
              return (
                <ul key={index}>
                  <li className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">
                    <a href={`mailto:${emailAddress}`} className="hover:underline underline-offset-1">
                      {emailAddress}
                    </a>
                  </li>
                </ul>
              );
            })}
          </DetailsModal>
        );
      },
      dataIndex: "emailAddresses",
    },
    {
      key: "notes",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <BiMessageAltError className="w-5 h-5  text-yellow-500" />
          Notes
        </div>
      ),
      render: (text, params) => {
        if (!params.notes) {
          return "No notes";
        }
        return (
          <DetailsModal title="Notes">
            <p className="text-gray-500 font-semibold text-left whitespace-break-spaces mb-2">{params.notes}</p>
          </DetailsModal>
        );
      },
      dataIndex: "notes",
    },
    {
      key: "operations",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <FaGear className="w-5 h-5  text-yellow-500" />
          Operations
        </div>
      ),
      render: (text, params) => {
        return (
          <div className="flex gap-1 items-center justify-center">
            {isViewingUserProfileAllowed && (
              <Link to={`/dashboard/user/${params.id}`}>
                <AiOutlineEye className="w-5 h-5 text-gray-500 hover:text-[#001a35]" />
              </Link>
            )}

            {isEditingAgentAllowed && (
              <Link to={`/dashboard/edit-agent/${params.id}`}>
                <FiEdit className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800" />
              </Link>
            )}
            {isDeletingAgentAllowed && <DeleteAgentModal agentId={params.id} agentUsername={params.username} />}
          </div>
        );
      },
      dataIndex: "operations",
    },
  ];

  return isViewingAgentsListAllowed ? (
    <div className="min-h-screen w-full formPage-wrap">
      {/* <h2 className="text-center py-2 my-2 rounded-lg bg-[#002244] text-white font-bold text-uppercase">
        {"Agents List".toUpperCase()}
      </h2> */}
      <div className="w-full overflow-auto">
        <ListFilters columns={columns} rows={rows} title={"Agents List"} />
        {/* <DataGrid
          className="data-grid"
          columns={columns}
          rows={rows}
          initialState={{
            ..s?.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: {
              sortModel: [{ key: "id", sort: "desc" }],
            },
          }}
          pageSizeOptions={[5, 10, 25]}
        /> */}
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}

const DeleteAgentModal = ({ agentId, agentUsername }) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const { isPending, mutate: deleteAgent } = useMutation({
    mutationFn: () => mutateDataQuery(`/auth/api/v1/user-agent/${agentId}/`, "delete"),
    mutationKey: ["delete-agent", agentId],
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success(`Agent ${agentUsername} Deleted Successfully`);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to delete Agent");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to delete Agent");
    },
  });

  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="cursor-pointer"
      >
        <AiFillDelete
          onClick={() => {}}
          className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-700 hover:text-red-900"
        />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showModal}
        position={"center"}
        onClose={() => setShowModal(false)}
      >
        <Modal.Body>
          <h3 className="text-xl font-medium text-gray-900 mb-4 text-center">
            Do you want to delete agent {agentUsername}?
          </h3>
          <div className="flex justify-around items-center">
            <Button
              isProcessing={isPending}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              disabled={isPending}
              onClick={() => {
                deleteAgent(
                  {},
                  {
                    onSuccess: () => {
                      setShowModal(false);
                      void queryClient.refetchQueries({
                        queryKey: ["agents-list"],
                        type: "active",
                        exact: true,
                      });
                    },
                  },
                );
              }}
              color="failure"
            >
              Delete
            </Button>
            <Button disabled={isPending} onClick={() => setShowModal(false)} color="gray">
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
