import React, { useMemo, useState } from "react";
import { Button, Card, Result } from "antd";
import DateRangeSelector from "../../../customComponents/DateRangeSelector";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import dayjs from "dayjs";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../Loading/Loading";
import Errorfetch from "../Errorfetch/Errorfetch";

const UserSalesReport = () => {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "permissions_management.user_staff_commission_report_view",
  ]);
  const canViewSalesReport =
    permissions?.find((permission) => permission.name === "permissions_management.user_staff_commission_report_view")
      ?.value === true;

  const [dateRange, setDateRange] = useState({
    startDate: dayjs(),
    endDate: dayjs(),
  });

  const { isLoading, data, isError, refetch } = useQuery({
    queryKey: ["userSalesReport", dateRange],
    queryFn: async () =>
      fetchDataQuery(
        `/sales-management/api/v1/user-staff-commission-report/?start_date=${dateRange.startDate.format("YYYY-MM-DD")}&end_date=${dateRange.endDate.format("YYYY-MM-DD")}`,
      ),
    enabled: Boolean(dateRange.startDate && dateRange.endDate && canViewSalesReport),
  });

  const currencyGroups = data?.currency_groups;

  const totalSalesObj = useMemo(
    () =>
      currencyGroups?.map((group) => ({
        currency: group?.currency_type,
        total: group?.total_sales,
      })),
    [currencyGroups],
  );
  const totalProfitObj = useMemo(
    () =>
      currencyGroups?.map((group) => ({
        currency: group?.currency_type,
        total: group?.total_profit,
      })),
    [currencyGroups],
  );

  const handleRangeChange = (dates) => {
    setDateRange({
      startDate: dates ? dates[0] : null,
      endDate: dates ? dates[1] : null,
    });
  };

  if (!permissionFlag) return <Loading />;
  if (!canViewSalesReport) return null;
  if (permissionsError) return <Errorfetch Error="Error fetching permissions" />;

  return (
    <Card
      loading={isLoading}
      className="mx-auto w-full max-w-[600px] mb-8"
      title="Your Sales Report"
      extra={<DateRangeSelector onRangeChange={handleRangeChange} defaultValue={dateRange} />}
      bordered={false}
    >
      {isError ? (
        <Result
          status="error"
          title="Fetch Failed"
          subTitle="Please check your internet connection and try again"
          extra={[
            <Button key="retry" onClick={refetch}>
              Try Again
            </Button>,
          ]}
        />
      ) : dateRange.startDate && dateRange.endDate ? (
        <div className="flex gap-4 items-center justify-between">
          {currencyGroups?.length ? (
            <>
              <Card className="grow" title="Total Sales">
                <PriceDetailsTable data={totalSalesObj} />
              </Card>
              <Card className="grow" title="Total Profit">
                <PriceDetailsTable data={totalProfitObj} />
              </Card>
            </>
          ) : (
            <p className="text-center grow font-semibold">No data found between this range</p>
          )}
        </div>
      ) : (
        <p className="text-center font-semibold">Please select a date range</p>
      )}
    </Card>
  );
};

const PriceDetailsTable = ({ data }) => (
  <div className="relative overflow-x-auto">
    <table className="w-full text-sm text-left text-gray-500 border">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
          <th scope="col" className="px-6 py-3">
            Total
          </th>
          <th scope="col" className="px-6 py-3">
            Currency
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index} className="bg-white border-b">
            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{item.total}</th>
            <td className="px-6 py-4">{item.currency}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default UserSalesReport;
