import cookie from "react-cookies";
import api_token from "../../api/UserApi";
import requestHotelList from "./requestHotelList";

const token = cookie.load("access_token");

// takes hotel to delete then a page to request again after deletion
export const deleteHotel = (slug, page, sortOrder, callback) => {
  return async function (dispatch, getState) {
    try {
      const response = await api_token(token).delete(`hotels/api/v1/get-put-delete-hotels/${slug}`);
      if (response.status === 200) {
        callback(response);
        dispatch(requestHotelList(page, sortOrder, () => {}));
      }
    } catch (error) {}
  };
};
