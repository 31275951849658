import React, { useState } from "react";
import Select from "react-select";
import FileUploader from "../FileUploader/FileUploader";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "ckeditor5/ckeditor5.css";
import Loading from "../Loading/Loading";
import UserDepartment from "./userDepartment/UserDepartment";
import UsersSearchTextInputTasks from "./userDepartment/UserDepartment";
const TaskManagerForm = ({
  adding,
  toolDocument,
  setToolDcoument,
  setAdding,
  statusVal,
  departmentsVal,
  taskTypes,
  formik,
  editorKey,
  editorData,
  editorConfig,
  setEditorInstance,
  users,
  title,
  isLoading,
  formErrors,
  hasSubmitted,
  departmentOptions,
  isPending,
}) => {
  // const userOptions = formik.values?.user?.map((u) => ({
  //   label: u?.first_name ? u?.first_name + u?.last_name + "(" + u?.username + ")" : u.username,
  //   value: u.id,
  // }));

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    formik.setFieldValue("task_details", data || "");
  };
  const departmentIds = formik.values?.department?.map((i) => i.value);
  const customComponents = {
    DropdownIndicator: () => null, // Removing the default arrow
    IndicatorSeparator: () => null, // Optional: Remove the separator between the arrow and the input
  };
  return !isLoading ? (
    <section className="formPage-wrap min-h-screen flex flex-col ">
      <h2 className="header-h2">{title}</h2>
      <form onSubmit={formik.handleSubmit} className="form-wrap flex flex-col gap-2">
        <div className="input-par">
          <div className="input-chil flex flex-col gap-1">
            <label htmlFor="name">Task Name</label>
            <input
              type="text"
              id="name"
              placeholder="Task Name"
              className="px-2 py-1.5 rounded-lg border"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-xs">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="input-chil flex flex-col gap-1">
            <label htmlFor="taskType">Task Type</label>
            <Select
              options={taskTypes}
              placeholder="Task Type"
              onChange={(option) => formik.setFieldValue("task_type", option)}
              value={formik.values.task_type}
            />
            {formik.touched.task_type && formik.errors.task_type ? (
              <div className="text-red-500 text-xs">{formik.errors.task_type}</div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="input-par border p-2 bg-gray-50 rounded-md">
            <div className="input-chil flex flex-col gap-1">
              <label htmlFor="department">Department</label>
              <Select
                options={departmentsVal}
                placeholder="Department"
                onChange={(selectedOptions) => {
                  formik.setFieldValue("department", selectedOptions);
                }}
                value={formik.values.department || null}
                isMulti
              />
              {formik.touched.department && formik.errors.department ? (
                <div className="text-red-500">{formik.errors.department}</div>
              ) : null}
            </div>
            <div className="input-chil flex flex-col gap-2">
              <label htmlFor="department">Users</label>

              <UsersSearchTextInputTasks
                values={formik.values}
                setFieldValue={formik.setFieldValue}
                department={formik.values.department}
              />

              {/* Validation Error Message */}
            </div>
          </div>
          {formik.touched.user && formik.errors.user ? (
            <div className="text-red-500 text-center text-xs">{formik.errors.user}</div>
          ) : null}
        </div>

        <div className="input-chil flex flex-col gap-1">
          <label htmlFor="Details">Details</label>
          <CKEditor
            key={editorKey}
            editor={ClassicEditor}
            data={formik.values.task_details || ""}
            onChange={handleEditorChange}
            config={editorConfig}
            onReady={(editor) => {
              setEditorInstance(editor);
            }}
          />
          {formik.touched.task_details && formik.errors.task_details ? (
            <div className="text-red-500 text-xs">{formik.errors.task_details}</div>
          ) : null}
        </div>
        <div className="mt-4">
          <FileUploader
            maxFiles={1}
            maxFileSize={2}
            accept={"image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .csv"}
            setFiles={setToolDcoument}
            files={toolDocument}
            urls={[formik.values?.attachment]}
          />
        </div>
        <div className="flex justify-center w-full mt-6">
          <button type="submit" disabled={isPending} className={title === "Edit a Task" ? "updateButton" : "addButton"}>
            {isPending ? (
              <div className="flex items-center gap-2">
                {title === "Edit a Task" ? "Updating..." : "Adding..."}
                <ButtonLoading />
              </div>
            ) : title === "Edit a Task" ? (
              "Update Task"
            ) : (
              "Add New Task"
            )}
          </button>
        </div>
      </form>
    </section>
  ) : (
    <Loading />
  );
};

export default TaskManagerForm;
