import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Card, Modal } from "flowbite-react";
import { IoBedOutline } from "react-icons/io5";
import { FiPlusCircle } from "react-icons/fi";
import { GrSubtractCircle } from "react-icons/gr";
import { FaCirclePlus } from "react-icons/fa6";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function Travel({ setPerson, setFormRooms, setDetails, setFormKids, setAdultPerson, calendarDashboard }) {
  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer);

  const [roomCount, setRoomCount] = useState(1);
  const [roomDetails, setRoomDetails] = useState([{ adults: 1, children: [] }]);
  const [toggleTravel, setToggleTravel] = useState(false);
  const [adultContArray, setAdultContArray] = useState(Array.from({ length: roomCount }, () => 1));
  const [childrenContArray, setchildrenContArray] = useState(Array.from({ length: roomCount }, () => 0));

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  let kids = roomDetails?.reduce((acc, balc) => acc + parseInt(balc.children.length), 0);
  let adults = roomDetails?.reduce((acc, balc) => acc + parseInt(balc.adults), 0);

  useEffect(() => {
    setPerson(adults);
  }, [adults]);

  useEffect(() => {
    setDetails(roomDetails);
  }, [roomDetails]);

  const handleRemoveRoom = (e, index) => {
    e.preventDefault();
    if (roomCount > 1) {
      const updatedRoomDetails = roomDetails.filter((_, i) => i !== index);
      setRoomDetails(updatedRoomDetails);

      const updatedAdultContArray = adultContArray.filter((_, i) => i !== index);
      setAdultContArray(updatedAdultContArray);

      const updatedchildrenContArray = childrenContArray.filter((_, i) => i !== index);
      setchildrenContArray(updatedchildrenContArray);

      setRoomCount(updatedRoomDetails.length);
      setFormRooms(updatedRoomDetails.length);
    }
  };

  const handleRoomCountChange = (count) => {
    setRoomCount(count);
    setFormRooms(count);

    const currentRoomDetails = [...roomDetails];
    if (count > currentRoomDetails.length) {
      for (let i = currentRoomDetails.length; i < count; i++) {
        currentRoomDetails.push({ adults: 1, children: [] });
      }
    } else if (count < currentRoomDetails.length) {
      currentRoomDetails.splice(count);
    }
    setRoomDetails(currentRoomDetails);

    const currentAdultContArray = [...adultContArray];
    if (count > currentAdultContArray.length) {
      for (let i = currentAdultContArray.length; i < count; i++) {
        currentAdultContArray.push(1);
      }
    } else if (count < currentAdultContArray.length) {
      currentAdultContArray.splice(count);
    }
    setAdultContArray(currentAdultContArray);

    const currentChildrenContArray = [...childrenContArray];
    if (count > currentChildrenContArray.length) {
      for (let i = currentChildrenContArray.length; i < count; i++) {
        currentChildrenContArray.push(0);
      }
    } else if (count < currentChildrenContArray.length) {
      currentChildrenContArray.splice(count);
    }
    setchildrenContArray(currentChildrenContArray);
  };

  const handleAdultsChange = (index, value) => {
    const updatedAdultContArray = [...adultContArray];
    updatedAdultContArray[index] = value;
    setAdultContArray(updatedAdultContArray);

    const updatedRoomDetails = [...roomDetails];
    updatedRoomDetails[index].adults = value;
    setRoomDetails(updatedRoomDetails);

    setAdultPerson(value);
  };

  const handleChildrenChange = (index, value) => {
    const updatedChildrenContArray = [...childrenContArray];
    updatedChildrenContArray[index] = value;
    setchildrenContArray(updatedChildrenContArray);

    const updatedRoomDetails = [...roomDetails];
    updatedRoomDetails[index].children = updatedRoomDetails[index].children.slice(0, value);
    if (value > updatedRoomDetails[index].children.length) {
      updatedRoomDetails[index].children = [
        ...updatedRoomDetails[index].children,
        ...new Array(value - updatedRoomDetails[index].children.length).fill(0),
      ];
    }
    setRoomDetails(updatedRoomDetails);

    setFormKids(value);
  };

  const handleChildAgeChange = (roomIndex, childIndex, event) => {
    const value = parseInt(event.target.value);
    const updatedRoomDetails = [...roomDetails];
    updatedRoomDetails[roomIndex].children[childIndex] = value;
    setRoomDetails(updatedRoomDetails);
  };

  useEffect(() => {
    if (dataFromSearchForReseve.length !== 0) {
      setRoomCount(dataFromSearchForReseve.details.length);
      setRoomDetails(dataFromSearchForReseve.details);
      setAdultContArray(dataFromSearchForReseve.details && dataFromSearchForReseve.details.map((item) => item.adults));
      setchildrenContArray(
        dataFromSearchForReseve.details && dataFromSearchForReseve.details.map((item) => item.children.length),
      );
    }
  }, [dataFromSearchForReseve]);

  return (
    <div className="w-full">
      <p
        onClick={(e) => {
          e.stopPropagation();
          setToggleTravel(!toggleTravel);
        }}
        dir={!calendarDashboard && t("dir")}
        className={`flex ${
          calendarDashboard
            ? "justify-start font-extrabold text-[#002244]"
            : "justify-center font-bold text-[#002244] border-gray-400 border-b"
        } items-center gap-x-2 w-full text-center text-sm sm:text-[15px] bg-white rounded-sm active:scale-95 py-2 duration-300 transition-all`}
      >
        {`${adults} ${calendarDashboard ? "adults" : t("adults")} | ${kids} ${
          calendarDashboard ? "children" : t("children")
        } | ${roomCount} ${calendarDashboard ? "rooms" : t("rooms")}`}
      </p>

      {toggleTravel && (
        <Modal dismissible show={toggleTravel} onClose={() => setToggleTravel(!toggleTravel)}>
          <Modal.Header className="flex "></Modal.Header>
          <Modal.Body dir={t("dir")} className=" overflow-scroll flex flex-col items-center gap-2">
            <div className="flex flex-col items-center w-full justify-center mb-2">
              <p className="lg:text-lg text-base font-semibold text-[#002244]">{t("guests")}</p>
              <p className="pt-2 lg:text-base text-sm font-normal text-[#002244]">{t("roomsnum")}</p>
            </div>
            {roomDetails.map((room, roomIndex) => (
              <Card key={roomIndex} className="w-full">
                <div className="mb-4 flex items-center justify-between">
                  <div className="flex gap-2">
                    <IoBedOutline className="text-xl" />
                    <h6 className="text-base font-bold leading-none text-gray-900 dark:text-white">
                      {t("room")} {roomIndex + 1}
                    </h6>
                  </div>
                  {roomCount > 1 && (
                    <p
                      onClick={(e) => handleRemoveRoom(e, roomIndex)}
                      className="cursor-pointer text-sm font-medium text-red-600 hover:underline dark:text-cyan-500"
                    >
                      {t("delete")}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-2 w-full items-center">
                  <div className="flex justify-between gap-4 w-full items-center">
                    <div className="flex flex-col">
                      <span className="text-base font-medium">{t("adults")}</span>
                      <span className="text-sm text-gray-400 font-medium">(12+)</span>
                    </div>
                    <div className="flex gap-2">
                      <GrSubtractCircle
                        onClick={() => handleAdultsChange(roomIndex, Math.max(1, adultContArray[roomIndex] - 1))}
                        className="text-[#002244] text-2xl cursor-pointer"
                      />
                      {adultContArray[roomIndex]}
                      <FiPlusCircle
                        onClick={() => handleAdultsChange(roomIndex, Math.min(12, adultContArray[roomIndex] + 1))}
                        className="text-[#002244] text-2xl cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between gap-4 w-full items-center">
                    <div className="flex flex-col">
                      <span className="text-base font-medium">{t("children")}</span>
                      <span className="text-sm text-gray-400 font-medium">(0-11)</span>
                    </div>
                    <div className="flex gap-2">
                      <GrSubtractCircle
                        onClick={() => handleChildrenChange(roomIndex, Math.max(0, childrenContArray[roomIndex] - 1))}
                        className="text-[#002244] text-2xl cursor-pointer"
                      />
                      {childrenContArray[roomIndex]}
                      <FiPlusCircle
                        onClick={() => handleChildrenChange(roomIndex, Math.min(5, childrenContArray[roomIndex] + 1))}
                        className="text-[#002244] text-2xl cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-[0.8rem] w-full justify-center  text-center items-center">
                    {room.children.map((childAge, childIndex) => (
                      <div key={childIndex} className="flex flex-col w-[6rem]  ">
                        {/* <span className="text-base font-medium">
                          {t("child")} {childIndex + 1}
                        </span> */}

                        <FormControl sx={{ m: 1, minWidth: 10 }} size="small" className="w-[6rem]">
                          <InputLabel id="demo-select-small-label">
                            {t("agechild")} {childIndex + 1}{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={childAge !== undefined ? childAge : "Age needed"}
                            onChange={(event) => handleChildAgeChange(roomIndex, childIndex, event)}
                            label={t("agechild") + " " + (childIndex + 1)}
                            size="small"
                          >
                            <MenuItem value={0}>
                              <em>{t("lessthanone")}</em>
                            </MenuItem>
                            {[...Array(11).keys()].map((age) => (
                              <MenuItem key={age + 1} value={age + 1}>
                                {age + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    ))}
                  </div>
                </div>
              </Card>
            ))}
            {roomCount < 8 && (
              <div className="flex justify-center items-center mt-2">
                <Button
                  onClick={() => handleRoomCountChange(roomCount < 8 ? roomCount + 1 : roomCount)}
                  size="sm"
                  className=" text-[#002244] border border-cyan-700 flex justify-center items-center"
                  pill
                  color="light"
                >
                  <span className=" flex justify-center items-center">
                    <FaCirclePlus className="mx-2 text-lg text-[#002244] " />
                    <p className=" text-base font-medium">{t("addroom")}</p>
                  </span>
                </Button>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className=" w-full text-white bg-[#002244]"
              onClick={() => setToggleTravel(!toggleTravel)}
              label="5"
            >
              {t("allowed")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Travel;
