import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import cookie from "react-cookies";
import { AiFillDelete, AiOutlineBank, AiOutlineEye } from "react-icons/ai";
import Loading from "../Loading/Loading";
import lightToast from "light-toast";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { GoPerson } from "react-icons/go";
import { BsCurrencyExchange } from "react-icons/bs";
import { LiaCalendar } from "react-icons/lia";
import { BiTransfer } from "react-icons/bi";
import TopBarProgress from "react-topbar-progress-indicator";
import { deleteBatchAction, getAllBatchAction } from "../../../redux/actions/newBatchAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";
import ListFilters from "../../../utils/ListFilters";

const BatchList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = cookie.load("access_token");
  const params = useParams();
  const [isloading, setIsLoading] = useState(true);
  const [id, setId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const { getAllBatch } = useSelector((state) => state.newBatchReducer);
  const { getAllBatchFlag } = useSelector((state) => state.newBatchReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  const { deleteBatchFlag } = useSelector((state) => state.newBatchReducer);
  const { getSelectedBatch } = useSelector((state) => state.newBatchReducer);

  useEffect(() => {
    dispatch(getAllBatchAction(token, params.page, params.sorting));
    dispatch(getBankListAction(token));
  }, [params.page, location]);
  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("grid-visibility-batch-list");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility), slug: false });
    }
  }, []);
  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const rows =
    getAllBatch &&
    getAllBatch.results.map((item, index) => {
      return {
        id: item.id,
        created_by_name: item.created_by_name,
        batch_number: item.batch_number,
        creation_date: item.creation_date.slice(0, 10),
        mid_number: item.mid_number,
        currency: item.currency,
        total: item.total,
        subtotal: item.subtotal,
        bank: item.bank,
        created_by: item.created_by,
        pos_machines: item.pos_machines,
      };
    });
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex items-center justify-center w-full gap-x-1 text-xs ">
          <BiTransfer className="w-4 h-4  text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
      editable: false,
      render: (text, params) => {
        return <div className="w-full h-full  flex items-center gap-x-1 ml-5 ">{params.id}</div>;
      },
    },
    {
      key: "batch_number",
      title: (
        <div className="flex items-center justify-center w-full gap-x-1 text-xs ">
          <BiTransfer className="w-4 h-4  text-yellow-500" />
          BATCH NUMBER
        </div>
      ),
      dataIndex: "batch_number",
      editable: false,
      render: (text, params) => {
        return <div className="w-full h-full  flex items-center gap-x-1 ml-10 ">{params.batch_number}</div>;
      },
    },

    {
      key: "mid_number",
      title: (
        <div className="flex items-center gap-x-1">
          <BsCurrencyExchange className="w-4 h-4  text-yellow-500" />
          MID NUMBER
        </div>
      ),

      dataIndex: "mid_number",
      editable: false,
      render: (text, params) => {
        return <div className="w-full h-full  flex items-center gap-x-1 ml-10 ">{params.mid_number}</div>;
      },
    },
    {
      key: "currency",
      title: (
        <div className="flex items-center justify-center w-full gap-x-1 text-xs ">
          <BsCurrencyExchange className="w-4 h-4  text-yellow-500" />
          CURRENCY
        </div>
      ),
      dataIndex: "currency",
      editable: false,
      render: (text, params) => {
        return <div className="w-full h-full  flex items-center gap-x-1 ml-10 ">{params.currency}</div>;
      },
    },
    {
      key: "total",
      title: (
        <div className="flex items-center justify-center w-full gap-x-1 text-xs ">
          <GiMoneyStack className="w-4 h-4  text-yellow-500" />
          TOTAL
        </div>
      ),
      dataIndex: "total",
      editable: false,
      render: (text, params) => {
        return <div className="w-full h-full  flex items-center gap-x-1 ml-10 ">{params.total}</div>;
      },
    },
    // {
    //   key: 'booked_room_id',
    //   title: 'ID',

    //   flex :1,
    //   editable: false,
    // },
    {
      key: "subtotal",
      title: (
        <div className="flex items-center justify-center w-full gap-x-1 text-xs ">
          <GiTakeMyMoney className="w-4 h-4  text-yellow-500" />
          SUBTOTAL
        </div>
      ),
      dataIndex: "subtotal",
      editable: false,
      render: (text, params) => {
        return <div className="w-full h-full  flex items-center gap-x-1 ml-10 ">{params.subtotal}</div>;
      },
    },
    {
      key: "bank",
      title: (
        <div className="flex items-center justify-center w-full gap-x-1 text-xs ">
          <AiOutlineBank className="w-4 h-4  text-yellow-500" />
          BANK
        </div>
      ),

      dataIndex: "bank",
      editable: false,
      render: (text, params) => {
        const bankTable = Bank ? Bank.filter((item) => item.value === params.bank) : [];
        return (
          <div className="w-full h-full  flex items-center gap-x-1 ml-10 ">
            {bankTable && bankTable?.map((item) => item.label)}
          </div>
        );
      },
    },
    {
      key: "creation_date",
      title: (
        <div className="flex items-center gap-x-1">
          <LiaCalendar className="w-4 h-4  text-yellow-500" />
          CREATION DATE
        </div>
      ),
      dataIndex: "creation_date",
      editable: false,
      render: (text, params) => {
        return <div className="w-full h-full  flex items-center gap-x-1 ml-10 ">{params.creation_date}</div>;
      },
    },
    {
      key: "created_by",
      title: (
        <div className="flex items-center justify-center w-full gap-x-1 text-xs ">
          <GoPerson className="w-4 h-4  text-yellow-500" />
          CREATED BY
        </div>
      ),

      dataIndex: "created_by",
      editable: false,
      render: (text, params) => {
        return <div className="w-full h-full  flex items-center gap-x-1 ml-10 ">{params.created_by}</div>;
      },
    },

    {
      key: "operations",
      filterable: false,
      sortable: false,
      dataIndex: "operations",
      title: "OPERATIONS",
      render: (text, params) => {
        return (
          <div className="flex items-center justify-start gap-x-5">
            {permission.map((item) => {
              if (item.name === "accounting.view_batch" && item.value === true) {
                return (
                  <Link
                    key={item.id}
                    to={`./preview-batch/${params.id}/`}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <AiOutlineEye className="w-5 h-5 text-slate-900 " />
                  </Link>
                );
              } else if (item.name === "accounting.delete_batch" && item.value === true) {
                return (
                  <button
                    onClick={() => {
                      setId(params.id);
                      setConfirm(true);
                    }}
                    className="flex items-center gap-x-1 hover:scale-110 active:scale-95 duration-300 transition-all"
                  >
                    <AiFillDelete className="w-5 h-5 text-red-800" />
                  </button>
                );
              }
            })}
          </div>
        );
      },
    },
  ];

  function comparator([para]) {
    if (para) {
      if (para.sort === "asc") {
        //    dispatch(sortBookingListAction(token,params.key))
        navigate(`/dashboard/pos-(visa)/batch-list/${params.page}/${para.key}`);
      } else {
        //  dispatch(sortBookingListAction(token,"-"+params.key))
        navigate(`/dashboard/pos-(visa)/batch-list/${params.page}/-${para.key}`);
      }
    } else {
      // dispatch(sortBookingListAction(token,params.key))
      navigate(-1);
    }
  }

  function handlePageChange(para) {
    navigate(`/dashboard/pos-(visa)/batch-list/${para.page + 1}/${params.sorting}`);
    setIsLoading(false);
  }

  const handleDelete = (id) => {
    dispatch(
      deleteBatchAction(token, id, (result) => {
        if (result.status === 204) {
          lightToast.success("This Batch was completed Deleted");
          dispatch(getAllBatchAction(token));
        }
      }),
    );
  };
  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.view_batch", "accounting.change_batch", "accounting.delete_batch"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full formPage-wrap">
      {confirm && (
        <div className="relative z-50 ">
          <div className="absolute flex flex-col items-center justify-center gap-y-2 w-[300px] h-36 bg-white border-gray-300 border top-96 left-[450px] bg-center rounded-md ">
            <p className="text-2xl font-light">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleDelete(id);
                }}
                className="bg-green-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-700 rounded-md px-3 py-1 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      {getAllBatch && permissionFlag ? (
        deleteBatchFlag ? (
          <>
            {" "}
            <TopBarProgress />
            <div className="w-full h-full flex items-center justify-center">
              <Loading />
            </div>
          </>
        ) : permissionFlag && permission.some((item) => item.value) ? (
          // <div className="w-full overflow-x-hidden h-screen">
          //   <Box sx={{ height: "100%", width: "100%", backgroundColor: "white" }}>
          //     <DataGrid
          //       rows={rows}
          //       columns={columns}
          //       rowCount={getAllBatch.count}
          //       onSortModelChange={comparator}
          //       columnVisibilityModel={columnVisibility}
          //       onColumnVisibilityModelChange={(visibility) => {
          //         setColumnVisibility(visibility);
          //         localStorage.setItem("grid-visibility-batch-list", JSON.stringify(visibility));
          //       }}
          //       paginationModel={{ page: Number(params.page - 1), pageSize: 10 }}
          //       onPaginationModelChange={handlePageChange}
          //       paginationMode="server"
          //     />
          //   </Box>
          // </div>
          <div className="w-full overflow-auto">
            <ListFilters columns={columns} rows={rows} title="Batch List" />
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <>
          <TopBarProgress />
          <div className="w-full h-full flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default BatchList;
