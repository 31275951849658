import {
  SELECTEDHOTEL,
  SELECTEDDATAFROMSEARCHFORM,
  FLAG,
  ROOMDETIALS,
  ERROR_SEARCH,
  STOREHOTELS,
  MOREROOMS,
  HOTELSTATUS,
  HOTELNAME,
  SELECT_ROOM_FLAG,
  SELECTEDHOTELFILTER,
  MARKUP_CHECK,
  DEFAULT_AREA,
  DEFAULT_HOTEL,
  SINGLEROOMS,
} from "../types/types";

const initialState = {
  selectedHotel: [],
  selectedDataFromForm: [], // this is data which selected from search form in save it for if when use this date can catch them
  roomDetails: "",
  loader: false,
  errorStatus: "",
  storeHotel: null,
  moreRoomsData: [],
  singleRoomsData: [],
  hotelStatus: false,
  hotelNameRoom: "",
  moreRoomFlag: false,
  hotelsFilter: null,
  markupCheck: null,
  defaultAreaSearch: null,
  defaultHotelSearch: null,
};

export const SelectedSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTEDHOTEL:
      return { ...state, selectedHotel: action.payload };
    case HOTELSTATUS:
      return { ...state, hotelStatus: action.payload };
    case SELECTEDDATAFROMSEARCHFORM:
      return { ...state, selectedDataFromForm: action.payload };
    case DEFAULT_AREA:
      return { ...state, defaultAreaSearch: action.payload };
    case DEFAULT_HOTEL:
      return { ...state, defaultHotelSearch: action.payload };
    case MARKUP_CHECK:
      return { ...state, markupCheck: action.payload };
    case ROOMDETIALS:
      return { ...state, roomDetails: action.payload };
    case FLAG:
      return { ...state, loader: action.payload };
    case ERROR_SEARCH:
      return { ...state, errorStatus: action.payload };
    case STOREHOTELS:
      return { ...state, storeHotel: action.payload };
    case MOREROOMS:
      return { ...state, moreRoomsData: action.payload };
    case SINGLEROOMS:
      return { ...state, singleRoomsData: action.payload };
    case SELECT_ROOM_FLAG:
      return { ...state, moreRoomFlag: action.payload };
    case HOTELNAME:
      return { ...state, hotelNameRoom: action.payload };
    case SELECTEDHOTELFILTER:
      return { ...state, hotelsFilter: action.payload };
    default:
      return state;
  }
};
