import React, { useState } from "react";
import { FieldErrorText, FormLabel, FormSection, FormTextInput } from "../../../../customComponents/FormComponents";
import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import Select from "react-select";
import { FaTicketAlt } from "react-icons/fa";
import * as Yup from "yup";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { useMutation, useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import usePermissions from "../../../../customHooks/usePermissions";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import NoPermission from "../../NoPermission/NoPermission";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapterPlugin from "./CustomUploadAdapterPlugin";
import "ckeditor5/ckeditor5.css";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  website: Yup.number().required("Website is required"),
  type: Yup.string().required("Type is required"),
});

function AddNewITTicketForm() {
  const [permissions, permissionFlag, permissionsError] = usePermissions(["it_ticketing_system.add_ticketsupport"]);
  const [editorData, setEditorData] = useState("");

  const navigate = useNavigate();
  const { isPending: isAddingTicket, mutate: addTicket } = useMutation({
    mutationKey: ["add-ticket"],
    mutationFn: (newTicket) => {
      return mutateDataQuery("/it-ticketing-system/api/v1/tickets-support/", "post", newTicket, {
        "Content-Type": "multipart/form-data",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Ticket Added Successfully");
      navigate("/dashboard/tickets-support/tickets?status=open");
    },
    onError: () => {},
  });

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch Error={"Error fetching permissions"} />;
  }

  const cadAddTicket =
    permissions?.find((permission) => permission.name === "it_ticketing_system.add_ticketsupport")?.value === true;

  function handelSubmit(values) {
    const formData = new FormData();

    formData.append("request_name", values.name);
    formData.append("website", values.website);
    formData.append("type_of_request", values.type);
    formData.append("priority", values.priorities ? values.priorities : "medium");
    formData.append("details", editorData);
    formData.append("request_details", editorData);

    values.attachments.forEach((file) => {
      formData.append("attachments", file);
    });

    addTicket(formData);
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  const editorConfig = {
    extraPlugins: [CustomUploadAdapterPlugin],
    language: {
      // The UI will be English.
      ui: "en",
      // But the content will be edited in Arabic.
      content: "ar",
    },
    toolbar: {
      items: [
        "undo",
        "redo",
        "fontfamily",
        "fontsize",
        "|",
        "bold",
        "italic",
        "|",
        "link",
        "uploadImage",
        "|",
        "alignment", // Add the alignment option to the toolbar
      ],
      shouldNotGroupWhenFull: true,
    },
    alignment: {
      options: ["left", "center", "right", "justify"],
    },
  };

  return cadAddTicket ? (
    <Formik
      initialValues={{
        name: "",
        website: null,
        type: null,
        details: "",
        attachments: [],
      }}
      onSubmit={handelSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, setFieldValue }) => {
        return (
          <Form className="formPage-wrap">
            <FormSection title="Add New Ticket">
              <FormTextInput label={"Name"} name="name" placeholder="Enter Ticket Name" Icon={FaTicketAlt} />
              <TicketWebsiteSelectInput />
              <TicketTypeSelectInput />
              <TicketPrioritesSelectInput />
              <CKEditor editor={ClassicEditor} data={editorData} onChange={handleEditorChange} config={editorConfig} />
              {/* <div>
                <div>
                  <h3>Output HTML:</h3>
                  <div>{editorData}</div>
                </div>
              </div> */}
              <div className="w-full">
                <FormLabel label={"Attachments"} htmlFor={"attachments"} />
                <input
                  id="attachments"
                  name="attachments"
                  type="file"
                  multiple
                  onChange={(event) => {
                    const files = event.currentTarget.files;
                    let myFiles = Array.from(files);
                    void setFieldValue("attachments", myFiles);
                  }}
                />
              </div>
            </FormSection>
            <div className="flex mt-4 justify-center items-center">
              <Button
                className="mb-4"
                color="success"
                isProcessing={isAddingTicket}
                type="submit"
                disabled={isAddingTicket}
                processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              >
                Add Ticket
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <NoPermission />
  );
}

function TicketWebsiteSelectInput() {
  const {
    data: websites,
    error: errorFetchingWebsites,
    isLoading,
  } = useQuery({
    queryKey: ["ticket-websites"],
    queryFn: () => fetchDataQuery("/it-ticketing-system/api/v1/website-tickets/"),
  });

  const { values, setValues, errors } = useFormikContext();

  return (
    <>
      <FormLabel label={"Website"} htmlFor={"website"} />
      <Select
        isLoading={isLoading}
        className={`!mt-1.5`}
        menuPlacement={"top"}
        inputId={"Website"}
        name="website"
        placeholder={"Choose Ticket Website"}
        onChange={(value) => {
          void setValues({ ...values, website: value.value });
        }}
        options={websites?.map((website) => {
          return {
            label: website?.name,
            value: website?.id,
          };
        })}
      />
      <ErrorMessage name={"website"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingWebsites && <FieldErrorText message={"Error fetching websites"} />}
    </>
  );
}

function TicketPrioritesSelectInput() {
  const PRIORITY_CHOICES = [
    { label: "urgent", value: "urgent" },
    { label: "high", value: "high" },
    { label: "medium", value: "medium" },
    { label: "low", value: "low" },
  ];

  const { values, setValues, errors, initialValues } = useFormikContext();

  // Ensure the default value "medium" is part of the form's initial values
  const defaultPriority = initialValues.priorities || "medium";

  return (
    <>
      <FormLabel label={"Priorities"} htmlFor={"priorities"} />
      <Select
        // isLoading={isLoading}
        className={`!mt-1.5 capitalize`}
        menuPlacement={"top"}
        inputId={"priorities"}
        name="priorities"
        placeholder={"Choose Ticket Priority"}
        value={
          PRIORITY_CHOICES.find((priority) => priority.value === values.priorities) ||
          PRIORITY_CHOICES.find((priority) => priority.value === defaultPriority)
        }
        onChange={(selectedOption) => {
          setValues({ ...values, priorities: selectedOption.value });
        }}
        options={PRIORITY_CHOICES}
      />
      <ErrorMessage name={"priorities"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {/* {errorFetchingPriorities && <FieldErrorText message={"Error Fetching Tickets Priorities"} />} */}
    </>
  );
}

function TicketTypeSelectInput() {
  const {
    data: types,
    error: errorFetchingTypes,
    isLoading,
  } = useQuery({
    queryKey: ["ticket-types"],
    queryFn: () => fetchDataQuery("/it-ticketing-system/api/v1/type-tickets/"),
  });

  const { values, setValues, errors } = useFormikContext();

  return (
    <>
      <FormLabel label={"Type"} htmlFor={"type"} />
      <Select
        isLoading={isLoading}
        menuPlacement={"top"}
        className={`!mt-1.5`}
        inputId={"type"}
        name="type"
        onChange={(value) => {
          void setValues({ ...values, type: value.value });
        }}
        placeholder={"Choose Ticket Type"}
        options={types?.map((type) => {
          return {
            label: type?.name,
            value: type?.id,
          };
        })}
      />
      <ErrorMessage name={"type"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingTypes && <FieldErrorText message={"Error fetching ticket types"} />}
    </>
  );
}

export default AddNewITTicketForm;
