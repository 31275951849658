import React, { useState, useEffect } from "react";
import "./MultiDynamicInputs.scss";
import { BiMinusCircle } from "react-icons/bi";
import { MdAddCircleOutline } from "react-icons/md";

export default function MultiDynamicInputs({ heading, inputsList, inputsNum, className = "" }) {
  // to allow setting the number of inputs upon calling the component
  let inputStateValue = [];
  for (let i = 0; i < inputsNum; i++) {
    inputStateValue.push(1);
  }

  useEffect(() => {
    // Create a new array with the specified length
    const newInputArray = Array.from({ length: inputsNum }, () => 1);
    setInput(newInputArray);
  }, [inputsNum]);

  let [input, setInput] = useState(inputStateValue);

  function addInput() {
    if (input.length < 100) {
      setInput([...input, 1]);
    }
  }

  function removeInput() {
    if (input.length >= 0) {
      let newArr = input.slice();
      newArr.pop();
      setInput(newArr);
    }
  }

  return (
    <div className="w-full flex flex-col gap-y-1  ">
      <div className="w-full flex items-center justify-start gap-x-3 ">
        <p className="text-slate-800 font-semibold">{heading}</p>
        <div className="flex items-center gap-x-1 ">
          <button
            type="button"
            onClick={addInput}
            className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800 "
          >
            <MdAddCircleOutline className="w-5 h-5 " />
          </button>
          <button
            type="button"
            onClick={removeInput}
            className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800 "
          >
            <BiMinusCircle className="w-5 h-5" />
          </button>
        </div>
      </div>
      {input.map((elem, index) => {
        return (
          <div
            className={`w-full flex items-center justify-between border rounded-md py-3 px-10 ${className}`}
            key={index}
          >
            {inputsList.map((input) => input)}
          </div>
        );
      })}
    </div>
  );
}
