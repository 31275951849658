import React, { useEffect, useState } from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { PiHandshakeLight } from "react-icons/pi";
import { VscAdd } from "react-icons/vsc";
import { IoIosList } from "react-icons/io";
import { useNavigate } from "react-router";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { Link } from "react-router-dom";

export default function WhitelistIP() {
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const permissionData = {
    permission_codes: ["auth_users.add_whitelistedip", "auth_users.view_whitelistedip"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        <div className="w-full ">
          <div className="w-full flex justify-center  ">
            <h2 className="header-h2">
              <RiKeyboardLine className="w-5 h-5" />
              Whitelist IP
            </h2>
          </div>

          <div className="w-full grid grid-cols-1 form-wrap sm:grid-cols-2">
            {permission.map((item) => {
              if (item.name === "auth_users.add_whitelistedip" && item.value === true) {
                return (
                  <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                    <Link
                      to={"./add-whitelist"}
                      // onClick={() => navigate("./add-whitelist")}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center">
                        <div className="relative flex flex-col">
                          <PiHandshakeLight className="w-12 h-12" />
                          <VscAdd className="absolute top-10 left-10 w-4 h-4" />
                        </div>
                      </div>
                    </Link>
                    <p className="text-lg font-semibold">Add Whitelist IP</p>
                  </div>
                );
              } else if (item.name === "auth_users.view_whitelistedip" && item.value === true) {
                return (
                  <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                    <Link
                      to={"./whitelist-ip-list"}
                      // onClick={() => navigate("./whitelist-ip-list")}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <IoIosList className="w-12 h-12" />
                      </div>
                    </Link>
                    <h2 className="text-lg font-semibold">Whitelisted IPs List</h2>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
