export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function splitAndCapitalize(str, splitBy, joinBy = " ") {
  return str
    .split(splitBy)
    .map((s) => capitalize(s))
    .join(joinBy);
}
