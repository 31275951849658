import "react-phone-number-input/style.css";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import AgentForm from "./AgentForm";
import * as Yup from "yup";
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";
import usePermissions from "../../../../customHooks/usePermissions";
import Loading from "../../Loading/Loading";
import NoPermission from "../../NoPermission/NoPermission";
import Errorfetch from "../../Errorfetch/Errorfetch";
import { useNavigate } from "react-router";

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    if (!list) return true; // If the list is empty, skip the test

    const set = new Set(list.map(mapper));
    const isUnique = set.size === list.length;

    if (!isUnique) {
      return this.createError({
        path: this.path,
        message: message,
      });
    }

    return true;
  });
});

const AddAgentValidationSchema = Yup.object().shape({
  // name: Yup.string()
  //   .required("Name is required")
  //   .test("is-three-words", "The name must consist of at least three words", (value) => {
  //     if (value) {
  //       // Split the string by spaces and filter out empty strings
  //       const words = value.split(" ").filter((word) => word.trim().length > 0);
  //       return words.length >= 3;
  //     }
  //     return false;
  //   }),
  firstName: Yup.string().notRequired(),
  lastName: Yup.string().notRequired(),
  username: Yup.string()
    .required("Username is required")
    .test("no-spaces", "Username must not contain spaces", (value) => {
      // Ensure the value is defined and doesn't contain any spaces
      return value && !/\s/.test(value);
    }),
  nickname: Yup.string().required("Nickname is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  id: Yup.string().required("ID Number is required"),
  mobileNumbers: Yup.array()
    .of(
      Yup.object().shape({
        num: Yup.string()
          .trim()
          .matches(/^\+?[0-9]{5,}$/, "Please add a valid phone number with at least 5 digits")
          .min(5, "Please add at least 5 numbers")
          .required("This field is required"), // these constraints take precedence
        whatsapp: Yup.boolean().default(false), // these constraints take precedence
      }),
    )
    .required("Must Add at least one mobile number")
    .unique("Mobile numbers must be unique", (item) => item.num),
  emailAddresses: Yup.array()
    .of(Yup.string().email("Invalid Email"))
    .required("Must Add at least one email")
    .unique("Emails must be unique"),
  market: Yup.array()
    .of(Yup.number())
    .min(1, "Please select at least one market")
    .required("Please select at least one market"),
  markup: Yup.number().required("Please select a markup"),
  groups: Yup.array()
    .of(Yup.number())
    .min(1, "Please select at least one group")
    .required("Please select at least one group"),
  notificationPreferences: Yup.array()
    .of(Yup.string())
    .min(1, "Please select a notification preference")
    .required("Please select a notification preference"),
  paymentMethod: Yup.string().required("Please select a payment method"),
  creditLimit: Yup.number().required("Please enter a credit limit").min(1, "Credit limit must be greater than 0"),
  creditLimitCurrency: Yup.string().required("Please select a currency"),
});

const AddAgentForm = () => {
  const navigate = useNavigate();

  const [image, setImage] = useState({
    src: "",
    file: null,
  });

  const { isPending, mutate: createAgent } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery("/auth/api/v1/user-agent/", "post", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    mutationKey: "createAgent",
    networkMode: "always",
    retry: false,
    onSuccess: (agent) => {
      toast.success("Agent Created Successfully");
      navigate(`/dashboard/permissions/search-permission/create/${agent.id}`);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to create Agent");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to create Agent");
    },
  });

  function handleSubmit(values, helpers) {
    const formData = new FormData();

    // formData.append("profile_agent[agent_name]", values.name);

    formData.append("first_name", values.firstName);

    formData.append("last_name", values.lastName);

    formData.append("username", values.username);

    formData.append("email", values.email);

    formData.append("password", values.password);

    formData.append("groups", JSON.stringify(values.groups));

    formData.append("profile_agent[nickname]", values.nickname);

    formData.append("profile_agent[id_number]", values.id);

    formData.append("profile_agent[notes]", values.notes);

    const emailObject = values.emailAddresses.reduce((acc, email, index) => {
      acc[index + 1] = email;
      return acc;
    }, {});

    const emailJson = JSON.stringify(emailObject);

    formData.append("profile_agent[email_address]", emailJson);

    const mobileObject = values.mobileNumbers.reduce((acc, number, index) => {
      acc[index + 1] = number.num;
      return acc;
    }, {});

    const mobileJson = JSON.stringify(mobileObject);
    formData.append("profile_agent[mobile_numbers]", mobileJson);

    const whatsappArray = values.mobileNumbers.filter((number) => number.whatsapp);

    const whatsappObject = whatsappArray.reduce((acc, number, index) => {
      acc[index + 1] = number.num;
      return acc;
    }, {});

    const whatsappJson = JSON.stringify(whatsappObject);
    formData.append("profile_agent[whats_app_numbers]", whatsappJson);

    if (image.file) formData.append("profile_agent[avatar]", image.file);

    formData.append("profile_agent[country]", values.country);

    if (values.city !== "") {
      formData.append("profile_agent[city]", values.city);
    }

    const user = jwtDecode(cookie.load("access_token"));

    formData.append("profile_agent[markup]", values.markup);
    formData.append("profile_agent[market]", JSON.stringify(values.market));

    formData.append("profile_agent[created_by]", user.user_id);

    formData.append("is_active", values.active);

    formData.append("profile_agent[notifications_preferred]", JSON.stringify(values.notificationPreferences));

    formData.append("profile_agent[payment_method]", values.paymentMethod);

    formData.append("credit_info[credit_limit]", values.creditLimit);

    formData.append("credit_info[currency]", values.creditLimitCurrency);

    formData.append("profile_agent[accounting_department_note]", values.accountingNotes);

    createAgent(formData);
  }

  const [permissions, permissionFlag, permissionsError] = usePermissions(["auth_users.add_profile_agent"]);

  if (!permissionFlag) return <Loading />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const isAddAgentAllowed =
    permissions?.find((permission) => permission.name === "auth_users.add_profile_agent")?.value === true;

  return (
    <>
      {isAddAgentAllowed ? (
        <AgentForm
          submitButtonTitle={"Add Agent and Create Search Permission"}
          validationSchema={AddAgentValidationSchema}
          image={image}
          setImage={setImage}
          title={"Add Agent"}
          isPending={isPending}
          onSubmit={handleSubmit}
          initialValues={{
            // name: "",
            firstName: "",
            lastName: "",
            username: "",
            nickname: "",
            email: "",
            password: "",
            id: "",
            notes: "",
            notificationPreferences: ["email"],
            mobileNumbers: [
              {
                num: "+20",
                whatsapp: false,
              },
            ],
            emailAddresses: [" "],
            market: [],
            markup: null,
            groups: [],
            active: true,
            whitelist: true,
            city: null,
            cityName: null,
            country: 194,
            countryName: "Saudi Arabia",
            paymentMethod: "cash",
            creditLimit: 0,
            creditLimitCurrency: "",
            accountingNotes: "",
          }}
        />
      ) : (
        <NoPermission />
      )}
    </>
  );
};

export default AddAgentForm;
