import React from "react";
import { Alert, Button, Modal } from "flowbite-react";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { FieldErrorText, FormLabel } from "../../../customComponents/FormComponents";
import Select from "react-select";
import { toast } from "react-toastify";
import { HiInformationCircle } from "react-icons/hi";
import * as Yup from "yup";
import { MdDelete } from "react-icons/md";

const validationSchema = Yup.object().shape({
  max_adults: Yup.number().required("Max adults is required"),
  children_room_package_occupancy: Yup.array().of(
    Yup.object().shape({
      ordering_child: Yup.number().required("Child order is required"),
      max_age: Yup.number().required("Max age is required"),
    }),
  ),
});

function PackageModal({ initialValues, onSubmit, openModal, roomData, isPending, setOpenModal }) {
  const { max_adults: maxAdults, max_children: maxChildren, max_occupancy: maxOccupancy } = roomData;

  const handleMaxAdultsChange = (selectedOption, values, setFieldValue) => {
    const maxAdults = selectedOption.value;
    if (maxAdults > maxOccupancy) {
      toast.error("Max adults can't be more than max occupancy");
      return setFieldValue("max_adults", null);
    }

    const allChildOrdering = values.children_room_package_occupancy
      .map((child) => child.ordering_child)
      .sort((a, b) => a - b);
    const maxChildOrder = allChildOrdering[allChildOrdering.length - 1];

    if (maxChildOrder + maxAdults > maxOccupancy) {
      toast.error("Total occupancy can't be more than max occupancy");
      return setFieldValue("max_adults", null);
    }

    setFieldValue("max_adults", selectedOption.value);
  };

  const handleChildOrderChange = (selectedOption, index, values, setFieldValue) => {
    const childOrder = selectedOption.value;
    const allOrderingChild = values.children_room_package_occupancy.map((child) => child.ordering_child);

    if (childOrder !== 1 && !allOrderingChild.includes(childOrder - 1)) {
      toast.error("You must add the previous child order first");
      return;
    }

    if (allOrderingChild.includes(childOrder)) {
      toast.error("Child order already exists");
      return;
    }

    if (childOrder > maxOccupancy || childOrder + values.max_adults > maxOccupancy) {
      toast.error("Total occupancy can't be more than max occupancy");
      return;
    }

    setFieldValue(`children_room_package_occupancy[${index}].ordering_child`, childOrder);
  };

  const handleMaxAgeChange = (selectedOption, index, values, setFieldValue) => {
    const newMaxAge = selectedOption.value;

    const previousChildMaxAge = values.children_room_package_occupancy[index - 1]?.max_age;
    const nextChildMaxAge = values.children_room_package_occupancy[index + 1]?.max_age;

    // Check if the new max age is less than the previous child's max age
    if (previousChildMaxAge !== undefined && newMaxAge < previousChildMaxAge) {
      toast.error("Max age must be greater than the previous child's max age");
      return;
    }

    // Check if the new max age is greater than the next child's max age
    if (nextChildMaxAge !== undefined && newMaxAge > nextChildMaxAge) {
      toast.error("Max age must be less than the next child's max age");
      return;
    }

    // If validation passes, update the max age
    setFieldValue(`children_room_package_occupancy[${index}].max_age`, newMaxAge);
  };

  const handleAddChildChange = (values, maxChildren, maxAdults, maxOccupancy, push) => {
    if (!maxChildren) {
      toast.error("Please add max Children in room first");
      return;
    }

    if (!values.max_adults) {
      toast.error("Please add max adults first");
      return;
    }

    if (
      values.children_room_package_occupancy.length &&
      (!values.children_room_package_occupancy.at(-1).ordering_child ||
        values.children_room_package_occupancy.at(-1).max_age === "")
    ) {
      toast.error("Please add child order and max age first");
      return;
    }

    if (Number(values.children_room_package_occupancy.at(-1)?.ordering_child) + values.max_adults >= maxOccupancy) {
      toast.error("Total occupancy can't be more than max occupancy");
      return;
    }

    push({
      ordering_child: "",
      max_age: "",
    });
  };

  const modalTheme = {
    body: { base: "flex-1 p-6", popup: "pt-0" },
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex  flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <Formik validationSchema={validationSchema} key={openModal} initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, handleReset }) => (
        <Modal
          theme={modalTheme}
          show={openModal}
          onClose={() => {
            setOpenModal(false);
            handleReset();
          }}
        >
          <Form>
            <Modal.Header>Add new package</Modal.Header>
            <Modal.Body>
              <p className="text-center font-bold">
                Max Occupancy: <span className="font-normal">{maxOccupancy}</span>
              </p>
              {maxAdults ? (
                <>
                  <div>
                    <FormLabel label={"Max Adults"} htmlFor={"max_adults"} />
                    <Select
                      name={"max_adults"}
                      id={"max_adults"}
                      value={{ label: values.max_adults, value: values.max_adults }}
                      options={Array.from({ length: maxAdults }, (_, i) => ({ label: i + 1, value: i + 1 }))}
                      onChange={(selectedOption) => handleMaxAdultsChange(selectedOption, values, setFieldValue)}
                    />
                    <ErrorMessage name={"max_adults"}>{(msg) => <FieldErrorText message={msg} />}</ErrorMessage>
                  </div>
                  <FieldArray name={"children_room_package_occupancy"}>
                    {({ push, remove }) => (
                      <div>
                        {values.children_room_package_occupancy.map((value, index) => (
                          <div key={index} className="flex gap-12 items-center justify-between w-full mt-4 space-y-4">
                            <div className="grow">
                              <FormLabel
                                label={"Child Order"}
                                htmlFor={`children_room_package_occupancy[${index}].ordering_child`}
                              />
                              <Select
                                required
                                name={`children_room_package_occupancy[${index}].ordering_child`}
                                id={`children_room_package_occupancy[${index}].ordering_child`}
                                value={{ label: value.ordering_child, value: value.ordering_child }}
                                options={Array.from({ length: maxChildren }, (_, i) => ({
                                  label: i + 1,
                                  value: i + 1,
                                }))}
                                onChange={(selectedOption) =>
                                  handleChildOrderChange(selectedOption, index, values, setFieldValue)
                                }
                              />
                              <ErrorMessage name={`children_room_package_occupancy[${index}].ordering_child`}>
                                {(msg) => <FieldErrorText message={msg} />}
                              </ErrorMessage>
                            </div>
                            <div className="grow !m-0">
                              <FormLabel
                                label={"Max Age"}
                                htmlFor={`children_room_package_occupancy[${index}].max_age`}
                              />
                              <Select
                                isDisabled={!value.ordering_child}
                                required
                                name={`children_room_package_occupancy[${index}].max_age`}
                                id={`children_room_package_occupancy[${index}].max_age`}
                                value={{ label: value.max_age, value: value.max_age }}
                                options={Array.from({ length: 12 }, (_, i) => ({ label: i, value: i }))}
                                onChange={(selectedOption) =>
                                  handleMaxAgeChange(selectedOption, index, values, setFieldValue)
                                }
                              />
                              <ErrorMessage name={`children_room_package_occupancy[${index}].max_age`}>
                                {(msg) => <FieldErrorText message={msg} />}
                              </ErrorMessage>
                            </div>
                            <button
                              onClick={() => remove(index)}
                              className="w-8 h-8 rounded-full bg-red-800 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800"
                            >
                              <MdDelete />
                            </button>
                          </div>
                        ))}
                        <div className="w-full flex items-center justify-center mt-4">
                          <Button
                            color="success"
                            onClick={() => handleAddChildChange(values, maxChildren, maxAdults, maxOccupancy, push)}
                          >
                            Add Child
                          </Button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </>
              ) : (
                <Alert className="font-medium" color="failure" icon={HiInformationCircle}>
                  Please add max adults to the room first
                </Alert>
              )}
            </Modal.Body>
            {Boolean(maxAdults) && (
              <Modal.Footer>
                <Button disabled={isPending} isProcessing={isPending} type="submit">
                  Save
                </Button>
                <Button
                  color="gray"
                  onClick={() => {
                    handleReset();
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default PackageModal;
