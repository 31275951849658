import { REFUND_FLAG  , ALL_REFUND , DELETE_REFUND_FLAG , UPDATE_REFUND_FLAG , GET_REFUND_ID} from "../types/types";

const initialState = {
 refundFlag : false,
 allRefundData:null,
 deleteRefundFlag:false,
 getRefundId:null,
 updateRefundFlag:false,
}


export const refundReducer =  (state = initialState , action) => {
    switch(action.type)
    {
        case REFUND_FLAG : 
             return{...state , refundFlag : action.payload}
        case ALL_REFUND : 
             return{...state , allRefundData : action.payload}
        case DELETE_REFUND_FLAG : 
             return{...state , deleteRefundFlag : action.payload}
        case GET_REFUND_ID : 
             return{...state , getRefundId : action.payload}
        case UPDATE_REFUND_FLAG : 
             return{...state , updateRefundFlag : action.payload}
        default:
             return state;
    }
}
