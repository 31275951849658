import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteHotelInfo, getAllHotelsInfo } from "../../../redux/actions/HotelInfo";
import { useNavigate, useParams } from "react-router";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import ListFilters from "../../../utils/ListFilters";

export default function HotellMainInfoList() {
  const [listPageSize, setListPageSize] = useState(20);

  const { page, sorting } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.allHotelsInfo);
  const currentPage = Number(page) || 1;
  const totalPages = Math.ceil(list?.data.length / listPageSize);

  useEffect(() => {
    dispatch(getAllHotelsInfo());
  }, [page, sorting]);
  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "hotelName",
      title: "Hotel Name",
      dataIndex: "hotelName",
    },
    {
      key: "receptionEmail",
      title: "Reception Email",
      dataIndex: "receptionEmail",
    },
    {
      key: "reservationEmail",
      title: "Reservation Email",
      dataIndex: "reservationEmail",
    },
    {
      key: "telephoneNumbers",
      title: "Telephone Numbers",
      dataIndex: "telephoneNumbers",
    },
    {
      key: "dutyManagerNumber",
      title: "Duty Manager Number",
      dataIndex: "dutyManagerNumber",
    },
    {
      key: "actions",
      filterable: false,
      sortable: false,
      title: "Actions",
      dataIndex: "actions",
      render: (text, params) => {
        return (
          <div className="w-full flex items-center gap-x-1">
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" onClick={() => {}}>
              <title>eye-outline</title>
              <path d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
            </svg> */}
            {permission.map((item) => {
              if (item.name === "hotels_management.change_mainhotelinfo" && item.value === true) {
                return (
                  <Link to={`/dashboard/edit-hotel-main-info/${params.id}`}>
                    <FiEdit
                      className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
                      // onClick={() => navigate(`/dashboard/edit-hotel-main-info/${params.id}`)}
                    />
                  </Link>
                );
              } else if (item.name === "hotels_management.delete_mainhotelinfo" && item.value === true) {
                return (
                  <AiFillDelete
                    className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
                    onClick={() => {
                      dispatch(deleteHotelInfo(params.id, () => dispatch(getAllHotelsInfo())));
                    }}
                  />
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  const rows = list.data
    ? list.data.map((obj) => {
        return {
          id: obj.id,
          hotelName: obj.hotel.name,
          receptionEmail: obj.reception_emails[1],
          reservationEmail: obj.reservation_emails[1],
          telephoneNumbers: obj.telephone_numbers[1],
          dutyManagerNumber: obj.duty_manager_numbers[1],
        };
      })
    : [];

  function handlePageChange(params, size) {
    setListPageSize(size);
    navigate(`/dashboard/hotel-main-info-list/${sorting}/${params.page + 1}`);
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "hotels_management.change_mainhotelinfo",
      "hotels_management.view_mainhotelinfo",
      "hotels_management.delete_mainhotelinfo",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "hotels_management.view_mainhotelinfo" && item.value === true) {
            return (
              <div>
                {list.loading ? (
                  <div className="flex items-center justify-center w-full h-screen">
                    <Loading /> <TopBarProgress />
                  </div>
                ) : (
                  <div className="w-full overflow-x-auto ">
                    <ListFilters
                      columns={columns}
                      rows={rows}
                      title="Hotel Main Info List"
                      handlePagination={handlePageChange}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      listPageSize={listPageSize}
                    />
                  </div>
                )}
              </div>
            );
          }
        })
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
