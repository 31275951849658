import React, { useEffect, useState } from "react";

const NoPermission = () => {
  return (
    <div className="w-full  flex flex-col items-center justify-center h-[80%]  ">
      <img
        src="/images/pngtree-login-access-denied-vector-illustration-picture-image_8480029.png"
        className="w-96  opacity-50  "
      />
    </div>
  );
};

export default NoPermission;
