import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { FieldErrorText, FormLabel } from "../../../customComponents/FormComponents";
import Select from "react-select";
import React from "react";
import { useFormikContext } from "formik";

function HotelSelectInput() {
  const { values, setValues } = useFormikContext();

  const {
    isLoading,
    data: hotels,
    error,
  } = useQuery({
    queryKey: ["hotels", values.city],
    queryFn: () => fetchDataQuery("/search/api/hotels-by-city/?city=" + values.city),
    enabled: !!values.city,
  });

  return (
    <div>
      <FormLabel label={"Hotel"} htmlFor={"hotel"} />
      <Select
        key={values.city}
        name={"hotel"}
        id={"hotel"}
        isLoading={isLoading}
        onChange={(newValue, actionMeta) => {
          setValues({
            city: values.city,
            hotel: newValue.value,
            room: "",
            roomData: "",
          });
        }}
        isDisabled={isLoading || !values.city}
        loadingMessage={() => "Getting Hotels"}
        placeholder="Choose Hotel"
        defaultValue={values.hotel}
        options={hotels?.map((hotel) => {
          return {
            value: hotel.id,
            label: hotel.name,
          };
        })}
      />
      {error && <FieldErrorText message={"Error fetching hotels"} />}
    </div>
  );
}

export default HotelSelectInput;
