import { GET_CURRENCY_TYPES, GET_MARKET_TYPES, GET_MEALS_INCLUDED, GET_OFFER_TYPES } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function requestMarketTypesSuccessfull(data) {
  return {
    type: GET_MARKET_TYPES,
    payload: data,
  };
}

function requestOffecrTypesSuccessfull(data) {
  return {
    type: GET_OFFER_TYPES,
    payload: data,
  };
}

function requestMealsIncludedSuccessfull(data) {
  return {
    type: GET_MEALS_INCLUDED,
    payload: data,
  };
}

function requestCurrencyTypesSuccessfull(data) {
  return {
    type: GET_CURRENCY_TYPES,
    payload: data,
  };
}

export function requestMarketTypes(callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/site-settings/api/market");
      dispatch(requestMarketTypesSuccessfull(response.data));
      callback(response);
    } catch (error) {}
  };
}

export function requestOfferTypes(callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/contracts/api/offertype/");
      dispatch(requestOffecrTypesSuccessfull(response.data));
      if (callback) {
        callback(response.data);
      }
    } catch (error) {}
  };
}

export function requestMealsIncluded() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/contracts/api/meals/");
      dispatch(requestMealsIncludedSuccessfull(response.data));
    } catch (error) {}
  };
}

export function requestCurrencyTypes() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/contracts/api/currency-types/");
      dispatch(requestCurrencyTypesSuccessfull(response.data));
    } catch (error) {}
  };
}

export function addContract(data, callback, errorCallBack) {
  return async function () {
    try {
      const response = await api_token(token).post("/contracts/api/", data, {
        headers: {
          "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });
      if (response) {
        callback(response.data.id);
      }
    } catch (error) {
      errorCallBack(error);
    }
  };
}
