import api_token from "../../api/UserApi";

export const addSmtpAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).post(`booking/api/v1/smtp-settings/`, data);
      callback(response);
    } catch (error) {
      throw error;
    }
  };
};

export const getSmtpAction = (token, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/smtp-settings/`);
      callback(response);
    } catch (error) {
      throw error;
    }
  };
};

export const updateSmtpAction = (token, data, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`booking/api/v1/smtp-settings/${id}/`, data);
      callback(response);
    } catch (error) {
      throw error;
    }
  };
};

export const deleteSmtpAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`booking/api/v1/smtp-settings/${id}/`);
      callback(response);
    } catch (error) {
      throw error;
    }
  };
};

export const getAllWelcomeMessages = async (token) => {
  const response = await api_token(token).get(`booking/api/v1/welcome-messages/`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error getting welcome messages");
  }
};

export const createWelcomeMessage = async (token, data) => {
  const response = await api_token(token).post(`booking/api/v1/welcome-messages/`, data);
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error("Error creating welcome message");
  }
};

export const updateWelcomeMessage = async (token, data, messageId) => {
  const response = await api_token(token).patch(`booking/api/v1/welcome-messages/${messageId}/`, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error updating welcome message");
  }
};

export const deleteWelcomeMessage = async (token, messageId) => {
  const response = await api_token(token).delete(`booking/api/v1/welcome-messages/${messageId}/`);
  if (response.status === 204) {
    return response.data;
  } else {
    throw new Error("Error deleting welcome message");
  }
};

// Signature Message
export const getAllSignatureMessages = async (token) => {
  const response = await api_token(token).get(`booking/api/v1/signatures/`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error getting welcome messages");
  }
};

export const createSignatureMessage = async (token, data) => {
  const response = await api_token(token).post(`booking/api/v1/signatures/`, data);
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error("Error creating welcome message");
  }
};

export const updateSignatureMessage = async (token, data, messageId) => {
  const response = await api_token(token).patch(`booking/api/v1/signatures/${messageId}/`, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error updating welcome message");
  }
};

export const deleteSignatureMessage = async (token, messageId) => {
  const response = await api_token(token).delete(`booking/api/v1/signatures/${messageId}/`);
  if (response.status === 204) {
    return response.data;
  } else {
    throw new Error("Error deleting welcome message");
  }
};

// Signature Message
