import React from "react";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading";
import { Accordion } from "flowbite-react";
import { FaChildren, FaPerson } from "react-icons/fa6";
import Errorfetch from "../Errorfetch/Errorfetch";
import DeletePackageModal from "./DeletePackageModal";
import EditPackageModal from "./EditPackageModal";

function PackagesList({ roomData, isDeletingPackageAllowed, isEditingPackageAllowed }) {
  const {
    data: packages,
    isPending,
    error,
  } = useQuery({
    queryKey: ["room_packages", roomData.id],
    queryFn: () => fetchDataQuery(`/en/rooms/api/v1/room-package-occupancy/?room=${roomData.id}`),
  });

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Errorfetch Error={"Error fetching packages"} />;
  }

  if (!packages || packages.length === 0) {
    return (
      <div className="flex items-center justify-center h-56">
        <p className="font-bold"> No packages added to this room </p>
      </div>
    );
  }

  return (
    <Accordion className="mt-4">
      {packages?.map((roomPackage) => {
        return (
          <Accordion.Panel>
            <Accordion.Title>
              <div className="flex gap-2 items-center">
                <div className="flex gap-1 items-center">
                  <span> {roomPackage.max_adults} </span>
                  <FaPerson />
                </div>

                <div className="flex gap-1 items-center">
                  <span>
                    {" "}
                    {roomPackage.children_room_package_occupancy.length > 0
                      ? roomPackage.children_room_package_occupancy.length
                      : 0}{" "}
                  </span>
                  <FaChildren />
                </div>
              </div>
            </Accordion.Title>
            <Accordion.Content>
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex gap-1">
                    <p className="font-bold"> Max Adults: </p>
                    <span> {roomPackage.max_adults} </span>
                  </div>
                  {roomPackage.children_room_package_occupancy.length > 0 ? (
                    roomPackage.children_room_package_occupancy.map((child) => {
                      return (
                        <div className="flex items-center gap-2 ">
                          <div className="flex items-center gap-1 ">
                            <p className="font-bold"> Child Order: </p>
                            <span> {child.ordering_child} </span>
                          </div>
                          <div className="flex items-center gap-1 ">
                            <p className="font-bold"> Max Age: </p>
                            <span> {child.max_age} </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p> No children added to this packages </p>
                  )}
                </div>
                <div className="flex items-center gap-1">
                  {isDeletingPackageAllowed && <DeletePackageModal packageID={roomPackage.id} roomID={roomData.id} />}
                  {isEditingPackageAllowed && <EditPackageModal roomData={roomData} roomPackage={roomPackage} />}
                </div>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
        );
      })}
    </Accordion>
  );
}

export default PackagesList;
