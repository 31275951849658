import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { CiLock, CiUnlock } from "react-icons/ci";

function ChangeNoteStatus({ id, status }) {
  const queryClient = useQueryClient();

  const { isPending, mutate: changeNoteStatus } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/notes-management/api/v1/notes/${id}/`, "patch", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: "editNote",
    networkMode: "always",
    retry: false,
    onSuccess: (agent) => {
      toast.success(`Note ${status === "open" ? "closed" : "reopened"} Successfully`);
    },
  });

  function handleChangeNoteStatus() {
    changeNoteStatus(
      { status: status === "open" ? "closed" : "open" },
      {
        onSuccess: () => {
          void queryClient.refetchQueries({
            queryKey: ["notes"],
          });
        },
      },
    );
  }

  return (
    <button disabled={isPending} onClick={handleChangeNoteStatus}>
      {status === "open" ? (
        <CiUnlock className="w-6 h-6 text-green-900 hover:text-red-900 transition " />
      ) : (
        <CiLock className="w-6 h-6 text-red-900 hover:text-green-900 transition " />
      )}
    </button>
  );
}

export default ChangeNoteStatus;
