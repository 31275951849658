import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import PackageModal from "./PackageModal";
import { MdModeEditOutline } from "react-icons/md";

function EditPackageModal({ roomData, roomPackage }) {
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);

  const { isPending, mutate: editNewPackage } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/en/rooms/api/v1/room-package-occupancy/${roomPackage.id}/`, "patch", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: ["edit-room-package"],
    onSuccess: () => {
      setOpenModal(false);
      toast.success("Package Edited successfully");
      queryClient.refetchQueries({
        queryKey: ["room_packages", roomData.id],
      });
    },
  });

  function handleSubmit(values, helpers) {
    const data = {
      room: roomData.id,
      ...values,
    };

    editNewPackage(data, {
      onSuccess: helpers.resetForm,
    });
  }

  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        className="w-8 h-8 rounded-full dark:bg-gray-100  bg-gray-800 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-black"
      >
        <MdModeEditOutline />
      </button>
      <PackageModal
        initialValues={{
          max_adults: roomPackage?.max_adults,
          children_room_package_occupancy: roomPackage?.children_room_package_occupancy,
        }}
        openModal={openModal}
        onSubmit={handleSubmit}
        roomData={roomData}
        isPending={isPending}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default EditPackageModal;
