import fetchDataQuery from "../../../react_query/fetchDataQuery";
import React, { useEffect, useMemo, useState } from "react";
import { MdOutlineHotelClass } from "react-icons/md";
import { useMutation, useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { useFormik } from "formik";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const fetchCities = () => fetchDataQuery("/search/api/v1/get-cities/");
const fetchAreas = (city) => fetchDataQuery(`/search/api/load-areas/?city_name=${city}`);
const fetchHotels = (city, area) =>
  fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]&areas=[${area ? area : 0}]&preferred=true`);
const fetchHotelsAll = (city, area) =>
  fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]&areas=[${area ? area : 0}]`);

const AddPreferedHotels = () => {
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [chosenHotelVal, setChosenHotelsVal] = useState([]);
  const [chosenAllHotelVal, setAllChosenHotels] = useState([]);
  const navigate = useNavigate();

  const { mutate: preferedHotels, isLoading } = useMutation({
    mutationFn: (data) => mutateDataQuery("/en/hotels/api/v1/update-preferred-hotels/", "post", data),
    mutationKey: "preferedHotels",
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Prefered Hotels Created Successfully");
      navigate(-1);
    },
    onError: (error) => {
      const errorMessage =
        error.response?.status === 400
          ? error.response.data.map((err) => err.message).join(", ")
          : error.message || "Failed to create prefered Hotels";
      toast.error(errorMessage);
    },
  });

  const {
    data: cities,
    isLoading: loadingCities,
    error: citiesError,
  } = useQuery({
    queryKey: "cities",
    queryFn: fetchCities,
  });

  const chosenCityVal = chosenCity?.value;

  const {
    data: areas,
    isLoading: loadingAreas,
    error: areasError,
    refetch: refetchAreas,
  } = useQuery({
    queryKey: ["areas", chosenCityVal],
    queryFn: () => fetchAreas(chosenCityVal),
    enabled: !!chosenCityVal && chosenCityVal !== "All",
  });

  const {
    data: hotels,
    isLoading: loadingHotels,
    error: hotelsError,
    refetch: refetchHotels,
  } = useQuery({
    queryKey: ["hotels", chosenCityVal, chosenArea],
    queryFn: () => {
      const areaValue = chosenArea?.value === "All" ? areas?.map((area) => area.id) : chosenArea.value;
      return fetchHotels(chosenCityVal, areaValue);
    },
    enabled: !!chosenCityVal && (!!chosenArea || chosenCityVal === "All"),
  });
  const {
    data: hotelsAll,
    isLoading: loadingHotelsAll,
    error: hotelsErrorAll,
    refetch: refetchHotelsAll,
  } = useQuery({
    queryKey: ["hotelsAll", chosenCityVal, chosenArea],
    queryFn: () => {
      const areaValue = chosenArea?.value === "All" ? areas.map((area) => area.id) : chosenArea.value;
      return fetchHotelsAll(chosenCityVal, areaValue);
    },
    enabled: !!chosenCityVal && (!!chosenArea || chosenCityVal === "All"),
  });
  const formik = useFormik({
    initialValues: {
      cities: [],
      areas: [],
      selected_hotel: [],
    },
    validationSchema: Yup.object({
      cities: Yup.array().min(1, "City is required"),
      areas: Yup.array().min(1, "Area is required"),
      selected_hotel: Yup.array().of(Yup.string().required("Hotel is required")),
    }),
    onSubmit: async (values) => {
      const formattedValues = {
        cities: values.cities, // already an array
        areas: values.areas.includes("All") ? areas.map((i) => i.id) : values.areas, // already an array
        selected_hotel: values.selected_hotel,
      };
      try {
        await preferedHotels(formattedValues);
      } catch (error) {
        // Handle submission error
      }
    },
  });

  useEffect(() => {
    if (chosenCity) {
      formik.setFieldValue("cities", [chosenCity.value]); // set as array
      setChosenArea(null); // Reset chosen area when a new city is selected
      setChosenHotelsVal([]); // Reset chosen hotels when a new city is selected
      formik.setFieldValue("areas", []);
      formik.setFieldValue("selected_hotel", []);
      if (chosenCity.value !== "All") {
        refetchAreas();
      } else {
        setChosenArea({ label: "All", value: "All" });
        formik.setFieldValue("areas", ["All"]); // set as array
      }
    }
  }, [chosenCity, refetchAreas]);

  useEffect(() => {
    if (chosenArea) {
      formik.setFieldValue("areas", [chosenArea.value]); // set as array
      setChosenHotelsVal([]); // Reset chosen hotels when a new area is selected
      formik.setFieldValue("selected_hotel", []);
      refetchHotelsAll();
    }
  }, [chosenArea, refetchHotelsAll]);

  useEffect(() => {
    if (chosenArea) {
      formik.setFieldValue("areas", [chosenArea.value]); // set as array
      setChosenHotelsVal([]); // Reset chosen hotels when a new area is selected
      formik.setFieldValue("selected_hotel", []);
      refetchHotels();
    }
  }, [chosenArea, refetchHotels]);

  useEffect(() => {
    if (hotels) {
      setChosenHotels(hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })));
    }
  }, [hotels]);

  useEffect(() => {
    if (hotelsAll) {
      setAllChosenHotels(hotelsAll?.map((hotel) => ({ label: hotel.name, value: hotel.id })));
    }
  }, [hotelsAll]);

  const cityOptions = useMemo(() => cities?.map((city) => ({ label: city.name, value: city.id })), [cities]);
  const areaOptions = useMemo(
    () => [{ label: "All", value: "All" }, ...(areas?.map((area) => ({ label: area.name, value: area.id })) || [])],
    [areas],
  );
  const hotelOptions = useMemo(() => chosenHotels, [chosenHotels]);
  const allHotelOptions = useMemo(() => chosenAllHotelVal, [chosenAllHotelVal]);
  const filteredHotels = allHotelOptions?.filter((val) => val.value !== hotelOptions.value);

  return (
    <section className="formPage-wrap min-h-screen">
      <div className="flex w-full justify-center rounded-lg px-2">
        <h2 className="header-h2">
          <MdOutlineHotelClass className="w-5 h-5 text-yellow-500" />
          Prefered Hotels
        </h2>
      </div>
      <form className="form-wrap border" onSubmit={formik.handleSubmit}>
        <div className="border">
          <div className=" p-2 grid  sm:grid-cols-2 grid-cols-1 gap-4 rounded-md">
            <div className="input-chil">
              <label className="font-semibold w-full flex gap-x-2 text-[#002244]">City</label>
              <Select
                required
                className="w-full h-10 text-gray-800 font-semibold border rounded-lg bg-white"
                options={cityOptions}
                placeholder="Select City"
                onChange={(e) => setChosenCity(e)}
                value={chosenCity}
                isLoading={loadingCities}
              />
              {citiesError && <p className="text-red-500">{citiesError.message}</p>}
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Area</label>
              <Select
                name="area"
                options={areaOptions}
                isSearchable
                isLoading={loadingAreas}
                value={chosenArea}
                isDisabled={!chosenCityVal || loadingAreas}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: formik.errors.areas && formik.touched.areas && "rgb(135 27 27)",
                  }),
                }}
                className="w-full h-10 rounded-lg bg-white"
                placeholder="Area"
                onChange={(e) => setChosenArea(e)}
                onBlur={formik.handleBlur}
              />
              {formik.errors.areas && formik.touched.areas && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {formik.errors.areas}
                </div>
              )}
              {areasError && <p className="text-red-500">{areasError.message}</p>}
            </div>
          </div>
          <div className="input-chil p-2">
            <label className="w-full text-gray-600 font-semibold flex items-center">Hotel</label>

            <Select
              name="hotel"
              options={filteredHotels}
              isSearchable
              isMulti
              isDisabled={!chosenArea}
              value={
                chosenCity && chosenArea && chosenHotelVal.length === 0 ? chosenHotels?.map((i) => i) : chosenHotelVal
              }
              isLoading={loadingHotels}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: formik.errors.selected_hotel && formik.touched.selected_hotel && "rgb(135 27 27)",
                }),
              }}
              className="w-full rounded-lg bg-white"
              placeholder="Hotel"
              onChange={(e) => {
                setChosenHotels(e);
                setChosenHotelsVal(e);
                formik.setFieldValue(
                  "selected_hotel",
                  e?.map((i) => i.value),
                );
              }}
              onBlur={formik.handleBlur}
            />
            {formik.errors.selected_hotel && formik.touched.selected_hotel && (
              <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                {formik.errors.selected_hotel}
              </div>
            )}
            {hotelsError && <p className="text-red-500">{hotelsError.message}</p>}
          </div>
        </div>
        {/* <div className="my-4 flex flex-wrap gap-4 w-full ">
          {chosenHotelVal.length > 0 &&
            chosenHotelVal?.map((hotel) => (
              <p
                key={hotel?.value}
                className="px-6 py-2 rounded-md bg-white shadow-md flex justify-between items-center w-max gap-4"
              >
                <RiHotelLine className="text-blue-700" />
                <span className="w-full text-gray-600">{hotel?.label}</span>
              </p>
            ))}
        </div> */}
        <div className="w-full flex justify-center items-center gap-x-4 px-1 my-6">
          <button type="submit" className="addButton">
            {formik.isSubmitting ? (
              <span className="flex items-center gap-2">
                {" "}
                "Adding..." <ButtonLoading />{" "}
              </span>
            ) : (
              "Edit Prefered Hotels"
            )}
          </button>
        </div>
      </form>
    </section>
  );
};

export default AddPreferedHotels;
