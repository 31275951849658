import { BEDONLY , BREAKFAST , HALFBOARD , FULLBOARD , ALLINCLUSIVE} from "../types/types";


const initailState = {
    bedOnlyRoom : '',
    breakfastRoom:'',
    halfboardRoom:'',
    fullboardRoom:'',
    allInclusive : ''
}

export const filterReducer = (state = initailState , action) =>{
    switch(action.type){
        case BEDONLY:
            return {...state , bedOnlyRoom:action.payload};
        case BREAKFAST :
            return {...state , breakfastRoom : action.payload}
        case HALFBOARD :
            return {...state , halfboardRoom : action.payload}
        case FULLBOARD: 
            return {...state , fullboardRoom : action.payload};
        case ALLINCLUSIVE: 
             return {...state , fullboardRoom : action.payload};
        default :
            return state;
    }
}