import React from "react";
import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import {
  FieldErrorText,
  FormLabel,
  FormSection,
  FormTextAreaInput,
  FormTextInput,
} from "../../../../customComponents/FormComponents";
import { CiCalendarDate } from "react-icons/ci";
import { ImListNumbered } from "react-icons/im";
import { GrNotes } from "react-icons/gr";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Select from "react-select";
import { useParams } from "react-router";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";

function ClientBookingHistoryForm({
  title,
  initialValues,
  validationSchema,
  onSubmit,
  isPending,
  onSubmitButtonTitle,
}) {
  const { clientID } = useParams();

  const {
    data: client,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["user-customer-specific", clientID],
    queryFn: () => {
      return fetchDataQuery(`/auth/api/v1/user-customer-specific/${clientID}/`);
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error || !client) {
    return <Errorfetch Error={error.message} />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values, setValues, errors }) => (
        <Form className="space-y-6 mt-2 w-full">
          <FormSection title={title}>
            <h3 className=" flex justify-center gap-1 font-semibold text-gray-600">
              Client name: <span className="font-normal "> {client.customer_name} </span>
            </h3>

            <FormTextInput
              onFocus={(e) => e.currentTarget.showPicker()}
              className="cursor-pointer"
              label="Date"
              name="date"
              type="date"
              Icon={CiCalendarDate}
            />
            <HotelSelectInput />
            <HotelRoomsSelectInput />
            <FormTextInput label="Number of nights" name="nightsNum" type="number" Icon={ImListNumbered} />
            <FormTextAreaInput label="Notes" id={"notes"} Icon={GrNotes} />
          </FormSection>
          <div className="flex justify-center items-center">
            <Button
              className="mb-4"
              color="success"
              isProcessing={isPending}
              type="submit"
              disabled={isPending}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
            >
              {onSubmitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const HotelSelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    data: hotels,
    error: errorFetchingHotels,
    isLoading: isFetchingHotels,
  } = useQuery({
    queryKey: ["hotels"],
    queryFn: () => {
      return fetchDataQuery("/hotels/api/v1/get-hotel-names/");
    },
  });

  return (
    <>
      <FormLabel label={"Hotel"} htmlFor={"hotel"} />
      <Select
        menuPlacement="top"
        isSearchable
        name={"hotel"}
        inputId={"hotel"}
        value={{
          label: hotels?.find((hotel) => hotel.id === values.hotel)?.name,
          value: values.hotel,
        }}
        isDisabled={isFetchingHotels}
        isLoading={isFetchingHotels}
        loadingMessage={() => {
          return "Loading Cities";
        }}
        onChange={(selectedOption) => {
          void setValues({ ...values, hotel: selectedOption?.value, rooms: [] });
        }}
        options={hotels?.map((hotel) => {
          return {
            label: hotel.name,
            value: hotel.id,
          };
        })}
      />
      <ErrorMessage name={"hotel"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingHotels && <FieldErrorText message={"Error Fetching Hotels "} />}
    </>
  );
};

const HotelRoomsSelectInput = () => {
  const { values, setValues } = useFormikContext();

  const {
    data: rooms,
    isLoading: isFetchingRooms,
    error: errorFetchingHotels,
  } = useQuery({
    queryKey: ["rooms", values.hotel],
    queryFn: () => {
      if (!values.hotel) return Promise.resolve([]);
      return fetchDataQuery(`/en/rooms/api/v1/get-rooms-in-hotel/${values.hotel}/`);
    },
  });

  return (
    <>
      <FormLabel label={"Rooms"} htmlFor={"rooms"} />
      <Select
        menuPlacement="top"
        isMulti
        isSearchable
        name={"rooms"}
        inputId={"rooms"}
        isDisabled={isFetchingRooms || rooms?.length === 0}
        isLoading={isFetchingRooms}
        loadingMessage={() => {
          return "Loading rooms";
        }}
        value={values.rooms?.map((room) => {
          return {
            label:
              rooms?.find((r) => r.id === room)?.room_name_en + " " + rooms?.find((r) => r.id === room)?.room_name_ar,
            value: room,
          };
        })}
        onChange={(selectedOption, actionMeta) => {
          if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
            const newRooms = values.rooms.filter((room) => room !== actionMeta.removedValue.value);
            void setValues({ ...values, rooms: newRooms });
            return;
          }

          if (actionMeta.action === "clear") {
            void setValues({ ...values, rooms: [] });
            return;
          }
          void setValues({ ...values, rooms: selectedOption?.map((option) => option.value) });
        }}
        placeholder={rooms?.length === 0 ? "No rooms for this hotel" : "Select Rooms"}
        options={rooms?.map((room) => {
          return {
            label: room.room_name_en + " " + room.room_name_ar,
            value: room.id,
          };
        })}
      />
      <ErrorMessage name={"rooms"}>
        {(msg) => {
          return <FieldErrorText message={msg} />;
        }}
      </ErrorMessage>
      {errorFetchingHotels && <FieldErrorText message={"Error Fetching Rooms "} />}
    </>
  );
};

export default ClientBookingHistoryForm;
