import {
  GET_HOTEL_BANK_ACCOUNTS,
  GET_HOTELS_WITH_BANK_INFO,
  RESET_BANK_ACCOUNTS,
  GET_PAYMENT_DETAILS_SINGLE_BOOKING,
} from "../types/types";

export function hotelBankAccountsReducer(state = [], action) {
  switch (action.type) {
    case GET_HOTEL_BANK_ACCOUNTS:
      return action.payload;
    case RESET_BANK_ACCOUNTS:
      return [];
    default:
      return state;
  }
}

export function hotelsWithBankAccountsReducer(state = [], action) {
  switch (action.type) {
    case GET_HOTELS_WITH_BANK_INFO:
      return action.payload;
    default:
      return state;
  }
}

export function paymentDetailsSingleBookingReducer(state = [], action) {
  switch (action.type) {
    case GET_PAYMENT_DETAILS_SINGLE_BOOKING:
      return action.payload;
    default:
      return state;
  }
}
