import React, { useEffect, useState } from "react";
import { getSelectedCustomerLink } from "../../../../../redux/actions/linkAction";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import Loading from "../../../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../../../NoPermission/NoPermission";
import usePermissions from "../../../../../customHooks/usePermissions";
import { TbListDetails } from "react-icons/tb";
import { main_URL } from "../../../../../urls";
import { FcLink } from "react-icons/fc";
import lightToast from "light-toast";

const CustomerLinkDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [customerLink, setCustomerLink] = useState();
  const [permissions, permissionsFlag] = usePermissions(["link.view_link_customer"]);

  useEffect(() => {
    // if (!location?.state?.unique_code) {
    //   navigate("/dashboard/create-link/customer-link/link-list");
    // }
    dispatch(
      getSelectedCustomerLink(id, (result) => {
        if (result.status === 200) {
          setCustomerLink(result.data);
        }
      }),
    );
  }, []);

  const copyLinkToClipboard = () => {
    const link = `${main_URL}/${customerLink?.unique_code}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        lightToast.success("Link copied to clipboard!");
      })
      .catch((err) => {});
  };

  return (
    <div className="formPage-wrap min-h-screen overflow-x-auto">
      {customerLink && permissionsFlag ? (
        permissions.some((item) => item.value) ? (
          <div className="container  ">
            <div className="flex flex-col  justify-center items-center ">
              <div className=" flex items-center justify-center ">
                <h2 className="header-h2">
                  <TbListDetails className="w-5 h-5" />
                  Customer Link Details{" "}
                </h2>
              </div>
              <div className="flex flex-col   mt-6  w-full  text-black rounded-md  ">
                {/* Hotel id & id & persons*/}
                <div className="flex w-full lg:flex-row flex-col item-end justify-between lg:items-center  p-3 bg-gray-200 ">
                  <div className="flex gap-3   justify-between items-center lg:w-[50%] ">
                    <div className="w-[40%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">ID </p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem]">{customerLink?.id}</p>
                    </div>
                  </div>
                  <div className="flex  justify-between items-center lg:w-[50%] ">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">CREATED BY</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem]">
                        {customerLink?.created_by?.username} / {customerLink?.created_by?.email}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex w-full lg:flex-row flex-col item-end justify-between lg:items-center  p-3 ">
                  <div className="flex  justify-between items-center lg:w-[50%] ">
                    <div className="w-[40%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">UNIQUE CODE</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] capitalize">
                        {customerLink?.unique_code}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center lg:w-[50%]">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">UPDATED AT</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem]">
                        {" "}
                        {customerLink?.updated_at?.slice(0, 10)} / {customerLink?.updated_at?.slice(11, 19)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex w-full lg:flex-row flex-col item-end justify-between lg:items-center  p-3 bg-gray-200">
                  <div className="flex  justify-between items-center lg:w-[50%] ">
                    <div className="w-[40%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">LINK STATUS</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] capitalize">
                        {customerLink?.link_status}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center lg:w-[50%]">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">EXPIRED DATE</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem]">
                        {" "}
                        {customerLink?.expired_date?.slice(0, 10)} / {customerLink?.expired_date?.slice(11, 19)}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Client text & Client Tag Request*/}
                <div className="flex w-full lg:flex-row flex-col item-end justify-between lg:items-center  p-3 ">
                  <div className="flex items-center lg:w-[50%]">
                    <div className="w-[40%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">MARKUP</p>
                    </div>
                    <div className="w-[70%] font-semibold text-gray-800 capitalize lg:text-lg text-[.6rem]">
                      {customerLink?.markup?.name}
                    </div>
                  </div>
                  <div className="flex items-center lg:w-[50%]">
                    <div className="w-[33%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">CUSTOMER LINK</p>
                    </div>
                    <div
                      onClick={copyLinkToClipboard}
                      className="w-[70%] flex items-center gap-x-1 lg:text-lg text-[.6rem]  font-semibold text-sky-800 cursor-pointer hover:text-sky-700 duration-500 transition-all"
                    >
                      {`${main_URL}/${customerLink?.unique_code}`} <FcLink className="w-5 h-5 -rotate-45" />
                    </div>
                  </div>
                </div>

                <div className="flex w-full flex-col item-end justify-between lg:items-center  p-3  bg-gray-200">
                  <div className="flex  justify-between items-center w-full ">
                    <div className="w-[22%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">HOTELS</p>
                    </div>
                    <div className="w-full flex items-center gap-x-1 lg:text-lg text-[.6rem]  font-semibold text-gray-800 capitalize">
                      {customerLink?.hotels?.length > 0 ? (
                        customerLink?.hotels.map((item, index) => (
                          <>
                            <div className="flex items-center  gap-x-2">
                              {" "}
                              {item?.name} {index + 1 === customerLink?.hotels?.length - 1 && <span>/</span>}{" "}
                            </div>
                          </>
                        ))
                      ) : (
                        <>
                          <div className="flex items-center  gap-x-2"> All Hotels</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-col item-end justify-between lg:items-center  p-3  ">
                  <div className="flex  justify-between items-center w-full ">
                    <div className="w-[22%]  ">
                      <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">CITIES</p>
                    </div>
                    <div className="w-full flex items-center gap-x-1 lg:text-lg text-[.6rem]  font-semibold text-gray-800 capitalize">
                      {customerLink?.city?.length > 0 ? (
                        customerLink?.city?.map((item, index) => (
                          <>
                            <div className="flex items-center  gap-x-2">
                              {" "}
                              {item?.name} {index + 1 === customerLink?.city?.length - 1 && <span>/</span>}{" "}
                            </div>
                          </>
                        ))
                      ) : (
                        <>
                          <div className="flex items-center  gap-x-2"> All Cities</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {customerLink?.allowed_contoury.length > 0 ? (
                  <div className="flex w-full justify-between items-center  p-3  bg-gray-200 ">
                    <div className="flex  justify-between items-center w-full ">
                      <div className="w-[33%]  ">
                        <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold">ALLOWED COUNTRY</p>
                      </div>
                      <div className="w-full flex items-center gap-x-1 lg:text-lg text-[.6rem]  font-semibold text-gray-800 capitalize">
                        {customerLink?.allowed_contoury?.map((item, index) => (
                          <>
                            <div className="flex items-center  gap-x-2">
                              {" "}
                              {item?.name} {index + 1 === customerLink?.allowed_contoury?.length - 1 && <span>/</span>}{" "}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full justify-between items-center  p-3  bg-gray-200">
                    <div className="flex  justify-between items-center w-full ">
                      <div className="w-[22%]  ">
                        <p className="flex items-center gap-x-1 lg:text-lg text-[.6rem] font-bold ">
                          RESTRICTED COUNTRY
                        </p>
                      </div>
                      <div className="w-full flex items-center gap-x-1 lg:text-lg text-[.6rem]  font-semibold text-gray-800 capitalize">
                        {customerLink?.restricted_contoury?.map((item, index) => (
                          <>
                            <div className="flex items-center  gap-x-2">
                              {" "}
                              {item.name}{" "}
                              {index + 1 === customerLink?.restricted_contoury?.length - 1 && <span>/</span>}{" "}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-screen flex items-center justify-center">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default CustomerLinkDetails;
