import React, { useEffect, useRef } from "react";

export default function GoogleMapsSearchBox({ map, maps, getLocationData, searchValue, formikSet }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!map || !maps) return;

    // Create a new instance of the SearchBox class
    const searchBox = new maps.places.SearchBox(inputRef.current);

    // Add a listener for the places_changed event
    searchBox.addListener("places_changed", () => {
      // Get the places that match the user's query
      const places = searchBox.getPlaces();

      // Check if any places were found
      if (places.length === 0) return;

      // Get the first place from the array
      const place = places[0];

      // Get the location of the place as a LatLng object
      const location = place.geometry.location;
      let locationData = { lat: location.lat().toFixed(5), lng: location.lng().toFixed(5) };
      getLocationData(locationData);

      // Center the map on the location
      map.setCenter(location);

      // Set the zoom level of the map
      map.setZoom(15);
    });
  }, [map, maps]);
  return (
    <div className="w-full">
      <label
        htmlFor="hotel_map_address "
        className="text-center w-full flex items-center justify-center text-gray-600 font-semibold"
      >
        Hotel Map Address
      </label>
      {searchValue ? (
        <input
          ref={inputRef}
          type="text"
          id="hotel_map_address"
          name="hotel_map_address"
          placeholder="Search for a place"
          // style={{
          //   borderRadius: "10px",
          //   boxShadow: "2px 2px 10px 1px rgba(0, 0, 0, 0.2)",
          //   width: "max(35vw, 200px)",
          //   marginBottom: "10px",
          //   alignSelf: "flex-start",
          // }}
          defaultValue={searchValue}
          className="w-full px-3 h-10  border rounded-lg bg-white mb-3"
          onBlur={(e) => (formikSet ? formikSet("hotelMapAddress", e.target.value) : void 0)}
        />
      ) : (
        <input
          ref={inputRef}
          type="text"
          id="hotel_map_address"
          name="hotel_map_address"
          placeholder="Search for a place"
          // style={{
          //   borderRadius: "10px",
          //   boxShadow: "2px 2px 10px 1px rgba(0, 0, 0, 0.2)",
          //   width: "max(35vw, 200px)",
          //   marginBottom: "10px",
          //   alignSelf: "flex-start",
          // }}
          className="w-full px-3 h-10  border rounded-lg bg-white mb-3"
          onBlur={(e) => (formikSet ? formikSet("hotelMapAddress", e.target.value) : void 0)}
        />
      )}
    </div>
  );
}
