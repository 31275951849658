import React, { useEffect, useRef, useState } from "react";
import "./../AddHotelForm/DashboardForm.scss";
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import StaticFromInput from "../../molecules/StaticFormInput/StaticFromInput";
import DynamicFormInputFile from "../../molecules/DynamicFormInputFile/DynamicFormInputFile";
import DynamicFormInputText from "../../molecules/DynamicFormInputText/DynamicFormInputText";
import GoogleMap from "../../molecules/GoogleMap/GoogleMap";
import { FaStar } from "react-icons/fa";
import Select from "react-select";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import getHotelDetails from "../../../redux/actions/hotelDetails";
import { getFormAreas, getFormCities, getFormCountries } from "../../../redux/actions/formCountries";
import getFeatures from "../../../redux/actions/formFeatures";
import Loading from "../Loading/Loading";
import editHotel from "../../../redux/actions/editHotel";
import { emailPattern, phonePattern } from "../AddHotelForm/AddHotelForm";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";

// function that always returns the svg elem when clicked
export function returnSVG(elem) {
  if (elem.tagName === "svg") {
    return elem;
  } else {
    return elem.parentNode;
  }
}

export default function EditHotelForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isImageRequired, setIsImageRequired] = useState(false);
  const [isVideoRequired, setIsVideoRequired] = useState(false);
  const { id } = useParams();
  const hotel = useSelector((state) => state.hotelDetails);

  const countries = useSelector((state) => state.formCountries.countries);
  const cities = useSelector((state) => state.formCountries.cities);
  const areas = useSelector((state) => state.formCountries.areas);
  const features = useSelector((state) => state.formFeatures);

  const [rating, setRating] = useState(0);
  const featuresOptions = features.map((elem) => {
    return { value: elem.feature, label: elem.feature };
  });
  const [chosenFeatures, setChosenFeatures] = useState("");

  const [mapCenter, setMapCenter] = useState({ lat: 30.0444, lng: 31.2357 });
  const [chosenCountry, setChosenCountry] = useState("");
  const [chosenCountryID, setChosenCountryID] = useState(null);
  const [chosenCity, setChosenCity] = useState("");
  const [deletedImgs, setDeletedImgs] = useState([]);
  const [deletedVids, setDeletedVids] = useState([]);
  const dispatch = useDispatch();
  const formRef = useRef();
  const form = formRef.current;

  // get count of dynamic inputs
  const telephoneNums = Math.max(Object.keys(hotel.telephone).length, 1);

  const reservationTelephoneNums = Math.max(Object.keys(hotel.reservation_telephone).length, 1);
  const reservationEmailNums = Math.max(Object.keys(hotel.reservation_email).length, 1);
  const receptionEmailNums = Math.max(Object.keys(hotel.reception_email).length, 1);
  const restaurantsNums = Math.max(Object.keys(hotel.restaurants).length, 1);

  useEffect(() => {
    dispatch(getHotelDetails(id));
    dispatch(getFormCountries());
    dispatch(getFeatures());
  }, []);

  useEffect(() => {
    if (hotel.country_id) {
      dispatch(getFormCities(`${hotel.country_id}`));
    }
    if (hotel.city_id) {
      dispatch(getFormAreas(Number(hotel.city_id)));
    }
  }, [hotel.country_id, hotel.city_id]);

  useEffect(() => {
    if (hotel.features) {
      setChosenFeatures([
        ...hotel.features.map((elem) => {
          return { value: elem.feature_name, label: elem.feature_name };
        }),
      ]);
      setIsLoading(false);
    }
    // when hotel.rate is recieved the rating state will be updated so that the rating component would be updated
    if (hotel.rate) {
      handleRating(hotel.rate);
    }
    // when hotel.latitude is recieved the mapCenter would be updtated so that the map component would be updated
    // if (hotel.latitude) {
    //   setMapCenter({ lat: Number(hotel.latitude), lng: Number(hotel.longitude) });
    // }
  }, [hotel.features, hotel.rating]);

  useEffect(() => {
    // putting the values inside the form
    if (form) {
      form.name.value = hotel.name;
      form.name_ar.value = hotel.name_ar;
      form.address.value = hotel.address;
      form.country.value = countries.length > 0 ? hotel.country_id : null;
      form.city.value = hotel.city_id;
      form.address.value = hotel.address;
      form.address_ar.value = hotel.address_ar;
      form["payment-type"].value = hotel.payment_type;
      form.internet.value = hotel.internet;
      form.description.value = hotel.description;
      form.description_ar.value = hotel.description_ar;
      // form.hotel_map_address = hotel.hotel_map_address;
      // check if form.telephone is an HTMLCollection or a single value
      if (form.telephone[0]) {
        [...form.telephone].map((elem, index) => (elem.value = Object.values(hotel.telephone)[index]));
      } else {
        form.telephone.value = Object.values(hotel.telephone)[0];
      }
      // check if form["reservation-telephone"] is an HTMLCollection or a single value
      if (form["reservation-telephone"][0]) {
        [...form["reservation-telephone"]].map(
          (elem, index) => (elem.value = Object.values(hotel.reservation_telephone)[index]),
        );
      } else {
        form["reservation-telephone"].value = Object.values(hotel.reservation_telephone)[0];
      }
      // check if form["reservation-email"] is an HTMLCollection or a single value
      if (form["reservation-email"][0]) {
        [...form["reservation-email"]].map(
          (elem, index) => (elem.value = Object.values(hotel.reservation_email)[index]),
        );
      } else {
        form["reservation-email"].value = Object.values(hotel.reservation_email)[0];
      }
      // check if form["reception-email"] is an HTMLCollection or a single value
      if (form["reception-email"][0]) {
        [...form["reception-email"]].map((elem, index) => (elem.value = Object.values(hotel.reception_email)[index]));
      } else {
        form["reception-email"].value = Object.values(hotel.reception_email)[0];
      }
      // check if form.restaurants is an HTMLCollection or a single value
      if (form.restaurants[0]) {
        [...form.restaurants].map((elem, index) => (elem.value = Object.values(hotel.restaurants)[index]));
      } else {
        form.restaurants.value = Object.values(hotel.restaurants)[0];
      }
    }
    setChosenCountryID(hotel._country);
  }, [hotel, form, countries, cities, areas]);

  function handleRating(rate) {
    setRating(Number(rate));
  }

  // the function passed to the map component
  function getLocationData(data) {
    setMapCenter(data);
  }

  function getCountry(data) {
    setChosenCountry(data);
  }

  function getCountryId(data) {
    setChosenCountryID(data);
  }

  function getCity(data) {
    setChosenCity(data);
  }

  // push the img id to the state and remove toggle undo svg
  function removeImg(e, imgObj) {
    const svg = returnSVG(e.target);
    svg.style.display = "none";
    svg.nextSibling.style.display = "block";
    setDeletedImgs([...deletedImgs, imgObj.image_id]);
    // check if the user deleted all images to make the image input required
    // the state here will reflect the state right before changing so we must add 1
    if (deletedImgs.length + 1 === hotel.images.length) {
      setIsImageRequired(true);
    }
  }

  // remove the img id from the state and remove toggle delete svg
  function undoRemoveImg(e, imgObj) {
    const svg = returnSVG(e.target);
    svg.style.display = "none";
    svg.previousSibling.style.display = "block";
    const deletedImgsArr = deletedImgs.slice(0);

    setDeletedImgs(
      deletedImgsArr.filter((id) => {
        return id !== imgObj.image_id;
      }),
    );
    // if the user decides to undo image delete the image input doesnt need to be required
    if (isImageRequired === true) {
      setIsImageRequired(false);
    }
  }

  // push the video id to the state and remove toggle undo svg
  function removeVid(e, vidObj) {
    const svg = returnSVG(e.target);
    svg.style.display = "none";
    svg.nextSibling.style.display = "block";
    setDeletedVids([...deletedVids, vidObj.video_id]);
    // check if the user deleted all images to make the image input required
    // the state here will reflect the state right before changing so we must add 1
    if (deletedVids.length + 1 === hotel.videos.length) {
      setIsVideoRequired(true);
    }
  }

  // remove the video id from the state and remove toggle delete svg
  function undoRemoveVid(e, vidObj) {
    const svg = returnSVG(e.target);
    svg.style.display = "none";
    svg.previousSibling.style.display = "block";
    const deletedVidsArr = deletedVids.slice(0);
    setDeletedVids(
      deletedVidsArr.filter((id) => {
        return id !== vidObj.video_id;
      }),
    );
    // if the user decides to undo video delete the video input doesnt need to be required
    if (isVideoRequired === true) {
      setIsVideoRequired(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const phonePattern = /^\+?\d+$/;
    const emailPattern = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/;
    const formData = new FormData(e.target);
    // check the validity of submitted data before sending request
    // check arrays
    const checkTelephone = formData.getAll("telephone").map((elem) => phonePattern.test(elem));
    const checkReservationTelephone = formData.getAll("reservation-telephone").map((elem) => phonePattern.test(elem));
    const checkReservationEmail = formData.getAll("reservation-email").map((elem) => emailPattern.test(elem));
    const checkReceptionEmail = formData.getAll("reception-email").map((elem) => emailPattern.test(elem));

    if (
      rating !== 0 &&
      formData.getAll("features")[0] !== "" &&
      !checkTelephone.includes(false) &&
      !checkReservationTelephone.includes(false) &&
      !checkReservationEmail.includes(false) &&
      !checkReceptionEmail.includes(false)
    ) {
      const data = {
        name: formData.get("name"),
        name_ar: formData.get("name_ar"),
        telephone: JSON.stringify(Object.assign({}, formData.getAll("telephone"))),
        country: chosenCountry || hotel.country,
        _country: chosenCountryID,
        city: chosenCity || hotel.city_id,
        area: formData.get("area"),
        address: formData.get("address"),
        address_ar: formData.get("address_ar"),
        rate: rating,
        description: formData.get("description"),
        description_ar: formData.get("description_ar"),
        internet: formData.get("internet"),
        restaurants: JSON.stringify(Object.assign({}, formData.getAll("restaurants"))),
        payment_type: formData.get("payment-type"),
        hotel_map_address: formData.get("hotel_map_address"),
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        reservation_telephone: JSON.stringify(Object.assign({}, formData.getAll("reservation-telephone"))),
        reservation_email: JSON.stringify(Object.assign({}, formData.getAll("reservation-email"))),
        reception_email: JSON.stringify(Object.assign({}, formData.getAll("reception-email"))),
      };
      const features = formData.getAll("features");
      const images = formData.getAll("images");
      const videos = formData.getAll("videos");

      // the data to be submitted as form data
      const finalData = new FormData();
      // put the data from the data object
      for (let item in data) {
        finalData.append(`${item}`, data[item]);
      }
      // append images
      for (let img of images) {
        finalData.append("images", img);
      }
      // append videos
      for (let vid of videos) {
        finalData.append("videos", vid);
      }
      // append features
      for (let feature of features) {
        finalData.append("features", feature);
      }

      // append deleted images
      for (let deleted_img of deletedImgs) {
        finalData.append("delete_images", JSON.stringify(deleted_img));
      }
      // append deleted videos
      for (let deleted_vids of deletedVids) {
        finalData.append("delete_videos", JSON.stringify(deleted_vids));
      }
      dispatch(editHotel(id, finalData, () => navigate(`/dashboard/hotel-list/hotel-details/${id}`)));
      setIsLoading(true);
    }
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["hotels_management.change_hotels"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div>
      {((countries.length === 0 || hotel.name === "") && <Loading />) || (
        <div>
          <h1 className="formHeading">Edit Hotel</h1>
          <form className="dashboardForm" encType="multipart/form-data" ref={formRef} onSubmit={handleSubmit}>
            <div className="flex-row">
              <div className="flex-column-20">
                <StaticFromInput text="Hotel Name" name="name" />
                <StaticFromInput text="Hotel Name Arabic" name="name_ar" />
                <StaticFormSelect
                  text="Country"
                  name="country"
                  options={countries}
                  action={getFormCities}
                  func={getCountry}
                  callback={getCountryId}
                />
                <StaticFormSelect text="City" name="city" options={cities} callback={getCity} action={getFormAreas} />
                <div className="flex-column">
                  <label htmlFor="area">Areas</label>
                  <select name="area" id="area" required>
                    {areas.map((elem, index) => {
                      return (
                        <option value={elem.id} key={index}>
                          {elem.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <StaticFromInput text="Address" name="address" />
                <StaticFromInput text="Arabic Address" name="address_ar" />
                <div>
                  <div>Payment Type</div>
                  <span className="radioSpan">
                    <input type="radio" name="payment-type" value="credit" required id="credit" />
                    <label htmlFor="credit">Credit</label>
                  </span>
                  <span className="radioSpan">
                    <input type="radio" name="payment-type" value="cash" id="cash" />
                    <label htmlFor="cash">Cash</label>
                  </span>
                </div>

                <StaticFromInput text="Internet" name="internet" />
                <div className="flex-column">
                  <label htmlFor="description">Description</label>
                  <textarea name="description" id="description" cols="40" rows="3"></textarea>
                </div>
                <div className="flex-column">
                  <label htmlFor="description_ar">Arabic Description</label>
                  <textarea name="description_ar" id="description_ar" cols="40" rows="3"></textarea>
                </div>
                <h2>Old Images & videos </h2>
                <div className="oldFiles">
                  <div className="flex flex-wrap max-w-[40vw] border gap-2">
                    {hotel.images.map((imgObj, index) => {
                      return (
                        <div key={index}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            height={20}
                            onClick={(e) => removeImg(e, imgObj)}
                          >
                            <title>trash-can-outline</title>
                            <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style={{ display: "none" }}
                            onClick={(e) => undoRemoveImg(e, imgObj)}
                          >
                            <title>arrow-u-left-top</title>
                            <path d="M20 13.5C20 17.09 17.09 20 13.5 20H6V18H13.5C16 18 18 16 18 13.5S16 9 13.5 9H7.83L10.91 12.09L9.5 13.5L4 8L9.5 2.5L10.92 3.91L7.83 7H13.5C17.09 7 20 9.91 20 13.5Z" />
                          </svg>
                          <img src={imgObj.image_url} width={90} />
                        </div>
                      );
                    })}
                  </div>
                  <div className="files-preview">
                    {hotel.videos.map((vidObj, index) => {
                      return (
                        <div key={index}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            height={20}
                            onClick={(e) => removeVid(e, vidObj)}
                          >
                            <title>trash-can-outline</title>
                            <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style={{ display: "none" }}
                            onClick={(e) => undoRemoveVid(e, vidObj)}
                          >
                            <title>arrow-u-left-top</title>
                            <path d="M20 13.5C20 17.09 17.09 20 13.5 20H6V18H13.5C16 18 18 16 18 13.5S16 9 13.5 9H7.83L10.91 12.09L9.5 13.5L4 8L9.5 2.5L10.92 3.91L7.83 7H13.5C17.09 7 20 9.91 20 13.5Z" />
                          </svg>
                          <video src={vidObj.video_url} width={90} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex-column">
                <DynamicFormInputText
                  text="Telephone"
                  name="telephone"
                  inputsNum={telephoneNums}
                  type="tel"
                  pattern={phonePattern}
                  errMsg="Invalide Phone Number"
                />
                <DynamicFormInputText
                  text="Reservation Telephone"
                  name="reservation-telephone"
                  inputsNum={reservationTelephoneNums}
                  type="tel"
                  pattern={phonePattern}
                  errMsg="Invalide Phone Number"
                />
                <DynamicFormInputText
                  text="Reservation Email"
                  name="reservation-email"
                  inputsNum={reservationEmailNums}
                  type="email"
                  pattern={emailPattern}
                  errMsg="Invalide Email"
                />
                <DynamicFormInputText
                  text="Reception Email"
                  name="reception-email"
                  inputsNum={receptionEmailNums}
                  type="email"
                  pattern={emailPattern}
                  errMsg="Invalide Email"
                />
                <DynamicFormInputText text="Restaurants" name="restaurants" inputsNum={restaurantsNums} />
                <DynamicFormInputFile text="Images" name="images" type="image" required={isImageRequired} />
                <DynamicFormInputFile text="Videos" name="videos" type="video" required={isVideoRequired} />

                <div>
                  <label htmlFor="features">Features</label>
                  {chosenFeatures ? (
                    <Select
                      options={featuresOptions}
                      isMulti
                      id="features"
                      name="features"
                      defaultValue={chosenFeatures}
                      required
                    />
                  ) : null}
                </div>
                <div className="flex-column">
                  <label>Rating</label>
                  <Rating
                    onClick={handleRating}
                    iconsCount={5}
                    size={10}
                    fillIcon={<FaStar />}
                    emptyIcon={<FaStar />}
                    initialValue={rating}
                  />
                </div>
              </div>
            </div>
            <GoogleMap
              getLocationData={getLocationData}
              defaultProps={{ center: mapCenter, zoom: 15 }}
              searchValue={hotel.hotel_map_address}
            />
            <input type="submit" />
          </form>
        </div>
      )}
    </div>
  );
}
