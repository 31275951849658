import axios from "axios";
import cookie from "react-cookies";
import { base_URL } from "../urls";
import { toast } from "react-toastify";

const axiosApi = axios.create({
  baseURL: base_URL,
});

const refreshToken = cookie.load("refresh_token");

function api_token(token) {
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // axiosApi.defaults.headers.common["userName"] = "ahmed";
  }
  return axiosApi;
}

// /request
axiosApi.interceptors.request.use(
  (config) => {
    const token = cookie.load("access_token");

    if (token) {
      config.headers.Authorization = `Bearer ${cookie.load("access_token")}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Add a request interceptor to refresh the token before each request

axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = cookie.load("refresh_token");
        const response = await axios.post(`${base_URL}/auth/api/v1/token/refresh/`, {
          refresh: cookie.load("refresh_token"),
        });
        cookie.save("access_token", response.data.access);
        cookie.save("refresh_token", response.data.refresh);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
        return axios(originalRequest);
      } catch (error) {
        cookie.remove("access_token", { path: "/" });
        cookie.remove("refresh_token", { path: "/" });
        // Handle refresh token error or redirect to login
      }
    } else if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        cookie.remove("access_token", { path: "/" });
        cookie.remove("refresh_token", { path: "/" });

        // Retry the original request with the new token
        // originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
        // return axios(originalRequest);
      } catch (error) {
        cookie.remove("access_token", { path: "/" });
        cookie.remove("refresh_token", { path: "/" });
        // Handle refresh token error or redirect to login
      }
    }
    const objectKey = error.response.data;
    const errorDataKeys = Object.keys(objectKey);
    const errorData = Object.values(objectKey);

    const errorMessage = () => {
      return (
        <div className="flex flex-col ">
          {error.response?.data && error?.response?.data[0]?.message ? (
            <p>
              <span className=" font-bold">Error Message: </span>
              {error?.response?.data[0]?.message}{" "}
            </p>
          ) : error?.response?.data?.message ? (
            <p>
              <span className=" font-bold">Error Message: </span>
              {error?.response?.data?.message}{" "}
            </p>
          ) : error.response?.data?.error ? (
            <p>
              <span className=" font-bold">Error Message: </span>
              {error.response?.data?.error}{" "}
            </p>
          ) : error.response?.data && errorData && errorDataKeys.length > 0 && objectKey[0] !== "<" ? (
            <p>
              <span className=" font-bold">Error Message: </span>
              <span className=" capitalize">{errorData}</span>{" "}
            </p>
          ) : (
            !error?.response && ""
          )}
          <p>
            {" "}
            <span className=" font-bold">Error Status:</span> {error?.message}{" "}
          </p>
        </div>
      );
    };
    toast.error(errorMessage, { autoClose: 7000 });
    return Promise.reject(error);
  },
);

// Function to check if a token is expired (you might need to implement your logic here)
// function isTokenExpired(token) {
//   if (!token) {
//     // If there's no token, consider it expired
//     return true;
//   }

//   try {
//     // Decode the token to get the expiration time
//     const decodedToken = jwt_decode(token);

//     // Get the current time in seconds
//     const currentTime = Date.now() / 1000;

//     // Check if the token has expired by comparing the expiration time with the current time
//     return decodedToken.exp < currentTime;
//   } catch (error) {
//     // If decoding fails, consider the token as expired
//     return true;
//   }
// }

export default api_token;
