import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction, getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import cookie from "react-cookies";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import lightToast from "light-toast";
import { FaWpforms } from "react-icons/fa6";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { AiOutlinePercentage } from "react-icons/ai";
import { addNewWalletBankTransferAction } from "../../../redux/actions/walletAction";
import BankTransferWallet from "./BankTransferWallet";
import CashWallet from "./CashWallet";
import JumboWallet from "./JumboWallet";
import PosVisaWallet from "./PosVisaWallet";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

const AddNewWallet = () => {
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState();
  const [commission, setCommission] = useState(null);
  const [subTotal, setSubTotal] = useState();
  const [convertRate, setConvertRate] = useState(1);
  const [methodOfPayment, setMethodOfPayment] = useState();
  const [agent, setAgent] = useState();
  const format = "HH:mm";
  const paymentMethods = [
    { value: "cash", label: "cash" },
    { value: "bank_transfer", label: "Bank Transfer" },
    { value: "jumbo4pay", label: "Jumbo4pay" },
    { value: "visa_pos", label: "visa pos" },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const { addWalletFlag } = useSelector((state) => state.walletReducer);
  useRemoveScroll();

  useEffect(() => {
    dispatch(getSelectedBookedRoomSingleAction(token, params.id));
    dispatch(getCurrencyAction(token));
    dispatch(getBankListAction(token));
  }, []);

  const { currencyList } = useSelector((state) => state.bookingListReducer);
  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  const handleInputChange = (e) => {
    // Use a regular expression to check if the input is a number with up to two digits.
    if (e.target.value <= 9) {
      const isValidInput = e.target.value;
      setCommission(isValidInput); // Change 1 to your desired maximum length

      const calc = (amount * isValidInput) / 100;
      setSubTotal(amount - calc);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    formData.append("subtotal", Number(subTotal).toFixed(2));
    formData.append("amount_used", "0.00");
    formData.append("rest_of_payment", "0.00");
    formData.append("commission", Number(commission).toFixed(2));
    dispatch(
      addNewWalletBankTransferAction(token, formData, (result) => {
        if (result.status === 201) {
          lightToast.success("E-Wallet Form was completed successfully");
          navigate(-1);
        }
        // else
        // {
        //     toast.success(`The bank transfer was completed successfully`)
        // }
      }),
    );
  };

  return (
    <div className="w-full md:px-6 px-2 my-8">
      <div className="flex flex-col items-center justify-start h-full w-full">
        <div className=" w-full  border-gray-300 border rounded-lg p-5 ">
          <div className=" flex justify-center ">
            <h2 className="header-h2">
              {" "}
              <FaWpforms className="w-5 h-5" />
              Add New E-Wallet
            </h2>
          </div>
          <div className="mt-3  w-full bg-gray-50 border rounded-md p-2 py-4">
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <div className="input-par">
                <div className="input-chil">
                  <label className=" flex text-gray-600 "> Amount</label>
                  <input
                    placeholder="Amount"
                    name="amount"
                    type="number"
                    step="any"
                    required
                    onChange={(e) => setAmount(e.target.value)}
                    className=" w-full px-3 h-10  border rounded-lg bg-white "
                  />
                </div>
                <div className="input-chil">
                  <label className=" flex text-gray-600 ">Commission</label>
                  <div className="w-full relative text-gray-600">
                    <input
                      placeholder="commission"
                      type="number"
                      step="any" // set the step to allow up to two decimal places
                      value={commission}
                      onChange={handleInputChange}
                      required
                      className=" w-full px-8 h-10  border rounded-md floating-input bg-white "
                    />
                    <div className="absolute top-3 left-2">
                      <AiOutlinePercentage className="w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-par">
                <div className="input-chil">
                  <label className=" flex text-gray-600  ">Currency</label>
                  <Select
                    name="currency"
                    options={CurrencyType}
                    onChange={(e) => {
                      setCurrency(e.label);
                    }}
                    isSearchable
                    className=" w-full  h-10  border rounded-lg  bg-white "
                    placeholder="Currency"
                  />
                </div>
                <div className="input-chil">
                  <label className=" flex text-gray-600">SubTotal</label>
                  <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                    {commission && amount ? (
                      <p className="text-black flex items-end gap-x-1 ">
                        {" "}
                        {subTotal}
                        <span className="font-semibold text-xs ">{currency}</span>
                      </p>
                    ) : (
                      <p className="text-gray-400 "> SubTotal</p>
                    )}
                  </div>
                </div>
              </div>

              <label className=" flex text-gray-600  ">Agent Name</label>
              <input
                placeholder="Agent Name"
                name="agent_name"
                type="text"
                required
                onChange={(e) => setAgent(e.target.value)}
                className=" w-full px-3 h-10  border rounded-lg bg-white "
              />

              <label className=" flex text-gray-600  ">Method Of Payment </label>
              <Select
                name="method_of_payment"
                options={paymentMethods}
                isDisabled={!currency}
                onChange={(e) => {
                  setMethodOfPayment(e.label);
                }}
                isSearchable
                className=" w-full  h-10  border rounded-lg  bg-white  capitalize"
                placeholder="Method Of Payment"
              />

              {/* <button type='Submit'
                                disabled={addWalletFlag}
                                className='flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all'>
                       Add   {addWalletFlag  ?<><TopBarProgress /><ButtonLoading /></> :<IoAdd className="w-5 h-5" /> }
                        </button>            */}
            </div>
          </div>
          {methodOfPayment === "Bank Transfer" ? (
            <div className="w-full ">
              <BankTransferWallet
                amountWallet={amount}
                commission={commission}
                currencyWallet={currency}
                subTotal={subTotal}
                agent={agent}
                methodOfPayment={methodOfPayment}
              />
            </div>
          ) : methodOfPayment === "cash" ? (
            <div className="w-full ">
              <CashWallet
                amountWallet={amount}
                commission={commission}
                currencyWallet={currency}
                subTotal={subTotal}
                agent={agent}
                methodOfPayment={methodOfPayment}
              />
            </div>
          ) : methodOfPayment === "Jumbo4pay" ? (
            <div className="w-full ">
              <JumboWallet
                amountWallet={amount}
                commission={commission}
                currencyWallet={currency}
                subTotal={subTotal}
                agent={agent}
                methodOfPayment={methodOfPayment}
              />
            </div>
          ) : (
            methodOfPayment === "visa pos" && (
              <div className="w-full ">
                <PosVisaWallet
                  amountWallet={amount}
                  commissionWallet={commission}
                  currencyWallet={currency}
                  subTotal={subTotal}
                  agent={agent}
                  methodOfPayment={methodOfPayment}
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewWallet;
