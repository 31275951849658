import React, { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CityHotelRoomSelector from "./roomsFilter/CityHotelRoomSelector";
import FileUploader from "../../../FileUploader/FileUploader";
import { MdDelete } from "react-icons/md";
import ButtonLoading from "../../../ButtonLoading/ButtonLoading";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import mutateDataQuery from "../../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom"; // Add this import
import Select from "react-select";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import Loading from "../../../Loading/Loading";
import { FaPlus, FaTrash } from "react-icons/fa";

const fetchCities = () => fetchDataQuery("/search/api/v1/get-cities/");
const fetchAreas = (city) => fetchDataQuery(`/search/api/load-areas/?city_name=${city}`);
const fetchHotels = (city, area) => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]&areas=[${area}]`);
const fetchRooms = (hotel) => fetchDataQuery(`/en/rooms/api/v1/get-rooms-in-hotel/${hotel}/`);
let pairIdCounter = 1;

const AddRoomExtraTool = () => {
  const [toolDocument, setToolDocument] = useState([]);
  const [clicked, setClicked] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [adding, setAdding] = useState(false);
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [chosenHotelVal, setChosenHotelsVal] = useState(null);
  const [roomValue, setRoomValue] = useState(null);
  const [roomUpgradeValue, setRoomUpgradeValue] = useState(null);
  const [offerData, setOfferData] = useState([]);
  const [inputPairs, setInputPairs] = useState([
    {
      id: pairIdCounter++, // Assign a unique ID when initializing
      stay_period_start: "",
      stay_period_end: "",
      checkedDays: {}, // Ensure each validity period has its own checkedDays object
    },
  ]);
  const [chosenRoomVal, setChosenRoomVal] = useState(null);
  const [roomFields, setRoomFields] = useState([{ room: null, upgrade_room: null }]);
  const firstUpgrade = offerData?.upgrades?.find((r) => r?.room?.hotel_name?.name);

  const handleAddRoom = () => {
    const newRoomFields = [...roomFields, { room: null, upgrade_room: null }];
    setRoomFields(newRoomFields);
    setFieldValue(`rooms`, newRoomFields); // Sync Formik values
  };

  const handleRemoveRoom = (index) => {
    const updatedFields = roomFields.filter((_, i) => i !== index);
    setRoomFields(updatedFields);
    setFieldValue(`rooms`, updatedFields); // Sync Formik values
  };

  const {
    mutate: upgradeTool,
    isLoading: loading,
    isPending,
  } = useMutation({
    mutationFn: (data) =>
      mutateDataQuery(`/upgrade-tool/api/v1/room-upgrade-tool/${id}/`, "put", data, {
        "Content-Type": "multipart/form-data",
      }),
    onSuccess: () => {
      toast.success("Offer Updated Successfully");
      navigate(`/dashboard/upgrade-tool/room-upgrade-tool/offer-list`);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || error.message || "Failed to update offer");
    },
  });
  useEffect(() => {
    if (offerData?.periods) {
      let idCounter = 0; // Initialize an ID counter
      const formattedInputPairs = offerData.periods?.map((period) => ({
        id: period.id || ++idCounter, // Use period.id if it exists, otherwise assign an incrementing ID
        stay_period_start: period.start_date,
        stay_period_end: period.end_date,
        checkedDays: period.days_of_week.reduce((acc, day) => ({ ...acc, [day]: true }), {}),
      }));
      setInputPairs(formattedInputPairs);
    }
  }, [offerData]);

  const {
    data: offer,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["offer", id],
    queryFn: () => fetchDataQuery(`/upgrade-tool/api/v1/room-upgrade-tool/${id}/`),
  });

  useEffect(() => {
    if (isSuccess && offer) {
      setOfferData(offer);
    }
  }, [offer, isSuccess]);

  const addDateRange = () => {
    const newPair = {
      id: pairIdCounter++, // Assign a unique ID to each new pair
      stay_period_start: "",
      stay_period_end: "",
      checkedDays: {}, // Independent checkedDays object for this pair
    };
    setInputPairs((prevPairs) => [...prevPairs, newPair]); // Add the new pair to the state
  };

  const removeDateRange = (index) => {
    setInputPairs((prevPairs) => prevPairs.filter((_, i) => i !== index));
  };
  const isDateDisabled = (date, currentIndex, isStart) => {
    return inputPairs.some((range, index) => {
      if (index === currentIndex) return false;
      if (isStart) {
        return date >= range.stay_period_start && date <= range.stay_period_end;
      } else {
        return date >= range.stay_period_start && date <= range.stay_period_end;
      }
    });
  };

  const daysMapping = {
    Sat: "Saturday",
    Sun: "Sunday",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
  };

  const daysShort = Object.keys(daysMapping);
  const handleDaysChange = (pairId, day) => {
    setInputPairs((prevInputPairs) =>
      prevInputPairs.map((pair) => {
        if (pair.id === pairId) {
          return {
            ...pair,
            checkedDays: {
              ...pair.checkedDays,
              [day]: !pair.checkedDays[day], // Toggle the selected day only for this pair
            },
          };
        }
        return pair;
      }),
    );
  };

  const {
    data: cities,
    isLoading: loadingCities,
    error: citiesError,
  } = useQuery({
    queryKey: "cities",
    queryFn: fetchCities,
  });

  const chosenCityVal = chosenCity?.value;
  const {
    data: areas,
    isLoading: loadingAreas,
    error: areasError,
    refetch: refetchAreas,
  } = useQuery({
    queryKey: ["areas", chosenCityVal],
    queryFn: () => fetchAreas(chosenCityVal || firstUpgrade?.room?.hotel_name?.city?.id),
    enabled: !!chosenCityVal && chosenCityVal !== "All",
  });
  const {
    data: hotels,
    isLoading: loadingHotels,
    error: hotelsError,
    refetch: refetchHotels,
  } = useQuery({
    queryKey: ["hotels", chosenCityVal, chosenArea],
    queryFn: () => {
      const areaValue = chosenArea?.value === "All" ? areas.map((area) => area.id) : chosenArea?.value;
      return fetchHotels(
        chosenCityVal || firstUpgrade?.room?.hotel_name?.city?.id,
        areaValue || firstUpgrade?.room?.hotel_name?.area?.id,
      );
    },
    enabled: !!chosenCityVal && (!!chosenArea || chosenCityVal === "All"),
  });
  const {
    data: rooms,
    isLoading: loadingRooms,
    error: roomError,
    refetch: refetchRooms,
  } = useQuery({
    queryKey: ["rooms", chosenHotelVal?.value, firstUpgrade?.room?.hotel_name?.id],
    queryFn: () => fetchRooms(chosenHotelVal?.value || firstUpgrade?.room?.hotel_name?.id),
    enabled: !!(chosenHotelVal?.value || firstUpgrade?.room?.hotel_name?.id), // Ensure query only runs if hotel has a value
  });

  useEffect(() => {
    if (chosenCity || firstUpgrade?.room?.hotel_name?.city?.id) {
      if (chosenCity?.value !== "All") {
        refetchAreas();
      } else {
        setChosenArea({ label: "All", value: "All" });
      }
    }
  }, [chosenCity, refetchAreas, firstUpgrade?.room?.hotel_name?.city?.id]);

  useEffect(() => {
    if (chosenArea || firstUpgrade?.room?.hotel_name?.area?.id) {
      refetchHotels();
    }
  }, [chosenArea, refetchHotels, firstUpgrade?.room?.hotel_name?.area?.id]);
  useEffect(() => {
    if (chosenHotelVal?.value || (firstUpgrade?.room?.hotel_name?.id && offerData)) {
      refetchRooms(); // Refetch rooms when hotel value changes
    }
  }, [chosenHotelVal, refetchRooms, firstUpgrade?.room?.hotel_name?.id, offerData]);
  useEffect(() => {
    if (hotels) {
      setChosenHotels(hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })));
    }
  }, [hotels]);

  useEffect(() => {
    if (chosenHotelVal?.value && rooms) {
      setChosenRoomVal(rooms?.map((room) => ({ label: room.room_name, value: room.id })));
    }
  }, [chosenHotelVal?.value, rooms]);

  const cityOptions = useMemo(() => cities?.map((city) => ({ label: city.name, value: city.id })), [cities]);
  const areaOptions = useMemo(
    () => [{ label: "All", value: "All" }, ...(areas?.map((area) => ({ label: area.name, value: area.id })) || [])],
    [areas],
  );
  const hotelOptions = useMemo(() => [...chosenHotels], [chosenHotels]);
  const roomOptions = useMemo(() => {
    if (chosenRoomVal && chosenRoomVal.length > 0) {
      return chosenRoomVal;
    }

    // Return room options if rooms are available
    if (rooms && rooms.length > 0) {
      return rooms.map((room) => ({ label: room.room_name, value: room.id }));
    }

    return []; // Default return value if no room options are available
  }, [chosenRoomVal, rooms]);

  const checkUniqueDates = (pairs, field) => {
    const dates = pairs.map((pair) => pair[field]).filter((date) => date);
    const uniqueDates = new Set(dates);
    return dates.length === uniqueDates.size;
  };
  const arabicRegex = /^[\u0600-\u06FF\u0660-\u0669\d\s]+$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Offer name is required"),
    name_ar: Yup.string()
      .matches(arabicRegex, "Offer name (Arabic) must be in Arabic")
      .required("Offer name (Arabic) is required"),
    description: Yup.string().required("Description is required"),
    description_ar: Yup.string()
      .matches(arabicRegex, "Description (Arabic) must be in Arabic")
      .required("Description (Arabic) is required"),
    inputPairs: Yup.array().of(
      Yup.object().shape({
        stay_period_start: Yup.date()
          .required("Start date is required")
          .nullable()
          .test("unique-start-date", "This start date is already chosen", function (value) {
            return checkUniqueDates(this.parent, "stay_period_start");
          }),
        stay_period_end: Yup.date()
          .required("End date is required")
          .nullable()
          .min(Yup.ref("stay_period_start"), "End date cannot be before start date")
          .test("unique-end-date", "This end date is already chosen", function (value) {
            return checkUniqueDates(this.parent, "stay_period_end");
          }),
      }),
    ),
    // Add more validation as needed
  });
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: offerData?.name,
      name_ar: offerData?.name_ar,
      description: offerData?.description,
      description_ar: offerData?.description_ar,
      document: offerData?.document,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setAdding(true);

      const upgrades = roomFields.map((field) => ({
        room: field.room?.value, // Get selected room
        upgrade_room: field.upgrade_room?.value, // Get selected upgrade room
      }));

      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("name_ar", values.name_ar);
        formData.append("description", values.description);
        formData.append("description_ar", values.description_ar);
        formData.append("upgrades", JSON.stringify(upgrades));

        // Handle document upload
        if (toolDocument.length > 0) {
          for (let doc of toolDocument) {
            if (!doc.valid) {
              toast.error(`File ${doc.name} is not valid because ${doc.errors[0]}`);
              return;
            }
            formData.append("document", doc.file);
          }
        }
        if (toolDocument.length === 0) formData.append("document", "");

        // Handle periods (stay periods and days of week)
        const periods = inputPairs.map((pair) => ({
          start_date: pair.stay_period_start ? new Date(pair.stay_period_start).toISOString().split("T")[0] : "",
          end_date: pair.stay_period_end ? new Date(pair.stay_period_end).toISOString().split("T")[0] : "",
          days_of_week: Object.keys(pair.checkedDays)
            .filter((day) => pair.checkedDays[day])
            .map((day) => `${day}`),
        }));
        formData.append("periods", JSON.stringify(periods));

        // API call
        await upgradeTool(formData);
      } catch (error) {
        toast.error(error.message || "Failed to create new offer");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting, handleBlur } = formik;

  const upgradeRoomOptions = roomOptions ? (
    roomOptions?.filter((val) => val?.value !== roomValue?.value)
  ) : (
    <ButtonLoading />
  );
  const getFilteredRoomOptions = (index) => {
    const selectedRooms = roomFields
      .filter((_, i) => i !== index) // Exclude the current select field
      .map((field) => field.room?.value); // Get the selected room values

    return roomOptions.filter((option) => !selectedRooms.includes(option.value));
  };
  const getFilteredUpgradeRoomOptions = (index) => {
    const selectedUpgradeRooms = roomFields
      .filter((_, i) => i !== index) // Exclude the current select field
      .map((field) => field.upgrade_room?.value); // Get the selected upgrade room values

    // Filter out the already selected rooms and the room from room selector
    return upgradeRoomOptions.filter((option) => {
      return !selectedUpgradeRooms.includes(option.value) && option.value !== roomFields[index]?.room?.value;
    });
  };
  useEffect(() => {
    if (offerData?.upgrades) {
      const mappedRoomFields = offerData.upgrades.map((upgrade) => ({
        room: {
          label: upgrade.room.room_name,
          value: upgrade.room.id,
        },
        upgrade_room: {
          label: upgrade.upgrade_room.room_name,
          value: upgrade.upgrade_room.id,
        },
      }));
      setRoomFields(mappedRoomFields);
    }
  }, [offerData]);
  if (isLoading) {
    <Loading />;
  }
  return isLoading && offerData ? (
    <Loading />
  ) : (
    <section className="formPage-wrap">
      <h2 className="header-h2">Edit Room Extra Tool</h2>
      <form className="form-wrap gap-2 flex flex-col" onSubmit={handleSubmit}>
        <div className="border rounded-md p-2 flex flex-col gap-4">
          <div className="input-par">
            <div className="input-chil">
              <label className="font-semibold w-full flex gap-x-2 text-[#002244]">City</label>
              <Select
                required
                className="w-full h-10 text-gray-800 font-semibold border rounded-lg bg-white"
                options={cityOptions}
                placeholder="Select City"
                onChange={(e) => {
                  setChosenCity(e);
                  setFieldValue("city", e);
                }}
                value={
                  chosenCity || {
                    label: firstUpgrade?.room?.hotel_name?.city?.name,
                    value: firstUpgrade?.room?.hotel_name?.city?.id,
                  }
                }
                isLoading={loadingCities}
              />
              {citiesError && <p className="text-red-500">{citiesError.message}</p>}
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Area</label>
              <Select
                name="area"
                options={areaOptions}
                isSearchable
                value={
                  chosenArea || {
                    label: firstUpgrade?.room?.hotel_name?.area?.name,
                    value: firstUpgrade?.room?.hotel_name?.area?.name,
                  }
                }
                isDisabled={chosenCityVal || firstUpgrade?.room?.hotel_name?.area?.name ? false : true}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.area && touched.area && "rgb(135 27 27)",
                  }),
                }}
                className="w-full h-10 rounded-lg bg-white"
                placeholder="Area"
                onChange={(e) => {
                  setChosenArea(e);
                  setFieldValue("area", e);
                }}
                onBlur={handleBlur}
              />
              {errors.area && touched.area && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.area}
                </div>
              )}
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Hotel</label>
              <Select
                name="hotel"
                options={hotelOptions}
                isSearchable
                isDisabled={(chosenCity && chosenArea) || firstUpgrade?.room?.hotel_name?.id ? false : true}
                value={
                  chosenHotelVal || {
                    label: firstUpgrade?.room?.hotel_name?.name,
                    value: firstUpgrade?.room?.hotel_name?.id,
                  }
                }
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.hotel && touched.hotel && "rgb(135 27 27)",
                  }),
                }}
                className="w-full  rounded-lg bg-white"
                placeholder="Hotel"
                onChange={(e) => {
                  setFieldValue("hotel", e.value);
                  setChosenHotelsVal(e);
                }}
                onBlur={handleBlur}
              />
              {errors.hotel && touched.hotel && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.hotel}
                </div>
              )}
            </div>
          </div>
          {/* <div className="input-par ">
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Room</label>

              <Select
                name="room"
                options={roomOptions}
                isSearchable
                isDisabled={chosenHotelVal || offerData?.room_details?.hotel?.id ? false : true}
                value={
                  roomValue
                    ? { label: roomValue?.label, value: roomValue?.value }
                    : {
                        label: offerData?.room_details?.name,
                        value: offerData?.room_details?.id,
                      }
                }
                className="w-full rounded-lg bg-white"
                placeholder="Select Room"
                onChange={(e) => {
                  setRoomValue(e);
                  setFieldValue("room", e);
                }}
                onBlur={handleBlur}
              />
              {errors.room && touched.room && <div className="text-red-600 text-xs">{errors.room}</div>}
            </div>
            <div className="flex   justify-center items-center border rounded-full bg-[#002244] w-10 h-10 mt-4">
              <FaArrowRightArrowLeft className="flex justify-center items-center text-white" />
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Upgrade Room</label>
              <Select
                name="upgrade_room"
                options={roomOptions}
                isSearchable
                isDisabled={chosenHotelVal || offerData?.room_details?.hotel?.id ? false : true}
                value={
                  roomUpgradeValue
                    ? { label: roomUpgradeValue?.label, value: roomUpgradeValue?.value }
                    : {
                        label: offerData?.upgrade_room_details?.name,
                        value: offerData?.upgrade_room_details?.id,
                      }
                }
                className="w-full rounded-lg bg-white"
                placeholder="Select Upgrade Room"
                onChange={(e) => {
                  setRoomUpgradeValue(e);
                  setFieldValue("upgrade_room", e);
                }}
                onBlur={handleBlur}
              />
              {errors.upgrade_room && touched.upgrade_room && (
                <div className="text-red-600 text-xs">{errors.upgrade_room}</div>
              )}
            </div>
          </div> */}
          <div>
            {roomFields?.map((field, index) => (
              <div className="input-par" key={index}>
                <div className="input-chil">
                  <label className="w-full text-gray-600 font-semibold flex items-center">Room</label>
                  <Select
                    name={`room_${index}`}
                    options={getFilteredRoomOptions(index)}
                    isSearchable
                    value={field.room}
                    className="w-full rounded-lg bg-white"
                    placeholder="Select Room"
                    onChange={(e) => {
                      const updatedFields = [...roomFields];
                      updatedFields[index].room = e;
                      setRoomFields(updatedFields);
                      setFieldValue(`rooms[${index}].room`, e); // Sync with Formik
                    }}
                    onBlur={handleBlur}
                  />

                  {errors[`room_${index}`] && touched[`room_${index}`] && (
                    <div className="text-red-600 text-xs">{errors[`room_${index}`]}</div>
                  )}
                </div>

                <div className="flex justify-center items-center border rounded-full bg-[#002244] w-10 h-10 mt-4">
                  <FaArrowRightArrowLeft className="flex justify-center items-center text-white" />
                </div>

                {/* Upgrade Room Selector */}
                <div className="input-chil">
                  <label className="w-full text-gray-600 font-semibold flex items-center">Upgrade Room</label>
                  <Select
                    name={`upgrade_room_${index}`}
                    options={getFilteredUpgradeRoomOptions(index)}
                    isSearchable
                    value={field.upgrade_room}
                    className="w-full rounded-lg bg-white"
                    placeholder="Select Upgrade Room"
                    onChange={(e) => {
                      const updatedFields = [...roomFields];
                      updatedFields[index].upgrade_room = e;
                      setRoomFields(updatedFields);
                      setFieldValue(`rooms[${index}].upgrade_room`, e); // Sync with Formik
                    }}
                    onBlur={handleBlur}
                  />

                  {errors[`upgrade_room_${index}`] && touched[`upgrade_room_${index}`] && (
                    <div className="text-red-600 text-xs">{errors[`upgrade_room_${index}`]}</div>
                  )}
                </div>

                {/* Delete button */}
                {roomFields.length > 1 && (
                  <button type="button" className="text-red-600 mt-4" onClick={() => handleRemoveRoom(index)}>
                    <FaTrash />
                  </button>
                )}
              </div>
            ))}

            {/* Add button */}
            <button type="button" className="text-green-600 mt-4 flex items-center" onClick={handleAddRoom}>
              <FaPlus className="mr-2" /> Add Another Room
            </button>
          </div>
        </div>
        <div className="input-par">
          <div className="input-chil ">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Offer Name
            </label>
            <input
              id="name"
              type="text"
              className="mt-1 block w-full border p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && touched.name && <div className="text-red-600 text-sm">{errors.name}</div>}
          </div>
          <div className="input-chil">
            <label dir="rtl" htmlFor="name_ar" className="block text-sm font-medium text-gray-700">
              إسم العرض
            </label>
            <input
              id="name_ar"
              dir="rtl"
              type="text"
              className="mt-1 block border p-2 w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={values.name_ar}
              onChange={handleChange}
            />
            {errors.name_ar && touched.name_ar && <div className="text-red-600 text-sm">{errors.name_ar}</div>}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            rows={5}
            dir="ltr"
            id="description"
            className="mt-1 border p-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={values.description}
            onChange={handleChange}
          />
          {errors.description && touched.description && (
            <div className="text-red-600 text-sm">{errors.description}</div>
          )}
        </div>
        <div className="form-group">
          <label dir="rtl" htmlFor="name_ar" className="block text-sm font-medium text-gray-700">
            الوصف{" "}
          </label>
          <textarea
            rows={5}
            dir="rtl"
            id="description_ar"
            className="mt-1 block w-full border p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={values.description_ar}
            onChange={handleChange}
          />
          {errors.description_ar && touched.description_ar && (
            <div className="text-red-600 text-sm">{errors.description_ar}</div>
          )}
        </div>
        <div className="border  p-2 rounded-lg ">
          <label
            htmlFor="validities"
            className="text-center !text-lg font-semi-bold header-h2 justify-center flex w-full"
          >
            Validities
          </label>
          <div className="flex flex-col gap-2">
            {inputPairs &&
              inputPairs?.map((pair, index) => (
                <div key={pair.id} className="border p-4 rounded-lg bg-gray-50 space-y-4 relative">
                  <label className="block text-lg font-semibold">Stay Period {index + 1}</label>
                  <div className="input-par">
                    <div className="input-chil">
                      <label htmlFor={`start_date_${pair.id}`} className="block text-sm font-medium text-gray-700">
                        Start Date
                      </label>
                      <input
                        id={`start_date_${pair.id}`}
                        type="date"
                        className="mt-1 block w-full py-1.5 px-2 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm floating-input"
                        value={pair.stay_period_start || ""}
                        onChange={(e) => {
                          const startDate = e.target.value;
                          setInputPairs((prevInputPairs) =>
                            prevInputPairs.map((p) => (p.id === pair.id ? { ...p, stay_period_start: startDate } : p)),
                          );
                        }}
                        min={inputPairs[index - 1]?.stay_period_end || ""}
                        disabled={isDateDisabled(new Date(pair.stay_period_start), index, true)}
                        required
                      />
                    </div>
                    <div className="input-chil">
                      <label htmlFor={`end_date_${pair.id}`} className="block text-sm font-medium text-gray-700">
                        End Date
                      </label>
                      <input
                        id={`end_date_${pair.id}`}
                        type="date"
                        className="mt-1 block w-full py-1.5 px-2 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm floating-input"
                        value={pair.stay_period_end || ""}
                        onChange={(e) => {
                          const endDate = e.target.value;
                          setInputPairs((prevInputPairs) =>
                            prevInputPairs.map((p) => (p.id === pair.id ? { ...p, stay_period_end: endDate } : p)),
                          );
                        }}
                        min={pair.stay_period_start || ""}
                        disabled={isDateDisabled(new Date(pair.stay_period_end), index, false)}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2 w-full">
                    <div className="flex flex-col gap-2 w-full">
                      <label htmlFor="activeDays"> Active Days</label>
                      <div className="grid lg:grid-cols-7 md:grid-cols-4 grid-cols-2  w-full">
                        {daysShort?.map((dayShort) => (
                          <div className="flex w-full" key={dayShort}>
                            <label className="flex items-center space-x-2">
                              <input
                                type="checkbox"
                                checked={pair.checkedDays[dayShort] || false}
                                onChange={() => handleDaysChange(pair.id, dayShort)}
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                              <span className="text-sm text-gray-700">{daysMapping[dayShort]}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className=" mt-4">
                      {clicked && (
                        <p className="text-red-700 text-sm">
                          {pair?.stay_period_start === null || pair?.stay_period_end === null
                            ? "Please select a start date and end date"
                            : ""}
                        </p>
                      )}
                    </div>
                  </div>
                  {inputPairs?.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeDateRange(index)}
                      className="absolute top-0 right-[3%] flex items-center bg-red-600 hover:bg-red-700 text-sm bg text-white rounded-full p-3"
                    >
                      <MdDelete size={20} className="rounded-full" />
                    </button>
                  )}
                </div>
              ))}
          </div>
          <div className="mt-4 flex justify-center">
            <button
              type="button"
              onClick={addDateRange}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Date Range
            </button>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="document" className="block text-sm font-medium text-gray-700">
            Upgrade Document
          </label>
          <FileUploader
            maxFiles={1}
            maxFileSize={2}
            accept={"image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .csv"}
            setFiles={setToolDocument}
            files={toolDocument}
            urls={[offerData?.document]}
          />
          {errors.document && touched.document && <div className="text-red-600 text-sm">{errors.document}</div>}
        </div>
        <div className="mt-6 flex justify-center items-center">
          <button type="submit" disabled={isPending} className="updateButton">
            {isPending ? (
              <div className="flex items-center gap-2">
                Updating...
                <ButtonLoading />
              </div>
            ) : (
              "Update"
            )}
          </button>
        </div>
      </form>
    </section>
  );
};

export default AddRoomExtraTool;
