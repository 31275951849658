import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "Chart.js Line Chart - Multi Axis",
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [100, 55, 700, 90, 1005, 85, 200, 1500],
      borderColor: "rgb(170 132 83",
      backgroundColor: "rgb(170 132 83",
      yAxisID: "y",
    },
    {
      label: "Dataset 2",
      data: [200, 255, 300, 890, 755, 755, 350],
      borderColor: "rgb(0, 71, 171)",
      backgroundColor: "rgb(0, 71, 171)",
      yAxisID: "y1",
    },
  ],
};

export default function LineChart() {
  return (
    <div>
      <Line options={options} data={data} style={{ width: "200px" }} />
    </div>
  );
}
