import React, { useEffect, useRef, useState } from "react";
import { PiUsersThreeFill } from "react-icons/pi";
import { editData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import useData from "../../../customHooks/useData";
import Select from "react-select";
import { useNavigate, useParams } from "react-router";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { GoPerson } from "react-icons/go";
import { NotificationContainer, NotificationManager } from "react-notifications";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { RxUpdate } from "react-icons/rx";
import { MdOutlineMailOutline } from "react-icons/md";
import { BiUserCircle } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi2";

export default function EditUsers() {
  const formRef = useRef(null);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(true);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [chosenGroup, setChosenGroup] = useState(null);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const groups = useData("permissions/api/v1/user-groups/");
  const user = useData(`/auth/api/v1/users/${id}/`);

  const groupsArr = groups ? groups.map((group) => ({ label: group.name, value: group.id })) : [];

  useEffect(() => {
    if (formRef.current) {
      const form = formRef.current;
      setIsEnabled(user.is_enabled);
      setIsWhitelisted(user.is_whitelisted);
      form.first_name.value = user.first_name;
      form.last_name.value = user.last_name;
      form.email.value = user.email;
      form.username.value = user.username;
      if (groups && user.groups.length > 0) {
        setChosenGroup({ label: user.groups[0].name, value: user.groups[0].id });
      }
    }
  }, [formRef.current, user, groups]);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      username: e.target.username.value,
      email: e.target.email.value,
      is_enabled: isEnabled,
      is_whitelisted: isWhitelisted,
      group_id: chosenGroup.value,
    };

    setLoad(true);
    dispatch(
      editData(
        `/auth/api/v1/users/${id}/`,
        data,
        () => {
          setLoad(false);
          lightToast.success("The user was updated successfully");
          navigate(-1);
        },
        (error) => {
          setLoad(false);
          NotificationManager.error(` ${error?.response?.data[0]?.field_name}  ${error?.response?.data[0]?.message}`);
        },
      ),
    );
  }

  return (
    <div className="formPage-wrap min-h-screen">
      {groups && user && (
        <div className="flex flex-col items-center h-full w-full">
          <div className=" w-full">
            <div className=" flex justify-center ">
              <h2 className="header-h2">
                <PiUsersThreeFill className="w-5 h-5" />
                Edit Users
              </h2>
            </div>
            <form className="form-wrap  w-full" onSubmit={handleSubmit} encType="multipart/form-data" ref={formRef}>
              <div className="w-full flex flex-col justify-center py-4  gap-y-8">
                <div className="input-par">
                  <div className="relative input-chil">
                    <label className="  text-[#002244] font-semibold ">First Name</label>
                    <input
                      placeholder="First Name"
                      name="first_name"
                      type="text"
                      required
                      onChange={(e) => {}}
                      className="w-full pl-10 h-10 border rounded-lg bg-white"
                    />
                    <div className="absolute top-[33px] left-3">
                      <GoPerson className="w-5 h-5 text-[#002244]" />
                    </div>
                  </div>
                  <div className="relative input-chil">
                    <label className="  text-[#002244] font-semibold ">Last Name</label>
                    <input
                      placeholder="Last Name"
                      name="last_name"
                      type="text"
                      required
                      onChange={(e) => {}}
                      className="w-full pl-10 h-10 border rounded-lg bg-white"
                    />
                    <div className="absolute top-[33px] left-3">
                      <GoPerson className="w-5 h-5 text-[#002244]" />
                    </div>
                  </div>
                </div>
                <div className="input-par">
                  <div className="input-chil">
                    <label className=" text-[#002244] font-semibold  ">Email</label>
                    <div className="w-full relative">
                      <input
                        placeholder="Email"
                        name="email"
                        type="email"
                        required
                        onChange={(e) => {}}
                        className="w-full pl-10 h-10 border rounded-lg bg-white"
                      />
                      <div className="absolute top-[10px] left-3">
                        <MdOutlineMailOutline className="w-5 h-5 text-[#002244]" />
                      </div>
                    </div>
                  </div>
                  <div className="input-chil">
                    {" "}
                    <label className="text-[#002244] font-semibold ">User Name</label>
                    <div className="w-full relative">
                      <input
                        placeholder="User Name"
                        name="username"
                        type="text"
                        required
                        onChange={(e) => {}}
                        className="w-full pl-10 h-10  border rounded-lg bg-white "
                      />
                      <div className="absolute top-[10px] left-3">
                        <BiUserCircle className="w-5 h-5 text-[#002244]" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <label className=" text-[#002244] font-semibold  ">Group</label>
                  <div className="relative w-full border rounded-lg">
                    <Select
                      options={groupsArr}
                      className="w-full h-10  border rounded-lg bg-white pl-8"
                      required
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,

                          borderBottom: "none",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          boxShadow: "none",
                          borderRadius: "0",
                        }),
                      }}
                      onChange={(e) => {
                        setChosenGroup(e);
                      }}
                      value={chosenGroup}
                    />
                    <div className="absolute top-[10px] left-3">
                      <HiOutlineUserGroup className="w-5 h-5 text-[#002244]" />
                    </div>
                  </div>
                </div>

                <div className="w-full flex justify-around max-sm:flex-col">
                  <div className="shadow-md border rounded-md px-4 py-1.5">
                    <input
                      type="checkbox"
                      name="enabled"
                      className="mx-2"
                      checked={isEnabled}
                      onChange={() => setIsEnabled(!isEnabled)}
                    />
                    <label htmlFor="enabled">Active</label>
                  </div>
                  <div className="shadow-md border rounded-md px-4 py-1.5">
                    <input
                      type="checkbox"
                      name="whitlisted"
                      className="mx-2"
                      checked={isWhitelisted}
                      onChange={() => setIsWhitelisted(!isWhitelisted)}
                    />
                    <label htmlFor="whitelisted">Whitelisted</label>
                  </div>
                </div>
                <div className="flex justify-center items-center mt-6">
                  <button type="Submit" disabled={isDisabled || load} className="updateButton">
                    Edit
                    {load ? (
                      <>
                        <TopBarProgress />
                        <ButtonLoading />
                      </>
                    ) : (
                      <RxUpdate className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {!groups && !user && (
        <div className="w-full flex items-center justify-center h-[90%]">
          <Loading /> <TopBarProgress />
        </div>
      )}
      <NotificationContainer />
    </div>
  );
}
