import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { RoomStatus } from "../../../redux/actions/filterAction";
import { FaCheck } from "react-icons/fa";

const RadioInput = ({ roomType, type }) => {
  const [check, setCheck] = useState(false);
  const checkOfType = false;

  const dispatch = useDispatch();
  const handleFilter = async () => {
    setCheck(!check);
    check ? dispatch(RoomStatus(type, checkOfType)) : dispatch(RoomStatus(type, !checkOfType));
  };
  return (
    <div onClick={handleFilter} className="w-full flex flex-col">
      <div className="flex gap-x-1 items-center  w-full ">
        <div
          id="radio-2"
          className={` flex items-center justify-center border border-solid cursor-pointer rounded-sm border-gray-500 w-5 h-5 text-white ${
            check ? "bg-lion" : ""
          } `}
        >
          {" "}
          {check ? <FaCheck className="w-4 h-4" /> : null}
        </div>
        <label htmlFor="radio-2" className="cursor-pointer ">
          <span className="text-sm font-semibold text-black capitalize">{roomType}</span>
        </label>
      </div>
    </div>
  );
};

export default RadioInput;
