import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiKeyboardLine } from "react-icons/ri";
import { BsDatabaseAdd } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { Link } from "react-router-dom";

const CollectionRequestPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["room_booking.add_collection", "room_booking.view_collection"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        <div className="w-full ">
          <div className="w-full flex justify-center ">
            <h2 className="header-h2">
              {" "}
              <RiKeyboardLine className="w-5 h-5" />
              Collection Request
            </h2>
          </div>

          <div className="grid sm:grid-cols-2 grid-cols-1  gap-y-5 form-wrap">
            {permission.map((item) => {
              if (item.name === "room_booking.add_collection" && item.value === true) {
                return (
                  <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                    <Link
                      to={"./add-collection-request"}
                      // onClick={() => navigate("./add-collection-request")}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <div className="relative flex flex-col">
                          <BsDatabaseAdd className="w-12 h-12" />
                        </div>
                      </div>
                    </Link>
                    <p className="text-lg font-semibold">Add Collection Request</p>
                  </div>
                );
              } else if (item.name === "room_booking.view_collection" && item.value === true) {
                return (
                  <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                    <Link
                      to={"./collection-request-list"}
                      // onClick={() => navigate("./collection-request-list")}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <AiOutlineUnorderedList className="w-12 h-12   " />
                      </div>
                    </Link>
                    <p className="text-lg font-semibold">Colllection Request List </p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default CollectionRequestPage;
