import React, { useEffect, useMemo, useState } from "react";
import { FaWpforms } from "react-icons/fa6";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { destinationAction, getHotelCityAction } from "../../../redux/actions/searchformAction";
import cookie from "react-cookies";
import { requestOfferTypes } from "../../../redux/actions/addContract";

import {
  getCancellationCharge,
  getCancellationPolicyId,
  getCancellationTime,
  updateCancellationPolicy,
} from "../../../redux/actions/cancellation";
import { DateRangePicker } from "react-dates";
import { useNavigate, useParams } from "react-router";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import TopBarProgress from "react-topbar-progress-indicator";
import { NotificationContainer, NotificationManager } from "react-notifications";
import lightToast from "light-toast";
import Loading from "../Loading/Loading";
import { IoMdAdd } from "react-icons/io";
import { HiOutlineMinusSmall } from "react-icons/hi2";
import { GrUpdate } from "react-icons/gr";
import { useFormik } from "formik";
import { getCurrencyTypes_URL } from "../../../urls";
import useData from "../../../customHooks/useData";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/en-gb"; // Set locale for formatting
import { DatePicker, Button, Space, message } from "antd"; // Ensure message is imported

const { RangePicker } = DatePicker;
const token = cookie.load("access_token");

function reshapeOptions(option) {
  return option
    .split("_")
    .map((word) => word[0].toUpperCase() + word.split("").slice(1).join(""))
    .join(" ");
}

export default function EditCancelation() {
  const [chargeForm, setChargeForm] = useState([{ id: 1, selectValue: "", input1: 0, input2: 0 }]);
  const [chargeValue, setChargeValue] = useState("");
  const [daysValue, setDaysValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  useRemoveScroll();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { destinationData } = useSelector((state) => state.SearchFormReducer);
  const [desFlag, setDesFlag] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [hotelFlag, setHotelFlag] = useState(false);
  const [charge, setCharge] = useState([]);
  const [cancellationTime, setCancellationTime] = useState([]);
  const [offerTypes, setOfferTypes] = useState([]);
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenHotel, setChosenHotel] = useState(null);
  const [chosenOffer, setChosenoffer] = useState(null);
  const [chosenCharge, setChosenCharge] = useState([]);
  const [chosenCancellationTime, setChosenCancellationTime] = useState(null);
  // const [dateRange, setDateRange] = useState([{ startDate: null, endDate: null }]);
  const [focusedInput, setFocusedInput] = useState([null]);
  const [btnFlag, setBtnFlag] = useState(false);
  const [chargeArrData, setChargeArr] = useState([]);
  const [selectedCharge, setSelectedCharge] = useState("");
  // const [selectedCharge, setSelectedCharge] = useState([{ id: 1, charge: "", num_days: 0, price: 0 }]);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedOffer, setSelectedOffer] = useState("");
  const [startDateVal, setStartDateVal] = useState();
  const [endDateVal, setEndDateVal] = useState();
  const [defaultDate, setDefaultDate] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [chargeData, setChargeData] = useState([]);
  const [DateStart, setDateStart] = useState([]);
  const [DateEnd, setDateEnd] = useState([]);
  const [specificHotels, setSpecificCtiyHotels] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [dateRange, setDateRange] = useState({ startDate: startDateVal, endDate: endDateVal });

  const citiesArr = useMemo(
    () => destinationData?.map((city) => ({ label: city.name, value: city.id })),
    [destinationData],
  );
  const [dateRanges, setDateRanges] = useState([{ startDate: null, endDate: null }]);
  const [error, setError] = useState("");

  // Check if the new range overlaps with existing ranges
  const isOverlap = (newRange) => {
    return dateRanges?.some(({ range }) => {
      if (!range || !newRange) return false;
      const [startA, endA] = range;
      const [startB, endB] = newRange;
      return startA.isBefore(endB) && startB.isBefore(endA);
    });
  };

  // Handle adding new range picker
  const handleAddRange = () => {
    setDateRanges([...dateRanges, { key: Date.now(), range: null }]);
    setError(""); // Clear error on adding new range
  };

  // Handle removing range picker
  const handleRemoveRange = (key) => {
    setDateRanges(dateRanges?.filter((item) => item.key !== key));
    setError(""); // Clear error on removing range
  };

  // Handle date selection change
  const handleDateChange = (dates, key) => {
    if (isOverlap(dates)) {
      setError("Date range overlaps with an existing range!");
      message.error("Date range overlaps with an existing range!");
    } else {
      setError("");
      setDateRanges(dateRanges?.map((item) => (item.key === key ? { ...item, range: dates } : item)));
    }
  };
  const hotelsArr = useMemo(() => hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })), [hotels]);
  const chargeArr = useMemo(() => charge.map((elem) => ({ label: reshapeOptions(elem), value: elem })), [charge]);
  const offerTypesArr = useMemo(() => offerTypes.map((type) => ({ label: type.name, value: type.id })), [offerTypes]);
  const cancellationTimeArr = useMemo(
    () => cancellationTime.map((elem) => ({ label: reshapeOptions(elem), value: elem })),
    [cancellationTime],
  );

  const params = useParams();
  useEffect(() => {
    setChargeArr(charge.map((elem) => ({ label: reshapeOptions(elem), value: elem })));
  }, [chargeData]);
  // useEffect(() => {
  //   if (chargeForm.length > chargeArr.length) {
  //     setShow(false);
  //   }
  // }, [chargeArr, chargeForm]);

  const addCharge = () => {
    // Clone the last charge object or create a new one with default values
    const lastCharge = chargeData[chargeData.length - 1];
    const newCharge = {
      id: lastCharge.id + 1, // Assign a new unique ID
      charge: "", // Default values for charge, num_days, and price
      num_days: 0,
      price: 0,
      currency: null,
    };

    // Add the new charge object to the selectedCharge array
    setChargeData([...chargeData, newCharge]);
  };
  const currencies = useData(getCurrencyTypes_URL);

  let currenciesArr = currencies ? currencies.map((currency) => ({ value: currency, label: currency })) : [];

  // const removeCharge = (id) => {
  //   if (chargeForm.length === 1) {
  //     return;
  //   }
  //   const selectedForm = chargeForm.filter((form) => form.id !== id);
  //   const removedForm = chargeForm.find((form) => form.id === id);
  //   setChosenCharge.filter((option) => option !== removedForm.selectValue);

  //   // const updatedForms = [...chargeForm];
  //   // updatedForms.pop();
  //   setChargeForm(selectedForm);
  // };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedForms = [...chargeData];
    updatedForms[index] = { ...updatedForms[index], [name]: value };
    setChargeData(updatedForms);

    // Update selected options
    setSelectedOptions((prev) => {
      const newSelectedOptions = [...prev];
      newSelectedOptions[index] = value;
      return newSelectedOptions;
    });
  };
  // const handleSelectedChange = (id, value) => {
  //   handleChange(id, "selectValue", value);
  //   setChosenCharge([...chosenCharge, value]);
  // };
  const removeCharge = (id) => {
    // Filter out the charge with the given ID
    const updatedCharges = chargeData?.filter((charge) => charge.id !== id);
    // Update the selectedCharge array
    setChargeData(updatedCharges);
  };

  const handleSelectedChange = (id, value) => {
    handleChange(id, "charge", value);

    // Update available options
    const updatedChargeArr = chargeArr?.filter((option) => !chargeData.some((form) => form.charge === option.value));
    setChargeArr(updatedChargeArr);
  };

  useEffect(() => {
    dispatch(
      destinationAction(token, "en", (result) => {
        setDesFlag(true);
      }),
    );
    dispatch(requestOfferTypes((resp) => setOfferTypes(resp)));
    dispatch(getCancellationCharge((resp) => setCharge(resp)));
    dispatch(getCancellationTime((resp) => setCancellationTime(resp)));
  }, []);

  // [
  //   {
  //     charge: chargeValue,
  //     num_days: e.target.num_days.value,
  //     price: e.target.price.value,
  //   },
  // ],

  // const { selectValue } = chargeForm;

  // const { input1 } = chargeForm;

  // const { input2 } = chargeForm;

  useEffect(() => {
    dispatch(
      getCancellationPolicyId(token, params.id, (result) => {
        if (result.status === 200) {
          setFieldValue("cancellation_valid_from", result.data.cancellation_valid_from.slice(0, 10));
          setStartDateVal(result.data.cancellation_valid_from.slice(0, 10));
          setEndDateVal(result.data.cancellation_valid_to.slice(0, 10));
          setFieldValue("hotel", result.data.hotel.id);
          setSelectedHotel({ label: result?.data?.hotel?.name, value: result?.data?.hotel?.id });
          setFieldValue("cities", result.data.hotel.city);
          setSelectedCity([{ label: result.data.hotel.city_name, value: result.data.hotel.city }]);
          setSpecificCtiyHotels(result.data.hotel.city);
          setChargeData(result.data.cancellation_charge);
          setFieldValue("cancellation_valid_to", result.data.cancellation_valid_to.slice(0, 10));
          // setFieldValue("cancellation_before", result.data.cancellation_before);
          setFieldValue("cancellation_charge", result.data.cancellation_charge);
          // setSelectedCharge(result.data.cancellation_charge.length > 0 ? result.data.cancellation_charge : []);
          // setFieldValue(
          //   "num_days",
          //   result.data.cancellation_charge.map((policy) => policy.num_days)
          // );
          // setFieldValue(
          //   "price",
          //   result.data.cancellation_charge.map((policy) => policy.price)
          // );
          setFieldValue("notes", result.data.notes);
          setFieldValue("cancellation_time", result.data.cancellation_time);
          setSelectedTime({ label: result.data.cancellation_time, value: result.data.cancellation_time });
        }
        setFieldValue("offer_type", result?.data?.offer_type?.id);
        setSelectedOffer({ label: result?.data.offer_type?.name, value: result?.data?.offer_type?.id });
        setDateRanges(
          result?.data?.cancellation_period?.map((period) => ({
            key: period.id, // Assuming there's an 'id' or unique key
            range: [dayjs(period.valid_from.slice(0, 10)), dayjs(period.valid_to.slice(0, 10))],
          })),
        );
        setDateRange({
          startDate: moment(result.data.cancellation_valid_from.slice(0, 10)),
          endDate: moment(result.data.cancellation_valid_to.slice(0, 10)),
        });
      }),
    );
  }, [dispatch, params.id]);
  // Form State
  const { values, handleSubmit, handleBlur, setSubmitting, isSubmitting, touched, errors, setFieldValue } = useFormik({
    initialValues: {
      cities: "",
      cancellation_valid_from: "",
      cancellation_valid_to: "",
      offer_type: "",

      notes: "",
      cancellation_time: "",
      cancellation_charge: "",
    },
    // validationSchema: CancellationValidation,
    onSubmit: submit,
  });
  // const handleDateChange = ({ startDate, endDate }) => {
  //   setDateRange({ startDate, endDate });
  // };
  // const handleDateChange = ({ startDate, endDate }) => {
  //   setDateRange({ startDate, endDate });
  //   setDefaultDate(false);
  //   setFieldValue("cancellation_valid_from", startDate);
  //   setFieldValue("cancellation_valid_to", endDate);
  //   setDateStart(startDate);
  //   setDateEnd(endDate);
  // };
  //

  //

  // const cancelCharge = selectedCharge?.map((form) => ({
  //   charge: form.selectValue,
  //   num_days: form.input1,
  //   price: form.input2,
  // }));

  useEffect(() => {
    if (specificHotels) {
      dispatch(
        getHotelCityAction(token, specificHotels, (resp) => {
          if (resp.status === 200) {
            setHotels(resp.data);
            //   setFieldValue(e.label);
          } else {
            setHotelFlag(false);
          }
        }),
      );
    }
  }, [specificHotels, dispatch]);

  function submit(data) {
    const cancelCharge = chargeData?.map((item) => ({
      charge: item.charge,
      num_days: item.num_days,
      price: item.price,
      currency: item.currency,
    }));
    //

    setBtnFlag(true);
    const cancellationPeriod = dateRanges?.map((date) => {
      const { range } = date;
      return {
        valid_from: range && range[0] ? dayjs(range[0])?.format("YYYY-MM-DD") : null,
        valid_to: range && range[1] ? dayjs(range[1])?.format("YYYY-MM-DD") : null,
      };
    });

    const dataVal = {
      // cancellation_valid_from: DateStart,
      // cancellation_valid_to: DateEnd,
      cancellation_period: cancellationPeriod,
      cancellation_valid_from: cancellationPeriod.startDate,
      cancellation_valid_to: cancellationPeriod.endDate,
      cancellation_charge: cancelCharge,
      notes: data.notes,
      offer_type: values?.offer_type,
      hotel: data.hotel,
      cancellation_time: data.cancellation_time,
    };

    dispatch(
      updateCancellationPolicy(token, params.id, dataVal, (result) => {
        if (result.status === 200) {
          setSubmitting(false);
          lightToast.success("Cancellation policy List Updated successfully");
          navigate(-1);
        } else {
          setSubmitting(false);
          lightToast.error("Cancellation policy List failed to Update ");

          NotificationManager.error(result?.response?.data[0]?.message);
        }
      }),
    );
  }

  return (
    <div className=" formPage-wrap">
      <div className="flex flex-col items-center justify-center px-6 h-full w-full">
        {desFlag ? (
          <div className=" w-full  ">
            <div className=" flex justify-center ">
              <h2 className="header-h2">
                <FaWpforms className="w-5 h-5" />
                Update Cancellation Policy
              </h2>
            </div>
            {selectedHotel && selectedOffer ? (
              <form className="form-wrap  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                <div className="w-full flex flex-col justify-center items-center gap-y-2">
                  <div className="input-par flex items-center">
                    <div className="input-child flex flex-col w-full">
                      <label className="text-gray-900 font-semibold">Date Range</label>
                      <div className="grid w-full justify-start grid-cols-1 gap-2">
                        {dateRanges?.length > 0 ? (
                          dateRanges?.map((item, index) => (
                            <div key={index} style={{ marginBottom: 16 }}>
                              <RangePicker
                                value={item.range} // This is how you pass the start and end date
                                onChange={(dates) => handleDateChange(dates, item.key)}
                                style={{ marginRight: 8 }}
                                required
                              />
                              {dateRanges?.length > 1 && (
                                <Button danger onClick={() => handleRemoveRange(item.key)}>
                                  Remove
                                </Button>
                              )}
                            </div>
                          ))
                        ) : (
                          <DateRangePicker
                            startDate={dateRange.startDate}
                            startDateId="startDate"
                            endDate={dateRange.endDate}
                            endDateId="endDate"
                            onDatesChange={handleDateChange}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                            minimumNights={0} // Allow same-day selection
                            daySize={30} // Allow same-day selection
                            required
                          />
                        )}
                        <Button type="dashed" className="addButton" onClick={handleAddRange}>
                          Add Date Range
                        </Button>

                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col ">
                    <label className=" text-center  text-gray-900 font-semibold ">City</label>
                    <Select
                      name="cities"
                      className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                      options={citiesArr}
                      placeholder="Select City"
                      //   value={values.cities ? [{ value: values.cities, label: values.cities }] : selectedCity}
                      onChange={(e) => {
                        setFieldValue(e.value);
                        setHotelFlag(true);
                        setChosenCity(e);
                        dispatch(
                          getHotelCityAction(token, e.value, (resp) => {
                            if (resp.status === 200) {
                              setHotelFlag(false);
                              setHotels(resp.data);
                              //   setFieldValue(e.label);
                            } else {
                              setHotelFlag(false);
                            }
                          }),
                        );
                      }}
                      defaultValue={selectedCity}
                      // value={chosenCity}
                    />
                    {touched.cities && errors.cities ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                        {errors.cities}
                      </div>
                    ) : null}
                  </div>

                  <label className="  text-gray-900 font-semibold ">Hotel</label>
                  {hotelFlag ? (
                    <div className="w-full flex items-center justify-center ">
                      <ButtonLoading /> <TopBarProgress />
                    </div>
                  ) : (
                    <Select
                      className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                      options={hotelsArr}
                      defaultValue={selectedHotel}
                      // placeholder={selectedHotel?.label ? selectedHotel?.label : " Hotel Name"}
                      onChange={(e) => {
                        setFieldValue("hotel", e.value);
                        setSelectedHotel(e.label);
                      }}
                      // value={chosenHotel}
                      // value={selectedHotel?.label && selectedHotel?.label}
                    />
                  )}
                  <label className="  text-gray-900 font-semibold ">Offer Type</label>
                  <Select
                    className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                    // defaultInputValue={selectedOffer ? selectedOffer?.label : " type offer"}
                    // value={selectedOffer ? selectedOffer?.value : " type offer"}
                    options={offerTypesArr}
                    placeholder={selectedOffer ? selectedOffer?.label : " type offer"}
                    onChange={(e) => {
                      setChosenoffer(e.value);
                      setSelectedOffer(e.label);
                      setFieldValue("offer_type", e?.value);
                    }}
                    defaultValue={selectedOffer}

                    // value={chosenOffer}
                  />
                  {touched.offer_type && errors.offer_type ? (
                    <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                      {errors.offer_type}
                    </div>
                  ) : null}

                  <label className="  text-gray-900 font-semibold ">Charge</label>

                  {chargeData?.map((item, index) => (
                    <div key={item?.id} className="flex flex-col gap-2 w-full border rounded-md shadow-md p-2">
                      <div className="flex gap-2 w-full">
                        <div className=" flex flex-col gap-2 flex-1">
                          <label htmlFor="">Charge</label>

                          <select
                            className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                            name="charge"
                            value={item.charge}
                            onChange={(e) => {
                              // handleSelectedChange(item.id, e.target.value);
                              handleChange(e, index);
                              setFieldValue("cancellation_charge", e.target.value);
                              // setChargeData(e.target.value);
                              setSelectedCharge(e.target.value);
                            }}
                          >
                            <option selected>--select Charge--</option>
                            {chargeArr?.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                disabled={
                                  chargeData.some((item) => item.charge === option.value) &&
                                  chargeData.findIndex((item) => item.charge === option.value) !== index
                                }
                                // disabled={selectedOptions.includes(option) && selectedOptions.indexOf(option) !== index}
                              >
                                {option?.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        {item?.charge === "First Night" && (
                          <div className="flex items-center gap-2 flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                            {/* <input
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Price"
                              name="price"
                              onChange={(e) => handleChange(form.id, "input2", Number(e.target.value))}
                            /> */}
                          </div>
                        )}
                        {item?.charge === "first_night" && (
                          <div className="flex items-center gap-2 flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                            {/* <input
                              type="number"
                              className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                              placeholder="Price"
                              name="price"
                              onChange={(e) => handleChange(form.id, "input2", Number(e.target.value))}
                            /> */}
                          </div>
                        )}
                        {item?.charge === "First Two Night" && (
                          <div className="flex items-center gap-2 flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {item?.charge === "first_two_night" && (
                          <div className="flex items-center gap-2 flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>
                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {item?.charge === "First Three Night" && (
                          <div className="flex items-center gap-2 flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {item?.charge === "first_three_night" && (
                          <div className="flex items-center gap-2 flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>
                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {item?.charge === "Whole Period" && (
                          <div className="flex items-center gap-2 flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {item?.charge === "whole_period" && (
                          <div className="flex items-center gap-2 flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>
                              {}

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {item?.charge === "Percentage Amount" && (
                          <div className="flex items-center gap-2 flex-1 max-md:flex-col w-full">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Percentage</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.price}
                                defaultValue={item?.price}
                                name="price"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.price && errors.price ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.price}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {item?.charge === "percentage_amount" && (
                          <div className="flex items-center gap-2 flex-1 max-md:flex-col   w-full">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of days</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Percentage</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.price}
                                defaultValue={item?.price}
                                name="price"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.price && errors.price ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.price}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {item?.charge === "Fixed Amount" && (
                          <div className="flex items-center gap-2 flex-1  max-md:flex-col w-full ">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of Days</label>

                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                            <div className=" flex flex-col gap-2 w-full">
                              <label htmlFor="">Price</label>
                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.price}
                                defaultValue={item?.price}
                                name="price"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              <select
                                className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                                name="charge"
                                value={item.currency}
                                onChange={(e) => {
                                  // handleSelectedChange(item.id, e.target.value);
                                  handleChange(e, index);
                                  // setFieldValue("cancellation_charge", e.target.value);
                                  // setChargeData(e.target.value);
                                  setSelectedCurrency(e.target.value);
                                }}
                              >
                                <option selected disabled>
                                  --select currency--
                                </option>
                                {currenciesArr?.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                    // disabled={
                                    //   chargeData.some((item) => item.charge === option.value) &&
                                    //   chargeData.findIndex((item) => item.charge === option.value) !== index
                                    // }
                                    // disabled={selectedOptions.includes(option) && selectedOptions.indexOf(option) !== index}
                                  >
                                    {option?.label}
                                  </option>
                                ))}
                              </select>
                              {/* <Select
                              name="currency"
                              options={currenciesArr}
                              isSearchable
                              placeholder={item?.currency}
                              defaultValue={item?.currency}
                              onChange={(e) => {
                                setSelectedCharge(e.value);
                                handleChange(e, index);
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,

                                  borderBottom: "none",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  boxShadow: "none",
                                  borderRadius: "0",
                                }),
                              }}
                              // value={[{ label: values.currency, value: values.currency }]}
                              className={`w-full  pl-5 md:pl-8 border rounded-md text-sm md:text-base ${
                                touched.currency && errors.currency && "border-red-800"
                              } `}
                              // onChange={(e) => setFieldValue("currency", e.value)}
                              onBlur={handleBlur}
                            /> */}
                              {/* {touched.price && errors.price ? (
                              <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                {errors.price}
                              </div>
                            ) : null} */}
                            </div>
                          </div>
                        )}
                        {item?.charge === "fixed_amount" && (
                          <div className="flex items-center gap-2 flex-1  max-md:flex-col w-full ">
                            <div className="flex flex-col gap-2 w-full">
                              <label htmlFor="">Number of Days</label>
                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.num_days || "Number of days"}
                                defaultValue={item?.num_days === 0 ? Number(0) : item?.num_days}
                                name="num_days"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />
                              {touched.num_days && errors.num_days ? (
                                <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                  {errors.num_days}
                                </div>
                              ) : null}
                            </div>
                            <div className=" flex flex-col gap-2 w-full">
                              <label htmlFor="">Price</label>
                              <input
                                type="number"
                                className="w-full px-3 h-10  border-2 border-black border-opacity-20 rounded-lg bg-white"
                                placeholder={item?.price}
                                defaultValue={item?.price}
                                name="price"
                                onChange={(e) => handleChange(e, index)}
                                required
                              />

                              {/* {touched.price && errors.price ? (
                              <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                                {errors.price}
                              </div>
                            ) : null} */}
                            </div>
                            <div className="">
                              <label htmlFor="">Currency</label>
                              <select
                                className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                                name="currency"
                                value={item.currency}
                                onChange={(e) => {
                                  // handleSelectedChange(item.id, e.target.value);
                                  handleChange(e, index);
                                  // setFieldValue("cancellation_charge", e.target.value);
                                  // setChargeData(e.target.value);
                                  setSelectedCurrency(e.target.value);
                                }}
                              >
                                <option selected disabled>
                                  --select currency--
                                </option>
                                {currenciesArr?.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                    // disabled={
                                    //   chargeData.some((item) => item.charge === option.value) &&
                                    //   chargeData.findIndex((item) => item.charge === option.value) !== index
                                    // }
                                    // disabled={selectedOptions.includes(option) && selectedOptions.indexOf(option) !== index}
                                  >
                                    {option?.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-full flex  justify-end items-center gap-4  ">
                        {chargeData?.length < chargeArr?.length && (
                          <button
                            type="button"
                            onClick={addCharge}
                            className="flex w-full max-w-[fit-content]   bg-green-500 rounded-full text-white"
                          >
                            <IoMdAdd className="text-4xl" />
                          </button>
                        )}
                        {chargeData?.length > 1 && (
                          <button
                            type="button"
                            onClick={() => removeCharge(item?.id)}
                            className="flex w-full max-w-[fit-content]   bg-red-500 rounded-full text-white"
                          >
                            <HiOutlineMinusSmall className="text-4xl" />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}

                  <label className="  text-gray-900 font-semibold ">Time of Cancellation</label>
                  <Select
                    className=" w-full  h-10 text-gray-800 font-semibold  border rounded-lg bg-white "
                    options={cancellationTimeArr}
                    name="cancellation_time"
                    menuPlacement="top"
                    placeholder={
                      selectedTime?.label === "hotel_reservations_department_local_time_working_hours"
                        ? "Hotel Reservations Department Local Time Working Hours"
                        : "City Locale Time"
                    }
                    // defaultValue={selectedTime?.label ? selectedTime?.label : "Time of Cancellation"}
                    onChange={(e) => {
                      setChosenCancellationTime(e.label);
                      setFieldValue("cancellation_time", e.value);
                      setSelectedTime(e.value);
                    }}
                    value={
                      values.cancellation_time
                        ? [
                            {
                              value:
                                values.cancellation_time === "hotel_reservations_department_local_time_working_hours"
                                  ? "Hotel Reservations Department Local Time Working Hours"
                                  : "City Locale Time",
                              label:
                                values.cancellation_time === "hotel_reservations_department_local_time_working_hours"
                                  ? "Hotel Reservations Department Local Time Working Hours"
                                  : "City Locale Time",
                            },
                          ]
                        : selectedTime.label
                    }
                  />
                  {touched.cancellation_time && errors.cancellation_time ? (
                    <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                      {errors.cancellation_time}
                    </div>
                  ) : null}
                  <label className="  text-gray-900 font-semibold ">Notes</label>
                  <textarea
                    className="w-full px-3 py-1 h-10  border-2 border-black border-opacity-20 text-gray-800 font-semibold  rounded-lg bg-white"
                    name="notes"
                    onChange={(e) => {
                      setFieldValue("notes", e.target.value);
                    }}
                    placeholder={values?.notes || "Notes"}
                    // defaultValue={values?.notes || "Notes"}
                    value={values.notes}
                  />
                  <div className=" flex justify-center mt-6">
                    <button disabled={btnFlag} type="Submit" className="updateButton">
                      {btnFlag ? (
                        <>
                          <ButtonLoading /> <TopBarProgress />
                        </>
                      ) : (
                        <GrUpdate className="w-5 h-5" />
                      )}{" "}
                      Update
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <Loading />
            )}
          </div>
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <Loading /> <TopBarProgress />
          </div>
        )}
      </div>
      <NotificationContainer />
    </div>
  );
}
