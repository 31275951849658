import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyRates, updateCurrencyRates } from "../../../redux/actions/currencyRates";
import { useNavigate } from "react-router";
import "./CurrencyRates.scss";
import toast from "react-hot-toast";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import { GiEgyptianSphinx } from "react-icons/gi";
import { HiMiniCurrencyDollar } from "react-icons/hi2";
import { MdCurrencyExchange } from "react-icons/md";
import { TbCurrencyDirham, TbCurrencyIranianRial } from "react-icons/tb";
import { RxUpdate } from "react-icons/rx";
import { VscHistory } from "react-icons/vsc";

export default function CurrencyRates() {
  useRemoveScroll();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  const permissionData = {
    permission_codes: [
      "history_management.view_history",
      "site_settings.view_exchangerate",
      " site_settings.change_exchangerate",
    ],
  };
  const currencyRates = useSelector((state) => state.CurrencyRates);
  let currencies = Object.keys(currencyRates);

  const [USD2EGP, setUSD2EGP] = useState("");
  const [USD2AED, setUSD2AED] = useState("");
  const [USD2SAR, setUSD2SAR] = useState("");
  const [EGP2USD, setEGP2USD] = useState("");
  const [EGP2AED, setEGP2AED] = useState("");
  const [EGP2SAR, setEGP2SAR] = useState("");
  const [AED2USD, setAED2USD] = useState("");
  const [AED2EGP, setAED2EGP] = useState("");
  const [AED2SAR, setAED2SAR] = useState("");
  const [SAR2USD, setSAR2USD] = useState("");
  const [SAR2EGP, setSAR2EGP] = useState("");
  const [SAR2AED, setSAR2AED] = useState("");

  useEffect(() => {
    dispatch(getCurrencyRates());
  }, []);

  useEffect(() => {
    if (Object.keys(currencyRates).length > 0) {
      // USD rates
      let USDToEGP = currencyRates.USD.filter((obj) => obj.to_currency === "EGP");
      let USDToAED = currencyRates.USD.filter((obj) => obj.to_currency === "AED");
      let USDToSAR = currencyRates.USD.filter((obj) => obj.to_currency === "SAR");
      setUSD2EGP(Number(USDToEGP[0].rate));
      setUSD2AED(Number(USDToAED[0].rate));
      setUSD2SAR(Number(USDToSAR[0].rate));

      // EGP rates
      let EGPToUSD = currencyRates.EGP.filter((obj) => obj.to_currency === "USD");
      let EGPToAED = currencyRates.EGP.filter((obj) => obj.to_currency === "AED");
      let EGPToSAR = currencyRates.EGP.filter((obj) => obj.to_currency === "SAR");
      setEGP2USD(Number(EGPToUSD[0].rate));
      setEGP2AED(Number(EGPToAED[0].rate));
      setEGP2SAR(Number(EGPToSAR[0].rate));

      // AED rates
      let AEDToUSD = currencyRates.AED.filter((obj) => obj.to_currency === "USD");
      let AEDToEGP = currencyRates.AED.filter((obj) => obj.to_currency === "EGP");
      let AEDToSAR = currencyRates.AED.filter((obj) => obj.to_currency === "SAR");
      setAED2USD(Number(AEDToUSD[0].rate));
      setAED2EGP(Number(AEDToEGP[0].rate));
      setAED2SAR(Number(AEDToSAR[0].rate));

      // SAR rates
      let SARToUSD = currencyRates.SAR.filter((obj) => obj.to_currency === "USD");
      let SARToEGP = currencyRates.SAR.filter((obj) => obj.to_currency === "EGP");
      let SARToAED = currencyRates.SAR.filter((obj) => obj.to_currency === "AED");
      setSAR2USD(Number(SARToUSD[0].rate));
      setSAR2EGP(Number(SARToEGP[0].rate));
      setSAR2AED(Number(SARToAED[0].rate));
    }
  }, [currencyRates]);

  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  function editRates() {
    const data = [
      { from_currency: "USD", to_currency: "EGP", rate: USD2EGP },
      { from_currency: "USD", to_currency: "AED", rate: USD2AED },
      { from_currency: "USD", to_currency: "SAR", rate: USD2SAR },
      { from_currency: "EGP", to_currency: "USD", rate: EGP2USD },
      { from_currency: "EGP", to_currency: "AED", rate: EGP2AED },
      { from_currency: "EGP", to_currency: "SAR", rate: EGP2SAR },
      { from_currency: "AED", to_currency: "USD", rate: AED2USD },
      { from_currency: "AED", to_currency: "EGP", rate: AED2EGP },
      { from_currency: "AED", to_currency: "SAR", rate: AED2SAR },
      { from_currency: "SAR", to_currency: "USD", rate: SAR2USD },
      { from_currency: "SAR", to_currency: "EGP", rate: SAR2EGP },
      { from_currency: "SAR", to_currency: "AED", rate: SAR2AED },
    ];
    const finalData = data.filter((obj) => {
      let comparisonArr = currencyRates[obj.from_currency];
      let comparisonObj = comparisonArr.filter((compObj) => obj.to_currency === compObj.to_currency)[0];
      return obj.rate != Number(comparisonObj.rate);
    });

    if (finalData.length > 0) {
      dispatch(
        updateCurrencyRates(finalData, () => {
          dispatch(getCurrencyRates());
          toast.success("The rates were updated successfully");
        }),
      );
    } else {
      toast.error("No data updates to send");
    }
  }

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission?.some((item) => item.value) ? (
          <div className="flex flex-col  w-full gap-4 ">
            <div className="">
              <h2 className="header-h2">Currency Rate</h2>
            </div>
            <div className="w-full flex flex-col ">
              <div className="w-full grid grid-cols-5 items-center justify-center gap-4 text-center bg-black/90 font-normal text-2xl text-white px-3 py-3 rounded-md   ">
                <div className=" w-full flex items-center justify-center  gap-x-1">
                  <MdCurrencyExchange className="w-5 h-5 text-yellow-500" />
                  <p> From / To</p>
                </div>
                <div className=" w-full flex items-center justify-center  gap-x-1">
                  <HiMiniCurrencyDollar className="w-5 h-5 text-yellow-500" />{" "}
                  <p className=" text-2xl text-white "> USD</p>
                </div>
                <div className=" w-full flex items-center justify-center  gap-x-1">
                  <GiEgyptianSphinx className="w-5 h-5 text-yellow-500" /> <p className=" text-2xl text-white">EGP</p>
                </div>
                <div className=" w-full flex items-center justify-center  gap-x-1">
                  <TbCurrencyDirham className="w-8 h-8 text-yellow-500" /> <p className=" text-2xl text-white">AED</p>
                </div>
                <div className=" w-full flex items-center justify-center  gap-x-1">
                  <TbCurrencyIranianRial className="w-8 h-8 text-yellow-500" />{" "}
                  <p className="text-2xl text-white"> SAR</p>
                </div>
                <>
                  <div className="w-full flex items-center justify-center  gap-x-1">
                    <HiMiniCurrencyDollar className="w-5 h-5 text-yellow-500" />{" "}
                    <p className=" text-2xl text-white "> USD</p>
                  </div>

                  <input type="text" className=" px-3 py-2 rounded-md border text-center " value="N/A" disabled />
                  <input
                    type="number"
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl "
                    value={USD2EGP}
                    onChange={(e) => setUSD2EGP(e.target.value)}
                  />
                  <input
                    type="number"
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    value={USD2AED}
                    onChange={(e) => setUSD2AED(e.target.value)}
                  />
                  <input
                    type="number"
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    value={USD2SAR}
                    onChange={(e) => setUSD2SAR(e.target.value)}
                  />
                </>
                <>
                  <div className="w-full flex items-center justify-center  gap-x-1">
                    <GiEgyptianSphinx className="w-5 h-5 text-yellow-500" />{" "}
                    <p className="text-md md:text-xl text-white">EGP</p>
                  </div>
                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={EGP2USD}
                    onChange={(e) => setEGP2USD(e.target.value)}
                  />

                  <input className=" px-3 py-2 rounded-md border text-center" type="text" value="N/A" disabled />

                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={EGP2AED}
                    onChange={(e) => setEGP2AED(e.target.value)}
                  />

                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={EGP2SAR}
                    onChange={(e) => setEGP2SAR(e.target.value)}
                  />
                </>
                <>
                  <div className="w-full flex items-center justify-center  gap-x-1">
                    <TbCurrencyDirham className="w-8 h-8 text-yellow-500" />{" "}
                    <p className="text-md md:text-xl text-white">AED</p>
                  </div>
                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={AED2USD}
                    onChange={(e) => setAED2USD(e.target.value)}
                  />

                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={AED2EGP}
                    onChange={(e) => setAED2EGP(e.target.value)}
                  />

                  <input className=" px-3 py-2 rounded-md border text-center" type="text" value="N/A" disabled />

                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={AED2SAR}
                    onChange={(e) => setAED2SAR(e.target.value)}
                  />
                </>
                <>
                  <div className="w-full flex items-center justify-center  gap-x-1">
                    <TbCurrencyIranianRial className="w-8 h-8 text-yellow-500" />{" "}
                    <p className="text-2xl text-white"> SAR</p>
                  </div>

                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={SAR2USD}
                    onChange={(e) => setSAR2USD(e.target.value)}
                  />

                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={SAR2EGP}
                    onChange={(e) => setSAR2EGP(e.target.value)}
                  />

                  <input
                    className=" px-3 py-2 rounded-md border text-black text-center text-md md:text-xl"
                    type="number"
                    value={SAR2AED}
                    onChange={(e) => setSAR2AED(e.target.value)}
                  />

                  <input className=" px-3 py-2 rounded-md border text-center" type="text" value="N/A" disabled />
                </>
              </div>

              {/* <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <table className="min-w-full text-center text-sm font-light">
                  <thead className=" font-medium dark:border-neutral-500">
                    <tr className=" bg-[#002244] text-white">
                      <th scope="col" className="px-6 py-4">
                        From / To
                      </th>
                      <th scope="col" className="px-6 py-4">
                        USD
                      </th>
                      <th scope="col" className="px-6 py-4">
                        EGP
                      </th>
                      <th scope="col" className="px-6 py-4">
                        AED
                      </th>
                      <th scope="col" className="px-6 py-4">
                        SAR
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr className="">
                      <td className="whitespace-nowrap px-6 py-4 font-medium bg-[#002244] text-white">USD</td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <input type="text" value="N/A" disabled />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <input type="number" value={USD2EGP} onChange={(e) => setUSD2EGP(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <input type="number" value={USD2AED} onChange={(e) => setUSD2AED(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <input type="number" value={USD2SAR} onChange={(e) => setUSD2SAR(e.target.value)} />
                      </td>
                    </tr>
                    <tr className="  text-neutral-800">
                      <td className="whitespace-nowrap px-6 py-4 font-medium bg-[#002244] text-white">EGP</td>
                      <td className="whitespace-nowrap px-6 py-4 bg-slate-300">
                        <input type="number" value={EGP2USD} onChange={(e) => setEGP2USD(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 bg-slate-300">
                        <input type="text" value="N/A" disabled />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 bg-slate-300">
                        <input type="number" value={EGP2AED} onChange={(e) => setEGP2AED(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 bg-slate-300">
                        <input type="number" value={EGP2SAR} onChange={(e) => setEGP2SAR(e.target.value)} />
                      </td>
                    </tr>
                    <tr className=" bg-secondary-100 text-neutral-800">
                      <td className="whitespace-nowrap px-6 py-4 font-medium bg-[#002244] text-white">AED</td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <input type="number" value={AED2USD} onChange={(e) => setAED2USD(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <input type="number" value={AED2EGP} onChange={(e) => setAED2EGP(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <input type="text" value="N/A" disabled />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <input type="number" value={AED2SAR} onChange={(e) => setAED2SAR(e.target.value)} />
                      </td>
                    </tr>
                    <tr className="bg-success-100 text-neutral-800">
                      <td className="whitespace-nowrap px-6 py-4 font-medium bg-[#002244] text-white">SAR</td>
                      <td className="whitespace-nowrap px-6 py-4 bg-slate-300">
                        <input type="number" value={SAR2USD} onChange={(e) => setSAR2USD(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 bg-slate-300">
                        <input type="number" value={SAR2EGP} onChange={(e) => setSAR2EGP(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 bg-slate-300">
                        <input type="number" value={SAR2AED} onChange={(e) => setSAR2AED(e.target.value)} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 bg-slate-300">
                        <input type="text" value="N/A" disabled />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
            <div className="w-full flex items-center justify-between ">
              {permission.map((item) => {
                if (item.name === "history_management.view_history" && item.value === true) {
                  return (
                    <button
                      className="md:w-max w-full flex items-center gap-2 text-center justify-center tracking-wider font-semibold text-gray-50 rounded-lg uppercase px-8 py-2 bg-gray-600 hover:scale-105 hover:bg-gray-500 hover:shadow-md active:scale-95 duration-300 transition-all"
                      onClick={() => navigate("./history/1")}
                    >
                      <VscHistory className="w-5 h-5" /> History
                    </button>
                  );
                } else if (item.name === "site_settings.change_exchangerate" && item.value === true) {
                  return (
                    <button className="updateButton" onClick={editRates}>
                      <RxUpdate className="w-5 h-5 " /> Update
                    </button>
                  );
                }
              })}
            </div>
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
