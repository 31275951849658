import React, { useEffect, useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import ButtonLoading from "../../../ButtonLoading/ButtonLoading";
import { IoAdd } from "react-icons/io5";
import usePermissions from "../../../../../customHooks/usePermissions";
import Loading from "../../../Loading/Loading";
import { useFormik } from "formik";
import { FaWpforms } from "react-icons/fa6";
import { CustomerValidation } from "./CustomerValidation";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { getData } from "../../../../../redux/actions/sendDataAction";
import cookie from "react-cookies";
import jwtDecode from "jwt-decode";
import { FcCalendar, FcClock, FcComboChart, FcDam, FcDepartment, FcGlobe } from "react-icons/fc";
import { useNavigate } from "react-router";
import NoPermission from "../../../NoPermission/NoPermission";
import { TimePicker } from "antd";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import mutateDataQuery from "../../../../../react_query/mutateDataQuery";

const CreateCustomerLink = () => {
  const [status, setStatus] = useState();
  const [hotels, setHotels] = useState([]);
  const [hotelsFlag, setHotelsFlag] = useState(false);
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [markup, setMarkup] = useState();
  const [hotelChoosen, setHotelChoosen] = useState([]);
  const [cityChoosen, setCityChoosen] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const user = jwtDecode(token);
  const format = "HH:mm";
  const [permissions, permissionsFlag] = usePermissions(["link.add_link_customer"]);

  const { mutate, isError, isPending } = useMutation({
    mutationFn: (newValues) => mutateDataQuery(`/link/api/`, "post", newValues),
    onError: (error) => {
      // An error happened!
      toast.error(`${error?.message || "failed post data"}  `);
    },
    onSuccess: (result) => {
      // Boom baby!
      toast.success("Link is created successfully");
      navigate(`/dashboard/create-link/customer-link/link-list/${result.id}/preview`);
    },
  });

  const { handleChange, handleBlur, handleSubmit, setFieldValue, setSubmitting, touched, errors, values } = useFormik({
    initialValues: {
      link_status: "new",
      expired_date: "",
      time: "",
      created_by: "",
      hotels: [],
      city: [],
      allowed_contoury: [],
      restricted_contoury: [],
      markup: "",
    },
    validationSchema: CustomerValidation,

    onSubmit: async (values) => {
      const { time, expired_date, hotels, city, ...restData } = values;
      const hotelsData = hotels[0] === "All" ? [] : hotels;
      const cityData = city[0] === "All" ? [] : city;
      const newValues = { ...restData, expired_date: `${expired_date},${time}`, hotels: hotelsData, city: cityData };

      mutate(newValues);
      // const result = await makeRequest(newValues, "post");

      // dispatch(
      //   createLinkAction(newValues, (result) => {
      //     if (result.status === 201) {
      //       setSubmitting(false);
      //       lightToast.success("Link is created successfully");
      //       navigate(`/dashboard/create-link/customer-link/link-list/${result.data.id}/preview`, {
      //         state: { unique_code: result.data.unique_code },
      //       }); // to navigate customer link details
      //     } else {
      //       setSubmitting(false);
      //     }
      //   })
      // );
    },
  });

  useEffect(() => {
    setFieldValue("created_by", user.user_id);
    // link status
    dispatch(
      getData(
        `/link/api/link-status/`,
        (resp) => {
          if (resp.status === 200) {
            setStatus(resp.data);
          }
        },
        (err) => {},
      ),
    );

    //City
    dispatch(
      getData(
        `/search/api/v1/get-cities/`,
        (resp) => {
          if (resp.status === 200) {
            setCity(resp.data);
          }
        },
        (err) => {},
      ),
    );
    //Country
    dispatch(
      getData(
        `site-settings/api/countries-flags`,
        (resp) => {
          if (resp.status === 200) {
            setCountry(resp.data);
          }
        },
        (err) => {},
      ),
    );
    //Markup
    dispatch(
      getData(
        `/markup/api/`,
        (resp) => {
          if (resp.status === 200) {
            setMarkup(resp.data);
          }
        },
        (err) => {},
      ),
    );
  }, []);

  //Status Arr
  let statusArr = status ? status.map((item) => ({ label: item, value: item })) : [];
  //Hotels Arr
  let hotelsArr = hotels ? hotels.map((item) => ({ label: item.name, value: item.id })) : [];
  //City Arr
  let cityArr = city ? city.map((item) => ({ label: item.name, value: item.id })) : [];
  //Country Arr
  let countryArr = country ? country.map((item) => ({ label: item.name, value: item.country, flag: item.flag })) : [];
  //Markup Arr
  let markupArr = markup ? markup.map((item) => ({ label: item.name, value: item.id })) : [];

  //Style
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,

      borderBottom: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      boxShadow: "none",
      borderRadius: "0",
    }),
  };
  // handle time
  const handleTimeChange = (newTime) => {
    const date = new Date(newTime.$d);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hours1 = hours < 10 ? `0${hours}` : hours.toString();
    const minutes1 = minutes < 10 ? `0${minutes}` : minutes.toString();
    setFieldValue("time", `${hours1}:${minutes1}`);
  };

  return (
    <div className="formPage-wrap ">
      <div className="flex flex-col items-center justify-start min-h-screen w-full">
        {permissionsFlag && status && city && country && markup ? (
          permissions.some((item) => item.value) ? (
            <div className=" w-full ">
              <div className=" flex justify-center ">
                <h2 className="header-h2">
                  <FaWpforms className="w-5 h-5 text-yellow-600" />
                  Create Customer Link{" "}
                </h2>
              </div>
              <form className="form-wrap" onSubmit={handleSubmit}>
                <div className="w-full flex flex-col justify-center items-center gap-y-4">
                  <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">Markup</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="markup"
                        styles={style}
                        options={markupArr}
                        onChange={(e) => {
                          setFieldValue("markup", e.value);
                        }}
                        placeholder="Select Markup"
                        onBlur={handleBlur}
                      />
                      <FcComboChart className="absolute top-3 left-3 w-5 h-5" />
                    </div>
                    {errors.markup && touched.markup && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.markup}
                      </div>
                    )}
                  </div>
                  <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">City</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="city"
                        styles={style}
                        value={
                          values.city.some((item) => item === "All") ? [{ label: "All", value: "All" }] : cityChoosen
                          // if select all , previous will be hidden expected All
                        }
                        options={
                          values.city.some((item) => item === "All") ? [] : [{ label: "All", value: "All" }, ...cityArr]
                        }
                        onChange={(e) => {
                          // Delete hotel value
                          setHotelChoosen(null);
                          setFieldValue("hotels", []);
                          setFieldValue(
                            "city",
                            e.some((item) => item.value === "All") ? ["All"] : e.map((item) => item.value),
                          );
                          setCityChoosen(e);
                          // hotels
                          setHotelsFlag(true);
                          dispatch(
                            getData(
                              e.some((item) => item.value === "All")
                                ? `/hotels/api/v1/hotel-filter/?cities=${JSON.stringify(city.map((item) => item.id))}`
                                : `/hotels/api/v1/hotel-filter/?cities=${JSON.stringify(e.map((item) => item.value))}`,
                              (resp) => {
                                if (resp.status === 200) {
                                  setHotelsFlag(false);
                                  setHotels(resp.data);
                                }
                              },
                              (err) => {
                                setHotelsFlag(false);
                              },
                            ),
                          );
                        }}
                        placeholder="Select City"
                        onBlur={handleBlur}
                        isMulti
                      />
                      <FcDam className="absolute top-3 left-3 w-5 h-5" />
                    </div>
                    {errors.city && touched.city && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.city}</div>
                    )}
                  </div>
                  {hotelsFlag ? (
                    <div className="w-full flex justify-center ">
                      <ButtonLoading /> <TopBarProgress />
                    </div>
                  ) : (
                    <div className="w-full flex flex-col items-center justify-center">
                      <label className="font-semibold text-[#002244]">Hotels</label>
                      <div className="relative w-full">
                        <Select
                          className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                          name="hotels"
                          styles={style}
                          value={
                            values.hotels.some((item) => item === "All")
                              ? [{ label: "All", value: "All" }]
                              : hotelChoosen
                            // if select all , previous will be hidden expected All
                          }
                          options={
                            hotels.length === 0
                              ? []
                              : values.hotels.some((item) => item === "All")
                                ? []
                                : [{ label: "All", value: "All" }, ...hotelsArr]
                            // if select all , options will be hidden
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "hotels",
                              e.some((item) => item.value === "All") ? ["All"] : e.map((item) => item.value),
                            );
                            setHotelChoosen(e);
                          }}
                          placeholder={`${hotels.length === 0 ? "Please select Hotel" : "Select hotel"}`}
                          onBlur={handleBlur}
                          isMulti
                        />
                        <FcDepartment className="absolute top-3 left-3 w-5 h-5" />
                      </div>
                      {errors.hotels && touched.hotels && (
                        <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                          {errors.hotels}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">Allowed Nationality</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="allowed_contoury"
                        options={countryArr}
                        styles={style}
                        // isDisabled={values.restricted_contoury?.length > 0}
                        onChange={(e) => {
                          setFieldValue("allowed_contoury", [e.value]);
                        }}
                        formatOptionLabel={(flagData) => (
                          <div className="flex justify-start items-center  gap-x-3 country-option  ">
                            <img src={flagData.flag} alt="country-image" className="w-5 h-3" />
                            <span>{flagData.label}</span>
                          </div>
                        )}
                        placeholder=" Allowed Country"
                        onBlur={handleBlur}
                      />
                      <FcGlobe className="absolute top-3 left-3 w-5 h-5" />
                    </div>
                    {errors.allowed_contoury && touched.allowed_contoury && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.allowed_contoury}
                      </div>
                    )}
                  </div>

                  {/* <div className="w-full flex flex-col items-center justify-center">
                    <label className="font-semibold text-[#002244]">Restricted Nationality</label>
                    <div className="relative w-full">
                      <Select
                        className="w-full capitalize text-gray-700 pl-8 border rounded-md"
                        name="restricted_contoury"
                        options={countryArr}
                        styles={style}
                        isDisabled={values.allowed_contoury?.length > 0}
                        onChange={(e) => {
                          setFieldValue(
                            "restricted_contoury",
                            e.map((item) => item.value)
                          );
                        }}
                        formatOptionLabel={(flagData) => (
                          <div className="flex justify-start items-center  gap-x-3 country-option  ">
                            <img src={flagData.flag} alt="country-image" className="w-5 h-3" />
                            <span>{flagData.label}</span>
                          </div>
                        )}
                        placeholder=" Restricted  Country"
                        onBlur={handleBlur}
                        isMulti
                      />
                      <FcCancel className="absolute top-3 left-3 w-5 h-5" />
                    </div>
                    {errors.restricted_contoury && touched.restricted_contoury && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.restricted_contoury}
                      </div>
                    )}
                  </div> */}

                  <div className="input-par">
                    <div className="input-chil ">
                      <label className="font-semibold text-[#002244]">Expired Date</label>
                      <div className="relative w-full">
                        <input
                          placeholder="Tranfer Date"
                          type="date"
                          name="expired_date"
                          onChange={(e) => {
                            setFieldValue("expired_date", e.target.value);
                          }}
                          value={values.expired_date}
                          onBlur={handleBlur}
                          className={` w-full pl-10 h-10  border rounded-lg bg-white floating-input  text-gray-700  `}
                        />
                        <FcCalendar className="absolute top-3 left-3 w-5 h-5" />
                      </div>
                      {touched.expired_date && errors.expired_date ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.expired_date}
                        </div>
                      ) : null}{" "}
                    </div>
                    <div className="input-chil ">
                      <label className="font-semibold text-[#002244] ">Expired Time</label>
                      <div className="w-full relative flex items-center gap-x-1">
                        <TimePicker
                          clearIcon={false}
                          name="time"
                          as={TimePicker}
                          format={format}
                          className={` w-full py-2 pl-10  border rounded-lg bg-white `}
                          onChange={handleTimeChange}
                          onBlur={handleBlur}
                        />
                        <div className="absolute pl-2">
                          <FcClock className="w-5 h-5 text-[#002244]" />
                        </div>
                      </div>
                      {touched.time && errors.time ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                          {errors.time}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex justify-center mt-6">
                    <button type="Submit" disabled={isPending} className="addButton">
                      Create{" "}
                      {isPending ? (
                        <>
                          <TopBarProgress />
                          <ButtonLoading />
                        </>
                      ) : (
                        <IoAdd className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-full flex items-center justify-center h-screen">
              <NoPermission />
            </div>
          )
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <Loading /> <TopBarProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateCustomerLink;
