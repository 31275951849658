import { GET_HOTEL_NAMES } from "../types/types";

const defaultState = [];

export default function hotelNamesReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_HOTEL_NAMES:
      return [...action.payload];
    default:
      return state;
  }
}
