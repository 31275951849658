import { Suspense, useEffect, useState } from "react";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import ReqAuth from "./api/ReqAuth";
import { useSelector } from "react-redux";
import Login from "./components/pages/Login/Login";
import BookingHubTable from "./components/organisms/BookingHubTable/BookingHubTable";
import HotelListTable from "./components/organisms/HotelListTable/HotelListTable";
import HotelDetailCard from "./components/organisms/HotelDetailCard/HotelDetailCard";
import Hotels from "./components/pages/Hotels/Hotels";
// import AddHotelForm from "./components/organisms/AddHotelForm/AddHotelForm";
import RoomDetailsCard from "./components/organisms/RoomDetailsCard/RoomDetailsCard";
import RoomListTable from "./components/organisms/RoomListTable/RoomListTable";
import AddContractForm from "./components/organisms/AddContractForm/AddContractForm";
import DashboardPage from "./components/pages/DashboardPage/DashboardPage";
import ContractList from "./components/organisms/ContractList/ContractList";
import ContractDetails from "./components/organisms/ContractDetails/ContractDetails";
import EditContract from "./components/organisms/EditContract/EditContract";
import Inventory from "./components/organisms/Inventory/Inventory";
import Reservation from "./components/pages/Reservation/Reservation";
import MultiReservation from "./components/pages/MultiReservation/MultiReservation";
import BookingListTable from "./components/organisms/BookingListTable/BookingListTable";
import BookingListTableId from "./components/organisms/BookingListTableId/BookingListTableId";
import BookingListTableSingleId from "./components/organisms/BookingListTableSingleId/BookingListTableSingleId";
import BookingListTableIdOne from "./components/organisms/BookingListTableIdOne/BookingListTableIdOne";
import Payments from "./components/organisms/Payments/Payments";
import AddBankTransfer from "./components/organisms/AddBankTransfer/AddBankTransfer";
import Transactions from "./components/organisms/Transactions/Transactions";
import AddJumbo from "./components/organisms/AddJumbo/AddJumbo";
import AddCash from "./components/organisms/AddCash/AddCash";
import RequestRefund from "./components/organisms/RequestRefund/RequestRefund";
import CollectionRequest from "./components/organisms/CollectionRequest/CollectionRequest";
import CollectionRequestUpdate from "./components/organisms/CollectionRequest/CollectionRequestUpdate";
import AllCollectionRequest from "./components/organisms/AllCollectionRequest/AllCollectionRequest";
import AllRefundRequest from "./components/organisms/AllRefundRequest/AllRefundRequest";
import UpdateRefund from "./components/organisms/UpdateRefund/UpdateRefund";
import AddPosVisa from "./components/organisms/AddPosVisa/AddPosVisa";
import CreateNewPosVisa from "./components/organisms/CreateNewPosVisa/CreateNewPosVisa";
import CreateNewBatch from "./components/organisms/CreateNewBatch/CreateNewBatch";
import AllTransactions from "./components/organisms/allTransactions/allTransactions";
import UpdateTransaction from "./components/organisms/UpdateTransaction/UpdateTransaction";
import TransactionsDetails from "./components/organisms/TransactionsDetails/TransactionsDetails";
import TopBarProgress from "react-topbar-progress-indicator";
import PosVisa from "./components/organisms/PosVisa/PosVisa";
import AddBank from "./components/organisms/AddBank/AddBank";
import BookingRequests from "./components/organisms/BookingRequests/BookingRequests";
import AddBookingRequest from "./components/organisms/AddBookingRequest/AddBookingRequest";
import BookingRequestsList from "./components/organisms/BookingRequestsList/BookingRequestsList";
import SelectedAllTransaction from "./components/organisms/SelectedAllTransaction/SelectedAllTransaction";
import MarkUpControl from "./components/organisms/MarkUpControl/MarkUpControl";
import MarkUpList from "./components/organisms/MarkUpList/MarkUpList";
import AddMarkup from "./components/organisms/AddMarkup/AddMarkup";
import PosMachineList from "./components/organisms/PosMachineList/PosMachineList";
import BatchList from "./components/organisms/BatchList/BatchList";
import BatchDetails from "./components/organisms/BatchDetails/BatchDetails";
import EditMarkup from "./components/organisms/EditMarkup/EditMarkup";
import CurrencyRates from "./components/organisms/CurrencyRates/CurrencyRates";
import CurrencyHistory from "./components/organisms/CurrencyHistory/CurrencyHistory";
import CurrencyHistoryDetails from "./components/organisms/CurrencyHistoryDetails/CurrencyHistoryDetails";
import CreateLink from "./components/organisms/CreateLink/CreateLink";
import AddHotelMainInfo from "./components/organisms/AddHotelMainInfo/AddHotelMainInfo";
import HotelMainInfo from "./components/organisms/HotelMainInfo/HotelMainInfo";
import HotellMainInfoList from "./components/organisms/HotelMainInfoList/HotellMainInfoList";
import EditHotelMainInfo from "./components/organisms/EditHotelMainInfo/EditHotelMainInfo";
import AddHotelBankDetails from "./components/organisms/AddHotelBankDetails/AddHotelBankDetails";
import AddHotelContact from "./components/organisms/AddHotelContact/AddHotelContact";
import AccountingSetting from "./components/organisms/AccountingSetting/AccountingSetting";
import BankTransferList from "./components/organisms/BankTransferList/BankTransferList";
import BookingRequestDetails from "./components/organisms/BookingRequestDetails/BookingRequestDetails";
import HotelBankDetailsList from "./components/organisms/HotelBankDetailsList/HotelBankDetailsList";
import HotelBankDetailsListPreview from "./components/organisms/HotelBankDetailsListPreview/HotelBankDetailsListPreview";
import HotelBankAccountNumber from "./components/organisms/HotelBankAccountNumber/HotelBankAccountNumber";
import EditHotelContact from "./components/organisms/EditHotelContact/EditHotelContact";
import SalesTeamContact from "./components/organisms/SalesTeamContact/SalesTeamContact";
import ReservationTeamContact from "./components/organisms/ReservationTeamContact/ReservationTeamContact";
import CreditTeamContact from "./components/organisms/CreditTeamContact/CreditTeamContact";
import AccountingTeamContact from "./components/organisms/AccountingTeamContact/AccountingTeamContact";
import FrontDeskContact from "./components/organisms/FrontDeskContact/FrontDeskContact";
import CreditHotel from "./components/organisms/CreditHotel/CreditHotel";
import AddCashBox from "./components/organisms/AddCashBox/AddCashBox";
import CashBoxList from "./components/organisms/CashBoxList/CashBoxList";
import CashBoxesStatments from "./components/organisms/CashBoxesStatments/CashBoxesStatments";

import HotelMainInfoDetails from "./components/organisms/HotelMainInfoDetails/HotelMainInfoDetails";
import ContractApproval from "./components/organisms/ContractApproval/ContractApproval";
import ContractApprovalDetails from "./components/organisms/ContractApprovalDetails/ContractApprovalDetails";
import ContractArchive from "./components/organisms/ContractArchive/ContractArchive";
import ContractArchiveDetails from "./components/organisms/ContractArchiveDetails/ContractArchiveDetails";
import DepartmentsManagment from "./components/organisms/DepartmentsManagment/DepartmentsManagement";
import StaffUsers from "./components/organisms/Staffusers/StaffUsers";
import BankList from "./components/organisms/BankList/BankList";
import Expenses from "./components/organisms/Expenses/Expenses";
import HotelsPaymentOrders from "./components/organisms/HotelsPaymentOrders/HotelsPaymentOrders";
import CreatePaymentOrder from "./components/organisms/CreatePaymentOrder/CreatePaymentOrder";
import AddCollectors from "./components/organisms/AddCollectors/AddCollectors";
import ImportContract from "./components/organisms/ImportContract/ImportContract";
import AddImportedContract from "./components/organisms/AddImportedContract/AddImportedContract";
import CollectionRequestDetails from "./components/organisms/CollectionRequestDetails/CollectionRequestDetails";
import CollectionRequestPage from "./components/organisms/CollectionRequestPage/CollectionRequestPage";
import SelectedCollectionRequest from "./components/organisms/SelectedCollectionRequest/SelectedCollectionRequest";
import CollectorsList from "./components/organisms/CollectorsList/CollectorsList";
import CollectorsDetails from "./components/organisms/CollectorsDetails/CollectorsDetails";
import PriceTestTool from "./components/organisms/PriceTestTool/PriceTestTool";
import EWallet from "./components/organisms/E-Wallet/E-Wallet";
import AddNewWallet from "./components/organisms/AddNewWallet/AddNewWallet";
import WalletList from "./components/organisms/WalletList/WalletList";
import WalletDetails from "./components/organisms/WalletDetails/WalletDetails";
import TransferAmount from "./components/organisms/TransferAmount/TransferAmount";
import HotelPaymentOrdersList from "./components/organisms/HotelPaymentOrdersList/HotelPaymentOrdersList";
import BankCashDeposit from "./components/organisms/HotelPaymentForm/BankCashDeposit";
import PaymnetLink from "./components/organisms/HotelPaymentForm/PaymnetLink";
import CashInHotel from "./components/organisms/HotelPaymentForm/CashInHotel";
import OnlineBankTransfer from "./components/organisms/HotelPaymentForm/OnlineBankTransfer";
import PaymentOrderDetails from "./components/organisms/PaymentOrderDetails/PaymentOrderDetails";
import HotelPaymentOrderDetails from "./components/organisms/HotelPaymentOrderDetails/HotelPaymentOrderDetails";
import AddAgentForm from "./components/organisms/Agents/AddEditAgent/AddAgentForm";
import CancellationPolicy from "./components/organisms/CancellationPolicy/CancellationPolicy";
import CreateCancellationPolicy from "./components/organisms/CreateCancellationPolicy/CreateCancellationPolicy";
import MeetAssistService from "./components/organisms/MeetAssistService/MeetAssistService";
import CancellationpolicyList from "./components/organisms/CancellationPolicyList/CancellationpolicyList";
import CancellationPolicyArchive from "./components/organisms/CancellationPolicyArchive/CancellationPolicyArchive";
import EmailSettings from "./components/organisms/EmailSettings/EmailSettings";
import AgentsList from "./components/organisms/Agents/AgentsList/AgentsList";
import AgentsAccounting from "./components/organisms/Agents/AgentsAccounting/AgentsAccounting";
import AgentsReports from "./components/organisms/AgentsReports/AgentsReports";
import Offers from "./components/organisms/Offers/Offers";
import OffersList from "./components/organisms/OffersList/OffersList";
import CreateOffer from "./components/organisms/CreateOffer/CreateOffer";
import OfferDetails from "./components/organisms/OfferDetails/OfferDetails";
import EditOffer from "./components/organisms/EditOffer/EditOffer";
import WhitelistIP from "./components/organisms/WhitelistIP/WhitelistIP";
import AddWhitelistIP from "./components/organisms/AddWhitelistIP/AddWhitelistIP";
import WhitelistIPList from "./components/organisms/WhitelistIPList/WhitelistIPList";
import EditWhitelistIP from "./components/organisms/EditWhitelistIP/EditWhitelistIP";
import Permissions from "./components/organisms/Permissions/Permissions";
import AddGroupPermissions from "./components/organisms/AddGroupPermissions/AddGroupPermissions";
import EditGroupPermissions from "./components/organisms/EditGroupPermissions/EditGroupPermissions";
import AssignUsersToGroups from "./components/organisms/AssignUsersToGroups/AssignUsersToGroups";
import PermissionsGroupsList from "./components/organisms/PermissionsGroupsList/PermissionsGroupsList";
import AddUsers from "./components/organisms/AddUsers/AddUsers";
import AddMenus from "./components/organisms/AddMenus/AddMenus";
import EditMenus from "./components/organisms/EditMenus/EditMenus";
import SearchUsers from "./components/organisms/SearchUsers/SearchUsers";
import EditUsers from "./components/organisms/EditUsers/EditUsers";
import AddRoom from "./components/organisms/AddRoomForm/AddRoom";
import EditRoom from "./components/organisms/EditRoom/EditRoom";
import AddHotel from "./components/organisms/AddHotelForm/AddHotel";
import EditHotel from "./components/organisms/EditHotelForm/EditHotel";

import AirportCar from "./components/organisms/AirportCar/AirportCar";
import AddAirportService from "./components/organisms/AddAirportService/AddAirportService";
import AirportServiceList from "./components/organisms/AirportServiceList/AirportServiceList";
import AirportServiceDetails from "./components/organisms/AirportServiceDetails/AirportServiceDetails";
import UpdateAirportService from "./components/organisms/UpdateAirportService/UpdateAirportService";
import RepresentativeMemberlist from "./components/organisms/RepresentativeMember/RepresentativeMemberlist";
import RepresentativeMemberDetails from "./components/organisms/RepresentativeMember/RepresentativeMemberDetails";
import DeletedContract from "./components/organisms/DeletedContract/DeletedContract";
import UserProfile from "./components/organisms/UserProfile/UserProfile";
import CustomersLink from "./components/organisms/CreateLink/CustomersLink/CustomersLink";
import CreateCustomerLink from "./components/organisms/CreateLink/CustomersLink/CreateCustomerLink/CreateCustomerLink";
import CustomerLinkList from "./components/organisms/CreateLink/CustomersLink/CustomerLinkList/CustomerLinkList";
import UpdateCustomerLink from "./components/organisms/CreateLink/CustomersLink/UpdateCustomerLink/UpdateCustomerLink";
import SearchPermission from "./components/organisms/SearchPermission/SearchPermission";
import CreateSearchPermission from "./components/organisms/SearchPermission/CreateSearchPermission/CreateEditSearchPermission";
import SearchPermissionsList from "./components/organisms/SearchPermission/SearchPermissionsList/SearchPermissionsList";
import UpdateSearchPermission from "./components/organisms/SearchPermission/DetailsSearchPermission/DetailsSearchPermission";
import CustomerLinkDetails from "./components/organisms/CreateLink/CustomersLink/CustomerLinkDetails/CustomerLinkDetails";
import SearchAuth from "./api/SearchAuth";
import RepresentativeMember from "./components/organisms/RepresentativeMember/RepresentativeMember";
import UpdateRepresentativeMember from "./components/organisms/RepresentativeMember/UpdateRepresentativeMember";
// import AddHotel from "./components/organisms/AddHotel/AddHotel";
// statistics page
import StatisticsPage from "./components/organisms/statisticsPage/StatisticsPage";
import ChequeDetail from "./components/organisms/bankCheques/Cheques/ChequeDetail";
import ChequeList from "./components/organisms/bankCheques/Cheques/ChequeList";
import UpdateCheque from "./components/organisms/bankCheques/Cheques/UpdateCheque";
import BankChequesDetails from "./components/organisms/bankCheques/BankChequesDetails";
import BankChequesList from "./components/organisms/bankCheques/BankChequesList";
import EditCashBoxList from "./components/organisms/CashBoxList/EditCashBoxList";
import Loading from "./components/organisms/Loading/Loading";
import CollectorsEdit from "./components/organisms/CollectorsList/CollectorsEdit";
import DetailsPosMachine from "./components/organisms/PosMachineList/details/DetailsPosMachine";
import EditPosMachine from "./components/organisms/PosMachineList/createEdit/EditPosMachine";
import DetailBankList from "./components/organisms/BankList/details/DetailBankList";
import CashBoxDetails from "./components/organisms/CashBoxList/CashBoxDetails";
import AddEditBankCheques from "./components/organisms/bankCheques/AddEditBankCheques";
import MeetAssistFormPublic from "./components/organisms/MeetAssistFormPublic/MeetAssistFormPublic";
import CancellationReport from "./components/organisms/CancellationReport/CancellationReport";
import PosMAchineRelated from "./components/organisms/AccountingSetting/posMachineRelated/PosMAchineRelated";
import CashBoxRelated from "./components/organisms/AccountingSetting/cashBoxRelation/CashBoxRelated";
import BankRelated from "./components/organisms/AccountingSetting/bankRelated/BankRelated";
import CollectorRelated from "./components/organisms/AccountingSetting/collectorRelated/CollectorRelated";
import BankChequeRelated from "./components/organisms/AccountingSetting/bankChequesRelated/BankChequeRelated";
import EditCancelation from "./components/organisms/CreateCancellationPolicy/EditCancelation";
import ViewCancellation from "./components/organisms/cancelationPolicy/ViewCancellation";
import { ToastContainer } from "react-toastify";
import BookingEmailList from "./components/organisms/BookingEmailList/BookingEmailList";
import EditAgentForm from "./components/organisms/Agents/AddEditAgent/EditAgentForm";
import AddClientForm from "./components/organisms/Clients/AddClientForm";
import ClientsList from "./components/organisms/Clients/ClientsList";
import EditClientForm from "./components/organisms/Clients/EditClientForm";

import AccountUser from "./components/pages/AccountUser/AccountUser";
import ClientBookingHistory from "./components/organisms/Clients/BookingHistory/ClientBookingHistory";
import AddClientBookingHistoryForm from "./components/organisms/Clients/BookingHistory/AddClientBookingHistoryForm";
import ClientBookingHistoryList from "./components/organisms/Clients/BookingHistory/ClientBookingHistoryList";
import EditClientBookingHistoryForm from "./components/organisms/Clients/BookingHistory/EditClientBookingHistoryForm";
import ClientFamily from "./components/organisms/Clients/Family/ClientFamily";
import AddClientFamilyForm from "./components/organisms/Clients/Family/AddClientFamilyForm";
import ClientFamilyList from "./components/organisms/Clients/Family/ClientFamilyList";
import EditClientFamilyForm from "./components/organisms/Clients/Family/EditClientFamilyForm";
import BannerList from "./components/organisms/Banner/BannerList";
import AddBanner from "./components/organisms/Banner/AddBanner";
import EditBanner from "./components/organisms/Banner/EditBanner";

import EditAgentAccountingForm from "./components/organisms/Agents/AgentsAccounting/EditAgentAccountingForm";
import ITTickets from "./components/organisms/ITDepartment/Tickets/ITTickets";
import AddNewITTicketForm from "./components/organisms/ITDepartment/Tickets/AddNewITTicketForm";
import ITTicketsList from "./components/organisms/ITDepartment/Tickets/ITTicketList/ITTicketsList";
import ITTicketDetails from "./components/organisms/ITDepartment/Tickets/ITTicketDetails/ITTicketDetails";
import SoftDeleteTicketsList from "./components/organisms/ITDepartment/Tickets/ITTicketList/SoftDeleteTicketsList";

import SoftDeleteList from "./components/organisms/AirportServiceList/SoftDeleteList";
import PreferedHotels from "./components/organisms/preferedHotels/PreferedHotels";
// import PreferedHotelList from "./components/organisms/preferedHotels/PreferedHotelList";
import AddPreferedHotels from "./components/organisms/preferedHotels/AddPreferedHotels";
import NotesPage from "./components/organisms/Notes/NotesPage";
import AddHotelTool from "./components/organisms/upgrade_tool/upgradeHotelTool/HotelTool/AddExtraOffer";
import EditHotelTool from "./components/organisms/upgrade_tool/upgradeHotelTool/HotelTool/EditExtraOffer";
import UpgradeTool from "./components/organisms/upgrade_tool/UpgradeTool";
import HotelTool from "./components/organisms/upgrade_tool/upgradeHotelTool/HotelTool/HotelTool";
import ExtraOffersList from "./components/organisms/upgrade_tool/upgradeHotelTool/HotelTool/ExtraOffersList";

import RoomPackages from "./components/organisms/RoomPackages/RoomPackages";

import HotelOfferDetails from "./components/organisms/upgrade_tool/upgradeHotelTool/HotelTool/HotelOfferDetails";
import AddRoomExtraTool from "./components/organisms/upgrade_tool/upgradeRoomTool/RoomTool/AddRoomExtraTool";
import EditRoomExtraTool from "./components/organisms/upgrade_tool/upgradeRoomTool/RoomTool/EditRoomExtraTool";
import RoomExtraToolList from "./components/organisms/upgrade_tool/upgradeRoomTool/RoomTool/RoomExtraToolList";
import RoomExtraToolDetails from "./components/organisms/upgrade_tool/upgradeRoomTool/RoomTool/RoomExtraToolDetails";
import RoomTool from "./components/organisms/upgrade_tool/upgradeRoomTool/RoomTool/RoomTool.jsx";
import NotFound from "./NotFound.jsx";
import TaskManager from "./components/organisms/taskManager/TaskManager.jsx";
import AddNewTask from "./components/organisms/taskManager/AddNewTask.jsx";
import EditTask from "./components/organisms/taskManager/EditTask.jsx";
import ViewTask from "./components/organisms/taskManager/ViewTask.jsx";
import TasksList from "./components/organisms/taskManager/TasksList.jsx";
import VoucherImage from "./components/organisms/VoucherImage/VoucherImage";
import HotelContactList from "./components/organisms/HotelContactList/HotelContactList";
import BookingVoucher from "./components/organisms/BookingVoucher/BookingVoucher";
import CreateVoucherForm from "./components/organisms/BookingVoucher/CreateVoucherForm";
import VouchersList from "./components/organisms/BookingVoucher/VouchersList";
import VoucherEmailForm from "./components/organisms/BookingVoucher/VoucherEmailForm";
import AddManualBooking from "./components/organisms/ManualBooking/AddManualBooking.js";
import SpecialRate from "./components/organisms/SpecialRateRequest/SpecialRate.jsx";
import AddSpecialRate from "./components/organisms/SpecialRateRequest/AddSpecialRate.jsx";
import SpecialRateList from "./components/organisms/SpecialRateRequest/SpecialRateList.jsx";
import AddSpecialRateSetup from "./components/organisms/SpecialRateRequest/specialRateSetup/AddSpecialRateSetup.jsx";
import SpecialRateView from "./components/organisms/SpecialRateRequest/SpecialRateView.jsx";
import EditSpecialRateRequest from "./components/organisms/SpecialRateRequest/EditSpecialRateRequest.jsx";
import CreateDepartmentForm from "./components/organisms/DepartmentsManagment/DepartementForm/CreateDepartmentForm";

import DepartmentsList from "./components/organisms/DepartmentsManagment/DepartmentsList/DepartmentsList";
import EditDepartmentForm from "./components/organisms/DepartmentsManagment/DepartementForm/EditDepartmentForm";
import WorkOrderHtml from "./components/pages/WorkOrder/WorkOrderHtml";

import ContractsStatistics from "./components/organisms/ContractsStatistics/ContractsStatistics";
import ContractDepartmentReport from "./components/organisms/ContractsStatistics/ContractDepartmentReport/ContractDepartmentReport";

import CollectedBookingListTable from "./components/organisms/BookingListTable/collectedBookingList.jsx";
import SearchAnalyze from "./components/organisms/search_analyze/SearchAnalyze.jsx";

function App() {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    TopBarProgress.config({
      barThickness: 5,
      barColors: {
        0: "#001a35",
      },
    });
    setLoad(true);

    // Start the loading bar when the page is initially loading

    // Optionally, you can return a cleanup function if needed
    return () => {
      setLoad(false);
    };
  }, []);

  const { notificationResveFlag } = useSelector((state) => state.reservationReducer);
  const [flag, setFlag] = useState(false);

  // const Layout =()=>{
  //   <>
  //   <Dashboard>
  //     <Outlet/>
  //   </Dashboard>
  //   </>
  // }

  return (
    <div className="App  ">
      <Suspense fallback={<Loading />}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Router>
          {/* {notificationResveFlag && <Notif />} */}

          {!load && <TopBarProgress />}

          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/workorder/:id" element={<WorkOrderHtml />} />
            <Route path={"/booking-voucher/:voucherRandomId"} element={<VoucherImage />} />
            <Route path="/meetassistform/:lang" element={<MeetAssistFormPublic />} />
            <Route path="/login" element={<Login />} />
            <Route element={<SearchAuth />}>
              <Route path="/:code?" element={<Hotels />} />
            </Route>
            <Route element={<ReqAuth />}>
              {/* <Route path="/" element={<SearchHotel />} /> */}

              <Route path="/reservation" element={<Reservation />} />
              <Route path="/multireservation" element={<MultiReservation />} />
              <Route path="/dashboard" element={<Dashboard />} />

              <Route
                path="/dashboard/bookings-hub"
                element={
                  <DashboardPage>
                    <BookingHubTable />
                  </DashboardPage>
                }
              />
              <Route path="/dashboard/hotel-list" element={<Navigate to="/dashboard/hotel-list/1/id" replace />} />
              <Route
                path="/dashboard/hotel-list/:page/:sortingOrder"
                element={
                  <DashboardPage>
                    <HotelListTable />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-list/hotel-details/:id"
                element={
                  <DashboardPage>
                    <HotelDetailCard />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/add-hotel"
                element={
                  <DashboardPage>
                    <AddHotel />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/edit-hotel/:id"
                element={
                  <DashboardPage>
                    <EditHotel />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/add-room"
                element={
                  <DashboardPage>
                    <AddRoom />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/room-details/:id"
                element={
                  <DashboardPage>
                    <RoomDetailsCard />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/edit-room/:id"
                element={
                  <DashboardPage>
                    <EditRoom />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/room-list"
                element={
                  <DashboardPage>
                    <RoomListTable />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/add-contract"
                element={
                  <DashboardPage>
                    <AddContractForm />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/deleted-contract"
                element={<Navigate to="/dashboard/deleted-contract/1" replace />}
              />
              <Route
                path="/dashboard/deleted-contract/:page/"
                element={
                  <DashboardPage>
                    <DeletedContract />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list"
                element={<Navigate to="/dashboard/booking-list/filter/1/-created_date" replace />}
              />

              <Route
                path="/dashboard/booking-list/:filter/:page/:sortingOrder"
                element={
                  <DashboardPage>
                    <BookingListTable />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/cancelled-bookings"
                element={<Navigate to="/dashboard/cancelled-bookings/filter/1/-created_date" replace />}
              />

              <Route
                path="/dashboard/cancelled-bookings/:filter/:page/:sortingOrder"
                element={
                  <DashboardPage>
                    <BookingListTable listType="cancelled-bookings" lastOperationStatus="Confirmed cancelation" />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-archive"
                element={<Navigate to="/dashboard/booking-archive/filter/1/-created_date" replace />}
              />

              <Route
                path="/dashboard/booking-archive/:filter/:page/:sortingOrder"
                element={
                  <DashboardPage>
                    <BookingListTable listType="booking-archive" lastOperationStatus="Archived" />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-id/:short_id"
                element={
                  <DashboardPage>
                    <BookingListTableId />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/contract-list"
                element={<Navigate to="/dashboard/contract-list/1/rejected" replace />}
              />
              <Route
                path="/dashboard/contract-list/:page/:sorting"
                element={
                  <DashboardPage>
                    <ContractList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/contract-details/:id"
                element={
                  <DashboardPage>
                    <ContractDetails />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/edit-contract/:id"
                element={
                  <DashboardPage>
                    <EditContract />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/deleted-contract/:id/details"
                element={
                  <DashboardPage>
                    <ContractDetails />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/deleted-contract/:id/edit"
                element={
                  <DashboardPage>
                    <EditContract />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/inventory"
                element={
                  <DashboardPage>
                    <Inventory />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-id/:short_id/:id"
                element={
                  <DashboardPage>
                    <BookingListTableIdOne />
                  </DashboardPage>
                }
              />

              {/*             
              <Route
              path="/dashboard/booking-list-single-id/:short_id/:id/:operation"
              element={
                <DashboardPage>
                  <BookingListTableSingleId />
                </DashboardPage>
              }
            /> */}

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/"
                element={
                  <DashboardPage>
                    <BookingListTableSingleId />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/booking-email-list"
                element={
                  <DashboardPage>
                    <BookingEmailList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/payments"
                element={
                  <DashboardPage>
                    <Payments />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/transactions"
                element={
                  <DashboardPage>
                    <Transactions />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/transactions"
                element={
                  <DashboardPage>
                    <Transactions />
                  </DashboardPage>
                }
              />

              {/* <Route
              path="/dashboard/representative-serivce"
              element={
                <DashboardPage>
                  <BookingMeetAssist />
                </DashboardPage>
              }
            /> */}
              <Route
                path="/dashboard/staff-users/add-representative-service"
                element={
                  <DashboardPage>
                    <RepresentativeMember />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/staff-users/all-representative-service"
                element={
                  <DashboardPage>
                    <RepresentativeMemberlist />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/staff-users/all-representative-service/:id/preview"
                element={
                  <DashboardPage>
                    <RepresentativeMemberDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/staff-users/all-representative-service/:id/update"
                element={
                  <DashboardPage>
                    <UpdateRepresentativeMember />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/meet-service"
                element={
                  <DashboardPage>
                    <AirportCar />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/meet-service/add-meet-service"
                element={
                  <DashboardPage>
                    <AddAirportService />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/meet-service"
                element={
                  <DashboardPage>
                    <AirportServiceList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/meet-service/:id/preview"
                element={
                  <DashboardPage>
                    <AirportServiceDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/meet-service/:id/update"
                element={
                  <DashboardPage>
                    <UpdateAirportService />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/payment-hotels"
                element={
                  <DashboardPage>
                    <CreatePaymentOrder />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/payment-hotels/payment-order-details"
                element={
                  <DashboardPage>
                    <PaymentOrderDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/all-transactions"
                element={<Navigate to="/dashboard/all-transactions/1/-id" replace />}
              />
              {/* <Route
              path="/dashboard/booking-list-single-id/:short_id/:id/transactions/selected-transaction"
              element={
                <DashboardPage>
                  <AllTransactions />
                </DashboardPage>
              }
            /> */}
              <Route
                path="/dashboard/all-transactions/:page/:sorting"
                element={
                  <DashboardPage>
                    <AllTransactions />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/all-transactions/:page/:sorting/update/:bookedroom/:type/:id"
                element={
                  <DashboardPage>
                    <UpdateTransaction />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/all-transactions/:page/:sorting/preview/:bookedroom/:type/:id"
                element={
                  <DashboardPage>
                    <TransactionsDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/selected-all-transactions"
                element={<Navigate to="/dashboard/selected-all-transactions/1/id" replace />}
              />
              {/* <Route
              path="/dashboard/booking-list-single-id/:short_id/:id/transactions/selected-transaction"
              element={
                <DashboardPage>
                  <AllTransactions />
                </DashboardPage>
              }
            /> */}
              {/* <Route path="/dashboard/booking-list-single-id/:short_id/:id/transactions/selected-all-transactions" element={<Navigate to="/dashboard/booking-list-single-id/:short_id/:id/transactions/selected-all-transactions/1/id" replace />} /> */}
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/transactions/selected-all-transactions"
                element={
                  <DashboardPage>
                    <SelectedAllTransaction />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/transactions/add-bank-transfer"
                element={
                  <DashboardPage>
                    <AddBankTransfer />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/transactions/add-cash"
                element={
                  <DashboardPage>
                    <AddCash />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/transactions/add-pos-visa"
                element={
                  <DashboardPage>
                    <AddPosVisa />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/transactions/add-jumbo"
                element={
                  <DashboardPage>
                    <AddJumbo />
                  </DashboardPage>
                }
              />
              {/* <Route
              path="/dashboard/booking-list-single-id/:short_id/:id/transactions/add-new-pos"
              element={
                <DashboardPage>
                  <CreateNewPosVisa />
                </DashboardPage>
              }
            /> */}
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/request-refund"
                element={
                  <DashboardPage>
                    <RequestRefund />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/booking-voucher"
                element={
                  <DashboardPage>
                    <BookingVoucher />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/booking-voucher/create-voucher"
                element={
                  <DashboardPage>
                    <CreateVoucherForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/booking-voucher/vouchers-list"
                element={
                  <DashboardPage>
                    <VouchersList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/booking-voucher/:voucherRandomId/send-email"
                element={
                  <DashboardPage>
                    <VoucherEmailForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/collection-request"
                element={
                  <DashboardPage>
                    <CollectionRequestPage />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/collection-request/add-collection-request"
                element={
                  <DashboardPage>
                    <CollectionRequest />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/collection-request/collection-request-list"
                element={
                  <DashboardPage>
                    <SelectedCollectionRequest />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/collection-request/update/:id"
                element={
                  <DashboardPage>
                    <CollectionRequestUpdate />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/collection-requests"
                element={<Navigate to="/dashboard/collection-requests/1/-id" replace />}
              />

              <Route
                path="/dashboard/collection-requests/:page/:sorting"
                element={
                  <DashboardPage>
                    <AllCollectionRequest />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/collection-requests/preview/:id"
                element={
                  <DashboardPage>
                    <CollectionRequestDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/refund-requests"
                element={
                  <DashboardPage>
                    <AllRefundRequest />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/refund-requests/update/:id"
                element={
                  <DashboardPage>
                    <UpdateRefund />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/pos-(visa)"
                element={
                  <DashboardPage>
                    <PosVisa />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/bank-transfers"
                element={
                  <DashboardPage>
                    <BankTransferList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting"
                element={
                  <DashboardPage>
                    <AccountingSetting />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/pos-machine"
                element={
                  <DashboardPage>
                    <CreateNewPosVisa />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/accounting-setting/add-bank"
                element={
                  <DashboardPage>
                    <AddBank />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/pos-(visa)/pos-(visa)-list"
                element={
                  <DashboardPage>
                    <PosMachineList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/collector-list"
                element={
                  <DashboardPage>
                    <CollectorsList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/collector-list/preview/:id"
                element={
                  <DashboardPage>
                    <CollectorsDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/collector-list/create/:id/update"
                element={
                  <DashboardPage>
                    <CollectorsEdit />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/pos-(visa)/new-batch"
                element={
                  <DashboardPage>
                    <CreateNewBatch />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/pos-(visa)/batch-list"
                element={<Navigate to="/dashboard/pos-(visa)/batch-list/1/-id" replace />}
              />
              <Route
                path="/dashboard/pos-(visa)/batch-list/:page/:sorting"
                element={
                  <DashboardPage>
                    <BatchList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/pos-(visa)/batch-list/:page/:sorting/preview-batch/:id"
                element={
                  <DashboardPage>
                    <BatchDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/booking-requests"
                element={
                  <DashboardPage>
                    <BookingRequests />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/booking-requests/add-booking-request"
                element={
                  <DashboardPage>
                    <AddBookingRequest />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/booking-requests/booking-requests-list"
                element={
                  <DashboardPage>
                    <BookingRequestsList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/create-link"
                element={
                  <DashboardPage>
                    <CreateLink />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/create-link/customer-link"
                element={
                  <DashboardPage>
                    <CustomersLink />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/create-link/customer-link/create"
                element={
                  <DashboardPage>
                    <CreateCustomerLink />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/create-link/customer-link/link-list"
                element={
                  <DashboardPage>
                    <CustomerLinkList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/create-link/customer-link/link-list/:id/update"
                element={
                  <DashboardPage>
                    <UpdateCustomerLink />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/create-link/customer-link/link-list/:id/preview"
                element={
                  <DashboardPage>
                    <CustomerLinkDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/markup-control"
                element={
                  <DashboardPage>
                    <MarkUpControl />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/markup-control/markups-list"
                element={
                  <DashboardPage>
                    <MarkUpList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/markup-control/add-markup"
                element={
                  <DashboardPage>
                    <AddMarkup />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/markup-control/edit-markup/:id"
                element={
                  <DashboardPage>
                    <EditMarkup />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/currency-rate"
                element={
                  <DashboardPage>
                    <CurrencyRates />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/currency-rate/history/:page"
                element={
                  <DashboardPage>
                    <CurrencyHistory />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/currency-rate/history/:page/:id"
                element={
                  <DashboardPage>
                    <CurrencyHistoryDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-info-setup"
                element={
                  <DashboardPage>
                    <HotelMainInfo />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/add-hotel-main-info"
                element={
                  <DashboardPage>
                    <AddHotelMainInfo />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-main-info-list/:sorting/:page"
                element={
                  <DashboardPage>
                    <HotellMainInfoList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/edit-hotel-main-info/:id"
                element={
                  <DashboardPage>
                    <EditHotelMainInfo />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/room-packages"
                element={
                  <DashboardPage>
                    <RoomPackages />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/add-hotel-bank-details"
                element={
                  <DashboardPage>
                    <AddHotelBankDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-info-setup/hotel-bank-details-list"
                element={<Navigate to="/dashboard/hotel-info-setup/hotel-bank-details-list/1/-id" replace />}
              />
              <Route
                path="/dashboard/hotel-info-setup/hotel-bank-details-list/:page/:sorting"
                element={
                  <DashboardPage>
                    <HotelBankDetailsList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/hotel-info-setup/hotel-bank-details-list/preview/:id"
                element={
                  <DashboardPage>
                    <HotelBankDetailsListPreview />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/add-hotel-contract"
                element={
                  <DashboardPage>
                    <AddHotelContact />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/edit-hotel-contact/:id"
                element={
                  <DashboardPage>
                    <EditHotelContact />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-info-setup/hotel-contact-list"
                element={<Navigate to="/dashboard/hotel-info-setup/hotel-contact-list/1/-id" replace />}
              />
              <Route
                path="/dashboard/hotel-info-setup/hotel-contact-list/:page/:sorting"
                element={
                  <DashboardPage>
                    <HotelContactList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/Booking-request-details/:id"
                element={
                  <DashboardPage>
                    <BookingRequestDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-bank-account"
                element={
                  <DashboardPage>
                    <HotelBankAccountNumber />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/sales-team-contacts"
                element={
                  <DashboardPage>
                    <SalesTeamContact />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/reservations-contacts"
                element={
                  <DashboardPage>
                    <ReservationTeamContact />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/credit-team-contacts"
                element={
                  <DashboardPage>
                    <CreditTeamContact />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-contacts"
                element={
                  <DashboardPage>
                    <AccountingTeamContact />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/front-desk-contacts"
                element={
                  <DashboardPage>
                    <FrontDeskContact />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-main-info"
                element={
                  <DashboardPage>
                    <HotelMainInfoDetails />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/credit-hotels"
                element={
                  <DashboardPage>
                    <CreditHotel />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/cash-box"
                element={
                  <DashboardPage>
                    <AddCashBox />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/accounting-setting/cash-box-list"
                element={
                  <DashboardPage>
                    <CashBoxList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/add-collectors"
                element={
                  <DashboardPage>
                    <AddCollectors />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/accounting-setting/cash-box-list"
                element={
                  <DashboardPage>
                    <CashBoxList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/cash-boxes-statment"
                element={<Navigate to="/dashboard/cash-boxes-statment/1/-id" replace />}
              />
              <Route
                path="/dashboard/cash-boxes-statment/:page/:sorting"
                element={
                  <DashboardPage>
                    <CashBoxesStatments />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/contract-approval"
                element={<Navigate to="/dashboard/contract-approval/1/id" replace />}
              />

              <Route
                path="/dashboard/contract-approval/:page/:sorting"
                element={
                  <DashboardPage>
                    <ContractApproval />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/contract-approval/:id"
                element={
                  <DashboardPage>
                    <ContractApprovalDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/contract-archive"
                element={<Navigate to="/dashboard/contract-archive/1/id" replace />}
              />

              <Route
                path="/dashboard/contract-archive/:page/:sorting"
                element={
                  <DashboardPage>
                    <ContractArchive />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/contract-archive/:id"
                element={
                  <DashboardPage>
                    <ContractArchiveDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/departments-management"
                element={
                  <DashboardPage>
                    <DepartmentsManagment />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/departments-management/create-department"
                element={
                  <DashboardPage>
                    <CreateDepartmentForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/departments-management/edit-department/:id"
                element={
                  <DashboardPage>
                    <EditDepartmentForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/departments-management/departments-list"
                element={
                  <DashboardPage>
                    <DepartmentsList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/import-&-edit-contract"
                element={
                  <DashboardPage>
                    <ImportContract />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/staff-users"
                element={
                  <DashboardPage>
                    <StaffUsers />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/bank-list"
                element={
                  <DashboardPage>
                    <BankList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/pos-(visa)-list"
                element={
                  <DashboardPage>
                    <PosMachineList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/expenses"
                element={
                  <DashboardPage>
                    <Expenses />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-payment-orders"
                element={
                  <DashboardPage>
                    <HotelsPaymentOrders />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/import-&-edit-contract/:id"
                element={
                  <DashboardPage>
                    <AddImportedContract />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/price-test-tool"
                element={
                  <DashboardPage>
                    <PriceTestTool />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/payment-hotels/bank-cash-deposit"
                element={
                  <DashboardPage>
                    <BankCashDeposit />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/payment-hotels/payment-link"
                element={
                  <DashboardPage>
                    <PaymnetLink />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/payment-hotels/cash-in-hotel"
                element={
                  <DashboardPage>
                    <CashInHotel />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/booking-list-single-id/:short_id/:id/payment-hotels/online-bank-transfer"
                element={
                  <DashboardPage>
                    <OnlineBankTransfer />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/e-wallet"
                element={
                  <DashboardPage>
                    <EWallet />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/e-wallet/new-wallet"
                element={
                  <DashboardPage>
                    <AddNewWallet />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/e-wallet/transfer-amount"
                element={
                  <DashboardPage>
                    <TransferAmount />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/e-wallet/wallet-list"
                element={<Navigate to="/dashboard/e-wallet/wallet-list/1/-created_date" replace />}
              />
              <Route
                path="/dashboard/e-wallet/wallet-list/:page/:sorting"
                element={
                  <DashboardPage>
                    <WalletList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/e-wallet/wallet-list/preview/:id"
                element={
                  <DashboardPage>
                    <WalletDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-payment-orders/:title"
                element={
                  <DashboardPage>
                    <HotelPaymentOrdersList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/hotel-payment-orders/:title/details/:id"
                element={
                  <DashboardPage>
                    <HotelPaymentOrderDetails />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/email-settings"
                element={
                  <DashboardPage>
                    <EmailSettings />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/add-agent"
                element={
                  <DashboardPage>
                    <AddAgentForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/cancellation-policy"
                element={
                  <DashboardPage>
                    <CancellationPolicy />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/cancellation-policy/create-cancellation-policy"
                element={
                  <DashboardPage>
                    <CreateCancellationPolicy />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/airport-control"
                element={
                  <DashboardPage>
                    <MeetAssistService />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/meet-&-assist-service"
                element={
                  <DashboardPage>
                    <AirportCar general={true} />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/meet-&-assist-service/add-meet-service"
                element={
                  <DashboardPage>
                    <AddAirportService general={true} />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/meet-&-assist-service/UnderReview"
                element={<Navigate to="/dashboard/meet-&-assist-service/UnderReview/1" replace />}
              />
              <Route
                path="/dashboard/meet-&-assist-service/UnderReview/:page"
                element={
                  <DashboardPage>
                    <AirportServiceList general={true} />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/meet-&-assist-service/New"
                element={<Navigate to="/dashboard/meet-&-assist-service/New/1" replace />}
              />
              <Route
                path="/dashboard/meet-&-assist-service/New/:page"
                element={
                  <DashboardPage>
                    <AirportServiceList general={true} />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/meet-&-assist-service/Pending"
                element={<Navigate to="/dashboard/meet-&-assist-service/Pending/1" replace />}
              />
              <Route
                path="/dashboard/meet-&-assist-service/Pending/:page"
                element={
                  <DashboardPage>
                    <AirportServiceList general={true} />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/meet-&-assist-service/Archive"
                element={<Navigate to="/dashboard/meet-&-assist-service/Archive/1" replace />}
              />
              <Route
                path="/dashboard/meet-&-assist-service/Archive/:page"
                element={
                  <DashboardPage>
                    <AirportServiceList general={true} />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/meet-&-assist-service/soft-deleted/"
                element={
                  <DashboardPage>
                    <SoftDeleteList general={true} />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/meet-&-assist-service/:status/:id/preview"
                element={
                  <DashboardPage>
                    <AirportServiceDetails general={true} />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/meet-&-assist-service/:status/:id/update"
                element={
                  <DashboardPage>
                    <UpdateAirportService general={true} />
                  </DashboardPage>
                }
              />

              {/* <Route
              path="/dashboard/airport-control/work-order-list"
              element={
                <DashboardPage>
                  <WorkOrderList />
                </DashboardPage>
              }
            /> */}

              <Route
                path="/dashboard/cancellation-policy/cancellation-policy-list"
                element={
                  <DashboardPage>
                    <CancellationpolicyList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/cancellation-policy/cancellation-policy-archive"
                element={
                  <DashboardPage>
                    <CancellationPolicyArchive />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/agents-list"
                element={
                  <DashboardPage>
                    <AgentsList />
                  </DashboardPage>
                }
              />

              {/* B2B Client Start */}

              <Route
                path="/dashboard/add-client"
                element={
                  <DashboardPage>
                    <AddClientForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/edit-client/:id"
                element={
                  <DashboardPage>
                    <EditClientForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-list"
                element={
                  <DashboardPage>
                    <ClientsList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-booking-history/:clientID"
                element={
                  <DashboardPage>
                    <ClientBookingHistory />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-booking-history/:clientID/add-booking"
                element={
                  <DashboardPage>
                    <AddClientBookingHistoryForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-booking-history/:clientID/edit-booking/:bookingID"
                element={
                  <DashboardPage>
                    <EditClientBookingHistoryForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-booking-history/:clientID/booking-list"
                element={
                  <DashboardPage>
                    <ClientBookingHistoryList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-family/:clientID"
                element={
                  <DashboardPage>
                    <ClientFamily />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-family/:clientID/add-family"
                element={
                  <DashboardPage>
                    <AddClientFamilyForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-family/:clientID/family-list"
                element={
                  <DashboardPage>
                    <ClientFamilyList />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/client-family/:clientID/edit-family"
                element={
                  <DashboardPage>
                    <EditClientFamilyForm />
                  </DashboardPage>
                }
              />

              {/* B2B Client End */}

              <Route
                path="/dashboard/edit-agent/:id"
                element={
                  <DashboardPage>
                    <EditAgentForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/agents-accounting"
                element={
                  <DashboardPage>
                    <AgentsAccounting />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/agents-accounting/edit-agent-accounting/:agentID"
                element={
                  <DashboardPage>
                    <EditAgentAccountingForm />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/agent-reports"
                element={
                  <DashboardPage>
                    <AgentsReports />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/offers"
                element={
                  <DashboardPage>
                    <Offers />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/offers/offers-list"
                element={
                  <DashboardPage>
                    <OffersList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/offers/create-offer"
                element={
                  <DashboardPage>
                    <CreateOffer />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/offers/:id"
                element={
                  <DashboardPage>
                    <OfferDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/offers/edit-offer/:id"
                element={
                  <DashboardPage>
                    <EditOffer />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/whitelist-ip"
                element={
                  <DashboardPage>
                    <WhitelistIP />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/whitelist-ip/add-whitelist"
                element={
                  <DashboardPage>
                    <AddWhitelistIP />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/whitelist-ip/whitelist-ip-list"
                element={
                  <DashboardPage>
                    <WhitelistIPList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/whitelist-ip/edit-whitelist/:id"
                element={
                  <DashboardPage>
                    <EditWhitelistIP />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions"
                element={
                  <DashboardPage>
                    <Permissions />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/search-permission"
                element={
                  <DashboardPage>
                    <SearchPermission />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/search-permission/create/"
                element={
                  <DashboardPage>
                    <CreateSearchPermission />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/permissions/search-permission/create/:userID"
                element={
                  <DashboardPage>
                    <CreateSearchPermission />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/search-permission/create/:id/update"
                element={
                  <DashboardPage>
                    <CreateSearchPermission />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/search-permission/permissions-list"
                element={
                  <DashboardPage>
                    <SearchPermissionsList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/search-permission/searchpermission-details/:id"
                element={
                  <DashboardPage>
                    <UpdateSearchPermission />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/add-group-permissions"
                element={
                  <DashboardPage>
                    <AddGroupPermissions />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/edit-group-permissions"
                element={
                  <DashboardPage>
                    <EditGroupPermissions />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/assign-users-to-groups"
                element={
                  <DashboardPage>
                    <AssignUsersToGroups />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/groups-list"
                element={
                  <DashboardPage>
                    <PermissionsGroupsList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/add-users"
                element={
                  <DashboardPage>
                    <AddUsers />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/add-menus"
                element={
                  <DashboardPage>
                    <AddMenus />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/permissions/edit-menus"
                element={
                  <DashboardPage>
                    <EditMenus />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/search-users"
                element={<Navigate to="/dashboard/permissions/search-users/1" replace />}
              />
              <Route
                path="/dashboard/permissions/search-users/:page"
                element={
                  <DashboardPage>
                    <SearchUsers />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/permissions/edit-user/:id"
                element={
                  <DashboardPage>
                    <EditUsers />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/user-profile"
                element={
                  <DashboardPage>
                    <UserProfile />
                  </DashboardPage>
                }
              />
              {/* statistcis page */}
              <Route
                path="/dashboard/create-link-statistics"
                element={
                  <DashboardPage>
                    <StatisticsPage />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/account-list/posmachinelist/:id"
                element={
                  <DashboardPage>
                    <DetailsPosMachine />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/account-list/posmachinelist/create/:id/update"
                element={
                  <DashboardPage>
                    <EditPosMachine />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/account-list/banklist/create/:id/update"
                element={
                  <DashboardPage>
                    <AddBank />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/account-list/banklist/:id"
                element={
                  <DashboardPage>
                    <DetailBankList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/account-list/cash_box_list/create/:id/update"
                element={
                  <DashboardPage>
                    <EditCashBoxList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/account-list/cash_box_list/:id"
                element={
                  <DashboardPage>
                    <CashBoxDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/bank-cheques-list"
                element={
                  <DashboardPage>
                    <BankChequesList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/account-setting/bankcheque/create/:id/update"
                element={
                  <DashboardPage>
                    <AddEditBankCheques />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/add-bank-cheques"
                element={
                  <DashboardPage>
                    <AddEditBankCheques />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/bankcheque/:id/:page"
                element={
                  <DashboardPage>
                    <BankChequesDetails />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/chequelist/:id/update"
                element={
                  <DashboardPage>
                    <UpdateCheque />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/chequelist/:id"
                element={
                  <DashboardPage>
                    <ChequeList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/cheque-details/:id"
                element={
                  <DashboardPage>
                    <ChequeDetail />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/cancellation-report"
                element={
                  <DashboardPage>
                    <CancellationReport />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/posmachine"
                element={
                  <DashboardPage>
                    <PosMAchineRelated />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/cBox"
                element={
                  <DashboardPage>
                    <CashBoxRelated />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/bankrelated"
                element={
                  <DashboardPage>
                    <BankRelated />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/collectorRelated"
                element={
                  <DashboardPage>
                    <CollectorRelated />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/accounting-setting/bankChequeRelated"
                element={
                  <DashboardPage>
                    <BankChequeRelated />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/cancellation-policy/viewList/:id"
                element={
                  <DashboardPage>
                    <ViewCancellation />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/cancellation-policy/create-cancellation-policy/create/:id/update"
                element={
                  <DashboardPage>
                    <EditCancelation />
                  </DashboardPage>
                }
              />

              <Route
                path="/dashboard/user/:id"
                element={
                  <DashboardPage>
                    <AccountUser />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/Banner"
                element={
                  <DashboardPage>
                    <BannerList />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/Banner/addBanner"
                element={
                  <DashboardPage>
                    <AddBanner />
                  </DashboardPage>
                }
              />
              <Route
                path="/dashboard/Banner/EditBanner"
                element={
                  <DashboardPage>
                    <EditBanner />
                  </DashboardPage>
                }
              />
              {/* IT Department */}
              <Route
                path={"/dashboard/tickets-support"}
                element={
                  <DashboardPage>
                    <ITTickets />
                  </DashboardPage>
                }
              />

              <Route
                path={"/dashboard/tickets-support/add-ticket"}
                element={
                  <DashboardPage>
                    <AddNewITTicketForm />
                  </DashboardPage>
                }
              />

              <Route
                path={"/dashboard/tickets-support/tickets"}
                element={
                  <DashboardPage>
                    <ITTicketsList />
                  </DashboardPage>
                }
              />

              <Route
                path={"/dashboard/tickets-support/tickets/soft-deleted"}
                element={
                  <DashboardPage>
                    <SoftDeleteTicketsList />
                  </DashboardPage>
                }
              />

              <Route
                path={"/dashboard/tickets-support/tickets/:id"}
                element={
                  <DashboardPage>
                    <ITTicketDetails />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/preferred-hotels"}
                element={
                  <DashboardPage>
                    <PreferedHotels />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/addPreferedHotels"}
                element={
                  <DashboardPage>
                    <AddPreferedHotels />
                  </DashboardPage>
                }
              />
              {/* <Route
                path={"/dashboard/PreferedHotelsList"}
                element={
                  <DashboardPage>
                    <PreferedHotelList />
                  </DashboardPage>
                }
              /> */}

              <Route
                path={"/dashboard/notes"}
                element={
                  <DashboardPage>
                    <NotesPage />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool"}
                element={
                  <DashboardPage>
                    <UpgradeTool />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/hotel-upgrade-tool"}
                element={
                  <DashboardPage>
                    <HotelTool />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/hotel-upgrade-tool/offer-form"}
                element={
                  <DashboardPage>
                    <AddHotelTool />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/hotel-upgrade-tool/offer-form/:id"}
                element={
                  <DashboardPage>
                    <EditHotelTool />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list"}
                element={
                  <DashboardPage>
                    <ExtraOffersList />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/hotel-upgrade-tool/offer-list/:id"}
                element={
                  <DashboardPage>
                    <HotelOfferDetails />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/room-upgrade-tool/"}
                element={
                  <DashboardPage>
                    <RoomTool />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/room-upgrade-tool/offer-form"}
                element={
                  <DashboardPage>
                    <AddRoomExtraTool />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/room-upgrade-tool/offer-form/:id"}
                element={
                  <DashboardPage>
                    <EditRoomExtraTool />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/room-upgrade-tool/offer-list/"}
                element={
                  <DashboardPage>
                    <RoomExtraToolList />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/upgrade-tool/room-upgrade-tool/offer-list/:id"}
                element={
                  <DashboardPage>
                    <RoomExtraToolDetails />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/task-manager"}
                element={
                  <DashboardPage>
                    <TaskManager />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/add-task"}
                element={
                  <DashboardPage>
                    <AddNewTask />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/Edit-task/:id"}
                element={
                  <DashboardPage>
                    <EditTask />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/view-task/:id"}
                element={
                  <DashboardPage>
                    <ViewTask />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/task-list"}
                element={
                  <DashboardPage>
                    <TasksList />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/manual-booking"}
                element={
                  <DashboardPage>
                    <AddManualBooking />
                  </DashboardPage>
                }
              />

              <Route
                path={"/dashboard/special-rates-requests"}
                element={
                  <DashboardPage>
                    <SpecialRate />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/add-special-rates-requests"}
                element={
                  <DashboardPage>
                    <AddSpecialRate />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/special-rates-requests-list"}
                element={
                  <DashboardPage>
                    <SpecialRateList />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/add-special-rates-setup"}
                element={
                  <DashboardPage>
                    <AddSpecialRateSetup />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/special-rates-requests-list/:id"}
                element={
                  <DashboardPage>
                    <SpecialRateView />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/special-rates-requests-list-update/:id"}
                element={
                  <DashboardPage>
                    <EditSpecialRateRequest />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/collected-booking"}
                element={
                  <DashboardPage>
                    <CollectedBookingListTable />
                  </DashboardPage>
                }
              />
              <Route
                path={"/dashboard/search-analyze"}
                element={
                  <DashboardPage>
                    <SearchAnalyze />
                  </DashboardPage>
                }
              />
            </Route>

            <Route
              path="/dashboard/contracts-statistics"
              element={
                <DashboardPage>
                  <ContractsStatistics />
                </DashboardPage>
              }
            />

            <Route
              path="/dashboard/contracts-statistics/contract-department-report"
              element={
                <DashboardPage>
                  <ContractDepartmentReport />
                </DashboardPage>
              }
            />

            {/*<Route*/}
            {/*  path="/dashboard/file-uploader"*/}
            {/*  element={*/}
            {/*    <DashboardPage>*/}
            {/*      <FileUploaderPage />*/}
            {/*    </DashboardPage>*/}
            {/*  }*/}
            {/*/>*/}
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
