import api_token from "../../api/UserApi";
import { GET_EMAIL_DATA, GET_EMAIL_DATA_FLAG, POST_EMAIL_DATA_FLAG } from "../types/types";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export const getRoomBookingEmail = async (roomId) => {
  const response = await api_token(token).get(`booking/api/v1/email-data/${roomId}/`);
  return response.data;
};

export const sendRoomBookingEmail = async (data) => {
  const response = await api_token(token).post(`booking/api/v1/send-email/`, data);
  return response.data;
};

export const getBookingEmailAction = (token, id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_EMAIL_DATA_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`booking/api/v1/email-data/${id}/`);
      dispatch({
        type: GET_EMAIL_DATA,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_EMAIL_DATA_FLAG,
          payload: false,
        });
      }
    } catch (error) {}
  };
};
export const postBookingEmaailAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: POST_EMAIL_DATA_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`booking/api/v1/send-email/`, data);
      // dispatch({
      //   type:GET_EMAIL_DATA,
      //   payload:response.data
      // })
      if (response.status === 201) {
        dispatch({
          type: POST_EMAIL_DATA_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      dispatch({
        type: POST_EMAIL_DATA_FLAG,
        payload: false,
      });
    }
  };
};
