import { GET_MARKET_TYPES, GET_OFFER_TYPES, GET_MEALS_INCLUDED, GET_CURRENCY_TYPES } from "../types/types";

const defaultContractState = {
  marketTypes: [],
  offerTypes: [],
  mealsIncluded: [],
  currencyTypes: [],
};

export default function addContractReducer(state = defaultContractState, action) {
  switch (action.type) {
    case GET_MARKET_TYPES:
      return { ...state, marketTypes: action.payload };
    case GET_OFFER_TYPES:
      return { ...state, offerTypes: action.payload };
    case GET_MEALS_INCLUDED:
      return { ...state, mealsIncluded: action.payload };
    case GET_CURRENCY_TYPES:
      return { ...state, currencyTypes: action.payload };
    default:
      return state;
  }
}
