import {
  CLEAR_SINGLE_MARKUP,
  GET_ALL_MARKUPS,
  GET_MARKUP_PRICE_TYPES,
  GET_SINGLE_MARKUP,
  GET_USER_GROUPS,
} from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getAllMarkupsSuccess(data) {
  return {
    type: GET_ALL_MARKUPS,
    payload: data,
  };
}

function getMarkupPriceTypesSuccess(data) {
  return {
    type: GET_MARKUP_PRICE_TYPES,
    payload: data,
  };
}

function getUserGroupsSuccess(data) {
  return {
    type: GET_USER_GROUPS,
    payload: data,
  };
}

function getSingleMarkupSuccess(data) {
  return {
    type: GET_SINGLE_MARKUP,
    payload: data,
  };
}

export function getAllMarkups(callback, lang) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/markup/api/`, { headers: { "Accept-Language": lang } });
      dispatch(getAllMarkupsSuccess(response.data));
      callback(response);
    } catch (error) {}
  };
}

export function getMarkupPriceTypes(callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/markup/api/markup-types/");
      dispatch(getMarkupPriceTypesSuccess(response.data));
      callback(response);
    } catch (error) {}
  };
}

export function sendMarkup(data, callback) {
  return async function () {
    try {
      const response = await api_token(token).post("/markup/api/new-create/", data);
      if (response && callback) {
        callback(response);
      }
    } catch (error) {}
  };
}

export function getUserGroups(callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/permissions/api/v1/user-groups/");
      dispatch(getUserGroupsSuccess(response.data));
      callback(response);
    } catch (error) {}
  };
}

export function deleteMarkup(id, callback) {
  return async function () {
    try {
      const response = await api_token(token).delete(`/markup/api/${id}/`);

      callback(response);
    } catch (error) {}
  };
}

export function getSingleMarkup(id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/markup/api/${id}/`);
      dispatch(getSingleMarkupSuccess(response.data));
      callback(response);
    } catch (error) {}
  };
}

export function updateMarkup(id, data, callback) {
  return async function () {
    try {
      const response = await api_token(token).patch(`/markup/api/new-update/${id}/`, data);
      callback(response);
    } catch (error) {}
  };
}

export function clearSingleMarkup() {
  return {
    type: CLEAR_SINGLE_MARKUP,
  };
}
