import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { getBankByIdAction } from "../../../../redux/actions/addBankAction";
import { useParams } from "react-router";
import { TbListDetails } from "react-icons/tb";
import Loading from "../../Loading/Loading";

const DetailBankList = () => {
  const [bank, setBank] = useState([]);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const params = useParams();

  useEffect(() => {
    setLoad(true);
    dispatch(
      getBankByIdAction(token, params.id, (result) => {
        if (result.status === 200) {
          setLoad(false);
          setBank(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      <div className="flex justify-center">
        <h2 className="header-h2">
          <TbListDetails />
          Bank List Detials
        </h2>
      </div>
      {bank ? (
        <div className="  flex flex-col  ">
          <table className="min-w-full border form-wrap md:hidden">
            <tbody>
              <tr>
                <td className="p-2 font-bold border bg-gray-100">ID:</td>
                <td className="p-2 border">{bank?.id}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border bg-gray-100">Name:</td>
                <td className="p-2 border">{bank?.name}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border bg-gray-100">Account Number:</td>
                <td className="p-2 border">{bank?.account_number}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border bg-gray-100">Currency:</td>
                <td className="p-2 border">{bank?.currency}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border bg-gray-100">Account Balance:</td>
                <td className="p-2 border">{bank?.account_balance}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border bg-gray-100">Notes:</td>
                <td className="p-2 border">{bank?.notes}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border bg-gray-100">Created At:</td>
                <td className="p-2 border">{bank?.updated_at?.slice(0, 10)}</td>
              </tr>
              <tr>
                <td className="p-2 font-bold border bg-gray-100">Updated At:</td>
                <td className="p-2 border">{bank?.updated_at?.slice(11, 19)}</td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full max-md:hidden form-wrap">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 font-bold border">ID</th>
                <th className="p-2 font-bold border">Name</th>
                <th className="p-2 font-bold border">Account Number</th>
                <th className="p-2 font-bold border">Currency</th>
                <th className="p-2 font-bold border">Account Balance</th>
                <th className="p-2 font-bold border">Notes</th>
                <th className="p-2 font-bold border">Created At</th>
                <th className="p-2 font-bold border">Updated At</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td className="p-2 border">{bank?.id}</td>
                <td className="p-2 border">{bank?.name}</td>
                <td className="p-2 border">{bank?.account_number}</td>
                <td className="p-2 border">{bank?.currency}</td>
                <td className="p-2 border">{bank?.account_balance}</td>
                <td className="p-2 border">{bank?.notes}</td>
                <td className="p-2 border">{bank?.updated_at?.slice(0, 10)}</td>
                <td className="p-2 border">{bank?.updated_at?.slice(11, 19)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default DetailBankList;
