import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Select, Spin } from "antd";
import { debounce } from "lodash";
import fetchDataQuery from "../../../../react_query/fetchDataQuery"; // Adjust this path based on your setup
import "./userDepartment.css";
const { Option } = Select;

function DebouncedUserSelect({ values, setFieldValue, department }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Memoize department IDs to prevent re-calculations and re-renders
  const departmentIds = useMemo(() => (Array.isArray(department) ? department?.map((i) => i.value) : []), [department]);

  // Memoize the fetch function to avoid recreating on each render
  const fetchUsers = useCallback(
    async (query) => {
      // Start with the base URL
      let generatedUrl = `/department/api/v1/users/`;

      // Append query to the URL if it exists
      if (query) {
        generatedUrl += `?find=${query}`;
      }

      // Fetch the data
      const results = await fetchDataQuery(generatedUrl);

      // Return the first 10 results
      return results?.results;
    },
    [], // No dependencies needed, function only uses query
  );

  useEffect(() => {
    // Debounced fetch to call fetchUsers based on searchQuery and departmentIds
    const debouncedFetch = debounce(async () => {
      setLoading(true);
      const results = await fetchUsers(searchQuery);
      setData(results);
      setLoading(false);
    }, 500);

    // Fetch data regardless of the presence of searchQuery and departmentIds
    debouncedFetch();

    // Cleanup the debounced function on unmount
    return () => {
      debouncedFetch.cancel();
    };
  }, [searchQuery, departmentIds, fetchUsers]);

  return (
    <Select
      showSearch
      placeholder="Search for Users by First Name, Last Name, Username, or Email"
      value={values.user}
      onSearch={setSearchQuery}
      onChange={(selectedUser) => setFieldValue("user", selectedUser)}
      filterOption={false}
      loading={loading}
      notFoundContent={loading ? <Spin size="small" /> : "No results found"}
      className="  placeholder:text-xs placeholder:text-gray-300"
      mode="multiple"
      allowClear
    >
      {data?.map((user) => (
        <Option key={user.id} value={user.id} className="py-1">
          {`${user.first_name} ${user.last_name} (${user.username})`}
        </Option>
      ))}
    </Select>
  );
}

export default DebouncedUserSelect;
