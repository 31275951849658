import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUploader from "../../../FileUploader/FileUploader";
import { MdDelete } from "react-icons/md";
import ButtonLoading from "../../../ButtonLoading/ButtonLoading";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import mutateDataQuery from "../../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { FaPlus, FaTrash } from "react-icons/fa";

const fetchCities = () => fetchDataQuery("/search/api/v1/get-cities/");
const fetchAreas = (city) => fetchDataQuery(`/search/api/load-areas/?city_name=${city}`);
const fetchHotels = (city, area) => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]&areas=[${area}]`);
const fetchRooms = (hotel) => fetchDataQuery(`/en/rooms/api/v1/get-rooms-in-hotel/${hotel}/`);

const AddRoomExtraTool = () => {
  const [toolDocument, setToolDcoument] = useState([]);
  const [adding, setAdding] = useState(false);
  const [clicked, setClicked] = useState(false);

  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [chosenHotelVal, setChosenHotelsVal] = useState(null);
  const [roomValue, setRoomValue] = useState(null);
  const [chosenRoomVal, setChosenRoomVal] = useState(null);
  const [roomFields, setRoomFields] = useState([{ room: null, upgrade_room: null }]);
  const [roomUpgradeValue, setRoomUpgradeValue] = useState(null);
  const [inputPairs, setInputPairs] = useState([
    { id: 1, stay_period_start: null, stay_period_end: null, checkedDays: {} },
  ]);
  const navigate = useNavigate();

  const handleAddRoom = () => {
    setRoomFields([...roomFields, { room: null, upgrade_room: null }]);
  };

  const handleRemoveRoom = (index) => {
    const updatedFields = roomFields.filter((_, i) => i !== index);
    setRoomFields(updatedFields);
  };
  const {
    mutate: upgradeTool,
    isLoading,
    isPending,
  } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery("/upgrade-tool/api/v1/room-upgrade-tool/", "post", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    mutationKey: "upgradeTool",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Offer Created Successfully");
      navigate(`/dashboard/upgrade-tool/room-upgrade-tool/offer-list`);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to create new offer");
    },
  });

  const {
    data: cities,
    isLoading: loadingCities,
    error: citiesError,
  } = useQuery({
    queryKey: "cities",
    queryFn: fetchCities,
  });

  const chosenCityVal = chosenCity?.value;

  const {
    data: areas,
    isLoading: loadingAreas,
    error: areasError,
    refetch: refetchAreas,
  } = useQuery({
    queryKey: ["areas", chosenCityVal],
    queryFn: () => fetchAreas(chosenCityVal),
    enabled: !!chosenCityVal && chosenCityVal !== "All",
  });
  const {
    data: hotels,
    isLoading: loadingHotels,
    error: hotelsError,
    refetch: refetchHotels,
  } = useQuery({
    queryKey: ["hotels", chosenCityVal, chosenArea],
    queryFn: () => {
      const areaValue = chosenArea?.value === "All" ? areas.map((area) => area.id) : chosenArea?.value;
      return fetchHotels(chosenCityVal, areaValue);
    },
    enabled: !!chosenCityVal && (!!chosenArea || chosenCityVal === "All"),
  });
  const {
    data: rooms,
    isLoading: loadingRooms,
    error: roomError,
    refetch: refetchRooms,
  } = useQuery({
    queryKey: ["rooms", chosenHotelVal?.value],
    queryFn: () => fetchRooms(chosenHotelVal?.value),
    enabled: !!chosenHotelVal?.value, // Ensure query only runs if hotel has a value
  });

  useEffect(() => {
    if (chosenCity) {
      if (chosenCity?.value !== "All") {
        refetchAreas();
      } else {
        setChosenArea({ label: "All", value: "All" });
      }
    }
  }, [chosenCity, refetchAreas]);

  useEffect(() => {
    if (chosenArea) {
      refetchHotels();
    }
  }, [chosenArea, refetchHotels]);
  useEffect(() => {
    if (chosenHotelVal?.value) {
      refetchRooms(); // Refetch rooms when hotel value changes
    }
  }, [chosenHotelVal, refetchRooms]);
  useEffect(() => {
    if (hotels) {
      setChosenHotels(hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })));
    }
  }, [hotels]);

  useEffect(() => {
    if (chosenHotelVal?.value && rooms) {
      setChosenRoomVal(rooms?.map((room) => ({ label: room.room_name, value: room.id })));
    }
  }, [chosenHotelVal?.value, rooms]);

  const cityOptions = useMemo(() => cities?.map((city) => ({ label: city.name, value: city.id })), [cities]);
  const areaOptions = useMemo(
    () => [{ label: "All", value: "All" }, ...(areas?.map((area) => ({ label: area.name, value: area.id })) || [])],
    [areas],
  );
  const hotelOptions = useMemo(() => [...chosenHotels], [chosenHotels]);
  const roomOptions = useMemo(() => {
    if (chosenRoomVal && chosenRoomVal.length > 0) {
      return chosenRoomVal;
    }

    // Return room options if rooms are available
    if (rooms && rooms.length > 0) {
      return rooms.map((room) => ({ label: room.room_name, value: room.id }));
    }

    return []; // Default return value if no room options are available
  }, [chosenRoomVal, rooms]);

  const upgradeRoomOptions = roomOptions ? (
    roomOptions?.filter((val) => val?.value !== roomValue?.value)
  ) : (
    <ButtonLoading />
  );
  const getFilteredRoomOptions = (index) => {
    const selectedRooms = roomFields
      .filter((_, i) => i !== index) // Exclude the current select field
      .map((field) => field.room?.value); // Get the selected room values

    return roomOptions.filter((option) => !selectedRooms.includes(option.value));
  };

  const getFilteredUpgradeRoomOptions = (index) => {
    const selectedUpgradeRooms = roomFields
      .filter((_, i) => i !== index) // Exclude the current select field
      .map((field) => field.upgrade_room?.value); // Get the selected upgrade room values

    // Filter out the already selected rooms and the room from room selector
    return upgradeRoomOptions.filter((option) => {
      return !selectedUpgradeRooms.includes(option.value) && option.value !== roomFields[index].room?.value;
    });
  };

  const addDateRange = () => {
    setClicked(true);
    if (inputPairs[inputPairs.length - 1].stay_period_start && inputPairs[inputPairs.length - 1].stay_period_end) {
      setInputPairs([
        ...inputPairs,
        { id: Date.now(), stay_period_start: null, stay_period_end: null, checkedDays: {} },
      ]);
      setClicked(false);
    }
  };

  const removeDateRange = (index) => {
    const updatedRanges = inputPairs.filter((_, i) => i !== index);
    setInputPairs(updatedRanges);
  };

  const isDateDisabled = (date, currentIndex, isStart) => {
    return inputPairs.some((range, index) => {
      if (index === currentIndex) return false;
      if (isStart) {
        return date >= range.stay_period_start && date <= range.stay_period_end;
      } else {
        return date >= range.stay_period_start && date <= range.stay_period_end;
      }
    });
  };

  const days = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const handleDaysChange = (pairId, day) => {
    setInputPairs((prevInputPairs) =>
      prevInputPairs.map((pair) => {
        if (pair.id === pairId) {
          return {
            ...pair,
            checkedDays: {
              ...pair.checkedDays,
              [day]: !pair.checkedDays[day],
            },
          };
        }
        return pair;
      }),
    );
  };

  const checkUniqueDates = (pairs, field) => {
    const dates = pairs.map((pair) => pair[field]).filter((date) => date);
    const uniqueDates = new Set(dates);
    return dates.length === uniqueDates.size;
  };
  const arabicRegex = /^[\u0600-\u06FF\u0660-\u0669\d\s]+$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Offer name is required"),
    name_ar: Yup.string()
      .matches(arabicRegex, "Offer name (Arabic) must be in Arabic")
      .required("Offer name (Arabic) is required"),
    description: Yup.string().required("Description is required"),
    description_ar: Yup.string()
      .matches(arabicRegex, "Description (Arabic) must be in Arabic")
      .required("Description (Arabic) is required"),
    inputPairs: Yup.array().of(
      Yup.object().shape({
        stay_period_start: Yup.date()
          .required("Start date is required")
          .nullable()
          .test("unique-start-date", "This start date is already chosen", function (value) {
            return checkUniqueDates(this.parent, "stay_period_start");
          }),
        stay_period_end: Yup.date()
          .required("End date is required")
          .nullable()
          .min(Yup.ref("stay_period_start"), "End date cannot be before start date")
          .test("unique-end-date", "This end date is already chosen", function (value) {
            return checkUniqueDates(this.parent, "stay_period_end");
          }),
      }),
    ),
    // Add more validation as needed
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      name_ar: "",
      description: "",
      description_ar: "",
      document: null,
      // Add more initial values as needed
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setAdding(true);
      const upgrades = roomFields.map((field) => ({
        room: field.room?.value, // Get the value from the selected room option
        upgrade_room: field.upgrade_room?.value, // Get the value from the selected upgrade room option
      }));
      try {
        const formData = new FormData();
        // formData.append("city", values.city);
        // formData.append("area", values.area);
        formData.append("name", values.name);
        formData.append("name_ar", values.name_ar);
        formData.append("description", values.description);
        formData.append("description_ar", values.description_ar);
        // formData.append("room", roomValue?.value);
        // formData.append("upgrade_room", roomUpgradeValue?.value);
        formData.append("upgrades", JSON.stringify(upgrades));

        if (toolDocument.length > 0) {
          for (let doc of toolDocument) {
            if (!doc.valid) {
              toast.error(`File ${doc.name} is not valid because ${doc.errors[0]}`);
              return;
            }
            formData.append("document", doc.file);
          }
        }
        const dayMap = {
          Saturday: "Sat",
          Sunday: "Sun",
          Monday: "Mon",
          Tuesday: "Tue",
          Wednesday: "Wed",
          Thursday: "Thu",
          Friday: "Fri",
        }; // formData.append("document", values.document);
        // inputPairs?.forEach((pair, index) => {
        //   formData.append(`period_start_${index}`, pair.stay_period_start || "");
        //   // .toISOString().split("T")[0]
        //   formData.append(`period_end_${index}`, pair.stay_period_end || "");
        //   formData.append(
        //     `days_of_week_${index}`,
        //     `[${Object.keys(pair.checkedDays)
        //       .filter((day) => pair.checkedDays[day])
        //       .map((day) => `"${dayMap[day]}"`)
        //       .join(",")}]`,
        //   );
        // });
        const periods = inputPairs.map((pair) => ({
          start_date: pair.stay_period_start ? new Date(pair.stay_period_start).toISOString().split("T")[0] : "", // Convert to ISO string if available
          end_date: pair.stay_period_end ? new Date(pair.stay_period_end).toISOString().split("T")[0] : "", // Convert to ISO string if available
          days_of_week: Object.keys(pair.checkedDays)
            .filter((day) => pair.checkedDays[day])
            .map((day) => dayMap[day]), // Get the mapped day names
        }));
        formData.append("periods", JSON.stringify(periods));

        // Use the FormData for the API call
        await upgradeTool(formData);
      } catch (error) {
        // Handle error

        toast.error(error.message || "Failed to create new offer");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting, handleBlur } = formik;

  return (
    <section className="formPage-wrap">
      <div className="">
        <h2 className="header-h2">Add Room Extra Offer</h2>
      </div>
      <form className="form-wrap gap-2 flex flex-col" onSubmit={handleSubmit}>
        <div className="border rounded-md p-2 flex flex-col gap-4">
          <div className="input-par">
            <div className="input-chil">
              <label className="font-semibold w-full flex gap-x-2 text-[#002244]">City</label>
              <Select
                required
                className="w-full h-10 text-gray-800 font-semibold border rounded-lg bg-white"
                options={cityOptions}
                placeholder="Select City"
                onChange={(e) => {
                  setChosenCity(e);
                  setFieldValue("city", e);
                }}
                value={chosenCity}
                isLoading={loadingCities}
              />
              {citiesError && <p className="text-red-500">{citiesError.message}</p>}
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Area</label>
              <Select
                name="area"
                options={areaOptions}
                isSearchable
                value={chosenArea}
                isDisabled={chosenCityVal ? false : true}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.area && touched.area && "rgb(135 27 27)",
                  }),
                }}
                className="w-full h-10 rounded-lg bg-white"
                placeholder="Area"
                onChange={(e) => {
                  setChosenArea(e);
                  setFieldValue("area", e);
                }}
                onBlur={handleBlur}
              />
              {errors.area && touched.area && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.area}
                </div>
              )}
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Hotel</label>
              <Select
                name="hotel"
                options={hotelOptions}
                isSearchable
                isDisabled={chosenCity && chosenArea ? false : true}
                value={chosenHotelVal}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.hotel && touched.hotel && "rgb(135 27 27)",
                  }),
                }}
                className="w-full  rounded-lg bg-white"
                placeholder="Hotel"
                onChange={(e) => {
                  setFieldValue("hotel", e.value);
                  setChosenHotelsVal(e);
                }}
                onBlur={handleBlur}
              />
              {errors.hotel && touched.hotel && (
                <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                  {errors.hotel}
                </div>
              )}
            </div>
          </div>
          <div>
            {roomFields.map((field, index) => (
              <div className="input-par" key={index}>
                <div className="input-chil">
                  <label className="w-full text-gray-600 font-semibold flex items-center">Room</label>

                  <Select
                    name={`room_${index}`}
                    options={getFilteredRoomOptions(index)} // Filtered options
                    isSearchable
                    isDisabled={!chosenHotelVal}
                    value={field.room}
                    className="w-full rounded-lg bg-white"
                    placeholder="Select Room"
                    onChange={(e) => {
                      const updatedFields = [...roomFields];
                      updatedFields[index].room = e;
                      setRoomFields(updatedFields);
                      setFieldValue(`room_${index}`, e);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors[`room_${index}`] && touched[`room_${index}`] && (
                    <div className="text-red-600 text-xs">{errors[`room_${index}`]}</div>
                  )}
                </div>

                <div className="flex justify-center items-center border rounded-full bg-[#002244] w-10 h-10 mt-4">
                  <FaArrowRightArrowLeft className="flex justify-center items-center text-white" />
                </div>

                {/* Upgrade Room Selector */}
                <div className="input-chil">
                  <label className="w-full text-gray-600 font-semibold flex items-center">Upgrade Room</label>
                  <Select
                    name={`upgrade_room_${index}`}
                    options={getFilteredUpgradeRoomOptions(index)} // Filtered upgrade options
                    isSearchable
                    isDisabled={!chosenHotelVal}
                    value={field.upgrade_room}
                    className="w-full rounded-lg bg-white"
                    placeholder="Select Upgrade Room"
                    onChange={(e) => {
                      const updatedFields = [...roomFields];
                      updatedFields[index].upgrade_room = e;
                      setRoomFields(updatedFields);
                      setFieldValue(`upgrade_room_${index}`, e);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors[`upgrade_room_${index}`] && touched[`upgrade_room_${index}`] && (
                    <div className="text-red-600 text-xs">{errors[`upgrade_room_${index}`]}</div>
                  )}
                </div>

                {/* Delete button */}
                {roomFields.length > 1 && (
                  <button type="button" className="text-red-600 mt-4" onClick={() => handleRemoveRoom(index)}>
                    <FaTrash />
                  </button>
                )}
              </div>
            ))}

            {/* Add button */}
            <button type="button" className="text-green-600 mt-4 flex items-center" onClick={handleAddRoom}>
              <FaPlus className="mr-2" /> Add Another Room
            </button>
          </div>
        </div>

        <div className="input-par">
          <div className="input-chil ">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Offer Name
            </label>
            <input
              id="name"
              type="text"
              className="mt-1 block w-full border p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && touched.name && <div className="text-red-600 text-sm">{errors.name}</div>}
          </div>
          <div className="input-chil">
            <label dir="rtl" htmlFor="name_ar" className="block text-sm font-medium text-gray-700">
              إسم العرض
            </label>
            <input
              id="name_ar"
              dir="rtl"
              type="text"
              className="mt-1 block border p-2 w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={values.name_ar}
              onChange={handleChange}
            />
            {errors.name_ar && touched.name_ar && <div className="text-red-600 text-sm">{errors.name_ar}</div>}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            rows={5}
            dir="ltr"
            id="description"
            className="mt-1 border p-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={values.description}
            onChange={handleChange}
          />
          {errors.description && touched.description && (
            <div className="text-red-600 text-sm">{errors.description}</div>
          )}
        </div>
        <div className="form-group">
          <label dir="rtl" htmlFor="name_ar" className="block  text-sm font-medium text-gray-700">
            الوصف
          </label>
          <textarea
            rows={5}
            dir="rtl"
            id="description_ar"
            className="mt-1 block w-full border p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={values.description_ar}
            onChange={handleChange}
          />
          {errors.description_ar && touched.description_ar && (
            <div className="text-red-600 text-sm">{errors.description_ar}</div>
          )}
        </div>

        <div className="border  p-2 rounded-lg ">
          <label
            htmlFor="validities"
            className="text-center !text-lg font-semi-bold header-h2 justify-center flex w-full"
          >
            Validities
          </label>
          <div className="flex flex-col gap-2">
            {inputPairs?.map((pair, index) => (
              <div key={pair.id} className="border p-4 rounded-lg bg-gray-50 space-y-4 relative">
                <label className="block text-lg font-semibold">Stay Period {index + 1}</label>
                <div className="input-par">
                  <div className="input-chil">
                    <label htmlFor={`start_date_${pair.id}`} className="block text-sm font-medium text-gray-700">
                      Start Date
                    </label>
                    <input
                      id={`start_date_${pair.id}`}
                      type="date"
                      className="mt-1 block w-full py-1.5 px-2 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm floating-input"
                      value={pair.stay_period_start || ""}
                      onChange={(e) => {
                        const startDate = e.target.value;
                        setInputPairs((prevInputPairs) =>
                          prevInputPairs.map((p) => (p.id === pair.id ? { ...p, stay_period_start: startDate } : p)),
                        );
                      }}
                      min={inputPairs[index - 1]?.stay_period_end || ""}
                      disabled={isDateDisabled(new Date(pair.stay_period_start), index, true)}
                      required
                    />
                  </div>
                  <div className="input-chil">
                    <label htmlFor={`end_date_${pair.id}`} className="block text-sm font-medium text-gray-700">
                      End Date
                    </label>
                    <input
                      id={`end_date_${pair.id}`}
                      type="date"
                      className="mt-1 block w-full py-1.5 px-2 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm floating-input"
                      value={pair.stay_period_end || ""}
                      onChange={(e) => {
                        const endDate = e.target.value;
                        setInputPairs((prevInputPairs) =>
                          prevInputPairs.map((p) => (p.id === pair.id ? { ...p, stay_period_end: endDate } : p)),
                        );
                      }}
                      min={pair.stay_period_start || ""}
                      disabled={isDateDisabled(new Date(pair.stay_period_end), index, false)}
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap gap-2 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="activeDays"> Active Days</label>
                    <div className="grid lg:grid-cols-7 md:grid-cols-4 grid-cols-2  w-full">
                      {days.map((day, index) => (
                        <div key={index} className="flex w-full">
                          <label key={day} className="flex items-center space-x-2">
                            <input
                              type="checkbox"
                              checked={pair.checkedDays[day] || false}
                              onChange={() => handleDaysChange(pair.id, day)}
                              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                            <span className="text-sm text-gray-700">{day}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className=" mt-4">
                    {clicked && (
                      <p className="text-red-700 text-sm">
                        {pair?.stay_period_start === null || pair?.stay_period_end === null
                          ? "Please select a start date and end date"
                          : ""}
                      </p>
                    )}
                  </div>
                </div>
                {inputPairs?.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeDateRange(index)}
                    className="absolute top-0 right-[3%] flex items-center bg-red-600 hover:bg-red-700 text-sm bg text-white rounded-full p-3"
                  >
                    <MdDelete size={20} className="rounded-full" />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-center">
            <button
              type="button"
              onClick={addDateRange}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Date Range
            </button>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="document" className="block text-sm font-medium text-gray-700">
            Upgrade Document
          </label>
          <FileUploader
            maxFiles={1}
            maxFileSize={2}
            accept={"image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .csv"}
            setFiles={setToolDcoument}
            files={toolDocument}
          />
          {errors.document && touched.document && <div className="text-red-600 text-sm">{errors.document}</div>}
        </div>
        <div className="mt-6 flex justify-center items-center">
          <button type="submit" disabled={isPending} className="addButton">
            {isPending ? (
              <div className="flex items-center gap-2">
                Adding...
                <ButtonLoading />
              </div>
            ) : (
              "Add"
            )}
          </button>
        </div>
      </form>
    </section>
  );
};

export default AddRoomExtraTool;
