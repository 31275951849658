import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction } from "../../../redux/actions/bookingListAction";
import cookie from "react-cookies";
import Select from "react-select";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import lightToast from "light-toast";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { BsFillCreditCardFill } from "react-icons/bs";
import { VscAdd } from "react-icons/vsc";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { postPosMachineAction } from "../../../redux/actions/posMachineAction";
import TopBarProgress from "react-topbar-progress-indicator";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

const CreateNewPosVisa = () => {
  const [checkId, setCheckId] = useState(false);
  const [checkOption, setCheckOption] = useState(false);
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [btnCheck, setBtnCheck] = useState(false);
  const [cardStatus, setCardStatus] = useState(false);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [date, setDate] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);
  const { bankTransferFlag } = useSelector((state) => state.bookingListReducer);
  const { currencyList } = useSelector((state) => state.bookingListReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  const { postMachineDataFlag } = useSelector((state) => state.posMachineReducer);
  useRemoveScroll();

  useEffect(() => {
    dispatch(getCurrencyAction(token));
    dispatch(getBankListAction(token));
  }, []);

  let CurrencyType = currencyList
    ? currencyList.map((item, index) => {
        return { value: item, label: item };
      })
    : [];

  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];
  const handleFileChange = (e) => {
    setFile(e.target.value);
  };

  // const formData = new FormData();
  // formData.append('file', file);

  const transferData = {
    bank_name: bank,
    transferor_name: name,
    transfer_amount: amount,
    transfer_acc_number: Number(accountNumber),
    transfer_date: "2023-09-9",
    currency_type: currency,
    booked_room: selectedSingleRoom.id,
    transfer_receipt: file,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("bank", bank);
    dispatch(
      postPosMachineAction(token, formData, (result) => {
        if (result.status === 201) {
          lightToast.success("POS Visa was completed Created");
          navigate(-1);
        }
        // else
        // {
        //     toast.success(`The bank transfer was completed successfully`)
        // }
      }),
    );
  };
  return (
    <div className="formPage-wrap">
      {currencyList && bankData ? (
        <div className=" w-full  ">
          <div className="flex items-center justify-center gap-x-5  text-md">
            <h2 className="header-h2">
              {" "}
              <div className="relative flex flex-col">
                <BsFillCreditCardFill className="w-5 h-5" />
                <VscAdd className="absolute top-3 left-5 w-3 h-3" />
              </div>
              Create New POS Visa{" "}
            </h2>
          </div>
          <form className=" form-wrap w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <div className="flex w-full max-sm:flex-col gap-4">
                <div className="flex-1 max-sm:w-full">
                  <label className=" flex text-gray-600   ">MID Number</label>
                  <input
                    placeholder="MID Numbers"
                    name="mid_number"
                    type="number"
                    required
                    className=" w-full px-3 h-10  border rounded-lg bg-white "
                  />
                </div>
                <div className="flex-1 max-sm:w-full">
                  <label className=" flex text-gray-600 ">Currency</label>
                  <Select
                    name="currency"
                    options={CurrencyType}
                    onChange={(e) => {
                      setCurrency(e.label);
                    }}
                    isSearchable
                    className=" w-full  h-10  rounded-lg  bg-white "
                    placeholder="Currency"
                  />
                </div>
              </div>
              <div className="flex w-full max-sm:flex-col gap-4">
                <div className="flex-1 max-sm:w-full">
                  <label className=" flex text-gray-600   ">Bank Name</label>
                  <Select
                    placeholder="Bank Name"
                    type="text"
                    required
                    options={Bank}
                    onChange={(e) => {
                      setBank(e.value);
                    }}
                    isSearchable
                    className=" w-full  h-10  rounded-lg  bg-white "
                  />
                </div>
                <div className="flex-1 max-sm:w-full">
                  <label className=" flex text-gray-600   ">Location (City)</label>
                  <input
                    placeholder="Location"
                    name="location"
                    type="text"
                    required
                    // onChange={(e)=>setBank(e.target.value)}
                    className=" w-full px-3 h-10  border rounded-lg bg-white "
                  />
                </div>
              </div>

              <label className=" flex text-gray-600   ">Notes</label>
              <textarea
                name="notes"
                type="text"
                required
                // onChange={(e)=>setBank(e.target.value)}
                className=" w-full  p-3 border rounded-lg bg-white "
              />
              <button
                type="Submit"
                disabled={postMachineDataFlag}
                className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Create {postMachineDataFlag ? <ButtonLoading /> : <IoAdd className="w-5 h-5" />}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <>
          <TopBarProgress /> <Loading />
        </>
      )}
    </div>
  );
};

export default CreateNewPosVisa;
