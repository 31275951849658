import React, { useEffect } from "react";
import "./DashClocks.scss";
import DashClockCard from "../../molecules/DashClockCard/DashClockCard";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function DashClocks() {
  useEffect(() => {
    moment.locale("en");
  }, []);

  return (
    <div className="w-full grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 place-content-center  px-6">
      <DashClockCard
        props={{
          country: "Egypt",
          timezone: "Africa/Cairo",
          bg: "inline-block p-6 bg-gradient-to-r from-sky-400 to-sky-800  ",
        }}
      />
      <DashClockCard
        props={{
          country: "London",
          timezone: "Europe/London",
          bg: "inline-block p-6 bg-gradient-to-br from-yellow-400 to-orange-500",
        }}
      />
      <DashClockCard
        props={{
          country: "Saudi Arabia",
          timezone: "Asia/Riyadh",
          bg: "inline-block p-6 bg-gradient-to-l from-green-400 to-teal-500  ",
        }}
      />
      <DashClockCard
        props={{
          country: "UAE",
          timezone: "Asia/Dubai",
          bg: "inline-block p-6 bg-gradient-to-br from-teal-400 to-cyan-400",
        }}
      />
    </div>
  );
}
