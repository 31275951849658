import React from "react";
import { FaRegImages } from "react-icons/fa";
import { RiImageEditLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { IoCloudUploadOutline } from "react-icons/io5";

const BannerList = () => {
  //Permission

  return (
    <div className="formPage-wrap">
      <div className=" w-full">
        <div className="w-full flex justify-center  ">
          <h2 className="header-h2">
            <FaRegImages className="w-5 h-5" />
            Search Banner
          </h2>
        </div>
        <div className="w-full  grid sm:grid-cols-2   form-wrap grid-cols-1">
          <div className="flex flex-col gap-y-4 items-center justify-between   pt-4  border-solid">
            <Link
              to={"addBanner"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center">
                <div className="relative flex flex-col">
                  <IoCloudUploadOutline className="w-12 h-12" />
                </div>
              </div>
            </Link>
            <p className="text-lg font-semibold">Add Banner</p>
          </div>

          <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"EditBanner"}
              // onClick={() => navigate("./edit-group-permissions")}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <RiImageEditLine className="w-12 h-12" />
              </div>
            </Link>

            <p className="text-lg font-semibold"> Edit Banner </p>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default BannerList;
