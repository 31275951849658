import {
  GET_ALL_CONTRACTS,
  GET_SINGLE_CONTRACT,
  CONTRACT_FLAG,
  GET_CONTRACT_STATUSES,
  GET_UNDER_REVIEW_CONTRACTS,
  GET_ARCHIVED_CONTRACTS,
  GET_HOTEL_CONTRACTS,
  CLEAR_SINGLE_CONTRACT,
} from "../types/types";

const allContractsDefaultState = {
  count: "",
  results: [],
};
const underReviewContractsDefaultState = {
  count: "",
  results: [],
};
const archivedContractsDefaultState = {
  count: "",
  results: [],
};

const hotelContractsDefaultState = [];

const singleContractDefaultState = {
  reservation_policy: null,
  same_day_reservation_price: "",
  booking_valid_from: "",
  booking_valid_to: "",
  one_day_supplements: [],
  contractFlag: false,
};

export function getAllContractsReducer(state = allContractsDefaultState, action) {
  switch (action.type) {
    case GET_ALL_CONTRACTS:
      return { ...action.payload };
    default:
      return state;
  }
}

export function underReviewContractsReducer(state = underReviewContractsDefaultState, action) {
  switch (action.type) {
    case GET_UNDER_REVIEW_CONTRACTS:
      return { ...action.payload };
    default:
      return state;
  }
}

export function archivedContractsReducer(state = archivedContractsDefaultState, action) {
  switch (action.type) {
    case GET_ARCHIVED_CONTRACTS:
      return { ...action.payload };
    default:
      return state;
  }
}

export function hotelContractsReducer(state = hotelContractsDefaultState, action) {
  switch (action.type) {
    case GET_HOTEL_CONTRACTS:
      return action.payload;
    default:
      return state;
  }
}

export function getSingleContractReducer(state = singleContractDefaultState, action) {
  switch (action.type) {
    case GET_SINGLE_CONTRACT:
      return { ...state, ...action.payload };
    case CONTRACT_FLAG:
      return { ...state, contractFlag: action.payload };
    case CLEAR_SINGLE_CONTRACT:
      return singleContractDefaultState;
    default:
      return state;
  }
}

export function ContractStatusesReducer(state = [], action) {
  switch (action.type) {
    case GET_CONTRACT_STATUSES:
      return action.payload;
    default:
      return state;
  }
}
