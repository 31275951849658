import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { TbListDetails } from "react-icons/tb";
import TopBarProgress from "react-topbar-progress-indicator";
import { GoPerson } from "react-icons/go";
import { BiTransfer } from "react-icons/bi";
import { LiaCalendar } from "react-icons/lia";
import { BsCurrencyExchange } from "react-icons/bs";
import { AiOutlineBank } from "react-icons/ai";
import { getSelectedBatchAction } from "../../../redux/actions/newBatchAction";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import NoPermission from "../NoPermission/NoPermission";

const BatchDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [toggle, setToggle] = useState(false);
  const [posState, setPosState] = useState([]);
  const [flag, setFlag] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const token = cookie.load("access_token");
  const { getSelcBatch } = useSelector((state) => state.newBatchReducer);
  const { selectetdPosVisa } = useSelector((state) => state.posVisaReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  const { getSelcBatchFlag } = useSelector((state) => state.newBatchReducer);

  useEffect(() => {
    dispatch(getSelectedBatchAction(token, params.id));
    dispatch(getBankListAction(token));
  }, []);

  const bankTable = getSelcBatch && bankData ? bankData.filter((item) => item.id === getSelcBatch.bank) : [];

  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.view_batch"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////
  return (
    <div className="w-full overflow-x-auto">
      {getSelcBatch && !getSelcBatchFlag && permissionFlag ? (
        permission.map((item) => {
          if (item.name === "accounting.view_batch" && item.value === true) {
            return (
              <div className="container border-gray-300 border rounded-lg  p-10 ">
                <div className="flex flex-col justify-center items-center ">
                  <div className="flex flex-col    w-full  text-black rounded-md  ">
                    <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-xl text-white h-16">
                      <p className="flex items-center gap-x-3">
                        <TbListDetails className="w-5 h-5" />
                        Batch Details{" "}
                      </p>
                    </div>
                    {/* Hotel id & id & persons*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">ID</p>
                        </div>
                        <div>
                          <p className="flex items-center gap-x-1">
                            <BiTransfer className="w-5 h-5 text-[#002244]" /> {getSelcBatch.id}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex items-center w-[50%]">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Created By Name</p>
                        </div>
                        <div className="w-[70%]">
                          <p className="flex items-center gap-x-1">
                            <GoPerson className="w-5 h-5 text-[#002244]" /> {getSelcBatch.created_by_name}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Client & phone Number*/}
                    <div className="flex w-full justify-between items-center  p-3">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Batch Number </p>
                        </div>
                        <div className="w-[70%]">
                          <p className="flex items-center gap-x-1">
                            <GiTakeMyMoney className="w-5 h-5 text-[#002244]" />
                            {getSelcBatch.batch_number}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">MID Number </p>
                        </div>
                        <div className="w-[70%]">
                          <p className="flex items-center gap-x-1">{getSelcBatch.mid_number}</p>
                        </div>
                      </div>
                    </div>
                    {/* WhatsApp Number & Email*/}
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Currency</p>
                        </div>
                        <div className="w-[70%]">
                          <p className="flex items-center gap-x-1">
                            <BsCurrencyExchange className="w-5 h-5 text-[#002244]" />
                            {getSelcBatch.currency}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Bank</p>
                        </div>
                        <div className="w-[70%]">
                          <p className="flex items-center gap-x-1">
                            <AiOutlineBank className="w-5 h-5 text-[#002244]" />
                            {bankTable[0].name}
                          </p>
                          <p className="flex items-center gap-x-1"></p>
                        </div>
                      </div>
                    </div>
                    {/* Hotel & Room Name*/}
                    <div className="flex w-full justify-between items-center  p-3">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">total </p>
                        </div>
                        <div className="w-[70%]">
                          <p className="flex items-center gap-x-1">
                            <GiMoneyStack className="w-4 h-4 text-[#002244]" />
                            {getSelcBatch.total}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">SubTotal </p>
                        </div>
                        <div className="w-[70%]">
                          <p className="flex items-center gap-x-1">
                            <GiTakeMyMoney className="w-5 h-5 text-[#002244]" />
                            {getSelcBatch.subtotal}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full justify-between items-center  p-3 bg-gray-200">
                      <div className="flex  justify-between items-center w-[50%] ">
                        <div className="w-[33%]  ">
                          <p className="flex items-center gap-x-1 font-bold">Created By </p>
                        </div>
                        <div className="w-[70%]">
                          <p className="flex items-center gap-x-1">
                            <GoPerson className="w-5 h-5 text-[#002244]" /> {getSelcBatch.created_by}
                          </p>
                        </div>
                      </div>
                      {/* <div className='flex  justify-between items-center w-[50%] '>
                                <div className='w-[33%]  '>
                                  <p className='flex items-center gap-x-1 font-bold'>POS Machine </p>
                                </div> 
                                <div className='w-[70%]'>
                                    <p className='flex items-center gap-x-1'>{getSelcBatch.pos_machines}</p>
                                </div>
                              </div> */}
                    </div>
                  </div>
                  <div className="w-full flex flex-col items-center justify-center mt-10">
                    <p className="flex items-center gap-x-1 font-bold text-md">POS Machine </p>
                    <div className="w-full grid grid-cols-5 items-center rounded-lg bg-[#002244] ">
                      <div className="flex flex-col items-center justify-between  border-solid">
                        <div className="w-full flex items-center justify-center  py-3  text-white ">
                          <p className="flex items-center gap-x-1">
                            {" "}
                            <BiTransfer className="w-5 h-5 text-white" />
                            ID
                          </p>
                        </div>
                      </div>

                      {/* <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                     <div className="w-full flex items-center justify-center py-3 text-white ">
                       <p className="flex items-center gap-x-1"> <BiTransfer className="w-5 h-5 text-white" />Order ID</p>
                      </div>
                  </div> */}
                      <div className="flex flex-col items-center justify-between gap-y-4 border-solid">
                        <div className="w-full flex items-center justify-center py-3 text-white  ">
                          <p className="flex items-center gap-x-1">
                            <GiMoneyStack className="w-5 h-5 text-white" />
                            Amount
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-between gap-y-4   border-solid">
                        <div className="w-full flex items-center justify-center py-3 text-white ">
                          <p className="flex items-center gap-x-1">
                            <GiMoneyStack className="w-5 h-5 text-white" />
                            Balance
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-between gap-y-4 border-solid">
                        <div className="w-full flex items-center justify-center py-3 text-white ">
                          <p className="flex items-center gap-x-1">
                            {" "}
                            <GiMoneyStack className="w-5 h-5 text-white" /> Commission
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-between gap-y-4 border-solid">
                        <div className="w-full flex items-center justify-center py-3 text-white  ">
                          <p className="flex items-center gap-x-1">
                            <LiaCalendar className="w-5 h-5 text-white" />
                            Date
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full grid grid-cols-5 items-center rounded-lg bg-white ">
                      {getSelcBatch.pos_machines.length === 0 ? (
                        <div className="flex flex-col items-center justify-between  border-solid col-span-6 bg-gray-100">
                          <div className="w-full flex items-center justify-center  py-3  text-gray-800 ">
                            <p className="text-gray-500">Not Found Transactions Visa For This Batch </p>
                          </div>
                        </div>
                      ) : (
                        getSelcBatch &&
                        getSelcBatch.pos_machines.map((item) => {
                          return (
                            <>
                              <div className="flex flex-col items-center justify-between  border-solid">
                                <div className="w-full flex items-center justify-center  py-3  text-gray-800 ">
                                  <p>{item.id}</p>
                                </div>
                              </div>

                              <div className="flex flex-col items-center justify-between gap-y-4 border-solid">
                                <div className="w-full flex items-center justify-center py-3 text-gray-800 ">
                                  <p>{item.amount} </p>
                                </div>
                              </div>
                              {/* <div class="flex flex-col items-center justify-between gap-y-4 border-solid">
                     <div className="w-full flex items-center justify-center py-3 text-gray-800 ">
                       <p>{item.booked_room}</p>
                      </div>
                  </div> */}
                              <div className="flex flex-col items-center justify-between gap-y-4 border-solid">
                                <div className="w-full flex items-center justify-center py-3 text-gray-800  ">
                                  <p>{item.balance}</p>
                                </div>
                              </div>
                              <div className="flex flex-col items-center justify-between gap-y-4   border-solid">
                                <div className="w-full flex items-center justify-center py-3 text-gray-800 ">
                                  <p>{item.commission}</p>
                                </div>
                              </div>
                              <div className="flex flex-col items-center justify-between gap-y-4 border-solid">
                                <div className="w-full flex items-center justify-center py-3 text-gray-800  ">
                                  <p>{item.created_at.slice(0, 10)}</p>
                                </div>
                              </div>
                            </>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="w-full h-screen">
                <NoPermission />
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
};

export default BatchDetails;
