import React from "react";
import BankChequeListRelated from "./BankChequeListRelated";
import AddBankChequeRelated from "./AddBankChequeRelated";

const BankChequeRelated = () => {
  return (
    <div className="formPage-wrap">
      <div className="form-wrap  grid sm:grid-cols-2 grid-cols-1  ">
        <BankChequeListRelated />
        <AddBankChequeRelated />
      </div>
    </div>
  );
};

export default BankChequeRelated;
