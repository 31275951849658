import React, { useEffect, useState } from "react";
import Map from "google-map-react";
import GoogleMapsSearchBox from "../../atoms/GoogleMapSearchBox/GoogleMapsSearchBox";

export default function GoogleMap({ getLocationData, defaultProps, searchValue, formikSet }) {
  const key = 1;
  const [mapKey, setMapKey] = useState(key);
  const Marker = ({ svg }) => svg;
  const [mapRef, setMapRef] = useState(null);
  const [mapsRef, setMapsRef] = useState(null);
  const handleApiLoaded = (map, maps) => {
    // Store the map and maps objects in the state
    setMapRef(map);
    setMapsRef(maps);
  };

  // this use effect checks if mapRef is defined and if not it changes the ket to allow for another render to ensure the map
  // will load and search box will appear every time
  useEffect(() => {
    if (mapRef === null) {
      setMapKey(key + 1);
    }
  });

  return (
    <div style={{ height: "400px" }}>
      <div style={{ height: "300px" }}>
        {/* in case searchvalue is defined the value will be passed to the map component otherwise the map will be called */}
        {searchValue
          ? mapRef &&
            mapsRef && (
              <GoogleMapsSearchBox
                map={mapRef}
                maps={mapsRef}
                getLocationData={getLocationData}
                searchValue={searchValue}
                formikSet={formikSet}
              />
            )
          : mapRef &&
            mapsRef && (
              <GoogleMapsSearchBox
                map={mapRef}
                maps={mapsRef}
                getLocationData={getLocationData}
                formikSet={formikSet}
              />
            )}
        <Map
          key={mapKey}
          bootstrapURLKeys={{
            key: "AIzaSyBP17fEEeHvC5egLp88y-vMpbavGvrnQcU",
            libraries: ["places"],
          }}
          center={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            handleApiLoaded(map, maps);
          }}
        >
          <Marker
            svg={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>map-marker</title>
                <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
              </svg>
            }
          />
        </Map>
      </div>
    </div>
  );
}
