import React, { useEffect, useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { RiKeyboardLine } from "react-icons/ri";
import { VscAdd } from "react-icons/vsc";
import { useNavigate } from "react-router";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";
import { Link } from "react-router-dom";

export default function MarkUpControl() {
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const dispatch = useDispatch();
  const permissionData = {
    permission_codes: ["markup.add_markup", "markup.view_markup"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission.some((item) => item.value) ? (
          <div className="w-full ">
            <div className="w-full flex justify-center   ">
              <h2 className="header-h2">
                <RiKeyboardLine className="w-5 h-5" />
                Markup Control
              </h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 form-wrap ">
              {permission.map((item) => {
                if (item.name === "markup.add_markup" && item.value === true) {
                  return (
                    <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                      <Link
                        to={"./add-markup"}
                        // onClick={() => navigate("./add-markup")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-[#0C4A6E] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <VscAdd className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Add Markup</p>
                    </div>
                  );
                } else if (item.name === "markup.view_markup" && item.value === true) {
                  return (
                    <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                      <Link
                        to={"./markups-list"}
                        // onClick={() => navigate("./markups-list")}
                        className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        <div className="w-full flex justify-center items-center ">
                          <AiOutlineUnorderedList className="w-12 h-12  " />
                        </div>
                      </Link>
                      <p className="text-lg font-semibold">Markups List</p>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : (
          <div className="w-ffull h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
