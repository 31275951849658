import React from "react";
import "./DashStatesCard.scss";
import { Pie } from "react-chartjs-2";

export default function DashStatesCard(props) {
  const {
    svg,
    title,
    quantity,
    labels = ["Yellow", "White", " Blue"],
    stats = [12, 19, 25],
    dataLabel = "# of Votes",
  } = props.props;
  const data = {
    labels: labels,
    datasets: [
      {
        label: dataLabel,
        data: stats,
        backgroundColor: ["rgb(220 179 8) ", "white", "#002244"],
        borderColor: ["rgb(170, 132, 83)", "rgb(0, 26, 53))", "rgb(0, 0, 0)"],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className={`w-full h-36 flex items-center justify-around bg-gray-50 shadow-lg rounded-lg  `}>
      <div className="w-[40%] flex flex-col  items-start justify-start gap-y-1 ">
        <div className="w-7 h-7 ">{svg}</div>
        <div className="font-semibold text-gray-800">{quantity}</div>
        <div className="font-semibold text-gray-800 block">{title}</div>
      </div>
      <div className="md:w-28  w-20 ">
        <Pie data={data} options={options} />
      </div>
    </div>
  );
}
