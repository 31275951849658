import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loading from "../../../Loading/Loading";
import Errorfetch from "../../../Errorfetch/Errorfetch";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import usePermissions from "../../../../../customHooks/usePermissions";
import ITTickestListTable from "./ITTickestListTable";

function ITTicketsList() {
  const [websitesData, setWebisteData] = useState();
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "it_ticketing_system.view_ticketsupport",
    "it_ticketing_system.delete_ticketsupport",
  ]);

  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status") || "open";

  const [selectedWebsites, setSelectedWebsites] = useState();
  const [paginationModel, setPaginationModel] = useState({
    current: 1,
    pageSize: 20,
  });
  const [selectedPriority, setSelectedPriority] = useState();

  const priority = selectedPriority?.value;
  const canViewTickets =
    permissions?.find((permission) => permission.name === "it_ticketing_system.view_ticketsupport")?.value === true;

  const canDeleteTickets =
    permissions?.find((permission) => permission.name === "it_ticketing_system.delete_ticketsupport")?.value === true;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (canViewTickets) {
      queryClient.invalidateQueries([
        "tickets",
        status,
        paginationModel.current,
        paginationModel.pageSize,
        selectedPriority?.value,
        selectedWebsites,
      ]);
    }
  }, [paginationModel, canViewTickets, status, queryClient, selectedPriority?.value, selectedWebsites]);

  useEffect(() => {
    const searchParams = {
      status,
      page: paginationModel.current,
      pageSize: paginationModel.pageSize,
    };

    if (selectedPriority) {
      searchParams.priority = selectedPriority.value;
    }
    if (selectedWebsites?.length > 0) {
      searchParams.website = selectedWebsites?.map((website) => website.value).join(",");
    }
    setSearchParams(searchParams);
  }, [paginationModel, selectedPriority, selectedWebsites, status, setSearchParams]);

  const { data: websiteData, isSuccess } = useQuery({
    queryFn: () => fetchDataQuery(`/it-ticketing-system/api/v1/tickets-support/filter_websites/`),
    queryKey: ["websiteData"],
  });

  const {
    isLoading: isFetchingTicketsList,
    error: isFetchingError,
    data,
  } = useQuery({
    queryFn: () => {
      let endpoint = `/it-ticketing-system/api/v1/tickets-support/?status=${status}&page=${paginationModel.current}&page_size=${paginationModel.pageSize}`;

      if (selectedPriority) {
        endpoint += `&priority=${selectedPriority.value}`;
      }
      if (selectedWebsites?.length > 0) {
        endpoint += `&website=${selectedWebsites?.map((website) => website.value).join(",")}`;
      }

      return fetchDataQuery(endpoint);
    },
    queryKey: [
      "tickets",
      status,
      paginationModel.current,
      paginationModel.pageSize,
      selectedWebsites,
      websiteData,
      selectedPriority?.value,
    ],
    enabled: canViewTickets,
  });

  useEffect(() => {
    if (isSuccess) {
      setWebisteData(websiteData);
    }
  }, [isSuccess, websiteData]);

  const tickets = data?.results;
  const count = data?.count;

  if (isFetchingTicketsList || !permissionFlag) {
    return <Loading />;
  }

  if (isFetchingError) {
    return <Errorfetch Error={`Error fetching ${status} tickets list`} />;
  }

  if (permissionsError) {
    return <Errorfetch Error={"Error fetching permissions"} />;
  }

  return (
    <ITTickestListTable
      websitesData={websiteData}
      softDelete={true}
      canViewTickets={canViewTickets}
      canDeleteTickets={canDeleteTickets}
      count={count}
      tickets={tickets}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      setSelectedPriority={setSelectedPriority}
      selectedPriority={selectedPriority}
      setSelectedWebsites={setSelectedWebsites}
      selectedWebsites={selectedWebsites}
    />
  );
}

export default ITTicketsList;
