import React, { useEffect, useState } from "react";
import Navbar from "../../organisms/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ClientNameFormAr from "../../organisms/ClientNameFormAr/ClientNameFormAr";
import ClientNameFormEn from "../../organisms/ClientNameFornEn/ClientNameFormEn";
import { useLocation } from "react-router-dom";
import cookie from "react-cookies";
import { getClientRequestTag, reservationRoomAction } from "../../../redux/actions/reservationAction";
import { BiBed, BiCalendar } from "react-icons/bi";
import { GiNightSleep, GiPerson } from "react-icons/gi";
import { FaHotel } from "react-icons/fa";

const Reservation = () => {
  const location = useLocation();
  const token = cookie.load("access_token");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientRequestTag(token));
  }, []);

  const { clientRequestData } = useSelector((state) => state.reservationReducer);
  const clientRequsetOption = [];
  clientRequestData.map((item) => {
    clientRequsetOption.push({ value: item.id, label: item.tag });
  });

  const [reservation, setReservation] = useState("reservation");
  const [clientnames, setClientName] = useState("");
  const [showPrice, setShowPrice] = useState();
  const [addtoprice, setAddtoprice] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [phonenumber, setPhonenumber] = useState("");
  const [whatsappnumber, setWhatsappnumber] = useState("");
  const [email, setEmail] = useState("");
  const [clientrequesttags, setClientrequesttags] = useState([]);
  const [voucher, setVoucher] = useState(false);
  const [clientrequest, setClientrequest] = useState("");
  const [clientNameAr, setClientNameAr] = useState("");
  const [clientNameEn, setClientNameEn] = useState("");
  const [validationOption, setValidationOption] = useState(false);
  const [arrivalTimeFrom, setArrivalTimeFrom] = useState("");
  const [arrivalTimeTo, setArrivalTimeTo] = useState("");

  useEffect(() => {
    setShowPrice(location.state.price);
  }, []);

  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer);

  let ClientArray = [];
  for (let y = 0; y < dataFromSearchForReseve.persons; y++) {
    ClientArray.push({ arabic_name: clientNameAr[y], english_name: clientNameEn[y] });
  }

  const reservationData = {
    number_of_persons: dataFromSearchForReseve.persons,
    client_names: ClientArray,
    phone_number: phonenumber,
    whatsapp_number: whatsappnumber,
    booking_type: voucher ? "voucher" : "voucher",
    email: email,
    client_requests_text: clientrequest,
    hotel: location.state.nameofhotel,
    room: location.state.selectedroom,
    client_requests_tags: [clientrequesttags],
    arrival_time_from: arrivalTimeFrom,
    arrival_time_to: arrivalTimeTo,
    add_to_total_price: parseInt(addtoprice),
    total_price: parseInt(totalPrice),
    from_date: dataFromSearchForReseve.from_date,
    to_date: dataFromSearchForReseve.to_date,
    nights: dataFromSearchForReseve.nights,
  };

  const handleBook = (e) => {
    e.preventDefault();
    dispatch(reservationRoomAction(token, reservationData));
  };
  const handleTotalPrice = (e) => {
    setShowPrice(parseInt(e) + parseInt(location.state.price));
    setTotalPrice(parseInt(e) + parseInt(location.state.price));
  };

  return (
    <div className="h-full">
      <Navbar />
      <div className="w-full  border-black border">
        <img src="/images/front.959.png" className="w-full lg:h-[500px] " />
      </div>
      <div className="  justify-center items-center  text-white flex flex-wrap lg:gap-x-10 gap-4  w-full  mt-10  ">
        <div
          className="flex flex-col justify-center  items-center gap-y-3 cursor-pointer  bg-gray-200 text-black
                         border w-[15%] h-28  hover:scale-105 active:scale-95 duration-300 transition-all  rounded-lg  shadow-lg shadow-slate-300 "
        >
          <div className="flex items-center  justify-start gap-x-3  w-full ">
            <div className="w-28 ">
              <p className=" text-center text-xl font-semibold">Check In </p>
            </div>
            <div className="flex items-center  ml-4  font-semibold text-black border-b-2 border-opacity-50  ">
              <span>
                <BiCalendar className="w-5 h-5 text-green-700 " />
              </span>
              {dataFromSearchForReseve.from_date}
            </div>
          </div>

          <div className="flex items-center justify-start gap-x-3 w-full ">
            <div className="w-32">
              <p className=" text-center text-xl font-semibold">Check Out </p>
            </div>
            <div className="flex items-center  font-semibold text-black border-b-2 border-opacity-50">
              <span>
                <BiCalendar className="w-5 h-5 text-red-800 " />
              </span>
              {dataFromSearchForReseve.to_date}
            </div>
          </div>
        </div>
        <div className=" flex justify-center items-center w-[10%] h-28    shadow-lg shadow-slate-300 bg-gray-200 text-black  font-semibold rounded-xl text-2xl  border hover:scale-105 active:scale-95 cursor-pointer duration-300 transition-all">
          <p className="flex gap-x-2">
            <GiNightSleep className="text-yellow-400 w-8 h-8" />
            {dataFromSearchForReseve.nights} Nights
          </p>
        </div>
        <div className=" flex justify-center items-center w-[10%] h-28   shadow-lg shadow-slate-300 bg-gray-200  text-black   rounded-xl  font-semibold text-3xl  border hover:scale-105 active:scale-95 cursor-pointer duration-300 transition-all">
          <p className="flex items-center">
            {dataFromSearchForReseve.persons} <GiPerson className="text-black " />
          </p>
        </div>
        <div className="flex justify-center items-center gap-x-2 px-5 py-9   rounded-xl   shadow-lg shadow-slate-300   bg-gray-200  text-black   border hover:scale-105 active:scale-95 cursor-pointer duration-300 transition-all">
          <div className="flex items-end gap-x-2">
            <p className="flex  font-semibold text-3xl ">${showPrice}</p>
            <p className="text-xl  font-semibold text-green-800">USD</p>
          </div>
        </div>
        <div className="flex justify-center items-center gap-x-2 px-5 py-10 text-xl rounded-xl  font-semibold shadow-lg shadow-slate-300   bg-gray-200  text-black  border hover:scale-105 active:scale-95 cursor-pointer duration-300 transition-all">
          <p className="flex items-center gap-x-3">
            <FaHotel className="text-black " />
            {location.state.nameofhotel}
          </p>
        </div>
        <div className="flex justify-center items-center gap-x-2 px-5 py-10 text-xl rounded-xl  font-semibold shadow-lg shadow-slate-300   bg-gray-200  text-black  border hover:scale-105 active:scale-95 cursor-pointer duration-300 transition-all">
          <p className="flex items-center gap-x-3">
            <BiBed className="text-black " />
            {location.state.selectedroom}
          </p>
        </div>
      </div>

      <div className=" p-5 flex  w-full justify-center items-center   pb-5 mt-12 shadow-xl shadow-gray-500 rounded-2xl ">
        <form className="flex flex-col justify-center items-center gap-y-10 w-[70%]   shadow-xl shadow-slate-400 p-5 rounded-3xl ">
          <div className="flex flex-col md:flex-row justify-center items-center gap-y-10 gap-x-5 w-full  ">
            <div className="flex flex-col   w-[45%]">
              <ClientNameFormEn setClientNameEn={setClientNameEn} />
            </div>
            <div className="flex flex-col w-[45%]">
              <ClientNameFormAr setClientNameAr={setClientNameAr} />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center gap-x-5 w-full ">
            <div className="flex flex-col  w-[45%]">
              <label className=" flex text-gray-600 text-lg  ">
                Add To Price<span className="text-yellow-400">*</span>
              </label>
              <input
                name="addtoprice"
                type="number"
                min={0}
                className="w-full py-2 rounded-lg border-gray-300 border  "
                onChange={(e) => {
                  setAddtoprice(e.target.value);
                  handleTotalPrice(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col  w-[45%]">
              <label className="  flex  text-gray-600 text-lg  ">
                Phone Number<span className="text-yellow-400">*</span>
              </label>
              <input
                name="phonenumber"
                type="number"
                className="w-full py-2 rounded-lg border-gray-300 border  "
                onChange={(e) => setPhonenumber(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-x-5 w-full">
            <div className="flex flex-col  w-[45%]">
              <label className="  text-gray-600 text-lg  ">
                Whatsapp Number<span className="text-yellow-400">*</span>
              </label>
              <input
                name="whatsappnumber"
                type="number"
                className="w-full py-2 rounded-lg border-gray-300 border "
                onChange={(e) => setWhatsappnumber(e.target.value)}
              />
            </div>
            <div className="flex flex-col  w-[45%]">
              <label className="  text-gray-600 text-lg  ">
                Email<span className="text-yellow-400">*</span>
              </label>
              <input
                name="email"
                type="email"
                className="w-full py-2 rounded-lg border-gray-300 border "
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center gap-x-5 w-full ">
            <div className="flex flex-col  w-[45%]  ">
              <label className="  text-gray-600 text-lg  ">
                Client request tags<span className="text-yellow-400">*</span>
              </label>
              <Select
                className="w-full rounded-lg  "
                name="clientrequesttags"
                options={clientRequsetOption}
                onChange={(e) => setClientrequesttags(e.label)}
                isSearchable
                placeholder="Client request tags"
              />
            </div>
            <div className="flex flex-col w-[45%]">
              <label className="  text-gray-600 text-lg  ">
                Client Requests<span className="text-yellow-400">*</span>
              </label>
              <textarea
                name="clientrequest"
                type="text"
                className="w-full  rounded-lg border-gray-300 border "
                onChange={(e) => setClientrequest(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center gap-x-5 w-full">
            <div className="flex justify-between w-[45%]">
              <div className="flex flex-col w-[45%]">
                <label className="  text-gray-600 text-lg  ">Arrival Time From</label>
                <input
                  type="time"
                  id="meeting-time"
                  name="meeting-time"
                  className="w-full py-2 rounded-lg border-gray-300 border"
                  onChange={(e) => setArrivalTimeFrom(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-[45%]">
                <label className="  text-gray-600 text-lg  ">Arrival Time To</label>
                <input
                  type="time"
                  id="meeting-time"
                  name="meeting-time"
                  className="w-full py-2 rounded-lg border-gray-300 border"
                  onChange={(e) => setArrivalTimeTo(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col w-[45%]  ">
              <p>
                Voucher<span className="text-yellow-400">*</span>
              </p>
              <div className="flex w-full border-gray-300 border justify-around items-center p-3 rounded-xl">
                <div className="flex justify-center items-center w-[45%] gap-x-2 ">
                  <input name="voucher" type="radio" className="w-5 h-5" onChange={(e) => setVoucher(true)} />
                  <label>Voucher</label>
                </div>
                <div className="flex justify-center items-center w-[45%] gap-x-2">
                  <input name="voucher" type="radio" className="w-5 h-5  " onChange={(e) => setVoucher(false)} />
                  <label>Without Voucher</label>
                </div>
              </div>
            </div>
          </div>

          <button
            className="w-24 h-10  bg-gray-950 rounded-lg text-white hover:scale-105 active:scale-95 duration-300 transition-all  "
            type="submit"
            onClick={handleBook}
          >
            Book Now
          </button>
        </form>
      </div>
    </div>
  );
};

export default Reservation;
