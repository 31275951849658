import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Menu, Select, Table } from "antd";
import { HiOutlineViewColumns } from "react-icons/hi2";
import { IoMdArrowDropdown } from "react-icons/io";
import { TbListDetails } from "react-icons/tb";
import Loading from "../components/organisms/Loading/Loading";

const { Option } = Select;

const ListFilters = ({
  columns,
  rows,
  title,
  className,
  filters,
  params,
  url,
  listPageSize,
  totalPages,
  currentPage,
  setCurrentPage,
  setListPageSize,
  condition,
  handlePagination,
}) => {
  const [visibleColumns, setVisibleColumns] = useState(columns.map((col) => ({ ...col, visible: true })));
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedData, setSortedData] = useState([]);

  // Update sortedData whenever rows, sortColumn, or sortOrder change
  useEffect(() => {
    if (!sortColumn || !sortOrder) {
      setSortedData(rows);
      return;
    }

    const sortedRows = [...rows].sort((a, b) => {
      if (sortOrder === "ascend") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    });

    setSortedData(sortedRows);
  }, [rows, sortColumn, sortOrder]);

  const handleToggleColumn = (key) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.map((col) => (col.key === key ? { ...col, visible: !col.visible } : col)),
    );
  };

  const handleRemoveColumn = (key) => {
    setVisibleColumns((prevColumns) => prevColumns.filter((col) => col.key !== key));
  };

  const getSortOrder = (column) => {
    if (sortColumn === column) {
      return sortOrder;
    }
    return null;
  };

  const handleSortColumnChange = (value) => {
    setSortColumn(value);
  };

  const handleSortOrderChange = (value) => {
    setSortOrder(value);
  };

  const toggleMenu = (
    <Menu>
      {visibleColumns.map((col) => (
        <Menu.Item key={col.key}>
          <Checkbox className="flex items-center" checked={col.visible} onChange={() => handleToggleColumn(col.key)}>
            {col.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  const columnsWithOperations = visibleColumns
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      sortOrder: getSortOrder(col.dataIndex),
    }));

  return (
    <div className=" bg-gray-50 flex flex-col items-end mt-4">
      <div className="flex justify-between w-full max-md:flex-col gap-2 max-md:justify-center max-md:items-center ">
        <h2 className={` header-h2 max-md:text-center w-full max-md:justify-center capitalize ${className}`}>
          {title && <TbListDetails className="w-6 h-6 text-yellow-600 " />}
          {title}
        </h2>

        <div className="flex gap-2 items-center max-md:flex-col">
          {filters && <div className="">{filters}</div>}
          <Dropdown
            overlay={toggleMenu}
            trigger={["click"]}
            visible={visibleDropdown}
            onVisibleChange={setVisibleDropdown}
            onOpenChange={setVisibleDropdown}
          >
            <button
              className="bg-gray-100 font-medium px-6 py-1 text-[#002244] hover:bg-gray-200 hover:shadow-md duration-300 transition-all flex gap-2 items-center rounded-md w-max"
              onClick={() => setVisibleDropdown(!visibleDropdown)}
            >
              <HiOutlineViewColumns className="h-5 w-5" />
              Toggle Columns
              <IoMdArrowDropdown className="h-5 w-5" />
            </button>
          </Dropdown>
          <div className="flex items-start gap-2 ">
            <Select
              placeholder="Sort by"
              style={{ minWidth: "max-content", width: 200 }}
              onChange={handleSortColumnChange}
              className="sortOrder text-sm"
            >
              {visibleColumns
                .filter((col) => col.key !== "operations")
                .map((col) => (
                  <Option key={col.key} value={col.dataIndex} className="flex items-start flex-col ">
                    {col.title}
                  </Option>
                ))}
            </Select>
            <Select
              className="sortOrder"
              placeholder="Sort Order"
              style={{ width: "max-content" }}
              onChange={handleSortOrderChange}
            >
              <Option value="ascend">Ascending</Option>
              <Option value="descend">Descending</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="w-full h-full ">
        <div className="w-full   overflow-x-auto ">
          <div className="bg-gray-50  ">
            <Table
              loading={sortedData ? "" : <Loading />}
              columns={columnsWithOperations}
              dataSource={sortedData}
              pagination={{
                showQuickJumper: true,
                showSizeChanger: true,
                defaultPageSize: 20,
                current: currentPage,
                total: totalPages * listPageSize,
                onChange: handlePagination,
                pageSize: listPageSize,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListFilters;
