import React from "react";
import { VscAdd } from "react-icons/vsc";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { BsFillCreditCardFill } from "react-icons/bs";
import { IoCreateOutline } from "react-icons/io5";
import { GiMoneyStack } from "react-icons/gi";
import { RiKeyboardLine } from "react-icons/ri";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { CiViewList } from "react-icons/ci";
const CreditHotel = () => {
  return (
    <div className="px-6 py-8">
      <div className="w-full border-gray-300 border rounded-lg bg-white px-6 flex flex-col items-center">
        <div className="w-full flex justify-center   ">
          <h2 className="flex items-center gap-x-3 border-[#00224493] border text-gray-100 font-bold  rounded-lg text-2xl bg-[#002244] mt-3 px-4 py-2">
            <RiKeyboardLine className="w-5 h-5" />
            Credit Hotels
          </h2>
        </div>

        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 text-center sm:grid-cols-1 grid-cols-1 p-2  gap-y-8 my-5 border bg-gray-50 rounded-md">
          <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
            <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
              <div className="w-full flex justify-center items-center ">
                <LiaFileInvoiceDollarSolid className="w-12 h-12  text-yellow-500 " />
              </div>
            </button>
            <p className="text-lg font-semibold">Pay Cash Invoice</p>
          </div>

          <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
            <button className="flex items-center gap-x-1 w-24 h-24 rounded-full  bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
              <div className="w-full flex justify-center items-center ">
                <IoCreateOutline className="w-12 h-12  text-yellow-500 " />
              </div>
            </button>
            <p className="text-lg font-semibold ">Create Statement</p>
          </div>

          <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid">
            <button className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
              <div className="w-full flex justify-center items-center ">
                <CiViewList className="w-12 h-12 text-yellow-500  " />
              </div>
            </button>
            <p className="text-lg font-semibold">Statement List</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditHotel;
