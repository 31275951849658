import React from "react";
import Select from "react-select";
import { AiOutlineLoading } from "react-icons/ai";
import { Button } from "flowbite-react";
import { Formik, Form } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
function ChangeTaskStatusForm({ status }) {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { isPending, mutate: changeStatus } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/tasks-management/api/v1/task-assignments/${id}/`, "patch", data);
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Status Changed Successfully");
      void queryClient.refetchQueries({
        queryKey: ["ticket-details", id],
      });
    },
  });

  function handleSubmit(values) {
    if (!values.status) return;
    changeStatus({
      status: values.status,
    });
  }

  return (
    <Formik
      initialValues={{
        status: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form className="flex gap-2">
            <Select
              className="w-max"
              onChange={(newValue) => {
                setFieldValue("status", newValue.value);
              }}
              options={[
                { label: "New", value: "new" },
                { label: "Pending", value: "pending" },
                {
                  label: "Done",
                  value: "done",
                },
              ]}
            />
            <Button
              className="mb-4"
              color="success"
              isProcessing={isPending}
              type="submit"
              disabled={isPending || !values.status}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
            >
              Change Status
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ChangeTaskStatusForm;
