import api_token from "../../api/UserApi";
import {
  CREATE_NEW_BATCH,
  CREATE_NEW_BATCH_FLAG,
  GET_POS_MACHINE_BATCH,
  GET_ALL_BATCH,
  GET_ALL_BATCH_FLAG,
  DELETE_BATCH_FLAG,
  GET_SELECTED_BATCH,
  GET_SELECTED_BATCH_FLAG,
} from "../types/types";

export const getPosMachineBatch = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`accounting/api/v1/pos-machines/not-in-batch/${id}/`);

      dispatch({
        type: GET_POS_MACHINE_BATCH,
        payload: response.data,
      });
      callback(response);
    } catch (error) {}
  };
};

export const createNewBatchAction = (token, data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_NEW_BATCH_FLAG,
        payload: true,
      });
      const response = await api_token(token).post(`accounting/api/v1/batches/`, data);

      dispatch({
        type: CREATE_NEW_BATCH,
        payload: response.data,
      });
      if (response.status === 201) {
        dispatch({
          type: CREATE_NEW_BATCH_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {
      dispatch({
        type: CREATE_NEW_BATCH_FLAG,
        payload: false,
      });
    }
  };
};

export const getAllBatchAction = (token, page, sort, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_BATCH_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(
        `accounting/api/v1/batches/?ordering=${sort}&page_size=10&page=${page}`,
      );
      dispatch({
        type: GET_ALL_BATCH,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_ALL_BATCH_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const deleteBatchAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_BATCH_FLAG,
        payload: true,
      });
      const response = await api_token(token).delete(`accounting/api/v1/batches/${id}/`);
      if (response.status === 204) {
        dispatch({
          type: DELETE_BATCH_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};

export const getSelectedBatchAction = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_SELECTED_BATCH_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`accounting/api/v1/batches/${id}/`);
      dispatch({
        type: GET_SELECTED_BATCH,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_SELECTED_BATCH_FLAG,
          payload: false,
        });
      }
      callback(response);
    } catch (error) {}
  };
};
