import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export async function archiveBooking(bookingId) {
  try {
    const response = await api_token(token).post(`/booking/api/v1/archive-book-room/${bookingId}/`);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      throw new Error("Error arching booking");
    }
  } catch (e) {
    throw new Error(e);
  }
}
