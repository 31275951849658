import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../../redux/actions/loginAction";
import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import LoginLoadingBtn from "../LoginLoadingBtn/LoginLoadingBtn";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { NotificationContainer, NotificationManager } from "react-notifications";
import CodeForm from "./CodeForm";
import { IoIosArrowBack } from "react-icons/io";
import { IoLogInOutline } from "react-icons/io5";
import { CiBarcode } from "react-icons/ci";
import { toast } from "react-toastify";

const Form = () => {
  const [username, setUsername] = useState();
  const [password, setPasword] = useState();
  const [errorUserName, setErrorUserName] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [codeFlag, setCodeFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookie.load("access_token");
  const { loginFlag } = useSelector((state) => state.loginReducerUser);
  const { loginError } = useSelector((state) => state.loginReducerUser);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (
    <div className="w-full grid md:p-10 p-[2rem] ">
      {/* <p className="text-3xl font-bold  text-[#002235] text-center  mb-3">LOGIN</p> */}
      <div className=" m-auto">
        <img src="/images/logo.png" className=" h-36  " />
      </div>
      {/* {loginError && <p className="text-orange-600 mb-1">{loginError}</p>} */}
      {codeFlag ? (
        <div className="w-full ">
          <CodeForm setCodeFlag={setCodeFlag} />
        </div>
      ) : (
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={Yup.object().shape({
            username: Yup.string()
              .min(3, "Name Should be between 3 and 50 characters")
              .max(50, "Name should less than 50 characters")
              .required("Username is Required"),
            password: Yup.string().required("Password is Required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            resetForm();
            dispatch(
              loginAction(values, (result) => {
                if (result.status === 200) {
                  cookie.save("access_token", result.data.tokens.access, { path: "/" });
                  cookie.save("refresh_token", result.data.tokens.refresh, { path: "/" });
                  cookie.save("currency", "USD", { path: "/" });
                  cookie.save("language", "ar", { path: "/" });
                  navigate("/");
                } else {
                  if (result?.response?.data[0]?.message) {
                    toast.error("The password or username is incorrect.");
                  } else {
                    toast.error("This IP is not authorized to enter the system.");
                  }
                }
              }),
            );
            setUsername(values.username);
            setPasword(values.password);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, dirty, isSubmitting, handleReset, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="grid md:gap-y-5 gap-y-5 ">
              <span>
                <p className="text-[#002235] font-medium">Enter Username</p>
                <div className="w-full flex flex-col gap-y-1">
                  <input
                    className="shadow appearance-none w-full border border-gray-300  rounded-2xl font-semibold  h-10 lg:h-12 px-4 text-[#002235] leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="username"
                    placeholder="Enter Username"
                    onChange={handleChange}
                    value={values.username}
                    onBlur={handleBlur}
                  />

                  <span className="text-yellow-600 font-semibold">
                    {errors.username && touched.username && errors.username}
                  </span>
                </div>
              </span>
              <span>
                <p className="text-[#002235] font-medium">Enter Password</p>

                <div className="relative">
                  <input
                    className="shadow appearance-none w-full border border-gray-300  rounded-2xl font-semibold h-10 lg:h-12 px-4 text-[#002235] leading-tight focus:outline-none focus:shadow-outline"
                    type={passwordCheck ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                  />
                  {passwordCheck ? (
                    <button
                      type="button"
                      onClick={() => setPasswordCheck(false)}
                      className="absolute lg:top-4 top-3 right-3  hover:text-black active:scale-90 duration-300 transition-all "
                    >
                      <VscEyeClosed className="w-6 h-6 text-orange-600" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setPasswordCheck(true)}
                      className="absolute lg:top-4 top-3 right-3  hover:text-black active:scale-90 duration-300 transition-all "
                    >
                      <VscEye className="w-6 h-6 text-yellow-600" />
                    </button>
                  )}
                  <span className="text-yellow-600 font-semibold">
                    {errors.password && touched.password && errors.password}
                  </span>
                </div>
              </span>

              <div className="grid  lg:gap-x-5 md:gap-x-10 md:gap-y-5 gap-y-2 ">
                <button
                  className="w-full bg-yellow-600 text-white font-semibold lg:px-5 h-10 lg:h-12 px-4  rounded-2xl  active:scale-95  hover:bg-yellow-700 duration-300 transition-all "
                  type="submit"
                  disabled={loginFlag}
                >
                  <div className="flex items-center justify-center gap-x-1">
                    {loginFlag ? (
                      <LoginLoadingBtn />
                    ) : (
                      <>
                        <IoLogInOutline className="w-5 h-5" />
                        <p>Login</p>{" "}
                      </>
                    )}
                  </div>
                </button>
                <button
                  className="flex items-center gap-x-1 justify-center w-full bg-[#002235] text-white font-semibold lg:px-5  h-10 lg:h-12 px-4  rounded-2xl  active:scale-95  hover:bg-slate-950 duration-300 transition-all "
                  type="button"
                  onClick={() => setCodeFlag(true)}
                >
                  <CiBarcode className="w-5 h-5" /> Login with code
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
      <NotificationContainer />
    </div>
  );
};

export default Form;
