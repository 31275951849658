import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Loading from "../Loading/Loading";
import useHotelNames from "../../../customHooks/useHotelNames";
import { getData } from "../../../redux/actions/sendDataAction";
import { FaStreetView } from "react-icons/fa";
import { useNavigate } from "react-router";
import TopBarProgress from "react-topbar-progress-indicator";
import toast from "react-hot-toast";
import { TbListDetails } from "react-icons/tb";
import { MdDeleteSweep, MdOutlineBalcony, MdOutlineBedroomParent, MdOutlineSettings } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { RxSpaceBetweenHorizontally } from "react-icons/rx";
import { Link } from "react-router-dom";
import { deleteRoom } from "../../../redux/actions/getRooms";
import DeletePopUp from "../../molecules/DeletePopUp/DeletePopUp";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import ListFilters from "../../../utils/ListFilters";
import CityAreaHotel from "../../../customComponents/CityAreaHotelCustom/CityAreaHotel";

export default function RoomListTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotels = useHotelNames();
  const hotelsArr = hotels ? hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })) : [];
  const [roomsList, setRoomsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [roomData, setRoomData] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [deletePopup, setDeletePopup] = useState("");
  const [deleteHotelID, setDeleteHotelID] = useState(null);
  const [rooms, setRooms] = useState([]);

  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "rooms_management.add_rooms",
      "rooms_management.change_rooms",
      "rooms_management.delete_rooms",
      "rooms_management.view_rooms",
    ],
  };

  // Initialize state from localStorage if it exists
  const [permission, setPermission] = useState(() => {
    const storedPermission = localStorage.getItem("permission");
    return storedPermission ? JSON.parse(storedPermission) : undefined;
  });
  const [permissionFlag, setPermissionFlag] = useState(!!localStorage.getItem("permission"));

  useEffect(() => {
    if (!permissionFlag) {
      dispatch(
        getPermissionAction(token, permissionData, (result) => {
          if (result.status === 200) {
            setPermissionFlag(true);
            setPermission(result.data);
          }
        }),
      );
    }
  }, [dispatch, token, permissionFlag]);

  useEffect(() => {
    if (permissionFlag) {
      localStorage.setItem("permission", JSON.stringify(permission));
    }
  }, [permission, permissionFlag]);

  // useEffect(() => {
  //   fetchRooms();
  // }, []);

  // const fetchRooms = () => {
  //   dispatch(
  //     getData(`/en/rooms/api/v1/get-rooms-in-hotel/${hotelId}/`, (res) => {
  //       if (res) {
  //         setRooms(res.data);
  //       }
  //     }),
  //   );
  // };

  // const handleRoomDeleted = (deletedRoomId) => {
  //   setRooms(rooms.filter((room) => room.id !== deletedRoomId));
  // };
  function handleDeletePopUp(params) {
    setDeletePopup(params.roomName);
    setDeleteHotelID(params.id);
  }
  function handleDelete() {
    setLoading(true);
    dispatch(
      deleteRoom(deleteHotelID, (result) => {
        if (result.status === 200) {
          // Remove the room from local storage
          const storedRooms = JSON.parse(localStorage.getItem("roomsList"));
          const updatedRooms = storedRooms.filter((room) => room.id !== deleteHotelID);
          localStorage.setItem("roomsList", JSON.stringify(updatedRooms));
          // Update state
          setRoomsList(updatedRooms);
          // Refetch rooms data
          getRooms(selectedHotel.value);
          setLoading(false);
          toast.success("Room deleted successfully");
        } else {
          setLoading(false);
          toast.error("Failed to delete room");
        }
      }),
    );
    setDeletePopup("");
  }

  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full justify-center items-center gap-x-1">
          <BiTransfer className="w-5 h-5 text-yellow-500" />
          ID
        </div>
      ),
      dataIndex: "id",
      render: (text, params) => {
        return <div className="ml-3">{params.id}</div>;
      },
    },
    {
      key: "roomName",
      dataIndex: "roomName",
      title: (
        <div className="flex w-full justify-center items-center gap-x-1">
          <MdOutlineBedroomParent className="w-5 h-5 text-yellow-500" />
          ROOM NAME
        </div>
      ),
    },
    {
      key: "space",
      minWidth: 100,
      dataIndex: "space",
      title: (
        <div className="flex w-full justify-center items-center gap-x-1">
          <RxSpaceBetweenHorizontally className="w-5 h-5 text-yellow-500" />
          SPACE
        </div>
      ),
    },
    {
      key: "view",
      dataIndex: "view",
      title: (
        <div className="flex w-full justify-center items-center gap-x-1">
          <FaStreetView className="w-5 h-5 text-yellow-500" />
          VIEW
        </div>
      ),
    },
    {
      key: "balcony",
      title: (
        <div className="flex w-full justify-center items-center gap-x-1">
          <MdOutlineBalcony className="w-5 h-5 text-yellow-500" />
          BALCONY
        </div>
      ),
      dataIndex: "balcony",
    },
    {
      key: "Actions",
      filterable: false,
      sortable: false,
      title: (
        <div className="flex w-full justify-center items-center gap-x-1">
          <MdOutlineSettings className="w-5 h-5 text-yellow-500" />
          ACTIONS
        </div>
      ),
      dataIndex: "Actions",
      render: (text, params) => {
        return (
          <div className="w-full flex items-center justify-center  gap-x-3">
            {permission?.map((item) => {
              if (item.name === "rooms_management.change_rooms" && item.value === true) {
                return (
                  <button
                    key="edit"
                    className="action-btn-edit hover:scale-105 active:scale-95 duration-300 transition-all"
                    onClick={() => navigate(`/dashboard/edit-room/${params.id}`)}
                  >
                    <FiEdit className="w-5 h-5 text-blue-500" />
                  </button>
                );
              } else if (item.name === "rooms_management.add_rooms" && item.value === true) {
                return (
                  <Link to={`/dashboard/room-details/${params.id}`}>
                    <AiOutlineEye className="w-5 h-5 text-slate-800 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all " />
                  </Link>
                );
              } else if (item.name === "rooms_management.delete_rooms" && item.value === true) {
                return (
                  <button
                    key="delete"
                    className="action-btn-delete hover:scale-105 active:scale-95 duration-300 transition-all"
                    onClick={() => handleDeletePopUp(params)}
                  >
                    <MdDeleteSweep className="w-5 h-5 text-red-500" />
                  </button>
                );
              }
              return null;
            })}
            {/* <Link to={`/dashboard/room-details/${params.id}`}>
              <AiOutlineEye className="w-5 h-5 text-slate-800 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all " />
            </Link>
            <Link to={`/dashboard/edit-room/${params.id}`}>
              <FiEdit className="w-5 h-5 text-green-800 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all " />
            </Link> */}
            {/* <AiFillDelete
              onClick={() => handleDeletePopUp(params)}
              className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
            /> */}
          </div>
        );
      },
    },
  ];

  const rows = roomsList?.map((room) => ({
    id: room.id,
    roomName: room.room_name,
    space: room.space,
    view: room.view,
    balcony: room.balcony,
  }));

  useEffect(() => {
    const storedHotelId = localStorage.getItem("selectedHotelId");
    const storedRooms = JSON.parse(localStorage.getItem("roomsList"));
    if (hotelsArr.length > 0 && storedHotelId) {
      const storedHotel = hotelsArr.find((hotel) => hotel.label === storedHotelId);
      if (storedHotel) {
        setSelectedHotel((prevSelectedHotel) => {
          if (prevSelectedHotel?.value !== storedHotel.value) {
            return storedHotel;
          }
          return prevSelectedHotel;
        });

        if (storedRooms) {
          setRoomsList((prevRoomsList) => {
            if (prevRoomsList.length !== storedRooms.length) {
              return storedRooms;
            }
            return prevRoomsList;
          });
        } else {
          getRooms(storedHotel.value);
        }
      }
    }
  }, [hotelsArr, roomsList]);

  const getRooms = (hotelId) => {
    setLoading(true);
    if (hotelId) {
      dispatch(
        getData(
          `/en/rooms/api/v1/get-rooms-in-hotel/${hotelId}/`,
          (resp) => {
            setLoading(false);
            setRoomsList(resp.data);
            localStorage.setItem("roomsList", JSON.stringify(resp.data));
          },
          () => {
            setLoading(false);
            toast.error("There was an error fetching rooms. Please try again.");
          },
        ),
      );
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedHotel(selectedOption);
    localStorage.setItem("selectedHotelId", selectedOption.label);
    getRooms(selectedOption.value);
  };

  return (
    <div className="formPage-wrap min-h-screen bg-gray-50">
      {hotels ? (
        <>
          <div className="flex justify-center">
            <h2 className="header-h2">
              <TbListDetails className="w-6 h-6 mt-0.5 text-yellow-500" />
              Room List
            </h2>
          </div>

          <div className="w-full flex flex-col items-center center p-2 mx-auto rounded-md gap-4 mb-4  max-w-6xl border  justify-center gap-y-5 form-wrap">
            <label htmlFor="hotel" className="font-bold text-md text-gray-800  capitalize">
              Please select hotels to show their rooms
            </label>

            <CityAreaHotel
              render={(hotel) => {
                handleChange(hotel);
              }}
              localStorageName={"RoomhotelList"}
            />
            {/* <Select
              options={hotelsArr}
              isSearchable
              value={selectedHotel}
              onChange={handleChange}
              className="md:flex-[1] w-full font-semibold text-gray-800 capitalize"
              placeholder="Select Hotel"
            /> */}
          </div>
          {loading ? (
            <div className="w-full flex items-center justify-center h-screen">
              <Loading />
              <TopBarProgress />
            </div>
          ) : roomsList.length > 0 ? (
            <ListFilters columns={columns} rows={rows} title="Rooms List" />
          ) : (
            <div className="w-full flex items-center justify-center h-screen">
              <img src="/images/download.png" className="w-96 h-96 opacity-30 mb-48" />
            </div>
          )}
          {deletePopup && <DeletePopUp name={deletePopup} Func={handleDelete} hideFunc={() => setDeletePopup("")} />}
        </>
      ) : (
        <div className="w-full flex items-center justify-center h-screen">
          <Loading />
          <TopBarProgress />
        </div>
      )}
    </div>
  );
}
