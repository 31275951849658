import React, { useEffect, useState } from "react";
import { getUserProfileAction, updateUserProfileAction } from "../../../redux/actions/userProfileAction";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";
import { LuUpload } from "react-icons/lu";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { MdOutlineDone, MdOutlineMailOutline, MdPublishedWithChanges } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { base_URL } from "../../../urls";
import lightToast from "light-toast";
import { useNavigate } from "react-router";
import { IoKey } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";

import PasswordPage from "../PasswordPage/PasswordPage";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { Switch } from "antd";
import { FaWhatsapp } from "react-icons/fa6";
import PhoneInput from "react-phone-number-input";
import UserSalesReport from "../UserSalesReport/UserSalesReport";

const UserProfile = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const switchReseveAgain = JSON.parse(localStorage.getItem("switchReseveAgain"));

  const user = jwtDecode(cookie.load("access_token"));

  const [load, setLoad] = useState(false);
  const [changeFlag, setChangeFlag] = useState(false);
  const [img, setImg] = useState();
  const [bioFlag, setBioFlag] = useState(false);
  const [emailFlag, setEmailFlag] = useState(false);
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [whatsappFlag, setWhatsappFlag] = useState(false);
  const [whatsappGroupFlag, setWhatsappGroupFlag] = useState(false);

  const [currentData, setCurrentData] = useState("");
  const [newData, setNewData] = useState("");
  const [confirmData, setConfirmData] = useState("");
  const navigate = useNavigate();
  //Formik

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      bio: "",
      avatar: "",
      recive_email_notification: false,
      whatsapp_notification: "",
      recive_whatsapp_notification: false,
      whatsapp_group_notification: "",
      recive_whatsapp_group_notification: false,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter First Name"),
      last_name: Yup.string().required("Please Enter Last Name"),
      email: Yup.string().email("Invalid email").required("Please Enter E-mail"),
      bio: Yup.string().required("Please Enter Bio"),
      old_password: Yup.string(),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("notification_settings[email_notification]", values.email);

      Object.keys(values).forEach((key) => {
        if (
          key === "recive_email_notification" ||
          key === "recive_whatsapp_notification" ||
          key === "recive_whatsapp_group_notification" ||
          key === "whatsapp_notification" ||
          key === "whatsapp_group_notification"
        ) {
          formData.append(`notification_settings[${key}]`, values[key]);
          return;
        }

        formData.append(key, values[key]);
      });
      if (!values.avatar) {
        formData.delete("avatar");
      }
      if (currentData !== "" && newData !== "" && confirmData !== "" && newData === confirmData) {
        formData.append("old_password", currentData);
        formData.append("new_password1", newData);
        formData.append("new_password2", confirmData);
      }
      dispatch(
        updateUserProfileAction(formData, user.user_id, (result) => {
          if (result.status === 200) {
            lightToast.success("Your profile was updated successfully");
            navigate(-1);
          }
        }),
      );
    },
  });

  useEffect(() => {
    dispatch(
      getUserProfileAction(user.user_id, (result) => {
        if (result.status === 200) {
          setLoad(true);
          setFieldValue("first_name", result.data.first_name);
          setFieldValue("last_name", result.data.last_name);
          setFieldValue("email", result.data.email);
          setFieldValue("bio", result.data.bio);
          setFieldValue("recive_email_notification", result.data.notification_settings.recive_email_notification);
          setFieldValue("whatsapp_notification", result.data.notification_settings.whatsapp_notification);
          setFieldValue("recive_whatsapp_notification", result.data.notification_settings.recive_whatsapp_notification);
          setFieldValue("whatsapp_group_notification", result.data.notification_settings.whatsapp_group_notification);
          setFieldValue(
            "recive_whatsapp_group_notification",
            result.data.notification_settings.recive_whatsapp_group_notification,
          );
          if (result.data.avatar) {
            setImg(result.data.avatar);
          }
        }
      }),
    );
  }, []);

  const [switch1, setSwitch1] = useState(switchReseveAgain);

  useEffect(() => {
    localStorage.setItem("switchReseveAgain", switch1);
  }, [switch1]);

  return (
    <div className="flex flex-col gap-8">
      <div className="w-full ">
        <div className="w-full  mt-5 flex items-center justify-center  ">
          {load ? (
            <div className=" w-[600px] flex flex-col gap-y-5 py-4 ">
              {passwordFlag ? (
                <PasswordPage setPasswordFlag={setPasswordFlag} />
              ) : (
                <form
                  className=" flex flex-col  justify-start items-center gap-y-3 border rounded-lg p-5"
                  onSubmit={handleSubmit}
                >
                  <div className=" flex justify-center items-center  gap-2">
                    <p className=" text-cyan-700 font-medium">Show History Search</p>
                    <Switch
                      checkedChildren={<BiSolidShow className=" text-xl text-center" />}
                      unCheckedChildren={<BiSolidHide className=" text-xl text-center" />}
                      defaultChecked={switch1}
                      onChange={setSwitch1}
                      className="bg-gray-500"
                    />
                  </div>
                  {/* <ToggleSwitch checked={switch1} label="Show Previous Search" onChange={setSwitch1} /> */}
                  {/*Pofile Picture */}
                  <div className="relative w-full  h-[45%]  flex items-center justify-center">
                    <img
                      src={
                        values.avatar
                          ? URL.createObjectURL(values.avatar)
                          : img
                            ? `${base_URL}${img}`
                            : `/images/app.jpg`
                      }
                      className="w-64 h-64 rounded-full border "
                    />
                    <input
                      type="file"
                      className="absolute top-52 right-[180px] p-1   z-50  opacity-0 w-12  h-12 rounded-full"
                      onChange={(e) => {
                        setFieldValue("avatar", e.target.files[0]);
                      }}
                    />
                    <button
                      type="button"
                      className="absolute p-3 bg-[#002230] hover:bg-[#002244]  flex items-center justify-center rounded-full  text-white bottom-0 right-[180px] hover:scale-105 active:scale-95 duration-300 transition-all  "
                    >
                      <LuUpload className="w-5 h-5" />
                    </button>
                  </div>
                  {/*User Name */}
                  <div className="w-full flex items-end  justify-center ">
                    <div className="w-full mr-4">
                      <div>
                        <label className="text-slate-900 font-semibold text-sm">First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          disabled={!changeFlag}
                          className={`w-full p-1  rounded-md bg-white text-lg  ${
                            changeFlag ? "text-start border" : values.first_name ? "" : " border "
                          }  text-[#002244] font-bold disabled:bg-gray-100`}
                        />
                      </div>
                      {touched.first_name && errors.first_name && changeFlag ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                          {errors.first_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <div>
                        <label className="text-slate-900 font-semibold text-sm">Last Name</label>
                        <input
                          type="text"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          disabled={!changeFlag}
                          className={`w-full p-1  rounded-md bg-white  text-lg  ${
                            changeFlag ? "text-start border" : values.first_name ? "text-start" : "text-start border "
                          }  text-[#002244] font-bold disabled:bg-gray-100`}
                        />
                      </div>
                      {touched.last_name && errors.last_name && changeFlag ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                          {errors.last_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-[2%]">
                      {changeFlag ? (
                        <button className="" type="button" onClick={() => setChangeFlag(false)}>
                          <MdOutlineDone className="w-5 h-5 text-green-900" />
                        </button>
                      ) : (
                        <button className="" type="button" onClick={() => setChangeFlag(true)}>
                          <RiEdit2Fill className="w-5 h-5 text-yellow-600" />
                        </button>
                      )}
                    </div>
                  </div>
                  {/*BIO*/}
                  <div className="w-full">
                    <label className="text-slate-900 font-semibold text-sm">BIO</label>
                    <div className="w-full flex items-center gap-x-1">
                      <textarea
                        className={`w-full h-16 px-1 py-2 ${
                          bioFlag && "border"
                        }  rounded-md text-[#002244] font-semibold  bg-white disabled:bg-gray-100 `}
                        name="bio"
                        disabled={!bioFlag}
                        value={values.bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className="w-[2%]">
                        {bioFlag ? (
                          <button className="" type="button" onClick={() => setBioFlag(false)}>
                            <MdOutlineDone className="w-5 h-5 text-green-900" />
                          </button>
                        ) : (
                          <button className="" type="button" onClick={() => setBioFlag(true)}>
                            <RiEdit2Fill className="w-5 h-5 text-yellow-600" />
                          </button>
                        )}
                      </div>
                    </div>

                    {touched.bio && errors.bio ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                        {errors.bio}
                      </div>
                    ) : null}
                  </div>
                  {/*Email*/}
                  <div className="w-full">
                    <div className="w-full flex items-center gap-x-1">
                      <MdOutlineMailOutline className="w-4 h-4 text-[#002244]" />
                      <label className="text-slate-900 font-semibold text-sm">Email</label>
                    </div>
                    <div className="w-full flex items-center gap-x-1">
                      <div className="flex flex-col gap-1 grow">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={values.email}
                          disabled={!emailFlag}
                          className={`w-full p-1 ${
                            emailFlag && "border"
                          }  rounded-md text-start text-[#002244] font-bold bg-white disabled:bg-gray-100 disabled:cursor-not-allowed`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className={"flex items-center gap-1"}>
                          <input
                            type="checkbox"
                            name="recive_email_notification"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!emailFlag}
                            defaultChecked={values.recive_email_notification}
                            value={values.recive_email_notification}
                            className={"disabled:cursor-not-allowed"}
                          />
                          <label className="text-slate-900 font-semibold text-sm disabled:cursor-not-allowed">
                            Receive Email Notification
                          </label>
                        </div>
                      </div>
                      <div className="w-[2%]">
                        {emailFlag ? (
                          <button className="" type="button" onClick={() => setEmailFlag(false)}>
                            <MdOutlineDone className="w-5 h-5 text-green-900" />
                          </button>
                        ) : (
                          <button className="" type="button" onClick={() => setEmailFlag(true)}>
                            <RiEdit2Fill className="w-5 h-5 text-yellow-600" />
                          </button>
                        )}
                      </div>
                    </div>

                    {touched.email && errors.email ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                        {errors.email}
                      </div>
                    ) : null}
                  </div>
                  {/*Whatsapp*/}
                  <div className="w-full">
                    <div className="w-full flex items-center gap-x-1">
                      <FaWhatsapp className="w-4 h-4 text-[#002244]" />
                      <label className="text-slate-900 font-semibold text-sm">Whatsapp</label>
                    </div>
                    <div className="w-full flex items-center gap-x-1">
                      <div className="flex flex-col gap-1 grow">
                        <PhoneInput
                          className="text-start text-[#002244] font-bold bg-white disabled:cursor-not-allowed"
                          placeholder="Whatsapp Number"
                          international={true}
                          country={"EG"}
                          withCountryCallingCode={true}
                          value={values.whatsapp_notification}
                          onChange={(value) => {
                            if (value === undefined) {
                              void setFieldValue("whatsapp_notification", "");
                              return;
                            }
                            setFieldValue("whatsapp_notification", value);
                          }}
                          onBlur={handleBlur}
                          disabled={!whatsappFlag}
                        />

                        <div className={"flex items-center gap-1"}>
                          <input
                            type="checkbox"
                            name="recive_whatsapp_notification"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!whatsappFlag}
                            defaultChecked={values.recive_whatsapp_notification}
                            value={values.recive_whatsapp_notification}
                            className={"disabled:cursor-not-allowed"}
                          />
                          <label className="text-slate-900 font-semibold text-sm disabled:cursor-not-allowed">
                            Receive Whatsapp Notification
                          </label>
                        </div>
                      </div>
                      <div className="w-[2%]">
                        {whatsappFlag ? (
                          <button className="" type="button" onClick={() => setWhatsappFlag(false)}>
                            <MdOutlineDone className="w-5 h-5 text-green-900" />
                          </button>
                        ) : (
                          <button className="" type="button" onClick={() => setWhatsappFlag(true)}>
                            <RiEdit2Fill className="w-5 h-5 text-yellow-600" />
                          </button>
                        )}
                      </div>
                    </div>

                    {touched.whatsapp_notification && errors.whatsapp_notification ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                        {errors.whatsapp_notification}
                      </div>
                    ) : null}
                  </div>
                  {/*Whatsapp Group*/}
                  <div className="w-full">
                    <div className="w-full flex items-center gap-x-1">
                      <FaWhatsapp className="w-4 h-4 text-[#002244]" />
                      <label className="text-slate-900 font-semibold text-sm">Whatsapp Group</label>
                    </div>
                    <div className="w-full flex items-center gap-x-1">
                      <div className="flex flex-col gap-1 grow">
                        <input
                          type="text"
                          name="whatsapp_group_notification"
                          placeholder="Whatsapp Group ID"
                          value={values.whatsapp_group_notification}
                          disabled={!whatsappGroupFlag}
                          className={`w-full p-1 ${
                            whatsappGroupFlag && "border"
                          }  rounded-md text-start text-[#002244] font-bold bg-white disabled:cursor-not-allowed disabled:bg-gray-100`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className={"flex items-center gap-1"}>
                          <input
                            type="checkbox"
                            name="recive_whatsapp_group_notification"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!whatsappGroupFlag}
                            defaultChecked={values.recive_whatsapp_group_notification}
                            value={values.recive_whatsapp_group_notification}
                            className={"disabled:cursor-not-allowed"}
                          />
                          <label className="text-slate-900 font-semibold text-sm disabled:cursor-not-allowed">
                            Receive Whatsapp Group Notification
                          </label>
                        </div>
                      </div>
                      <div className="w-[2%]">
                        {whatsappGroupFlag ? (
                          <button className="" type="button" onClick={() => setWhatsappGroupFlag(false)}>
                            <MdOutlineDone className="w-5 h-5 text-green-900" />
                          </button>
                        ) : (
                          <button className="" type="button" onClick={() => setWhatsappGroupFlag(true)}>
                            <RiEdit2Fill className="w-5 h-5 text-yellow-600" />
                          </button>
                        )}
                      </div>
                    </div>

                    {touched.whatsapp_group_notification && errors.whatsapp_group_notification ? (
                      <div className="w-full flex items-center justify-start text-red-800 text-sm capitalize font-semibold">
                        {errors.whatsapp_group_notification}
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="Submit"
                    disabled={isSubmitting}
                    className="flex items-center gap-x-1 px-3 py-1 rounded-lg bg-[#002230] hover:bg-[#002244] mt-5 text-white  active:scale-95 duration-300 transition-all"
                  >
                    Update{" "}
                    {isSubmitting ? (
                      <>
                        <TopBarProgress />
                        <ButtonLoading />
                      </>
                    ) : (
                      <MdPublishedWithChanges className="w-5 h-5" />
                    )}
                  </button>
                </form>
              )}
              <div className="w-full flex justify-end ">
                {passwordFlag ? (
                  <button
                    type="button"
                    onClick={() => setPasswordFlag(false)}
                    className="flex items-center gap-x-1 px-1.5 py-0.5 text-sm bg-[#002230] text-white rounded-md hover:bg-[#002244] active:scale-95 duration-300 transition-all"
                  >
                    <IoIosArrowBack className="w-5 h-5" /> BACK
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setPasswordFlag(true)}
                    className="flex items-center gap-x-1 p-0.5 text-sm bg-[#002230] text-white rounded-md hover:bg-[#002244] active:scale-95 duration-300 transition-all"
                  >
                    <IoKey className="w-5 h-5" /> Change Password
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full h-screen flex items-center justify-center">
              <Loading /> <TopBarProgress />
            </div>
          )}
          {/*Password*/}
        </div>
      </div>
      <UserSalesReport />
    </div>
  );
};

export default UserProfile;
