import {
  GET_ALL_MARKUPS,
  GET_MARKUP_PRICE_TYPES,
  GET_USER_GROUPS,
  GET_SINGLE_MARKUP,
  CLEAR_SINGLE_MARKUP,
} from "../types/types";

export function allMarkupsReducer(state = [], action) {
  switch (action.type) {
    case GET_ALL_MARKUPS:
      return action.payload;
    default:
      return state;
  }
}

export function markupPriceTypesReducer(state = [], action) {
  switch (action.type) {
    case GET_MARKUP_PRICE_TYPES:
      return action.payload;
    default:
      return state;
  }
}

export function userGroupsReducer(state = [], action) {
  switch (action.type) {
    case GET_USER_GROUPS:
      return action.payload;
    default:
      return state;
  }
}

export function singleMarkupReducer(state = null, action) {
  switch (action.type) {
    case GET_SINGLE_MARKUP:
      return action.payload;
    case CLEAR_SINGLE_MARKUP:
      return null;
    default:
      return state;
  }
}
