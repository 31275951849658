import React, { useState } from "react";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import { DateRangePicker } from "react-dates";
import { Button } from "flowbite-react";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { FieldErrorText } from "../../../../../customComponents/FormComponents";

const ContractAssistanceValiditySelector = () => {
  const { values, setFieldValue } = useFormikContext();

  const [focusedInputs, setFocusedInputs] = useState(Array(values.validities.length).fill(null));

  return (
    <>
      <h2 className={"text-gray-800 font-semibold"}>Room Validities</h2>
      <FieldArray name={"validities"}>
        {({ push, remove }) => (
          <div className="space-y-4 !mt-1">
            {values.validities.map((validity, index) => {
              return (
                <div key={index} className="w-full border rounded flex flex-col items-center  gap-x-5 p-5">
                  <div className="w-full flex items-center gap-4 ">
                    <h2 className="text-gray-800 font-semibold">Room Validity</h2>
                    <div className="flex items-center justify-start gap-x-3">
                      <DateRangePicker
                        required
                        startDate={values.validities[index].startDate}
                        endDate={values.validities[index].endDate}
                        startDateId={`validities[${index}].startDate`}
                        endDateId={`validities[${index}].endDate`}
                        onDatesChange={({ startDate, endDate }) => {
                          // Format the selected start and end dates
                          const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
                          const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;

                          // Check if the new date range overlaps with any previous ranges
                          const hasOverlap = values.validities.some((validity, idx) => {
                            if (idx === index) return false; // Skip the current index

                            const previousStart = moment(validity.startDate);
                            const previousEnd = moment(validity.endDate);

                            // Check if the new start or end date falls within the existing date range
                            return (
                              (formattedStartDate &&
                                moment(formattedStartDate).isBetween(previousStart, previousEnd, null, "[]")) ||
                              (formattedEndDate &&
                                moment(formattedEndDate).isBetween(previousStart, previousEnd, null, "[]")) ||
                              (previousStart.isBetween(
                                moment(formattedStartDate),
                                moment(formattedEndDate),
                                null,
                                "[]",
                              ) &&
                                previousEnd.isBetween(moment(formattedStartDate), moment(formattedEndDate), null, "[]"))
                            );
                          });

                          if (hasOverlap) {
                            toast.error("The selected date range overlaps with an existing date range");
                            return;
                          }

                          // Set the values if no conflicts were found
                          setFieldValue(`validities[${index}].startDate`, startDate);
                          setFieldValue(`validities[${index}].endDate`, endDate);
                        }}
                        focusedInput={focusedInputs[index]}
                        onFocusChange={(focusedInput) => {
                          const newFocusedInputs = [...focusedInputs];
                          newFocusedInputs[index] = focusedInput;
                          setFocusedInputs(newFocusedInputs);
                        }}
                        minimumNights={0} // Allow same-day selection
                        displayFormat={"DD/MM/YYYY"}
                        className="w-full"
                        small
                        daySize={30}
                      />
                    </div>

                    {values.validities.length > 1 && (
                      <Button
                        type={"button"}
                        className="ml-auto"
                        color={"failure"}
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        Remove Validity
                      </Button>
                    )}
                  </div>
                  <ErrorMessage
                    name={`validities[${index}].startDate`}
                    render={(msg) => <FieldErrorText message={msg} />}
                  />
                  <ErrorMessage
                    name={`validities[${index}].endDate`}
                    render={(msg) => <FieldErrorText message={msg} />}
                  />
                </div>
              );
            })}
            <Button
              className="mx-auto mt-4"
              color="success"
              onClick={() => {
                push({
                  startDate: "",
                  endDate: "",
                });
              }}
            >
              Add Validity
            </Button>
          </div>
        )}
      </FieldArray>
      <ErrorMessage
        name="validities"
        render={(msg) => {
          if (typeof msg === "string") {
            return <FieldErrorText message={msg} />;
          }
        }}
      />
    </>
  );
};

export default ContractAssistanceValiditySelector;
