import React, { useEffect, useState } from "react";
import "./../AddHotelForm/DashboardForm.scss";
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import StaticFromInput from "../../molecules/StaticFormInput/StaticFromInput";
import { addHotelBankDetails } from "../../../redux/actions/hotelBankDetails";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import TopBarProgress from "react-topbar-progress-indicator";
import HashLoader from "react-spinners/HashLoader";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import NoPermission from "../NoPermission/NoPermission";

export default function AddHotelBankDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const hotelNames = useSelector((state) => state.hotelNames);
  const [numOfAccounts, setNumOfAccounts] = useState([1]);
  useEffect(() => {
    dispatch(requestHotelNames());
  }, []);

  function incInputs() {
    let copy = numOfAccounts.slice(0);
    copy.push(1);
    setNumOfAccounts(copy);
  }

  function decInputs() {
    if (numOfAccounts.length > 1) {
      let copy = numOfAccounts.slice(0);
      copy.pop();
      setNumOfAccounts(copy);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true);
    const data = new FormData(e.target);
    const finalData = [];
    if (e.target.bank_name.length) {
      Array.from(e.target.bank_name).forEach((item, index) => {
        const formData = new FormData();
        formData.append("bank_name", e.target.bank_name[index].value);
        formData.append("account_number", e.target.account_number[index].value);
        formData.append("iban_number", e.target.iban_number[index].value);
        formData.append("swift_code", e.target.swift_code[index].value);
        formData.append("notes", e.target.notes[index].value);
        formData.append("currency", e.target.currency[index].value);
        formData.append("document", data.getAll("file")[index]);
        formData.append("hotel", e.target.hotel.value);
        finalData.push(formData);
      });
    } else {
      const formData = new FormData();
      formData.append("bank_name", e.target.bank_name.value);
      formData.append("account_number", e.target.account_number.value);
      formData.append("iban_number", e.target.iban_number.value);
      formData.append("swift_code", e.target.swift_code.value);
      formData.append("notes", e.target.notes.value);
      formData.append("currency", e.target.currency.value);
      formData.append("document", data.getAll("file")[0]);
      formData.append("hotel", e.target.hotel.value);
      finalData.push(formData);
    }

    dispatch(addHotelBankDetails(finalData, () => navigate(`/dashboard/hotel-info-setup/hotel-bank-details-list`)));
  }

  /////permission/////////
  const permissionData = {
    permission_codes: ["hotels_management.add_hotelbankaccountnumber"],
  };
  const token = cookie.load("access_token");
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        permission.map((item) => {
          if (item.name === "hotels_management.add_hotelbankaccountnumber" && item.value === true) {
            return (
              <div>
                <div className="w-full flex justify-center">
                  <h2 className="header-h2 max-w-[max-content] ">Add Hotel Bank Details</h2>
                </div>
                <form method="post" className=" form-wrap" onSubmit={handleSubmit}>
                  <div className="flex-row">
                    <StaticFormSelect
                      name="hotel"
                      text="Choose Hotel"
                      options={hotelNames}
                      placeholder="Choose Hotel"
                    />
                  </div>
                  <div className="dynamicInputs">
                    {numOfAccounts.map((elem, index) => {
                      return (
                        <div className=" gap-2 flex flex-col" key={index}>
                          <div className="flex flex-col">
                            <StaticFromInput name="bank_name" text="Bank Name" />
                            <StaticFromInput name="account_number" text="Account Number" type="number" />
                            <StaticFromInput name="iban_number" text="IBAN Number" type="number" required={false} />
                            <div className="w-full ">
                              <label htmlFor="notes font-medium">Notes</label> <br />
                              <textarea
                                name="notes"
                                id="notes"
                                className="w-full p-2 border rounded-md"
                                cols={10}
                                rows={10}
                                placeholder="Notes..."
                              ></textarea>
                            </div>
                          </div>
                          <div>
                            <StaticFromInput name="swift_code" text="Swift Code" required={false} />
                            <StaticFromInput
                              name="currency"
                              text="Currency"
                              placeholder="Choose Currency"
                              required={false}
                            />
                            <div className="flex-column">
                              <label htmlFor="file">Upload File</label>
                              <input type="file" name="file" className="multiple" />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div
                      className=" flex justify-end my-4 gap-2 "
                      style={{ display: "flex", justifyContent: "flex-end", paddingRight: "40px" }}
                    >
                      <AiOutlinePlus
                        className="hover:text-green-50 cursor-pointer p-[1px]  bg-green-500  rounded-full"
                        onClick={incInputs}
                      />
                      <AiOutlineMinus
                        className="hover:text-red-50 cursor-pointer p-[1px]  bg-red-500 rounded-full"
                        onClick={decInputs}
                      />
                    </div>
                  </div>
                  {/* <MultiDynamicInputs
              heading="Bank Details"
              inputsList={[
                <div className="flex-column-20">
                  <StaticFromInput name="bank_name" text="Bank Name" />
                  <StaticFromInput name="account_number" text="Account Number" type="number" />
                  <StaticFromInput name="iban_number" text="IBAN Number" type="number" required={false} />
                  <div>
                    <label htmlFor="notes">Notes</label> <br />
                    <textarea name="notes" id="notes" cols="10" rows="5"></textarea>
                  </div>
                </div>,
                <div className="flex-column-20">
                  <StaticFromInput name="swift_code" text="Swift Code" required={false} />
                  <StaticFromInput name="currency" text="Currency" placeholder="Choose Currency" required={false} />
                  <div className="flex-column">
                    <label htmlFor="file">Upload File</label>
                    <input type="file" name="file" className="multiple" />
                  </div>
                </div>,
              ]}
              inputsNum={1}
            /> */}
                  <div className="flex justify-center">
                    <input
                      type="Submit"
                      disabled={isDisabled}
                      className="bg-[#002244] text-white px-4 py-2 rounded-md cursor-pointer hover:scale-[1.01] hover:shadow-sm hover:bg-[#002244e5]"
                    />
                    {isDisabled ? (
                      <>
                        <TopBarProgress />
                        <div className="h-[100px] rounded-lg bg-gray-900 bg-opacity-60 fixed top-[40vh] left-[45vw] flex flex-column justify-center items-center p-2 px-3">
                          <HashLoader size={30} color="white" />
                          <div className="text-white font-bold my-2">Submitting...</div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </form>
              </div>
            );
          } else {
            return <NoPermission />;
          }
        })
      ) : (
        <>
          <TopBarProgress />
          <Loading />
        </>
      )}
    </div>
  );
}
