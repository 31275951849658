import api_token from "../../api/UserApi";
import {
  GET_SELECTED_PAYMENT_TRANSACTION,
  GET_SELECTED_PAYMENT_TRANSACTION_FLAG,
  UPDATE_PAYMENT_TRANSACTION,
  UPDATE_PAYMENT_TRANSACTION_FLAG,
  DELETE_PAYMENT_TRANSACTION_FLAG,
} from "../types/types";

export const getSelectedPaymentTransAction = (token, id, callback, signal) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_SELECTED_PAYMENT_TRANSACTION_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`booking/api/v1/payments-transaction/${id}/`, { signal });
      dispatch({
        type: GET_SELECTED_PAYMENT_TRANSACTION,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_SELECTED_PAYMENT_TRANSACTION_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
export const updatePaymentTransaction = (token, data, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PAYMENT_TRANSACTION_FLAG,
        payload: true,
      });
      const response = await api_token(token).patch(`booking/api/v1/payments-transaction/${id}/`, data);
      if (response.status === 200) {
        dispatch({
          type: UPDATE_PAYMENT_TRANSACTION_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
export const deletePaymentTransactions = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_PAYMENT_TRANSACTION_FLAG,
        payload: true,
      });
      const response = await api_token(token).delete(`booking/api/v1/payments-transaction/${id}/`);
      if (response.status === 204) {
        dispatch({
          type: DELETE_PAYMENT_TRANSACTION_FLAG,
          payload: false,
        });
        callback(response);
      }
    } catch (error) {}
  };
};
