import { GET_DEPARTMENT_CHOICES, GET_SINGLE_HOTEL_CONTACT, SINGLE_HOTEL_CONTACT_LOADING } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getDepartmentChoicesSuccess(data) {
  return {
    type: GET_DEPARTMENT_CHOICES,
    payload: data,
  };
}

export function getDepartmentChoices() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/en/hotels/api/depertment-contact/`);
      dispatch(getDepartmentChoicesSuccess(response.data));
    } catch (error) {}
  };
}

export function sendHotelContactData(data, callback) {
  return async function () {
    try {
      const response = await api_token(token).post(`/en/hotels/api/hotel-contact`, data);
      if (response && callback) {
        callback();
      }
    } catch (error) {}
  };
}

export const getContactListAction = (page, listPageSize, sorting, hotel, callback) => {
  return async function () {
    try {
      // Build the URL dynamically
      let url = `/en/hotels/api/hotel-contact?page_size=${listPageSize}&page=${page}&ordering=${sorting}`;

      // Append 'hotel' to the URL only if it is defined
      if (hotel) {
        url += `&hotel=${hotel}`;
      }

      const response = await api_token(token).get(url);
      callback(response);
    } catch (error) {}
  };
};

export const deleteSelectedContact = (id, callback) => {
  return async function () {
    try {
      const response = await api_token(token).delete(`/en/hotels/api/hotel-contact/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const getContactbasedHotel = (hotelId, callback) => {
  return async function () {
    try {
      const response = await api_token(token).get(`/en/hotels/api/hotel-contact/hotel?=${hotelId}`);
      callback(response);
    } catch (error) {}
  };
};

export const getSalesContact = (hotelId, callback) => {
  return async function () {
    try {
      const response = await api_token(token).get(`/en/hotels/api/hotel-contact?hotel=${hotelId}&depertment=sales`);
      callback(response);
    } catch (error) {}
  };
};

export function getSingleHotelContact(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: SINGLE_HOTEL_CONTACT_LOADING });
      const response = await api_token(token).get(`/en/hotels/api/hotel-contact/${id}/`);
      dispatch({ type: GET_SINGLE_HOTEL_CONTACT, payload: response.data });
    } catch (error) {}
  };
}

export function editHotelContactData(id, data, callback) {
  return async function () {
    try {
      const response = await api_token(token).patch(`/en/hotels/api/hotel-contact/${id}/`, data);
      if (response && callback) {
        callback();
      }
    } catch (error) {}
  };
}

export const getDepartmentContact = (hotelId, department, callback) => {
  return async function () {
    try {
      const response = await api_token(token).get(
        `/en/hotels/api/hotel-contact?hotel=${hotelId}&depertment=${department}`,
      );
      callback(response);
    } catch (error) {}
  };
};
