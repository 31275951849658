import api_token from "../../api/UserApi";
import { GET_SELEC_OPERATION } from "../types/types";

export const getBookedRoomInfo = (token, id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`booking/api/v1/booked-room/${id}/`);
      dispatch({
        type: GET_SELEC_OPERATION,
        payload: [...getState().paymentTransactionReducer.getselecoperation, response.data],
      });
      callback(response);
    } catch (error) {}
  };
};
