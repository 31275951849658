import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { decreaseData, deleteDataOfBookingOrder, increaseData } from "../../../redux/actions/bookingOrderAction";
import { useTranslation } from "react-i18next";
import { IoIosPerson } from "react-icons/io";
import CancelationModal from "../cancelation_policyModel/CancelationModal";
import CPTest from "../cancelation_policyModel/CPTest";

import moment from "moment";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { GiNightSleep } from "react-icons/gi";
import { clearDisplayMultiReservationData } from "../../../redux/actions/MultiReservationAction";
import { CgBoy } from "react-icons/cg";

const CardItem = ({ cardData }) => {
  const {
    hotel_En,
    hotel_AR,
    selectedroom,
    mealType,
    price,
    commissions,
    room_id,
    hotel,
    roomtype_id,
    contractOfferType,
    cancellationPolicy,
    quantity,
    roomImage,
    nationality,
    searchPrice,
    startDate,
    hotelId,
    contractOfferID,
    item,
    cpHotel,
    formCheckOut_whats_app,
    formCheckIn_whats_app,
    uniqueId,
    number_of_persons,
    nights_whats_app,
    currency_type,
    children_num,
  } = cardData;
  const dispatch = useDispatch();
  const currencySearch = cookie.load("currency");
  const { cardTotalAmount } = useSelector((state) => state.cardReducer);

  const [t, i18n] = useTranslation();

  const handleDelete = () => {
    dispatch(deleteDataOfBookingOrder(room_id, mealType, price, selectedroom, roomtype_id, uniqueId));
  };

  const handleIncrease = () => {
    dispatch(increaseData(room_id, mealType, price, roomtype_id, uniqueId));
    dispatch(clearDisplayMultiReservationData());
  };
  const handleDecrease = () => {
    dispatch(decreaseData(room_id, mealType, price, roomtype_id, uniqueId));
    dispatch(clearDisplayMultiReservationData());
  };

  const [showNote, setShowNote] = useState(false);

  const toggleNote = () => {
    setShowNote(!showNote);
  };
  const localeCode = t("localeCode");
  return (
    <div className="w-full  border-gray-300  rounded-xl boxShadow ">
      <div className=" flex  justify-between   w-full  ">
        <div className="grid  md:flex items-center  md:gap-5 w-full  ">
          <div
            className={`flex flex-col sm:flex-row  gap-x-5   w-full mx-auto rounded p-3  transition-all  duration-75 ease-in-out  items-center   `}
          >
            <img src={roomImage} className="w-full sm:w-36 md:h-[115px] h-[115px] object-fill rounded-md  lg:w-28" />
            <div className=" flex flex-col justify-center gap-[5px] items-center text-center blur-theme-effect text-sm    text-black  rounded  md:w-40">
              <div className=" flex gap-2 text-[#002244]">
                <p className="font-semibold text-[#002244]">{t("lan") === "en" ? hotel_En : hotel_AR}</p>
              </div>
              <div className=" flex gap-2 text-[#002244]">
                <div className=" flex gap-1 text-center justify-center">
                  {number_of_persons}
                  <IoIosPerson className=" text-lg text-[#002244]" />
                </div>

                <div className=" flex gap-1 text-center justify-center text-cyan-700 ">
                  {children_num}
                  <CgBoy className=" text-lg " />
                </div>

                <div className=" flex gap-1 text-center justify-center">
                  {nights_whats_app}
                  <GiNightSleep className="w-5 h-5 text-yellow-600 " />
                </div>
              </div>
              <p
                className="text-green-800 font-medium text-[13px] flex justify-center items-center gap-1 "
                dir={t("dir")}
              >
                {moment(formCheckIn_whats_app, "YYYY-MM-DD").format("DD-MM-YYYY")}

                {t("lan") === "ar" ? (
                  <FaLongArrowAltLeft className="font-extrabold" />
                ) : (
                  <FaLongArrowAltRight className="font-extrabold" />
                )}

                {moment(formCheckOut_whats_app, "YYYY-MM-DD").format("DD-MM-YYYY")}
              </p>
              <p className="text-black ">{selectedroom}</p>
              <div className="flex justify-around items-center border-gray-100 border border-opacity-30  rounded-xl w-full ">
                <button
                  type="button"
                  className="bg-[#002244] rounded w-6 h-6 lg:w-5 lg:h-5 flex items-center justify-center active:scale-90"
                >
                  <MinusIcon onClick={handleDecrease} className="w-5 h-5 lg:w-4 lg:h-4 text-white stroke-[2]" />
                </button>
                <p className="text-black">{quantity}</p>
                <button
                  type="button"
                  className="bg-[#002244] rounded w-6 h-6 lg:w-5 lg:h-5 flex items-center justify-center active:scale-90"
                >
                  <PlusIcon onClick={handleIncrease} className="w-5 h-5 lg:w-4 lg:h-4 text-white stroke-[2]" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center gap-x-4 w-full p-3 ">
            <div className="flex flex-col  md:justify-center justify-start items-center gap-y-1 sm:gap-x-12 lg:gap-x-3  leading-none  w-full   ">
              <h1 className="bg-[#002244] py-1 font-medium text-xs text-white  rounded-xl w-full  text-center ">
                {t(mealType)}
              </h1>
              <p className="text-xs xl:text-sm text-black ">
                {contractOfferType}
                {commissions}
              </p>
              {/* <CancelationModal
                startDate={startDate}
                hotelId={hotelId}
                contractOfferID={contractOfferID}
                currencySearch={currencySearch}
                item={item}
                price={price}
                hotel={cpHotel}
              /> */}
              <CPTest
                startDate={startDate}
                hotelId={hotelId}
                contractOfferID={contractOfferID}
                currencySearch={currencySearch}
                item={item}
                price={price}
                hotel={cpHotel}
              />{" "}
              {/* <button
                className="flex items-center justify-center   relative text-xs  text-green-700 font-bold rounded-lg w-full text-center "
                onClick={toggleNote}
                dir="ltr"
              >
                <IoMdDoneAll className="w-4 h-4 text-green-700  " />
                {t("cancellation")}
                <div className="absolute w-0 h-0 border-transparent  border-10 border-solid border-b-solid border-black  border top-0 left-1/2 transform -translate-x-1/2">
                  {showNote && (
                    <>
                      {" "}
                      <BiSolidUpArrow className="w-5 h-6 absolute left-0 text-black/60  top-[18px] " />{" "}
                      <p className="absolute border border-black bg-black/80 w-48 p-2 top-8 rounded-lg text-white z-50 overflow-y-auto h-24 overflow-x-auto px-2 ">
                        {cancellationPolicy}
                      </p>{" "}
                    </>
                  )}
                </div>
              </button> */}
            </div>
            <div className="flex  justify-center items-center gap-x-3   leading-none xl:w-32  w-full   ">
              <p className=" rounded text-black font-medium lg:text-2xl text-lg flex items-end justify-center gap-x-1">
                {new Intl.NumberFormat(localeCode).format(price * quantity)}{" "}
                <span className="text-yellow-600 text-sm">{t(currency_type)}</span>{" "}
              </p>
              <button
                type="button"
                className="bg-red-700 rounded sm:p-1 lg:p-0.5 grid items-center p-0.5
                                                justify-items-center cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <TrashIcon onClick={handleDelete} className="w-5 h-5 text-white  " />
              </button>
            </div>
          </div>
        </div>
        {/* <div className="grid items-center gap-5">
          <div className="grid items-center justify-center">
            <h1 className="text-lg lg:text-base text-slate-900 font-medium">${price * cartQuantity}</h1>
          </div>
          <div className="grid items-center justify-center">
            <button type="button" onClick={()=>deleteItem()} className="bg-theme-cart rounded p-1 lg:p-0.5 grid items-center 
                                                justify-items-center cursor-pointer" >
              <TrashIcon className="w-5 h-5 text-white" />
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CardItem;
