import React, { useState } from "react";
import CustomCity from "./CustomCity";
import CoustomArea from "./CoustomArea";
import CoustomHotel from "./CoustomHotel";
import { useEffect } from "react";

const CityAreaHotel = ({ render, localStorageName = {}, reset }) => {
  /*=== formValue info ===*/
  const initialState = {
    city: "",
    area: "",
    Allarea: "",
    hotel: "",
  };

  const [formValue, setFormValue] = useState(initialState);
  const { city, area, Allarea, hotel } = formValue;

  const onChange = (name, e) => {
    let updatedFormValue = { ...formValue, [name]: e };

    if (name === "area") {
      updatedFormValue["Allarea"] = ""; // Clear Allarea if area is changed
    } else if (name === "Allarea") {
      updatedFormValue["area"] = ""; // Clear area if Allarea is changed
    }

    Object.keys(localStorageName).length !== 0 &&
      localStorage.setItem(localStorageName, JSON.stringify(updatedFormValue));

    if (name === "city") {
      setFormValue({
        ...formValue,
        city: e,
        area: "", // Clear the area when city changes
        Allarea: "", // Optionally, clear Allarea as well
        hotel: "",
      });
    } else if (name === "Allarea") {
      setFormValue({
        ...formValue,
        Allarea: e,
        area: "", // Clear the area when city changes
      });
    } else if (name === "area") {
      setFormValue({
        ...formValue,
        Allarea: "",
        area: e, // Clear the area when city changes
      });
    } else if (name === "hotel") {
      setFormValue({
        ...formValue,
        [name]: e,
      });
      render(e);
    }
  };

  const Getlocal = JSON.parse(localStorage.getItem(`${localStorageName}`));

  useEffect(() => {
    if (reset) {
      setFormValue(initialState);
    }
  }, [reset]);

  return (
    <div className=" w-full md:flex-row flex-col flex gap-2 ">
      <CustomCity formValue={formValue} onChange={onChange} Getlocal={Getlocal} />
      <CoustomArea formValue={formValue} onChange={onChange} city={city?.value} Getlocal={Getlocal} />
      <CoustomHotel
        formValue={formValue}
        onChange={onChange}
        city={city?.value}
        area={area.value}
        Allarea={Allarea}
        Getlocal={Getlocal}
      />
    </div>
  );
};

export default CityAreaHotel;
