import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

export function getContractTest(data, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/testing-tool/`, { params: data });
      callback(response?.data);
    } catch (error) {
      callback(false);
    }
  };
}
