import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { FaDatabase } from "react-icons/fa6";
import React from "react";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import { IoMdPerson } from "react-icons/io";
import { MdOutlinePayment } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BsCurrencyExchange } from "react-icons/bs";
import usePermissions from "../../../../customHooks/usePermissions";
import NoPermission from "../../NoPermission/NoPermission";
import ListFilters from "../../../../utils/ListFilters";

export default function AgentsAccounting() {
  const {
    data: agentsAccounting,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["agents-accounting"],
    queryFn: () => fetchDataQuery("/accounting/api/v1/credit-agent-info/"),
  });

  const [permissions, permissionFlag, permissionsError] = usePermissions(["accounting.view_agentcreditaccounting"]);

  if (isLoading || !agentsAccounting || !permissionFlag) return <Loading />;

  if (error) return <Errorfetch error={error.message} />;

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  const isViewingAgentsAccountingAllowed =
    permissions?.find((permission) => permission.name === "accounting.view_agentcreditaccounting")?.value === true;

  const rows =
    agentsAccounting &&
    agentsAccounting.results?.map((agent) => {
      return {
        id: agent?.id,
        agentID: agent?.agent_id,
        agentName: agent?.first_name + " " + agent?.last_name,
        methodOfPayment: agent?.method_of_payment,
        creditLimit: agent?.credit_limit,
        creditUsed: agent?.credit_used,
        outstandingAmt: agent?.outstanding_amount,
        creditAvailable: agent?.credit_available,
        currency: agent?.currency,
      };
    });

  const columns = [
    {
      key: "agentID",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <FaDatabase className="w-5 h-5  text-yellow-500" />
          Agent ID
        </div>
      ),
      dataIndex: "agentID",
    },
    {
      key: "agentName",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <IoMdPerson className="w-5 h-5  text-yellow-500" />
          Agent Name
        </div>
      ),
      dataIndex: "agentName",
    },
    {
      key: "methodOfPayment",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <MdOutlinePayment className="w-5 h-5  text-yellow-500" />
          Method of Payment
        </div>
      ),
      dataIndex: "methodOfPayment",
    },
    {
      key: "creditLimit",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <RiMoneyDollarCircleFill className="w-5 h-5  text-yellow-500" />
          Credit Limit
        </div>
      ),
      dataIndex: "creditLimit",
    },

    {
      key: "creditAvailable",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <RiMoneyDollarCircleFill className="w-5 h-5  text-yellow-500" />
          Credit Available
        </div>
      ),
      renderCell: (params) => {
        return params.row.creditAvailable.toFixed(2);
      },
      dataIndex: "creditAvailable",
    },
    {
      key: "outstandingAmt",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <RiMoneyDollarCircleFill className="w-5 h-5  text-yellow-500" />
          Outstanding Amount
        </div>
      ),
      dataIndex: "outstandingAmt",
    },
    {
      key: "currency",
      title: (
        <div className="flex items-center gap-x-1 w-full">
          <BsCurrencyExchange className="w-5 h-5  text-yellow-500" />
          Currency
        </div>
      ),
      dataIndex: "currency",
    },
    // {
    //   key: "operations",
    //   title: () => <ColumnHeader label={"Operations"} Icon={FaGear} />,
    //   renderCell: (params) => {
    //     return (
    //       <div className="flex gap-.5">
    //         <Link to={`./edit-agent-accounting/${params.row.agentID}`}>
    //           <FiEdit className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800" />
    //         </Link>
    //       </div>
    //     );
    //   },
    //   flex: 1,
    //   minWidth: 130,
    // },
  ];

  return isViewingAgentsAccountingAllowed ? (
    <div className=" w-full formPage-wrap">
      {/* <h2 className="text-center py-2 my-2 rounded-lg bg-[#002244] text-white font-bold text-uppercase">
        {"Agents Accounting".toUpperCase()}
      </h2> */}
      <div className=" w-full overflow-auto">
        <ListFilters columns={columns} rows={rows} title={"Agents Accounting"} />

        {/* <DataGrid
          // className="data-grid"
          columns={columns}
          autoHeight
          rows={rows}
          initialState={{
            ...rows?.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
        /> */}
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}
