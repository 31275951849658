import api_token from "../../api/UserApi";

import cookie from "react-cookies";

const token = cookie.load("access_token");

export const getDeletedContract = (page, listPageSize, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(
        `/contracts/api/soft-deleted/?page=${page}&page_size=${listPageSize}`,
      );
      callback(response);
    } catch (error) {}
  };
};

export const restoreDeletedContract = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).patch(`/contracts/api/soft-deleted/restore/${id}/`);
      callback(response);
    } catch (error) {}
  };
};

export const deleteDeletedContract = (id, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).delete(`/contracts/api/soft-deleted/restore/${id}/`);
      callback(response);
    } catch (error) {}
  };
};
