import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedBookedRoomSingleAction } from "../../../redux/actions/bookingListAction";
import { useNavigate, useParams } from "react-router";
import { IoAdd } from "react-icons/io5";
import { FaWpforms } from "react-icons/fa6";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { AiOutlinePercentage } from "react-icons/ai";
import lightToast from "light-toast";
import { getCurrencyMidNumber, getMidNumber } from "../../../redux/actions/posVisaAction";
import { getBankListAction } from "../../../redux/actions/cashAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { convertingCurrencyAction } from "../../../redux/actions/convertingCurrency";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendData } from "../../../redux/actions/sendDataAction";
import { ADD_POSVISA_URL } from "../../../urls";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";

const AddPosVisa = () => {
  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  const params = useParams();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [mid, setMid] = useState("");
  const [amount, setAmount] = useState(null);
  const [commission, setCommission] = useState(null);
  const [balance, setBalance] = useState("");
  const [currency, setCurrency] = useState("");
  const [bank, setBank] = useState("");
  const [convertAmount, setConvertAmount] = useState(0);
  const [convertRate, setConvertRate] = useState();
  useRemoveScroll();
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      source_name: "",
      amount: "",
      commission: "",
      transaction_receipt: "",
      mid_number: "",
    },
    validationSchema: Yup.object({
      source_name: Yup.string()
        .required("Please enter source name")
        .min(1, "source name must be greater than or equal to 2")
        .max(50, "source name must be smaller than or equal to 50"),
      mid_number: Yup.string().required("Please select MID Number"),
      amount: Yup.number()
        .required("Please enter an amount")
        .positive(" Amount must be positive number")
        .integer(" Amount must be positive number")
        .min(0, " Amount must be greater than or equal to 0"),
      commission: Yup.number()
        .required("Please enter an commission")
        .positive(" Commission must be positive number")
        .min(0, " Commission must be greater than or equal to 0")
        .max(9.99, "Commission must be smaller than 10%")
        .transform((originalValue, originalObject) => {
          // Round the number to 2 decimal places
          return isNaN(originalValue) ? originalValue : parseFloat(Number(originalValue).toFixed(2));
        }),
      transaction_receipt: Yup.mixed().required("Please upload a transaction receipt"),
    }),
    onSubmit: (values) => {
      const bankSelect = Bank.filter((item) => item.label === bank);

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      formData.append("booked_room", params.id);
      formData.append("currency", currency);
      formData.append("transaction_status", "confirmed");
      formData.append("bank", bankSelect[0].value);
      formData.append("balance", Number(balance).toFixed(2));
      formData.append("contract_currency", selectedSingleRoom.currency_type);
      formData.append("conversion_rate_price", convertAmount.toFixed(2));
      formData.append("conversion_rate", convertRate);
      setSubmitting(true);
      dispatch(
        sendData(
          ADD_POSVISA_URL,
          formData,
          (result) => {
            if (result.status === 201) {
              lightToast.success("The Add Cash Form was completed successfully");
              navigate(-1);
              setSubmitting(true);
            }
          },
          (failure) => {
            setSubmitting(false);
          },
        ),
      );
    },
  });

  useEffect(() => {
    //////////handle when reload page , alert show to make sure this i want reload ////////////
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    //////////////// Cancellatioin Request ////////////////////
    const controller = new AbortController();
    const signal = controller.signal;
    ////////////////////////////////////////////////////////
    dispatch(getSelectedBookedRoomSingleAction(token, params.id, (result) => {}, signal));
    dispatch(getMidNumber(token, signal));
    dispatch(getBankListAction(token, (result) => {}, signal));
    return () => {
      controller.abort();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const { midNumberData } = useSelector((state) => state.posVisaReducer);
  const { currencyMidNumber } = useSelector((state) => state.posVisaReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  const { selectedSingleRoom } = useSelector((state) => state.bookingListReducer);

  let Bank = bankData
    ? bankData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  let midNumber = midNumberData
    ? midNumberData.map((item, index) => {
        return { value: item.id, label: item.mid_number };
      })
    : [];

  const handleAmount = (e) => {
    setAmount(e.target.value);
    setFieldValue("amount", e.target.value);
    if (commission) {
      const calc = (e.target.value * commission) / 100;
      setBalance(amount - calc);
      setConvertAmount((amount - calc) * convertRate);
    }
  };

  const handleInputChange = (e) => {
    // Use a regular expression to check if the input is a number with up to two digits.

    const isValidInput = e.target.value;
    setFieldValue("commission", Number(e.target.value).toFixed(2));
    setCommission(isValidInput); // Change 1 to your desired maximum length

    const calc = (amount * isValidInput) / 100;
    setBalance(amount - calc);
    if (convertRate) {
      setConvertAmount((amount - calc) * convertRate);
    }
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const bankSelect = Bank.filter((item) => item.label === bank);
  //
  //
  //   const formData = new FormData(e.target);
  //   formData.append("booked_room", params.id);
  //   formData.append("mid_number", mid);
  //   formData.append("currency", currency);
  //   formData.append("transaction_status", "confirmed");
  //   formData.append("bank", bankSelect[0].value);
  //   formData.append("commission", Number(commission).toFixed(2));
  //   formData.append("balance", Number(balance).toFixed(2));
  //   formData.append("contract_currency", selectedSingleRoom.currency_type);
  //   formData.append("conversion_rate_price", convertAmount.toFixed(2));
  //   formData.append("conversion_rate", convertRate);
  //   dispatch(
  //     postPosVisaAAction(token, formData, (result) => {
  //       if (result.status === 201) {
  //         lightToast.success("The POS Visa Form was completed successfully");
  //         navigate(-1);
  //       }
  //     })
  //   );
  // };

  /////permission/////////
  const permissionData = {
    permission_codes: ["accounting.add_posmachinetransaction"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap">
      <div className="flex flex-col items-center justify-center h-full w-full">
        {midNumberData && permissionFlag ? (
          permission.map((item, index) => {
            if (item.name === "accounting.add_posmachinetransaction" && item.value === true) {
              return (
                <div key={index} className=" w-full   ">
                  <div className=" flex justify-center ">
                    <h2 className="header-h2">
                      <FaWpforms className="w-5 h-5" />
                      Add POS Visa Amount Form
                    </h2>
                  </div>
                  <form className="form-wrap  w-full" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col justify-center items-center gap-y-2">
                      <div className="w-full flex flex-col gap-y-1 justify-start items-start">
                        <label className=" text-center w-full text-gray-600">Source Name</label>
                        <input
                          placeholder="Source Name"
                          name="source_name"
                          type="text"
                          value={values.source_name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className={`w-full px-3 h-10  border rounded-md  bg-white ${
                            touched.source_name && errors.source_name && "border border-red-800"
                          } `}
                        />
                        {touched.source_name && errors.source_name ? (
                          <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                            {errors.source_name}
                          </div>
                        ) : null}
                      </div>
                      <label className=" flex text-gray-600 ">MID Number</label>
                      <Select
                        name="mid_number"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: `${touched.mid_number && errors.mid_number && "rgb(153 27 27)"}`,
                          }),
                        }}
                        options={midNumber}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("mid_number", e.label);
                          setMid(e.label);
                          setToggleLoading(true);
                          setToggle(false);
                          dispatch(
                            getCurrencyMidNumber(token, e.label, (result) => {
                              if (result.status === 200) {
                                setToggle(true);
                                setToggleLoading(false);
                                setCurrency(result.data.currency);
                                setBank(result.data.bank);
                                dispatch(
                                  convertingCurrencyAction(
                                    token,
                                    result.data.currency,
                                    selectedSingleRoom.currency_type,
                                    (result) => {
                                      if (result.status === 200) {
                                        result.data.results.length !== 0
                                          ? setConvertAmount(
                                              amount && amount * result.data.results.map((item) => item.rate),
                                            )
                                          : setConvertAmount(amount && amount * 1);
                                        result.data.results.length !== 0
                                          ? setConvertRate(...result.data.results.map((item) => item.rate))
                                          : setConvertRate(1);
                                      }
                                    },
                                  ),
                                );
                              }
                            }),
                          );
                        }}
                        isSearchable
                        className=" w-full h-10 rounded-md bg-white "
                        placeholder="Mid Number"
                      />
                      {touched.mid_number && errors.mid_number ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.mid_number}
                        </div>
                      ) : null}
                      {toggle ? (
                        <>
                          <label className=" flex text-gray-600 ">Currency</label>
                          <div name="currency" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{currencyMidNumber && currencyMidNumber.currency}</p>
                          </div>

                          <label className=" flex text-gray-600 ">Bank Name</label>
                          <div name="bank" className=" w-full px-3 h-10  border rounded-md floating-input bg-white">
                            <p className="text-black mt-2">{currencyMidNumber && currencyMidNumber.bank}</p>
                          </div>
                        </>
                      ) : (
                        toggleLoading && (
                          <div className=" w-full px-3 h-10 flex items-center justify-center  ">
                            <ButtonLoading />
                          </div>
                        )
                      )}
                      <label className=" flex text-gray-600">Amount</label>
                      <input
                        placeholder="Amount"
                        name="amount"
                        type="Number"
                        step="any"
                        onChange={handleAmount}
                        onBlur={handleBlur}
                        className={`w-full px-3 h-10  border rounded-md  bg-white ${
                          touched.amount && errors.amount && "border border-red-800"
                        } `}
                      />
                      {touched.amount && errors.amount ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.amount}
                        </div>
                      ) : null}
                      {amount && (
                        <>
                          <label className=" flex text-gray-600 ">Commission</label>
                          <div className="w-full relative text-gray-600">
                            <input
                              placeholder="commission"
                              type="number"
                              step="any" // set the step to allow up to two decimal places
                              name="commission"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              className={`w-full px-8 h-10  border rounded-md  bg-white ${
                                touched.commission && errors.commission && "border border-red-800"
                              } `}
                            />
                            <div className="absolute top-3 left-2">
                              <AiOutlinePercentage className="w-5 h-5 text-gray-400" />
                            </div>
                          </div>
                          {touched.commission && errors.commission ? (
                            <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                              {errors.commission}
                            </div>
                          ) : null}
                        </>
                      )}
                      {values.commission && 9.99 >= values.commission && values.commission >= 0 && (
                        <>
                          <label className=" flex text-gray-600">Balance</label>
                          <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                            <p className="text-black "> {balance}</p>
                          </div>
                        </>
                      )}
                      {values.commission && 9.99 >= values.commission && values.commission >= 0 && currency && (
                        <>
                          <label className=" flex text-gray-600 ">
                            Amount With Currency Of Contract ({selectedSingleRoom.currency_type})
                          </label>
                          <div className=" w-full flex flex-col justify-center items-start px-3 h-10  border rounded-md floating-input bg-white ">
                            <div className="text-black flex items-end gap-x-1">
                              {convertAmount}{" "}
                              <span className="text-sm font-semibold text-gray-700">
                                {selectedSingleRoom.currency_type}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      <label className=" flex text-gray-600 ">Visa Copy</label>
                      <input
                        placeholder="Visa Copy"
                        name="transaction_receipt"
                        type="file"
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue("transaction_receipt", e.target.files[0])}
                        className={` w-full px-3 h-10 py-1 border rounded-lg bg-white ${
                          touched.transaction_receipt && errors.transaction_receipt && "border border-red-800"
                        } `}
                      />
                      {touched.transaction_receipt && errors.transaction_receipt ? (
                        <div className="w-full flex items-center justify-start text-red-800 text-sm font-semibold">
                          {errors.transaction_receipt}
                        </div>
                      ) : null}
                      <div className="w-full flex items-center justify-center">
                        <img
                          src={values.transaction_receipt && URL.createObjectURL(values.transaction_receipt)}
                          className="w-52"
                        />
                      </div>
                      <button
                        type="Submit"
                        disabled={isSubmitting}
                        className="flex items-center gap-x-1 px-3 py-1 rounded-md bg-[#002244] text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                      >
                        Add{" "}
                        {isSubmitting ? (
                          <>
                            <TopBarProgress />
                            <ButtonLoading />
                          </>
                        ) : (
                          <IoAdd className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              );
            }
          })
        ) : (
          <div className="w-full flex items-center justify-center h-screen">
            <TopBarProgress /> <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddPosVisa;
