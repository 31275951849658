import { GET_HOTEL_DETAILS } from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getHotelDetailsSuccessfull(data) {
  return {
    type: GET_HOTEL_DETAILS,
    payload: data,
  };
}

export default function getHotelDetails(slug) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/hotels/api/v1/get-put-delete-hotels/${slug}`);
      const data = response.data;
      dispatch(getHotelDetailsSuccessfull(data));
    } catch (error) {}
  };
}
