const initialState = {
  previousSearch: true,
};

export const previousSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PREVIOUSSEARCH":
      return { ...state, previousSearch: action.payload };
    default:
      return state;
  }
};
