import {
  ADD_CHILD_MEAL,
  ADD_MEAL_CHILD_END_AGE,
  Add_MEAL_CHILD_ORDER,
  ADD_MEAL_CHILD_START_AGE,
  ADD_NEW_CHILD_MEAL,
  CLEAR_CHILDREN_MEALS_OLD_VALUES,
  REMOVE_CHILD_MEAL,
  REMOVE_MEAL_NAME,
  USE_EXISTING_MEALS_VALUES,
} from "../types/types";

const defaultState = [];

export default function contractChildrenMealsReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_NEW_CHILD_MEAL:
      return action.payload;
    case REMOVE_CHILD_MEAL:
      return action.payload;
    case Add_MEAL_CHILD_ORDER:
      return action.payload;
    case ADD_MEAL_CHILD_START_AGE:
      return action.payload;
    case ADD_MEAL_CHILD_END_AGE:
      return action.payload;
    case ADD_CHILD_MEAL:
      return action.payload;
    case REMOVE_MEAL_NAME:
      return action.payload;
    case USE_EXISTING_MEALS_VALUES:
      return action.payload;
    case CLEAR_CHILDREN_MEALS_OLD_VALUES:
      return action.payload;
    default:
      return state;
  }
}
