import React from "react";
import useHotelNames from "../../../customHooks/useHotelNames";
import useData from "../../../customHooks/useData";
import Select from "react-select";
import { FaWpforms } from "react-icons/fa";
import usePermissions from "../../../customHooks/usePermissions";
import { useFormik } from "formik";
import { addRoomValidationSchema } from "./addRoomValidationSchema";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { IoAdd } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { sendFormData } from "../../../redux/actions/sendDataAction";
import { useNavigate } from "react-router";
import Loading from "../Loading/Loading";
import NoPermission from "../NoPermission/NoPermission";
import { addRoom_URL, roomFeatures_URL } from "../../../urls";
import lightToast from "light-toast";
import { NotificationContainer, NotificationManager } from "react-notifications";
import TopBarProgress from "react-topbar-progress-indicator";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import FileUploader from "../FileUploader/FileUploader";
import { toast } from "react-toastify";

export default function AddRoom() {
  useRemoveScroll();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hotels = useHotelNames();
  const features = useData(roomFeatures_URL);
  const extraInfo = useData("/rooms/api/v1/room-extra-info");

  const [permissions, permissionsFlag] = usePermissions(["rooms_management.add_rooms"]);

  const hotelsArr = hotels ? hotels.map((hotel) => ({ label: hotel.name, value: hotel.name })) : [];
  const featuresArr = features ? features.map((feature) => ({ label: feature.feature, value: feature.feature })) : [];
  const extraInfArr = extraInfo ? extraInfo.map((info) => ({ label: info.name, value: info.id })) : [];

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues: {
      hotel_name: "",
      room_name: "",
      room_name_ar: "",
      description: "",
      description_ar: "",
      space: "",
      max_adults: "",
      max_children: "",
      max_occupancy: "",
      view: "",
      view_ar: "",
      balcony: "",
      features: [],
      ordering: "",
      room_images: [],
      extra_info: [],
    },
    validationSchema: addRoomValidationSchema,
    onSubmit: submit,
  });

  function submit(values) {
    const { extra_info, room_images, features, ...restOfValues } = values;

    for (const image of room_images) {
      if (!image.valid) {
        toast.error(`Image ${image.name} is not valid because ${image.errors[0]}`);
        setSubmitting(false);
        return;
      }
    }

    const formData = new FormData();

    extra_info.forEach((info) => formData.append("extra_info", info));

    features.forEach((feature) => formData.append("features", feature));
    room_images.forEach((image) => formData.append("room_images", image.file));
    Object.keys(restOfValues).forEach((item) => formData.append(`${item}`, restOfValues[item]));
    dispatch(
      sendFormData(
        addRoom_URL,
        formData,
        (resp) => {
          navigate(`/dashboard/room-details/${resp.data.id}`);
          lightToast.success("Your room was added successfully");
          setSubmitting(false);
        },
        (error) => {
          NotificationManager.error("There was an error In your Room Please try again later");
          setSubmitting(false);
        },
      ),
    );
  }

  return (
    <div className="formPage-wrap">
      {permissionsFlag ? (
        permissions[0].value ? (
          <div className="w-full">
            {(!hotels || !features) && (
              <div className="w-full flex items-center justify-center h-screen">
                <TopBarProgress /> <Loading />
              </div>
            )}
            {hotels && features && (
              <div className=" w-full  ">
                <div className=" flex justify-center ">
                  <h2 className="header-h2">
                    <FaWpforms className="w-5 h-5" />
                    Add Room
                  </h2>
                </div>
                <form className="form-wrap" onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="w-full flex flex-col justify-center items-center gap-y-2">
                    <label className=" text-center w-full text-gray-600 font-semibold">Hotel Name</label>
                    <Select
                      name="hotel_name"
                      options={hotelsArr}
                      isSearchable
                      className="w-full  h-10  rounded-lg  bg-white capitalize"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: `${errors.hotel_name && touched.hotel_name && "rgb(153 27 27)"}`,
                        }),
                      }}
                      placeholder="Hotel Name"
                      onChange={(e) => setFieldValue("hotel_name", e.value)}
                      onBlur={handleBlur}
                    />
                    {errors.hotel_name && touched.hotel_name && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.hotel_name}
                      </div>
                    )}
                    <div className="flex max-sm:flex-col gap-4 w-full">
                      <div className="flex-1 max-sm:w-full">
                        <label className=" text-center w-full text-gray-600 font-semibold">Room Name</label>
                        <input
                          placeholder="Room Name"
                          name="room_name"
                          type="text"
                          className={`w-full px-3 h-10  border rounded-lg bg-white ${
                            errors.room_name && touched.room_name && "border-red-900"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.room_name && touched.room_name && (
                          <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                            {errors.room_name}
                          </div>
                        )}
                      </div>
                      <div className="flex-1 max-sm:w-full">
                        <label className="text-center w-full text-gray-600 font-semibold">Room Arabic Name</label>

                        <input
                          placeholder="Room Arabic Name"
                          name="room_name_ar"
                          type="text"
                          className={`w-full px-3 h-10  border rounded-lg bg-white ${
                            errors.room_name_ar && touched.room_name_ar && "border-red-900"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.room_name_ar && touched.room_name_ar && (
                          <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                            {errors.room_name_ar}
                          </div>
                        )}
                      </div>
                    </div>

                    <label className="flex text-gray-600 font-semibold">Description</label>
                    <textarea
                      name="description"
                      type="text"
                      className={`w-full p-3 border rounded-lg bg-white ${
                        errors.description && touched.description && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.description}
                      </div>
                    )}

                    <label className="flex text-gray-600 font-semibold">Arabic Description</label>
                    <textarea
                      dir="rtl"
                      name="description_ar"
                      type="text"
                      className={`w-full p-3 border rounded-lg bg-white ${
                        errors.description_ar && touched.description_ar && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description_ar && touched.description_ar && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.description_ar}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Space</label>
                    <input
                      placeholder="Space"
                      name="space"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.space && touched.space && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.space && touched.space && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.space}</div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Max Adults</label>
                    <input
                      placeholder="Max Adults"
                      name="max_adults"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.max_adults && touched.max_adults && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.max_adults && touched.max_adults && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.max_adults}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Max Children</label>
                    <input
                      placeholder="Max Children"
                      name="max_children"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.max_children && touched.max_children && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.max_children && touched.max_children && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.max_children}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Max Occupancy</label>
                    <input
                      placeholder="Max Occupancy"
                      name="max_occupancy"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.max_occupancy && touched.max_occupancy && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.max_occupancy && touched.max_occupancy && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.max_occupancy}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">View</label>
                    <input
                      placeholder="View"
                      name="view"
                      type="text"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.view && touched.view && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.view && touched.view && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.view}</div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Arabic View</label>
                    <input
                      placeholder="Arabic View"
                      name="view_ar"
                      type="text"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.view_ar && touched.view_ar && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.view_ar && touched.view_ar && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.view_ar}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Features</label>
                    <Select
                      name="features"
                      options={featuresArr}
                      isSearchable
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: `${errors.features && touched.features && "rgb(153 27 27)"}`,
                        }),
                      }}
                      className={`w-full  h-10  rounded-lg  bg-white capitalize `}
                      isMulti
                      placeholder="Features"
                      onChange={(selectedOptions) => {
                        const updatedFeatures = selectedOptions.map((option) => option.value);
                        setFieldValue("features", updatedFeatures);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.features && touched.features && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.features}
                      </div>
                    )}
                    <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                      Extra Info
                    </label>
                    <Select
                      name="extra_info"
                      options={extraInfArr}
                      isSearchable
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: `${errors.extra_info && touched.extra_info && "rgb(135 27 27)"}`,
                        }),
                      }}
                      className={`w-full  h-10  rounded-lg  bg-white capitalize `}
                      isMulti
                      placeholder="Room Extra Info"
                      onChange={(selectedOptions) => {
                        const updatedExtra = selectedOptions.map((option) => option.value);
                        setFieldValue("extra_info", updatedExtra);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.extra_info && touched.extra_info && (
                      <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                        {errors.extra_info}
                      </div>
                    )}
                    <label className="text-center w-full text-gray-600 font-semibold">Balcony</label>
                    <div
                      className={`flex items-center justify-center gap-x-10  h-10 w-full p-3 border rounded-lg bg-white ${
                        errors.balcony && touched.balcony && "border-red-900"
                      }`}
                    >
                      <div className="flex items-center justify-center gap-x-3 w-[50%]">
                        <label className="text-gray-600 text-lg">Yes</label>
                        <input
                          placeholder="Employee Name"
                          name="balcony"
                          type="radio"
                          value="Yes"
                          className=" w-5 h-5  border rounded-lg bg-white "
                          onChange={(e) => setFieldValue("balcony", e.target.value)}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="flex items-center justify-center gap-x-3 w-[50%]">
                        <label className="text-gray-600 text-lg">No</label>
                        <input
                          placeholder="Total Amount"
                          name="balcony"
                          type="radio"
                          value="No"
                          className=" w-5 h-5  border rounded-lg bg-white font-semibold"
                          onChange={(e) => setFieldValue("balcony", e.target.value)}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    {errors.balcony && touched.balcony && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold ">
                        {errors.balcony}
                      </div>
                    )}
                    <label className=" text-center w-full text-gray-600 font-semibold">Arrange Rooms</label>
                    <input
                      placeholder="Arrange Rooms"
                      name="ordering"
                      type="number"
                      className={`w-full px-3 h-10  border rounded-lg bg-white ${
                        errors.ordering && touched.ordering && "border-red-900"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.ordering && touched.ordering && (
                      <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                        {errors.ordering}
                      </div>
                    )}

                    <label className=" text-center w-full text-gray-600 font-semibold">Upload Images</label>
                    <div
                      className={` w-full  border rounded-md   bg-white ${
                        errors.room_images && touched.room_images && "border border-red-900"
                      }`}
                    >
                      <FileUploader
                        accept={"image/*"}
                        files={values.room_images}
                        setFiles={(files) => {
                          setFieldValue("room_images", files);
                        }}
                      />
                    </div>
                  </div>
                  {errors.room_images && touched.room_images && (
                    <div className="w-full mt-2 flex justify-start text-xs text-red-800 font-semibold">
                      {errors.room_images}
                    </div>
                  )}
                  <div className="flex justify-center mt-6">
                    <button
                      type="Submit"
                      disabled={isSubmitting}
                      className={`addButton ${isSubmitting && "cursor-not-allowed"}`}
                    >
                      Add Room{" "}
                      {isSubmitting ? (
                        <>
                          <ButtonLoading /> <TopBarProgress />
                        </>
                      ) : (
                        <IoAdd className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}
            <NotificationContainer />
          </div>
        ) : (
          <NoPermission />
        )
      ) : (
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress /> <Loading />
        </div>
      )}
    </div>
  );
}
