import React, { useEffect, useState } from "react";
import CustomCity from "../CityAreaHotelCustom/CustomCity";
import CoustomArea from "../CityAreaHotelCustom/CoustomArea";
import CoustomHotel from "./CoustomHotel";

const CityAreaHotel = ({ render, localStorageName = "hotelListContact" }) => {
  const initialState = {
    city: "",
    area: "",
    Allarea: "",
    hotel: "",
  };

  // Initialize formValue from localStorage if available
  const [formValue, setFormValue] = useState(() => {
    const stored = localStorage.getItem(localStorageName);
    return stored ? JSON.parse(stored) : initialState;
  });

  const { city, area, Allarea, hotel } = formValue;

  // Handle form value changes
  const onChange = (name, value) => {
    let updatedFormValue = { ...formValue };

    if (name === "city") {
      // Clear area, Allarea, and hotel when a new city is selected
      updatedFormValue = {
        ...formValue,
        city: value,
        area: "", // Clear the area value when city changes
        Allarea: "", // Clear Allarea as well
        hotel: "", // Clear hotel as well
      };
      render({ city: value, area: "", hotel: "" });
    } else if (name === "area") {
      updatedFormValue = {
        ...formValue,
        area: value,
        Allarea: "", // Clear Allarea if a specific area is selected
        hotel: "", // Clear hotel when area changes
      };
      render({ city: formValue.city, area: value, hotel: "" });
    } else if (name === "Allarea") {
      updatedFormValue = {
        ...formValue,
        Allarea: value,
        area: "", // Clear area if Allarea is selected
        hotel: "", // Clear hotel when Allarea is selected
      };
      render({ city: formValue.city, area: value, hotel: "" });
    } else if (name === "hotel") {
      updatedFormValue = {
        ...formValue,
        hotel: value,
      };
      render({ city: formValue.city, area: formValue.area || formValue.Allarea, hotel: value });
    }

    setFormValue(updatedFormValue);
    localStorage.setItem(localStorageName, JSON.stringify(updatedFormValue));
  };

  // Sync formValue with localStorage on component mount
  useEffect(() => {
    const stored = localStorage.getItem(localStorageName);
    if (stored) {
      setFormValue(JSON.parse(stored));
    }
  }, [localStorageName]);

  return (
    <div className="w-full md:flex-row flex-col flex gap-2">
      <CustomCity onChange={onChange} Getlocal={formValue} />
      <CoustomArea onChange={onChange} city={city?.value} Getlocal={formValue} />
      <CoustomHotel
        onChange={onChange}
        city={city?.value}
        area={area?.value}
        Allarea={Allarea}
        hotel={hotel}
        Getlocal={formValue}
      />
    </div>
  );
};

export default CityAreaHotel;
