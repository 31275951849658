import { useFormikContext } from "formik";
import fetchDataQuery from "../../../../../react_query/fetchDataQuery";
import AsyncSearchTextInput from "../../../../../customComponents/FormComponents";
import React from "react";

function UsersSearchTextInput() {
  const { values, setFieldValue } = useFormikContext();
  // Ensure groups is an array before mapping
  const groupsIds = Array.isArray(values.groups) ? values.groups.map((group) => group.id) : [];

  const fetchData = async (query) => {
    return fetchDataQuery(`/department/api/v1/users/?groups=[${groupsIds.join(",")}]&find=${query}`);
  };

  return (
    <AsyncSearchTextInput
      label="Search for Users"
      fetchData={fetchData}
      queryKeyPrefix={["search-users", ...groupsIds]} // groupsIds is now guaranteed to be an array
      placeholder="Search for Users by First Name, Last Name, Username, or Email"
      extractKey={(user) => user.id}
      extractValue={(user) => `${user.first_name} ${user.last_name} (${user.username})`}
      value={values.users}
      setValue={(users) => setFieldValue("users", users)}
      disabled={groupsIds.length === 0} // groupsIds will always be an array
    />
  );
}

export default UsersSearchTextInput;
