import { Modal } from "flowbite-react";
import { Link } from "react-router-dom";
import { FaList } from "react-icons/fa";
import { SiMinutemailer } from "react-icons/si";
import SendEmailModal from "./SendEmailModal";
import { useState } from "react";

const modalTheme = {
  content: {
    base: "relative h-auto h-full w-full p-4 md:h-auto",
    inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
  },
};

function BookingEmailModal({ showBookingEmailModal, setShowBookingEmailModal, selectedSingleRoom }) {
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);

  return (
    <>
      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showBookingEmailModal}
        position={"center"}
        onClose={() => setShowBookingEmailModal(false)}
      >
        <Modal.Body>
          <div className="flex gap-4 justify-around">
            <div
              onClick={() => {
                setShowSendEmailModal(true);

                setShowBookingEmailModal(false);
              }}
              className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid cursor-pointer"
            >
              <div className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all">
                <div className="w-full flex justify-center items-center">
                  <div className="relative flex flex-col">
                    <SiMinutemailer className="w-12 h-12" />
                  </div>
                </div>
              </div>
              <p className="text-lg font-semibold">Send Mail</p>
            </div>
            <div className="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
              <Link
                to={"./booking-email-list"}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center">
                  <div className="relative flex flex-col">
                    <FaList className="w-12 h-12" />
                  </div>
                </div>
              </Link>
              <p className="text-lg font-semibold">Booking Email List</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SendEmailModal
        selectedSingleRoom={selectedSingleRoom}
        showSendEmailModal={showSendEmailModal}
        setShowSendEmailModal={setShowSendEmailModal}
      />
    </>
  );
}

export default BookingEmailModal;
