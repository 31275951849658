import React from "react";
import UpdateCash from "../UpdateCash/UpdateCash";
import { useParams } from "react-router";
import UpdatePaymentTransaction from "../UpdatePaymentTransaction/UpdatePaymentTransaction";
import UpdateBankTransfer from "../UpdateBankTransfer/UpdateBankTransfer";
import UpdatePosVisa from "../UpdatePosVisa/UpdatePosVisa";

const UpdateTransaction = () => {
  const params = useParams();

  const handleShow = () => {
    if (params.type === "Cash") {
      return <UpdateCash />;
    } else if (params.type === "Jumbo4pay") {
      return <UpdatePaymentTransaction />;
    } else if (params.type === "Bank Transfer") {
      return <UpdateBankTransfer />;
    } else if (params.type === "(POS) Visa") {
      return <UpdatePosVisa />;
    }
  };
  return <>{handleShow()}</>;
};

export default UpdateTransaction;
