import React from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import DateRangeSelector from "../../../../../customComponents/DateRangeSelector";

const RangeSelector = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRangeChange = (dates, dateString) => {
    if (dates) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("start_date", moment.parseZone(dateString[0]).format("YYYY-MM-DD"));
        newParams.set("end_date", moment.parseZone(dateString[1]).format("YYYY-MM-DD"));
        return newParams;
      });
    } else {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.delete("start_date");
        newParams.delete("end_date");
        return newParams;
      });
    }
  };

  return (
    <DateRangeSelector
      defaultValue={{
        startDate: searchParams.get("start_date") || null,
        endDate: searchParams.get("end_date") || null,
      }}
      format={"YYYY-MM-DD"}
      onRangeChange={handleRangeChange}
    />
  );
};

export default RangeSelector;
