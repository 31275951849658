import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";
import { Form, Formik } from "formik";
import { FormTextAreaInput, FormTextInput } from "../../../../customComponents/FormComponents";
import { LuSubtitles } from "react-icons/lu";
import { RiStickyNoteLine } from "react-icons/ri";
import { MdModeEditOutline } from "react-icons/md";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";

function EditNoteModal({ note }) {
  const { id, title, content } = note;
  const formInitialValues = { title, content };
  const [openModal, setOpenModal] = useState(false);
  const queryClient = useQueryClient();

  const { isPending, mutate: addNote } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/notes-management/api/v1/notes/${id}/`, "patch", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: "editNote",
    networkMode: "always",
    retry: false,
    onSuccess: (agent) => {
      toast.success("Note Edited Successfully");
    },
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    content: Yup.string().required("Content is required"),
  });

  function handleSubmit(values, helpers) {
    addNote(values, {
      onSuccess: () => {
        setOpenModal(false);
        helpers.resetForm();
        void queryClient.refetchQueries({
          queryKey: ["notes"],
        });
      },
    });
  }

  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        className="w-8 h-8 rounded-full dark:bg-gray-100  bg-gray-800 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-black"
      >
        <MdModeEditOutline />
      </button>

      <Formik
        key={openModal}
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => {
          return (
            <Modal dismissible position={"center"} show={openModal} onClose={() => setOpenModal(false)}>
              <Modal.Header>Add new note</Modal.Header>
              <Form>
                <Modal.Body>
                  <div className="space-y-4">
                    <FormTextInput label="Title" name="title" type="text" placeholder="Note title" Icon={LuSubtitles} />
                    <FormTextAreaInput
                      rows={5}
                      label={"Content"}
                      name="content"
                      placeholder="Note content"
                      Icon={RiStickyNoteLine}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button isProcessing={isPending} disabled={isPending} color="success" type={"submit"}>
                    Save
                  </Button>
                  <Button disabled={isPending} color="gray" onClick={() => setOpenModal(false)}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}

export default EditNoteModal;
