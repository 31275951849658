import React, { useEffect } from "react";
import { Pagination } from "flowbite-react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export function NotesPagination({ count = 1 }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get("page")) || 1);
  const pageSize = parseInt(searchParams.get("pageSize")) || 8;

  const onPageChange = (page) => {
    setCurrentPage(page);
    setSearchParams({ ...Object.fromEntries(searchParams), page });
  };

  return (
    <div className="flex overflow-x-auto justify-center pb-4">
      <Pagination currentPage={currentPage} totalPages={Math.ceil(count / pageSize)} onPageChange={onPageChange} />
    </div>
  );
}
