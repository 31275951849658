import React, { useEffect } from "react";
import "./Timer.scss";
import { useStopwatch } from "react-timer-hook";

export default function Timer({ onTick, offsetTimestamp = 0, autoStart = true }) {
  const { totalSeconds, seconds, minutes, hours, reset } = useStopwatch({
    autoStart: autoStart,
  });

  useEffect(() => {
    const stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offsetTimestamp);
    reset(stopwatchOffset, autoStart);
  }, [autoStart, offsetTimestamp, reset]);

  useEffect(() => {
    onTick(totalSeconds);
  }, [onTick, totalSeconds]);

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  return (
    <div className="timer">
      <div className="hour">{formatTime(hours)}</div>:<div className="minute">{formatTime(minutes)}</div>:
      <div className="second">{formatTime(seconds)}</div>
    </div>
  );
}
