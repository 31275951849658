import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getBankListAction } from "../../../redux/actions/cashAction";
import { TbListDetails } from "react-icons/tb";
import TopBarProgress from "react-topbar-progress-indicator";
import { getSelectedWallet } from "../../../redux/actions/walletAction";
import PosVisaWalletDetails from "./PosVisaWalletDetails";
import CashWalletDetails from "./CashWalletDetails";
import PaymentWalletDetails from "./PaymentWalletDetails";
import BankTransferWalletDetails from "./BankTransferWalletDetails";

const WalletDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const token = cookie.load("access_token");
  const [load, setLoad] = useState(false);
  const [wallet, setWallet] = useState(false);
  useEffect(() => {
    dispatch(getBankListAction(token));
    dispatch(
      getSelectedWallet(token, params.id, (result) => {
        if (result.status === 200) {
          setLoad(true);
          setWallet(result.data);
        }
      }),
    );
  }, []);
  const { cashSelectedData } = useSelector((state) => state.cashReducer);
  const { cashSelectedDataFlag } = useSelector((state) => state.cashReducer);
  const { bankData } = useSelector((state) => state.cashReducer);
  let BankFilter = bankData && wallet ? bankData.filter((item) => item.id === wallet.bank_transfer?.bank_name) : [];
  return (
    <div className="w-full overflow-x-auto h-full">
      {load ? (
        <div className="container border-gray-300 border rounded-lg  p-10 ">
          <div className="flex flex-col justify-center items-center ">
            <div className="flex flex-col    w-full  text-black rounded-md  ">
              <div className=" flex items-center justify-center w-full bg-[#002244]  rounded-lg text-2xl text-white h-16">
                <p className="flex items-center gap-x-3">
                  <TbListDetails className="w-5 h-5" />
                  Wallet Details{" "}
                </p>
              </div>

              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">User</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.user}</p>
                  </div>
                </div>
                <hr />
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">ID</p>
                  </div>
                  <div>
                    <p className="flex items-center gap-x-1"> {wallet.id}</p>
                  </div>
                </div>
              </div>
              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Agent Name</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.agent_name}</p>
                  </div>
                </div>
                <hr />
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Method Of Payment </p>
                  </div>
                  <div>
                    <p className="flex items-center gap-x-1"> {wallet.method_of_payment}</p>
                  </div>
                </div>
              </div>

              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3 bg-gray-200 ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Amount</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1">
                      {" "}
                      {wallet.amount}
                      <span>{wallet.currency}</span>
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Subtotal</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.subtotal}</p>
                  </div>
                </div>
              </div>

              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Commission </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.commission}</p>
                  </div>
                </div>
                <hr />
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Currency </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.currency}</p>
                  </div>
                </div>
              </div>
              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3  bg-gray-200">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Amount Used </p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.amount_used}</p>
                  </div>
                </div>
                <hr />
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Rest Of Payment</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-2">{wallet.rest_of_payment}</p>
                  </div>
                </div>
              </div>

              {/* Hotel id & id & persons*/}
              <div className="flex w-full justify-between items-center  p-3  ">
                <div className="flex items-center w-[50%]">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Wallet Number</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-1"> {wallet.wallet_number}</p>
                  </div>
                </div>
                <hr />
                <div className="flex  justify-between items-center w-[50%] ">
                  <div className="w-[33%]  ">
                    <p className="flex items-center gap-x-1 font-bold">Creation Date</p>
                  </div>
                  <div className="w-[70%] font-semibold text-gray-800">
                    <p className="flex items-center gap-x-2">
                      {wallet.created_date.slice(0, 10)} / {wallet.created_date.slice(11, 19)}
                    </p>
                  </div>
                </div>
              </div>
              <hr />

              {/* Hotel id & id & persons*/}
              {wallet.bank_transfer ? (
                <BankTransferWalletDetails wallet={wallet.bank_transfer} />
              ) : wallet.pos_machine_transaction ? (
                <PosVisaWalletDetails wallet={wallet.pos_machine_transaction} />
              ) : wallet.cash_payment_transaction ? (
                <CashWalletDetails wallet={wallet.cash_payment_transaction} />
              ) : (
                wallet.payment && <PaymentWalletDetails wallet={wallet.payment} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <TopBarProgress />
          <div className="w-full h-full flex items-center justify-center">
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default WalletDetails;
