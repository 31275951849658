import {
  ADD_CHILD_MAX_AGE_VALUE,
  ADD_CHILD_MIN_AGE_VALUE,
  ADD_CHILD_ORDER_VALUE,
  ADD_CHILD_PRICE_VALUE,
  ADD_DATE_VALUE,
  ADD_NEW_CHILD_TYPE_OBJ,
  ADD_NEW_DATE_RANGE,
  ADD_NEW_PRICE_TYPE_OBJ,
  ADD_NEW_ROOM_PACKAGE_OBJ,
  ADD_NEW_ROOM_VALIDITY,
  ADD_PRICE_VALUE,
  ADD_ROOM_PACKAGE_ID,
  ADD_ROOM_PACKAGE_PRICE,
  ADD_ROOM_VALUE,
  ADD_TYPE_VALUE,
  CLEAR_OLD_VALUES,
  DELETE_DATE_RANGE,
  DELETE_NEW_CHILD_TYPE_OBJ,
  DELETE_PRICE_TYPE_OBJ,
  DELETE_ROOM_PACKAGE_OBJ,
  DELETE_ROOM_VALIDITY,
  REFRESH_CONTRACT_VALIDITY,
  USE_DRAFT_VALUES,
  USE_EXISTING_VALUES,
} from "../types/types";

const defaultState = [
  {
    room: "",
    date_range: [
      {
        from_date: "",
        to_date: "",
        rooms_types: [{ price: "", type: "" }],
        validity_children_price: [],
        validity_room_packages: [{ package: "", price: 0 }],
      },
    ],
  },
];

export default function contractValidityReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_NEW_ROOM_VALIDITY:
      return action.payload;
    case DELETE_ROOM_VALIDITY:
      return action.payload;
    case ADD_ROOM_VALUE:
      return action.payload;
    case ADD_NEW_DATE_RANGE:
      return action.payload;
    case DELETE_DATE_RANGE:
      return action.payload;
    case ADD_DATE_VALUE:
      return action.payload;
    case ADD_NEW_PRICE_TYPE_OBJ:
      return action.payload;
    case ADD_NEW_CHILD_TYPE_OBJ:
      return action.payload;
    case ADD_NEW_ROOM_PACKAGE_OBJ:
      return action.payload;
    case DELETE_NEW_CHILD_TYPE_OBJ:
      return action.payload;
    case DELETE_PRICE_TYPE_OBJ:
      return action.payload;
    case DELETE_ROOM_PACKAGE_OBJ:
      return action.payload;
    case ADD_PRICE_VALUE:
      return action.payload;
    case ADD_TYPE_VALUE:
      return action.payload;
    case ADD_CHILD_ORDER_VALUE:
      return action.payload;
    case ADD_CHILD_MIN_AGE_VALUE:
      return action.payload;
    case ADD_CHILD_MAX_AGE_VALUE:
      return action.payload;
    case ADD_CHILD_PRICE_VALUE:
      return action.payload;
    case ADD_ROOM_PACKAGE_ID:
      return action.payload;
    case ADD_ROOM_PACKAGE_PRICE:
      return action.payload;
    case CLEAR_OLD_VALUES:
      return action.payload;
    case USE_EXISTING_VALUES:
      return action.payload;
    case USE_DRAFT_VALUES:
      return action.payload;
    case REFRESH_CONTRACT_VALIDITY:
      return action.payload;
    default:
      return state;
  }
}
