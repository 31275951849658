import React from 'react'
import Loading from '../Loading/Loading';
import cookie from "react-cookies";
import { useDispatch } from 'react-redux';
import { useEffect , useState } from 'react';
import { getCurrencyAction } from '../../../redux/actions/bookingListAction';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {MdOutlineDone} from 'react-icons/md'
import { getSelectedCash } from '../../../redux/actions/cashAction';
import {FaRegCalendarAlt} from 'react-icons/fa';
import {MdOutlineAccessTime} from 'react-icons/md';
import {TbListDetails} from 'react-icons/tb';
import TopBarProgress from "react-topbar-progress-indicator";
import {GoPerson} from 'react-icons/go';
import {BsCurrencyExchange} from 'react-icons/bs';

const CashWalletDetails = ({wallet}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const token = cookie.load("access_token");

  return (
  
    <div className='w-full flex flex-col justify-center items-center '>
       <div className='flex flex-col    w-full  text-black rounded-md  '> 
           <div className=' flex items-center justify-center w-full border rounded-lg text-xl font-semibold text-black h-16'>
               <p className='flex items-center gap-x-3'><TbListDetails className='w-5 h-5' />Method OF Payment (Cash) </p>   
           </div>
           
                           {/* Hotel id & id & persons*/}
                           <div className='flex w-full justify-between items-center  p-3 bg-gray-200 '>
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Amount</p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800'>
                               <p className='flex items-center gap-x-1'> {wallet.amount}<span>{wallet.currency_type}</span></p>
                               </div>
                               </div> 
                               <hr />
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>ID</p>
                               </div> 
                               <div>
                               <p className='flex items-center gap-x-1'> {wallet.id}</p>
                               </div>
                               </div> 
                           
                           </div> 
                             {/* Hotel id & id & persons*/}
                             <div className='flex w-full justify-between items-center  p-3  '>
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Conversion Rate</p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800'>
                               <p className='flex items-center gap-x-1'> {wallet.conversion_rate}</p>
                               </div>
                               </div> 
                               <hr />
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Booked Room</p>
                               </div> 
                               <div>
                               <p className='flex items-center gap-x-1'> {wallet.booked_room}</p>
                               </div>
                               </div> 
                           
                           </div> 

                            {/* Hotel id & id & persons*/}
                            <div className='flex w-full justify-between items-center  p-3 bg-gray-200 '>
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Conversion Price</p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800'>
                               <p className='flex items-center gap-x-1'> {wallet.conversion_rate_price}<span>{wallet.contract_currency}</span></p>
                               </div>
                               </div> 
                               <hr />
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Creator</p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800'>
                                   <p className='flex items-center gap-x-1'> <GoPerson className='w-5 h-5 text-[#002244] ' />{wallet.creator}</p>
                               </div>
                               </div>
                           
                           </div> 
                           
                            {/* Hotel id & id & persons*/}
                            <div className='flex w-full justify-between items-center  p-3  '>
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Currency </p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800'>
                               <p className='flex items-center gap-x-1'> {wallet.currency_type}</p>
                               </div>
                               </div> 
                               <hr />
                               <div className='flex  justify-between items-center w-[50%] '>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Source Name</p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800'>
                                   <p className='flex items-center gap-x-2'><GoPerson className='w-5 h-5 text-[#002244] ' />{wallet.source_name}</p>
                               </div>
                               </div>
                           
                           </div> 
                             {/* Hotel id & id & persons*/}
                             <div className='flex w-full justify-between items-center  p-3 bg-gray-200 '>
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Contract Currency </p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800'>
                               <p className='flex items-center gap-x-1'> {wallet.contract_currency}</p>
                               </div>
                               </div> 
                               <hr />
                               <div className='flex items-center w-[50%]'>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Booked Room Name </p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800'>
                               <p className='flex items-center gap-x-1'> {wallet.booked_room_name}</p>
                               </div>
                               </div> 
                           
                           </div> 
                           
                             
                          
                               {/* Hotel & Room Name*/}
                           <div className='flex w-full justify-between items-center  p-3'>
                           
                                   <div className='flex  justify-between items-center w-[50%] '>
                                   <div className='w-[33%]  '>
                                           <p className='flex items-center gap-x-1 font-bold'>Service Type </p>
                                       </div> 
                                       <div className='w-[70%] font-semibold text-gray-800'>
                                           <p className='flex items-center gap-x-2'>{wallet.service_type}</p>
                                       </div>
                                   </div>
                                   <div className='flex  justify-between items-center w-[50%] '>
                                       <div className='w-[33%]  '>
                                           <p className='flex items-center gap-x-1 font-bold'>Creation Date </p>
                                       </div> 
                                       <div className='w-[70%] font-semibold text-gray-800'>
                                           <p className='flex items-center gap-x-2'>{wallet.transaction_date.slice(0,10)} / {wallet.transaction_date.slice(11,19)}</p>
                                       </div>
                                   </div>
                               
                           </div>  
                           {/* Client text & Client Tag Request*/}
                           <div className='flex w-full justify-between items-center  p-3 bg-gray-200 '>
                           <div className='flex  justify-between items-start w-[50%] '>
                                   <div className='w-[33%]  '>
                                       <p className='flex items-center gap-x-2 font-bold'>Box Details </p>
                                   </div> 
                                   <div className='flex flex-col gap-y-3 w-[70%] font-semibold text-gray-800'>
                                       <p className='flex items-center gap-x-1'>
                                         <p className='flex items-center gap-x-2 w-28 font-semibold '>Name</p>  <GoPerson className='w-5 h-5 text-[#002244] ' /> {wallet.box_name.name}
                                       </p>
                                       <p className='flex items-center gap-x-1'>
                                            <p className='flex items-center gap-x-2 w-28 font-semibold '>Currency</p><BsCurrencyExchange className='w-5 h-5 text-[#002244]' /> {wallet.box_name.currency_type}
                                       </p>
                                       <p className='flex items-center gap-x-1'>
                                            <p className='flex items-center gap-x-2 w-28 font-semibold '>location</p> {wallet.box_name.location}
                                       </p>
                                   </div>
                                   </div>
                           
                               <div className='flex  justify-between items-center w-[50%] '>
                               <div className='w-[33%]  '>
                                   <p className='flex items-center gap-x-1 font-bold'>Transaction Status </p>
                               </div> 
                               <div className='w-[70%] font-semibold text-gray-800 capitalize'>
                               {wallet.transaction_status === "confirmed" ?
                                   <p className='w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-green-700 font-semibold'>
                                     {wallet.transaction_status} <MdOutlineDone className='bg-green-700 border text-white rounded-full ' /> 
                                   </p>
                                   :
                                   <p className='w-full flex items-center justify-start gap-x-1 mr-4  h-[70%] rounded-md bg-opacity-70 text-yellow-600 font-semibold'>
                                     {wallet.transaction_status}....
                                   </p>
                                 }
                               </div>
                               </div>
                           </div>  
                           
                           
   </div>
   </div>

  )
}

export default CashWalletDetails