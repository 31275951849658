import React from "react";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";
import DepartmentForm from "./DepartmentForm";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import usePermissions from "../../../../customHooks/usePermissions";
import Errorfetch from "../../Errorfetch/Errorfetch";
import NoPermission from "../../NoPermission/NoPermission";

const EditDepartmentForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [permissions, permissionFlag, permissionsError] = usePermissions(["department.change_department"]);

  const canEditDepartment =
    permissions?.find((permission) => permission.name === "department.change_department")?.value === true;

  const { isLoading: isFetchingDepartment, data: department } = useQuery({
    queryKey: ["department", id],
    queryFn: () => {
      return fetchDataQuery(`/department/api/v1/department/${id}/`);
    },
  });

  const { mutate: editDepartment, isPending: isEditingDepartment } = useMutation({
    mutationKey: ["editDepartment", id],
    mutationFn: async (data) => {
      return mutateDataQuery(`/department/api/v1/department/${id}/`, "patch", data);
    },
    onSuccess: () => {
      toast.success("Department Edited Successfully");
      navigate("/dashboard/departments-management/departments-list");
    },
  });

  const handleSubmit = (values) => {
    editDepartment({
      name: values.name,
      user: values.users.map((user) => user.id),
    });
  };

  if (isFetchingDepartment || !permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch error={"Failed to Fetch Permissions"} />;
  }

  const initialValues = {
    name: department?.name,
    groups: [],
    users: department?.user,
  };

  return canEditDepartment ? (
    <DepartmentForm
      initialValues={initialValues}
      title={"Edit Department"}
      handleSubmit={handleSubmit}
      isLoading={isEditingDepartment}
      submitButtonText={"Edit Department"}
    />
  ) : (
    <NoPermission />
  );
};

export default EditDepartmentForm;
