import api_token from "../../api/UserApi";
import {
  DEFAULT_AREA,
  DEFAULT_HOTEL,
  ERROR_SEARCH,
  FLAG,
  HOTELNAME,
  HOTELSTATUS,
  MARKUP_CHECK,
  MOREROOMS,
  ROOMDETIALS,
  SELECT_ROOM_FLAG,
  SELECTEDDATAFROMSEARCHFORM,
  SELECTEDHOTEL,
  SELECTEDHOTELFILTER,
  SINGLEROOMS,
  STOREHOTELS,
} from "../types/types";
import { base_URL } from "../../urls";

export const roomDetailsAction = (data, callback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ROOMDETIALS,
      payload: data,
    });
    callback(data);
  };
};

export const selectedSearchAction = (token, data, lang) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: HOTELSTATUS,
        payload: true,
      });
      dispatch({
        type: ERROR_SEARCH,
        payload: "",
      });

      dispatch({
        type: SELECTEDDATAFROMSEARCHFORM, /// if do this to save data which selected from search form even if use it i can catch them
        payload: data,
      });
      dispatch({
        type: FLAG,
        payload: true,
      });

      dispatch({
        type: SELECTEDHOTELFILTER,
        payload: null,
      });
      const response = await api_token(token).get("/search-api/v12", {
        params: data,
        headers: { "Accept-Language": lang },
      });

      dispatch({
        type: SELECTEDHOTEL,
        payload: response.data.data,
      });
      dispatch({
        type: SELECTEDHOTELFILTER,
        payload: response.data.filters,
      });

      dispatch({
        type: FLAG,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: ERROR_SEARCH,
        payload: "Error With Search",
      });
    }
  };
};
export const checkMarkup = (markup) => {
  return async (dispatch, getState) => {
    dispatch({
      type: MARKUP_CHECK,
      payload: markup,
    });
  };
};
export const defaultArea = (area) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DEFAULT_AREA,
      payload: area,
    });
  };
};
export const defaultHotel = (hotel) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DEFAULT_HOTEL,
      payload: hotel,
    });
  };
};
export const selectedSearchFilterAction = (token, data, lang) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: HOTELSTATUS,
        payload: true,
      });
      dispatch({
        type: ERROR_SEARCH,
        payload: "",
      });

      dispatch({
        type: SELECTEDDATAFROMSEARCHFORM, /// if do this to save data which selected from search form even if use it i can catch them
        payload: data,
      });
      dispatch({
        type: FLAG,
        payload: true,
      });

      // dispatch({
      //     type:SELECTEDHOTELFILTER,
      //     payload:null
      // })
      const response = await api_token(token).get("/search-api/v12", {
        params: data,
        headers: { "Accept-Language": lang },
      });

      dispatch({
        type: SELECTEDHOTEL,
        payload: response.data.data,
      });
      dispatch({
        type: SELECTEDHOTELFILTER,
        payload: response.data.filters,
      });

      dispatch({
        type: FLAG,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: ERROR_SEARCH,
        payload: "Error With Search",
      });
    }
  };
};

export const getMoreRoomsAction = (token, data, hotel_pk, hotelName, check, lang, callback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: STOREHOTELS,
      payload: hotel_pk,
    });
    dispatch({
      type: HOTELNAME,
      payload: hotelName,
    });

    const moreHotelsRoom = getState().SelectedSearchReducer.moreRoomsData;
    if (moreHotelsRoom.length > 0) {
      const checkHotel = moreHotelsRoom.findIndex((item) => {
        return item.hotel_pk === hotel_pk;
      });

      if (checkHotel >= 0) {
      } else {
        try {
          dispatch({
            type: SELECT_ROOM_FLAG,
            payload: true,
          });

          const response = await api_token(token).get(`${base_URL}/search-api/hotel/v12`, {
            params: data,
            headers: { "Accept-Language": lang },
          });

          dispatch({
            type: MOREROOMS,
            payload: [...getState().SelectedSearchReducer.moreRoomsData, ...response.data.data],
          });
          dispatch({
            type: HOTELSTATUS,
            payload: true,
          });
          if (response.status === 200) {
            dispatch({
              type: SELECT_ROOM_FLAG,
              payload: false,
            });
          }
        } catch (error) {}
      }
    } else {
      try {
        dispatch({
          type: SELECT_ROOM_FLAG,
          payload: true,
        });
        const response = await api_token(token).get("/search-api/hotel/v12", {
          params: data,
          headers: { "Accept-Language": lang },
        });

        dispatch({
          type: MOREROOMS,
          payload: [...getState().SelectedSearchReducer.moreRoomsData, ...response.data.data],
        });
        dispatch({
          type: SINGLEROOMS,
          payload: response.data.data,
        });
        dispatch({
          type: HOTELSTATUS,
          payload: true,
        });
        if (response.status === 200) {
          dispatch({
            type: SELECT_ROOM_FLAG,
            payload: false,
          });
        }
        callback(response);
      } catch (error) {}
    }

    dispatch({
      type: HOTELSTATUS,
      payload: false,
    });
  };
};

export const clearmoreRooms = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: MOREROOMS,
      payload: null,
    });
  };
};

export const clearDataOfRoom = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: MOREROOMS,
      payload: "",
    });
  };
};
