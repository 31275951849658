import React, { useEffect, useState } from "react";
import { RiKeyboardLine } from "react-icons/ri";
import { PiHandshakeLight } from "react-icons/pi";
import { VscAdd } from "react-icons/vsc";
import { IoIosList } from "react-icons/io";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { Link } from "react-router-dom";

export default function Offers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["offers.add_offer", "offers.view_offer"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap">
      {permissionFlag ? (
        <div className="w-full ">
          <div className="w-full flex justify-center   ">
            <h2 className="header-h2">
              <RiKeyboardLine className="w-5 h-5" />
              Offers
            </h2>
          </div>

          <div className="w-full grid grid-cols-2 border mb-4  gap-y-5 my-5 form-wrap gap-4 max-sm:grid-cols-1">
            {permission.map((item) => {
              if (item.name === "offers.add_offer" && item.value === true) {
                return (
                  <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                    <Link
                      to={"./create-offer"}
                      // onClick={() => navigate("./create-offer")}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center">
                        <div className="relative flex flex-col">
                          <PiHandshakeLight className="w-12 h-12" />
                          <VscAdd className="absolute top-10 left-10 w-4 h-4" />
                        </div>
                      </div>
                    </Link>
                    <p className="text-lg font-semibold">Create an offer</p>
                  </div>
                );
              } else if (item.name === "offers.view_offer" && item.value === true) {
                return (
                  <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
                    <Link
                      to={"./offers-list"}
                      // onClick={() => navigate("./offers-list")}
                      className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-slate-800 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                    >
                      <div className="w-full flex justify-center items-center ">
                        <IoIosList className="w-12 h-12" />
                      </div>
                    </Link>
                    <p className="text-lg font-semibold">Offers List</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
