import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillCreditCardFill } from "react-icons/bs";
import { BsCashCoin } from "react-icons/bs";
import { GiBank } from "react-icons/gi";
import { RiKeyboardLine } from "react-icons/ri";
import { BsSafe } from "react-icons/bs";
import { GiCash } from "react-icons/gi";
import { IoIosList } from "react-icons/io";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { AiOutlineLink } from "react-icons/ai";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
import { getMethodsPayment } from "../../../redux/actions/hotelPayments";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";

const HotelsPaymentOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setload] = useState(false);
  const [methods, setMethods] = useState();
  const token = cookie.load("access_token");
  useEffect(() => {
    dispatch(
      getMethodsPayment((result) => {
        if (result.status === 200) {
          setload(true);
          setMethods(result.data);
        }
      }),
    );
  }, []);
  return (
    <div className="formPage-wrap">
      {load ? (
        <div className="w-full ">
          <div className="w-full flex justify-center  ">
            <h2 className="header-h2">
              {" "}
              <RiKeyboardLine className="w-5 h-5" />
              Hotel Payment Orders
            </h2>
          </div>

          <div className="w-full grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 form-wrap">
            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={`./${methods[0]}`}
                // onClick={() => navigate(`./${methods[0]}`)}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <BsCashCoin className="w-12 h-12   " />
                </div>
              </Link>
              <p className="text-lg font-semibold">Bank Cash Deposit </p>
            </div>

            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={`./${methods[1]}`}
                //  onClick={() => navigate(`./${methods[1]}`)}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <AiOutlineLink className="w-12 h-12   " />
                </div>
              </Link>
              <p className="text-lg font-semibold">Payment Link</p>
            </div>

            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={`./${methods[2]}`}
                // onClick={() => navigate(`./${methods[2]}`)}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <GiCash className="w-12 h-12   " />
                </div>
              </Link>
              <p className="text-lg font-semibold">Cash In Hotel</p>
            </div>
            <div class="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
              <Link
                to={`./${methods[3]}`}
                // onClick={() => navigate(`./${methods[3]}`)}
                className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-green-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                <div className="w-full flex justify-center items-center ">
                  <HiOutlineStatusOnline className="w-12 h-12   " />
                </div>
              </Link>
              <p className="text-lg font-semibold">Online Bank Tranfer</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <>
            <TopBarProgress />
            <Loading />
          </>
        </div>
      )}
    </div>
  );
};

export default HotelsPaymentOrders;
