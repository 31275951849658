import {
  MULTIRESERVATIONDATA,
  DISPLAYMULTIRESERVATION,
  BTN_SAVE_FLAG,
  BTN_SAVE_NUM,
  RESRVATION_NOTIF_FLAG,
} from "../types/types";
import api_token from "../../api/UserApi";
import { toast } from "react-hot-toast";
import { base_URL } from "../../urls";

export const multiReservationDataAction = (data, hotelName, roomDetails, room, currentStep, roomtype_id) => {
  return async (dispatch, getState) => {
    const itemIndex = getState().multiReservationReducer.multiReservationData.findIndex(
      (item) => item.hotel.name === hotelName,
    );

    if (itemIndex >= 0) {
      const newItem = getState().multiReservationReducer.multiReservationData.map((item, index) => {
        if (item.hotel.name === hotelName) {
          const roomIndex = item.hotel.rooms.findIndex(
            (
              info,
              index, /// i use this to catch index of array which i want update
            ) => currentStep === info.currentStep,
          );

          if (roomIndex >= 0) {
            return {
              ...item,
              hotel: {
                ...item.hotel,
                rooms: item.hotel.rooms.map((room, index) => {
                  if (currentStep === room.currentStep) {
                    // If the room's ID matches the updated room's ID, update it
                    return { ...room, ...roomDetails };
                  } else {
                    // Otherwise, keep the room unchanged

                    return room;
                  }
                }),
              },
            };
          } else {
            return {
              ...item,
              hotel: {
                ...item.hotel,
                rooms: [...item.hotel.rooms, roomDetails],
              },
            };
          }
        } else {
          return item;
        }
      });
      dispatch({
        type: MULTIRESERVATIONDATA,
        payload: newItem,
      });
    } else {
      dispatch({
        type: MULTIRESERVATIONDATA,
        payload: [...getState().multiReservationReducer.multiReservationData, data],
      });
    }
    toast.success(`${room} is submitted`);
  };
};

export const postMultiReservationData = (token, data, callback, failureCallback) => {
  return async (dispatch, getState) => {
    // callback(response.data);
    try {
      const response = await api_token(token).post(`${base_URL}/booking/api/v1/add-multiple-book-room/`, data);

      dispatch({
        type: DISPLAYMULTIRESERVATION,
        payload: response.data,
      });
      if (response.status === 201) {
        callback(response.data);
        dispatch({
          type: RESRVATION_NOTIF_FLAG,
          payload: true,
        });
        setTimeout(() => {
          dispatch({
            type: RESRVATION_NOTIF_FLAG,
            payload: false,
          });
        }, [10000000]);
      } else {
        failureCallback(response.data);
      }
    } catch (error) {
      failureCallback(error);
    }
  };
};

export const clearDisplayMultiReservationData = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: MULTIRESERVATIONDATA,
      payload: [],
    });
    dispatch({
      type: DISPLAYMULTIRESERVATION,
      payload: [],
    });
  };
};

export const savedButtonAction = (status, x) => {
  //// this fro next button can't work without click on save btn in form
  return async (dispatch, getState) => {
    dispatch({
      type: BTN_SAVE_FLAG,
      payload: status,
    });
  };
};
