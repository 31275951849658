import React, { useEffect } from "react";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import "./StaticFromInput.css";
// this component should take
// 1) name: (string) will be put as the name and the id of the select element
// 2) text: (string) will be put as the text of the select element
// 3) type: to determine the type of input
// 4) func: optional function to get the data of the input on change

export default function StaticFromInput({
  name,
  text,
  type,
  required,
  func,
  defaultValue,
  className,
  placeholder,
  icon,
  id,
  canDefaultValueZero = false,
  disabled,
  contractCallBack,
  ...props
}) {
  useRemoveScroll();

  const random = Math.random();

  function handleChange(e) {
    if (func) {
      func(e.target.value);
    }

    if (contractCallBack) {
      contractCallBack(e, e.target.value);
    }
  }

  // useEffect(() => {
  //   if (type === "date") {
  //     // Initialize with the current date
  //     const currentDate = moment().format("YYYY-MM-DD");

  //     $("input")
  //       .on("change", function () {
  //         this.setAttribute(
  //           "data-date",
  //           moment(this.value, "YYYY-MM-DD").format(this.getAttribute("data-date-format"))
  //         );
  //       })
  //       .trigger("change");

  //     // Initial setting of data-date attribute
  //     // $("input").attr("data-date", "YYYY-MM-DD");

  //     $(".input").attr("placeholder", "YYYY-MM-DD");
  //     // $("input").attr("data-date", moment(currentDate, "YYYY-MM-DD").format("Your desired format"));
  //   }
  // }, []);
  useEffect(() => {
    if (disabled) {
      document.getElementById(`${name}-${random}`).value = "";
    }
  }, [disabled]);

  return (
    <div className={`flex flex-col gap-y-1 ${className}`}>
      {text && (
        <div className="w-full flex items-center gap-x-1 text-slate-800 font-semibold">
          <div className=" text-slate-600 ">{icon}</div>
          <label htmlFor={`${name}-${random}`} className="text-slate-800 font-semibold md:text-base text-sm ">
            {text}
          </label>
        </div>
      )}

      <input
        {...props}
        type={type || "text"}
        id={id ? id : `${name}-${random}`}
        name={name}
        required={required === undefined ? true : required}
        onChange={handleChange}
        disabled={disabled}
        defaultValue={canDefaultValueZero && defaultValue === 0 ? 0 : defaultValue || ""}
        // className={className || ""}
        // pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
        className={`w-full px-3 h-10  border rounded-lg bg-white focus:outline-none focus:ring focus:border-blue-300 floating-input text-gray-800 font-semibold ${disabled && "cursor-not-allowed !bg-stone-200"}`}
        step={null}
        // data-date={type === "date" && ""}
        data-date-format={type === "date" && "DD/MM/YYYY"}
        placeholder={placeholder ? placeholder : ""}
        onWheel={(e) => e.preventDefault()}
      />
    </div>
  );
}
