import React, { memo, useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

// This function wraps any component that you want to repeat and make dynamic, it is supposed to repeat inputs
// This component will start with 1 child unless you pass number, it will start with the specified number of children
// you must pass a state and setState, the control states of the input that you use

export default memo(function RepeatComponent({ children, number, state, setState, name, errors }) {
  const [count, setCount] = useState([1]);

  useEffect(() => {
    if (number) {
      let arr = [];
      for (let i = 0; i < number; i++) {
        arr.push(1);
        setCount(arr);
      }
    }
  }, [number]);

  function increase() {
    setCount([...count, 1]);
    let stateCopy = [...state];
    stateCopy.push("");
    setState(name, stateCopy);
  }

  function decrease() {
    if (count.length > 1) {
      let countCopy = [...count];
      countCopy.pop();
      setCount(countCopy);
      let stateCopy = [...state];
      stateCopy.pop();
      setState(name, stateCopy);
    }
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        {count.map((elem, idx) => {
          return (
            <>
              {children(idx)}
              {Array.isArray(errors) && errors[idx] && (
                <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors[idx]}</div>
              )}
            </>
          );
        })}
      </div>
      <div className="flex gap-3 justify-end my-2">
        <FaMinus size={12} onClick={decrease} className="cursor-pointer hover:text-red-500" />
        <FaPlus size={12} onClick={increase} className="cursor-pointer hover:text-green-500" />
      </div>
    </div>
  );
});
