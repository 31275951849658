import React from "react";
import { Link } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";
import { CiCircleList, CiReceipt } from "react-icons/ci";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../Loading/Loading";
import NoPermission from "../NoPermission/NoPermission";
import Errorfetch from "../Errorfetch/Errorfetch";

function BookingVoucher() {
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "room_booking.add_evoucher",
    "room_booking.view_evoucher",
  ]);

  const isAddingVoucherAllowed =
    permissions?.find((permission) => permission.name === "room_booking.add_evoucher")?.value === true;

  const isViewingVoucherAllowed =
    permissions?.find((permission) => permission.name === "room_booking.view_evoucher")?.value === true;

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch Error={`Error Fetching permissions ${permissionsError.message}`} />;
  }

  return isAddingVoucherAllowed || isViewingVoucherAllowed ? (
    <div className="border-gray-300 border rounded-lg bg-white p-4 w-[95%] mx-auto">
      <div className="w-full flex justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 mt-3">
        <p className="flex items-center gap-x-3">
          <CiReceipt className="w-6 h-6" />
          Booking Voucher
        </p>
      </div>
      <div className="flex flex-wrap gap-4 justify-around items-center mt-12">
        {isAddingVoucherAllowed && (
          <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"./create-voucher"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <IoIosAdd className="w-12 h-12  " />
              </div>
            </Link>
            <p className="text-lg font-semibold">Create Voucher</p>
          </div>
        )}

        {isViewingVoucherAllowed && (
          <div className="flex flex-col items-center justify-between gap-y-4 px-3 pt-4  border-solid ">
            <Link
              to={"./vouchers-list"}
              className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-emerald-600 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
            >
              <div className="w-full flex justify-center items-center ">
                <CiCircleList className="w-12 h-12  " />
              </div>
            </Link>
            <p className="text-lg font-semibold">Vouchers List</p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <NoPermission />
  );
}

export default BookingVoucher;
