import React from "react";
import Loading from "../../organisms/Loading/Loading";
import Select from "react-select";
import { BsInfoLg } from "react-icons/bs";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function AgentsReports() {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const data = {
    labels: [
      "Bookings",
      "New Bookings",
      "Pending Bookings",
      "Confirmed Bookings",
      "Collected Bookings",
      "Cancelled Bookings",
      "Avg Daily Bookings",
    ],
    datasets: [
      {
        label: "Bookings",
        data: [100, 25, 30, 15, 12, 18, 10],
        backgroundColor: "rgba(0, 32, 64, 0.8)",
      },
    ],
  };

  return (
    <div className="w-full h-screen">
      <h1 className="mx-auto text-3xl text-white font-bold p-3 rounded-lg border w-[95%] bg-[#001a35] flex items-center justify-center gap-5">
        <BsInfoLg size={30} />
        Agents Reports
      </h1>
      <div className="mx-auto w-[30vw]  py-1">
        <label htmlFor="hotel" className="font-bold text-center flex items-center justify-center w-full">
          Choose Agent
        </label>
        <Select options={[]} isSearchable onChange={(e) => {}} />
      </div>

      {false ? (
        <Loading />
      ) : true ? (
        <Bar options={options} data={data} />
      ) : (
        <div className="text-center font-black my-10">No available data for this Agent</div>
      )}
    </div>
  );
}
