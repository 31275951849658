import { FILTER_BOOKING_TYPE } from "../types/types";


const initialState = {
    filterBookingList:null,
}

export const FilterBookingListReducer = (state = initialState , action) =>{
    switch(action.type)
{
    case FILTER_BOOKING_TYPE : 
         return{...state , filterBookingList : action.payload}
    default:
         return state;
}
}