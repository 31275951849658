import { GET_SELECTED_CASH, GET_SELECTED_CASH_FLAG , UPDATE_CASH_FLAG , DELETE_CASH_FLAG , BANK_DATA , BOX_NAME , BOX_NAME_FLAG} from "../types/types";

const initialState = {
   cashSelectedData : null,
   cashSelectedDataFlag:false,
   updateFlag : false,
   deleteCashFlag:false,
   bankData:null,
   boxNameData:null,
   boxFlag:false,
}

export const cashReducer  = (state = initialState , action) =>{
    switch(action.type)
    {
        case GET_SELECTED_CASH : 
             return{...state , cashSelectedData : action.payload}
        case GET_SELECTED_CASH_FLAG : 
             return{...state , cashSelectedDataFlag : action.payload}
        case UPDATE_CASH_FLAG : 
             return{...state , updateFlag : action.payload}
        case DELETE_CASH_FLAG : 
             return{...state , deleteCashFlag : action.payload}
        case BANK_DATA : 
             return{...state , bankData : action.payload}
        case BOX_NAME : 
             return{...state , boxNameData : action.payload}
        case BOX_NAME_FLAG : 
             return{...state , boxFlag : action.payload}
        default:
             return state;
    }
}