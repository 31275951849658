import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import TaskManagerForm from "./TaskManagerForm";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapterPlugin from "../ITDepartment/Tickets/CustomUploadAdapterPlugin";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useParams } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import NoPermission from "../NoPermission/NoPermission";
import usePermissions from "../../../customHooks/usePermissions";
import Errorfetch from "../Errorfetch/Errorfetch";

const EditTask = () => {
  const [editorData, setEditorData] = useState("");
  const [editorInstance, setEditorInstance] = useState(null);
  const [editorKey, setEditorKey] = useState(0);
  const [toolDocument, setToolDcoument] = useState([]);
  const [adding, setAdding] = useState();
  const [taskData, setTaskData] = useState();
  const [userData, setUserData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [TypeData, setTypeData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    mutate: taskManager,
    isLoading: loading,
    isPending,
  } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/tasks-management/api/v1/task-assignments/${id}/`, "patch", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    mutationKey: "taskManager",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Task Updated Successfully");
      navigate(`/dashboard/task-list`);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to updae  this task");
    },
  });

  const {
    data: task,
    error,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["task", id],
    queryFn: () => fetchDataQuery(`/tasks-management/api/v1/task-assignments/${id}/`),
  });
  useEffect(() => {
    if (isSuccess && task) {
      setTaskData(task);
    }
  }, [task, isSuccess]);

  const {
    data: department,
    error: errorDepartment,
    isSuccess: departmentSuccess,
    isLoadingTask: departmentLoading,
  } = useQuery({
    queryKey: ["department"],
    queryFn: () => fetchDataQuery("/department/api/v1/department"),
  });

  useEffect(() => {
    if (departmentSuccess && department) {
      setGroupData(department.results);
    }
  }, [department, departmentSuccess]);
  const grouOptions = useMemo(() => groupData?.map((user) => ({ label: user.name, value: user.id })), [groupData]);
  const {
    data: taskType,
    error: errorType,
    isSuccess: typeSuccess,
    isLoadingTask: typeLoading,
  } = useQuery({
    queryKey: ["taskType"],
    queryFn: () => fetchDataQuery("/tasks-management/api/v1/task-types/"),
  });
  const userIDS = taskData?.user ? taskData.user.map((usr) => usr.id) : [];
  // const {
  //   data: departmentData,
  //   error: departmentErr,
  //   isSuccess: departmentDataSuccess,
  //   isLoadingTask: deparmentLoading,
  // } = useQuery({
  //   queryKey: ["departmentData"],
  //   queryFn: () => fetchDataQuery(`/department/api/v1/department?pagination=false&users=[${userIDS}]`),
  //   enabled: userIDS.length > 0,
  // });
  // const departmentOptions = useMemo(
  //   () => departmentData?.map((departmen) => ({ label: departmen.name, value: departmen.id })),
  //   [departmentData],
  // );
  useEffect(() => {
    if (typeSuccess && taskType) {
      setTypeData(taskType);
    }
  }, [taskType, typeSuccess]);
  const typeOptions = useMemo(() => TypeData?.map((user) => ({ label: user.name, value: user.id })), [TypeData]);
  const editorConfig = {
    extraPlugins: [CustomUploadAdapterPlugin],
    language: {
      // The UI will be English.
      ui: "en",
      // But the content will be edited in Arabic.
      content: "ar",
    },
    toolbar: {
      items: [
        "undo",
        "redo",
        "fontfamily",
        "fontsize",
        "|",
        "bold",
        "italic",
        "|",
        "link",
        "uploadImage",
        "|",
        "alignment", // Add the alignment option to the toolbar
      ],
      shouldNotGroupWhenFull: true,
    },
    alignment: {
      options: ["left", "center", "right", "justify"],
    },
  };
  // function stripHTMLTags(input) {
  //   // Use a regular expression to remove any HTML tags
  //   return input.replace(/<\/?[^>]+(>|$)/g, "");
  // }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: taskData?.name || "",
      task_type: taskData?.task_type ? { label: taskData?.task_type.name, value: taskData?.task_type.id } : null,
      department: taskData?.department
        ? taskData?.department?.map((d) => ({
            label: d?.name,
            value: d.id,
          }))
        : [],
      user: taskData?.user
        ? taskData?.user?.map((usr) => ({
            label: usr?.first_name + usr?.last_name + "(" + usr?.username + ")",
            value: usr.id,
          }))
        : [],
      task_details: taskData?.task_details || "",
      attachment: taskData?.attachment || "",
    },
    validationSchema: Yup.object()
      .shape({
        name: Yup.string().required("Task Name is required"),
        task_details: Yup.string().required("Task Details is required"),
        task_type: Yup.object().required("Task Type is required"),
        user: Yup.array().nullable(),
        department: Yup.array().nullable(),
      })
      .test("user-or-department", "At least one of 'user' or 'department' is required", function (value) {
        const { user, department } = value || {};
        const isUserValid = user && user.length > 0;
        const isDepartmentValid = department && department.length > 0;

        // Ensure at least one of them is not empty
        if (!isUserValid && !isDepartmentValid) {
          return this.createError({ path: "user", message: "At least one of 'user' or 'department' must be selected" });
        }
        return true;
      }),

    onSubmit: async (values, { setSubmitting }) => {
      setAdding(true);
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("task_type", values.task_type.value);
        formData.append("taskStatus", "new");

        const departments = values.department.map((departmentId) => departmentId.value);
        const users = values.user?.map((userId) => (userId.value ? userId.value : userId.id ? userId.id : userId));
        formData.append("department", JSON.stringify(departments));
        formData.append("user", JSON.stringify(users));
        formData.append("task_details", values.task_details);

        if (toolDocument.length > 0) {
          for (let doc of toolDocument) {
            if (!doc.valid) {
              toast.error(`File ${doc.name} is not valid because ${doc.errors[0]}`);
              return;
            }
            formData.append("attachment", doc.file);
          }
        }

        // Submit the form data
        await taskManager(formData);
      } catch (error) {
        toast.error(error.message || "Failed to update this task");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [permissions, permissionFlag, permissionsError] = usePermissions(["tasks_management.change_taskassignment"]);

  const isEditingOfferAllowed =
    permissions?.find((permission) => permission.name === "tasks_management.change_taskassignment")?.value === true;

  // this will delete the hotel stored in the state by passing it to the action then remove the popup

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;
  return isEditingOfferAllowed ? (
    <TaskManagerForm
      title={"Edit a Task"}
      formik={formik}
      toolDocument={toolDocument}
      setToolDcoument={setToolDcoument}
      adding={adding}
      setAdding={setAdding}
      // statusVal={statusVal}
      initialValues={formik.initialValues}
      departmentsVal={grouOptions}
      // taskTypes={typeOptions.length > 0 ? typeOptions : taskTypes}
      taskTypes={typeOptions}
      // handleEditorChange={handleEditorChange}
      editorKey={editorKey}
      ClassicEditor={ClassicEditor}
      editorData={editorData}
      editorConfig={editorConfig}
      setEditorInstance={setEditorInstance}
      isLoading={loading}
      isPending={isPending}
      isEdit={true}
      formErrors={formErrors}
      hasSubmitted={hasSubmitted}
      // departmentOptions={departmentOptions}
    />
  ) : (
    <NoPermission />
  );
};

export default EditTask;
