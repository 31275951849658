import React from "react";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";
import Errorfetch from "../../Errorfetch/Errorfetch";
import * as Yup from "yup";
import ClientBookingHistoryForm from "./ClientBookingHistoryForm";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";

function EditClientBookingHistoryForm() {
  const { clientID, bookingID } = useParams();
  const navigate = useNavigate();
  const EditClientBookingHistoryValidationSchema = Yup.object().shape({
    hotel: Yup.number().required("Hotel is required"),
    nightsNum: Yup.number()
      .min(1, "Number of nights must be greater than or equal to 1")
      .required("Number of nights is required"),
    date: Yup.string().required("Date is required"),
    rooms: Yup.array()
      .of(Yup.number())
      .min(1, "Please select at least one room")
      .required("Please select at least one room"),
  });

  const {
    data: bookingHistory,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["client-booking-history", bookingID],
    queryFn: () => {
      return fetchDataQuery(`/auth/api/v1/customer-booking-history/${bookingID}/`);
    },
  });

  const { isPending, mutate: editBookingHistory } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery(`/auth/api/v1/customer-booking-history/${bookingID}/`, "patch", data, {
        "Content-Type": "application/json",
      });
    },
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success("Client Booking History Edited Successfully");
      navigate(`/dashboard/client-booking-history/${clientID}/booking-list`);
      refetch();
    },
    onError: (error) => {
      if (error.response.status === 400) {
        error.response.data.forEach((error) => {
          toast.error(error.message);
        });
        return;
      }
      toast.error(error.message || "Failed to edit Client Booking History");
    },
    throwOnError: (error) => {
      toast.error(error.message || "Failed to edit Client Booking History");
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Errorfetch Error={error.message} />;
  }

  const handleSubmit = (values, helpers) => {
    const data = JSON.stringify({
      customer: clientID,
      hotels: values.hotel,
      number_nights: values.nightsNum,
      notes: values.notes,
      date: values.date,
      rooms: values.rooms,
    });

    editBookingHistory(data, {
      onSuccess: () => {
        helpers.resetForm();
      },
    });
  };

  return (
    <ClientBookingHistoryForm
      validationSchema={EditClientBookingHistoryValidationSchema}
      initialValues={{
        hotel: bookingHistory?.hotels,
        nightsNum: bookingHistory?.number_nights,
        notes: bookingHistory?.notes,
        date: bookingHistory?.date,
        rooms: bookingHistory?.rooms,
      }}
      isPending={isPending}
      onSubmit={handleSubmit}
      title="Edit client booking history"
      onSubmitButtonTitle={"Update Booking"}
    />
  );
}

export default EditClientBookingHistoryForm;
