import { MULTIRESERVATIONDATA  , DISPLAYMULTIRESERVATION , BTN_SAVE_FLAG} from "../types/types"

const initialState = {
        multiReservationData : [],
        displayMultiReservationData : {},
        saveBtnFlag : false,
}

export  const multiReservationReducer = (state = initialState , action) => {
    switch(action.type){
        case MULTIRESERVATIONDATA:
            return{...state , multiReservationData: action.payload }
        case DISPLAYMULTIRESERVATION:
            return{...state , displayMultiReservationData: action.payload }
        case BTN_SAVE_FLAG:
            return{...state , saveBtnFlag : action.payload }
        default:
            return state;
    }
}
