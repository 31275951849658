import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiBaseStationLine, RiKeyboardLine } from "react-icons/ri";
import { MdAddLink, MdAddTask } from "react-icons/md";
import { BsDatabaseAdd } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentDetailsSingleBooking } from "../../../redux/actions/hotelPayments";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import NoPermission from "../NoPermission/NoPermission";

const CreatePaymentOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { short_id, id } = useParams();
  const paymentDetails = useSelector((state) => state.paymentDetailsSingleBooking);

  useEffect(() => {
    dispatch(getPaymentDetailsSingleBooking(id));
  }, []);

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: [
      "room_booking.add_hotelpaymentorder",
      "room_booking.change_hotelpaymentorder",
      "room_booking.view_hotelpaymentorder",
      "room_booking.delete_hotelpaymentorder",
    ],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);
  /////permission/////////

  return (
    <>
      {permissionFlag ? (
        permission.some((item) => item.value) ? (
          <div className="w-full border-gray-300 border rounded-lg bg-white h-screen">
            <div className="w-[98%] flex justify-center mx-auto  border-[#002244] border text-[#002244]  rounded-lg text-2xl py-2 mt-3">
              <p className="flex items-center gap-x-3">
                <RiKeyboardLine className="w-5 h-5" />
                Create Hotel Payment Order
              </p>
            </div>

            <div className="grid grid-cols-4  gap-y-5 mt-12">
              <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                <button
                  onClick={() => navigate(`./bank-cash-deposit`)}
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-teal-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                >
                  <div className="w-full flex justify-center items-center ">
                    <MdAddTask className="w-12 h-12   " />
                  </div>
                </button>
                <p className="text-lg font-semibold">Add Bank Cash Deposit </p>
              </div>

              <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                <button
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-red-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                  onClick={() => navigate(`./payment-link`)}
                >
                  <div className="w-full flex justify-center items-center ">
                    <MdAddLink className="w-12 h-12   " />
                  </div>
                </button>
                <p className="text-lg font-semibold">Add Payment Link</p>
              </div>

              <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                <button
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-sky-950 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                  onClick={() => navigate(`./cash-in-hotel`)}
                >
                  <div className="w-full flex justify-center items-center ">
                    <BsDatabaseAdd className="w-12 h-12   " />
                  </div>
                </button>
                <p className="text-lg font-semibold">Add Cash In Hotel</p>
              </div>

              <div class="flex flex-col gap-y-4 items-center justify-between  px-3 pt-4  border-solid">
                <button
                  className="flex items-center gap-x-1 w-24 h-24 rounded-full bg-gray-900 text-white hover:scale-105 active:scale-95 duration-300 transition-all"
                  onClick={() => navigate(`./online-bank-transfer`)}
                >
                  <div className="w-full flex justify-center items-center ">
                    <RiBaseStationLine className="w-12 h-12   " />
                  </div>
                </button>
                <p className="text-lg font-semibold">Add Online Bank Transfer</p>
              </div>
            </div>
            <div className="py-5 my-10 mx-auto text-center">
              {/* {paymentDetails.length > 0 ? (
          paymentDetails.map((payment_obj) => {
            return <PaymentDetailsCard payment_obj={payment_obj} />;
          })
        ) : (
          <div>No payments yet</div>
        )} */}
            </div>
          </div>
        ) : (
          <div className="w-full h-screen">
            <NoPermission />
          </div>
        )
      ) : (
        <div className="w-full h-screen flex items-center justify-center ">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </>
  );
};

export default CreatePaymentOrder;
