import { BEDONLY , BREAKFAST , HALFBOARD ,FULLBOARD , ALLINCLUSIVE} from "../types/types"

export const RoomStatus = (type , checkOfType) =>{
    return async (dispatch , getState) =>{
        if(type === 'b.o')
            if(checkOfType)
            {
                dispatch({
                    type:BEDONLY,
                    payload:type
                })
            }
            else
            {
                dispatch({
                    type:BEDONLY,
                    payload:'',
                })
            }
        else if(type === 'b.b')
        {
            if(checkOfType)
            {
                dispatch({
                    type:BREAKFAST,
                    payload:type
                })
            }
            else
            {
                dispatch({
                    type:BREAKFAST,
                    payload:'',
                })
            }
            
        }
        else if(type === 'h.b')
        {
            if(checkOfType)
            {
                dispatch({
                    type:HALFBOARD,
                    payload:type
                })
            }
           else
           {
            dispatch({
                type:HALFBOARD,
                payload:'',
            })
           }
        }
        else if(type === 'f.b')
        {   
            if(checkOfType)
            {
                dispatch({
                    type:FULLBOARD,
                    payload:type
                })
            }
            else
            {
                dispatch({
                    type:FULLBOARD,
                    payload:'',
                })
            }
            
        }
        else if(type === 'all')
        {
            if(checkOfType)
            {
                dispatch({
                    type:ALLINCLUSIVE,
                    payload:type
                })
            }
            else
            {
                dispatch({
                    type:ALLINCLUSIVE,
                    payload:'',
                })
            }
        }
        
    }
}